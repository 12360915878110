import React from 'react';
import { Outlet } from 'react-router-dom';
import styles from "./apiClient.module.scss";

function ApiClient() {
  
  return (
    <div className={styles.wrapper}>
      <Outlet/>
    </div>
  );
};

export default ApiClient;
