import React from "react";
function Documents(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="var(--palette-gray-8)" xmlns="http://www.w3.org/2000/svg">
      <g id="Icons/deployment">
        <path id="Vector" d="M23 11.9927L12 19.5406L1 11.9927V10.7703L12 18.3182L23 10.7703V11.9927ZM22.9743 8.53007L12 1L1.02567 8.53007L12 16.0601L22.9743 8.53007ZM12 2.22234L21.1923 8.53112L12 14.8388L2.80767 8.53007L12 2.22234ZM12 21.7777L1 14.2298V15.4521L12 23L22.9743 15.5L23 14.5L12 21.7777Z" fill="var(--palette-gray-8)" />
      </g>
    </svg>
  );
}


export default Documents;