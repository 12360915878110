import { getCookie } from 'shared/utils/cookies';
import { KoDate, KoForm, KoFormBuilder, KoIcon, KoLoader, KoNano, KoToast, Upload } from 'packages';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import styles from './curd.module.scss';


export default function UploadCrud({form, handleFinish, initialValues}) {
  const [isLoading, setLoading] = useState(false);
  const [lookupId, setLookupId] = useState();
  const {folderId}=useParams();

  useEffect(()=>{
    const lp = KoNano('LOK');
    setLookupId(lp);
    let data = form.getFieldsValue();
    data['lookupId'] = lp;
    form.setFieldsValue({...data});
  },[]);

  const handleSubmit = useCallback(values => {
    handleFinish(values);
  });



  const Date = ({ value, onChange }) =>
    <KoDate value={value} format={'DD-MMM-YYYY'}
      onChange={v => onChange(v)} />;
         
  const meta = {
    columns: 4,
    formItemLayout: null,
    colon: true,
    initialValues,
    fields: [
      {
        key: 'name', placeholder: 'Select name', label: 'Name', colSpan: 2,
        rules: [
          {
            required: true,
            message: 'Name is required'
          }
        ]
      },
      {
        key: 'project', placeholder: 'Select project', label: 'Project', colSpan: 2
      },
     
      {
        key: 'invoiceNo', label: 'invoiceNo', colSpan: 2, rules: [
          {
            required: true,
            message: 'invoiceNo is required'
          }
        ]
      },
    //   {
    //     key: 'status', label: 'Status', colSpan: 2
    //   },
      {
        key: 'dueDate', label: 'Due Date', colSpan: 2,widget: Date
      },
      {
        key: 'invoiceDate', label: 'Invoice Date', colSpan: 2,widget: Date
      },
      {
        key: 'lookupId', label: 'Lookup Id', colSpan: 2, className: 'metaFieldHide'
      },
      {
        key: 'invoiceNote', label: 'invoice Note', colSpan: 4,widget: 'textarea'
      }
      
      
    ]
  };




  const props = {
    name: 'files',
    action: `${process.env.REACT_APP_PROXY_HOST}/api/v1/org/upload`,
    data:{service:'finance', module:'invoice', folderId:folderId, lookupId: lookupId},
    headers: {
      Authorization: `Bearer ${getCookie("accessToken")}`
    },
    onChange(info) {
      setLoading(true);
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'done') {
        KoToast.success(`${info.file.name} file uploaded successfully`);
        setLoading(false);
      } else if (info.file.status === 'error') {
        setLoading(false);
        KoToast.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  const beforeUpload = (file) => {
  
    const isPdfOrDoc = file.type === 'application/pdf' || file.type === 'application/doc' || file.type === 'application/docx' || file.type === 'application/msword';
    if (!isPdfOrDoc) {
      KoToast.error('You can only upload PDF or DOC files!');
      setLoading(false);
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      KoToast.error('File must be smaller than 2MB!');
    }
    return isPdfOrDoc && isLt2M;
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e[0];
    }
    return e?.fileList[0];
  };

  const uploadButton = (
    <div className={styles.dndArea}>
      {isLoading && <KoLoader size='small'/>}
      <KoIcon name='AttachmentOutlined'/>
      <div>Add image, video, or file</div>
    </div>
    
  );

  return (
    <div className={styles.schedule}>
      <div className={styles.recurring}>
        <KoForm layout="vertical" onFinish={handleSubmit} form={form} onSubmit={handleSubmit} >
          <div className={styles.logoWrapper}>
            <KoForm.Item
              name={"invoiceFile"}
              valuePropName={"invoiceFile"}
              getValueFromEvent={normFile}
            >
              <Upload {...props}
                className={styles.upload}
                beforeUpload={beforeUpload}
                max={{ count: 1 }}
                showUploadList={false}
                showPreviewIcon={false}
                listType="picture-card"
              >
                {uploadButton}
              </Upload>
            </KoForm.Item>
          </div>
          <KoFormBuilder form={form}  meta={meta} />
        </KoForm>
      </div>
    </div>
  );
}
