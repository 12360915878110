import React from "react";
function Share(props) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill="#FFF"
    >
      <path
        d="M23.665 8.253l-9-8A.998.998 0 0013 1v3.207C9.996 5.013 0 8.765 0 23a1 1 0 001.928.371c2.965-7.413 8.745-8.96 11.071-9.283V17a1 1 0 001.666.747l9-8a1 1 0 000-1.494z"
      />
    </svg>


  );
}


export default Share;