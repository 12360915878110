const  workspaceItems = [
  {
    "name": "Dartmouth Daily Standup",
    "logo": null,
    "key": "UT",
    "managers": [],
    "assignees": [
      "usr-cda499047b",
      "usr-0ef6bcedcd"
    ],
    "groups": [],
    "leads": [],
    "workspaceId": "wos-2bf731d47d",
    "customer": "cus-eeca3a713f",
    "description": "Dartmouth Daily Standup Dartmouth Daily StandupDartmouth Daily Standup",
    "createdBy": "usr-cda499047b",
    "createdAt": "2024-07-11T06:24:13.979Z",
    "updatedBy": "usr-cda499047b",
    "updatedAt": "2024-07-29T13:29:32.438Z"
  },
  {
    "name": "Dartmouth Daily Standup",
    "logo": null,
    "key": "GCP",
    "managers": [],
    "assignees": [
      "usr-0ef6bcedcd",
      "usr-cda499047b",
      "usr-92ffb9aac2"
    ],
    "groups": [],
    "leads": [],
    "workspaceId": "wos-468f89aa9c",
    "customer": "cus-51ceee13af",
    "description": "Dartmouth Daily Standup Dartmouth Daily StandupDartmouth Daily Standup",
    "createdBy": "usr-cda499047b",
    "createdAt": "2024-07-12T09:35:38.553Z",
    "updatedBy": "usr-cda499047b",
    "updatedAt": "2024-08-08T18:34:59.572Z"
  },
  {
    "name": "Dartmouth Daily Standup",
    "logo": null,
    "key": "QN",
    "managers": [
      "usr-cda499047b"
    ],
    "assignees": [
      "usr-0ef6bcedcd",
      "usr-8612f12a6d",
      "usr-390a642cef",
      "usr-cda499047b",
      "usr-92ffb9aac2",
      "usr-f7cb9dea97"
    ],
    "groups": [
      "grp-7977bfb250",
      "grp-101dd960bb",
      "grp-1b7f92ed3c"
    ],
    "leads": [
      "usr-cda499047b"
    ],
    "workspaceId": "wos-925b2bc4be",
    "customer": "cus-eeca3a713f",
    "description": "Dartmouth Daily Standup Dartmouth Daily StandupDartmouth Daily Standup",
    "createdBy": "usr-cda499047b",
    "createdAt": "2024-07-22T06:27:46.111Z",
    "updatedBy": "usr-92ffb9aac2",
    "updatedAt": "2024-08-16T08:58:23.749Z"
  }
];


const COLUMN = [
  {
    name: "name",
    id: "name",
    label: "Name",
    widget: "text",
    fixed:'left',
    width:100
  },
  {
    name: "email",
    id: "email",
    label: "email",
    widget: "text",
    width:100
  },
  {
    name: "date",
    id: "date",
    label: "Date",
    width:100,
    widget: "date",
    format : "DD-MMM-YYYY"
  },
  {
    name: "slot",
    id: "slot",
    label: "Slot",
    width:100,
    widget: "eventSlot"
  },
  {
    name: "client",
    id: "client",
    label: "TimeZone",
    width:100,
    widget: "text"
  },
  {
    name: "status",
    id: "status",
    width:80,
    label: "Status",
    widget: "invoicestatus",
    fixed:'right'
  }
];


export {
  COLUMN,workspaceItems
};