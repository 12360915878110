import React from "react";
function Requested(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icons/requested" clipPath="url(#clip0_110_65)">
        <path id="Vector" d="M8 0C6.41775 0 4.87104 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87897 15.1541 3.84547 13.6543 2.34568C12.1545 0.845886 10.121 0.00229405 8 0ZM8.66667 7.91933C8.66674 8.03234 8.63808 8.14351 8.58339 8.2424C8.5287 8.3413 8.44976 8.42466 8.354 8.48467L5.794 10.0847C5.71959 10.1312 5.63674 10.1625 5.5502 10.177C5.46366 10.1915 5.37512 10.1888 5.28963 10.169C5.20413 10.1493 5.12337 10.1129 5.05194 10.0619C4.98052 10.0109 4.91983 9.94642 4.87334 9.872C4.82685 9.79759 4.79547 9.71474 4.781 9.6282C4.76653 9.54166 4.76925 9.45311 4.78899 9.36762C4.80874 9.28213 4.84514 9.20137 4.8961 9.12994C4.94706 9.05851 5.01159 8.99782 5.086 8.95133L7.33334 7.55V4.66667C7.33334 4.48986 7.40358 4.32029 7.5286 4.19526C7.65362 4.07024 7.82319 4 8 4C8.17682 4 8.34638 4.07024 8.47141 4.19526C8.59643 4.32029 8.66667 4.48986 8.66667 4.66667V7.91933Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_110_65">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}


export default Requested;