import React from "react";
function TailOut(props) {
  return (
    <svg viewBox="0 0 8 13"  x="0px" y="0px" height={13} width={8}>
      <path
        fill="var(--active-light)"
        opacity={0.13}
        d="M5.188 1H0v11.193l6.467-8.625C7.526 2.156 6.958 1 5.188 1z"
      />
      <path
        fill="var(--active-light)"
        d="M5.188 0H0v11.193l6.467-8.625C7.526 1.156 6.958 0 5.188 0z"
      />
    </svg>
  );
}
export default TailOut;