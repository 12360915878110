import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {getCookie} from "shared/utils/cookies";
import { adminConsoleAPI } from "pages/admin/admin.service";

const initialState = {
  data: {},
  state: ""
};
export const settingsSlice = createSlice({
  name: "accountById",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSettings.fulfilled, (state, action) => {
        state.data = action.payload;
        state.state = "success";
      })
      .addCase(getSettings.pending, (state, action) => {
        state.state = "pending";
      });
  }
});

export const getSettings = createAsyncThunk('settings/get', async(query) => {
  let accId = getCookie('accountId');
  let data = await adminConsoleAPI.getByIdSettings(accId);
  return data['account'];
});


export default settingsSlice.reducer;