import _find from 'lodash/find';
import KoIcon from "packages/icon";
import { WORKFLOWSTATUSLIST } from "../common";
import styles from './status.module.scss';
import KoPopOver from '../../../popover';
import { KoText } from 'packages/typography';

function WorkFlowStatus(props) {
  let { data, cob, row, isLog } = props;
  let issueType = _find(WORKFLOWSTATUSLIST, function(o) { return o.value === data; }) || {};
  
  return (
    cob ? <div className={`${styles.wrapper}`}>
      <div className={`${styles.status}`} style={{background: issueType?.background, color: issueType?.color}}>
        <div className={styles.text}>{issueType?.label}</div>
      </div>
    </div>:<div className={`${styles.wrapper}`}>
      {issueType?.label &&<KoPopOver title={'Work Log'} content={
        <div className={styles.worklog}>
          <KoText text={`Time spent: ${row?.timeSpent ? row?.timeSpent : '0h'}`}/>
          <KoText text={`Time remaining: ${row?.timeRemaining ? row?.timeRemaining : '0h'}`}/>
        </div>
      }>
        {isLog && <div className={styles.text}>
          <KoIcon width={14} height={14} name={'LogOutlined'}/>
        </div>}
      </KoPopOver>}
      <div className={`${styles.status}`} style={{background: issueType?.background, color: issueType?.color}}>
        <div className={styles.text}>{issueType?.label}</div>
      </div>
    </div>
  );
}
export default WorkFlowStatus;
