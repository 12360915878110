import React, { useCallback, useEffect } from 'react';
import styles from './crud.module.scss';
import { KoForm, KoFormBuilder, KoSelect, KoText } from 'packages';
import { TASKSTATUSLIST } from 'shared/commonConstants';

const PrStatusCrud =({ form, handleFinish, initialValues }) => {

  useEffect(() => {
    if (!initialValues || Object.keys(initialValues).length === 0) {
      form.resetFields();
    } else {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  const handleSubmit = useCallback(values => {
    handleFinish(values);
  });



  const Status = ({ value, onChange }) => (
    <KoSelect
      value={value}
      showSearch
      defaultValue={TASKSTATUSLIST[0].value}
      onChange={v => onChange(v)}
      placeholder='Select status'
      options={TASKSTATUSLIST}
      optionRender={(option) => {
        const label = option?.data?.label;
        const formattedLabel = typeof label === 'string' ? label.replace(" ", '') : '';
        return (
          <div className={`${styles.statusWrapper}`}>
            <div className={`${styles.status} ${styles[formattedLabel]}`} role="img" aria-label={label}/>
            <KoText text={label} />
          </div>
        );
      }}
    />
  );

  const meta = {
    columns: 4,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
    
      {
        key: 'status', label: 'Status', colSpan: 3,
        widget: Status, rules: [
          {
            required: true,
            message: `Please select status.`
          }
        ]
      }
    ]

  };

  return (
    <div className={styles.wrapper}>
      <KoForm form={form} layout="vertical"
        onFinish={handleSubmit} onSubmit={handleSubmit}
      >
        <KoFormBuilder form={form} meta={meta} />
      </KoForm>
    </div>
  );
};

export default PrStatusCrud;
