import { UploadAPI } from "./upload.service";

const KoGetFiles = async(serviceName, moduleName, id, filename) => {
  let files = [];
  let obj = {
    path: `${serviceName}/${moduleName}/${id}/`,
    files: [filename]
  };

  try {
    const data = await UploadAPI.getUpload(obj);
    files = data['urls'];
  } catch (err) {
    console.error('Error fetching upload:', err);
  }

  return files;
};

const KoSetUpload =(data)=>{
  UploadAPI.setUpload(data).then(
    (data) => {
    },
    (err) => {
    }
  );
  return '';
};


export {
  KoGetFiles,
  KoSetUpload
};