
import React from "react";
function StopHand(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128 128" width={width} height={height}>
      <path d="M99.1 61.2c-3.5-2.9-8.8-2.5-11.8 1l-9 10.8V32.1c0-4-2.6-7.5-6.3-8.4-1.9-.5-3.9-.3-5.6.6v-1.7c0-4-2.7-7.5-6.3-8.4-2.6-.6-5.2-.1-7.2 1.5-1.3 1-2.3 2.4-2.8 4-1.8-1-3.9-1.3-6-.8-3.6.9-6.3 4.4-6.3 8.4v6.5c-1.6-.8-3.5-1-5.3-.6-3.6.8-6.6 4.5-6.6 8.4v40.6c0 16.3 12.3 30.2 28 31.6 1 .1 2 .1 3 .1 7.6 0 14.9-2.7 20.6-7.9 4.6-4.1 7.9-9.1 11.1-14 .7-1.1 1.4-2.1 2.1-3.2 3.4-5.1 7-10.5 10-16.4.9-1.9 1.4-3.5 1.4-5 0-2.3-1.1-4.6-3-6.2zm-2.6 9.2c-2.8 5.6-6.3 10.9-9.7 15.9-.7 1.1-1.4 2.2-2.1 3.2-3.2 4.8-6.2 9.4-10.3 13.1-5.5 4.9-12.6 7.2-20 6.5-13.3-1.2-23.7-13-23.7-26.9V41.7c0-1.6 1.4-3.5 2.8-3.8 1.2-.3 2.5.1 3.3 1 .7.7 1 1.6 1 2.5v20.2c0 1.3 1.1 2.4 2.4 2.4 1.3 0 2.4-1.1 2.4-2.4V27.4c0-1.8 1.1-3.4 2.6-3.7 1.1-.3 2.3-.1 3.1.6.9.7 1.4 1.7 1.4 2.8v32.1c0 1.3 1.1 2.4 2.4 2.4 1.3 0 2.4-1.1 2.4-2.4V22.3c0-1.1.5-2.1 1.4-2.8.9-.7 2-.9 3.1-.6 1.5.4 2.7 2 2.7 3.8v36.6c0 1.3 1.1 2.4 2.4 2.4s2.4-1.1 2.4-2.4V31.9c0-1.1.5-2.1 1.4-2.8.9-.7 2-.9 3.1-.7 1.5.4 2.7 2 2.7 3.8v47.4c0 1 .6 1.9 1.6 2.2.9.3 2 .1 2.6-.7L91 65.3c1.3-1.5 3.5-1.7 5-.5.8.7 1.3 1.7 1.3 2.8 0 .7-.3 1.6-.8 2.8z" fill="#000000" className="color000 svgShape">
      </path>
    </svg>
  );
}


export default StopHand;