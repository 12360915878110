import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {getCookie} from "shared/utils/cookies";
import { workspaceAPI } from "pages/workspace/workspace.service";

const initialState = {
  data: {},
  state: ""
};
export const workspaceByIdSlice = createSlice({
  name: "workspaceById",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWorkspaceById.fulfilled, (state, action) => {
        state.data = action.payload;
        state.state = "success";
      })
      .addCase(getWorkspaceById.pending, (state, action) => {
        state.state = "pending";
      });
  }
});

export const getWorkspaceById = createAsyncThunk('workspace/get', async(workspaceId) => {
  let wos = getCookie('wos');
  let data = await workspaceAPI.getWorkspaceById(workspaceId || wos);
  return data['project'];
});


export default workspaceByIdSlice.reducer;