import { KoButton, KoDataTableEdit, KoForm, KoIcon, KoNano } from 'packages';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './curd.module.scss';

function SprintLeavePlan({ form, handleFinish, initialValues }) {
  const wosAssignees = useSelector(state => state.wosAssignees);

  const defaultRow = () => ({
    key: KoNano('SLP'),
    employee: null,
    noOfDays: null,
    fromDate: null,
    endDate: null
  });

  const [dataSource, setDataSource] = useState([defaultRow()]);

  useEffect(() => {
    if (initialValues?.leavePlan?.length > 0) {
      setDataSource(initialValues.leavePlan);
    } else {
      setDataSource([defaultRow()]);
    }
  }, [initialValues]);

  useEffect(() => {
    form.setFieldsValue({
      leavePlan: dataSource
    });
  }, [dataSource, form]);

  const handleDelete = (key) => {
    let newData = dataSource.filter((item) => item.key !== key);
    if (newData.length === 0) {
      newData = [defaultRow()];
    }
    setDataSource(newData);
    HandleSave(newData);
  };


  const columns = [
    {
      dataIndex: 'employee',
      title: 'Employee',
      width: 200,
      editable: true,
      fixed: 'left',
      widget: 'UserSelect',
      options: wosAssignees[0],
      placeholder: 'Select user'
    },
    {
      dataIndex: 'fromDate',
      title: 'From Date',
      editable: true,
      widget: 'date',
      daysAgo: 0,
      placeholder: 'Select From Date',
      width: 170
    },
    {
      dataIndex: 'endDate',
      title: 'To Date',
      editable: true,
      widget: 'date',
      daysAgo: 0,
      placeholder: 'Select To Date',
      width: 170
    },
    {
      dataIndex: 'noOfDays',
      title: 'No Of Days',
      editable: true,
      widget: 'amount',
      readonly: true,
      width: 100
    },
    {
      title: ` `,
      dataIndex: 'operation',
      width: 40,
      fixed: 'right',
      render: (_, record, i) =>
        dataSource.length >= 1 ? (
          <div className={'editTableCol'}>
            <KoButton
              icon={<KoIcon width={12} height={12} name={'DeleteOutlined'} />}
              className={styles.actionBtn}
              onClick={() => handleDelete(record?.key)}
              label=""
            />
            {dataSource?.length === i + 1 && (
              <KoButton
                icon={<KoIcon color={`var(--palette-gray-7)`} width={17} height={17} name={'PlusOutlined'} />}
                className={styles.actionBtn}
                onClick={handleAdd}
                label={``}
              />
            )}
          </div>
        ) : null
    }
  ];

  const handleAdd = () => {
    const newData = defaultRow();
    setDataSource([...dataSource, newData]);
  };

  const HandleSave = (newData) => {
    let data = form.getFieldsValue();
    newData = newData.map((item) => {
      if (item.endDate && item.fromDate) {
        const leaveDays = Math.ceil((item.endDate - item.fromDate) / (1000 * 60 * 60 * 24));
        item['noOfDays'] = leaveDays;
      }
      return item;
    });
    data['leavePlan'] = newData;
    form.setFieldsValue({ data });
    setDataSource(newData);
  };

  const onRow = (record) => {
  };

  return (
    <KoForm form={form} layout="vertical" onFinish={handleFinish} onSubmit={handleFinish}>
      <KoForm.Item name={'leavePlan'}>
        <KoDataTableEdit
          dataSource={dataSource}
          form={form}
          onCellClick={onRow}
          onSave={HandleSave}
          defaultColumns={columns}
        />
      </KoForm.Item>
    </KoForm>
  );
}

export default SprintLeavePlan;
