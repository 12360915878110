import React, { useState, useEffect } from 'react';
import mammoth from 'mammoth';
import KoLoader from 'packages/loader';

const DocxViewer = ({ filePath }) => {
  const [htmlContent, setHtmlContent] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAndConvertDocx = async() => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(filePath, { mode: 'no-cors' });
        if (!response.ok) {
          throw new Error(`Failed to fetch file: ${response.statusText}`);
        }
        const arrayBuffer = await response.arrayBuffer();
        const result = await mammoth.convertToHtml({ arrayBuffer });
        setHtmlContent(result.value);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAndConvertDocx();
  }, [filePath]);

  if (loading) {
    return <div>
      <KoLoader/>
    </div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div
      dangerouslySetInnerHTML={{ __html: htmlContent }}
      style={{ padding: '20px', border: '1px solid #ddd' }}
    />
  );
};

export default DocxViewer;
