import {KoAPI} from "packages";
import { configs } from 'configuration';
const version = configs().API_VERSION;
export const deploymentAPI = {

  // getEnvironments(id) {
  //   return KoAPI.get(`/api/${version}/deployment/environment`);
  // },

  // createOrUpdateEnvironment(data, id){
  //   if(id){
  //     return KoAPI.update(`/api/${version}/deployment/environment//${id}`, {  data: { ...data }  });
  //   }else{
  //     return KoAPI.post(`/api/${version}/deployment/environment`, { data });
  //   }
  // },
  // deleteEnvironment(id){
  //   return KoAPI.delete(`/api/${version}/deployment/environment/${id}`);
  // },

  
  getServices(id) {
    return KoAPI.get(`/api/${version}/deployment/service`);
    // return KoAPI.get(`/api/${version}/customer`);
  },

  createOrUpdateServices(data, id){
    if(id){
      return KoAPI.update(`/api/${version}/deployment/service/${id}`, { data });
    }else{
      return KoAPI.post(`/api/${version}/deployment/service`, { data });
    }
  },
  deleteService(id){
    return KoAPI.delete(`/api/${version}/deployment/service/${id}`);
  },
   


  getEnvironments(id) {
    return KoAPI.get(`/api/${version}/apiclient/environment`);
  },

  createOrUpdateEnvironment(data, id) {
    if(id){
      return KoAPI.update(`/api/${version}/apiclient/environment/${id}`, { data: { ...data } });
    }else{
      return KoAPI.post(`/api/${version}/apiclient/environment`, { data: { ...data } });
    }
  },
  deleteEnvironment(id){
    return KoAPI.delete(`/api/${version}/apiclient/environment/${id}`);
  }
   
};