import { KoAffix, KoButton, KoCard, KoDateFormatter, KoDropdown, KoEmpty, KoFlex, KoForm, KoIcon, KoLoader, KoModal, KoNano, KoPagination, KoTable, KoText, KoTitle, KoToast } from "packages";
import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { useParams } from "react-router";
import { add, remove } from 'shared/store/breadcrumbSlice';
import KoToolBar from "shared/components/toolbar";
import { PR_ITEMS_COLUMNS } from '../constant';
import { TaskPlannerAPI } from "../taskPlanner.service";
import ReleaseCrud from "./crud";
import PullRequestCrud from "./prCrud";
import PrStatusCrud from "./prStatusCrud";
import styles from './release.module.scss';
import _find from 'lodash/find';
import { useSelector } from "react-redux";
const ReleasePlan = (props) => {
  const {workspaceId} = useParams();
  const dispatch = useDispatch();
  const [release, setRelease] = useState();
  const [pullRequest, setPullRequest] = useState();
  const [recentPullRequest, setRecentPullRequest] = useState();
  const [isOpen, setModal] = useState(false);
  const [isPullRequestOpen, setPullRequestModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isOpenPRDelete, setDeletePRModal] = useState(false);
  const [isReleasePlanOpen, setReleasePlanModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState();
  const [currentPRRecord, setCurrentPRRecord] = useState({
    status : 1
  });
  const [isOpenPRStatus, setPRStatusModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(100);
  const [prTotalCount, setPRTotalCount] = useState(100);

  const [form] = KoForm.useForm();
  const { title, profile } = props;
  const [pagination, setPagination] = useState({
    size: 10,
    page: 1
  });
  const [prPagination, setPrPagination] = useState({
    size: 10,
    page: 1
  });

  const workspace = useSelector(state => state?.workspace);
  let workspaceList = workspace['data'];
  const currentWorkspace = _find(workspaceList, function(o) { return o.workspaceId === workspaceId; });

  const openInNewTab = (url) => {
    window.open(url, "_blank");
  };
  
  const onRow=(record)=>{
    setCurrentPRRecord(record);
    openInNewTab(record?.pullRequest);
  };

  useEffect(()=>{
    dispatch(remove());
    dispatch(add([
      {
        title: 'workspace',
        path: 'workspace'
      },
      {
        title: currentWorkspace?.name
      },
      {
        title: 'Release Plans'
      }
    ]));

  }, [currentWorkspace]);
  
  useEffect(()=>{
    getRelease();
    getRecentPullRequest();
  },[]);

  useEffect(()=>{
    if(isPullRequestOpen){
      getPullRequest();
    }
  },[isPullRequestOpen]);

  const getPullRequest=()=>{
    setLoading(true);

    TaskPlannerAPI.getPullRequest(pagination, currentRecord?.releaseId, workspaceId).then(
      (data) => {
        setPullRequest();
        setPRTotalCount();
        setLoading(false);
        setPullRequest(data?.pullRequest);
        setPRTotalCount(data?.totalCount);
      },
      (err) => {
        setLoading(false);
      }
    );

  };

  const getRecentPullRequest=()=>{
    setLoading(true);
    TaskPlannerAPI.getRecentPullRequest(pagination,workspaceId).then(
      (data) => {
        setRecentPullRequest();
        setTotalCount();
        setLoading(false);
        setRecentPullRequest(data?.pullRequests);
        setTotalCount(data?.totalCount);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const onSearchText=(text)=>{
    let q = pagination;
    q['search'] = text;
    getRecentPullRequest(q);
  };

  const  onRecentPRSort= (key, type) => {
    let q = pagination;
    q['sort'] = `${key}:${type}`;
    getRecentPullRequest(q);
  };

  const onSort= (key, type) => {
    let q = pagination;
    q['sort'] = `${key}:${type}`;
    getPullRequest(q);
  };

  const getRelease=()=>{
    setLoading(true);
    TaskPlannerAPI.getRelease(pagination, workspaceId).then(
      (data) => {
        setRelease();
        setTotalCount();
        setLoading(false);
        setRelease(data?.releases);
        setTotalCount(data?.totalCount);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const handleFinish = (values => {
    setLoading(true);
    if(!currentRecord?.releaseId){
      values['releaseId'] = KoNano('REL');
    }
    TaskPlannerAPI.createOrUpdateRelease(values, currentRecord?.releaseId,workspaceId ).then(
      (data) => {
        getRelease();
        setLoading(false);
        setReleasePlanModal(false);
        KoToast.success(`Release ${currentRecord?.releaseId ? 'updated': 'created'} successfully`);
      },
      (err) => {
        setLoading(false);
      }
    );
  });

  const handlePRFinish = (values => {
    setLoading(true);
    if(!currentPRRecord?.prId){
      values['prId'] = KoNano('PLR');
    }

    TaskPlannerAPI.createOrUpdatePullRequest(values , currentPRRecord?.prId, currentRecord?.releaseId, workspaceId).then(
      (data) => {
        getPullRequest();
        setLoading(false);
        setModal(false);
        KoToast.success(`Pull Request ${currentPRRecord?.prId ? 'updated' : 'created' } successfully`);
      },
      (err) => {
        KoToast.error(err?.error);
        setLoading(false);
      }
    );
  });

  const UpdatePRStatus = (newStatus) => {
    const updatedValues = {
      ...currentPRRecord,
      status: newStatus['status']
    };

    setLoading(true);
    TaskPlannerAPI.createOrUpdatePullRequest(updatedValues, currentPRRecord.prId, currentRecord?.releaseId, workspaceId)
      .then(
        (data) => {
          getPullRequest();
          setLoading(false);
          setModal(false);
          setPRStatusModal(false);
          getRelease();
          getRecentPullRequest();
          KoToast.success('Pull Request status updated successfully');
        },
        (err) => {
          KoToast.error(err?.error);
          setLoading(false);
        }
      );
  };



  const DeleteRelease = () => {
    TaskPlannerAPI.DeleteRelease(currentRecord?.releaseId, workspaceId).then(
      (data) => {
        setLoading(false);
        setDeleteModal(false);
        getRelease();
        KoToast.success("Release Deleted Successfully");
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const DeletePullRequest = () => {
    TaskPlannerAPI.deletePullRequest(currentRecord?.releaseId ,currentPRRecord?.prId, workspaceId).then(
      (data) => {
        setLoading(false);
        setDeletePRModal(false);
        getPullRequest();
        KoToast.success("PR Deleted Successfully");
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const onPagination = (page, other) => {
    const query = {
      size: other,
      page: page
    };
    const p = Object.assign(pagination, query);
    setPagination(p);
    getRelease(p);
    getRecentPullRequest(p);
    
  };
  const onPrPagination = (page, other) => {
    const query = {
      size: other,
      page: page
    };
    const p = Object.assign(prPagination, query);
    setPrPagination(p);
    getPullRequest(p);
  };
  
  const items = [
    {
      key: '0',
      label: (<KoText text='Update PR Status' />),
      icon: <KoIcon name="EditOutlined" height={12} width={13} />
    },
    {
      key: '1',
      label: (<KoText text='Update Pull Request' />),
      icon: <KoIcon name="EditOutlined" height={12} width={13} />
    },
    {
      type: 'divider'
    },
    {
      key: '2',
      danger: true,
      label: 'Delete Pull Request',
      icon: <KoIcon name="DeleteOutlined" height={12} width={13} />
    }
  ];
 
  const columnsRight = [{
    title: '',
    dataIndex: 'x',
    key: 'x',
    fixed: 'right',
    width: 40,
    render: (text, row) => {
      return <div className={`${styles.circle} columnsRight`} >
        <div shape="circle">
          <KoDropdown
            menu={{
              items,
              onClick:(e)=>onHandelSprint(e, row)
            }}
            overlayClassName={styles.actionDropdown}
            placement="bottomLeft"
          >
            <div shape="circle" >
              <KoIcon name="VerticalMenuOutlined" onClick={ ()=>(row)} />
            </div>
          </KoDropdown>
        </div>
      </div>;
    }
  }];




  const onClickRelease = ({key}, workspace) => {
    setCurrentRecord(workspace);
    if(key === "1"){
      setReleasePlanModal(true);
    } else if(key === '2'){
      setDeleteModal(true);
    }
  };
  const action = [
    {
      label: "Update",
      key: "1",
      icon: <KoIcon name="EditOutlined" />
    },
    {
      type: 'divider'
    },
    {
      label: "Delete",
      key: "2",
      icon: <KoIcon name="DeleteOutlined" height={14} width={14} />,
      danger: true
    }
  ];

  const onHandelSprint = (e, row) => {
    setCurrentPRRecord(row);
    
    setCurrentRecord(row);
    if (e.key === '0') {
      setPRStatusModal(true);
    }
    else if (e.key === "1") {
      setModal(true);
    }
    else if (e.key === "2") {
      setDeletePRModal(true);
    }
  };



  return (
    <div className={styles.wrapper}>
      {isLoading && <KoLoader />}
      <KoAffix top={56}>
        <KoToolBar
          title={`${title}`}
          search={{ visible: true, placeholder: 'Search by any parameter' }}
          filter={{
            visible: false,
            options: [{ label: 'Sort By Name', key: '0' },
              { label: 'Created At', key: '2' },
              { label: 'Sort By designation', key: '3' }]
          }}
          buttonList={[{
            label: 'Create',
            type: 'primary',
            key: 'create',
            icon: <KoIcon color="#FFF" name="PlusOutlined" width={18} height={18} />, onClick: () => setReleasePlanModal(!isReleasePlanOpen)
          }]} />
      </KoAffix>
      {release?.length > 0 ?<KoFlex wrap="wrap" gap="small" className={styles.listSection}>
        {release?.map((rel, i) =>
          <KoCard
            className={styles.card}
            key={rel?.workspaceId}
            hoverable={true}>
            <div className={styles.actions}>
              <KoDropdown
                placement="bottomRight"
                overlayClassName={'overlayClassName'}
                menu={{
                  items:action,
                  onClick:(e)=>onClickRelease(e, rel)
                }}
              >
                <div onClick={(e) => e.preventDefault()}>
                  <KoIcon name="MenuOutlined"/>
                </div>
              </KoDropdown>
            </div>
            <div onClick={() => { setCurrentRecord(rel); setPullRequestModal(true); }}>
              <div className={styles.titleKey}>
                <div className={styles.titleValue}>
                  <div className={styles.verified}>
                    <KoTitle className={`${styles.title}`} text={rel?.name} level={5} />

                    {rel?.endDate &&
                    Math.ceil((new Date(rel?.endDate) - new Date()) / (1000 * 60 * 60 * 24)) < 1 ?
                      <KoIcon name="CrossFilled" /> :
                      <KoIcon name={`${rel?.status === 1 ? 'DotFilled' : 'TickFilled'}`} color={rel?.status === 2 ? 'var(--inprogress)' : ''} />
                    }
                  </div>
                </div>
               
              </div>
              {/* <KoText className={`${styles.key} ${styles[rel?.key? rel?.key?.charAt(0).toLowerCase() :'a']}`}
              text={`${rel?.status}`} /> */}
              <div className={styles.description} >
                <KoText text={`${rel?.releaseNotes ? rel?.releaseNotes :'No Description'}`}/>
              </div>
              <div className={styles.link}>
                <div className={styles.row}>
                  <KoText className={styles.date}  text='From'/>
                  <KoDateFormatter format='DD-MMM-YYYY' data={rel?.startDate} className={styles.date}/>
                </div>
                <div className={styles.row}>
                  <KoText className={styles.date}  text='To'/>
                  <KoDateFormatter format='DD-MMM-YYYY' data={rel?.endDate} className={styles.date}/>
                </div>
              </div>
            </div>
          </KoCard>
        )}
      </KoFlex>:
        <KoFlex wrap="wrap" gap="small" className={styles.emptySection}>
          <KoEmpty onClick={()=>setReleasePlanModal(true)} label="Create Release" icon={'EmptyTwoTone'} title="Create a new Release"/>
        </KoFlex>
      }
      {release?.length > 0 &&
        <div className='tableCard'>
          <KoToolBar
            className={styles.toolbar}
            title={'Recent Pull Request'}
            search={{ visible: true, onChange: onSearchText, placeholder: 'Search by any parameter' }}
            filter={{
              visible: false,
              options: [{ label: 'Sort By Name', key: '0' },
                { label: 'Created At', key: '2' },
                { label: 'Sort By designation', key: '3' }]
            }}
            buttonList={[]} />
          <div className={styles.reacentTable}>

            <KoTable
              rowKey='prId'
              onSort={onRecentPRSort}
              onCellClick={onRow}
              data={{ columns: PR_ITEMS_COLUMNS, rows: recentPullRequest}}
              extraColumnsRight={columnsRight} maxHeight={50} />


            <KoPagination current={parseInt(pagination.page) || 1}
              total={totalCount}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} records`
              }
              pageSize={pagination.size}
              defaultCurrent={pagination.page}
              onChange={onPagination}
              showQuickJumper={false}
              simple={false}
              responsive={false} />
          </div>
        </div>
      }

      


      <KoModal
        title={`${currentRecord?.name} Pull Request ` }
        centered
        open={isPullRequestOpen}
        afterClose={() => {setCurrentPRRecord();}}
        handleOk={() => { setCurrentPRRecord(); setPullRequestModal(false); }}
        onClose={() => { setCurrentPRRecord(); setPullRequestModal(false);}}
        width={`80vw`}
        // maskClosable={false}
        height={650}
        footer={[
          // <KoButton key='cancel' label='Close' onClick={() => setPullRequestModal(!isPullRequestOpen)} />
        ]}
      >
        <KoToolBar
          className={styles.toolbar}
          title={false}
          search={{ visible: true, onChange: () => { }, placeholder: 'Search by any parameter' }}
          filter={{
            visible: false,
            options: [{ label: 'Sort By Name', key: '0' },
              { label: 'Created At', key: '2' },
              { label: 'Sort By date', key: '3' }]
          }}
          buttonList={[{
            label: 'Create',
            type: 'primary',
            icon: <KoIcon color="#FFF" name="PlusOutlined" width={18} height={18} />, onClick: () => {setModal(true); form.resetFields();
            }
          }]} />

        <KoTable
          rowKey='prId'
          onCellClick={onRow}
          onSort={onSort}
          data={{ columns: PR_ITEMS_COLUMNS, rows: pullRequest }} extraColumnsRight={columnsRight} maxHeight={100} />

        <KoPagination current={parseInt(prPagination.page) || 1}
          total={prTotalCount}
          showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} records`
          }
          pageSize={prPagination.size}
          defaultCurrent={prPagination.page}
          onChange={onPrPagination}
          showQuickJumper={false}
          simple={false}
          responsive={false} />

      </KoModal>

      <KoModal
        title={`${currentRecord?.releaseId ? 'Update' : 'Create'} Release Plan`}
        centered
        open={isReleasePlanOpen}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setReleasePlanModal(false); form.resetFields();}}
        onClose={() => { setCurrentRecord(); setReleasePlanModal(false); form.resetFields();}}
        width={780}
        // maskClosable={false}
        height={650}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => {setReleasePlanModal(!isReleasePlanOpen); form.resetFields();}} />,
          <KoButton key='create' loading={isLoading} type='primary' label={currentRecord?.releaseId ? `Update` : `Create`}
            onClick={() => form.submit()} />
        ]}
      >
        <ReleaseCrud initialValues={currentRecord} form={form} handleFinish={handleFinish} profile={profile}/>
      </KoModal>

      <KoModal
        title={`Delete ${currentRecord?.name?.toUpperCase()}`}
        centered
        open={deleteModal}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setDeleteModal(false); form.resetFields();}}
        onClose={() => { setCurrentRecord(); setDeleteModal(false); form.resetFields();}}
        width={580}
        // maskClosable={false}
        height={450}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => {setDeleteModal(!deleteModal); form.resetFields();}} />,
          <KoButton key='delete' loading={isLoading} type='primary' label='Delete' onClick={() => DeleteRelease()} />
        ]}
      >
        <div className={styles.delWrapper}>
          <KoTitle level={5} text='This action will delete item and cannot be undone' />
          <KoText text={`Do you wish to proceed?`} />
        </div>
      </KoModal>

      <KoModal
        title={`${currentPRRecord?.prId ? 'Update' : 'Create'} Pull Request`}
        centered
        open={isOpen}
        afterClose={() => {setCurrentPRRecord();}}
        handleOk={() => { setCurrentPRRecord(); setModal(false); form.resetFields();}}
        onClose={() => { setCurrentPRRecord(); setModal(false); form.resetFields();}}
        width={780}
        // maskClosable={false}
        height={650}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setModal(!isOpen)} />,
          <KoButton key='create' loading={isLoading} type='primary' label={currentPRRecord?.prId ? `Update` : `Create`}
            onClick={() => form.submit()} />
        ]}
      >
        <PullRequestCrud initialValues={currentPRRecord} form={form} handleFinish={handlePRFinish} profile={profile}/>
      </KoModal>

      <KoModal
        title={`Delete ${currentPRRecord?.name?.toUpperCase()}`}
        centered
        open={isOpenPRDelete}
        afterClose={() => {setCurrentPRRecord();}}
        handleOk={() => { setCurrentPRRecord(); setDeletePRModal(false); form.resetFields();}}
        onClose={() => { setCurrentPRRecord(); setDeletePRModal(false); form.resetFields();}}
        width={580}
        // maskClosable={false}
        height={450}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => {setDeletePRModal(!isOpenPRDelete); form.resetFields();}} />,
          <KoButton key='delete' loading={isLoading} type='primary' label='Delete' onClick={() => DeletePullRequest()} />
        ]}
      >
        <div className={styles.delWrapper}>
          <KoTitle level={5} text='This action will delete item and cannot be undone' />
          <KoText text={`Do you wish to proceed?`} />
        </div>
      </KoModal>

      <KoModal
        title={`Status Update ${currentPRRecord?.name?.toUpperCase()}`}
        centered
        open={isOpenPRStatus}
        afterClose={() => {setCurrentPRRecord();}}
        handleOk={() => { setCurrentPRRecord(); setPRStatusModal(false); form.resetFields();}}
        onClose={() => { setCurrentPRRecord(); setPRStatusModal(false); form.resetFields();}}
        width={480}
        // maskClosable={false}
        height={350}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => {setPRStatusModal(!isOpenPRStatus); form.resetFields();}} />,
          <KoButton key='update' loading={isLoading} type='primary' label='Update' onClick={() => form.submit()} />
        ]}
      >
        <PrStatusCrud initialValues={currentPRRecord} form={form} handleFinish={UpdatePRStatus}/>
      </KoModal>
    </div>
  );
};

export default ReleasePlan;
