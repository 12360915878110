import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {setCookie} from "shared/utils/cookies";
import { workspaceAPI } from "pages/workspace/workspace.service";
const initialState = {
  data: [],
  state: ""
};
export const workspaceSlice = createSlice({
  name: "workspace",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(getWorkspace.fulfilled, (state, action) => {
        state.data = action.payload;
        state.state = "success";
      })
      .addCase(getWorkspace.pending, (state, action) => {
        state.state = "pending";
      });
  }
});

export const getWorkspace = createAsyncThunk('workspace/get', async(query) => {
  let data = await workspaceAPI.getWorkspace();
  if(data?.totalCount > 0){
    setCookie('wos', data['workspaces'][0]?.workspaceId);
  }
  return data['workspaces'];

});


export default workspaceSlice.reducer;