import React, {useEffect, useState} from 'react';
import { KoFormBuilder, KoSelect, KoForm, KoNumber } from 'packages';
import { OrgAPI } from '../org.service';
import styles from './designations.module.scss';

function DesignationCrud({ form, initialValues, handleFinish }) {

  const [departments, setDepartment] = useState();
  // const [isLoading, setLoading] = useState(false);
  useEffect(()=>{
    getOrgDepartments();
  },[]);
  
  useEffect(() => {
    if (!initialValues || Object.keys(initialValues).length === 0) {
      form.resetFields();
    } else {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  const getOrgDepartments = () => {
    // setLoading(true);
    OrgAPI.getOrgDepartment().then(
      (data) => {
        setDepartment(data['departments']);
        // setLoading(false);
      },
      (err) => {
        // setLoading(false);
      }
    );
  };

  const Departments = ({ value, onChange }) =>

    <KoSelect value={value}
      placeholder={"Select department"}
      onChange={v => onChange(v)}
      showSearch
      options={departments?.map((dept) => ({
        label: dept?.name,
        value: dept?.departmentId
      }))} />;

  const SeniorityLevel = ({ value, onChange }) =>

    <KoSelect value={value}
      onChange={v => onChange(v)}
      showSearch
      placeholder={"Select experience level"}
      options={[{
        value:0,
        label:'0 Year '
      },{
        value:1,
        label:'1 Year '
      },{
        value:2,
        label:'2 Years'
      },{
        value:3,
        label:'3 Years'
      },{
        value:4,
        label:'4 Years'
      },{
        value:5,
        label:'5 Years'
      },{
        value:6,
        label:'6 Years'
      },{
        value:7,
        label:'7 Years'
      },{
        value:8,
        label:'8 Years'
      },{
        value:9,
        label:'9 Years'
      },{
        value:10,
        label:'10 Years'
      },{
        value:11,
        label:'11 Years'
      },{
        value:12,
        label:'12 Years'
      },{
        value:13,
        label:'13 Years'
      },{
        value:14,
        label:'14 Years'
      },{
        value:15,
        label:'15 Years'
      },{
        value:16,
        label:'16 Years'
      },{
        value:17,
        label:'17 Years'
      },{
        value:18,
        label:'18 Years'
      },{
        value:19,
        label:'19 Years'
      },{
        value:20,
        label:'20 Years'
      },{
        value:21,
        label:'20+ Years'
      }]} />;



  const meta = {
    columns: 4,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      {
        key: 'name', label: 'Designation Name', colSpan: 2, placeholder:"Enter designation", rules: [
          {
            required: true,
            message: 'designation name is required'
          }
        ]
      },
      {
        key: 'department', label: 'Department', colSpan: 2, widget: Departments
      },
      {
        key: 'experienceLevel', label: 'Experience Level', colSpan: 2, widget: SeniorityLevel
      },
      {
        key: 'label1',
        colSpan: 4,
        render() {
          return (
            <fieldset>
              <legend>SALARY RANGE YEAR</legend>
            </fieldset>
          );
        }
      },
      {
        key: 'salaryRange.from',  widget: KoNumber, label: 'From', placeholder:"Enter salary from Ex: 100000", colSpan: 2
      },
      {
        key: 'salaryRange.to',  widget: KoNumber, label: 'To', placeholder:"Enter salary to Ex: 2000000", colSpan: 2
      }
    ]
  };

  return (
    <div className={styles.wrapper}>
      <KoForm form={form} layout="vertical"
        onFinish={handleFinish} onSubmit={handleFinish}
      >
        <KoFormBuilder form={form} meta={meta} />
      </KoForm>
    </div>
  );
};

export default DesignationCrud;