import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { add, remove } from 'shared/store/breadcrumbSlice';
import styles from './home.module.scss';
import {  Outlet } from 'react-router-dom';
const Home = () => {
  const dispatch = useDispatch();
  // const {userById} = useSelector(state => state);
  // let userDetails = userById['data'];
  useEffect(()=>{
    dispatch(remove());
    dispatch(add([]));
  },[]);

 
  return (
    <div className={styles.wrapper}>
      <Outlet/>
    </div>
  );
};
export default Home;



