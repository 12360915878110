const COLUMNS_USERS = [
  {
    id: "name",
    label: "Name",
    widget: "text",
    fixed: 'left'
  },
  {
    id: "accountWoner",
    label: "Account Owner",
    widget: "bool",
    width:100
  },
  {
    id: "superAdmin",
    label: "Super Admin",
    widget: "bool",
    width:100
  },
  {
    id: "iamAdmin",
    label: "IAM Admin",
    widget: "bool",
    width:100
  },
  {
    id: "workspaceAdmin",
    label: "Workspace Admin",
    widget: "bool",
    width:150
  },{
    id: "userAdmin",
    label: "User Admin",
    widget: "bool",
    width:100
  },{
    id: "billingAdmin",
    label: "Billing Admin",
    widget: "bool",
    width:100
  }];

const ROWS = [{
  name:'View/Update Account Details',
  accountWoner:true,
  iamAdmin:true,
  superAdmin:true,
  roleId: "1212",
  workspaceAdmin:false,
  userAdmin:false,
  billingAdmin:false
},{
  name:'View Account Owner',
  accountWoner:true,
  iamAdmin:false,
  superAdmin:false,
  roleId: "1212",
  workspaceAdmin:false,
  userAdmin:false,
  billingAdmin:false
},{
  name:'View/Update Format Settings',
  accountWoner:true,
  iamAdmin:true,
  superAdmin:true,
  roleId: "1212",
  workspaceAdmin:false,
  userAdmin:false,
  billingAdmin:false
},{
  name:'View/Update Billing Details',
  accountWoner:true,
  iamAdmin:false,
  superAdmin:false,
  roleId: "1212",
  workspaceAdmin:false,
  userAdmin:false,
  billingAdmin:true
}];
  
export {
  COLUMNS_USERS , ROWS
};