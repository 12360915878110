

const grid = {
  left: '0%',
  right: '0%',
  bottom: '0%',
  top:'10%',
  containLabel: true
};


function timeStringToHours(timeStr) {
  const [hours, minutes] = timeStr.split(':').map(Number);
  return hours + minutes / 60;
}


const dataForDates = [
  { date: "2024-09-02", billableHours: "5:00", nonBillableHours: "17:00" },
  { date: "2024-09-03", billableHours: "6:30", nonBillableHours: "15:00" },
  { date: "2024-09-04", billableHours: "7:15", nonBillableHours: "14:45" },
  { date: "2024-09-05", billableHours: "4:45", nonBillableHours: "18:30" }
];


const BillableData = dataForDates?.map(entry => timeStringToHours(entry.billableHours));
const nonBillableData = dataForDates?.map(entry => timeStringToHours(entry.nonBillableHours));

const series = [
  {
    name: 'Billable Time',
    type: 'bar',
    stack: 'total',
    emphasis: {
      disabled: true
    },
    data: BillableData
  },
  {
    name: 'Non billable Time',
    type: 'bar',
    stack: 'total',
    emphasis: {
      disabled: true
    },
    data: nonBillableData
  }
];

var stackInfo = {};
for (let i = 0; i < series[0].data?.length; ++i) {
  for (let j = 0; j < series.length; ++j) {
    const stackName = series[j].stack;
    if (!stackName) {
      continue;
    }
    if (!stackInfo[stackName]) {
      stackInfo[stackName] = {
        stackStart: [],
        stackEnd: []
      };
    }
    const info = stackInfo[stackName];
    const data = series[j].data[i];
    if (data && data !== '-') {
      if (info.stackStart[i] == null) {
        info.stackStart[i] = j;
      }
      info.stackEnd[i] = j;
    }
  }
}

for (let i = 0; i < series.length; ++i) {
  const data = series[i].data;
  const info = stackInfo[series[i].stack];
  for (let j = 0; j < series[i].data?.length; ++j) {
    const isEnd        = info.stackEnd[j] === i;
    const topBorder    = isEnd ? 8 : 0;
    const bottomBorder = 0;
    data[j] = {
      value: data[j],
      itemStyle: {
        borderRadius: [topBorder, topBorder, bottomBorder, bottomBorder]
      }
    };
  }
}

const BILLABLE_HOURS={
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'none'
    }
  },
  color: [
    "rgba(0, 120, 103, 0.8)",
    "rgba(255, 171, 0, 0.8)"
  ],
  legend: {
    show: false
  },
  barWidth:20,
  grid,
  xAxis: {
    type: 'category',
    data: ['Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat', '2', '23'],
    axisLabel:{
      fontSize:9
    }
  },
  yAxis: {
    type: 'value'
  },
  series
};


const WORKING_HOURS = {
  color:['#6a25eb','#9ebdf9'],
  series: [
    {
      type: 'pie',
      radius: '75%',
      labelLine:{
        show:false
      },
      emphasis: {
        disabled: true
      },
      data: [
        { value: 16 },
        { value: 3 }
      ]
    }
  ]
};
const TOTAL_WORKING_HOURS = {
  color:['#6a25eb','#9ebdf9'],
  series: [
    {
      type: 'pie',
      radius: '100%',
      labelLine:{
        show:false
      },
      emphasis: {
        disabled: true
      },
      data: [
        { value: 16 },
        { value: 3 }
      ]
    }
  ]
};

const PROJECT_DESCRIPTION = [
  {name:"Project assets to implement in loam sector - Nigeria",time:"16h 23m"},
  {name:"Brand Strategy and internal Daily progress - Ad campaigns",time:"8h 50m"},
  {name:"External project meeting - missingComponents and assets",time:"2hr 34m"},
  {name:"UI components and guidelines Mind map - 3rd sector",time:"16h 30m"}
];

const REPORT_HEADER = [
  {
    id: "monday",
    label: "Mon 15",
    widget: "text",
    width:'10rem'
  },
  {
    id: "tuesday",
    label: "Tue 16",
    widget: "text",
    width:'10rem'
  },{
    id: "wednesday",
    label: "Wed 17",
    widget: "text",
    width:'10rem'
  },{
    id: "thursday",
    label: "Thu 18",
    width:'10rem'
  },{
    id: "friday",
    label: "Fri 19",
    widget: "text",
    width:'10rem'
  },{
    id: "saturday",
    label: "Sat 20",
    widget: "text",
    width:'10rem'
  },{
    id: "sunday",
    label: "Sun 21",
    widget: "text",
    width:'10rem'
  }
];


const events = [
  {
    eventId: '12q11',
    startTime: "2024-07-11T18:30:00Z",
    endTime: new Date('2024-07-10 12:35:00'),
    title: 'Daily Milk',
    author: 'Jane Cooper',
    platform:'slack',
    assignees: ['usr-820d8cd7ba', `usr-b5d13986c7`, `usr-51820a2de9`, `usr-9b68aed57d`]
  },
  {
    eventId: '12',
    startTime: new Date('2024-07-11 08:00:00'),
    endTime: new Date('2024-07-11 13:50:00'),
    title: 'Daily Meetsfsdfsdfsdf',
    author: 'Jane Cooper',
    platform:'gmeet',
    assignees: ['usr-820d8cd7ba', `usr-b5d13986c7`, `usr-51820a2de9`, `usr-9b68aed57d`]
  },
  {
    eventId: '1w2',
    startTime: new Date('2024-07-08 12:00:00'),
    endTime: new Date('2024-07-08 13:00:00'),
    title: 'skype meet',
    platform:'skype',
    author: 'Jane Cooper',
    assignees: ['usr-820d8cd7ba', 'usr-b2e07bc4d3','usr-137120b5cf', `usr-b5d13986c7`, `usr-51820a2de9`, `usr-9b68aed57d`]
  },
  {
    eventId: '1w2',
    startTime: new Date('2024-07-08 15:00:00'),
    endTime: new Date('2024-07-08 16:20:00'),
    title: 'goToMeeting meet',
    platform:'goToMeeting',
    author: 'Jane Cooper',
    assignees: ['usr-820d8cd7ba', 'usr-b2e07bc4d3','usr-137120b5cf', `usr-b5d13986c7`, `usr-51820a2de9`, `usr-9b68aed57d`]
  },
  {
    eventId: '1w23',
    startTime: new Date('2024-07-08 10:00:00'),
    endTime: new Date('2024-07-08 11:00:00'),
    title: 'Daily Meet',
    author: 'Ralph Edward',
    platform:'zoom',
    assignees: ['usr-820d8cd7ba', 'usr-b2e07bc4d3','usr-137120b5cf', `usr-b5d13986c7`, `usr-51820a2de9`, `usr-9b68aed57d`]
  },
  {
    eventId: '12232222',
    startTime: new Date('2024-07-09 08:00:00'),
    endTime: new Date('2024-07-09 08:30:00'),
    title: 'Daily Meetsfsdfsdfsdf',
    platform:'gmeet',
    author: 'Jane Cooper',
    assignees: ['usr-820d8cd7ba','usr-137120b5cf', `usr-b5d13986c7`, `usr-51820a2de9`, `usr-9b68aed57d`]
  },
  {
    eventId: '13ed2',
    startTime: new Date('2024-07-09 08:00:00'),
    endTime: new Date('2024-07-09 09:50:00'),
    title: 'Daily Meetsfsdfsdfsdf',
    author: 'Jane Cooper',
    platform:'ciscoWebex',
    assignees: ['usr-820d8cd7ba', 'usr-137120b5cf', `usr-b5d13986c7`, `usr-51820a2de9`, `usr-9b68aed57d`]
  },
  {
    eventId: '1d21111',
    startTime: new Date('2024-07-09 12:00:00'),
    endTime: new Date('2024-07-09 13:00:00'),
    title: 'Daily Meet',
    platform:'gmeet',
    author: 'Jane Cooper',
    assignees: ['usr-820d8cd7ba', 'usr-820d8cd7ba', `usr-b5d13986c7`, `usr-51820a2de9`, `usr-9b68aed57d`]
  },
  {
    eventId: '1223',
    startTime: new Date('2024-07-09 10:00:00'),
    endTime: new Date('2024-07-09 11:00:00'),
    title: 'Daily Meet',
    author: 'Ralph Edward',
    platform:'teams',
    assignees: ['usr-820d8cd7ba', 'usr-820d8cd7ba','usr-137120b5cf', `usr-b5d13986c7` ,`usr-51820a2de9`, `usr-9b68aed57d`]
  }
];


const TIMESHEETCOLUMNS = [
  {
    name: "name",
    id: "name",
    label: "Workspace",
    width:250,
    widget: "workspace",
    fixed: true
  },
  {
    name: "customer",
    id: "customer",
    label: "Customer",
    width:150,
    widget: "text"
  },
  {
    name: "totalBillableWorkHours",
    id: "totalBillableWorkHours",
    label: "Total Billable WorkHours",
    widget: "text",
    width:250
  },
  {
    name: "totalNonBillableWorkHours",
    id: "totalNonBillableWorkHours",
    label: "Total Non Billable WorkHours",
    widget: "text",
    width:250
  },
  {
    name: "approver",
    id: "approver",
    label: "Approver",
    width:150,
    widget: "text"
  },
  {
    name: "approvelRequestedDate",
    id: "approvelRequestedDate",
    label: "Requested Date",
    width:150,
    widget: "date"
  },
  {
    name: "approvedDate",
    id: "approvedDate",
    label: "approved Date",
    width:150,
    widget: "date"
  },
  {
    name: "status",
    id: "status",
    label: "Approval Status",
    widget: "workflowstatus",
    width:200,
    fixed:'right'
  }

];

const KoTicketTable_COLUMNS = [
  {
    name: "ticketId",
    id: "ticketId",
    label: "Ticket Id",
    width:130,
    widget: "ticketLink",
    fixed: true
  },
  {
    name: "summary",
    id: "summary",
    label: "Summary",
    width:230,
    widget: "text"
  },
  {
    name: "totalBillableWorkHours",
    id: "totalBillableWorkHours",
    label: "Total Billable WorkHours",
    widget: "text"
  },
  {
    name: "totalNonBillableWorkHours",
    id: "totalNonBillableWorkHours",
    label: "Total Non-Billable WorkHours",
    widget: "text",
    width:250
  },
  {
    name: "status",
    id: "status",
    label: "Approval Status",
    widget: "workflowstatus",
    width:200
  }
];

const KoTicketTable_COLUMNSNONTicket = [
  {
    name: "date",
    id: "date",
    label: "Log Date",
    widget: "date",
    width:130
  },
  {
    name: "summary",
    id: "summary",
    label: "Summary",
    width:230,
    widget: "text"
  },
  {
    name: "totalHours",
    id: "totalHours",
    label: "Total Billable WorkHours",
    widget: "billable"
  },
  {
    name: "totalHours",
    id: "totalHours",
    label: "Total Non-Billable WorkHours",
    widget: "nonbillable",
    width:250
  },
  {
    name: "status",
    id: "status",
    label: "Approval Status",
    widget: "workflowstatus",
    width:200
  }
];

const TESTDATA = {
  "overAllHoursPerWeek": {
    "billable": "115:50",
    "nonBillable": "00:00",
    "description": null
  },
  "workLog": [
    {
      "workspace": "wos-925b2bc4be",
      "workTime": {
        "2024-11-19": {
          "billable": "00:00",
          "nonBillable": "00:00",
          "description": null
          
        },
        "2024-11-22": {
          "billable": "00:00",
          "nonBillable": "00:00",
          "description": null
        },
        "2024-11-26": {
          "billable": "72:30",
          "nonBillable": "00:00",
          "description": [
            {
              "children": [
                {
                  "bold": true,
                  "text": "welcome "
                },
                {
                  "strikethrough": true,
                  "text": "devlopers"
                }
              ],
              "type": "paragraph"
            }
          ],
          "testChildData" : [
            {
              "ticketId": "QN-21",
              "issueType": 2,
              "isBillable": true,
              "timeSpent": "40m"
            },
            {
              "ticketId": "QN-41",
              "issueType": 4,
              "isBillable": true,
              "timeSpent": "5h"
            }
          ]
        },
        "2024-11-27": {
          "billable": "00:20",
          "nonBillable": "00:00",
          "description": [
            {
              "children": [
                {
                  "text": ""
                }
              ],
              "type": "paragraph"
            }
          ],
          "testChildData" : [
            {
              "ticketId": "QN-221",
              "issueType": 1,
              "isBillable": true,
              "timeSpent": "10m"
            },
            {
              "ticketId": "QN-41",
              "issueType": 6,
              "isBillable": true,
              "timeSpent": "5h"
            }
           
          ]
        },
        "2024-11-28": {
          "billable": "03:00",
          "nonBillable": "00:00",
          "description": null
        },
        "2024-11-29": {
          "billable": "40:00",
          "nonBillable": "00:00",
          "description": [
            {
              "children": [
                {
                  "text": ""
                }
              ],
              "type": "paragraph"
            }
          ],
          "testChildData" : [
            {
              "ticketId": "QN-221",
              "issueType": 5,
              "isBillable": false,
              "timeSpent": "2h"
            }
          ]
        }
      },
      "totalHoursPerDay": {
        "2024-11-19": {
          "billable": "00:00",
          "nonBillable": "00:00",
          "description": null
        },
        "2024-11-22": {
          "billable": "00:00",
          "nonBillable": "00:00",
          "description": null
        },
        "2024-11-26": {
          "billable": "72:30",
          "nonBillable": "00:00",
          "description": null
        },
        "2024-11-27": {
          "billable": "00:20",
          "nonBillable": "00:00",
          "description": null
        },
        "2024-11-28": {
          "billable": "03:00",
          "nonBillable": "00:00",
          "description": null
        },
        "2024-11-29": {
          "billable": "40:00",
          "nonBillable": "00:00",
          "description": null
        }
      },
      "totalHoursPerWeek": {
        "billable": "115:50",
        "nonBillable": "00:00",
        "description": null
      }
    }
  ]
};


export {
  BILLABLE_HOURS, events, PROJECT_DESCRIPTION, REPORT_HEADER, TOTAL_WORKING_HOURS,
  WORKING_HOURS, TESTDATA,
  TIMESHEETCOLUMNS,
  KoTicketTable_COLUMNSNONTicket,
  KoTicketTable_COLUMNS
};

