import {KoAPI} from "packages";
import { configs } from 'configuration';
const version = configs().API_VERSION;

export const adminConsoleAPI = {
  ChangeOwnerByID(id){
    return KoAPI.update(`/api/${version}/account/owner/${id}`);
  },
  uploadFile(data){
    return KoAPI.post(`/api/${version}/upload`, { data });
  },
  updateSettings(data, id) {
    return KoAPI.update(`/api/${version}/admin/settings/${id}`, { data });
  },
  getByIdSettings(id){
    return KoAPI.get(`/api/${version}/admin/settings/${id}`);
  },
  getUsers(){
    return KoAPI.get(`/api/${version}/org/user`);
  },


  getHolidays(query) {
    return KoAPI.get(`/api/${version}/admin/holidaycalendar`, { query: { ...query } });
  },
  createOrUpdateHoliday(data, id) {
    if(id) {
      return KoAPI.update(`/api/${version}/admin/holidaycalendar/${id}`, { data: { ...data } });
    } else {
      return KoAPI.post(`/api/${version}/admin/holidaycalendar`, { data: { ...data } });
    }
  },
  deleteHoliday(id){
    return KoAPI.delete(`/api/${version}/admin/holidaycalendar/${id}`);
  },


  getAvailability() {
    return KoAPI.get(`/api/${version}/admin/availability`);
  },
  updateAvailability(data, id) {
    if(id) {
      return KoAPI.update(`/api/${version}/admin/availability/${id}`, { data: { ...data } });
    }
  }
};