import React from "react";
import { Tabs, theme } from "antd";
function KoTabs(props) {
  const {
    token: { colorBgContainer }
  } = theme.useToken();
  const {
    options,
    onChange,
    activeKey,
    box,
    tabPosition
  } = props;

  return (
    <Tabs
      tabPosition={tabPosition}
      onChange={onChange && onChange}
      tabBarStyle={{background: colorBgContainer, borderRadius:4, boxShadow: `${!box ? 'none' : 'var(--card-3)'}`}}
      activeKey={activeKey} items={options} />
  );
}
export default KoTabs;