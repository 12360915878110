import React from "react";
function Doc(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 64 64">
      <defs>
        <clipPath id="aacc11">
          <rect id="Rectangle_17322" data-name="Rectangle 17322"fill="red" width="64" height="64" transform="translate(266 374)" />
        </clipPath>
      </defs>
      <g id="word_doc" fill="red" transform="translate(-266 -374)">
        <g id="document" transform="translate(216.278 379.027)">
          <path id="Path_28731" data-name="Path 28731" fill="red" d="M89.585,15.939a3.723,3.723,0,0,1-3.719-3.719V0H66.844A5.851,5.851,0,0,0,61,5.844V48.562a5.851,5.851,0,0,0,5.844,5.844H96.6a5.851,5.851,0,0,0,5.844-5.844V15.939ZM69.714,38.255h7.727a1.594,1.594,0,1,1,0,3.188H69.714a1.594,1.594,0,1,1,0-3.188ZM68.12,31.347a1.594,1.594,0,0,1,1.594-1.594H93.091a1.594,1.594,0,1,1,0,3.188H69.714A1.594,1.594,0,0,1,68.12,31.347ZM93.091,21.253a1.594,1.594,0,1,1,0,3.188H69.714a1.594,1.594,0,1,1,0-3.188Z" />
          <path id="Path_28732" data-name="Path 28732" fill="red" d="M325,17.78a.532.532,0,0,0,.531.531h12.147a5.83,5.83,0,0,0-1.117-1.459L326.315,7.159A5.857,5.857,0,0,0,325,6.221V17.78Z" transform="translate(-235.947 -5.56)" />
        </g>
      </g>
    </svg>
  );
}


export default Doc;