import React from "react";
import { Radio, Space } from 'antd';
function KoRadio(props) {

  const { direction = 'vertical', onChange, value, buttonStyle, options } = props;
  return (
    <Radio.Group onChange={onChange} value={value} buttonStyle={buttonStyle}>
      <Space direction={direction}>
        {options.map((items) => (
          <Radio key={items?.value ? items?.value : items?.label}
            value={items?.value ? items?.value : items?.label}>{items.label}</Radio>
        ))}
      </Space>
    </Radio.Group>
  );
}
export {KoRadio, Radio};
