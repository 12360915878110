import React from "react";
function Shared(props) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
    >
      <path d="M5 19c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5zm0-9c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm16 14c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zm0-5c-1.103 0-2 .897-2 2s.897 2 2 2 2-.897 2-2-.897-2-2-2zM20 8c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4zm0-7c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3-1.346-3-3-3z" />
      <path d="M8.74 12a.5.5 0 01-.277-.916l8.35-5.56a.499.499 0 11.554.832l-8.35 5.56A.492.492 0 018.74 12zm9.97 8.5a.485.485 0 01-.2-.042l-9.59-4.2c-.253-.111-.368-.406-.258-.659s.41-.367.658-.257l9.59 4.2a.501.501 0 01-.2.958z" />
    </svg>
  );
}


export default Shared;