import React from 'react';
import { useDrop } from 'react-dnd';
import { FormItemTypes } from './formItemTypes';
import styles from '../template.module.scss';

const DroppableArea = ({ onDrop, children }) => {
  const [{ isOver }, drop] = useDrop({
    accept: [FormItemTypes[0]?.type,
      FormItemTypes[1]?.type,
      FormItemTypes[2]?.type,
      FormItemTypes[3]?.type,
      FormItemTypes[4]?.type,
      FormItemTypes[5]?.type,
      FormItemTypes[6]?.type,
      FormItemTypes[7]?.type,
      FormItemTypes[8]?.type,
      FormItemTypes[9]?.type,
      FormItemTypes[10]?.type,
      FormItemTypes[11]?.type,
      FormItemTypes[12]?.type,
      FormItemTypes[13]?.type,
      FormItemTypes[14]?.type,
      FormItemTypes[15]?.type,
      FormItemTypes[16]?.type],
    drop: (item, monitor) => {
      onDrop(item);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  });

  return (
    <div className={styles.droppableArea} ref={drop} style={{ backgroundColor: isOver ? 'var(--palette-gray-5)' : 'var(--palette-background-neutral)'}}>
      {children}
    </div>
  );
};

export default DroppableArea;
