import React from "react";
function DaysRemining(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 12 12" fill="none">
      <g clipPath="url(#clip0_204_502)">
        <path d="M0 4V2.5C0 1.673 0.673 1 1.5 1H3V0H4V1H8V0H9V1H10.5C11.327 1 12 1.673 12 2.5V4H0ZM12 5V12H0V5H12ZM8 8H4V9H8V8Z" fill="var(--palette-primary-darker)" />
      </g>
      <defs>
        <clipPath id="clip0_204_502">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}


export default DaysRemining;