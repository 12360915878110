import React from "react";
function Dashboard(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1024 1024"
      className="icon"
      {...props}
    >
      <path
        d="M896 32H640a96 96 0 00-96 96v256a96 96 0 0096 96h256a96 96 0 0096-96V128a96 96 0 00-96-96zm32 352a32 32 0 01-32 32H640a32 32 0 01-32-32V128a32 32 0 0132-32h256a32 32 0 0132 32zM64 224a32 32 0 0012.16-2.56 37.12 37.12 0 0010.56-6.72 32 32 0 006.72-34.88 32 32 0 00-6.72-10.56A32 32 0 0057.6 160a20.8 20.8 0 00-5.76 1.92 23.68 23.68 0 00-5.76 2.88l-4.8 3.84a32 32 0 00-6.72 10.56A32 32 0 0032 192a32 32 0 0032 32zm85.76-96a32 32 0 0017.6-5.44 160 160 0 01115.2-24.32 32 32 0 1010.56-64 225.92 225.92 0 00-160 34.24A32 32 0 00149.76 128zm228.16-24a32 32 0 00-2.56 45.12A160 160 0 01416 256v128a32 32 0 01-32 32H256a160 160 0 01-150.72-106.56 32 32 0 00-60.16 21.44A224 224 0 00256 480h128a96 96 0 0096-96V256a224 224 0 00-56.96-149.44 32 32 0 00-45.12-2.56zM896 544H640a96 96 0 00-96 96v256a96 96 0 0096 96h256a96 96 0 0096-96V640a96 96 0 00-96-96zm32 352a32 32 0 01-32 32H640a32 32 0 01-32-32V640a32 32 0 0132-32h256a32 32 0 0132 32zM384 544H128a96 96 0 00-96 96v256a96 96 0 0096 96h256a96 96 0 0096-96V640a96 96 0 00-96-96zm32 352a32 32 0 01-32 32H128a32 32 0 01-32-32V640a32 32 0 0132-32h256a32 32 0 0132 32z"
        fill={`var(--palette-gray-8)`}
      />
    </svg>
  );
}


export default Dashboard;