import React from "react";
import styles from './role.module.scss';

function UserRole({data}) {
  
  const getRoleName = (id) => {
    switch (id) {
    case 7: return "User";
    case 6: return "Billing Admin";
    case 5: return "User Admin";
    case 4: return "Workspace Admin";
    case 3: return "IAM Admin";
    case 2: return "Super Admin";
    case 1: return "Account Owner";
    default: return "Unknown Role";
    }
  };

  return (
    <div className={styles.wrapper}>
      {data.map((role, index) => (
        <div key={index} className={`${styles.status} ${styles[getRoleName(role).replace(/ /g, '')]}`}>
          <div className={styles.text}>{getRoleName(role)}</div>
        </div>
      ))}
    </div>

  );
}
export default UserRole;
