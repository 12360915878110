import React from "react";
function ActiveSprint(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path d="M9.37436 2.06214C7.68995 2.30276 6.14305 2.96774 4.71217 4.06583C4.2395 4.43332 3.35433 5.34329 2.98479 5.85078C1.17577 8.31384 0.574199 11.3238 1.30038 14.2637C1.68711 15.8343 2.49923 17.3392 3.59496 18.5248C3.97309 18.936 4.0977 19.0148 4.34692 19.0148C4.76803 19.0148 5.06022 18.6473 4.96998 18.2273C4.9528 18.1442 4.81959 17.9561 4.63912 17.7548C3.26839 16.2324 2.53361 14.6137 2.32736 12.6625C2.06954 10.1732 2.9719 7.60511 4.73795 5.80266C6.2204 4.2977 8.12395 3.4271 10.1951 3.31335C10.6935 3.28273 10.8611 3.25648 10.9685 3.19523C11.3896 2.94149 11.4111 2.36838 11.0115 2.09714C10.8783 2.00526 10.8181 1.99651 10.324 2.00089C10.0275 2.00526 9.5978 2.03151 9.37436 2.06214Z" fill="black" />
      <path d="M14.7584 2.3465C13.8217 2.60462 13.6799 2.66587 13.5639 2.84524C13.4264 3.05961 13.4393 3.2171 13.667 4.10083C13.7916 4.56894 13.9205 5.04143 13.9549 5.1508C14.1182 5.66703 14.7069 5.81578 15.042 5.43079C15.1967 5.25142 15.244 5.03268 15.1838 4.78768C15.1581 4.68706 15.1409 4.59956 15.1452 4.59081C15.1495 4.58644 15.3213 4.70456 15.5276 4.85331C16.8897 5.8464 17.8823 7.11949 18.4796 8.64633C18.8534 9.60005 19.0339 10.4663 19.0726 11.5119C19.1972 14.8805 17.3366 18.0217 14.3373 19.496C13.3276 19.9948 12.3092 20.2573 11.1404 20.3272C10.7709 20.3491 8.43762 20.371 5.95398 20.371H1.43789L1.29179 20.4716C0.887877 20.7472 0.913658 21.316 1.34335 21.5741C1.48515 21.6616 1.57969 21.6616 5.85086 21.6747C10.4572 21.6878 11.5486 21.6616 12.3822 21.5085C14.0366 21.2066 15.5534 20.4891 16.8725 19.3867C19.1542 17.4792 20.4777 14.478 20.3617 11.4681C20.2585 8.86945 19.2402 6.53326 17.4226 4.74831C16.98 4.3152 16.3225 3.77709 15.9788 3.57147C15.8928 3.51897 15.8198 3.45772 15.8198 3.44022C15.8198 3.42272 15.8885 3.3921 15.9702 3.37897C16.1721 3.3396 16.4085 3.12086 16.4686 2.91524C16.5975 2.47775 16.301 2.07964 15.8542 2.08839C15.751 2.08839 15.2569 2.20651 14.7584 2.3465Z" fill="black" />
      <path d="M9.69663 6.0039C7.64269 6.38014 5.94539 7.8501 5.23639 9.85379C5.00866 10.5013 4.92701 11.0088 4.92701 11.8181C4.92701 12.6275 5.00866 13.135 5.23639 13.7824C5.94539 15.7905 7.67277 17.2736 9.71382 17.6323C10.2423 17.7242 11.1705 17.7242 11.699 17.6323C13.7444 17.2736 15.4674 15.7949 16.1764 13.7824C16.4042 13.135 16.4858 12.6275 16.4858 11.8181C16.4858 11.2013 16.4686 10.9956 16.387 10.6194C15.8585 8.20884 14.0323 6.41076 11.699 6.0039C11.1963 5.9164 10.1736 5.9164 9.69663 6.0039ZM13.117 10.6894C13.3104 10.8994 13.3619 11.1138 13.2846 11.3719C13.2416 11.525 13.0182 11.7744 11.9568 12.8593C11.2564 13.5768 10.6076 14.2287 10.5131 14.303C10.3627 14.4299 10.3154 14.443 10.0533 14.443H9.76109L8.96615 13.6337C8.11535 12.7718 8.0466 12.6625 8.10246 12.3431C8.14973 12.0981 8.40325 11.84 8.63099 11.8094C8.99193 11.7612 9.08646 11.8094 9.6064 12.3212L10.0877 12.7981L11.1834 11.6825C11.785 11.0656 12.3135 10.545 12.3607 10.5231C12.408 10.5013 12.5627 10.4925 12.7002 10.4969C12.9322 10.51 12.9666 10.5275 13.117 10.6894Z" fill="black" />
      <path d="M20.6882 19.146C20.323 19.3692 20.2757 19.8679 20.5894 20.1741C20.6625 20.2441 20.7183 20.3141 20.7183 20.336C20.7183 20.3535 20.0566 20.371 19.2488 20.371C17.878 20.371 17.7663 20.3754 17.6503 20.4541C17.4612 20.5854 17.3667 20.7604 17.3667 21.001C17.3667 21.2547 17.4698 21.4472 17.6761 21.5741C17.8093 21.6572 17.9167 21.6616 19.2659 21.6835L20.7183 21.7053L20.5679 21.8978C20.3402 22.1909 20.3187 22.5059 20.5121 22.7553C20.6882 22.9872 21.0578 23.0703 21.3113 22.9347C21.3758 22.8997 21.7711 22.5191 22.1879 22.0903C23.2707 20.9747 23.2707 21.001 22.1879 19.9291C21.7926 19.5398 21.4144 19.1854 21.3414 19.1417C21.1695 19.0323 20.8644 19.0323 20.6882 19.146Z" fill="black" />
    </svg>

  );
}


export default ActiveSprint;