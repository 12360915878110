import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { createEditor } from 'slate';
import { Emoji } from 'emoji-picker-react';
import { Slate, Editable, withReact } from 'slate-react';

const Element = ({ attributes, children, element, from }) => {
  switch (element.type) {
  case 'emoji':
    return (
      <span {...attributes} style={{ display: 'inline-block', verticalAlign: 'middle' }}>
        <Emoji
          size={from === 'contacts' ? 14 : 25}
          unified={element?.emoji}
          style={{ display: 'inline-block', verticalAlign: 'middle', marginTop: '-4px' }}
        />
      </span>
    );
  case 'block-quote':
    return <blockquote {...attributes}>{children}</blockquote>;
  case 'bulleted-list':
    return <ul {...attributes}>{children}</ul>;
  case 'list-item':
    return <li {...attributes}>{children}</li>;
  case 'numbered-list':
    return <ol {...attributes}>{children}</ol>;
  default:
    return <p {...attributes}>{children}</p>;
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) children = <strong>{children}</strong>;
  if (leaf.code) children = <code>{children}</code>;
  if (leaf.italic) children = <i>{children}</i>;
  if (leaf.underline) children = <u>{children}</u>;
  if (leaf.strikethrough) children = <s>{children}</s>;
  if (leaf.link) {
    children = (
      <a
        href={leaf.url}
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: 'blue', textDecoration: 'underline' }}
        title={`Preview: ${leaf.url}`}
      >
        {children}
      </a>
    );
  }

  return <span {...attributes}>{children}</span>;
};

const KoEditorReadOnly = ({ initialValue, text, status, from, data, ...rest }) => {

  const renderElement = useCallback((props) => <Element from={from} {...props} />, [from]);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withReact(createEditor()), []);
  const defaultValue = [
    {
      type: 'paragraph',
      children: [{ text: '' }]
    }
  ];

  if(!initialValue){
    initialValue = data;
  }
  
  const [value, setValue] = useState(() => {
    return Array.isArray(initialValue) && initialValue.length > 0 ? initialValue : defaultValue;
  });

  useEffect(() => {
    if (Array.isArray(initialValue) && initialValue.length > 0) {
      setValue(initialValue);
    }
  }, [initialValue]);

  return (
    !status && (
      <Slate key={JSON.stringify(value)} editor={editor} initialValue={value} value={value} onChange={(newValue) => setValue(newValue)}>
        <Editable
          readOnly
          renderLeaf={renderLeaf}
          renderElement={renderElement}
          placeholder={text || ''}
        />
      </Slate>
    )
  );
};

export default KoEditorReadOnly;
