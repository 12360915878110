import {
  KoButton,
  KoDropdown,
  KoForm,
  KoIcon,
  KoLoader,
  KoModal,
  KoNano,
  KoPagination,
  KoTable,
  KoText, KoTitle, KoToast,
  KoTooltips
} from 'packages';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { add, remove } from 'shared/store/breadcrumbSlice';
import KoToolBar from 'shared/components/toolbar';
import styles from '../org.module.scss';
import { OrgAPI } from '../org.service';
import { COLUMNS_GROUPS } from './constant';
import GroupCrud from './crud';
import { getGroups } from 'shared/store/groupsSlice';

function Groups() {
  const dispatch = useDispatch();
  const [totalCount, setTotalCount] = useState(100);
  const [dataList, setDataList] = useState();
  const [isOpen, setModal] = useState(false);
  const [isOpenDelete, setDeleteModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [currentRecord, setCurrentRecord] = useState();
  const [pagination, setPagination] = useState({
    size: 10,
    offset: 0,
    page: 1
  });
  const [form] = KoForm.useForm();

  useEffect(() => {
    dispatch(remove());
    dispatch(add([
      {
        title: 'Home',
        path: 'home/dashboard/myoverview'
      },
      {
        title: 'Groups'
      }
    ]));

    getOrgGroups();
  }, []);

  const getOrgGroups = () => {
    setLoading(true);
    OrgAPI.getOrgGroups(pagination).then(
      (data) => {
        setDataList();
        setTotalCount();
        setLoading(false);
        setDataList(data['groups']);
        setTotalCount(data['totalCount']);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const onSearchText=(text)=>{
    let q = pagination;
    if(text){
      q['search'] = text;
    }
    else{
      delete q?.['search'];
    }
    getOrgGroups(q);
  };
  
  const onSort = (key, type) => {
    let q = pagination;
    q['sort'] = `${key}:${type}`;
    getOrgGroups(q);
  };

  const onPagination = (page, other) => {
    const query = {
      size: other,
      page: page,
      offset: (page - 1) * other
    };
    const pQuery = Object.assign(pagination, query);
    setPagination(pQuery);
    getOrgGroups(pQuery);
  };

  const handleFinish = (values => {
    setLoading(true);
    if(!currentRecord?.groupId){
      values['groupId'] = KoNano('GRP');
    }
    OrgAPI.createOrgGroups(values, currentRecord?.groupId).then(
      (data) => {
        getOrgGroups();
        setLoading(false);
        form.resetFields();
        dispatch(getGroups());
        // KoToast.success('Group created');
        KoToast.success(`${currentRecord?.name}! Group ${currentRecord?.groupId? 'updated': 'created'} successfully! `) ;
        setModal(false);
        // KoToast.success(`Profile created successfully! ${values.firstname}!`);
      },
      (err) => {
        setLoading(false);
        // KoToast.error(`Login Successful! Welcome back!`);
      }
    );
  });

  const handleFinishDelete = () => {
    setLoading(true);
    OrgAPI.groupdDelete(currentRecord?.groupId).then(
      (data) => {
        setLoading(false);
        getOrgGroups();
        KoToast.success('deleted successfully');
        setDeleteModal(false);
        // setResetPassModal(false);
        // KoToast.success(`Profile created successfully! ${values.firstname}!`);
      },
      (err) => {
        setLoading(false);
        // KoToast.error(`Login Successful! Welcome back!`);
      }
    );
  };

  const handleRowActions = ({ key }) => {
    if (key === '2') {
      setDeleteModal(true);
    }

  };

  const items = [
    // {
    //   key: '1',
    //   label: (<KoText text='Update Group' />),
    //   icon: <KoIcon name='AddtoGroupsOutlined' />
    // },
    // {
    //   type: 'divider'
    // },
    {
      key: '2',
      danger: true,
      label: 'Delete',
      icon: <KoIcon name='DeleteOutlined' />
    }
  ];

  const columnsRight = [{
    title: '',
    dataIndex: 'x',
    key: 'x',
    fixed: 'right',
    width: 40,
    render: (text, row) => {
      return <div className={`${styles.circle} tableBtn columnsRight`} onClick={() => setCurrentRecord(row)}>
        <KoTooltips title={'Edit'}>
          <KoButton  className={'btn'} iconBtn={true} icon={<KoIcon width={14} height={14} name={'EditOutlined'}/>} onClick={() => setModal(true)}/>
        </KoTooltips>
        <KoDropdown
          menu={{
            items,
            onClick: handleRowActions
          }}
          overlayClassName={styles.actionDropdown}
          placement="bottomLeft"
        >
          <div shape="circle" >
            <KoIcon name="VerticalMenuOutlined" onClick={() => (row)} />
          </div>
        </KoDropdown>

      </div>;
    }
  }];


  return (<div className='tableCard'>
    { isLoading && <KoLoader />}
    <KoToolBar
      title={'Groups '}
      search={{ visible: true, onChange: (e) => { onSearchText(e);}, placeholder: 'Search by any parameter' }}
      filter={{
        visible: false,
        options: [{ label: 'Sort By Name', key: '1' },
          { label: 'Created At', key: '2' },
          { label: 'Sort By Status', key: '3' }]
      }}
      buttonList={[{
        label: 'Create Group',
        type: 'primary',
        icon: <KoIcon color="#FFF" name="PlusOutlined" width={18} height={18} />,
        onClick: () => { setCurrentRecord(); setModal(!isOpen); }
      }]} />
    <KoTable rowKey='groupId' data={{ columns: COLUMNS_GROUPS, rows: dataList }} onSort={onSort} extraColumnsRight={columnsRight} maxHeight={100} />
    <KoPagination current={parseInt(pagination.page) || 1}
      total={totalCount}
      showTotal={(total, range) =>
        `${range[0]}-${range[1]} of ${total} records`
      }
      pageSize={pagination.limit}
      defaultCurrent={pagination.page}
      onChange={onPagination}
      showQuickJumper={false}
      simple={false}
      responsive={false} />
    <KoModal
      title={currentRecord?.groupId ? `Update Group ${currentRecord?.name?.toUpperCase()}` : 'Create Group'}
      centered
      open={isOpen}
      afterClose={() => {setCurrentRecord();}}
      handleOk={() => { setCurrentRecord(); setModal(false); }}
      onClose={() => { setCurrentRecord(); setModal(false); }}
      width={680}
      // maskClosable={false}
      height={650}
      footer={[
        <KoButton key='cancel' label='Cancel' onClick={() => setModal(!isOpen)} />,
        <KoButton key='create' loading={isLoading} type='primary' label={`${currentRecord?.groupId ? 'Update' : 'Create'}`} onClick={() => form.submit()} />
      ]}
    >
      {isLoading && <KoLoader circle={false} />}
      <GroupCrud getOrgGroups={getGroups} initialValues={currentRecord} form={form} handleFinish={handleFinish} />
    </KoModal>

    <KoModal
      title={`Delete group ${currentRecord?.name?.toUpperCase()}`}
      centered
      open={isOpenDelete}
      afterClose={() => {setCurrentRecord();}}
      handleOk={() => { setCurrentRecord(); setDeleteModal(false); }}
      onClose={() => { setCurrentRecord(); setDeleteModal(false); }}
      width={580}
      // maskClosable={false}
      height={450}
      footer={[
        <KoButton key='cancel' label='Cancel' onClick={() => setDeleteModal(!isOpenDelete)} />,
        <KoButton key='reset' loading={isLoading} type='primary' label='Delete' onClick={() => handleFinishDelete()} />
      ]}
    >
      {isLoading && <KoLoader circle={false} />}
      <div className={styles.suspend}>
        <KoTitle level={5} text={`Are you sure you want to delete this group?`} />
        <KoText text={`This action is irreversible, and it will result in the removal of their associated data.`} />
      </div>
    </KoModal>

  </div>);
}

export default Groups;