import React from "react";
function Slack(props) {
  const { width, height } = props;
  return (
  
   

    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width} height={height}
      {...props}
    >
      <path
        fill="#e91e63"
        d="M8.843 12.651a2.521 2.521 0 00-2.521 2.521v6.306a2.521 2.521 0 005.042 0v-6.306a2.523 2.523 0 00-2.521-2.521zM.019 15.172a2.524 2.524 0 005.046 0v-2.523H2.542a2.524 2.524 0 00-2.523 2.523z"
        className="colore91e63 svgShape"
      />
      <path
        fill="#00bcd4"
        d="M8.846-.001h-.003a2.524 2.524 0 000 5.046h2.521V2.522v-.005A2.519 2.519 0 008.846-.001zM2.525 11.37h6.318a2.524 2.524 0 000-5.046H2.525a2.524 2.524 0 000 5.046z"
        className="color00bcd4 svgShape"
      />
      <path
        fill="#4caf50"
        d="M21.457 6.323a2.518 2.518 0 00-2.518 2.518v2.528h2.521a2.524 2.524 0 000-5.046h-.003zm-8.816-3.801v6.325a2.521 2.521 0 005.042 0V2.522a2.521 2.521 0 00-5.042 0z"
        className="color4caf50 svgShape"
      />
      <path
        fill="#ff9800"
        d="M17.682 21.476a2.521 2.521 0 00-2.521-2.521H12.64v2.523a2.521 2.521 0 005.042-.002zm3.797-8.827h-6.318a2.524 2.524 0 000 5.046h6.318a2.524 2.524 0 000-5.046z"
        className="colorff9800 svgShape"
      />
    </svg>
  );
}


export default Slack;