import React from "react";
function Table(props) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 128 128"
      width={width}
      height={height}
    >
      <rect
        width={108}
        height={92}
        x={10}
        y={18}
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={4}
        rx={8}
        className="colorStroke000 svgStroke"
      />
      <path
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={4}
        d="M82 18v92M46 18v92M10 90h108M10 70h108M10 50h108M20 32h16m20 0h16m20 0h16"
        className="colorStroke000 svgStroke"
      />
    </svg>
  );
}


export default Table;