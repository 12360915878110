const KoTicketTable_COLUMNS = [
  {
    name: "ticketId",
    id: "ticketId",
    label: "Ticket Id",
    width:130,
    widget: "ticketLink",
    fixed: true
  },

  {
    name: "summary",
    id: "summary",
    label: "Summary",
    width:230,
    widget: "text"
  },
  {
    name: "status",
    id: "status",
    label: "Status",
    widget: "taskstatus",
    isLog:true,
    width:130
  },
  {
    name: "priority",
    id: "priority",
    label: "Priority",
    widget: "priority",
    width:110
  },
  {
    name: "endDate",
    id: "endDate",
    label: "Due Date",
    format: "DD-MMM-YYYY",
    widget: "date",
    width:150
  },
  {
    name: "assignee",
    id: "assignee",
    label: "Assignee",
    widget: "assignees"
  },
  // {
  //   name: "attachments",
  //   id: "attachments",
  //   label: "Attachments",
  //   widget: "attachments"
  // },
  {
    name: "reporter",
    id: "reporter",
    label: "Reporter",
    widget: "assignees"
  }];


export default KoTicketTable_COLUMNS;