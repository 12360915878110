import React, { useState, useEffect } from 'react';
import styles from './tms.module.scss';
import { KoTicketTable_COLUMNS, WORKLOG_COLUMNS, KoTicketTable_COLUMNSNONTicket } from '../constant';
import { KoBadge, KoButton, KoCollapse, KoDateFormatter, KoDP, KoEmpty, KoIcon, KoLoader, KoModal,
  KoTable, KoTabs, KoText, KoTextArea, KoTitle, KoToast } from 'packages';
import { InboxAPI } from '../inbox.service';
import { useNavigate, useParams } from 'react-router';
export default function TimeSheetApproval() {
  const [isLoading, setLoading] = useState(false);
  const {tabId} = useParams();
  const [isOpenWorkLog, setWorkLogModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [currentRecord, setCurrentRecord] = useState();
  const [workLogHistory, setWorkLogHistory] = useState();
  const [requesters, setApprovelRequesters] = useState();
  const [sheetCount, setTimeSheetCounts ] =  useState();
  const [activeKey, setActiveKey] = useState(tabId ? tabId :'all');
  const [isReject, setRejectModal] = useState(false);
  const [workLogs, setWorklogs] = useState();
  const [comments, setComments] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    getUsersList();
  }, []);

  useEffect(() => {
    if(tabId === 'all'){
      getAllTimesheets(selectedUser?.userId);
    }else if(tabId === 'inprogress'){
      getApprovals(selectedUser?.userId);
    }else if(tabId === 'approved'){
      getTimesheetHistoryApproved(selectedUser?.userId);
    }else if(tabId === 'rejected'){
      getTimesheetHistoryRejected(selectedUser?.userId);
    }
  }, [tabId, selectedUser?.userId]);

  const getUsersList = () => {
    InboxAPI.getUsersbyTimeSheetId().then(
      (data) => {
        setApprovelRequesters(data['users']);
        if(tabId === 'all'){
          getAllTimesheets(data?.users?.length > 0 ? data?.users[0]?.userId : null);
        }else if(tabId === 'inprogress'){
          getApprovals(data?.users?.length > 0 ? data?.users[0]?.userId : null);
        }else if(tabId === 'approved'){
          getTimesheetHistoryApproved(data?.users?.length > 0 ? data?.users[0]?.userId : null);
        }else if(tabId === 'rejected'){
          getTimesheetHistoryRejected(data?.users?.length > 0 ? data?.users[0]?.userId : null);
        }
       
        setSelectedUser(data?.users?.length > 0 ? data?.users[0] : null);
        getTimesheetCount(data?.users?.length > 0 ? data?.users[0]?.userId : null);
      },
      (err) => {
      }
    );
  };


  const getTimesheetCount = (id) => {
    InboxAPI.getTimesheetCount(id).then(
      (data) => {
        setTimeSheetCounts(data);
      },
      (err) => {
      }
    );
  };

  console.log('workLogs', sheetCount);

  const getAllTimesheets = (userId) => {
    setLoading(true);
    InboxAPI.getAllTimesheets(userId).then(
      (data) => {
        setLoading(false);
        setWorklogs(data?.workLogs);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const getApprovals = (userId) => {
    setLoading(true);
    InboxAPI.getTimesheetApprovals(userId ? userId : selectedUser?.userId).then(
      (data) => {
        setLoading(false);
        setWorklogs(data?.workLogs);
      },
      (err) => {
        setLoading(false);
      }
    );
  };


  const panelStyle = {
    marginBottom: 15,
    background: 'var(--layout)',
    border:'1px solid var(--border)',
    borderRadius: 4
  };

  const onExtraClick = (record) => {
    setWorkLogModal(true);
    setWorkLogHistory(record?.logs || []);
  };


  const handleReject = () => {
    setLoading(true);
    let obj = {
      workspace: currentRecord?.workspace,
      status: 3,
      comments: comments,
      userId: selectedUser?.userId
    };
    InboxAPI.rejectTimesheet(obj).then(
      (data) => {
        setLoading(false);
        setRejectModal(false);
        setComments('');
        KoToast.success(`Timesheet rejected successfully!`);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const handleApprove = (item) => {
    setLoading(true);
    let obj = {
      workspace: item?.workspace,
      status: 2,
      userId: selectedUser?.userId
    };
    InboxAPI.approveTimesheet(obj).then(
      (data) => {
        setLoading(false);
        getApprovals();
        KoToast.success(`Timesheet approved successfully!`);
      },
      (err) => {
        setLoading(false);
      }
    );
  };


  const getItems = () => {
    let items = workLogs?.map((item) => {
      return {
        key: item?.workspace,
        label: <div className={styles.panelHeader}>
          <div className={styles.panelHeaderWrapper}>
            <div className={styles.panelHeaderLeft}>
              <div className={`${styles.circle}  ${styles[item ?
                item?.name?.charAt(0).toLowerCase() : 'a']}`}>
                {item['logo'] ?
                  <KoDP logo={item['logo']} className={styles.profile} width={20} height={20} />
                  :
                  <KoText className={`${styles.letter}`} text={item?.name?.charAt(0)} />}
              </div>
              <div>
                <KoTitle text={item?.name} level={5} className={styles.panelHeaderTitle} />
                <div className={styles.panelHeaderTextWrapper}>
                  <KoText text={`Total Billable Work Hours: `} className={styles.panelHeaderText} />
                  <KoText text={`${item?.totalBillableWorkHours} `} className={styles.panelHeaderTextBold} />
                  <KoText text={`Total Non-Billable Work Hours: `} className={styles.panelHeaderText} />
                  <KoText text={`${item?.totalNonBillableWorkHours} `} className={styles.panelHeaderTextBold} />
                </div>
                {item?.comments && item?.status === 3 && <div className={styles.panelHeaderTextWrapper}>
                  <KoText text={`Rejected Comments: ${item?.comments}`} className={styles.panelHeaderTextDanger} />
                </div>}
              </div>
            </div>
            <div>
              {item?.status === 0 && <div className={`${styles.panelHeaderCenter} ${styles.draft}`}>
                <KoIcon name="DotFilled" width={20} height={20} className={styles.panelHeaderIcon} />
                <KoText text="Not Submitted" className={styles.panelHeaderText} />
              </div>}

              {item?.status === 1 && <div className={`${styles.panelHeaderCenter} ${styles.waitingForApproval}`}>
                <KoIcon name="TickFilled" color={item?.status === 1 ? 'var(--inprogress)' : ''} width={20} height={20} className={styles.panelHeaderIcon} />
                <KoText text="Waiting for approval" className={styles.panelHeaderText} />
              </div>}
              {item?.status === 2 && <div className={`${styles.panelHeaderCenter} ${styles.approved}`}>
                <KoIcon name="TickFilled" width={20} height={20} className={styles.panelHeaderIcon} />
                <KoText text="Approved" className={styles.panelHeaderText} />
              </div>}
              {item?.status === 3 && <div className={`${styles.panelHeaderCenter} ${styles.rejected}`}>
                <KoIcon name="CrossFilled" width={20} height={20} className={styles.panelHeaderIcon} />
                <KoText text="Rejected" className={styles.panelHeaderText} />
              </div>}

              <div className={styles.customerSection}>
                <KoText text={`${item?.customer}`} className={styles.panelHeaderCenterText} />
              </div>
            </div>
          </div>
          <div className={styles.panelFooter}>
            <div className={` ${styles.tag} ${styles.panelHeaderTextIcon}`}>
              <KoIcon name="CalendarOutlined" className={styles.panelHeaderIcon} />
              <KoText text={`Requested at:`} className={styles.panelHeaderText} />
              <KoDateFormatter data={item?.approvelRequestedDate} />
            </div>
            {item?.status === 2 &&
              <div className={` ${styles.tag} ${styles.panelHeaderTextIcon}`}>
                <KoIcon name="CalendarOutlined" className={styles.panelHeaderIcon} />
                <KoText text={`Approved at: `} className={styles.panelHeaderText} />
                <KoDateFormatter data={item?.approvedDate} />
              </div>}
            {item?.status === 3 &&
              <div className={` ${styles.tag} ${styles.panelHeaderTextIcon}`}>
                <KoIcon name="CalendarOutlined" className={styles.panelHeaderIcon} />
                <KoText text={`Rejected at: `} className={styles.panelHeaderText} />
                <KoDateFormatter data={item?.rejectedDate} />
              </div>}
            <div className={` ${styles.tag} ${styles.panelHeaderTextIcon} profileChar`}>
              <KoText text="Requested by: " className={styles.panelHeaderText} />
              <div className={`${styles.circleDPsmall}  ${item?.requestedBy?.firstName?.charAt(0) ? item?.requestedBy?.firstName?.charAt(0).toLowerCase() : 'a'}`}>
                {item?.requestedBy?.['logo'] ?
                  <KoDP logo={item?.requestedBy?.['logo']} className={styles.profile} width={20} height={20} />
                  :
                  <KoText className={`${styles.letter}`} text={item?.requestedBy?.firstName?.charAt(0)} />}
              </div>
              <KoText text={`${item?.requestedBy?.firstName} ${item?.requestedBy?.lastName}`} className={styles.panelHeaderText} />
            </div>
            <div className={` ${styles.tag} ${styles.panelHeaderTextIcon} profileChar`}>
              <KoText text={`${item.status === 1 ? 'Approver: ' : item.status === 2 ? 'Approved by: ' : 'Rejected by: '}`} className={styles.panelHeaderText} />
              <div className={`${styles.circleDPsmall}  ${item?.approverProfile?.firstName?.charAt(0) ? item?.approverProfile?.firstName?.charAt(0).toLowerCase() : 'a'}`}>
                {item?.approverProfile['logo'] ?
                  <KoDP logo={item?.approverProfile['logo']} className={styles.profile} width={20} height={20} />
                  :
                  <KoText className={`${styles.letter}`} text={item?.approverProfile?.firstName?.charAt(0)} />}
              </div>
              <KoText text={`${item?.approverProfile?.firstName} ${item?.approverProfile?.lastName}`} className={styles.panelHeaderText} />
            </div>
          </div>
        </div>,
        children: <div className={`${styles.tablePart} tableCard`}>
          <div className={styles.tableHeader}>
            <KoTitle text='Worklogs Associated with Tickets' level={5} />
            <div className={styles.tableHeaderBtns}>
              {item?.status === 1 && <KoButton type='default' label='Reject Timesheet' onClick={() => { setCurrentRecord(item); setRejectModal(true); }} />}
              {item?.status === 1 && <KoButton type='primary' label='Approve Timesheet' onClick={() => { setCurrentRecord(item); handleApprove(item); }} />}
            </div>
          </div>
          <KoTable
            rowKey="workspace"
            onExtraClick={onExtraClick}
            data={{ columns: KoTicketTable_COLUMNS, rows: item.workLogs }}
          />
          <div className={styles.tableHeaderSub}>
            <KoTitle text='Worklogs Not Associated with Tickets' level={5} />
          </div>
          <KoTable
            rowKey="workspace"
            onExtraClick={onExtraClick}
            data={{ columns: KoTicketTable_COLUMNSNONTicket, rows: item.nonTicketLogs?.logs }}
          />
          
          
        </div>,
        style: panelStyle
        // showArrow: false
      };
    });
    return items;
  };

  const getTimesheetHistoryApproved = (userId) => {
    setLoading(true);
    InboxAPI.getTimesheetHistoryApproved(userId ? userId : selectedUser?.userId).then(
      (data) => {
        setLoading(false);
        setWorklogs(data?.workLogs);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const getTimesheetHistoryRejected = (userId) => {
    setLoading(true);
    InboxAPI.getTimesheetHistoryRejected(userId ? userId : selectedUser?.userId).then(
      (data) => {
        setLoading(false);
        setWorklogs(data?.workLogs);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const onChangeTabs =(key) =>{
    setActiveKey(key);
    navigate(`/home/approvals/tms/${key}`);
    if(key ==='inprogress'){
      getApprovals(selectedUser?.userId);
    }else  if(key ==='approved'){
      getTimesheetHistoryApproved(selectedUser?.userId);
    }else  if(key ==='rejected'){
      setLoading(true);
      getTimesheetHistoryRejected(selectedUser?.userId);
    }
  };

  return (
    <>
      {isLoading && <KoLoader />}
      <div className={`${styles.wrapper}`}>

        <div className={styles.sideNav}>
          <ul>
            {requesters?.map((data, index) => {
              return (
                <li className={selectedUser?.userId === data?.userId ? styles.active : ''} key={index} onClick={() => { setSelectedUser(data);  setSelectedUser({ userId: data?.userId, ...data?.profile }); }}>
                  <div className={`${styles.circle}  ${styles[data ?
                    data?.profile?.firstName?.charAt(0).toLowerCase() : 'a']}`}>
                    {data?.profile?.['logo'] ?
                      <KoDP logo={data?.profile?.['logo']} className={styles.profile} width={30} height={30} />
                      :
                      <KoText className={`${styles.letter}`} text={data?.profile?.firstName?.charAt(0)} />}
                  </div>
                  <div className={styles.sideNavText}>
                    <KoText ellipsis={true} text={data?.profile?.firstName} />
                    <KoText ellipsis={true} className={styles.email} text={data?.profile?.email} />
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <div className={styles.content}>
          {isLoading && <KoLoader />}
          <KoTabs activeKey={activeKey} onChange={onChangeTabs} options={[{
            label: 'All Timesheets',
            children:     <div className={`${styles.timeSheetPart} tableCard`}>
              {workLogs?.length > 0 ?  <KoCollapse
                className={styles.collapse}
                bordered={false}
                items={getItems(panelStyle)}
              /> : <KoEmpty title="No data available." />}
            </div>,
            key: 'all'
          },{
            label: <KoBadge count={sheetCount?.waitingCount} color={`var(--palette-error-main)`} offset={[12, 0]} >
              <KoText text={`Waiting for apprvals`}/>
            </KoBadge>,
            children:     <div className={`${styles.timeSheetPart} tableCard`}>
              {workLogs?.length > 0 ?  <KoCollapse
                className={styles.collapse}
                bordered={false}
                items={getItems(panelStyle)}
              /> : <KoEmpty title="No data available." />}
            </div>,
            key: 'inprogress'
          },{
            label: <KoBadge count={sheetCount?.approvedCount} color={`var(--palette-error-main)`} offset={[12, 0]} >
              <KoText text={`Approved Timesheets`}/>
            </KoBadge>,
            children:     <div className={`${styles.timeSheetPart} tableCard`}>
              {workLogs?.length > 0 ?  <KoCollapse
                className={styles.collapse}
                bordered={false}
                items={getItems(panelStyle)}
              /> : <KoEmpty title="No data available." />}
            </div>,
            key: 'approved'
          }, {
            label: <KoBadge count={sheetCount?.rejectedCount} color={`var(--palette-error-main)`} offset={[12, 0]} >
              <KoText text={`Rejected Timesheets`}/>
            </KoBadge>,
            children:     <div className={`${styles.timeSheetPart} tableCard`}>
              {workLogs?.length > 0 ?  <KoCollapse
                className={styles.collapse}
                bordered={false}
                items={getItems(panelStyle)}
              /> : <KoEmpty title="No data available." />}
            </div>,
            key: 'rejected'
          }] }/>
        </div>
        <KoModal
          title={`Worklog History`}
          centered
          open={isOpenWorkLog}
          afterClose={() => { setCurrentRecord(); }}
          handleOk={() => { setCurrentRecord(); setWorkLogModal(false); }}
          onClose={() => { setCurrentRecord(); setWorkLogModal(false); }}
          width={850}
          // maskClosable={false}
          footer={[
            <KoButton key='cancel' label='Close' onClick={() => setWorkLogModal(false)} />
          ]}
        >
          {isLoading && <KoLoader circle={false} />}
          <div className={styles.suspend}>
            <KoTable data={{ columns: WORKLOG_COLUMNS, rows: workLogHistory }} />
          </div>
        </KoModal>

        <KoModal
          title={`Reject ${currentRecord?.name?.toUpperCase()}`}
          centered
          open={isReject}
          afterClose={() => { setCurrentRecord(); }}
          handleOk={() => { setCurrentRecord(); setRejectModal(false); }}
          onClose={() => { setCurrentRecord(); setRejectModal(false); }}
          width={580}
          // maskClosable={false}
          footer={[
            <KoButton key='cancel' label='Cancel' onClick={() => setRejectModal(false)} />,
            <KoButton key='reset' loading={isLoading} type='primary' label='Reject' onClick={() => handleReject(currentRecord)} />
          ]}
        >
          {isLoading && <KoLoader circle={false} />}
          <div className={styles.suspend}>
            <KoText text={`Are you sure you want to reject the ${currentRecord?.name?.toUpperCase()}?`} />
            <div className={styles.textArea}>
              <KoTextArea showCount maxLength={100} value={comments} onChange={e => setComments(e?.target?.value)} />
            </div>
          </div>
        </KoModal>
      </div>
    </>
  );
}
