import React from 'react';
import { KoForm, KoFormBuilder } from 'packages';
import styles from '../../template.module.scss';

export default function DividerSetUpdate({form, currentItem,  currentRecord, currentProperties, handleSaveProperties}) {
  currentProperties['colSpan'] = currentRecord?.columns || 6;
  const InputMeta = {
    columns: 6,
    formItemLayout: null,
    initialValues:currentProperties,
    colon: true,
    fields: [
      {
        key: 'label', placeholder: 'Enter Label', label: 'Display Label', colSpan: 3, rules: [
          {
            required: true,
            message: `Please enter a label to display in the form`
          }
        ]
      },
      {
        key: `key`, placeholder: 'Enter Label', label: 'Key', colSpan: 3},
      {
        key: 'colSpan', placeholder: 'Enter colspan', label: 'Col Span', colSpan: 3, widget:'number'
      }
    ]
  };
  return (
    currentItem && (
      <div className={styles.propertyWrapper}>
        <KoForm form={form} layout="vertical"onFinish={handleSaveProperties}>
          <KoFormBuilder form={form} meta={InputMeta} />
        </KoForm>
      </div>
    )
  );
}