import React from "react";
function CiscoWebex(props) {
  const { width, height } = props;
  return (
  
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width={width} height={height}
      viewBox="0 0 48 48"
      {...props}
    >
      <linearGradient
        id="a"
        x1={25.503}
        x2={0.665}
        y1={26.5}
        y2={26.5}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#0c63ad" />
        <stop offset={1} stopColor="#50e6ff" />
      </linearGradient>
      <path
        fill="url(#a)"
        d="M26.64 30.93l-2.5 5.55c-.97 1.25-2.23 2.26-3.66 2.93-1.43.7-2.99 1.08-4.58 1.12-6.6.13-9.67-5.81-9.67-5.81-3.73-6.15-4.84-14.26-5.1-16.87-.07-.3-.12-.61-.13-.92-.01-.8.21-1.6.66-2.28.38-.7.96-1.27 1.66-1.66.75-.36 1.58-.54 2.42-.52.78.03 1.55.25 2.22.65.51.31.95.71 1.31 1.18.54.91.82 1.95.83 3.01.26 2.32.72 4.61 1.37 6.86.5 1.79 1.2 3.52 2.09 5.16.33.75.85 1.4 1.5 1.89l.4.2c1.05.2 1.63-.66 2.03-1.37.52-1.24.98-2.5 1.37-3.79.06-.26.13-.46.2-.72.13-.45.24-.91.32-1.37l2.1-5.63 5.16 12.39z"
      />
      <radialGradient
        id="b"
        cx={26.403}
        cy={22.574}
        r={21.814}
        gradientTransform="matrix(1 0 0 1.1255 0 -2.832)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#185c37" />
        <stop offset={0.661} stopColor="#30dc80" />
        <stop offset={1} stopColor="#5eee5c" />
      </radialGradient>
      <path
        fill="url(#b)"
        d="M32.255 7.471a11.155 11.155 0 00-4.64 1.046 10.276 10.276 0 00-3.661 3.071l-2.939 7.903 5.753 11.049 1.959-6.276.191-.98a30.27 30.27 0 011.569-4.84 3.635 3.635 0 011.112-1.66l.066-.066a1.303 1.303 0 011.436.191c.245.17.447.394.589.656.199.257.324.523.523.78.317.532.594 1.087.83 1.66.218.465.476.909.772 1.328.037 0 .066.03.066.066.447.483.979.879 1.569 1.17.58.314 1.233.472 1.893.457a5.204 5.204 0 001.768-.324 4.147 4.147 0 001.503-.98c.384-.461.713-.965.98-1.503a5.826 5.826 0 00.324-1.768 4.76 4.76 0 00-.39-1.893s-1.768-4.574-4.84-6.865a10.45 10.45 0 00-6.433-2.222z"
      />
      <linearGradient
        id="c"
        x1={1.591}
        x2={52.822}
        y1={27.9}
        y2={18.867}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#103f8d" />
        <stop offset={0.494} stopColor="#2f64f7" />
        <stop offset={1} stopColor="#11408b" />
      </linearGradient>
      <path
        fill="url(#c)"
        d="M15.063 7.471c-.393.02-.783.064-1.17.133a11.067 11.067 0 00-7.189 4.981c.438.105.856.282 1.237.523.511.303.956.703 1.312 1.179.54.91.826 1.947.83 3.005a47.32 47.32 0 001.312 6.724 21.716 21.716 0 013.071-6.79c.164-.246.387-.449.647-.589a.958.958 0 01.457-.133c.276-.005.548.064.789.199.066 0 .066.066.133.066.21.201.404.417.581.647.347.636.655 1.293.921 1.967.523 1.436.98 3.262.98 3.262l1.303 5.23a37.266 37.266 0 001.768 5.296c2.026 4.64 5.097 5.944 5.097 5.944a11.63 11.63 0 004.898 1.303 9.618 9.618 0 004.707-.98 12.688 12.688 0 004.151-3.32 22.644 22.644 0 001.826-2.939 35.402 35.402 0 002.814-7.587 42.494 42.494 0 001.046-4.981c.133-.83.257-1.66.324-2.49a5.62 5.62 0 00-.066-2.349c-.058-.133-.058-.266-.125-.398a4.635 4.635 0 00-2.026-2.283 3.878 3.878 0 00-2.225-.656 5.622 5.622 0 00-1.112.133 23.05 23.05 0 012.158 3.918 4.76 4.76 0 01.39 1.893 5.82 5.82 0 01-.324 1.768 7.672 7.672 0 01-.98 1.503 3.39 3.39 0 01-1.503.98 5.237 5.237 0 01-1.768.332 4.045 4.045 0 01-2.283-.656l-.465 1.76a21.312 21.312 0 01-1.436 3.926 14.12 14.12 0 01-1.17 2.216c-.298.484-.73.871-1.245 1.112a1.777 1.777 0 01-.589.066 1.047 1.047 0 01-.589-.199 3.32 3.32 0 01-.83-1.046 12.962 12.962 0 01-.714-1.336c-.39-.98-1.046-3.395-1.046-3.395l-.614-2.582-.656-2.548-.457-1.702a19.48 19.48 0 00-2.881-6.533 10.974 10.974 0 00-5.354-4.051 8.94 8.94 0 00-2.648-.523h-1.287z"
      />
    </svg>
  );
}


export default CiscoWebex;