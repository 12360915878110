import React from "react";
function WishList(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 22" fill="none">
      <path d="M0.431975 0.0536003C0.263732 0.126987 0.113678 0.292107 0.0500182 0.461813C0.0136413 0.553547 0 3.74128 0 11.002C0 19.6891 0.00909421 21.4366 0.0636595 21.5605C0.190978 21.8723 0.536559 22.0512 0.886686 21.987C1.00946 21.9641 1.8916 21.2761 4.51528 19.1616C6.42051 17.6205 8.00291 16.3729 8.03474 16.3821C8.06202 16.3959 9.63077 17.648 11.5133 19.1708C13.8323 21.0422 14.9873 21.9457 15.0964 21.9778C15.4511 22.0696 15.8649 21.8494 15.9603 21.51C15.9967 21.3907 16.0058 18.1296 15.9967 10.8965L15.9831 0.452641L15.8876 0.3288C15.8376 0.26 15.7375 0.159094 15.6693 0.108641C15.5466 0.0123215 15.542 0.0123215 8.05747 0.00314713C1.88705 -0.00602531 0.545653 0.00314713 0.431975 0.0536003Z" fill="black" />
    </svg>
  );
}


export default WishList;