import React from 'react';
import { Breadcrumb } from 'antd';

function KoBreadcrumb(props) {
  return (
    <Breadcrumb
      { ...props}
    />
  );
}

export default KoBreadcrumb;