import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { add, remove } from 'shared/store/breadcrumbSlice';
import styles from './deployment.module.scss';
import { KoAffix, KoButton, KoIcon, KoModal, KoForm, KoLoader, KoToast, KoNano, KoTable, KoPagination, KoDropdown, KoTitle, KoText, KoEmpty, KoFlex } from 'packages';
import KoToolBar from 'shared/components/toolbar';
import ServiceCurd from './serviceCurd';
import { deploymentAPI } from './deployment.services';
import { COLUMNS } from './constant';

export default function Services() {
  const dispatch = useDispatch();
  const [isOpen, setModal] = useState(false);
  const [isOpenPRDelete, setDeleteModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState();
  const [serviceList, setServiceList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(100);
  const [pagination, setPagination] = useState({
    size: 10,
    page: 1
  });

  const [form] = KoForm.useForm();

  useEffect(() => {
    getServices();
    dispatch(remove());
    dispatch(add([
      {
        title: 'Workspace',
        path: 'workspace'
      },
      {
        title: 'Deployment',
        path: 'deployment'
      },
      {
        title: 'Devlop', isOpen
      }
    ]));
  }, []);


  const getServices=()=>{
    setServiceList();
    setTotalCount();
    deploymentAPI.getServices(pagination, currentRecord?.serviceId).then(
      (data) => {
        setServiceList(data?.services);
        setTotalCount(data?.count);
          // setServiceList(data?.customers);
      },
      (err) => {
      }
    );

  };

  const handleFinish = (values) => {
   
    if(!currentRecord?.envId){
      values['serviceId'] = KoNano('SER');
    }
    deploymentAPI.createOrUpdateServices(values, currentRecord?.serviceId).then(
      (data) => {
        setLoading(false);
        setModal(false);
        getServices();
        KoToast.success(`Service ${currentRecord?.serviceId ? 'updated':'created' } successfully!`);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const DeleteService = () => {
    deploymentAPI.deleteService(currentRecord?.serviceId).then(
      (data) => {
        setLoading(false);
        setDeleteModal(false);
        getServices();
        KoToast.success("Service Deleted Successfully");
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const items = [
    {
      label: "Update Service",
      key: "1",
      icon: <KoIcon name="EditOutlined" height={12} width={13} />
    },
    {
      type: 'divider'
    },
    {
      key: '2',
      danger: true,
      label: 'Delete Service',
      icon: <KoIcon name='DeleteOutlined' />
    }
  ];
  const onPagination = (page, other) => {
    const query = {
      size: other,
      page: page,
      offset: (page - 1) * other
    };
    const pQuery = Object.assign(pagination, query);
    setPagination(pQuery);
  };

  const onClick = (e, row) => {
    setCurrentRecord(row);
    
    if (e.key === "1") {
      setModal(true);
    }
    else if (e.key === "2") {
      setDeleteModal(true);
    }
  };


  const columnsRight = [{
    title: '',
    dataIndex: 'x',
    key: 'x',
    fixed: 'right',
    width: 40,
    render: (text, row) => {
      return <div className={`${styles.circle} columnsRight`} onClick={() => setCurrentRecord(row)}>
        <KoButton label='Start' icon={<KoIcon name='PlayOutlined' color="Green"/>}  onClick={() => (row)} />
        <KoButton label='Pause' icon={<KoIcon name='PauseOutlined' color="orange"/>} onClick={() => (row)} />
        <KoButton label='Stop' icon={<KoIcon name='StopOutlined' color="red"/>} onClick={() => (row)} />
        <div shape="circle">
          <KoDropdown
            overlayClassName={styles.actionsLi}
            placement="bottomRight"
            menu={{
              items,
              onClick:(e)=>onClick(e, row)
            }}
          >
            <div onClick={(e) => e.preventDefault()}>
              <KoIcon name="VerticalMenuOutlined" />
            </div>
          </KoDropdown>
        </div>
      </div>;
    }
  }];
  

  return (
    <div className={styles.wrapper}>
      <KoAffix top={56}>
        <KoToolBar
          className={styles.toolbar}
          title={'Service'}
          search={{ visible: true, onChange: () => { }, placeholder: 'Search by name' }}
          buttonList={[{
            label: 'Create Service',
            type: 'primary',
            icon: <KoIcon color="#FFF" name="PlusOutlined" width={18} height={18} />,
            onClick: () => setModal(true)
          }]} />
      </KoAffix>

      {serviceList?.length > 0 ?
        <div className='tableCard'>
          <KoToolBar
            className={styles.toolbar}
            title={'Recent release'}
            search={{ visible: true, onChange: () => { }, placeholder: 'Search by any parameter' }}
            filter={{
              visible: false,
              options: [{ label: 'Sort By Name', key: '0' },
                { label: 'Created At', key: '2' },
                { label: 'Sort By designation', key: '3' }]
            }}
            buttonList={[]} />
          <div className={styles.reacentTable}>
            <KoTable
              rowKey='serviceId'
              data={{ columns: COLUMNS, rows: serviceList}} extraColumnsRight={columnsRight} maxHeight={50} />
            <KoPagination current={parseInt(pagination.page) || 1}
              total={totalCount}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} records`
              }
              pageSize={pagination.limit}
              defaultCurrent={pagination.page}
              onChange={onPagination}
              showQuickJumper={false}
              simple={false}
              responsive={false} />
          </div>
        </div> :
        <KoFlex wrap="wrap" gap="small" className={styles.emptySection}>
          <KoEmpty onClick={()=>setModal(true)} label="Create service" icon={'EmptyTwoTone'} title="Create a new Service"/>
        </KoFlex>
      }

      <KoModal
        title={currentRecord?.serviceId  ? `Update Service ${currentRecord?.serviceName?.toUpperCase()}` : 'Create Service'}
        centered
        open={isOpen}
        handleOk={() => {setModal(false); }}
        onClose={() => {setModal(false); }}
        width={680}
        // maskClosable={false}
        height={520}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => {setModal(!isOpen); form.resetFields();}} />,
          <KoButton key='create' loading={isLoading} type='primary' label={currentRecord?.serviceId ? `Update` : `Create`}
            onClick={() => {form.submit(); form.resetFields();}} />
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <ServiceCurd form={form} handleFinish={handleFinish} initialValues={currentRecord}/>
      </KoModal>

      <KoModal
        title={`Delete ${currentRecord?.serviceName?.toUpperCase()} Service`}
        centered
        open={isOpenPRDelete}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setDeleteModal(false); }}
        onClose={() => { setCurrentRecord(); setDeleteModal(false); }}
        width={580}
        // maskClosable={false}
        height={450}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setDeleteModal(!isOpenPRDelete)} />,
          <KoButton key='delete' loading={isLoading} type='primary' label='Delete Service' onClick={() => DeleteService()} />
        ]}
      >
        <div className={styles.suspend}>
          <KoTitle level={5} text='License fees apply to activated users,' />
          <KoText text={`granting access to all paid services.`} />
        </div>
      </KoModal>

    </div>
  );
}
