import { KoDate, KoForm, KoFormBuilder, KoSelect } from 'packages';
import styles from './about.module.scss';
import { useCallback } from 'react';

function TimeCrud({form, handleFinish, initialValues}) {

  const handleSubmit = useCallback(values => {
    const updatedValues = {
      employeeTime: { ...values.employeeTime}
    };
    handleFinish(updatedValues);
  });

  const Date = ({ value, onChange }) =>
    <KoDate value={value} format = {'DD-MMM-YYYY'}
      onChange={v => onChange(v)} />;

  const Shift = ({ value, onChange }) =>
    <KoSelect
      value={value}
      onChange={(v) => onChange(v)}
      placeholder="Select Shift"
      options={[
        { label: "Day", value: "day" },
        { label: "Night", value: "night" },
        { label: "Both", value: "both" }
      ]}
    />;

  const OffPolicy = ({ value, onChange }) =>
    <KoSelect
      value={value}
      onChange={(v) => onChange(v)}
      placeholder="Select Weekly Off Policy"
      options={[
        { label: "Saturday and Sunday", value: "Saturday and Sunday" },
        { label: "Saturday", value: "Saturday" },
        { label: "Sunday", value: "Sunday" }
      ]}
    />;

  const meta = {
    columns: 4,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      {
        key: 'employeeTime.Shift', label: 'Shift', colSpan: 2, widget : Shift
      }, {
        key: 'employeeTime.weeklyOffPolicy',label: 'Weekly Off Policy', placeholder: 'Enter Date of Join', colSpan: 2, widget: OffPolicy
      },
      {
        key: 'employeeTime.leavePlan', label: 'Leave Plan', placeholder: 'Enter Leave Plan', colSpan: 2
      }, {
        key: 'employeeTime.holidayCalendar', label: 'Holiday Calendar', placeholder: 'Enter Holiday Calendar', colSpan: 2
      },
      {
        key: 'employeeTime.attendanceNumber',label: 'Attendance Number', placeholder: 'Enter Attendance Number', colSpan: 2
      },
      {
        key: 'employeeTime.payrollTimeSource',label: 'Payroll Time Source', placeholder: 'Payroll Time Source', colSpan: 2, widget: Date
      },
      {
        key: 'employeeTime.attendanceSource',label: 'Attendance Source', placeholder: 'Enter Attendance Source', colSpan: 2
      },
      {
        key: 'employeeTime.attendancePenalizationPolicy',label: 'Attendance Penalization Policy', placeholder: 'Enter Attendance Penalization Policy', colSpan: 2
      },
      {
        key: 'employeeTime.shiftWeeklyOffRule',label: 'Shift Weekly Off Rule', placeholder: 'Enter Shift Weekly Off Rule', colSpan: 2
      },
      {
        key: 'employeeTime.shiftAllowancePolicy', label: 'Shift Allowance Policy', placeholder: 'Enter Shift Allowance Policy', colSpan: 2
      },
      {
        key: 'employeeTime.overtime', label: 'Overtime', placeholder: 'Enter Overtime', colSpan: 2
      }
    ]
  };
  return (
    <div className={styles.crudWrapper}>
      <KoForm form={form} layout="vertical" onFinish={handleSubmit} onSubmit={handleSubmit}>
        <KoFormBuilder form={form} meta={meta} />
      </KoForm>
    </div>
  );
};

export default TimeCrud;