
import { css, cx } from '@emotion/css';
import { KoButton, KoIcon } from 'packages';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createEditor, Editor, Range, Transforms,  Element as SlateElement } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, useSelected, ReactEditor, Slate, useFocused, useSlate, withReact } from 'slate-react';
import { Menu, Portal } from './component';
import styles from './editor.module.scss';
import { Emoji } from 'emoji-picker-react';



const KoChatEditor = ({ onSendMessage, callback, emoji, editMessage, replyMessage, onTyping }) => {
  const selected = useSelected();
  const reverse = useRef(false);

  const initialVal = editMessage ? editMessage?.content : [
    {
      type: 'paragraph',
      children: [{ text: '' }]
    }
  ];

  const [value, setValue] = useState(initialVal);
  const [initialValue, setInitialValue] = useState(initialVal);
  const renderElement = useCallback(props => <Element {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  const handleSend = () => {
    
    onSendMessage(value);
    setValue(initialValue);
    moveCursorToStart();
    Transforms.delete(editor, {
      at: {
        anchor: Editor.start(editor, []),
        focus: Editor.end(editor, [])
      }
    });
  };

  const onUpdateMessage = (newValue) => {
    localStorage.setItem('message', JSON.stringify(newValue));
    setValue(newValue);
    onTyping();
  };

  useEffect(() => {
    if(editMessage) {
      setInitialValue(editMessage?.message);
      setValue(editMessage?.message);
    }
  }, [editMessage]);

  useEffect(() => {
    if(callback) {
      handleSend();
    }
  }, [callback]);


  React.useEffect(() => {
    const selection = editor.selection;
    if (selected && selection?.anchor.path.length === 3) {
      Transforms.move(editor, {
        unit: "offset",
        reverse: reverse.current
      });
    }
  }, [selected]);
  

  
  useEffect(() => {
    if(emoji) {
      const { isInline, isVoid } = editor;

      editor.isInline = (element) => {
        return element.type === 'emoji' ? true : isInline(element);
      };
    
      editor.isVoid = (element) => {
        return element.type === 'emoji' ? true : isVoid(element);
      };
      Transforms.insertNodes(editor, [
        {
          type: "emoji",
          emoji,
          children: [{ text: "" }]
        }
      ]);


    }
  }, [emoji]);


  const moveCursorToStart = () => {
    if (Editor.hasPath(editor, [0, 0])) {
      Transforms.select(editor, { path: [0, 0], offset: 0 });
      ReactEditor.focus(editor);
    }
  };




  return (
    <Slate editor={editor} initialValue={initialValue}
      value={value}
      onChange={(newValue) => {onUpdateMessage(newValue);}}
    >
      <HoveringToolbar />
      <Editable
        onKeyDown={(event) => {
          if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            value && handleSend();
          }
        }}
        onKeyDownCapture={(e) => {
          reverse.current = e.key === "ArrowLeft";
        }}
        renderElement={renderElement}
        renderLeaf={props => <Leaf {...props} />}
        className={styles.editableWrapper}
        placeholder="Type a message"
        onDOMBeforeInput={event => {
          switch (event.inputType) {
          case 'formatBold':
            event.preventDefault();
            return toggleMark(editor, 'bold');
          case 'formatItalic':
            event.preventDefault();
            return toggleMark(editor, 'italic');
          case 'formatUnderline':
            event.preventDefault();
            return toggleMark(editor, 'underlined');
          default:
            return;
          }
        }}
      />
    </Slate>
  );
};

const isBlockActive = (editor, format, blockType = "type") => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format
    })
  );

  return !!match;
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);
  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
  case "block-quote":
    return <blockquote {...attributes}>{children}</blockquote>;

  case "emoji":
    return (
      <span {...attributes} style={{ display: 'inline-block', verticalAlign: 'middle' }}>
        <Emoji
          size={25}
          unified={element?.emoji}
          style={{ display: 'inline-block', verticalAlign: 'middle', marginTop: '-4px' }} // Adjust margin as needed
        />
      </span>
    );

  case "bulleted-list":
    return <ul {...attributes}>{children}</ul>;

  case "list-item":
    return <li {...attributes}>{children}</li>;

  case "numbered-list":
    return <ol {...attributes}>{children}</ol>;

  default:
    return <p {...attributes}>{children}</p>;
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }
  if (leaf.italic) {
    children = <em>{children}</em>;
  }
  if (leaf.underlined) {
    children = <u>{children}</u>;
  }
  if (leaf.strikethrough) {
    children = <s>{children}</s>;
  }
  return <span {...attributes}>{children}</span>;
};
const HoveringToolbar = () => {
  const ref = useRef();
  const editor = useSlate();
  const inFocus = useFocused();
  useEffect(() => {
    const el = ref.current;
    const { selection } = editor;
    if (!el) {
      return;
    }
    if (
      !selection ||
      !inFocus ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === ''
    ) {
      el.removeAttribute('style');
      return;
    }
    const domSelection = window.getSelection();
    const domRange = domSelection.getRangeAt(0);
    const rect = domRange.getBoundingClientRect();
    el.style.opacity = '1';
    el.style.top = `${rect.top + window.pageYOffset - el.offsetHeight}px`;
    el.style.left = `${rect.left + window.pageXOffset - el.offsetWidth / 2 + rect.width / 2}px`;
  });


  return (
    <Portal
    >
      <Menu
        ref={ref}
        className={css`
          padding: 8px 7px 6px;
          position: absolute;
          z-index: 1;
          top: -10000px;
          left: -10000px;
          margin-top: -.6rem;
          opacity: 0;
          background-color: #f4f6f8;
          box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.1);
          border-radius: .5rem;
          transition: opacity 0.75s;
        `}
        onMouseDown={e => {
          e.preventDefault();
        }}
      >
        <MarkButton format="bold" icon="BoldOutlined" />
        <MarkButton format="italic" icon="ItalicOutlined" />
        <MarkButton format="underlined" icon="UnderlineOutlined" />
        <MarkButton format="strikethrough" icon="StrikethroughOutlined" />
        <MarkButton format="code" icon="CodeOutlined" />
        <BlockButton format="numbered-list" icon="NumberedOutlined" />
        <BlockButton format="bulleted-list" icon="BulletOutlined" />
      </Menu>
    </Portal>
  );
};


const MarkButton = ({ format, icon }) => {
  const editor = useSlate();

  return (
    <KoButton
      type="text"
      iconBtn={true}
      className={cx(
        css`
          cursor: pointer;
          background-color: ${isMarkActive(editor, format) && `var( --palette-gray-6)`};
        `
      )}
      onClick={event => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
      active={isMarkActive(editor, format)}
      icon={<KoIcon name={icon} />}
    />
  );
};

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <KoButton
      type="text"
      iconBtn={true}
      className={cx(
        css`
          cursor: pointer;
          background-color: ${isMarkActive(editor, format) && `var( --palette-gray-6)`};
        `
      )}
      active={isBlockActive(editor, format)}
      onClick={event => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
      icon={<KoIcon name={icon} />}
    />

  );
};

const LIST_TYPES = ["numbered-list", "bulleted-list"];

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) ,
    split: true
  });
  let newProperties;
 
  newProperties = {
    type: isActive ? "paragraph" : isList ? "list-item" : format
  };
  
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};


export default KoChatEditor;
