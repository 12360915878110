import React from "react";
function EmpId(props) {
  return (
    <svg fill="var(--palette-primary-darker)"  viewBox="0 0 64 64" {...props}>
      <path d="M44 53H12a3 3 0 01-3-3V24a3 3 0 013-3h13a1 1 0 000-2H12a5 5 0 00-5 5v26a5 5 0 005 5h32a1 1 0 000-2zm8-34H39a1 1 0 000 2h13a3 3 0 013 3v26a3 3 0 01-3 3h-4a1 1 0 000 2h4a5 5 0 005-5V24a5 5 0 00-5-5z" />
      <path d="M21 25a6 6 0 106 6 6 6 0 00-6-6zm0 10a4 4 0 114-4 4 4 0 01-4 4z" />
      <path d="M26.25 37.17a9.72 9.72 0 01-10.5 0A.94.94 0 0015 37a1 1 0 00-.64.48 2.58 2.58 0 00-.28 1.77l1.41 7.37A3.06 3.06 0 0018.54 49h4.92a3.06 3.06 0 003.08-2.35L28 39.28a2.58 2.58 0 00-.28-1.77A1 1 0 0027 37a.94.94 0 00-.75.17zm-1.68 9.1a1.09 1.09 0 01-1.11.73h-4.92a1.09 1.09 0 01-1.11-.73l-1.27-6.6a12 12 0 009.68 0zM38 34h10a1 1 0 000-2H38a1 1 0 000 2zm0 4h10a1 1 0 000-2H38a1 1 0 000 2zm0 4h10a1 1 0 000-2H38a1 1 0 000 2zm-7-17h2a3 3 0 003-3V12a3 3 0 00-3-3h-2a3 3 0 00-3 3v10a3 3 0 003 3zm-1-13a1 1 0 011-1h2a1 1 0 011 1v10a1 1 0 01-1 1h-2a1 1 0 01-1-1z" />
    </svg>
  );
}


export default EmpId;