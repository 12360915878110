import { Layout, theme } from 'antd';
import { useState } from 'react';
import { Outlet } from "react-router-dom";

import { KoBreadcrumb } from 'packages';
import GlobalHeader from './header';
import styles from './layouts.module.scss';
import GlobalSider from './sidenav';
const { Content } = Layout;

function KoLayout(props) {
  // const {breadcrumbs } = useSelector(state => state);
  let breadcrumb = [];
  // const navigate = useNavigate();
  
  // if(breadcrumbs[0]){
  //   breadcrumb = breadcrumbs[0].map((curmb, index, array) => ({
  //     title: <KoText className={`${index === array.length - 1 ? styles.dark : styles.lite}`}
  //       text={curmb.title}/>,
  //     onClick:()=>onPageChange(curmb.path)
  //   }));
  // }

  // const onPageChange=(path)=>{
  //   if(path){
  //     navigate(path);
  //   }
  // };
  const {
    token: { colorBgContainer }
  } = theme.useToken();
  const [collapsed] = useState(false);

  return (
    <Layout className={styles.wrapper}>
      <GlobalHeader  />
      <Layout hasSider className={styles.sectionLayout}>

        <GlobalSider collapsed={collapsed} background={colorBgContainer} />
        <Layout style={{
          marginLeft: 87,
          transition: ' transform 0.3s'
        }} className={`${styles.card}`}>
          {breadcrumb?.length >0 && <div className={styles.breadcrumbs}>
            <KoBreadcrumb
              className={styles.breadWrapper}
              separator="/"
              items={breadcrumb}
            />
          </div>}
          <Content
            style={{
              transition: ' transform 0.3s',
              margin: 0
            }}
          >
            <Outlet/>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default KoLayout;