import React from 'react';
import {KoForm, KoFormBuilder } from 'packages';
import styles from './curd.module.scss';

export default function ApprovalsCurd({form, initialValues}) {
 
         
  const recurringMeta = {
    columns: 4,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      {
        key: 'invoiceApprover', placeholder: 'Select approver', label: 'Approver', colSpan: 4
      },
     
      {
        key: 'comments', label: 'comments', colSpan: 4,widget: 'textarea'
      }
      
    ]
  };

  return (
    <div className={styles.schedule}>
      <div className={styles.recurring}>
        <KoForm layout="vertical" form={form} >
          <KoFormBuilder form={form}  meta={recurringMeta} />
        </KoForm>
      </div>
    </div>
  );
}
