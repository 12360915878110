import { useRef } from 'react';
import { when } from 'packages/kanban/services/utils';
import KoButton from 'packages/button';

function CardForm({ onConfirm, onCancel }) {
  const inputCardTitle = useRef();
  const inputCardDescription = useRef();

  function addCard(event) {
    event.preventDefault();
    when(inputCardTitle.current.value)((value) => {
      onConfirm({ title: value, description: inputCardDescription.current.value });
    });
  }

  return (
    <div>
      <form onSubmit={addCard}>
        <input
          name='title'
          autoFocus
          defaultValue='Title'
          ref={inputCardTitle}
        />
        <input
          name='description'
          defaultValue='Description'
          ref={inputCardDescription}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '5px' }}>
          <KoButton label='Add' type='submit'/>
          <KoButton label='Cancel' onClick={onCancel}/>
        </div>
      </form>
    </div>
  );
}

export default CardForm;
