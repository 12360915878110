
import React from 'react';
import styles from './profilesettings.module.scss';
import { KoTitle } from 'packages';
import TimeIntervalForm from './slot';


export default function ProfileSettings({userData}) {



  return (
    <div className={styles.wrapper}>
      <KoTitle text='Settings' level={5} className={styles.title} />
      <div className={styles.card}>
        <KoTitle text='Availability (Weekly hours)' level={5} className={styles.header} />
        <div className={styles.content}>
          <TimeIntervalForm userData={userData}/>
        </div>
      </div>
    </div>
  );
}
