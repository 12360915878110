import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { KoButton, KoImage, KoIcon, KoForm, KoModal, KoFormBuilder, KoTitle, KoText, KoDP } from 'packages';
import { accountAPI } from '../account.service';
import { login, updateUser } from 'shared/store/authSlice';
import styles from './signin.module.scss';
import toast from 'react-hot-toast';

function LoginComponent() {
  const [form] = KoForm.useForm();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [accounts, setAccounts] = useState();

  function handleEnterKey(event) {
    if (event.keyCode === 13) {
      form.submit();
    }
  }

  const meta = {
    columns: 4,
    formItemLayout: null,
    colon: true,
    fields: [
      {
        key: 'email',
        label: 'Email',
        placeholder: 'Enter business email',
        colSpan: 4,
        rules: [
          {
            required: true,
            message: 'Email is required'
          }
        ]
      },
      {
        key: 'password',
        label: 'Password',
        rules: [
          {
            required: true,
            message: 'Password is required'
          }
        ],
        placeholder: 'Password',
        widget: 'password',
        colSpan: 4
      }
    ]
  };

  const handleFinish = useCallback((values) => {
    setLoading(true);
    accountAPI.login(values).then(
      (data) => {
        setLoading(false);
        if (data?.accounts) {
          setAccounts(data['accounts']);
          setShowModal(true);
          return;
        }
        if (data?.status === 2) {
          navigate(`/account/requested`);
          return;
        }
        if (!data?.isVerified) {
          navigate('/account/verify');
        } else if (data?.isChangePassword) {
          navigate('/account/resetpassword');
        } else {
          const urlParams = new URLSearchParams(window.location.search);
          const redirectUrl = urlParams.get('redirect');
          if (redirectUrl) {
            window.location.href = redirectUrl;
          } else {
            // Dispatch the login action to update the Redux state
            dispatch(
              login({
                userId: data.userId,
                user: data.user,
                accessToken: data.accessToken,
                accountId: data.accountId
              })
            );
            dispatch(updateUser({...data}));
            navigate('/home/dashboard/myoverview');
          }
        }
      },
      (err) => {
        if (err?.status === 2) {
          navigate(`/account/requested`);
          return;
        }
        if (!err?.isVerified) {
          toast.error(err?.error);
        }
        setLoading(false);
      }
    );
  });

  const onPageChange = () => {
    navigate('/account/forgotpassword');
  };

  const onPageChangeCreate = () => {
    navigate('/account/create-account');
  };

  const switchAccount = (account) => {
    setLoading(true);
    accountAPI.switchAccount(account).then(
      (data) => {
        setLoading(false);
        if (data?.status === 2) {
          navigate(`/account/requested`);
          return;
        }
        if (!data?.isVerified) {
          navigate('/account/verify');
        } else if (data?.isChangePassword) {
          navigate('/account/resetpassword');
        } else {
          const urlParams = new URLSearchParams(window.location.search);
          const redirectUrl = urlParams.get('redirect');
          if (redirectUrl) {
            window.location.href = redirectUrl;
          } else {
            dispatch(
              login({
                userId: data.userId,
                user: data.user,
                accessToken: data.accessToken,
                accountId: data.accountId
              })
            );
            dispatch(updateUser({...data}));
            navigate('/home/dashboard/myoverview');
          }
        }
      },
      (err) => {
        setLoading(false);
      }
    );
  };


  return (
    <div className={styles.wrapper}>
      <div className={styles.modal}>
        <div className={`${styles.formWrapper} ${styles.login}`}>
          <div className={styles.header}>
            <KoIcon name='AppLogoFilled' width={108}
              height={40} />
          </div>
          <KoTitle text={'Welcome Back'} level={3} />
          <KoText text={`Embark on a secure journey by logging in and unlocking a world of possibilities in our feature-packed realm.`} className={`${styles.small}`} />
          <KoText onClick={onPageChangeCreate} className={styles.otherOptions} text={` Don't have an account? Create one today.`} />
          <div className={styles.formData}>
          </div>
          <KoForm form={form} layout="vertical" onKeyUp={handleEnterKey} onFinish={handleFinish} onSubmit={handleFinish}>
            <KoFormBuilder className={styles.formData} form={form} meta={meta} />
          </KoForm>
          <div className={styles.signUpOptions}>
            <div className={styles.line} />
            <KoText className={styles.otherOptions} text='Sign in with' />
          </div>
          <div className={styles.thirdPartyBtn}>
            <KoButton label='Sign in with Google' icon={<KoIcon name="GoogleFilled" />} />
            <KoButton label='Sign in with Microsoft' icon={<KoIcon name="MicrosoftFilled" />} />
          </div>
          <div className={styles.signupBtn}>
            <KoButton loading={isLoading} onClick={() => form.submit()} type="primary" label={'Login'} />
            <KoText onClick={onPageChange} className={styles.otherOptions} text='Forgot Password?' />
          </div>

        </div>
        <div className={styles.banner}>
          <div className={styles.slider}>
            <KoImage
              alt={'name'}
              src={`/images/banner.svg`}
              width={500}
              height={300}
              preview={false}
            />
            <div className={styles.footer}>
              <KoTitle text={'Connect your team’s work to your product roadmap'} level={5} />
              <KoText text="Every team has a unique process. Use a out-of-boss workflow,
              or create one to match the way team works"/>
            </div>
          </div>
          <div className={styles.dots}>
            <div className={styles.active} />
            <div className={styles.circle} />
            <div className={styles.circle} />
            <div className={styles.circle} />
          </div>
        </div>
      </div>
      <KoModal
        title={'Select Business'}
        centered
        open={showModal}
        handleOk={() => { setShowModal(false); }}
        onClose={() => { setShowModal(false); }}
        width={500}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setShowModal(!showModal)} />
        ]}
      >
        <div className={styles.businessWrapper}>
          <ul>
            {accounts?.map((account) => (
              <li onClick={()=>switchAccount(account)} key={account.accountId}>
                <div className={`${styles.headCircle}  ${styles[accounts ?
                  account?.companyName?.charAt(0).toLowerCase() : 'a']}`}>
                  {account?.['logo'] ?

                    <KoDP logo={account?.logo} width={account?.logo?.[0]?.name ? 40 : 40} height={account?.companyName?.logo?.[0]?.name ? 40 : 40} />
                    :
                    <KoText className={`${styles.letter}`} text={account?.companyName?.charAt(0)} />
                  }
                </div>
                <div className={styles.companyNames}>
                  <KoText ellipsis={true} className={styles.domain} text={`${account?.companyName}`} />
                  <KoText ellipsis={true} className={styles.subdomain} text={`https://${account?.domain}.kwapio.com`} />
                </div>
              </li>
            ))}
          </ul>
        </div>
      </KoModal>
    </div>
  );
}

export default LoginComponent;

