
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import { KoButton, KoCard, KoDateFormatter, KoDivider, KoDP, KoIcon, KoText, KoTitle } from 'packages';
import { useSelector } from 'react-redux';
import styles from './about.module.scss';

export default function ProfileAbout({userDetails,userData, preferenceData}) {
  const users = useSelector(state => state?.users);
  const designations = useSelector(state => state?.designations);

  const ReportingTeam = _filter(users['data'], function(o) {
    return o.reportingTo === userDetails?.userId;
  });
  console.log(preferenceData);
  return (
    <div className={styles.wrapper}>
      <div >
        <KoCard hoverable={false} className={styles.tabs}>
          <div className={styles.previewWrapper}>
            <KoDivider orientation="left" orientationMargin="0">
              <KoText className={styles.headingText} text={'Primary Details'} />
            </KoDivider>
            <div className={styles.card}>
              <div className={styles.fieldList}>
                <div className={styles.viewMode}>
                  <KoText className={styles.title} text={'First Name'} />
                  <KoText className={styles.titleValue} text={userData?.primaryDetails?.firstName} />
                </div>
                <div className={styles.viewMode}>
                  <KoText className={styles.title} text={'Middle Name'} />
                  <KoText className={styles.titleValue} text={userData?.primaryDetails?.middleName ? userData?.primaryDetails?.middleName : '-Not Set-'} />
                </div>
                <div className={styles.viewMode}>
                  <KoText className={styles.title} text={'Last Name'} />
                  <KoText className={styles.titleValue} text={userData?.primaryDetails?.lastName ? userData?.primaryDetails?.lastName : '-Not Set-'} />

                </div>
                <div className={styles.viewMode}>
                  <KoText className={styles.title} text={'Display Name'} />
                  <KoText className={styles.titleValue} text={userData?.primaryDetails?.displayName ? userData?.primaryDetails?.displayName : '-Not Set-'} />

                </div>
                <div className={styles.viewMode}>
                  <KoText className={styles.title} text={'Gender'} />
                  <KoText className={styles.titleValue} text={userData?.primaryDetails?.gender ? userData?.primaryDetails?.gender.charAt(0).toUpperCase() + userData?.primaryDetails?.gender.slice(1) : '-Not Set-'} />

                </div>
                <div className={styles.viewMode}>
                  <KoText className={styles.title} text={'Date of Birth'} />
                  {
                    userData?.primaryDetails?.dateOfBirth === "0001-01-01T00:00:00Z" ?
                      <KoText className={styles.titleValue} text={'-Not Set-'} />
                      :
                      <KoDateFormatter format='DD-MMM-YYYY' data={userData?.primaryDetails?.dateOfBirth} className={styles.titleValue}/>
                  }
                </div>
                <div className={styles.viewMode}>
                  <KoText className={styles.title} text={'Marital Status'} />
                  <KoText className={styles.titleValue} text={userData?.primaryDetails?.maritalStatus ? userData?.primaryDetails?.maritalStatus : '-Not Set-' } />
                </div>
                <div className={styles.viewMode}>
                  <KoText className={styles.title} text={'Blood Group'} />
                  <KoText className={styles.titleValue} text={userData?.primaryDetails?.bloodGroup ? userData?.primaryDetails?.bloodGroup : '-Not Set-'} />
                </div>
                <div className={styles.viewMode}>
                  <KoText className={styles.title} text={'Physically Handicapped'} />
                  <KoText className={styles.titleValue} text={userData?.primaryDetails?.disability ? userData?.primaryDetails?.disability : '-Not Set-'} />
                </div>
                <div className={styles.viewMode}>
                  <KoText className={styles.title} text={'Nationality'} />
                  <KoText className={styles.titleValue} text={userData?.primaryDetails?.nationality ? userData?.primaryDetails?.nationality : '-Not Set-'} />
                </div>
              </div>
            </div>
         
       
            <KoDivider orientation="left" orientationMargin="0">
              <KoText className={styles.headingText} text={'General Preferences'} />
            </KoDivider>
            <div className={styles.card}>
              <div className={styles.fieldList}>
                <div className={styles.viewMode}>
                  <KoText className={styles.title} text={'Default Language'} />
                  <KoText className={styles.titleValue} text={preferenceData?.preferences?.defaultLanguage ? preferenceData?.preferences?.defaultLanguage : '-Not Set-'} />
                </div>
                <div className={styles.viewMode}>
                  <KoText className={styles.title} text={'Primary Currency'} />
                  <KoText className={styles.titleValue} text={preferenceData?.preferences?.primaryCurrency ? preferenceData?.preferences?.primaryCurrency : '-Not Set-'} />
                </div>
                <div className={styles.viewMode}>
                  <KoText className={styles.title} text={'Number Format'} />
                  <KoText className={styles.titleValue} text={preferenceData?.preferences?.numberFormat ? preferenceData?.preferences?.numberFormat : '-Not Set-'} />
                </div>
                <div className={styles.viewMode}>
                  <KoText className={styles.title} text={'Date Format'} />
                  <KoText className={styles.titleValue} text={preferenceData?.preferences?.dateFormat ? preferenceData?.preferences?.dateFormat  : '-Not Set-'} />
                </div>
                <div className={styles.viewMode}>
                  <KoText className={styles.title} text={'Time Format'} />
                  <KoText className={styles.titleValue} text={preferenceData?.preferences?.timeFormat ? preferenceData?.preferences?.timeFormat : '-Not Set-'} />
                </div>
                <div className={styles.viewMode}>
                  <KoText className={styles.title} text={'Financial Year Begins'} />
                  <KoText className={styles.titleValue} text={preferenceData?.preferences?.financialYearBegins ? preferenceData?.preferences?.financialYearBegins : '-Not Set-'} />
                </div>
                <div className={styles.viewMode}>
                  <KoText className={styles.title} text={'Invoice No. Prefix'} />
                  <KoText className={styles.titleValue} text={preferenceData?.preferences?.invoiceNoPrefix ? preferenceData?.preferences?.invoiceNoPrefix : '-Not Set-'} />
                </div>
              </div>
            </div>
         
          </div>
  
  
        </KoCard>
      </div>
      <div className={styles.card}>
   
        <KoDivider orientation="left" orientationMargin="0">
          <KoText className={styles.headingText} text={`Reporting Team (${ReportingTeam?.length})`} />
        </KoDivider>

        <div className={styles}>
          <div className={styles.profileBodyContnet}>
            {ReportingTeam?.map((item) =>
            {
              const UserDesignation = _find(designations['data'], function(o) {
                return o.designationId === item?.designation; });

              return<div key={item?.userId} className={styles.profileCard}>
                <div className={styles.profileList}>
                  <div className={styles.userWrapper } >
                    <div className={`${styles.headCircle}  ${styles[item?
                      item?.profile?.firstName?.charAt(0).toLowerCase() :'a']}`}>

                      {item?.profile?.['logo'] ?
                      
                        <KoDP logo={item?.profile?.logo} width={item?.profile?.logo?.[0]?.name ? 30 : 25} height={item?.profile?.logo?.[0]?.name ? 30 : 25} shape={'circle'} userId={item?.userId} userDetails={item} name={item?.profile?.logo?.[0]?.name} />
                        :
                        <KoText className={`${styles.letter}`} text={item?.profile?.firstName?.charAt(0)}/>
                      }
                    </div>
                  </div>
                  <div className={styles.profileInfo}>
                    <div className={styles.details}>
                      <KoTitle level={5} ellipsis={true} text={`${item?.profile?.firstName} ${item?.profile?.lastName}`} />
                      <KoTitle level={5} className={`${styles.mobile} mobileNo`} ellipsis={true} text={`${UserDesignation ? UserDesignation?.name : 'No Designation'}`} />
                    </div>
                  </div>
                </div>
                <div className={styles.icons}>
                  <KoButton iconBtn={true} shape="circle" icon={<KoIcon name='EmailOutlined'  width={17} height={16}/>} />
                  <KoButton iconBtn={true} shape="circle" icon={<KoIcon name='ChatOutlined'  width={17} height={16}/>} />
                  <KoButton iconBtn={true} shape="circle"  onClick={() => window.open(`/profile/${item?.userId}`, '_blank')} icon={<KoIcon name='OpenOutlined'  width={17} height={16}/>} />
                </div>

              </div>;}
            )}
          </div>
        </div>
      </div>

      
      
    </div>
  );
}
