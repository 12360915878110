import React from "react";
function Unplayedaudio(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
      <g id="unplayed_audio" transform="translate(-80.573 -119.132)">
        <g id="_104695" data-name="104695" transform="translate(80.573 119.132)">
          <path id="Path_78895" data-name="Path 78895" fill="#d1cbff" d="M189.892-507.663a.73.73 0 0 0-.293.245l-.1.15v17.468l.106.15a.754.754 0 0 0 .67.322.718.718 0 0 0 .633-.6c.051-.271.051-16.937 0-17.208a.723.723 0 0 0-.648-.6.526.526 0 0 0-.368.073z" transform="translate(-182.565 507.749)" />
          <path id="Path_78896" data-name="Path 78896" fill="#d1cbff" d="M94.939-427.9a.828.828 0 0 0-.392.414c-.04.113-.048 1.2-.048 5.848v5.709l.084.168a.717.717 0 0 0 1.292-.022c.066-.139.07-.4.07-5.874s0-5.735-.07-5.874a.845.845 0 0 0-.154-.227.789.789 0 0 0-.782-.142z" transform="translate(-91.042 430.866)" />
          <path id="Path_78897" data-name="Path 78897" fill="#d1cbff" d="M283.9-391.874a.664.664 0 0 0-.326.322l-.077.146v8.893l.077.146a.675.675 0 0 0 .633.384.671.671 0 0 0 .666-.421c.066-.139.069-.34.069-4.556s0-4.417-.069-4.556a.769.769 0 0 0-.381-.381.934.934 0 0 0-.592.023z" transform="translate(-273.125 396.173)" />
          <path id="Path_78898" data-name="Path 78898" fill="#d1cbff" d="M.421-370.69a.691.691 0 0 0-.366.381A32.1 32.1 0 0 0 0-366.54c0 3.447 0 3.641.07 3.78a.707.707 0 0 0 .739.41.706.706 0 0 0 .615-.575c.051-.227.051-7.019 0-7.246a.686.686 0 0 0-.651-.571.6.6 0 0 0-.352.052z" transform="translate(0 375.761)" />
          <path id="Path_78899" data-name="Path 78899" fill="#d1cbff" d="M378.421-370.814a.684.684 0 0 0-.366.377 32.09 32.09 0 0 0-.055 3.769c0 3.455 0 3.641.069 3.78a.71.71 0 0 0 1.2.154.706.706 0 0 0 .146-.271c.037-.183.037-7.158 0-7.341a.706.706 0 0 0-.146-.271.718.718 0 0 0-.848-.197z" transform="translate(-364.166 375.888)" />
          <path id="Path_78900" data-name="Path 78900" fill="#d1cbff" d="M472.505-307.463a.733.733 0 0 0-.465.538 12.069 12.069 0 0 0-.04 1.31 12.07 12.07 0 0 0 .04 1.31.708.708 0 0 0 1.328.183l.1-.19v-2.606l-.1-.19a.7.7 0 0 0-.863-.355z" transform="translate(-454.726 314.828)" />
        </g>
        <g id="_104695-2" data-name="104695" transform="translate(165.834 119.132)">
          <path id="Path_78895-2" data-name="Path 78895" fill="#d1cbff" d="M189.892-507.663a.73.73 0 0 0-.293.245l-.1.15v17.468l.106.15a.754.754 0 0 0 .67.322.718.718 0 0 0 .633-.6c.051-.271.051-16.937 0-17.208a.723.723 0 0 0-.648-.6.526.526 0 0 0-.368.073z" transform="translate(-182.565 507.749)" />
          <path id="Path_78896-2" data-name="Path 78896" fill="#d1cbff" d="M94.939-427.9a.828.828 0 0 0-.392.414c-.04.113-.048 1.2-.048 5.848v5.709l.084.168a.717.717 0 0 0 1.292-.022c.066-.139.07-.4.07-5.874s0-5.735-.07-5.874a.845.845 0 0 0-.154-.227.789.789 0 0 0-.782-.142z" transform="translate(-91.042 430.866)" />
          <path id="Path_78897-2" data-name="Path 78897" fill="#d1cbff" d="M283.9-391.874a.664.664 0 0 0-.326.322l-.077.146v8.893l.077.146a.675.675 0 0 0 .633.384.671.671 0 0 0 .666-.421c.066-.139.069-.34.069-4.556s0-4.417-.069-4.556a.769.769 0 0 0-.381-.381.934.934 0 0 0-.592.023z" transform="translate(-273.125 396.173)" />
          <path id="Path_78898-2" data-name="Path 78898" fill="#d1cbff" d="M.421-370.69a.691.691 0 0 0-.366.381A32.1 32.1 0 0 0 0-366.54c0 3.447 0 3.641.07 3.78a.707.707 0 0 0 .739.41.706.706 0 0 0 .615-.575c.051-.227.051-7.019 0-7.246a.686.686 0 0 0-.651-.571.6.6 0 0 0-.352.052z" transform="translate(0 375.761)" />
          <path id="Path_78899-2" data-name="Path 78899" fill="#d1cbff" d="M378.421-370.814a.684.684 0 0 0-.366.377 32.09 32.09 0 0 0-.055 3.769c0 3.455 0 3.641.069 3.78a.71.71 0 0 0 1.2.154.706.706 0 0 0 .146-.271c.037-.183.037-7.158 0-7.341a.706.706 0 0 0-.146-.271.718.718 0 0 0-.848-.197z" transform="translate(-364.166 375.888)" />
          <path id="Path_78900-2" data-name="Path 78900" fill="#d1cbff" d="M472.505-307.463a.733.733 0 0 0-.465.538 12.069 12.069 0 0 0-.04 1.31 12.07 12.07 0 0 0 .04 1.31.708.708 0 0 0 1.328.183l.1-.19v-2.606l-.1-.19a.7.7 0 0 0-.863-.355z" transform="translate(-454.726 314.828)" />
        </g>
        <g id="_104695-3" data-name="104695" transform="translate(131.402 119.132)">
          <path id="Path_78895-3" data-name="Path 78895" fill="#d1cbff" d="M189.892-507.663a.73.73 0 0 0-.293.245l-.1.15v17.468l.106.15a.754.754 0 0 0 .67.322.718.718 0 0 0 .633-.6c.051-.271.051-16.937 0-17.208a.723.723 0 0 0-.648-.6.526.526 0 0 0-.368.073z" transform="translate(-182.565 507.749)" />
          <path id="Path_78896-3" data-name="Path 78896" fill="#d1cbff" d="M94.939-427.9a.828.828 0 0 0-.392.414c-.04.113-.048 1.2-.048 5.848v5.709l.084.168a.717.717 0 0 0 1.292-.022c.066-.139.07-.4.07-5.874s0-5.735-.07-5.874a.845.845 0 0 0-.154-.227.789.789 0 0 0-.782-.142z" transform="translate(-91.042 430.866)" />
          <path id="Path_78897-3" data-name="Path 78897" fill="#d1cbff" d="M283.9-391.874a.664.664 0 0 0-.326.322l-.077.146v8.893l.077.146a.675.675 0 0 0 .633.384.671.671 0 0 0 .666-.421c.066-.139.069-.34.069-4.556s0-4.417-.069-4.556a.769.769 0 0 0-.381-.381.934.934 0 0 0-.592.023z" transform="translate(-273.125 396.173)" />
          <path id="Path_78898-3" data-name="Path 78898" fill="#d1cbff" d="M.421-370.69a.691.691 0 0 0-.366.381A32.1 32.1 0 0 0 0-366.54c0 3.447 0 3.641.07 3.78a.707.707 0 0 0 .739.41.706.706 0 0 0 .615-.575c.051-.227.051-7.019 0-7.246a.686.686 0 0 0-.651-.571.6.6 0 0 0-.352.052z" transform="translate(0 375.761)" />
          <path id="Path_78899-3" data-name="Path 78899" fill="#d1cbff" d="M378.421-370.814a.684.684 0 0 0-.366.377 32.09 32.09 0 0 0-.055 3.769c0 3.455 0 3.641.069 3.78a.71.71 0 0 0 1.2.154.706.706 0 0 0 .146-.271c.037-.183.037-7.158 0-7.341a.706.706 0 0 0-.146-.271.718.718 0 0 0-.848-.197z" transform="translate(-364.166 375.888)" />
          <path id="Path_78900-3" data-name="Path 78900" fill="#d1cbff" d="M472.505-307.463a.733.733 0 0 0-.465.538 12.069 12.069 0 0 0-.04 1.31 12.07 12.07 0 0 0 .04 1.31.708.708 0 0 0 1.328.183l.1-.19v-2.606l-.1-.19a.7.7 0 0 0-.863-.355z" transform="translate(-454.726 314.828)" />
        </g>
        <g id="_104695-4" data-name="104695" transform="translate(117.842 119.132)">
          <path id="Path_78895-4" data-name="Path 78895" fill="#d1cbff" d="M189.892-507.663a.73.73 0 0 0-.293.245l-.1.15v17.468l.106.15a.754.754 0 0 0 .67.322.718.718 0 0 0 .633-.6c.051-.271.051-16.937 0-17.208a.723.723 0 0 0-.648-.6.526.526 0 0 0-.368.073z" transform="translate(-189.5 507.749)" />
          <path id="Path_78897-4" data-name="Path 78897" fill="#d1cbff" d="M283.9-391.874a.664.664 0 0 0-.326.322l-.077.146v8.893l.077.146a.675.675 0 0 0 .633.384.671.671 0 0 0 .666-.421c.066-.139.069-.34.069-4.556s0-4.417-.069-4.556a.769.769 0 0 0-.381-.381.934.934 0 0 0-.592.023z" transform="translate(-280.06 396.173)" />
          <path id="Path_78899-4" data-name="Path 78899" fill="#d1cbff" d="M378.421-370.814a.684.684 0 0 0-.366.377 32.09 32.09 0 0 0-.055 3.769c0 3.455 0 3.641.069 3.78a.71.71 0 0 0 1.2.154.706.706 0 0 0 .146-.271c.037-.183.037-7.158 0-7.341a.706.706 0 0 0-.146-.271.718.718 0 0 0-.848-.197z" transform="translate(-371.101 375.888)" />
          <path id="Path_78900-4" data-name="Path 78900" fill="#d1cbff" d="M472.505-307.463a.733.733 0 0 0-.465.538 12.069 12.069 0 0 0-.04 1.31 12.07 12.07 0 0 0 .04 1.31.708.708 0 0 0 1.328.183l.1-.19v-2.606l-.1-.19a.7.7 0 0 0-.863-.355z" transform="translate(-461.661 314.828)" />
        </g>
        <g id="_104695-5" data-name="104695" transform="translate(152.274 119.132)">
          <path id="Path_78895-5" data-name="Path 78895" fill="#d1cbff" d="M189.892-507.663a.73.73 0 0 0-.293.245l-.1.15v17.468l.106.15a.754.754 0 0 0 .67.322.718.718 0 0 0 .633-.6c.051-.271.051-16.937 0-17.208a.723.723 0 0 0-.648-.6.526.526 0 0 0-.368.073z" transform="translate(-189.5 507.749)" />
          <path id="Path_78897-5" data-name="Path 78897" fill="#d1cbff" d="M283.9-391.874a.664.664 0 0 0-.326.322l-.077.146v8.893l.077.146a.675.675 0 0 0 .633.384.671.671 0 0 0 .666-.421c.066-.139.069-.34.069-4.556s0-4.417-.069-4.556a.769.769 0 0 0-.381-.381.934.934 0 0 0-.592.023z" transform="translate(-280.06 396.173)" />
          <path id="Path_78899-5" data-name="Path 78899" fill="#d1cbff" d="M378.421-370.814a.684.684 0 0 0-.366.377 32.09 32.09 0 0 0-.055 3.769c0 3.455 0 3.641.069 3.78a.71.71 0 0 0 1.2.154.706.706 0 0 0 .146-.271c.037-.183.037-7.158 0-7.341a.706.706 0 0 0-.146-.271.718.718 0 0 0-.848-.197z" transform="translate(-371.101 375.888)" />
          <path id="Path_78900-5" data-name="Path 78900" fill="#d1cbff" d="M472.505-307.463a.733.733 0 0 0-.465.538 12.069 12.069 0 0 0-.04 1.31 12.07 12.07 0 0 0 .04 1.31.708.708 0 0 0 1.328.183l.1-.19v-2.606l-.1-.19a.7.7 0 0 0-.863-.355z" transform="translate(-461.661 314.828)" />
        </g>
        <g id="_104695-6" data-name="104695" transform="translate(100.43 119.133)">
          <path id="Path_78895-6" data-name="Path 78895" fill="#d1cbff" d="M190.556-507.663a.73.73 0 0 1 .293.245l.1.15v17.468l-.106.15a.754.754 0 0 1-.67.322.718.718 0 0 1-.633-.6c-.051-.271-.051-16.937 0-17.208a.723.723 0 0 1 .648-.6.526.526 0 0 1 .368.073z" transform="translate(-182.605 507.749)" />
          <path id="Path_78896-4" data-name="Path 78896" fill="#d1cbff" d="M95.506-427.9a.828.828 0 0 1 .392.414c.04.113.048 1.2.048 5.848v5.709l-.084.168a.717.717 0 0 1-1.292-.022c-.066-.139-.07-.4-.07-5.874s0-5.735.07-5.874a.845.845 0 0 1 .154-.227.789.789 0 0 1 .782-.142z" transform="translate(-84.127 430.866)" />
          <path id="Path_78897-6" data-name="Path 78897" fill="#d1cbff" d="M284.543-391.874a.664.664 0 0 1 .326.322l.077.146v8.893l-.077.146a.675.675 0 0 1-.633.384.671.671 0 0 1-.666-.421c-.066-.139-.069-.34-.069-4.556s0-4.417.069-4.556a.769.769 0 0 1 .381-.381.934.934 0 0 1 .592.023z" transform="translate(-280.043 396.173)" />
          <path id="Path_78898-4" data-name="Path 78898" fill="#d1cbff" d="M1.041-370.69a.691.691 0 0 1 .366.381 32.1 32.1 0 0 1 .055 3.769c0 3.447 0 3.641-.07 3.78a.707.707 0 0 1-.739.41.706.706 0 0 1-.615-.575c-.051-.227-.051-7.019 0-7.246a.686.686 0 0 1 .651-.571.6.6 0 0 1 .352.052z" transform="translate(13.815 375.761)" />
          <path id="Path_78899-6" data-name="Path 78899" fill="#d1cbff" d="M379.023-370.814a.684.684 0 0 1 .366.377 32.091 32.091 0 0 1 .055 3.769c0 3.455 0 3.641-.069 3.78a.71.71 0 0 1-1.2.154.706.706 0 0 1-.146-.271c-.037-.183-.037-7.158 0-7.341a.706.706 0 0 1 .146-.271.718.718 0 0 1 .848-.197z" transform="translate(-378 375.888)" />
        </g>
        <g id="_104695-7" data-name="104695" transform="translate(185.691 119.133)">
          <path id="Path_78895-7" data-name="Path 78895" fill="#d1cbff" d="M190.556-507.663a.73.73 0 0 1 .293.245l.1.15v17.468l-.106.15a.754.754 0 0 1-.67.322.718.718 0 0 1-.633-.6c-.051-.271-.051-16.937 0-17.208a.723.723 0 0 1 .648-.6.526.526 0 0 1 .368.073z" transform="translate(-182.605 507.749)" />
          <path id="Path_78896-5" data-name="Path 78896" fill="#d1cbff" d="M95.506-427.9a.828.828 0 0 1 .392.414c.04.113.048 1.2.048 5.848v5.709l-.084.168a.717.717 0 0 1-1.292-.022c-.066-.139-.07-.4-.07-5.874s0-5.735.07-5.874a.845.845 0 0 1 .154-.227.789.789 0 0 1 .782-.142z" transform="translate(-84.127 430.866)" />
          <path id="Path_78897-7" data-name="Path 78897" fill="#d1cbff" d="M284.543-391.874a.664.664 0 0 1 .326.322l.077.146v8.893l-.077.146a.675.675 0 0 1-.633.384.671.671 0 0 1-.666-.421c-.066-.139-.069-.34-.069-4.556s0-4.417.069-4.556a.769.769 0 0 1 .381-.381.934.934 0 0 1 .592.023z" transform="translate(-280.043 396.173)" />
          <path id="Path_78898-5" data-name="Path 78898" fill="#d1cbff" d="M1.041-370.69a.691.691 0 0 1 .366.381 32.1 32.1 0 0 1 .055 3.769c0 3.447 0 3.641-.07 3.78a.707.707 0 0 1-.739.41.706.706 0 0 1-.615-.575c-.051-.227-.051-7.019 0-7.246a.686.686 0 0 1 .651-.571.6.6 0 0 1 .352.052z" transform="translate(13.815 375.761)" />
          <path id="Path_78899-7" data-name="Path 78899" fill="#d1cbff" d="M379.023-370.814a.684.684 0 0 1 .366.377 32.091 32.091 0 0 1 .055 3.769c0 3.455 0 3.641-.069 3.78a.71.71 0 0 1-1.2.154.706.706 0 0 1-.146-.271c-.037-.183-.037-7.158 0-7.341a.706.706 0 0 1 .146-.271.718.718 0 0 1 .848-.197z" transform="translate(-378 375.888)" />
        </g>
      </g>
    </svg>


  );
}


export default Unplayedaudio;