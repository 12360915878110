import React from "react";
function Skype(props) {
  const { width, height } = props;
  return (
  
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width={width} height={height}
      {...props}
    >
      <path
        fill="#03a9f4"
        d="M15.716 8.083c0-4.155-3.416-7.524-7.631-7.524-.444 0-.88.038-1.305.11A4.453 4.453 0 004.426 0C1.981 0 0 1.953 0 4.364a4.3 4.3 0 00.608 2.207 7.464 7.464 0 00-.155 1.512c0 4.156 3.417 7.525 7.631 7.525a7.85 7.85 0 001.398-.126 4.46 4.46 0 002.093.518C14.019 16 16 14.046 16 11.637c0-.696-.166-1.354-.46-1.938a7.43 7.43 0 00.176-1.616z"
        className="color03A9F4 svgShape"
      />
      <path
        fill="#fafafa"
        d="M11.999 11.679c-.353.493-.874.883-1.551 1.159-.669.274-1.47.415-2.384.415-1.096 0-2.015-.19-2.733-.566a3.547 3.547 0 01-1.256-1.1c-.325-.46-.488-.917-.488-1.36 0-.274.107-.514.318-.709.209-.195.477-.292.797-.292.262 0 .488.077.672.23.175.148.326.366.447.645.135.306.282.564.437.765.151.197.366.361.641.49.277.128.65.195 1.108.195.631 0 1.149-.134 1.537-.395.381-.255.565-.563.565-.939 0-.296-.097-.53-.294-.713a2.104 2.104 0 00-.814-.444 16.188 16.188 0 00-1.4-.342c-.793-.167-1.466-.364-2-.59-.547-.23-.989-.548-1.311-.945-.328-.406-.494-.913-.494-1.509 0-.568.174-1.08.518-1.522.341-.439.839-.782 1.482-1.015.633-.231 1.386-.347 2.239-.347.681 0 1.28.078 1.781.232.503.154.927.362 1.26.619.336.26.586.535.742.823.158.29.239.579.239.858 0 .269-.105.514-.313.726-.21.214-.474.322-.784.322-.282 0-.504-.069-.657-.202-.143-.125-.292-.32-.456-.598a2.507 2.507 0 00-.685-.836c-.257-.193-.685-.289-1.275-.289-.546 0-.992.108-1.322.322-.318.205-.473.441-.473.721 0 .171.05.314.153.437.108.132.261.245.455.341.2.099.407.179.614.235.212.058.567.145 1.056.256.618.13 1.185.277 1.687.436.509.159.947.356 1.307.587.365.235.654.535.86.895.206.363.31.808.31 1.326a2.833 2.833 0 01-.535 1.678z"
        className="colorFAFAFA svgShape"
      />
    </svg>
  );
}


export default Skype;