import React from "react";
function Drag(props) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width={width}
      height={height}
      fill="var(--palette-gray-6)"
    >
      <path d="M113 0c62.408 0 113 50.592 113 113s-50.592 113-113 113S0 175.408 0 113 50.592 0 113 0zm286 0c62.408 0 113 50.592 113 113s-50.592 113-113 113-113-50.592-113-113S336.592 0 399 0zM113 286c62.408 0 113 50.592 113 113s-50.592 113-113 113S0 461.408 0 399s50.592-113 113-113zm286 0c62.408 0 113 50.592 113 113s-50.592 113-113 113-113-50.592-113-113 50.592-113 113-113z" />
    </svg>
  );
}
export default Drag;