import { forwardRef, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import Column from './column';
import ColumnAdder from './addColumn';
import withDroppable from './withDroppable';
import { when, partialRight } from './services/utils';
import DefaultColumnHeader from './defaultColumnHeader';
import DefaultCard from './defaultCard';
import {
  getCard,
  getCoordinates,
  isAColumnMove,
  isMovingAColumnToAnotherPosition,
  isMovingACardToAnotherPosition
} from './services';
import { moveCard, moveColumn, addColumn, removeColumn, changeColumn, addCard, removeCard } from './services/helpers';

import styles from './kanban.module.scss';
const Columns = forwardRef((props, ref) => <div ref={ref} style={{ whiteSpace: 'nowrap' }} {...props} />);

const DroppableBoard = withDroppable(Columns);

function Board(props) {
  return props.initialBoard ? <UncontrolledBoard {...props} /> : <ControlledBoard {...props} />;
}

function UncontrolledBoard({
  initialBoard,
  onCardDragEnd,
  onColumnDragEnd,
  allowAddColumn,
  renderColumnAdder,
  onNewColumnConfirm,
  onColumnRemove,
  renderColumnHeader,
  allowRemoveColumn,
  allowRenameColumn,
  onColumnRename,
  onCardNew,
  renderCard,
  allowRemoveCard,
  onCardRemove,
  onColumnNew,
  disableCardDrag,
  disableColumnDrag,
  allowAddCard,
  setSubModel,
  onCellClick,
  onNewCardConfirm
}) {
  const [board, setBoard] = useState(initialBoard);
  const handleOnCardDragEnd = partialRight(handleOnDragEnd, { moveCallback: moveCard, notifyCallback: onCardDragEnd });
  const handleOnColumnDragEnd = partialRight(handleOnDragEnd, {
    moveCallback: moveColumn,
    notifyCallback: onColumnDragEnd
  });

  function handleOnDragEnd({ source, destination, subject }, { moveCallback, notifyCallback }) {
   
    if (!destination) return;

    const reorderedBoard = moveCallback(board, source, destination);
    setBoard(reorderedBoard);

    const isMovedToAnotherColumn = source.fromColumnId !== destination.toColumnId;


    const movedCard = getCard(board, source);
    if (!movedCard) {
      console.error('Moved card not found', { source, reorderedBoard });
      return;
    }

    if (isMovedToAnotherColumn && notifyCallback) {
      const newColumn = reorderedBoard.columns.find(column => column.id === destination.toColumnId);
        
      if (!newColumn) {
        console.error('New column not found', { destination });
        return;
      }
      console.log('newColumn', movedCard);
      notifyCallback({
        cardId: movedCard.ticketId,
        newColumnId: destination.toColumnId,
        newPosition: destination.toPosition,
        columnHeader: newColumn.label,
        currentCardData: movedCard
      });

      when(notifyCallback)((callback) => callback(reorderedBoard, subject, source, destination));
    }
  }




  function getCard(board, { fromColumnId, fromPosition }) {
    const column = board.columns.find(col => col.id === fromColumnId);
    return column ? column.cards[fromPosition] : null;
  }






  async function handleColumnAdd(newColumn) {
    const column = renderColumnAdder ? newColumn : await onNewColumnConfirm(newColumn);
    const boardWithNewColumn = addColumn(board, column);
    onColumnNew(boardWithNewColumn, column);
    setBoard(boardWithNewColumn);
  }

  function handleColumnRemove(column) {
    const filteredBoard = removeColumn(board, column);
    onColumnRemove(filteredBoard, column);
    setBoard(filteredBoard);
  }

  function handleColumnRename(column, title) {
    const boardWithRenamedColumn = changeColumn(board, column, { title });
    onColumnRename(boardWithRenamedColumn, { ...column, title });
    setBoard(boardWithRenamedColumn);
  }

  function handleCardAdd(column, card, options = {}) {
    const boardWithNewCard = addCard(board, column, card, options);

    onCardNew(
      boardWithNewCard,
      boardWithNewCard.columns.find(({ id }) => id === column.id),
      card
    );
    setBoard(boardWithNewCard);
  }

  async function handleDraftCardAdd(column, card, options = {}) {
    const newCard = await onNewCardConfirm(card);
    handleCardAdd(column, newCard, options);
  }

  function handleCardRemove(column, card) {
    const boardWithoutCard = removeCard(board, column, card);
    onCardRemove(
      boardWithoutCard,
      boardWithoutCard.columns.find(({ id }) => id === column.id),
      card
    );
    setBoard(boardWithoutCard);
  }

  return (
    <BoardContainer
      onCardDragEnd={handleOnCardDragEnd}
      onColumnDragEnd={handleOnColumnDragEnd}
      renderColumnAdder={() => {
        if (!allowAddColumn) return null;
        if (renderColumnAdder) return renderColumnAdder({ addColumn: handleColumnAdd });
        if (!onNewColumnConfirm) return null;
        return <ColumnAdder onConfirm={(title) => handleColumnAdd({ title, cards: [] })} />;
      }}
      {...(renderColumnHeader && {
        renderColumnHeader: (column) =>
          renderColumnHeader(column, {
            removeColumn: handleColumnRemove.bind(null, column),
            renameColumn: handleColumnRename.bind(null, column),
            addCard: handleCardAdd.bind(null, column)
          })
      })}
      renderCard={(column, card, dragging) => {
        if (renderCard) return renderCard(card, { removeCard: handleCardRemove.bind(null, column, card), dragging });
        return (
          <DefaultCard
            dragging={dragging}
            allowRemoveCard={allowRemoveCard}
            onCardRemove={(card) => handleCardRemove(column, card)}
            key={card.ticketId}
            setSubModel={setSubModel}
            onCellClick={onCellClick}
          >
            {card}
          </DefaultCard>
        );
      }}
      allowRemoveColumn={allowRemoveColumn}
      onColumnRemove={handleColumnRemove}
      allowRenameColumn={allowRenameColumn}
      onColumnRename={handleColumnRename}
      disableColumnDrag={disableColumnDrag}
      disableCardDrag={disableCardDrag}
      onCardNew={(column, card) => handleDraftCardAdd(column, card, allowAddCard)}
      allowAddCard={allowAddCard && onNewCardConfirm}
    >
      {board}
    </BoardContainer>
  );
}

function ControlledBoard({
  children: board,
  onCardDragEnd,
  onColumnDragEnd,
  allowAddColumn,
  renderColumnAdder,
  onNewColumnConfirm,
  onColumnRemove,
  renderColumnHeader,
  allowRemoveColumn,
  allowRenameColumn,
  onColumnRename,
  renderCard,
  allowRemoveCard,
  onCardRemove,
  disableCardDrag,
  setSubModel,
  onCellClick,
  disableColumnDrag
}) {
  const handleOnCardDragEnd = partialRight(handleOnDragEnd, { notifyCallback: onCardDragEnd });
  const handleOnColumnDragEnd = partialRight(handleOnDragEnd, { notifyCallback: onColumnDragEnd });

  function handleOnDragEnd({ source, destination, subject }, { notifyCallback }) {
    when(notifyCallback)((callback) => callback(subject, source, destination));
  }

  return (
    <BoardContainer
      onCardDragEnd={handleOnCardDragEnd}
      onColumnDragEnd={handleOnColumnDragEnd}
      renderColumnAdder={() => {
        if (!allowAddColumn) return null;
        if (renderColumnAdder) return renderColumnAdder();
        if (!onNewColumnConfirm) return null;
        return <ColumnAdder onConfirm={(title) => onNewColumnConfirm({ title, cards: [] })} />;
      }}
      {...(renderColumnHeader && { renderColumnHeader: renderColumnHeader })}
      renderCard={(_column, card, dragging) => {
        if (renderCard) return renderCard(card, { dragging });
        return (
          <DefaultCard dragging={dragging} allowRemoveCard={allowRemoveCard} onCardRemove={onCardRemove} onCellClick={onCellClick} key={card.ticketId} setSubModel={setSubModel}>
            {card}
          </DefaultCard>
        );
      }}
      allowRemoveColumn={allowRemoveColumn}
      onColumnRemove={onColumnRemove}
      allowRenameColumn={allowRenameColumn}
      onColumnRename={onColumnRename}
      disableColumnDrag={disableColumnDrag}
      disableCardDrag={disableCardDrag}
    >
      {board}
    </BoardContainer>
  );
}

function BoardContainer({
  children: board,
  renderCard,
  disableColumnDrag,
  disableCardDrag,
  renderColumnHeader,
  renderColumnAdder,
  allowRemoveColumn,
  onColumnRemove,
  allowRenameColumn,
  onColumnRename,
  onColumnDragEnd,
  onCardDragEnd,
  onCardNew,
  setSubModel,
  allowAddCard
}) {
  function handleOnDragEnd(event) {
    const coordinates = getCoordinates(event, board);
    if (!coordinates.source) return; // Early return if no source

  // Check if it's a column or card move and handle accordingly
    const isColumnMove = isAColumnMove(event.type);
    const isMovingColumn = isMovingAColumnToAnotherPosition(coordinates);
    const isMovingCard = isMovingACardToAnotherPosition(coordinates);
   
    if (isColumnMove && isMovingColumn) {
      onColumnDragEnd({
        ...coordinates,
        subject: board.columns[coordinates.source.fromPosition]
      });
    } else if (!isColumnMove && isMovingCard) {
      onCardDragEnd({
        ...coordinates,
        subject: getCard(board, coordinates.source)
      });
    }
  }

  const totalCards = board.columns.reduce((acc, column) => acc + column.cards.length, 0);

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <div style={{ overflowY: 'hidden', display: 'flex', alignItems: 'flex-start' }} className={styles.board}>
        <DroppableBoard style={{ gridAutoFlow: 'column', display: 'grid' }} droppableId='board-droppable' direction='horizontal' type='BOARD'>
          {board.columns.map((column, index) => (
            <Column
              key={column.id}
              index={index}
              renderCard={renderCard}
              renderColumnHeader={(column) =>
                renderColumnHeader ? (
                  renderColumnHeader(column)
                ) : (
                  <DefaultColumnHeader
                    totalCards={totalCards}
                    allowRemoveColumn={allowRemoveColumn}
                    onColumnRemove={onColumnRemove}
                    allowRenameColumn={allowRenameColumn}
                    onColumnRename={onColumnRename}
                  >
                    {column}
                  </DefaultColumnHeader>
                )
              }
              disableColumnDrag={disableColumnDrag}
              disableCardDrag={disableCardDrag}
              onCardNew={onCardNew}
              allowAddCard={allowAddCard}
            >
              {column}
            </Column>
          ))}
        </DroppableBoard>
        {renderColumnAdder()}
      </div>
    </DragDropContext>
  );

}

export default Board;