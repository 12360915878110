import React, { useEffect, useRef } from 'react';
import styles from './photo.module.scss';

const PhotoViewer = ({ originalWidth, originalHeight, width, height, texture }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const image = texture.image;
    
    // Ensure the image is loaded
    const handleImageLoad = () => {
      const imageDimensions = getImageDimensions(originalWidth, originalHeight);
      
      if (containerRef.current) {
        // image.style.width = `${imageDimensions.width}px`;
        // image.style.height = `${imageDimensions.height}px`;
        // image.setAttribute('class', 'photo');
        containerRef.current.appendChild(image);
      }
      if (0) {
        console.log('image', imageDimensions, originalWidth, originalHeight);
      }
    };
    
    if (image.complete) {
      handleImageLoad(); // if already loaded
    } else {
      image.onload = handleImageLoad; // wait for it to load
    }
    
    return () => {
      image.onload = null; // clean up the onload handler
    };
  }, [originalWidth, originalHeight, texture.image, width, height]);

  const getImageDimensions = (originalWidth, originalHeight) => {
    let imgHeight;
    let imgWidth;
    const { height: viewerHeight, width: viewerWidth } = { height, width };

    if (originalHeight <= viewerHeight && originalWidth <= viewerWidth) {
      imgWidth = originalWidth;
      imgHeight = originalHeight;
    } else {
      const heightRatio = viewerHeight / originalHeight;
      const widthRatio = viewerWidth / originalWidth;
      if (heightRatio < widthRatio) {
        imgHeight = originalHeight * heightRatio;
        imgWidth = originalWidth * heightRatio;
      } else {
        imgHeight = originalHeight * widthRatio;
        imgWidth = originalWidth * widthRatio;
      }
    }

    return { height: imgHeight, width: imgWidth };
  };

  return (
    <div className={`${styles.viewer} photo-viewer-container`} ref={containerRef} />
  );
};

export default PhotoViewer;
