import { KoText } from "packages/typography";
import styles from './ticketLink.module.scss';

function MultiTicketLinkStatic(props) {
  const { data } = props;
  
  return (
    <div className={styles.wrapper}>
      {data.map((item, index) => {
        return (
          <div  key={index}  className={styles.text}>
            <KoText ellipsis={true}  className={styles.text}  text={item.ticketId} />
          </div>
        );
      })}
    </div>
  );
}

export default MultiTicketLinkStatic;
