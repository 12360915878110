import { useAppStore } from "shared/newstore";
import React, { createContext, useContext, useEffect, useRef } from "react";
import { io } from "socket.io-client";
const SocketContext = createContext(null);

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
  const socket = useRef();
  const {
    selectedChatData,
    selectedChatType,
    addMessage,
    userInfo,
    addChannel,
    addContactInDMContacts,
    addChannelInChannelLists
  } = useAppStore();
  useEffect(() => {
    if (userInfo && userInfo?.userId === 'KIO') {
      socket.current = io(`${process.env.REACT_APP_PROXY_HOST}`, {
        withCredentials: true,
        extraHeaders: {
          authorization: `${userInfo?.accessToken}`
        },
        query: { userId: userInfo?.userId }
      });

      socket.current.on("connect", () => {
        console.log("Connected to socket server");
      });

      const handleReceiveMessage = (message) => {
        console.log('{ message }', message);
        if (selectedChatType !== undefined &&(selectedChatData?.userId === message.sender || selectedChatData.userId === message.recipient)) {
          addMessage(message);
        }
        addContactInDMContacts(message);
      };

      const handleReceiveChannelMessage = (message) => {
       

        if (
          selectedChatType !== undefined &&
          selectedChatData === message.channelId
        ) {
          addMessage(message);
        }
        addChannelInChannelLists(message);
      };

      const addNewChannel = (channel) => {
        addChannel(channel);
      };

      socket.current.on("receiveMessage", handleReceiveMessage);
      socket.current.on("recieve-channel-message", handleReceiveChannelMessage);
      socket.current.on("new-channel-added", addNewChannel);

      return () => {
        socket.current.disconnect();
      };
    }
  }, [userInfo]);

  return (
    <SocketContext.Provider value={socket.current}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
