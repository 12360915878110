import React, { useCallback, useEffect } from 'react';
import { KoFormBuilder, KoForm, KoTextArea, KoSelect, KoAvatar, KoText } from 'packages';
import styles from './curd.module.scss';
import { useSelector } from 'react-redux';

function KoFolderCrud({ form, handleFinish, initialValues }) {
  const users = useSelector(state => state?.users);
  const groups = useSelector(state => state?.groups);

  useEffect(() => {
    if (!initialValues || Object.keys(initialValues).length === 0) {
      form.resetFields();
    } else {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);


  const handleSubmit = useCallback(values => {
    handleFinish(values);
  });

  const Receivers = ({ value, onChange }) =>
    <KoSelect mode="multiple" value={value}
      onChange={v => onChange(v)}
      showSearch
      placeholder='Select assignee'
      options={users['data']?.map((user) => ({
        label: (
          <div className={'userDpWithName'}>
            <KoAvatar inLine={'0rem'} size={20} options={[{ name: user?.profile?.firstName, logo: user?.profile?.logo }]} />
            <KoText text={user.profile?.firstName +' '+ user.profile?.lastName} />
          </div>
        ),
        value: user?.userId
      }))}
    />;

  const Groups = ({ value, onChange }) =>
    <KoSelect mode="multiple" value={value}
      showSearch
      onChange={v => { onChange(v); }}
      placeholder='Select Group'
      options={groups['data']?.map((group) => ({
        label: group?.name,
        value: group?.groupId
      }))}
    />;

  const meta = {
    columns: 4,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      { key: 'name', placeholder: 'Enter folder name', label: 'Name', colSpan: 2, rules: [
        {
          required: true,
          message: `Enter new folder name.`
        }
      ] },
      {
        key: 'users', label: 'Receiver', colSpan: 2,
        widget: Receivers,
        forwardRef: true
      },
      {
        key: 'groups', label: 'Groups', colSpan: 2,
        widget: Groups,
        forwardRef: true
      },
      {
        key: 'description', widget: KoTextArea, label: 'Description', colSpan: 4, placeholder: 'Enter folder description'
      }
     
    ]
  };

  return (
    <div className={styles.wrapper}>
      <KoForm form={form} layout="vertical" onFinish={handleSubmit} onSubmit={handleSubmit} >
        <KoFormBuilder form={form} meta={meta} />
      </KoForm>
    </div>
  );
};

export default KoFolderCrud;