import { Input, Layout } from 'antd';
import _find from 'lodash/find';
import { Checkbox, KoBadge, KoBreadcrumb, KoButton, KoDropdown, KoForm, KoIcon, KoLoader, KoModal, KoNano, KoOTP, KoPagination, KoTable, KoText, KoTitle, KoToast, KoTooltips } from 'packages';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from "react-redux";
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { getTicketBreadCrumb } from 'shared/commonConstants';
import ReadonlyCurd from 'shared/components/tickettable/readonlyCurd';
import KoToolBar from 'shared/components/toolbar';
import {
  getDepartments,
  getDesignations,
  getGroups,
  getUsers,
  getWorkspace,
  setSelectedChatData,
  setSelectedChatType
} from "shared/store/slice";
import { deepMerge } from 'shared/utils';
import { deleteCookie, getCookie, setCookie } from 'shared/utils/cookies';
import { PRO_ITEMS_COLUMNS, TASKSTATUS } from './constant';
import TaskCrud from './crud';
import styles from './support.module.scss';
import { supportAPI } from './support.service';

const { Header } = Layout;
const { Search } = Input;

export default function SuportSystem() {
  const dispatch = useDispatch();
  const [otpmodal, setOtpModal] = useState(false);
  const [email, setEmail] = useState();
  const [otp, onChangeOtp] = useState();
  const [recent, setRecent] = useState();
  const [totalCount, setTotalCount] = useState();
  const [isLoading, setLoading] = useState(false);
  const [createTicketModal, setCreateTicketModal] = useState(false);
  const [tempToken, setTempToken] = useState();
  const { accountId, workspaceId } = useParams();
  const [openStatus, setOpenStatus] = useState(false);
  const [statusKeys, setStatusKeys] = useState([]);
  const [currentRecord, setCurrentRecord] = useState({
    issueType: 9,
    lookupId:KoNano('LOK')
  });
  const [copy, setCopy] = useState(false);
  const [breadCrumbs, setBreadCrumbs] = useState();
  const [pagination, setPagination] = useState({
    size: 20,
    page: 1
  });
  const [form] = KoForm.useForm();
  useEffect(() => {
    if (getCookie('tempToken')) {
      setTempToken(getCookie('tempToken'));
      getRecentTicket();
    }

  }, []);

  useEffect(() => {
    if (getCookie('userId')) {
      dispatch(getWorkspace());
      dispatch(getUsers());
      dispatch(getGroups());
      dispatch(getDesignations());
      dispatch(getDepartments());
    }
  }, []);




  const getRecentTicket = () => {
    setLoading(true);
    setRecent();
    setTotalCount();
    pagination['Authorization'] = 'TempToken ' + getCookie('tempToken');
    supportAPI.getRecentTicket(pagination).then(
      (data) => {
        setRecent(data['tickets']);
        if(currentRecord?.ticketId){
          const temp = _find(data['tickets'], { ticketId: currentRecord?.ticketId });
          setCurrentRecord(temp);
        }
        setTotalCount(data?.totalCount);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const onSearchText = (text) => {
    let q = pagination;
    q['search'] = text;
    getRecentTicket(q);
  };

  const onSort = (key, type) => {
    let q = pagination;
    q['sort'] = `${key}:${type}`;
    getRecentTicket(q);
  };

  const onPagination = (page, other) => {
    const query = {
      size: other,
      page: page
    };
    const p = Object.assign(pagination, query);
    setPagination(p);
    getRecentTicket(p);

  };


  const onEnterEmail = (email) => {
    setLoading(true);
    if (!email) {
      toast.error('Please enter email to search or create tickets.');
      setLoading(false);
      return;
    } else {
      var re = /\S+@\S+\.\S+/;
      let isValid = re.test(email);
      if (!isValid) {
        toast.error('Please enter correct email to search or create tickets.');
        setLoading(false);
        return;
      }
    }
    setEmail(email);
    let obj = {
      email: email
    };
    supportAPI.getValidateEmail(obj).then(
      (data) => {
        setLoading(false);
        setOtpModal(true);
        setTotalCount(data?.totalCount);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );

  };

  const handleFinish = () => {
    setLoading(true);
    let obj = {
      email: email,
      otp: otp,
      accountId,
      workspaceId
    };
    supportAPI.verifyOtp(obj).then(
      (data) => {
        setOtpModal(false);
        setLoading(false);
        setCookie('tempToken', data?.token);
        setTempToken(data?.token);
        getRecentTicket();
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const handleCreateTicket = (val, refresh) => {
    setLoading(true);
    setRecent();
    val['status'] =1;
    val['priority'] =3;
    supportAPI.createOrUpdate(val, currentRecord?.ticketId).then(
      (data) => {
        setLoading(false);
        if(!refresh){
          setCreateTicketModal(false);
          KoToast.success(`Ticket ${currentRecord?.ticketId ? 'updated':'created'} successfully`);
        }
        getRecentTicket();
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const handleLogout=()=>{
    deleteCookie('tempToken');
    window.location.reload();
  };

  const SearchInput = () => {
    return (
      <div
        key="SearchOutlined"
        className={styles.search}
      >
        <Search
          placeholder="Enter your email to search or create tickets."
          enterButton="Search"
          size="large"
          className={styles.searchBox}
          onSearch={onEnterEmail}
          variant={'borderless'}
        />
      </div>
    );
  };

  const onCellClick=(row)=>{
    if(!row.lookupId){
      row['lookupId'] = KoNano('LOK');;
    }
    supportAPI.getIssueByIdPublic(row.ticketId).then(
      (data) => {
        setCurrentRecord(data['issue']);
        setLoading(false);
        const updatedRoomObj = { ...data, source: 'tickets' };
        dispatch(setSelectedChatData(updatedRoomObj));
        dispatch(setSelectedChatType('tickets'));
        let crums = getTicketBreadCrumb(data['issue']);
        setBreadCrumbs(crums);
        setCreateTicketModal(true);
      },
      (err) => {
        setLoading(false);
      }
    );
   
  };

  const onValuesChange = (changedValues, refresh) => {
    const updatedCObj = deepMerge(currentRecord, changedValues);
    if(refresh){
      handleCreateTicket(updatedCObj, refresh);
    }
  };

  const onCopyTicket=(row)=>{
    setCopy(true);
    navigator.clipboard.writeText(`${window.location.origin}/open/ticket/${accountId}/${row?.workspace}/${row?.ticketId}?from=support`);
  };

  const columnsRight = [{
	  title: '',
	  dataIndex: 'x',
	  key: 'x',
	  fixed: 'right',
	  width: 40,
	  render: (text, row) => {
	    return <div className={` tableBtn columnsRight`} onClick={() => setCurrentRecord(row)}>
        <KoTooltips title={copy ? 'Copied' : 'Copy'}>
	      <KoButton onClick={()=>onCopyTicket(row)} className={'btn'} iconBtn={true} icon={<KoIcon width={14} height={14} name={'CopyOutlined'}/>}/>
        </KoTooltips>
        <KoTooltips title={'New window'}>
          <Link target="_blank" to={`/open/ticket/${accountId}/${workspaceId}/${row?.ticketId}?from=support`}>
            <KoButton className={'btn'}  iconBtn={true} icon={<KoIcon width={14} height={14} name={'OpenOutlined'}/>}/>
          </Link>
        </KoTooltips>
	    </div>;
	  }
  }];

  const onChangeStatus=(key)=>{
    let q =  pagination;
    if(key){
      q['status'] = key.toString();
    }else{
      delete q?.['status'];
    }
   
    getRecentTicket(q);
  };

  const handleOpenChangeStatus = (nextOpen, info) => {
    if (info.source === "trigger" || nextOpen) {
      setOpenStatus(nextOpen);
    }
  };

  const handleMenuClickStatus = (key, isChecked) => {
    let updatedStatusKeys;

    if (isChecked) {
      updatedStatusKeys = [...statusKeys, key];
    } else {
      updatedStatusKeys = statusKeys.filter((id) => id !== key);
    }

    updatedStatusKeys = Array.from(new Set(updatedStatusKeys));

    setStatusKeys(updatedStatusKeys);
    onChangeStatus(formatPayload(updatedStatusKeys));
  };

  const formatPayload = (statusKeys) => {
    if (statusKeys.includes(10)) {
      return [...statusKeys.filter(key => key !== 10), 10, 11];
    }
    return statusKeys;
  };

  const statusItems = TASKSTATUS?.map((sta) => ({
    label: (
      <div className={`${styles.filterList} ${statusKeys.includes(sta.key) ? styles.selected : ""}`}>
        <Checkbox
          checked={statusKeys.includes(sta.key)}
          onChange={(e) => handleMenuClickStatus(sta.key, e.target.checked)}>
          <div className={styles.userDpWithName}>
            {sta?.label}
          </div>
        </Checkbox>
      </div>
    ),
    key: sta?.key,
    value: sta?.value
  }));

  return (
    <div className={styles.wrapper}>
      {isLoading && <KoLoader />}
      <div className={styles.content}>
        <Header className={styles.shallowHeader}>
        </Header>
        <Header className={`${styles.header}`}>
          <div className={styles.topNavHeader}>
            <div className={styles.topNavHeaderMain}>
              <div className={styles.topNavHeaderMainLeft}>
                <div className={styles.logo}>
                  <KoIcon name='AppLogoFilled' width={115} height={25} />
                </div>
              </div>
              {tempToken && <div className={styles.logout}>
                <KoButton key='create' type='primary' label={`Logout`}
                  onClick={() => { handleLogout(); }} />
              </div>}
            </div>
          </div>
        </Header>

        <div className={styles.searchWrapper}>
          <KoText className={styles.workflow} text={`Collaboration Unified, Workflow Simplified`} />
          <KoTitle text={'How can we help you today?'} level={1} />
          <KoText className={styles.smallText} text={`Every team has a unique process. Use a out-of-boss workflow, or create one to match the way team works `} />
          {!tempToken && <SearchInput />}
        </div>
        {tempToken && <div className={`${styles.tableSection} tableCard`}>
          <KoToolBar
            className={styles.toolbar}
            title={'Recent works'}
            search={{ visible: true, onChange: onSearchText, placeholder: 'Search by ticket id / summary' }}
            filter={{
              visible: false,
              options: [{ label: 'Sort By Name', key: '0' },
                { label: 'Created At', key: '2' },
                { label: 'Sort By designation', key: '3' }]
            }}
            extraComponentAfter={
              <KoDropdown
                onOpenChange={handleOpenChangeStatus}
                open={openStatus}
                placement="bottomRight"
                menu={{items: statusItems}}>
                <div>
                  <KoBadge  count={statusKeys?.length}>
                    <KoTooltips title="Filter by status">
                      <KoButton iconBtn={true} icon={<KoIcon name="StatusFilled" />} />
                    </KoTooltips>
                  </KoBadge>
                </div>
              </KoDropdown>
            }
            buttonList={[{
              label: 'Create',
              type: 'primary',
              icon: <KoIcon color="#FFF" name="PlusOutlined" width={18} height={18} />,
              onClick: () => {setCreateTicketModal(true); setBreadCrumbs([]); setCurrentRecord();}
            }]} />
          <div className={styles.reacentTable}>
            <KoTable rowKey='ticketId'
              onCellClick={onCellClick}
              onSort={onSort}
              data={{ columns: PRO_ITEMS_COLUMNS, rows: recent }}
              extraColumnsRight={columnsRight}
              maxHeight={60} />
            <KoPagination current={parseInt(pagination.page) || 1}
              total={totalCount}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} records`
              }
              pageSize={pagination.limit}
              defaultCurrent={pagination.page}
              onChange={onPagination}
              showQuickJumper={false}
              simple={false}
              responsive={false} />
          </div>
        </div>}
      </div>

      <KoModal
        open={otpmodal}
        title='Verify Your Email'
        onClose={() => { setOtpModal(false); }}
        centered
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setOtpModal(false)} />,
          <KoButton key='create' type='primary' label={`Proceed`}
            onClick={() => { handleFinish(); }} />
        ]}
      >
        <div className={styles.modalWrapper}>
          <KoText text={`We've sent a four-digit code to ${email}. 
                    Please enter the code below to confirm your email address If you haven't received it,`} className={`${styles.smallOtp}`} />
          <KoText onClick={() => { onEnterEmail(email); }} text={` Resend`} className={`${styles.link}`} />
          <div className={styles.otpWrapper}>
            <KoOTP onChangeOtp={onChangeOtp} numInputs={4} />
          </div>
        </div>
      </KoModal>

      <KoModal
        open={createTicketModal}
        title={
          breadCrumbs?.length > 0
            ? <KoBreadcrumb items={breadCrumbs} />
            : (currentRecord?.ticketId || currentRecord?.ticketId)
              ? `Update Issue ${(currentRecord?.ticketId || currentRecord?.ticketId).toUpperCase()}`
              : `Create Issue`}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCreateTicketModal(false); setCurrentRecord(); form.resetFields(); }}
        onClose={() => { setCreateTicketModal(false); setCurrentRecord(); form.resetFields(); }}
        centered
        width={'95vw'}
        height={'95vh'}
        footer={[
          <KoButton key='cancel' label='Close' onClick={() => {setCreateTicketModal(false); setCurrentRecord(); form.resetFields();}} />,
          (!currentRecord?.ticketId) &&
          <KoButton key='create' type='primary' label={ currentRecord?.ticketId ? 'Update' :`Create`}
            onClick={() =>form.submit()} />
        ]}
      >
        {currentRecord?.ticketId ? <ReadonlyCurd issue={currentRecord}/> : <div className={styles.modalWrapper}>
          <TaskCrud onValuesChange={onValuesChange} form={form} handleFinish={handleCreateTicket} initialValues={currentRecord}/>
        </div>  }
      
      </KoModal>
    </div>
  );
}
