import React from "react";
import isString from 'lodash/isString';
import { useSelector } from "react-redux";
import styles from './groups.module.scss';

function KoGroups(props) {
  const groups = useSelector(state => state?.groups);
  let matchedRecords = groups['data'].filter(record => props.data?.includes(record.groupId));
  let filteredObjects = matchedRecords.map(({ name, groupId, logo }) => ({ name, groupId, logo }));
  if(isString(props?.data)){
    matchedRecords = groups['data'].filter(record => [props.data]?.includes(record.groupId));
    filteredObjects = matchedRecords.map(({ name, groupId, logo }) => ({ name, groupId, logo }));
  }
  return (
    <div className={styles.groupsWrapper}>
      {filteredObjects.map((group)=>
        <div key={group.groupId} className={`${styles.status} ${styles[group?.name? group?.name?.charAt(0).toLowerCase() :'a']}`}>
          <div className={styles.text}>{group?.name}</div>
        </div>
      )}
    </div>
  );
}
export default KoGroups;
