import { useState } from 'react';
import CardForm from './cardForm';
import KoButton from 'packages/button';

export default function CardAdder({ column, onConfirm }) {
  function confirmCard(card) {
    onConfirm(column, card);
    setAddingCard(false);
  }

  const [addingCard, setAddingCard] = useState(false);

  return (
    <>
      {addingCard ? (
        <CardForm onConfirm={confirmCard} onCancel={() => setAddingCard(false)} />
      ) : (
        <KoButton iconBtn={true} icon={'PlusOutlined'}  onClick={() => setAddingCard(!addingCard)}/>
       
      )}
    </>
  );
}
