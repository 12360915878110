import React from "react";
function Download(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 13.283 12.466">
      <path data-name="Path 30364" d="M10.634 3.316a4.12 4.12 0 0 0-8.08 0 2.884 2.884 0 0 0 .332 5.749h2.06a.412.412 0 1 0 0-.824H2.885a2.06 2.06 0 1 1 .024-4.12.412.412 0 0 0 .409-.367 3.3 3.3 0 0 1 6.548 0 .437.437 0 0 0 .434.367 2.06 2.06 0 1 1 0 4.12H8.241a.412.412 0 1 0 0 .824H10.3a2.884 2.884 0 0 0 .332-5.746zm0 0" transform="translate(.066 .057)" />
      <path data-name="Path 30365" d="m175.844 212.724-1.044 1.045v-5.185a.412.412 0 1 0-.824 0v5.185l-1.044-1.045a.412.412 0 0 0-.593.572l.01.01 1.747 1.748a.414.414 0 0 0 .133.089.407.407 0 0 0 .317 0 .413.413 0 0 0 .133-.089l1.747-1.748a.412.412 0 1 0-.572-.593zm0 0" transform="translate(-167.729 -202.761)" />
    </svg>
  );
}


export default Download;