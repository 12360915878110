import { KoForm, KoFormBuilder } from 'packages';
import { useCallback } from 'react';
import styles from './about.module.scss';

function OtherCrud({form, handleFinish, initialValues}) {

  const handleSubmit = useCallback(values => {
    const updatedValues = {
      others: { ...values.others}
    };
    handleFinish(updatedValues);
  });


  const meta = {
    columns: 4,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      {
        key: 'others.expensePolicy', label: 'Expense Policy',  placeholder: 'Enter Expense Policy', colSpan: 2
      }, {
        key: 'others.loanPolicy',label: 'Loan Policy', placeholder: 'Enter Loan Policy', colSpan: 2
      },
      {
        key: 'others.timesheetPolicy', label: 'TimeSheet Policy', placeholder: 'Enter TimeSheet Policy', colSpan: 2
      }
    ]
  };
  return (
    <div className={styles.crudWrapper}>
      <KoForm form={form} layout="vertical" onFinish={handleSubmit} onSubmit={handleSubmit}>
        <KoFormBuilder form={form} meta={meta} />
      </KoForm>
    </div>
  );
};

export default OtherCrud;