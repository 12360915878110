import React from "react";
function Preview(props) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      width={width}
      height={height}
      fill="#FFF"
    >
      <path fill="#000" d="M0 0L1 0" />
      <path fill="#000" d="M20 19L20 20" />
      <path
        d="M10 3C4 3 0 10 0 10s4 7 10 7 10-7 10-7-4-7-10-7zm0 12a5 5 0 110-10 5 5 0 010 10z"
      />
      <path
        d="M8 9a1 1 0 00.712-1.7C9.104 7.115 9.537 7 10 7a3 3 0 11-3 3c0-.463.114-.896.3-1.288.181.177.427.288.7.288z"
      />
    </svg>
  );
}


export default Preview;