const ORG_STATISTICS = [{
  color: '--palette-gray-10',
  backgroud: '--active',
  title: 'Active Projects',
  icon: 'AssigneeTwoTone',
  value: 10
}, {
  color: '--palette-gray-10',
  backgroud: '--active',
  title: 'Tasks Assigned',
  icon: 'AssigneeTwoTone',
  value: 120
}, {
  color: '--palette-gray-10',
  backgroud: '--active',
  title: 'Issues Raised',
  icon: 'AssigneeTwoTone',
  value: 103
}, {
  color: '--palette-gray-10',
  backgroud: '--active',
  title: 'Today Meeting',
  icon: 'AssigneeTwoTone',
  value: 110
}, {
  color: '--palette-gray-10',
  backgroud: '--active',
  title: 'Status / Progress',
  icon: 'AssigneeTwoTone',
  value: 10
}, {
  color: '--palette-gray-10',
  backgroud: '--active',
  title: 'In Review',
  icon: 'AssigneeTwoTone',
  value: 120
}];


const ITEM_STATISTICS = [{
  color: '--palette-gray-10',
  title: 'Days Remaining',
  icon: 'DaysReminingTwoTone',
  backgroud: '--active',
  value: 10
}, {
  color: '--palette-gray-10',
  title: 'Tasks Assigned',
  icon: 'AssignedTaskTwoTone',
  value: 120,
  backgroud: '--active'
}, {
  color: '--palette-gray-10',
  title: 'Issues Raised',
  icon: 'IssueRisedTwoTone',
  backgroud: '--active',
  value: 103
}, {
  color: '--palette-gray-10',
  title: 'Team assignee',
  icon: 'AssigneeTwoTone',
  value: 110,
  backgroud: '--active'
}, {
  color: '--palette-gray-10',
  title: 'Today Meeting',
  icon: 'MeetingsTwoTone',
  value: 10,
  backgroud: '--active'
}, {
  color: '--palette-gray-10',
  title: 'Wishlist',
  icon: 'WishListTwoTone',
  value: 120,
  backgroud: '--active'
}];

const PROJECT_LIST = [{
  name: 'qubernet, inc.',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint: 2,
  id: 'Pro_121212',
  tags: ['Development', 'design', 'IT', 'Finance', 'Devops', 'Demo']
}, {
  name: 'Kwapio, inc.',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint: 5,
  id: 'Pro_1212122',
  tags: ['Development', 'Demo']
}, {
  name: 'tonelix',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  id: 'Pro_1212123',
  sprint: 7,
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint: 2,
  id: 'Pro_121212',
  tags: ['Development', 'design', 'IT', 'Finance', 'Devops', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint: 5,
  id: 'Pro_1212122',
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  id: 'Pro_1212123',
  sprint: 7,
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint: 5,
  id: 'Pro_12121234',
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint: 6,
  id: 'Pro_12121235',
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint: 4,
  id: 'Pro_12121236',
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  sprint: 9,
  progress: 80,
  id: 'Pro_121212',
  tags: ['Development', 'design', 'IT', 'Finance', 'Devops', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint: 10,
  id: 'Pro_1212122',
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  sprint: 7,
  progress: 80,
  id: 'Pro_1212123',
  tags: ['Development', 'Demo']
}];

const PRO_ITEMS_COLUMNS = [
  {
    name: "ticketId",
    id: "ticketId",
    label: "Ticket Id",
    width:130,
    widget: "ticketLink",
    fixed: true
  },

  {
    name: "summary",
    id: "summary",
    label: "Summary",
    width:300,
    widget: "task"
  },
  {
    name: "status",
    id: "status",
    label: "Status",
    widget: "taskstatus",
    isLog:true,
    width:150
  },
  {
    name: "priority",
    id: "priority",
    label: "Priority",
    widget: "priority",
    width:130
  },
  {
    name: "assignee",
    id: "assignee",
    label: "Assignee",
    widget: "assignees"
  },
  // {
  //   name: "attachments",
  //   id: "attachments",
  //   label: "Attachments",
  //   widget: "attachments"
  // },
  {
    name: "reporter",
    id: "reporter",
    label: "Reporter",
    widget: "assignees"
  }];

const PRO_ITEMS_SUB_COLUMNS = [
  {
    name: "ticketId",
    id: "ticketId",
    label: "Ticket Id",
    width:130,
    widget: "ticketLink",
    fixed: true
  },
  
  {
    name: "summary",
    id: "summary",
    label: "Summary",
    width:300,
    widget: "task"
  },
  {
    name: "status",
    id: "status",
    label: "Status",
    widget: "taskstatus",
    isLog:true,
    width:150
  },
  {
    name: "assignee",
    id: "assignee",
    label: "Assignee",
    widget: "assignees"
  }];

const SPR_ITEMS_COLUMNS = [{
  name: "name",
  id: "name",
  label: "Name",
  widget: "link",
  fixed: true
}, {
  name: "description",
  id: "description",
  label: "Description",
  widget: "Description"
}, {
  name: "status",
  id: "status",
  label: "Status",
  widget: "sprintstatus"
},
{
  name: "startDate",
  id: "startDate",
  label: "Start Date",
  widget: "date",
  format:'DD-MMM-YYYY'
}, {
  name: "endDate",
  id: "endDate",
  label: "End Date",
  daysAgo:0,
  widget: "date",
  format:'DD-MMM-YYYY'
}];

const PRO_TABS = [
  {
    label: 'Dashboard',
    key: "dashboard"
  },
  // {
  //   label: 'Scrum',
  //   key: "scrum"
  // },
  {
    label: 'Sprints',
    key: "sprints"
  },
  {
    label: 'Tickets',
    key: "tickets"

  }, {
    label: 'Release',
    key: "release"
  }
  // {
  //   label: 'Build',
  //   key: "build"
  // }
];

const WORKFLOW = [{
  label: 'To Do',
  key: "todo"

}, {
  label: 'In Progress',
  key: "inprogress"
}, {
  label: 'Ready for QA',
  key: "invalidate"
},
{
  label: 'In Review',
  key: "inreview"
},
{
  label: 'Done',
  key: "done"
},
{
  label: 'Parking Lot',
  key: "parkinglot"
}];

const SPRINT = [{
  epic: 'Emandate documents Task -1',
  epicId: 'QD25',
  items: []
}, {
  epic: 'User Experience design #9201',
  epicId: 'QD26',
  items: []
}, {
  epic: 'UI components and guidelines',
  epicId: 'QD27',
  items: []
}, {
  epic: 'Mind map - 3rd sector',
  epicId: 'QD28',
  items: []
}, {
  epic: 'missingComponents and assets',
  epicId: 'QD29',
  items: []
}, {
  epic: 'olam sector - Nigeria',
  epicId: 'QD30',
  items: []
}];

const PR_ITEMS_COLUMNS = [
  {
    name: "ticketId",
    id: "ticketId",
    label: "Ticket Id's",
    width:130,
    widget: "multiTicketLink",
    fixed: true
  },{
    name: "name",
    id: "name",
    label: "Name",
    widget: "text"
  }, {
    name: "pullRequest",
    id: "pullRequest",
    label: "Pull Request Url",
    widget: "link"
  }, {
    name: "status",
    id: "status",
    label: "Status",
    widget: "releasestatus"
  },

  {
    name: "prOwner",
    id: "prOwner",
    label: "Owner",
    widget: "assignees"
  },
  {
    name: "requestApproval",
    id: "requestApproval",
    label: "Request Approval",
    widget: "assignees"
  },
  {
    name: "reviewPerson",
    id: "reviewPerson",
    label: "Reviewer",
    widget: "assignees"
  },
  {
    name: "startDate",
    id: "startDate",
    label: "Start Date",
    widget: "date",
    format:'DD-MMM-YYYY'
  }, {
    name: "endDate",
    id: "endDate",
    label: "End Date",
    daysAgo:0,
    widget: "date",
    format:'DD-MMM-YYYY'
  }];

export {
  ORG_STATISTICS, PRO_ITEMS_SUB_COLUMNS, WORKFLOW, SPRINT, PRO_TABS, PRO_ITEMS_COLUMNS,
  SPR_ITEMS_COLUMNS, PROJECT_LIST, ITEM_STATISTICS, PR_ITEMS_COLUMNS
};