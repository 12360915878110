// import { graphic } from 'echarts';
const COLUMN = [
  {
    name: "eventName",
    id: "eventName",
    label: "Event",
    widget: "text",
    fixed:'left',
    width:100
  },
  {
    name: "host",
    id: "eventName",
    label: "Host",
    widget: "text",
    fixed:'left',
    width:100
  },
  {
    name: "client",
    id: "client",
    label: "Attendees",
    width:100,
    widget: "text"
  },
  {
    name: "client",
    id: "client",
    label: "Email",
    width:100,
    widget: "text"
  },
  {
    name: "client",
    id: "client",
    label: "TimeZone",
    width:100,
    widget: "text"
  },

  {
    name: "amount",
    id: "amount",
    label: "Slots",
    width:100,
    widget: "text"
  },
  {
    name: "status",
    id: "status",
    width:80,
    label: "Status",
    widget: "invoicestatus",
    fixed:'right'
  }
];


export {
  COLUMN
};