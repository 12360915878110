import React from "react";
function Announcements(props) {
  const { width, height } = props;
  return (

    <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 66 66" viewBox="0 0 66 66" width={width} height={height}>
      <path
        d="M45.48 20.14c-3.22-5.58-6.69-10.59-9.76-14.1-3.44-3.93-5.84-5.43-7.35-4.56-1.5.87-1.41 3.69.27 8.64 1.5 4.41 4.11 9.92 7.33 15.5 3.22 5.58 6.69 10.59 9.76 14.1 2.81 3.21 4.93 4.8 6.43 4.8.34 0 .64-.08.92-.24 1.5-.87 1.41-3.69-.27-8.64C51.31 31.23 48.71 25.73 45.48 20.14zM34.42 19.61c2.73-.96 5.83.13 7.32 2.7 1.48 2.57.88 5.8-1.32 7.68-1.05-1.63-2.1-3.34-3.13-5.11C36.26 23.1 35.3 21.33 34.42 19.61zM52.34 42.98c-.32.19-1.94-.21-5.47-4.25-1.78-2.03-3.7-4.59-5.62-7.48 2.86-2.33 3.67-6.45 1.79-9.7-1.88-3.26-5.85-4.62-9.3-3.3-1.53-3.11-2.79-6.05-3.67-8.61-1.73-5.08-1.27-6.67-.94-6.86.04-.02.1-.04.18-.04.57 0 2.19.74 5.29 4.28 3.01 3.44 6.41 8.36 9.59 13.86 3.18 5.5 5.74 10.91 7.21 15.23C53.13 41.2 52.66 42.8 52.34 42.98zM51.51 17.13c.14.24.39.38.65.38.13 0 .26-.03.37-.1l8.08-4.67c.36-.21.48-.67.27-1.02-.21-.36-.66-.48-1.02-.27l-8.08 4.67C51.42 16.32 51.3 16.77 51.51 17.13zM63.98 24.28l-9.32.41c-.41.02-.73.37-.72.78.02.4.35.72.75.72.01 0 .02 0 .03 0l9.32-.41c.41-.02.73-.37.72-.78C64.74 24.58 64.39 24.26 63.98 24.28zM45.51 10.86c.13.08.26.12.4.12.25 0 .49-.12.63-.35l5.02-7.87c.22-.35.12-.81-.23-1.04-.35-.22-.81-.12-1.04.23l-5.02 7.87C45.05 10.17 45.16 10.64 45.51 10.86z"
        fill="#000000" className="color000 svgShape"></path>
      <path d="M47.94,44.18c-2.06-0.02-4.48-0.52-6.81-1.01c-1.1-0.23-2.2-0.46-3.24-0.63c-6.22-1.35-11.49-1.21-15.69,0.41l-4.06-7.03
		l-4.06-7.03c3.51-2.82,6.26-7.32,8.19-13.35c0.38-1.02,0.73-2.08,1.08-3.15c0.75-2.27,1.52-4.62,2.54-6.41
		c0.2-0.36,0.08-0.82-0.28-1.02c-0.36-0.2-0.82-0.08-1.02,0.28c-1.09,1.92-1.89,4.34-2.66,6.68c-0.35,1.05-0.69,2.1-1.07,3.13
		c-1.93,6.05-4.68,10.43-8.17,13.02L6,31.95c-2.2,1.27-3.78,3.32-4.44,5.78s-0.32,5.02,0.95,7.22c1.76,3.05,4.97,4.76,8.26,4.76
		c1.61,0,3.25-0.41,4.74-1.27l6.68-3.86c3.99-1.73,9.16-1.92,15.4-0.56c1.06,0.18,2.14,0.41,3.22,0.63c2.41,0.5,4.9,1.02,7.11,1.04
		c0,0,0,0,0.01,0c0.41,0,0.75-0.33,0.75-0.74C48.69,44.53,48.35,44.19,47.94,44.18z M14.77,47.14c-3.83,2.21-8.74,0.89-10.95-2.94
		c-1.07-1.85-1.36-4.02-0.8-6.08c0.55-2.07,1.88-3.8,3.74-4.87l6.08-3.51l4.01,6.94l4.01,6.94L14.77,47.14z"
      fill="#000000" className="color000 svgShape"></path>
      <path d="M22.49,47.23c-0.11-0.4-0.52-0.64-0.92-0.53c-0.4,0.1-0.64,0.52-0.54,0.92c0.97,3.67,2.81,7.09,5.33,9.88
		c0.68,0.76,0.98,1.77,0.82,2.78c-0.16,1.03-0.77,1.92-1.68,2.44l-0.11,0.06c-1.3,0.75-2.91,0.58-4.01-0.43
		c-3.22-2.95-5.48-6.79-6.54-11.08c-0.1-0.4-0.5-0.65-0.91-0.55c-0.4,0.1-0.65,0.51-0.55,0.91c1.13,4.58,3.55,8.68,6.98,11.83
		c0.93,0.85,2.1,1.29,3.3,1.29c0.85,0,1.7-0.22,2.48-0.67l-0.38-0.65l0.48,0.59c1.3-0.75,2.18-2.03,2.41-3.51
		c0.23-1.46-0.2-2.93-1.19-4.02C25.12,53.88,23.4,50.67,22.49,47.23z" fill="#000000" className="color000 svgShape"></path>
    </svg>
  );
}
export default Announcements;