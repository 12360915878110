import { KoDate, KoForm, KoFormBuilder, KoSelect } from 'packages';
import styles from './about.module.scss';
import { useCallback } from 'react';

function Crud({form, handleFinish, initialValues}) {

  const handleSubmit = useCallback(values => {
    const updatedValues = {
      jobDetails: { ...values.jobDetails}
    };
    handleFinish(updatedValues);
  });

  const Date = ({ value, onChange }) =>
    <KoDate value={value} format = {'DD-MMM-YYYY'}
      onChange={v => onChange(v)} />;


  const PayGrade = ({ value, onChange }) => (
    <KoSelect
      value={value}
      onChange={(v) => onChange(v)}
      placeholder="Select Pay Grade"
      options={[
        { label: "Junior", value: "junior" },
        { label: "Mid-Level", value: "midLevel" },
        { label: "Senior", value: "senior" },
        { label: "Lead", value: "lead" },
        { label: "Manager", value: "manager" },
        { label: "Director", value: "director" }
      ]}
    />
  );

  const PayBand = ({ value, onChange }) => (
    <KoSelect
      value={value}
      onChange={(v) => onChange(v)}
      placeholder="Select Pay Band"
      options={[
        { label: "A", value: "A" },
        { label: "B", value: "B" },
        { label: "C", value: "C" },
        { label: "D", value: "D" },
        { label: "E", value: "E" },
        { label: "F", value: "F" }
      ]}
    />
  );

  const ContractType = ({ value, onChange }) => (
    <KoSelect
      value={value}
      onChange={(v) => onChange(v)}
      placeholder="Select Contract Type"
      options={[
        { label: "Permanent", value: "permanent" },
        { label: "Temporary", value: "temporary" },
        { label: "Contract", value: "contract" }
      ]}
    />
  );

  const TimeType = ({ value, onChange }) => (
    <KoSelect
      value={value}
      onChange={(v) => onChange(v)}
      placeholder="Select Time Type"
      options={[
        { label: "Full Time", value: "fullTime" },
        { label: "Part Time", value: "partTime" }
      ]}
    />
  );

  const WorkerType = ({ value, onChange }) => (
    <KoSelect
      value={value}
      onChange={(v) => onChange(v)}
      placeholder="Select Worker Type"
      defaultValue="no"
      options={[
        { label: "Permanent", value: "permanent" },
        { label: "Temporary", value: "temporary" },
        { label: "Contract", value: "contract" }
      ]}
    />
  );


  const meta = {
    columns: 4,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      {
        key: 'jobDetails.employeeId', label: 'Employee ID',  placeholder: 'Enter Employee ID', colSpan: 2
      }, {
        key: 'jobDetails.dateOfJoin',label: 'Date of Join', placeholder: 'Enter Date of Join', colSpan: 2, widget: Date
      },
      {
        key: 'jobDetails.jobTitlePrimary', label: 'Job Title - Primary', placeholder: 'Enter Job Title - Primary', colSpan: 2
      }, {
        key: 'jobDetails.jobTitleSecondary', label: 'Job Title - Secondary', placeholder: 'Enter Job Title - Secondary', colSpan: 2
      },
      {
        key: 'jobDetails.InProbationPeriodFrom',label: 'In Probation Period From', placeholder: 'Enter Date of Join', colSpan: 2, widget: Date
      },
      {
        key: 'jobDetails.InProbationPeriodTo',label: 'In Probation Period To', placeholder: 'Enter Date of Join', colSpan: 2, widget: Date
      },
      {
        key: 'jobDetails.noticePeriod',label: 'Notice Period (Days)', widget: "number", placeholder: 'Enter Notice Period', colSpan: 2
      }, {
        key: 'jobDetails.workerType',label: 'Worker Type', placeholder: 'Enter Worker Type', colSpan: 2, widget: WorkerType
      },
      {
        key: 'jobDetails.timeType',label: 'Time Type', placeholder: 'Enter Time Type', colSpan: 2, widget: TimeType
      },
      {
        key: 'jobDetails.contractType',label: 'Contract Type', placeholder: 'Enter Contract Type', colSpan: 2, widget: ContractType
      },
      {
        key: 'jobDetails.payBand',label: 'Pay Band', placeholder: 'Select Pay Band', colSpan: 2, widget: PayBand
      },
      {
        key: 'jobDetails.payGrade',label: 'Pay Grade', placeholder: 'Select Pay Grade', colSpan: 2, widget: PayGrade
      }
    ]
  };
  return (
    <div className={styles.crudWrapper}>
      <KoForm form={form} layout="vertical" onFinish={handleSubmit} onSubmit={handleSubmit}>
        <KoFormBuilder form={form} meta={meta} />
      </KoForm>
    </div>
  );
};

export default Crud;