import KoTag from 'packages/tag';
import styles from './eventslot.module.scss';
import KoIcon from 'packages/icon';
import dayjs from 'dayjs';

function EventSlot(props) {
  let { data } = props;
 
  const startTime = data.match(/start:(\d{2}:\d{2})/)?.[1];
  const formattedStartTime = startTime ? dayjs(startTime, "HH:mm").format("hh:mm A") : "";
  return (
    <div className={styles.slots}>
      <KoTag
        className={`${styles.tag}`}
        value={formattedStartTime}
        closable={false}
        key={data}
        icon={<KoIcon name={'LogOutlined'} width={14} height={14} />}
      />
    </div>

  );
}
export default EventSlot;
