import React from "react";
function Inactiveusers(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icons/Inactive" clipPath="url(#clip0_110_47)">
        <path id="Vector" d="M14.276 6.638L15.9713 8.33334L15.0287 9.276L13.3333 7.58067L11.638 9.276L10.6953 8.33334L12.3907 6.638L10.6953 4.94267L11.638 4L13.3333 5.69534L15.0287 4L15.9713 4.94267L14.276 6.638ZM6 8C6.79112 8 7.56448 7.76541 8.22228 7.32588C8.88008 6.88635 9.39277 6.26164 9.69552 5.53074C9.99827 4.79983 10.0775 3.99556 9.92314 3.21964C9.7688 2.44372 9.38784 1.73098 8.82843 1.17157C8.26902 0.612165 7.55628 0.231202 6.78036 0.0768607C6.00444 -0.0774802 5.20017 0.00173314 4.46927 0.304484C3.73836 0.607234 3.11365 1.11992 2.67412 1.77772C2.2346 2.43552 2 3.20888 2 4C2 5.06087 2.42143 6.07828 3.17157 6.82843C3.92172 7.57857 4.93913 8 6 8ZM8.69533 9.33334H3.30467C2.42854 9.33439 1.5886 9.6829 0.969084 10.3024C0.349568 10.9219 0.00105855 11.7619 0 12.638L0 16H12V12.638C11.9989 11.7619 11.6504 10.9219 11.0309 10.3024C10.4114 9.6829 9.57146 9.33439 8.69533 9.33334Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_110_47">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}


export default Inactiveusers;