import React from "react";
function Department(props) {
  const { width, height } = props;
  return(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width={width}
      height={height}
      {...props}
    >
      <path
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeWidth={3}
        d="M36.85 8.82a4.33 4.33 0 11-4.33-4.32 4.32 4.32 0 014.33 4.32zM25.3 28.18c.5-4.48 2.25-9.48 7.22-9.48s6.64 4.46 7.18 9.22M17.45 40.14a4.32 4.32 0 11-4.32-4.32 4.32 4.32 0 014.32 4.32zM5.9 59.5C6.4 55 8.15 50 13.13 50s6.63 4.46 7.17 9.22m34.94-19.08a4.32 4.32 0 11-4.32-4.32 4.31 4.31 0 014.32 4.32zM43.7 59.5C44.19 55 46 50 50.92 50s6.64 4.46 7.18 9.22M32.5 30v13m-.25-1.04l-10.92 6.46m10.74-6.46l10.91 6.46"
        className="colorStroke000 svgStroke"
      />
    </svg>);
}
export default Department;