import React, { useState } from 'react';

import { Country, State, City } from 'country-state-city';
import { KoFormBuilder, KoSelect, KoNumber } from 'packages';
import styles from './curd.module.scss';

function AddressCrud({ form, handleFinish, settings }) {


  const [countriesList] = useState(Country.getAllCountries());
  const [stateList, setStateList] = useState();
  const [cityList, setCityList] = useState();


  const optionGroups = [];
  for (let i = 10; i < 36; i++) {
    optionGroups.push({
      label: i.toString(36) + i,
      value: i.toString(36) + i
    });
  }

  const CountryD = ({ value, onChange }) =>

    <KoSelect showSearch value={value}
      onChange={(v) => {
        onChange(v);
        setStateList(State.getStatesOfCountry(v));
      }}
      placeholder={'Select Country'}
      options={countriesList?.map((country) => ({
        label: country?.name,
        value: country?.isoCode
      }))} />;


  const StateD = ({ value, onChange }) =>

    <KoSelect showSearch value={value}
      onChange={(v) => {
        onChange(v);
        setCityList(City.getCitiesOfState(form.getFieldValue('address')?.country, v));
      }}
      disabled={!form.getFieldValue('address')?.country}
      placeholder={'Select State'}
      options={stateList?.map((state) => ({
        label: state?.name,
        value: state.isoCode
      }))} />;

  const CityD = ({ value, onChange }) =>

    <KoSelect showSearch value={value}
      onChange={(v) => {
        onChange(v);
      }}
      disabled={!form.getFieldValue('address')?.state}
      placeholder={'Select City'}
      options={cityList?.map((cit, index) => ({
        label: cit?.name,
        value: cit?.name
      }))} />;

  const meta = {
    columns: 4,
    formItemLayout: null,
    initialValues: settings,
    colon: true,
    fields: [
      {
        key: 'address.addressLine1', label: 'Address Line 1', placeholder: 'Enter Address Line 1', colSpan: 2, rules: [
          {
            required: true,
            message: 'Address Line 1 is required'
          }
        ]
      }, {
        key: 'address.addressLine2', label: 'Address Line 2', placeholder: 'Enter Address Line 2', colSpan: 2, rules: [
          {
            required: true,
            message: 'Address Line 2 is required'
          }
        ]
      },
      {
        key: 'address.country', label: 'Country', colSpan: 2,
        widget: CountryD,
        forwardRef: true
      },
      {
        key: 'address.state', label: 'State', colSpan: 2,
        widget: StateD,
        forwardRef: true
      },
      {
        key: 'address.city', label: 'City', colSpan: 2,
        widget: CityD,
        forwardRef: true
      },
      {
        key: 'address.pincode', widget: KoNumber, label: 'Pincode', placeholder: 'Enter pincode', colSpan: 2, rules: [
          {
            required: true,
            message: 'pincode is required'
          }
        ]
      }
    ]
  };

  return (
    <div className={styles.wrapper}>
      <KoFormBuilder form={form} meta={meta} />
    </div>
  );
};

export default AddressCrud;