import { KoAPI } from "packages";
import {getCookie} from "shared/utils/cookies";
import { configs } from 'configuration';
const version = configs().API_VERSION;
let accId = getCookie('accountId');
export const InvoicesAPI = {

  getInvoiceStatistics(query, workspaceId) {
    return KoAPI.get(`/api/${version}/finance/invoice/Statistics/month`, { query: { ...query }, header: {
      "workspace": workspaceId
    }});
  },

  getInvoiceCountByStatus( workspaceId) {
    return KoAPI.get(`/api/${version}/finance/invoice/dashboard/status`, {header: {
      "workspace": workspaceId
    }});
  },

  getInvoiceByStatus( workspaceId) {
    return KoAPI.get(`/api/${version}/finance/invoice/status`, {header: {
      "workspace": workspaceId
    }});
  },
  getWorkspaceById(id) {
    return KoAPI.get(`/api/${version}/workspace/${id}`);
  },

  getInvoiceSummary( workspaceId) {
    return KoAPI.get(`/api/${version}/finance/invoice/summary/status`, {header: {
      "workspace": workspaceId
    }});
  },
  getFolders( workspaceId) {
    return KoAPI.get(`/api/${version}/finance/folder`, {header: {
      "workspace": workspaceId
    }});
  },
  createOrUpdateFolder(data, id ,workspaceId){
    if(id){
      return KoAPI.update(`/api/${version}/finance/folder/${id}`, { data: { ...data }, header: {
        "workspace": workspaceId
      }});
    }else{
      return KoAPI.post(`/api/${version}/finance/folder`, { data: { ...data }, header: {
        "workspace": workspaceId
      }});
    }
  },
  getTemplates( workspaceId) {
    return KoAPI.get(`/api/${version}/finance/template`, {header: {
      "workspace": workspaceId
    }});
  },
  deleteById(id ,workspaceId){
    return KoAPI.delete(`/api/${version}/finance/template/${id}`, {header: {
      "workspace": workspaceId
    }});
  },
  createOrUpdateTemplate(data, id ,workspaceId){
    if(id){
      return KoAPI.update(`/api/${version}/finance/template/${id}`, { data: { ...data }, header: {
        "workspace": workspaceId
      }});
    }else{
      return KoAPI.post(`/api/${version}/finance/template`, { data: { ...data }, header: {
        "workspace": workspaceId
      }});
    }
  },
  getAccountById(){
    return KoAPI.get(`/api/${version}/account/${accId}`);
  },
  getCustomers(){
    return KoAPI.get(`/api/${version}/admin/customer`);
  },
  deleteFolder(id ,workspaceId){
    return KoAPI.delete(`/api/${version}/finance/folder/${id}`, {header: {
      "workspace": workspaceId
    }});
  },

  getInvoices(folderId ,workspaceId){
    return KoAPI.get(`/api/${version}/finance/${folderId}/invoice`, {header: {
      "workspace": workspaceId
    }});
  },
  getInvoiceById(folderId, id ,workspaceId){
    return KoAPI.get(`/api/${version}/finance/${folderId}/invoice/${id}`, {header: {
      "workspace": workspaceId
    }});
  },

  createOrUpdateInvoices(data, id, folderId ,workspaceId){
    if(id){
      return KoAPI.update(`/api/${version}/finance/template/${id}`, { data: { ...data } , header: {
        "workspace": workspaceId
      }});
    }else{
      return KoAPI.post(`/api/${version}/finance/${folderId}/invoice`, { data: { ...data }, header: {
        "workspace": workspaceId
      }});
    }
  },
  deleteInvoice(folderId, id, workspaceId){
    return KoAPI.delete(`/api/${version}/finance/${folderId}/invoice/${id}`, {header: {
      "workspace": workspaceId
    }});
  },
  getClients(){
    return KoAPI.get(`/api/${version}/admin/customer`);
  },


  uploadInvoice(data, id, folderId, workspaceId){
    return KoAPI.post(`/api/${version}/finance/${folderId}/invoice/upload`, { data: { ...data }, header: {
      "workspace": workspaceId
    }});
  },
  getUploadInvoiceById(folderId, id, workspaceId){
    return KoAPI.get(`/api/${version}/finance/${folderId}/invoice/upload/${id}`, {header: {
      "workspace": workspaceId
    }});
  },
  getUploadInvoices(folderId, workspaceId){
    return KoAPI.get(`/api/${version}/finance/${folderId}/invoice/upload`, {header: {
      "workspace": workspaceId
    }});
  }

};