import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCookie } from 'shared/utils/cookies';

import {
  KoIcon, KoAccordion, KoToast, KoCard,
  KoTitle, KoForm, KoDescription
} from 'packages';
import BusinessInfoCurd from './curd/business';
import BusinessProfile from './curd/businessprofile';
import PreferencesCrud from './curd/preferences';
import AddressCrud from './curd/address';
import DiscountCrud from './curd/discount';
import TaxCrud from './curd/tax';
import { add, remove } from 'shared/store/breadcrumbSlice';
import { adminConsoleAPI } from '../admin.service';

import styles from './settings.module.scss';
import BankCrud from './curd/bank';
import Preview from './preview';
import { deepMerge } from 'shared/utils';


function Settings(props) {
  const dispatch = useDispatch();
  // const [isLoading, setLoading] = useState(false);
  const [form] = KoForm.useForm();
  const [settings, setSettings] = useState();
  const panelStyle = {
    marginBottom: 2,
    background: '#fff',
    border: 'none',
    borderRadius: 1
  };

  useEffect(() => {
    dispatch(remove());
    getByIdSettings();
  }, []);

  const getByIdSettings = () => {
    // setLoading(true);
    const accountId = getCookie("accountId");
    adminConsoleAPI.getByIdSettings(accountId).then(
      (data) => {
        // setLoading(false);
        setSettings(data['account']);
        form.setFieldsValue({ ...data['account'] });
        dispatch(add([
          {
            title: 'Admin Console',
            path: 'admin/settings'
          },
          {
            title: 'Settings'
          },
          {
            title: data['account']?.companyName
          }
        ]));
      },
      (err) => {
        // setLoading(false);
      }
    );
  };

  const handleFinish = useCallback((values, refresh) => {
    // setLoading(true);
    const accountId = getCookie("accountId");
    adminConsoleAPI.updateSettings(values, accountId).then(
      (data) => {
        // setLoading(false);
        if (refresh !== 'refresh') {
          KoToast.success('Account settings saved!');
        }

        getByIdSettings(accountId);
      },
      (err) => {
        // setLoading(false);
      }
    );
  });


  const onValuesChange = (changedValues, refresh) => {
    const updatedCObj = deepMerge(settings, changedValues);
    if (refresh === 'refresh') {
      handleFinish(updatedCObj, refresh);
      setSettings(updatedCObj);
    }
  };

  const HEADER_OPTIONS = [{
    label: <div className={styles.header}>
      <div className={styles.circle}>
        <KoIcon name="BusinessFilled" height={12} width={12} />
      </div>
      <div className={styles.title}>
        <KoTitle className={styles.title} level={5} text="Business Information" />
        <KoDescription className={styles.description} text="Details about your business information" />
      </div>
    </div>,
    children: <BusinessInfoCurd onValuesChange={onValuesChange} handleFinish={handleFinish} settings={settings} form={form} />,
    key: 1,
    style: panelStyle
  },
  {
    label: <div className={styles.header}>
      <div className={styles.circle}>
        <KoIcon name="BusinessProfileFilled" height={15} width={15} />
      </div>
      <div className={styles.title}>
        <KoTitle className={styles.title} level={5} text="Business Profile" />
        <KoDescription className={styles.description} text="Details about your Business profile" />
      </div>
    </div>,
    children: <BusinessProfile handleFinish={handleFinish} settings={settings} form={form} />,
    key: 2,
    style: panelStyle
  }, {
    label: <div className={styles.header}>
      <div className={styles.circle}>
        <KoIcon name="PreferencesFilled" height={12} width={12} />
      </div>
      <div className={styles.title}>
        <KoTitle className={styles.title} level={5} text="General Preferences" />
        <KoDescription className={styles.description} text="Describe about your general preferences" />
      </div>
    </div>,
    children: <PreferencesCrud handleFinish={handleFinish} settings={settings} form={form} />,
    key: 3,
    style: panelStyle
  }, {
    label: <div className={styles.header}>
      <div className={styles.circle}>
        <KoIcon name="LocationFilled" height={20} width={20} />
      </div>
      <div className={styles.title}>
        <KoTitle className={styles.title} level={5} text="Address Information" />
        <KoDescription className={styles.description} text="Details about organization  location" />
      </div>
    </div>,
    children: <AddressCrud handleFinish={handleFinish} settings={settings} form={form} />,
    key: 4,
    style: panelStyle
  }, {
    label: <div className={styles.header}>
      <div className={styles.circle}>
        <KoIcon name="BankFilled" height={14} width={14} />
      </div>
      <div className={styles.title}>
        <KoTitle className={styles.title} level={5} text="Bank Details" />
        <KoDescription className={styles.description} text="Details about organization  bank info" />
      </div>
    </div>,
    children: <BankCrud handleFinish={handleFinish} settings={settings} form={form} />,
    key: 5,
    style: panelStyle
  }, {
    label: <div className={styles.header}>
      <div className={styles.circle}>
        <KoIcon name="TaxFilled" height={12} width={12} />
      </div>
      <div className={styles.title}>
        <KoTitle className={styles.title} level={5} text="Tax Details" />
        <KoDescription className={styles.description} text="Details about your tax levied directly" />
      </div>
    </div>,
    children: <TaxCrud handleFinish={handleFinish} settings={settings} form={form} />,
    key: 6,
    fields: 2,
    outof: 5,
    style: panelStyle
  }, {
    label: <div className={styles.header}>
      <div className={styles.circle}>
        <KoIcon name="DiscountFilled" height={16} width={16} />
      </div>
      <div className={styles.title}>
        <KoTitle className={styles.title} level={5} text="Discount Details" />
        <KoDescription className={styles.description} text="Details about your discount rates" />
      </div>
    </div>,
    children: <DiscountCrud handleFinish={handleFinish} settings={settings} form={form} />,
    key: 7,
    style: panelStyle
  }];


  return (
    <div className={styles.layout}>
      <KoTitle className={styles.title} strong={true} level={5} text={`The settings for ${settings?.companyName}. have been configured.`} />
      <KoDescription className={styles.descriptionTitle} text={`All of the preferences below are directly associated
          with the current business (${settings?.companyName})
          you are currently using. Changes on this page will only affect the current business.`} />
      <div className={styles.wrapper}>
        <KoCard hoverable={false} className={styles.tabsContnet}>
          <div className={styles.accordion}>
            <KoForm form={form} layout="vertical" onFinish={handleFinish} onSubmit={handleFinish}>
              <KoAccordion defaultActiveKey={[1]} expandIconPosition={'end'} data={HEADER_OPTIONS} bordered={false} />
            </KoForm>
          </div>
        </KoCard>
        <Preview settings={settings} form={form} />
      </div>

    </div>
  );
}

export default Settings;