import React, { useEffect, useState } from 'react';
import {
  KoAffix,
  KoText,
  KoIcon,
  KoModal,
  KoButton,
  KoForm,
  KoLoader,
  KoTable,
  KoPagination,
  KoDropdown,
  KoNano,
  KoTitle,
  KoToast,
  KoFolderCrud,
  KoFolder,
  KoFlex
} from "packages";
import KoToolBar from "shared/components/toolbar";
import { COLUMNS_GROUPS } from "./constant";
import styles from "./invoice.module.scss";
import { InvoicesAPI } from '../invoice.service';
import InvoiceShareCrud from './invoiceShareCrud';
import { useParams } from 'react-router';


function InvoicePage() {
  const {workspaceId} = useParams();
  const [isFolderOpen, setFolderModal] = useState(false);
  // const [isTableOpen, setTableOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [currentRecord, setCurrentRecord] = useState();
  const [isOpenDelete, setDeleteModal] = useState(false);
  const [isInvoiceShareOpen, setInvoiceShareModal] = useState(false);
  const [totalCount] = useState(100);
  const [folders, setFolders] = useState();
  const [recentRequestList, setRecentRequestList] = useState();

  const [pagination, setPagination] = useState({
    size: 10,
    offset: 0,
    page: 1
  });

  const [form] = KoForm.useForm();

  useEffect(()=>{
    getFolders();
  },[]);

  const getFolders = () => {
    setLoading(true);
    InvoicesAPI.getFolders(workspaceId).then(
      (data) => {
        setFolders(data['folders']);
        setFolderModal(false);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };
  
  const getInvoices = () => {
    setLoading(true);
    InvoicesAPI.getInvoices(workspaceId).then(
      (data) => {
        setRecentRequestList(data['invoices']);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const onPagination = (page, other) => {
    const query = {
      size: other,
      page: page

    };
    const p = Object.assign(pagination, query);
    setPagination(p);
    getInvoices(p);
  };
  const onCreateFolder = () => {
    setFolderModal(true);
  };

  const handleFolderFinish = (values => {
    if (!currentRecord?.folderId) {
      values['folderId'] = KoNano('FOL');
    }
    InvoicesAPI.createOrUpdateFolder(values, currentRecord?.folderId, workspaceId).then(
      (data) => {
        getFolders();
        setFolderModal(false);
        KoToast.success(`Folder ${currentRecord?.folderId ? 'updated' : 'created' } successfully`);
      },
      (err) => {
      }
    );
  });

  const handleFolderShare = (ShareValue) => {
    const updatedValues = {
      ...currentRecord,
      users: ShareValue['users'],
      groups: ShareValue['groups']
    };
    InvoicesAPI.createOrUpdateFolder(updatedValues, currentRecord?.folderId, workspaceId).then(
      (data) => {
        getFolders();
        setFolderModal(false);
        KoToast.success(`Folder Shared successfully`);
      },
      (err) => {
      }
    );
  };



  const handleRowActions = ({ key }) => {
    // if (key === '1') {
    //   setModal(true);
    // } else if (key === '2') {
    //   setResetPassModal(true);
    // } else if (key === '3') {
    //   setGroupModal(true);
    // } else if (key === '4') {
    //   setSuspendModal(true);
    // } else if(key === '0'){
    //   setActivateModal(true);
    // }else{
    //   setDeleteModal(true);
    // }

  };
  const items = [
    {
      label: "About",
      key: "0",
      icon: <KoIcon name="InfoOutlined"/>
    },
    {
      label: "Edit",
      key: "1",
      icon: <KoIcon name="EditOutlined" />
    },
    {
      label: "Share Document",
      key: "2",
      icon: <KoIcon name="SharedOutlined"  />
    },
    {
      type: 'divider'
    },
    {
      label: "Delete",
      key: "3",
      danger: true,
      icon: <KoIcon name="DeleteOutlined" />
    }
  ];


  const onHandelFolder = ({key}, coll) => {
    setCurrentRecord(coll);
    
    if (key === "0") {
      setFolderModal(true);
    }
    if (key === "1") {
      setFolderModal(true);
    }
    if (key === "2") {
      setInvoiceShareModal(true);
    }
    if (key === "3") {
      setDeleteModal(true);
    }
  };

  const handleFinishDelete = () => {
    InvoicesAPI.deleteFolder(currentRecord?.folderId, workspaceId).then(
      (data) => {
        getFolders();
        setDeleteModal(false);
        KoToast.success(`Folder deleted successfully! ${currentRecord.name}!`);
      },
      (err) => {
        KoToast.error(`error`);
      }
    );
  };


  const columnsRight = [{
    title: '',
    dataIndex: 'x',
    key: 'x',
    fixed: 'right',
    width: 40,
    render: (text, row) => {
      return <div className={`${styles.circle} columnsRight`} onClick={() => setCurrentRecord(row)}>
        <div shape="circle">
          <KoDropdown
            menu={{
              items,
              onClick: handleRowActions
            }}
            overlayClassName={styles.actionDropdown}
            placement="bottomLeft"
          >
            <div shape="circle" >
              <KoIcon name="VerticalMenuOutlined" onClick={() => (row)} />
            </div>
          </KoDropdown>
        </div>
      </div>;
    }
  }];

  return (
    <div className={styles.wrapper}>
      {isLoading && <KoLoader/>}
      <KoAffix top={56}>
        <KoToolBar
          className={styles.toolbar}
          title={"Invoice "}
          search={{
            visible: true,
            onChange: () => { },
            placeholder: "Search by invoice name"
          }}
          buttonList={[
            {
              label: "New Folder",
              type: "primary",
              icon: (
                <KoIcon
                  color="#FFF"
                  name="PlusOutlined"
                  width={18}
                  height={18}
                />
              ),
              onClick: () => onCreateFolder()
            }
          ]}
        />
      </KoAffix>
      <KoFlex wrap="wrap" gap="small" className={styles.recentFolders}>
        {folders?.map((folder, i) => (
          <div key={folder?.folderId}>
            <KoFolder data={folder} actions={items} onClick={onHandelFolder}/>
          </div>
        ))}
      </KoFlex>
     
      <div className={`${styles.tableWrapper} tableCard`}>
        <KoToolBar
          title={'Invoice Details'}
          search={{ visible: true, onChange: () => { }, placeholder: 'Search by any parameter' }}
          filter={{
            visible: false,
            options: [{ label: 'Sort By Name', key: '0' },
              { label: 'Created At', key: '2' },
              { label: 'Sort By designation', key: '3' }]
          }}
          buttonList={[]} />

        <KoTable data={{ columns: COLUMNS_GROUPS, rows: recentRequestList }}
          extraColumnsRight={columnsRight} maxHeight={50} />
        <KoPagination current={parseInt(pagination.page) || 1}
          total={totalCount}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} records`
          }
          pageSize={pagination.limit}
          defaultCurrent={pagination.page}
          onChange={onPagination}
          showQuickJumper={false}
          simple={false}
          responsive={false} />
      </div>

      <KoModal
        title={currentRecord?.folderId ? `Update Folder ${currentRecord?.name?.toUpperCase()}` : 'Create Folder'}
        centered
        open={isFolderOpen}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setFolderModal(false); }}
        onClose={() => { setCurrentRecord(); setFolderModal(false); }}
        width={680}
        // maskClosable={false}
        height={650}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setFolderModal(!isFolderOpen)} />,
          <KoButton key='create' loading={isLoading} type='primary' label={`${currentRecord?.folderId ? 'Update' : 'Create'}`} onClick={() => form.submit()} />
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <KoFolderCrud form={form} handleFinish={handleFolderFinish} initialValues={currentRecord} />
      </KoModal>

      <KoModal
        title={`Delete folder ${currentRecord?.name?.toUpperCase()}`}
        centered
        open={isOpenDelete}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setDeleteModal(false); }}
        onClose={() => { setCurrentRecord(); setDeleteModal(false); }}
        width={580}
        // maskClosable={false}
        height={450}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setDeleteModal(!isOpenDelete)} />,
          <KoButton key='reset' loading={isLoading} type='primary' label='Delete' onClick={() => handleFinishDelete()} />
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <div className={styles.delWrapper}>
          <KoTitle level={5} text={`Are you sure you want to delete this folder?`} />
          <KoText text={`This action is irreversible, and it will result in the removal of their associated data and Kwapio license.`} />
        </div>
      </KoModal>

      <KoModal
        title={'Share Invoice Folder'}
        centered
        open={isInvoiceShareOpen}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setInvoiceShareModal(false); }}
        onClose={() => { setCurrentRecord(); setInvoiceShareModal(false); }}
        width={560}
        // maskClosable={false}
        height={650}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setInvoiceShareModal(!isInvoiceShareOpen)} />,
          <KoButton key='create' loading={isLoading} type='primary' label={'Share'} onClick={() => form.submit()} />
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <InvoiceShareCrud form={form} initialValues={currentRecord} handleFinish={handleFolderShare}/>
      </KoModal>
    </div>
  );
}

export default InvoicePage;
// , rows: ROWS