
import { KoButton, KoCard, KoDivider, KoForm, KoIcon, KoModal, KoText } from 'packages';
import styles from './bank.module.scss';
import BankCrud from './bank';
import { useState } from 'react';

export default function ProfileBank({userData, handleFinish}) {
  const [isOpen, setModal] = useState(false);
  const [isLoading] = useState(false);
  const [form] = KoForm.useForm();

  const handleSubmit = (values) => {
    handleFinish(values);
    setModal(false);
  };

  return (
    <div className={styles.wrapper}>
      <div >

        <KoCard hoverable={false} className={styles.tabs}>
          <div className={styles.previewWrapper}>
            <KoDivider orientation="left" orientationMargin="0">
              <div className={styles.heading}>
                <KoText className={styles.headingText} text={'Bank Details'} />
                <KoIcon width={14} height={14} name={'EditOutlined'} onClick={() => setModal(true)}/>
              </div>
            </KoDivider>
            <div className={styles.card}>
              <div className={styles.fieldList}>
                <div className={styles.viewMode}>
                  <KoText className={styles.title} text={'Account Name'} />
                  <KoText className={styles.titleValue} text={`${userData?.bankDetails?.accountHolderName ? userData?.bankDetails?.accountHolderName : '-not set-'}`} />
                </div>
                <div className={styles.viewMode}>
                  <KoText className={styles.title} text={'Bank Name'} />
                  <KoText className={styles.titleValue} text={`${userData?.bankDetails?.accountHolderName ? userData?.bankDetails?.bankName : '-not set-'}`} />
                </div>
                <div className={styles.viewMode}>
                  <KoText className={styles.title} text={'Branch Name'} />
                  <KoText className={styles.titleValue} text={`${userData?.bankDetails?.accountHolderName ? userData?.bankDetails?.branchName : '-not set-'}`} />
                </div>
                <div className={styles.viewMode}>
                  <KoText className={styles.title} text={'IFSC Code'} />
                  <KoText className={styles.titleValue} text={`${userData?.bankDetails?.accountHolderName ? userData?.bankDetails?.ifscCode : '-not set-'}`} />
                </div>
                <div className={styles.viewMode}>
                  <KoText className={styles.title} text={'Account No'} />
                  <KoText className={styles.titleValue} text={`${userData?.bankDetails?.accountHolderName ? userData?.bankDetails?.accountNumber : '-not set-'}`} />
                </div>
              </div>
            </div>
         
          </div>
  
  
        </KoCard>
      </div>
    
      <KoModal
        title={`Edit`}
        centered
        open={isOpen}
        handleOk={() => { setModal(false); }}
        onClose={() => { setModal(false); }}
        width={580}
        // maskClosable={false}
        height={650}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setModal(!isOpen)} />,
          <KoButton key='create' loading={isLoading} type='primary' label={`Update`}
            onClick={() => form.submit()} />
        ]}
      >
        <BankCrud form={form} handleFinish={handleSubmit} initialValues={userData}/>
      </KoModal>
      
      
    </div>
  );
}
