import React from "react";
function Bug(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g id="bug">
        <rect width="24" height="24" rx="4" fill='var(--bug)' />
        <path id="Vector" d="M17.8333 9.08333C18.1428 9.08333 18.4395 8.96042 18.6583 8.74162C18.8771 8.52283 19 8.22609 19 7.91667V5H17.8333V7.91667H15.479C15.1965 7.22658 14.7151 6.63617 14.0959 6.22061C13.4768 5.80505 12.748 5.58314 12.0023 5.58314C11.2567 5.58314 10.5279 5.80505 9.90874 6.22061C9.28961 6.63617 8.80815 7.22658 8.52567 7.91667H6.16667V5H5V7.91667C5 8.22609 5.12292 8.52283 5.34171 8.74162C5.5605 8.96042 5.85725 9.08333 6.16667 9.08333H8.19958L7.60867 11.4167H5V12.5833H7.31292L7.22833 12.9176L7.21667 12.9917C7.19066 13.1913 7.17702 13.3924 7.17583 13.5937C7.1752 14.0411 7.23704 14.4864 7.35958 14.9167H6.16667C5.85725 14.9167 5.5605 15.0396 5.34171 15.2584C5.12292 15.4772 5 15.7739 5 16.0833V19H6.16667V16.0833H7.86942C8.29852 16.7952 8.90431 17.3841 9.62802 17.7928C10.3517 18.2016 11.1688 18.4164 12 18.4164C12.8312 18.4164 13.6483 18.2016 14.372 17.7928C15.0957 17.3841 15.7015 16.7952 16.1306 16.0833H17.8333V19H19V16.0833C19 15.7739 18.8771 15.4772 18.6583 15.2584C18.4395 15.0396 18.1428 14.9167 17.8333 14.9167H16.6404C16.7636 14.4855 16.8259 14.0392 16.8253 13.5907C16.8236 13.3904 16.8096 13.1903 16.7833 12.9917L16.6812 12.5833H19V11.4167H16.3878L15.7992 9.08333H17.8333Z" fill='var(--palette-gray-1)' />
      </g>
    </svg>
  );
}


export default Bug;