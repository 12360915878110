import React from "react";
function WishList(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path d="M4.43198 1.0536C4.26373 1.12699 4.11368 1.29211 4.05002 1.46181C4.01364 1.55355 4 4.74128 4 12.002C4 20.6891 4.00909 22.4366 4.06366 22.5605C4.19098 22.8723 4.53656 23.0512 4.88669 22.987C5.00946 22.9641 5.8916 22.2761 8.51528 20.1616C10.4205 18.6205 12.0029 17.3729 12.0347 17.3821C12.062 17.3959 13.6308 18.648 15.5133 20.1708C17.8323 22.0422 18.9873 22.9457 19.0964 22.9778C19.4511 23.0696 19.8649 22.8494 19.9603 22.51C19.9967 22.3907 20.0058 19.1296 19.9967 11.8965L19.9831 1.45264L19.8876 1.3288C19.8376 1.26 19.7375 1.15909 19.6693 1.10864C19.5466 1.01232 19.542 1.01232 12.0575 1.00315C5.88705 0.993975 4.54565 1.00315 4.43198 1.0536Z" fill="var(--palette-primary-darker)" />
    </svg>
  );
}


export default WishList;