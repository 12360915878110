import React from "react";
import { KoText } from "../../../typography";
import styles from './api.module.scss';

function API(props) {
  return (
    <div className={styles.wrapper}>
      {props?.hideCopy ? <KoText className={`${styles[props.value]}`} text={props.value} /> :
        <KoText className={`${styles[props.value]}`} text={props.value} copyable={{
          tooltips: false
        }} />}
    </div>
  );
}
export default API;
