import { KoLoader, KoDriver } from 'packages';
import React, { useState, useEffect } from 'react';
import {TaskPlannerAPI} from '../taskPlanner.service';
import styles from './curd.module.scss';
export default function Attachemnts({ticketId, workspaceId, onRemoveFile}) {
  const [files, setFiles] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    TaskPlannerAPI.getAttachments(ticketId, workspaceId).then(
      (data) => {
        setLoading(false);
        setFiles(data['attachments']);
      },
      (err) => {
        setLoading(false);
        console.log(err);
      }
    );
  }, []);

  return (
    <>
      {loading && <KoLoader />}
      { files?.length > 0 && files?.map((att) =>
        <div key={att.uid} className={styles.attachmentsArea}>
          <KoDriver  action={true}fileType={att?.type} filePath={att.url} handleDelete={() => onRemoveFile(att)} />
        </div>
      )}
    </>
   
  );
}
