import React from "react";
function EpicOutlined(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
      <path
        fill="none"
        stroke="#666"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M21 1h12l-8 16h12L18 47l3-22H11L21 1z"
      />
    </svg>

  );
}


export default EpicOutlined;