import React from "react";
function Performance(props) {
  const { width, height } = props;
  return (
   
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      width={width} height={height}
      {...props}
    >
      <path d="M48 28v-6c0-1.1.9-2 2-2s2 .9 2 2v6c0 1.1-.9 2-2 2s-2-.9-2-2zm26.8 2.2c-.8-.8-2-.8-2.8 0l-4.2 4.2c-.8.8-.8 2 0 2.8.4.4.9.6 1.4.6.5 0 1-.2 1.4-.6l2.8-2.8c4.5 5.2 7.2 11.6 7.6 18.5h-4c-1.1 0-2 .9-2 2s.9 2 2 2h3.9c-.4 6.9-3.1 13.3-7.6 18.5l-2.8-2.8c-.8-.8-2-.8-2.8 0-.8.8-.8 2 0 2.8l4.2 4.2c.4.4.9.6 1.4.6.5 0 1-.2 1.4-.6C81.4 73.1 85 64.3 85 55c0-9.4-3.6-18.1-10.2-24.8zM23 53h-6c-1.1 0-2 .9-2 2s.9 2 2 2h6c1.1 0 2-.9 2-2s-.9-2-2-2zm6.5 19.7l-4.2 4.2c-.8.8-.8 2 0 2.8.4.4.9.6 1.4.6.5 0 1-.2 1.4-.6l4.2-4.2c.8-.8.8-2.1 0-2.8-.8-.8-2-.8-2.8 0zm-1.4-42.5c-.8-.8-2-.8-2.8 0-.8.8-.8 2 0 2.8l4.2 4.2c.4.4.9.6 1.4.6.5 0 1-.2 1.4-.6.8-.8.8-2.1 0-2.8l-4.2-4.2zM95 55c0 13.4-5.9 26-16.2 34.5 0 0-.1 0-.1.1l-.1.1c-.1 0-.1.1-.2.1 0 0-.1 0-.1.1-.1 0-.1 0-.2.1H21.9c-.1 0-.1 0-.2-.1-.1 0-.1 0-.2-.1-.1 0-.1-.1-.2-.1s-.1-.1-.2-.1c0 0-.1 0-.1-.1C10.9 81 5 68.4 5 55c0-24.8 20.2-45 45-45s45 20.2 45 45zm-4 0c0-22.6-18.4-41-41-41S9 32.4 9 55c0 11.9 5.2 23.2 14.2 31H48v-4c0-1.1.9-2 2-2s2 .9 2 2v4h24.8c9-7.8 14.2-19.1 14.2-31zM63.4 41.6c.8.8.8 2 0 2.8l-7.3 7.3c.5 1 .8 2.1.8 3.3 0 3.9-3.1 7-7 7s-7-3.1-7-7 3.1-7 7-7c1.2 0 2.3.3 3.3.8l7.3-7.3c.9-.7 2.2-.7 2.9.1zM53 55c0-1.7-1.3-3-3-3s-3 1.3-3 3c0 1.6 1.3 3 3 3s3-1.4 3-3z" />
    </svg>
  );
}


export default Performance ;