import React from "react";
import styles from './status.module.scss';

function KoUserStatus(props) {
  let { data } = props;
  if (data === 1) {
    data = "active";
  } else if (data === 2) {
    data = "requested";
  } else if (data === 3) {
    data = "invited";
  } else if (data === 0) {
    data = "suspended";
  } else if (data === -1) {
    data = "deleted";
  }

  return (
    <div className={styles.wrapper}>
      <div className={`${styles.status} ${styles[data]}`}>
      </div>
      <div className={styles.text}>{data}</div>
    </div>
  );
}
export default KoUserStatus;
