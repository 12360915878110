import { KoIcon } from "packages";
const HEADER = [
  {
    value: 'p',
    label: 'Normal text'
  },
  {
    value: 'h1',
    label: 'Heading 1'
  },
  {
    value: 'h2',
    label: 'Heading 2'
  },
  {
    value: 'h3',
    label: 'Heading 3'
  },
  {
    value: 'h4',
    label: 'Heading 4'
  },
  {
    value: 'h5',
    label: 'Heading 5'
  },
  {
    value: 'h6',
    label: 'Heading 6'
  }

];

const SIZE = [
  {
    value: '8',
    label: '8'
  },
  {
    value: '9',
    label: '9'
  },
  {
    value: '10',
    label: '10'
  },
  {
    value: '11',
    label: '11'
  },
  {
    value: '12',
    label: '12'
  },
  {
    value: '13',
    label: '13'
  },
  {
    value: '14',
    label: '14'
  },
  {
    value: '15',
    label: '15'
  },
  {
    value: '16',
    label: '16'
  },
  {
    value: '17',
    label: '17'
  }, {
    value: '18',
    label: '18'
  },
  {
    value: '19',
    label: '19'
  },
  {
    value: '20',
    label: '20'
  },
  {
    value: '21',
    label: '21'
  },
  {
    value: '22',
    label: '22'
  }, {
    value: '23',
    label: '23'
  },
  {
    value: '24',
    label: '24'
  },
  {
    value: '25',
    label: '25'
  }

];

const ALIGN = [
  {
    key: 'left',
    label: <KoIcon name='LeftOutlined' />
  },
  {
    key: 'right',
    label: <KoIcon name='RightOutlined' />
  },
  {
    key: 'center',
    label: <KoIcon name='CenterOutlined' />
  },
  {
    key: 'justify',
    label: <KoIcon name='JustifyOutlined' />
  }
];

const FONTS = [];
export {
  HEADER,
  FONTS,
  SIZE,
  ALIGN
};