import React from "react";
function Epic(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="4" fill="var(--epic)" />
      <path d="M18.5898 7.33333H15.5V4.39067L18.5898 7.33333ZM12 14V11.3333C10.4558 11.3333 9.2 12.5293 9.2 14C9.2 15.4707 10.4558 16.6667 12 16.6667C13.5442 16.6667 14.8 15.4707 14.8 14C14.8 13.6273 14.7181 13.2727 14.5725 12.95L12 14ZM19 8.66667V20H5V6C5 4.89533 5.9401 4 7.1 4H14.1V8.66667H19ZM16.2 14C16.2 11.794 14.3163 10 12 10C9.6837 10 7.8 11.794 7.8 14C7.8 16.206 9.6837 18 12 18C14.3163 18 16.2 16.206 16.2 14Z" fill="white" />
    </svg>

  );
}


export default Epic;