/* eslint-disable import/no-anonymous-default-export */
import _find from 'lodash/find';
import KoButton from 'packages/button';
import KoIcon from 'packages/icon';
import KoModal from 'packages/modal';
import KoPreview from 'packages/preview';
import KoPriority from 'packages/priority';
import KoTooltips from 'packages/tooltips';
import { KoDescription } from 'packages/typography';
import { useState } from 'react';
import { ISSUETYPE } from 'shared/commonConstants';
import styles from '../kanban.module.scss';

export default function({ children: card, dragging, allowRemoveCard, onCardRemove, setSubModel, onCellClick }) {
  const [isPreviewOpen, setPreviewModal] = useState(false);
  const [isCommentsOpen, setCommentsModal] = useState(false);
  let issueType = _find(ISSUETYPE, function(o) { return o.value === card?.issueType; }) || {};


  return (
    card.summary &&
      <div className={`${styles.card} ${dragging ? styles.kanbaCardDragging : ''}`}>
        <div className={styles.cardTitle}>
          { card?.priority ? <div className={`${styles.priority} ${styles['priority'+card?.priority]}`}>
            <KoPriority size={'small'} value={card?.priority}/>
          </div> : ''}
          <KoDescription className={styles.summary}  text={card.summary}
            ellipsis={{
              rows:2
            }}
          />
          <div className={styles.contentTags}>
            <KoTooltips title={'Open'}>
              <div className={styles.contentTagsInfo} >
                <KoButton variant="outlined" icon={issueType?.icon} className={`${styles.btn}`} label={card?.id} type="text"/>
                {/* <span className={`${styles.openIcon}`} >
                  <KoIcon width={11} height={11} name="OpenOutlined" />
                </span> */}
              </div>
            </KoTooltips>
            <div className={styles.contentTagsFile}>
              { card?.subTask && <KoButton iconBtn={true} type="text" className={`${styles.btn}`}  icon={<KoIcon width={11} height={11} name='TreeOutlined'/>} onClick={() => {onCellClick(card); setSubModel(true);}}/>}
            </div>
          </div>
          {/* {allowRemoveCard && (
          <span style={{ cursor: 'pointer' }} onClick={() => onCardRemove(card)}>
              ×
          </span>
        )} */}
        </div>
        {/* <KoDescription text={card.description} className={styles.cardDescription}/> */}
   

        <KoModal
          title={`Preview Attachment`}
          centered
          open={isPreviewOpen}
          handleOk={() => { setPreviewModal(false); }}
          onClose={() => { setPreviewModal(false); }}
          width={'45vw'}
          // maskClosable={false}
          height={650}
          footer={[
            <KoButton key='cancel' label='Close' onClick={() => setPreviewModal(!isPreviewOpen)} />
            // <KoButton key='create'  type='primary' label={`Update`}
            //   onClick={() => form.submit()} />
          ]}
        >
          <KoPreview/>
        </KoModal>

        <KoModal
          title={`Comments`}
          centered
          open={isCommentsOpen}
          handleOk={() => { setCommentsModal(false); }}
          onClose={() => { setCommentsModal(false); }}
          width={'45vw'}
          // maskClosable={false}
          height={650}
          footer={[
            <KoButton key='cancel' label='Close' onClick={() => setCommentsModal(!isCommentsOpen)} />
            // <KoButton key='create'  type='primary' label={`Update`}
            //   onClick={() => form.submit()} />
          ]}
        >
          <KoPreview/>
        </KoModal>


      </div>
  );
}
