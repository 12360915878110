import { configs } from 'configuration';
import { KoAPI } from "packages";
const version = configs().API_VERSION;
export const FilesAPI = {
  getFolders(workspaceId) {
    return KoAPI.get(`/api/${version}/file/folder`, {header: {
      "workspace": workspaceId
    }});
  },
  getFolderById(id, workspaceId) {
    return KoAPI.get(`/api/${version}/file/folder/${id}`, {header: {
      "workspace": workspaceId
    }});
  },
  createOrUpdateFolder(data, id, workspaceId){
    if(id){
      return KoAPI.update(`/api/${version}/file/folder/${id}`, { data, header: {
        "workspace": workspaceId
      }});
    }else{
      return KoAPI.post(`/api/${version}/file/folder`, { data, header: {
        "workspace": workspaceId
      }});
    }
  },
  deleteFolderById(folderId, workspaceId){
    return KoAPI.delete(`/api/${version}/file/folder/${folderId}`, {header: {
      "workspace": workspaceId
    }});
  },
  uploadFile(folderId, data, workspaceId){
    return KoAPI.post(`/api/${version}/file/${folderId}/file/upload`, { data, header: {
      "workspace": workspaceId
    }});
  },

  getUpload(data){
    return KoAPI.post(`/api/${version}/upload/read`, { data: { ...data } });
  },

  getRecentFiles(query){
    return KoAPI.get(`/api/${version}/file/recent`, { query: { ...query } });
  },
  getFile(url, query){
    return KoAPI.get(url, { query: { ...query } });
  },
  getFileById(folderId, id, query, workspaceId){
    return KoAPI.get(`/api/${version}/file/${folderId}/file/${id}`, { query: { ...query }, header: {
      "workspace": workspaceId
    }});
  },
  getFilJSON(url){
    return KoAPI.getURL(`${url}`);
  },

  getFilesByFolderId(folderId, workspaceId){
    return KoAPI.get(`/api/${version}/file/${folderId}/file`, {header: {
      "workspace": workspaceId
    }});
  },
  createOrUpdateFile(data, folderId, id, workspaceId) {
    console.log('clg', id, workspaceId);
    if(id){
      return KoAPI.update(`/api/${version}/file/${folderId}/file/${id}`,  { data: { ...data } , header: {
        "workspace": workspaceId
      }});
    }else{
      return KoAPI.post(`/api/${version}/file/${folderId}/file`, { data: { ...data }, header: {
        "workspace": workspaceId
      }});
    }
  },
  deleteFileById(folderId, id, workspaceId){
    return KoAPI.delete(`/api/${version}/file/${folderId}/file/${id}`, {header: {
      "workspace": workspaceId
    }});
  }

};