import React from "react";
function ManageTax(props) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width={width}
      height={height}
      {...props}
    >
      <path
        fill="none"
        stroke="#474747"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={15}
        className="colorStroke474747 svgStroke"
        d="M79.361 63.979H401.606V483.768H79.361z"
      />
      <path
        fill="none"
        stroke="#474747"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={15}
        d="M115.878 24.989L438.122 24.989 438.122 444.778"
      />
      <path
        fill="#474747"
        d="M205.227 153.443v-8.631a2.914 2.914 0 00-2.914-2.914H143.57a2.914 2.914 0 00-2.914 2.914v8.631a2.914 2.914 0 002.914 2.914h21.5v60.909a2.914 2.914 0 002.914 2.914h9.914a2.914 2.914 0 002.914-2.914v-60.909h21.5a2.915 2.915 0 002.915-2.914zm36.542-9.626a2.913 2.913 0 00-2.739-1.92h-11.08c-1.213 0-2.3.752-2.727 1.887l-27.285 72.455a2.915 2.915 0 002.727 3.941h10.062a2.914 2.914 0 002.74-1.922l7.162-19.78h24.311l6.883 19.747a2.914 2.914 0 002.752 1.955h10.752a2.915 2.915 0 002.74-3.909l-26.298-72.454zm-16.136 40.843l7.384-20.392 7.106 20.392h-14.49zm114.176 30.971l-24.09-35.532 22.869-33.584a2.912 2.912 0 00-2.363-4.617h-11.651c-.983 0-1.9.496-2.439 1.319l-15.706 24.007-15.838-24.016a2.913 2.913 0 00-2.433-1.31H275.92a2.912 2.912 0 00-2.408 4.555l22.914 33.633-24.509 35.526a2.914 2.914 0 002.399 4.57h11.795c.975 0 1.885-.487 2.426-1.299l17.383-26.099 16.892 26.068a2.914 2.914 0 002.446 1.329h12.14a2.915 2.915 0 002.411-4.55z"
        className="color474747 svgShape"
      />
      <path
        fill="none"
        stroke="#474747"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={15}
        className="colorStroke474747 svgStroke"
        d="M140.655 296.392L340.311 296.392"
      />
      <path
        fill="none"
        stroke="#474747"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={15}
        className="colorStroke474747 svgStroke"
        d="M140.655 351.121L340.311 351.121"
      />
      <path
        fill="none"
        stroke="#474747"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={15}
        className="colorStroke474747 svgStroke"
        d="M140.655 405.85L232.878 405.85"
      />
    </svg>
  );
}
export default ManageTax;