import React, { useState, useEffect, useRef } from 'react';
import styles from './drivers.module.scss';
import withFetching from './withfetching';

import {
  CsvViewer,
  DocxViewer,
  VideoViewer,
  XlsxViewer,
  // XBimViewer,
  PDFViewer,
  UnsupportedViewer,
  ArchiveViewer,
  PhotoViewerWrapper,
  AudioViewer,
  ProgramsViewer
} from './type';
import KoButton from 'packages/button';
import KoIcon from 'packages/icon';

const Driver = (props) => {
  const {DeleteFile = true} = props;
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const containerRef = useRef(null);
  useEffect(() => {
    if (containerRef.current) {
      const height = containerRef.current.clientHeight;
      const width = containerRef.current.clientWidth;
      setDimensions({ width, height });
    }
  }, []); // Empty dependency array ensures this effect runs only once after the component mounts
  const getDriver = () => {
    switch (props.fileType) {
      // Short type (csv) and full type (text/csv)
    case 'csv':
    case 'text/csv': {
      return withFetching(CsvViewer, props);
    }
      // Short type (xlsx, xls) and full types
    case 'xlsx':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'application/vnd.ms-excel': // for .xls
    {
      const newProps = Object.assign({}, props, { responseType: 'arraybuffer' });
      return withFetching(XlsxViewer, newProps);
    }
      // Short types for images and their full types
    case 'jpg':
    case 'jpeg':
    case 'image/jpeg':
    case 'gif':
    case 'image/gif':
    case 'bmp':
    case 'image/bmp':
    case 'png':
    case 'image/png':
    case 'webp':
    case 'image/webp':
    case 'svg':
    case 'image/svg+xml': {
      return (innerProps) => <PhotoViewerWrapper {...innerProps} {...props} filePath={props?.filePath}/>;
    }
    case 'pdf':
    case 'application/pdf': {
      return (innerProps) => <PDFViewer thumbnails={props?.thumbnails} {...innerProps} filePath={props?.filePath} />;
    }
    case 'doc':
    case 'docx':
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
      return (innerProps) => <DocxViewer {...innerProps} filePath={props?.filePath} />;
    }
    // case 'ppt':
    // case 'pptx':
    // case 'application/vnd.ms-powerpoint':
    // case 'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
    //   return (innerProps) => <PptViewer {...innerProps} filePath={props?.currentRecord?.invoiceFile?.url} />;
    // }
    // case 'txt':
    // case 'text/plain': {
    //   return TextViewer;
    // }
    // case 'xml':
    // case 'application/xml': {
    //   return XmlViewer;
    // }
    // case 'json':
    // case 'application/json': {
    //   return JsonViewer;
    // }
    case 'zip':
    case 'application/zip':
    case 'rar':
    case 'application/x-zip-compressed':
    case 'application/x-rar-compressed':
    case '7z':
    case 'application/x-7z-compressed': {
      return (innerProps) => <ArchiveViewer {...innerProps} file={innerProps?.filePath} />;
    }

    case 'mp3':
    case 'audio/mpeg':
    case 'wav':
    case 'audio/wav':
    case 'ogg':
    case 'audio/ogg': {
      return AudioViewer;
    }
    case 'webm':
    case 'video/webm':
    case 'mp4':
    case 'video/mp4':
    case 'avi':
    case 'video/x-msvideo':
    case 'mov':
    case 'video/quicktime': {
      return VideoViewer;
    }
    // case 'wexbim':
    // case 'model/vnd.wexbim': {
    //   return XBimViewer;
    // }
    case 'html':
    case 'text/html': {
      return (innerProps) => <ProgramsViewer {...innerProps} filePath={props?.filePath} />;
    }
    case 'css':
    case 'text/css': {
      return (innerProps) => <ProgramsViewer {...innerProps} filePath={props?.filePath} />;
    }
    case 'js':
    case 'application/javascript': {
      return (innerProps) => <ProgramsViewer {...innerProps} filePath={props?.filePath} />;
    }
    // case 'rtf':
    // case 'application/rtf': {
    //   return RtfViewer;
    // }
    // case 'iso':
    // case 'application/x-iso9660-image': {
    //   return IsoViewer;
    // }
    default: {
      return UnsupportedViewer;
    }
    }
  };


  const downloadFile = () => {
    const link = document.createElement('a');
    link.href = props?.filePath;
    link.target = '_blank';
    link.rel = 'noopener noreferrer';
    link.click();
  };
  
  const DriverComponent = getDriver();

  return (
    <div className={styles.wrapper}>
      {props?.action &&<div className={styles.overlay}>
        <div className={styles.header}>
          {DeleteFile&&  <KoButton className={styles.btn} onClick={props?.handleDelete} iconBtn={true} icon={<KoIcon width={11} height={11} name='DeleteOutlined' />} />}
          <KoButton onClick={downloadFile} className={styles.btn} iconBtn={true} icon={<KoIcon width={11} height={11} name='DownloadOutlined' />} />
        </div>
      </div>}
      <div className={styles.pgViewer} ref={containerRef}>
        <DriverComponent {...props} width={dimensions.width} height={dimensions.height} />
      </div>
    </div>
  );
};

export default Driver;
