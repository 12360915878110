import moment from "moment";
const LANGUAGES = [{
  label: 'English',
  value: 'en'
}];

const DATEFORMATS = [{
  label: `${moment().format('MM/DD/YY')}`,
  value: 'MM/DD/YY'
}, {
  label: `${moment().format('MM/DD/YYYY')}`,
  value: 'MM/DD/YYYY'
},{
  label: `${moment().format('MMM/DD/YYYY')}`,
  value: 'MMM/DD/YYYY'
}, {
  label: `${moment().format('YYYY-MM-DD')}`,
  value: 'YYYY-MM-DD'
}, {
  label: `${moment().format('DD-MM-YY')}`,
  value: 'DD-MM-YY'
}, {
  label: `${moment().format('DD-MM-YYYY')}`,
  value: 'DD-MM-YYYY'
},{
  label: `${moment().format('DD-MMM-YYYY')}`,
  value: 'DD-MMM-YYYY'
}];

const TIMEFORMAT = [{
  label: '24 Hour',
  value: '24hour'
}, {
  label: 'AM/PM',
  value: '12 Hour (AM/PM)'
}];

const NUMBERFORMT = [{
  label: '100,000.00',
  value: 'comma_dot'
}, {
  label: '100.000,00',
  value: 'dot_comma'
},{
  label: '100 000.00',
  value: 'space_dot'
},{
  label: '100 000,00',
  value: 'space_comma'
},{
  label: '100000.00',
  value: 'swiss'
},{
  label: '100000,00',
  value: 'swiss_german'
}];

const CURRENCYPOSITION=[{
  label: 'Left of amount',
  value: 'left'
}, {
  label: 'Right of amount',
  value: 'right'
}];

const MONTHS = [
  {
    label: "January",
    value: "January"
  },
  {
    label: "February",
    value: "February"
  },
  {
    label: "March",
    value: "March"
  },
  {
    label: "April",
    value: "April"
  },
  {
    label: "May",
    value: "May"
  },
  {
    label: "June",
    value: "June"
  },
  {
    label: "July",
    value: "July"
  },
  {
    label: "August",
    value: "August"
  },
  {
    label: "September",
    value: "September"
  },
  {
    label: "October",
    value: "October"
  },
  {
    label: "November",
    value: "November"
  },
  {
    label: "December",
    value: "December"
  }];
  
const CURRENCY=[{
  label: "United States Dollar - USD",
  value: "usd"
},{
  label: "Indian Rupee - INR",
  value: "inr"
}];

export {
  LANGUAGES,
  NUMBERFORMT,
  TIMEFORMAT,
  CURRENCY,
  DATEFORMATS,
  CURRENCYPOSITION,
  MONTHS
};