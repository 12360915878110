/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/display-name */
import React, { forwardRef, useMemo } from 'react';
import memoize from 'lodash/memoize';
import isArray from 'lodash/isArray';
import has from 'lodash/has';
import find from 'lodash/find';
import pick from 'lodash/pick';
import capitalize from 'lodash/capitalize';
import { Form, Tooltip, Input } from 'antd';
import QuestionIcon from './questionIcon';

const FormItem = Form.Item;

const getValue = (obj, namePath) => {
  const arr = typeof namePath === 'string' ? namePath.split('.') : namePath;
  let current = obj;

  for (let i = 0; i < arr?.length; i += 1) {
    if (has(current, arr[i])) {
      current = current[arr[i]];
    } else {
      return undefined;
    }
  }

  return current;
};

const getWrappedComponentWithForwardRef = memoize((Comp) =>
  forwardRef((props, ref) => {
    return (
      <span ref={ref}>
        <Comp {...props} />
      </span>
    );
  })
);

function FormBuilderField(props) {
  const { field, meta, form } = props;

  // Construct the label with tooltip if necessary
  const label = field.tooltip ? (
    <span>
      {field.label}
      <Tooltip title={field.tooltip}>
        {' '}
        <QuestionIcon />
      </Tooltip>
    </span>
  ) : (
    field.label
  );

  // Determine form item layout
  let formItemLayout =
    field.formItemLayout || (field.label ? getValue(meta, 'formItemLayout') || [8, 16] : null);
    
  if (isArray(formItemLayout) && formItemLayout.length >= 2) {
    formItemLayout = {
      labelCol: { span: formItemLayout[0] },
      wrapperCol: { span: formItemLayout[1] }
    };
  }

  const isFieldViewMode = meta.viewMode || field.viewMode || field.readOnly;
  
  const formItemProps = {
    key: field.key,
    colon: meta.colon,
    ...(meta.formItemLayout !== null ? formItemLayout : {}),
    label,
    ...pick(field, [
      'help',
      'extra',
      'labelCol',
      'wrapperCol',
      'colon',
      'htmlFor',
      'noStyle',
      'validateStatus',
      'hasFeedback'
    ]),
    ...field.formItemProps,
    className: `${meta.viewMode ? 'ant-form-item-view-mode ant-form-item-view-mode-v4' : ''
      } ${field.className || (field.formItemProps && field.formItemProps.className)}`
  };

  if (field.key || field.name) {
    formItemProps.name = field.name || field.key.split('.');
  }
  
  Object.assign(formItemProps, {
    noStyle: field.noFormItem || field.noStyle,
    ...pick(field, ['shouldUpdate', 'dependencies'])
  });

  if (field.label && typeof field.label === 'string') {
    formItemProps['data-label'] = field.label; // help e2e test
  }
  
  // Handle column span
  if (field.colSpan && formItemProps.labelCol && !field.formItemLayout) {
    const labelCol = Math.round(formItemProps.labelCol.span / field.colSpan);
    Object.assign(formItemProps, {
      labelCol: { span: labelCol },
      wrapperCol: { span: 24 - labelCol }
    });
  }

  if (field.render) {
    return field.render.call(this, {
      formItemProps,
      field,
      form,
      ...pick(props, ['disabled', 'viewMode', 'initialValues'])
    });
  }

  // Determine initial value
  const initialValues = meta.initialValues || {};
  const initialValue = useMemo(() => {
    if (has(field, 'initialValue')) {
      return field.initialValue;
    } else if (field.getInitialValue) {
      return field.getInitialValue(field, initialValues, form);
    } else {
      return getValue(initialValues, field.name || field.key);
    }
  }, [field, initialValues, form]);

  // Handle field props
  const rules = useMemo(() => {
    const validationRules = [...(field.rules || [])];
    if (field.required) {
      validationRules.unshift({
        required: true,
        message: field.message || field.requiredMessage || undefined
      });
    }
    return validationRules;
  }, [field]);

  const fieldProps = {
    initialValue,
    preserve: meta.preserve,
    ...pick(field, [
      'getValueFromEvent',
      'getValueProps',
      'normalize',
      'trigger',
      'preserve',
      'valuePropName',
      'validateTrigger',
      'validateFirst'
    ]),
    rules,
    ...field.fieldProps
  };
  
  Object.assign(formItemProps, fieldProps);

  // Handle view mode
  if (isFieldViewMode) {
    let viewEle = null;
    const formValues = form ? form.getFieldsValue(true) : {};
    let viewValue = has(formValues, field.key || field.name.join('.'))
      ? getValue(formValues, formItemProps.name || field.key)
      : initialValue;

    if (field.renderView) {
      viewEle = field.renderView(viewValue, form, initialValues);
    } else if (field.viewWidget) {
      const ViewWidget = field.viewWidget;
      viewEle = (
        <ViewWidget value={viewValue} form={form} field={field} {...field.viewWidgetProps} />
      );
    } else if (field.link) {
      const href = typeof field.link === 'string' ? field.link : viewValue;
      viewEle = (
        <a href={href} target={field.linkTarget || '_self'}>
          {viewValue}
        </a>
      );
    } else if (field.options) {
      const found = find(field.options, (opt) => opt[0] === viewValue);
      if (found) {
        viewValue = found[1];
      }
    }

    if (!viewEle) {
      if (typeof viewValue === 'boolean') viewEle = capitalize(String(viewValue));
      else if (viewValue === undefined) viewEle = 'N/A';
      else {
        viewEle = (
          <span className="antd-form-builder-string-content">{String(viewValue) || ''}</span>
        );
      }
    }

    // Render read-only view
    if (form && field.readOnly) {
      const ele = <span className="antd-form-builder-read-only-content">{viewEle}</span>;
      return <FormItem {...formItemProps}>{ele}</FormItem>;
    }

    delete formItemProps.name;
    delete formItemProps.key;
    return <FormItem {...formItemProps}>{viewEle}</FormItem>;
  }

  // Handle widget props
  const wp = field.widgetProps || {};
  const widgetProps = {
    ...pick(field, ['placeholder', 'type', 'className', 'class', 'onChange']),
    disabled: field.disabled || meta.disabled || props.disabled,
    ...wp
  };

  let FieldWidget = field.widget || Input;

  if (field.forwardRef) {
    FieldWidget = getWrappedComponentWithForwardRef(FieldWidget);
  }

  const valueProps = {};
  const ele = (
    <FieldWidget {...widgetProps} {...valueProps}>
      {field.children || null}
    </FieldWidget>
  );

  delete formItemProps['key'];
  return <FormItem {...formItemProps}>{ele}</FormItem>;
}

export default FormBuilderField;
