import { KoForm, KoFormBuilder, KoSelect, KoUserSelect } from 'packages';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PRIORITY, STATUS } from 'shared/commonConstants';
import styles from './crud.module.scss';
import { useParams } from 'react-router';

const BulkCrud = ({ form, bulkUpdate, initialValues, API, setBulkConfirmModal}) => {
  const {workspaceId} = useParams();
  const wosAssignees = useSelector(state => state.wosAssignees);
  const [sprints, setSprints] = useState();


  useEffect(() => {
    getSprints();
  }, []);


  useEffect(()=>{
    form?.resetFields();
  }, [initialValues]);
    
  const getSprints = () => {
    setSprints();
    API.getActiveSprints(workspaceId).then(
      (data) => {
        setSprints(data?.sprints);
      },
      (err) => {
      }
    );
  };
  
  const handleSubmit = useCallback(values => {
    bulkUpdate(values);
  });

  const Assignees = ({ value, onChange }) =>
    <KoUserSelect value={value} onChange={v => onChange(v)} users={wosAssignees[0]} placeholder='Select assignee'/>;
  
  const Sprint = ({ value, onChange }) =>
    <KoSelect value={value}
      onChange={v => onChange(v)}
      showSearch
      placeholder='Select sprint'
      options={sprints?.map((spr) => ({
        label: spr?.name,
        value: spr?.sprintId
      }))}
    />;


  const Status = ({ value, onChange }) =>
    <KoSelect value={value}
      showSearch
      onChange={v => { onChange(v); }}
      placeholder='Select status'
      options={STATUS}
    />;

  const Priority = ({ value, onChange }) =>
    <KoSelect value={value}
      showSearch
      onChange={v => onChange(v)}
      placeholder='Select priority'
      options={PRIORITY}/>;

  const meta = {
    columns: 4,
    formItemLayout: null,
    // initialValues,
    colon: true,
    fields: [
      {
        key: 'assignee', label: 'Assignee', colSpan: 2,
        widget: Assignees,
        forwardRef: true
      },
      {
        key: 'status', label: 'Status', colSpan: 2,
        widget: Status
      },
      {
        key: 'priority', label: 'Priority', colSpan: 2,
        widget: Priority
      },
      {
        key: 'sprint', label: 'Sprint', colSpan: 2, widget: Sprint
      }
    ]

  };


  return (
    <div className={styles.wrapper}>
      <KoForm form={form} layout="vertical" onFinish={handleSubmit}
        onSubmit={handleSubmit}>
        <KoFormBuilder form={form} meta={meta} />
      </KoForm>
    </div>
  );
};

export default BulkCrud;
