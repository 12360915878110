import React from "react";
function Kanban(props) {
  const { width, height } = props;
  return (<svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    width={width}
    height={height}
  >
    <path
      d="M7 6c-.545 0-1 .455-1 1v7c0 .545.455 1 1 1h7c.545 0 1-.455 1-1V7c0-.545-.455-1-1-1H7zm11 0c-.545 0-1 .455-1 1v7c0 .545.455 1 1 1h7c.545 0 1-.455 1-1V7c0-.545-.455-1-1-1h-7zM7 7h7v7H7V7zm11 0h7v7h-7V7zM7 17c-.545 0-1 .455-1 1v7c0 .545.455 1 1 1h7c.545 0 1-.455 1-1v-7c0-.545-.455-1-1-1H7zm11 0c-.545 0-1 .455-1 1v7c0 .545.455 1 1 1h7c.545 0 1-.455 1-1v-7c0-.545-.455-1-1-1h-7zM7 18h7v7H7v-7zm11 0h7v7h-7v-7z"
      style={{
        lineHeight: "normal",
        textIndent: 0,
        textAlign: "start",
        textDecorationLine: "none",
        textDecorationStyle: "solid",
        textDecorationColor: "#000",
        textTransform: "none",
        blockProgression: "tb",
        isolation: "auto",
        mixBlendMode: "normal"
      }}
      color="#000"
      fontFamily="sans-serif"
      overflow="visible"
      fill="#000000"
      className="color000 svgShape"
    />
  </svg>
  );
}
export default Kanban;