import {KoAPI} from "packages";
import { configs } from 'configuration';
const version = configs().API_VERSION;
export const clientsAPI = {
  getClients(query){
    return KoAPI.get(`/api/${version}/admin/customer` , { query: { ...query } });
  },
  createOrUpdateClient(data, id) {
    if(id && id!=='CLI3103B7FE26'){
      return KoAPI.update(`/api/${version}/admin/customer/${id}`, { data });
    }else{
      return KoAPI.post(`/api/${version}/admin/customer`, { data });
    }
  
  },
  getClientById(id){
    return KoAPI.get(`/api/${version}/admin/customer/${id}`);
  },
  deleteById(id){
    return KoAPI.delete(`/api/${version}/admin/customer/${id}`);
  },
  getUsers(){
    return KoAPI.get(`/api/${version}/org/users`);
  }
};