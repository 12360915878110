import React from 'react';
import { Button } from 'antd';
import KoIcon from 'packages/icon';
import styles from './dp.module.scss';

export default function KoDP({logo, type, fallback, width,className, height, userId, name, shape}) {
  let dpurl =`${logo?.length > 0 ? logo[0].url : false}`;

  return (
    <div className={styles.wrapper}>
      <Button className={`${styles.btn} ${className ?className:''}`} shape={`${shape ? shape : 'circle'}`}
        icon={<KoIcon fallback={fallback} isImage={true} src={dpurl} name='UserFilled'
          width={width ? width : 16} height={height ? height: 16} />}/>
    </div>
  );
}
