import React, { useState, useEffect } from "react";
import {
  KoEditor, KoLoader, KoNano, KoToast
} from "packages";

import { FilesAPI } from "../files.service";
import { useNavigate, useParams } from "react-router";

import styles from './curd.module.scss';

function FileCurd() {
  const { folderId, fileId, workspaceId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [fileDetails, setFileDetails] = useState();
  const [jsonData, setJsonData] = useState();


  useEffect(() => {
    getFileById();
  }, []);

  useEffect(() => {
    if(fileDetails?.url){
      getFile(fileDetails?.url);
    }
  }, [fileDetails]);

  const getFile = async(url) => {
    setLoading(true);
    try {
      const response = await fetch(url);
      const result = await response.json();
      setJsonData(result['content']);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  };

  


  const getFileById = () => {
    setLoading(true);
    FilesAPI.getFileById(folderId, fileId, workspaceId).then(
      (data) => {
        setLoading(false);
        setFileDetails(data?.file);
      },
      (err) => {
        setLoading(false);
      }
    );
  };


  const handleSaveFile = (values) => {
    values['fileId'] = KoNano('FLE');
    values['type'] = 'docx';
    values['shared'] = [];
   
    setLoading(true);
    FilesAPI.createOrUpdateFile(values, folderId, workspaceId).then(
      (data) => {
        setLoading(false);
        KoToast.success('File created successfully');
        navigate(-1);
      },
      (err) => {
        setLoading(false);
        KoToast.error(err?.error);
      }
    );
  };

 
  return (
    <div className={styles.koEditor}>
      {isLoading && <KoLoader circle={true} />}
      {!isLoading && jsonData !== undefined && (
        <KoEditor
          initialValues={jsonData}
          onSaveFile={handleSaveFile}
          fileName={fileDetails?.name}
        />
      )}
      {!isLoading && jsonData === undefined && (
        <KoEditor
          initialValues={[
            {
              type: 'paragraph',
              children: [
                { text: '' }
              ]
            }
          ]}
          onSaveFile={handleSaveFile}
          fileName={fileDetails?.name}
        />
      )}
    </div>
  );



}

export default FileCurd;