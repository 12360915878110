import React, { useState, useEffect } from "react";
import { startOfWeek, endOfWeek } from "date-fns";

import Calendar from "./calendarBody";
import { CalendarHeader } from "./calendarHeader";
import { daysToWeekObject } from "./utils";

export function KoCalendar({
  events,
  onEventClick,
  onSelectDate,
  weekends = true,
  value,
  isOpen,
  setModal,
  currentRecord,
  setCurrentRecord,
  onCreateHuddle,
  setDeleteModal
}) {
  const [startWeek, setStartWeek] = useState(startOfWeek(value || new Date()));
  const weekPeriod = {
    startDate: startWeek,
    endDate: endOfWeek(startWeek)
  };

  useEffect(() => {
    if (value && startOfWeek(value).getTime() !== startWeek.getTime()) {
      setStartWeek(value);
    }
  }, [value]);

  useEffect(() => {
    onSelectDate && onSelectDate(startWeek);
  }, [startWeek]);

  const weekObject = daysToWeekObject(events, startWeek);

  return (
    <>
      <CalendarHeader onCreateHuddle={onCreateHuddle} startWeek={startWeek} setStartWeek={setStartWeek} />
      <Calendar
        weekDates={weekPeriod}
        getDayEvents={weekObject}
        onEventClick={onEventClick}
        onCreateHuddle={onCreateHuddle}
        weekends={weekends}
        currentRecord={currentRecord}
        setCurrentRecord={setCurrentRecord}
        setDeleteModal={setDeleteModal}
        isOpen={isOpen}
        setModal={setModal}
      />
    </>
  );
}
