import {KoAPI} from "packages";
import { configs } from 'configuration';

const version = configs().API_VERSION;

export const accountAPI = {
   
  login(data) {
    return KoAPI.post(`/api/${version}/account/login`, { data });
  },
  createAccount(data) {
    return KoAPI.post(`/api/${version}/account/signup`, { data });
  },
  verify(data){
    return KoAPI.update(`/api/${version}/account/verify`, { data });
  },
  resedOTP(data){
    return KoAPI.post(`/api/${version}/account/resend/otp`, { data });
  },
  forgotpassword(data){
    return KoAPI.post(`/api/${version}/account/forgot`, { data });
  },
  changePassword(data){
    return KoAPI.update(`/api/${version}/account/password/reset`, { data });
  },
  newPassword(data){
    return KoAPI.post(`/api/${version}/account/newpwd`, { data });
  },
  getAccounts(){
    return KoAPI.get(`/api/${version}/account/count`);
  },
  switchAccount(data){
    return KoAPI.post(`/api/${version}/account/switch`, { data });
  }
};