import {
  KoButton,
  KoCard,
  KoCol,
  // KoDropdown,
  // KoButton,
  KoIcon,
  KoLoader,
  KoModal,
  KoPagination,
  KoRow,
  KoStatistic,
  KoTable,
  KoText, KoTitle,
  KoTooltips
} from "packages";
import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { Link, useOutletContext, useParams } from "react-router-dom";
import { add, remove } from 'shared/store/breadcrumbSlice';
import { getCookie } from 'shared/utils/cookies';
import { PRO_ITEMS_COLUMNS } from "../constant";
import { ISSUE_STATUS_COLUMNS, ISSUE_STATUS_COLUMNS_BUG, SPR_ITEMS_COLUMNS } from "./constant";
import styles from './dashboard.module.scss';
import { DashboardAPI } from "./dashboard.service";
import HighPriorityTasks from "./highprioritytasks";
import { useSelector } from "react-redux";
import _find from 'lodash/find';
import { setSelectedChatData, setSelectedChatType } from "shared/store/chatSlice";

function Dashboard() {
  const dispatch = useDispatch();
  const { workspaceId } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [daysRemaining, setDaysRemaining] = useState();
  const [percentageByStatus, setPercentageByStatus] = useState();
  const [todoCountByStatus, setTodoCountByStatus] = useState();
  const [bugCountByStatus, setBugCountByStatus] = useState();
  const [ticketInprogressStatistics, setTicketInprogressStatistics] = useState();
  const [issueStatistics, setTicketCountByStatus] = useState();
  const [ticketByPriority, setTicketByPriority] = useState();
  const [currentRecord, setCurrentRecord] = useState();
  const [isStaticModel, setStaticModel] = useState(false);
  const [tickets, setTickets] = useState();
  const [copy, setCopy] = useState(false);
  const [totalCount, setTotalCount] = useState(100);
  const [pagination, setPagination] = useState({
    size: 20,
    page: 1
  });
  const [shareSprintId ] = useOutletContext();

  const workspace = useSelector(state => state?.workspace);
  let workspaceList = workspace['data'];
  const currentWorkspace = _find(workspaceList, function(o) { return o.workspaceId === workspaceId; });


  useEffect(()=>{
    dispatch(remove());
    dispatch(add([
      {
        title: 'workspace',
        path: 'workspace'
      },
      {
        title: currentWorkspace?.name
      },
      {
        title: 'Dashboard'
      }
    ]));

  }, [workspaceId]);

  useEffect(()=>{
    getTickets();
  }, []);



  useEffect(() => {
    getTicketCountByStatus(shareSprintId);
    getDahboardItems(shareSprintId);
    getPercentageByStatus(shareSprintId);
    getBugCountByStatus('todo', shareSprintId);
    getBugCountByStatus('inprogress', shareSprintId);
    getBugCountByStatus('bug', shareSprintId);
    getTicketByPriority(shareSprintId);
  }, [shareSprintId]);



  const getDahboardItems=(id)=>{
    setLoading(true);
    id && DashboardAPI.getDaysRemaining(id, workspaceId).then(
      (data) => {
        setLoading(false);
        setDaysRemaining(data['sprintStatus']);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const getTickets=()=>{
    setTickets();
    setTotalCount();
    setLoading(true);
    DashboardAPI.getIssues(pagination, workspaceId).then(
      (data) => {
        setTickets(data?.tickets);
        setLoading(false);
        setTotalCount(data?.totalCount);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const getPercentageByStatus=(id)=>{
    setLoading(true);
    id && DashboardAPI.getPercentageByStatus(id, workspaceId).then(
      (data) => {
        setPercentageByStatus(data['ticketStatus']);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };


  const getBugCountByStatus=(status, id)=>{
    setLoading(true);
    id && DashboardAPI.getIssuetypeCountByStatus(status, id, workspaceId).then(
      (data) => {
        if(status === 'todo'){
          setTodoCountByStatus(data['ticketStatus']);
        }else  if(status === 'inprogress'){
          setTicketInprogressStatistics(data['ticketStatus']);
        }else  if(status === 'bug'){
          setBugCountByStatus(data['ticketStatus']);
        }
        setLoading(false);
      },
      (err) => {
      }
    );
  };

  const getTicketCountByStatus = (id) => {
    setLoading(true);
    setTicketCountByStatus();
    id && DashboardAPI.getTicketCountByStatus(id, workspaceId).then(
      (data) => {
        setTicketCountByStatus(data?.totalCount);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };


  const getTicketByPriority=(id)=>{
    setLoading(true);
    id &&  DashboardAPI.getTicketByPriority(id,workspaceId).then(
      (data) => {
        setTicketByPriority(data['tickets']);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };


   
  function dominantProperty(data){
    const totalCount = Object.values(data).reduce((total, count) => total + count, 0);

    let maxPercentage = 0;
    let dominantProperty = '';

    for (const [key, value] of Object.entries(data)) {
      const percentage = (value / totalCount) * 100;
      if (percentage > maxPercentage) {
        maxPercentage = percentage;
        dominantProperty = key;
      }
    }
    return `${maxPercentage.toFixed(0)}% of  ${dominantProperty}s dominated`;
  }

  const onChangeType=(key)=>{
    let q =  pagination;
    if(key){
      q['type'] = key;
    }else{
      delete q['type'];
    }
    getTickets(q);
  };


  const handleStatisticClick = (statType) => {
    setCurrentRecord(statType);
    
    switch (statType) {
    case "bug":
      onChangeType('5');
      break;
    case "task":
      onChangeType('3');
      break;
    case "story":
      onChangeType('2');
      break;
    case "subtask":
      onChangeType('4');
      break;
    case "support":
      onChangeType('9');
      break;
    default:
      onChangeType('1');
      break;
    }
    
    setStaticModel(true);
  };

  const onChangeAssignees =(key)=>{
    let q =  pagination;
    if(key){
      q['assignee'] = key.toString();
    }else{
      delete q?.['assignee'];
    }
   
    getTickets(q);
  };

  const handleYetToStartClick = (key) => {
  
    setCurrentRecord(key);
    onChangeAssignees(key?.assignee);
    setStaticModel(true);
  };
  
  const onCopyTicket=(row)=>{
    setCopy(true);
    navigator.clipboard.writeText(`${window.location.origin}/open/ticket/${getCookie("accountId")}/${row?.workspaceId}/${row?.ticketId}`);
  };

  const onCellClick=(row)=>{
    const updatedRoomObj = { ...row, source: 'tickets' };
    dispatch(setSelectedChatData(updatedRoomObj));
    dispatch(setSelectedChatType('tickets'));
    setCurrentRecord(row);
    setStaticModel(true);
  };

  const columnsRight = [{
	  title: '',
	  dataIndex: 'x',
	  key: 'x',
	  fixed: 'right',
	  width: 40,
	  render: (data, row) => {
	    return <div className={`${styles.circle} tableBtn columnsRight`} onClick={() => setCurrentRecord(row)}>
        <KoTooltips title={copy ? 'Copied' : 'Copy'}>
	      <KoButton onClick={()=>onCopyTicket(row)} className={'btn'} iconBtn={true} icon={<KoIcon width={14} height={14} name={'CopyOutlined'}/>}/>
        </KoTooltips>

        <KoTooltips title={'New window'}>
          <Link target="_blank" to={`/open/ticket/${getCookie("accountId")}/${row?.workspace}/${row?.ticketId}`}>
            <KoButton className={'btn'}  iconBtn={true} icon={<KoIcon width={14} height={14} name={'OpenOutlined'}/>}/>
          </Link>
        </KoTooltips>
	    </div>;
	  }
  }];


  const onPagination = (page, other) => {
    const query = {
      size: other,
      page: page
     
    };
    const p = Object.assign(pagination, query);
    setPagination(p);
    getTickets(p);
  };


  return (
    <div className={`${styles.wrapper} ${!shareSprintId && styles.outletBlurWrapper}`}>
      { isLoading && <KoLoader />}
      {/* <div className={styles.dataFilter}>
        <KoDropdown
        
          menu={{
            items: FILTER,
            onClick: (event) => {}
          }}
          overlayClassName={styles.actionDropdown}
          placement="bottomRight">
          <KoButton iconBtn={true} icon={ <KoIcon width={14} height={14} name={`FilterOutlined`}/>}/>
        </KoDropdown>
      </div> */}
      <div className={styles.statistic}>
        <KoCard  className={styles.stats}>
          <KoTitle text='Days Remaining' level={5} />
          <KoText
            className={styles.liteText}
            text={daysRemaining?.name < 0 ? '0' : (daysRemaining?.name ?? '-')}
            level={6}
          />


          <div className={styles.daysRemaining}>
            <KoTitle
              text={daysRemaining?.remainingDays < 0 ? '0' : (daysRemaining?.remainingDays ?? 'No sprint')}
              className={`${shareSprintId ? styles.count : styles.nosprint}`}
              level={5}/>

            {
              daysRemaining?.remainingDays >= 0&&
            <div className={styles.remainingText}>
              <div className={styles.calIcon}>
                <KoIcon name='CalendarOutlined' color="#FFF"/>
              </div>
             
              <KoText className={styles.liteText} text={`Days Remaining(s)`} />
            </div>
            }
          </div>
        </KoCard>
        <KoCard  className={styles.stats}>
          <KoTitle  text='Issue Statistics' level={5}/>
          <div className={styles.graphStatisticCount}>
            <KoTable
              maxHeight={25}
              rowKey='issueStatistics'
              data={{ columns: SPR_ITEMS_COLUMNS, rows: percentageByStatus }} />
          </div>
        </KoCard>
        <KoCard  className={styles.stats}>
          <KoTitle text='Current Sprint’s Deadline' level={5} />
          {issueStatistics&&  <KoText className={styles.liteText} text={dominantProperty(issueStatistics)} />}
          <div className={styles.graphStatistic}>
            <KoRow gutter={16}>
              <KoCol span={8} >
                <KoStatistic
                  onClick={() => handleStatisticClick("bug")}
                  title="Bugs"
                  value={issueStatistics?.bug}
                  prefix={<KoIcon name={"BugFilled"} height={17} width={17} />} />
              </KoCol>

              <KoCol span={8}>
                <KoStatistic
                  onClick={() => handleStatisticClick("task")}
                  title="Task"
                  value={issueStatistics?.task}
                  prefix={<KoIcon name={"TaskFilled"} height={17} width={17} />} />
              </KoCol>
              <KoCol span={8}>
                <KoStatistic
                  onClick={() => handleStatisticClick("query")}
                  title="Query"
                  value={issueStatistics?.query}
                  prefix={<KoIcon name={"QueryFilled"} height={17} width={17} />} />
              </KoCol>
            </KoRow>
            <KoRow gutter={16}>
              <KoCol span={8}>
                <KoStatistic
                  onClick={() => handleStatisticClick("story")}
                  title="Story"
                  value={issueStatistics?.story}
                  prefix={<KoIcon name={"StoryFilled"} height={17} width={17} />} />
              </KoCol>
              <KoCol span={8}>
                <KoStatistic
                  onClick={() => handleStatisticClick("subTask")}
                  title="Sub Task"
                  value={issueStatistics?.subTask}
                  prefix={<KoIcon name={"SubtaskFilled"} height={17} width={17} />} />
              </KoCol>
              <KoCol span={8}>
                <KoStatistic
                  onClick={() => handleStatisticClick("support")}
                  title="Support"
                  value={issueStatistics?.support}
                  prefix={<KoIcon name={"SupportFilled"} height={17} width={17} />} />
              </KoCol>
            </KoRow>
          </div>
        </KoCard>
      </div>
      <div className={styles.teamStatus}>
        <KoCard  className={styles.stats}>
          <KoTitle text='Yet To Start' level={5} className={styles.tableTitle}/>
          <div className={styles.graphDonut}>
            <KoTable
              maxHeight={37}
              rowKey='assignee'
              onCellClick={handleYetToStartClick}
              data={{ columns: ISSUE_STATUS_COLUMNS, rows: todoCountByStatus }} />
          </div>
        </KoCard>
        <KoCard  className={styles.stats}>
          <KoTitle text='In Progress' level={5} className={styles.tableTitle}/>
          <div className={styles.graphDonut}>
            <KoTable
              maxHeight={37}
              onCellClick={onCellClick}
              rowKey='assignee'
              data={{ columns: ISSUE_STATUS_COLUMNS, rows: ticketInprogressStatistics }} />
          </div>
        </KoCard>
        <KoCard  className={styles.stats}>
          <KoTitle text='Open Defects - Current Sprint' className={styles.tableTitle} level={5} />
          <div className={styles.graphDonut}>
            <KoTable
              maxHeight={37}
              onCellClick={onCellClick}
              rowKey='assignee'
              data={{ columns: ISSUE_STATUS_COLUMNS_BUG, rows: bugCountByStatus }} />
          </div>
        </KoCard>
        <KoCard  className={styles.stats}>
          <HighPriorityTasks data={ticketByPriority}/>
        </KoCard>
      </div>

      
      <KoModal
        title={`${currentRecord}`}

        centered
        open={isStaticModel}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); }}
        onClose={() => { setCurrentRecord(); setStaticModel(false);}}
        width={`65vw`}
        // maskClosable={false}
        height={850}
        footer={false}
      >
        <KoTable rowKey='ticketId'
          onCellClick={onCellClick}
          data={{ columns: PRO_ITEMS_COLUMNS, rows: tickets }}
          extraColumnsRight={columnsRight} maxHeight={36} />
        <KoPagination current={parseInt(pagination.page) || 1}
          total={totalCount}
          showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} records`
          }
          pageSize={pagination.limit}
          defaultCurrent={pagination.page}
          onChange={onPagination}
          showQuickJumper={false}
          simple={false}
          responsive={false} />
      </KoModal>
    </div>
  );
}

export default Dashboard;