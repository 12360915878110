import React from "react";
function Upload(props) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      clipRule="evenodd"
      viewBox="0 0 64 64"
      width={width}
      height={height}>
      <path fill="none" d="M0 0H64V64H0z" />
      <path fill="#ffff" d="M12.323 25.427C13.685 20.115 19.237 16 26 16c4.579 0 8.622 1.889 11.158 4.746A10.124 10.124 0 0141 20c3.599 0 6.654 1.844 8.094 4.404C54.22 25.771 58 30.447 58 36c0 6.623-5.377 12-12 12h-4a2 2 0 010-4h4c4.415 0 8-3.585 8-8a8.005 8.005 0 00-6.637-7.884 2 2 0 01-1.541-1.289C45.204 25.126 43.241 24 41 24c-1.243 0-2.389.352-3.286.959a1.999 1.999 0 01-2.804-.579C33.217 21.737 29.843 20 26 20c-5.053 0-9.346 3.002-9.934 7.082a2 2 0 01-1.196 1.554A8.009 8.009 0 0010 36c0 4.415 3.585 8 8 8h4a2 2 0 010 4h-4c-6.623 0-12-5.377-12-12 0-4.57 2.56-8.547 6.323-10.573z" />
      <path fill="#ffff" d="M30 44v12a2 2 0 004 0V44a2 2 0 00-4 0z" />
      <path fill="#ffff" d="M32 46.828l-3.586 3.586a2 2 0 11-2.828-2.828l5-5a2 2 0 012.828 0l5 5a2 2 0 11-2.828 2.828L32 46.828z" />
    </svg>
  );
}

export default Upload;
