import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import KoToolBar from "shared/components/toolbar";
import {
  KoIcon, KoAffix, KoFlex, KoDropdown, KoText, KoModal, KoForm, KoButton,
  KoLoader, KoEmpty,
  KoFolderCrud,
  KoToast,
  KoNano,
  KoTitle,
  KoDateFormatter
} from "packages";
import InvoiceCrud from './curd';
import { InvoicesAPI } from 'pages/invoices/invoice.service';
import styles from "./invoiceitem.module.scss";
import InvoiceShareCrud from './invoiceShareCrud';
import ApprovalsCurd from './approvalsCurd';
import SendCurd from './sendCurd';
import RecurringCurd from './recurringCurd';
import UploadCrud from './uploadCrud';
// import { INV } from './constant';


function InvoiceList(props) {
  let { folderId, workspaceId } = useParams();
  const [isFolderOpen, setFolderModal] = useState(false);
  const [isInvoiceOpen, setInvoiceModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [invoiceList, setInvoices] = useState();
  const [isInvoiceShareOpen, setInvoiceShareModal] = useState(false);
  const [isOpen, setDrawer] = useState(false);
  const [isOpenDelete, setDeleteModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState();
  const [isOpenApproval, setApprovalModal] = useState(false);
  const [isUploadOpen, setUploadModal] = useState(false);
  const [isOpenSend, setSendModal] = useState(false);
  const [isOpenRecurring, setRecurring] = useState(false);
  const [form] = KoForm.useForm();

  useEffect(() => {
    getInvoices();
  }, []);

  const getInvoices = () => {
    setLoading(true);
    Promise.all([
      InvoicesAPI.getInvoices(folderId, workspaceId),
      InvoicesAPI.getUploadInvoices(folderId, workspaceId)
    ])
      .then(([invoicesData, uploadInvoicesData]) => {
        const combinedInvoices = [
          ...invoicesData['invoices'],
          ...uploadInvoicesData['invoices']
        ];
        setInvoices(combinedInvoices);
        setLoading(false);
      }).then();
  };
  


  const items = [
    {
      label: "Download",
      key: "1",
      icon: <KoIcon name="CloudDownloadOutlined" width={13} />
    },
    {
      label: "Edit",
      key: "3",
      icon: <KoIcon name="EditOutlined" height={12} width={13} />
    },
    
    {
      label: "Share Document",
      key: "4",
      icon: <KoIcon name="ShareOutlined" height={14} width={13} />
    },
    {
      type: 'divider'
    },
    {
      label: "Delete",
      key: "5",
      danger: true,
      icon: <KoIcon name="DeleteOutlined" />
    }
  ];

  const onClickInvoice = ({key}, project) => {

    setCurrentRecord(project);
    if(key === "1"){
      // setDeleteModal(true);
    } else if(key === '2'){
      // setDeleteModal(true);
    }
    else if(key === '3'){
      project?.invoiceFile ? setUploadModal(true) : setInvoiceModal(true);
    }
    else if(key === '4'){
      setInvoiceShareModal(true);
    }
    else if(key === '5'){
      setDeleteModal(true);
    }

  };

  const handleFolderFinish = (values => {
  });

 
  const onCreateInvoice = async() => {
    setInvoiceModal(true);
  };

  const handleInvoiceFinish = (values => {
    setLoading(true);
    if(!currentRecord?.invoiceId){
      values['invoiceId'] = KoNano('INV');
    }

    InvoicesAPI.createOrUpdateInvoices(values, currentRecord?.invoiceId, folderId,workspaceId).then(
      (data) => {
        getInvoices();
        setInvoiceModal(false);
        setLoading(false);
        KoToast.success(`Invoice ${currentRecord?.invoiceId ? 'updated' : 'created' } successfully`);
      },
      (err) => {
        setLoading(false);
        KoToast.error(err?.error);
      }
    );
  });

  const handleInvoiceShare = (values => {
    setLoading(true);
    
    const updatedValues = {
      ...currentRecord,
      users: values['users'],
      groups: values['groups']
    };
    InvoicesAPI.createOrUpdateInvoices(updatedValues, currentRecord?.invoiceId, folderId, workspaceId).then(
      (data) => {
        getInvoices();
        setInvoiceModal(false);
        setLoading(false);
        KoToast.success(`Invoice ${currentRecord?.invoiceId ? 'updated' : 'created' } successfully`);
      },
      (err) => {
        setLoading(false);
        KoToast.error(err?.error);
      }
    );
  });


  const handleUploadInvoiceFinish = (values => {
    setLoading(true);
    if(!currentRecord?.invoiceId){
      values['invoiceId'] = KoNano('INV');
    }

    InvoicesAPI.uploadInvoice(values, currentRecord?.invoiceId, folderId, workspaceId).then(
      (data) => {
        getInvoices();
        setInvoiceModal(false);
        setLoading(false);
        KoToast.success(`Invoice Uploaded successfully`);
        setUploadModal(false);
      },
      (err) => {
        setLoading(false);
        KoToast.error(err?.error);
      }
    );
  });

  const handleInvoiceDelete = () => {
    setLoading(true);
    InvoicesAPI.deleteInvoice(folderId, currentRecord?.invoiceId,workspaceId).then(
      (data) => {
        getInvoices();
        setDeleteModal(false);
        setLoading(false);
        KoToast.success(`Invoice deleted successfully! ${currentRecord.name}!`);
      },
      (err) => {
        KoToast.error(`error`);
      }
    );
  };

  return (
    <div className={styles.wrapper}>
      {isLoading && <KoLoader/>}
      <KoAffix top={56}>
        <KoToolBar
          className={styles.toolbar}
          title={"Invoices "}
          search={{
            visible: true,
            onChange: () => {},
            placeholder: "Search by invoice name"
          }}
          buttonList={[
            {
              label: "Create Invoice",
              type: "primary",
              icon: (
                <KoIcon
                  color="#FFF"
                  name="PlusOutlined"
                  width={18}
                  height={18}
                />
              ),
              onClick: () => onCreateInvoice()
            },
            {
              label: "Upload Invoice",
              type: "primary",
              icon: (
                <KoIcon
                  color="#FFF"
                  name="UploadOutlined"
                  width={18}
                  height={18}
                />
              ),
              onClick: () => setUploadModal(true)
            }
             
          ]}
        />
      </KoAffix>
      
      {invoiceList?.length > 0 ?  <KoFlex wrap="wrap" gap="small" className={styles.quickAccess}>
        {invoiceList?.map((project, i) => (
          <div key={project?.invoiceId} className={`${styles.card} ${styles.link}`}>
            <div className={styles.actions} >
              <KoDropdown
                placement="bottomRight"
                overlayClassName={styles.actionsLi}
                menu={{
                  items: items,
                  onClick:(e)=>onClickInvoice(e, project, i)
       
                }}>
                <div onClick={(e) => e.preventDefault()}>
                  <KoIcon name="VerticalMenuOutlined" />
                </div>
              </KoDropdown>
            </div>
            <Link to={`/${workspaceId}/invoices/${folderId}/view/${project.invoiceFile ? 'upload/' : ''}${project.invoiceId}`}>
              <div className={styles.fineName} onClick={ () => setDrawer(!isOpen)}>
                <div className={styles.image}>
                  <KoIcon name='InvoicesOutlined' width={70} height={90}/>
                </div>
              </div>
              <div className={styles.footer}>
                <KoText ellipsis={true} className={styles.projectName} text={project?.name} />
                <KoDateFormatter format='DD-MMM-YYYY' data={project?.createdAt} className={styles.mimeType}/>
              </div>
            </Link>
          </div>
             
        ))}
      </KoFlex>: <KoFlex wrap="wrap" gap="small" className={styles.emptySection}>
        <KoEmpty onClick={()=>onCreateInvoice('1')} label="Create Invoice" icon={'EmptyTwoTone'} title="Create Invoice"/>
      </KoFlex>}
      

      <KoModal
        title={currentRecord?.id ? `Update Folder ${currentRecord?.randaomId?.toUpperCase()}` : 'New Folder'}
        centered
        open={isFolderOpen}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setFolderModal(false); }}
        onClose={() => { setCurrentRecord(); setFolderModal(false); }}
        width={680}
        // maskClosable={false}
        height={650}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setFolderModal(!isFolderOpen)} />,
          <KoButton key='create' loading={isLoading} type='primary' label={`${currentRecord?.randaomId ? 'Update' : 'Create'}`} onClick={() => form.submit()} />
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <KoFolderCrud form={form} handleFinish={handleFolderFinish}/>
      </KoModal>

      <KoModal
        title={'Upload Invoice'}
        centered
        open={isUploadOpen}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setUploadModal(false); }}
        onClose={() => { setCurrentRecord(); setUploadModal(false); }}
        width={680}
        // maskClosable={false}
        height={650}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setUploadModal(!isUploadOpen)} />,
          <KoButton key='create' loading={isLoading} type='primary' label={`${currentRecord?.randaomId ? 'Update' : 'Create'}`} onClick={() => form.submit()} />
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <UploadCrud form={form} handleFinish={handleUploadInvoiceFinish} initialValues={currentRecord}/>
      </KoModal>

      <KoModal
        title={<div className={styles.titleActions}>
          <KoTitle text={currentRecord?.invoiceId ? `Update Invoice ${currentRecord?.name?.toUpperCase()}` : 'New Invoice'} level={5}/>
          <div className={styles.actionsBtn}>
            <KoButton onClick={()=>setApprovalModal(true)} type={'primary'} label='Approval'/>
            <KoButton onClick={()=>setRecurring(true)} type={'primary'} label='Recurring Invoice'/>
            <KoButton onClick={()=>setSendModal(true)} type={'primary'} label='Send Invoice'/>
          </div>
        </div>}
        centered
        open={isInvoiceOpen}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setInvoiceModal(false); }}
        onClose={() => { setCurrentRecord(); setInvoiceModal(false); }}
        width={'100%'}
        // maskClosable={false}
        height={'95.5%'}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setInvoiceModal(!isInvoiceOpen)} />,
          <KoButton key='create' loading={isLoading} type='primary' label={`${currentRecord?.invoiceId ? 'Update' : 'Create'}`} onClick={() => form.submit()} />
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <InvoiceCrud form={form} initialValues={currentRecord} handleFinish={handleInvoiceFinish}/>
      </KoModal>

      <KoModal
        title={'Share Invoice'}
        centered
        open={isInvoiceShareOpen}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setInvoiceShareModal(false); }}
        onClose={() => { setCurrentRecord(); setInvoiceShareModal(false); }}
        width={560}
        // maskClosable={false}
        height={650}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setInvoiceShareModal(!isInvoiceShareOpen)} />,
          <KoButton key='create' loading={isLoading} type='primary' label={'Share'} onClick={() => form.submit()} />
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <InvoiceShareCrud form={form} initialValues={currentRecord} handleFinish={handleInvoiceShare}/>
      </KoModal>

      <KoModal
        title={`Delete Invoice ${currentRecord?.name?.toUpperCase()}`}
        centered
        open={isOpenDelete}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setDeleteModal(false); }}
        onClose={() => { setCurrentRecord(); setDeleteModal(false); }}
        width={580}
        // maskClosable={false}
        height={450}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setDeleteModal(!isOpenDelete)} />,
          <KoButton key='reset' loading={isLoading} type='primary' label='Delete' onClick={() => handleInvoiceDelete()} />
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <div className={styles.delWrapper}>
          <KoTitle level={5} text={`Are you sure you want to delete this invoice?`} />
          <KoText text={`This action is irreversible, and it will result in the removal of their associated data and Kwapio license.`} />
        </div>
      </KoModal>

      <KoModal
        title={`Approval Request`}
        centered
        open={isOpenApproval}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setApprovalModal(false); }}
        onClose={() => { setCurrentRecord(); setApprovalModal(false); }}
        width={580}
        // maskClosable={false}
        height={450}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setApprovalModal(!isOpenApproval)} />,
          <KoButton key='reset' loading={isLoading} type='primary' label='send'/>
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <ApprovalsCurd form={form} />
      </KoModal>

      <KoModal
        title={`Send Invoice`}
        centered
        open={isOpenSend}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setSendModal(false); }}
        onClose={() => { setCurrentRecord(); setSendModal(false); }}
        width={580}
        // maskClosable={false}
        height={450}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setSendModal(!isOpenSend)} />,
          <KoButton key='reset' loading={isLoading} type='primary' label='send'/>
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <SendCurd form={form} initialValues={currentRecord}/>
      </KoModal>

      <KoModal
        title={`Recurring Invoice`}
        centered
        open={isOpenRecurring}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setRecurring(false); }}
        onClose={() => { setCurrentRecord(); setRecurring(false); }}
        width={580}
        // maskClosable={false}
        height={450}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setRecurring(!isOpenRecurring)} />,
          <KoButton key='reset' loading={isLoading} type='primary' label='send'/>
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <RecurringCurd form={form} />
      </KoModal>


    </div>
  );
}

export default InvoiceList;
