import React, { useCallback, useEffect, useState } from 'react';
import { KoFormBuilder, KoSelect, KoForm, Upload, KoIcon, KoText, KoTitle, Checkbox, KoMobile, KoToast, KoModal, KoButton, KoLoader, KoImage, KoInput, KoUserSelect } from 'packages';
import { getCookie } from 'shared/utils/cookies';
import _set from 'lodash/set';
import ImgCrop from 'antd-img-crop';

import { OrgAPI } from '../org.service';

import styles from './employees.module.scss';
import { useSelector } from 'react-redux';


function EmployeesCrud({ form, handleFinish, onValuesChange, initialValues, setModal, getOrgUsers }) {
  const [groups, setGroups] = useState();
  const users = useSelector(state => state?.users);
  const designations = useSelector(state => state?.designations);
  const [isOpenActivate, setActivateModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [currentRecord, setCurrentRecord] = useState();
  const autoGenerate = KoForm.useWatch('autoGenerate', form);


  useEffect(() => {
    getGroups();
  }, []);

  useEffect(() => {
    if (!initialValues || Object.keys(initialValues).length === 0) {
      form.resetFields();
    } else {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  const getGroups = () => {
    OrgAPI.getOrgGroups().then(
      (data) => {
        setGroups(data['groups']);
      },
      (err) => {
      }
    );
  };


  const handleSubmit = useCallback(values => {
    handleFinish(values);
  });

  const handleFinishActivate = () => {
    setLoading(true);
    let obj = {
      userId: [currentRecord?.userId],
      status: 1
    };
    OrgAPI.statusUpdate(obj).then(
      (data) => {
        setLoading(false);
        setActivateModal(false);
        setModal(false);
        getOrgUsers();
        KoToast.success(`Profile activated successfully! ${currentRecord.profile?.firstName}!`);
      },
      (err) => {
        setLoading(false);
        KoToast.error(`error`);
      }
    );
  };

  const options = [
    { label: 'Automatically generate  a password', value: 'Yes' },
    { label: 'Create password', value: 'No' }];

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e?.fileList;
  };

  const Designations = ({ value, onChange }) =>

    <KoSelect value={value}
      placeholder={"Select designation"}
      onChange={v => onChange(v)}
      showSearch
      options={designations['data']?.map((dept) => ({
        label: dept?.name,
        value: dept?.designationId
      }))} />;

  const Members = ({ value, onChange }) =>
    <KoUserSelect value={value} onChange={v => onChange(v)} users={users['data']} placeholder='Select Department Head'/>;


  const Groups = ({ value, onChange }) =>

    <KoSelect mode="multiple" value={value}
      showSearch
      onChange={v => { onChange(v); }}
      placeholder='Select Group'
      options={groups?.map((group) => ({
        label: group.name,
        value: group?.groupId,
        disabled: group.name === 'Everyone'
      }))}
    />;


  const RolesSelect = ({ value, onChange }) => {
    const roles = [
      { label: "Account Owner", value: 1, disabled: true },
      { label: "Super Admin", value: 2 },
      { label: "IAM Admin", value: 3 },
      { label: "Workspace Admin", value: 4 },
      { label: "User Admin", value: 5 },
      { label: "Billing Admin", value: 6 },
      { label: "User", value: 7 }
    ];
    return (
      <KoSelect
        mode="multiple"
        value={value}
        showSearch
        onChange={v => onChange(v)}
        placeholder="Select Role"
        options={roles}
      />
    );
  };


  const getUserByMailId = (value) => {
    setActivateModal(true);
    let ActiveUser = users?.data?.find(user => user.email === value);
    setCurrentRecord(ActiveUser);
  };

  const emailVerify = async(value) => {
    const userList = users?.data?.map(e => e.email);

    if (userList.includes(value)) {
      setTimeout(() => getUserByMailId(value), 1000);

      return Promise.reject(new Error('Mail id already exists!'));
    }
    return Promise.resolve();
  };


  const EmailInput = ({ value, onChange }) =>
    <KoInput
      type="email"
      value={value}
      placeholder={'Enter Email'}
      autoComplete="off"
      onChange={(e) => onChange(e.target.value)}
    />;

  

  const meta = {
    columns: 4,
    formItemLayout: null,
    initialValues: {
      autoGenerate: 'Yes'
    },
    colon: true,
    fields: [
      { key: 'profile.firstName', placeholder: 'Enter First Name', label: 'First Name', colSpan: 2, rules: [
        {
          required: true,
          pattern: new RegExp(/^[a-zA-Z]{1,}$/),
          message: `Enter new profile's first name.`
        }
      ] },
      { key: 'profile.lastName', label: 'Last Name', placeholder: 'Enter Last Name',  rules: [
        {
          required: true,
          pattern: new RegExp(/^[a-zA-Z]{1,}$/),
          message: `Enter new profile's last name.`
        }
      ],colSpan: 2 },
      {
        key: 'email', label: 'Email', colSpan: 2, placeholder: 'Enter Email',
        widget: EmailInput, rules: [
          {
            required: true,
            message: `Enter new profile's email.`
          },{
            pattern: new RegExp(/^[\w-.]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/g),
            message: 'Enter a valid email.'
          },

          {
            validator: async(_, value) => await emailVerify(value)
          }
          
        ]
      },
      { key: 'mobile', colSpan: 2, label: 'Phone No',  placeholder: 'Ex. 98765*****', widget:KoMobile  },
      {
        key: 'designation', label: 'Designation', colSpan: 2, widget: Designations,
        rules: [
          {
            required: true,
            message: 'Designation is required'
          }
        ]
      },
      {
        key: 'reportingTo', label: 'Reporting To', colSpan: 2,  widget: Members, rules: [
          {
            required: true,
            message: 'Reporting To is required'
          }
        ]
      },
    
      {
        key: 'groups', label: 'Groups', colSpan: 2,
        widget: Groups
      },
      {
        key: 'roles', label: 'Roles', colSpan: 2,widget: RolesSelect
      },

      {
        key: 'label1',
        colSpan: 4,
        render() {
          return (
            <fieldset>
              <legend>PASSWORD</legend>
            </fieldset>
          );
        }
      },
      {
        colSpan: 4,
        key: 'autoGenerate',
        widget: 'radioGroup',
        direction: 'horizontal',
        options
      }
     
    ]
  };
  if (autoGenerate === 'No') {
    meta['fields'].push( { key: 'password', placeholder:'Enter password', label: 'Set password', colSpan: 2, widget: 'password', rules: [
      {
        required: true,
        message: 'Password is required'
      }
    ] });
    meta['fields'].push( { key: 'isChangePassword', colSpan: 4, widget: Checkbox.Group, options:[{label:'Ask user to change their password when they sign in', value: true}] });
  }

  const meta2 = {
    columns: 4,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      { key: 'profile.firstName', placeholder: 'Enter First Name', label: 'First Name', colSpan: 2, rules: [
        {
          required: true,
          message: `Enter new profile's first name.`
        }
      ] },
      { key: 'profile.lastName', label: 'Last Name', placeholder: 'Enter Last Name',  rules: [
        {
          required: true,
          message: `Enter new profile's last name.`
        }
      ],colSpan: 2 },
      {
        key: 'email', label: 'Email', colSpan: 2, placeholder: 'Enter Email', rules: [
          {
            required: true,
            message: `Enter new profile's email.`
          }
        ]
      },
      { key: 'mobile', colSpan: 2, label: 'Phone No',  placeholder: 'Ex. 98765*****', widget:KoMobile  },
      {
        key: 'designation', label: 'Designation', colSpan: 2, widget: Designations,
        rules: [
          {
            required: true,
            message: 'Designation is required'
          }
        ]
      },
      {
        key: 'reportingTo', label: 'Reporting To', colSpan: 2,  widget: Members, rules: [
          {
            required: true,
            message: 'Reporting To is required'
          }
        ]
      },
      {
        key: 'groups', label: 'Groups', colSpan: 2,
        widget: Groups
      },
      {
        key: 'roles', label: 'Roles', colSpan: 2,widget: RolesSelect
      }
    ]
  };

  const updateProfile=(values)=>{
    setLoading(true);
    OrgAPI.createOrgUsers(values, initialValues?.userId).then(
      (data) => {
        setLoading(false);
        KoToast.success(`Profile updated successfully`);
        getOrgUsers();
      },
          
      (err) => {
        setLoading(false);
        err.error === 'this user already exists please contact your admin' && KoToast.error(`User already exists`);
        KoToast.error(`error`);
      }
    );
  };

  const props = {
    name: 'files',
    action: `${process.env.REACT_APP_PROXY_HOST}/api/v1/org/upload/profile/${initialValues?.userId}`,
    data:{service:'organization', module:'user'},
    headers: {
      Authorization: `Bearer ${getCookie("accessToken")}`
    },
    onChange(info) {
      setLoading(true);
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'done') {
        const file = info.file;
        file['url'] = file.response[0];
        _set(initialValues, 'profile.logo', [file]);
        form.setFieldsValue(initialValues);
        updateProfile(initialValues);
        setLoading(false);
      } else if (info.file.status === 'error') {
        setLoading(false);
        KoToast.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      KoToast.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      KoToast.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const uploadButton = (
    <div className={styles.dndArea}>
      {!initialValues?.profile?.logo ? <>
        <KoIcon name='ImagesOutlined'/>
        {isLoading && <KoLoader size='small'/>}
        <KoText className={styles.fileInfo} text='At least 256 x 256px PNG or JPG file'/>
      </>:
        initialValues?.profile?.logo?.length > 0 && <KoImage preview={false} src={initialValues?.profile?.logo[0].url}/>}
    </div>
  );

  const onRemoveFile = (d) => {
    let obj = {
      path: `${getCookie('wos')}/taskplanner/${initialValues?.lookupId}/`,
      files: [d?.uid]
    };
    OrgAPI.deleteAttachments(obj).then(
      (data) => {
      },
      (err) => {
      }
    );
  };

  
  return (
    <div className={styles.wrapper}>
      {isLoading && <KoLoader />}
      <KoForm  onValuesChange={onValuesChange} form={form} layout="vertical" onFinish={handleSubmit} onSubmit={handleSubmit} >
       
        <KoForm.Item
          name={["profile", "logo"]}
          valuePropName={["profile", "logo"]}
          getValueFromEvent={normFile}

        >
          <ImgCrop>
            <Upload {...props}
              className={styles.upload}
              beforeUpload={beforeUpload}
              onRemove={onRemoveFile}
              max={{ count: 1 }}
              showUploadList={false}
              showPreviewIcon={false}
              listType="picture-card"
            >
              {uploadButton}
            </Upload>
          </ImgCrop>
        </KoForm.Item>
        <KoFormBuilder form={form} meta={initialValues?.userId ? meta2 : meta} />
      </KoForm>

      <KoModal
        title={`Activate user ${currentRecord?.profile?.firstName?.toUpperCase()}`}
        centered
        open={isOpenActivate}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setActivateModal(false); }}
        onClose={() => { setCurrentRecord(); setActivateModal(false); }}
        width={580}
        // maskClosable={false}
        height={450}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setActivateModal(!isOpenActivate)} />,
          <KoButton key='reset' loading={isLoading} type='primary' label='Activate' onClick={() => handleFinishActivate()} />
        ]}
      >
        <div className={styles.suspend}>
          <KoTitle level={5} text='License fees apply to activated users,' />
          <KoText text={`granting access to all paid services.`} />
        </div>
      </KoModal>
    </div>
  );
};

export default EmployeesCrud;