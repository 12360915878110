import React from "react";
function Skype(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width={width} height={height} fill="#637481">
      <path d="M45.33 32.605A23 23 0 0015.395 2.67 9 9 0 102.67 15.395 23 23 0 0032.605 45.33 9 9 0 1045.33 32.605zM39 46a6.987 6.987 0 01-5.366-2.508 1 1 0 00-1.17-.273A21 21 0 014.781 15.536a1 1 0 00-.273-1.17 7 7 0 119.857-9.857 1 1 0 001.17.273 21 21 0 0127.684 27.682 1 1 0 00.273 1.17A7 7 0 0139 46z" />
      <path d="M26 13h-4a5.006 5.006 0 00-5 5v2a5.006 5.006 0 005 5h4a3 3 0 013 3v2a3 3 0 01-3 3h-4a3 3 0 01-3-3v-2a1 1 0 00-2 0v2a5.006 5.006 0 005 5h4a5.006 5.006 0 005-5v-2a5.006 5.006 0 00-5-5h-4a3 3 0 01-3-3v-2a3 3 0 013-3h4a3 3 0 013 3v2a1 1 0 002 0v-2a5.006 5.006 0 00-5-5z" />
    </svg>
  );
}
export default Skype;