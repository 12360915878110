import React from "react";
function Home(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 56 56">
      <path fill="#000"
        d="m51.69 27.28-23-22a1 1 0 0 0-1.38 0l-23 22a1 1 0 1 0 1.38 1.44L28 7.38l22.31 21.34a1 1 0 0 0 1.41 0 1 1 0 0 0-.03-1.44z"></path>
      <path fill="#000"
        d="M45 29a1 1 0 0 0-1 1v19H34V38a1 1 0 0 0-1-1H23a1 1 0 0 0-1 1v11H12V30a1 1 0 0 0-2 0v20a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V39h8v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V30a1 1 0 0 0-1-1z"></path>
    </svg>
  );
}


export default Home;