import { Checkbox, KoDate, KoForm, KoFormBuilder, KoSelect, KoTextArea } from 'packages';
import React, { useCallback, useEffect } from 'react';
import styles from './holiday.module.scss';
import { DAYS } from './constant';
const HolidayCrud = ({ form, handleFinish, initialValues }) => {
  useEffect(() => {
    if (!initialValues || Object.keys(initialValues).length === 0) {
      form.resetFields();
    } else {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);
    
  const handleSubmit = useCallback(values => {
    handleFinish(values);
  });

  const Date = ({ value, onChange }) =>
    <KoDate value={value} format = {'DD-MMM-YYYY'}
      onChange={v => onChange(v)} />;

  const CheckBox = ({ value, onChange }) =>
    <Checkbox checked={value} onChange={v => onChange(v?.target?.checked)}>{`It's optional`}</Checkbox>;

  const DaysList = ({ value, onChange }) =>
    <KoSelect value={value}
      showSearch
      onChange={v => { onChange(v); }}
      placeholder='Select Day'
      options={DAYS?.map((day) => ({
        label: day?.name,
        value: day?.id
      }))}
    />;

  const meta = {
    columns: 4,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      {
        key: 'title', placeholder: 'Enter Title', label: 'Title', colSpan: 4, rules: [
          {
            required: true,
            message: `Please enter title.`
          }
        ]
      },
      {
        key: 'date', label: 'Date', colSpan: 2,
        widget: Date, rules: [
          {
            required: true,
            message: `Please enter date.`
          }
        ]
      },
      {
        key: 'day', placeholder: 'Enter Day', label: 'Day', widget: DaysList,  colSpan: 2, rules: [
          {
            required: true,
            message: `Please enter day.`
          }
        ]
      },
      {
        key: 'optional', placeholder: 'Enter Day', colSpan: 2,label: ``,
        widget: CheckBox
      },
      {
        key: 'description', widget: KoTextArea, label: 'Description', colSpan: 4, placeholder: 'Enter description'
      }
    ]

  };


  return (
    <div className={styles.delWrapper}>
      <KoForm form={form} layout="vertical" onFinish={handleSubmit} onSubmit={handleSubmit} >
        <KoFormBuilder form={form} meta={meta} />
      </KoForm>
    </div>
  );
};

export default HolidayCrud;
