import {
  Checkbox,
  KoAvatar,
  KoBadge,
  KoButton,
  KoDropdown,
  KoIcon,
  KoModal,
  KoRow,
  KoSearch,
  KoText,
  KoTitle,
  KoToast,
  KoTooltips,
  KoLoader,
  Upload

} from "packages";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { ISSUETYPE, PRIORITY, STATUS } from "shared/commonConstants";
import styles from "./toolbar.module.scss";
import { getCookie } from "shared/utils/cookies";

function KoToolBar(props) {
  const wosAssignees = useSelector(state => state?.wosAssignees);
  const {workspaceId} = useParams();

  const {
    title,
    buttonList = [],
    search,
    className,
    onChangeStatus,
    onChangeType,
    onChangePriority,
    onChangeAssignees,
    isExport,
    isImport,
    
    handleExport,
    Bulk:{
      visible : bulkVisible,
      selectedTickets = 0,
      setBulkModal
    }
  } = props;
  const [open, setOpen] = useState(false);
  const [openPriority, setOpenPriority] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [issueKeys, setIssueKeys] = useState([]);
  const [priorityKeys, setPriorityKeys] = useState([]);
  const [statusKeys, setStatusKeys] = useState([]);
  const [openAssignees, setOpenAssignees] = useState(false);
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const [openImport, setOpenImport] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoader] = useState(false);
  const handleMenuClickIssue = (key, isChecked) => {
    let updatedStatusKeys;
    if (isChecked) {
      updatedStatusKeys = [...issueKeys, key];
    } else {
      updatedStatusKeys = issueKeys.filter((id) => id !== key);
    }
    setIssueKeys(updatedStatusKeys);
    onChangeType && onChangeType(updatedStatusKeys);
  };

  const handleMenuClickPriority =  (key, isChecked) => {
    let updatedStatusKeys;
    if (isChecked) {
      updatedStatusKeys = [...priorityKeys, key];
    } else {
      updatedStatusKeys = priorityKeys.filter((id) => id !== key);
    }
    setPriorityKeys(updatedStatusKeys);
    onChangePriority(updatedStatusKeys);
  };

  const handleMenuClickStatus =  (key, isChecked) => {

    let updatedStatusKeys;
    if (isChecked) {
      updatedStatusKeys = [...statusKeys, key];
    } else {
      updatedStatusKeys = statusKeys.filter((id) => id !== key);
    }
    setStatusKeys(updatedStatusKeys);
    onChangeStatus(updatedStatusKeys);

  };

  const handleOpenChange = (nextOpen, info) => {
    if (info.source === "trigger" || nextOpen) {
      setOpen(nextOpen);
    }
  };

  const handleOpenChangePriority = (nextOpen, info) => {
    if (info.source === "trigger" || nextOpen) {
      setOpenPriority(nextOpen);
    }
  };

  const handleOpenChangeStatus = (nextOpen, info) => {
    if (info.source === "trigger" || nextOpen) {
      setOpenStatus(nextOpen);
    }
  };

  const handleClearAllFilters = () => {
    setIssueKeys([]);
    setPriorityKeys([]);
    setStatusKeys([]);
    setSelectedAssignees([]);
    onChangeType &&  onChangeType([]);
    onChangePriority([]);
    onChangeStatus([]);
    onChangeAssignees([]);
  };

  const handleOpenChangeAssignees = (nextOpen, info) => {
    if (info.source === 'trigger' || nextOpen) {
      setOpenAssignees(nextOpen);
    }
  };

  const issueTypesList = ISSUETYPE?.map((issue) => ({
    label: (
      <div className={`${styles.filterList} ${issueKeys.includes(issue.key) ? styles.selected : ""}`}>
        <Checkbox
          checked={issueKeys.includes(issue.key)}
          onChange={(e) => handleMenuClickIssue(issue.key, e.target.checked)}>
          <div className={styles.userDpWithName}>
            {issue.icon}
            <KoText text={issue.label} />
          </div>
        </Checkbox>
      </div>
    ),
    key: issue?.key,
    value: issue?.key
  }));
  
  const assigneesItems = wosAssignees[0]?.map((user) => ({
    label: (
      <div className={`${styles.filterList} ${selectedAssignees.includes(user.userId) ? styles.selected : ""}`}>
        <Checkbox
          checked={selectedAssignees.includes(user.userId)}
          onChange={(e) => handleUserSelection(user.userId, e.target.checked)}>
          <div className={styles.userDpWithName}>
            <KoAvatar inLine={'0rem'} size={20} options={[{ name: user?.profile?.firstName, logo: user?.profile?.logo }]} />
            <KoText text={user.profile?.firstName +' '+ user.profile?.lastName} />
          </div>
        </Checkbox>
      </div>
    ),
    key: user?.userId,
    value: user?.userId
  }));

  const statusItems = STATUS?.map((sta) => ({
    label: (
      <div className={`${styles.filterList} ${statusKeys.includes(sta.key) ? styles.selected : ""}`}>
        <Checkbox
          checked={statusKeys.includes(sta.key)}
          onChange={(e) => handleMenuClickStatus(sta.key, e.target.checked)}>
          <div className={styles.userDpWithName}>
            {sta?.label}
          </div>
        </Checkbox>
      </div>
    ),
    key: sta?.key,
    value: sta?.value
  }));

  const priorityItems = PRIORITY?.map((prty) => ({
    label: (
      <div className={`${styles.filterList} ${priorityKeys.includes(prty.key) ? styles.selected : ""}`}>
        <Checkbox
          checked={priorityKeys.includes(prty.key)}
          onChange={(e) => handleMenuClickPriority(prty.key, e.target.checked)}>
          <div className={styles.userDpWithName}>
            {prty?.label}
          </div>
        </Checkbox>
      </div>
    ),
    key: prty?.key,
    value: prty?.value
  }));

  const handleUserSelection = (userId, isChecked) => {
    let updatedSelectedUsers;
    if (isChecked) {
      updatedSelectedUsers = [...selectedAssignees, userId];
    } else {
      updatedSelectedUsers = selectedAssignees.filter((id) => id !== userId);
    }
    setSelectedAssignees(updatedSelectedUsers);
    onChangeAssignees(updatedSelectedUsers);
  };

  const handleUpload = () => {
    const formData = new FormData();
    console.log(fileList);
    fileList.forEach((file) => {
      formData.append('file', file);
    });
    setLoader(true);
    fetch(`${process.env.REACT_APP_PROXY_HOST}/api/v1/taskplanner/import/ticket`, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${getCookie("accessToken")}`,
        workspace: workspaceId
      }
    })
      .then((res) => res.json())
      .then(() => {
        setFileList([]);
        setLoader(false);
        KoToast.success('upload successfully.');
      })
      .catch(() => {
        setLoader(false);
        KoToast.error('upload failed.');
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const uploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([file]);
      return false;
    },
    fileList
  };

  return (
    <>
      <KoRow className={`${styles.wrapper} ${className ? className : ""}`}>
        {title ? <KoTitle level={5} text={title} /> : <div>&nbsp;</div>}
        <div className={styles.rightContent}>
          {search?.visible && (
            <KoSearch
              allowClear={true}
              onChange={(e) => search?.onChange(e.target.value)}
              placeholder={search?.placeholder}
            />
          )}
          {isImport&& <KoTooltips title="Import issues from CSV">
            <KoButton
              iconBtn={true}
              onClick={setOpenImport}
              icon={<KoIcon name="ImportOutlined" />}
            />
          </KoTooltips>}
          {isExport&& <KoTooltips title="Export issues to CSV">
            <KoButton
              iconBtn={true}
              onClick={handleExport}
              icon={<KoIcon name="ExportOutlined" />}
            />
          </KoTooltips>}
          {onChangeType &&  <KoDropdown
            onOpenChange={handleOpenChange}
            open={open}
            placement="bottomRight"
            menu={{items: issueTypesList }}>
            <div>
              <KoBadge  count={issueKeys?.length}>
                <KoTooltips title="Filter by issue type">
                  <KoButton
                    iconBtn={true}
                    icon={<KoIcon name="ProjectsOutlined" />}
                  />
                </KoTooltips>
              </KoBadge>
            </div>
          </KoDropdown>}

          <KoDropdown
            onOpenChange={handleOpenChangePriority}
            open={openPriority}
            placement="bottomRight"
            menu={{items: priorityItems}}>
            <div>
              <KoBadge  count={priorityKeys?.length}>
                <KoTooltips title="Filter by priority">
                  <KoButton
                    iconBtn={true}
                    icon={<KoIcon name="PriorityFilled" />}
                  />
                </KoTooltips>
              </KoBadge>
            </div>
          </KoDropdown>

          <KoDropdown
            onOpenChange={handleOpenChangeStatus}
            open={openStatus}
            placement="bottomRight"
            menu={{items: statusItems}}>
            <div>
              <KoBadge  count={statusKeys?.length}>
                <KoTooltips title="Filter by status">
                  <KoButton iconBtn={true} icon={<KoIcon name="StatusFilled" />} />
                </KoTooltips>
              </KoBadge>
            </div>
          </KoDropdown>

          <KoDropdown
            onOpenChange={handleOpenChangeAssignees}
            open={openAssignees}
            placement="bottomRight"
            menu={{items: assigneesItems}}>
            <div>
              <KoBadge  count={selectedAssignees?.length}>
                <KoTooltips title='Filter by assignees'>
                  <KoButton iconBtn={true} icon={<KoIcon name='UsersOutlined'/>}/>
                </KoTooltips>
              </KoBadge>
            </div>
          </KoDropdown>

          <div>
            <KoTooltips title="Reset Filter">
              <KoButton
                type="default"
                iconBtn={true}
                icon={<KoIcon name="ResetOutlined" />}
                onClick={handleClearAllFilters}
                className={styles.clearAllBtn}
              />
            </KoTooltips>
          </div>
          {
            bulkVisible &&
        <KoButton icon={<KoIcon color="#FFF" name="EditOutlined" width={18} height={18}/>} onClick={()=>setBulkModal(true)} disabled={!selectedTickets || selectedTickets.length === 0}
          className={`${styles.btn} ${selectedTickets?.length ===0 ? styles.disbaled :''}`} label='Bulk Change'/>
          }


          {buttonList?.map((btn) => (
            <div key={btn.label} className={styles.actionList}>
              <KoButton {...btn} />
            </div>
          ))}
        </div>
      
      </KoRow>
      <KoModal
        title={`Import issues from CSV`}
        centered
        open={openImport}
        handleOk={() => {setOpenImport(false);}}
        onClose={() => {setOpenImport(false);}}
        // maskClosable={false}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => {setOpenImport(false);}} />,
          <KoButton key='upload' onClick={() => handleUpload()}  type='primary' label={ `Import`}/>
        ]}
      >

        <div className={styles.modalContent}>
          {loading && <KoLoader/>}
          <KoText text="Download CSV Template" className={styles.templateText} />
          <Upload {...uploadProps} accept=".csv" className={styles.uploadWrapper}>
            <div className={styles.upload}>
              <KoIcon width={24} height={24} name="ImportOutlined" />
              {fileList.length === 0 && <div className={styles.upload}>
                <KoText text="Click to upload the CSV file" />
              </div>}
            </div>
          </Upload>
        </div>
        
      </KoModal>
    </>
  );
}
export default KoToolBar;
