import React from "react";
function Clients(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icons / client">
        <path id="Vector" d="M7.41667 13.8333C9.4425 13.8333 11.0833 12.1925 11.0833 10.1667C11.0833 8.14083 9.4425 6.5 7.41667 6.5C5.39083 6.5 3.75 8.14083 3.75 10.1667C3.75 12.1925 5.39083 13.8333 7.41667 13.8333ZM7.41667 7.41667C8.92917 7.41667 10.1667 8.65417 10.1667 10.1667C10.1667 11.6792 8.92917 12.9167 7.41667 12.9167C5.90417 12.9167 4.66667 11.6792 4.66667 10.1667C4.66667 8.65417 5.90417 7.41667 7.41667 7.41667ZM13.8333 17.9583V23H12.9167V17.9583C12.9167 17.1975 12.3025 16.5833 11.5417 16.5833H3.29167C2.53083 16.5833 1.91667 17.1975 1.91667 17.9583V23H1V17.9583C1 16.6933 2.02667 15.6667 3.29167 15.6667H11.5417C12.8067 15.6667 13.8333 16.6933 13.8333 17.9583ZM19.3333 7.15083L14.2917 12.1925L12.88 10.7808C12.8983 10.5792 12.9167 10.3775 12.9167 10.1667C12.9167 9.92833 12.8983 9.69917 12.8708 9.47917L14.2917 10.9L18.6825 6.50917H14.75V5.5925H18.875C19.6358 5.5925 20.25 6.20667 20.25 6.9675V11.0925H19.3333V7.15083ZM23 3.29167V17.5H15.575C15.5108 17.1792 15.41 16.8767 15.2725 16.5833H22.0833V3.29167C22.0833 2.53083 21.4692 1.91667 20.7083 1.91667H7.875C7.11417 1.91667 6.5 2.53083 6.5 3.29167V4.74917C6.18833 4.80417 5.87667 4.88667 5.58333 4.9875V3.29167C5.58333 2.02667 6.61 1 7.875 1H20.7083C21.9733 1 23 2.02667 23 3.29167Z" fill="black" />
      </g>
    </svg>
  );
}


export default Clients;