import React from "react";
function ImportSVG(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M20.096 5.389l-3.484-3.485A6.46 6.46 0 0012.015 0H6.5A4.505 4.505 0 002 4.5v7a.5.5 0 001 0v-7C3 2.57 4.57 1 6.5 1h5.515c.334 0 .663.03.985.088V6.5C13 7.878 14.121 9 15.5 9h5.412c.058.322.088.651.088.985V19.5c0 1.93-1.57 3.5-3.5 3.5h-11C4.57 23 3 21.43 3 19.5v-2a.5.5 0 00-1 0v2C2 21.981 4.019 24 6.5 24h11c2.481 0 4.5-2.019 4.5-4.5V9.985a6.458 6.458 0 00-1.904-4.596zM15.5 8c-.827 0-1.5-.673-1.5-1.5V1.368a5.496 5.496 0 011.904 1.243l3.484 3.485A5.49 5.49 0 0120.632 8H15.5zm-3.636 7H.5a.5.5 0 010-1h11.363a1.027 1.027 0 00-.146-.187l-2.566-2.511a.5.5 0 01.699-.715l2.57 2.515c.384.384.577.886.58 1.388v.018a1.96 1.96 0 01-.576 1.384L9.85 18.411a.502.502 0 01-.707-.007.5.5 0 01.008-.707l2.57-2.515a.923.923 0 00.143-.183z" />
    </svg>
  );
}


export default ImportSVG;