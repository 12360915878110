function WorkSpace(props) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={width}
      height={height}
    >
      <rect width={width} height={height} fill="none" />
      <circle
        cx={128}
        cy={68}
        r={40}
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={8}
        className="colorStroke000 svgStroke"
      />
      <circle
        cx={188}
        cy={172}
        r={40}
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={8}
        className="colorStroke000 svgStroke"
      />
      <circle
        cx={68}
        cy={172}
        r={40}
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={8}
        className="colorStroke000 svgStroke"
      />
    </svg>

  );
}


export default WorkSpace;