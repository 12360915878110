import React from "react";
function Activate(props) {
  const { width, height } = props;
  return (<svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
  >
    <g
      fill="none"
      stroke="#303c42"
      strokeLinecap="round"
      strokeLinejoin="round"
      data-name="Arrow 6 Down"
      className="colorStroke303c42 svgStroke"
    >
      <circle
        cx={12}
        cy={12}
        r="11.5"
        fill="none"
        className="color000 svgShape"
      />
      <path
        d="M17.5 10 12 15.5 6.5 10"
        fill="none"
        className="color000 svgShape"
      />
    </g>
  </svg>
  );
}
export default Activate;