import React from "react";
function Yesno(props) {
  const { width, height } = props;
  return (
    <svg width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
      <path d="M11.136 33a1 1 0 001-1A19.886 19.886 0 0132 12.136a1 1 0 000-2A21.889 21.889 0 0010.136 32a1 1 0 001 1zm41.728-2a1 1 0 00-1 1A19.886 19.886 0 0132 51.864a1 1 0 000 2A21.889 21.889 0 0053.864 32a1 1 0 00-1-1zM46.727 4a13.288 13.288 0 00-13.272 13.273c.729 17.608 25.819 17.602 26.545 0A13.288 13.288 0 0046.727 4zm0 24.545a11.285 11.285 0 01-11.272-11.272c.619-14.955 21.928-14.95 22.545 0a11.285 11.285 0 01-11.273 11.272zm-29.454 4.91A13.288 13.288 0 004 46.727c.729 17.608 25.819 17.604 26.545 0a13.288 13.288 0 00-13.272-13.272zm0 24.545A11.286 11.286 0 016 46.727c.62-14.954 21.929-14.95 22.545 0A11.285 11.285 0 0117.273 58z" />
      <path d="M52.49 12.105l-7.55 8.214-4.006-4.006a1 1 0 00-1.414 1.414l4.744 4.744a1.006 1.006 0 001.443-.03l8.256-8.982a1 1 0 00-1.472-1.354zM22.98 41.02a1 1 0 00-1.414 0l-4.293 4.293-4.293-4.293a1 1 0 00-1.414 1.414l4.293 4.293-4.293 4.293a1 1 0 101.414 1.414l4.293-4.293 4.293 4.293a1 1 0 101.414-1.414l-4.293-4.293 4.293-4.293a1 1 0 000-1.414z" />
    </svg>
  );
}
export default Yesno;