import React from "react";
import styles from './log.module.scss';
import KoIcon from "../../../icon";
import { KoText } from "../../../typography";

function ExecutionTime(props) {
  const {  value } = props;

  return (
    <div className={styles.wrapper}>
      <KoIcon name="LogOutlined" width={13} height={13} />
      <KoText text={value}/>
    </div>
  );
}
export default ExecutionTime;
