import React from "react";
function Close(props) {
  const { width, height, color } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg" >
      <g id="Close" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <path d="M11.75,1.25 L1.25,11.75 M1.25,1.25 L11.75,11.75" id="Combined-Shape" stroke={color ? color : "#707070"} strokeWidth="1.5"></path>
      </g>
    </svg>
  );
}


export default Close;