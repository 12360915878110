import React from "react";
function Env(props) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width={width}
      height={height}
    >
      <path d="M6 25h.299a15.96 15.96 0 002.48 5.98c-.94.754-1.095 2.173-.216 3.037l1.42 1.42c.863.873 2.278.727 3.037-.215a15.959 15.959 0 005.98 2.48V38c0 1.103.897 2 2 2h2c1.233.006 2.125-1.098 2-2.299a15.96 15.96 0 005.98-2.48l.213.216c.778.78 2.046.78 2.824 0l1.42-1.42a2 2 0 00-.004-2.829l-.211-.208a15.96 15.96 0 002.48-5.98H38c1.103 0 2-.897 2-2v-2c0-1.103-.897-2-2-2h-.299a15.96 15.96 0 00-2.48-5.98l.216-.213a1.999 1.999 0 000-2.824l-1.42-1.42c-.863-.873-2.278-.727-3.037.215A15.959 15.959 0 0025 6.298c.125-1.197-.766-2.308-2-2.298h-2c-1.103 0-2 .897-2 2v.299a15.96 15.96 0 00-5.98 2.48l-.213-.216a1.998 1.998 0 00-2.824 0l-1.42 1.42a2 2 0 00.004 2.829l.211.208A15.96 15.96 0 006.298 19H6c-1.103 0-2 .897-2 2v2c0 1.103.897 2 2 2zm0-4c.801-.018 1.979.22 2.141-.869a13.943 13.943 0 012.744-6.614 1 1 0 00-.093-1.318l-.815-.802 1.412-1.424c.577.558 1.239 1.567 2.128.912a13.943 13.943 0 016.614-2.744A1 1 0 0021 7.15V6h2c.018.802-.22 1.979.869 2.141a13.943 13.943 0 016.614 2.744c.4.306.965.265 1.318-.093l.802-.815 1.424 1.412c-.559.577-1.567 1.24-.912 2.128a13.943 13.943 0 012.744 6.614 1 1 0 00.991.869H38v2c-.802.018-1.979-.22-2.141.869a13.943 13.943 0 01-2.744 6.614 1 1 0 00.092 1.318l.816.802-1.412 1.424c-.568-.555-1.252-1.568-2.128-.912a13.944 13.944 0 01-6.614 2.744 1 1 0 00-.869.991V38h-2c-.018-.801.22-1.979-.869-2.141a13.943 13.943 0 01-6.614-2.744 1 1 0 00-1.318.093l-.802.815-1.424-1.412c.559-.577 1.567-1.24.912-2.128a13.943 13.943 0 01-2.744-6.614A1 1 0 007.15 23H6v-2z" />
      <path d="M22 34c6.617 0 12-5.383 12-12 .142-1.32-1.136-7.85-3.174-6.662a1 1 0 00-.327 1.376A10.082 10.082 0 0132 22c-.548 13.264-19.452 13.265-20 0-.133-7.702 8.82-12.659 15.286-8.498a1 1 0 001.376-.328C29.842 11.129 23.334 9.864 22 10c-15.917.658-15.917 23.342 0 24zm36 11h-.466a9.937 9.937 0 00-.672-1.619c.995-.767 1.276-2.233.33-3.16l-1.414-1.413c-.926-.942-2.39-.67-3.16.33A9.932 9.932 0 0051 38.466c.156-1.258-.668-2.47-2-2.466h-2c-1.103 0-2 .897-2 2v.466c-.558.176-1.099.4-1.619.672-.767-.996-2.235-1.276-3.16-.33l-1.413 1.414c-.78.78-.78 2.049 0 2.828l.33.331A9.937 9.937 0 0038.466 45c-1.257-.156-2.47.668-2.466 2v2c0 1.103.897 2 2 2h.466c.176.558.4 1.099.672 1.619-.995.767-1.275 2.233-.33 3.16l1.414 1.413c.928.944 2.39.67 3.16-.33.52.272 1.06.496 1.618.672-.156 1.257.668 2.47 2 2.466h2c1.333 0 2.156-1.204 2-2.466a9.934 9.934 0 001.619-.672c.766.995 2.234 1.276 3.16.33l1.413-1.414c.942-.928.669-2.39-.33-3.16.272-.52.496-1.06.672-1.618 1.258.156 2.47-.668 2.466-2v-2c0-1.103-.897-2-2-2zm0 4c-.768.034-1.979-.247-2.206.778a7.928 7.928 0 01-1.027 2.473 1 1 0 00.14 1.24l.871.873-1.414 1.414c-.56-.516-1.235-1.577-2.113-1.01a7.928 7.928 0 01-2.473 1.026 1 1 0 00-.778.976V58h-2v-1.23a1 1 0 00-.778-.976 7.927 7.927 0 01-2.473-1.027c-.876-.57-1.559.503-2.113 1.011l-1.414-1.414.872-.872a1 1 0 00.139-1.24 7.927 7.927 0 01-1.027-2.474A1 1 0 0039.23 49H38v-2c.768-.034 1.979.247 2.206-.778.199-.875.544-1.707 1.027-2.473a1 1 0 00-.14-1.24l-.871-.873 1.414-1.414.872.872c.33.33.846.388 1.24.139a7.927 7.927 0 012.474-1.027c1.028-.226.743-1.448.778-2.206h2c.034.769-.247 1.979.778 2.206.875.199 1.707.544 2.473 1.027a.998.998 0 001.24-.14l.873-.871 1.414 1.414-.872.872a1 1 0 00-.139 1.24 7.927 7.927 0 011.027 2.474c.226 1.028 1.448.743 2.206.778v2z" />
      <path d="M48 42c-4.146-.078-7.153 4.366-5.593 8.19.495 1.219 2.317.514 1.866-.72C43.23 46.92 45.23 43.948 48 44c2.206 0 4 1.794 4 4 .052 2.77-2.92 4.77-5.47 3.727-1.233-.451-1.94 1.373-.72 1.866C49.635 55.153 54.08 52.146 54 48c0-3.309-2.691-6-6-6zm-8.697-31.802c10.146 3.332 16.698 13.504 15.582 24.12-.115 1.302 1.833 1.515 1.99.203 1.212-11.542-5.907-22.597-16.938-26.22-1.246-.388-1.88 1.453-.634 1.897zM32 57c1.104-.15 3.445.416 3.415-1.221a.997.997 0 00-1.096-.894C23.7 56 13.53 49.45 10.199 39.303a1 1 0 10-1.897.634A24.953 24.953 0 0032 57z" />
    </svg>
  );
}


export default Env;