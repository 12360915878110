import React,{useState} from 'react';
import { KoForm, KoFormBuilder, Checkbox,KoDataTableEdit,KoButton,KoIcon,KoNano } from 'packages';
import styles from '../../template.module.scss';

export default function RadioUpdate({form, currentItem,  currentProperties, handleSaveProperties}) {
  const [dataSource,setDataSource] = useState(currentProperties?.options?.length > 0 ? currentProperties?.options : [{
    key: KoNano('INV'),
    value: null,
    label: null
  }]);

  const columns = [
    {
      dataIndex: 'label',
      title: 'Display Label',
      editable: true,
      widget:'text',
      placeholder: 'Enter displayLabel',
      width: 200
    },
    {
      dataIndex: 'value',
      title: 'Value',
      width: 200,
      editable: true,
      widget: 'text',
      placeholder: 'Enter Value'
    },
    {
      title: ` `,
      dataIndex: 'operation',
      width: 40,
      render: (_, record, i) =>
        dataSource.length >= 1 ? (
          <div className={'editTableCol'}>
            <KoButton icon={<KoIcon width={12} height={12} name={'DeleteOutlined'} />} className={styles.actionBtn} onClick={() => handleDelete(record?.key)} label="" />
            {dataSource?.length === (i+1) && <KoButton icon={<KoIcon color={`var(--palette-gray-7)`} width={17} height={17} name={'PlusOutlined'} />} className={styles.actionBtn} onClick={() => handleAdd(record?.key)} label={``} />}
          </div>
        ) : null
    }
  ];
  const OptionsTable = () =>
    <KoForm.Item name={'options'}>
      <KoDataTableEdit
        dataSource={dataSource}
        form={form}
        onSave={handleSave}
        defaultColumns={columns} />
    </KoForm.Item>;
  ;

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
    handleSave(newData);
  };

  const handleAdd = () => {
    const newData = {
      key: KoNano('INV'),
      value: ``,
      label: ''
    };
    setDataSource([...dataSource, newData]);
  };

  const handleSave = (newData) => {
    let data = form.getFieldsValue();
    data['options'] = newData;
    form.setFieldsValue({...data});
    setDataSource(newData);
  };

  const InputMeta = {
    columns: 6,
    formItemLayout: null,
    initialValues:currentProperties,
    colon: true,
    fields: [
      {
        key: 'label', placeholder: 'Enter Label', label: 'Display Label', colSpan: 3, rules: [
          {
            required: true,
            message: `Please enter a label to display in the form`
          }
        ]
      },
      {
        key: 'placeholder', placeholder: 'Enter placeholder', label: 'Placeholder', colSpan: 3, rules: [
          {
            required: true,
            message: `Enter placeholder.`
          }
        ]
      },
      {
        key: 'colSpan', placeholder: 'Enter colspan', label: 'Col Span', colSpan: 3, widget:'number'
      },
      { key: 'required', colSpan: 3, label: 'Is Required', widget: Checkbox },
      { key: 'isLookUp', colSpan: 3, label: 'Is Look Up', widget: Checkbox },
      { key: 'options', colSpan: 6, label: 'Options', widget: OptionsTable }
    ]
  };
  return (
    currentItem && (
      <div className={styles.propertyWrapper}>
        <KoForm form={form} layout="vertical"onFinish={handleSaveProperties}>
          <KoFormBuilder form={form} meta={InputMeta} />
        </KoForm>
      </div>
    )
  );
}