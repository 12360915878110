import React from "react";
import PhoneInput from "antd-phone-input";

function KoMobile(props){
  let {data} = props;
  return(
    data?.phoneNumber?  <PhoneInput className='mobileNo' disableDropdown readOnly value ={data}/> : ''
  );
}

export default KoMobile;