import React from "react";
function TotalUsers(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_12_196)">
        <path d="M16.043 14H7.957C6.64281 14.0016 5.3829 14.5244 4.45363 15.4536C3.52435 16.3829 3.00159 17.6428 3 18.957V24H21V18.957C20.9984 17.6428 20.4756 16.3829 19.5464 15.4536C18.6171 14.5244 17.3572 14.0016 16.043 14Z" fill="white" />
        <path d="M12 12C15.3137 12 18 9.31371 18 6C18 2.68629 15.3137 0 12 0C8.68629 0 6 2.68629 6 6C6 9.31371 8.68629 12 12 12Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_12_196">
          <rect width="24" height="24" fill="#000" />
        </clipPath>
      </defs>
    </svg>
  );
}


export default TotalUsers;