import React from "react";
function TextColor(props) {
  const { width, height , color} = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill= {color ? color : "black"}
    >
      <path
        d="M12.447 2.776a.52.52 0 00-.894 0l-9 18a.5.5 0 10.894.448L7.31 13.5h9.382l3.862 7.724a.5.5 0 00.894-.448zM7.81 12.5L12 4.118l4.19 8.382z"
        className="color000 svgShape"
      />
    </svg>
  );
}


export default TextColor;