import React, {useState, useEffect} from 'react';
import KoToolBar from "shared/components/toolbar";
import { KoAffix, KoButton, KoLoader, KoPagination, KoTable } from 'packages';
import { useParams } from 'react-router';
import { COLUMNS_GROUPS, ROW_ACTIONS } from "./constant";
import { InvoicesAPI } from '../invoice.service';


import styles from './genrateInvoice.module.scss';

export default function GenrateInvoice() {
  const {workspaceId} = useParams();
  const [isLoading, setLoading] = useState(false);
  const [currentRecord, setCurrentRecord] = useState();
  const [totalCount, setTotalCount] = useState(0);
  const [recentRequestList, setRecentRequestList] = useState(ROW_ACTIONS);
  
  const [pagination, setPagination] = useState({
    size: 10,
    offset: 0,
    page: 1
  });

  useEffect(() => {
    getInvoices();
  },[]);
  const getInvoices = () => {
    setLoading(true);
    InvoicesAPI.getInvoices(workspaceId).then(
      (data) => {
        setRecentRequestList(ROW_ACTIONS);
        setLoading(false);
        setTotalCount(10);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  console.log(currentRecord);

  const onPagination = (page, other) => {
    const query = {
      size: other,
      page: page

    };
    const p = Object.assign(pagination, query);
    setPagination(p);
    getInvoices(p);
  };

  const columnsRight = [{
    title: '',
    dataIndex: 'x',
    key: 'x',
    fixed: 'right',
    width: 40,
    render: (text, row) => {
      return <div className={`${styles.circle} columnsRight`} onClick={() => setCurrentRecord(row)}>
        <KoButton type='primary' label='Send'/>
        <KoButton type='primary' label='Generate'/>
      </div>;
    }
  }];
      
  return (
    <div className={styles.wrapper}>
      {isLoading && <KoLoader/>}
      <KoAffix top={56}>
        <KoToolBar
          className={styles.toolbar}
          title={"Generate Invoice"}
          search={{
            visible: false,
            onChange: () => { },
            placeholder: "Search by invoice name"
          }}
          buttonList={[]}
        />
      </KoAffix>
      <div className={`tableCard`}>
        <KoToolBar
          title={'Invoice Details'}
          search={{ visible: true, onChange: () => { }, placeholder: 'Search by any parameter' }}
          filter={{
            visible: false,
            options: [{ label: 'Sort By Name', key: '0' },
              { label: 'Created At', key: '2' },
              { label: 'Sort By designation', key: '3' }]
          }}
          buttonList={[]} />

        <KoTable data={{ columns: COLUMNS_GROUPS, rows: totalCount > 0 ? recentRequestList : recentRequestList }}
          extraColumnsRight={columnsRight} maxHeight={50} />
        <KoPagination current={parseInt(pagination.page) || 1}
          total={totalCount}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} records`
          }
          pageSize={pagination.limit}
          defaultCurrent={pagination.page}
          onChange={onPagination}
          showQuickJumper={false}
          simple={false}
          responsive={false} />
      </div>
    </div>
  );
}
