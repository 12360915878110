import React from "react";
import { ColorPicker } from 'antd';


function KoColorPicker(props) {
  return (
    <ColorPicker {...props} arrow={false} placement="topLeft"/>
  );
}
export default KoColorPicker;
