import React from "react";
function Delete(props) {
  const { width, height } = props;
  return (
    <svg fill='var(--palette-gray-8)' xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 10.078 10.932">
      <g data-name="Group 12247">
        <g data-name="Group 12246">
          <path data-name="Path 30360" d="M9.651 1.772H7.068v-.491A1.283 1.283 0 0 0 5.786 0H4.292a1.283 1.283 0 0 0-1.281 1.281v.491H.427a.427.427 0 1 0 0 .854h.442l.52 6.747v.006a1.708 1.708 0 0 0 1.7 1.553h3.9a1.694 1.694 0 0 0 1.7-1.541.427.427 0 0 0-.851-.078.844.844 0 0 1-.851.765h-3.9a.85.85 0 0 1-.85-.774l-.515-6.678h6.626l-.365 4.729a.427.427 0 0 0 .393.459h.033a.427.427 0 0 0 .425-.394l.37-4.794h.442a.427.427 0 0 0 0-.854zm-3.438 0H3.865v-.491a.428.428 0 0 1 .427-.427h1.495a.428.428 0 0 1 .427.427z" />
          <path data-name="Path 30361" d="M3.099 3.482a.427.427 0 0 0-.408.445l.214 4.888a.427.427 0 0 0 .426.408h.019a.427.427 0 0 0 .408-.445L3.544 3.89a.427.427 0 0 0-.445-.408z" />
          <path data-name="Path 30362" d="m6.535 3.889-.214 4.892a.427.427 0 0 0 .408.445h.019a.427.427 0 0 0 .426-.408l.214-4.888a.427.427 0 0 0-.853-.037z" />
          <path data-name="Path 30363" d="M4.612 3.907v4.888a.427.427 0 1 0 .854 0V3.907a.427.427 0 1 0-.854 0z" />
        </g>
      </g>
    </svg>
  );
}


export default Delete;