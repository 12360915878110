import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import {  Outlet, useParams } from 'react-router-dom';
import { KoTitle, KoAvatar, KoIcon, KoButton, KoModal, KoForm, KoSelect, KoFormBuilder, KoAffix, KoToast, KoLoader, KoUserSelect, KoDropdown } from 'packages';
import { Form } from "antd";
import { useDispatch } from 'react-redux';
import { TaskPlannerAPI } from "./taskPlanner.service";
import { updateAssignees, removeAssignees } from 'shared/store/wosAssigneesSlice';

import styles from './details.module.scss';
import { getSprint } from "shared/store/sprintSlice";

function TaskPlannerDetails() {
  const  users = useSelector(state => state?.users);
  const  groups = useSelector(state => state?.groups);
  let { workspaceId } = useParams();
  const dispatch = useDispatch();
  const [isOpen, setModal] = useState();
  const [isLoading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [workspace, setCurrentRecord] = useState();
  const [profile, setProfile] = useState([]);
  const [sprints, setSprints] = useState();
  const [shareSprint, setShareSprint] = useState();
  const initialValues = workspace;

  useEffect(() => {
    if (users?.data && workspaceId) {
      getWorkspaceById();
    }
    dispatch(getSprint({ workspaceId: workspaceId }));
  }, [workspaceId, users?.data]);

  useEffect(() => {
    getActiveSprints();
  }, []);
  const isDashboardUrl = window.location.pathname.split('/').pop();


  const getWorkspaceById = () => {
    TaskPlannerAPI.getWorkspaceById(workspaceId).then(
      (data) => {
        setCurrentRecord(data['project']);
        dispatch(removeAssignees());
        dispatch(updateAssignees(data['project'].teams));
        setProfile(data['project'].teams);
      },
      (err) => {
      }
    );
  };


  const getActiveSprints=()=>{
    setLoading(true);
    setSprints();
    TaskPlannerAPI.getActiveSprints(workspaceId).then(
      (data) => {
        setSprints(data?.sprints);
        setLoading(false);
        if (data?.sprints?.length > 0) {
          setShareSprint(data?.sprints[0]);
        }
      },
      (err) => {
        
        setLoading(false);
      }
    );
  };


  const handleSubmit = useCallback(values => {
    let value = workspace;
    value['assignees'] = values.assignees;
    value['groups'] = values.groups;
    TaskPlannerAPI.createOrUpdateClient(value, workspaceId).then(
      (data) => {
        setLoading(false);
        setModal(false);
        KoToast.success( 'Updated successfully');
        getWorkspaceById();
      },
      (err) => {
        setLoading(false);
      }
    );
  });
 

  const Assignees = ({ value, onChange }) =>
    <KoUserSelect value={value} onChange={v => onChange(v)} users={users['data']} placeholder='Select Assignees' mode='multiple'/>;


  const Groups = ({ value, onChange }) =>
    <KoSelect value={value}
      onChange={v => onChange(v)}
      mode="multiple"
      showSearch
      placeholder='Select Team'
      options={groups['data']?.map((team) => ({
        
        label: (
          <>
            <KoAvatar inLine={'0rem'} size={20} options={[{ name: team?.name }]} /> {team?.name}
          </>
        ),
        value: team?.groupId
      }))}
    />;
 
  const meta = {
    columns: 6,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [{
      key: 'assignees', label: 'Assignees', colSpan: 6,
      widget: Assignees,
      forwardRef: true
    },
    {
      key: 'groups', label: 'Teams', colSpan: 6,
      widget: Groups,
      forwardRef: true
    }]};


  const handleMenuClickIssue = ({key}) => {
    let findObj = sprints?.find((obj) => obj?.sprintId === key);
    setShareSprint(findObj);
  };

  const shareSprintId = shareSprint && shareSprint?.sprintId;

  return <>
    <div className={`${styles.wrapper}`}>
      <KoAffix top={50}>
        <div className={styles.header}>
          <KoButton color="default" variant="filled" iconBtn={true} icon={<KoIcon name='ArrowLeftOutlined' />} onClick={() => window.history.back()}/>
          <KoTitle className={styles.title} level={4} text={workspace?.name} />
   
          {isDashboardUrl === 'dashboard' ? (
            <div className={styles.sprintWrapper}>
              <KoDropdown.Button
                icon={<KoIcon name="ActiveSprintOutlined" />}
                placement="bottomRight"
                menu={{
                  items: sprints?.map((spr) => ({
                    label: spr.name,
                    value: spr?.sprintId,
                    key: spr?.sprintId
                  })),
                  onClick: handleMenuClickIssue
                }}
              >
                <div className={styles.sprint}>
                  {sprints?.length > 0
                    ? shareSprint?.name
                      ? `${shareSprint?.name}`
                      : 'Select Sprint'
                    : 'No sprints available'}
                </div>
              </KoDropdown.Button>
            </div>
          ) : <div></div>}


          
            
          <div className={styles.profileAssign}>
            <KoAvatar isProfile={true} size={25} options={profile} maxCount={5}/>
            <KoButton circle={styles.btn}  shape="circle" icon={ <KoIcon name='PlusOutlined'/>} onClick={()=>setModal(true)}/>
          </div>
        </div>
      </KoAffix>

      <Outlet context={[shareSprintId]} />

    </div>

    <KoModal
      title={`Update Assignees / Teams for  ${workspace?.name}` }
      centered
      open={isOpen}
      handleOk={() => {setModal(false); }}
      onClose={() => {setModal(false); }}
      width={480}
      // maskClosable={false}
      height={520}
      footer={[
        <KoButton key='cancel' label='Cancel' onClick={() => setModal(!isOpen)} />,
        <KoButton key='create' loading={isLoading} type='primary' label={`Update`} onClick={() => form.submit()}/>
      ]}
    >
      <div className={styles.wrapper}>
        {isLoading && <KoLoader circle={false} />}
        <KoForm form={form} layout="vertical"
          onFinish={handleSubmit} onSubmit={handleSubmit}>
          <div className={styles.leftForm}>
            <KoFormBuilder form={form} meta={meta} />
          </div>
        </KoForm>
      </div>
    </KoModal>
  </>;
  
}

export default TaskPlannerDetails;