import React from "react";
import { Rate  } from 'antd';

function KoRating(props) {
  return (
    <Rate
      {...props}
      style={{ width: '100%' }}
    />
  );
}

export  {KoRating, Rate};