import React from "react";
function Dashboard(props) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width={width}
      height={height}
    >
      <path
        d="M11 4H7a3 3 0 00-3 3v18a3 3 0 003 3h4a3 3 0 003-3V7a3 3 0 00-3-3zm1 21a1 1 0 01-1 1H7a1 1 0 01-1-1V7a1 1 0 011-1h4a1 1 0 011 1zm13-5.3h-6a3 3 0 00-3 3V25a3 3 0 003 3h6a3 3 0 003-3v-2.3a3 3 0 00-3-3zm1 5.3a1 1 0 01-1 1h-6a1 1 0 01-1-1v-2.3a1 1 0 011-1h6a1 1 0 011 1zM25 4h-6a3 3 0 00-3 3v7.7a3 3 0 003 3h6a3 3 0 003-3V7a3 3 0 00-3-3zm1 10.7a1 1 0 01-1 1h-6a1 1 0 01-1-1V7a1 1 0 011-1h6a1 1 0 011 1z"
        data-name="Dashboard"
      />
    </svg>
  );
}


export default Dashboard;