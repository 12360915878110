import React from "react";
function Poll(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 512 512" version="1.0" viewBox="0 0 512 512"
      width={width} height={height}>
      <path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"
        strokeWidth="15"
        d="M124.909 85.696v29.156c0 9.42-7.636 17.056-17.056 17.056H36.317c-9.42 0-17.056-7.636-17.056-17.056V43.316c0-9.42 7.636-17.056 17.056-17.056H90.73M107.853 306.984H36.317c-9.42 0-17.056-7.636-17.056-17.056v-71.536c0-9.42 7.636-17.056 17.056-17.056h71.536c9.42 0 17.056 7.636 17.056 17.056v71.536C124.909 299.348 117.273 306.984 107.853 306.984zM107.853 482.059H36.317c-9.42 0-17.056-7.636-17.056-17.056v-71.536c0-9.42 7.636-17.056 17.056-17.056h71.536c9.42 0 17.056 7.636 17.056 17.056v71.536C124.909 474.423 117.273 482.059 107.853 482.059z"
        className="colorStroke000 svgStroke"></path>
      <polyline fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"
        strokeWidth="15" points="56.133 79.084 72.133 95.084 140.956 26.26"></polyline>
      <rect width="299.575" height="47.963" x="190.588" y="55.103" fill="none" stroke="#000" strokeLinecap="round"
        strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="15" className="colorStroke000 svgStroke"></rect>
      <rect width="97.754" height="47.963" x="190.588" y="230.178" fill="none" stroke="#000" strokeLinecap="round"
        strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="15" className="colorStroke000 svgStroke"></rect>
      <rect width="210.918" height="47.963" x="190.588" y="405.254" fill="none" stroke="#000" strokeLinecap="round"
        strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="15" className="colorStroke000 svgStroke"></rect>
    </svg>  );
}


export default Poll;