import KoAvatar from 'packages/avatar';
import { KoSelect } from 'packages/select';
import { KoText } from 'packages/typography';
import React from 'react';

export default function KoUserSelect({ value, onChange, users , placeholder, mode, unAssignee = false}) {

  return (
    <KoSelect value={value}
      onChange={v => onChange(v)}
      showSearch
      allowClear
      mode={mode ? mode : '' }
      placeholder={placeholder ? placeholder : ''}
      filterOption={(input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
      }
      optionRender={(option) => (
        <div className={'userDpWithName'}>
          <KoAvatar inLine={'0rem'} size={30} options={[{ name: option.data.label, logo: option?.data.emoji }]} />
          <div className={'userDpWithNameEmail'}>
            <KoText className={'name'} text={option.data.label} />
            <KoText className={'email'} text={option.data.desc} />
          </div>
        </div>
      )}
      options ={ [
        ...(users || []).map((user) => ({
          label: `${user.profile?.firstName || user?.email} ${user.profile?.lastName || ''}`,
          value: user?.userId || '',
          emoji: user?.profile?.logo || '',
          desc: user?.email || ''
        })),
        ...(unAssignee ? [{
          label: 'Unassigned',
          value: 'unassigned',
          emoji: '',
          desc: 'unassigned'
        }] : [])
      ]}

    />
  );
}
