import KoCard from 'packages/cards';
import React from 'react';
import styles from './folder.module.scss';
import { KoDropdown } from 'packages/dropdown';
import KoIcon from 'packages/icon';
import { Link } from 'react-router-dom';
import { KoText } from 'packages/typography';
export default function KoFolder(props) {
  const {key, id, noLink, actions, placement, data, onClick } = props;
  return (
    <KoCard
      key={key}
      className={styles.folderWrapper}
      hoverable={true}
    >
      <div className={styles.actions}>
        <KoDropdown
          overlayClassName={'dropdownOverlayClassName'}
          placement={placement ? placement : "bottomRight"}
          menu={{
            items:actions,
            onClick:(e)=>onClick(e, data)
          }}
        >
          <div onClick={(e) => {
            e.preventDefault();
          }}>
            <KoIcon name="VerticalMenuOutlined" />
          </div>
        </KoDropdown>
      </div>
   
      {noLink ? <Link to={`#`} className={styles.link}>
        <KoIcon name={`FolderOutlined`} width={40} height={40} />
        <div className={styles.folderInformations}>
          <KoText
            ellipsis={true}
            text={data?.name}
            className={styles.fileName}
          />
          <KoText ellipsis={true} className={styles.description} text={`381.47 Mb
600 files`} />
        </div>
      </Link> : <Link to={`${id ? data[id] : data?.folderId}`} className={styles.link}>
        <KoIcon name={`FolderOutlined`} width={40} height={40} />
        <div className={styles.folderInformations}>
          <KoText
            ellipsis={true}
            text={data?.name}
            className={styles.fileName}
          />
          <KoText ellipsis={true} className={styles.description} text={`381.47 Mb
600 files`} />
        </div>
      </Link>}
    </KoCard>
  );
}
