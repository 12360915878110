import React from "react";
import { Layout } from 'antd';
import { Link, useLocation, useParams } from 'react-router-dom';
import { KoIcon, KoText } from "packages";
import { useSelector } from 'react-redux';
import styles from './layouts.module.scss';
import {
  APICLIENT_SUB_MENU,
  FILES_SUB_MENU,
  HOME_SUB_MENU,
  CALENDAR_SUB_MENU
} from "./constant";
import { getTaskSubMenuForRoles } from "./taskmenu";
import { getOrgMenuForRoles } from "./orgmenu";
import { getAdminMenuForRoles } from "./adminmenu";
import { getInvoiceMenuForRoles } from "./invoicemenu";

const { Sider } = Layout;

function GlobalSider(props) {
  const location = useLocation();
  const { pathname } = location;
  const { workspaceId } = useParams();
  const currentPath = workspaceId ? pathname?.split('/')[2] : pathname?.split('/')[1];
  const activeCurrentPath = workspaceId ? pathname?.split('/')[3] : pathname?.split('/')[2];
  const { background } = props;
  // const { workspace } = useSelector(state => state);
  // const [, setActiveWorkspace] = useState(currentPath);

  const userById  = useSelector(state => state?.userById);
  let userDetails = userById['data'];

  let items = [];
  let wosId = workspaceId;
  if (currentPath === 'org') {
    items = getOrgMenuForRoles(userDetails);
  } else if (currentPath === 'admin') {
    items = getAdminMenuForRoles(userDetails);
  } else if (currentPath === 'taskplanner' && userDetails?.roles) {
    items = getTaskSubMenuForRoles(userDetails);
    wosId = workspaceId;
  } else if (currentPath === 'invoices') {
    items = getInvoiceMenuForRoles(userDetails);
    wosId = workspaceId;
  } else if (currentPath === 'files') {
    items = FILES_SUB_MENU;
    wosId = workspaceId;
  } else if (currentPath === 'apiclient') {
    items = APICLIENT_SUB_MENU;
    wosId = workspaceId;
  } else if (currentPath === 'home' ||
    currentPath === 'chat' ||
    currentPath === 'huddle') {
    items = HOME_SUB_MENU;
  } else if (currentPath === 'calendar') {
    items = CALENDAR_SUB_MENU;
  }
  // let workspaceItems = [];
  // if (workspace['data']) {
  //   let wos = getCookie('wos');
  //   setCookie('wos', wos ? wos : workspace['data'][0]?.workspaceId);
  //   workspaceItems = workspace['data'];
  // }



  // const onChangeWorkspace = (workspace, path) => {
  //   setCookie('wos', workspace?.workspaceId);
  //   setActiveWorkspace(path);
  // };

  return (
    <Sider
      style={{
        background: background
      }}
      className={styles.sideNavWrapper}
      trigger={null}
    >
      <ul>
        {/* <li onClick={()=>onChangeWorkspace(workspaceItems[0].workspaceId, 'workspace')}>
          <Link to={`workspace`}>
            <KoIcon name={'WorkspaceOutlined'} />
            <KoText ellipsis={true} className={styles.text} text={'Workspace'} />
          </Link>
        </li> */}
        {/* {workspaceItems.map((item) =>
          <li onClick={()=>onChangeWorkspace(item)} key={item.key} className={item.key.includes(activeWorkspace) > 0 && styles.activeWorkspace}>
            <Link to={`${item.workspaceId}/taskplanner/dashboard`}>
              <KoIcon name={item?.logo ? item?.logo : 'NoWorkSpaceOutlined'} />
              <KoText ellipsis={true} className={styles.text} text={item.name} />
            </Link>
          </li>
        )} */}
      </ul>

      <ul>
        <li key={'kio-ai'} className={'/kio-ai'.includes(pathname) > 0 ? styles.active : ''}>
          <Link to={`/kio-ai`}>
            <KoIcon name='LoaderFilled' width={20} height={20}/>
            <KoText ellipsis={true} className={styles.text} text={'Kio AI'} />
          </Link>
        </li>
        <li key={'home'} className={pathname.includes('/home/dashboard') > 0 ? styles.active : ''}>
          <Link to={`/home/dashboard/myoverview`}>
            <KoIcon name={'HomeOutlined'} />
            <KoText ellipsis={true} className={styles.text} text={'Home'} />
          </Link>
        </li>
        {/* <li key="chat" className={pathname.includes('/chat') ? styles.active : ''}>

          <Link to={`chat`}>
            <span className={styles.chatCount}>
              <span className={styles.count}>2</span>
            </span>
            <KoIcon name={'ChatOutlined'} />
            <KoText ellipsis={true} className={styles.text} text={'Chat'} />
          </Link>
        </li> */}
        {/* <li key={'workspace'} className={'/workspace'.includes(pathname) > 0 ? styles.active :''}>
          <Link to={`/workspace`}>
            <KoIcon name={'WorkspaceOutlined'} />
            <KoText ellipsis={true} className={styles.text} text={'Workspace'} />
          </Link>
        </li> */}
        {items.map((item) =>
          <li key={item.key} className={item.key.includes(activeCurrentPath) > 0 ? styles.active : ''}>
            <Link to={`${wosId ? wosId + '/' + item?.key : item?.key}`}>
              <KoIcon name={item?.icon} />
              <KoText ellipsis={true} className={styles.text} text={item.label} />
            </Link>
          </li>
        )}
        {/* {items.length >0 ? <KoDivider className={styles.divider} /> : <></>} */}


      
        {/* <li key={'huddle'} className={'/huddle'.includes(pathname) > 0 ? styles.active :''}>
          <Link to={`huddle`}>
            <KoIcon name={'CalendarOutlined'} />
            <KoText ellipsis={true} className={styles.text} text={'Calendar'} />
          </Link>
        </li> */}

      </ul>

    </Sider>


  );
}

export default GlobalSider;