import React, { useState } from 'react';
import { KoFormBuilder, KoForm, KoSelect, KoDate } from 'packages';
import styles from './about.module.scss';
import { Country} from 'country-state-city';

function Crud({form, handleFinish, initialValues}) {
  const [countriesList] = useState(Country.getAllCountries());

  const Gender = ({ value, onChange }) =>
    <KoSelect value={value} onChange={v => onChange(v)} placeholder='Select Gender'
      options={[
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' }
      ]}
    />;

  const Date = ({ value, onChange }) =>
    <KoDate value={value} format = {'DD-MMM-YYYY'}
      onChange={v => onChange(v)} />;

  const MaritalStatus = ({value, onChange})=>
    <KoSelect value={value} onChange={v => onChange(v)} placeholder='Select Marital Status'
      options={[
        { label: 'Single', value: 'single' },
        { label: 'Married', value: 'married' },
        { label: 'Divorced', value: 'divorced' },
        { label: 'Widowed', value: 'widowed' },
        { label: 'Separated', value: 'separated' },
        { label: 'Other', value: 'other' }
      ]}
    />;

  const BloodGroup = ({value, onChange})=>
    <KoSelect value={value} onChange={v => onChange(v)} placeholder='Select Blood Group'
      options={[
        { label: 'A+', value: 'A+' },
        { label: 'A-', value: 'A-' },
        { label: 'B+', value: 'B+' },
        { label: 'B-', value: 'B-' },
        { label: 'O+', value: 'O+' },
        { label: 'O-', value: 'O-' }
      ]}
    />;


  const Handicapped = ({value, onChange})=>
    <KoSelect value={value} onChange={v => onChange(v)} placeholder='Select Physically Handicapped'
      defaultValue={'no'}
      options={[
        { label: 'Yes', value: 'yes' },
        { label: 'No', value: 'no' }
      ]}
    />;


  const CountrySelect = ({ value, onChange }) =>
    <KoSelect showSearch value={value}
      onChange={(v) => onChange(v)}
      placeholder={'Select Country'}
      options={countriesList?.map((country) => ({
        label: country?.name,
        value: country?.isoCode
      }))} />;


  const meta = {
    columns: 4,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      {
        key: 'primaryDetails.firstName', label: 'First Name',  placeholder: 'Enter First Name', colSpan: 2
      },
      {
        key: 'primaryDetails.lastName', label: 'Last Name', placeholder: 'Enter Last Name', colSpan: 2
      }, {
        key: 'primaryDetails.middleName', label: 'Middle Name', placeholder: 'Enter Middle Name', colSpan: 2
      }, {
        key: 'primaryDetails.displayName',label: 'Display Name', placeholder: 'Enter Display Name', colSpan: 2
      }, {
        key: 'primaryDetails.gender',label: 'Gender', placeholder: 'Enter Gender', colSpan: 2, widget: Gender
      },
      {
        key: 'primaryDetails.dateOfBirth',label: 'Date of Birth', placeholder: 'Enter Date of Birth', colSpan: 2, widget: Date
      }, {
        key: 'primaryDetails.maritalStatus',label: 'Marital Status', placeholder: 'Enter Marital Status', colSpan: 2, widget: MaritalStatus
      },
      {
        key: 'primaryDetails.bloodGroup',label: 'Blood Group', colSpan: 2, widget: BloodGroup
      },
      {
        key: 'primaryDetails.physicallyHandicapped',label: 'Physically Handicapped', colSpan: 2, widget: Handicapped
      },
      {
        key: 'primaryDetails.nationality',label: 'Nationality', placeholder: 'Enter Nationality', colSpan: 2, widget: CountrySelect
      }
    ]
  };
  return (
    <div className={styles.crudWrapper}>
      <KoForm form={form} layout="vertical" onFinish={handleFinish} onSubmit={handleFinish}>
        <KoFormBuilder form={form} meta={meta} />
      </KoForm>
    </div>
  );
};

export default Crud;