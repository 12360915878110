import { KoText } from 'packages';
import styles from './curd.module.scss';
const PRO_ITEMS_COLUMNS = [
  {
    name: "ticketId",
    id: "ticketId",
    label: "Ticket Id",
    width:130,
    widget: "ticketLink",
    fixed: true
  },
  {
    name: "summary",
    id: "summary",
    label: "Summary",
    width:300,
    widget: "text"
  },
  {
    name: "status",
    id: "status",
    label: "Status",
    widget: "taskstatuscob",
    worklog:false,
    width:150
  }];
  

const TASKSTATUS = [{
  label: 'Unresolved',
  name: 'Todo',
  value: 1,
  background: 'rgba(145, 158, 171, 0.16)',
  color: '#637381'
},
{
  label: 'In Progress',
  value: 2,
  name: 'In Progress',
  background: 'rgba(255, 239, 179, 0.16)',
  color: '#f57f17'
},
{
  label: 'Reopen',
  name: 'Reopen',
  value: 12,
  background: 'rgba(145, 158, 171, 0.16)',
  color: '#637381'
},
{
  label: 'Resolved',
  value: 10,
  name: 'Done',
  background: 'rgba(165, 214, 167, 0.16)',
  color: '#388e3c'
}].map(status => ({
  label: (
    <div className={styles.statusWrapper}>
      <div
        className={`${styles.status}`}
        style={{
          background: status.color,
          color: status.background
        }}
      />
      <KoText text={status.label} />
    </div>
  ),
  value: status.value,
  key: status.value
}));
// closed = 11
export {PRO_ITEMS_COLUMNS, TASKSTATUS};