import React from "react";
function Suspend(props) {
  const { width, height } = props;
  return (<svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    width={width} height={height}
  >
    <path
      d="M26.09 35.46c-9.78 0-17.73-7.95-17.73-17.73S16.32 0 26.09 0c9.78 0 17.73 7.95 17.73 17.73 0 4.71-1.83 9.15-5.15 12.49-.56.56-1.47.56-2.03.01-.56-.56-.57-1.46-.01-2.03 2.78-2.8 4.32-6.52 4.32-10.47 0-8.2-6.67-14.86-14.86-14.86S11.23 9.54 11.23 17.73s6.67 14.86 14.86 14.86c.68 0 1.38-.05 2.06-.14.78-.1 1.51.44 1.62 1.22.11.78-.44 1.51-1.22 1.62-.82.11-1.64.17-2.46.17z"
    />
    <path
      d="M1.81 59.96c-.79 0-1.43-.64-1.43-1.43 0-14.3 11.63-25.93 25.93-25.93a1.43 1.43 0 110 2.86c-12.72 0-23.07 10.35-23.07 23.07.01.79-.63 1.43-1.43 1.43zM62.28 64c-.37 0-.75-.15-1.03-.44L30.87 32.22a1.437 1.437 0 012.06-2l30.38 31.34c.55.57.54 1.47-.03 2.03-.28.28-.64.41-1 .41z"
    />
    <path
      d="M31.9 64c-.36 0-.72-.13-1-.4-.57-.55-.58-1.46-.03-2.03l30.38-31.34a1.437 1.437 0 012.06 2L32.93 63.56c-.28.29-.66.44-1.03.44z"
    />
  </svg>

  );
}
export default Suspend;