
import { KoAvatar, KoButton, KoCard, KoDateFormatter, KoDivider, KoDP, KoIcon, KoModal, KoText } from 'packages';
import styles from './about.module.scss';
import { getCookie } from 'shared/utils/cookies';
import { useState } from 'react';
import Crud from './crud';
import TimeCrud from './timeCrus';
import OtherCrud from './otherCrud';
import OrgCrud from './orgCrud';


export default function JobDetails({userData,preferenceData, ReporterDetails, form, handleFinish, ManagerDetails}) {
  const [isOpen, setModal] = useState(false);
  const [isTimeOpen, setTimeModal] = useState(false);
  const [isOtherOpen, setOtherModal] = useState(false);
  const [isOrgOpen, setOrgModal] = useState(false);

  const handleSubmit = (values) => {
    handleFinish(values);
    setModal(false);
    setTimeModal(false);
    setOtherModal(false);
    setOrgModal(false);
  };

  return (
    <div className={styles.wrapper}>
     
      <KoCard hoverable={false} className={styles.tabs}>
        <div className={styles.previewWrapper}>
          <KoDivider orientation="left" orientationMargin="0">
            <div className={styles.heading}>
              <KoText className={styles.headingText} text={'Job Details'} />
              {
                userData?.userId === getCookie('userId') &&
             <KoIcon width={14} height={14} name={'EditOutlined'} onClick={() => setModal(true)}/>
              }
            </div>
          </KoDivider>
          <div className={styles.card}>
            
            <div className={styles.fieldList}>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Employee ID'} />
                <KoText className={styles.titleValue} text={`${userData?.jobDetails?.employeeId ? userData?.jobDetails?.employeeId : '-Not Set-'}`}  />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Date of Join'} />
                {
                  userData?.jobDetails?.dateOfJoin !== '0001-01-01T00:00:00Z' ?
                    <KoDateFormatter format='DD-MMM-YYYY' data={userData?.jobDetails?.dateOfJoin} className={styles.titleValue}/> :
                    <KoText className={styles.titleValue} text={'-Not Set-'}  />
                }
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Job Title - Primary'} />
                <KoText className={styles.titleValue} text={`${userData?.jobDetails?.jobTitlePrimary ? userData?.jobDetails?.jobTitlePrimary : '-Not Set-'}`}  />

              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Job Title - Secondary'} />
                <KoText className={styles.titleValue} text={`${userData?.jobDetails?.jobTitleSecondary ? userData?.jobDetails?.jobTitleSecondary : '-Not Set-'}`}  />

              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'In Probation Period From'} />
                {
                  userData?.jobDetails?.probationPeriodFrom !== '0001-01-01T00:00:00Z' ?
                    <KoDateFormatter format='DD-MMM-YYYY' data={userData?.jobDetails?.probationPeriodFrom} className={styles.titleValue}/> :
                    <KoText className={styles.titleValue} text={'-Not Set-'}  />
                }
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'In Probation Period To'} />
                {
                  userData?.jobDetails?.probationPeriodTo !== '0001-01-01T00:00:00Z' ?
                    <KoDateFormatter format='DD-MMM-YYYY' data={userData?.jobDetails?.probationPeriodTo} className={styles.titleValue}/> :
                    <KoText className={styles.titleValue} text={'-Not Set-'}  />
                }
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Notice Period'} />
                <KoText className={styles.titleValue} text={`${userData?.jobDetails?.noticePeriod ? userData?.jobDetails?.noticePeriod : '-Not Set-'} Days`}  />

              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Worker Type'} />
                <KoText className={styles.titleValue} text={`${userData?.jobDetails?.workerType ? userData?.jobDetails?.workerType : '-Not Set-'}`}  />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Time Type'} />
                <KoText className={styles.titleValue} text={`${userData?.jobDetails?.timeType ? userData?.jobDetails?.timeType : '-Not Set-'}`}  />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Contract Type'} />
                <KoText className={styles.titleValue} text={`${userData?.jobDetails?.contractType ? userData?.jobDetails?.contractType : '-Not Set-'}`}  />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Pay Band'} />
                <KoText className={styles.titleValue} text={`${userData?.jobDetails?.payBand ? userData?.jobDetails?.payBand : '-Not Set-'}`}  />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Pay Grade'} />
                <KoText className={styles.titleValue} text={`${userData?.jobDetails?.payGrade ? userData?.jobDetails?.payGrade : '-Not Set-'}`}  />
              </div>
            </div>
          </div>
         
      
          <KoDivider orientation="left" orientationMargin="0">
            <div className={styles.heading}>
              <KoText className={styles.headingText} text={'Employee Time'} />
              {
                userData?.userId === getCookie('userId') &&
             <KoIcon width={14} height={14} name={'EditOutlined'} onClick={() => setTimeModal(true)}/>
              }
            </div>
          </KoDivider>
          <div className={styles.card}>
            <div className={styles.fieldList}>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Shift'} />
                <KoText className={styles.titleValue} text={userData?.employeeTime?.shift ? userData?.employeeTime?.shift : ('-Not Set-')}
                />

              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Weekly Off Policy'} />
                <KoText className={styles.titleValue} text={userData?.employeeTime?.weeklyOffPolicy ? userData?.employeeTime?.weeklyOffPolicy : '-Not Set-'}  />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Leave Plan'} />
                <KoText className={styles.titleValue} text={`${userData?.employeeTime?.leavePlan ? userData?.employeeTime?.leavePlan : '-Not Set-'}`}  />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Holiday Calendar'} />
                <KoText className={styles.titleValue} text={`${userData?.employeeTime?.holidayCalendar ? userData?.employeeTime?.holidayCalendar : '-Not Set-'}`}  />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Attendance Number'} />
                <KoText className={styles.titleValue} text={`${userData?.employeeTime?.attendanceNumber ? userData?.employeeTime?.attendanceNumber : '-Not Set-'}`}  />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Payroll Time Source'} />
                <KoText className={styles.titleValue} text={`${userData?.employeeTime?.payrollTimeSource ? userData?.employeeTime?.payrollTimeSource : '-Not Set-'}`}  />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Attendance Source'} />
                <KoText className={styles.titleValue} text={`${userData?.employeeTime?.attendanceSource ? userData?.employeeTime?.attendanceSource : '-Not Set-'}`}  />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Attendance Penalization Policy'} />
                <KoText className={styles.titleValue} text={`${userData?.employeeTime?.attendancePenalizationPolicy ? userData?.employeeTime?.attendancePenalizationPolicy : '-Not Set-'}`}  />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Shift Weekly Off Rule'} />
                <KoText className={styles.titleValue} text={`${userData?.employeeTime?.attendancePenalizationPolicy ? userData?.employeeTime?.attendancePenalizationPolicy : '-Not Set-'}`}  />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Shift Allowance Policy'} />
                <KoText className={styles.titleValue} text={`${userData?.employeeTime?.shiftAllowancePolicy ? userData?.employeeTime?.shiftAllowancePolicy : '-Not Set-'}`}  />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Overtime'} />
                <KoText className={styles.titleValue} text={`${userData?.employeeTime?.overtime ? userData?.employeeTime?.overtime : '-Not Set-'}`}  />
              </div>
            
            </div>
          </div>
          <KoDivider orientation="left" orientationMargin="0">
            <div className={styles.heading}>
              <KoText className={styles.headingText} text={'Other'} />
              {
                userData?.userId === getCookie('userId') &&
             <KoIcon width={14} height={14} name={'EditOutlined'} onClick={() => setOtherModal(true)}/>
              }
            </div>
          </KoDivider>
          <div className={styles.card}>
            <div className={styles.fieldList}>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Expense Policy'} />
                <KoText className={styles.titleValue} text={`${userData?.others?.expensePolicy ? userData?.others?.expensePolicy : '-Not Set-'}`}  />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'TimeSheet Policy'} />
                <KoText className={styles.titleValue} text={`${userData?.others?.timesheetPolicy ? userData?.others?.timesheetPolicy : '-Not Set-'}`}  />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Loan Policy'} />
                <KoText className={styles.titleValue} text={`${userData?.others?.loanPolicy ? userData?.others?.loanPolicy : '-Not Set-'}`}  />
              </div>
            </div>
          </div>
        </div>
       
      </KoCard>

      <KoCard hoverable={false} className={styles.tabs}>
        <div className={styles.previewWrapper}>

          <KoDivider orientation="left" orientationMargin="0">
            <div className={styles.heading}>
              <KoText className={styles.headingText} text={'Organization'} />
              {
                userData?.userId === getCookie('userId') &&
             <KoIcon width={14} height={14} name={'EditOutlined'} onClick={() => setOrgModal(true)}/>
              }
            </div>
          </KoDivider>
          <div className={styles.card}>
            <div className={styles.fieldListTwo}>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Business Unit'} />
                <KoText className={styles.titleValue} text={preferenceData?.companyName ? preferenceData?.companyName : "-Not set-"} />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Department'} />
                <KoText className={styles.titleValue} text={'Development'} />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Location'} />
                <KoText className={styles.titleValue} text={userData?.organization?.location ? userData?.organization?.location : "-Not set-"} />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Cost Center'} />
                <KoText className={styles.titleValue} text={userData?.organization?.costCenter ? userData?.organization?.costCenter : "-Not set-"} />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Legal Entity'} />
                <KoText className={styles.titleValue} text={userData?.organization?.legalEntity ? userData?.organization?.legalEntity : "-Not set-"} />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Reporting to'} />
                <div className={styles.profileName}>
                  {ReporterDetails?.profile?.['logo'] ?
                    <KoDP logo={ReporterDetails?.profile?.['logo']} className={styles.profile} width={30} height={30} />
                    :
                    <KoAvatar inLine={'0rem'} options={[{ name: ReporterDetails?.profile?.firstName }]} size={30}/>}
                  <div className={styles.userInfo}>
                    <KoText className={styles.titleValue} text={`${ReporterDetails?.profile?.firstName} ${ReporterDetails?.profile?.lastName}`} />
                  </div>
                </div>
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Manager of Manager'} />
                {ManagerDetails? <div className={styles.profileName}>
                  {ManagerDetails?.profile?.['logo'] ?
                    <KoDP logo={ManagerDetails?.profile?.['logo']} className={styles.profile} width={30} height={30} />
                    :
                    <KoAvatar inLine={'0rem'} options={[{ name: ManagerDetails?.profile?.firstName }]} size={30}/>}
                  <div className={styles.userInfo}>
                    <KoText className={styles.titleValue} text={`${ManagerDetails?.profile?.firstName} ${ManagerDetails?.profile?.lastName}`} />
                  </div>
                </div>:
                  <div className={styles.viewMode}>
                    <KoText className={styles.title} text={'Legal Entity'} />
                    <KoText className={styles.titleValue} text={userData?.organization?.legalEntity ? userData?.organization?.legalEntity : "-Not set-"} />
                  </div>}
              </div>
            </div>
          </div>
        </div>
      </KoCard>

      <KoModal
        title={`Employee Details Update`}
        centered
        open={isOpen}
        handleOk={() => { setModal(false); }}
        onClose={() => { setModal(false); }}
        width={780}
        // maskClosable={false}
        height={650}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setModal(!isOpen)} />,
          <KoButton key='create' type='primary' label={`Update`}
            onClick={() => form.submit()} />
        ]}
      >
        <Crud form={form} handleFinish={handleSubmit} initialValues={userData}/>
      </KoModal>
      
      <KoModal
        title={`Employee Time Update`}
        centered
        open={isTimeOpen}
        handleOk={() => { setTimeModal(false); }}
        onClose={() => { setTimeModal(false); }}
        width={780}
        // maskClosable={false}
        height={650}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setTimeModal(!isTimeOpen)} />,
          <KoButton key='create' type='primary' label={`Update`}
            onClick={() => form.submit()} />
        ]}
      >
        <TimeCrud form={form} handleFinish={handleSubmit} initialValues={userData}/>
      </KoModal>
      <KoModal
        title={`Employee Other Details Update`}
        centered
        open={isOtherOpen}
        handleOk={() => { setOtherModal(false); }}
        onClose={() => { setOtherModal(false); }}
        width={780}
        // maskClosable={false}
        height={650}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setOtherModal(!isOtherOpen)} />,
          <KoButton key='create' type='primary' label={`Update`}
            onClick={() => form.submit()} />
        ]}
      >
        <OtherCrud form={form} handleFinish={handleSubmit} initialValues={userData}/>
      </KoModal>
      <KoModal
        title={`Organization Details Update`}
        centered
        open={isOrgOpen}
        handleOk={() => { setOrgModal(false); }}
        onClose={() => { setOrgModal(false); }}
        width={780}
        // maskClosable={false}
        height={650}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setOrgModal(!isOrgOpen)} />,
          <KoButton key='create' type='primary' label={`Update`}
            onClick={() => form.submit()} />
        ]}
      >
        <OrgCrud form={form} handleFinish={handleSubmit} initialValues={userData}/>
      </KoModal>
    </div>
  );
}
