import { createSlice } from '@reduxjs/toolkit';
import { getCookie, setCookie } from 'shared/utils/cookies';

const token = getCookie("accessToken") || '';
const userId = getCookie("userId") || '';
const accountId = getCookie("accountId") || '';

const initialState = {
  status: 'offline',
  authenticated: Boolean(token && userId && token !== "undefined" && userId !== "undefined"),
  userId,
  user: {},
  accessToken: token,
  accountId
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action) {
      const { userId, user, accessToken, accountId } = action.payload;
      state.status = 'online';
      state.authenticated = true;
      state.userId = userId;
      state.user = user;
      state.accessToken = accessToken;
      state.accountId = accountId;
    },
    logout(state) {
      state.status = 'offline';
      state.authenticated = false;
      state.userId = '';
      state.user = {};
      state.accessToken = '';
      state.accountId = '';
    },
    updateUser(state, action) {
      setCookie("accessToken", action?.payload.accessToken);
      setCookie("userId", action?.payload.userId, 1);
      setCookie("accountId", action?.payload.accountId, 1);
      updateUserWithMiddleware(action.payload);
      // Update state only; external logic for cookies or other actions should be handled in middleware
      state.user = { ...state.user, ...action.payload };
    }
  }
});

// Async action creator for middleware management
export const updateUserWithMiddleware = (payload) => (dispatch) => {
  setCookie("accessToken", payload.accessToken);
  setCookie("userId", payload.userId, 1);
  setCookie("accountId", payload.accountId, 1);
  dispatch(authSlice.actions.updateUser(payload));
};

export const { login, logout, updateUser } = authSlice.actions;

export default authSlice.reducer;
