import {KoAPI} from "packages";
import { configs } from 'configuration';
const version = configs().API_VERSION;


export const CalendarAPI = {
  getMeetings(){
    return KoAPI.get(`/api/${version}/org/meeting`);
  },
  createMeeting(data, id){
    if(id) {
      return KoAPI.update(`/api/${version}/org/meeting/${id}`, { data: { ...data } });
    } else {
      return KoAPI.post(`/api/${version}/org/meeting`, { data: { ...data } });
    }
  },
  deleteMeeting(id){
    return KoAPI.delete(`/api/${version}/org/meeting/${id}`);
  },
  getEvents(){
    return KoAPI.get(`/api/${version}/events`);
  },
  getAllBookings(eventId){
    return KoAPI.get(`/api/${version}/events/booking/${eventId}`);
  },
  createOrUpdateEvents(data, id){
    if(id) {
      return KoAPI.update(`/api/${version}/events/${id}`, { data: { ...data } });
    } else {
      return KoAPI.post(`/api/${version}/events`, { data: { ...data } });
    }
  },
  updateEventStatus(id, data){
    return KoAPI.update(`/api/${version}/events/update/bookingstatus/${id}`, { data: { ...data } });
  },
  deleteEvents(id){
    return KoAPI.delete(`/api/${version}/events/${id}`);
  },

  getEventCountByStatus(){
    return KoAPI.get(`/api/${version}/events/count/status`);
  },
  getTodaySlots(query){
    return KoAPI.get(`/api/${version}/events/slots`, { query: { ...query } });
  }



};