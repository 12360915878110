import React from "react";
function Cross(props) {
  const { width, height , color} = props;
  return (

    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      height={height} width={width}
      {...props}
    >
      <path
        fill={color? color : "#f44336"}
        d="M16 2c-18.498.586-18.502 27.412 0 28 18.498-.586 18.502-27.412 0-28z"
        className="colorf44336 svgShape"
      />
      <path
        fill="#eee"
        d="M17.414 16l4.243-4.243c.931-.904-.51-2.345-1.414-1.414L16 14.586l-4.243-4.243c-.904-.931-2.345.51-1.414 1.414L14.586 16l-4.243 4.243c-.93.904.51 2.346 1.414 1.414L16 17.414l4.243 4.243c.904.932 2.345-.51 1.414-1.414z"
        className="coloreee svgShape"
      />
    </svg>
  );
}


export default Cross;