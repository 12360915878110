import React from "react";
function Pdf(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 512 512">
      <g>
        <path fill="red" d="M189.031,111.069V6.177L68.398,126.817h104.884C184.618,126.817,189.031,122.404,189.031,111.069z" />
        <path fill="red" d="M252.038,254.612c2.19-1.672,4.397-15.914,8.777-37.329c4.397-21.398-1.104-34.563-12.605-32.899
		c-11.535,1.63-14.26,14.794-13.157,28.519c1.087,13.708,8.208,29.072,12.605,37.856
		C247.658,250.759,249.848,256.251,252.038,254.612z"/>
        <path fill="red" d="M198.258,365.431c-3.728,1.379-28.503,8.785-43.314,23.036c-14.829,14.277-13.742,26.881-7.138,31.27
		c6.57,4.381,25.226-8.777,36.761-22.484c11.518-13.733,19.192-27.442,19.192-29.631
		C203.759,365.431,202.672,363.777,198.258,365.431z"/>
        <path fill="red" d="M239.45,341.835c10.934-4.38,50.436-13.174,50.436-13.174s-25.762-30.701-29.071-36.745
		C260.815,291.917,245.452,331.947,239.45,341.835z"/>
        <path fill="red" d="M429.091,0h-216.74v125.104c0,13.8-11.234,25.043-25.026,25.043H62.498V491.58
		c0,11.259,9.161,20.42,20.411,20.42h346.182c11.25,0,20.411-9.161,20.411-20.42V20.404C449.502,9.152,440.342,0,429.091,0z
		 M362.322,380.77c-19.325-2.633-42.814-22.719-62.707-40.246c-0.586-0.201-1.104-0.318-1.488-0.318
		c-3.227,0-39.118,10.34-66.401,18.088c-39.854,71.266-79.558,91.612-98.198,68.032c-9.796-12.412-6.018-41.702,41.158-63.652
		c9.478-4.38,22.668-9.57,38.048-14.26c13.14-23.864,26.397-56.838,33.82-76.356c-12.104-23.153-21.933-47.544-23.037-59.137
		c-2.206-23.062,7.674-43.917,27.433-43.917c25.176,0,43.866,24.14,14.36,109.038c9.194,15.088,21.632,33.385,35.976,47.904
		c24.792-4.722,49.566-6.185,59.865-5.567c35.507,2.14,42.88,18.716,43.966,31.337C406.222,364.337,390.307,384.623,362.322,380.77z
		"/>
        <path fill="red" d="M358.478,331.947c-13.24-0.853-47.728,3.836-43.9,5.474c0,0,27.984,19.786,44.417,21.959
		c16.5,2.19,22.535-4.932,22.535-12.062C381.53,340.18,375.495,333.05,358.478,331.947z"/>
      </g>
    </svg>
  );
}


export default Pdf;