import React, { useState, useCallback } from 'react';
import DndContext from './dndContext';
import DraggableItem from './draggableItem';
import DroppableArea from './droppableArea';
import { FormItemTypes } from './formItemTypes';
import {
  KoButton, KoIcon, KoForm,
  KoModal, KoNano, KoText, KoTitle, KoTextArea, KoInput,
  KoNumber, KoCheckBox, KoDate, KoAttachment, KoSwitch, KoDataTableEdit,
  KoSelect,
  KoRadio
} from 'packages';


import { useDrag, useDrop } from 'react-dnd';
import styles from '../template.module.scss';
import InputUpdate from './currentProperties/input';
import TextAreaUpdate from './currentProperties/textArea';
import NumberUpdate from './currentProperties/number';
import DropdownUpdate from './currentProperties/dropdown';
import AssigneeUpdate from './currentProperties/assignee';
import CheckboxUpdate from './currentProperties/checkbox';
import RadioUpdate from './currentProperties/radio';
import DatePickerUpdate from './currentProperties/datepicker';
import YesNoUpdate from './currentProperties/yesno';
import AttachmentUpdate from './currentProperties/attachment';
import WebsiteUpdate from './currentProperties/website';
import CustomerUpdate from './currentProperties/customer';
import ProjectUpdate from './currentProperties/project';
import CountryUpdate from './currentProperties/country';
import LineItemUpdate from './currentProperties/lineItem';
import FieldSetUpdate from './currentProperties/fieldset';
import DividerSetUpdate from './currentProperties/divder';

const FormBuilder = ({ saveFormData, currentRecord, saveForm }) => {
  const [form] = KoForm.useForm();
  const [formItems, setFormItems] = useState( currentRecord?.fields ? currentRecord?.fields : []);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [currentProperties, setCurrentProperties] = useState({});
  const [dataSource] = useState([{
    key: KoNano('INV'),
    index: 1
  }, {
    key: KoNano('INV'),
    index: 2
  }, {
    key: KoNano('INV'),
    index: 3
  }
  ]);


  const handleDrop = (item) => {
    let id =KoNano('FID');
    const newItem = { ...item, key: id, id: id };
    setFormItems((prevItems) => [...prevItems, newItem]);
  };


  const handleSaveProperties = (currentProperties) => {
    setFormItems((prevItems) =>
      prevItems.map((item) =>
        item.id === currentItem.id ? { ...item, ...currentProperties } : item
      )
    );
    setIsModalVisible(false);
  };

  const moveItem = (dragIndex, hoverIndex) => {
    const updatedItems = [...formItems];
    const [movedItem] = updatedItems.splice(dragIndex, 1);
    updatedItems.splice(hoverIndex, 0, movedItem);
    setFormItems(updatedItems);
  };

  const handleDeleteItem = (itemId) => {
    setFormItems((prevItems) => prevItems.filter((item) => item.id !== itemId));
  };


  const DraggableFormItem = ({ item, index }) => {
    const [, drag] = useDrag({
      type: 'FORM_ITEM',
      item: { index }
    });

    const [, drop] = useDrop({
      accept: 'FORM_ITEM',
      hover: (draggedItem) => {
        if (draggedItem.index !== index) {
          moveItem(draggedItem.index, index);
          draggedItem.index = index;
        }
      }
    });

    return (
      <div className={styles.formItems} ref={(node) => drag(drop(node))}>
        {renderFormItem(item)}
      </div>
    );
  };

  const renderFormItem = (item) => {
    switch (item.type) {
    case FormItemTypes[0].type:
      return (
        <>
          <div className={styles.heading}>
            <div className={styles.titleIndex}>
              <KoText text='1' className={styles.index} />
              <KoText text={item?.label} className={styles.indexLabel} />
            </div>
            <div className={styles.actions}>
              <div className={styles.deleteAction} onClick={() => { form.resetFields(); openPropertyEditor(item); }}>
                <KoIcon name='EditOutlined' />
              </div>
              <div className={styles.deleteAction} onClick={() => handleDeleteItem(item.id)}>
                <KoIcon name='DeleteOutlined' />
              </div>
            </div>
          </div>
          <KoForm.Item  key={item.id} label={item.label || 'Input'}>
            <KoInput placeholder={item.placeholder || 'Input'} />
          </KoForm.Item>
        </>
      );
    case FormItemTypes[1].type:
      return (
        <>
          <div className={styles.heading}>
            <div className={styles.titleIndex}>
              <KoText text='1' className={styles.index} />
              <KoText text={item?.label} className={styles.indexLabel} />
            </div>
            <div className={styles.actions}>
              <div className={styles.deleteAction} onClick={() => { form.resetFields(); openPropertyEditor(item); }}>
                <KoIcon name='EditOutlined' />
              </div>
              <div className={styles.deleteAction} onClick={() => handleDeleteItem(item.id)}>
                <KoIcon name='DeleteOutlined' />
              </div>
            </div>
          </div>
          <KoForm.Item  key={item.id} label={item.label || 'Input'}>
            <KoTextArea placeholder={item.placeholder || 'Input'}
            />
          </KoForm.Item>
        </>

      );
    case FormItemTypes[2].type:
      return (
        <>
          <div className={styles.heading}>
            <div className={styles.titleIndex}>
              <KoText text='1' className={styles.index} />
              <KoText text={item?.label} className={styles.indexLabel} />
            </div>
            <div className={styles.actions}>
              <div className={styles.deleteAction} onClick={() => { form.resetFields(); openPropertyEditor(item); }}>
                <KoIcon name='EditOutlined' />
              </div>
              <div className={styles.deleteAction} onClick={() => handleDeleteItem(item.id)}>
                <KoIcon name='DeleteOutlined' />
              </div>
            </div>
          </div>
          <KoForm.Item  key={item.id} label={item.label || 'Input'}>
            <KoNumber placeholder={item.placeholder || 'Input'}
            />
          </KoForm.Item>
        </>

      );
    case FormItemTypes[3].type:
      return (
        <>
          <div className={styles.heading}>
            <div className={styles.titleIndex}>
              <KoText text='1' className={styles.index} />
              <KoText text={item?.label} className={styles.indexLabel} />
            </div>
            <div className={styles.actions}>
              <div className={styles.deleteAction} onClick={() => { form.resetFields(); openPropertyEditor(item); }}>
                <KoIcon name='EditOutlined' />
              </div>
              <div className={styles.deleteAction} onClick={() => handleDeleteItem(item.id)}>
                <KoIcon name='DeleteOutlined' />
              </div>
            </div>
          </div>
          <KoForm.Item  key={item.id} label={item.label || 'Input'}>
            <KoSelect mode={item.isMultiSelect ? 'multiple' : ''} options={item.options} placeholder={item.placeholder || 'Input'}
            />
          </KoForm.Item>
        </>
      );
    case FormItemTypes[4].type:
      return (
        <>
          <div className={styles.heading}>
            <div className={styles.titleIndex}>
              <KoText text='1' className={styles.index} />
              <KoText text={item?.label} className={styles.indexLabel} />
            </div>
            <div className={styles.actions}>
              <div className={styles.deleteAction} onClick={() => { form.resetFields(); openPropertyEditor(item); }}>
                <KoIcon name='EditOutlined' />
              </div>
              <div className={styles.deleteAction} onClick={() => handleDeleteItem(item.id)}>
                <KoIcon name='DeleteOutlined' />
              </div>
            </div>
          </div>
          <KoForm.Item  key={item.id} label={item.label || 'Input'}>
            <KoSelect mode={item.isMultiSelect ? 'multiple' : ''} options={item.options} placeholder={item.placeholder || 'Input'}
            />
          </KoForm.Item>
        </>
      );
    case FormItemTypes[5].type:
      return (
        <>
          <div className={styles.heading}>
            <div className={styles.titleIndex}>
              <KoText text='1' className={styles.index} />
              <KoText text={item?.label} className={styles.indexLabel} />
            </div>
            <div className={styles.actions}>
              <div className={styles.deleteAction} onClick={() => { form.resetFields(); openPropertyEditor(item); }}>
                <KoIcon name='EditOutlined' />
              </div>
              <div className={styles.deleteAction} onClick={() => handleDeleteItem(item.id)}>
                <KoIcon name='DeleteOutlined' />
              </div>
            </div>
          </div>
          <KoForm.Item  key={item.id} label={item.label || 'Input'}>
            <KoCheckBox options={item.options} placeholder={item.placeholder || 'Input'}
            />
          </KoForm.Item>
        </>
      );
    case FormItemTypes[6].type:
      return (
        <>
          <div className={styles.heading}>
            <div className={styles.titleIndex}>
              <KoText text='1' className={styles.index} />
              <KoText text={item?.label} className={styles.indexLabel} />
            </div>
            <div className={styles.actions}>
              <div className={styles.deleteAction} onClick={() => { form.resetFields(); openPropertyEditor(item); }}>
                <KoIcon name='EditOutlined' />
              </div>
              <div className={styles.deleteAction} onClick={() => handleDeleteItem(item.id)}>
                <KoIcon name='DeleteOutlined' />
              </div>
            </div>
          </div>
          <KoForm.Item  key={item.id} label={item.label || 'Input'}>
            <KoRadio options={item.options || []} placeholder={item.placeholder || 'Input'}
            />
          </KoForm.Item>
        </>
      );
    case FormItemTypes[7].type:
      return (
        <>
          <div className={styles.heading}>
            <div className={styles.titleIndex}>
              <KoText text='1' className={styles.index} />
              <KoText text={item?.label} className={styles.indexLabel} />
            </div>
            <div className={styles.actions}>
              <div className={styles.deleteAction} onClick={() => { form.resetFields(); openPropertyEditor(item); }}>
                <KoIcon name='EditOutlined' />
              </div>
              <div className={styles.deleteAction} onClick={() => handleDeleteItem(item.id)}>
                <KoIcon name='DeleteOutlined' />
              </div>
            </div>
          </div>
          <KoForm.Item   key={item.id} name={['lineItem', item.id]} label={item.label || 'Table'}>
            <KoDataTableEdit
              dataSource={dataSource}
              form={form}
              defaultColumns={item.defaultColumns} />
          </KoForm.Item>
        </>
      );
    case FormItemTypes[8].type:
      return (
        <>
          <div className={styles.heading}>
            <div className={styles.titleIndex}>
              <KoText text='1' className={styles.index} />
              <KoText text={item?.label} className={styles.indexLabel} />
            </div>
            <div className={styles.actions}>
              <div className={styles.deleteAction} onClick={() => { form.resetFields(); openPropertyEditor(item); }}>
                <KoIcon name='EditOutlined' />
              </div>
              <div className={styles.deleteAction} onClick={() => handleDeleteItem(item.id)}>
                <KoIcon name='DeleteOutlined' />
              </div>
            </div>
          </div>
          <KoForm.Item  key={item.id} label={item.label || 'Input'}>
            <KoDate dateFormat={'DD-MMM-YYYY'} />
          </KoForm.Item>
        </>
      );
    case FormItemTypes[9].type:
      return (
        <>
          <div className={styles.heading}>
            <div className={styles.titleIndex}>
              <KoText text='1' className={styles.index} />
              <KoText text={item?.label} className={styles.indexLabel} />
            </div>
            <div className={styles.actions}>
              <div className={styles.deleteAction} onClick={() => { form.resetFields(); openPropertyEditor(item); }}>
                <KoIcon name='EditOutlined' />
              </div>
              <div className={styles.deleteAction} onClick={() => handleDeleteItem(item.id)}>
                <KoIcon name='DeleteOutlined' />
              </div>
            </div>
          </div>

          <KoForm.Item  key={item.id} label={item.label || 'Input'} className={styles.switchAction}>
            <KoSwitch defaultChecked />
          </KoForm.Item>
        </>
      );
    case FormItemTypes[10].type:
      return (
        <>
          <div className={styles.heading}>
            <div className={styles.titleIndex}>
              <KoText text='1' className={styles.index} />
              <KoText text={item?.label} className={styles.indexLabel} />
            </div>
            <div className={styles.actions}>
              <div className={styles.deleteAction} onClick={() => { form.resetFields(); openPropertyEditor(item); }}>
                <KoIcon name='EditOutlined' />
              </div>
              <div className={styles.deleteAction} onClick={() => handleDeleteItem(item.id)}>
                <KoIcon name='DeleteOutlined' />
              </div>
            </div>
          </div>
          <KoForm.Item  key={item.id} label={item.label || 'Input'}>
            <KoAttachment placeholder={item.placeholder || 'Input'} />
          </KoForm.Item>
        </>
      );
    case FormItemTypes[11].type:
      return (
        <>
          <div className={styles.heading}>
            <div className={styles.titleIndex}>
              <KoText text='1' className={styles.index} />
              <KoText text={item?.label} className={styles.indexLabel} />
            </div>
            <div className={styles.actions}>
              <div className={styles.deleteAction} onClick={() => { form.resetFields(); openPropertyEditor(item); }}>
                <KoIcon name='EditOutlined' />
              </div>
              <div className={styles.deleteAction} onClick={() => handleDeleteItem(item.id)}>
                <KoIcon name='DeleteOutlined' />
              </div>
            </div>
          </div>
          <KoForm.Item  key={item.id} label={item.label || 'Input'}>
            <KoInput placeholder={item.placeholder || 'Input'} />
          </KoForm.Item>
        </>
      );
    case FormItemTypes[12].type:
      return (
        <>
          <div className={styles.heading}>
            <div className={styles.titleIndex}>
              <KoText text='1' className={styles.index} />
              <KoText text={item?.label} className={styles.indexLabel} />
            </div>
            <div className={styles.actions}>
              <div className={styles.deleteAction} onClick={() => { form.resetFields(); openPropertyEditor(item); }}>
                <KoIcon name='EditOutlined' />
              </div>
              <div className={styles.deleteAction} onClick={() => handleDeleteItem(item.id)}>
                <KoIcon name='DeleteOutlined' />
              </div>
            </div>
          </div>
          <KoForm.Item  key={item.id} label={item.label || 'Input'}>
            <KoSelect mode={item.isMultiSelect ? 'multiple' : ''} options={item.options} placeholder={item.placeholder || 'Input'}
            />
          </KoForm.Item>
        </>
      );
    case FormItemTypes[13].type:
      return (
        <>
          <div className={styles.heading}>
            <div className={styles.titleIndex}>
              <KoText text='1' className={styles.index} />
              <KoText text={item?.label} className={styles.indexLabel} />
            </div>
            <div className={styles.actions}>
              <div className={styles.deleteAction} onClick={() => { form.resetFields(); openPropertyEditor(item); }}>
                <KoIcon name='EditOutlined' />
              </div>
              <div className={styles.deleteAction} onClick={() => handleDeleteItem(item.id)}>
                <KoIcon name='DeleteOutlined' />
              </div>
            </div>
          </div>
          <KoForm.Item  key={item.id} label={item.label || 'Input'}>
            <KoSelect mode={item.isMultiSelect ? 'multiple' : ''} options={item.options} placeholder={item.placeholder || 'Input'}
            />
          </KoForm.Item>
        </>
      );
    case FormItemTypes[14].type:
      return (
        <>
          <div className={styles.heading}>
            <div className={styles.titleIndex}>
              <KoText text='1' className={styles.index} />
              <KoText text={item?.label} className={styles.indexLabel} />
            </div>
            <div className={styles.actions}>
              <div className={styles.deleteAction} onClick={() => { form.resetFields(); openPropertyEditor(item); }}>
                <KoIcon name='EditOutlined' />
              </div>
              <div className={styles.deleteAction} onClick={() => handleDeleteItem(item.id)}>
                <KoIcon name='DeleteOutlined' />
              </div>
            </div>
          </div>
          <KoForm.Item  key={item.id} label={item.label || 'Input'}>
            <KoSelect mode={item.isMultiSelect ? 'multiple' : ''} options={item.options} placeholder={item.placeholder || 'Input'}
            />
          </KoForm.Item>
        </>
      );
    case FormItemTypes[15].type:
      return (
        <>
          <div className={styles.heading}>
            <div className={styles.titleIndex}>
              <KoText text='1' className={styles.index} />
              <KoText text={item?.label} className={styles.indexLabel} />
            </div>
            <div className={styles.actions}>
              <div className={styles.deleteAction} onClick={() => { form.resetFields(); openPropertyEditor(item); }}>
                <KoIcon name='EditOutlined' />
              </div>
              <div className={styles.deleteAction} onClick={() => handleDeleteItem(item.id)}>
                <KoIcon name='DeleteOutlined' />
              </div>
            </div>
          </div>
          <KoForm.Item  key={item.id} >
            <fieldset>
              <legend>{item?.label}</legend>
            </fieldset>
          </KoForm.Item>
        </>
      );
    case FormItemTypes[16].type:
      return (
        <>
          <div className={styles.heading}>
            <div className={styles.titleIndex}>
              <KoText text='1' className={styles.index} />
              <KoText text={item?.label} className={styles.indexLabel} />
            </div>
            <div className={styles.actions}>
              <div className={styles.deleteAction} onClick={() => { form.resetFields(); openPropertyEditor(item); }}>
                <KoIcon name='EditOutlined' />
              </div>
              <div className={styles.deleteAction} onClick={() => handleDeleteItem(item.id)}>
                <KoIcon name='DeleteOutlined' />
              </div>
            </div>
          </div>
          <KoForm.Item  key={item.id} >
            <fieldset>
              <legend>{item?.label}</legend>
            </fieldset>
          </KoForm.Item>
        </>
      );
    default:
      return null;
    }
  };

  const openPropertyEditor = (item) => {
    setCurrentItem(item);
    setCurrentProperties(item);
    setIsModalVisible(true);
  };

  const handleSubmit = useCallback(values => {
    let data = saveForm.getFieldsValue();
    data['fields'] = formItems;
    saveForm.setFieldsValue(data);
    saveFormData(data);
  });

  return (
    <DndContext>
      <div className={styles.tempCurdWraper}>
        <div className={styles.formBasicFields}>
          <div className={styles.dnd}>
            <KoText className={styles.title} text={'Form Basic Fields'} />
            {
              FormItemTypes.map((filed, i) =>
                <DraggableItem key={i} id="input1" type={filed?.type} label={filed?.label}>
                  <div className={styles.box} >
                    <KoIcon name={'DragOutlined'} width={11} height={11} />
                    <KoIcon name={filed?.icon} />
                    <KoText text={filed?.label} />
                  </div>
                </DraggableItem>

              )}

          </div>
        </div>
        <div className={styles.createForm}>
          <KoTitle level={5} className={styles.title} text={'Create Form'} />

          <KoForm layout="vertical" form={saveForm} initialValues={currentRecord} onFinish={handleSubmit} onSubmit={handleSubmit}>
            <KoForm.Item name={'name'} label={'Name'}>
              <KoInput placeholder={'Enter template name'} />
            </KoForm.Item>
            <KoForm.Item name={'columns'} label={'Columns'}>
              <KoNumber placeholder={'Enter description'} />
            </KoForm.Item>

            <KoForm.Item name={'description'} label={'Description'}>
              <KoInput placeholder={'Enter description'} />
            </KoForm.Item>
            <KoForm.Item name={'fields'} label={'Fields'}>
              <DroppableArea onDrop={handleDrop}>
                {formItems.map((item, index) => (
                  <DraggableFormItem key={item.id} item={item} index={index} />
                ))}
              </DroppableArea>
            </KoForm.Item>
          </KoForm>

        </div>
        <div className={styles.formActions}>
          <div className={styles.publish}>
            <KoText className={styles.title} text={'Share Form'} />
            <KoButton type={'primary'} label={'Publish'} />
          </div>
        </div>
      </div>

      <KoModal
        title="Edit Properties"
        open={isModalVisible}
        centered
        onClose={() => { setIsModalVisible(false); }}
        width={600}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setIsModalVisible(false)} />,
          <KoButton key='reset' type='primary' label='Update' onClick={() => form.submit()} />
        ]}
      >
        <div className={styles.updateFieldsWrapper}>
          {currentItem?.type === 'input' &&
            <InputUpdate form={form} currentItem={currentItem} currentRecord={currentRecord} currentProperties={currentProperties} FormItemTypes={FormItemTypes}
              handleSaveProperties={handleSaveProperties} />
          }
          {currentItem?.type === 'textarea' &&
            <TextAreaUpdate form={form} currentItem={currentItem} currentRecord={currentRecord} currentProperties={currentProperties} FormItemTypes={FormItemTypes} handleSaveProperties={handleSaveProperties} />
          }
          {currentItem?.type === 'number' &&
            <NumberUpdate form={form} currentItem={currentItem} currentRecord={currentRecord} currentProperties={currentProperties} FormItemTypes={FormItemTypes}
              handleSaveProperties={handleSaveProperties} />
          }
          {currentItem?.type === 'select' &&
            <DropdownUpdate form={form} currentItem={currentItem} currentRecord={currentRecord} currentProperties={currentProperties} FormItemTypes={FormItemTypes} handleSaveProperties={handleSaveProperties} />
          }
          {currentItem?.type === 'user' &&
            <AssigneeUpdate form={form} currentItem={currentItem} currentRecord={currentRecord} currentProperties={currentProperties} FormItemTypes={FormItemTypes} handleSaveProperties={handleSaveProperties} />
          }
          {currentItem?.type === 'checkbox' &&
            <CheckboxUpdate form={form} currentItem={currentItem} currentRecord={currentRecord} currentProperties={currentProperties} FormItemTypes={FormItemTypes} handleSaveProperties={handleSaveProperties} />
          }
          {currentItem?.type === 'radio' &&
            <RadioUpdate form={form} currentItem={currentItem} currentRecord={currentRecord} currentProperties={currentProperties} FormItemTypes={FormItemTypes} handleSaveProperties={handleSaveProperties} />
          }
          {currentItem?.type === 'table' &&
            <LineItemUpdate form={form} currentItem={currentItem} currentRecord={currentRecord} currentProperties={currentProperties} FormItemTypes={FormItemTypes} handleSaveProperties={handleSaveProperties} />
          }
          {currentItem?.type === 'date' &&
            <DatePickerUpdate form={form} currentItem={currentItem} currentRecord={currentRecord} currentProperties={currentProperties} FormItemTypes={FormItemTypes} handleSaveProperties={handleSaveProperties} />
          }
          {currentItem?.type === 'yesno' &&
            <YesNoUpdate form={form} currentItem={currentItem} currentRecord={currentRecord} currentProperties={currentProperties} FormItemTypes={FormItemTypes} handleSaveProperties={handleSaveProperties} />
          }
          {currentItem?.type === 'upload' &&
            <AttachmentUpdate form={form} currentItem={currentItem} currentRecord={currentRecord} currentProperties={currentProperties} FormItemTypes={FormItemTypes} handleSaveProperties={handleSaveProperties} />
          }
          {currentItem?.type === 'website' &&
            <WebsiteUpdate form={form} currentItem={currentItem} currentRecord={currentRecord} currentProperties={currentProperties} FormItemTypes={FormItemTypes} handleSaveProperties={handleSaveProperties} />
          }
          {currentItem?.type === 'customer' &&
            <CustomerUpdate form={form} currentItem={currentItem} currentRecord={currentRecord} currentProperties={currentProperties} FormItemTypes={FormItemTypes} handleSaveProperties={handleSaveProperties} />
          }
          {currentItem?.type === 'project' &&
            <ProjectUpdate form={form} currentItem={currentItem} currentRecord={currentRecord} currentProperties={currentProperties} FormItemTypes={FormItemTypes} handleSaveProperties={handleSaveProperties} />
          }
          {currentItem?.type === 'country' &&
            <CountryUpdate form={form} currentItem={currentItem} currentRecord={currentRecord} currentProperties={currentProperties} FormItemTypes={FormItemTypes} handleSaveProperties={handleSaveProperties} />
          }
          {currentItem?.type === 'fieldset' &&
            <FieldSetUpdate form={form} currentItem={currentItem} currentRecord={currentRecord} currentProperties={currentProperties} FormItemTypes={FormItemTypes} handleSaveProperties={handleSaveProperties} />
          }
          {currentItem?.type === 'divider' &&
            <DividerSetUpdate form={form} currentItem={currentItem} currentRecord={currentRecord} currentProperties={currentProperties} FormItemTypes={FormItemTypes} handleSaveProperties={handleSaveProperties} />
          }
        </div>
      </KoModal>
    </DndContext>
  );
};

export default FormBuilder;
