import React from "react";
function Notification(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 34 34">
      <defs>
        <clipPath id="clipPath">
          <path id="Rectangle_17452" fill="#fff" stroke="#707070" d="M0 0H34V34H0z" transform="translate(266 374)" />
        </clipPath>

      </defs>
      <g id="notification_48px" clipPath="url(#clipPath)" transform="translate(-266 -374)">
        <g id="notification" transform="translate(269.983 377.188)">
          <path id="Path_29228" d="M210.452 355.529a4.417 4.417 0 0 0 4.321-3.529h-8.644a4.42 4.42 0 0 0 4.323 3.529zm0 0" transform="translate(-198.687 -327.297)" />
          <path id="Path_29229" fill="#5b6374" d="M158.662 161.078h-.014a8.216 8.216 0 0 1-7.448-11.725c-.259-.025-.521-.04-.787-.04a8.235 8.235 0 0 0-8.235 8.235v3.28a7.891 7.891 0 0 1-2.809 6.038 2.06 2.06 0 0 0-.647 2.112 2.161 2.161 0 0 0 2.117 1.51h19.145a2.157 2.157 0 0 0 2.151-1.631 2.062 2.062 0 0 0-.7-2.013 7.837 7.837 0 0 1-2.773-5.766zm0 0" transform="translate(-138.648 -146.962)" />
          <path id="Path_29230" d="M278.434 133.881a5.881 5.881 0 1 1-5.881-5.881 5.881 5.881 0 0 1 5.881 5.881zm0 0" transform="translate(-252.553 -128)" />
        </g>
      </g>
    </svg>
  );
}


export default Notification;