

const LEV_ITEMS_COLUMNS = [{
  name: "title",
  id: "title",
  label: "Title",
  widget: "text",
  fixed: true
}, {
  name: "description",
  id: "description",
  label: "Description",
  widget: "Description"
},
{
  name: "date",
  id: "date",
  label: "Date",
  widget: "date",
  format : "DD-MMM-YYYY"
},
{
  name: "day",
  id: "day",
  label: "Day",
  widget: "day"
},
{
  name: "optional",
  id: "optional",
  label: "Optional",
  widget: 'optionalStatus'
}
];

const DAYS = [
  { name: 'Sunday', id: 'sunday' },
  { name: 'Monday', id: 'monday' },
  { name: 'Tuesday', id: 'tuesday' },
  { name: 'Wednesday', id: 'wednesday' },
  { name: 'Thursday', id: 'thursday' },
  { name: 'Friday', id: 'friday' },
  { name: 'Saturday', id: 'saturday' }
];

const WEEKENDS = [
  {
    weekDay: 'Sunday',
    workDay: false
  },
  {
    weekDay: 'Monday',
    workDay: false
  },
  {
    weekDay: 'Tuesday',
    workDay: false
  },
  {
    weekDay: 'Wednesday',
    workDay: false
  },
  {
    weekDay: 'Thursday',
    workDay: false
  },
  {
    weekDay: 'Friday',
    workDay: false
  },
  {
    weekDay: 'Saturday',
    workDay: false
  }
];

export {
  LEV_ITEMS_COLUMNS, DAYS, WEEKENDS
};