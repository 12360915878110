import React from 'react';
import { KoFormBuilder, KoForm } from 'packages';
import styles from './curd.module.scss';

function BankCrud({form, handleFinish, settings }) {

  const meta = {
    columns: 4,
    formItemLayout: null,
    initialValues: settings,
    colon: true,
    fields: [
      {
        key: 'bank.accountName', label: 'Account Name',  placeholder: 'Enter Acc Holder Name', colSpan: 2
      },
      {
        key: 'bank.bankName', label: 'Bank Name', placeholder: 'Enter Bank Name', colSpan: 2
      }, {
        key: 'bank.ifscCode', label: 'IFSC Code', placeholder: 'Enter IFSC code', colSpan: 2
      },{
        key: 'bank.swipeCode', label: 'Swipe code', placeholder: 'Enter Swipe code', colSpan: 2
      }, {
        key: 'bank.accountNo',name:'bank.accountNo'  , label: 'Account No', type:"number", placeholder: 'Enter Account No', colSpan: 2
      }, {
        key: 'bank.confirmAccountNo',label: 'Confirm Account No', type:"number", placeholder: 'Enter Confirm Account No', colSpan: 2
      }
    ]
  };
  return (
    <div className={styles.wrapper}>
      <KoForm form={form} layout="vertical" onFinish={handleFinish} onSubmit={handleFinish}>
        <KoFormBuilder form={form} meta={meta} />
      </KoForm>
    </div>
  );
};

export default BankCrud;