import React from "react";
function Tick(props) {
  const { width, height, color } = props;
  return (
    <svg viewBox="0 0 18 18" height={height} width={width} x="0px" y="0px" {...props}>
      <path
        fill={color? color : "#00DA60"}
        // fill="#00DA60"
        d="M9 16L7.1 16.9 5.8 15.2 3.7 15.1 3.4 13 1.5 12 2.2 9.9 1.1 8.2 2.6 6.7 2.4 4.6 4.5 4 5.3 2 7.4 2.4 9 1.1 10.7 2.4 12.7 2 13.6 4 15.6 4.6 15.5 6.7 17 8.2 15.9 9.9 16.5 12 14.7 13 14.3 15.1 12.2 15.2 10.9 16.9z"
      />
      <path fill="#FFF" d="M13.1 7.3L12.2 6.5 8.1 10.6 5.9 8.5 5 9.4 8 12.4z" />
    </svg>
  );
}


export default Tick;