const COLUMNS_GROUPS = [
  {
    id: "randaomId",
    label: "Assignee",
    widget: "user",
    fixed:true
  }, {
    id: "clientName",
    label: "Client Name",
    widget: "text",
    width: 300
  }, {
    id: "unit",
    label: "Unit",
    widget: "status",
    width: 100
  },{
    id: "qty",
    label: "quantity",
    widget: "status",
    width: 100
  },{
    id: "amount",
    label: "Amount",
    widget: "number",
    width: 100
  },  {
    id: "timeSheetStatus",
    label: "Time sheet staus",
    widget: "status"
  }, {
    id: "status",
    label: "Status",
    widget: "status"
  }
];

const ROW_ACTIONS = [
  {
    randaomId: "Kamal",
    clientName: "Qubernet Technologies",
    created: '',
    unit:'Hr',
    amount:'2000$',
    qty:'200',
    timeSheetStatus:1,
    status:2
  },
  {
    randaomId: "Kamal",
    clientName: "Qubernet Technologies",
    created: '',
    unit:'month',
    qty:'1',
    amount:'1000$',
    timeSheetStatus:3,
    status:2
  }
];

export {
  COLUMNS_GROUPS,
  ROW_ACTIONS
};