import React from "react";
import styles from './priority.module.scss';
import { KoText } from "packages/typography";

function KoPriority(props) {
  const {size, data} = props;
  const priorityList =[
    "Lowest",
    "Low",
    "Medium",
    "High",
    "Highest"
  ];
  return (
    <div className={styles.priorityWrapper}>
      {data > 0 && <div className={`${styles.priority}`}>
        {[1].map((item) => (
          <div key={item} className={styles.priorityStatus}>
            <div  className={`${size ==='small' ? styles.small : styles.dot}  ${data >= item ?  styles[priorityList[data - 1]] : ''}`}/>
            <KoText text={priorityList[data - 1]}/>
          </div>
        ))}
      </div>}
    </div>
  );
}
export default KoPriority;
