import React from "react";
function UAT(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision" textRendering="geometricPrecision" viewBox="0 0 1706.66 1706.66" width={width} height={height}>
      <defs>
        <linearGradient id="a" x1="240.12" x2="1466.52" y1="853.33" y2="853.33" gradientUnits="userSpaceOnUse">
          <stop offset="0" stopColor="var(--get)"></stop>
          <stop offset="1" stopColor="var(--palette-success-main)"></stop>
        </linearGradient>
      </defs>
      <path fill="url(#a)"
        d="M853.39 1153.12c-317.91 0-576.52-258.61-576.52-576.52C276.87 258.68 535.48.07 853.39.07c317.92 0 576.53 258.61 576.53 576.53 0 317.77-258.61 576.52-576.53 576.52zm-186.52 553.47c-7.78 0-14.86-4.44-18.06-11.53l-114.17-244.58-266.25 120.14c-16.56 7.57-33.94-9.52-26.53-26.25l217.93-493.58c106.65 88.67 243.57 142.13 392.77 142.33v-.01l1.47.01c149.17-.15 286.11-53.59 392.79-142.24l217.96 493.64c7.35 16.74-9.83 33.82-26.53 26.25l-266.11-120.28-114.03 244.58c-7.49 15.99-31.28 15.34-37.08-2.08L853.31 1195.3l-167.68 497.55c-2.75 8.13-10.66 13.75-18.76 13.75zm186.52-693.47c-240.83 0-436.66-195.83-436.66-436.66s195.83-436.67 436.66-436.67 436.67 195.83 436.67 436.67c0 240.83-195.83 436.67-436.67 436.67zm-75.13-285.55c-5.14 0-10.28-1.94-14.17-5.83L652.15 609.8c-18.54-18.54 9.53-46.97 28.33-28.33l97.78 97.78 248.05-248.06c18.54-18.54 46.97 9.53 28.33 28.33L792.42 721.74c-3.89 3.89-9.03 5.83-14.17 5.83zm75.13 325.55c-262.91 0-476.66-213.75-476.66-476.66S590.48 99.79 853.39 99.79s476.66 213.75 476.66 476.66-213.75 476.66-476.66 476.66z">
      </path>
    </svg>
  );
}


export default UAT;