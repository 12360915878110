
import { KoButton, KoEmpty, KoForm, KoIcon, KoLoader, KoModal, KoNano, KoTable, KoText, KoTitle, KoToast, KoTooltips } from 'packages';
import React, { useState, useEffect } from 'react';
import { WORKLOG_COLUMNS } from './constant';
import styles from './curd.module.scss';
import WorkLogCrud from './workLogCrud';
import { TaskPlannerAPI } from '../taskPlanner.service';
import { useParams } from 'react-router';
import { getCookie } from 'shared/utils/cookies';

const WorkLog = ({  isCreateLogOpen, setCreateLog, ticketId, getIssueById, workspaceIdFromParam}) => {
  const [currentRecord, setCurrentRecord] = useState();
  const [isLoading, setLoading] = useState(false);
  const [isOpenDelete, setDeleteModal] = useState(false);
  const [workLogList, setLogList] = useState();
  const {workspaceId} = useParams();
  const [form] = KoForm.useForm();

  useEffect(() => {
    getWorkLogs(ticketId, workspaceId);
  }, [ticketId]);

  const getWorkLogs = (ticketId, workspaceId) => {
    setLogList();
    TaskPlannerAPI.getWorkLogs(ticketId, workspaceId || workspaceIdFromParam).then(
      (data) => {
        setLoading(false);
        setLogList(data?.workLogs);
      },
      (err) => {
        setLoading(false);
      }
    );
  };
  
  const handleFinish = (values => {
    setLoading(true);
    if (!currentRecord?.workLogId) {
      values['workLogId'] = KoNano('WKL');
    }
    if (!currentRecord?.assignee) {
      values['assignee'] = getCookie('userId');
    }
    TaskPlannerAPI.createOrUpdateWorkLog(values,
      ticketId,
      currentRecord?.workLogId, workspaceId || workspaceIdFromParam).then(
      (data) => {
        setLoading(false);
        setCreateLog(false);
        form.resetFields();
        getWorkLogs(ticketId, workspaceId || workspaceIdFromParam);
        KoToast.success(`Work Log ${currentRecord?.workLogId ? 'updated':'Added'} successfully`);
        getIssueById(ticketId, workspaceIdFromParam);
      },
      (err) => {
        setLoading(false);
      }
    );
  });


  const DeleteWorkLog = ()=> {
    setLoading(true);
    TaskPlannerAPI.deleteWorkLog(currentRecord?.workLogId,ticketId, workspaceId || workspaceIdFromParam).then(
      (data) => {
        setLoading(false);
        setDeleteModal(false);
        getWorkLogs(ticketId);
        form.resetFields();
        getIssueById(ticketId);
        KoToast.success("Work Log deleted successfully");
      },
      (err) => {
        setLoading(false);
      }
    );
  };


  const columnsRight = [{
    title: '',
    dataIndex: 'x',
    key: 'x',
    fixed: 'right',
    width: 40,
    render: (text, row) => {
      return <div className={`${styles.circle} tableBtn columnsRight`} onClick={() => setCurrentRecord(row)}>
        <KoTooltips title={'Edit'}>
          <KoButton className={'btn'} iconBtn={true} icon={<KoIcon width={14} height={14} name={'EditOutlined'} />} onClick={() => setCreateLog(true)} />
        </KoTooltips>
        <KoTooltips title={'Delete'}>
          <KoButton className={'btn'} iconBtn={true} icon={<KoIcon width={14} height={14} name={'DeleteOutlined'} />} onClick={() => setDeleteModal(true)} />
        </KoTooltips>
      </div>;
    }
  }];

  return (
    <div className={styles.curdWrapperChild}>
      { isLoading && <KoLoader />}
      {(!workLogList || workLogList?.length === 0) ?  <KoEmpty width={300} height={200} onClick={() => setCreateLog(true)} title={'Add a Worklog'}
        subText={''} label={'Add Worklog'}
        description={''}/> :
        <div className={`tableCard`}>
          <div className={styles.tableHeader}>
            <KoTitle level={4} text={`Wokrlogs (${workLogList?.length})`} />
            <KoButton type='primary' label='Add Log' onClick={() => setCreateLog(true)} />
          </div>
          <KoTable rowKey='ticketId'
            data={{ columns: WORKLOG_COLUMNS, rows: workLogList }}
            extraColumnsRight={columnsRight} maxHeight={38} />
        </div>
      }
     
      <KoModal
        title={currentRecord?.workLogId ? 'Update Log' : 'Add Log'}
        centered
        open={isCreateLogOpen}
        afterClose={() => { setCurrentRecord(); }}
        handleOk={() => { setCurrentRecord(); setCreateLog(false); }}
        onClose={() => { setCurrentRecord(); setCreateLog(false); }}
        width={`35vw`}
      // maskClosable={false}
        // height={450}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setCreateLog(!isCreateLogOpen)} />,
          <KoButton key='add' type='primary' label={currentRecord?.workLogId ? 'Update' : 'Add'} onClick={()=>form.submit()}/>
        ]}>
        <div className={styles.subwrapper}>
          <WorkLogCrud form={form} handleFinish={handleFinish} initialValues={currentRecord}/>
        </div>
      </KoModal>

      <KoModal
        title={`Delete Worklog ${currentRecord?.workLogId?.toUpperCase()}`}
        centered
        open={isOpenDelete}
        afterClose={() => { setCurrentRecord(); }}
        handleOk={() => { setCurrentRecord(); setDeleteModal(false); }}
        onClose={() => { setCurrentRecord(); setDeleteModal(false); }}
        width={580}
      // maskClosable={false}
        height={450}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setDeleteModal(!isOpenDelete)} />,
          <KoButton key='delete' loading={isLoading} type='primary' label='Delete Worklog' onClick={() => DeleteWorkLog()} />
        ]}>
        <div className={styles.wrapperModal}>
          <KoTitle level={5} text={`This action will delete item and cannot be undone`} />
          <KoText text={`Do you wish to proceed?`} />
        </div>
      </KoModal>

    </div>
  );
};

export default WorkLog;
