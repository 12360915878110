import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import CSV from 'comma-separated-values';

const CsvViewer = (props) => {
  const parseData = (data) => {
    const rows = [];
    const columns = [];
    if(data)
      new CSV(data).forEach((array) => {
        if (columns.length < 1) {
          array.forEach((cell, idx) => {
            columns.push({
              title: cell,
              dataIndex: `key-${idx}`,
              key: `key-${idx}`,
              sorter: true
            });
          });
        } else {
          const row = {};
          array.forEach((cell, idx) => {
            row[`key-${idx}`] = cell;
          });
          rows.push({ key: rows.length, ...row });
        }
      });

    return { rows, columns };
  };

  const [gridData, setGridData] = useState(parseData(props.data));

  useEffect(() => {
    setGridData(parseData(props.data));
  }, [props.data]); // Re-parse data when props.data changes

  const { rows, columns } = gridData;
  return (
    <Table
      columns={columns}
      dataSource={rows}
      pagination={false}
    />
  );
};

export default CsvViewer;
