import React from "react";
function Totalgroup(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icons/groups">
        <path id="Icons/groups_2" d="M5 8.66667C4.40666 8.66667 3.82664 8.49072 3.33329 8.16108C2.83994 7.83143 2.45542 7.3629 2.22836 6.81472C2.0013 6.26654 1.94189 5.66334 2.05764 5.0814C2.1734 4.49945 2.45912 3.9649 2.87868 3.54535C3.29824 3.12579 3.83279 2.84007 4.41473 2.72431C4.99667 2.60856 5.59987 2.66797 6.14805 2.89503C6.69623 3.12209 7.16476 3.50661 7.49441 3.99996C7.82405 4.4933 8 5.07332 8 5.66667C7.99912 6.46205 7.68276 7.2246 7.12035 7.78701C6.55793 8.34943 5.79538 8.66578 5 8.66667ZM10 13.3333C9.99894 12.4496 9.64741 11.6024 9.02252 10.9775C8.39763 10.3526 7.5504 10.0011 6.66667 10H3.33333C2.4496 10.0011 1.60237 10.3526 0.97748 10.9775C0.352588 11.6024 0.00105857 12.4496 0 13.3333L0 16H10V13.3333ZM11.6667 6C11.0733 6 10.4933 5.82405 9.99996 5.49441C9.50661 5.16477 9.12209 4.69623 8.89503 4.14805C8.66796 3.59987 8.60855 2.99667 8.72431 2.41473C8.84007 1.83279 9.12579 1.29824 9.54535 0.878681C9.9649 0.459123 10.4995 0.173401 11.0814 0.0576455C11.6633 -0.0581101 12.2665 0.00129986 12.8147 0.228363C13.3629 0.455426 13.8314 0.839943 14.1611 1.33329C14.4907 1.82664 14.6667 2.40666 14.6667 3C14.6658 3.79538 14.3494 4.55793 13.787 5.12035C13.2246 5.68276 12.462 5.99912 11.6667 6ZM12.6667 7.33333H9.33333C9.21633 7.3387 9.09971 7.3505 8.984 7.36867C8.74161 7.92954 8.38359 8.43295 7.93333 8.846C8.91088 9.12259 9.77162 9.71028 10.3852 10.52C10.9987 11.3298 11.3316 12.3174 11.3333 13.3333H16V10.6667C15.9989 9.78294 15.6474 8.93571 15.0225 8.31081C14.3976 7.68592 13.5504 7.33439 12.6667 7.33333Z" fill="white" />
      </g>
    </svg>
  );
}


export default Totalgroup;