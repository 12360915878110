import { getCookie, setCookie } from 'shared/utils/cookies';

const token = getCookie("accessToken");
const userId = getCookie("userId");
const accountId = getCookie("accountId");

export const createAuthSlice = (set) => ({
  userInfo: {
    status: 'offline',
    authenticated: token && userId && token !== "undefined" && userId !== "undefined",
    userId: userId,
    user: {},
    accessToken: token,
    accountId: accountId
  },
  
  setUserInfo: (userInfo) => {
    setCookie("accessToken", userInfo.accessToken);
    setCookie("userId", userInfo.userId, 1);
    setCookie("accountId", userInfo.accountId, 1);
    set({
      userInfo: {
        status: 'online',
        authenticated: userInfo.accessToken && userInfo.userId,
        userId: userInfo.userId,
        user: userInfo.user,
        accessToken: userInfo.accessToken,
        accountId: userInfo.accountId
      }
    });
  }
});
