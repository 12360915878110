import _find from 'lodash/find';
import { KoButton, KoDropdown, KoForm, KoIcon, KoLoader, KoModal, KoNano, KoPagination, KoTable, KoText, KoTitle, KoToast, KoTooltips } from 'packages';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import KoToolBar from 'shared/components/toolbar';
import { add, remove } from 'shared/store/breadcrumbSlice';
import { SPRINTSTATUS } from '../../../shared/commonConstants';
import { SPR_ITEMS_COLUMNS } from '../constant';
import { TaskPlannerAPI } from '../taskPlanner.service';
import SprintCrud from './crud';
import Expandable from './expandable';
import Issue from './Issue';
import styles from './sprint.module.scss';
import { setSelectedChatData, setSelectedChatType } from 'shared/store/chatSlice';

export default function Sprint(props) {
  const {workspaceId} = useParams();
  const dispatch = useDispatch();
  const [sprints, setSprints] = useState();
  const { title, profile } = props;
  const [totalCount, setTotalCount] = useState(100);
  const [isLoading, setLoading] = useState(false);
  const [isOpen, setModal] = useState(false);
  const [isOpenGoalLeavePlan, setOpenGoalLeavePlan] = useState(false);
  const [isOpenLeavePlan, setOpenLeavePlan] = useState(false);
  const [isOpenSprintGoal, setOpenSprintGoal] = useState(false);
  const [isIssueModel, setIssueModel] = useState(false);
  const [isOpenActivate, setActivateModal] = useState(false);
  const [isOpenSuspend, setSuspendModal] = useState(false);
  const [isOpenClose, setCloseModal] = useState(false);
  const [isOpenDelete, setDeleteModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({
    status: 1
  });
  const [pagination, setPagination] = useState({
    size: 20,
    page: 1
  });

  const [form] = KoForm.useForm();
  const workspace = useSelector(state => state?.workspace);
  let workspaceList = workspace['data'];
  const currentWorkspace = _find(workspaceList, function(o) { return o.workspaceId === workspaceId; });


  useEffect(()=>{
    dispatch(remove());
    dispatch(add([
      {
        title: 'workspace',
        path: 'workspace'
      },
      {
        title: currentWorkspace?.name
      },
      {
        title: 'Sprints'
      }
    ]));

  }, [workspace]);

  useEffect(() => {
    GetSprints();
  }, []);

  const GetSprints = () => {
   
    setLoading(true);
    TaskPlannerAPI.getSprints(pagination, workspaceId).then(
      (data) => {
        setSprints();
        setTotalCount();
        setSprints(data?.sprints);
        setTotalCount(data?.totalCount);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const onChangeStatus=(key)=>{
    let q =  pagination;
    if(key === '5'){
      delete q?.[`status`];
    }
    else if(key){
      q['status'] = key.toString();
    }
    else{
      delete q?.status;
    }
    GetSprints(q);
  };

  const onSearchText=(text)=>{
    let q = pagination;
    if(text){
      q['search'] = text;
    }
    else{
      delete q?.['search'];
    }
    GetSprints(q);
  };
  
  const onSort = (key, type) => {
    let q = pagination;
    q['sort'] = `${key}:${type}`;
    GetSprints(q);
  };


  const DeleteSprints = () => {
    TaskPlannerAPI.deleteSprint(currentRecord?.sprintId, workspaceId).then(
      (data) => {
        setLoading(false);
        setModal(false);
        setDeleteModal(false);
        GetSprints();
        KoToast.success("Sprint Deleted Successfully");
      },
      (err) => {
        setLoading(false);
      }
    );
  };


  const onPagination = (page, other) => {
    const query = {
      size: other,
      page: page
     
    };
    const p = Object.assign(pagination, query);
    setPagination(p);
    GetSprints(p);
  };

  const handleFinish = (values => {
    setLoading(true);
    if (!currentRecord?.sprintId) {
      values['sprintId'] = KoNano('spr');
    }

    TaskPlannerAPI.createOrUpdateSprint(values, currentRecord?.sprintId,workspaceId).then(
      (data) => {
        setLoading(false);
        setModal(false);
        GetSprints();
        KoToast.success(`Sprint ${currentRecord?.sprintId ? 'Updated': 'Created'} Successfully`);
        form.resetFields();
      },
      (err) => {
        setLoading(false);
      }
    );
  });

  const handleFinishActivate = (status) => {
    let obj = {
      status: status
    };
    TaskPlannerAPI.updateSprintStatus(obj, currentRecord?.sprintId,workspaceId).then(
      (data) => {
        setLoading(false);
        setActivateModal(false);
        setSuspendModal(false);
        setCloseModal(false);
        GetSprints();
        if (status === 2) {
          KoToast.success('Sprint Activated');
        }
        else if (status === 3) {
          KoToast.success('Sprint Suspended');
        }
        else if (status === 4) {
          KoToast.success('Sprint Closed');
        }
      },
      (err) => {
        setLoading(false);
      }
    );
  };
  const onHandelSprint = (e, spr) => {
    setCurrentRecord(spr);
    if (e.key === '0') {
      setActivateModal(true);
    }
    // else if (e.key === "1") {
    //   setModal(true);
    // }
    else if (e.key === "2") {
      setSuspendModal(true);
    }
    else if (e.key === "3") {
      setCloseModal(true);
    }
    else if (e.key === '4') {
      setDeleteModal(true);
    }
  };
  const setCurrentRecordModify = (data) => {
    setCurrentRecord(data);
  };





  const StatusFilter = [...SPRINTSTATUS,
    {
      label: 'Clear',
      key: 5,
      value:5,
      icon: <KoIcon name="ActiveSprintOutlined" />
    }
  ];



  const columnsRight = [{
    title: '',
    dataIndex: 'x',
    key: 'x',
    fixed: 'right',
    width:80,
    render: (text, row) => {
      return <div className={`${styles.circle} tableBtn columnsRight`} onClick={() => setCurrentRecordModify(row)}>
        <KoTooltips title={'Leave Plan'}>
          <KoButton onClick={()=>{setOpenGoalLeavePlan(true); setOpenLeavePlan(true);}} className={'btn'}  iconBtn={true}
            icon={<KoIcon width={14} height={14} name={'CalendarOutlined'}/>}/>
        </KoTooltips>
        <KoTooltips title={'Sprint Goal'}>
          <KoButton onClick={()=>{setOpenGoalLeavePlan(row); setOpenSprintGoal(true);}} className={'btn'}  iconBtn={true}
            icon={<KoIcon width={14} height={14} name={'GoalFilled'}/>}/>
        </KoTooltips>
        <KoTooltips title={'Edit'}>
	      <KoButton  className={'btn'} iconBtn={true} icon={<KoIcon width={14} height={14} name={'EditOutlined'}/>} onClick={() => setModal(true)}/>
        </KoTooltips>
        <div shape="circle">
          <KoDropdown
            menu={{
              items : [
                ...(row?.status !== 2 ? [{
                  key: '0',
                  label: (<KoText text='Activate' />),
                  icon: <KoIcon name="ActiveSprintFilled" height={12} width={13} />

                }] : []),
                ...(row?.status !== 3 ? [{
                  key: '2',
                  label: (<KoText text='Suspend' />),
                  icon: <KoIcon name="SuspendOutlined" height={12} width={13} />

                }] : []),
                ...(row?.status !== 4 ? [{
                  key: '3',
                  label: (<KoText text='Close' />),
                  icon: <KoIcon name="ClosedSprintOutlined" height={12} width={13} />

                }] : []),
                {
                  type: 'divider'
                },
                {
                  key: '4',
                  danger: true,
                  label: 'Delete',
                  icon: <KoIcon name="DeleteOutlined" height={12} width={13} />
                }
              ],
              onClick: onHandelSprint
            }}
            overlayClassName={styles.actionDropdown}
            placement="bottomLeft"
          >
            <KoButton className={'btn'}  iconBtn={true} icon={<KoIcon width={14} height={14} name={'VerticalMenuOutlined'}/>}/>
          </KoDropdown>
        </div>
      </div>;
    }
  }];

  const onCellClick=(row)=>{
    setCurrentRecord(row);
    setIssueModel(true);
    const updatedRoomObj = { ...row, source: 'tickets' };
    dispatch(setSelectedChatData(updatedRoomObj));
    dispatch(setSelectedChatType('tickets'));
  };




  return (
    <div className='tableCard'>
      { isLoading && <KoLoader />}
      <KoToolBar
        title={title}
        search={{ visible: true, onChange: (e) => { onSearchText(e);}, placeholder: 'Search by any parameter' }}
        filter={{ visible: true, options: StatusFilter , onClick: (e) => { onChangeStatus(e);}}}
        buttonList={[{
          label: 'Create',
          type: 'primary',
          icon: <KoIcon color="#FFF" name="PlusOutlined" width={18} height={18} />,
          onClick: () => setModal(!isOpen)
        }]} />

      <KoTable
        rowKey='sprintId'
        onSort={onSort}
        onCellClick={onCellClick}
        data={{ columns: SPR_ITEMS_COLUMNS, rows: sprints }} extraColumnsRight={columnsRight} maxHeight={100} />
      <KoPagination current={parseInt(pagination.page) || 1}
        total={totalCount}
        showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} records`
        }
        pageSize={pagination.limit}
        defaultCurrent={pagination.page}
        onChange={onPagination}
        showQuickJumper={false}
        simple={false}
        responsive={false} />

      <KoModal
        title={`${currentRecord?.sprintId ? 'Update' : 'Create'} Sprint`}
        centered
        afterClose={() => {setCurrentRecord();}}
        open={isOpen}
        handleOk={() => { setCurrentRecord(); setModal(false); }}
        onClose={() => { setCurrentRecord(); setModal(false); }}
        width={840}
        // maskClosable={false}
        height={650}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setModal(!isOpen)} />,
          <KoButton key='create' loading={isLoading} type='primary' label={currentRecord?.sprintId ? `Update` : `Create`}
            onClick={() => form.submit()} />
        ]}
      >
        <SprintCrud initialValues={currentRecord} form={form} handleFinish={handleFinish} profile={profile}/>
      </KoModal>

      <KoModal
        title={`Activate Sprint ${currentRecord?.name?.toUpperCase()}`}
        centered
        open={isOpenActivate}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setActivateModal(false); }}
        onClose={() => { setCurrentRecord(); setActivateModal(false); }}
        width={580}
        // maskClosable={false}
        height={450}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setActivateModal(!isOpenActivate)} />,
          <KoButton key='reset' loading={isLoading} type='primary' label='Activate' onClick={() => handleFinishActivate(2)} />
        ]}
      >
        <div className={styles.delWrapper}>
          <KoTitle level={5} text='Are you ready to Activate this sprint?' />
          <KoText text={`Activating it will move tasks to the active phase and allow you to track their progress.`} />
        </div>
      </KoModal>

      <KoModal
        title={`Suspend Sprint ${currentRecord?.name?.toUpperCase()}`}
        centered
        open={isOpenSuspend}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setSuspendModal(false); }}
        onClose={() => { setCurrentRecord(); setSuspendModal(false); }}
        width={580}
        // maskClosable={false}
        height={450}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setSuspendModal(!isOpenSuspend)} />,
          <KoButton key='reset' loading={isLoading} type='primary' label='Suspend' onClick={() => handleFinishActivate(3)} />
        ]}
      >
        <div className={styles.delWrapper}>
          <KoTitle level={5} text='Are you sure you want to suspend this sprint?' />
          <KoText text={`Suspended sprints will pause task tracking and prevent further progress updates.`} />
        </div>
      </KoModal>

      <KoModal
        title={`Close Sprint ${currentRecord?.name?.toUpperCase()}`}
        centered
        open={isOpenClose}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setCloseModal(false); }}
        onClose={() => { setCurrentRecord(); setCloseModal(false); }}
        width={580}
        // maskClosable={false}
        height={450}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setCloseModal(!isOpenClose)} />,
          <KoButton key='reset' loading={isLoading} type='primary' label='Close Sprint' onClick={() => handleFinishActivate(4)} />
        ]}
      >
        <div className={styles.delWrapper}>
          <KoTitle level={5} text='Are you sure you want to close this sprint?' />
          <KoText text={`Closing it will finalize the tasks and prevent any further changes or updates.`} />
        </div>
      </KoModal>
      <KoModal
        title={`Delete Sprint ${currentRecord?.name?.toUpperCase()}`}
        centered
        open={isOpenDelete}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setDeleteModal(false); }}
        onClose={() => { setCurrentRecord(); setDeleteModal(false); }}
        width={580}
        // maskClosable={false}
        height={450}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setDeleteModal(!isOpenDelete)} />,
          <KoButton key='delete' loading={isLoading} type='primary' label='Delete Sprint' onClick={() => DeleteSprints()} />
        ]}
      >
        <div className={styles.delWrapper}>
          <KoTitle level={5} text='Are you sure you want to delete this sprint?' />
          <KoText text={`This action will permanently remove the sprint and all associated tasks. This cannot be undone.`} />
        </div>
      </KoModal>


      <KoModal
        title={(isOpenSprintGoal === true ? 'Sprint goal' : isOpenLeavePlan === true ? 'Team leave plan' : '')}
        open={isOpenGoalLeavePlan}
        handleOk={() => { setCurrentRecord(); setOpenGoalLeavePlan(false); setOpenSprintGoal(false); setOpenLeavePlan(false); }}
        onClose={() => { setCurrentRecord(); setOpenGoalLeavePlan(false); setOpenSprintGoal(false); setOpenLeavePlan(false);}}
        width={930}
        centered
        footer={[
          <KoButton key='cancel' label='Close' onClick={() => {setOpenGoalLeavePlan(false); setOpenSprintGoal(false); setOpenLeavePlan(false);}} />
        ]}
      >
        <Expandable currentRecord={currentRecord} isOpenLeavePlan={isOpenLeavePlan} isOpenSprintGoal={isOpenSprintGoal}/>
      </KoModal>

      <KoModal
        title={currentRecord?.name}

        centered
        open={isIssueModel}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); }}
        onClose={() => { setCurrentRecord(); setIssueModel(false);}}
        width={`95vw`}
        // height={450}
        // maskClosable={false}
        footer={false}
      >
        <Issue profile={profile} currentSprintRecord={currentRecord}/>
      </KoModal>

    </div>
  );
}

