import styles from './status.module.scss';

function OverDueStatus(props) {
  let { data } = props;
  let statusClass;

  if (data < 3) {
    statusClass = styles.low;
  } else if (data >= 3 && data <= 5) {
    statusClass = styles.low;
  } else {
    statusClass = styles.low;
  }

  let DAY = data > 1 ? 'Days' : 'Day';
  return (
    <div className={styles.wrapper}>
      <div className={`${styles.status} ${statusClass}`}>
        <div className={styles.text}>{`${data} ${DAY}`}</div>
      </div>
    </div>
  );
}
export default OverDueStatus;
