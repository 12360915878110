import React from "react";
function Numbered(props) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 25 24"
      width={width}
      height={height}
    >
      <g clipPath="url(#a)" fill="#000" className="color000 svgShape">
        <path
          d="M23.907 4.5h-17a.5.5 0 110-1h17a.5.5 0 010 1zm0 8h-17a.5.5 0 110-1h17a.5.5 0 010 1zm0 8h-17a.5.5 0 110-1h17a.5.5 0 010 1zm-21.5 3c-1.103 0-2-.897-2-2a.5.5 0 011 0 1.001 1.001 0 002 0c0-.551-.448-1-1-1h-.5a.5.5 0 110-1h.5a1.001 1.001 0 000-2c-.552 0-1 .449-1 1a.5.5 0 11-1 0c0-1.103.897-2 2-2s2 .897 2 2c0 .597-.263 1.133-.679 1.5.416.367.68.904.68 1.5 0 1.103-.898 2-2 2zm1.5-8h-3a.5.5 0 01-.354-.854l1.94-1.94c.581-.58.914-1.384.914-2.205a1 1 0 00-2 0V11a.5.5 0 01-1 0v-.5c0-1.103.897-2 2-2s2 .897 2 2A4.096 4.096 0 013.2 13.414L2.114 14.5h1.793a.5.5 0 010 1zm-1-8a.5.5 0 01-.5-.5V2.652L1.323 4.278a.5.5 0 01-.832-.555l2-3A.5.5 0 013.407 1v6a.5.5 0 01-.5.5z"
          className="color000 svgShape"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path
            fill="#fff"
            transform="translate(.407)"
            className="colorfff svgShape"
            d="M0 0H24V24H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}


export default Numbered;


