import React, { useState, useEffect } from 'react';
import { KoTabs, KoLoader, KoTitle } from 'packages';
 
import MyOverview from './myOverview';
import TicketsOverview from './ticketsOverview';
import WorkspaceInsights from './workspaceInsights';
import TrackActivities from './trackActivities';
import { useNavigate, useParams } from 'react-router';
import styles from './myOverview/myOverview.module.scss';
import { useSelector } from 'react-redux';
const Dashboard = () => {
  const userById = useSelector(state => state.userById);
  let userDetails = userById['data'];
  const [activeTab, setActiveTab] = useState();
  const [loading, setLoading] = useState(true);
  const {tabId} = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    setActiveTab(tabId);
    setLoading(false);
  }, [tabId]);

  let tabs = [
    {
      label: 'My Overview',
      key: 'myoverview',
      children: <MyOverview />
    },
    {
      label: 'Tickets Overview',
      key: 'ticketsOverview',
      children: <TicketsOverview />
    },
    {
      label: 'Workspace Insights',
      key: 'workspaceInsights',
      children: <WorkspaceInsights />
    },
    {
      label: 'Track Activities',
      key: 'activities',
      children: <TrackActivities />
    }
    
  ];

  const changeTabs = (key) => {
    console.log(key);
    navigate(`/home/dashboard/${key}`);
  };

  const  getGreeting=()=> {
    const currentHour = new Date().getHours();
  
    if (currentHour < 12) {
      return '🌞 Good Morning';
    } else if (currentHour < 16) {
      return '☀️ Good Afternoon';
    } else {
      return '🌇 Good Evening';
    }
  };

  return (
    <div className={'profileSection'}>
      {loading && <KoLoader />}
      <div className={styles.dashboardHeader}>
        {
          userDetails?.profile &&
            <KoTitle text={`${getGreeting()}, ${userDetails?.profile?.firstName}! Here is your summary`} level={5} />
        }
      </div>
      <KoTabs activeKey={activeTab} options={tabs} onChange={changeTabs}/>
    </div>
  );
};
export default Dashboard;