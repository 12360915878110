import JSZip from 'jszip';
import KoButton from 'packages/button';
import KoTable from 'packages/datatable';
import KoIcon from 'packages/icon';
import KoModal from 'packages/modal';
import KoTooltips from 'packages/tooltips';
import { useEffect, useState } from 'react';
import styles from './archive.module.scss';
import { KoText } from 'packages/typography';

const ArchiveViewer = ({ file }) => {
  const [fileTree, setFileTree] = useState([]);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  useEffect(() => {
    if (typeof file === 'string') {
      fetchFileAsBlob(file);
    } else if (file instanceof Blob) {
      loadZipFile(file);
    } else {
      setError('Invalid file type.');
    }
  }, [file]);
  const buildFileTree = (files) => {
    const tree = [];

    files?.forEach(({ name, isFolder, zipEntry }) => {
      const pathParts = name.split('/');
      let currentLevel = tree;

      pathParts?.forEach((part, index) => {
        let existing = currentLevel.find(item => item.name === part);
        if (!existing) {
          existing = {
            key: pathParts?.slice(0, index + 1).join('/'),
            name: part,
            isFolder: index < pathParts.length - 1 || isFolder,
            children: [],
            zipEntry: zipEntry
          };
          currentLevel.push(existing);
        }
        if (index < pathParts.length - 1) {
          currentLevel = existing.children;
        }
      });
    });

    return tree;
  };

  const loadZipFile = async(fileBlob) => {
    try {
      const zip = await JSZip.loadAsync(fileBlob);
      const files = [];

      zip.forEach((relativePath, zipEntry) => {
        files.push({
          name: relativePath,
          isFolder: zipEntry.dir,
          zipEntry: zipEntry
        });
      });

      setFileTree(buildFileTree(files));
    } catch (error) {
      setError('Error reading the archive.');
    }
  };

  const fetchFileAsBlob = async(fileUrl) => {
    try {
      const response = await fetch(fileUrl);
      const fileBlob = await response.blob();
      loadZipFile(fileBlob);
    } catch (err) {
      setError('Error fetching the file.');
    }
  };

  const downloadFile = async(zipEntry = null) => {
    try {
      const getFileName = (fullName, isBlob = false, blobFileName = '') => {
        const baseName = isBlob ? blobFileName : fullName.split('/').pop();
        const nameWithoutExtension = baseName.replace(/\.\w+$/, '');
        const extension = baseName.includes('.') ? baseName.split('.').pop() : 'zip';
        const match = nameWithoutExtension.match(/^\D+/);
        return match ? `${match[0]}.${extension}` : `download.${extension}`;
      };

      const triggerDownload = (blob, fileName) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        link.remove();
        URL.revokeObjectURL(url);
      };

      if (zipEntry) {
        const fileData = await zipEntry.async('blob');
        const fileName = getFileName(zipEntry.name);
        triggerDownload(fileData, fileName);
      }
      else {
        let fileBlob;

        if (typeof file === 'string') {
          const response = await fetch(file);
          fileBlob = await response.blob();
        } else if (file instanceof Blob) {
          fileBlob = file;
        } else {
          throw new Error('Invalid file type.');
        }

        const fileName = getFileName(file.name || file, true, file.name);
        triggerDownload(fileBlob, fileName || 'archive.zip');
      }
    } catch (err) {
      setError('Error downloading the file.');
    }
  };


  const onRowExpand = (expanded, record) => {
    setExpandedRowKeys((prevKeys) => (
      expanded ? [...prevKeys, record.key] : prevKeys.filter((key) => key !== record.key)
    ));
  };

  const renderFileTreeTable = () => {
    const columns = [
      {
        label: 'Name',
        dataIndex: 'name',
        key: 'name',
        widget: 'folder'
      },
      {
        label: 'Date',
        key: 'date',
        widget: 'date',
        format: "DD-MMM-YYYY"
      }
    ];


    const columnsRight = [{
      title: '',
      dataIndex: 'x',
      key: 'x',
      fixed: 'right',
      width: 40,
      render: (data, row) => (
        <div className={`${styles.table} tableBtn columnsRight`} >
      
          <KoTooltips title={'Download'}>
            <KoButton
              onClick={() => downloadFile(row.zipEntry)}
              size="small"
              className={'btn'}
              iconBtn={true}
              icon={<KoIcon width={14} height={14} name={'DownloadOutlined'} />}
            />
          </KoTooltips>
        </div>
      )
    }];
    return (
      <KoTable
        rowKey="name"
        data={{
          columns: columns,
          rows: Array.isArray(fileTree) ? fileTree : []
        }}
        extraColumnsRight={columnsRight}
        expandedRowKeys={expandedRowKeys}
        onRowExpand={onRowExpand}
        maxHeight={800}
        expandable={{
          expandedRowRender: (record) =>
            record.children && Array.isArray(record.children) && record.children.length > 0 && (
              <KoTable
                rowKey="key"
                expandable={{
                  columns: columns,
                  rows: record?.children,
                  rowKey: 'key'
                }}
                maxHeight={500}
                extraColumnsRight={columnsRight}
                onRowExpand={onRowExpand}
                expandedRowKeys={expandedRowKeys}
              />
            ),
          rowExpandable: (record) => Array.isArray(record.children) && record.children.length > 0
        }}
      />
    );
  };

  const fileName = typeof file === 'string' ? decodeURIComponent(file.split('/').pop().split('?')[0]) : file instanceof Blob ? 'Uploaded File' : 'Unknown File';

  return (
    <div className={styles.hoverContainer}>
      {error && <p>{error}</p>}
      <div className={styles.fileName}>
        <KoIcon name="ZipFilled" width={40} height={40}/>
        <KoText ellipsis={true} text={fileName} />
      </div>
      {/* <KoTooltips title={'Download'}>
        <KoButton
          key="download"
          iconButton
          className={styles.downloadBtn}
          size="small"
          onClick={() => downloadFile()}
          icon={<KoIcon name="DownloadOutlined" />}
        />
      </KoTooltips> */}
      {/* 
      <div className={styles.hoverBtn}>
        <KoTooltips title={'View'}>
          <KoButton
            key="view"
            iconButton
            size="small"
            onClick={() => setIsModalOpen(true)}
            icon={<KoIcon name="EyeOpenedOutlined" />}
          />
        </KoTooltips>
      </div> */}

      <KoModal
        title="View Archive"
        centered
        open={isModalOpen}
        handleOk={() => setIsModalOpen(false)}
        onClose={() => setIsModalOpen(false)}
        width={580}
        height={450}
        footer={[
          <KoButton key="cancel" label="Cancel" onClick={() => setIsModalOpen(false)} />
        ]}
      >
        <div>{renderFileTreeTable()}</div>
      </KoModal>

    </div>
  );
};

export default ArchiveViewer;
