import React, { useState, useEffect } from 'react';
import { KoTabs, KoLoader, KoTitle } from 'packages';
 
import TimeSheetApproval from './timesheet';

import { useNavigate, useParams } from 'react-router';
import styles from './inbox.module.scss';
import { useSelector } from 'react-redux';
const Inbox = () => {
  const userById = useSelector(state => state.userById);
  let userDetails = userById['data'];
  const [activeTab, setActiveTab] = useState();
  const [loading, setLoading] = useState(true);
  const {approvalId} = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    setActiveTab(approvalId);
    setLoading(false);
  }, [approvalId]);

  let tabs = [
    {
      label: 'Timesheet Approvals',
      key: 'tms',
      children: <TimeSheetApproval />
    }
    // {
    //   label: 'PR Approvals',
    //   key: 'prapprovals',
    //   children: <TimeSheetApproval />

    // },
    // {
    //   label: 'Release Approvals',
    //   key: 'releaseapprovals',
    //   children: <TimeSheetApproval />
    // }
  ];

  const changeTabs = (key) => {
    console.log(key);
    navigate(`/home/${key}/`);
  };

  const  getGreeting=()=> {
    const currentHour = new Date().getHours();
  
    if (currentHour < 12) {
      return '🌞 Good Morning';
    } else if (currentHour < 16) {
      return '☀️ Good Afternoon';
    } else {
      return '🌇 Good Evening';
    }
  };

  return (
    <div className={'profileSection approval'}>
      {loading && <KoLoader />}
      <div className={styles.dashboardHeader}>
        {
          userDetails?.profile &&
            <KoTitle text={`${getGreeting()}, ${userDetails?.profile?.firstName}! Here is people are waiting for your approval`} level={5} />
        }
      </div>
      <KoTabs activeKey={activeTab} options={tabs} onChange={changeTabs}/>
    </div>
  );
};
export default Inbox;