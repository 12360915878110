
import { KoButton, KoCard, KoDateFormatter, KoFlex, KoIcon, KoImage, KoLoader, KoText, KoTitle, KoTooltips } from 'packages';
// import styles from '../profile.module.scss';
import { workspaceAPI } from 'pages/workspace/workspace.service';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getCompanyLogo, getCompanyName } from 'shared/utils';
import { getCookie } from 'shared/utils/cookies';
import styles from './profileJob.module.scss';
export default function ProfileJob({userData, userDetails}) {
  const [isLoading, setLoading] = useState(false);
  const [dataClients, setDataClients] = useState();
  let navigate = useNavigate();
  const workspace = useSelector((state) => state.workspace);
  const [copy, setCopy] = useState(false);
  const accountId = getCookie("accountId");

  useEffect(()=>{
    getClients();
  },[]);

  const getClients=()=>{
    setLoading(true);
    workspaceAPI.getClients().then(
      (data) => {
        setDataClients(data['customers']);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const onCopyPublicUrl=(text)=>{
    setCopy(true);
    navigator.clipboard.writeText(`${text}`);
  };

  const KeyColor = (bgColor) => {
    const isColorDark = (color) => {
      const hex = color.replace("#", "");
      const [r, g, b] = [parseInt(hex.substr(0, 2), 16), parseInt(hex.substr(2, 2), 16), parseInt(hex.substr(4, 2), 16)];
      const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
      return luminance < 128;
    };
    
    return {
      backgroundColor: bgColor,
      color: isColorDark(bgColor) ? "#ffffff" : "#000000"
    };
  };

  return <div className={styles.wrapper}>
    {isLoading && <KoLoader />}

    {workspace['data']?.length > 0 && <KoFlex wrap="wrap" gap="small" className={styles.listSection}>
      {workspace['data']?.map((workspace, i) =>
        <KoCard className={styles.card}
          key={workspace?.workspaceId}
          hoverable={true}>
        
          <div className={styles.aLink}>
            <div onClick={() => navigate(`/${workspace?.workspaceId}/taskplanner/dashboard`)}>
              <div className={styles.titleKey}>
                <KoImage
                  src={`${getCompanyLogo(dataClients, workspace?.customer)}`}
                  width={35}
                  preview={false}
                  alt={`doc${i % 3}`}
                />
                <div className={styles.titleValue}>
                  <div className={styles.verified}>
                    <KoText className={`${styles.text}`} text={workspace?.name} />
                  </div>
                  <KoTitle className={styles.title} ellipsis={true}  text={getCompanyName(dataClients, workspace?.customer)} level={4} />
                </div>
              </div>
              <KoText style={workspace?.color && KeyColor(workspace?.color)} className={`${styles.key} ${!workspace?.color && styles[workspace?.key? workspace?.key?.charAt(0).toLowerCase() :'a']}`}
                text={`${workspace?.key}`} />
              <div className={styles.description} >
                <KoText text={`${workspace?.description ? workspace?.description :'-'}`} ellipsis={true}/>
              </div>
            </div>
            <div className={styles.link}>
              <div className={styles.row}>
                <KoText className={styles.date}  text='Created on'/>
                <KoDateFormatter format='DD-MMM-YYYY' data={workspace?.createdAt} className={styles.date}/>
              </div>
              <div className={styles.workspaceLink}>
                {workspace?.isPublic && (
                  <KoTooltips title={copy ? 'Copied' : 'Copy Public Url'}>
                    <KoButton className={styles.copyBtn} onClick={()=>onCopyPublicUrl(`https://app.kwapio.com/support/tickets/${accountId}/${workspace?.workspaceId}`)} iconBtn={true} icon={<KoIcon width={14} height={14} name={'CopyOutlined'}/>}/>
                  </KoTooltips>
                ) }
              </div>
            </div>
          </div>
        </KoCard>
      )}
    </KoFlex>
    }
 

 



  



  
  </div>;
}
