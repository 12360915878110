import React from "react";
import styles from './progress.module.scss';

function KoProgress(props) {
  const { options } = props;
  return (
    <div className={styles.wrapper}>
      {options.map((opt, i)=>
        <div key={i} style={{ width: opt?.count+'%' }} className={styles[`${'progress_'+opt?.value}`]}></div>
      )}
    </div>
  );
}

export default KoProgress;