import { KoDate, KoForm, KoFormBuilder, KoSelect, KoText, KoTextArea, KoUserSelect } from 'packages';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TaskPlannerAPI } from '../taskPlanner.service';
import styles from './crud.module.scss';
const ReleaseCrud = ({ form, handleFinish, initialValues, profile }) => {
  const  wosAssignees = useSelector(state => state.wosAssignees);
  const [environments, setEnvironments] = useState();
  
  useEffect(() => {
    if (!initialValues || Object.keys(initialValues).length === 0) {
      form.resetFields();
    } else {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  useEffect(() => {
    getEnvironments();
  }, []);


  const handleReleasePlanSubmit = useCallback(values => {
    handleFinish(values);
  });

  const getEnvironments = () => {
    setEnvironments();
    TaskPlannerAPI.getEnvironments().then(
      (data) => {
        setEnvironments(data?.environments);
      },
      (err) => {
      }
    );
  };
  const Environments = ({ value, onChange }) =>
    <KoSelect value={value}
      onChange={v => onChange(v)}
      showSearch
      placeholder='Select Build Environment'
      options={environments?.map((env) => ({
        label: env?.name,
        value: env?.environmentId
      }))}
    />;

  const ApprovalSTATUS = [
    {
      label: 'Todo',
      value: 1
    },
    {
      label: 'In Progress',
      value: 2
    },
    {
      label: 'Approved',
      value: 3
    }
  ];
  const STATUS = [
    {
      label: 'Todo',
      value: 1
    },
    {
      label: 'In Progress',
      value: 2
    },
    {
      label: 'Done',
      value: 3
    }
  ];

  const Status = ({ value, onChange }) =>
    <KoSelect value={value}
      showSearch
      onChange={v => { onChange(v); }}
      placeholder='Select status'
      options={STATUS}
      optionRender={(option) => (
        <div className={styles.statusWrapper}>
          <div className={`${styles.status} ${styles[option?.data?.label?.replace(" ", '')]}`} role="img" aria-label={option.data.label} />
          <KoText text={option.data.label} />
        </div>
      )}
    />;

  const QaApproval = ({ value, onChange }) =>
    <KoSelect value={value}
      showSearch
      onChange={v => { onChange(v); }}
      placeholder='Select approval'
      options={ApprovalSTATUS}
      optionRender={(option) => (
        <div className={styles.statusWrapper}>
          <div className={`${styles.status} ${styles[option?.data?.label?.replace(" ", '')]}`} role="img" aria-label={option.data.label} />
          <KoText text={option.data.label} />
        </div>
      )}
    />;

  const PoApproval = ({ value, onChange }) =>
    <KoSelect value={value}
      showSearch
      onChange={v => { onChange(v); }}
      placeholder='Select approval'
      options={ApprovalSTATUS}
      optionRender={(option) => (
        <div className={styles.statusWrapper}>
          <div className={`${styles.status} ${styles[option?.data?.label?.replace(" ", '')]}`} role="img" aria-label={option.data.label} />
          <KoText text={option.data.label} />
        </div>
      )}
    />;

  const Date = ({ value, onChange }) =>
    <KoDate value={value} dateFormat={'DD-MMM-YYYY'} daysAgo={0}
      onChange={v => onChange(v)} />;

  const PoApprover = ({ value, onChange }) =>
    <KoUserSelect value={value} onChange={v => onChange(v)} users={wosAssignees[0]}  placeholder='Select Approver person' mode='multiple'/>;

  const QoApprover = ({ value, onChange }) =>
    <KoUserSelect value={value} onChange={v => onChange(v)} users={wosAssignees[0]}   placeholder='Select Approver person' mode='multiple'/>;
  

  const meta = {
    columns: 4,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      {
        key: 'name', placeholder: 'Enter Release name', label: 'Release Name', colSpan: 2, rules: [
          {
            required: true,
            message: `Please enter name.`
          }
        ]
      }, {
        key: 'status', label: 'Status', colSpan: 2,
        widget: Status, rules: [
          {
            required: true,
            message: `Please select status.`
          }
        ]
      },
      {
        key: 'qaApprovel', label: 'QA approval status', colSpan: 2,
        widget: QaApproval, rules: [
          {
            required: true,
            message: `Please select approval.`
          }
        ]
      },
      {
        key: 'qaApprover', label: 'QA approver', colSpan: 2,
        widget: QoApprover, rules: [
          {
            required: true,
            message: `Please select Approver.`
          }
        ]
      },

      {
        key: 'poApprovel', label: 'PO approval status', colSpan: 2,
        widget: PoApproval, rules: [
          {
            required: true,
            message: `Please select approval.`
          }
        ]
      },
      {
        key: 'poApprover', label: 'PO Approver', colSpan: 2,
        widget: PoApprover, rules: [
          {
            required: true,
            message: `Please select Approver.`
          }
        ]
      },
      {
        key: 'build', label: 'Build pass', colSpan: 2,
        widget: Environments, forwardRef: true, rules: [
          {
            // required: true,
            message: `Please select build environment.`
          }
        ]
      },
      { key: 'startDate', placeholder: 'Enter Start Date', label: 'Start Date', colSpan: 2, widget: Date },
      { key: 'endDate', placeholder: 'Enter End Date', label: 'End Date', colSpan: 2, widget: Date },
      {
        key: 'releaseNotes', widget: KoTextArea, label: 'Release Notes', colSpan: 4, placeholder: 'Enter Release Notes'
      }

    ]

  };

  return (
    <div className={styles.wrapper}>
      <KoForm form={form} layout="vertical" onFinish={handleReleasePlanSubmit} onSubmit={handleReleasePlanSubmit} >
        <KoFormBuilder form={form} meta={meta} />
      </KoForm>
    </div>
  );
};

export default ReleaseCrud;
