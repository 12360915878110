import React from "react";
function Strikethrough(props) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 32 32"
      width={width}
      height={height}
    >
      <path
        fill="#000"
        fillRule="evenodd"
        d="M18 2a7 7 0 017 7v1a1 1 0 11-2 0V9a5 5 0 00-5-5h-4a5 5 0 00-5 5v1a5 5 0 004.997 5H29a1 1 0 110 2h-6.101A6.979 6.979 0 0125 22v1a7 7 0 01-7 7h-4a7 7 0 01-7-7v-.983a1 1 0 112 0V23a5 5 0 005 5h4a5 5 0 005-5v-1a5 5 0 00-5-5H3a1 1 0 110-2h6.101A6.979 6.979 0 017 10V9a7 7 0 017-7h4z"
        clipRule="evenodd"
        className="color000 svgShape"
      />
    </svg>

  );
}


export default Strikethrough;