import React, { useState, useEffect } from 'react';
import { KoIcon, KoButton, KoDataTableEdit, KoNano, KoText, KoSelect, KoForm, KoNumber } from 'packages';
import styles from './curd.module.scss';

function LineItemEntryCrud({ fields, form, customer }) {
  let lineItemName = fields?.children?.name;
  let colFields = fields?.children?.defaultColumns;
 

  const [dataSource, setDataSource] = useState([{
    key: KoNano('Lit')
  }]);


  useEffect(()=>{
    if(fields?.children?.initialValues?.invoiceId){
      setDataSource(fields?.children?.initialValues['lineItems'][lineItemName]);
      form.setFieldsValue({ ...fields?.children?.initialValues });
      calculateInvoiceSummary(fields?.children?.initialValues);
    }
    
  },[]);

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };

  const handleFieldWidgets = (colFields) => {
    return colFields?.map(field => {
      let updatedField = { ...field };

      switch (field.widget) {

      case 'options':
        switch (field.dataIndex) {
        case 'tax':
          updatedField.options = customer?.tax;
          updatedField.widget = 'tax';
          break;
        case 'discount':
          updatedField.options = customer?.discount;
          updatedField.widget = 'discount';
          break;
        default:
          break;
        }
        break;

      default:
        break;
      }

      return updatedField;
    });
  };
  let col = handleFieldWidgets(colFields);
  col[0].fixed = 'left';
  const columns = [
    {
      dataIndex: 'amount',
      title: 'Amount',
      editable: true,
      widget: 'amount',
      placeholder: 'Amount',
      width: 130,
      readonly: true,
      align: 'right',
      fixed: 'right'
    },
    {
      title: ` `,
      dataIndex: 'operation',
      width: 40,
      fixed: 'right',
      render: (_, record, i) =>
        dataSource.length >= 1 ? (
          <div className={'editTableCol'}>
            <KoButton icon={<KoIcon width={12} height={12} name={'DeleteOutlined'} />} className={styles.actionBtn} onClick={() => handleDelete(record?.key)} label="" />
          </div>
        ) : null
    }
  ];

  col = [...col, ...columns];

  const handleAdd = () => {
    const newData = {
      key: KoNano('Lit')
    };
    setDataSource([...dataSource, newData]);
  };

  const getTaxRate = (taxIdentifiers) => {
    return taxIdentifiers.reduce((total, obj) => {
      return total + (obj?.percent ? obj?.percent : 0);
    }, 0);
  };

  const getDiscountRate = (discountIdentifiers) => {
    return discountIdentifiers.reduce((total, obj) => {
      return total + (obj ? obj.percent : 0);
    }, 0);
  };

  const calculateAmount = (item) => {
    let amount = item.quantity * item.rate;
    const discountIdentifiers = item.discount || [];
    const totalDiscountPercentage = getDiscountRate(discountIdentifiers);
    if (totalDiscountPercentage > 0) {
      amount -= (amount * totalDiscountPercentage) / 100;
    }
    const taxIdentifiers = item.tax || [];
    const totalTaxPercentage = getTaxRate(taxIdentifiers);
    if (totalTaxPercentage > 0) {
      amount += (amount * totalTaxPercentage) / 100;
    }

    return amount;
  };

  function roundToTwoDecimalPlaces(num) {
    return Math.round(num * 100) / 100;
  }

  function calculateInvoiceSummary(invoice) {
    for (let summaryId in invoice.summary) {
      let subtotal = 0;
      let totalDiscount = 0;
      let totalTax = 0;
      let shippingCharge = 0;
      let total = 0;
      if (invoice.lineItems[summaryId]) {
        invoice.lineItems[summaryId].forEach(item => {
          subtotal += item.amount;
        });
      }
      let discountedSubtotal = subtotal;
      if (invoice.summary[summaryId].discount) {
        invoice.summary[summaryId].discount.forEach(discount => {
          totalDiscount += discountedSubtotal * (discount.percent / 100);
        });
        discountedSubtotal -= totalDiscount;
      }
      if (invoice.summary[summaryId].tax) {
        invoice.summary[summaryId].tax.forEach(tax => {
          totalTax += discountedSubtotal * (tax.percent / 100);
        });
      }
      shippingCharge = invoice.summary[summaryId].shippingCharge ? Number(invoice.summary[summaryId].shippingCharge) : 0;
      total = discountedSubtotal + totalTax + shippingCharge;
      invoice.summary[summaryId] = {
        ...invoice.summary[summaryId],
        subtotal: roundToTwoDecimalPlaces(subtotal),
        totalDiscount: roundToTwoDecimalPlaces(totalDiscount),
        totalTax: roundToTwoDecimalPlaces(totalTax),
        shippingCharge: roundToTwoDecimalPlaces(shippingCharge),
        total: roundToTwoDecimalPlaces(total)
      };
    }

    return invoice.summary;
  }



  const handleSave = (newData) => {
    newData = newData.map(item => {
      let amount = calculateAmount(item);
      item['amount'] = amount;
      return item;
    });

    let data = form.getFieldsValue();

    if (!data['lineItems']) {
      data['lineItems'] = {};
    }
    data['lineItems'][lineItemName] = [...newData];
    calculateInvoiceSummary(data);
    form.setFieldsValue({ ...data });
    setDataSource([...newData]);
  };


  const updateTaxDiscount = (e, obj, fId, source) => {
    let data = form.getFieldsValue();
    if (!data['summary']) {
      data['summary'] = {};
    }
    if (!data['summary'][fId]) {
      data['summary'][fId] = {};
    }
    data['summary'][fId][source] = obj;
    
    calculateInvoiceSummary(data);
    form.setFieldsValue(data);
  };

  const updateShippingCharge = (val) => {
    let data = form.getFieldsValue();
    calculateInvoiceSummary(data, val);
    form.setFieldsValue(data);
  };



  return (
    <KoForm.Item name={['lineItems', lineItemName]}>
      <KoDataTableEdit
        dataSource={dataSource}
        form={form}
        rowKey={'key'}
        footer={() => <div className={styles.footer}>
          <div className={styles.addNewRow}>
            <KoButton type='primary' icon={<KoIcon color={`var(--palette-gray-7)`} width={17} height={17} name={'PlusOutlined'} />} className={styles.actionBtn} onClick={() => handleAdd()} label={`New Item`} />
            <div className={styles.tax}>
              <div className={styles.row2}>
                <label className={`${styles.bold}`}>Sub Total</label>
                <KoText className={`${styles.amountInfo} ${styles.bold} ${styles.totalText}`}
                  text={`${form.getFieldValue(['summary', lineItemName, 'subtotal']) ? form.getFieldValue(['summary', lineItemName, 'subtotal']) : '0.00'}`} />
              </div>
            </div>
          </div>
          <div className={styles.totalSummary}>

            <div className={styles.tax}>
              <div className={styles.row}>
                <KoText text='Tax' />
                <KoForm.Item name={['summary', lineItemName, 'tax']}>
                  <KoSelect
                    showSearch
                    mode="multiple"
                    placeholder='Select Tax'
                    onChange={(val, obj) => updateTaxDiscount(val, obj, lineItemName, 'tax')}
                    options={customer?.tax?.map((tax) => ({
                      label: `${tax?.taxName} (${tax?.taxRate ?? '0.00'}%)`,
                      value: tax?.key,
                      percent: tax?.taxRate
                    }))}
                  />
                </KoForm.Item>
                <KoText className={`${styles.amountInfo}`}
                  text={`+ ${form.getFieldValue(['summary', lineItemName, 'totalTax']) ?? '0.00'}`} />
              </div>
            </div>
            <div className={styles.tax}>
              <div className={styles.row}>
                <KoText text='Discount' />
                <KoForm.Item name={['summary', lineItemName, 'discount']}>
                  <KoSelect
                    showSearch
                    mode="multiple"
                    onChange={(val, obj) => updateTaxDiscount(val, obj, lineItemName, 'discount')}
                    placeholder='Select Discount'
                    options={customer?.discount?.map((discount) => ({
                      label: `${discount?.discountName} (${discount?.discountRate ?? '0.00'}%)`,
                      value: discount?.key,
                      percent: discount?.discountRate
                    }))}
                  />
                </KoForm.Item>
                <KoText className={`${styles.amountInfo} ${styles.discount}`}
                  text={`- ${form.getFieldValue(['summary', lineItemName, 'totalDiscount']) ?? '0.00'}`} />
              </div>
            </div>
            <div className={styles.tax}>
              <div className={styles.row}>
                <KoText text='Shipping Charge' />
                <KoForm.Item name={['summary', lineItemName, 'shippingCharge']}>
                  <KoNumber onChange={updateShippingCharge} placeholder='shipping amount' />
                </KoForm.Item>
                <KoText className={`${styles.amountInfo}`}
                  text={`+ ${form.getFieldValue(['summary', lineItemName, 'shippingCharge']) ?? '0.00'}`}
                />
              </div>
            </div>
            <div className={styles.addNewRow}>
              <div>&nbsp;</div>
              <div className={`${styles.tax} ${styles.totalAmount}`}>
                <div className={styles.row2}>
                  <label className={`${styles.bold} ${styles.total}`}>Total (INR)</label>
                  <KoText className={`${styles.amountInfo} ${styles.bold} ${styles.totalText}`}
                    text={`${form.getFieldValue(['summary', lineItemName, 'total']) ? form.getFieldValue(['summary', lineItemName, 'total']) : '0.00'}`} />
                </div>
              </div>
            </div>
          </div>
        </div>}
        onSave={handleSave}
        defaultColumns={col} />
    </KoForm.Item>
  );
};

export default LineItemEntryCrud;