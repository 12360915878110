import React, { useState } from 'react';
import { KoCheckBox, KoIcon, KoForm, KoButton, KoDataTableEdit, KoNano } from 'packages';
import styles from './curd.module.scss';

function TaxCrud({ form, handleFinish, settings }) {

  
  let ds=  settings?.tax?.length > 0 ? settings?.tax :  [{
    key: KoNano('TAX'),
    taxName: null,
    taxRate: null,
    isDefault: false
  }];
  const [dataSource, setDataSource] = useState(ds);
 
  const handleDelete = (key) => {
    let newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
    if(newData.length === 0){
      newData =[{
        key: KoNano('TAX'),
        taxName: null,
        taxRate: null,
        isDefault: false
      }];
    }
    handleSave(newData);
  };

  const columns = [
    {
      dataIndex: 'taxName',
      title: 'Tax Name',
      width: 200,
      editable: true,
      widget: 'text',
      placeholder: 'Enter tax name'
    },
    {
      dataIndex: 'taxRate',
      title: 'Tax Rate (%)',
      editable: true,
      widget:'number',
      placeholder: 'Enter tax rate',
      width: 200
    },
    {
      dataIndex: 'isDefault',
      title: 'Set as Default',
      key: "isDefault",
      width: 200,
      render: (value, record, rowIndex) => (
        <KoCheckBox
          onChange={handleCheckboxChangeFactory(rowIndex, "isDefault")}
          checked={value}
          label={'Set as default'}
        />
      )
    },
    {
      title: ` `,
      dataIndex: 'operation',
      width: 40,
      fixed:'right',
      render: (_, record, i) =>
        dataSource.length >= 1 ? (
          <div className={'editTableCol'}>
            <KoButton icon={<KoIcon width={12} height={12} name={'DeleteOutlined'} />} className={styles.actionBtn} onClick={() => handleDelete(record?.key)} label="" />
            {dataSource?.length === (i+1) && <KoButton icon={<KoIcon color={`var(--palette-gray-7)`} width={17} height={17} name={'PlusOutlined'} />} className={styles.actionBtn} onClick={() => handleAdd(record?.key)} label={``} />}
          </div>
        ) : null
    }
  ];

  const handleAdd = () => {
    const newData = {
      key: KoNano('TAX'),
      taxName: ``,
      taxRate: '',
      isDefault: false
    };
    setDataSource([...dataSource, newData]);
  };

  const handleCheckboxChangeFactory = (rowIndex, columnKey) => event => {
    const newCheckboxState = [...dataSource];
    newCheckboxState[rowIndex][columnKey] = event.target.checked;
    setDataSource(newCheckboxState);
    handleSave(newCheckboxState);
  };

  const handleSave = (newData) => {
    let data = form.getFieldsValue();
    data['tax'] = newData;
    form.setFieldsValue({...data});
    setDataSource(newData);
  };


  return (
    <div className={styles.wrapper}>
      <KoForm.Item name={'tax'}>
        <KoDataTableEdit
          dataSource={dataSource}
          form={form}
          onSave={handleSave}
          defaultColumns={columns} />
      </KoForm.Item>
    </div>
  );
};

export default TaxCrud;