import React from "react";
import { Tooltip } from 'antd';
import styles from './tooltips.module.scss';
function KoTooltips(props) {
  const color = props.color ? props.color : '#212121';
  const placement = props.placement ? props.placement : "top";
  const text = <span className={styles.tooltipsWrapper}>{props.title}</span>;

  return (
    <Tooltip placement={placement} title={text} color={color}>
      {props.children}
    </Tooltip>
  );
}
export default KoTooltips;