import { KoEditorReadOnly, KoGroups, KoTable, KoText } from 'packages';
import styles from './sprint.module.scss';
export default function Expandable({currentRecord, isOpenSprintGoal, isOpenLeavePlan}) {

  return (
    <div className={styles.expandableWrapper}>

      {isOpenSprintGoal === true && <>
        {
          currentRecord?.sprintGoal?.map((goal)=>
            <div key={goal?.key} className={`dataCard ${styles.sprintGoal}`}>
              <div className={styles.teamName}>
                <KoText className={styles?.team} text={<KoGroups data={goal?.team}/>}/>
              </div>
              <div className={styles.description}>
                {currentRecord?.sprintGoal?.map((val)=>
                  <div key={val?.key}>
                    <KoEditorReadOnly initialValue={val?.description} />
                  </div>
                )}
                
              </div>
            </div>
          )
        } </>}

      {isOpenLeavePlan && <>
        <div className={`dataCard ${styles.sprintGoal}`}>
          <KoTable data={{columns:[ {
            name: "employee",
            id: "employee",
            label: "Employee",
            widget: "assignees"
          }, {
            name: "noOfDays",
            id: "noOfDays",
            label: "No Of Days",
            widget: "text"
          },{
            name: "fromDate",
            id: "fromDate",
            label: "From Date",
            format : "DD-MMM-YYYY",
            widget: "date"
          }, {
            name: "endDate",
            id: "endDate",
            label: "End Date",
            format : "DD-MMM-YYYY",
            daysAgo: 0,
            widget: "date"
          }], rows:currentRecord?.leavePlan}}/>
        </div>
      </>}
    </div>
  );
}
