import React from "react";

import styles from './wos.module.scss';
import KoDP from "packages/dp";
import { KoText } from "packages/typography";

function Workspace(props) {
  let { data, row  } = props;
  return (
    <div className={styles.userWrapper} >
      <div className={`${styles.circle}  ${styles[data? data?.charAt(0).toLowerCase() :'a']}`}>
        {data?.['logo'] ?
          <KoDP logo={row?.['logo']} className={styles.profile} width={20} height={20} />
          :
          <KoText className={`${styles.letter}`} text={data?.charAt(0)}/>}
      </div>
      <KoText className={`${styles.text}`} text={data}/>
    </div>
  );
}
export default Workspace;

