import React from "react";
function Business(props) {
  const { width, height } = props;
  return (
    <svg fill="var(--palette-primary-darker)" width={width} height={height} version="1.1" id="Capa_1"
      viewBox="0 0 612 612" stroke="var(--palette-primary-darker)">
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <g>
          <g>
            <path d="M354.682,428.678c0,7.678-6.224,13.909-13.909,13.909h-69.545c-7.678,0-13.909-6.231-13.909-13.909v-48.863L0,321.356 v232.943c0,15.363,12.456,27.818,27.818,27.818h556.364c15.363,0,27.818-12.456,27.818-27.818V321.592L354.682,379.85V428.678z"></path> <path d="M584.182,164.844H27.818C12.456,164.844,0,177.299,0,192.662v93.045l257.318,58.453v-26.754 c0-7.678,6.231-13.909,13.909-13.909h69.545c7.685,0,13.909,6.231,13.909,13.909v26.789L612,285.936v-93.274 C612,177.299,599.544,164.844,584.182,164.844z"></path> <path d="M198.664,100.278c0-20.78,16.906-37.687,37.68-37.687H375.65c20.78,0,37.687,16.906,37.687,37.687v36.741h32.707v-36.741 c0-38.82-31.574-70.394-70.394-70.394H236.343c-38.813,0-70.394,31.574-70.394,70.394v36.741h32.714L198.664,100.278 L198.664,100.278z">
            </path>
          </g>
        </g>
      </g>
    </svg>

  );
}


export default Business;