import React from "react";
import { KoIcon, KoText, KoDivider, KoAvatar,
  KoCard, KoTitle, KoButton,
  KoImage} from "packages";
import PhoneInput from "antd-phone-input";
import styles from './settings.module.scss';

function Preview(props) {
  const {form, settings } = props;

 
  return (
    <KoCard hoverable={false} className={styles.tabs} >
      <div className={styles.saveAction}>
        <div className={styles.upload}>
          <div className={styles.picture}>
            {settings?.logo?.length > 0 ? <KoImage src={settings?.logo[0]?.url}/> :
              <KoIcon name="Avatar1Filled" width={45} height={45} />}
          </div>
          <div className={styles.actionText}>
            <KoTitle className={styles.uploadText} text={settings?.companyName} level={5} />
            <KoText className={styles.fileInfo} text={settings?.url} />
          </div>
          <KoButton onClick={() => form.submit()} label="Save" type={'primary'} />
        </div>
      </div>
         
       
      <div className={styles.previewWrapper}>
        <div className={styles.card}>
          <div className={styles.fieldList}>
            <div className={styles.viewMode}>
              <KoText className={styles.title} text={'GST Number'} />
              <KoText className={styles.titleValue} text={settings?.GST} />
            </div>
            <div className={styles.viewMode}>
              <KoText className={styles.title} text={'PAN Number'} />
              <KoText className={styles.titleValue} text={settings?.PAN} />
            </div>
            <div className={styles.viewMode}>
              <KoText className={styles.title} text={'UAN Number'} />
              <KoText className={styles.titleValue} text={settings?.UAN} />
            </div>
          </div>
        </div>
        <KoDivider orientation="left" orientationMargin="0">
          <KoText className={styles.headingText} text={'Business Primary Contact'} />
        </KoDivider>
        <div className={styles.card}>
          <div className={styles.profileName}>
            <div className={styles.circle}>
              <KoAvatar inLine={'0rem'} options={[{ name: settings?.firstName }]} size={30}/>
            </div>
            {settings?.firstName && <div className={styles.userInfo}>
              <KoText className={styles.nameText} text={`${settings?.firstName} ${settings?.lastName}`} />
              <KoText className={styles.designationText} text={settings?.designation} />
            </div>}
          </div>
          <div className={styles.contacts}>
            <div className={styles.circle}>
              <KoIcon name="PhoneOutlined" height={12} width={12} />
            </div>
            {settings?.mobile && <PhoneInput className='mobileNo' disableDropdown readOnly value={settings?.mobile}/>}
          </div>
          <div className={styles.contacts}>
            <div className={styles.circle}>
              <KoIcon name="EmailOutlined" height={14} width={14} />
            </div>
            <KoText className={styles.nameText} text={settings?.email}/>
          </div>
        </div>
        <KoDivider orientation="left" orientationMargin="0">
          <KoText className={styles.headingText} text={'Business Secondary Contact'} />
        </KoDivider>
        <div className={styles.card}>
          <div className={styles.profileName}>
            <KoAvatar inLine={'0rem'} options={[{ name: settings?.alternativeContact?.firstName, logo: settings?.alternativeContact?.profile?.logo }]} size={30}/>
            {settings?.alternativeContact?.firstName&& <div className={styles.userInfo}>
              <KoText className={styles.nameText} text={`${settings?.alternativeContact?.firstName} ${settings?.alternativeContact?.lastName}`} />
              <KoText className={styles.designationText} text={settings?.alternativeContact?.designation} />
            </div>}
          </div>
          <div className={styles.contacts}>
            <div className={styles.circle}>
              <KoIcon name="PhoneOutlined" height={12} width={12} />
            </div>
            {settings?.alternativeContact?.mobile && <PhoneInput className='mobileNo' disableDropdown readOnly value={settings?.alternativeContact?.mobile}/>}
          </div>
          <div className={styles.contacts}>
            <div className={styles.circle}>
              <KoIcon name="EmailOutlined" height={14} width={14} />
            </div>
            <KoText className={styles.nameText} text={settings?.alternativeContact?.email}/>
          </div>
        </div>
        <KoDivider orientation="left" orientationMargin="0">
          <KoText className={styles.headingText} text={'General Preferences'} />
        </KoDivider>
        <div className={styles.card}>
          <div className={styles.fieldList}>
            <div className={styles.viewMode}>
              <KoText className={styles.title} text={'Default Language'} />
              <KoText className={styles.titleValue} text={settings?.preferences?.defaultLanguage} />
            </div>
            <div className={styles.viewMode}>
              <KoText className={styles.title} text={'Primary Currency'} />
              <KoText className={styles.titleValue} text={settings?.preferences?.primaryCurrency} />
            </div>
            <div className={styles.viewMode}>
              <KoText className={styles.title} text={'Number Format'} />
              <KoText className={styles.titleValue} text={settings?.preferences?.numberFormat} />
            </div>
            <div className={styles.viewMode}>
              <KoText className={styles.title} text={'Date Format'} />
              <KoText className={styles.titleValue} text={settings?.preferences?.dateFormat} />
            </div>
            <div className={styles.viewMode}>
              <KoText className={styles.title} text={'Time Format'} />
              <KoText className={styles.titleValue} text={settings?.preferences?.timeFormat} />
            </div>
            <div className={styles.viewMode}>
              <KoText className={styles.title} text={'Financial Year Begins'} />
              <KoText className={styles.titleValue} text={settings?.preferences?.financialYearBegins} />
            </div>
            <div className={styles.viewMode}>
              <KoText className={styles.title} text={'Invoice No. Prefix'} />
              <KoText className={styles.titleValue} text={settings?.preferences?.invoiceNoPrefix} />
            </div>
          </div>
        </div>
        <KoDivider orientation="left" orientationMargin="0">
          <KoText className={styles.headingText} text={'Address'} />
        </KoDivider>
        <div className={styles.card}>
          <div className={styles.viewMode}>
            <KoText className={styles.titleValue}
              text={`${settings?.address?.addressLine1 ? settings?.address?.addressLine1+', ' :''}
          ${settings?.address?.addressLine2 ? settings?.address?.addressLine2+', ' :''}
          ${settings?.address?.city ? settings?.address?.city+', ' :''}
          ${settings?.address?.state ? settings?.address?.state+', ' :''}
          ${settings?.address?.country ? settings?.address?.country+', ' :''}
          ${settings?.address?.pincode ? settings?.address?.pincode :''}`} />
          </div>
        </div>
        <KoDivider orientation="left" orientationMargin="0">
          <KoText className={styles.headingText} text={'Bank Details'} />
        </KoDivider>
        <div className={styles.card}>
          <div className={styles.fieldList}>
            <div className={styles.viewMode}>
              <KoText className={styles.title} text={'Account Name'} />
              <KoText className={styles.titleValue} text={settings?.bank?.accountName} />
            </div>
            <div className={styles.viewMode}>
              <KoText className={styles.title} text={'Bank Name'} />
              <KoText className={styles.titleValue} text={settings?.bank?.bankName} />
            </div>
            <div className={styles.viewMode}>
              <KoText className={styles.title} text={'IFSC Code'} />
              <KoText className={styles.titleValue} text={settings?.bank?.ifscCode} />
            </div>
            <div className={styles.viewMode}>
              <KoText className={styles.title} text={'Swipe code'} />
              <KoText className={styles.titleValue} text={settings?.bank?.swipeCode} />
            </div>
            <div className={styles.viewMode}>
              <KoText className={styles.title} text={'Account No'} />
              <KoText className={styles.titleValue} text={settings?.bank?.accountNo} />
            </div>
          </div>
        </div>
        <KoDivider orientation="left" orientationMargin="0">
          <KoText className={styles.headingText} text={'Tax Details'} />
        </KoDivider>
        <div className={styles.card}>
          {settings?.tax?.map((tax, i)=>
            <div className={styles.fieldList} key={i}>
              <div className={styles.viewMode}>
                {i ===0 && <KoText className={styles.title} text={'Tax Name'} />}
                <KoText className={styles.titleValue} text={tax?.taxName} />
              </div>
              <div className={styles.viewMode}>
                {i ===0 && <KoText className={styles.title} text={'Tax Rate'} />}
                <KoText className={styles.titleValue} text={tax?.taxRate + '%'} />
              </div>
              <div className={styles.viewMode}>
                {i ===0 &&  <KoText className={styles.title} text={'Is Default'} />}
                <KoText className={styles.titleValue} text={tax?.isDefault ? 'Yes' : 'No'} />
              </div>
            </div>
          )}
        </div>
        <KoDivider orientation="left" orientationMargin="0">
          <KoText className={styles.headingText} text={'Discount Details'} />
        </KoDivider>

        <div className={styles.card}>
          {settings?.discount?.map((discount, i)=>
            <div className={styles.fieldList} key={i}>
              <div className={styles.viewMode}>
                {i ===0 &&  <KoText className={styles.title} text={'Discount Name'} />}
                <KoText className={styles.titleValue} text={discount?.discountName} />
              </div>
              <div className={styles.viewMode}>
                {i ===0 &&  <KoText className={styles.title} text={'Discount Rate'} />}
                <KoText className={styles.titleValue} text={discount?.discountRate + '%'} />
              </div>
              <div className={styles.viewMode}>
                {i ===0 &&   <KoText className={styles.title} text={'Is Default'} />}
                <KoText className={styles.titleValue} text={discount?.isDefault ? 'Yes' : 'No'} />
              </div>
            </div>
          )}
        </div>
      </div>
    </KoCard>
  );
}

export default Preview;