import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import {  KoStatistic, KoTable, KoIcon, KoText,KoForm,
  KoFlex, KoButton, KoNano, KoModal,
  KoLoader} from 'packages';
import { REQUEST_COLUMNS } from '../constant';
// import RequestCrud from './curd';
import RequestCrud from './reqCrud';
import KoToolBar from 'shared/components/toolbar';
import { ApiClientAPI } from '../apiClient.service';
import styles from './request.module.scss';
import { useDispatch } from 'react-redux';
import { add, remove } from 'shared/store/breadcrumbSlice';
import { isJsonString } from 'shared/utils';
import { debounce } from 'lodash';

function APIRequets() {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [searchedDataList, setSearchedDataList] = useState([]);
  const [executeStatus, setExecuteStatus] = useState();
  const [isOpen, setRequestModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({
    method: 'GET',
    requestBody: {},
    requestParams: []
  });
  const [form] = KoForm.useForm();
  // const location = useLocation();
  // const { pathname } = location;
  // const collectionId = pathname.split('/')[pathname.split('/').length-2];
  const { envronmentId, collectionId } = useParams();

  useEffect(() => {
    getAPISList(collectionId);
    dispatch(remove());
    dispatch(add([
      {
        title: 'Workspace',
        path: 'workspace'
      },
      {
        title: 'API Client'
        // path: 'apiclient'
      },
      {
        title: envronmentId,
        path: `apiclient/${envronmentId}`
      },
      {
        title: collectionId
      }
    ]));
  }, []);
  
  const getAPISList = (id) => {
    setLoading(true);
    ApiClientAPI.getRequests(id).then(
      (data) => {
        const requests = data?.requests?.map(request => Object.assign(request, {...request?.response}));
        setDataList(requests);
        setSearchedDataList(requests);
        setLoading(false);
        setExecuteStatus(data);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const resetRequestFormFields = () => {
    form.resetFields();
    setCurrentRecord({
      method: 'GET',
      requestBody: {},
      requestParams: []
    });
  };

  const expandcurrentRecord = (row) => {
    resetRequestFormFields();
    const resBoj = {
      requestId: row?.requestId,
      name: row?.name,
      method: row?.method,
      body: row?.requestbody ? row?.requestbody : {},
      requestParams: row?.requestParams,
      requestHeaders: row?.requestHeaders,
      requestURL: row?.requestURL,
      responseBody: row?.response?.body,
      responseHeaders: row?.response?.headers,
      statusCode: row?.response?.statusCode
    };
    setCurrentRecord(resBoj);
    setRequestModal(true);
  };


  const handleFinish = (values) => {
    setIsFormLoading(true);
    delete values?.responseHeaders;
    delete values?.responseBody;
    if (!currentRecord?.requestId) {
      values['requestId'] = KoNano('req');
    }
    const payload = {
      ...values,
      body: values?.body ? JSON?.parse(values?.body) : ''
    };
    ApiClientAPI.createOrUpdateRequest(collectionId, envronmentId, payload, currentRecord?.requestId).then(
      (data) => {
        setIsFormLoading(false);
        setCurrentRecord(prevRecord => ({
          ...prevRecord,
          requestHeaders: values?.requestHeaders,
          requestParams: values?.requestParams,
          body: isJsonString(values?.body) ? JSON.parse(values?.body) : values?.body,
          statusCode: data?.success?.statusCode ? data?.success?.statusCode : '',
          responseBody: data?.success?.responseBody ? data?.success?.responseBody : '',
          responseHeaders: data?.success?.headers ? data?.success?.headers : ''
        }));
        getAPISList(collectionId);
      },
      (err) => {
        setIsFormLoading(false);
      }
    );
  };

  const handleSearch = debounce((value) => {
    const filteredValues = dataList?.filter(obj => {
      const objName = obj?.name?.toLowerCase();
      return objName.startsWith(value.toLowerCase());
    });
    setSearchedDataList(filteredValues);
  }, 200);

  const columnsRight = [{
    title: '',
    dataIndex: 'x',
    key: 'x',
    fixed: 'right',
    width: 50,
    render: (text, row) => {
      return <div className={'columnsRight'} >
        <KoIcon name="OpenOutlined" onClick={() => expandcurrentRecord(row)} />
        <KoIcon name="SendRequestOutlined" onClick={() => expandcurrentRecord(row)} />
      </div>;
    }
  }];

  const closeRequestModal = () => {
    resetRequestFormFields();
    setRequestModal(false);
  };

  return (
    <div className={styles.wrapper}>
      {isLoading && <KoLoader />}
      {/* <KoAffix top={56}> */}
      {searchedDataList?.length > 0 && <div className={styles.apiWrapper}>
        <KoFlex wrap="wrap" gap="small" className={styles.statistic}>
          <KoStatistic title="Total Test Runs" value={executeStatus?.totalTestRun} />
          <KoStatistic title="Successful Test Runs" value={executeStatus?.successfullTestRun} />
          <KoStatistic title="Failed Test Runs" value={executeStatus?.failedTestRun} />
          <div>
            <KoButton type={"primary"} label="Execute all APIs" icon={<KoIcon color='#FFF' name="SendRequestOutlined" />} />
          </div>
        </KoFlex>
      </div>}
      <div className='tableCard'>
        { isLoading && <KoLoader />}
        <KoToolBar
          className={styles.toolbar}
          title={
            <div className={styles.inner}>
              <div className={styles.parent}>
                <div className={styles.parentTitle}>
                  <KoText className={styles.l2} level={5} text={'Clinet Address'} />
                </div>
              </div>
            </div>
          }
          search={{ visible: true, onChange: handleSearch, placeholder: 'Search by file name' }}
          buttonList={[{
            label: 'Create Request',
            type: 'primary',
            icon: <KoIcon color="#FFF" name="PlusOutlined" width={18} height={18} />,
            onClick: () => {
              resetRequestFormFields();
              setRequestModal(!isOpen);
            }
          }]} />
     
       
        <div className={styles.table}>
          <KoTable data={{ columns: REQUEST_COLUMNS, rows: searchedDataList }} extraColumnsRight={columnsRight} maxHeight={34} />
        </div>
      </div>
      <KoModal
        title={currentRecord?.requestId ? `Update Request ${currentRecord?.name?.toUpperCase()}` : 'Create Request'}
        centered
        open={isOpen}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setRequestModal(false); }}
        onClose={closeRequestModal}
        width={'70%'}
        // maskClosable={false}
        // height={650}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setRequestModal(!isOpen)} />,
          <KoButton key='create' type='primary' label={`Run & Save`} onClick={() => form.submit()} loading={isFormLoading} />
        ]}
      // footer={null}
      >
        {isFormLoading && <KoLoader circle={false} />}
        {/* <RequestCrud initialValues={currentRecord} form={form}  handleFinish={handleFinish}/> */}
        <RequestCrud initialValues={currentRecord} handleFinish={handleFinish} form={form} />
      </KoModal>

    </div>
    // </KoCard>
  );
}

export default APIRequets;