import React from "react";
import { Outlet } from 'react-router-dom';
import { KoButton, KoIcon, KoTitle } from 'packages';
import styles from './org.module.scss';
import Statistic from "./statistic";

function Organization() {

  return <div className={`${styles.wrapper}`}>
    <div className={styles.top}>
      <div className={styles.orgTitle}>
        <KoButton color="default" variant="filled" iconBtn={true} icon={<KoIcon name='ArrowLeftOutlined' />} onClick={() => window.history.back()}/>
        <KoTitle ellipsis={true} level={5} text='Organization'/>
      </div>
      <div className={styles.statistics}>
        <Statistic />
      </div>
    </div>
    <div className={styles.outletWrapper}>
      <Outlet/>
    </div>
  
  </div>;
}

export default Organization;
