const roleAccess = {
  1: ['Holiday Calendar', 'Customers', 'Change owner', 'Settings'],
  2: ['Holiday Calendar', 'Customers', 'Settings'],
  3: ['Holiday Calendar', 'Settings'],
  4: [],
  5: [],
  6: [],
  7: []
};


const menu =  [
  // {
  //   key: '/admin/payroll',
  //   icon:'PayslipOutlined',
  //   label: "Payroll"
  // },
  {
    key: '/admin/holiday',
    icon:'CalendarOutlined',
    label: "Holiday Calendar"
  },
  // {
  //   key: '/admin/announcements',
  //   icon:'AnnouncementsOutlined',
  //   label: "Announcements"
  // },
  {
    key: '/admin/customers',
    icon:'ClientsOutlined',
    label: "Customers"
  },
  // {
  //   key: '/admin/policy',
  //   icon:'DocumentsOutlined',
  //   label: "Policy Documents"
  // },
  {
    key:'/admin/changeowner',
    label:'Change owner',
    icon:'UsersOutlined'
  },
  {
    key: '/admin/settings',
    icon:'SettingsOutlined',
    label: "Settings"
  }
];
  
function getAdminMenuForRoles(user) {
  const accessibleMenus = new Set();
  user?.roles?.forEach(roleId => {
    const accessibleItems = roleAccess[roleId] || [];
    accessibleItems?.forEach(item => accessibleMenus.add(item));
  });
  return menu.filter(menuItem => accessibleMenus.has(menuItem.label));
}

  
export {
  getAdminMenuForRoles
};
  