import React, { useCallback } from 'react';
import { KoFormBuilder, KoForm } from 'packages';
// import styles from './curd.module.scss';

function FileRenameCrud({ form, handleFinish, initialValues }) {

  const handleSubmit = useCallback(values => {
    handleFinish(values);
  });

 

  
  const meta = {
    columns: 4,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      {
        key: 'name', label: 'Name', colSpan: 3,
        forwardRef: true, rules: [
          {
            required: true,
            message: `Please select Name.`
          }
        ]
      }
    ]
  };



  return (
    <div >
      <div >
        <KoForm form={form} layout="vertical" onFinish={handleSubmit} onSubmit={handleSubmit}>
          <KoFormBuilder form={form} meta={meta} />
        </KoForm>
      </div>
    </div>
  );
};

export default FileRenameCrud;