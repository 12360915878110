import React from "react";
function MoveTo(props) {
  const { width, height } = props;
  return (
    <svg fill="var(--palette-gray-7)" width={width} height={height} viewBox="0 0 10.46 10.483">
      <g data-name="Group 18302">
        <g data-name="Group 18301">
          <path data-name="Path 81628" d="M8.776 0H2.231a.4.4 0 0 0-.262.094l-1.86 1.6A.3.3 0 0 0 0 1.925V10.6a.348.348 0 0 0 .371.32h8.405a.348.348 0 0 0 .371-.32V.32A.348.348 0 0 0 8.776 0zm-.371 10.281H.742V2.057L2.384.64h6.021z" />
        </g>
      </g>
      <g data-name="Group 18304">
        <g data-name="Group 18303">
          <path data-name="Path 81629" d="M6.566 3.977 5.517 2.928a.371.371 0 1 0-.524.524l.416.416H2.843a.371.371 0 1 0 0 .742h2.566l-.416.416a.371.371 0 1 0 .524.524l1.049-1.049a.372.372 0 0 0 0-.524z" />
        </g>
      </g>
      <g data-name="Group 18306">
        <g data-name="Group 18305">
          <path data-name="Path 81630" d="M6.305 6.718H3.739l.416-.416a.371.371 0 1 0-.524-.524L2.582 6.827a.372.372 0 0 0 0 .525l1.049 1.049a.371.371 0 0 0 .524-.524l-.416-.416h2.566a.371.371 0 1 0 0-.742z" />
        </g>
      </g>
    </svg>

  );
}


export default MoveTo;