import React from "react";
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(utc);
dayjs.extend(customParseFormat);
const range = (start, end) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  return result;
};

function KoDateTime(props) {
 
  const disabledDate = (current) => {
    return current && current < dayjs().endOf('day');
  };

  const disabledDateTime = () => ({
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56]
  });
  const dateValue = props?.value && props?.value !== '0001-01-01T00:00:00Z' ? dayjs.utc(props?.value) : null;
  
  return (
    <DatePicker
      format={props?.format ? props?.format : "DD-MMM-YYYY"}
      disabledDate={disabledDate}
      disabledTime={disabledDateTime}
      showTime={false}
      value={dateValue}
    /> );
}
export default KoDateTime;
