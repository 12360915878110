import React, { useCallback, useEffect, useState } from 'react';
import { KoFormBuilder, KoSelect, KoForm, Checkbox } from 'packages';
import { OrgAPI } from '../org.service';

import styles from './roles.module.scss';


function RolesCrud({ form, handleFinish, initialValues }) {
  const [groups, setGroups] = useState();


  useEffect(() => {
    getGroups();
  }, []);


  const getGroups = () => {
    OrgAPI.getOrgGroups().then(
      (data) => {
        setGroups(data['groups']);
      },
      (err) => {
      }
    );
  };


  const handleSubmit = useCallback(values => {
    handleFinish(values);
  });

  const options = [
    { label: 'Creator', value: 'creator' },
    { label: 'Read', value: 'read' },
    { label: 'Write', value: 'write' },
    { label: 'Delete', value: 'delete' }];

  const Modules = ({ value, onChange }) =>

    <KoSelect value={value}
      showSearch
      onChange={v => { onChange(v); }}
      placeholder='Select Module'
      options={groups?.map((group) => ({
        label: group.name,
        value: group?.groupId
      }))}
    />;

  const Designations = ({ value, onChange }) =>

    <KoSelect value={value}
      showSearch
      onChange={v => { onChange(v); }}
      placeholder='Select designations'
      options={groups?.map((group) => ({
        label: group.name,
        value: group?.groupId
      }))}
    />;

  const meta = {
    columns: 4,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      {
        key: 'name', placeholder: 'Enter Role Name', label: 'Name', colSpan: 2, rules: [
          {
            required: true,
            pattern: new RegExp(/^[a-zA-Z]{1,}$/),
            message: `Enter new role name.`
          }
        ]
      },
      {
        key: 'designations', label: 'Designations', colSpan: 2,
        widget: Designations
      },
      {
        key: 'moduleAccess', label: 'Module', colSpan: 2,
        widget: Modules
      },
      {
        key: 'permissions',
        colSpan: 4,
        label: 'Permissions',
        widget: Checkbox.Group,
        options: options
      }

    ]
  };

  return (
    <div className={styles.wrapper}>
      <KoForm form={form} layout="vertical" onFinish={handleSubmit} onSubmit={handleSubmit} >
        <KoFormBuilder form={form} meta={meta} />
      </KoForm>
    </div>
  );
};

export default RolesCrud;