import React, { useCallback } from 'react';
import { KoFormBuilder, KoForm, Upload, KoToast, KoIcon } from 'packages';
import styles from './bank.module.scss';
import { getCookie } from 'shared/utils/cookies';
import Driver from 'packages/drivers';

function BankCrud({form, handleFinish, initialValues }) {

  const handleSubmit = useCallback(values => {
    const updatedValues = {
      bankDetails: { ...values.bankDetails, file: values.file }
    };
    handleFinish(updatedValues);
  });


  const props = {
    name: 'files',
    multiple: true,
    action: `${process.env.REACT_APP_PROXY_HOST}/api/v1/org/upload`,
    data: { module: 'taskplanner' },
    headers: {
      Authorization: `Bearer ${getCookie("accessToken")}`
    },

    onChange(info) {
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'done') {
        KoToast.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        KoToast.error(`${info.file.name} file upload failed.`);
      }
    }
  };
  const normFile = (e) => {
    return e?.file;
  };



  const uploadButton = (
    <div className={styles.dndArea}>
      <KoIcon name='AttachmentOutlined' />
      <div className={styles.dndText}>Add image, PDF or file</div>
    </div>

  );
  const onRemoveFile = (d) => {
    console.log();
  };

  const meta = {
    columns: 4,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      {
        key: 'bankDetails.accountHolderName', label: 'Account Name',  placeholder: 'Enter Acc Holder Name', colSpan: 2
      },
      {
        key: 'bankDetails.bankName', label: 'Bank Name', placeholder: 'Enter Bank Name', colSpan: 2
      }, {
        key: 'bankDetails.accountNumber'  , label: 'Account No',  placeholder: 'Enter Account No', colSpan: 2
      }, {
        key: 'bankDetails.branchName',label: 'Branch Name', placeholder: 'Enter Branch Name', colSpan: 2
      },{
        key: 'bankDetails.confirmAccountNo',label: 'Confirm Account No', placeholder: 'Enter Confirm Account No', colSpan: 2
      }, {
        key: 'bankDetails.ifscCode', label: 'IFSC Code', placeholder: 'Enter IFSC code', colSpan: 2
      }
    ]
  };
  return (
    <div className={styles.crudWrapper}>
      <KoForm form={form} layout="vertical" onFinish={handleSubmit} onSubmit={handleSubmit}>
        <div className={styles.attachments}>
          {
            initialValues?.bankDetails?.file?.type !== "" &&
          <div className={styles.attachmentsArea}>
            <Driver fileType={initialValues?.bankDetails?.file?.type} filePath={initialValues?.bankDetails?.file.url} handleDelete={() => onRemoveFile(initialValues?.bankDetails?.file)} />
          </div>
          }
          <KoForm.Item
            name={["file"]}
            valuePropName={["file"]}
            getValueFromEvent={normFile}>

            <Upload {...props}
              className={styles.card}
              onRemove={onRemoveFile}
              showUploadList={{showPreviewIcon : false}}
              showPreviewIcon={true}
              listType="picture-card"
              multiple={true}
            >
              {uploadButton}
            </Upload>
          </KoForm.Item>
        </div>
        <KoFormBuilder form={form} meta={meta} />
      </KoForm>
    </div>
  );
};

export default BankCrud;