

const COLUMNS_GROUPS = [
  {
    id: "name",
    label: "Name",
    widget: "text",
    fixed:true
  }, {
    id: "members",
    label: "Members",
    widget: "members"
  },  {
    id: "whoCanJoin",
    label: "Who can join the group",
    widget: "text"
  }, {
    id: "status",
    label: "Status",
    widget: "status"
  },{
    id: "createdBy",
    label: "Created By",
    widget: "profile"
  }, {
    id: "createdAt",
    label: "Created On",
    widget: "date"
  }
];
const ROWS = [{
  id:'1',
  roleName:'roleName',
  permissionName:'permissionName',
  roleDescription:' simply dummy text of the printing and ',
  permissionDescription:' simply dummy text of the printing and ',
  randaomId: 'Kamal K',
  createdBy: 'Kamal K',
  designation: "Hr",
  phone: {
    countryCode: 91,
    phoneNumber: "9944929666"
  },
  email: "kamal@qubernet.com",
  description:`Lorem Ipsum is simply dummy text of the printing and Typesetting industry.` +
      `Lorem Ipsum has been the industry’s Standard dummy text ever since the 1500s,`  +
      ` when an unknown Printer took a gallery of type and scrambled it to make a type Specimen book.`+
      `It has survived not only five centuries, but also The leap into electronic typesetting`,
  status: "Active",
  roles: 'Paradot',
  groups:'human resources',
  lastSignedIn:'2024-01-25T10:42:08.097Z',
  issueType:'bug',
  task:'Android Keyboard Document',
  comments:[{text:1},{text:2}],
  priority:2,
  members:[{name:'kamal', profilePic:'m1'},{name:'suku', profilePic:'m2'},{name:'john', profilePic:'m3'}]
}, {
  id:'2',
  randaomId: 'John',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  description:"Desc Text Desc TextDesc TextDesc Text Desc TextDesc TextDesc TextDesc TextDesc Text Desc TextDesc Text",
  status: "Inactive",
  roles: 'engineering',
  groups:'engineering',
  lastSignedIn:'2024-01-25T10:42:08.097Z',
  issueType:'task',
  comments:[{text:1}],
  priority:4,
  task:'Android Keyboard Document',
  members:[{name:'kamal', profilePic:'m1'},{name:'suku', profilePic:'m2'},{name:'john', profilePic:'m3'}]
},{
  id:'3',
  randaomId: 'Musk',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  description:"Desc Text Desc TextDesc TextDesc Text Desc TextDesc TextDesc TextDesc TextDesc Text Desc TextDesc Text",
  status: "On Hold",
  roles: 'Devops',
  groups:'Devops',
  lastSignedIn:'2024-01-25T10:42:08.097Z',
  issueType:'improvement',
  task:'Android Keyboard Document',
  priority:3,
  comments:[{text:1},{text:2}, {text:3}],
  members:[{name:'kamal', profilePic:'m1'},{name:'suku', profilePic:'m2'},{name:'john', profilePic:'m3'}]
},{
  id:'4',
  randaomId: 'Siva',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  description:"Desc Text Desc TextDesc TextDesc Text Desc TextDesc TextDesc TextDesc TextDesc Text Desc TextDesc Text",
  status: "In Review",
  roles: 'Finance',
  groups:'Finance',
  lastSignedIn:'2024-01-25T10:42:08.097Z',
  issueType:'improvement',
  priority:1,
  task:'Android Keyboard Document',
  comments:[],
  members:[{name:'kamal', profilePic:'m1'},{name:'suku', profilePic:'m2'},{name:'john', profilePic:'m3'}]
},{
  id:'5',
  randaomId: 'Rajesh',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  description:"Desc Text Desc TextDesc TextDesc Text Desc TextDesc TextDesc TextDesc TextDesc Text Desc TextDesc Text",
  status: "Blocked",
  roles: 'Paradot',
  groups:'Marketing',
  lastSignedIn:'2024-01-25T10:42:08.097Z',
  issueType:'improvement',
  priority:5,
  task:'Android Keyboard Document',
  comments:[],
  members:[]
},{
  id:'6',
  randaomId: 'Gokul',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  description:"Desc Text Desc TextDesc TextDesc Text Desc TextDesc TextDesc TextDesc TextDesc Text Desc TextDesc Text",
  status: "Duplicate",
  roles: 'Paradot',
  groups:'Pm team',
  lastSignedIn:'2024-01-25T10:42:08.097Z',
  issueType:'improvement',
  priority:4,
  task:'Android Keyboard Document',
  comments:[{text:1},{text:2}, {text:3}],
  members:[]
},
{
  id:'7',
  randaomId: 'Kamal K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  status: "Done",
  roles: 'Paradot',
  groups:'design',
  lastSignedIn:'2024-01-25T10:42:08.097Z',
  issueType:'newFeature',
  task:'Android Keyboard Document',
  priority:5,
  comments:[],
  members:[]
},{
  id:'8',
  randaomId: 'Kamal K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  status: "Completed",
  roles: 'Paradot',
  groups:'Development',
  lastSignedIn:'2024-01-25T10:42:08.097Z',
  issueType:'newFeature',
  priority:1,
  task:'Android Keyboard Document',
  comments:[{text:1}],
  members:[]
}, {
  id:'9',
  randaomId: 'Hema K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  status: "Reopen",
  roles: 'Paradot',
  groups:'Javascript',
  lastSignedIn:'2024-01-25T10:42:08.097Z',
  issueType:'query',
  priority:5,
  task:'Android Keyboard Document',
  members:[],
  comments:[{text:1},{text:2}, {text:3}]
},{
  id:'19',
  randaomId: 'Hema K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  status: "Reopen",
  roles: 'Paradot',
  groups:'Javascript',
  lastSignedIn:'2024-01-25T10:42:08.097Z',
  issueType:'query',
  priority:5,
  task:'Android Keyboard Document',
  members:[],
  comments:[{text:1},{text:2}, {text:3}]
},{
  id:'29',
  randaomId: 'Hema K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  status: "Reopen",
  roles: 'Paradot',
  groups:'Javascript',
  lastSignedIn:'2024-01-25T10:42:08.097Z',
  issueType:'query',
  priority:5,
  task:'Android Keyboard Document',
  members:[],
  comments:[{text:1},{text:2}, {text:3}]
},{
  id:'39',
  randaomId: 'Hema K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  status: "Reopen",
  roles: 'Paradot',
  groups:'Javascript',
  lastSignedIn:'2024-01-25T10:42:08.097Z',
  issueType:'query',
  priority:5,
  task:'Android Keyboard Document',
  members:[],
  comments:[{text:1},{text:2}, {text:3}]
},{
  id:'49',
  randaomId: 'Hema K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  status: "Reopen",
  roles: 'Paradot',
  groups:'Javascript',
  lastSignedIn:'2024-01-25T10:42:08.097Z',
  issueType:'query',
  priority:5,
  task:'Android Keyboard Document',
  members:[],
  comments:[{text:1},{text:2}, {text:3}]
},{
  id:'59',
  randaomId: 'Hema K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  status: "Open",
  roles: 'Paradot',
  groups:'Javascript',
  lastSignedIn:'2024-01-25T10:42:08.097Z',
  issueType:'query',
  priority:5,
  task:'Android Keyboard Document',
  members:[],
  comments:[{text:1},{text:2}, {text:3}]
},{
  id:'69',
  randaomId: 'Hema K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  status: "Todo",
  roles: 'Paradot',
  groups:'Javascript',
  lastSignedIn:'2024-01-25T10:42:08.097Z',
  issueType:'query',
  priority:5,
  task:'Android Keyboard Document',
  members:[],
  comments:[{text:1},{text:2}, {text:3}]
}];
  
export {
  COLUMNS_GROUPS, ROWS
};