import React from "react";
function Changerequest(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" width="24" height="24" rx="4" fill="var(--changerequest)" />
      <path d="M13.419 5.02601C13.2467 5.0835 13.0682 5.23405 12.9805 5.39828C12.9053 5.54062 12.899 5.58168 12.8896 6.02511C12.8771 6.56709 12.921 6.50687 12.5075 6.53698C12.1818 6.56162 11.524 6.67384 11.1294 6.77512C8.93378 7.33626 7.1015 8.72405 6.1556 10.5361C5.83926 11.1438 5.5605 11.9869 5.50725 12.5179C5.49159 12.6712 5.49786 12.7095 5.5605 12.8026C5.68892 12.9914 5.94262 13.0599 6.16813 12.9668C6.2339 12.9394 6.35919 12.8272 6.4876 12.6849C7.50554 11.5434 9.03087 10.7715 10.7535 10.5306C11.1544 10.4732 12.1974 10.4704 12.6015 10.5252L12.8677 10.5608L12.8834 11.0808C12.9022 11.6611 12.9303 11.7624 13.1371 11.9403C13.3594 12.1347 13.6413 12.1812 13.9702 12.0772C14.1393 12.0224 14.2458 11.9376 15.6647 10.7004C17.2245 9.34267 17.3372 9.23044 17.4375 8.93482C17.5001 8.75142 17.5032 8.38737 17.4437 8.21766C17.3278 7.88371 17.2996 7.85634 15.7085 6.46581C14.061 5.02875 14.0516 5.02328 13.6977 5.00138C13.6038 4.9959 13.4785 5.00685 13.419 5.02601Z" fill="white" />
      <path d="M18.8156 11.037C18.7655 11.0644 18.6214 11.1958 18.4992 11.3299C18.233 11.6228 17.6536 12.1183 17.331 12.3318C16.4352 12.923 15.4016 13.3062 14.2615 13.465C13.8606 13.5225 12.8176 13.5252 12.4135 13.4705L12.1473 13.4349L12.1285 12.9422C12.1066 12.3454 12.0659 12.2195 11.8435 12.0334C11.6117 11.8363 11.2985 11.8062 10.9696 11.9458C10.7911 12.0224 10.5969 12.1839 9.29397 13.3226C8.21653 14.2615 7.79056 14.653 7.71852 14.7624C7.46169 15.1621 7.44916 15.6411 7.68407 16.0517C7.73105 16.1338 8.23532 16.5937 9.27205 17.5052C10.9039 18.934 10.9477 18.9641 11.2923 18.997C11.6431 19.0271 11.95 18.8273 12.069 18.4906C12.1191 18.3483 12.1317 18.236 12.1317 17.9076C12.1317 17.4395 12.0909 17.4888 12.5075 17.4586C12.8207 17.4367 13.4503 17.3273 13.8481 17.2287C16.764 16.4979 18.969 14.3327 19.4608 11.7214C19.5234 11.3819 19.514 11.2615 19.4107 11.1411C19.2823 10.9905 18.9972 10.9412 18.8156 11.037Z" fill="white" />
    </svg>

  );
}

export default Changerequest;