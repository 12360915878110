import { KoBreadcrumb, KoButton, KoForm, KoLoader, KoModal, KoTicketTable, KoToast } from "packages";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import TaskCrud from "../../task/crud";
import { TaskPlannerAPI } from "../../taskPlanner.service";
import styles from './issue.module.scss';
import { useDispatch } from "react-redux";
import { getTicketBreadCrumb } from "shared/commonConstants";
import { useSelector } from "react-redux";
import { setSelectedChatData, setSelectedChatType } from "shared/store/chatSlice";
const Issue = ({profile, currentSprintRecord}) => {
  const userById = useSelector(state => state?.userById);
  const {workspaceId} = useParams();
  let userDetails = userById['data'];

  const [totalCount, setTotalCount] = useState(100);
  const [subTotalCount, setSubTotalCount] = useState(100);
  const [isLoading, setLoading] = useState(false);
  const [isSubLoading, setSubLoading] = useState(false);
  const [currentRecord, setCurrentRecord] = useState();
  const [ticketsList, setTicketsList] = useState();
  const [ subTickets ,setSubTickets] = useState();
  const [isOpen, setModal] = useState(false);
  const [openSubModel, setSubModel] = useState(false);
  const [breadCrumbs, setBreadCrumbs] = useState();
  const [form] = KoForm.useForm();
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({
    size: 10,
    page: 1
  });
  const [subPagination, setSubPagination] = useState({
    size: 10,
    page: 1
  });
  const [subCurrentRecord, setSubCurrentRecord] = useState({
    status:1,
    issueType:2,
    priority:3,
    reporter: userDetails?.userId
  });
  
  useEffect(()=>{
    getTicketsList();
  },[]);

  const getIssueById = (ticketId)=> {
    setLoading(true);
    TaskPlannerAPI.getIssueById(ticketId, workspaceId).then(
      (data) => {
        setLoading(false);
        const updatedRoomObj = { ...data?.issue, source: 'tickets' };
        dispatch(setSelectedChatData(updatedRoomObj));
        setCurrentRecord(data?.issue);
        dispatch(setSelectedChatType('tickets'));
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const getTicketsList=()=>{
    setLoading(true);
   
    TaskPlannerAPI.getTicketsBySprint(currentSprintRecord?.sprintId , pagination, workspaceId).then(
      (data) => {
        setTicketsList();
        setTotalCount();
        setLoading(false);
        setTicketsList(data?.tickets);
        setTotalCount(data?.totalCount);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const onPagination = (page, other) => {
    const query = {
      size: other,
      page: page
     
    };
    const p = Object.assign(pagination, query);
    setPagination(p);
    getTicketsList(p);
  };

  const handleFinish = (values => {
    setLoading(true);
    TaskPlannerAPI.createOrUpdateIssues(values, currentRecord?.ticketId,workspaceId).then(
      (data) => {
        setLoading(false);
        setModal(false);
        getTicketsList();
        form.resetFields();
        KoToast.success(`Ticket ${currentRecord?.ticketId ? 'updated':'created'} successfully`);
      },
      (err) => {
        setLoading(false);
      }
    );
  });

  const getSubIssueById = (ticketId)=> {
    setSubLoading(true);
    TaskPlannerAPI.getSubTaskIssuesWithQuery(subPagination, ticketId,  workspaceId).then(
      (data) => {
        setSubLoading(false);
        setSubTickets(data?.tickets);
        setSubTotalCount(data?.totalCount);
      },
      (err) => {
        setSubLoading(false);
      }
    );
  };

  const onChangeStatus=(key)=>{
    let q =  pagination;
    if(key){
      q['status'] = key.toString();
    }else{
      delete q?.['status'];
    }
   
    getTicketsList(q);
  };

  const onChangeType=(key)=>{
    let q =  pagination;
    if(key){
      q['issueType'] = key;
    }else{
      delete q['issueType'];
    }
    getTicketsList(q);
  };

  const onChangePriority =(key)=>{
    let q =  pagination;
    if(key){
      q['priority'] = key.toString();
    }else{
      delete q['priority'];
    }
   
    getTicketsList(q);
  };

  const onSearchText=(text)=>{
    let q = pagination;
    q['search'] = text;
    getTicketsList(q);
  };
  
  const onSort = (key, type) => {
    let q = pagination;
    q['sort'] = `${key}:${type}`;
    getTicketsList(q);
  };

  const onChangeAssignees =(key)=>{
    let q =  pagination;
    if(key){
      q['assignee'] = key.toString();
    }else{
      delete q?.['assignee'];
    }
   
    getTicketsList(q);
  };

  const onChangeSubStatus=(key)=>{
    let q =  subPagination;
    if(key){
      q['status'] = key.toString();
    }else{
      delete q?.['status'];
    }
   
    getSubIssueById(currentRecord.ticketId, q);
  };

  const onChangeSubType=(key)=>{
    let q =  subPagination;
    if(key){
      q['issueType'] = key.toString();
    }else{
      delete q['issueType'];
    }
    getSubIssueById(currentRecord.ticketId, q);
  };

  const onChangeSubPriority =(key)=>{
    let q =  subPagination;
    if(key){
      q['priority'] = key.toString();
    }else{
      delete q?.['priority'];
    }
   
    getSubIssueById(currentRecord.ticketId, q);
  };

  const onChangeSubAssignees =(key)=>{
    let q =  subPagination;
    if(key){
      q['assignee'] = key.toString();
    }else{
      delete q?.['assignee'];
    }
   
    getSubIssueById(currentRecord.ticketId, q);
  };
  
  const onSubSearchText=(text)=>{
    let q = subPagination;
    q['search'] = text;
    getSubIssueById(currentRecord.ticketId, q);
  };

  const onSubSort = (key, type) => {
    let q = subPagination;
    q['sort'] = `${key}:${type}`;
    getSubIssueById(currentRecord.ticketId, q);
  };
   
  const onSubPagination = (page, other) => {
    const query = {
      size: other,
      page: page
     
    };
    const p = Object.assign(subPagination, query);
    setSubPagination(p);
    getSubIssueById(currentRecord.ticketId, p);
  };


  const onExtraClick=(row)=>{
    setCurrentRecord(row);
    row['source'] = 'tickets';
    const updatedRoomObj = { ...row, source: 'tickets' };
    dispatch(setSelectedChatData(updatedRoomObj));
    dispatch(setSelectedChatType('tickets'));
    let crums = getTicketBreadCrumb(row);
    setBreadCrumbs(crums);
    getSubIssueById(row.ticketId);
    setSubModel(true);
    console.log(subCurrentRecord);
  };

  return (
    <div className={styles.wrapper}>
      { isLoading && <KoLoader />}
      <KoTicketTable
        setModal={setModal}
        onSearchText={onSearchText}
        profile={profile}
        onSort={onSort}
        title={currentRecord?.name}
        getIssueById={getIssueById}
        API={TaskPlannerAPI}
        form={form}
        workspaceId={workspaceId}
        maxHeight={100}
        RowSelectionOption={{
          visible: true
        }}
        onChangeStatus={onChangeStatus}
        onChangeType={onChangeType}
        onChangePriority={onChangePriority}
        onChangeAssignees={onChangeAssignees}
        isOpen={isOpen}
        Bulk={{
          visible: true
        }}
        setBreadCrumbs={setBreadCrumbs}
        tickets={ticketsList}
        setLoading={setLoading}
        onPagination={onPagination}
        onExtraClick={onExtraClick}
        getTicketsList={getTicketsList}
        setCurrentRecord={setCurrentRecord}
        pagination={{page: pagination.page, limit: pagination.limit}}
        totalCount={totalCount} />

      <KoModal
        title={ breadCrumbs ? <KoBreadcrumb items={breadCrumbs} /> : (currentRecord?.ticketId )
          ? `Update Issue ${(currentRecord?.ticketId).toUpperCase()}` : `Create Issue` }
        centered
        open={isOpen}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => {setCurrentRecord(); setModal(false);form.resetFields(); }}
        onClose={() => {setCurrentRecord(); setModal(false); form.resetFields();}}
        width={'95vw'}
        height={'95vh'}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => {setModal(!isOpen); form.resetFields();}} />,
          <KoButton key='create' type='primary' label={currentRecord?.ticketId ? `Update` : `Create`}
            onClick={() => form.submit()} />
        ]}
      >
        <TaskCrud form={form} getIssueById={getIssueById} handleFinish={handleFinish} initialValues={currentRecord} profile={profile}/>
      </KoModal>

      <KoModal
        title={`${currentRecord?.ticketId } Subtasks Lists`}
        centered
        open={openSubModel}
        afterClose={() => { setSubCurrentRecord(); setBreadCrumbs();}}
        handleOk={() => { setSubCurrentRecord(); setSubModel(false); }}
        onClose={() => { setSubCurrentRecord(); setSubModel(false); }}
      // maskClosable={false}
        width={`95vw`}
        footer={[]}>
        <>
          {isSubLoading && <KoLoader circle={false} />}
          <KoTicketTable
            setModal={setModal}
            onSearchText={onSubSearchText}
            profile={profile}
            API={TaskPlannerAPI}
            getIssueById={getIssueById}
            onSort={onSubSort}
            onChangeStatus={onChangeSubStatus}
            onChangeType={onChangeSubType}
            onChangePriority={onChangeSubPriority}
            onChangeAssignees={onChangeSubAssignees}
            setCurrentRecord={setSubCurrentRecord}
            isOpen={openSubModel}
            maxHeight={100}
            Bulk={{
              visible: false
            }}
            RowSelectionOption={{
              visible: false
            }}
            setBreadCrumbs={setBreadCrumbs}
            createBtn={false}
            tickets={subTickets}
            // columnsRight={columnsRight}
            onPagination={onSubPagination}
            pagination={{page: subPagination.page, limit: subPagination.limit}}
            totalCount={subTotalCount} />
        </>
      </KoModal>

    </div>
  );
};

export default Issue;
