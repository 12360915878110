import React from "react";
function Audio(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props}>
      <g data-name="ICON 14">
        <path d="M48.45 13.75a4.92 4.92 0 00-5 .08L16.72 30H8a5 5 0 00-5 5v30a5 5 0 005 5h8.72l26.69 16.17a4.89 4.89 0 005 .08A4.91 4.91 0 0051 81.9V18.1a4.91 4.91 0 00-2.55-4.35zM5 65V35a3 3 0 013-3h8v36H8a3 3 0 01-3-3zm44 16.9a3 3 0 01-4.55 2.56L18 68.44V31.56l26.45-16A3 3 0 0149 18.1zm9.91-45.98A3.86 3.86 0 0055.72 37a4 4 0 00-.54 4.9 14.94 14.94 0 010 16.12 4 4 0 00.54 4.9l.7-.71-.7.71a3.9 3.9 0 002.76 1.15h.43a4 4 0 002.93-1.82 22.87 22.87 0 000-24.51 4 4 0 00-2.93-1.82zm1.24 25.26a2 2 0 01-1.46.91 1.88 1.88 0 01-1.56-.55 1.91 1.91 0 01-.26-2.39 17 17 0 000-18.29 1.93 1.93 0 01.27-2.41 1.89 1.89 0 011.55-.54 2 2 0 011.46.91 20.85 20.85 0 010 22.36zm14.16-32.44a3.93 3.93 0 10-6.2 4.83 27.06 27.06 0 010 32.86 3.92 3.92 0 003.07 6.37h.25a4 4 0 002.88-1.54 34.95 34.95 0 000-42.52zM72.73 70a2 2 0 01-1.43.76 1.91 1.91 0 01-1.48-.56 2 2 0 01-.13-2.59 29 29 0 000-35.3 2 2 0 01.13-2.59 1.88 1.88 0 011.48-.56 2 2 0 011.43.76 33 33 0 010 40.08z" />
        <path d="M86.55 20.91a3.86 3.86 0 00-2.8-1.4A3.94 3.94 0 0080.53 26a37.87 37.87 0 010 48.08 3.92 3.92 0 00.24 5.29 3.93 3.93 0 002.8 1.16h.18a3.86 3.86 0 002.8-1.4 45.71 45.71 0 000-58.18zM85 77.82a1.83 1.83 0 01-1.35.67 2.1 2.1 0 01-1.48-.57 1.92 1.92 0 01-.11-2.59 39.88 39.88 0 000-50.64 1.94 1.94 0 011.59-3.17 1.84 1.84 0 011.35.67 43.73 43.73 0 010 55.64z" />
      </g>
    </svg>
  );
}
export default Audio;