import React from "react";
function Discount(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 24 24">
      <rect data-name="Rectangle 18810" width="24" height="24" rx="12" fill="var(--palette-primary-darker)" />
      <path data-name="Path 81776" d="M127.681 141.553a2.432 2.432 0 1 0 2.432-2.432 2.432 2.432 0 0 0-2.432 2.432zm1.482-.95a.324.324 0 1 1-.095.229.326.326 0 0 1 .095-.232zm1.9 1.9a.324.324 0 1 1 .095-.229.326.326 0 0 1-.095.226zm0-1.9a.324.324 0 0 1 0 .458l-1.433 1.433a.324.324 0 0 1-.458-.458l1.433-1.433a.324.324 0 0 1 .453.004z" transform="translate(-116.113 -127.79)" fill="#fff" />
      <path data-name="Path 81777" d="M16.005 6.763a3.083 3.083 0 0 1 3.079-3.079h.168V.324A.324.324 0 0 0 18.929 0h-5.52a.324.324 0 0 0-.324.324v8.6a.324.324 0 0 0 .324.324h3.857a3.077 3.077 0 0 1-1.261-2.485zM14.916 1.72h2.293a.324.324 0 1 1 0 .647h-2.293a.324.324 0 0 1 0-.647zm0 1.72h2.293a.324.324 0 1 1 0 .647h-2.293a.324.324 0 0 1 0-.647zm.573 2.367h-.573a.324.324 0 0 1 0-.647h.573a.324.324 0 1 1 0 .647z" transform="translate(-5.085 7)" fill="#fff" />
    </svg>
  );
}


export default Discount;