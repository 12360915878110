import { Avatar, Tooltip } from 'antd';
function KoAvatar(props) {
  const { options, isProfile, maxCount = 5, inLine, size = 'default', className, classNameGroup, onClick } = props;
  const LETTERS_COLORS = {
    a: '#2fa561',
    j: '#2fa561',
    s: '#2fa561',
    b: '#ff647c',
    k: '#ff647c',
    t: '#ff647c',
    c: '#6258c4',
    l: '#6258c4',
    u: '#6258c4',
    d: '#6979f8',
    m: '#6979f8',
    v: '#6979f8',
    e: '#be52f2',
    n: '#be52f2',
    w: '#be52f2',
    f: '#ffa030',
    o: '#ffa030',
    x: '#ffa030',
    g: '#11afa1',
    p: '#11afa1',
    y: '#11afa1',
    h: '#f52f62',
    q: '#f52f62',
    z: '#f52f62',
    i: '#1c90f5',
    r: '#1c90f5'
  };
  
  return (
    <Avatar.Group
    
      max={{ count: maxCount, style: {
        color: `var(--palette-gray-8)`,
        backgroundColor: `var(--palette-gray-6)`,
        fontSize:`1rem`
      }}}
      size={size}
      className={classNameGroup ? classNameGroup : ''}>
      {options?.map((items, i) => (
        <Tooltip key={i} title={isProfile? items.profile?.firstName : items?.name} placement="top">
          {(items?.logo && items?.logo !=='') ?<Avatar
            style={{
              fontSize: '1rem',
              display: 'grid',
              alignItems: 'center',
              marginInlineStart: inLine ? inLine : '-.6rem',
              justifyContent: 'center',
              borderRadius: '50%',
              border: '1px solid var(--border)'
            }}
            onClick={()=>onClick && onClick(items)}
            className={className?className:''}
            src={items?.logo[0]?.url ?? ''}
          />
         
            : <Avatar
              style={{
                backgroundColor: LETTERS_COLORS[isProfile? items.profile?.firstName?.charAt(0).toLowerCase() : items?.name?.charAt(0).toLowerCase()],
                fontSize: '1rem',
                display: 'grid',
                alignItems: 'center',
                marginInlineStart: inLine ? inLine : '-.6rem',
                justifyContent: 'center',
                borderRadius: '50%'
              }}
              onClick={()=>onClick && onClick(items)}
              className={className?className:''}>
              {isProfile? items.profile?.firstName?.charAt(0)?.toUpperCase() :items?.name?.charAt(0)?.toUpperCase()}
            </Avatar>
          }
        </Tooltip>
      ))}

    </Avatar.Group>
  );
}

export default KoAvatar;