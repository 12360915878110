import React, {useEffect, useState} from 'react';
import { KoFormBuilder, KoForm, Upload,  KoIcon, KoText, KoToast, KoLoader, KoImage } from 'packages';
import styles from './curd.module.scss';
import { getCookie } from 'shared/utils/cookies';
import ImgCrop from 'antd-img-crop';

function BusinessInfoCrud({ form, handleFinish, settings, onValuesChange }) {

  const [loading, setLoading] = useState(false);


  useEffect(()=>{
    form?.resetFields();
  },[]);


  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      KoToast.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      KoToast.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };


  const meta = {
    columns: 4,
    formItemLayout: null,
    initialValues: settings,
    colon: true,
    fields: [
      {
        key: 'companyName', label: 'Company Name' , placeholder: 'Ex: qubernet, inc.', colSpan: 2, rules: [
          {
            required: true,
            message: 'Company name is required'
          }
        ]
      },
      {
        key: 'url', label: 'Website Url', placeholder: 'Ex: https://qubernet.com', rules: [
          {
            required: true,
            message: 'Website url is required'
          },
          {
            pattern: new RegExp(/^https?:\/\/([\w\d]+\.)+\w{2,}(\/.+)?$/),
            message: 'Enter Valid url'
          }
        ], colSpan: 2
      },
      {
        key: 'GST', label: 'GST Number', placeholder: 'Enter GST', colSpan: 2
      },
      {
        key: 'PAN', label: 'PAN Number', placeholder: 'Enter PAN', colSpan: 2
      },
      {
        key: 'UAN', label: 'TAN Number', placeholder: 'Enter TAN', colSpan: 2
      }
    ]
  };


  const props = {
    name: 'files',
    action: `${process.env.REACT_APP_PROXY_HOST}/api/v1/org/upload`,
    data:{service:'admin', module:'customers'},
    headers: {
      Authorization: `Bearer ${getCookie("accessToken")}`
    },
    onChange(info) {
      setLoading(true);
      if (info.file.status !== 'uploading') {
        onValuesChange({
          logo: info.fileList
        }, 'refresh');
      }
      if (info.file.status === 'done') {
        KoToast.success(`${info.file.name} file uploaded successfully`);
        setLoading(false);
      } else if (info.file.status === 'error') {
        setLoading(false);
        KoToast.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  const uploadButton = (
    <div className={styles.dndArea}>
      {loading && <KoLoader size='small'/>}
      {settings?.logo?.length > 0   ?
        <KoImage preview={false} src={settings?.logo[0].url}/>:
        <>
          <KoIcon name='ImagesOutlined'/>
          <KoText className={styles.fileInfo} text='At least 256 x 256px PNG or JPG file'/>
        </>}
    </div>
  );


  return (
    <div className={styles.wrapper}>
      <KoForm.Item
        name={["logo"]}
        valuePropName={["logo"]}
        getValueFromEvent={normFile}

      >
        <ImgCrop>
          <Upload {...props}
            className={styles.upload}
            beforeUpload={beforeUpload}
            max={{ count: 1 }}
            showUploadList={false}
            showPreviewIcon={false}
            listType="picture-card"
          >
            {uploadButton}
          </Upload>
        </ImgCrop>
      </KoForm.Item>
      <KoFormBuilder form={form} meta={meta} />
    </div>
  );
};

export default BusinessInfoCrud;