"use client";
import { getCookie } from 'shared/utils/cookies';
import { KoForm, KoFormBuilder, KoIcon, KoLoader, KoNano, KoToast, Upload } from 'packages';
import { useCallback, useEffect, useState } from 'react';
import styles from './curd.module.scss';

function FolderCrud({ form, handleFinish, initialValues }) {
  const [isLoading, setLoading] = useState(false);
  const [lookupId, setLookupId] = useState();

  useEffect(()=>{
    const lp = KoNano('LOK');
    setLookupId(lp);
    let data = form.getFieldsValue();
    data['lookupId'] = lp;
    form.setFieldsValue({...data});
  },[]);

  const handleSubmit = useCallback(values => {
    handleFinish(values);
  });

  const optionGroups = [];
  for (let i = 10; i < 36; i++) {
    optionGroups.push({
      label: i.toString(36) + i,
      value: i.toString(36) + i
    });
  }




  const meta = {
    columns: 4,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
     
     
  
      {
        key: 'lookupId', label: 'Lookup Id', colSpan: 2, className: 'metaFieldHide'
      }
     
    ]
  };



  const props = {
    name: 'files',
    action: `${process.env.REACT_APP_PROXY_HOST}/api/v1/org/upload`,
    data:{service:'finance', module:'invoice',  lookupId: lookupId},
    headers: {
      Authorization: `Bearer ${getCookie("accessToken")}`
    },
    onChange(info) {
      setLoading(true);
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'done') {
        KoToast.success(`${info.file.name} file uploaded successfully`);
        setLoading(false);
      } else if (info.file.status === 'error') {
        setLoading(false);
        KoToast.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e[0];
    }
    return e?.fileList[0];
  };

  const uploadButton = (
    <div className={styles.dndArea}>
      {isLoading && <KoLoader size='small'/>}
      <KoIcon name='AttachmentOutlined'/>
      <div>Add image, video, or file</div>
    </div>
    
  );

  return (
    <div className={styles.wrapper}>
      <KoForm form={form} layout="vertical"
        onFinish={handleSubmit} onSubmit={handleSubmit}
        initialValues={initialValues}
      >
        <div className={styles.logoWrapper}>
          <KoForm.Item
            name={"invoiceFile"}
            valuePropName={"invoiceFile"}
            getValueFromEvent={normFile}
          >
            <Upload {...props}
              className={styles.upload}
                // beforeUpload={beforeUpload}
              max={{ count: 1 }}
              showUploadList={false}
              showPreviewIcon={false}
              listType="picture-card"
            >
              {uploadButton}
            </Upload>
          </KoForm.Item>
        </div>
        <KoFormBuilder form={form} meta={meta} />
      </KoForm>
      {/* <ReactEditor/> */}
    </div>
  );
};

export default FolderCrud;