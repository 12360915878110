import React from "react";
function Production(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width={width} height={height}>
      <path fill="#239F61"
        d="M7.633 26.018c-.496-.137-.514-.155-.651-.651-.12-.434-.844-.434-.964 0-.137.496-.155.514-.651.651a.5.5 0 0 0 0 .964c.496.137.514.155.651.651a.5.5 0 0 0 .964 0c.137-.496.155-.514.651-.651a.5.5 0 0 0 0-.964z"
      ></path>
      <circle cx="28.5" cy="20.5" r="1.5" fill="#239F61" ></circle>
      <circle cx="26.5" cy="23.5" r=".5" fill="#239F61" ></circle>
      <circle cx="4.5" cy="4.5" r=".5" fill="#239F61" ></circle>
      <path fill="#239F61"
        d="M24.988 8.573c1.694-1.601 3.01-3.51 3.012-6.07A.513.513 0 0 0 27.497 2c-2.56.001-4.469 1.318-6.07 3.012-.051.008-.96.446-.96.446a10.942 10.942 0 0 0-5.829-1.452C8.632 4.2 3.771 9.432 4.008 15.436a10.93 10.93 0 0 0 1.757 5.52c-1.916 1.006-3.262 1.278-3.64.912-.25-.245-.247-1.54 1.24-4.031a.505.505 0 0 0-.155-.687l-.845-.533a.507.507 0 0 0-.7.166C.519 18.691 0 20.5 0 21.5c0 .742.263 1.345.729 1.8.5.487 1.164.706 1.944.706 1.225 0 2.74-.546 4.364-1.438 2.344 2.464 5.805 3.856 9.571 3.317 4.59-.658 8.357-4.255 9.2-8.815.491-2.65.028-5.287-1.266-7.537 0 0 .438-.909.446-.96zm-9.75 15.424A8.96 8.96 0 0 1 8.791 21.5c1.88-1.251 3.819-2.857 5.565-4.56l2.978-2.873a.5.5 0 0 0 .012-.707l-.694-.72a.5.5 0 0 0-.707-.012l-2.982 2.877c-1.972 1.923-3.85 3.386-5.477 4.437a8.944 8.944 0 0 1-1.48-5.294c.12-3.118 2.008-5.91 4.713-7.464 2.584-1.484 5.166-1.448 7.455-.58l-.899.449a.5.5 0 1 0 .448.894l1.612-.806c.073.04.146.084.218.127-.214.281-.425.563-.633.841-.274.369-.546.733-.817 1.086a.5.5 0 0 0 .042.659l.29.289c-.771.314-1.436 1.152-1.436 2.356 0 .28.22.501.501.501 1.204 0 2.042-.665 2.356-1.435l.29.289a.499.499 0 0 0 .658.042c.353-.271.717-.543 1.086-.817.278-.208.56-.42.841-.633.043.072.086.145.127.218l-.806 1.612a.5.5 0 1 0 .894.448l.45-.899c.866 2.287.903 4.867-.577 7.448-1.573 2.743-4.422 4.642-7.583 4.724zM23.5 5.5c0-.551.449-1 1-1s1 .449 1 1-.449 1-1 1-1-.449-1-1z"
      ></path>
    </svg>

  );
}


export default Production;