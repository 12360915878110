import React from "react";
function Tree(props) {
  return (<svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <rect
      x={18}
      y={9}
      width={4}
      height={4}
      rx={2}
      transform="rotate(90 18 9)"
      stroke="#00A3BF"
      strokeWidth={2}
    />
    <rect
      x={18}
      y={17}
      width={4}
      height={4}
      rx={2}
      transform="rotate(90 18 17)"
      stroke="#00A3BF"
      strokeWidth={2}
    />
    <rect
      x={3}
      y={7}
      width={4}
      height={4}
      rx={2}
      transform="rotate(-90 3 7)"
      stroke="#00A3BF"
      strokeWidth={2}
    />
    <path
      d="M5 8v7c0 1.886 0 2.828.586 3.414C6.172 19 7.114 19 9 19h5"
      stroke="#00A3BF"
      strokeWidth={2}
    />
    <path
      d="M5 7v0c0 1.886 0 2.828.586 3.414C6.172 11 7.114 11 9 11h5"
      stroke="#00A3BF"
      strokeWidth={2}
    />
  </svg>);
}
export default Tree;