import React from "react";
function Points(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path d="M21.5 4H21V0H0V9H21V5H21.5C22.327 5 23 5.673 23 6.5V9.5C23 10.327 22.327 11 21.5 11H12.5C11.122 11 10 12.121 10 13.5V15.051C8.86 15.283 8 16.293 8 17.5V24H13V17.5C13 16.292 12.14 15.283 11 15.051V13.5C11 12.673 11.673 12 12.5 12H21.5C22.878 12 24 10.879 24 9.5V6.5C24 5.121 22.878 4 21.5 4ZM20 8H1V1H20V8ZM12 17.5V23H9V17.5C9 16.673 9.673 16 10.5 16C11.327 16 12 16.673 12 17.5Z" fill="black" />
    </svg>
  );
}


export default Points;