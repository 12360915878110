import React from "react";
function Clouddownload(props) {
  const { width, height } = props;
  return (
    <svg fill="var(--palette-gray-7)" width={width} height={height}>
      <defs>
        <clipPath id="clipPath">
          <path id="Rectangle_18218" data-name="Rectangle 18218" transform="translate(.163)" d="M0 0h14v14H0z" />
        </clipPath>

      </defs>
      <g id="cloud_download" transform="translate(-.163)">
        <g id="cloud-computing" transform="translate(.645 1.111)">
          <path id="Path_30364" data-name="Path 30364" d="M10.146 3.164a3.931 3.931 0 0 0-7.71 0 2.752 2.752 0 0 0 .317 5.485h1.965a.393.393 0 1 0 0-.786H2.753a1.966 1.966 0 1 1 .023-3.931.393.393 0 0 0 .391-.35 3.145 3.145 0 0 1 6.248 0 .417.417 0 0 0 .414.35 1.965 1.965 0 1 1 0 3.931H7.863a.393.393 0 0 0 0 .786h1.966a2.752 2.752 0 0 0 .317-5.483zm0 0" transform="translate(.001)" />
          <path id="Path_30365" data-name="Path 30365" d="m175.678 212.516-1 1v-4.947a.393.393 0 1 0-.786 0v4.947l-1-1a.393.393 0 0 0-.566.546l.01.01 1.667 1.668a.394.394 0 0 0 .127.085.389.389 0 0 0 .3 0 .393.393 0 0 0 .127-.085l1.667-1.668a.393.393 0 1 0-.546-.565zm0 0" transform="translate(-167.997 -203.064)" />
        </g>
      </g>
    </svg>
  );
}


export default Clouddownload;