import React from "react";
function Uploadvideo(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_130_143)">
        <path d="M24 4.793L20 8.793V6.5C20 5.122 18.878 4 17.5 4H2.5C1.122 4 0 5.122 0 6.5V20H20V15.207L24 19.207V4.793ZM19 19H1V6.5C1 5.673 1.673 5 2.5 5H17.5C18.327 5 19 5.673 19 6.5V19ZM23 16.793L20 13.793V10.207L23 7.207V16.793ZM10.5 11.5H14V12.5H10.5V16H9.5V12.5H6V11.5H9.5V8H10.5V11.5Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_130_143">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}


export default Uploadvideo;