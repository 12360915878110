import {KoButton, Checkbox, KoDate, KoInput, KoNumber, Radio, Rate, Select, KoTextArea, KoPassword} from '../index';

import isArray from 'lodash/isArray';
import isPlainObject from 'lodash/isPlainObject';
import React from 'react';
import FormBuilder from './formbuilder';

const mapOptions = (options) => {
  if (!isArray(options)) {
    throw new Error('Options should be an array in form builder meta.');
  }
  return options.map((opt) => {
    if (isArray(opt)) {
      return { value: opt[0], label: opt[1] };
    } else if (isPlainObject(opt)) {
      return opt;
    } else {
      return { value: opt, label: opt };
    }
  });
};

FormBuilder.defineWidget('checkbox', Checkbox, (field) => ({
  ...field,
  valuePropName: 'checked'
}));

FormBuilder.defineWidget('button', KoButton);
FormBuilder.defineWidget('input', KoInput);
FormBuilder.defineWidget('password', KoPassword);
FormBuilder.defineWidget('textarea', KoTextArea);
FormBuilder.defineWidget('number', KoNumber);
FormBuilder.defineWidget('datePicker', KoDate);
FormBuilder.defineWidget('radio', Radio);
FormBuilder.defineWidget('rating', Rate);

FormBuilder.defineWidget('radioGroup', Radio.Group, (field) => {
  const RadioComp = field.buttonGroup ? Radio.Button : Radio;
  if (field.options && !field.children) {
    return {
      ...field,
      widgetProps: {
        ...field.widgetProps,
        name: field.key
      },
      children: mapOptions(field.options).map((opt) => (
        <RadioComp value={opt.value} key={opt.value}>
          {opt.label}
        </RadioComp>
      ))
    };
  }
  return field;
});

FormBuilder.defineWidget('checkboxGroup', Checkbox.Group, (field) => {
  if (field.options && !field.children) {
    return {
      ...field,
      children: mapOptions(field.options).map((opt) => (
        <Checkbox value={opt.value} key={opt.value}>
          {opt.label}
        </Checkbox>
      ))
    };
  }
  return field;
});

FormBuilder.defineWidget('select', Select, (field) => {
  if (field.options && !field.children) {
    return {
      ...field,
      children: mapOptions(field.options).map((opt) => (
        <Select.Option
          label={opt.label}
          value={opt.value}
          key={opt.value}
          disabled={opt.disabled}
        >
          {opt.children || opt.label}
        </Select.Option>
      ))
    };
  }
  return field;
});
