import React from "react";
function WorkingTwoTone(props) {
  const { width, height } = props;
 
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"  width={width} height={height}>
      <path fill="#ebebeb" d="M0 382.4H500V382.65H0z" />
      <path fill="#ebebeb" d="M131.47 397.82H164.59V398.07H131.47z" />
      <path
        fill="#ebebeb"
        d="M250.15 395.11H258.84000000000003V395.36H250.15z"
      />
      <path fill="#ebebeb" d="M52.46 390.89H71.65V391.14H52.46z" />
      <path fill="#ebebeb" d="M389.7 389.21H415.78V389.46H389.7z" />
      <path fill="#ebebeb" d="M357.35 389.21H380.79V389.46H357.35z" />
      <path fill="#ebebeb" d="M356.23 401.21H449.91V401.46H356.23z" />
      <path
        d="M237 337.8H43.91a5.71 5.71 0 01-5.7-5.71V60.66a5.71 5.71 0 015.7-5.66H237a5.71 5.71 0 015.71 5.71v271.38a5.71 5.71 0 01-5.71 5.71zM43.91 55.2a5.46 5.46 0 00-5.45 5.46v271.43a5.46 5.46 0 005.45 5.46H237a5.47 5.47 0 005.46-5.46V60.66A5.47 5.47 0 00237 55.2zM453.31 337.8h-193.1a5.72 5.72 0 01-5.71-5.71V60.66a5.72 5.72 0 015.71-5.66h193.1a5.71 5.71 0 015.69 5.66v271.43a5.71 5.71 0 01-5.69 5.71zM260.21 55.2a5.47 5.47 0 00-5.46 5.46v271.43a5.47 5.47 0 005.46 5.46h193.1a5.47 5.47 0 005.46-5.46V60.66a5.47 5.47 0 00-5.46-5.46z"
        fill="#ebebeb"
      />
      <path
        transform="rotate(180 330.38 327.9)"
        fill="#f0f0f0"
        d="M318.61 276.38H342.16V379.42H318.61z"
      />
      <path
        transform="rotate(180 285.79 380.91)"
        fill="#f0f0f0"
        d="M233.94 379.42H337.63V382.40000000000003H233.94z"
      />
      <path fill="#f5f5f5" d="M229.42 276.38H318.6V379.42H229.42z" />
      <path
        fill="#f0f0f0"
        d="M238.68 282.16H309.34000000000003V325.6H238.68z"
      />
      <path
        fill="#f0f0f0"
        d="M238.68 330.2H309.34000000000003V373.64H238.68z"
      />
      <path
        d="M292.33 289.73H255.7A8.69 8.69 0 01247 281h54a8.69 8.69 0 01-8.67 8.73zM292.33 337.77H255.7a8.69 8.69 0 01-8.69-8.69h54a8.69 8.69 0 01-8.68 8.69z"
        fill="#f5f5f5"
      />
      <path
        transform="rotate(180 426.555 327.9)"
        fill="#f0f0f0"
        d="M414.78 276.38H438.33V379.42H414.78z"
      />
      <path
        transform="rotate(180 381.965 380.91)"
        fill="#f0f0f0"
        d="M330.12 379.42H433.81V382.40000000000003H330.12z"
      />
      <path fill="#f5f5f5" d="M325.6 276.38H414.78000000000003V379.42H325.6z" />
      <path fill="#f0f0f0" d="M334.86 282.16H405.52V325.6H334.86z" />
      <path fill="#f0f0f0" d="M334.86 330.2H405.52V373.64H334.86z" />
      <path
        d="M388.5 289.73h-36.63a8.69 8.69 0 01-8.69-8.69h54a8.69 8.69 0 01-8.68 8.69zM388.5 337.77h-36.63a8.69 8.69 0 01-8.69-8.69h54a8.69 8.69 0 01-8.68 8.69z"
        fill="#f5f5f5"
      />
      <path
        transform="rotate(180 131.59 114.63)"
        fill="#e6e6e6"
        d="M111.42 91.32H151.76V137.95H111.42z"
      />
      <path
        transform="rotate(180 128.75 114.63)"
        fill="#f5f5f5"
        d="M106.82 91.32H150.68V137.95H106.82z"
      />
      <path
        transform="rotate(90 128.75 114.63)"
        fill="#fff"
        d="M109.11 96.38H148.39V132.89H109.11z"
      />
      <path
        d="M138.16 124.75v-1.43a18.82 18.82 0 00-18.81-18.81v1.43a18.81 18.81 0 0018.81 18.81z"
        fill="#f5f5f5"
      />
      <path
        transform="rotate(180 362.485 155.005)"
        fill="#e6e6e6"
        d="M286.63 84.06H438.33V225.96H286.63z"
      />
      <path
        transform="rotate(180 359.035 155.005)"
        fill="#f0f0f0"
        d="M281.93 84.06H436.13V225.96H281.93z"
      />
      <path
        transform="rotate(180 362.485 228.48)"
        fill="#e6e6e6"
        d="M286.63 225.95H438.33V231.01H286.63z"
      />
      <path
        transform="rotate(180 351.2 228.48)"
        fill="#f0f0f0"
        d="M274.1 225.95H428.3V231.01H274.1z"
      />
      <path
        transform="rotate(90 359.035 155.005)"
        fill="#fafafa"
        d="M295.7 84.49H422.36V225.52999999999997H295.7z"
      />
      <path
        fill="#fff"
        d="M389.56 218.34L366.07 91.67 327.37 91.67 350.86 218.34 389.56 218.34z"
      />
      <path
        d="M426.51 175.36a.51.51 0 00.47-.54V96.63a.47.47 0 10-.94 0v78.19a.51.51 0 00.47.54z"
        fill="#f0f0f0"
      />
      <path
        fill="#fff"
        d="M342.54 218.34L319.06 91.67 303.97 91.67 327.45 218.34 342.54 218.34z"
      />
      <path
        transform="rotate(90 288.935 155.005)"
        fill="#e6e6e6"
        d="M225.6 154.59H352.26V155.43H225.6z"
      />
      <path
        fill="#e6e6e6"
        opacity={0.4}
        d="M280.38 101.42L432.07 101.42 432.68 94.64 280.98 94.64 280.38 101.42z"
      />
      <path
        fill="#e6e6e6"
        opacity={0.4}
        d="M280.38 109.23L432.07 109.23 432.68 102.44 280.98 102.44 280.38 109.23z"
      />
      <path
        fill="#e6e6e6"
        opacity={0.4}
        d="M280.38 117.03L432.07 117.03 432.68 110.25 280.98 110.25 280.38 117.03z"
      />
      <path
        fill="#e6e6e6"
        opacity={0.4}
        d="M280.38 124.84L432.07 124.84 432.68 118.05 280.98 118.05 280.38 124.84z"
      />
      <path
        fill="#e6e6e6"
        opacity={0.4}
        d="M280.38 132.64L432.07 132.64 432.68 125.86 280.98 125.86 280.38 132.64z"
      />
      <path
        fill="#e6e6e6"
        opacity={0.4}
        d="M280.38 140.45L432.07 140.45 432.68 133.66 280.98 133.66 280.38 140.45z"
      />
      <path
        fill="#e6e6e6"
        opacity={0.4}
        d="M280.38 148.25L432.07 148.25 432.68 141.47 280.98 141.47 280.38 148.25z"
      />
      <path
        fill="#e6e6e6"
        opacity={0.4}
        d="M280.38 156.06L432.07 156.06 432.68 149.27 280.98 149.27 280.38 156.06z"
      />
      <path
        fill="#e6e6e6"
        opacity={0.4}
        d="M280.38 163.86L432.07 163.86 432.68 157.08 280.98 157.08 280.38 163.86z"
      />
      <path
        transform="rotate(180 98.555 195.955)"
        fill="#e6e6e6"
        d="M82.82 177.9H114.28999999999999V214.01H82.82z"
      />
      <path
        transform="rotate(180 96.245 195.955)"
        fill="#f5f5f5"
        d="M79.26 177.9H113.23V214.01H79.26z"
      />
      <path
        transform="rotate(90 96.245 195.955)"
        fill="#fff"
        d="M81.03 181.81H111.45V210.09H81.03z"
      />
      <path
        d="M103.53 196.51v-1.11a7.28 7.28 0 00-7.29-7.28A7.28 7.28 0 0089 195.4v1.11a7.28 7.28 0 007.28 7.28 7.28 7.28 0 007.25-7.28z"
        fill="#f5f5f5"
      />
      <path
        transform="rotate(180 182.92 176.185)"
        fill="#e6e6e6"
        d="M161.32 151.3H204.53V201.08H161.32z"
      />
      <path
        transform="rotate(180 179.82 176.185)"
        fill="#f5f5f5"
        d="M156.41 151.3H203.23V201.08H156.41z"
      />
      <path
        transform="rotate(90 179.82 176.19)"
        fill="#fff"
        d="M158.86 156.7H200.79000000000002V195.67999999999998H158.86z"
      />
      <path
        d="M181.45 168.41l7.4 12.82a1.88 1.88 0 01-1.63 2.82h-14.8a1.88 1.88 0 01-1.63-2.82l7.4-12.82a1.89 1.89 0 013.26 0z"
        fill="#f5f5f5"
      />
      <path
        transform="rotate(180 101.895 344.955)"
        fill="#e6e6e6"
        d="M90.02 307.52H113.77V382.4H90.02z"
      />
      <path
        fill="#f0f0f0"
        d="M94.53 382.4L90.02 382.4 90.02 369.57 99.26 369.57 94.53 382.4z"
      />
      <path
        transform="rotate(180 190.645 344.955)"
        fill="#e6e6e6"
        d="M178.77 307.52H202.52V382.4H178.77z"
      />
      <path
        transform="rotate(180 136.365 341.02)"
        fill="#f0f0f0"
        d="M90.02 307.52H182.7V374.53H90.02z"
      />
      <path
        transform="rotate(-90 114.185 335.105)"
        fill="#e6e6e6"
        d="M90.8 314.44H137.57V355.77H90.8z"
      />
      <path
        transform="rotate(-90 158.545 335.105)"
        fill="#e6e6e6"
        d="M135.16 314.44H181.93V355.77H135.16z"
      />
      <path
        d="M154.46 315.31h8.17a12.93 12.93 0 009.39-4h-27a12.91 12.91 0 009.44 4zM109.68 315.31h8.18a12.94 12.94 0 009.39-4h-27a12.93 12.93 0 009.43 4z"
        fill="#f0f0f0"
      />
      <path
        fill="#f0f0f0"
        d="M178.2 382.4L182.71 382.4 182.71 369.57 173.47 369.57 178.2 382.4z"
      />
      <path
        d="M153.75 297.48a5.15 5.15 0 105.15-5.14 5.16 5.16 0 00-5.15 5.14z"
        fill="#e0e0e0"
      />
      <circle cx={161.23} cy={294.14} r={5.14} fill="#e0e0e0" />
      <path
        d="M128 298.31a5.14 5.14 0 105.14-5.14 5.14 5.14 0 00-5.14 5.14z"
        fill="#e0e0e0"
      />
      <circle cx={168.11} cy={294.98} r={6.31} fill="#e0e0e0" />
      <path
        d="M149.3 289.17a6.31 6.31 0 106.31-6.3 6.32 6.32 0 00-6.31 6.3z"
        fill="#e0e0e0"
      />
      <path
        d="M139.3 290.78a6.31 6.31 0 106.31-6.3 6.31 6.31 0 00-6.31 6.3z"
        fill="#e0e0e0"
      />
      <path d="M143.53 295.87a6 6 0 106-6 6 6 0 00-6 6z" fill="#e0e0e0" />
      <path
        d="M133.09 295.48a6.48 6.48 0 106.48-6.48 6.48 6.48 0 00-6.48 6.48z"
        fill="#e0e0e0"
      />
      <path
        d="M178.17 297.48s-.7 10-27.77 10-27.78-10-27.78-10z"
        fill="#f0f0f0"
      />
      <g>
        <ellipse cx={250} cy={416.24} rx={193.89} ry={11.32} fill="#f5f5f5" />
      </g>
      <g>
        <path
          d="M307.77 119.9a42.3 42.3 0 00-3.22-7.76l2-6.55-12.93-12.94-6.55 2a42.3 42.3 0 00-7.76-3.22l-3.22-6h-18.3l-3.21 6a42 42 0 00-7.76 3.22l-6.55-2-12.94 12.94 2 6.55a43 43 0 00-3.21 7.76l-6.05 3.22v18.3l6.05 3.21a42.67 42.67 0 003.21 7.76l-2 6.55 12.94 12.94 6.55-2a42.67 42.67 0 007.76 3.21l3.21 6h18.3l3.22-6a43 43 0 007.76-3.21l6.55 2 12.94-12.94-2-6.55a42 42 0 003.22-7.76l6.05-3.21v-18.3zm-40.83 38.36a26 26 0 1126-26 26 26 0 01-26 26z"
          fill="#00a76f"
        />
        <path
          d="M307.77 119.9a42.3 42.3 0 00-3.22-7.76l2-6.55-12.93-12.94-6.55 2a42.3 42.3 0 00-7.76-3.22l-3.22-6h-18.3l-3.21 6a42 42 0 00-7.76 3.22l-6.55-2-12.94 12.94 2 6.55a43 43 0 00-3.21 7.76l-6.05 3.22v18.3l6.05 3.21a42.67 42.67 0 003.21 7.76l-2 6.55 12.94 12.94 6.55-2a42.67 42.67 0 007.76 3.21l3.21 6h18.3l3.22-6a43 43 0 007.76-3.21l6.55 2 12.94-12.94-2-6.55a42 42 0 003.22-7.76l6.05-3.21v-18.3zm-40.83 38.36a26 26 0 1126-26 26 26 0 01-26 26z"
          fill="#fff"
          opacity={0.2}
        />
        <path
          d="M266.94 123.65a8.62 8.62 0 108.62 8.62 8.62 8.62 0 00-8.62-8.62zM438 148.93a61.16 61.16 0 00-4.59-11.1l2.86-9.36L417.8 110l-9.37 2.87a58.11 58.11 0 00-11.09-4.6l-4.59-8.64H366.6l-4.6 8.64a58.65 58.65 0 00-11.09 4.6l-9.36-2.87-18.49 18.49 2.86 9.36a59.76 59.76 0 00-4.59 11.1l-8.65 4.59v26.15l8.64 4.6a59.39 59.39 0 004.59 11.09l-2.86 9.35 18.49 18.49 9.36-2.86a58.61 58.61 0 0011.1 4.58l4.6 8.65h26.15l4.59-8.64a59.42 59.42 0 0011.09-4.6l9.37 2.86 18.49-18.49-2.86-9.36a60.77 60.77 0 004.57-11.09l8.64-4.6v-26.15zM379.67 214a47.41 47.41 0 1147.41-47.41A47.41 47.41 0 01379.67 214z"
          fill="#00a76f"
        />
        <path
          d="M379.67 125.83a40.77 40.77 0 1040.78 40.77 40.82 40.82 0 00-40.78-40.77zm0 76.11A35.34 35.34 0 11415 166.6a35.38 35.38 0 01-35.33 35.34z"
          fill="#00a76f"
        />
        <path
          d="M379.67 125.83a40.77 40.77 0 1040.78 40.77 40.82 40.82 0 00-40.78-40.77zm0 76.11A35.34 35.34 0 11415 166.6a35.38 35.38 0 01-35.33 35.34z"
          opacity={0.2}
        />
        <path
          d="M286 236.16a1 1 0 01-1-1v-2a1 1 0 112 0v2a1 1 0 01-1 1zM270.43 210.32a5.41 5.41 0 01-2.06-2.45 1 1 0 00-1.84.79 7.55 7.55 0 002.81 3.34 1 1 0 00.54.16 1 1 0 00.55-1.84zM266.94 197.83a1 1 0 001-1V193a1 1 0 00-2 0v3.83a1 1 0 001 1zM277.35 211.2h-3.84a1 1 0 000 2h3.84a1 1 0 000-2zM267.94 204.5v-3.83a1 1 0 00-2 0v3.83a1 1 0 002 0zM286.94 217.7a1 1 0 00-1.12-.86A1 1 0 00285 218a5.36 5.36 0 01.05.7v3a1 1 0 002 0v-3a7.73 7.73 0 00-.11-1zM286 224.49a1 1 0 00-1 1v3.84a1 1 0 102 0v-3.84a1 1 0 00-1-1zM285.13 213.73a7.41 7.41 0 00-3.71-2.29 1 1 0 00-.5 1.94 5.39 5.39 0 012.71 1.67 1 1 0 00.75.34 1 1 0 00.75-1.66zM266.94 190.12a1 1 0 01-1-1v-2a1 1 0 012 0v2a1 1 0 01-1 1z"
          fill="#00a76f"
        />
      </g>
      <g>
        <path
          fill="#00a76f"
          d="M101.83 286.51H333.37V290.21999999999997H101.83z"
        />
        <path
          transform="rotate(180 358.49 288.365)"
          fill="#00a76f"
          d="M333.37 286.51H383.61V290.21999999999997H333.37z"
        />
        <path
          transform="rotate(180 358.49 288.365)"
          opacity={0.2}
          d="M333.37 286.51H383.61V290.21999999999997H333.37z"
        />
        <path
          fill="#00a76f"
          d="M366.83 294.19L118.61 294.19 110.31 290.22 375.13 290.22 366.83 294.19z"
        />
        <path
          fill="#fff"
          opacity={0.6000000000000001}
          d="M366.83 294.19L118.61 294.19 110.31 290.22 375.13 290.22 366.83 294.19z"
        />
        <path
          fill="#00a76f"
          d="M126.11 294.19L126.11 412.53 129.29 412.53 133.29 294.19 126.11 294.19z"
        />
        <path
          fill="#00a76f"
          d="M163.65 294.19L163.65 412.53 166.83 412.53 170.83 294.19 163.65 294.19z"
        />
        <path
          fill="#00a76f"
          d="M366.83 294.19L366.83 412.53 363.66 412.53 359.65 294.19 366.83 294.19z"
        />
        <path
          fill="#00a76f"
          d="M329.3 294.19L329.3 412.53 326.12 412.53 322.12 294.19 329.3 294.19z"
        />
        <path
          fill="#00a76f"
          d="M253.74 241.59L243.29 284.16 243.29 284.16 243 286.51 315.08 286.51 315.38 284.16 325.82 241.59 253.74 241.59z"
        />
        <path
          opacity={0.30000000000000004}
          d="M253.74 241.59L243.29 284.16 243.29 284.16 243 286.51 315.08 286.51 315.38 284.16 325.82 241.59 253.74 241.59z"
        />
        <path
          fill="#00a76f"
          d="M323.61 241.59L251.52 241.59 241.08 284.16 313.16 284.16 323.61 241.59z"
        />
        <path
          fill="#fff"
          d="M311.63 282.21L321.13 243.54 253.05 243.54 243.56 282.21 311.63 282.21z"
        />
        <path
          fill="#00a76f"
          d="M294.38 284.16L294.08 286.51 214.71 286.51 215 284.16 294.38 284.16z"
        />
        <path
          fill="#fff"
          opacity={0.6000000000000001}
          d="M294.38 284.16L294.08 286.51 214.71 286.51 215 284.16 294.38 284.16z"
        />
        <path
          d="M144.44 278.77a6.35 6.35 0 00-5.63-6.1v-1.15a.5.5 0 00-.52-.49h-10.8a.51.51 0 00-.53.49v15h11.85v-1.64a6.34 6.34 0 005.63-6.11zm-5.63 4.17v-8.33a4.21 4.21 0 010 8.33z"
          fill="#00a76f"
        />
        <path
          fill="#263238"
          d="M346.56 286.51L356.6 286.51 353.63 264.52 346.56 264.52 346.56 286.51z"
        />
        <path
          fill="#00a76f"
          d="M331.84 286.51L350.86 286.51 354.39 260.77 335.38 260.77 331.84 286.51z"
        />
        <path
          opacity={0.30000000000000004}
          d="M331.84 286.51L350.86 286.51 354.39 260.77 335.38 260.77 331.84 286.51z"
        />
        <path
          fill="#00a76f"
          d="M329.3 286.51L349.09 286.51 352.63 260.77 332.84 260.77 329.3 286.51z"
        />
        <path
          fill="#fff"
          opacity={0.4}
          d="M347.34 284.48L350.32 262.8 334.59 262.8 331.61 284.48 347.34 284.48z"
        />
      </g>
      <g>
        <path
          d="M197.54 248.51l-.82.88-.88 1c-.59.68-1.18 1.37-1.74 2.08a75.14 75.14 0 00-3.21 4.36 46.61 46.61 0 00-5 9.24 28.59 28.59 0 00-1.42 4.78c-.16.92-.26 1.32-.31 1.71a2.68 2.68 0 000 .94 3.9 3.9 0 002 2.25 14 14 0 002.12 1.07q.58.24 1.2.45c.38.14.86.28 1.16.4v5.51c-.7.1-1.21.18-1.8.22s-1.16.05-1.75 0a17 17 0 01-3.6-.46 13.49 13.49 0 01-3.78-1.52 10.94 10.94 0 01-3.48-3.23 11.67 11.67 0 01-1.82-4.66 9.89 9.89 0 01-.13-1.18 10.94 10.94 0 010-1.14v-.56a3 3 0 010-.45l.08-.88a34.7 34.7 0 011.44-6.73 49.94 49.94 0 015.67-11.92 57.63 57.63 0 013.77-5.25c.67-.84 1.36-1.66 2.08-2.47a36.643 36.643 0 012.35-2.46z"
          fill="#b55b52"
        />
        <path
          d="M188.65 277.55l4.66-3.42 2.65 9.58s-4.13 1.53-7.28-.53z"
          fill="#b55b52"
        />
        <path
          fill="#b55b52"
          d="M199.78 271.83L201.6 279.85 195.96 283.71 193.31 274.13 199.78 271.83z"
        />
        <path
          d="M229.25 211.75c-.21.59-.06 1.18.33 1.31s.87-.23 1.07-.82.06-1.19-.32-1.32-.87.23-1.08.83z"
          fill="#263238"
        />
        <path
          d="M229.14 212.91a22.54 22.54 0 001.07 6.06 3.63 3.63 0 01-3-.46z"
          fill="#a02724"
        />
        <path
          d="M228.29 207.73a.36.36 0 00.23 0 2.92 2.92 0 012.63.4.37.37 0 00.48-.56 3.67 3.67 0 00-3.32-.55.37.37 0 000 .7z"
          fill="#263238"
        />
        <path
          d="M207.32 213.93c1.12 5.69 2.23 16.12-1.75 19.92 0 0 1.56 5.77 12.14 5.77 11.63 0 5.56-5.77 5.56-5.77-6.35-1.52-6.19-6.23-5.08-10.65z"
          fill="#b55b52"
        />
        <path
          d="M204.05 235.83c-.84-1.83-1.75-3.92.14-4.57 2.09-.72 13.53-1.46 17.47-.11a4.06 4.06 0 012.83 5.06z"
          fill="#263238"
        />
        <path
          d="M244 245.59c2.45 4.41 5.08 8.95 7.74 13.31s5.45 8.7 8.43 12.66c.74 1 1.51 1.95 2.27 2.9.38.49.78.89 1.17 1.34l.58.66.15.17.07.08.07.09a4.38 4.38 0 001.64 1 15.7 15.7 0 002.91.71 65.22 65.22 0 0014.61-.17l1.52 5.3c-1.35.6-2.61 1-3.95 1.5s-2.66.83-4 1.17a38.86 38.86 0 01-8.68 1.16 21.23 21.23 0 01-4.89-.49 13.49 13.49 0 01-5.44-2.42l-.67-.55-.16-.14-.1-.1-.19-.19-.78-.77c-.51-.51-1.07-1-1.53-1.54-1-1.05-1.94-2.1-2.81-3.18a129.93 129.93 0 01-9.65-13.29q-2.19-3.42-4.22-6.91c-1.36-2.35-2.67-4.65-3.94-7.13z"
          fill="#b55b52"
        />
        <path
          d="M191.64 235.61c-7.3 1.67-15.66 21.56-15.66 21.56l14.65 10a123.23 123.23 0 009.67-16.45c4.33-9.06-1.2-16.82-8.66-15.11z"
          fill="#00a76f"
        />
        <path
          d="M196.86 248.29l-6.92 18.44.68.48s3-4.21 6.24-9.89z"
          opacity={0.2}
        />
        <path
          d="M235.24 236s8.28 9.36.46 67.26h-41.63c-.28-6.39 3.73-37.55-2.43-67.66a108.85 108.85 0 0113.93-1.76 151.37 151.37 0 0117.7 0 77.87 77.87 0 0111.97 2.16z"
          fill="#00a76f"
        />
        <path
          d="M230.61 252.75a37 37 0 008.22 17.82c.17-3.73.24-7.08.23-10.07z"
          opacity={0.2}
        />
        <path
          d="M227 242.32c-1 7.37 13.5 26.19 13.5 26.19l14.59-10s-4.05-10.37-10.86-17.17c-7.76-7.8-16.09-7.04-17.23.98z"
          fill="#00a76f"
        />
        <path
          d="M282 278.39l5.94-2.44 6.11 4.62s-5.83 5.16-11.13 3.15z"
          fill="#b55b52"
        />
        <path
          fill="#b55b52"
          d="M291.86 275.71L296.34 278.91 294.02 280.57 287.91 275.95 291.86 275.71z"
        />
        <path
          d="M209.91 203.61c-2.08 7.44-3.68 11.69-1.3 16.7 3.57 7.55 13.72 7.34 18 .68 3.84-6 6.6-17.16.48-22.53a10.54 10.54 0 00-17.18 5.15z"
          fill="#b55b52"
        />
        <path
          d="M225.75 203.69c-3 8.54-6.53 17.92-15.15 16.14-5.56-1.15-6-10.1-8-14.17-1.77-3.57-1.66-10.75 2-10.84-1.64-2.38 6.65-11.09 12.31-6.49 1.45-2 11.9-7.89 19.64 1.46 7.29 8.84 3.72 16.21-10.8 13.9z"
          fill="#263238"
        />
        <path
          d="M219 209.15a8.8 8.8 0 001.22 5.47c1.33 2.14 3.28 1.32 4-.87.64-2 .71-5.45-1.14-6.84s-3.8-.06-4.08 2.24z"
          fill="#b55b52"
        />
        <path
          d="M282.88 406.88c1 0 2-.14 2.29-.59a.71.71 0 000-.81 1 1 0 00-.58-.43c-1.3-.35-4 1.2-4.13 1.27a.17.17 0 00-.09.19.19.19 0 00.14.15 13.6 13.6 0 002.37.22zm1.24-1.51a1.25 1.25 0 01.35 0 .52.52 0 01.36.26c.13.23.09.34 0 .4-.36.51-2.33.52-3.78.31a8.35 8.35 0 013.07-.97z"
          fill="#00a76f"
        />
        <path
          d="M280.53 406.66h.08c.94-.42 2.8-2.1 2.65-3 0-.21-.18-.46-.68-.52a1.36 1.36 0 00-1 .32c-1 .81-1.2 2.91-1.21 3a.19.19 0 00.07.17.23.23 0 00.09.03zm1.9-3.15h.11c.33 0 .35.17.36.21.09.53-1.18 1.85-2.14 2.42a4.3 4.3 0 011-2.39 1 1 0 01.67-.24z"
          fill="#00a76f"
        />
        <path
          fill="#b55b52"
          d="M271.48 406.48L279.26 406.48 281.03 388.45 273.25 388.45 271.48 406.48z"
        />
        <path
          d="M279.7 405.58h-8.49a.69.69 0 00-.67.54L269 413a1.13 1.13 0 001.12 1.39c3.06-.05 7.49-.23 11.34-.23 4.51 0 8.4.24 13.68.24 3.2 0 4.09-3.23 2.75-3.52-6.09-1.33-11.06-1.47-16.32-4.72a3.61 3.61 0 00-1.87-.58z"
          fill="#263238"
        />
        <path
          opacity={0.2}
          d="M281.02 388.46L280.12 397.75 272.33 397.75 273.24 388.46 281.02 388.46z"
        />
        <path
          d="M251.69 406.87a2.51 2.51 0 001.87-.51.74.74 0 000-.87.85.85 0 00-.58-.43c-1.14-.27-3.29 1.2-3.38 1.26a.21.21 0 00-.08.19.18.18 0 00.14.14 9.58 9.58 0 002.03.22zm1-1.48a.78.78 0 01.22 0 .46.46 0 01.34.24c.17.31.07.43 0 .48-.33.43-1.92.44-3.09.25a6 6 0 012.51-.97z"
          fill="#00a76f"
        />
        <path
          d="M249.71 406.66h.08c.81-.44 2.37-2.14 2.2-3a.6.6 0 00-.59-.49 1 1 0 00-.78.24c-.9.74-1.09 3-1.1 3.08a.2.2 0 00.09.17.17.17 0 00.1 0zm1.57-3.15h.08c.24 0 .26.14.27.19.1.51-.9 1.8-1.7 2.41a4.41 4.41 0 01.92-2.44.66.66 0 01.43-.16z"
          fill="#00a76f"
        />
        <path
          fill="#b55b52"
          d="M240.79 406.48L248.57 406.48 252.48 388.45 244.7 388.45 240.79 406.48z"
        />
        <path
          d="M249.12 405.58h-8.74a.68.68 0 00-.66.54l-1.54 6.88a1.15 1.15 0 001.14 1.39c3.05-.05 4.54-.23 8.38-.23 2.37 0 5.4.24 8.67.24s3.69-3.23 2.35-3.52c-6-1.31-6.41-3.12-8.33-4.85a1.9 1.9 0 00-1.27-.45z"
          fill="#263238"
        />
        <path
          opacity={0.2}
          d="M244.7 388.46L252.48 388.46 250.46 397.75 242.68 397.75 244.7 388.46z"
        />
        <path
          d="M235.7 303.27s58.3 6.17 57.66 35.91a164.52 164.52 0 01-10.62 54.62h-11.56s.59-18.87.2-49.32c-.12-9.13-18.55-12.14-36.56-13.2a41 41 0 01-35.24-25.07l-1.23-2.94z"
          fill="#263238"
        />
        <path
          d="M285.57 390.7c.06 0-.75 5.47-.75 5.47h-16l-.44-4.88z"
          fill="#00a76f"
        />
        <path
          d="M223.22 303.27s42.26 14 43.33 31.36c2 31.94-13.71 59.17-13.71 59.17h-11.67s4.43-13.5 6-42.44c2-36.35-65.83-.06-53.12-48.09z"
          fill="#263238"
        />
        <path
          d="M256.37 390.7c.06 0-1.47 5.47-1.47 5.47h-16l.29-4.88zM253.7 333.89h-65a7.42 7.42 0 017.42-7.42h50.16a7.42 7.42 0 017.42 7.42z"
          fill="#00a76f"
        />
        <path
          fill="#00a76f"
          d="M191.71 333.88L181.91 416.04 184.01 416.04 196.44 333.88 191.71 333.88z"
        />
        <path
          opacity={0.4}
          d="M191.71 333.88L181.91 416.04 184.01 416.04 196.44 333.88 191.71 333.88z"
        />
        <path
          fill="#00a76f"
          d="M204.82 333.88L204.63 416.04 206.72 416.04 209.55 333.88 204.82 333.88z"
        />
        <path
          opacity={0.4}
          d="M204.82 333.88L204.63 416.04 206.72 416.04 209.55 333.88 204.82 333.88z"
        />
        <path
          fill="#00a76f"
          d="M250.69 333.88L260.49 416.04 258.39 416.04 245.97 333.88 250.69 333.88z"
        />
        <path
          opacity={0.4}
          d="M250.69 333.88L260.49 416.04 258.39 416.04 245.97 333.88 250.69 333.88z"
        />
        <path
          fill="#00a76f"
          d="M237.58 333.88L237.77 416.04 235.68 416.04 232.85 333.88 237.58 333.88z"
        />
        <path
          opacity={0.4}
          d="M237.58 333.88L237.77 416.04 235.68 416.04 232.85 333.88 237.58 333.88z"
        />
      </g>
    </svg>
  );
}


export default WorkingTwoTone;