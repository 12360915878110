import KoMonaco from 'packages/monaco';
import React from 'react';

const ProgramsViewer = (props) => {
//   const [fileContent, setFileContent] = useState('');
//   const [language, setLanguage] = useState('plaintext'); // Default language
//   console.log('props', props);

//   const { file, filePath } = props;

//   useEffect(() => {
//     // Function to fetch file content
//     const fetchFileContent = async() => {
//       try {
//         let content = '';
//         if (typeof file === 'string') {
//           const response = await fetch(filePath);
//           content = await response.text();
//         } else if (filePath instanceof Blob) {
//           content = await filePath.text();
//         }

//         setFileContent(content);

//         // Set the language based on file extension or MIME type
//         if (filePath instanceof Blob) {
//           const fileName = filePath.name || '';
//           const fileExtension = fileName.split('.').pop().toLowerCase();
//           switch (fileExtension) {
//           case 'html':
//             setLanguage('html');
//             break;
//           case 'css':
//             setLanguage('css');
//             break;
//           case 'js':
//             setLanguage('javascript');
//             break;
//           default:
//             setLanguage('plaintext'); // Fallback to plaintext
//             break;
//           }
//         } else if (typeof file === 'string') {
//           const fileExtension = filePath.split('.').pop().toLowerCase();
//           switch (fileExtension) {
//           case 'html':
//             setLanguage('html');
//             break;
//           case 'css':
//             setLanguage('css');
//             break;
//           case 'js':
//             setLanguage('javascript');
//             break;
//           default:
//             setLanguage('plaintext');
//             break;
//           }
//         }
//       } catch (error) {
//         console.error('Error fetching file content:', error);
//       }
//     };

//     fetchFileContent();
//   }, [filePath]);

  return (
    <div>
      <KoMonaco  />
    </div>
  );
};

export default ProgramsViewer;
