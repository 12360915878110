import React from "react";
function RightArrow(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 46 46">
      <path data-name="Subtraction 83" d="M23 46a23.006 23.006 0 0 1-8.953-44.193 23.006 23.006 0 0 1 17.9 42.385A22.857 22.857 0 0 1 23 46zM9.661 21.713a1.124 1.124 0 1 0 0 2.248h23.8l-3.944 3.924a1.127 1.127 0 0 0 0 1.589 1.124 1.124 0 0 0 1.59 0l5.873-5.845a1.128 1.128 0 0 0 0-1.593L31.108 16.2a1.124 1.124 0 0 0-1.586 1.593l3.944 3.925z" fill="#fff" opacity=".931" />
    </svg>

  );
}


export default RightArrow;