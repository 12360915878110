import _find from 'lodash/find';
import { KoBreadcrumb, KoButton, KoForm, KoIcon, KoLoader, KoModal, KoTicketTable, KoToast, KoTooltips } from 'packages';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { add, remove } from 'shared/store/breadcrumbSlice';
import { getCookie } from 'shared/utils/cookies';
import TaskCrud from '../task/crud';
import { TaskPlannerAPI } from '../taskPlanner.service';
import styles from './curd.module.scss';
import { Link } from 'react-router-dom';
import { getTicketBreadCrumb } from 'shared/commonConstants';
import { setSelectedChatData, setSelectedChatType } from 'shared/store/chatSlice';

const BackLogs = (props) => {
  const {workspaceId} = useParams();
  const { profile} = props;
  const dispatch = useDispatch();
  const userById = useSelector(state => state?.userById);
  const [isLoading, setLoading] = useState(false);
  const [tickets, setTickets] = useState();
  const [isOpen, setModal] = useState(false);
  const [totalCount, setTotalCount] = useState(100);
  const [copy, setCopy] = useState(false);
  const [breadCrumbs, setBreadCrumbs] = useState();
  const [pagination, setPagination] = useState({
    size: 10,
    page: 1
  });
  let userDetails = userById['data'];
  const [currentRecord, setCurrentRecord] = useState({
    status:1,
    issueType:2,
    priority:3,
    reporter:userDetails?.userId
  });

  const [openSubModel, setSubModel] = useState(false);
  const [subCurrentRecord, setSubCurrentRecord] = useState({
    status:1,
    issueType:2,
    priority:3,
    reporter: userDetails?.userId
  });
  const [isSubLoading, setSubLoading] = useState(false);
  const [subPagination, setSubPagination] = useState({
    size: 10,
    page: 1
  });
  const [ subTickets ,setSubTickets] = useState();
  const [subTotalCount, setSubTotalCount] = useState(100);

  const [form] = KoForm.useForm();
  const workspace = useSelector(state => state?.workspace);
  let workspaceList = workspace['data'];
  const currentWorkspace = _find(workspaceList, function(o) { return o.workspaceId === workspaceId; });


  useEffect(()=>{
    dispatch(remove());
    dispatch(add([
      {
        title: 'workspace',
        path: 'workspace'
      },
      {
        title: currentWorkspace?.name
      },
      {
        title: 'BackLogs'
      }
    ]));

  }, [workspace, currentWorkspace]);

  useEffect(()=>{
    getTickets();
  },[]);

  const getTickets=()=>{
    setLoading(true);
    TaskPlannerAPI.getBacklog(pagination, workspaceId).then(
      (data) => {
        setTickets();
        setTotalCount();
        setLoading(false);
        setModal(false);
        setTickets(data?.tickets);
        setTotalCount(data?.totalCount);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const getIssueById = (ticketId)=> {
    setLoading(true);
    TaskPlannerAPI.getIssueById(ticketId, workspaceId).then(
      (data) => {
        setLoading(false);
        const updatedRoomObj = { ...data, source: 'tickets' };
        dispatch(setSelectedChatData(updatedRoomObj));
        dispatch(setSelectedChatType('tickets'));
        setCurrentRecord(data?.issue);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const getSubIssueById = (ticketId)=> {
    setSubLoading(true);
    TaskPlannerAPI.getSubTaskIssuesWithQuery(subPagination, ticketId,  workspaceId).then(
      (data) => {
        setSubLoading(false);
        setSubTotalCount(data?.totalCount);
        setSubTickets(data?.tickets);
      },
      (err) => {
        setSubLoading(false);
      }
    );
  };


  const handleFinish = (values => {
    setLoading(true);
    TaskPlannerAPI.createOrUpdateIssues(values, currentRecord?.ticketId, workspaceId).then(
      (data) => {
        setLoading(false);
        setModal(false);
        getTickets();
        form.resetFields();
        KoToast.success(`Ticket ${currentRecord?.ticketId ? 'updated':'created'} successfully`);
      },
      (err) => {
        setLoading(false);
      }
    );
  });

  const onChangeStatus=(key)=>{
    let q =  pagination;
    if(key){
      q['status'] = key.toString();
    }else{
      delete q?.['status'];
    }
   
    getTickets(q);
  };
  const onChangeType=(key)=>{
    let q =  pagination;
    if(key){
      q['type'] = key.toString();
    }else{
      delete q['type'];
      // delete q?.['type'];
    }
    getTickets(q);
  };
  const onChangePriority =(key)=>{
    let q =  pagination;
    if(key){
      q['priority'] = key.toString();
    }else{
      delete q?.['priority'];
    }
   
    getTickets(q);
  };
  const onChangeAssignees =(key)=>{
    let q =  pagination;
    if(key){
      q['assignee'] = key.toString();
    }else{
      delete q?.['assignee'];
    }
   
    getTickets(q);
  };

  const onPagination = (page, other) => {
    const query = {
      size: other,
      page: page
     
    };
    const p = Object.assign(pagination, query);
    setPagination(p);
    getTickets(p);
  };


  const onSearchText=(text)=>{
    let q = pagination;
    q['search'] = text;
    getTickets(q);
  };

  const onSort = (key, type) => {
    let q = pagination;
    q['sort'] = `${key}:${type}`;
    getTickets(q);
  };

  const onCopyTicket=(row)=>{
    setCopy(true);
    navigator.clipboard.writeText(`${window.location.origin}/open/ticket/${getCookie("accountId")}/${row?.workspace}/${row?.ticketId}`);
  };


  const onChangeSubStatus=(key)=>{
    let q =  subPagination;
    if(key){
      q['status'] = key.toString();
    }else{
      delete q?.['status'];
    }
   
    getSubIssueById(currentRecord.ticketId, q);
  };

  const onChangeSubType=(key)=>{
    let q =  subPagination;
    if(key){
      q['issueType'] = key.toString();
    }else{
      delete q['issueType'];
    }
    getSubIssueById(currentRecord.ticketId, q);
  };

  const onChangeSubPriority =(key)=>{
    let q =  subPagination;
    if(key){
      q['priority'] = key.toString();
    }else{
      delete q?.['priority'];
    }
   
    getSubIssueById(currentRecord.ticketId, q);
  };

  const onChangeSubAssignees =(key)=>{
    let q =  subPagination;
    if(key){
      q['assignee'] = key.toString();
    }else{
      delete q?.['assignee'];
    }
   
    getSubIssueById(currentRecord.ticketId, q);
  };
  
  const onSubSearchText=(text)=>{
    let q = subPagination;
    q['search'] = text;
    getSubIssueById(currentRecord.ticketId, q);
  };

  const onSubSort = (key, type) => {
    let q = subPagination;
    q['sort'] = `${key}:${type}`;
    getSubIssueById(currentRecord.ticketId, q);
  };
   
  const onSubPagination = (page, other) => {
    const query = {
      size: other,
      page: page
     
    };
    const p = Object.assign(subPagination, query);
    setSubPagination(p);
    getSubIssueById(currentRecord.ticketId, p);
  };


  const onExtraClick=(row)=>{
    setCurrentRecord(row);
    const updatedRoomObj = { ...row, source: 'tickets' };
    dispatch(setSelectedChatData(updatedRoomObj));
    dispatch(setSelectedChatType('tickets'));
    let crums = getTicketBreadCrumb(row);
    setBreadCrumbs(crums);
    getSubIssueById(row.ticketId);
    setSubModel(true);
    console.log(subCurrentRecord);
  };


  const columnsRight = [{
	  title: '',
	  dataIndex: 'x',
	  key: 'x',
	  fixed: 'right',
	  width: 40,
	  render: (data, row) => {
	    return <div className={`${styles.circle} tableBtn columnsRight`} onClick={() => setCurrentRecord(row)}>
        <KoTooltips title={copy ? 'Copied' : 'Copy'}>
	      <KoButton onClick={()=>onCopyTicket(row)} className={'btn'} iconBtn={true} icon={<KoIcon width={14} height={14} name={'CopyOutlined'}/>}/>
        </KoTooltips>
        
        <KoTooltips title={'New window'}>
          <Link target="_blank" to={`/open/ticket/${getCookie("accountId")}/${row?.workspace}/${row?.ticketId}`}>
            <KoButton className={'btn'}  iconBtn={true} icon={<KoIcon width={14} height={14} name={'OpenOutlined'}/>}/>
          </Link>
        </KoTooltips>
	    </div>;
	  }
  }];

  return (
    <div className='tableCard'>
      { isLoading && <KoLoader />}

      <KoTicketTable
        setModal={setModal}
        onSearchText={onSearchText}
        profile={profile}
        onSort={onSort}
        title={currentRecord?.name}
        API={TaskPlannerAPI}
        form={form}
        getIssueById={getIssueById}
        workspaceId={workspaceId}
        // maxHeight={10}
        RowSelectionOption={{
          visible: true
        }}
        onChangeStatus={onChangeStatus}
        onChangeType={onChangeType}
        onChangePriority={onChangePriority}
        onChangeAssignees={onChangeAssignees}
        isOpen={isOpen}
        Bulk={{
          visible: true
        }}
        setBreadCrumbs={setBreadCrumbs}
        tickets={tickets}
        setLoading={setLoading}
        columnsRight={columnsRight}
        onPagination={onPagination}
        onExtraClick={onExtraClick}
        getTicketsList={getTickets}
        setCurrentRecord={setCurrentRecord}
        pagination={{page: pagination.page, limit: pagination.limit}}
        totalCount={totalCount} />


      <KoModal
        title={`${currentRecord?.ticketId } Subtasks Lists`}
        centered
        open={openSubModel}
        afterClose={() => { setSubCurrentRecord(); setBreadCrumbs();}}
        handleOk={() => { setSubCurrentRecord(); setSubModel(false); }}
        onClose={() => { setSubCurrentRecord(); setSubModel(false); }}
      // maskClosable={false}
        width={`95vw`}
        footer={[]}>
        <>
          {isSubLoading && <KoLoader circle={false} />}
          <KoTicketTable
            setModal={setModal}
            onSearchText={onSubSearchText}
            profile={profile}
            API={TaskPlannerAPI}
            getIssueById={getIssueById}
            onSort={onSubSort}
            onChangeStatus={onChangeSubStatus}
            onChangeType={onChangeSubType}
            onChangePriority={onChangeSubPriority}
            onChangeAssignees={onChangeSubAssignees}
            setCurrentRecord={setSubCurrentRecord}
            isOpen={openSubModel}
            maxHeight={100}
            Bulk={{
              visible: false
            }}
            RowSelectionOption={{
              visible: false
            }}
            setBreadCrumbs={setBreadCrumbs}
            createBtn={false}
            tickets={subTickets}
            columnsRight={columnsRight}
            onPagination={onSubPagination}
            pagination={{page: subPagination.page, limit: subPagination.limit}}
            totalCount={subTotalCount} />
        </>
      </KoModal>


      <KoModal
        title={breadCrumbs ? <KoBreadcrumb items={breadCrumbs} /> : currentRecord?.ticketId ? `Update Issue ${currentRecord?.ticketId?.toUpperCase()}` : `Create Issue`}
        centered
        open={isOpen}
        afterClose={() => {setCurrentRecord(); setBreadCrumbs();}}
        handleOk={() => {setCurrentRecord(); setModal(false);}}
        onClose={() => {setCurrentRecord(); setModal(false); form.resetFields();}}
        width={'95vw'}
        height={'95vh'}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => {setModal(!isOpen); form.resetFields();}} />,
          <KoButton key='create' loading={isLoading} type='primary' label={currentRecord?.ticketId ? `Update` : `Create`}
            onClick={() => form.submit()} />
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <TaskCrud form={form} handleFinish={handleFinish} initialValues={currentRecord} profile={profile}/>
      </KoModal>
    </div>
  );
};

export default BackLogs;
