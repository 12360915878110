import React, { useState } from 'react';
import styles from './attachments.module.scss';
import KoButton from '../../../button';
import KoModal from 'packages/modal';
import KoPreview from 'packages/preview';
import KoTooltips from 'packages/tooltips';
import Driver from 'packages/drivers';
import { Button } from 'antd';
export default function Attachments(props) {
  const [isOpen, setModal] = useState(false);
  const [current, setCurrent] = useState(false);
  let {data} = props;


  const removePrefix = (type, prefix) => {
    return type.startsWith(prefix) ? type.substring(prefix.length) : type;
  };

  const setCurrentRecord = (record) => {
    setCurrent((record));
    setModal(true);
  };


  return (<>
    <div className={`${styles.wrapper} tableBtn`}>
      {data?.map((att, index)=>
        <KoTooltips title={att.name} key={att.id} >
          <Button className={styles.btnWrapper} icon={ <Driver
            fileType={removePrefix(att.type, 'image/')}
            filePath={att?.url}/> }
          onClick={()=>setCurrentRecord(index)}>
          </Button>
        </KoTooltips>
      )}
    </div>

    <KoModal
      title={`Preview Attachment`}
      centered
      open={isOpen}
      handleOk={() => { setModal(false); }}
      onClose={() => { setModal(false); }}
      width={'70%'}
      // maskClosable={false}
      height={'70%'}
      footer={[
        <KoButton key='cancel' label='Close' onClick={() => setModal(!isOpen)} />
      ]}
    >
      <KoPreview data={data} active={current}/>
    </KoModal>
  </>
  );
}
