import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import moment from "moment";
import styles from "./tab.module.scss";

const generateWeeks = (startDate, endDate) => {
  const weeks = [];
  let currentWeekStart = startDate.clone().startOf("week");

  while (currentWeekStart.isBefore(endDate)) {
    const weekEnd = currentWeekStart.clone().endOf("week");
    weeks.push({
      start: currentWeekStart.format("YYYY-MM-DD"),
      end: weekEnd.format("YYYY-MM-DD"),
      label: `${currentWeekStart.format("MMM D")} - ${weekEnd.format("MMM D, YYYY")}`
    });
    currentWeekStart.add(1, "week");
  }

  return weeks;
};

const WeeklyTabs = ({ getTimesheet, currentWeek }) => {
  const today = moment();

  const [visibleWeeks ] = useState(
    generateWeeks(today.clone().subtract(3, "weeks"), today.clone().endOf("month"))
  );

  const [activeWeek, setActiveWeek] = useState(() => {
    const currentWeekIndex = visibleWeeks.findIndex(
      (week) =>
        today.isSameOrAfter(moment(week.start)) && today.isSameOrBefore(moment(week.end))
    );
    return currentWeekIndex !== -1 ? currentWeekIndex : 0;
  });

  useEffect(() => {
    if (currentWeek) {
      const weekIndex = visibleWeeks.findIndex(
        (week) =>
          moment(currentWeek).isSameOrAfter(moment(week.start)) &&
          moment(currentWeek).isSameOrBefore(moment(week.end))
      );
      setActiveWeek(weekIndex !== -1 ? weekIndex : 0);
    }
  }, [currentWeek, visibleWeeks]);

  const moveToPreviousWeek = () => {
    if (activeWeek > 0) {
      const newActiveWeek = activeWeek - 1;
      setActiveWeek(newActiveWeek);
      const selectedWeek = visibleWeeks[newActiveWeek];
      getTimesheet && getTimesheet(selectedWeek.start, selectedWeek.end);
    }
  };

  const moveToNextWeek = () => {
    if (activeWeek < visibleWeeks.length - 1) {
      const newActiveWeek = activeWeek + 1;
      setActiveWeek(newActiveWeek);
      const selectedWeek = visibleWeeks[newActiveWeek];
      getTimesheet && getTimesheet(selectedWeek.start, selectedWeek.end);
    }
  };

  const jumpToCurrentWeek = () => {
    const currentWeekIndex = visibleWeeks.findIndex(
      (week) =>
        today.isSameOrAfter(moment(week.start)) && today.isSameOrBefore(moment(week.end))
    );
    setActiveWeek(currentWeekIndex !== -1 ? currentWeekIndex : 0);
    const selectedWeek = visibleWeeks[currentWeekIndex];
    getTimesheet && getTimesheet(selectedWeek.start, selectedWeek.end);
  };

  const currentWeekData = visibleWeeks[activeWeek];

  return (
    <div className={styles.wrapper}>
      <div className={styles.buttonWrapper}>
        <Button
          icon={<LeftOutlined />}
          onClick={moveToPreviousWeek}
          disabled={activeWeek === 0}
        />
        <Button onClick={jumpToCurrentWeek}>{currentWeekData?.label}</Button>
        <Button
          icon={<RightOutlined />}
          onClick={moveToNextWeek}
          disabled={moment(currentWeekData?.start).isAfter(today)}
        />
      </div>
    </div>
  );
};

export default WeeklyTabs;
