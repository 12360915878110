const ORG_STATISTICS = [{
  color: '--palette-gray-10',
  backgroud:'--active',
  title: 'Active Projects',
  icon: 'AssigneeTwoTone',
  value: 10
}, {
  color: '--palette-gray-10',
  backgroud:'--active',
  title: 'Tasks Assigned',
  icon: 'AssigneeTwoTone',
  value: 120
}, {
  color: '--palette-gray-10',
  backgroud:'--active',
  title: 'Issues Raised',
  icon: 'AssigneeTwoTone',
  value: 103
}, {
  color: '--palette-gray-10',
  backgroud:'--active',
  title: 'Today Meeting',
  icon: 'AssigneeTwoTone',
  value: 110
}, {
  color: '--palette-gray-10',
  backgroud:'--active',
  title: 'Status / Progress',
  icon: 'AssigneeTwoTone',
  value: 10
}, {
  color: '--palette-gray-10',
  backgroud:'--active',
  title: 'In Review',
  icon: 'AssigneeTwoTone',
  value: 120
}];


const ITEM_STATISTICS = [{
  color: '--palette-gray-10',
  title: 'Days Remaining',
  icon: 'DaysReminingTwoTone',
  backgroud:'--active',
  value: 10
}, {
  color: '--palette-gray-10',
  title: 'Tasks Assigned',
  icon: 'AssignedTaskTwoTone',
  value: 120,
  backgroud:'--active'
}, {
  color: '--palette-gray-10',
  title: 'Issues Raised',
  icon: 'IssueRisedTwoTone',
  backgroud:'--active',
  value: 103
}, {
  color: '--palette-gray-10',
  title: 'Team assignee',
  icon: 'AssigneeTwoTone',
  value: 110,
  backgroud:'--active'
}, {
  color: '--palette-gray-10',
  title: 'Today Meeting',
  icon: 'MeetingsTwoTone',
  value: 10,
  backgroud:'--active'
}, {
  color: '--palette-gray-10',
  title: 'Wishlist',
  icon: 'WishListTwoTone',
  value: 120,
  backgroud:'--active'
}];

const PROJECT_LIST = [{
  name: 'qubernet, inc.',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint:2,
  id: 'Pro_121212',
  tags: ['Development', 'design', 'IT', 'Finance', 'Devops', 'Demo']
}, {
  name: 'Kwapio, inc.',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint:5,
  id: 'Pro_1212122',
  tags: ['Development', 'Demo']
}, {
  name: 'tonelix',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  id: 'Pro_1212123',
  sprint:7,
  tags: ['Development', 'Demo']
},{
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint:2,
  id: 'Pro_121212',
  tags: ['Development', 'design', 'IT', 'Finance', 'Devops', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint:5,
  id: 'Pro_1212122',
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  id: 'Pro_1212123',
  sprint:7,
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint:5,
  id: 'Pro_12121234',
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint:6,
  id: 'Pro_12121235',
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint:4,
  id: 'Pro_12121236',
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  sprint:9,
  progress: 80,
  id: 'Pro_121212',
  tags: ['Development', 'design', 'IT', 'Finance', 'Devops', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint:10,
  id: 'Pro_1212122',
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  sprint:7,
  progress: 80,
  id: 'Pro_1212123',
  tags: ['Development', 'Demo']
}];

const PRO_ITEMS_COLUMNS = [
  {
    name: "ticketId",
    id: "ticketId",
    label: "Ticket Id",
    width:130,
    widget: "ticketLink",
    fixed: true
  },
  {
    name: "summary",
    id: "summary",
    label: "Summary",
    width:300,
    widget: "text"
  },
  {
    name: "workspace",
    id: "workspace",
    label: "Workspace",
    width:150,
    widget: 'workspace'
  },
  {
    name: "sprint",
    id: "sprint",
    label: "Sprint",
    width:150,
    widget: "text"
  },
  {
    name: "priority",
    id: "priority",
    label: "Priority",
    widget: "priority",
    width:130
  },
  {
    name: "assignee",
    id: "assignee",
    label: "Assignee",
    widget: "assignees"
  },
  {
    name: "reporter",
    id: "reporter",
    label: "Reporter",
    widget: "assignees"
  },
  {
    name: "status",
    id: "status",
    label: "Status",
    widget: "taskstatus",
    isLog:true,
    width:150
  }];

const PRO_ITEMS_ROWS = [{
  id: '1',
  randaomId: 'Kamal K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  description: `Lorem Ipsum is simply dummy text of the printing and Typesetting industry.` +
    `Lorem Ipsum has been the industry’s Standard dummy text ever since the 1500s,` +
    ` when an unknown Printer took a gallery of type and scrambled it to make a type Specimen book.` +
    `It has survived not only five centuries, but also The leap into electronic typesetting`,
  status: "Active",
  roles: 'Paradot',
  groups: 'human resources',
  lastSignedIn: '4557890',
  storyPoints: 8,
  reporter: [{ name: 'Kamal' }],
  issueType: 'bug',
  task: 'Android Keyboard Document',
  comments: [{ text: 1 }, { text: 2 }],
  priority: 2,
  assignee: [{ name: 'kamal', profilePic: 'm1' }, { name: 'suku', profilePic: 'm2' }, { name: 'john', profilePic: 'm3' }]
}, {
  id: '2',
  randaomId: 'John',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  description: "Desc Text Desc TextDesc TextDesc Text Desc TextDesc TextDesc TextDesc TextDesc Text Desc TextDesc Text",
  status: "Inactive",
  storyPoints: 8,
  reporter: [{ name: 'Kamal' }],
  roles: 'engineering',
  groups: 'engineering',
  lastSignedIn: '4557890',
  issueType: 'task',
  comments: [{ text: 1 }],
  priority: 4,
  task: 'Android Keyboard Document',
  assignee: [{ name: 'kamal', profilePic: 'm1' }, { name: 'suku', profilePic: 'm2' }, { name: 'john', profilePic: 'm3' }]
}, {
  id: '3',
  randaomId: 'Musk',
  designation: "Hr",
  storyPoints: 8,
  reporter: [{ name: 'Kamal' }],
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  description: "Desc Text Desc TextDesc TextDesc Text Desc TextDesc TextDesc TextDesc TextDesc Text Desc TextDesc Text",
  status: "On Hold",
  roles: 'Devops',
  groups: 'Devops',
  lastSignedIn: '4557890',
  issueType: 'improvement',
  task: 'Android Keyboard Document',
  priority: 3,
  comments: [{ text: 1 }, { text: 2 }, { text: 3 }],
  assignee: [{ name: 'kamal', profilePic: 'm1' }, { name: 'suku', profilePic: 'm2' }, { name: 'john', profilePic: 'm3' }]
}, {
  id: '4',
  randaomId: 'Siva',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  storyPoints: 8,
  reporter: [{ name: 'Kamal' }],
  description: "Desc Text Desc TextDesc TextDesc Text Desc TextDesc TextDesc TextDesc TextDesc Text Desc TextDesc Text",
  status: "In Review",
  roles: 'Finance',
  groups: 'Finance',
  lastSignedIn: '4557890',
  issueType: 'improvement',
  priority: 1,
  task: 'Android Keyboard Document',
  comments: [],
  assignee: [{ name: 'kamal', profilePic: 'm1' }, { name: 'suku', profilePic: 'm2' }, { name: 'john', profilePic: 'm3' }]
}, {
  id: '5',
  randaomId: 'Rajesh',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  description: "Desc Text Desc TextDesc TextDesc Text Desc TextDesc TextDesc TextDesc TextDesc Text Desc TextDesc Text",
  status: "Blocked",
  roles: 'Paradot',
  storyPoints: 8,
  reporter: [{ name: 'Kamal' }],
  groups: 'Marketing',
  lastSignedIn: '4557890',
  issueType: 'improvement',
  priority: 5,
  task: 'Android Keyboard Document',
  comments: [],
  assignee: []
}, {
  id: '6',
  randaomId: 'Gokul',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  description: "Desc Text Desc TextDesc TextDesc Text Desc TextDesc TextDesc TextDesc TextDesc Text Desc TextDesc Text",
  status: "Duplicate",
  roles: 'Paradot',
  groups: 'Pm team',
  storyPoints: 8,
  reporter: [{ name: 'Kamal' }],
  lastSignedIn: '4557890',
  issueType: 'improvement',
  priority: 4,
  task: 'Android Keyboard Document',
  comments: [{ text: 1 }, { text: 2 }, { text: 3 }],
  assignee: []
},
{
  id: '7',
  randaomId: 'Kamal K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  status: "Done",
  roles: 'Paradot',
  groups: 'design',
  lastSignedIn: '4557890',
  storyPoints: 8,
  reporter: [{ name: 'Kamal' }],
  issueType: 'newFeature',
  task: 'Android Keyboard Document',
  priority: 5,
  comments: [],
  assignee: []
}, {
  id: '8',
  randaomId: 'Kamal K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  status: "Completed",
  storyPoints: 8,
  reporter: [{ name: 'Kamal' }],
  roles: 'Paradot',
  groups: 'Development',
  lastSignedIn: '4557890',
  issueType: 'newFeature',
  priority: 1,
  task: 'Android Keyboard Document',
  comments: [{ text: 1 }],
  assignee: []
}, {
  id: '9',
  randaomId: 'Hema K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  status: "Reopen",
  roles: 'Paradot',
  storyPoints: 8,
  reporter: [{ name: 'Kamal' }],
  groups: 'Javascript',
  lastSignedIn: '4557890',
  issueType: 'query',
  priority: 5,
  task: 'Android Keyboard Document',
  assignee: [],
  comments: [{ text: 1 }, { text: 2 }, { text: 3 }]
}, {
  id: '19',
  randaomId: 'Hema K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  status: "Reopen",
  storyPoints: 8,
  reporter: [{ name: 'Kamal' }],
  roles: 'Paradot',
  groups: 'Javascript',
  lastSignedIn: '4557890',
  issueType: 'query',
  priority: 5,
  task: 'Android Keyboard Document',
  assignee: [],
  comments: [{ text: 1 }, { text: 2 }, { text: 3 }]
}, {
  id: '29',
  randaomId: 'Hema K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  status: "Reopen",
  roles: 'Paradot',
  groups: 'Javascript',
  storyPoints: 8,
  reporter: [{ name: 'Kamal' }],
  lastSignedIn: '4557890',
  issueType: 'query',
  priority: 5,
  task: 'Android Keyboard Document',
  assignee: [],
  comments: [{ text: 1 }, { text: 2 }, { text: 3 }]
}, {
  id: '39',
  randaomId: 'Hema K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  status: "Reopen",
  storyPoints: 8,
  reporter: [{ name: 'Kamal' }],
  roles: 'Paradot',
  groups: 'Javascript',
  lastSignedIn: '4557890',
  issueType: 'query',
  priority: 5,
  task: 'Android Keyboard Document',
  assignee: [],
  comments: [{ text: 1 }, { text: 2 }, { text: 3 }]
}, {
  id: '49',
  randaomId: 'Hema K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  status: "Reopen",
  roles: 'Paradot',
  groups: 'Javascript',
  lastSignedIn: '4557890',
  issueType: 'query',
  storyPoints: 8,
  reporter: [{ name: 'Kamal' }],
  priority: 5,
  task: 'Android Keyboard Document',
  assignee: [],
  comments: [{ text: 1 }, { text: 2 }, { text: 3 }]
}, {
  id: '59',
  randaomId: 'Hema K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  status: "Reopen",
  roles: 'Paradot',
  groups: 'Javascript',
  lastSignedIn: '4557890',
  issueType: 'query',
  storyPoints: 8,
  reporter: [{ name: 'Kamal' }],
  priority: 5,
  task: 'Android Keyboard Document',
  assignee: [],
  comments: [{ text: 1 }, { text: 2 }, { text: 3 }]
}, {
  id: '69',
  randaomId: 'Hema K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  status: "Reopen",
  roles: 'Paradot',
  groups: 'Javascript',
  lastSignedIn: '4557890',
  issueType: 'query',
  storyPoints: 8,
  reporter: [{ name: 'Kamal' }],
  priority: 5,
  task: 'Android Keyboard Document',
  assignee: [],
  comments: [{ text: 1 }, { text: 2 }, { text: 3 }]
}];



const PRO_SPRINT_ITEMS = [{
  id: 'QD1-1',
  randaomId: 'Kamal K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  description: `Lorem Ipsum is simply dummy text of the printing and Typesetting industry.` +
    `Lorem Ipsum has been the industry’s Standard dummy text ever since the 1500s,` +
    ` when an unknown Printer took a gallery of type and scrambled it to make a type Specimen book.` +
    `It has survived not only five centuries, but also The leap into electronic typesetting`,
  status: { value: 1, label: "To Do" },
  roles: 'Paradot',
  groups: 'human resources',
  lastSignedIn: '4557890',
  storyPoints: 8,
  reporter: [{ name: 'Kamal', id: '1' }],
  issueType: { value: 4, label: "Bug" },
  summary: 'Android Keyboard Document',
  acceptanceCriteria: `Lorem Ipsum is simply dummy text of the printing and Typesetting industry.` +
    `Lorem Ipsum has been the industry’s Standard dummy text ever since the 1500s,` +
    ` when an unknown Printer took a gallery of type and scrambled it to make a type Specimen book.` +
    `It has survived not only five centuries, but also The leap into electronic typesetting`,
  comments: [{ text: 1 }, { text: 2 }],
  priority: 2,
  workLog: '2d',
  assignee: [{ name: 'john', id: '10' }]
}, {
  id: 'QD1-2',
  randaomId: 'John',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  description: "Desc Text Desc TextDesc TextDesc Text Desc TextDesc TextDesc TextDesc TextDesc Text Desc TextDesc Text",
  status: { value: 2, label: "In Progress" },
  roles: 'engineering',
  groups: 'engineering',
  storyPoints: 8,
  reporter: [{ name: 'Kamal', id: '1' }],
  workLog: '4H',
  lastSignedIn: '4557890',
  issueType: { value: 3, label: "Task" },
  comments: [{ text: 1 }],
  priority: 4,
  summary: 'Android Keyboard Document',
  assignee: [{ name: 'suku', id: '11' }]
}, {
  id: 'QD1-22332',
  randaomId: 'John',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  description: "Desc Text Desc TextDesc TextDesc Text Desc TextDesc TextDesc TextDesc TextDesc Text Desc TextDesc Text",
  status: { value: 2, label: "In Progress" },
  roles: 'engineering',
  groups: 'engineering',
  lastSignedIn: '4557890',
  storyPoints: 8,
  reporter: [{ name: 'Kamal' }],
  workLog: '4d',
  issueType: { value: 3, label: "Task" },
  comments: [{ text: 1 }],
  priority: 4,
  summary: 'Android Keyboard Document',
  assignee: [{ name: 'john', id: '10' }]
}, {
  id: 'QD1-222332',
  randaomId: 'John',
  designation: "Hr",
  storyPoints: 8,
  reporter: [{ name: 'Kamal' }],
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  description: "Desc Text Desc TextDesc TextDesc Text Desc TextDesc TextDesc TextDesc TextDesc Text Desc TextDesc Text",
  status: { value: 2, label: "In Progress" },
  roles: 'engineering',
  groups: 'engineering',
  lastSignedIn: '4557890',
  issueType: { value: 3, label: "Task" },
  comments: [{ text: 1 }],
  priority: 4,
  summary: 'Android Keyboard Document',
  assignee: [{ name: 'kamal', id: '12' }]
}, {
  id: 'QD1-3',
  storyPoints: 8,
  reporter: [{ name: 'Kamal' }],
  randaomId: 'Musk',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  description: "Desc Text Desc TextDesc TextDesc Text Desc TextDesc TextDesc TextDesc TextDesc Text Desc TextDesc Text",
  status: { value: 6, label: "Parking Lot" },
  roles: 'Devops',
  groups: 'Devops',
  lastSignedIn: '4557890',
  issueType: { value: 4, label: "Improvements" },
  summary: 'Android Keyboard Document',
  priority: 3,
  comments: [{ text: 1 }, { text: 2 }, { text: 3 }],
  assignee: [{ name: 'kamal', id: '12' }]
}, {
  id: 'QD1-4',
  storyPoints: 8,
  reporter: [{ name: 'Kamal' }],
  randaomId: 'Siva',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  description: "Desc Text Desc TextDesc TextDesc Text Desc TextDesc TextDesc TextDesc TextDesc Text Desc TextDesc Text",
  status: { value: 4, label: "In Review" },
  roles: 'Finance',
  groups: 'Finance',
  lastSignedIn: '4557890',
  issueType: { value: 4, label: "Improvements" },
  priority: 1,
  summary: 'Android Keyboard Document',
  comments: [],
  assignee: [{ name: 'kamal', id: '12' }]
}, {
  id: 'QD1-5',
  randaomId: 'Rajesh',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  description: "Desc Text Desc TextDesc TextDesc Text Desc TextDesc TextDesc TextDesc TextDesc Text Desc TextDesc Text",
  status: { value: 3, label: "With QA" },
  roles: 'Paradot',
  storyPoints: 8,
  reporter: [{ name: 'Kamal' }],
  groups: 'Marketing',
  lastSignedIn: '4557890',
  issueType: { value: 4, label: "Improvements" },
  priority: 5,
  summary: 'Android Keyboard Document',
  comments: [],
  assignee: [{ name: 'Dion', id: '03' }]
}, {
  id: 'QD1-6',
  randaomId: 'Gokul',
  designation: "Hr",
  storyPoints: 8,
  reporter: [{ name: 'Kamal' }],
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  description: "Desc Text Desc TextDesc TextDesc Text Desc TextDesc TextDesc TextDesc TextDesc Text Desc TextDesc Text",
  status: { value: 5, label: "Done" },
  roles: 'Paradot',
  groups: 'Pm team',
  lastSignedIn: '4557890',
  issueType: { value: 4, label: "Improvements" },
  priority: 4,
  summary: 'Android Keyboard Document',
  comments: [{ text: 1 }, { text: 2 }, { text: 3 }],
  assignee: [{ name: 'Hema', id: '4' }]
},
{
  id: 'QD1-7',
  randaomId: 'Kamal K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  status: { value: 5, label: "Done" },
  roles: 'Paradot',
  groups: 'design',
  storyPoints: 8,
  reporter: [{ name: 'Kamal' }],
  lastSignedIn: '4557890',
  issueType: { value: 6, label: "FeatureRequest" },
  summary: 'Android Keyboard Document',
  priority: 5,
  comments: [],
  assignee: [{ name: 'Suku', id: '11' }]
}, {
  id: 'QD1-8',
  randaomId: 'Kamal K',
  designation: "Hr",
  mobile: "98766433456",
  storyPoints: 8,
  reporter: [{ name: 'Kamal' }],
  email: "kamal@qubernet.com",
  status: { value: 5, label: "Done" },
  roles: 'Paradot',
  groups: 'Development',
  lastSignedIn: '4557890',
  issueType: { value: 6, label: "FeatureRequest" },
  priority: 1,
  summary: 'Android Keyboard Document',
  comments: [{ text: 1 }],
  assignee: [{ name: 'John', id: '10' }]
}, {
  id: 'QD1-9',
  randaomId: 'Hema K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  status: { value: 2, label: "Reopen" },
  roles: 'Paradot',
  storyPoints: 8,
  reporter: [{ name: 'Kamal' }],
  groups: 'Javascript',
  lastSignedIn: '4557890',
  issueType: { value: 7, label: "Query" },
  priority: 5,
  summary: 'Android Keyboard Document',
  assignee: [{ name: 'Kamal', id: '1' }],
  comments: [{ text: 1 }, { text: 2 }, { text: 3 }]
}, {
  id: 'QD1-19',
  randaomId: 'Hema K',
  designation: "Hr",
  mobile: "98766433456",
  storyPoints: 8,
  reporter: [{ name: 'Kamal' }],
  email: "kamal@qubernet.com",
  status: { value: 2, label: "Reopen" },
  roles: 'Paradot',
  groups: 'Javascript',
  lastSignedIn: '4557890',
  issueType: { value: 7, label: "Query" },
  priority: 5,
  summary: 'Android Keyboard Document',
  assignee: [{ name: 'Smitha', id: '14' }],
  comments: [{ text: 1 }, { text: 2 }, { text: 3 }]
}, {
  id: 'QD1-29',
  randaomId: 'Hema K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  status: { value: 2, label: "Reopen" },
  roles: 'Paradot',
  storyPoints: 8,
  reporter: [{ name: 'Kamal' }],
  groups: 'Javascript',
  lastSignedIn: '4557890',
  issueType: { value: 1, label: "Epic" },
  priority: 5,
  summary: 'Android Keyboard Document',
  assignee: [{ name: 'Gokul', id: '4' }],
  comments: [{ text: 1 }, { text: 2 }, { text: 3 }]
}, {
  id: 'QD1-39',
  randaomId: 'Hema K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  status: { value: 2, label: "Reopen" },
  roles: 'Paradot',
  storyPoints: 8,
  reporter: [{ name: 'Kamal' }],
  groups: 'Javascript',
  lastSignedIn: '4557890',
  issueType: { value: 2, label: "Story" },
  priority: 5,
  summary: 'Android Keyboard Document',
  assignee: [{ name: 'Raja', id: '6' }],
  comments: [{ text: 1 }, { text: 2 }, { text: 3 }]
}, {
  id: 'QD1-49',
  randaomId: 'Hema K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  status: { value: 2, label: "Reopen" },
  roles: 'Paradot',
  groups: 'Javascript',
  storyPoints: 8,
  reporter: [{ name: 'Kamal' }],
  lastSignedIn: '4557890',
  issueType: { value: 2, label: "Story" },
  priority: 5,
  summary: 'Android Keyboard Document',
  assignee: [{ name: 'Kevin', id: '7' }],
  comments: [{ text: 1 }, { text: 2 }, { text: 3 }]
}, {
  id: 'QD1-59',
  randaomId: 'Hema K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  status: { value: 2, label: "Reopen" },
  roles: 'Paradot',
  storyPoints: 8,
  reporter: [{ name: 'Kamal' }],
  groups: 'Javascript',
  lastSignedIn: '4557890',
  issueType: { value: 1, label: "Epic" },
  priority: 5,
  summary: 'Android Keyboard Document',
  assignee: [{ name: 'Nikil', id: '43' }],
  comments: [{ text: 1 }, { text: 2 }, { text: 3 }]
}, {
  id: 'QD1-69',
  randaomId: 'Hema K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  status: { value: 2, label: "Reopen" },
  roles: 'Paradot',
  groups: 'Javascript',
  storyPoints: 8,
  reporter: [{ name: 'Kamal' }],
  issueType: { value: 8, label: "Subtask" },
  priority: 5,
  summary: 'Android Keyboard Document',
  assignee: [{ name: 'Naveen', id: '435' }],
  comments: [{ text: 1 }, { text: 2 }, { text: 3 }]
}];

const PRO_TABS = [
  {
    label: 'Dashboard',
    key: "dashboard"
  },
  {
    label: 'Epic',
    key: "epic"
  },
  {
    label: 'Sprints',
    key: "sprints"
  },
  {
    label: 'To Do',
    key: "todo"

  }, {
    label: 'In Progress',
    key: "inprogress"
  }, {
    label: 'Ready for QA',
    key: "invalidate"
  },
  {
    label: 'In Review',
    key: "inreview"
  },
  {
    label: 'Done',
    key: "done"
  },

  {
    label: 'Release',
    key: "release"
  },

  {
    label: 'Backlogs',
    key: "backlogs"
  }
];

const WORKFLOW = [{
  label: 'To Do',
  key: "todo"

}, {
  label: 'In Progress',
  key: "inprogress"
}, {
  label: 'Ready for QA',
  key: "invalidate"
},
{
  label: 'In Review',
  key: "inreview"
},
{
  label: 'Done',
  key: "done"
},
{
  label: 'Parking Lot',
  key: "parkinglot"
}];

const SPRINT = [{
  epic: 'Emandate documents Task -1',
  epicId: 'QD25',
  items: PRO_SPRINT_ITEMS
}, {
  epic: 'User Experience design #9201',
  epicId: 'QD26',
  items: PRO_SPRINT_ITEMS
}, {
  epic: 'UI components and guidelines',
  epicId: 'QD27',
  items: PRO_SPRINT_ITEMS
}, {
  epic: 'Mind map - 3rd sector',
  epicId: 'QD28',
  items: PRO_SPRINT_ITEMS
}, {
  epic: 'missingComponents and assets',
  epicId: 'QD29',
  items: PRO_SPRINT_ITEMS
}, {
  epic: 'olam sector - Nigeria',
  epicId: 'QD30',
  items: PRO_SPRINT_ITEMS
}];


const COLUMNS_USERS = [
  {
    id: "profile",
    label: "Name",
    widget: "profile",
    fixed: 'left'
  }, {
    id: "email",
    label: "Email Id",
    widget: "text"
  },
  {
    id: "mobile",
    label: "Mobile No",
    widget: "phone"
  }, {
    id: "status",
    label: "status",
    widget: "status"
  }];

const COLUMNS_GROUPS = [
  {
    id: "name",
    label: "Name",
    widget: "text",
    fixed:true
  }, {
    id: "members",
    label: "members",
    widget: "members"
  }, {
    id: "status",
    label: "status",
    widget: "status"
  }
];

export {
  ORG_STATISTICS, COLUMNS_GROUPS, COLUMNS_USERS, WORKFLOW, SPRINT, PRO_TABS, PRO_ITEMS_COLUMNS, PRO_ITEMS_ROWS, PROJECT_LIST, ITEM_STATISTICS
};