import React, { useState } from 'react';
import { KoIcon, KoButton, KoDataTableEdit, KoNano } from 'packages';
import styles from './tcurd.module.scss';

function HeaderCrud({ form, initialValues, onChange }) {

  const [dataSource, setDataSource] = useState(initialValues?.requestHeaders?.length > 0 ?
    initialValues?.requestHeaders :  [{
      key: KoNano('hdr'),
      name: null,
      value: null
    }]);

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
    handleSave(newData);
  };

  const columns = [
    {
      dataIndex: 'name',
      title: 'Key',
      width: 200,
      editable: true,
      widget: 'text',
      placeholder: ''
    },
    {
      dataIndex: 'value',
      title: 'Value',
      editable: true,
      widget:'text',
      placeholder: '',
      width: 200
    },
    {
      dataIndex: 'description',
      title: 'Description',
      editable: true,
      widget:'text',
      placeholder: '',
      width: 200
    },
    {
      title: ` `,
      dataIndex: 'operation',
      width: 40,
      fixed:'right',
      render: (_, record, i) =>
        dataSource.length >= 1 ? (
          <div className={'editTableCol'}>
            {dataSource.length > 1 && <KoButton icon={<KoIcon width={12} height={12} name={'DeleteOutlined'} />} className={styles.actionBtn} onClick={() => handleDelete(record?.key)} label="" />}
            {dataSource?.length === (i+1) && <KoButton icon={<KoIcon color={`var(--palette-gray-7)`} width={17} height={17} name={'PlusOutlined'} />} className={styles.actionBtn} onClick={() => handleAdd(record?.key)} label={``} />}
          </div>
        ) : null
    }
  ];

  const handleAdd = () => {
    const newData = {
      key: KoNano('hdr'),
      name: ``,
      value: ''
    };
    setDataSource([...dataSource, newData]);
  };

  const handleSave = (newData) => {
    setDataSource(newData);
    onChange(newData);
  };

  return (
    <div className={styles.wrapper}>
      <KoDataTableEdit
        dataSource={dataSource}
        form={form}
        onSave={handleSave}
        defaultColumns={columns} />
    </div>
  );
};

export default HeaderCrud;