import React from "react";
import styles from './status.module.scss';

function InvoiceStatus(props) {
  let { data } = props;
  if (data === 0) {
    data = "Due";
  } else if (data === 1) {
    data = "Sent";
  } else if (data === 2) {
    data = "Paid";
  } else if (data === 3) {
    data = "Rejected";
  } else if (data === -1) {
    data = "Deleted";
  }
  return (
    <div className={styles.wrapper}>
      <div className={`${styles.status} ${styles[data?.replace(" ", '')]}`}>
        <div className={styles.text}>{data}</div>
      </div>
    </div>

  );
}
export default InvoiceStatus;
