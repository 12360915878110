import { KoButton, KoDropdown, KoEmpty, KoFlex, KoForm, KoIcon, KoLoader, KoModal, KoNano, KoPagination, KoTable, KoText, KoTitle, KoToast, KoTooltips } from "packages";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import KoToolBar from "shared/components/toolbar";
import { TaskPlannerAPI } from "pages/taskPlanner/taskPlanner.service";
import styles from './testcases.module.scss';
import { LEV_ITEMS_COLUMNS } from './constant';
import TestCaseCrud from "./crud";

const TestCases = (props) => {
  const [totalCount, setTotalCount] = useState(100);
  const [testCasesList, settestCasesList] = useState();
  const [isOpen, setModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState();
  const [isOpenDelete, setDeleteModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [profile, setProfile] = useState([]);
  const [form] = KoForm.useForm();
  const {workspaceId} = useParams();
  const [pagination, setPagination] = useState({
    size: 10,
    page: 1
  });
  const onRow = (record) => {
  };

  useEffect(() => {
    getWorkspaceById();
    getTestCases();
  }, []);

  const getTestCases = () => {
    settestCasesList();
    setLoading(true);
    TaskPlannerAPI.getTestCases(pagination, workspaceId).then(
      (data) => {
        setLoading(false);
        settestCasesList(data?.components);
        setTotalCount(data?.totalCount);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const getWorkspaceById = () => {
    TaskPlannerAPI.getWorkspaceById(workspaceId).then(
      (data) => {
        setProfile(data['project'].teams);
      },
      (err) => {
      }
    );
  };

  const onSearchText = (text) => {
    let q = pagination;
    if (text) {
      q['search'] = text;
    }
    else {
      delete q?.['search'];
    }
    getTestCases(q);
  };

  const onSort = (key, type) => {
    let q = pagination;
    q['sort'] = `${key}:${type}`;
    getTestCases(q);
  };

  const onPagination = (page, other) => {
    const query = {
      size: other,
      page: page,
      offset: (page - 1) * other
    };
    const pQuery = Object.assign(pagination, query);
    setPagination(pQuery);
    getTestCases(pQuery);
  };


  const handleFinish = (values => {
    setLoading(true);
    if (!currentRecord?.testId) {
      values['testId'] = KoNano('TC');
    }
    TaskPlannerAPI.createOrUpdateComponent(values, currentRecord?.testId, workspaceId).then(
      (data) => {
        getTestCases();
        setLoading(false);
        setModal(false);
        form.resetFields();
        KoToast.success("Component created Successfully");

      },

      (err) => {
        setLoading(false);
      }
    );
  });

  const HandleDelete = () => {
    setLoading(true);
    TaskPlannerAPI.deleteComponent(currentRecord?.testId, workspaceId).then(
      (data) => {
        setLoading(false);
        setDeleteModal(false);
        KoToast.success("Component Deleted Successfully");
        getTestCases();
      },
      (err) => {
        setLoading(false);
      }
    );
  };



  const items = [
    {
      key: '2',
      danger: true,
      label: 'Delete',
      icon: <KoIcon name='DeleteOutlined' />
    }
  ];

  const onHandelSprint = (e, row) => {
    if (e.key === "2") {
      setDeleteModal(true);
    }
  };
  const columnsRight = [{
    title: '',
    dataIndex: 'x',
    key: 'x',
    fixed: 'right',
    width: 40,
    render: (text, row) => {
      return <div className={`${styles.circle} tableBtn columnsRight`} onClick={() => setCurrentRecord(row)}>
        <KoTooltips title={'Edit'}>
          <KoButton className={'btn'} iconBtn={true} icon={<KoIcon width={14} height={14} name={'EditOutlined'} />} onClick={() => setModal(true)} />
        </KoTooltips>
        <div shape="circle">
          <KoDropdown
            menu={{
              items,
              onClick: (e) => onHandelSprint(e, row)
            }}
            overlayClassName={styles.actionDropdown}
            placement="bottomLeft"
          >
            <div shape="circle" >
              <KoIcon name="VerticalMenuOutlined" onClick={() => (row)} />
            </div>
          </KoDropdown>
        </div>
      </div>;
    }
  }];

  
  return (
    <>
      {isLoading && <KoLoader />}
      <div className={`${styles.wrapper} tableCard`}>
        <KoToolBar
          title={'Test Cases'}
          search={{ visible: true, onChange: (e) => { onSearchText(e); }, placeholder: 'Search by any parameter' }}
          filter={{ visible: false }}
          buttonList={[{
            label: 'Create',
            type: 'primary',
            icon: <KoIcon color="#FFF" name="PlusOutlined" width={18} height={18} />, onClick: () => setModal(!isOpen)
          }]}
        />
        {testCasesList?.length > 0 ? <>
          <KoTable
            rowKey='testCaseId'
            onRow={onRow}
            onSort={onSort}
            data={{ columns: LEV_ITEMS_COLUMNS, rows: testCasesList }} extraColumnsRight={columnsRight} maxHeight={50} />
          <KoPagination current={parseInt(pagination.page) || 1}
            total={totalCount}
            showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} records`
            }
            pageSize={pagination.limit}
            defaultCurrent={pagination.page}
            onChange={onPagination}
            showQuickJumper={false}
            simple={false}
            responsive={false} />
        </>
          :

          <KoFlex wrap="wrap" gap="small" className={styles.emptySection}>
            <KoEmpty onClick={() => setModal(!isOpen)} label="Create test case" icon={'EmptyTwoTone'} title="Create a new test case" />
          </KoFlex>}

        <KoModal
          title={`${currentRecord?.testId ? 'Update' : 'Create'} Test Case`}
          centered
          open={isOpen}
          afterClose={() => {setCurrentRecord();}}
          handleOk={() => { setCurrentRecord(); setModal(false); }}
          onClose={() => { setCurrentRecord(); setModal(false); }}
          width={'95vw'}
          maskClosable={false}
          footer={[
            <KoButton key='cancel' label='Cancel' onClick={() => setModal(!isOpen)} />,
            <KoButton key='create' loading={isLoading} type='primary' label={currentRecord?.testId ? `Update` : `Create`}
              onClick={() => form.submit()} />
          ]}
        >
          <TestCaseCrud form={form} initialValues={currentRecord} handleFinish={handleFinish} workspaceProfile={profile} />
        </KoModal>

        <KoModal
          title={`Delete ${currentRecord?.name?.toUpperCase()} Component `}
          centered
          open={isOpenDelete}
          afterClose={() => {setCurrentRecord();}}
          handleOk={() => { setCurrentRecord(); setDeleteModal(false); }}
          onClose={() => { setCurrentRecord(); setDeleteModal(false); }}
          width={580}
        // maskClosable={false}
          height={450}
          footer={[
            <KoButton key='cancel' label='Cancel' onClick={() => setDeleteModal(!isOpenDelete)} />,
            <KoButton key='delete' loading={isLoading} type='primary' label='Delete' onClick={() => HandleDelete()} />
          ]}
        >
          <div className={styles.delWrapper}>
            <KoTitle level={5} text={`This action will delete item and cannot be undone`} />
            <KoText text={`Do you wish to proceed?`} />
          </div>
        </KoModal>
      </div>
    </>
  );
};

export default TestCases;