import React from "react";
function Play(props) {
  const { width, height, color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color? color : 'var(--palette-primary-darker)'}>
      <path data-name="Path 78901" d="M7.85-511.549a8.184 8.184 0 0 0-4.309 1.769 9.768 9.768 0 0 0-1.2 1.212 8.391 8.391 0 0 0-1.55 3.2 6.723 6.723 0 0 0-.188 1.8 6.724 6.724 0 0 0 .188 1.8 8.328 8.328 0 0 0 1.594 3.257 9.772 9.772 0 0 0 1.212 1.2 8.394 8.394 0 0 0 3.2 1.55 6.725 6.725 0 0 0 1.8.188 6.725 6.725 0 0 0 1.8-.188 8.7 8.7 0 0 0 2.593-1.105 8.018 8.018 0 0 0 2.8-3.181 7.945 7.945 0 0 0 .755-2.427 10.682 10.682 0 0 0 0-2.192 7.945 7.945 0 0 0-.755-2.427 7.587 7.587 0 0 0-1.56-2.167 7.44 7.44 0 0 0-2.108-1.5 7.913 7.913 0 0 0-2.4-.752 12.068 12.068 0 0 0-1.872-.037zm-.542 5.035c.141.069 4.04 2.5 4.121 2.571a.654.654 0 0 1 .175.379.654.654 0 0 1-.175.379c-.081.069-3.98 2.5-4.121 2.571a.6.6 0 0 1-.219.056.554.554 0 0 1-.423-.266l-.072-.125v-5.229l.072-.125a.554.554 0 0 1 .423-.266.6.6 0 0 1 .219.055z" transform="translate(-.6 511.567)"  />
    </svg>

  );
}

// fill="var(--palette-primary-darker)"
export default Play;