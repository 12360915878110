import {KoAPI} from "packages";
import { configs } from 'configuration';
const version = configs().API_VERSION;
export const DashbordAPI = {
      
  getRecentTicket(query) {
    return KoAPI.get(`/api/${version}/taskplanner/ticket/recent`, { query: { ...query } });
  },
  getIssueById(id, workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/ticket/${id}`, { header: {
      "workspace": workspaceId
    }});
  },
  createOrUpdateIssues(data, id, workspaceId) {
    if(id) {
      return KoAPI.update(`/api/${version}/taskplanner/ticket/${id}`, { data: { ...data }, header: {
        "workspace": workspaceId
      }});
    } else {
      return KoAPI.post(`/api/${version}/taskplanner/ticket`, { data: { ...data }, header: {
        "workspace": workspaceId
      }});
    }
  },
  getWorkspaceById(id){
    return KoAPI.get(`/api/${version}/workspace/${id}`);
  },
  getStatus(){
    return KoAPI.get(`/api/${version}/taskplanner/ticket/recent/status/count`);
  },
  getAssignedTicketCount(workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/dashboard/ticket/assigned/count`);
  },
  getActiveTicketCount() {
    return KoAPI.get(`/api/${version}/taskplanner/dashboard/ticket/active/count`);
  },

  getTicketDescriptionCount() {
    return KoAPI.get(`/api/${version}/taskplanner/dashboard/ticket/description/count`);
  },
  getTicketCriteriaCount() {
    return KoAPI.get(`/api/${version}/taskplanner/dashboard/ticket/criteria/count`);
  },
  getTicketStatusCount() {
    return KoAPI.get(`/api/${version}/taskplanner/dashboard/ticket/status/count`);
  },
  getTicketOverDueCount() {
    return KoAPI.get(`/api/${version}/taskplanner/dashboard/ticket/overdue/count`);
  },
  getTicketsByDateCount() {
    return KoAPI.get(`/api/${version}/taskplanner/dashboard/date/filled/count`);
  },
  getWorkspaceTaskCount(query) {
    return KoAPI.get(`/api/${version}/taskplanner/dashboard/workspace/task/count`, { query: { ...query } });
  },

  getTicketPercentage(query) {
    return KoAPI.get(`/api/${version}/taskplanner/dashboard/ticket/percentage`, { query: { ...query } });
  },

  getCycleTaskCount(query) {
    return KoAPI.get(`/api/${version}/taskplanner/dashboard/cycle/task/count`, { query: { ...query } });
  },

  getProjectCompletedTask(query) {
    return KoAPI.get(`/api/${version}/taskplanner/dashboard/project/completed/task`, { query: { ...query } });
  },

  getWorkspaceCriteriaCount(query) {
    return KoAPI.get(`/api/${version}/taskplanner/workspace/dashboard/criteria/count`, { query: { ...query } });
  },

  getWorkspaceDescriptionCount(query) {
    return KoAPI.get(`/api/${version}/taskplanner/workspace/dashboard/description/count`, { query: { ...query } });
  },

  getOverdueIssueCount(query) {
    return KoAPI.get(`/api/${version}/taskplanner/dashboard/overdue/issue/count`, { query: { ...query } });
  },

  getWorkspaceDateFilledCount(query) {
    return KoAPI.get(`/api/${version}/taskplanner/dashboard/workspace/date/filled/count`, { query: { ...query } });
  },
  getIssueTypeCount() {
    return KoAPI.get(`/api/v1/taskplanner/dashboard/issuetype/count`);
  },
  getWorkspaceActiveCount() {
    return KoAPI.get(`/api/v1/taskplanner/dashboard/workspace/active/count`);
  },
  getTicketUserData() {
    return KoAPI.get(`/api/v1/taskplanner/dashboard/ticket/user`);
  },
  getDescriptionFilledCount() {
    return KoAPI.get(`/api/v1/taskplanner/dashboard/description/filled/count`);
  }
};