import { KoAPI } from "packages";
import { configs } from 'configuration';
const version = configs().API_VERSION;
export const TaskPlannerAPI = {
  getCount(data, workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/ticket/priority/statistics`, { data });
  },
  getWorkspaceById(id){
    return KoAPI.get(`/api/${version}/workspace/${id}`);
  },
  createReaction(ticketId, id, workspaceId, data) {
    return KoAPI.update(`/api/${version}/ticket/comments/reaction/${ticketId}/${id}`, { header: {
      "workspace": workspaceId
    }, data});
  },
  getAttachments(ticketId, workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/ticket/attachments/${ticketId}`, { header: {
      "workspace": workspaceId
    }});
  },
  createOrUpdateSprint(data, id, workspaceId) {
    if(id) {
      return KoAPI.update(`/api/${version}/taskplanner/sprint/${id}`, { data: { ...data }, header: {
        "workspace": workspaceId
      }});
    } else {
      return KoAPI.post(`/api/${version}/taskplanner/sprint`, { data: { ...data }, header: {
        "workspace": workspaceId
      }});
    }
  },
  updateSprintStatus(data, id, workspaceId) {
    return KoAPI.update(`/api/${version}/taskplanner/sprint/status/${id}`, { data: { ...data }, header: {
      "workspace": workspaceId
    }});
  },
  getActiveSprints(workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/sprint/active`, { header: {
      "workspace": workspaceId
    }});
  },
  getSprints(query, workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/sprint`, { query: { ...query }, header: {
      "workspace": workspaceId
    }});
  },
  deleteSprint(id, workspaceId){
    return KoAPI.delete(`/api/${version}/taskplanner/sprint/${id}`, { header: {
      "workspace": workspaceId
    }});
  },
  getTicketsBySprint(id, query, workspaceId){
    return KoAPI.get(`/api/${version}/taskplanner/ticket/sprint/${id}`, { query: { ...query }, header: {
      "workspace": workspaceId
    }});
  },

  getIssuesByScrum(query, workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/ticket/scrum`, { query: { ...query }, header: {
      "workspace": workspaceId
    }});
  },

  createOrUpdateIssues(data, id, workspaceId) {
    if(id) {
      return KoAPI.update(`/api/${version}/taskplanner/ticket/${id}`, { data: { ...data }, header: {
        "workspace": workspaceId
      }});
    } else {
      return KoAPI.post(`/api/${version}/taskplanner/ticket`, { data: { ...data }, header: {
        "workspace": workspaceId
      }});
    }
  },
  createOrUpdateSubtask(data, id, workspaceId) {
    if(id) {
      return KoAPI.update(`/api/${version}/taskplanner/ticket/${id}`, { data: { ...data }, header: {
        "workspace": workspaceId
      }});
    } else {
      return KoAPI.post(`/api/${version}/taskplanner/ticket/subtask`, { data: { ...data }, header: {
        "workspace": workspaceId
      }});
    }
  },
  BulkUpdateIssue(data, workspaceId){
    return KoAPI.update(`/api/${version}/taskplanner/ticket/multiple`, { data: { ...data }, header: {
      "workspace": workspaceId
    }});
  },

  uploadAttachments(data){
    return KoAPI.post(`/api/${version}/upload`, { data: { ...data } });
  },
  getBacklog(query, workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/ticket/backlog`, { query: { ...query }, header: {
      "workspace": workspaceId
    }});
  },
  getIssues(query, workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/ticket`, { query: { ...query }, header: {
      "workspace": workspaceId
    }});
  },
  getSubTaskIssues(ticketId, workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/ticket/subtask/${ticketId}`,  {header: {
      "workspace": workspaceId
    }});
  },
  getSubTaskIssuesWithQuery(query, ticketId, workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/ticket/subtask/${ticketId}`,  {
      query: { ...query },
      header: { "workspace": workspaceId }});
  },

  getEpicIssues(ticketId, query, workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/ticket/epic/${ticketId}`, { query: { ...query }, header: {
      "workspace": workspaceId
    }});
  },
  getIssueById(id, workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/ticket/${id}`, { header: {
      "workspace": workspaceId
    }});
  },

  getIssueByIdPublic(id) {
    return KoAPI.get(`/api/${version}/taskplanner/public/ticket/${id}`);
  },
  deleteIssues(id, workspaceId){
    return KoAPI.delete(`/api/${version}/taskplanner/ticket/${id}`,{ header: {
      "workspace": workspaceId
    }});
  },

  getModules(query, workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/module`, { query: { ...query }, header: {
      "workspace": workspaceId
    }});
  },
  createOrUpdateModule(data, id, workspaceId) {
    if(id) {
      return KoAPI.update(`/api/${version}/taskplanner/module/${id}`, { data: { ...data }, header: {
        "workspace": workspaceId
      }});
    } else {
      return KoAPI.post(`/api/${version}/taskplanner/module`, { data: { ...data }, header: {
        "workspace": workspaceId
      }});
    }
  },
  deleteModule(id, workspaceId){
    return KoAPI.delete(`/api/${version}/taskplanner/module/${id}`, { header: {
      "workspace": workspaceId
    }});
  },
  
  getComponents(query, workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/component`, { query: { ...query },  header: {
      "workspace": workspaceId
    }});
  },
  createOrUpdateComponent(data, id, workspaceId) {
    if(id) {
      return KoAPI.update(`/api/${version}/taskplanner/component/${id}`, { data: { ...data }, header: {
        "workspace": workspaceId
      } });
    } else {
      return KoAPI.post(`/api/${version}/taskplanner/component`, { data: { ...data }, header: {
        "workspace": workspaceId
      } });
    }
  },
  deleteComponent(id, workspaceId){
    return KoAPI.delete(`/api/${version}/taskplanner/component/${id}`, { header: {
      "workspace": workspaceId
    }});
  },

  getEnvironments(id, workspaceId) {
    return KoAPI.get(`/api/${version}/apiclient/environment`, { header: {
      "workspace": workspaceId
    }});
  },

  getReleases(workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/release`, { header: {
      "workspace": workspaceId
    }});
  },

  getServices(id) {
    return KoAPI.get(`/api/${version}/deployment/service`);
  },

  getRelease(query, workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/release`, { query: { ...query },header: {
      "workspace": workspaceId
    }});
  },
  createOrUpdateRelease(data, id, workspaceId) {
    if(id) {
      return KoAPI.update(`/api/${version}/taskplanner/release/${id}`, { data: { ...data }, header: {
        "workspace": workspaceId
      } });
    } else {
      return KoAPI.post(`/api/${version}/taskplanner/release`, { data: { ...data }, header: {
        "workspace": workspaceId
      } });
    }
  },
  DeleteRelease(id,workspaceId){
    return KoAPI.delete(`/api/${version}/taskplanner/release/${id}`, {
      header: {
        "workspace": workspaceId
      }
    });
  },

  getPullRequest(query, id, workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/${id}/pr`, { query: { ...query } , header: {
      "workspace": workspaceId
    }});
  },
  createOrUpdatePullRequest(data, id, relid, workspaceId) {
    if(id) {
      return KoAPI.update(`/api/${version}/taskplanner/${relid}/pr/${id}`, { data: { ...data }, header: {
        "workspace": workspaceId
      } });
    } else {
      return KoAPI.post(`/api/${version}/taskplanner/${relid}/pr`, { data: { ...data }, header: {
        "workspace": workspaceId
      } });
    }
  },
  deletePullRequest(relId, id, workspaceId){
    return KoAPI.delete(`/api/${version}/taskplanner/${relId}/pr/${id}`, { header: {
      "workspace": workspaceId
    }});
  },
  getRecentPullRequest(query, workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/pr/recent`, { query: { ...query }, header: {
      "workspace": workspaceId
    } });
  },

  GetIssueStatistics(id, workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/ticket/issuetype/statistics/${id}` , { header: {
      "workspace": workspaceId
    }});
  },
  GetTicketCountByStatus(id, workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/ticket/status/${id}`, { header: {
      "workspace": workspaceId
    }});
  },
  deleteAttachments( workspaceId,lookupId, uid) {
    return KoAPI.post(`/api/${version}/org/upload/delete/${workspaceId}/${lookupId}/${uid}`);
  },
  createOrUpdateClient(data, id) {
    if(id){
      return KoAPI.update(`/api/${version}/workspace/${id}`, { data });
    }else{
      return KoAPI.post(`/api/${version}/workspace`, { data });
    }
  },

  UpdateStatusSprint(data, id, workspaceId){
    return KoAPI.update(`/api/${version}/taskplanner/ticket/status/sprint/${id}`, { data: { ...data }, header: {
      "workspace": workspaceId}});
  },
  updateFieldsTicket(data, id, workspaceId){
    return KoAPI.update(`/api/${version}/taskplanner/ticket/update/fields/${id}`, { data: { ...data }, header: {
      "workspace": workspaceId}});
  },

  createOrUpdateWorkLog(data, ticketId, id, workspaceId) {
    if(id){
      return KoAPI.update(`/api/${version}/home/ticket/worklog/${ticketId}/${id}`, { data: { ...data }, header: {
        "workspace": workspaceId}});
  
    }else{
      return KoAPI.post(`/api/${version}/home/ticket/worklog/${ticketId}`, { data: { ...data }, header: {
        "workspace": workspaceId}});
    }
  },
  getWorkLogs(ticketId, workspaceId){
    return KoAPI.get(`/api/${version}/home/ticket/worklogs/${ticketId}`, { header: {
      "workspace": workspaceId
    }});
  },
  deleteWorkLog(id,ticketId, workspaceId){
    return KoAPI.delete(`/api/${version}/home/ticket/worklog/${ticketId}/${id}`, { header: {
      "workspace": workspaceId
    }});
  },
  getComments(ticketId, workspaceId){
    return KoAPI.get(`/api/${version}/ticket/comments/${ticketId}`, { header: {
      "workspace": workspaceId
    }});
  },
  getActivities(ticketId, workspaceId){
    return KoAPI.get(`/api/${version}/taskplanner/ticket/activity/${ticketId}`, { header: {
      "workspace": workspaceId
    }});
  },
  createComment(data, id, workspaceId){
    if(id){
      return KoAPI.update(`/api/${version}/ticket/comments/${data?.ticketId}/${id}`, { data: { ...data }, header: {
        "workspace": workspaceId
      }});
    }else{
      return KoAPI.post(`/api/${version}/ticket/comments`, { data: { ...data }, header: {
        "workspace": workspaceId
      } });
    }
  },
  deleteComment(ticketId, commentId, workspaceId){
    return KoAPI.delete(`/api/${version}/ticket/comments/${ticketId}/${commentId}`, { header: {
      "workspace": workspaceId
    }});
  },
  getTestCases(id) {
    return KoAPI.get(`/api/${version}/testcase`);
  }
};