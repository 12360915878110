
import React from 'react';
import { KoButton,  KoIcon, KoImage, KoTitle, KoText } from 'packages';

import styles from './password.module.scss';

function Requested() {
 
 
  return (
    <div className={styles.wrapper}>
      <div className={styles.modal}>
        <div className={styles.formWrapper}>
          <div className={styles.header}>
            <KoIcon name='AppLogoFilled' width={108}
              height={37} />
          </div>
          <KoTitle text={`Request sent to your admin`} level={4} />
          <KoText text={`Please wait until approval`} className={`${styles.small}`} />
          <div className={styles.formData}>
          </div>
          
          <div className={styles.signupBtn}>
            <KoButton label={'OK'} type={'primary'} />
          </div>
        </div>
        <div className={styles.banner}>
          <div className={styles.slider}>
            <KoImage
              alt={'name'}
              src={`/images/banner.svg`}
              width={500}
              height={300}
              preview={false}
            />
            <div className={styles.footer}>
              <KoTitle text={'Connect your team’s work to your product roadmap'} level={5} />
              <KoText text="Every team has a unique process. Use a out-of-boss workflow,
              or create one to match the way team works"/>
            </div>
          </div>
          <div className={styles.dots}>
            <div className={styles.active} />
            <div className={styles.circle} />
            <div className={styles.circle} />
            <div className={styles.circle} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Requested;

