import React, { useState } from 'react';
import {  KoIcon, KoForm, KoButton, KoDataTableEdit, KoNano } from 'packages';
import styles from './env.module.scss';

function Variable({ form, handleFinish, settings }) {

  let ds=  settings?.var?.length > 0 ? settings?.var :  [{
    key: KoNano('VAR'),
    name: null,
    value: null
  }];
  const [dataSource, setDataSource] = useState(ds);
 
  const handleDelete = (key) => {
    let newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
    if(newData.length === 0){
      newData =[{
        key: KoNano('VAR'),
        name: null,
        value: null
      }];
    }
    handleSave(newData);
  };

  const columns = [
   
    {
      dataIndex: 'name',
      title: 'Name',
      editable: true,
      // readonly: true,
      widget:'text',
      width: 200
    },
    {
      dataIndex: 'value',
      title: 'Value',
      editable: true,
      // readonly: true,
      widget:'text',
      width: 200
    },
    {
      title: ` `,
      dataIndex: 'operation',
      width: 40,
      render: (_, record, i) =>
        dataSource.length >= 1 ? (
          <div className={'editTableCol'}>
            <KoButton icon={<KoIcon width={12} height={12} name={'DeleteOutlined'} />} className={styles.actionBtn} onClick={() => handleDelete(record?.key)} label="" />
            {dataSource?.length === (i+1) && <KoButton icon={<KoIcon color={`var(--palette-gray-7)`} width={17} height={17} name={'PlusOutlined'} />} className={styles.actionBtn} onClick={() => handleAdd(record?.key)} label={``} />}
          </div>
        ) : null
    }
  ];

  const handleAdd = () => {
    const newData = {
      key: KoNano('VAR'),
      name: null,
      value: null
    };
    setDataSource([...dataSource, newData]);
  };


  const handleSave = (newData) => {
    let data = form.getFieldsValue();
    data['variables'] = newData;
    form.setFieldsValue({...data});
    setDataSource(newData);
  };


  return (
    <KoForm form={form} layout="vertical" onFinish={handleFinish} onSubmit={handleFinish}>
      <KoForm.Item name={'variables'}>
        <KoDataTableEdit
          dataSource={dataSource}
          form={form}
          onSave={handleSave}
          defaultColumns={columns} />
      </KoForm.Item>
    </KoForm>
  );
};

export default Variable;