import React from "react";
function API(props) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width={width}
      height={height}
    >
      <path d="M6.578 6.772h1.553a1.018 1.018 0 000-2.036H6.578a1.018 1.018 0 000 2.036zm5.628 0h1.552a1.018 1.018 0 000-2.036h-1.552a1.018 1.018 0 000 2.036zm5.628 0h1.553a1.018 1.018 0 000-2.036h-1.553a1.018 1.018 0 000 2.036zm38.035 0h1.553a1.018 1.018 0 000-2.036h-1.553a1.018 1.018 0 000 2.036z" />
      <path d="M58.278 1H5.722A4.727 4.727 0 001 5.722v56.26C1 62.544 1.456 63 2.018 63h59.964c.562 0 1.018-.456 1.018-1.018V5.722A4.728 4.728 0 0058.278 1zM5.722 3.036h52.556a2.69 2.69 0 012.686 2.686v3.283H3.036V5.722a2.69 2.69 0 012.686-2.686zM3.036 60.964V11.041h57.928v49.923H3.036z" />
      <path d="M14.407 29.499a1.009 1.009 0 00.293-.694c0-.009.005-.017.005-.026v-7.675a3.253 3.253 0 013.249-3.25 1.018 1.018 0 000-2.036 5.291 5.291 0 00-5.285 5.286v7.254l-5.953 5.953a1.018 1.018 0 000 1.44l5.953 5.953v7.255a5.29 5.29 0 005.285 5.285 1.018 1.018 0 000-2.036 3.252 3.252 0 01-3.249-3.249v-7.676c0-.011-.006-.021-.006-.032a1.02 1.02 0 00-.072-.357 1.01 1.01 0 00-.221-.332l-5.53-5.531 5.531-5.531zm36.924-1.142v-7.254a5.291 5.291 0 00-5.285-5.286 1.018 1.018 0 000 2.036 3.253 3.253 0 013.249 3.25v7.675c0 .009.005.017.005.026a1.009 1.009 0 00.293.694l5.531 5.531-5.53 5.531a1.027 1.027 0 00-.221.332 1.02 1.02 0 00-.072.357c0 .011-.006.021-.006.032v7.676a3.252 3.252 0 01-3.249 3.249 1.018 1.018 0 000 2.036 5.29 5.29 0 005.285-5.285v-7.255l5.953-5.953a1.018 1.018 0 000-1.44l-5.953-5.953zm-17.553.772c-1.063-.651-2.493-.651-3.556 0-1.048.643-1.589 1.785-1.477 3.183l1.807 14.036c.094.739.704 1.275 1.449 1.275s1.354-.536 1.449-1.274l1.807-14.037.006-.05c.106-1.349-.435-2.492-1.484-3.134zM32 41.658l-1.231-9.554c-.046-.583.128-.999.517-1.238.207-.127.461-.19.714-.19s.507.063.714.19c.383.235.558.644.519 1.216L32 41.659zm0-15.356a3.057 3.057 0 003.054-3.054v-1.244c0-1.684-1.37-3.054-3.054-3.054s-3.054 1.37-3.054 3.054v1.244A3.057 3.057 0 0032 26.302zm-1.018-4.298c0-.561.457-1.018 1.018-1.018s1.018.457 1.018 1.018v1.244c0 .561-.457 1.018-1.018 1.018s-1.018-.457-1.018-1.018v-1.244z" />
    </svg>

  
  );
}


export default API;