import React from 'react';
import './invoiceView.scss';
import { Image, Typography, Table } from 'antd';
import KoDateFormatter from 'packages/dateformat';
const { Title, Paragraph, Text } = Typography;

const KoInvoiceView = React.forwardRef((props, ref) => {
  const { currentRecord, WorkspaceDetails } = props;

  var colors = {
    layout : "#f4f6f8",
    primaryDark:"#004b50",
    hover:"rgba(145 158 171 / 0.08)"
  };

  const columns = [
    {
      "dataIndex": "items",
      "editable": true,
      "key": "items",
      "placeholder": "Item & Description",
      "title": "Item & Description",
      "widget": "text"
    },
    {
      "dataIndex": "unit",
      "editable": true,
      "key": "unit",
      "placeholder": "Ex: Day",
      "title": "Unit",
      "widget": "options",
      "width": 110
    },
    {
      "dataIndex": "quantity",
      "editable": true,
      "key": "quantity",
      "placeholder": "Quantity",
      "title": "Quantity",
      "widget": "number",
      "width": 130
    },
    {
      "dataIndex": "rate",
      "editable": true,
      "key": "rate",
      "placeholder": "Rate",
      "title": "Rate",
      "widget": "number",
      "width": 120
    },
    {
      "dataIndex": "amount",
      "editable": true,
      "key": "rate",
      "placeholder": "Rate",
      "title": "Amount",
      "widget": "number",
      "width": 120
    }
  ];

  return (
    <div  style={{borderRadius:"0.4rem",padding:"0.5rem 3rem 3rem ",background:"#FFFFFF",height:"calc(100% - 7rem)"}} >
      <div style={{background:colors.layout,display:"grid",justifyContent:"center",padding:"3rem",height:"100%",overflow:"auto"}}>
        <div style={{width:"79.4rem",height:"100%",background:"#FFFFFF"}} ref={ref}>
          <div style={{display:"grid",gridTemplateColumns:"10rem 1fr",gap:"2rem",padding:"0 4rem"}}>
            <div style={{display:"grid",alignItems:"center",background:"#DFE3E8",height:"10rem",width:"10rem",justifyContent:"center"}}>
              <Image width={90} src={'https://qubernet.com/assets/images/logo.svg'} />
            </div>
            <div style={{display:"grid",justifyContent:"right",alignItems:"center"}}>
              <h2 style={{fontSize:"4.4rem",letterSpacing:"0.3rem",color:colors.primaryDark}}>INVOICE</h2>
            </div>
          </div>
          <div style={{display:"grid",gridTemplateColumns:"1fr 2fr",padding:"3rem 4rem 0"}}>
            <div>
              <div style={{borderBottom:"1px solid #DFE3E8",height:"10.5rem"}}>
                <Title level={5} style={{textTransform:"uppercase",color:"#141A21",fontSize:"1.2rem"}}>INVOICE TO</Title>
                <Title style={{textTransform:"uppercase",color:"#141A21",fontSize:"1.9rem"}} level={5}>{WorkspaceDetails?.name}</Title>
                <Paragraph>{currentRecord?.to}</Paragraph>
              </div>
              <div style={{paddingTop:"1rem"}}>
                <Text >Contact Person</Text>
                <div style={{display:"grid"}}>
                  <div style={{display:"grid",gridTemplateColumns:"5rem 2fr"}}>
                    <Text style={{color:"#454F5B"}}> Phone</Text>
                    <Text style={{color:"#454F5B"}} > : 12943671293691236</Text>
                  </div>
                  <div style={{display:"grid",gridTemplateColumns:"5rem 2fr",justifyContent:"left"}}>
                    <Text style={{color:"#454F5B"}} >Email</Text>
                    <Text style={{color:"#454F5B"}} > : kamal@qubernet.com</Text>
                  </div>
                </div>
              </div>
            </div>
            <div style={{display:"grid",justifyContent:"right"}}>
              <div style={{height:"10.5rem"}}>
                <div style={{display:"grid",gridTemplateColumns:"10rem 2fr",justifyContent:"left"}}>
                  <Text style={{color:"#454F5B"}} >Invoice No</Text>
                  <Text style={{color:"#454F5B"}} > : {currentRecord?.invoiceNo}</Text>
                </div>
                {/* <div style={{display:"grid",gridTemplateColumns:"10rem 2fr",justifyContent:"left"}}>
                  <Text style={{color:"#454F5B"}} >Invoice Name</Text>
                  <Text style={{color:"#454F5B"}} > : #23421342314</Text>
                </div> */}
                <div style={{display:"grid",gridTemplateColumns:"10rem 2fr",justifyContent:"left"}}>
                  <Text style={{color:"#454F5B"}} >Invoice Date</Text>
                  <Text style={{color:"#454F5B"}} > :
                    <KoDateFormatter format='DD-MMM-YYYY' data={currentRecord?.invoiceDate}/>
                  </Text>
                </div>
              </div>
              <div style={{paddingTop:"1rem", display:"grid",gap:"0.3rem"}}>
                <Text>Payment Method</Text>
                <div style={{display:"grid",gap:"0.1rem"}}>
                  <div style={{display:"grid",gridTemplateColumns:"10rem 2fr",justifyContent:"left"}}>
                    <Text style={{color:"#454F5B"}} >Bank</Text>
                    <Text style={{color:"#454F5B"}} > : Karur Vysya Bank</Text>
                  </div>
                  <div style={{display:"grid",gridTemplateColumns:"10rem 2fr",justifyContent:"left"}}>
                    <Text style={{color:"#454F5B"}} >Account Name</Text>
                    <Text style={{color:"#454F5B"}} > : Qubernet Technologies</Text>
                  </div>
                  <div style={{display:"grid",gridTemplateColumns:"10rem 2fr",justifyContent:"left"}}>
                    <Text style={{color:"#454F5B"}} >Account Id</Text>
                    <Text style={{color:"#454F5B"}} > : 23432432423423423</Text>
                  </div>
                  <div style={{display:"grid",gridTemplateColumns:"10rem 2fr",justifyContent:"left"}}>
                    <Text style={{color:"#454F5B"}} >IFSC Code</Text>
                    <Text style={{color:"#454F5B"}} > : KVBL8924D</Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {currentRecord && Object.keys(currentRecord?.lineItems).map((fid, index) => (
            <div key={fid} style={{padding:"4rem 4rem 0"}}>
              <Table pagination={false} dataSource={currentRecord?.lineItems[fid]} columns={columns} />
              <div style={{display:"grid",gap:"1rem",paddingTop:"2rem"}}>
                {currentRecord?.summary[fid] &&
                <div style={{display:"grid",gap:"1rem",gridAutoFlow:"row"}}>
                  <div style={{display:"grid",gap:"1rem",justifyContent:"right",alignItems:"center"}}>
                    <div style={{display:"grid",gap:"1rem",justifyContent:"left",alignItems:"center",gridTemplateColumns:"max-content 10rem"}}>
                      <Text style={{fontWeight:"700 !important"}}>Sub Total</Text>
                      <Text style={{textAlign:"right",padding:"0 11px",fontWeight:"700 !important"}}>{currentRecord?.summary[fid]?.subTotal.toFixed(2)}</Text>
                    </div>
                  </div>
              
                  {currentRecord?.summary[fid]?.tax?.map((tax)=>
                    <div key={tax.value} style={{display:"grid",justifyContent:"right",alignItems:"center",gap:"1rem"}}>
                      <div style={{display:"grid",gap:"1rem",justifyContent:"left",alignItems:"center",gridTemplateColumns:"max-content 10rem"}}>
                        <Text style={{color:"#919EAB",fontSize:"1.2rem"}}>Tax {tax.label}</Text>
                        <Text style={{textAlign:"right",padding:"0 11px",color:"#919EAB",fontSize:"1.2rem"}}>+ {tax.amount ? tax.amount.toFixed(2) : '0.00'}</Text>
                      </div>
                    </div>
                  )}
                 
                  {currentRecord?.summary[fid]?.discount?.map((discount)=>
                    <div key={discount.value} style={{display:"grid",justifyContent:"right",alignItems:"center",gap:"1rem"}}>
                      <div style={{display:"grid",gap:"1rem",justifyContent:"left",alignItems:"center",gridTemplateColumns:"max-content 10rem"}}>
                        <Text style={{color:"#919EAB",fontSize:"1.2rem"}} >Discount {discount.label}</Text>
                        <Text style={{textAlign:"right",padding:"0 11px",color:"#919EAB",fontSize:"1.2rem"}}>- {discount.amount ? discount.amount.toFixed(2) : '0.00'}</Text>
                      </div>
                    </div>
                  )}
                 
                  <div style={{display:"grid",justifyContent:"right",alignItems:"center",gap:"1rem"}}>
                    <div style={{display:"grid",gap:"1rem",justifyContent:"left",alignItems:"center",gridTemplateColumns:"max-content 10rem"}}>
                      <Text style={{color:"#919EAB",fontSize:"1.2rem"}} >Shipping Charge</Text>
                      <Text style={{textAlign:"right",padding:"0 11px",color:"#919EAB",fontSize:"1.2rem"}}>+ {currentRecord?.summary[fid]?.shippingCharge.toFixed(2)}</Text>
                    </div>
                  </div>
                  <div style={{display:"grid",gap:"1rem",gridTemplateColumns:"max-content 1fr",alignItems:"center"}}>
                    <div>&nbsp;</div>
                    <div style={{display:"grid",justifyContent:"right",alignItems:"center",gap:"1rem",width:"max-content",justifySelf:"right",padding:"0rem",borderRadius:"0.4rem",margin:"1rem 0",background:colors.hover,border:`2px solid ${colors.primaryDark}`}}>
                      <div style={{display:"grid",gap:"0.5rem",justifyContent:"left",alignItems:"center",gridTemplateColumns:"20rem max-content"}}>
                        <label style={{color:"#FFF",fontWeight:"500",background:colors.primaryDark,width:"max-content",padding:"1rem"}}>Total (INR)</label>
                        <Text style={{textAlign:"right",padding:"0 11px",color:"#141A21",fontSize:"1.2rem",fontWeight:"500"}} >+ {currentRecord?.summary[fid]?.total.toFixed(2)}</Text>
                      </div>
                    </div>
                  </div>
                </div>
                }
              </div>
            </div>
          ))}
          {currentRecord?.invoiceNote && <div style={{maxWidth:"50%",padding:"3rem 0"}}>
            <Title level={5}>Notes</Title>
            <Paragraph style={{color:"#919EAB",fontSize:"1.2rem",margin:"0rem"}}>{currentRecord?.invoiceNote}</Paragraph>
          </div>}
          <div style={{marginTop:"4rem",padding:"2rem",background:colors.primaryDark,textAlign:"center"}}>
            <Paragraph style={{color:"#919EAB",fontSize:"1.2rem",margin:"0rem"}}>{'qubernet private ltd'}</Paragraph>
            <Paragraph style={{color:"#919EAB",fontSize:"1.2rem",margin:"0rem"}}>{currentRecord?.from}</Paragraph>
            <Paragraph style={{color:"#919EAB",fontSize:"1.2rem",margin:"0rem"}}>{'growth@gmail.com / 98472319469234'}</Paragraph>
          </div>
        </div>
      </div>
    </div>
  );
});
export default KoInvoiceView;
