import React, { useState, useEffect } from 'react';
import styles from './ticketsOverview.module.scss';
import { KoLoader, KoTable, KoTitle } from 'packages';
import { AssignedTicketsCol, discriptionTicketsCol, dueCountCol, ISSUE_STATUS_COLUMNS } from '../constant';
import { DashbordAPI } from '../dashboard.service';
export default function TicketsOverview() {
  const [loading, setLoading] = useState(false);
  const [activeTicketCount, setActiveTicketCount] = useState([]);
  const [ticketDescriptionCount, setTicketDescriptionCount] =  useState([]);
  const [ticketCriteriaCount, setTicketCriteriaCount] =  useState([]);
  const [ticketStatusCount, setTicketStatusCount] =  useState([]);
  const [ticketOverDueCount ] =  useState([]);
  const [ticketsByDateCount, setTicketsByDateCount] =  useState([]);
  

  useEffect(() => {
    getActiveTicketCount();
    getTicketDescriptionCount();
    getTicketCriteriaCount();
    getTicketStatusCount();
    // getTicketOverDueCount();
    getTicketsByDateCount();
  }, []);

  const getTicketsByDateCount = () => {
    setLoading(true);
    DashbordAPI.getTicketsByDateCount().then(
      (data) => {
        setTicketsByDateCount(data?.tickets || []);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };
  const getActiveTicketCount = () => {
    setLoading(true);
    DashbordAPI.getActiveTicketCount().then(
      (data) => {
        setActiveTicketCount(data);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };


  // const getTicketOverDueCount = () => {
  //   setLoading(true);
  //   DashbordAPI.getTicketOverDueCount().then(
  //     (data) => {
  //       setTicketOverDueCount(data);
  //       setLoading(false);
  //     },
  //     (err) => {
  //       setLoading(false);
  //     }
  //   );
  // };

  const getTicketDescriptionCount = () => {
    setLoading(true);
    DashbordAPI.getTicketDescriptionCount().then(
      (data) => {
        setTicketDescriptionCount(data);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const getTicketCriteriaCount = () => {
    setLoading(true);
    DashbordAPI.getTicketCriteriaCount().then(
      (data) => {
        setTicketCriteriaCount(data);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const getTicketStatusCount = () => {
    setLoading(true);
    DashbordAPI.getTicketStatusCount().then(
      (data) => {
        setTicketStatusCount(data?.users);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  return (
    <div className={styles.wrapper}>
      {loading && <KoLoader/>}
      <div className={styles.content}>
        <div className={'tableCard'}>
          <KoTitle text={'Assigned Tickets'} level={5} className={styles.panelHeaderTitle} />
          <KoTable data={{rows: activeTicketCount ? activeTicketCount : [], columns:AssignedTicketsCol }} />
        </div>
        <div className={'tableCard'}>
          <KoTitle text={`Without Ticket Descriptions`} level={5} className={styles.panelHeaderTitle} />
          <KoTable data={{rows: ticketDescriptionCount?.description ? ticketDescriptionCount?.description : [] , columns:discriptionTicketsCol }} />
        </div>
        <div className={'tableCard'}>
          <KoTitle text={`Without Ticket Criteria`} level={5} className={styles.panelHeaderTitle} />
          <KoTable data={{rows: ticketCriteriaCount?.criteria ? ticketCriteriaCount?.criteria : [], columns:discriptionTicketsCol }} />
        </div>
      </div>
      <div className={'tableCard'}>
        <KoTitle text={`Ticket count by issue type`} level={5} className={styles.panelHeaderTitle} />
        <KoTable data={{rows: ticketStatusCount ? ticketStatusCount : [], columns:ISSUE_STATUS_COLUMNS }} />
      </div>
      <div className={`${styles.content} ${styles.secondContent}`}>
        <div className={'tableCard'}>
          <KoTitle text={'Overdue Tickets by assignee'} level={5} className={styles.panelHeaderTitle} />
          <KoTable data={{rows: ticketOverDueCount ? ticketOverDueCount : [], columns:dueCountCol }} />
        </div>
        <div className={'tableCard'}>
          <KoTitle text={`Tickets by date filled count`} level={5} className={styles.panelHeaderTitle} />
          <KoTable data={{rows: ticketsByDateCount ? ticketsByDateCount : [], columns:dueCountCol }} />
        </div>
      </div>
    </div>
  );
}
