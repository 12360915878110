import React, { useState, useEffect } from 'react';
import MessageBubble from './messageBubble';

import styles from './chat.module.scss';

const ChatBox = ({ messages, source, users, currentUser, updateMessage, deleteMessage, handleSendReaction }) => {

  const [messageList, setMessageList] = useState([]);

  useEffect(() => {
    if (messages) {
      const messagesFull = messages.filter(
        (item, index, self) =>
          self.findIndex((obj) => obj.messageId === item.messageId) === index
      );

      const sortedMessages = messagesFull.sort(
        (a, b) => new Date(a?.dateTime) - new Date(b?.dateTime)
      );

      setMessageList(sortedMessages);
    }
  }, [messages]);

  const sortedMessages = [...messageList].sort((a, b) => new Date(a?.dateTime) - new Date(b?.dateTime));
  return (
    <div className={styles.chatBox}>
      {sortedMessages.reduce((acc, msg, index) => {
        const messageDate = new Date(msg?.dateTime).toLocaleDateString();
        if (index === 0 || messageDate !== new Date(sortedMessages[index - 1]?.dateTime).toLocaleDateString()) {
          acc.push(
            messageDate !== 'Invalid Date' && <div key={index}>
              <div className={styles.dateWrapper}>
                <div className={styles.date}>{messageDate}</div>
              </div>
            </div>
          );
        }
        acc.push(
          <MessageBubble
            data={msg}
            index={index}
            source={source}
            users={users}
            messages={messages}
            currentUser={currentUser}
            updateMessage={updateMessage}
            deleteMessage={deleteMessage}
            handleSendReaction={handleSendReaction}
          />
        );
        return acc;
      }, [])}
    </div>
  );
};

export default ChatBox;
