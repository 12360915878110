import React from "react";
import { useSelector } from "react-redux";
import KoAvatar from "packages/avatar";
import styles from './members.module.scss';

function Members(props) {
  const users = useSelector(state => state?.users);
  let matchedRecords = users['data'].filter(record => props.data?.includes(record.userId));

  let data = matchedRecords.map(({ profile, userId, logo }) => ({ profile, userId, logo }));

  const { label, size } = props;
  
  return (
    <div className={`${label ? styles.inputField : styles.membersWrapper}`}>
      <KoAvatar size={size ? size : 22} options={data} isProfile={true}/>
    </div>
  );
}
export default Members;
