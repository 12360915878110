
import React from "react";
function Send(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_130_171)">
        <path d="M3.53899 0.259895L3.50099 0.239895C2.56499 -0.194105 1.48099 -0.0251051 0.737993 0.670895C0.0409929 1.32289 -0.182007 2.27289 0.186993 3.21689L5.11999 12.0019L0.246993 20.8529C-0.090007 21.7319 0.135993 22.6819 0.833993 23.3319C1.29899 23.7659 1.89599 23.9929 2.51399 23.9929C2.88799 23.9929 3.26899 23.9099 3.63499 23.7389L24.003 11.9989L3.53899 0.259895ZM1.08999 2.79189C0.897993 2.2939 1.02199 1.7739 1.42099 1.40089C1.64999 1.1869 2.01499 1.00089 2.43899 1.00089C2.63599 1.00089 2.84599 1.0419 3.06099 1.1389L21.124 11.4999H5.98499L1.08999 2.79189ZM3.17399 22.8529C2.61199 23.1149 1.96199 23.0159 1.51599 22.6009C1.11599 22.2279 0.990993 21.7079 1.15199 21.2729L5.98899 12.5009H21.133L3.17399 22.8529Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_130_171">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}


export default Send;