const theme = {
  "token": {
    "colorPrimary": "#004b50",
    "colorInfo": "#004b50",
    "fontSize": 13,
    "borderRadius": 4,
    "colorError": "#ff5630",
    "colorSuccess": "#22c55e",
    "colorWarning": "#ffab00",
    "colorTextBase": "#1c252e",
    "colorTextSecondary": "#637381",
    "colorText": "#1c252e",
    "colorBorder": "#e4e8eb",
    "colorBorderSecondary": "#e4e8eb",
    "colorBgMask": "#1c252e14",
    "colorBgLayout": "#ffffff",
    "boxShadow": "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) -20px 20px 40px -4px",
    "boxShadowSecondary": "rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) -20px 20px 40px -4px"
  },
  "components": {
    "Menu": {
      "itemHoverColor": "rgb(99, 115, 129)",
      "itemHoverBg": "rgba(145, 155, 171, 0.08)",
      "itemColor": "rgb(99, 115, 129)",
      "itemSelectedBg": "rgba(0, 167, 111, 0.1)",
      "itemSelectedColor": "rgb(0, 167, 111)",
      "itemDisabledColor": "rgb(145, 158, 171)",
      "itemActiveBg": "rgba(0, 167, 111, 0.1)"
    },
    "Select": {
      "optionSelectedBg": "rgba(0, 167, 111, 0.08)",
      "optionSelectedColor": "rgb(0, 167, 111)",
      "optionActiveBg": "rgba(145, 158, 171, 0.08)",
      "optionPadding": "5px 12px",
      "borderRadius": 4,
      "borderRadiusLG": 4,
      "borderRadiusXS": 4,
      "colorBgContainerDisabled": "rgb(255, 255, 255)",
      "colorTextDisabled": "rgb(166, 176, 187)",
      "colorTextPlaceholder": "rgb(145, 158, 171)",
      "activeShadow": "0",
      "errorActiveShadow": "0",
      "warningActiveShadow": "0",
      "algorithm": true
    },
    "Table": {
      "headerBorderRadius": 4,
      "borderRadius": 4,
      "lineType": "dashed",
      "padding": 8,
      "borderColor": "rgba(145, 158, 171, 0.2)",
      "headerBg": "rgb(244, 246, 248)",
      "headerColor": "rgb(99, 115, 129)",
      "rowHoverBg": "rgb(247 247 248)",
      "rowSelectedBg": "rgba(0, 120, 103, 0.04)",
      "rowSelectedHoverBg": "rgba(0, 120, 103, 0.08)",
      "colorText": "rgb(28, 37, 46)",
      "headerSortActiveBg": "rgba(0, 120, 103, 0.08)",
      "headerSortHoverBg": "rgba(0, 120, 103, 0.08)",
      "bodySortBg": "rgba(0, 120, 103, 0.08)",
      "footerBg": "rgb(244, 246, 248)",
      "algorithm": true
    },
    "Tabs": {
      "colorBorder": "rgb(244, 246, 248)"
    },
    "Typography": {
      "colorText": "rgb(28, 37, 46)",
      "colorTextHeading": "rgb(28, 37, 46)",
      "colorTextDescription": "rgb(28, 37, 46)",
      "colorTextDisabled": "rgb(145, 158, 171)",
      "fontSizeHeading1": 22,
      "lineHeightHeading1": 1.33,
      "fontWeightStrong": 600,
      "fontSizeHeading2": 20,
      "fontSizeHeading3": 18,
      "fontSizeHeading4": 16,
      "fontSizeHeading5": 14,
      "lineHeightHeading2": 1.5,
      "lineHeightHeading3": 1.5,
      "lineHeightHeading4": 1.5,
      "lineHeightHeading5": 1.56,
      "fontSize": 13,
      "lineHeight": 1.56,
      "titleMarginTop": "",
      "titleMarginBottom": "",
      "marginXS": 0,
      "marginXXS": 0,
      "paddingSM": 0
    },
    "Input": {
      "algorithm": true,
      "colorBgContainerDisabled": "rgb(255, 255, 255)",
      "colorTextDisabled": "rgb(166, 176, 187)",
      "colorTextPlaceholder": "rgb(145, 158, 171)",
      "activeShadow": "0",
      "errorActiveShadow": "0",
      "warningActiveShadow": "0"
    },
    "Layout": {
      "triggerBg": "rgb(231, 242, 236)",
      "lightTriggerColor": "rgb(231, 242, 236)",
      "bodyBg": "rgb(243, 246, 248)",
      "headerBg": "rgb(29, 35, 42)"
    },
    "Pagination": {
      "controlHeight": 28,
      "itemSize": 28,
      "controlHeightLG": 28,
      "controlHeightSM": 28,
      "itemSizeSM": 28,
      "lineHeight": 1.56
    },
    "Form": {
      "labelColor": "rgb(99, 115, 129)",
      "labelRequiredMarkColor": "rgb(255, 86, 48)",
      "algorithm": true,
      "lineHeight": 1.56,
      "controlHeightLG": 32,
      "controlHeightSM": 32,
      "itemMarginBottom": 15
    },
    "InputNumber": {
      "colorTextDisabled": "rgb(166, 176, 187)",
      "colorBgContainerDisabled": "rgb(255, 255, 255)",
      "colorTextPlaceholder": "rgb(145, 158, 171)",
      "lineHeight": 1.56,
      "controlHeightLG": 32,
      "activeShadow": "0",
      "errorActiveShadow": "0",
      "warningActiveShadow": "0"
    },
    "Mentions": {
      "colorTextDisabled": "rgb(166, 176, 187)",
      "colorTextPlaceholder": "rgb(145, 158, 171)",
      "colorBgContainerDisabled": "rgb(255, 255, 255)",
      "lineHeight": 1.56,
      "lineHeightLG": 1.56,
      "activeShadow": "0",
      "errorActiveShadow": "0",
      "warningActiveShadow": "0"
    },
    "Radio": {
      "colorTextDisabled": "rgb(166, 176, 187)",
      "activeShadow": "0",
      "errorActiveShadow": "0",
      "warningActiveShadow": "0"
    },
    "Collapse": {
      "headerBg": "rgb(251, 251, 252)",
      "colorTextDisabled": "rgb(166, 176, 187)",
      "lineHeight": 1.56
    },
    "Tooltip": {
      "controlHeight": 28,
      "lineHeight": 1.56
    },
    "Alert": {
      "colorInfoBg": "rgb(0, 185, 217)",
      "colorInfo": "rgb(255, 255, 255)",
      "colorInfoBorder": "rgb(0, 185, 217)"
    },
    "Drawer": {
      "colorBgMask": "rgba(28, 37, 46, 0.48)"
    },
    "Modal": {
      "colorBgMask": "rgba(28, 37, 42, 0.48)",
      "fontSize": 16,
      "fontSizeHeading5": 16,
      "fontSizeLG": 16,
      "lineHeight": 1.56,
      "lineHeightHeading5": 1.56
    },
    "DatePicker": {
      "activeShadow": "0",
      "errorActiveShadow": "0",
      "warningActiveShadow": "0",
      "colorBgContainerDisabled": "rgb(255, 255, 255)"
    },
    "Button": {
      "primaryShadow": "0",
      "defaultShadow": "0",
      "dangerShadow": "0"
    },
    "Dropdown": {
      "controlItemBgActive": "rgba(0, 167, 111, 0.08)",
      "controlItemBgActiveHover": "rgba(0, 167, 111, 0.16)",
      "optionSelectedBg": "rgba(0, 167, 111, 0.08)",
      "optionSelectedColor": "rgb(0, 167, 111)",
      "optionActiveBg": "rgba(145, 158, 171, 0.08)",
      "optionPadding": "5px 12px",
      "borderRadius": 4,
      "borderRadiusLG": 4,
      "borderRadiusXS": 4,
      "colorBgContainerDisabled": "rgb(255, 255, 255)",
      "colorTextDisabled": "rgb(166, 176, 187)",
      "colorTextPlaceholder": "rgb(145, 158, 171)",
      "activeShadow": "0",
      "errorActiveShadow": "0",
      "warningActiveShadow": "0",
      "algorithm": true
    },
    "TimePicker": {
      "controlItemBgActive": "rgba(0, 167, 111, 0.08)",
      "controlItemBgActiveHover": "rgba(0, 167, 111, 0.16)",
      "optionSelectedBg": "rgba(0, 167, 111, 0.08)",
      "optionSelectedColor": "rgb(0, 167, 111)",
      "optionActiveBg": "rgba(145, 158, 171, 0.08)",
      "optionPadding": "5px 12px",
      "borderRadius": 4,
      "borderRadiusLG": 4,
      "borderRadiusXS": 4,
      "colorBgContainerDisabled": "rgb(255, 255, 255)",
      "colorTextDisabled": "rgb(166, 176, 187)",
      "colorTextPlaceholder": "rgb(145, 158, 171)",
      "activeShadow": "0",
      "errorActiveShadow": "0",
      "warningActiveShadow": "0",
      "algorithm": true
    },
    "Transfer": {
      "controlItemBgActive": "rgba(0, 167, 111, 0.08)",
      "controlItemBgActiveHover": "rgba(0, 167, 111, 0.16)",
      "optionSelectedBg": "rgba(0, 167, 111, 0.08)",
      "optionSelectedColor": "rgb(0, 167, 111)",
      "optionActiveBg": "rgba(145, 158, 171, 0.08)",
      "optionPadding": "5px 12px",
      "borderRadius": 4,
      "borderRadiusLG": 4,
      "borderRadiusXS": 4,
      "colorBgContainerDisabled": "rgb(255, 255, 255)",
      "colorTextDisabled": "rgb(166, 176, 187)",
      "colorTextPlaceholder": "rgb(145, 158, 171)",
      "activeShadow": "0",
      "errorActiveShadow": "0",
      "warningActiveShadow": "0",
      "algorithm": true
    },
    "Segmented": {
      "itemSelectedBg": "rgba(0,167,111,0.1)",
      "itemSelectedColor": "rgb(0,167,111)",
      "itemActiveBg": "rgba(0,167,111,0.1)",
      "itemColor": "rgb(99,115,129)",
      "itemHoverColor": "rgb(99,115,129)",
      "itemHoverBg": "rgba(145,155,171,0.08)",
      "trackBg": "rgb(255,255,255)"
    }
  }
};

export {theme};
