import React from "react";
import isString from 'lodash/isString';
import { useSelector } from "react-redux";
import styles from './departments.module.scss';

function Departments(props) {
  const departments = useSelector(state => state?.departments);
  let { data } = props;
 
  if(isString(data)){
    let matchedRecords = departments['data'].filter(record => [props.data]?.includes(record.departmentId));
    data = matchedRecords.map(({ name, departmentId }) => ({ name, departmentId }));
  }

  return (
    <div className={styles.groupsWrapper}>
      {data.map((group)=>
        <div key={group.departmentId} className={`${styles.status} ${styles[group?.name? group?.name?.charAt(0).toLowerCase() :'a']}`}>
          <div className={styles.text}>{group?.name}</div>
        </div>
      )}
    </div>
  );
}
export default Departments;
