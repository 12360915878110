import React from "react";
function Open(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" width={width} height={height}>
      <path
        d="M805.6 924.5H218.4c-65.6 0-118.9-53.3-118.9-118.9V217.4c0-65 52.9-117.9 117.9-117.9h280.1v30H217.4c-48.5 0-87.9 39.4-87.9 87.9v588.2c0 49 39.9 88.9 88.9 88.9h587.2c49 0 88.9-39.9 88.9-88.9V526.5h30v279.1c0 65.6-53.3 118.9-118.9 118.9zM924 335h-30V130H689v-30h235z"
        fill="var(--palette-gray-10)" className="color000 svgShape"></path>
      <path d="m898.958 103.896 21.213 21.214-396.694 396.68-21.213-21.214z" fill="var(--palette-gray-10)" className="color000 svgShape">
      </path>
    </svg>
  );
}


export default Open;