import React from "react";

import styles from './members.module.scss';
import KoDP from "packages/dp";
import { KoText } from "packages/typography";

function StaicMembers(props) {
  const { data } = props;
  
  return (
    <div className={`profileChar ${styles.membersWrapper}`}>
      <div className={`${styles.circle}  ${styles[data ?
        data?.firstName?.charAt(0).toLowerCase() : 'a']}`}>
        {data?.['logo'] ?
          <KoDP className={styles.btn} shape={'circle'} logo={data?.logo} width={data?.logo?.[0]?.name ? 20 : 25} height={data?.logo?.[0]?.name ? 20 : 25} />
          :
          <KoText className={`${styles.letter}`} text={data?.firstName?.charAt(0)} />
        }
      </div>
      <div className={styles.memberInfo}>
        <KoText text={`${data?.firstName} ${data?.lastName}`} className={styles.memberName} />
        <KoText text={`${data?.email}`} className={styles.email} />
      </div>
    </div>
  );
}
export default StaicMembers;
