
import { Checkbox, KoColorPicker, KoDate, KoForm, KoFormBuilder, KoInput, KoSegmented, KoSelect, KoText, KoTextArea, KoTimePicker, KoUserSelect } from 'packages';
import { useEffect, useState } from 'react';
import SettingsTableList from './settingslist';
import styles from './workspace.module.scss';
import { workspaceAPI } from './workspace.service';
import { useSelector } from 'react-redux';
import { removeAssignees, updateAssignees } from 'shared/store/wosAssigneesSlice';
import { useDispatch } from 'react-redux';

export default function WorkspaceSettings({form, handleFinish, initialValues, workspaceList}) {
  const users = useSelector(state => state?.users);
  const groups = useSelector(state => state?.groups);
  const [currentStep, setCurrentStep ] = useState('general');
  const [custmersList, setDataList] = useState();
  const [currentRecord,setCurrentRecord] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    getClients();
  }, []);

  useEffect(() => {
    if (!initialValues || Object.keys(initialValues).length === 0) {
      form?.resetFields();
    } else {
      form?.setFieldsValue(initialValues);
    }
    getWorkspaceById(initialValues?.workspaceId);
  }, [initialValues, form]);

  const generateKey = (name) => {
    const nameWords = name.trim().split(' ');

    if (nameWords.length === 1) {
      return nameWords[0].slice(0, 3).toUpperCase();
    } else {
      return nameWords.map(word => word.charAt(0)).join('').toUpperCase();
    }
  };

  const handleSubmit = (values) => {
    values['key'] = !initialValues ?  generateKey(values.name) : initialValues['key'];

    const teamUserIds = Array.isArray(values?.teams) ?
      values.teams.map(member => member.userId) :
      [];

    values['assignees'] = [
      ...new Set([
        ...(values?.assignees || initialValues?.assignees || []),
        ...teamUserIds
      ])
    ];


    const UpdatedValues = {
      ...initialValues,
      ...values
    };
    handleFinish(UpdatedValues);
  };

  const getClients = () => {
    workspaceAPI.getClients().then(
      (data) => {
        setDataList(data['customers']);
      },
      (err) => {
      }
    );
  };

  const getWorkspaceById=(id)=>{
    workspaceAPI.getWorkspaceById(id).then(
      (data) => {
        dispatch(removeAssignees());
        dispatch(updateAssignees(data['project'].teams));
        setCurrentRecord(data['project']);
      },
      (err) => {
      }
    );
    
  };

  const Assignees = ({ value, onChange }) =>
    <KoUserSelect value={value}  onChange={v => onChange(v)} users={users['data']} placeholder='Select assignees' />;
 
  const CarryForward = ({ value, onChange }) =>
    <KoSelect value={value}
      onChange={v => onChange(v)}
      showSearch
      placeholder='Select'
      options={[{
        label: 'Yes',
        value: true
      }, {
        label: 'No',
        value: false
      }]?.map((time) => ({
        label: time?.label,
        value: time?.value
      }))}
    />;

  const TypeofBilling = ({ value, onChange }) =>
    <KoSelect value={value}
      onChange={v => onChange(v)}
      showSearch
      placeholder='Select Billing Type'
      options={[{
        label: 'Billable',
        value: 'billable'
      }, {
        label: 'Non-Billable',
        value: 'nonBillable'
      }]?.map((bill) => ({
        label: bill?.label,
        value: bill?.value
      }))}
    />;

  const Groups = ({ value, onChange }) =>

    <KoSelect mode="multiple" value={value}
      showSearch
      onChange={v => { onChange(v); }}
      placeholder='Select Group'
      options={groups['data']?.map((group) => ({
        label: group.name,
        value: group?.groupId
      }))}
    />;

  const SprintDate = ({ value, onChange }) =>
    <KoDate value={value}
      format={'DD-MMM-YYYY'} daysAgo={0}
      onChange={v => onChange(v)} />;

  const usedColors = new Set();

  const getRandomLightColor = () => {
    const randomChannelValue = () => Math.floor(Math.random() * 128) + 128;

    let color;

  
    do {
      const r = randomChannelValue();
      const g = randomChannelValue();
      const b = randomChannelValue();

    
      const toHex = (c) => {
        const hex = c.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
      };

      color = `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    } while (usedColors.has(color));

    usedColors.add(color);
   
    return color;
  };

  const WosColor = ({ value, onChange }) => {
  
    const defaultColor = value ? value : getRandomLightColor();
    const handleChange = (colorValue) => {
      const { r, g, b } = colorValue.metaColor;
      const hexColor = rgbToHex(r, g, b);
      onChange(hexColor);
    };
    return (
      <KoColorPicker value={defaultColor} onChange={handleChange} />
    );
  };

  const rgbToHex = (r, g, b) => {
    const toHex = (c) => {
      const hex = Math.round(c).toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    };
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
  };



  const STATUS = [{
    label: 'Active',
    key: 'active',
    value: 1
  },
  {
    label: 'Inactive',
    key: 'inactive',
    value: -1
  },
  {
    label: 'Archived',
    key: 'archived',
    value: 2
  },
  {
    label: 'Paused',
    key: 'paused',
    value: 3
  }];

  const Status = ({ value, onChange }) => (
    <KoSelect
      value={value}
      showSearch
      defaultValue={STATUS[0].value}
      onChange={v => onChange(v)}
      placeholder='Select status'
      options={STATUS}
      optionRender={(option) => {
        const label = option?.data?.label;
        return (
          <div className={styles.statusWrapper}>
            <div
              className={`${styles.status} ${styles[label]}`} />
            <KoText text={option.label} />
          </div>
        );
      }}
    />
  );
  
  const SettingsTable = ({ value, onChange }) =>
    <SettingsTableList form={form} initialValues={currentRecord} profile={users['data']} handleFinish={handleSubmit}/>;
  
  const Customer = ({ value, onChange }) =>
    <KoSelect value={value}
      showSearch
      onChange={v => { onChange(v); }}
      placeholder='Select customer'
      options={custmersList?.map((cus) => ({
        label: cus?.companyName,
        value: cus?.customerId
      }))}
    />;

  const Managers = ({ value, onChange }) =>
    <KoUserSelect value={value}  onChange={v => onChange(v)} users={users['data']} placeholder='Select assignees' mode={'multiple'} />;
 

  const Time = ({ value, onChange }) => {
    const handleChange = (v) => {
      const formattedValue = v instanceof Date ? v.toTimeString().slice(0, 5) : v;
      onChange(formattedValue);
    };

    const formattedValue = value ? (value instanceof Date ? value.toTimeString().slice(0, 5) : value) : '';

    return (
      <KoTimePicker
        changeOnScroll
        needConfirm={false}
        allowClear={false}
        value={formattedValue}
        format='HH:mm'
        onChange={handleChange}
      />
    );
  };

  const CheckBox = ({ value, onChange }) =>
    <Checkbox checked={value} onChange={v => onChange(v?.target?.checked)}>{`Help desk`}</Checkbox>;

  const publicWorkspaces = workspaceList?.filter(workspace => workspace.isPublic) || [];
  const canMakePublic = publicWorkspaces.length < 3;
  const showIsPublic = (canMakePublic ) || initialValues?.isPublic;

  const wizardMeta = {
    steps: [
      {
        title: 'Basic Details',
        formMeta: {
          columns: 6,
          formItemLayout: null,
          initialValues : currentRecord,
          colon: true,
          fields: [
            {
              key: 'color', label: 'Workspace Color', colSpan: 1, widget: WosColor
            },
            ...(showIsPublic
              ? [
                {
                  key: 'isPublic',
                  colSpan: 1,
                  label: 'Make public',
                  widget: CheckBox
                }
              ]
              : []),
            {
              key: 'customer', label: 'Customer', colSpan: 2,
              widget: Customer,
              rules: [
                {
                  required: true,
                  message: `Select a customer.`
                }
              ]
            },
            {
              key: 'name', label: 'Name', colSpan: 2,
              placeholder:'Enter project name',
              rules: [
                {
                  required: true,
                  message: `Enter new project name.`
                }
              ]
            },
            {
              key: 'managers', label: 'Project Managers', colSpan: 2,
              widget: Managers,
              forwardRef: true
            },
            {
              key: 'leads', label: 'Project Leads', colSpan: 2,
              widget: Managers,
              forwardRef: true
            },
            {
              key: 'groups', label: 'Groups', colSpan: 2,
              widget: Groups
            },
            {
              key: 'status', label: 'Status', colSpan: 2,
              widget: Status,
              forwardRef: true
            },
            {
              key: 'typeofBilling', label: 'Type of Billing', colSpan: 2,  widget: TypeofBilling
            },
            // {
            //   key: 'storyPoints', label: 'Story Points (1sp = 8 hours)',
            //   colSpan: 2, widget: () => <KoInput type="number" placeholder="Enter Story Points" />
            // },
            // {
            //   key: 'maxHours', label: 'Work Hours',
            //   colSpan: 2, widget: () => <KoInput type="number" placeholder="Enter Work Hours" />
            // },
            {
              key: 'workingDaysPerSprint', label: 'Working Days per Sprint', colSpan: 2,
              widget: () => <KoInput type="number" placeholder="Enter Working Days per Sprint" />
            },
            {
              key: 'defaultAssignee', label: 'Default Assignee for Tasks', colSpan: 2, widget: Assignees
            },
           
            {
              key: 'idealStartDate', placeholder: 'Enter Ideal Start Date', label: 'Ideal Start Date', colSpan: 2, widget: SprintDate
            },
            {
              key: 'idealEndDate', placeholder: 'Enter Ideal End Date', label: 'Ideal End Date', colSpan: 2, widget: SprintDate
            },
            {
              key: 'sprintAutoClose', placeholder: 'Enter Sprint Auto-Close', label: 'Sprint Auto-Close', colSpan: 2, widget: CarryForward
            },
           
            {
              key: 'time', placeholder: 'Time', label: 'Time', colSpan: 2, widget: Time
            },
            {
              key: 'carryForward', label: 'Carry Forward to Retrospective', colSpan: 2,  widget: CarryForward
            },
            {
              key: 'description', widget: KoTextArea, label: 'Description', colSpan: 6, placeholder: 'Enter project description'
            }
           
          ]
        }
      },
      {
        title: 'Assignee',
        formMeta: {
          columns: 6,
          formItemLayout: null,
          initialValues: currentRecord?.teams,
          colon: true,
          fields: [
            {
              key: 'teams', label: '', colSpan: 6,
              widget: SettingsTable,
              forwardRef: true
            }
          ]
        }
      }
     
    ]
  };
 
  const TAB_LIST = [
    {
      label: 'General',
      value: 'general'
    },
    {
      label: 'Add Team Members',
      value: 'assignee'
    }
    
  ];

  return (
    <div className={styles.wizardMeta}>
      <div>
        <KoSegmented
          options={TAB_LIST}
          value={currentStep}
          onChange={setCurrentStep}
        />
      </div>
      <KoForm form={form} layout="vertical" onFinish={handleSubmit}>
        {currentStep === 'general' && <div className={styles.workspaceSettings}>
          <KoFormBuilder
            form={form}
            meta={wizardMeta.steps[0]?.formMeta}
          />
        </div>}
        {currentStep === 'assignee' &&
        <div className={styles.workspaceAssignee}>
          <KoFormBuilder
            form={form}
            meta={wizardMeta.steps[1]?.formMeta}
          /></div>}
      </KoForm>
 
    </div>

  );
}
