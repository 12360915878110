import React, {useEffect, useCallback, useState} from 'react';
import { useSelector } from "react-redux";
import _find from 'lodash/find';
import { InvoicesAPI } from '../invoice.service';
import styles from './curd.module.scss';
import { CURRENCIES, KoDate, KoForm, KoFormBuilder, KoIcon, KoImage, KoLoader, KoNano, KoSelect, KoText, KoTitle, KoToast, Upload } from 'packages';
import LineItemEntryCrud from './lineItem';
import { getCookie } from 'shared/utils/cookies';

function InvoiceCrud({ form, handleFinish, initialValues }) {
  const [templates, setTemplates] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState();
  const [customerList, setCustomerList] = useState();
  const [customer, setCustomer]= useState();
  const [toAddress, setToAddress] = useState(initialValues?.to);
  const workspace = useSelector((state) => state.workspace);
  const accountById = useSelector((state) => state.accountById);
  const project = KoForm.useWatch('project', form);

  let workspaceList = workspace['data'];
  const account=accountById['data'];
  const fromAddress = `${account?.address?.addressLine1 ? account?.address?.addressLine1+', ' :''}
          ${account?.address?.addressLine2 ? account?.address?.addressLine2+', ' :''}
          ${account?.address?.city ? account?.address?.city+', ' :''}
          ${account?.address?.state ? account?.address?.state+', ' :''}
          ${account?.address?.country ? account?.address?.country+', ' :''}
          ${account?.address?.pincode ? account?.address?.pincode :''}`;
  

  useEffect(()=>{
    getTemplates();
    getClients();
  },[initialValues]);

  useEffect(() => {
    if (!initialValues || Object.keys(initialValues).length === 0) {
      form.resetFields();
    } else {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);



  const getClients=()=>{
    setLoading(true);
    InvoicesAPI.getClients().then(
      (data) => {
        setCustomerList(data['customers']);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  useEffect(()=>{
    let client = _find(workspaceList, function(cli) {
      return cli.workspaceId === project || initialValues?.project;
    });
    let obj = _find(customerList, function(cli) {
      return cli.customerId === client?.customer;
    });
    setToAddress(
      `${obj?.address?.addressLine1 ? obj?.address?.addressLine1+', ' :''}
          ${obj?.address?.addressLine2 ? obj?.address?.addressLine2+', ' :''}
          ${obj?.address?.city ? obj?.address?.city+', ' :''}
          ${obj?.address?.state ? obj?.address?.state+', ' :''}
          ${obj?.address?.country ? obj?.address?.country+', ' :''}
          ${obj?.address?.pincode ? obj?.address?.pincode :''}`
    );
    setCustomer(obj);

  },[project, initialValues, customerList]);


  const getTemplates = () => {
    setLoading(true);
    InvoicesAPI.getTemplates().then(
      (data) => {
        setTemplates(data['templates']);
        setCurrentTemplate(data['templates'][0]);
        setLoading(false);
      },
      (err) => {
      }
    );
  };

  const handleSubmit = useCallback(values => {
    handleFinish(values);
  });

  const handleEntryFinish = (values) => {
  };

  const updateCustomerAddress=()=>{
    let data = form.getFieldsValue();
    data['from']=fromAddress?.replace(/^\s+|\s+$/gm,'');
    data['to']= toAddress?.replace(/^\s+|\s+$/gm,'');
    form.setFieldsValue({ ...data });
  };


  const From = ({ value, onChange }) =>
    <div className={styles.address}>
      <KoText className={styles.titleValue}
        text={`${fromAddress}`} />
    </div>;

  const To = ({ value, onChange }) =>
    <div className={styles.address}>
      <KoText className={styles.titleValue}
        text={`${toAddress}`} />
    </div>;

  const Projects = ({ value, onChange }) =>
    <KoSelect value={value}
      showSearch
      onChange={v => onChange(v)}
      placeholder='Select project'
      onSelect={updateCustomerAddress}
      options={workspaceList?.map((project) => ({
        label: project?.name,
        value: project?.workspaceId
      }))} />;

  const Currency = ({ value, onChange }) =>
    <KoSelect value={value}
      showSearch
      onChange={v => onChange(v)}
      placeholder='Select currency'
      options={CURRENCIES?.map((currency) => ({
        label: currency?.code + '-' + currency?.name,
        value: currency?.code
      }))} />;

  
  const TaxName = ({ value, onChange }) =>
    <KoSelect value={value}
      showSearch
      mode="multiple"
      onChange={v => onChange(v)}
      placeholder='Select Tax'
      options={customer?.tax?.map((tax) => ({
        label: tax?.taxName,
        value: tax?.taxRate
      }))}/>;

  const DiscountName = ({ value, onChange }) =>
    <KoSelect value={value}
      showSearch
      mode="multiple"
      onChange={v => onChange(v)}
      placeholder='Select Discount'
      options={[]} />;

  const ItemTable = (defaultColumns, lineItems) =>
    <LineItemEntryCrud customer={customer} form={form} fields={defaultColumns} lineItems={lineItems} handleFinish={handleEntryFinish} />;
    


  const Date = ({ value, onChange }) =>
    <KoDate value={value} dateFormat={'DD-MMM-YYYY'}
      onChange={v => onChange(v)} />;



  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleFieldWidgets = (fields) => {
    return fields?.map(field => {
      let updatedField = { name: field?.key , ...field };

      switch (field.type) {
      case 'input':
        break;
      
      case 'divider':
        updatedField = {
          key: KoNano('lab'),
          colSpan: field.colSpan,
          render() {
            return (
              <></>
            );
          }
        };
        break;

      case 'date':
        updatedField.widget = Date;
        break;

      case 'select':
        switch (field.key) {
        case 'currency':
          updatedField.widget = Currency;
          break;
        case 'from':
          updatedField.widget = From;
          break;
        case 'to':
          updatedField.widget = To;
          break;
        case 'tax':
          updatedField.widget = TaxName;
          break;
        case 'discount':
          updatedField.widget = DiscountName;
          break;
        default:
          break;
        }
        break;
      case 'project':
        updatedField.widget = Projects;
        break;
      case 'textarea':
        updatedField.widget = 'textarea';
        break;

      case 'table':
        updatedField.widget = ItemTable;
        updatedField.children = {
          defaultColumns: field.defaultColumns,
          other: field?.lineItems,
          name:`${field?.key}`,
          key:`${field?.key}`,
          initialValues: initialValues
        };
        break;

      case 'fieldset':
        updatedField = {
          key: KoNano('lab'),
          colSpan: 6,
          render() {
            return (
              <fieldset>
                <legend>{field.label}</legend>
              </fieldset>
            );
          }
        };
        break;

      default:
        break;
      }

      return updatedField;
    });
  };


  const updatedFields = handleFieldWidgets(currentTemplate?.fields);
  const meta = {
    columns: 6,
    formItemLayout: null,
    colon: true,
    initialValues,
    fields: updatedFields
  };

  

  const uploadButton = (
    <div className={styles.dndArea}>
      {account?.logo?.length > 0   ?
        <KoImage preview={false} src={account?.logo[0].url}/>:
        <>
          <KoIcon name='ImagesOutlined'/>
          {isLoading && <KoLoader size='small'/>}
          <KoText className={styles.fileInfo} text='At least 256 x 256px PNG or JPG file'/>
        </>}
    </div>
  );

  const props = {
    name: 'files',
    action: `${process.env.REACT_APP_PROXY_HOST}/api/v1/org/upload`,
    data:{service:'finance', module:'invoice'},
    headers: {
      Authorization: `Bearer ${getCookie("accessToken")}`
    },
    onChange(info) {
      setLoading(true);
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'done') {
        KoToast.success(`${info.file.name} file uploaded successfully`);
        setLoading(false);
        form.submit();
      } else if (info.file.status === 'error') {
        setLoading(false);
        KoToast.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      KoToast.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      KoToast.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

   

  return (
    <div className={styles.wrapper}>
      {isLoading && <KoLoader/>}
      <div className={styles.invoiceTemplete}>
        <div className={styles.heading}>
          <KoTitle text={'Templates'} level={5}/>
        </div>
        <ul className={styles.card} >
          {templates?.map((items)=>
            <li onClick={()=>setCurrentTemplate(items)} key={items.templeteId}className={styles.content}>
              <div className={styles.image}>
                <KoIcon name='InvoiceTempleteTwoTone' width={30} height={30}/>
              </div>
              <KoText ellipsis={true} text={items.name} />
            </li>
          )}
        </ul>
      </div>
      <div className={styles.invoiceCrudWrapper}>
        <KoForm layout="vertical" onFinish={handleSubmit} form={form} onSubmit={handleSubmit}>
          <div className={styles.logoWrapper}>
            <KoForm.Item
              name={["logo"]}
              valuePropName={["logo"]}
              getValueFromEvent={normFile}
            >
              <Upload {...props}
                className={styles.upload}
                beforeUpload={beforeUpload}
                max={{ count: 1 }}
                showUploadList={false}
                showPreviewIcon={false}
                listType="picture-card"
              >
                {uploadButton}
              </Upload>
            </KoForm.Item>
          </div>
          <KoFormBuilder form={form}  meta={meta} />
        </KoForm>
      </div>
    </div>
  );
};

export default InvoiceCrud;