import React from "react";
function Country(props) {
  const { width, height } = props;
  return (
  
    <svg
      width={width}
      height={height}
      viewBox="-2.24 0 32.493 32.493"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g transform="translate(-675.26 -258.504)">
        <path d="M690.914 260.5a10.36 10.36 0 11-10.36 10.36 10.372 10.372 0 0110.36-10.36m0-2a12.36 12.36 0 1012.36 12.36 12.36 12.36 0 00-12.36-12.36z" />
        <path d="M692.246 287.1c-16.527 0-16.983-12.68-16.986-12.809a1 1 0 01.98-1.021 1.029 1.029 0 011.02.976c.012.443.464 10.854 14.986 10.854a1 1 0 010 2z" />
        <path transform="translate(683.253 284.76)" d="M0 0H2V5.237H0z" />
        <path d="M692.246 291H676.26a1 1 0 010-2h15.986a1 1 0 010 2zM698.792 279.659L694.6 278.1c-2.095-1.082-3.311-2.3-3.721-3.735-.517-1.817.451-3.439 1.091-4.511l.191-.321a.352.352 0 000-.4c-.447-.825-2.512-1.785-5.461-1.94a4.076 4.076 0 01-3.406-1.65 4.873 4.873 0 01-.3-4.024l1.88.678-.94-.339.942.335a2.932 2.932 0 00.083 2.248 2.242 2.242 0 001.842.754c3.439.182 6.232 1.354 7.114 2.985a2.335 2.335 0 01-.029 2.359l-.2.343c-.546.914-1.165 1.951-.884 2.936.242.851 1.138 1.685 2.661 2.48l4.016 1.484z" />
      </g>
    </svg>
  );
}
export default Country;