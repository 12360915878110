import React from "react";
function Reset(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width={width}
      height={height}>
      <path
        fill="#222"
        d="M57.727 16.617a2 2 0 01-1.98 1.737 2.042 2.042 0 01-.265-.017l-3.474-.46A24.493 24.493 0 1132 7.5a2 2 0 010 4 20.52 20.52 0 1016.742 8.684l-.19 4.405a2 2 0 01-1.996 1.914l-.088-.002a2 2 0 01-1.912-2.084l.401-9.313c.002-.03.01-.057.013-.086.002-.02 0-.04.002-.06l.001-.031v-.001c.005-.04.02-.08.027-.12.012-.06.025-.119.042-.176.008-.031.013-.062.023-.093.013-.037.034-.07.049-.107a1.98 1.98 0 01.071-.158c.016-.03.027-.062.044-.092.022-.037.05-.068.075-.104.029-.042.058-.084.09-.124.01-.013.018-.027.029-.039.014-.017.023-.037.039-.054.03-.034.066-.058.097-.09s.062-.058.094-.086.059-.06.092-.084c.04-.031.085-.054.128-.082l.09-.054c.037-.022.073-.048.112-.068.046-.022.093-.036.14-.055.033-.014.067-.025.102-.037.041-.014.08-.033.123-.044.045-.012.09-.017.136-.025s.09-.016.135-.022c.038-.005.075-.015.115-.017.04-.003.082.002.123.002.031 0 .06-.007.092-.006s.058.01.087.012c.015.002.03 0 .045.002s.03 0 .045.001h.007l8.782 1.165a1.999 1.999 0 011.72 2.246z"
      />
    </svg>
  );



}
export default Reset;