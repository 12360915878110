
import React, { useCallback, useState } from 'react';
import { KoAvatar, KoForm, KoFormBuilder, KoSelect, KoText, KoTextArea, KoUserSelect } from 'packages';
import { useSelector } from "react-redux";
import styles from './env.module.scss';
import Variable from './variable';

export default function EnvCurd({ form, handleFinish, initialValues }) {
  const groups= useSelector(state => state.groups);
  const wosAssignees = useSelector(state => state.wosAssignees);
  const [branchList] = useState([{ name: 'main', id: 'main' }, { name: 'dev', id: 'dev' }]);

  const handleSubmit = useCallback(values => {
    handleFinish(values);
  });

  const Branches = ({ value, onChange }) =>
    <KoSelect value={value}
      showSearch
      onChange={v => { onChange(v); }}
      placeholder='Select Branch'
      options={branchList?.map((branch) => ({
        label: branch?.name,
        value: branch?.id
      }))}
    />;

  // const Types = ({ value, onChange }) =>
  //   <KoSelect value={value}
  //     showSearch
  //     onChange={v => { onChange(v); }}
  //     placeholder='Select environment type'
  //     options={['Dev', 'Test', 'Stg', 'Uat', 'Prod']?.map((type) => ({
  //       label: type,
  //       value: type
  //     }))}
  //   />;


  const Managers = ({ value, onChange }) =>
    <KoSelect value={value}
      showSearch
      mode="multiple"
      onChange={v => { onChange(v); }}
      placeholder='Select project managers'
      options={wosAssignees[0]?.map((user) => ({
        label: (
          <div className={'userDpWithName'}>
            <KoAvatar inLine={'0rem'} size={20} options={[{ name: user?.profile?.firstName, logo: user?.profile?.logo }]} />
            <KoText text={user.profile?.firstName +' '+ user.profile?.lastName} />
          </div>
        ),
        value: user?.userId
      }))}
    />;

  const Leads = ({ value, onChange }) =>
    <KoUserSelect value={value} onChange={v => onChange(v)} users={wosAssignees[0]} placeholder='Select project Leads' mode='multiple'/>;
  
  
  const Assignees = ({ value, onChange }) =>
    <KoUserSelect value={value} onChange={v => onChange(v)} users={wosAssignees[0]} placeholder='Select assignees' mode='multiple'/>;
  
  
  const Groups = ({ value, onChange }) =>
    <KoSelect mode="multiple" value={value}
      onChange={v => onChange(v)}
      showSearch
      placeholder='Choose assignee groups'
      options={groups['data']?.map((group) => ({
        label: group.name,
        value: group?.groupId
      }))}
    />;

  const EnvTable = ({ value, onChange }) =>
    <Variable form={form} handleFinish={handleSubmit} />;

  const meta = {
    columns: 6,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      {
        key: 'name', placeholder: 'Enter environment name', label: 'Name', colSpan: 3, rules: [
          {
            required: true,
            message: `Enter Environment name.`
          },{
            pattern: new RegExp(/^[a-zA-Z]{1,}$/),
            message: `Enter valid Environment name.`
          }
        ]
      },
      {
        key: 'apiKey', placeholder: 'Enter API Key', label: 'API KEY', colSpan: 3, rules: [
          {
            required: true,
            message: `Enter api key.`
          }
        ]
      },
      {
        key: 'repositoryUrl', placeholder: 'Enter repository url', label: 'Repository URL', colSpan: 6, rules: [
          // {
          //   required: true,
          //   message: `Enter repository url.`
          // },
          {
            pattern: new RegExp(/^https?:\/\/([\w\d]+\.)+\w{2,}(\/.+)?.git$/),
            message: `Enter valid repository url.`
          }
        ]
      },
      {
        key: 'baseUrl', placeholder: 'Enter base url', label: 'Base URL', colSpan: 4
      },
      {
        key: 'branch', label: 'Branch', colSpan: 2,
        widget: Branches,
        rules: [
          {
            required: true,
            message: `Enter project key.`
          }
        ]
      },
      // {
      //   key: 'type', label: 'Environment Type', colSpan: 2,
      //   widget: Types,
      //   rules: [
      //     {
      //       required: true,
      //       message: `Enter project key.`
      //     }
      //   ]
      // },
      {
        key: 'assignees', label: 'Assignees', colSpan: 3,
        widget: Assignees
      }, {
        key: 'groups', label: 'Team', colSpan: 3,
        widget: Groups
      }, {
        key: 'leads', label: 'Leads', colSpan: 3,
        widget: Leads,
        rules: [
          {
            required: true,
            message: `Enter project key.`
          }
        ]
      },
      {
        key: 'managers', label: 'Managers', colSpan: 3,
        widget: Managers,
        rules: [
          {
            required: true,
            message: `Enter project key.`
          }
        ]
      },
      {
        key: 'description', widget: KoTextArea, label: 'Description', colSpan: 6, placeholder: 'Enter project description'
      },
      {
        key: 'variables', label: 'Variables', colSpan: 6,
        widget: EnvTable,
        forwardRef: true
      }
    ]
  };

  return (
    <div className={styles.formWrapper}>
      <KoForm form={form} layout="vertical" onFinish={handleSubmit} onSubmit={handleSubmit}>
        <KoFormBuilder form={form} meta={meta} />
      </KoForm>
    </div>
  );
}
