import { KoText } from 'packages/typography';
import styles from './text.module.scss';

function Text(props) {
  const { data, onCellClick, row } = props;
  return (
    <KoText row={row} ellipsis={true} className={styles.wrapper}
      onCellClick={onCellClick && onCellClick} text={data} />
   
  );
}
export default Text;