import React from "react";
import styles from './status.module.scss';

function ReleaseStatus(props) {
  let { data } = props;
  if (data === 1) {
    data = "Todo";
  } else if (data === 2) {
    data = "InProgress";
  } else if (data === 3) {
    data = "Blocked";
  } else if (data === 4) {
    data = "Ready For QA";
  }else if (data === 5) {
    data = "In Review";
  }else if (data === 6) {
    data = "On Hold";
  }else if (data === 7) {
    data = "QA Failed";
  }
  else if (data === 8) {
    data = "Abandoned";
  }
  else if (data === 9) {
    data = "Deferred";
  }
  else if (data === 10) {
    data = "Done";
  }
  else if (data === 11) {
    data = "Closed";
  }

  const dataClass = typeof data === 'string' ? data.replace(" ", '') : '';
  
  return (
    <div className={styles.wrapper}>
      <div className={`${styles.status} ${styles[dataClass]}`}>
        <div className={styles.text}>{data}</div>
      </div>
    </div>
  );
}
export default ReleaseStatus;
