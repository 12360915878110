import React from 'react';
import styles from './unsupported.module.scss';
import { KoText } from 'packages/typography';

const UnsupportedViewer = ({ unsupportedComponent: UnsupportedComponent, fileType, ...props }) => (
  <div className={`${styles.view} "pg-driver-view"`}>
    <div className="unsupported-message">
      {UnsupportedComponent
        ? <UnsupportedComponent {...props} />
        :  <KoText text={`Unsupported file .${fileType}`} />}
    </div>
  </div>
);

export default UnsupportedViewer;
