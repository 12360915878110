import React from "react";
function Descending(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 3872.983 3872.983"
      {...props}
    >
      <path
        fill="#D2D6DB"
        clipRule="evenodd"
        d="M1938.539 241.984L875.053 1704.035 3002.024 1704.035z"
      />
      <path
        fill="var(--palette-primary-dark)"
        clipRule="evenodd"
        d="M1938.539 3642.648L875.053 2180.597 3002.024 2180.597z"
      />
    </svg>
  );
}
export default Descending;