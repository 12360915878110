import { Space } from 'antd';
import { KoButton, KoDataTableEdit, KoDivider, KoForm, KoIcon, KoInput, KoNano, KoSelect } from 'packages';
import { useEffect, useRef, useState } from 'react';
import styles from './crud.module.scss';

function ItemEntryCrud({ form,handleFinish, initialValues}) {

  const [dataSource, setDataSource] = useState();

  useEffect(() => {
    if (initialValues?.agenda) {
      setDataSource(initialValues.agenda);
    } else {
      setDataSource([{
        key: KoNano('CAL'),
        title: null,
        presenter: null,
        dueDate: null,
        status: null
      }]);
    }
  }, [initialValues]);

  

  const DurationValues = [
    {
      label: '15 Mins',
      value: '15m'
    },
    {
      label: '30 Mins',
      value: '30m'
    },
    {
      label: '45 Mins',
      value: '45m'
    }
  ];


  const Duration = ({ value, onChange }) => {
    const [durationItems, setDurationItems] = useState(DurationValues);
    const [durationName, setDurationName] = useState('');
    const [unit, setUnit] = useState('Hr');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const inputRef = useRef(null);

    const AddCustomDuration = () => {
      if (durationName && unit) {
        const newItem = { value: `${durationName}${unit}`, label: `${durationName} ${unit}` };
        setDurationItems([...durationItems, newItem]);
        setDurationName('');
        setUnit('');
        inputRef.current?.focus();
      }
    };

    const handleDropdownOpen = (open) => {
      setDropdownOpen(open);
    };

    return (
      <KoSelect
        value={value}
        onChange={v => onChange(v)}
        showSearch
        allowClear
        placeholder='Choose duration'
        options={durationItems}
        onDropdownVisibleChange={(open) => handleDropdownOpen(open)}
        open={dropdownOpen}
        dropdownRender={(menu) => (
          <>
            {menu}
            <KoDivider
              style={{
                margin: '8px 0'
              }}
            />
            <Space>
              <KoInput
                placeholder="Custom duration"
                onChange={(e) => setDurationName(e.target.value)}
                value={durationName}
                ref={inputRef}
              />
              <KoSelect
                placeholder="Select Hr/min"
                onChange={(v) => setUnit(v)}
                value={unit}
                options={[
                  { value: 'Hr', label: 'Hr' },
                  { value: 'Mins', label: 'Min' }
                ]}
              />
              <KoButton
                key='add'
                type='primary'
                label="Add"
                icon={<KoIcon color="#FFF" name='PlusOutlined' />}
                onClick={AddCustomDuration}
              />
            </Space>
          </>
        )}
      />
    );
  };

  const columns = [
    {
      dataIndex: 'title',
      title: 'Title',
      width: 200,
      editable: true,
      widget: 'text',
      placeholder: 'Title'
    },
    {
      dataIndex: 'presenter',
      title: 'Presenter',
      width: 200,
      editable: true,
      widget: 'text',
      placeholder: 'Presenter'
    },
    {
      dataIndex: 'duration',
      title: 'Duration',
      editable: true,
      width: 200,
      render: (text, record) => (
        <Duration
          value={record.duration}
          onChange={(newDuration) => {
            record.duration = newDuration;
          }}
        />
      )
    },

    {
      dataIndex: 'dueDate',
      title: 'Due Date',
      width: 200,
      editable: true,
      widget: 'date'
    },
    {
      dataIndex: 'status',
      title: 'Status',
      editable: true,
      widget:'options',
      options:[{
        value:1,
        label:'In Progress'
      },{
        value:2,
        label:'Completed'
      },{
        value:3,
        label:'Canceled'
      }],
      width: 150
    },
    {
      title: ` `,
      dataIndex: 'operation',
      width: 40,
      render: (_, record, i) =>
        dataSource.length >= 1 ? (
          <div className={'editTableCol'}>
            <KoButton icon={<KoIcon width={12} height={12} name={'DeleteOutlined'} />} className={styles.actionBtn} onClick={() => handleDelete(record?.key)} label="" />
            {dataSource?.length === (i+1) && <KoButton icon={<KoIcon color={`var(--palette-gray-7)`} width={17} height={17} name={'PlusOutlined'} />} className={styles.actionBtn} onClick={() => handleAdd(record?.key)} label={``} />}
          </div>
        ) : null
    }
  ];

  const handleDelete = (key) => {
    let newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
    if(newData.length === 0){
      newData =[{
        key: KoNano('TAX'),
        taxName: null,
        taxRate: null,
        isDefault: false
      }];
    }
    handleSave(newData);
  };

  const handleAdd = () => {
    const newData = {
      key: KoNano('CAL'),
      title: ``,
      presenter: '',
      dueDate: '',
      status: ''
    };
    setDataSource([...dataSource, newData]);
  };

  const handleSave = (newData) => {
    let data = form.getFieldsValue();
    data['agenda'] = newData;
    form.setFieldsValue({...data});
    setDataSource(newData);
  };

  return (
    <div className={styles.itemwrapper}>
      <KoForm form={form} layout="vertical" onFinish={handleFinish} onSubmit={handleFinish}>
        <KoForm.Item name={'agenda'}>
          <KoDataTableEdit
            dataSource={dataSource}
            form={form}
            onSave={handleSave}
            defaultColumns={columns} />
        </KoForm.Item>
      </KoForm>
    </div>
  );
};

export default ItemEntryCrud;