import React from "react";
function Microsoft(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16.003 16.004">
      <path fill="#4caf50" d="M272 7.5h7.5v-7a.5.5 0 0 0-.5-.5h-7z" transform="translate(-263.498)" />
      <path fill="#f44336" d="M7.5 7.5V0h-7a.5.5 0 0 0-.5.5v7z" />
      <path fill="#2196f3" d="M7.5 272H0v7a.5.5 0 0 0 .5.5h7z" transform="translate(0 -263.498)" />
      <path fill="#ffc107" d="M272 272v7.5h7a.5.5 0 0 0 .5-.5v-7z" transform="translate(-263.498 -263.498)" />
    </svg>
  );
}


export default Microsoft;