import React from "react";
function MobileMenuBar(props) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      {...props}
    >
      <path
	  d="M4 7h16a1 1 0 000-2H4a1 1 0 000 2zm16 10H4a1 1 0 000 2h16a1 1 0 000-2zM4 13h7a1 1 0 000-2H4a1 1 0 000 2z"
	  data-name="menu paragraph"
	  fill="#707070"
      />
    </svg>
  );
}


export default MobileMenuBar;