import React from "react";
function Avatar3(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <g id="avatar_2" transform="translate(0.5 0.5)">
        <path id="Path_15844" fill="#f2fdff" stroke="#c0f4fc" d="M65.516 80.635h-50.4A15.118 15.118 0 0 1 0 65.516v-50.4A15.118 15.118 0 0 1 15.119 0h50.4a15.118 15.118 0 0 1 15.116 15.119v50.4a15.118 15.118 0 0 1-15.119 15.116z" />
        <g id="mountains" transform="translate(9.122 14.634)">
          <path id="Path_28525" d="M157.208 111.869L132.775 65.62a.646.646 0 0 0-1.141 0l-24.777 46.249a.646.646 0 0 0 .57.951h49.21a.646.646 0 0 0 .571-.951z" fill='#' transform="translate(-93.472 -65.276)" />
          <path id="Path_28526" d="M319.3 112.818h-3.433L292.6 68.783l1.695-3.165a.646.646 0 0 1 1.141 0l24.433 46.248a.647.647 0 0 1-.569.952z" fill="#79b510" transform="translate(-256.135 -65.277)" />
          <path id="Path_28527" d="M243.416 87.887l-5.338 6.277a.646.646 0 0 1-.979-.008l-8.34-9.846a.646.646 0 0 0-.975-.013l-4.412 4.937a.646.646 0 0 1-1-.039l-2.638-3.459 10.776-20.114a.646.646 0 0 1 1.141 0z" fill="#e7ebec" transform="translate(-192.352 -65.279)" />
          <path id="Path_28528" d="M307.416 87.885l-2.779 3.268-11.719-22.325a.579.579 0 0 0-.068-.1l1.664-3.1a.646.646 0 0 1 1.141 0z" fill='#cdd7d9' transform="translate(-256.351 -65.278)" />
          <path id="Path_28529" d="M36.029 206.919L18.745 174.2a.646.646 0 0 0-1.141 0L.078 206.916a.646.646 0 0 0 .57.951h34.81a.646.646 0 0 0 .571-.948z" fill='#' transform="translate(0 -160.326)" />
          <path id="Path_28530" d="M149.456 207.87h-2.793l-16.432-31.1 1.369-2.57a.646.646 0 0 1 1.141 0l17.283 32.714a.646.646 0 0 1-.568.956z" fill="#79b510" transform="translate(-113.999 -160.329)" />
          <path id="Path_28531" d="M88.058 196.852l-3.5 3.23a.646.646 0 0 1-.893-.017l-9.165-9.087a.646.646 0 0 0-.937.029l-3.441 3.856a.646.646 0 0 1-.9.065l-3.714-3.11 9.444-17.618a.646.646 0 0 1 1.141 0z" fill="#e7ebec" transform="translate(-57.349 -160.326)" />
          <path id="SVGCleanerId_0" d="M144.708 196.854l-2.6 2.4-11.878-22.49 1.37-2.564a.681.681 0 0 1 .109-.149.567.567 0 0 1 .065-.059l.036-.026a.847.847 0 0 1 .074-.042.652.652 0 0 1 .545-.011.362.362 0 0 1 .051.026.626.626 0 0 1 .26.265z" fill='#cdd7d9' transform="translate(-113.999 -160.327)" />
          <g id="Group_11066" transform="translate(16.231 13.533)">
            <path id="SVGCleanerId_0_1_" d="M144.708 196.854l-2.6 2.4-11.878-22.49 1.37-2.564a.681.681 0 0 1 .109-.149.567.567 0 0 1 .065-.059l.036-.026a.847.847 0 0 1 .074-.042.652.652 0 0 1 .545-.011.362.362 0 0 1 .051.026.626.626 0 0 1 .26.265z" fill='#cdd7d9' transform="translate(-130.23 -173.86)" />
          </g>
        </g>
      </g>
    </svg>

  );
}


export default Avatar3;