import React from 'react';

const PageNotFound = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 135 135" {...props}>
      <path
        fill="#d6dce8"
        d="M127.41 67.495c0 14.24-4.91 27.33-13.12 37.67a60.352 60.352 0 01-5.03 5.57c-10.93 10.71-25.89 17.33-42.41 17.33-16.64 0-31.73-6.73-42.67-17.6a59.798 59.798 0 01-4.76-5.3c-8.22-10.34-13.13-23.43-13.13-37.67 0-14.23 4.91-27.32 13.13-37.65 3.55-4.48 7.74-8.45 12.42-11.77a60.318 60.318 0 0135.01-11.14c13.05 0 25.13 4.13 35.02 11.14 4.68 3.33 8.86 7.29 12.42 11.77 8.22 10.33 13.12 23.42 13.12 37.65zm-1.191 35.127c-1.6.001-2.895 1.32-2.894 2.92a2.896 2.896 0 002.897 2.893c1.6-.001 2.917-1.299 2.916-2.898a2.933 2.933 0 00-2.92-2.915zm3.42-6.252c-1.017.001-1.842.84-1.841 1.858 0 1.018.826 1.843 1.844 1.842a1.855 1.855 0 001.855-1.844c0-1.018-.84-1.856-1.857-1.856zm-119.211 8.652c1.599.001 2.894 1.32 2.893 2.919a2.896 2.896 0 01-2.897 2.893c-1.599 0-2.916-1.298-2.915-2.897s1.32-2.916 2.919-2.915zm4.735-88.27c1.6 0 2.895 1.32 2.894 2.919a2.896 2.896 0 01-2.898 2.893c-1.599 0-2.916-1.298-2.915-2.897a2.933 2.933 0 012.919-2.915z"
      />
      <linearGradient
        id="a"
        x1={66.194}
        x2={67.205}
        y1={125.265}
        y2={97.108}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#d6dce8" />
        <stop offset={0.503} stopColor="#fff" />
      </linearGradient>
      <path
        fill="url(#a)"
        d="M109.26 110.735c-10.93 10.71-25.89 17.33-42.41 17.33-16.64 0-31.73-6.73-42.67-17.6 12.24-5.67 26.71-8.95 42.24-8.95 15.78 0 30.48 3.39 42.84 9.22z"
      />
      <path
        fill="#d6dce8"
        d="M49.168 108.648c0 .936-2.266 1.693-5.062 1.693-2.795 0-5.06-.757-5.06-1.693 0-.94 2.265-1.696 5.06-1.696 2.796 0 5.062.757 5.062 1.696zm23.276-3.624c0 .935-2.265 1.693-5.061 1.693s-5.061-.758-5.061-1.693c0-.94 2.265-1.697 5.06-1.697 2.797 0 5.062.758 5.062 1.697z"
      />
      <path
        fill="#f1f3f9"
        d="M23.251 58.958c0 .27-.22.491-.49.491a3.626 3.626 0 00-3.623 3.622c0 .27-.22.491-.49.491s-.492-.22-.492-.49a3.626 3.626 0 00-3.622-3.623c-.27 0-.49-.22-.49-.49s.22-.492.49-.492a3.626 3.626 0 003.622-3.622c0-.27.221-.49.491-.49s.491.22.491.49a3.626 3.626 0 003.622 3.622c.27 0 .491.221.491.491zm16.55-27.266c0 .391-.32.711-.711.711a5.253 5.253 0 00-5.248 5.248c0 .39-.32.71-.71.71a.713.713 0 01-.712-.71 5.253 5.253 0 00-5.248-5.248c-.39 0-.71-.32-.71-.71s.32-.712.71-.712a5.253 5.253 0 005.248-5.248c0-.39.32-.71.711-.71.391 0 .711.32.711.71a5.253 5.253 0 005.248 5.248c.39 0 .71.32.71.711zm22.538-6.67a.316.316 0 01-.315.315 2.324 2.324 0 00-2.322 2.322c0 .173-.141.315-.314.315s-.315-.142-.315-.315a2.324 2.324 0 00-2.322-2.322.316.316 0 01-.314-.315c0-.173.141-.314.314-.314a2.324 2.324 0 002.322-2.322.316.316 0 01.63 0 2.324 2.324 0 002.321 2.322c.173 0 .315.141.315.314z"
      />
      <path
        fill="#d6dce8"
        d="M5.357 98.22c1.018 0 1.843.84 1.842 1.858s-.826 1.842-1.844 1.842a1.855 1.855 0 01-1.855-1.844c0-1.018.84-1.856 1.857-1.856z"
      />
      <path
        fill="#f1f3f9"
        d="M66.598 91.168a.385.385 0 01-.384.384 2.84 2.84 0 00-2.837 2.836c0 .212-.173.385-.384.385s-.384-.173-.384-.385a2.84 2.84 0 00-2.837-2.836.385.385 0 01-.384-.384c0-.212.173-.385.384-.385a2.84 2.84 0 002.837-2.836c0-.212.173-.385.384-.385s.384.173.384.385a2.84 2.84 0 002.837 2.836c.211 0 .384.173.384.385z"
      />
      <linearGradient
        id="b"
        x1={105.942}
        x2={67.125}
        y1={57.693}
        y2={57.838}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#d6dce8" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <path
        fill="url(#b)"
        d="M91.678 11.3v97.274h-8.73V11.3a4.365 4.365 0 118.73 0z"
      />
      <linearGradient
        id="c"
        x1={98.676}
        x2={84.218}
        y1={57.489}
        y2={58.049}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#d6dce8" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <path
        fill="url(#c)"
        d="M96.235 11.784v96.792h-4.558V11.297a4.354 4.354 0 00-1.28-3.081 4.296 4.296 0 00-3.081-1.28h4.071a4.848 4.848 0 014.848 4.848z"
      />
      <path
        fill="#d6dce8"
        d="M100.567 108.58c0 .973-2.358 1.76-5.266 1.76s-5.266-.787-5.266-1.76c0-.977 2.358-1.765 5.266-1.765s5.266.788 5.266 1.765z"
      />
      <linearGradient
        id="d"
        x1={126.95}
        x2={30.647}
        y1={49.528}
        y2={41.38}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#d6dce8" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <path
        fill="url(#d)"
        d="M124.47 50.355l-14.1 24.425a8.015 8.015 0 01-6.941 4.008h-28.21a8.015 8.015 0 01-6.942-4.008L54.18 50.355a8.015 8.015 0 010-8.013L68.277 17.91a8.015 8.015 0 016.942-4.01h28.208a8.015 8.015 0 016.942 4.01l14.1 24.432a8.015 8.015 0 010 8.013z"
      />
      <linearGradient
        id="e"
        x1={112.211}
        x2={66.435}
        y1={59.007}
        y2={33.69}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#989fb0" />
        <stop offset={1} stopColor="#aab2c5" />
      </linearGradient>
      <path
        fill="url(#e)"
        d="M118.408 47.801L105.127 70.81a2.92 2.92 0 01-2.52 1.457H76.045a2.935 2.935 0 01-2.53-1.457L60.242 47.801a2.909 2.909 0 010-2.914L73.524 21.89a2.887 2.887 0 012.52-1.457h26.563a2.91 2.91 0 012.52 1.457l13.28 23.008a2.938 2.938 0 010 2.904z"
      />
      <linearGradient
        id="f"
        x1={65.957}
        x2={79.888}
        y1={42.329}
        y2={42.329}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#d6dce8" />
        <stop offset={0.503} stopColor="#fff" />
      </linearGradient>
      <path
        fill="url(#f)"
        d="M77.742 45.002V34.325a.635.635 0 00-.635-.635h-3.594a.635.635 0 00-.536.295l-6.921 10.91a.635.635 0 00-.099.34v2.056c0 .35.284.635.635.635h7.655v2.407c0 .35.284.635.635.635h2.225c.35 0 .635-.284.635-.635v-2.407h1.511c.35 0 .635-.284.635-.635v-1.654a.635.635 0 00-.635-.635h-1.511zm-8.113 0v-.138l4.549-7.197h.138v7.335h-4.687z"
      />
      <linearGradient
        id="g"
        x1={82.113}
        x2={96.075}
        y1={42.399}
        y2={42.399}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#d6dce8" />
        <stop offset={0.503} stopColor="#fff" />
      </linearGradient>
      <path
        fill="url(#g)"
        d="M89.098 51.347c-1.452-.005-2.7-.363-3.742-1.071-1.044-.71-1.846-1.736-2.405-3.08-1.12-2.691-1.117-7.003.008-9.67 1.086-2.576 3.159-4.075 6.139-4.075 4.878 0 6.992 3.973 6.977 8.893 0 4.944-2.077 9.003-6.977 9.003zm2.37-4.522c.591-.996.883-2.49.878-4.48 0-2.62-.629-5.898-3.248-5.898-.985 0-1.772.492-2.363 1.476-.59.984-.89 2.458-.894 4.421 0 1.73.239 4.142 1.535 5.312 1.082.977 2.99 1.026 4.092-.831z"
      />
      <linearGradient
        id="h"
        x1={98.412}
        x2={112.347}
        y1={42.329}
        y2={42.329}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#d6dce8" />
        <stop offset={0.503} stopColor="#fff" />
      </linearGradient>
      <path
        fill="url(#h)"
        d="M110.201 45.002V34.325a.635.635 0 00-.635-.635h-3.594a.635.635 0 00-.536.295c-7.437 11.724-7.02 10.978-7.02 11.25v2.056c0 .35.285.635.635.635h7.655v2.407c0 .35.284.635.635.635h2.225c.35 0 .635-.284.635-.635v-2.407h1.511c.351 0 .635-.284.635-.635v-1.654a.635.635 0 00-.635-.635h-1.511zm-3.426 0h-4.686v-.138l4.548-7.197h.138v7.335z"
      />
      <path
        fill="#fff"
        d="M103.775 58.756H75.187a1 1 0 110-2h28.588a1 1 0 110 2zm-5.566 5.072H80.753a1 1 0 110-2h17.456a1 1 0 110 2z"
      />
      <linearGradient
        id="i"
        x1={188.59}
        x2={251.831}
        y1={272.504}
        y2={272.504}
        gradientTransform="rotate(-4.921 -2453.36 1831.742)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#d6dce8" />
        <stop offset={0.196} stopColor="#fff" />
      </linearGradient>
      <path
        fill="url(#i)"
        d="M45.69 58.423l29.693-33.867c21.58-11.773 46.771 34.406 25.191 46.178l-44.623 5.768-10.26-18.079z"
        opacity={0.3}
      />
      <linearGradient
        id="j"
        x1={613.732}
        x2={619.894}
        y1={1539.779}
        y2={1539.779}
        gradientTransform="rotate(-29.739 -2439.191 1874.361)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#989fb0" />
        <stop offset={1} stopColor="#aab2c5" />
      </linearGradient>
      <path
        fill="url(#j)"
        d="M44.543 55.045L57.48 77.691a1.373 1.373 0 01-.51 1.873l-2.967 1.695a1.373 1.373 0 01-1.874-.511L39.192 58.102a1.373 1.373 0 01.511-1.873l2.966-1.695a1.373 1.373 0 011.874.511z"
      />
      <linearGradient
        id="k"
        x1={-4033.42}
        x2={-4026.575}
        y1={1539.779}
        y2={1539.779}
        gradientTransform="matrix(-1.55708 .88956 .49606 .86829 -6998.514 2320.432)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#989fb0" />
        <stop offset={1} stopColor="#aab2c5" />
      </linearGradient>
      <path
        fill="url(#k)"
        d="M31.304 69.038L38.703 81.989 51.64 79.891 39.681 58.958z"
      />
      <linearGradient
        id="l"
        x1={148.53}
        x2={178.745}
        y1={297.217}
        y2={305.398}
        gradientTransform="rotate(-4.921 -2453.36 1831.742)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#d6dce8" />
        <stop offset={0.503} stopColor="#fff" />
      </linearGradient>
      <path
        fill="url(#l)"
        d="M38.707 81.99L14.95 95.56a2.083 2.083 0 01-2.842-.772l-5.339-9.342a2.072 2.072 0 01.782-2.834l23.757-13.578 7.4 12.957z"
      />
      <linearGradient
        id="m"
        x1={567.757}
        x2={576.401}
        y1={1539.779}
        y2={1539.779}
        gradientTransform="rotate(-29.739 -2439.191 1874.361)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#989fb0" />
        <stop offset={1} stopColor="#aab2c5" />
      </linearGradient>
      <path
        fill="url(#m)"
        d="M7.297 86.37a4.32 4.32 0 00-1.555 5.86 4.32 4.32 0 005.838 1.637L7.297 86.37z"
      />
      <linearGradient
        id="n"
        x1={156.965}
        x2={169.259}
        y1={304.452}
        y2={298.766}
        gradientTransform="rotate(-4.921 -2453.36 1831.742)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#989fb0" />
        <stop offset={1} stopColor="#aab2c5" />
      </linearGradient>
      <path
        fill="url(#n)"
        d="M27.153 82.922l-7.352 4.196c-1.213.7-2.77.275-3.46-.939a2.538 2.538 0 01.946-3.47l7.352-4.197a2.529 2.529 0 013.462.948 2.532 2.532 0 01-.948 3.462z"
      />
      <linearGradient
        id="o"
        x1={581.022}
        x2={584.219}
        y1={1539.779}
        y2={1539.779}
        gradientTransform="rotate(-29.739 -2439.191 1874.361)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#d6dce8" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
      <path
        fill="url(#o)"
        d="M20.035 84.065a1.599 1.599 0 01-2.775 1.586 1.6 1.6 0 012.775-1.586z"
      />
      <g>
        <path
          fill="#fff"
          d="M115.895 87.705a.343.343 0 01-.342.342 2.525 2.525 0 00-2.522 2.522.343.343 0 01-.342.342.343.343 0 01-.342-.342 2.525 2.525 0 00-2.523-2.522.343.343 0 01-.341-.342c0-.188.153-.342.341-.342a2.525 2.525 0 002.523-2.523c0-.188.154-.342.342-.342.188 0 .342.154.342.342a2.525 2.525 0 002.522 2.523c.188 0 .342.154.342.342z"
        />
      </g>
    </svg>
  );
};

export default PageNotFound;
