import _find from 'lodash/find';
import { KoBreadcrumb, KoButton, KoForm, KoIcon, KoLoader, KoModal, KoTicketTable, KoToast, KoTooltips } from 'packages';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { add, remove } from 'shared/store/breadcrumbSlice';
import { getCookie } from 'shared/utils/cookies';
import TaskCrud from '../task/crud';
import { TaskPlannerAPI } from '../taskPlanner.service';
import styles from './epic.module.scss';
import { getTicketBreadCrumb } from 'shared/commonConstants';
import { setSelectedChatData, setSelectedChatType } from 'shared/store/chatSlice';

const Epic = (props) => {
  const {workspaceId} = useParams();
  const {title, profile} = props;
  const dispatch = useDispatch();
  const userById = useSelector(state => state?.userById);
  const [isLoading, setLoading] = useState(false);
  const [isChildLoading, setChildLoading] = useState(false);
  const [epics, setEpics] = useState();
  const [epicsChild, setEpicsChild] = useState();
  const [isOpen, setModal] = useState(false);
  const [totalCount, setTotalCount] = useState(100);
  const [totalCountChild, setTotalChildCount] = useState(100);
  const [isIssueModel, setIssueModel] = useState(false);
  const [isIssueModelChild, setIssueModelChild] = useState(false);
  const [copy, setCopy] = useState(false);
  const [breadCrumbs, setBreadCrumbs] = useState();
  const [pagination, setPagination] = useState({
    size: 10,
    page: 1,
    issueType: 1
  });
  let userDetails = userById['data'];

  const [openSubModel, setSubModel] = useState(false);
  const [subTotalCount, setSubTotalCount] = useState(100);
  const [isSubLoading, setSubLoading] = useState(false);
  const [ subTickets ,setSubTickets] = useState();
  const [isIssueModelSub, setIssueModelSub] = useState(false);
  const [subCurrentRecord, setSubCurrentRecord] = useState({
    status:1,
    issueType:2,
    priority:3,
    reporter: userDetails?.userId
  });
  const [subPagination, setSubPagination] = useState({
    size: 10,
    page: 1
  });

  const [currentRecord, setCurrentRecord] = useState({
    status:1,
    issueType:2,
    priority:3,
    reporter:userDetails && userDetails?.userId
  });
  const [childCurrentRecord, setChildCurrentRecord] = useState({
    status:1,
    issueType:1,
    priority:3,
    reporter: userDetails && userDetails?.userId
  });
  const [form] = KoForm.useForm();

  const workspace = useSelector(state => state?.workspace);
  let workspaceList = workspace['data'];
  const currentWorkspace = _find(workspaceList, function(o) { return o.workspaceId === workspaceId; });


  useEffect(()=>{
    dispatch(remove());
    dispatch(add([
      {
        title: 'workspace',
        path: 'workspace'
      },
      {
        title: currentWorkspace?.name
      },
      {
        title: 'BackLogs'
      }
    ]));

  }, [currentWorkspace]);

  useEffect(()=>{
    GetEpics();
  },[]);


  const GetEpics = () => {
    setLoading(true);
    TaskPlannerAPI.getIssues(pagination, workspaceId).then(
      (data) => {
        setTotalCount();
        setEpics();
        setLoading(false);
        setEpics(data?.tickets);
        setTotalCount(data?.totalCount);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const getIssueById = (ticketId, sub)=> {
    if(sub){
      setSubLoading(true);
    }else{
      setChildLoading(true);
    }
    TaskPlannerAPI.getIssueById(ticketId, workspaceId).then(
      (data) => {
        const updatedRoomObj = { ...data?.issue, source: 'tickets' };
        dispatch(setSelectedChatData(updatedRoomObj));
        dispatch(setSelectedChatType('tickets'));
        if(sub){
          setSubLoading(false);
          setSubCurrentRecord(data?.issue);
        }else{
          console.log(subCurrentRecord);
          setChildLoading(false);
          setChildCurrentRecord(data?.issue);
        }
      },
      (err) => {
        setChildLoading(false);
      }
    );
  };

  const GetChildEpics = (row) => {
    setChildLoading(true);
    TaskPlannerAPI.getEpicIssues(row?.ticketId, pagination, workspaceId).then(
      (data) => {
        setEpicsChild();
        setEpicsChild(data?.tickets);
        setChildLoading(false);
        setIssueModel(true);
        setTotalChildCount(data?.totalCount);
      },
      (err) => {
        setChildLoading(false);
      }
    );
  };

  const getSubIssueById = (ticketId)=> {
    setSubLoading(true);
    TaskPlannerAPI.getSubTaskIssuesWithQuery(subPagination, ticketId,  workspaceId).then(
      (data) => {
        setSubLoading(false);
        setSubTickets(data?.tickets);
        setSubTotalCount(data?.totalCount);
      },
      (err) => {
        setSubLoading(false);
      }
    );
  };

  const handleFinish = (values, epicId) => {
    setLoading(true);

    if (epicId) {
      values.issueType = 1;
      values.epic = currentRecord?.ticketId;
      TaskPlannerAPI.createOrUpdateIssues(values, childCurrentRecord?.ticketId, workspaceId).then(
        (data) => {
          setLoading(false);
          setIssueModelChild(false);
          form.resetFields();
          GetChildEpics(currentRecord);
          KoToast.success(`Ticket ${childCurrentRecord?.ticketId ? 'updated':'created'} successfully`);
        },
        (err) => {
          setLoading(false);
        }
      );
    }
    else{
      TaskPlannerAPI.createOrUpdateIssues(values, currentRecord?.ticketId, workspaceId).then(
        (data) => {
          setLoading(false);
          setModal(false);
          form.resetFields();
          KoToast.success(`Ticket ${currentRecord?.ticketId ? 'updated':'created'} successfully`);
        },
        (err) => {
          setLoading(false);
        }
      );
    }
    GetEpics();
  };

  const onPagination = (page, other) => {
    const query = {
      size: other,
      page: page
     
    };
    const p = Object.assign(pagination, query);
    setPagination(p);
    GetEpics(p);
  };


  const onSearchText=(text)=>{
    let q = pagination;
    q['search'] = text;
    GetEpics(q);
  };
  const onChildSearchText=(text)=>{
    let q = pagination;
    q['search'] = text;
    GetChildEpics(currentRecord);
  };

  const onSort = (key, type) => {
    let q = pagination;
    q['sort'] = `${key}:${type}`;
    GetEpics(q);
  };
  
  const onCopyTicket=(row)=>{
    setCopy(true);
    navigator.clipboard.writeText(`${window.location.origin}/open/ticket/${getCookie("accountId")}/${row?.workspace}/${row?.ticketId}`);

  };


  const columnsRight = [{
    title: '',
    dataIndex: 'x',
    key: 'x',
    fixed: 'right',
    width:80,
    render: (text, row) => {
      return <div className={`${styles.circle} tableBtn columnsRight`} onClick={() => setCurrentRecord(row)}>
        <KoTooltips title={'Edit'}>
	      <KoButton  className={'btn'} iconBtn={true} icon={<KoIcon width={14} height={14} name={'EditOutlined'}/>} onClick={() => setModal(true)}/>
        </KoTooltips>
        <KoTooltips title={copy ? 'Copied' : 'Copy'}>
	      <KoButton onClick={()=>onCopyTicket(row)} className={'btn'} iconBtn={true} icon={<KoIcon width={14} height={14} name={'CopyOutlined'}/>}/>
        </KoTooltips>
      </div>;
    }
  }];


  const onChangeStatus=(key)=>{
    let q =  pagination;
    if(key){
      q['status'] = key.toString();
    }else{
      delete q?.['status'];
    }
   
    GetEpics(q);
  };

  const onChangePriority =(key)=>{
    let q =  pagination;
    if(key){
      q['priority'] = key.toString();
    }else{
      delete q?.['priority'];
    }
   
    GetEpics(q);
  };

  const onChangeAssignees =(key)=>{
    let q =  pagination;
    if(key){
      q['assignee'] = key.toString();
    }else{
      delete q?.['assignee'];
    }
   
    GetEpics(q);
  };

  const onChildChangeStatus=(key)=>{
    let q =  pagination;
    if(key){
      q['status'] = key.toString();
    }else{
      delete q?.['status'];
    }
   
    GetChildEpics(currentRecord);
  };

  const onChildChangePriority =(key)=>{
    let q =  pagination;
    if(key){
      q['priority'] = key.toString();
    }else{
      delete q?.['priority'];
    }
   
    GetChildEpics(currentRecord);
  };
  const onChildChangeAssignees =(key)=>{
    let q =  pagination;
    if(key){
      q['assignee'] = key.toString();
    }else{
      delete q?.['assignee'];
    }
   
    GetChildEpics(currentRecord);
  };

  const onChangeSubStatus=(key)=>{
    let q =  subPagination;
    if(key){
      q['status'] = key.toString();
    }else{
      delete q?.['status'];
    }
   
    getSubIssueById(currentRecord.ticketId, q);
  };

  const onChangeSubType=(key)=>{
    let q =  subPagination;
    if(key){
      q['issueType'] = key.toString();
    }else{
      delete q['issueType'];
    }
    getSubIssueById(currentRecord.ticketId, q);
  };

  const onChangeSubPriority =(key)=>{
    let q =  subPagination;
    if(key){
      q['priority'] = key.toString();
    }else{
      delete q?.['priority'];
    }
   
    getSubIssueById(currentRecord.ticketId, q);
  };

  const onChangeSubAssignees =(key)=>{
    let q =  subPagination;
    if(key){
      q['assignee'] = key.toString();
    }else{
      delete q?.['assignee'];
    }
   
    getSubIssueById(currentRecord.ticketId, q);
  };
  
  const onSubSearchText=(text)=>{
    let q = subPagination;
    q['search'] = text;
    getSubIssueById(currentRecord.ticketId, q);
  };

  const onSubSort = (key, type) => {
    let q = subPagination;
    q['sort'] = `${key}:${type}`;
    getSubIssueById(currentRecord.ticketId, q);
  };
   
  const onSubPagination = (page, other) => {
    const query = {
      size: other,
      page: page
     
    };
    const p = Object.assign(subPagination, query);
    setSubPagination(p);
    getSubIssueById(currentRecord.ticketId, p);
  };

  const onSubCellClick=(row)=>{
    // setSubCurrentRecord(row);
    const updatedRoomObj = { ...row, source: 'tickets' };
    dispatch(setSelectedChatData(updatedRoomObj));
    dispatch(setSelectedChatType('tickets'));
    let crums = getTicketBreadCrumb(row);
    setBreadCrumbs(crums);
    getIssueById(row.ticketId, true);
    setIssueModelSub(true);
  };


  const onExtraClick=(row)=>{
    setCurrentRecord(row);
    const updatedRoomObj = { ...row, source: 'tickets' };
    dispatch(setSelectedChatData(updatedRoomObj));
    dispatch(setSelectedChatType('tickets'));
    let crums = getTicketBreadCrumb(row);
    setBreadCrumbs(crums);
    getSubIssueById(row.ticketId);
    setSubModel(true);
  };

  return (
    <div className='tableCard'>
      { isLoading && <KoLoader />}

      <KoTicketTable
        setModal={setModal}
        onSearchText={onSearchText}
        profile={profile}
        title={title}
        API={TaskPlannerAPI}
        onSort={onSort}
        onChangeStatus={onChangeStatus}
        onChangePriority={onChangePriority}
        onChangeAssignees={onChangeAssignees}
        setCurrentRecord={setCurrentRecord}
        isOpen={isOpen}
        Bulk={{
          visible: false
        }}
        RowSelectionOption={{
          visible: false
        }}
        tickets={epics}
        onExtraClick={onExtraClick}
        GetChild={GetChildEpics}
        columnsRight={columnsRight}
        onPagination={onPagination}
        pagination={{page: pagination.page, limit: pagination.limit}}
        totalCount={totalCount} />



      <KoModal
        title={ breadCrumbs ? <KoBreadcrumb items={breadCrumbs} /> : (currentRecord?.ticketId )
          ? `Update Issuea ${(currentRecord?.ticketId).toUpperCase()}` : `Create Issue` }
        centered
        open={isOpen}
        afterClose={() => {setCurrentRecord(); GetEpics();}}
        handleOk={() => { setCurrentRecord(); GetEpics();  setIssueModel(false);}}
        onClose={() => { setIssueModel(false); setModal(false); GetEpics();}}
        width={'95vw'}
        height={'95vh'}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => {setModal(!isOpen); form.resetFields();}} />,
          <KoButton key='create' loading={isLoading} type='primary' label={currentRecord?.ticketId ? `Update` : `Create`}
            onClick={() => {form.submit();}} />
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <TaskCrud form={form} handleFinish={handleFinish} initialValues={currentRecord} profile={profile}  epic={true}/>
      </KoModal>



      <KoModal
        centered
        open={isIssueModel}
        afterClose={() => {setCurrentRecord(); setBreadCrumbs();}}
        handleOk={() => { setCurrentRecord(); setIssueModel(false);}}
        onClose={() => { setCurrentRecord(); setIssueModel(false);}}
        width={`95vw`}
        // maskClosable={false}
        footer={false}>

        <div className={styles.modelWrapper}>
          { isChildLoading && <KoLoader />}
          <KoTicketTable
            setModal={setIssueModelChild}
            onSearchText={onChildSearchText}
            profile={profile}
            title={currentRecord?.ticketId}
            API={TaskPlannerAPI}
            getIssueById={getIssueById}
            onSort={onSort}
            onChangeStatus={onChildChangeStatus}
            onChangePriority={onChildChangePriority}
            onChangeAssignees={onChildChangeAssignees}
            setCurrentRecord={setChildCurrentRecord}
            isOpen={isOpen}
            Bulk={{
              visible: false
            }}
            RowSelectionOption={{
              visible: false
            }}
            setBreadCrumbs={setBreadCrumbs}
            tickets={epicsChild}
            columnsRight={columnsRight}
            onPagination={onPagination}
            pagination={{page: pagination.page, limit: pagination.limit}}
            totalCount={totalCountChild} />

        </div>
      </KoModal>


      
      <KoModal
        title={ breadCrumbs ? <KoBreadcrumb items={breadCrumbs} /> : (childCurrentRecord?.ticketId )
          ? `Update Issuea ${(childCurrentRecord?.ticketId).toUpperCase()}` : `Create Issue` }
        centered
        open={isIssueModelChild}
        afterClose={() => {setChildCurrentRecord();}}
        handleOk={() => { setChildCurrentRecord();  setIssueModelChild(false);}}
        onClose={() => { setIssueModelChild(false); GetEpics();}}
        width={'95vw'}
        height={'95vh'}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => {setIssueModelChild(!isIssueModelChild); form.resetFields();}} />,
          <KoButton key='create' loading={isLoading} type='primary' label={childCurrentRecord?.ticketId ? `Update` : `Create`}
            onClick={() => form.submit()} />
        ]}
      >
        {/* {isLoading && <KoLoader circle={false} />} */}
        <TaskCrud form={form} handleFinish={handleFinish} initialValues={childCurrentRecord} profile={profile}  epic={true}
          epicId={currentRecord?.issueType === 1 ? currentRecord?.ticketId : null}
        />
      </KoModal>

      <KoModal
        title={`${currentRecord?.ticketId } Subtasks Lists`}
        centered
        open={openSubModel}
        afterClose={() => { setSubCurrentRecord(); setBreadCrumbs();}}
        handleOk={() => { setSubCurrentRecord(); setSubModel(false); }}
        onClose={() => { setSubCurrentRecord(); setSubModel(false); }}
      // maskClosable={false}
        width={`95vw`}
        footer={[]}>
        <>
          {isSubLoading && <KoLoader circle={false} />}
          <KoTicketTable
            setModal={setIssueModelSub}
            onSearchText={onSubSearchText}
            profile={profile}
            API={TaskPlannerAPI}
            getIssueById={getIssueById}
            onSort={onSubSort}
            onChangeStatus={onChangeSubStatus}
            onChangeType={onChangeSubType}
            onChangePriority={onChangeSubPriority}
            onChangeAssignees={onChangeSubAssignees}
            setCurrentRecord={setSubCurrentRecord}
            isOpen={isIssueModelSub}
            maxHeight={100}
            Bulk={{
              visible: false
            }}
            RowSelectionOption={{
              visible: false
            }}
            setBreadCrumbs={setBreadCrumbs}
            createBtn={false}
            tickets={subTickets}
            onCellClickExternal={onSubCellClick}
            // columnsRight={columnsRight}
            onPagination={onSubPagination}
            pagination={{page: subPagination.page, limit: subPagination.limit}}
            totalCount={subTotalCount} />
        </>
      </KoModal>

      <KoModal
        title={ breadCrumbs ? <KoBreadcrumb items={breadCrumbs} /> : (subCurrentRecord?.ticketId )
          ? `Update Issue ${(subCurrentRecord?.ticketId).toUpperCase()}` : `Create Issue` }
        centered
        open={isIssueModelSub}
        afterClose={() => {setSubCurrentRecord();}}
        handleOk={() => { setSubCurrentRecord();  setIssueModelSub(false);}}
        onClose={() => { setIssueModelSub(false); getSubIssueById(currentRecord?.ticketId);}}
        width={'95vw'}
        height={'95vh'}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => {setIssueModelSub(!isIssueModelSub); form.resetFields();}} />,
          <KoButton key='create' loading={isLoading} type='primary' label={subCurrentRecord?.ticketId ? `Update` : `Create`}
            onClick={() => form.submit()} />
        ]}
      >
        {isSubLoading && <KoLoader circle={false} />}
        <TaskCrud form={form} handleFinish={handleFinish} initialValues={subCurrentRecord} profile={profile}  epic={true}
          epicId={currentRecord?.issueType === 1 ? currentRecord?.ticketId : null}
        />
      </KoModal>
    </div>
  );
};

export default Epic;