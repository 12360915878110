import React, { useCallback } from 'react';
import { KoFormBuilder, KoForm } from 'packages';
import styles from './employees.module.scss';

function ResetCrud({ form, handleFinish }) {
  const handleSubmit = useCallback(values => {
    handleFinish(values);
  });

  // const options = [
  //   { label: 'Automatically generate  a password', value: 'Yes' },
  //   { label: 'Create password', value: 'No' }];
  
  const meta = {
    columns: 4,
    formItemLayout: null,
    colon: true,
    fields: [
      { key: 'newPassword', placeholder:'Enter New password', label: 'New password', colSpan: 2, widget: 'password', rules: [
        {
          required: true,
          message: 'Password is required'
        }
      ] },
      { key: 'confirmPassword', placeholder:'Enter confirm password', label: 'Confirm password', colSpan: 2, widget: 'password', rules: [
        {
          required: true,
          message: 'Password is confirm required'
        },
        (e)=>({
          validator(_, value){
            if (form?.getFieldValue('newPassword')!==value) {
              return Promise.reject(new Error('Confirm Password not match!'));
            }
            return Promise.resolve();
          }
        })
      ] }
      // {
      //   colSpan: 4,
      //   key: 'autoGenerate',
      //   widget: 'radioGroup',
      //   direction: 'horizontal',
      //   options
      // }
    ]
  };

  // if (form.getFieldValue('autoGenerate') === 'No') {
  //   meta['fields'].push( { key: 'password', placeholder:'Enter password', label: 'Set password', colSpan: 2, widget: 'password', rules: [
  //     {
  //       required: true,
  //       message: 'Password is required'
  //     }
  //   ] });
  //   meta['fields'].push( { key: 'isChangePassword', colSpan: 4, widget: Checkbox.Group, options:[{label:'Ask user to change their password when they sign in', value: true}] });
  // }

  return (
    <div className={styles.wrapper}>
      <KoForm form={form} layout="vertical" onFinish={handleSubmit} onSubmit={handleSubmit}
      >
        <KoFormBuilder form={form} meta={meta} />
      </KoForm>
    </div>
  );
};

export default ResetCrud;