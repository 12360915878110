import React from 'react';

const Polls = () => {
  return (
    <div>
      Polls
    </div>
  );
};

export default Polls;
