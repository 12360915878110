import React, { useState, useRef } from "react";
import EmojiPicker, { Emoji } from "emoji-picker-react";
import KoEditorReadOnly from 'packages/editor/readOnly';
import KoDP from 'packages/dp';
import { KoText, KoTitle } from 'packages/typography';
import {KoDateFormatter, KoIcon, KoPopOver, KoButton,  Upload, KoToast, KoLoader} from 'packages';
import styles from './comments.module.scss';
import { getCookie } from 'shared/utils/cookies';
const KoComments = ({workspaceId, ticketId, data, onReaction, onReply, onDelete, onUploadFiles, onEdit}) => {
  const [currentComment, setCurrentComment] = useState(null);
  const [loader, setLoader] = useState(false);
  const commentRefs = useRef({});
  const onEmojiClick = (emojiObject) => {
    onReaction(emojiObject?.unified, currentComment);
  };

  const scrollToComment = (commentId) => {
    const targetRef = commentRefs.current[commentId];
    if (targetRef) {
      targetRef.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const onRemoveFile = (d) => {
    // onUploadFiles(d, currentComment);
  };

  const props = {
    name: 'files',
    multiple: true,
    action: `${process.env.REACT_APP_PROXY_HOST}/api/v1/org/upload`,
    data: { service: workspaceId, module: 'comments',folderId:ticketId, lookupId: currentComment?.commentId },
    headers: {
      Authorization: `Bearer ${getCookie("accessToken")}`
    },

    onChange(info) {
      setLoader(true);
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'done') {
        setLoader(false);
        onUploadFiles(currentComment);
        KoToast.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        KoToast.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  const emojiContent = (
    <div className={styles.popContent}>
      <EmojiPicker
        Theme="auto"
        height={450}
        width={350}
        emojiStyle="native"
        onEmojiClick={onEmojiClick}
        previewConfig={{ showPreview: false }}
        suggestedEmojisMode='recent'
        skinTonesDisabled={true}
        reactionsDefaultOpen={true}  />
    </div>
  );

  const getParentComment = (comment) => {
    if(comment?.parentCommentId) {
      console.log(data?.find((item) => item?.commentId === comment?.parentCommentId));
      return data?.find((item) => item?.commentId === comment?.parentCommentId);
    }
  };

  return (
    <div className={styles.wrapper}>
      {loader&& <KoLoader/>}
      {
        data.map((comment, index) => {
          return (
            <div key={index} className={styles.commentContainer}  ref={(el) => (commentRefs.current[comment.commentId] = el)} >
              <div className={styles.commentWrapper}>
                <div className={styles.commentHeader}>
                  <div className={styles.commentPic}>
                    <KoDP width={25} height={25} logo={comment?.profile?.logo} />
                  </div>
                  <div className={styles.commentName}>
                    <KoTitle level={5} className={styles.name} text={comment?.profile?.firstName} />
                    <KoTitle level={5} className={styles.name}  text={comment?.profile?.lastName} />
                  </div>
                  <div className={styles.dot}/>
                  <KoDateFormatter data={comment?.createdAt}  format={'DD-MMM-YYYY hh:mm a'}/>
                </div>
                <div className={styles.comment}>
                  <div className={comment?.parentCommentId ? styles.parentCommentBody : ''}>
                    {comment?.parentCommentId && <div className={styles.parentComment} onClick={() =>
                      scrollToComment(comment?.parentCommentId) // Scroll to parent comment
                    }>
                      <div className={styles.commentHeader}>
                        <div className={styles.commentPic}>
                          <KoDP width={25} height={25} logo={getParentComment(comment)?.profile?.logo} />
                        </div>
                        <div className={styles.commentName}>
                          <KoTitle level={5} className={styles.name} text={getParentComment(comment)?.profile?.firstName} />
                          <KoTitle level={5} className={styles.name}  text={getParentComment(comment)?.profile?.lastName} />
                        </div>
                        <div className={styles.dot}/>
                        <KoDateFormatter data={getParentComment(comment)?.createdAt}  format={'DD-MMM-YYYY hh:mm a'}/>
                      </div>
                      <div className={styles.commentBody}>
                        <KoEditorReadOnly initialValue={getParentComment(comment)?.content} />
                      </div>
                    </div>}
                  </div>
                  <div className={styles.element}>
                    <KoEditorReadOnly initialValue={comment?.content} />
                  </div>
                  <div className={styles.commentFooter}>
                    <div className={styles.replyContainer}>
                      <KoButton onClick={() => onReply(comment)} type='text' iconBtn shape='circle' icon={<KoIcon width={20} height={20} name="ReplyOutlined" />} />
                      <KoPopOver placement="bottomLeft" className={styles.emojiPop} arrow={false} content={emojiContent} trigger="click">
                        <KoButton onClick={() => setCurrentComment(comment)} type='text' iconBtn shape='circle' icon={<KoIcon width={20} height={20} name="SmileyOutlined" />} />
                      </KoPopOver>
                      <KoButton onClick={() => onEdit(comment)} type='text' iconBtn shape='circle' icon={<KoIcon width={20} height={20} name="EditOutlined" />} />
                      <Upload {...props}
                        onRemove={onRemoveFile}
                        // showUploadList={{showPreviewIcon : false}}
                        showUploadList={false}
                        showPreviewIcon={true}
                        multiple={true}
                      >
                        <KoButton onClick={() => setCurrentComment(comment)} type='text' iconBtn shape='circle' icon={<KoIcon width={20} height={20} name="AttachmentOutlined" />} />
                      </Upload>
                      <KoButton onClick={() => onDelete(comment)} type='text' iconBtn shape='circle' icon={<KoIcon width={16} height={16} name="DeleteOutlined" />} />
                    </div>
                    <div className={styles.reactionsContainer}>
                      <div className={styles.reactions}>
                        {
                          comment?.reactions?.map((reactObj, index) => {
                            return (
                              <div className={styles.reaction} key={index}>
                                <span className={styles.reactionIcon} >
                                  <Emoji
                                    size={17}
                                    unified={reactObj?.reaction}
                                  />
                                </span>
                                <KoText text={reactObj?.count} className={styles.reactionCount} />
                              </div>
                            );
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      }
    </div>
  );
};

export default KoComments;
