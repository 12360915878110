import React, { useState } from 'react';
import styles from './video.module.scss';
import Loading from '../loading';

const VideoViewer = (props) => {
  const [loading, setLoading] = useState(true);

  const onCanPlay = () => {
    setLoading(false);
  };

  const renderLoading = () => {
    return loading ? <Loading /> : null;
  };

  const visibility = loading ? 'hidden' : 'visible';

  return (
    <div className={styles.pgDriverView}>
      <div className={styles.videoContainer}>
        {renderLoading()}
        <video
          style={{ visibility }}
          controls
          type={`video/${props.fileType}`}
          onCanPlay={onCanPlay}
          src={props.filePath}
        >
          Video playback is not supported by your browser.
        </video>
      </div>
    </div>
  );
};

export default VideoViewer;
