
import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { KoCharts, KoTable, KoText, KoTitle,
  KoIcon, KoButton, KoDP, KoLoader,
  KoModal, KoBreadcrumb, KoForm, KoToast } from 'packages';
import { DONUT } from '../constant';
import { getTicketBreadCrumb, PRO_ITEMS_COLUMNS, TASKSTATUSLIST } from 'shared/commonConstants';
import PhoneInput from 'antd-phone-input';
import { DashbordAPI } from '../dashboard.service';
import TaskCrud from 'pages/taskPlanner/task/crud';
import { useDispatch } from 'react-redux';
import { setSelectedChatData, setSelectedChatType } from 'shared/store/chatSlice';
import styles from './myOverview.module.scss';
 
function MyOverview() {
  const users = useSelector(state => state.users);
  const dispatch = useDispatch();
  const [form] = KoForm.useForm();
  const [recent, setRecent] = useState();
  const [isIssueModel, setIssueModel] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [currentRecord, setCurrentRecord] = useState();
  const [breadCrumbs, setBreadCrumbs] = useState();
  const [profile, setProfile] = useState();
  const [statusList, setStatusList] = useState();
  const [pagination] = useState({
    size: 10,
    page: 1
  });

  useEffect(() => {
    getRecentMyTickets();
    getCount();
  }, []);


  const handleFinish = (values => {
    setLoading(true);
    DashbordAPI.createOrUpdateIssues(values, currentRecord?.ticketId, currentRecord?.workspace).then(
      (data) => {
        setLoading(false);
        setIssueModel(false);
        form.resetFields();
        getRecentMyTickets();
        KoToast.success(`Ticket ${currentRecord?.ticketId ? 'updated':'created'} successfully`);
      },
      (err) => {
        setLoading(false);
      }
    );
  });

  const getIssueById = (ticketId, workspaceId)=> {
    setLoading(true);
    DashbordAPI.getIssueById(ticketId, workspaceId).then(
      (data) => {
        setLoading(false);
        const updatedRoomObj = { ...data, source: 'tickets' };
        dispatch(setSelectedChatData(updatedRoomObj));
        dispatch(setSelectedChatType('tickets'));
        setCurrentRecord(data?.issue);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const getWorkspaceById=(workspaceId, ticketId)=>{
    DashbordAPI.getWorkspaceById(workspaceId).then(
      (data) => {
        setProfile(data['project'].teams);
        getIssueById(ticketId, workspaceId);
      },
      (err) => {
      }
    );
  };

  const getCount = () => {
    setLoading(true);
    DashbordAPI.getStatus().then(
      (data) => {
        setLoading(false);
        const statusMap = TASKSTATUSLIST.reduce((acc, status) => {
          acc[status.value] = status;
          return acc;
        }, {});
        
        const updatedIssueTypeCount = data['tickets'].map(issue => {
          const matchedStatus = statusMap[issue.status] || {};
          return {
            ...issue,
            ...matchedStatus
          };
        });
        
        DONUT['series'][0]['data'] = updatedIssueTypeCount.map((item) => ({
          value: item.count,
          name: item.name,
          itemStyle: {
            color: item.color,
            borderColor: `var(--palette-gray-1)`,
            borderWidth: .2
          }
        }));
        DONUT['series'][1]['data'] = updatedIssueTypeCount.map((item) => ({
          value: item.count,
          name: item.name,
          itemStyle: {
            color: item.color,
            borderColor: `var(--palette-gray-1)`,
            borderWidth: .2
          }
        }));
        DONUT['graphic']['style']['text']=`Total\n${data?.totalCount}`;
        setStatusList(DONUT);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const getRecentMyTickets = () => {
    setLoading(true);
    setRecent();
    DashbordAPI.getRecentTicket(pagination).then(
      (data) => {
        setRecent(data['tickets']);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const onSort = (key, type) => {
    let q = pagination;
    q['sort'] = `${key}:${type}`;
    getRecentMyTickets(q);
  };

  
  const onCellClick=(row)=>{
    let crumbs = getTicketBreadCrumb(row);
    setBreadCrumbs(crumbs);
    row['source'] = 'tickets';
    const updatedRoomObj = { ...row, source: 'tickets' };
    dispatch(setSelectedChatData(updatedRoomObj));
    dispatch(setSelectedChatType('tickets'));
    getWorkspaceById(row?.workspaceId, row?.ticketId);
    setIssueModel(true);
  };
  
  return (
    <>
      <div className={styles.wrapper}>
        { isLoading && <KoLoader />}
        <div className={styles.summary}>
          <div className={styles.summaryRow}>
            <div className={styles.card}>
              <div className={styles.header}>
                <KoTitle text={`Issues`} level={5} />
              </div>
              <div className={`${styles.bodyContnet} smallTable`}>
                <KoTable data={{ columns: PRO_ITEMS_COLUMNS, rows: recent }} onSort={onSort}
                  maxHeight={43} onCellClick={onCellClick}/>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.incomeExpenditure}>
          <div className={styles.header}>
            <KoTitle text='Task by status' level={5} />
          </div>
          <div className={styles.bodyContnet}>
            <div className={styles.graphDonut}>
              {statusList && <KoCharts option={statusList} />}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.usersList}>
        <div className={styles.card}>
          <div className={styles.header}>
            <KoTitle text='Your Team' level={4} />
          </div>
          <div className={styles.profileBodyContnet}>
            {users['data']?.map((item,i) =>
              <div  key={item?.userId} className={styles.profileCard}>
                <div className={styles.profileList}>
                  <div className={styles.userWrapper } >
                    <div className={`${styles.headCircle}  ${styles[item?
                      item?.profile?.firstName?.charAt(0).toLowerCase() :'a']}`}>

                      {item?.profile?.['logo'] ?
                      
                        <KoDP logo={item?.profile?.logo} width={item?.profile?.logo?.[0]?.name ? 30 : 25} height={item?.profile?.logo?.[0]?.name ? 30 : 25} shape={'circle'} userId={item?.userId} userDetails={item} name={item?.profile?.logo?.[0]?.name} />
                        :
                        <KoText className={`${styles.letter}`} text={item?.profile?.firstName?.charAt(0)}/>
                      }
                    </div>
                  </div>
                  <div className={styles.profileInfo}>
                    <div className={styles.details}>
                      <KoTitle level={5} ellipsis={true} text={`${item?.profile?.firstName} ${item?.profile?.lastName}`} />
                      <PhoneInput className={`${styles.mobile} mobileNo`} value={item?.mobile} disableDropdown readOnly />
                    </div>
                  </div>
                </div>
                <div className={styles.icons}>
                  <KoButton iconBtn={true} shape="circle" icon={<KoIcon name='EmailOutlined'  width={17} height={16}/>} />
                  <KoButton iconBtn={true} shape="circle" icon={<KoIcon name='ChatOutlined'  width={17} height={16}/>} />
                  <KoButton iconBtn={true} shape="circle" icon={<KoIcon name='OpenOutlined'  width={17} height={16}/>} />
                </div>

              </div>
            )}
          </div>
        </div>
        <KoModal
          title={ breadCrumbs ? <KoBreadcrumb items={breadCrumbs} /> : (currentRecord?.ticketId )
            ? `Update Issue ${(currentRecord?.ticketId).toUpperCase()}` : `Create Issue` }
          centered
          open={isIssueModel}
          afterClose={() => {setCurrentRecord();}}
          handleOk={() => {setCurrentRecord(); setIssueModel(false);form.resetFields(); }}
          onClose={() => {setCurrentRecord(); setIssueModel(false); form.resetFields();}}
          width={'95vw'}
          height={'95vh'}
          footer={[
            <KoButton key='cancel' label='Cancel' onClick={() => {setIssueModel(!isIssueModel); form.resetFields();}} />,
            <KoButton key='create' type='primary' label={currentRecord?.ticketId ? `Update` : `Create`}
              onClick={() => form.submit()} />
          ]}
        >
          <TaskCrud form={form} getIssueById={getIssueById} handleFinish={handleFinish} initialValues={currentRecord} workspaceProfile={profile}/>
        </KoModal>
      </div>
    </>
  );
 
}

export default MyOverview;