export const FormItemTypes = [
  {label:'Text Input',  type: 'input', icon:'TextOutlined'},
  {label:'Paragraph Text',  type: 'textarea', icon:'ParagraphOutlined'},
  {label:'Number Field',  type: 'number', icon:'NumbersOutlined'},
  {label:'Dropdown',  type: 'select', icon:'DropdownOutlined'},
  {label:'Assignees',  type: 'user', icon:'UsersOutlined'},
  {label:'Checkbox',  type: 'checkbox', icon:'CheckboxOutlined'},
  {label:'Choice',  type: 'radio', icon:'ChoiceOutlined'},
  {label:'Line Item',  type: 'table', icon:'LineOutlined'},
  {label:'Date picker',  type: 'date', icon:'CalendarOutlined'},
  {label:'Yes/No',  type: 'yesno', icon:'YesnoOutlined'},
  {label:'Attachment',  type: 'upload', icon:'AttachmentOutlined'},
  {label:'Website',  type: 'website', icon:'WebOutlined'},
  {label:'Customer',  type: 'customer', icon:'CustomerOutlined'},
  {label:'Project',  type: 'project', icon:'WorkspaceOutlined'},
  {label:'Country',  type: 'country', icon:'CountryOutlined'},
  {label:'Heading',  type: 'fieldset', icon:'CountryOutlined'},
  {label:'Divider',  type: 'divider', icon:'CountryOutlined'}
];
  