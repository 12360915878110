import { createSlice } from "@reduxjs/toolkit";
import { current } from "immer";


const initialState = {
  selectedChatType: undefined,
  selectedChatData: undefined,
  selectedChatMessages: [],
  directMessagesContacts: [],
  channels: {
    users: [],
    groups: [],
    tickets: []
  }
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {

    setSelectedChatType(state, action) {
      state.selectedChatType = action.payload;
    },
    setSelectedChatData(state, action) {
      state.selectedChatData = action.payload;
    },
    setChannels(state, action) {
      state.channels = action.payload;
    },
    setSelectedChatMessages(state, action) {
      state.selectedChatMessages = action.payload;
    },
    setDirectMessagesContacts(state, action) {
      state.directMessagesContacts = action.payload;
    },
    closeChat(state) {
      state.selectedChatData = undefined;
      state.selectedChatType = undefined;
      state.selectedChatMessages = [];
    },
    addMessage(state, action) {
      const { message, userId } = action.payload;
      if (!message || state.selectedChatData?.roomId !== message?.roomId) {
        return;
      }

      const selectedChatType = message?.source;
      if (message?.action === "update") {
        const messageIndex = state.selectedChatMessages.findIndex(
          (msg) => msg.messageId === message.messageId
        );
        if (messageIndex !== -1) {
          state.selectedChatMessages[messageIndex] = {
            ...state.selectedChatMessages[messageIndex],
            ...message
          };
        }
        return;
      }
      if (message?.action === "delete") {
        state.selectedChatMessages = state.selectedChatMessages.filter(
          (msg) => msg.messageId !== message.messageId
        );
        return;
      }
      if (message?.action === "reaction") {
        const messageIndex = state.selectedChatMessages.findIndex(
          (msg) => msg.messageId === message.messageId
        );
        if (messageIndex !== -1) {
          state.selectedChatMessages[messageIndex].reactions = message.reactions;
        }
        return;
      }
      state.selectedChatMessages.push({
        ...message,
        sender: message.sender ? message.sender : userId,
        recipient: selectedChatType === "users" ? message.recipient : null
      });
    },

    addContactInDMContacts(state, action) {
      const { message, userId } = action.payload;
      const directMessagesContactsData = current(state.directMessagesContacts);
      const isGroupChat = message?.source !== "users";
      let fromId;
      if (isGroupChat) {
        if (message.roomId) {
          fromId = message.sender === userId ? message.recipient : message.sender;
        }
      } else {
        fromId = message.sender === userId ? message.recipient: message.sender;
      }
      const dmContacts = [...(directMessagesContactsData[message?.source] || [])];
      const index = dmContacts?.findIndex((contact) =>
        isGroupChat ? contact?.groupId === fromId : contact?.userId === fromId
      );
      if (index !== -1 && index !== undefined) {
        const updatedRoomObj = { ...dmContacts[index], content: message?.content };
        const updatedDmContacts = [
          updatedRoomObj,
          ...dmContacts.slice(0, index),
          ...dmContacts.slice(index + 1)
        ];
        state.directMessagesContacts = {
          ...directMessagesContactsData,
          [message?.source]: updatedDmContacts
        };
      } else {
        console.warn('Contact not found for message update');
      }
    }
  }
});

export const {
  setSelectedChatType,
  setSelectedChatData,
  setChannels,
  setSelectedChatMessages,
  setDirectMessagesContacts,
  closeChat,
  addMessage,
  addContactInDMContacts
} = chatSlice.actions;

export default chatSlice.reducer;
