import React from "react";
import isString from 'lodash/isString';
import { useSelector } from "react-redux";
import styles from './user.module.scss';
import KoDP from "packages/dp";
import { KoText } from "packages/typography";

function User(props) {
  const users = useSelector(state => state?.users);
  let { data, row, onCellClick } = props;
  if(isString(data) || !data?.profileId){
    let id =  row?.userId || props.data;
    let matchedRecords = users['data'].filter(record => [id]?.includes(record.userId));
    let result = matchedRecords.map(({ profile, userId, logo }) => ({ profile, userId, logo }));
   
    data = result.length > 0 && result[0];
  }

  
  return (
    <div className={styles.userWrapper} onClick={()=>onCellClick && onCellClick(row)}>
      <div className={`${styles.circle}  ${styles[data?
        data?.profile?.firstName?.charAt(0).toLowerCase() :'a']}`}>
        {data?.profile?.['logo'] ?
          <KoDP logo={data?.profile?.['logo']} className={styles.profile} width={20} height={20} />
          :
          <KoText className={`${styles.letter}`} text={data?.profile?.firstName?.charAt(0)}/>}
      </div>
      <div className={`${styles.text}`}>{`${data?.profile?.firstName ? data?.profile?.firstName:''} ${data?.profile?.lastName ? data?.profile?.lastName:''}`}</div>
    </div>
  );
}
export default User;

