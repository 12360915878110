import React from "react";
import KoChat from "shared/chat";

function Chats() {
  return (
    <KoChat/>
  );
}

export default Chats;
