import { Form } from 'antd';
import { KoButton, KoForm, KoFormBuilder, KoIcon, KoLoader, KoTitle, KoToast, KoUserSelect } from 'packages';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { add, remove } from 'shared/store/breadcrumbSlice';
import styles from './admin.module.scss';
import { adminConsoleAPI } from './admin.service';

const Ownership = () => {
  const dispatch = useDispatch();
  const users = useSelector(state => state?.users);
  const [isLoading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(()=>{
    dispatch(remove());
    dispatch(add([
      {
        title: 'Admin Console',
        path: 'admin/settings'
      },
      {
        title: 'Ownership'
      }
    ]));

  },[]);

  const Assignees = ({ value, onChange }) =>
    <KoUserSelect value={value} onChange={v => onChange(v)} users={users?.data} placeholder='Select owner'/>;


  const meta = {
    columns: 2,
    formItemLayout: null,
    // initialValues,
    colon: true,
    fields: [{
      key: 'owner', label: '', colSpan: 4,
      widget: Assignees,
      forwardRef: true
    }]};

  const ChangeOwner= (values)=> {
    setLoading(true);
    adminConsoleAPI.ChangeOwnerByID(values?.owner).then(
      (data) => {
        setLoading(false);
        form.resetFields();
        KoToast.success("Owner Changed Successfully");
      },

      (err) => {
        setLoading(false);
      }
    );
  
  };

  return (
    <div className={styles.OwnerWrapper}>
      {isLoading && <KoLoader/>}
    
      <div className={styles.subWrapper}>
        <KoTitle text='Change Owner' level={3}/>
        <div className={styles.formWrapper}>

          <KoForm form={form} layout="vertical"
            onFinish={ChangeOwner} onSubmit={ChangeOwner}>
            <div>
              <KoFormBuilder form={form} meta={meta} />
            </div>
          </KoForm>
          <div>
          </div>
        </div>
        <KoButton className={styles.saveBtn} label={'Save'} type={'primary'} icon={<KoIcon color="#FFF" name="PlusOutlined" width={18} height={18} />}
          onClick={() => form.submit()}/>
      </div>
    </div>
  );
};

export default Ownership;
