import React from 'react';
import { KoForm, KoFormBuilder, Checkbox } from 'packages';
import styles from '../../template.module.scss';

export default function InputUpdate({form, currentItem,  currentProperties, handleSaveProperties}) {

  const InputMeta = {
    columns: 6,
    formItemLayout: null,
    initialValues:currentProperties,
    colon: true,
    fields: [
      {
        key: `label`, placeholder: 'Enter Label', label: 'Display Label', colSpan: 3, rules: [
          {
            required: true,
            message: `Please enter a label to display in the form`
          }
        ]
      },
      {
        key: `key`, placeholder: 'Enter Label', label: 'Key', colSpan: 3},
      {
        key: 'placeholder', placeholder: 'Enter placeholder', label: 'Placeholder', colSpan: 3, rules: [
          {
            required: true,
            message: `Enter placeholder.`
          }
        ]
      },
      {
        key: 'colSpan', placeholder: 'Enter colspan', label: 'Col Span', colSpan: 3, widget:'number'
      },
      { key: 'required', colSpan: 3, label: 'Is Required', widget: Checkbox, initialValues:false }
    ]
  };
  return (
    currentItem && (
      <div className={styles.propertyWrapper}>
        <KoForm form={form} layout="vertical"onFinish={handleSaveProperties}>
          <KoFormBuilder form={form} meta={InputMeta} />
        </KoForm>
      </div>
    )
  );
}