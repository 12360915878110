import React from "react";
function Preferences(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} fill="var(--palette-primary-darker)" viewBox="0 0 32 32" version="1.1">
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" >
          <g id="Icon-Set-Filled" transform="translate(-154.000000, -361.000000)" fill="var(--palette-primary-darker)">
            <path d="M165,367.601 C163.674,367.601 162.6,368.675 162.6,370 C162.6,371.325 163.674,372.4 165,372.4 C166.326,372.4 167.4,371.325 167.4,370 C167.4,368.675 166.326,367.601 165,367.601 L165,367.601 Z M174,375.6 C172.674,375.6 171.6,376.675 171.6,378 C171.6,379.325 172.674,380.4 174,380.4 C175.326,380.4 176.4,379.325 176.4,378 C176.4,376.675 175.326,375.6 174,375.6 L174,375.6 Z M180,371 L168.858,371 C168.413,372.723 166.862,374 165,374 C163.138,374 161.587,372.723 161.142,371 L160,371 C159.448,371 159,370.553 159,370 C159,369.448 159.448,369 160,369 L161.142,369 C161.587,367.277 163.138,366 165,366 C166.862,366 168.413,367.277 168.858,369 L180,369 C180.552,369 181,369.448 181,370 C181,370.553 180.552,371 180,371 L180,371 Z M180,379 L177.858,379 C177.413,380.723 175.862,382 174,382 C172.138,382 170.587,380.723 170.142,379 L160,379 C159.448,379 159,378.553 159,378 C159,377.448 159.448,377 160,377 L170.142,377 C170.588,375.277 172.138,374 174,374 C175.862,374 177.413,375.277 177.858,377 L180,377 C180.552,377 181,377.448 181,378 C181,378.553 180.552,379 180,379 L180,379 Z M180,387 L168.858,387 C168.413,388.723 166.862,390 165,390 C163.138,390 161.587,388.723 161.142,387 L160,387 C159.448,387 159,386.553 159,386 C159,385.447 159.448,385 160,385 L161.142,385 C161.587,383.278 163.138,382 165,382 C166.862,382 168.413,383.278 168.858,385 L180,385 C180.552,385 181,385.447 181,386 C181,386.553 180.552,387 180,387 L180,387 Z M182,361 L158,361 C155.791,361 154,362.791 154,365 L154,389 C154,391.209 155.791,393 158,393 L182,393 C184.209,393 186,391.209 186,389 L186,365 C186,362.791 184.209,361 182,361 L182,361 Z M165,383.601 C163.674,383.601 162.6,384.675 162.6,386 C162.6,387.325 163.674,388.4 165,388.4 C166.326,388.4 167.4,387.325 167.4,386 C167.4,384.675 166.326,383.601 165,383.601 L165,383.601 Z" id="preferences" >
            </path>
          </g>
        </g>
      </g>
    </svg>
  );
}


export default Preferences;