import { KoButton, KoDropdown, KoEmpty, KoFlex, KoForm, KoIcon, KoLoader, KoModal, KoNano, KoPagination, KoTable, KoText, KoTitle, KoToast, KoTooltips, KoYear } from "packages";
import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { add, remove } from 'shared/store/breadcrumbSlice';
import KoToolBar from "shared/components/toolbar";
import { adminConsoleAPI } from '../admin.service';
import { LEV_ITEMS_COLUMNS } from './constant';
import HolidayCrud from "./crud";
import styles from './holiday.module.scss';

const HoliDayCalendar = (props) => {
  const dispatch = useDispatch();
  const [totalCount, setTotalCount] = useState(100);
  const [holidayList, setHolidayList] = useState();
  const [isOpen, setModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState();
  const [isOpenDelete, setDeleteModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [availabilityData, setAvailabilityData] = useState();
  const [year, setYear] = useState(new Date());
  const [form] = KoForm.useForm();
  const [pagination, setPagination] = useState({
    size: 10,
    page: 1
  });

  const onRow = (record) => {
  };

  useEffect(() => {
    dispatch(remove());
    dispatch(add([
      {
        title: 'Admin Console',
        path: 'admin/settings'
      },
      {
        title: 'Holiday Calendar'
      }
    ]));


    getHolidays();
    getAvailability();
  }, []);

  const getHolidays = () => {
    setHolidayList();
    setLoading(true);
    adminConsoleAPI.getHolidays(pagination).then(
      (data) => {
        setLoading(false);
        setHolidayList(data?.holidays);
        setTotalCount(data['totalCount']);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const onSearchText = (text) => {
    let q = pagination;
    if (text) {
      q['search'] = text;
    }
    else {
      delete q?.['search'];
    }
    getHolidays(q);
  };

  const onSearchYear = (text) => {
    let q = pagination;
    if (text) {
      q['year'] = text.year().toString();
      setYear(text);
    }
    else {
      delete q?.['year'];
    }
    getHolidays(q);
  };

  const onSort = (key, type) => {
    let q = pagination;
    q['sort'] = `${key}:${type}`;
    getHolidays(q);
  };

  const onPagination = (page, other) => {
    const query = {
      size: other,
      page: page,
      offset: (page - 1) * other
    };
    const pQuery = Object.assign(pagination, query);
    setPagination(pQuery);
    getHolidays(pQuery);
  };


  const handleFinish = (values => {
    setLoading(true);
    if (!currentRecord?.holidayId) {
      values['holidayId'] = KoNano('HOL');
    }

    values['year'] = new Date().getFullYear().toString();
    adminConsoleAPI.createOrUpdateHoliday(values, currentRecord?.holidayId).then(
      (data) => {
        getHolidays();
        setLoading(false);
        setModal(false);
        form.resetFields();
        KoToast.success(`Holiday ${currentRecord?.holidayId ? 'updated' : 'created'} successfully!`);

      },

      (err) => {
        setLoading(false);
      }
    );
  });

  const DeleteHoliday = () => {
    setLoading(true);
    adminConsoleAPI.deleteHoliday(currentRecord?.holidayId).then(
      (data) => {
        setLoading(false);
        setDeleteModal(false);
        KoToast.success("Holiday Deleted Successfully");
        getHolidays();
      },
      (err) => {
        setLoading(false);
      }
    );
  };


  const getAvailability = () => {
    setHolidayList();
    adminConsoleAPI.getAvailability().then(
      (data) => {
        setAvailabilityData(data?.availabilities[0]);
      },
      (err) => {
      }
    );
  };


  const updateAvailabilityForDay = (currentData, dayToUpdate, newWorkDayStatus) => {
    const updatedData = { ...currentData };

    updatedData.availability = updatedData?.availability?.map(day =>
      day.weekDay === dayToUpdate? { ...day, workDay: !newWorkDayStatus?.workDay }: day
    );

    return updatedData;
  };




  const handleFinishAvailability = (dayToUpdate, newWorkDayStatus) => {
    setLoading(true);
    
    const updatedData = updateAvailabilityForDay(availabilityData, dayToUpdate, newWorkDayStatus);

    adminConsoleAPI.updateAvailability(updatedData, availabilityData?.availabilityId)
      .then((data) => {
        KoToast.success("Availability Updated Successfully");
        getAvailability();
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      } );
  };




  const items = [
    {
      key: '2',
      danger: true,
      label: 'Delete',
      icon: <KoIcon name='DeleteOutlined' />
    }
  ];

  const onHandelSprint = (e, row) => {
    if (e.key === "2") {
      setDeleteModal(true);
    }
  };
  const columnsRight = [{
    title: '',
    dataIndex: 'x',
    key: 'x',
    fixed: 'right',
    width: 40,
    render: (text, row) => {
      return <div className={`${styles.circle} tableBtn columnsRight`} onClick={() => setCurrentRecord(row)}>
        <KoTooltips title={'Edit'}>
          <KoButton className={'btn'} iconBtn={true} icon={<KoIcon width={14} height={14} name={'EditOutlined'} />} onClick={() => setModal(true)} />
        </KoTooltips>
        <div shape="circle">
          <KoDropdown
            menu={{
              items,
              onClick: (e) => onHandelSprint(e, row)
            }}
            overlayClassName={styles.actionDropdown}
            placement="bottomLeft"
          >
            <div shape="circle" >
              <KoIcon name="VerticalMenuOutlined" onClick={() => (row)} />
            </div>
          </KoDropdown>
        </div>
      </div>;
    }
  }];

  
  return (
    <div className={styles.wrapper}>
      {isLoading && <KoLoader />}
      <KoTitle text={'Availability'} level={5} />
      <div className={styles.weekendsWrapper}>
        <KoText text={'Weekends are designated company off-days, providing employees with time to rest and recharge outside of work hours'} className={styles.sunday} />
        <div className={styles.weekends}>
          {availabilityData?.availability?.map((day, index) => (
            <KoButton shape="circle" key={index} type={day?.workDay ? "primary" : "default"} label={day?.weekDay?.charAt(0)} onClick={() => handleFinishAvailability(day?.weekDay, day)} />
          ))}
        </div>
      </div>

      <KoToolBar
        title={'Holiday Calender'}
        search={{ visible: true, onChange: (e) => { onSearchText(e); }, placeholder: 'Search by any parameter' }}
        filter={{ visible: false }}
        buttonList={[{
          label: 'Create',
          type: 'primary',
          icon: <KoIcon color="#FFF" name="PlusOutlined" width={18} height={18} />, onClick: () => setModal(!isOpen)
        }]}
        extraComponentBefore={<KoYear value={year} onChange={(e) => onSearchYear(e)} />}
      />
      {holidayList?.length > 0 ? <>
        <KoTable
          rowKey='holidayId'
          onRow={onRow}
          onSort={onSort}
          data={{ columns: LEV_ITEMS_COLUMNS, rows: holidayList }} extraColumnsRight={columnsRight} maxHeight={50} />
        <KoPagination current={parseInt(pagination.page) || 1}
          total={totalCount}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} records`
          }
          pageSize={pagination.limit}
          defaultCurrent={pagination.page}
          onChange={onPagination}
          showQuickJumper={false}
          simple={false}
          responsive={false} />
      </>
        :

        <KoFlex wrap="wrap" gap="small" className={styles.emptySection}>
          <KoEmpty onClick={() => setModal(!isOpen)} label="Create Holiday" icon={'EmptyTwoTone'} title="Create a new Holiday" />
        </KoFlex>}

      <KoModal
        title={`${currentRecord?.holidayId ? 'Update' : 'Create'} Holiday`}
        centered
        open={isOpen}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setModal(false); }}
        onClose={() => { setCurrentRecord(); setModal(false); }}
        width={580}
        // maskClosable={false}
        height={650}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setModal(!isOpen)} />,
          <KoButton key='create' loading={isLoading} type='primary' label={currentRecord?.holidayId ? `Update` : `Create`}
            onClick={() => form.submit()} />
        ]}
      >
        <HolidayCrud form={form} initialValues={currentRecord} handleFinish={handleFinish} />
      </KoModal>

      <KoModal
        title={`Delete Holiday ${currentRecord?.title?.toUpperCase()}`}
        centered
        open={isOpenDelete}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setDeleteModal(false); }}
        onClose={() => { setCurrentRecord(); setDeleteModal(false); }}
        width={580}
        // maskClosable={false}
        height={450}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setDeleteModal(!isOpenDelete)} />,
          <KoButton key='delete' loading={isLoading} type='primary' label='Delete' onClick={() => DeleteHoliday()} />
        ]}
      >
        <div className={styles.delWrapper}>
          <KoTitle level={5} text={`This action will delete item and cannot be undone`} />
          <KoText text={`Do you wish to proceed?`} />
        </div>
      </KoModal>
    </div>
  );
};

export default HoliDayCalendar;