import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  KoCard, KoFlex, KoImage, KoTitle, KoAffix, KoText,
  KoDropdown, KoIcon, KoEmpty, KoNano, KoModal, KoButton, KoLoader, KoDateFormatter,
  KoToast
} from 'packages';
import KoToolBar from 'shared/components/toolbar';
import { clientsAPI } from './customers.service';
import { add, remove } from 'shared/store/breadcrumbSlice';
import styles from './customers.module.scss';

function Clients() {
  const [rowList, setDataList] = useState();
  const [currentRecord, setCurrentRecord] = useState();
  const [isLoading, setLoading] = useState(false);
  const [isOpenDelete, setDeleteModal] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();

  const onCreateNewClient = () => {
    let values = {};
    values['customerId'] = KoNano('CUS');
    clientsAPI.createOrUpdateClient(values).then(
      (data) => {
        setLoading(false);
        navigate(`/admin/customers/${values['customerId']}`);
      },
      (err) => {
        setLoading(false);
      }
    );

  };


  useEffect(() => {
    dispatch(remove());
    dispatch(add([
      {
        title: 'Admin Console',
        path: 'admin/settings'
      },
      {
        title: 'customers'
      }
    ]));


    getClients();
  }, []);



  const getClients = () => {
    setLoading(true);
    clientsAPI.getClients().then(
      (data) => {
        setDataList(data['customers']);
        // setTotalCount(data['totalCount']);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  // const onSearchText=(text)=>{
  //   let q = pagination;
  //   if(text){
  //     q['search'] = text;
  //   }
  //   else{
  //     delete q?.['search'];
  //   }
  //   getClients(q);
  // };



  const items = [
    {
      label: "Update Customer",
      key: "1",
      icon: <KoIcon name="EditOutlined" height={12} width={13} />
    },
    {
      label: "Delete",
      key: "2",
      danger: true,
      icon: <KoIcon name="DeleteOutlined" height={12} width={13} />
    }
  ];

  const onClick = ({ key }, client) => {
    if (key === "2") {
      setDeleteModal(true);

    } else if (key === "1") {
      navigate(`/admin/customers/${client?.customerId}`);
    }
  };

  const handleFinishDelete = () => {
    setLoading(true);
    clientsAPI.deleteById(currentRecord?.customerId).then(
      (data) => {
        getClients();
        KoToast.success('Delete successfully');
        setDeleteModal(false);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  return <div className={styles.wrapper}>
    {isLoading && <KoLoader />}
    <KoAffix top={56}>
      <KoToolBar
        className={styles.toolbar}
        title={'Customers'}
        search={{ visible: true, onChange: (e) => { }, placeholder: 'Search by client name' }}
        buttonList={[{
          label: 'Create Customer',
          type: 'primary',
          icon: <KoIcon color="#FFF" name="PlusOutlined" width={18} height={18} />,
          onClick: () => onCreateNewClient()
        }]} />
    </KoAffix>
    {rowList?.length > 0 ? <KoFlex wrap="wrap" gap="small" className={styles.listSection}>

      {rowList?.map((client, i) =>
        <KoCard onClick={() => setCurrentRecord(client)} key={client?.customerId} className={styles.card}
          hoverable={true}>
          <div className={styles.actions}>
            <KoDropdown
              placement="bottomRight"
              overlayClassName={'overlayClassName'}
              menu={{
                items,
                onClick: (e) => onClick(e, client)
              }}
            >
              <div onClick={(e) => e.preventDefault()}>
                <KoIcon name="MenuOutlined" />
              </div>
            </KoDropdown>

          </div>
          <Link to={`/admin/customers/${client?.customerId}`}>
            <div className={styles.titleKey}>
              <KoImage
                src={`${client?.logo ? client?.logo[0]?.url : ''}`}
                width={35}
                preview={false}
                alt={`doc${i % 3}`}
              />
              <div className={styles.titleValue}>
                <div className={styles.verified}>
                  <KoText className={`${styles.text}`} text={client?.companyName} ellipsis={true} />
                  <KoIcon name='TickFilled' />
                </div>
                <KoTitle className={styles.title} ellipsis={true} text={client?.url} level={4} />
              </div>

            </div>
            <KoText className={`${styles.key} ${styles[client?.domain ? client?.domain?.charAt(0).toLowerCase() : 'a']}`}
              text={`${client?.domain}`} />
            <div className={styles.description} >
              <KoText text={`${client?.address?.city ? client?.address?.city + ' ' + client?.address?.country : '-'}`} />
            </div>
            <div className={styles.link}>
              <div className={styles.row}>
                <KoText className={styles.date} text='Created on' />
                <KoDateFormatter format='DD-MMM-YYYY' data={client?.createdAt} className={styles.date} />
              </div>
              {/* <Link to={`/customers/${client?.customerId}`}>
              <span>Open</span>
              <KoIcon name='ArrowFilled' width={12} height={10}/>
              </Link> */}
            </div>
          </Link>
        </KoCard>
      )}
    </KoFlex> :
      <KoFlex wrap="wrap" gap="small" className={styles.emptySection}>
        <KoEmpty onClick={() => onCreateNewClient()} label="Create Customer" icon={'EmptyTwoTone'} title="Create a new customer" />
      </KoFlex>}

    <KoModal
      title={`Delete Customer ${currentRecord?.companyName?.toUpperCase()}`}
      centered
      open={isOpenDelete}
      afterClose={() => {setCurrentRecord();}}
      handleOk={() => { setCurrentRecord(); setDeleteModal(false); }}
      onClose={() => { setCurrentRecord(); setDeleteModal(false); }}
      width={580}
      // maskClosable={false}
      height={450}
      footer={[
        <KoButton key='cancel' label='Cancel' onClick={() => setDeleteModal(!isOpenDelete)} />,
        <KoButton key='reset' loading={isLoading} type='primary' label='Delete' onClick={() => handleFinishDelete()} />
      ]}
    >
      {isLoading && <KoLoader circle={false} />}
      <div className={styles.delWrapper}>
        <KoTitle level={5} text={`Are you sure you want to delete this Customer?`} />
        <KoText text={`This action is irreversible, and it will result in the removal of their associated data.`} />
      </div>
    </KoModal>


  </div>;
}

export default Clients;
