import React from "react";
function Left(props) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 32 32"
      width={width}
      height={height}
    >
      <path
        d="M30 3H2a1 1 0 000 2h28a1 1 0 000-2zM20 27H2a1 1 0 000 2h18a1 1 0 000-2zM2 13h18a1 1 0 000-2H2a1 1 0 000 2zm28 6H2a1 1 0 000 2h28a1 1 0 000-2z"
        className="color000 svgShape"
      />
    </svg>
  );
}


export default Left;


