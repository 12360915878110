/* eslint-disable no-unused-expressions */
import { Form, Input, InputNumber, Table, Upload } from 'antd';
import { createStyles } from 'antd-style';
import { getCookie } from 'shared/utils/cookies';
import { KoAvatar, KoModal, KoText, KoToast } from 'packages';
import KoButton from 'packages/button';
import KoDate from 'packages/date';
import KoDescriptionEditor from 'packages/editor/description';
import KoEditorReadOnly from 'packages/editor/readOnly';
import KoIcon from 'packages/icon';
import { KoSelect } from 'packages/select';
import React, { useContext, useState } from 'react';
import css from './datatable.module.scss';
const EditableContext = React.createContext(null);
const KoEditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const useStyle = createStyles(({ css, token }) => {
  const { antCls } = token;
  return {
    customTable: css`
      ${antCls}-table {
        ${antCls}-table-container {
          ${antCls}-table-body,
          ${antCls}-table-content {
            scrollbar-width: thin;
            scrollbar-color: unset;
          }
        }
      }
    `
  };
});

const KoEditableCell = ({
  title,
  editable,
  children,
  widget,
  dataIndex,
  placeholder,
  readonly,
  record,
  options,
  unAssignee = true,
  daysAgo,
  handleSave,
  addRow,
  width,
  onSave,
  dataSource,
  handleAddRow,
  handleDeleteRow,
  ...restProps
}) => {
  const form = useContext(EditableContext);
  const [isOpen, setModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [active, setActive] = useState();

  const onActionBtn = (data) => {
    setActive(data);
    setModal(true);
  };
  
  const onUpdate = async(e, i) => {
    setActive(e.target.value);
    handleSave({
      ...record,
      description: e.target.value
    });

  };

  const getData = async(a) => {
    const values = await form.validateFields();
    handleSave({
      ...record,
      ...values
    });
    setTimeout(() => {
      setLoader(true); // hack
      setTimeout(() => {
        setLoader(false);
      }, 10);
    }, 1);
  };

  const save = async() => {
    try {
      const values = await form.validateFields();
      handleSave({
        ...record,
        ...values
      });
      onSave({
        ...record,
        ...values
      });
    } catch (errInfo) {
    }

  };


  const uploadButton = (
    <div className={css.dndArea}>
      <KoIcon name='AttachmentOutlined' />
    </div>
  );

  const props = {
    name: 'files',
    action: `${process.env.REACT_APP_PROXY_HOST}/api/v1/org/upload`,
    data: { service: 'lineitem', module: 'attachment', lookupId: record?.key },
    headers: {
      Authorization: `Bearer ${getCookie("accessToken")}`
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'done') {
        KoToast.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        KoToast.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  let childNode = children;
  const handleKeyPress = (event) => {
    if (!/[0-9]/.test(event.key) && event.key !== 'Backspace' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
      event.preventDefault();
    }
  };
  if (editable) {

    switch (widget) {
    case 'date':
      childNode = (
        <Form.Item
          className={css.formField}
          initialValue={record[dataIndex]}
          name={dataIndex}
        >
          <KoDate format={'DD-MMM-YYYY'}
            placeholder={placeholder || ''}
            daysAgo={daysAgo}
            onChange={save}
            onBlur={save} />
        </Form.Item>
      );
      break;
    case 'number':
      childNode = (
        <Form.Item
          className={css.formField}
          initialValue={record[dataIndex]}
          name={dataIndex}
        >
          <InputNumber
            className={readonly ? css.numberText : ''}
            onChange={save}
            readOnly={readonly}
            controls={false}
            placeholder={placeholder || ''}
            onPressEnter={save}
            onBlur={save}
          />
        </Form.Item>
      );
      break;
    case 'rate':
      childNode = (
        <Form.Item
          className={css.formField}
          initialValue={record[dataIndex]}
          name={dataIndex}
        >
          <InputNumber
            className={readonly ? css.numberText : ''}
            onChange={save}
            readOnly={readonly}
            controls={false}
            placeholder={placeholder || ''}
            onPressEnter={save}
            onBlur={save}
            onKeyDown={handleKeyPress}
            parser={value => {
              const numericValue = value.replace(/[^0-9]/g, '');
              return numericValue === '' ? '' : Number(numericValue);
            }}
            formatter={value => {
              return value === '' ? '' : value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }}
          />
        </Form.Item>
      );
      break;
    case 'options':
      childNode = (
        <Form.Item
          className={css.formField}
          initialValue={record[dataIndex]}
          name={dataIndex}
        >
          <KoSelect
            placeholder={placeholder || ''}
            onChange={save}
            disabled={readonly}
            onBlur={save}
            options={options}
            showSearch={true}
          />
        </Form.Item>
      );
      break;
    case 'UserSelect':
      childNode = (
        <Form.Item
          className={css.formField}
          initialValue={record[dataIndex]}
          name={dataIndex}
        >
          <KoSelect
            value={record[dataIndex]?.value || null}
            placeholder={placeholder || ''}
            onChange={(val, obj) => { form.setFieldValue(dataIndex, obj.value); save(val);  form.setFieldValue('designation', obj?.data?.designation ? obj?.data?.designation : 'No Designation'); }}
            onBlur={save}
            options={
              [
                ...options.map((user) => ({
                  label: `${user.profile?.firstName} ${user.profile?.lastName}`,
                  value: user?.userId,
                  emoji: user?.profile?.logo,
                  desc: user?.email,
                  data: {
                    designation : user?.designation,
                    reportTo : user?.reportingTo
                  }
                })),

                ...(unAssignee ? [{
                  label: 'Unassigned',
                  value: 'unassigned',
                  emoji: '',
                  desc: 'unassigned',
                  data: {
                    designation : 'No Designation',
                    reportTo : 'unassigned'
                  }
                }] : [])
              ]
            }
            showSearch={true}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            optionRender={(option) => (
              <div className="userDpWithName">
                <KoAvatar
                  inLine="0rem"
                  size={30}
                  options={[{ name: option.data.label, logo: option?.data.emoji }]}
                />
                <div className="userDpWithNameEmail">
                  <KoText className="name" text={option.data.label} />
                  <KoText className="email" text={option.data.desc} />
                </div>
              </div>
            )}
          />
        </Form.Item>
      );
      break;

    case 'tax':
      childNode = (
        <Form.Item
          className={css.formField}
          initialValue={record[dataIndex]}
          name={dataIndex}>
          <KoSelect
            mode="multiple"
            placeholder="select tax"
            onChange={(val, obj) => { form.setFieldValue(dataIndex, obj); }}
            onSelect={save}
            options={options?.map((tax) => ({
              label: `${tax?.taxName} (${tax?.taxRate}%)`,
              value: tax?.key,
              percent: tax?.taxRate
            }))}
            showSearch={true}
          />
        </Form.Item>
      );
      break;
    case 'attachment':
      childNode = (
        <Form.Item
          className={css.formField}
          initialValue={record[dataIndex]}
          name={dataIndex}
        >
          <Upload {...props}
            className={`smallUpload`}
            showUploadList={{ showPreviewIcon: false }}
            listType="picture-card"
          >
            {uploadButton}
          </Upload>
        </Form.Item>
      );
      break;
    case 'discount':
      childNode = (
        <Form.Item
          className={css.formField}
          initialValue={record[dataIndex]}
          name={dataIndex}>
          <KoSelect
            mode="multiple"
            placeholder="select discount"
            onChange={(val, obj) => { form.setFieldValue(dataIndex, obj); }}
            onSelect={save}
            options={options?.map((discount) => ({
              label: `${discount?.discountName} (${discount?.discountRate}%)`,
              value: discount?.key,
              percent: discount?.discountRate
            }))}
            showSearch={true}
          />
        </Form.Item>
      );
      break;
    case 'text':
      childNode = (
        <Form.Item
          initialValue={record[dataIndex]}
          className={css.formField}
          name={dataIndex}
        >
          <Input
            placeholder={placeholder || ''}
            onPressEnter={save}
            onBlur={save}
          />
        </Form.Item>
      );
      break;
    case 'amount':
      childNode = (
        <div className={css.colWrapper}>
          <div className={css.amountText}>
            {Math.round(record[dataIndex] * 100) / 100 || '00'}
          </div>
        </div>
      );
      break;
    case 'richText':
      childNode = (
        <>
          <Form.Item
            initialValue={record[dataIndex]}
            className={css.formField}
            name={dataIndex}>
            <div onClick={() => onActionBtn(record[dataIndex])} className={css.popoverContent}>
              <KoEditorReadOnly
                ellipsis={true}
                initialValue={record[dataIndex]}
                status={loader}
                text="Enter sprint goal"
              />
            </div>
          </Form.Item>
          <KoModal
            title={`${record[dataIndex] ? 'Update' : 'Add'} Sprint Goal`}
            centered
            open={isOpen}
            handleOk={() => setModal(false)}
            onClose={() => setModal(false)}
            width={580}
              // maskClosable={false}
            footer={[
              <KoButton
                onClick={() => setModal(false)}
                key="close"
                label="Close"
              />,
              <KoButton
                onClick={() => { setModal(false); getData(); }}
                key="create"
                type="primary"
                label="Done"
              />
            ]}
          >
            <KoDescriptionEditor
              value={active}
              form={form}
              initialValue={record[dataIndex]}
              onChange={(e) => onUpdate(e, dataIndex)}
              // onChange={(e) => setActive(e.target.value)}
            />
          </KoModal>
        </>
      );
      break;
    default:
      break;
    }
  }
  return <td {...restProps}>{childNode}</td>;
};


const KoDataTableEdit = ({ dataSource, footer, onRow, maxHeight, expandableRow, rowKey, defaultColumns, onSave, handleAddRow,
  handleDeleteRow }) => {
  const [expandedRow, setExpandedRow] = useState(0);
  const components = {
    body: {
      row: KoEditableRow,
      cell: KoEditableCell
    }
  };

  const columns = defaultColumns?.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      width: col.width,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        widget: col.widget,
        handleSave,
        unAssignee: col?.unAssignee,
        readonly: col.readonly,
        options: col?.options || [],
        placeholder: col?.placeholder,
        dataSource: dataSource,
        handleDeleteRow: handleDeleteRow,
        handleAddRow: handleAddRow,
        daysAgo: col?.daysAgo
      })
    };
  });

  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row
    });
    onSave(newData);
  };

  const expandedRowRender = () => {
    return (
      <div className={css.subTable}>
        {expandableRow?.content}
      </div>
    );

  };

  let expandable = {};
  if (expandableRow) {
    expandable['expandedRowRender'] = expandedRowRender;
    expandable['expandedRowKeys'] = [expandedRow];
    expandable['defaultExpandedRowKeys'] = [0];
    expandable['expandIcon'] = ({ expanded, onExpand, record }) =>
      expanded ? (
        record[expandableRow?.countBased] <= 1 ? "" : <KoIcon name="RowCloseOutlined"
          onClick={(e) => {
            onExpand(record, e);
            setExpandedRow(!expanded ? record[rowKey] : undefined);
            onExpand(record, e);
          }} />
      ) : (
        record[expandableRow?.countBased] <= 1 ? "" : <KoIcon name="RowOpenOutlined"
          onClick={(e) => {
            onExpand(record, e); setExpandedRow(!expanded ? record[rowKey] : undefined);
            onExpand(record, e);
          }} />
      );
  }

  const { styles } = useStyle();

  return (
    <div className={`${css.dataWrapper} ${css.editWrapper} editWrapper`}>
      <Table
        className={styles.customTable}
        components={components}
        rowClassName={() => 'editable-row'}
        pagination={false}
        footer={footer ? footer : false}
        onRow={(record, index) => ({
          onClick: () => { onRow ? onRow(record, index) : (() => { }); }
        })}
        dataSource={dataSource}
        columns={columns}
        expandable={expandable}
        scroll={{
          x: 'max-content',
          y: maxHeight ? maxHeight * 5 : 100 * 5
        }}
      />
    </div>
  );
};

export {
  KoDataTableEdit
};
