import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { OrgAPI } from "pages/organization/org.service";
const initialState = {
  data: [],
  state: ""
};
export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(getUsers.fulfilled, (state, action) => {
        state.data = action.payload;
        state.state = "success";
      })
      .addCase(getUsers.pending, (state, action) => {
        state.state = "pending";
      });
  }
});

export const getUsers = createAsyncThunk('users/get', async(query) => {
  let data = await OrgAPI.getActiveUsers();
  return data['users'];

});


export default usersSlice.reducer;