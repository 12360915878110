import { KoDate, KoSelect, KoText, KoUserSelect } from 'packages';
import { useSelector } from 'react-redux';
import { PRIORITY, STATUS } from 'shared/commonConstants';
import styles from './curd.module.scss';

const Priority = ({ value, onChange }) =>
  <KoSelect value={value}
    showSearch
    defaultValue={3}
    onChange={v => onChange(v)}
    placeholder='Select priority'
    options={PRIORITY}
    disabled={true}
    optionRender={(option) => (
      <div className={styles.priorityWrapper}>
        <span>{option.data.label}</span>
      </div>
    )}
  />;

const TaskStatus = ({ value, onChange }) => (
  <KoSelect
    value={value}
    showSearch
    defaultValue={STATUS[0].value}
    onChange={v => onChange(v)}
    placeholder='Select status'
    disabled={true}
    options={STATUS}
    optionRender={(option) => {
      const label = option?.data?.label;
      const formattedLabel = typeof label === 'string' ? label.replace(" ", '') : '';
      return (
        <div>
          <div className={`${styles.status} ${styles[formattedLabel]}`} role="img" aria-label={label} />
          <KoText text={label} />
        </div>
      );
    }}
  />
);

// const IssueType = ({ value, onChange }) => (
//   <KoSelect
//     value={epic ? 1 : value}
//     onChange={v => onChange(v)}
//     showSearch
//     disabled={subtask || initialValues?.issueType === 4 || epic}
//     placeholder="Select issue type"
//     options={(subtask || initialValues?.issueType === 4) ? ISSUETYPE : ISSUETYPEWITHOUTSUBTASK}
//     optionRender={(option) => (
//       <div className={styles.iconWithLabel} role="img" aria-label={option.data.label}>
//         {option.data.value === 1 && <KoIcon name="EpicFilled" />}
//         {option.data.value === 2 && <KoIcon name="StoryFilled" />}
//         {option.data.value === 3 && <KoIcon name="TaskFilled" />}
//         {option.data.value === 4 && <KoIcon name="SubtaskFilled" />}
//         {option.data.value === 5 && <KoIcon name="BugFilled" />}
//         {option.data.value === 6 && <KoIcon name="ImprovementsFilled" />}
//         {option.data.value === 7 && <KoIcon name="FeatureRequestFilled" />}
//         {option.data.value === 8 && <KoIcon name="ChangerequestFilled" />}
//         {option.data.value === 9 && <KoIcon name="SupportFilled" />}
//         {option.data.label}
//       </div>
//     )}
//   />
// );

const Assignees = ({ value, onChange }) =>{
  const wosAssignees = useSelector(state => state?.wosAssignees);
  return (
    <KoUserSelect  value={value} onChange={v => onChange(v)} users={wosAssignees[0]} placeholder='Select assignee' />);
};
    
    
const Reporter = ({ value, onChange }) =>{
  const wosAssignees = useSelector(state => state?.wosAssignees);
  return (
    <KoUserSelect value={value} onChange={v => onChange(v)} users={wosAssignees[0]} placeholder='Select reporter' />);
};


const Sprint = ({ value, onChange }) =>{
  const sprints = useSelector(state => state?.sprints);
  return (
    <KoSelect value={value || undefined}
      onChange={v => onChange(v)}
      showSearch
      allowClear
      disabled={true}
      placeholder='Select sprint'
      options={sprints['data']?.map((spr) => ({
        label: spr?.name + `${spr?.status === 2 ? '  (active sprint)' : ''}`,
        value: spr?.sprintId
      }))}
    />
  );
};

const Date = ({ value, onChange }) =>
  <KoDate  disabled={true} value={value} format={'DD-MMM-YYYY'} daysAgo={0}
    onChange={v => onChange(v)} />;

const LINKS = [
  {
    label: 'Is blocked by',
    value: 'isBlockedBy'
  },
  {
    label: 'Blocks',
    value: 'blocks'
  },
  {
    label: 'Is cloned by',
    value: 'isClonedBy'
  },
  {
    label: 'Clones',
    value: 'clones'
  },
  {
    label: 'Is duplicated by',
    value: 'isDuplicatedBy'
  },
  {
    label: 'Duplicates',
    value: 'duplicates'
  },
  {
    label: 'Relates to',
    value: 'relates'
  }
];

const LinkType = ({ value, onChange }) =>
  <KoSelect value={value}
    onChange={v => onChange(v)}
    showSearch
    allowClear
    placeholder='Select Link type'
    options={LINKS?.map((spr) => ({
      label: spr?.label,
      value: spr?.value
    }))}
  />;

export { Assignees, Date, LinkType, Priority, Reporter, Sprint, TaskStatus };

