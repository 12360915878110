import React from "react";
function Story(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" width="24" height="24" rx="4" fill="var(--story)" />
      <path d="M18.1 4H9.70002V16H19.5V5.33334C19.5 4.59697 18.8732 4 18.1 4Z" fill="white" />
      <path d="M7.60003 4C6.44024 4 5.50003 4.89544 5.50003 6V16.51C5.88443 16.181 6.38321 15.9993 6.90004 16H8.30001V4H7.60003Z" fill="white" />
      <path d="M19.5 20H6.90001C6.12682 20 5.5 19.4031 5.5 18.6667C5.5 17.9303 6.12682 17.3333 6.90001 17.3333H19.5V20Z" fill="white" />
    </svg>

  );
}


export default Story;