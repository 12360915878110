import React, { useState } from 'react';
import { Transfer } from 'antd';

import { KoForm, KoFormBuilder } from 'packages';
import styles from '../../template.module.scss';

export default function LineItemUpdate({ form, defaultColumns, currentRecord, currentItem, currentProperties, handleSaveProperties }) {
  currentProperties['colSpan'] = currentRecord?.columns || 6;

  const [targetKeys, setTargetKeys] = useState();
  const [selectedKeys, setSelectedKeys] = useState([]);

  const otherOptions = [
    {
      dataIndex: 'items',
      title: 'Item & Description',
      editable: true,
      widget: 'text',
      key:'items',
      placeholder: 'Item & Description'
    },
    {
      dataIndex: 'unit',
      title: 'Unit',
      editable: true,
      widget: 'options',
      width:110,
      placeholder: 'Ex: Day',
      key: 'unit',
      options: [{
        value: 'hrs',
        label: 'Hours'
      }, {
        value: 'd',
        label: 'Day'
      }, {
        value: 'm',
        label: 'Month'
      }, {
        value: 'y',
        label: 'year'
      }, {
        value: 'pc',
        label: 'pc'
      }, {
        value: 'kg',
        label: 'Kg'
      }, {
        value: 'lb',
        label: 'lb.'
      }, {
        value: 'ft',
        label: 'Feet'
      }]
    },
    {
      dataIndex: 'quantity',
      title: 'Quantity',
      editable: true,
      widget: 'number',
      key: 'quantity',
      width:130,
      placeholder: 'Quantity'
    },
    {
      key: 'rate',
      dataIndex: 'rate',
      title: 'Rate',
      editable: true,
      widget: 'number',
      width:120,
      placeholder: 'Rate'
    },{
      dataIndex:'tax',
      key: 'tax',
      title: 'Tax',
      editable: true,
      widget: 'options',
      width:230,
      placeholder: 'select tax'
    },{
      dataIndex:'discount',
      key: 'discount',
      editable: true,
      title: 'Discount',
      widget: 'options',
      width:150,
      placeholder: 'select discount'
    },{
      dataIndex:'date',
      key: 'date',
      title: 'Date',
      editable: true,
      widget: 'date',
      placeholder: 'select date'
    },{
      dataIndex:'link',
      key: 'link',
      title: 'Link',
      editable: true,
      width:150,
      widget: 'text',
      placeholder: 'Enter url'
    },{
      dataIndex:'productCode',
      key: 'productCode',
      title: 'Product code',
      editable: true,
      widget: 'text',
      width:150,
      placeholder: 'Enter Product code'
    },{
      dataIndex:'attachment',
      key: 'attachment',
      editable: true,
      title: 'Files',
      widget: 'attachment'
    }];

  const OptionsTransfer = () =>
    <KoForm.Item name={'defaultColumns'}>
      <Transfer
        dataSource={otherOptions}
        titles={['Source', 'Target']}
        targetKeys={targetKeys}
        selectedKeys={selectedKeys}
        onChange={handleChange}
        onSelectChange={handleSelectChange}
        render={(item) => item.title}
        oneWay
      />
    </KoForm.Item>;


  const handleChange = (newTargetKeys, direction, moveKeys) => {
    setTargetKeys(newTargetKeys);
    let data = form.getFieldsValue();
    let opt=[];
    const fieldMap = newTargetKeys.map(field => otherOptions.find(option => option.key === field));
    fieldMap.forEach(field => {
      opt.push(field);
    });

    data['defaultColumns'] = opt;
    form.setFieldsValue({ ...data });
  };
  const handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    let data = form.getFieldsValue();
    let opt=[];
    const fieldMap = targetSelectedKeys.map(field => otherOptions.find(option => option.key === field));
    fieldMap.forEach(field => {
      opt.push(field);
    });

    data['defaultColumns'] = opt;
    form.setFieldsValue({ ...data });
  };


  const InputMeta = {
    columns: 6,
    formItemLayout: null,
    initialValues: currentProperties,
    colon: true,
    fields: [
      { key: 'label', placeholder: 'Enter Label', label: 'Display Label', colSpan: 3 },
      { key: 'key', disabled:false, placeholder: 'Enter Label', label: 'Key', colSpan: 3 },
      { key: 'defaultColumns', colSpan: 6, label: 'Columns', widget: OptionsTransfer }
    ]
  };
  return (
    currentItem && (
      <div className={styles.propertyWrapper}>
        <KoForm form={form} layout="vertical" onFinish={handleSaveProperties}>
          <KoFormBuilder form={form} meta={InputMeta} />

        </KoForm>
      </div>
    )
  );
}