import { KoButton, KoDataTableEdit, KoForm, KoIcon, KoNano } from 'packages';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './curd.module.scss';

function SettingsTableList({ form, handleFinish, initialValues, profile }) {
  const designations = useSelector((state) => state.designations);
  const defaultRow = () => ({
    key: KoNano('TEM'),
    userId: null,
    designation: null,
    employeeType: null,
    unit: null,
    rate: null
  });
  console.log('initialValues',initialValues);
  const [dataSource, setDataSource] = useState([defaultRow()]);

  useEffect(() => {
    if (initialValues?.teams?.length > 0) {
      setDataSource(initialValues.teams);
    } else {
      setDataSource([defaultRow()]);
    }
  }, [initialValues]);

  useEffect(() => {
    form.setFieldsValue({
      teams: dataSource
    });
  }, [dataSource, form]);

  const handleDelete = (key) => {
    let newData = dataSource.filter((item) => item.key !== key);
    if (newData.length === 0) {
      newData = [defaultRow()];
    }
    setDataSource(newData);
    HandleSave(newData);
  };



  const columns = [
    {
      dataIndex: 'userId',
      title: 'Assignee',
      width: 200,
      editable: true,
      fixed: 'left',
      unAssignee: false,
      widget: 'UserSelect',
      options: profile,
      placeholder: 'Select user'
    },
    {
      dataIndex: 'designation',
      title: 'Designation',
      editable: true,
      widget: 'options',
      placeholder: 'Select Designation',
      readonly: true,
      options: designations['data']?.map((item) => ({ label: item?.name, value: item?.designationId })),
      width: 150
    },
    
    {
      dataIndex: 'employeeType',
      title: 'Employee Type',
      editable: true,
      daysAgo: 0,
      widget: 'options',
      placeholder: 'Select Employee Type',
      width: 150,
      options: [{
        key: 'fullTimeEmployees',
        value: 'fullTimeEmployees',
        label: 'Full-time employees'
      }, {
        key: 'partTimeEmployees',
        value: 'partTimeEmployees',
        label: 'Part-time employees'
      }, {
        key: 'temporaryEmployees',
        value: 'temporaryEmployees',
        label: 'Temporary employees'
      },{
        key: 'seasonalEmployees',
        value: 'seasonalEmployees',
        label: 'Seasonal employees'
      }, {
        key: 'fixedTermEmployees',
        value: 'fixedTermEmployees',
        label: 'Fixed-term employees'
      },{
        key: 'casualWorkers',
        value: 'casualWorkers',
        label: 'Casual workers'
      }, {
        key: 'agencyWorkers',
        value: 'agencyWorkers',
        label: 'Agency workers'
      }
      , {
        key: 'subcontractors',
        value: 'subcontractors',
        label: 'Subcontractors'
      }
      ]
    },
    {
      dataIndex: 'unit',
      title: 'Unit',
      editable: true,
      widget: 'options',
      width:110,
      placeholder: 'Ex: Day',
      key: 'unit',
      options: [{
        value: 'hrs',
        label: 'Hours'
      }, {
        value: 'd',
        label: 'Day'
      }, {
        value: 'm',
        label: 'Month'
      }, {
        value: 'y',
        label: 'year'
      }, {
        value: 'pc',
        label: 'pc'
      }, {
        value: 'kg',
        label: 'Kg'
      }, {
        value: 'lb',
        label: 'lb.'
      }, {
        value: 'ft',
        label: 'Feet'
      }]
    },
    {
      dataIndex: 'rate',
      title: 'Rate',
      editable: true,
      width: 100,
      widget: 'rate',
      placeholder: 'Rate'
     
    },
    {
      title: ` `,
      dataIndex: 'operation',
      width: 40,
      fixed: 'right',
      render: (_, record, i) =>
        dataSource.length >= 1 ? (
          <div className={'editTableCol'}>
            <KoButton
              icon={<KoIcon width={12} height={12} name={'DeleteOutlined'} />}
              className={styles.actionBtn}
              onClick={() => handleDelete(record?.key)}
              label=""
            />
            {dataSource?.length === i + 1 && (
              <KoButton
                icon={<KoIcon color={`var(--palette-gray-7)`} width={17} height={17} name={'PlusOutlined'} />}
                className={styles.actionBtn}
                onClick={handleAdd}
                label={``}
              />
            )}
          </div>
        ) : null
    }
  ];

  const handleAdd = () => {
    const newData = defaultRow();
    setDataSource([...dataSource, newData]);
  };

  const HandleSave = (newData) => {
    let data = form.getFieldsValue();
    data['teams'] = newData;
    form.setFieldsValue({ data });
    setDataSource(newData);
  };

  const onRow = (record) => {
  };



  return (
    <KoForm form={form} layout="vertical" onFinish={handleFinish} onSubmit={handleFinish}>
      <KoForm.Item name={'teams'}>
        <KoDataTableEdit
          dataSource={dataSource}
          form={form}
          onCellClick={onRow}
          maxHeight={67}
          onSave={HandleSave}
          
          defaultColumns={columns}
        />
      </KoForm.Item>
    </KoForm>
  );
}

export default SettingsTableList;
