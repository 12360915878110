import { KoButton, KoDropdown, KoEmpty, KoFlex, KoForm, KoIcon, KoLoader, KoModal, KoNano, KoPagination, KoTable, KoText, KoTitle, KoToast, KoTooltips } from "packages";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import KoToolBar from "shared/components/toolbar";
import { TaskPlannerAPI } from "../taskPlanner.service";
import styles from './component.module.scss';
import { LEV_ITEMS_COLUMNS } from './constant';
import Crud from "./crud";

const Component = (props) => {
  const [totalCount, setTotalCount] = useState(100);
  const [componentsList, setComponentsList] = useState();
  const [isOpen, setModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState();
  const [isOpenDelete, setDeleteModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [form] = KoForm.useForm();
  const {workspaceId} = useParams();
  const [pagination, setPagination] = useState({
    size: 10,
    page: 1
  });
  const onRow = (record) => {
  };

  useEffect(() => {
    getComponents();
  }, []);

  const getComponents = () => {
    setComponentsList();
    setLoading(true);
    TaskPlannerAPI.getComponents(pagination, workspaceId).then(
      (data) => {
        setLoading(false);
        setComponentsList(data?.components);
        setTotalCount(data?.totalCount);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const onSearchText = (text) => {
    let q = pagination;
    if (text) {
      q['search'] = text;
    }
    else {
      delete q?.['search'];
    }
    getComponents(q);
  };

  const onSort = (key, type) => {
    let q = pagination;
    q['sort'] = `${key}:${type}`;
    getComponents(q);
  };

  const onPagination = (page, other) => {
    const query = {
      size: other,
      page: page,
      offset: (page - 1) * other
    };
    const pQuery = Object.assign(pagination, query);
    setPagination(pQuery);
    getComponents(pQuery);
  };


  const handleFinish = (values => {
    setLoading(true);
    if (!currentRecord?.componentId) {
      values['componentId'] = KoNano('CMP');
    }
    TaskPlannerAPI.createOrUpdateComponent(values, currentRecord?.componentId, workspaceId).then(
      (data) => {
        getComponents();
        setLoading(false);
        setModal(false);
        form.resetFields();
        KoToast.success("Component created Successfully");

      },

      (err) => {
        setLoading(false);
      }
    );
  });

  const HandleDelete = () => {
    setLoading(true);
    TaskPlannerAPI.deleteComponent(currentRecord?.componentId, workspaceId).then(
      (data) => {
        setLoading(false);
        setDeleteModal(false);
        KoToast.success("Component Deleted Successfully");
        getComponents();
      },
      (err) => {
        setLoading(false);
      }
    );
  };



  const items = [
    {
      key: '2',
      danger: true,
      label: 'Delete',
      icon: <KoIcon name='DeleteOutlined' />
    }
  ];

  const onHandelSprint = (e, row) => {
    if (e.key === "2") {
      setDeleteModal(true);
    }
  };
  const columnsRight = [{
    title: '',
    dataIndex: 'x',
    key: 'x',
    fixed: 'right',
    width: 40,
    render: (text, row) => {
      return <div className={`${styles.circle} tableBtn columnsRight`} onClick={() => setCurrentRecord(row)}>
        <KoTooltips title={'Edit'}>
          <KoButton className={'btn'} iconBtn={true} icon={<KoIcon width={14} height={14} name={'EditOutlined'} />} onClick={() => setModal(true)} />
        </KoTooltips>
        <div shape="circle">
          <KoDropdown
            menu={{
              items,
              onClick: (e) => onHandelSprint(e, row)
            }}
            overlayClassName={styles.actionDropdown}
            placement="bottomLeft"
          >
            <div shape="circle" >
              <KoIcon name="VerticalMenuOutlined" onClick={() => (row)} />
            </div>
          </KoDropdown>
        </div>
      </div>;
    }
  }];

  
  return (
    <div className={`${styles.wrapper} tableCard`}>
      {isLoading && <KoLoader />}

      <KoToolBar
        title={'Components'}
        search={{ visible: true, onChange: (e) => { onSearchText(e); }, placeholder: 'Search by any parameter' }}
        filter={{ visible: false }}
        buttonList={[{
          label: 'Create',
          type: 'primary',
          icon: <KoIcon color="#FFF" name="PlusOutlined" width={18} height={18} />, onClick: () => setModal(!isOpen)
        }]}
      />
      {componentsList?.length > 0 ? <>
        <KoTable
          rowKey='componentId'
          onRow={onRow}
          onSort={onSort}
          data={{ columns: LEV_ITEMS_COLUMNS, rows: componentsList }} extraColumnsRight={columnsRight} maxHeight={50} />
        <KoPagination current={parseInt(pagination.page) || 1}
          total={totalCount}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} records`
          }
          pageSize={pagination.limit}
          defaultCurrent={pagination.page}
          onChange={onPagination}
          showQuickJumper={false}
          simple={false}
          responsive={false} />
      </>
        :

        <KoFlex wrap="wrap" gap="small" className={styles.emptySection}>
          <KoEmpty onClick={() => setModal(!isOpen)} label="Create Component" icon={'EmptyTwoTone'} title="Create a new Component" />
        </KoFlex>}

      <KoModal
        title={`${currentRecord?.componentId ? 'Update' : 'Create'} Component`}
        centered
        open={isOpen}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setModal(false); }}
        onClose={() => { setCurrentRecord(); setModal(false); }}
        width={580}
        // maskClosable={false}
        height={650}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setModal(!isOpen)} />,
          <KoButton key='create' loading={isLoading} type='primary' label={currentRecord?.componentId ? `Update` : `Create`}
            onClick={() => form.submit()} />
        ]}
      >
        <Crud form={form} initialValues={currentRecord} handleFinish={handleFinish} />
      </KoModal>

      <KoModal
        title={`Delete ${currentRecord?.name?.toUpperCase()} Component `}
        centered
        open={isOpenDelete}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setDeleteModal(false); }}
        onClose={() => { setCurrentRecord(); setDeleteModal(false); }}
        width={580}
        // maskClosable={false}
        height={450}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setDeleteModal(!isOpenDelete)} />,
          <KoButton key='delete' loading={isLoading} type='primary' label='Delete' onClick={() => HandleDelete()} />
        ]}
      >
        <div className={styles.delWrapper}>
          <KoTitle level={5} text={`This action will delete item and cannot be undone`} />
          <KoText text={`Do you wish to proceed?`} />
        </div>
      </KoModal>
    </div>
  );
};

export default Component;