import React from "react";
import {KoTooltips} from "../index";
import styles from './points.module.scss';

function KoStoryPoints(props) {
  const { value} = props;
 
  return (
    <div className={styles.priorityWrapper}>
      <KoTooltips title={`${value} Story Points`}>
        <div className={`${styles.priority}`}>
          {value}
        </div>
      </KoTooltips>
    </div>
  );
}
export default KoStoryPoints;
