import React from "react";
function ExportSVG(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      {...props}
    >
      <path d="M18.83 21.77a.514.514 0 00-.71.04c-.66.75-1.62 1.19-2.62 1.19h-11C2.57 23 1 21.43 1 19.5v-15C1 2.57 2.57 1 4.5 1h5.51c.33 0 .66.03.99.09V6.5A2.5 2.5 0 0013.5 9h5.41c.06.32.09.65.09.99v.51c0 .28.22.5.5.5s.5-.22.5-.5v-.51c0-1.74-.68-3.37-1.9-4.6l-3.48-3.48a6.45 6.45 0 00-4.6-1.9H4.5A4.492 4.492 0 000 4.5v15C0 21.98 2.02 24 4.5 24h11c1.29 0 2.52-.56 3.38-1.52.18-.21.16-.52-.04-.71zM17.39 6.1c.55.55.97 1.2 1.24 1.9H13.5c-.83 0-1.5-.67-1.5-1.5V1.37c.71.27 1.35.69 1.9 1.24l3.48 3.48zM24 16.51c0 .5-.19 1-.58 1.38l-2.57 2.52a.508.508 0 01-.71-.01.513.513 0 010-.71l2.57-2.51c.06-.06.1-.12.14-.18H13.5c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h9.36a.896.896 0 00-.15-.19l-2.57-2.51c-.2-.19-.2-.51 0-.71.19-.2.51-.2.71 0l2.57 2.51c.38.38.58.89.58 1.39z" />
    </svg>
  );
}


export default ExportSVG;