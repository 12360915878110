import React from "react";
function Line(props) {
  const { width, height } = props;
  return (<svg width={width}  height={height} viewBox="0 0 64 64">
    <path
      fill="#222"
      d="M55.93 25.41H8.07a1 1 0 010-2h47.86a1 1 0 010 2zm0 8.68H8.07a1 1 0 010-2h47.86a1 1 0 110 2zm0 8.68H8.07a1 1 0 010-2h47.86a1 1 0 010 2z"
    />
    <path
      fill="#222"
      d="M24 51.41a1 1 0 01-1-1v-26a1 1 0 012 0v26a1 1 0 01-1 1zm16 0a1 1 0 01-1-1v-26a1 1 0 012 0v26a1 1 0 01-1 1z"
    />
    <path
      fill="#222"
      d="M52.67 51.44H11.33a4.26 4.26 0 01-4.26-4.26V16.82a4.26 4.26 0 014.26-4.26h41.34a4.26 4.26 0 014.26 4.26v30.36a4.26 4.26 0 01-4.26 4.26zM11.33 14.56a2.26 2.26 0 00-2.26 2.26v30.36a2.26 2.26 0 002.26 2.26h41.34a2.26 2.26 0 002.26-2.26V16.82a2.26 2.26 0 00-2.26-2.26z"
    />
  </svg>
  );
}
export default Line;