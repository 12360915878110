import React from "react";
function Code(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path fill="#000000" d="M13.0342 4.9812L9.41071 18.5042 11.3426 19.0218 14.966 5.49884 13.0342 4.9812zM6.29304 7.29419L7.70726 8.7084 4.41436 12.0013 7.70726 15.2942 6.29304 16.7084 1.58594 12.0013 6.29304 7.29419zM17.707 16.7085L16.2927 15.2943 19.5856 12.0014 16.2927 8.7085 17.707 7.29428 22.4141 12.0014 17.707 16.7085z" ></path>
    </svg>
  );
}


export default Code;
