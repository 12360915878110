import { TASKSTATUSLIST } from "shared/commonConstants";

// import { graphic } from 'echarts';
const COLUMN = [
  {
    name: "invoiceNo",
    id: "invoiceNo",
    label: "Name",
    widget: "text",
    fixed:'left',
    width:100
  },
  {
    name: "client",
    id: "client",
    label: "Request",
    width:100,
    widget: "text"
  },

  {
    name: "amount",
    id: "amount",
    label: "Approval",
    width:100,
    widget: "text"
  },
  {
    name: "status",
    id: "status",
    width:80,
    label: "Status",
    widget: "invoicestatus",
    fixed:'right'
  }
];
const ROW = [{
  invoiceNo: '#79820 - INV -12',
  client: 'Qubernet Technologies',
  amount: 'USD 12854.00',
  status: 0
},{
  invoiceNo: '#79820 - INV -12',
  client: 'Qubernet Technologies',
  amount: 'USD 12854.00',
  status: 2
},{
  invoiceNo: '#79820 - INV -12',
  client: 'Qubernet Technologies',
  amount: 'USD 12854.00',
  status: 1
},{
  invoiceNo: '#79820 - INV -12',
  client: 'Qubernet Technologies',
  amount: 'USD 12854.00',
  status: 3
},{
  invoiceNo: '#79820 - INV -12',
  client: 'Qubernet Technologies',
  amount: 'USD 12854.00',
  status: 2
},{
  invoiceNo: '#79820 - INV -12',
  client: 'Qubernet Technologies',
  amount: 'USD 12854.00',
  status: -1
},{
  invoiceNo: '#79820 - INV -12',
  client: 'Qubernet Technologies',
  amount: 'USD 12854.00',
  status: 0
},{
  invoiceNo: '#79820 - INV -12',
  client: 'Qubernet Technologies',
  amount: 'USD 12854.00',
  status: 2
},{
  invoiceNo: '#79820 - INV -12',
  client: 'Qubernet Technologies',
  amount: 'USD 12854.00',
  status: 1
},{
  invoiceNo: '#79820 - INV -12',
  client: 'Qubernet Technologies',
  amount: 'USD 12854.00',
  status: 3
},{
  invoiceNo: '#79820 - INV -12',
  client: 'Qubernet Technologies',
  amount: 'USD 12854.00',
  status: 2
},{
  invoiceNo: '#79820 - INV -12',
  client: 'Qubernet Technologies',
  amount: 'USD 12854.00',
  status: -1
}];

const totalValue = TASKSTATUSLIST.reduce((sum, item) => item.value, 0);

const DONUT = {
  tooltip: {
    trigger: 'item'
  },
  legend: {
    top: '20%',
    right: '1%',
    orient: 'vertical',
    show: false,
    icon: 'circle'
  },
  series: [
    {
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      left: '0%',
      right: '0%',
      top: '0%',
      bottom: '0%',
      data: [],
      label: {
        // Inner label for showing the count (inside the slices)
        position: 'inner',
        formatter: '{c}',  // Shows the count (value)
        color: "var(--palette-gray-1)",
        fontSize: 14
      }
    },
    {
      // Second pie layer to show the labels outside
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      left: '0%',
      right: '0%',
      top: '0%',
      bottom: '0%',
      data: [],
      label: {
        position: 'outside',
        formatter: '{b}',
        color: "var(--palette-gray-7)",
        fontSize: 12,
        distanceToLabelLine: 3,
        alignTo: 'labelLine'
      },
      labelLine: {
        length: 15,
        length2: 15,
        smooth: 0.1
      }
    }
  ],
  height: "100%",
  graphic: {
    type: 'text',
    left: 'center',
    top: 'center',
    style: {
      text: `Total\n${totalValue}`,
      fontSize: 16,
      fill: 'var(--palette-gray-10)',
      textAlign: 'center',
      lineHeight: 20
    }
  }
};


const grid = {
  top:'5%',
  left: '0%',
  right: '0%',
  bottom: '0%',
  containLabel: true
};
const series= [
  {
    name: 'Invoiced',
    type: 'bar',
    stack: 'total',
    emphasis: {
      disabled: true
    },
    data: [6.5,4,12,14,11,7.5]
  },
  {
    name: 'Expenses',
    type: 'bar',
    stack: 'total',
    emphasis: {
      disabled: true
    },
    data: [0, 2,0,0,0,2]
  }
];
var stackInfo = {};
for (let i = 0; i < series[0].data.length; ++i) {
  for (let j = 0; j < series.length; ++j) {
    const stackName = series[j].stack;
    if (!stackName) {
      continue;
    }
    if (!stackInfo[stackName]) {
      stackInfo[stackName] = {
        stackStart: [],
        stackEnd: []
      };
    }
    const info = stackInfo[stackName];
    const data = series[j].data[i];
    if (data && data !== '-') {
      if (info.stackStart[i] == null) {
        info.stackStart[i] = j;
      }
      info.stackEnd[i] = j;
    }
  }
}
for (let i = 0; i < series.length; ++i) {
  const data = series[i].data;
  const info = stackInfo[series[i].stack];
  for (let j = 0; j < series[i].data.length; ++j) {
    const isEnd        = info.stackEnd[j] === i;
    const topBorder    = isEnd ? 8 : 0;
    const bottomBorder = 0;
    data[j] = {
      value: data[j],
      itemStyle: {
        borderRadius: [topBorder, topBorder, bottomBorder, bottomBorder]
      }
    };
  }
}
const BILLABLE_HOURS={
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'none'
    }
  },
  color: [
    "rgba(0, 120, 103, 0.8)",
    "rgba(255, 171, 0, 0.8)"
  ],
  legend: {
    show: false
  },
  barWidth:20,
  grid,
  xAxis: {
    type: 'category',
    data: ['Jan 24', 'Feb 24', 'Mar 24', 'Apr 24', 'May 24', 'Jun 24', 'Jul 24', 'Aug 24', 'Sep 24', 'Oct 24','Nov 24','Dec 24'],
    axisLabel:{
      fontSize:9
    }
  },
  yAxis: {
    type: 'value'
  },
  series
};

const INVOICE_EXPENDITURE = {
  color: ['#e6f4ee', '#f3f2e3'],
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      label: {
        backgroundColor: '#6a7985'
      }
    }
  },
  legend: {
    data: ['Line 1', 'Line 2', 'Line 3', 'Line 4', 'Line 5']
  },
  toolbox: {
    show:false
  },
  grid: {
    right: '2%',
    bottom: '3%',
    top:'8%',
    left:'1%',
    containLabel: true
  },
  xAxis: [
    {
      type: 'category',
      boundaryGap: false,
      data: ['Jan 24', 'Feb 24', 'Mar 24', 'Apr 24', 'May 24', 'Jun 24', 'Jul 24']
    }
  ],
  yAxis: [
    {
      type: 'value'
    }
  ],
  series: [
    {
      name: 'Invoiced',
      type: 'line',
      stack: 'Total',
      smooth: true,
      lineStyle: {
        width: 1,
        color:'#22c55e'
      },
      showSymbol: false,
      areaStyle: {
        opacity: 1
        // color: graphic.LinearGradient(0, 0, 0, 1, [
        //   {
        //     offset: 0,
        //     color: 'rgba(58,77,233,0.8)'
        //   },
        //   {
        //     offset: 1,
        //     color: 'rgba(58,77,233,0.3)'
        //   }
        // ])
      },
      emphasis: {
        focus: 'series'
      },
      data: [140, 232, 101, 264, 90, 340, 250]
    },
    {
      name: 'Expenses',
      type: 'line',
      stack: 'Total',
      smooth: true,
      lineStyle: {
        width: 1,
        color:'#ffab00'
      },
      showSymbol: false,
      areaStyle: {
        opacity: 1
        // color: graphic.LinearGradient(0, 0, 0, 1, [
        //   {
        //     offset: 0,
        //     color: 'rgba(213,72,120,0.8)'
        //   },
        //   {
        //     offset: 1,
        //     color: 'rgba(213,72,120,0.3)'
        //   }
        // ])
      },
      data: [120, 282, 111, 234, 220, 340, 310]
    }
  ]
};
const ACCOUNT_RECEIVABLE_COLUMN = [
  {
    name: "accountReceivable",
    id: "accountReceivable",
    label: "Account Receivable",
    widget: "text",
    width:100
  },
  {
    name: "total",
    id: "total",
    label: "Total",
    width:100,
    widget: "text",
    align:'right'
  }
];
const ACCOUNT_PAYABLE_COLUMN = [
  {
    name: "accountPayable",
    id: "accountPayable",
    label: "Account Payable",
    widget: "text",
    width:100
  },
  {
    name: "total",
    id: "total",
    label: "Total",
    width:100,
    widget: "text",
    align:'right'
  }
];

const INVOICE_TABLE = [
  {
    name: "customer",
    id: "customer",
    label: "Customer",
    widget: "text",
    width:100
  },
  {
    name: "create",
    id: "create",
    label: "Create",
    width:100,
    widget: "text"
  },
  {
    name: "due",
    id: "due",
    label: "Due",
    width:100,
    widget: "text"
  },
  {
    name: "amount",
    id: "amount",
    label: "Amount",
    width:100,
    widget: "text"
  },
  {
    name: "sent",
    id: "sent",
    label: "Sent",
    width:100,
    widget: "text"
  },
  {
    name: "status",
    id: "status",
    label: "Status",
    width:100,
    widget: "invoiceStatus"
  }
];


const AssignedTicketsCol = [
  {
    name: "profile",
    id: "profile",
    label: "Assignee",
    widget: "staticmember",
    fixed:'left'
  },
  {
    name: "activeCount",
    id: "activeCount",
    label: "Active Tickets",
    width:100,
    widget: "text"
  },
  {
    name: "totalCount",
    id: "totalCount",
    label: "Assigned Tickets",
    width:100,
    widget: "text"
  }
 
];

const discriptionTicketsCol = [
  {
    name: "profile",
    id: "profile",
    label: "Assignee",
    widget: "staticmember",
    fixed:'left'
  },
  {
    name: "count",
    id: "count",
    label: "Total Count",
    width:100,
    widget: "text"
  },
  {
    name: "tickets",
    id: "tickets",
    label: "Tickets",
    width:100,
    widget: "multiTicketLinkStatic"
  }
];

const dueCountCol = [
  {
    name: "profile",
    id: "profile",
    label: "Assignee",
    widget: "staticmember",
    fixed:'left'
  },
  {
    name: "count",
    id: "count",
    label: "Total Count",
    width:100,
    widget: "text"
  }
];

const ISSUE_STATUS_COLUMNS = [
  {
    name: "profile",
    id: "profile",
    label: "Assignee",
    widget: "staticmember",
    fixed:'left'
  }, {
    name: "1",
    id: "1",
    label: "Epic",
    widget: "number",
    width:100
  }, {
    name: "2",
    id: "2",
    label: "Story",
    widget: "number",
    width:100
  }, {
    name: "3",
    id: "3",
    label: "Task",
    widget: "number",
    width:100
  }, {
    name: "4",
    id: "4",
    label: "Sub Task",
    widget: "number",
    width:100
  }, {
    name: "5",
    id: "5",
    label: "Bug",
    widget: "number",
    width:100
  }, {
    name: "6",
    id: "6",
    label: "Improvement",
    widget: "text"
  }, {
    name: "7",
    id: "7",
    label: "Feature Request",
    widget: "text"
  }, {
    name: "8",
    id: "8",
    label: "Change Request",
    widget: "text"
  }, {
    name: "9",
    id: "9",
    label: "Support",
    widget: "text"
  }, {
    name: "totalCount",
    id: "totalCount",
    label: "Total",
    widget: "dashboardLink",
    width: '10rem',
    fixed: 'right',
    align: 'center'
  }
];
export {
  COLUMN,
  ROW,
  AssignedTicketsCol,
  discriptionTicketsCol,
  DONUT,
  BILLABLE_HOURS,
  INVOICE_EXPENDITURE,
  ACCOUNT_RECEIVABLE_COLUMN,
  ACCOUNT_PAYABLE_COLUMN,
  INVOICE_TABLE,
  ISSUE_STATUS_COLUMNS,
  dueCountCol
};