import { useSelector } from "react-redux";
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from "react-router-dom";
import { KoLayout } from './layouts';

import ChangePass from "pages/account/changePassword";
import ForgotPass from "pages/account/forgotPassword";
import Login from "pages/account/login";
import ResetPass from "pages/account/newPassword";
import Requested from "pages/account/requested";
import VerifyAccount from "pages/account/verify";


import AdminConsole from "pages/admin";
import Settings from "pages/admin/settings";
import ApiClient from "pages/apiClient";
import ApiRequest from "pages/apiClient/request";
import Browse from "pages/browse";
import FilesPreview from "pages/browse/files";
import InvoicePreview from "pages/browse/invoice";
import TaskPreview from "pages/browse/tasks";
import Calendar from "pages/calendar";
import Clients from "pages/customers";
import ClientDetails from "pages/customers/deatils";
import Deployment from 'pages/deployment';
import Services from "pages/deployment/services";
import SalesHub from "pages/file";
import Invoices from "pages/invoices";
import InvoiceDashboard from "pages/invoices/dashboard";
import InvoicePage from "pages/invoices/invoice";
import InvoiceTemplate from "pages/invoices/templete";
import Organization from "pages/organization";
import Departments from "pages/organization/departments";
import Designations from "pages/organization/designations";
import Employees from "pages/organization/employees";
import Groups from "pages/organization/groups";
import Payslips from "pages/payslips";
import Profile from "pages/profile";
import HomeDashboard from "pages/dashboard";
import ExpensesTravel from "pages/profile/expensesTravel";
import Inbox from "pages/inbox";
import Leaves from "pages/profile/leaves";
import ManageTax from "pages/profile/managetax";
import PaySlips from "pages/profile/payslips";
import Performance from "pages/profile/performance";
import Timesheet from "pages/profile/timesheet";
import TaskPlannerDetails from "pages/taskPlanner";
import Dashboard from "pages/taskPlanner/dashboard";
import Sprint from "pages/taskPlanner/sprint";
import Tasks from "pages/taskPlanner/task";
import Workspace from "pages/workspace";
import Scrum from "pages/taskPlanner/scrum";

import Chats from "pages/chat";
import HomePage from "pages/home";
import ReleasePlan from "pages/taskPlanner/releasePlan";

// import BuildRequest from "pages/taskPlanner/buildRequest";
import { KoButton, KoIcon, KoTitle } from "packages";
import CreateAccount from "pages/account/createAccount";
import Announcements from "pages/admin/announcements";
import HoliDayCalendar from "pages/admin/holiday";
import Ownership from "pages/admin/ownership";
import Payroll from "pages/admin/payroll";
import PolicyDocuments from "pages/admin/policyDocuments";
import Polls from "pages/admin/polls";
import Collections from "pages/apiClient/collections";
import ApiClientDashboard from "pages/apiClient/dashboard";
import Environments from "pages/apiClient/environment";
import CalendarPreview from "pages/browse/calendar";
import CalendarDashboard from "pages/calendar/dashboard";
import Events from "pages/calendar/events";
import Meetings from "pages/calendar/meetngs";
import Files from "pages/file";
import FilesDashboard from "pages/file/dashboard";
import FileDrive from "pages/file/drive";
import Artifacts from "pages/file/drive/artifacts";
import FileCurd from "pages/file/drive/curd";
import InvoiceList from "pages/invoices/invoice/list";
import PreviewInvoice from "pages/invoices/invoice/previewInvoice";
import Roles from "pages/organization/roles";
import BackLogs from "pages/taskPlanner/backlog";
import SuportSystem from "pages/support/tickets";
import Epic from "pages/taskPlanner/epic";
import Module from "pages/taskPlanner/module";
import Component from "pages/taskPlanner/component";
import KoChatLayout from "layouts/chatLayout";
import AccountDashboard from "pages/account/dashboard";
import KioAI from "pages/kioAI";
import GenrateInvoice from "pages/invoices/genrateInvoice";
import TestCases from "pages/testcases";


function RootRouter() {
  const auth = useSelector((state) => state?.auth);
  const router = createBrowserRouter(createRoutesFromElements(
    <>
      {auth?.authenticated ? <Route index element={<Navigate to="/home/dashboard/myoverview" />} /> :
        <Route index path="/" element={<Login />} />}
      <Route index path="/support/tickets/:accountId/:workspaceId" element={<SuportSystem />} />
      <Route index path="/account/forgotpassword" element={<ForgotPass />} />
      <Route index path="/account/verify" element={<VerifyAccount />} />
      <Route index path="/account/changepassword/:mailId" element={<ChangePass />} />
      <Route index path="/account/resetpassword" element={<ResetPass />} />
      <Route index path="/account/requested" element={<Requested />} />
      <Route index path="/account/login" element={<Login />} />
      <Route index path="/account/create-account" element={<CreateAccount />} />

      <Route path="/" element={auth?.authenticated ? <KoLayout /> : <Navigate to="/account/login" />}>
        <Route path="/workspace" element={<Workspace />} />
        <Route path="/kio-ai" element={<KioAI />} />
        <Route path="/home" element={<HomePage />} >
          <Route path="approvals/:approvalId/:tabId" element={<Inbox />} />
          <Route path="dashboard/:tabId" element={<HomeDashboard />} />
          <Route path="timesheet/:tabId" element={<Timesheet />} />
          <Route path="leaves" element={<Leaves />} />
          <Route path="performance" element={<Performance />} />
          <Route path="expenses" element={<ExpensesTravel />} />
          <Route path="payslips" element={<PaySlips />} />
          <Route path="managetax" element={<ManageTax />} />
        </Route>
        <Route path="accounts" element={<AccountDashboard />} />
        <Route path="calendar" element={<Calendar />} >
          <Route path="dashboard" element={<CalendarDashboard />} />
          <Route path="events" element={<Events />} />
          <Route path="meetings" element={<Meetings />} />
        </Route>

        <Route path=":workspaceId/taskplanner" element={<TaskPlannerDetails />} >
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="sprints" element={<Sprint title='Sprints' />} />
          <Route path="tickets" element={<Tasks title='My Tickets' />} />
          <Route path="backlog" element={<BackLogs title='BackLogs' />} />
          <Route path="epic" element={<Epic title='Epic' />} />
          <Route path="scrum" element={<Scrum title='Scrum' />} />
          <Route path="release" element={<ReleasePlan title='Release Plan' />} />
          <Route path="modules" element={<Module title='Module' />} />
          <Route path="components" element={<Component title='Component' />} />
          <Route path="testcases" element={<TestCases title='Test Cases' />} />
        </Route>

        <Route path=":workspaceId/files" element={<Files />} >
          <Route path="dashboard" element={<FilesDashboard />} />
          <Route path="me" element={<FileDrive />} />
          <Route path="me/:folderId" element={<Artifacts />} />
          <Route path="me/:folderId/:fileId" element={<FileCurd />} />
          <Route path="shared" element={<FileDrive />} />
          <Route path="shared/:folderId" element={<Artifacts />} />
          <Route path="shared/:folderId/:fileId" element={<FileCurd />} />
        </Route>

        <Route path=":workspaceId/invoices" element={<Invoices />} >
          <Route path="dashboard" element={<InvoiceDashboard />} />
          <Route path="me" element={<InvoicePage />} />
          <Route path="me/:folderId" element={<InvoiceList />} />
          <Route path=":folderId/view/:id" element={<PreviewInvoice />} />
          <Route path=":folderId/view/upload/:id" element={<PreviewInvoice />} />
          <Route path="templates" element={<InvoiceTemplate />} />
          <Route path="genrate" element={<GenrateInvoice />} />
          <Route path="shared" element={<InvoicePage />} />
          <Route path="shared/:folderId" element={<InvoiceList />} />
        </Route>

        <Route path=":workspaceId/apiclient" element={<ApiClient />} >
          <Route path="dashboard" element={<ApiClientDashboard />} />
          <Route path="environments" element={<Environments />} />
          <Route path="collections" element={<Collections />} />
          <Route path="collections/:collectionId" element={<ApiRequest />} />
          <Route path="deployment" element={<Deployment />} />
          <Route path="deployment/:id" element={<Services />} />
        </Route>

        <Route path="/org" element={<Organization />}>
          <Route path="employees" element={<Employees />} />
          <Route path="groups" element={<Groups />} />
          <Route path="departments" element={<Departments />} />
          <Route path="designations" element={<Designations />} />
          <Route path="roles" element={<Roles />} />
        </Route>

        <Route path="/admin" element={<AdminConsole />}>
          <Route path="settings" element={<Settings />} />
          <Route path="payroll" element={<Payroll />} />
          <Route path="policy" element={<PolicyDocuments />} />
          <Route path="polls" element={<Polls />} />
          <Route path="announcements" element={<Announcements />} />
          <Route path="holiday" element={<HoliDayCalendar />} />
          <Route path="customers" element={<Clients />} />
          <Route path="changeowner" element={<Ownership />} />
          <Route path="customers/:id" element={<ClientDetails />} />
        </Route>
        <Route path="/profile/:id" element={<Profile />} />
        <Route path="/payslips" element={<Payslips />} />
        <Route path="/sales" element={<SalesHub />} />
        <Route path="*" element={
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '60vh', textAlign: 'center' }}>
            <KoIcon name='PageNotFoundTwoTone' width={350} height={350} style={{ animation: 'pulse 2s infinite' }} />
            <KoTitle text={`404`} style={{ marginTop: '1rem', fontSize: '2rem', fontWeight: 'bold' }} />
            <KoTitle text={`Page not found!`} style={{ marginTop: '1rem', fontSize: '1.5rem', color: '#555' }} />
            <KoButton onClick={() => window.history.back()} label={'Back'} type={'primary'} style={{ marginTop: '1rem' }} />
          </div>
        } />
      </Route>

      <Route path="/" element={auth?.authenticated ? <KoChatLayout /> : <Navigate to="/account/login" />}>
        <Route path="/chat" element={<Chats />} />
        <Route path="/chat/:roomId" element={<Chats />} />
      </Route>
      <Route path="open" element={<Browse />} >
        <Route path="ticket/:accountId/:workspaceId/:ticketId" element={<TaskPreview />} />
        <Route path="file/:id" element={<FilesPreview />} />
        <Route path="invoice/:id" element={<InvoicePreview />} />
        <Route path="calendar/:accountId/:eventId/:userId" element={<CalendarPreview />} />
      </Route>



      <Route path="*" element={
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '60vh', textAlign: 'center' }}>
          <KoIcon name='PageNotFoundTwoTone' width={350} height={350} style={{ animation: 'pulse 2s infinite' }} />
          <KoTitle text={`404`} style={{ marginTop: '1rem', fontSize: '2rem', fontWeight: 'bold' }} />
          <KoTitle text={`Page not found!`} style={{ marginTop: '1rem', fontSize: '1.5rem', color: '#555' }} />
          <KoButton onClick={() => window.history.back()} label={'Back'} type={'primary'} style={{ marginTop: '1rem' }} />
        </div>
      } />
    </>
  ));

  return (
   
    <RouterProvider router={router} />
   
  );
};
export default RootRouter;