import React from "react";
import styles from './methods.module.scss';

function Methods(props) {
  return (
    <div className={styles.wrapper}>
      <div className={`${styles[props.value]} ${styles.method}`}>
        {props.value}
      </div>
    </div>
  );
}
export default Methods;
