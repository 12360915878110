
import PhoneInput from 'antd-phone-input';
import _find from 'lodash/find';
import { KoButton, KoDivider, KoDP, KoForm, KoIcon, KoImage, KoLoader, KoTabs, KoText, KoTitle, KoToast } from 'packages';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getCookie } from 'shared/utils/cookies';
import ProfileAbout from './about';
import ProfileBank from './bank';
import ProfileContact from './contactinfo';
import ProfileDocuments from './documents';
import ProfileJob from './job';
import JobDetails from './jobdetails';
import styles from './profile.module.scss';
import { ProfileAPI } from './profile.service';
import ProfileSettings from './settings';
export default function Profile() {
  const { id } = useParams();
  const users = useSelector(state => state?.users);
  const [userData, setUserData] = useState();
  const [preferenceData, setPreferenceData] = useState();
  const [isLoading, setLoading] = useState(false);
  const [form] = KoForm.useForm();
  
  const userDetails = _find(users['data'], function(o) {return o.userId === id; });
  const ReporterDetails = _find(users['data'], function(o) {return o.userId === userDetails?.reportingTo; });
  const ManagerOfManagerDetails = _find(users['data'], function(o) {return o.userId === ReporterDetails?.reportingTo; });
  
  useEffect(() => {
    getProfileData(id);
    getPreferenceData();
  }, []);

  const getProfileData = (id) => {
    setLoading(true);
    ProfileAPI.getProfile(id).then(
      (data) => {
        setLoading(false);
        setUserData(data['Profile']);
      },
      (err) => {
        setLoading(false);
      }
    );
  };
  const getPreferenceData = () => {
    setLoading(true);
    ProfileAPI.getPreference().then(
      (data) => {
        setLoading(false);
        setPreferenceData(data);
      },
      (err) => {
        setLoading(false);
      }
    );
  };


  const handleFinish = (values => {
    setLoading(true);
    ProfileAPI.updateFieldProfile(values, id).then(
      (data) => {
        getProfileData(id);
        setLoading(false);
        form.resetFields();
        KoToast.success(`Profile updated successfully`);
      },
      (err) => {
        setLoading(false);
      }
    );
  });
  
  return (
    <div className={styles.wrapper}>
      {isLoading && <KoLoader />}
      <div className={styles.top}>
        <div className={styles.banner}>
          <div className={`${styles.bannerProfile} ${ !userData?.logo && styles[userData? userData?.primaryDetails?.firstName?.charAt(0).toLowerCase() :'a']}`} >
            {userData?.logo ?
              <KoImage src={userData?.logo[0]?.url} />:
              <KoText className={`${styles.letter}`} text={userData?.primaryDetails?.firstName?.charAt(0)} level={5}/>
            }

          </div>
          <div className={styles.profileContactWrapper}>
            <div className={styles.profileWrapper}>
              <KoTitle text={`${userData?.primaryDetails?.firstName ?? ''} ${userData?.primaryDetails?.lastName ?? ''}`} level={5} className={styles.h2} />
            </div>
            <div className={styles.profileContact}>
              <div className={styles.contact}>
                <div className={styles.row}>
                  <KoText className={styles.titleValue} text={userData?.jobDetails?.jobTitlePrimary ? userData?.jobDetails?.jobTitlePrimary : '-Not Set-'}  />
                </div>
                <div className={styles.row}>
                  <KoButton className={styles.btn} iconBtn={true} icon={<KoIcon width={12} height={12} name="PhoneOutlined" />} />
                  <KoText text={`${userData?.contactDetails?.mobilePhone ?? '-'}`} level={5} />
                  {
                    userDetails?.mobile &&
                  <PhoneInput className={`${styles.mobile} mobileNo`} value={userDetails?.mobile} disableDropdown readOnly />
                  }
                </div>
                <div className={styles.row}>
                  <KoButton className={styles.btn} iconBtn={true} icon={<KoIcon width={12} height={12} name="EmailOutlined" />} />
                  <KoText text={`${userData?.contactDetails?.email ?? '-'}`} level={5} />
                </div>
                <div className={styles.row}>
                  <KoButton className={styles.btn} iconBtn={true} icon={<KoIcon name="EmpIdOutlined" />} />
                  <KoText text={userData?.jobDetails?.employeeId ? userData?.jobDetails?.employeeId :"-Not set-" } />
                </div>
                <div className={styles.row}>
                  <KoButton className={styles.btn} iconBtn={true} icon={<KoIcon width={18} height={18} name="LocationFilled" />} />
                  <KoText text={userData?.organization?.location ? userData?.organization?.location : "-Not set-"} level={5} />
                </div>
              </div>
              <KoDivider className={styles.divder} />
              <div className={`${styles.contact}`}>
                <div className={styles.reportingTo}>
                  <KoText className={styles.titleValue} text={'Business Unit'} />
                  <KoText text={preferenceData?.companyName ? preferenceData?.companyName :"-Not set-" } />
                </div>
                <div className={styles.reportingTo}>
                  <KoText className={styles.titleValue} text={'Department'} />
                  <KoText text={'Kwapio'} />
                </div>
                <div className={styles.reportingTo}>
                  <KoText className={styles.titleValue} text={'Reporting Manager'} />
                  {ReporterDetails?.profile?.['logo'] ?
                    <div className={styles.userWrapper} >
                      <div className={`${styles.circle}  ${styles[ReporterDetails ?
                        ReporterDetails?.profile?.firstName?.charAt(0).toLowerCase() : 'a']}`}>

                        {ReporterDetails?.profile?.['logo'] ?
                          <KoDP logo={ReporterDetails?.profile?.['logo']} className={styles.profile} width={20} height={20} />
                          :
                          <KoText className={`${styles.letter}`} text={ReporterDetails?.profile?.firstName?.charAt(0) ?? ''} />
                        }
                      </div>
                      <KoText className={styles.text} text={ReporterDetails?.profile?.firstName + ' ' + ReporterDetails?.profile?.lastName} ellipsis={true} />
                    </div> : <KoText text={'-'} />
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.profileSection} profileSection`}>
        <KoTabs options={[
          {
            label: <KoText text={'About'}/>,
            children: <ProfileAbout userDetails={userDetails} userData={userData} form={form} preferenceData={preferenceData}/>,
            key: 'about'
          },
          {
            label: 'Profile',
            children: <ProfileContact userData={userData} handleFinish={handleFinish} form={form} preferenceData={preferenceData}/>,
            key: 'profile'
          },
          ( userData?.userId === getCookie('userId') && {
            label: 'Job',
            children: <JobDetails userData={userData} preferenceData={preferenceData} ManagerDetails={ManagerOfManagerDetails} ReporterDetails={ReporterDetails} form={form} handleFinish={handleFinish}/>,
            key: 'job'
          } ),
          ( userData?.userId === getCookie('userId') && {
            label: 'Projects',
            children: <ProfileJob userData={userData} userDetails={userDetails} handleFinish={handleFinish} form={form}/>,
            key: 'project'
          } ),
          ( userData?.userId === getCookie('userId') && {
            label: 'Documents',
            children: <ProfileDocuments form={form} handleFinish={handleFinish} userData={userData} userDetails={userDetails}/>,
            key: 'doc'
          } ),
          ( userData?.userId === getCookie('userId') && {
            label: 'Bank & Statutory',
            children: <ProfileBank form={form} handleFinish={handleFinish} userData={userData}/>,
            key: 'bank'
          } ),
          ( userData?.userId === getCookie('userId') && {
            label: 'Settings',
            children: <ProfileSettings userData={userData} form={form}/>,
            key: 'settings'
          } )
        ]} />
      </div>

    </div>
  );
}
