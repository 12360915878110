import React from "react";
function Activate(props) {
  const { width, height } = props;
  return (<svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    width={width}
    height={height}
  >
    <path
      fill="#222"
      d="M31.57 32.31A13.44 13.44 0 1145 18.87a13.46 13.46 0 01-13.43 13.44zm0-24.87A11.44 11.44 0 1043 18.87 11.45 11.45 0 0031.57 7.44zM10.88 55a1 1 0 01-1-1v-5.38a10.94 10.94 0 0110.93-10.93H41.2a1 1 0 010 2H20.81a9 9 0 00-8.93 8.93V54a1 1 0 01-1 1zM37 58.56a1 1 0 01-.76-.39l-7.68-9.89a1 1 0 111.58-1.23l7 9c2.14-2.35 8.3-9 15.43-15.47A1 1 0 1153.79 42c-8.74 7.94-16 16.13-16.08 16.21a1 1 0 01-.71.35z"
    />
  </svg>);
}
export default Activate;