import React from "react";
function List(props) {
  const { width, height } = props;
  return (<svg
    viewBox="0 0 44 44"
    width={width}
    height={height}
  >
    <path
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={3}
      d="M18.43 13.1H35.2M18.43 22H35.2M18.43 30.9H35.2"
      className="colorStroke000 svgStroke"
    />
    <circle
      cx="11.05"
      cy="13.1"
      r="2.25"
      fill="#000000"
      className="color000 svgShape"
    />
    <circle
      cx="11.05"
      cy={22}
      r="2.25"
      fill="#000000"
      className="color000 svgShape"
    />
    <circle
      cx="11.05"
      cy="30.9"
      r="2.25"
      fill="#000000"
      className="color000 svgShape"
    />
  </svg>
  );
}
export default List;