import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { KoButton, KoImage, KoForm, KoFormBuilder, KoIcon, KoTitle, KoText } from 'packages';
import { accountAPI } from '../account.service';
import styles from './password.module.scss';

function ForgotPass() {
  let navigate = useNavigate();
  const [form] = KoForm.useForm();
  const [isLoading, setLoading] = useState(false);
  const meta = {
    columns: 4,
    formItemLayout: null,
    colon: true,
    fields: [

      {
        key: 'email', label: 'Office Email Address', placeholder: 'Ex. example@business.com', rules: [
          {
            required: true,
            message: 'Email is required'
          }
        ], colSpan: 4
      }
    ]
  };

  const handleFinish = useCallback((values) => {
    setLoading(true);
    onPageChange();
    accountAPI.createAccount(values).then(
      (data) => {
        setLoading(false);
        navigate(`/account/changepassword?email=${values.email}`);
      },
      (err) => {
        setLoading(false);
      }
    );
  });

  const onPageChange=()=>{
    navigate('/account/resetpassword');
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.modal}>
        <div className={styles.formWrapper}>
          <div className={styles.header}>
            <KoIcon name='AppLogoFilled' width={108}
              height={37} />
          </div>
          <KoTitle text={`Forgot your password?`} level={4} />
          <KoText text={`Not to worry, we've got you covered! Let's get you a new password.`} className={`${styles.small}`} />
          <div className={styles.formData}>
          </div>
          <KoForm form={form} layout="vertical" onFinish={handleFinish} onSubmit={handleFinish}>
            <KoFormBuilder className={styles.formData} form={form} meta={meta} />
          </KoForm>
          <div className={styles.signupBtn}>
            <KoButton loading={isLoading} onClick={() => form.submit()} label={'Continue'} type={'primary'} />
          </div>
        </div>
        <div className={styles.banner}>
          <div className={styles.slider}>
            <KoImage
              alt={'name'}
              src={`/images/banner.svg`}
              width={500}
              height={300}
              preview={false}
            />
            <div className={styles.footer}>
              <KoTitle text={'Connect your team’s work to your product roadmap'} level={5} />
              <KoText text="Every team has a unique process. Use a out-of-boss workflow,
              or create one to match the way team works"/>
            </div>
          </div>
          <div className={styles.dots}>
            <div className={styles.active} />
            <div className={styles.circle} />
            <div className={styles.circle} />
            <div className={styles.circle} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPass;

