import React from "react";
function Pause(props) {
  const { width, height, color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color? color : 'var(--palette-primary-darker)'}>
      <g data-name="Group 15556">
        <path data-name="Path 79572" d="M8 0a8 8 0 1 0 8 8 8 8 0 0 0-8-8zm-.88 10a.88.88 0 1 1-1.76 0V6a.858.858 0 0 1 .86-.88.892.892 0 0 1 .9.88zm3.54 0a.88.88 0 1 1-1.76 0V6a.858.858 0 0 1 .86-.88.892.892 0 0 1 .9.88z"  />
      </g>
    </svg>
  );
}

// fill="var(--palette-primary-darker)"
export default Pause;