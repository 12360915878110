import React from 'react';
// import Image from 'next/legacy/image';
function BannerImage() {
  return (
    <div>asdasd</div>
    // <Image
    //   src="/images/bg.png"
    //   layout="fill"
    //   objectFit="cover"
    //   quality={100}
    //   alt="Picture of the author"
    // />
  );
}


export default BannerImage;

