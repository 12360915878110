import { KoIcon, KoTitle } from "packages";
import { useEffect } from "react";
import { useDispatch } from 'react-redux';
import { add, remove } from 'shared/store/breadcrumbSlice';

const Performance = () => {
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(remove());
    dispatch(add([
      {
        title: 'Home',
        path: 'home/dashboard/myoverview'
      },
      {
        title: 'Performance'
      }
    ]));

  },[]);


  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '60vh', textAlign: 'center' }}>
      <KoIcon name='WorkingTwoTone' width={350} height={350} style={{ animation: 'pulse 2s infinite' }} />
      <KoTitle text={`We're currently working hard`} style={{ marginTop: '20px', fontSize: '2rem', fontWeight: 'bold' }} />
      <KoTitle text={`We'll be launching soon!`} style={{ marginTop: '10px', fontSize: '1.5rem', color: '#555' }} />
    </div>
  );
};

export default Performance;
