import React from "react";
import {KoText, KoTooltips} from "../index";
import styles from './priority.module.scss';

function KoPriority(props) {
  const {size, value, text=true} = props;
  const priorityList ={
    1:"Lowest",
    2:"Low",
    3:"Medium",
    4:"High",
    5:"Highest"};
    
  return (
    <div className={styles.priorityWrapper}>
      <KoTooltips title={`${priorityList[value]} Priority`}>
        <div className={`${styles.priority}`}>
          {[1].map((item) => (
            <div key={item} className={styles.priorityStatus}>
              <div  className={`${size ==='small' ? styles.small : styles.dot}  ${value >= item ?  styles[priorityList[value]] : ''}`}/>
              {text && <KoText className={`${size ==='small' ? styles.smallSize : ''}`} text={priorityList[value]}/>}
            </div>
          ))}
        </div>
      </KoTooltips>
    </div>
  );
}
export default KoPriority;
