import React from "react";

export const Menu = React.forwardRef(({ className, ...props }, ref) => (
  <div
    {...props}
    className={className}
    ref={ref}
  />
));

export const Toolbar = React.forwardRef(({ className, ...props }, ref) => (
  <Menu
    {...props}
    className={className}
    ref={ref}
  />
));
