import React from "react";
function LastSprint(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path d="M11.0891 1.0319C6.55423 1.42518 2.66443 4.59115 1.42363 8.9124C1.08881 10.0677 0.995259 10.7461 1.00018 12.0341C1.00018 13.2582 1.07896 13.8629 1.38424 14.969C2.19667 17.9187 4.29421 20.4603 7.06631 21.8614C7.94275 22.3038 9.16877 22.6922 10.2766 22.8839C11.1137 23.0265 12.7927 23.0413 13.6002 22.9085C16.1163 22.4956 18.2237 21.4091 19.9322 19.6442C21.2616 18.2677 22.1922 16.611 22.66 14.7921C22.9407 13.681 23.0047 13.1943 22.9997 11.985C22.9997 10.8149 22.921 10.1906 22.66 9.1877C21.7146 5.5547 18.8342 2.57555 15.1906 1.46943C13.9744 1.09581 12.3348 0.923742 11.0891 1.0319ZM14.3634 9.05988L16.7268 7.29009V12.0095V16.729L14.3634 14.9592L12 13.1894V14.9592V16.729L8.87335 14.3889C7.15001 13.096 5.75165 12.0243 5.75658 11.9997C5.76643 11.9751 7.16971 10.9083 8.87335 9.63015L11.9753 7.30975L11.9901 9.06971L12 10.8297L14.3634 9.05988Z" fill="black" />
    </svg>

  );
}


export default LastSprint;