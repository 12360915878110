import React from "react";
function Print(props) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      fill="#FFF"
      height={height}
    >
      <g
        fill="none"
        stroke="#303c42"
        strokeLinecap="round"
        strokeLinejoin="round"
        data-name="&amp;lt;Group&amp;gt;"
      >
        <path
          d="M20.5 5.5V22a1.5 1.5 0 01-1.5 1.5H5A1.5 1.5 0 013.5 22V2A1.5 1.5 0 015 .5h10.5z"
          data-name="&amp;lt;Path&amp;gt;"
          fill="#FFF"
        />
        <path
          d="M15.5.5V4A1.5 1.5 0 0017 5.5h3.5"
          data-name="&amp;lt;Path&amp;gt;"
          fill="#FFF"
        />
      </g>
      <path
        fill="none"
        stroke="#303c42"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.5 8.5c-.71 0-1 .55-1 1.55 0 1.35 1.75 3.2 2.5 4s3 2.5 3.5 2.5c.79 0 1-.32 1-.82S16.74 15 16 15a20.26 20.26 0 00-6 1.5c-2 1-3.5 1.5-3.5 2.34 0 .38.5.66 1 .66.76 0 2.5-1.5 3.5-4.5a32.36 32.36 0 001.5-5c0-1.5-.5-1.5-1-1.5z"
      />
    </svg>

  );
}


export default Print;