import { KoBreadcrumb, KoButton, KoForm, KoLoader, KoModal, KoToast } from 'packages';
import { supportAPI } from 'pages/support/tickets/support.service';
import TaskCrud from 'pages/taskPlanner/task/crud';
import { TaskPlannerAPI } from 'pages/taskPlanner/taskPlanner.service';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { getTicketBreadCrumb } from 'shared/commonConstants';
import ReadonlyCurd from 'shared/components/tickettable/readonlyCurd';
import { getUsers } from 'shared/store/usersSlice';
import { BrowseAPI } from '../browse.service';
import styles from './preview.module.scss';
import { setSelectedChatData } from 'shared/store/chatSlice';


const TaskById = () => {
  const [searchParams] = useSearchParams();
  const from = searchParams.get('from'); // "testCode"
  const [ticketDetails, setTicketDetails] = useState();
  const [breadCurmbs, setBreadCrumbs] = useState();
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState();
  let { accountId, workspaceId, ticketId } = useParams();
  const dispatch = useDispatch();
  const [form] = KoForm.useForm();

  useEffect(() => {
    if(workspaceId)
      dispatch(getUsers());
    getWorkspaceById(workspaceId);
  }, [workspaceId]);

  useEffect(() => {
    if (ticketId && from === 'support') {
      setLoading(true);
      console.log('ticketId', ticketId);
      supportAPI.getIssueByIdPublic(ticketId).then(
        (data) => {
          setTicketDetails(data['issue']);
          let crums = getTicketBreadCrumb(data?.issue);
          setBreadCrumbs(crums);
          setLoading(false);
        },
        (err) => {
          setLoading(false);
        }
      );
    }

  }, [ticketId]);

  const getWorkspaceById=(workspaceId)=>{
    TaskPlannerAPI.getWorkspaceById(workspaceId).then(
      (data) => {
        setProfile(data['project'].teams);
        getTickets(ticketId);
      },
      (err) => {
      }
    );
  };

  const getTickets=(id)=>{
    setLoading(true);
    setTicketDetails();
    BrowseAPI.getIssuesById(id, accountId, workspaceId).then(
      (data) => {
        setLoading(false);
        setTicketDetails(data?.issue);
        let crums = getTicketBreadCrumb(data?.issue);
        setBreadCrumbs(crums);
        const updatedRoomObj = { ...data, source: 'tickets' };
        dispatch(setSelectedChatData(updatedRoomObj));
      },
      (err) => {
        setLoading(false);
        if (err.error === "Unauthorized") {
          const currentPage = window.location.href;
          window.location.href = `/?redirect=${encodeURIComponent(currentPage)}`;
        }
      }
    );
  };


  const handleFinish = (values => {
    setLoading(true);
    TaskPlannerAPI.createOrUpdateIssues(values, ticketDetails?.ticketId , workspaceId).then(
      (data) => {
        setLoading(false);
        getTickets(ticketId);
        form.resetFields();
        KoToast.success(`Ticket updated successfully`);
      },
      (err) => {
        setLoading(false);
      }
    );
  });


  return (
    <div className={styles.wrapper}>
      {loading&&  <KoLoader/>}
      <div className={styles.header}>
        {  breadCurmbs ? <KoBreadcrumb items={breadCurmbs} /> : null}
      </div>
      <KoModal
        title={
          breadCurmbs
            ? <KoBreadcrumb items={breadCurmbs} />
            : (ticketDetails?.ticketDetails)
              ? `Update Issue ${(ticketDetails?.ticketId).toUpperCase()}`
              : `Create Issue`}
        centered
        afterClose={() => {setBreadCrumbs();}}
        open={true}
        handleOk={() => {window.close();}}
        onClose={() => {window.close();}}
        width={'95vw'}
        height={'95vh'}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => {window.close();form.resetFields();}} />,
          from !== 'support' && <KoButton key='create' type='primary' label={ticketDetails?.ticketId ? `Update` : `Create`}
            onClick={() => form.submit()} />
        ]}
      >
        {from !== 'support' &&  profile ? <TaskCrud form={form} getIssueById={getTickets} handleFinish={handleFinish} initialValues={ticketDetails} profile={profile}/>:
          <ReadonlyCurd issue={ticketDetails}/>}
        
      </KoModal>
   
    </div>
  );
};

export default TaskById;
