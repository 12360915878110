import React from "react";
import { Upload } from 'antd';
import styles from './upload.module.scss';


function KoUpload(props) {
  const { Dragger } = Upload;
  const { uploadProps, isDnd, children } = props;
  return (
    <div className={styles.uploadWrapper}>
      {isDnd ? <Dragger {...props}>
        {children}
      </Dragger> : <Upload {...uploadProps}>
        {children}
      </Upload>
      }
    </div>
  );
}
export {KoUpload, Upload};
