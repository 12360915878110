import React, { useCallback, useMemo } from "react";
import { KoButton, KoIcon, KoDropdown } from "packages";
import isHotkey from "is-hotkey";
import { Editable, withReact, useSlate, Slate } from "slate-react";
import {ALIGN} from './data';
import {
  Editor,
  Transforms,
  createEditor,
  Element as SlateElement
} from "slate";
import { withHistory } from "slate-history";
import { Toolbar } from "./toolbar";
import styles from './ac.module.scss';
const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code"
};

const LIST_TYPES = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true
  });
  let newProperties;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format
    };
  } else {
    newProperties = {
      type: isActive ? "paragraph" : isList ? "list-item" : format
    };
  }
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor, format, blockType = "type") => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format
    })
  );

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const Element = ({ attributes, children, element }) => {
  const style = {textAlign: element.align};
  switch (element.type) {
  case "block-quote":
    return (
      <blockquote style={style} {...attributes}>
        {children}
      </blockquote>
    );
  case "bulleted-list":
    return (
      <ul style={style} {...attributes}>
        {children}
      </ul>
    );
  case "list-item":
    return (
      <li style={style} {...attributes}>
        {children}
      </li>
    );
  
  case "numbered-list":
    return (
      <ol style={style} {...attributes}>
        {children}
      </ol>
    );
  case "left":
    return (
      <span style={style} {...attributes}>
        {children}
      </span>
    );
  case "center":
    return (
      <span style={style} {...attributes}>
        {children}
      </span>
    );
  case "right":
    return (
      <span style={style} {...attributes}>
        {children}
      </span>
    );
  case "justify":
    return (
      <span style={style} {...attributes}>
        {children}
      </span> );
    
  default:
    return (
      <p style={style} {...attributes}>
        {children}
      </p>
    );
  }
};

const Leaf = ({ attributes, children, leaf ,element}) => {
 
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <i>{children}</i>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }
  if (leaf.strikethrough) {
    children = <s>{children}</s>;
  }
 
  return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <KoButton
      type="text"
      iconBtn={true}
      active={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
      )}
      onClick={event => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
      icon={<KoIcon name={icon} />}
    />

  );
};

const BlockAlignmentButton = ({ format, icon }) => {
  const editor = useSlate();

  return (
    <KoDropdown
      menu={{
        items: ALIGN,
        onClick: (event) => {
          toggleBlock(editor, event.key);
        }
      }}
      overlayClassName={styles.actionDropdown}
      placement="bottom">
      <div shape="circle">
        <KoIcon name={`LeftOutlined`}/>
      </div>
    </KoDropdown>
  );};


const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <KoButton
      type="text"
      iconBtn={true}
      active={isMarkActive(editor, format)}
      onClick={event => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
      icon={<KoIcon name={icon} />}
    />
  );
};

const initial = [
 
  {
    type: "paragraph",
    children: [{ text: "" }]
  }
];


const KoDescriptionEditor = (props) => {
  const {form, initialValue=initial } = props;
  // const { initialValue=initial } = props;
  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const onChange = (value) => {
    let data = form.getFieldsValue();
    data['description'] = value;
    form.setFieldsValue({...data});
  };

  return (
    <Slate editor={editor} initialValue={initialValue? initialValue : initial} onChange={onChange}>
      <div className={styles.card}>
        <div className={styles.shadowBar}>
          <Toolbar className={styles.toolbar}>
            <MarkButton format="bold" icon="BoldOutlined" />
            <MarkButton format="italic" icon="ItalicOutlined" />
            <MarkButton format="underline" icon="UnderlineOutlined" />
            <MarkButton format="strikethrough" icon='StrikethroughOutlined'/>
            <MarkButton format="code" icon="CodeOutlined" />
            <MarkButton format="link" icon="LinkOutlined" />
            <BlockButton format="block-quote" icon="DoubleQuotesOutlined" />
            <BlockButton format="numbered-list" icon="NumberedOutlined" />
            <BlockButton format="bulleted-list" icon="BulletOutlined" />
            <BlockAlignmentButton format="align"  />
        
          </Toolbar>
        </div>
        <div className={styles.wrapper}>

          <Editable
            className={styles.editableWrapper}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder="Try it out for yourself!"
            spellCheck
            autoFocus
            onKeyDown={event => {
              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event)) {
                  event.preventDefault();
                  const mark = HOTKEYS[hotkey];
                  toggleMark(editor, mark);
                }
              }
            }}
          />
        </div>
        
      </div>
    </Slate>
  );
};


export default KoDescriptionEditor;
