import React from "react";
import { Flex } from 'antd';

function KoFlex(props) {
  return (
    <Flex {...props}>
      {props.children}
    </Flex>
  );
}

export default KoFlex;