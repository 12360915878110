import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useEffect } from "react";
import { useParams } from "react-router";
import { ProfileAPI } from "../profile.service";
import styles from "./profilesettings.module.scss";
const { KoForm, KoButton, KoIcon, KoInput, KoToast, KoTimePicker } = require("packages");

dayjs.extend(customParseFormat);

const TimeIntervalForm = ({ userData }) => {
  const [form] = KoForm.useForm();
  const { id } = useParams();
  const defaultTimeRange = { from: "09:00", to: "17:00" };

  const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

  const addHour = (timeStr) => dayjs(timeStr, "HH:mm").add(1, 'hour').format("HH:mm");

  const fallbackInitialValues = {
    intervals: weekdays.map((weekDay) => ({
      weekDay,
      type: "",
      intervals: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"].includes(weekDay)
        ? [{ from: defaultTimeRange.from, to: defaultTimeRange.to }]
        : []
    }))
  };
  
  useEffect(() => {
    form.setFieldsValue({
      intervals: userData?.availability || fallbackInitialValues.intervals
    });
  }, [userData, form]);

  const onFinish = async(values) => {
    try {
      const formattedValues = values.intervals?.map((interval) => ({
        weekDay: interval.weekDay,
        type: interval.type,
        intervals: interval?.intervals?.map((time) => ({
          from: time?.from,
          to: time?.to
        }))
      }));

      const updatedValues = {
        ...userData,
        availability: formattedValues
      };

      await ProfileAPI.updateProfile(updatedValues, id);
      KoToast.success("Saved successfully");
    } catch (err) {
      KoToast.error(err.message || "An error occurred");
    }
  };

  const convertToDayjs = (time) => dayjs(time, "HH:mm");

  return (
    <div className={styles.slotsWrapper}>
      <KoForm
        colon={false}
        form={form}
        initialValues={fallbackInitialValues}
        onFinish={onFinish}
      >
        <KoForm.List name="intervals">
          {(fields) =>
            weekdays.map((weekDay, index) => (
              <div key={weekDay}>
                <KoForm.Item className="slots" label={weekDay.slice(0, 3).toUpperCase()}>
                  <KoForm.Item name={[index, "weekDay"]} hidden>
                    <KoInput />
                  </KoForm.Item>
                  <KoForm.Item name={[index, "type"]} hidden>
                    <KoInput />
                  </KoForm.Item>
                  <KoForm.List name={[index, "intervals"]}>
                    {(timeFields, { add, remove }) => (
                      <>
                        {timeFields.length === 0 && (
                          <div className={`${styles.timeInput} timeInput`}>
                            <KoButton
                              iconBtn={true}
                              icon={<KoIcon name="PlusOutlined" />}
                              onClick={() =>
                                add({
                                  from: defaultTimeRange.from,
                                  to: defaultTimeRange.to
                                })
                              }
                              className={styles.addButton}
                            />
                          </div>
                        )}
                        {timeFields.map((field) => (
                          <div key={field.key} className={`${styles.timeInput} timeInput`}>
                            <KoForm.Item
                              name={[field.name, "from"]}
                              rules={[{ required: true, message: "Required" }]}
                            >
                              <KoTimePicker
                                format="HH:mm"
                                changeOnScroll
                                needConfirm={false}
                                defaultValue={convertToDayjs(defaultTimeRange.from)}
                              />
                            </KoForm.Item>
                            <span> - </span>
                            <KoForm.Item
                              name={[field.name, "to"]}
                              rules={[{ required: true, message: "Required" }]}
                            >
                              <KoTimePicker
                                format="HH:mm"
                                changeOnScroll
                                needConfirm={false}
                                defaultValue={convertToDayjs(defaultTimeRange.to)}
                              />
                            </KoForm.Item>
                            <KoButton
                              iconBtn={true}
                              icon={<KoIcon name="PlusOutlined" />}
                              onClick={() => {
                                const newFrom = addHour(defaultTimeRange.to);
                                const newTo = addHour(newFrom);
                                add({ from: newFrom, to: newTo });
                              }}
                              className={styles.addButton}
                            />
                            <KoButton
                              iconBtn={true}
                              icon={<KoIcon name="DeleteOutlined" />}
                              onClick={() => remove(field.name)}
                            />
                          </div>
                        ))}
                      </>
                    )}
                  </KoForm.List>
                </KoForm.Item>
              </div>
            ))
          }
        </KoForm.List>
        <KoForm.Item>
          <div className={styles.button}>
            <KoButton type="primary" htmlType="submit" label="Save" />
          </div>
        </KoForm.Item>
      </KoForm>
    </div>
  );
};

export default TimeIntervalForm;
