import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {  KoCalendar } from 'packages';
import { add, remove } from 'shared/store/breadcrumbSlice';
import {
  KoButton,
  KoModal,
  KoForm,
  KoLoader,
  KoNano,
  KoToast,
  KoText,
  KoTitle
} from "packages";
import styles from "./calendar.module.scss";
import EventCrud from './curd';

import { CalendarAPI } from '../calendar.service';


function Meetings() {

  const [currentRecord, setCurrentRecord] = useState();
  const [eventOpen, setEventOpen]       = useState(false);
  
  const [isOpen, setModal] = useState(false);
  const [isOpenDelete, setDeleteModal] = useState(false);
  const [data, setData] = useState();
  const [isLoading, setLoading] = useState(false);
  const [form] = KoForm.useForm();

  useEffect(() => {
    getMeetings();
  }, []);
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(remove());
    dispatch(add([]));
  },[]);


  const getMeetings = () => {
    setData();
    setLoading(true);
    CalendarAPI.getMeetings().then(
      (data) => {
        setData(data?.meetings);
        setEventOpen(false);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };


  const handleEventFinish = (values => {
    let data              = form.getFieldsValue();
    if(!currentRecord?.meetingId){
      values['meetingId']   = KoNano('CAL');
    }
    values['accepted']    = [];
    values['maybe']       = [];
    values['notJoining']  = [];
    values['platform']    = {name:values.meetingVia,url:values.url,id:KoNano('URL')};
    values['agenda']      = data['agenda'];

    CalendarAPI.createMeeting(values, currentRecord?.meetingId)
      .then((data) => {
        getMeetings();
        setEventOpen(false);
        KoToast.success(`Meeting ${currentRecord?.meetingId ? 'updated':'created'} successfully`);
      },
      (err) => {
        if(err?.error === 'this role does not have sufficient permission'){
          KoToast.error('You do not have sufficient permission');
        }}
      );

  });

  const DeleteMeeting= ()=> {
    CalendarAPI.deleteMeeting(currentRecord?.meetingId).then(
      (data) => {
        setLoading(false);
        setEventOpen(false);
        setDeleteModal(false);
        getMeetings();
        KoToast.success("Meeting Deleted Successfully");
      },
      (err) => {
        setLoading(false);
      }
    );
    getMeetings();
  };


  
  return (
    <div className={styles.wrapper}>
      {isLoading && <KoLoader />}
      <KoCalendar
        events={data}
        onEventClick={(event) => {setCurrentRecord(event); setModal(true);}}
        currentRecord={currentRecord}
        setCurrentRecord={setCurrentRecord}
        onSelectDate={(date) => {}}
        onCreateHuddle={()=>setEventOpen(true)}
        isOpen={isOpen}
        setDeleteModal={setDeleteModal}
        setModal={setModal}
      />
      <KoModal
        title={`${currentRecord?.meetingId ? 'Update' : 'Create'} meeting`}
        centered
        open={eventOpen}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setEventOpen(false); form.resetFields(); }}
        onClose={() => { setCurrentRecord(); setEventOpen(false); form.resetFields(); }}
        width={800}
        // maskClosable={false}
        height={650}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setEventOpen(!eventOpen)} />,
          <KoButton key='create' loading={isLoading} type='primary' label={`${currentRecord?.meetingId ? 'Update' : 'Create'}`} onClick={() => form.submit()} />
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <EventCrud form={form} handleFinish={handleEventFinish} initialValues={currentRecord}/>
      </KoModal>

      <KoModal
        title={`Delete meeting ${currentRecord?.name?.toUpperCase()}`}
        centered
        open={isOpenDelete}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setDeleteModal(false); }}
        onClose={() => { setCurrentRecord(); setDeleteModal(false); }}
        width={580}
        // maskClosable={false}
        height={450}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setDeleteModal(!isOpenDelete)} />,
          <KoButton key='delete' loading={isLoading} type='primary' label='Delete' onClick={() => DeleteMeeting()} />
        ]}
      >
        <div className={styles.suspend}>
          <KoTitle level={5} text='License fees apply to activated users,' />
          <KoText text={`granting access to all paid services.`} />
        </div>
      </KoModal>

   
    </div>
  );
}

export default Meetings;
