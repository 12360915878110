import React from 'react';
import {KoBadge, KoButton, KoIcon, KoPopOver, Upload} from 'packages';
import styles from './chat.module.scss';
import KoChatEditor from './chatEditor';
export default function MessageBox({
  footerCls,
  uploadProps,
  emojiContent,
  openDropArea,
  callback,
  emoji,
  handleSendMessage,
  currentUser,
  handleTyping,
  handleSendClick,
  fileList,
  updateMessage,
  replyMessage,
  attachmentHide
}) {
  return (
    <div className={`${styles.footer} ${footerCls}`}>
      <div className={styles.otherOptions}>
        {!attachmentHide&& <Upload {...uploadProps}
          showUploadList={false}
          multiple={true}
        >
          <KoButton onClick={openDropArea} type='text' iconBtn shape='circle' icon={<KoIcon width={17} height={17} name="AttachmentOutlined" />} />
        </Upload>}
        <KoPopOver placement="bottomLeft" className={styles.emojiPop} arrow={false} content={emojiContent} trigger="click">
          <KoButton type='text' iconBtn shape='circle' icon={<KoIcon width={20} height={20} name="SmileyOutlined" />} />
        </KoPopOver>
      </div>
      <div className={styles.footerWrapper}>
        <KoChatEditor
          editMessage={updateMessage}
          replyMessage={replyMessage}
          callback={callback}
          emoji={emoji}
          onSendMessage={(text) => handleSendMessage(text, currentUser?.userId)}
          onTyping={(isTyping) => handleTyping(currentUser?.userId)}
        />
      </div>
      <div className={styles.footerButtons}>
        <KoBadge count={fileList?.length} offset={[-8, 0]} color={'var(--palette-success-dark)'}  >
          <KoButton onClick={handleSendClick} iconBtn shape='circle' icon={<KoIcon width={16} height={16} name="SendOutlined" />} />
        </KoBadge>
      </div>
    </div>
  );
}
