import React from "react";
function GoogleMeet(props) {
  const { width, height } = props;
  return (
  
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 48 48"
      width={width} height={height}
      {...props}
    >
      <path
        fill="#00832d"
        d="M27.154 23.749l4.68 5.348 6.292 4.021 1.097-9.334-1.097-9.128-6.413 3.533-4.559 5.56z"
        className="color00832D svgShape"
      />
      <path
        fill="#0066da"
        d="M0 32.251v7.955a3.29 3.29 0 003.291 3.291h7.955l1.645-6.012-1.645-5.234-5.459-1.645L0 32.25z"
        className="color0066DA svgShape"
      />
      <path
        fill="#e94235"
        d="M11.246 4L0 15.246l5.787 1.645 5.459-1.645 1.618-5.165L11.246 4z"
        className="colorE94235 svgShape"
      />
      <path
        fill="#2684fc"
        d="M11.246 15.246H0V32.25h11.246V15.246z"
        className="color2684FC svgShape"
      />
      <path
        fill="#00ac47"
        d="M45.31 8.762l-7.184 5.894v18.462l7.213 5.917c1.081.844 2.661.074 2.661-1.298V10.034c0-1.39-1.616-2.153-2.69-1.272zM27.153 23.749v8.503H11.246v11.245h23.588a3.29 3.29 0 003.292-3.291v-7.088l-10.972-9.37z"
        className="color00AC47 svgShape"
      />
      <path
        fill="#ffba00"
        d="M34.834 4H11.246v11.246h15.908v8.503l10.972-9.093V7.291A3.29 3.29 0 0034.834 4z"
        className="colorFFBA00 svgShape"
      />
    </svg>
  );
}


export default GoogleMeet;