
const grid = {
  left: '0%',
  right: '0%',
  bottom: '0%',
  top: '10%',
  containLabel: true
};
const series= [
  {
    name: 'Annual Leave',
    type: 'bar',
    stack: 'total',
    emphasis: {
      disabled: true
    },
    data: [6.5,4,1,5,1,7.5]
  },
  {
    name: 'Sick Leave',
    type: 'bar',
    stack: 'total',
    emphasis: {
      disabled: true
    },
    data: [1,2,3,5,7,2]
  },
  {
    name: 'Leave Without Pay',
    type: 'bar',
    stack: 'total',
    emphasis: {
      disabled: true
    },
    data: [3, 5,7,9,11,1]
  },
  {
    name: 'Compensatory Off',
    type: 'bar',
    stack: 'total',
    emphasis: {
      disabled: true
    },
    data: [2, 4,6,8,10,5]
  },
  {
    name: 'Work from Home',
    type: 'bar',
    stack: 'total',
    emphasis: {
      disabled: true
    },
    data: [1, 4,8,5,2,3]
  },
  {
    name: 'Maternity Leave',
    type: 'bar',
    stack: 'total',
    emphasis: {
      disabled: true
    },
    data: [5, 6,8,3,1,7]
  },
  {
    name: 'Paternity Leave',
    type: 'bar',
    stack: 'total',
    emphasis: {
      disabled: true
    },
    data: [3, 6,9,7,2,4]
  },
  {
    name: 'Exception Leave',
    type: 'bar',
    stack: 'total',
    emphasis: {
      disabled: true
    },
    data: [6, 8,10,4,5,9]
  }
];
var stackInfo = {};
for (let i = 0; i < series[0].data.length; ++i) {
  for (let j = 0; j < series.length; ++j) {
    const stackName = series[j].stack;
    if (!stackName) {
      continue;
    }
    if (!stackInfo[stackName]) {
      stackInfo[stackName] = {
        stackStart: [],
        stackEnd: []
      };
    }
    const info = stackInfo[stackName];
    const data = series[j].data[i];
    if (data && data !== '-') {
      if (info.stackStart[i] == null) {
        info.stackStart[i] = j;
      }
      info.stackEnd[i] = j;
    }
  }
}
for (let i = 0; i < series.length; ++i) {
  const data = series[i].data;
  const info = stackInfo[series[i].stack];
  for (let j = 0; j < series[i].data.length; ++j) {
    const isEnd        = info.stackEnd[j] === i;
    const topBorder    = isEnd ? 8 : 0;
    const bottomBorder = 0;
    data[j] = {
      value: data[j],
      itemStyle: {
        borderRadius: [topBorder, topBorder, bottomBorder, bottomBorder]
      }
    };
  }
}
const WORK_TIMINGS_DATA={
  tooltip: {
    trigger: 'axis',
    textStyle: {
      fontSize: 10,
      color: "var(--palette-gray-7)"
    },
    axisPointer: {
      type: 'none'
    }
  },
  color: [
    "var(--support)",
    "var(--subtask)",
    "var(--color-palette-7-dark)",
    "var(--color-palette-3-mid)",
    "var(--color-palette-5-mid)",
    "var(--color-palette-4-dark)",
    "var(--color-palette-2-dark)",
    "var(--palette-warning-light)"
  ],
  legend: {
    show: false
  },
  barWidth:20,
  grid,
  xAxis: {
    type: 'category',
    data: ['Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'],
    axisLabel:{
      fontSize:9
    }
  },
  yAxis: {
    type: 'value',
    axisLabel: {
      formatter: val => `${val/10}h`
    }
  },
  series
};

const DONUT = {
  tooltip: {
    show:false
  },
  legend: {
    show: false
  },
  title: {
    text: 'Approved',
    subtext:'40',
    left: 'center',
    top: 'center',
    textStyle: {
      top:'-4%',
      fontSize: 12,
      color: "var(--palette-gray-7)"
    },
    subtextStyle: {
      fontSize: 30,
      lineHeight: 10
    }
  },
  color: ["#03D37E", "#E75167", "#79869A"],
  series: [
    {
      type: 'pie',
      radius: ['50%', '80%'],
      avoidLabelOverlap: false,
      left: '0%',
      showEmptyCircle: true,
      top: '5%',
      emphasis: {
        show: false,
        formatter: `customized {a}\n {b}: {c}`
      },
      data: [
        { value: 90, name: 'Approved' },
        { value: 50, name: 'Rejected' },
        { value: 70, name: 'Canceled' }
      ].sort(function(a, b) {
        return a.value - b.value;
      }),
      labelLine: {
        smooth: 0.2,
        length: 7,
        show:false
      },
      label: {
        positions:'center',
        show:false
      },
      animationType: 'scale',
      animationEasing: 'elasticOut',
      animationDelay: function() {
        return Math.random() * 200;
      }
    }
  ],
  height: "100%"
};
const ANNUAL_LEAVE_DATA = {
  tooltip: {
    show:false
  },
  legend: {
    top: '90%',
    right: '2%',
    show: true
    
  },
  title: {
    text: '13.5 days',
    subtext:'AVAILABLE',
    left: 'center',
    top: 'center',
    textStyle: {
      top:'-4%',
      fontSize: 18
    },
    subtextStyle: {
      fontSize: 10,
      lineHeight: 10,
      color: "var(--palette-gray-6)"
    }
  },
  color: ["#3179D4", "#4D579F", "#FE9082"],
  series: [
    {
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      left: '0%',
      showEmptyCircle: true,
      top: '0%',
      itemStyle : {
        normal : {
          label : {
            fontSize:12,
            fontWeight:'bold',
            show: true,
            position: 'inner',
            formatter : function(params){
              return  params.value + '%\n';
            }
          },
          labelLine : {
            show : false
          }
        },
        emphasis : {
          label : {
            show : true
          }
        }
      },
      emphasis: {
        show: true
      },
      data: [
        { value: 25, name: 'Requested Leave' },
        { value: 47, name: 'Remaining Leave' },
        { value: 12, name: 'Taken Leave' }
      ].sort(function(a, b) {
        return a.value - b.value;
      }),
      labelLine: {
        smooth: 0.2,
        length: 7,
        show:false
      },
      label: {
        positions:'center',
        show:false
      },
      animationType: 'scale',
      animationEasing: 'elasticOut',
      animationDelay: function() {
        return Math.random() * 200;
      }
    }
  ],
  height: "100%"
};

const LEAVE_BALANCE = [
  {
    id: "leaveType",
    label: "Leave Type",
    widget: "text",
    fixed: 'left'
  }, {
    id: "Accured",
    label: "Accured",
    widget: "text"
  },
  {
    id: "taken",
    label: "Taken",
    widget: "text"
  },
  {
    id: "requested",
    label: "Requested",
    widget: "text"
  },
    
  {
    id: "balance",
    label: "Balance",
    widget: "text"
  },
  {
    id: "action",
    label: "Action",
    widget: "button"
  }];
const LEAVE_REQUEST = [
  {
    id: "fromDate",
    label: "From Date",
    widget: "text"
  }, {
    id: "toDate",
    label: "To Date",
    widget: "text"
  },
  {
    id: "leaveType",
    label: "Leave Type",
    widget: "text"
  },
  {
    id: "reason",
    label: "Reason",
    widget: "text"
  },
  {
    id: "attachment",
    label: "Attachment",
    widget: "text"
  },
  {
    id: "approval_process",
    label: "Approval Process",
    widget: "text"
  }];
export {
  WORK_TIMINGS_DATA,
  DONUT,
  ANNUAL_LEAVE_DATA,
  LEAVE_BALANCE,
  LEAVE_REQUEST
};