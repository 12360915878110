const roleAccess = {
  1: ['Dashboard', 'Genrate Invoice', 'My Invoices', 'Pay out', 'Pay in','Overdue', 'Shared'],
  2: ['Dashboard', 'Genrate Invoice', 'My Invoices', 'Pay out', 'Pay in', 'Overdue','Shared'],
  3: [],
  4: ['Dashboard', 'My Invoices', 'Shared'],
  5: [],
  6: ['Dashboard', 'Genrate Invoice', 'My Invoices', 'Pay out', 'Pay in', 'Overdue', 'Shared'],
  7: []
};

const menu =  [{
  label: 'Dashboard',
  key: 'invoices/dashboard',
  icon: 'DashboardFilled'
},
{
  label: 'Genrate Invoice',
  key: 'invoices/genrate',
  icon: 'InvoicesOutlined'
},
{
  label: 'My Invoices',
  key: 'invoices/me',
  icon: 'InvoicesOutlined'
},
{
  label: 'Pay in',
  key: 'invoices/payin',
  icon: 'InvoicesOutlined'
},
{
  label: 'Pay out',
  key: 'invoices/payout',
  icon: 'InvoicesOutlined'
},
{
  label: 'Overdue',
  key: 'invoices/overdue',
  icon: 'InvoicesOutlined'
},
{
  label: 'Shared',
  key: 'invoices/shared',
  icon: 'SharedOutlined'
}];
  
function getInvoiceMenuForRoles(user) {
  const accessibleMenus = new Set();
  user?.roles?.forEach(roleId => {
    const accessibleItems = roleAccess[roleId] || [];
    accessibleItems?.forEach(item => accessibleMenus.add(item));
  });
  return menu.filter(menuItem => accessibleMenus.has(menuItem.label));
}

  
export {
  getInvoiceMenuForRoles
};
  