import { KoCharts, KoLoader, KoProgressCircle, KoSegmented, KoTable, KoText, KoTitle } from 'packages';
import { useEffect, useState } from 'react';
import { InvoicesAPI } from '../invoice.service';
import { ACCOUNT_PAYABLE_COLUMN, ACCOUNT_RECEIVABLE_COLUMN, COLUMN, INVOICE_EXPENDITURE, INVOICE_TABLE } from './constant';
import styles from './dashboard.module.scss';
import { useParams } from 'react-router';
export default function InvoiceDashboard() {

  const { workspaceId} = useParams();

  const [tabVal, setTabVal] = useState('all');
  const [isLoading, setLoading] = useState(false);
  const [invoiceSummary, setInvoiceSummary] = useState();
  const [invoiceByStatus, setInvoiceByStatus] = useState();
  const [invoiceCountByStatus, setInvoiceCountByStatus] = useState();
  const [chartData, setChartData] = useState();

  useEffect(()=>{
    getInvoiceSummary();
    getInvoiceByStatus();
    getInvoiceCountByStatus();
    // getInvoiceStatistics();
    setChartData();
  },[]);



  // const getInvoiceStatistics=()=>{
  //   const today = new Date();
  //   const dayOfWeek = today.getDay();

  //   const startDate = new Date(today);
  //   startDate.setDate(today.getDate() - dayOfWeek);

  //   const endDate = new Date(today);
  //   endDate.setDate(today.getDate() + (6 - dayOfWeek));

  //   const formatDate = (date) => {
  //     const year = date.getFullYear();
  //     const month = (date.getMonth() + 1).toString().padStart(2, '0');
  //     const day = date.getDate().toString().padStart(2, '0');
  //     return `${year}-${month}-${day}`;
  //   };

  //   const q = {
  //     startDate: formatDate(startDate),
  //     endDate: formatDate(endDate)
  //   };
  //   function timeStringToHours(timeStr) {
  //     const [hours, minutes] = timeStr.split(':').map(Number);
  //     return hours + minutes / 60;
  //   }
   
  //   setLoading(true);
  //   InvoicesAPI.getInvoiceStatistics(q).then(
  //     (data) => {
        
  //       const BillableData = dataTest?.map(entry => timeStringToHours(entry.billableHours));
  //       const nonBillableData = dataTest?.map(entry => timeStringToHours(entry.nonBillableHours));
        
  //       const workLogDatesArray = data?.map(entry => {
  //         const dates = new Date(entry.workLogDate);
  //         const day = dates.getDate().toString().padStart(2, '0');
  //         const month = dates.toLocaleString('en-US', { month: 'short' });
  //         return `${day}-${month}`;
  //       });

        
  //       const series = [
  //         {
  //           name: 'Billable Time',
  //           type: 'bar',
  //           stack: 'total',
  //           emphasis: {
  //             disabled: true
  //           },
  //           data: [6.5,4,12,14,11,7.5]
  //         },
  //         {
  //           name: 'Non billable Time',
  //           type: 'bar',
  //           stack: 'total',
  //           emphasis: {
  //             disabled: true
  //           },
  //           data:  [0, 2,0,0,0,2]
  //         }
  //       ];
       
        
  //       var stackInfo = {};
  //       for (let i = 0; i < series[0].data?.length; ++i) {
  //         for (let j = 0; j < series.length; ++j) {
  //           const stackName = series[j].stack;
  //           if (!stackName) {
  //             continue;
  //           }
  //           if (!stackInfo[stackName]) {
  //             stackInfo[stackName] = {
  //               stackStart: [],
  //               stackEnd: []
  //             };
  //           }
  //           const info = stackInfo[stackName];
  //           const data = series[j].data[i];
  //           if (data && data !== '-') {
  //             if (info.stackStart[i] == null) {
  //               info.stackStart[i] = j;
  //             }
  //             info.stackEnd[i] = j;
  //           }
  //         }
  //       }

  //       for (let i = 0; i < series.length; ++i) {
  //         const data = series[i].data;
  //         const info = stackInfo[series[i].stack];
  //         for (let j = 0; j < series[i].data?.length; ++j) {
  //           const isEnd        = info.stackEnd[j] === i;
  //           const topBorder    = isEnd ? 8 : 0;
  //           const bottomBorder = 0;
  //           data[j] = {
  //             value: data[j],
  //             itemStyle: {
  //               borderRadius: [topBorder, topBorder, bottomBorder, bottomBorder]
  //             }
  //           };
  //         }
  //       }

  //       let obj = BILLABLE_HOURS;
  //       obj['xAxis']['data']=workLogDatesArray;
  //       obj['series'] = series;
  //       setChartData(obj);
  //       setLoading(false);
  //     },
  //     (err) => {
  //       setLoading(false);
  //     }
  //   );
  // };


  const getInvoiceByStatus = () => {
    setLoading(true);
    InvoicesAPI.getInvoiceByStatus(workspaceId).then(
      (data) => {
        setInvoiceByStatus(data['invoices']);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const getInvoiceCountByStatus = () => {
    setLoading(true);
    InvoicesAPI.getInvoiceCountByStatus(workspaceId).then(
      (data) => {
        setInvoiceCountByStatus(data['totalCount']);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const getInvoiceSummary = () => {
    setLoading(true);
    InvoicesAPI.getInvoiceSummary(workspaceId).then(
      (data) => {
        setInvoiceSummary(data['invoiceSummary']);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const transformData = (data) => {
    return Object.keys(data).map(key => ({
      name: key,
      value: data[key]
    }));
  };

  const getDonutChartConfig = (data) => {
    const arrayData = transformData(data);
    const sortedData = arrayData.sort((a, b) => a.value - b.value);

    

    return {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        top: '20%',
        right: '6%',
        orient: 'vertical',
        show: true,
        icon: 'circle'
      },
      color: ["#FE9082", "#D3D7E7", "#4D579F", "#3179D4"],
      series: [
        {
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          left: '-40%',
          showEmptyCircle: true,
          top: '8%',
          data: sortedData,
          labelLine: {
            smooth: 0.2,
            length: 7
          },
          label: {
            formatter: '{d}',
            color: "var(--palette-gray-7)"
          },
          animationType: 'scale',
          animationEasing: 'elasticOut',
          animationDelay: function() {
            return Math.random() * 200;
          }
        }
      ],
      height: "100%"
    };
  };

  
 

  const TAB_LIST = [
    {
      label: 'All',
      value: 'all'
    },
    {
      label: 'Paid',
      value: 'paid'
    },
    {
      label: 'Pending',
      value: 'pending'
    },
    {
      label: 'Overdue',
      value: 'overdue'
    },
    {
      label: 'Draft',
      value: 'draft'
    }
  ];

  const formatCurrency = (amount) => {
    return `$ ${new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2
    }).format(amount)}`;
  };



  return (
    <>
      <div className={styles.wrapper}>
        {isLoading && <KoLoader/>}
        <div className={styles.invoiceSummary}>
          <div className={styles.invoiceSummaryRow}>
            <div className={styles.card}>
              <div className={styles.header}>
                <KoTitle text='Invoice Summary' level={5} />
              </div>
              <div className={`${styles.bodyContnet} smallTable`}>
                <div className={styles.invoiceStatus}>
                  <div className={styles.progressCircle}>
                    <KoProgressCircle strokeColor={`var(--palette-info-main)`} size={35} type="circle" percent={invoiceSummary?.totalInvoiced ?? '0'} />
                    <div className={styles.amount}>
                      <KoTitle className={styles.text} text={formatCurrency(invoiceSummary?.totalInvoiced ?? '0')} level={5} />
                      <KoText className={styles.small} text='Total Invoiced' />
                    </div>
                  </div>
                  <div className={styles.progressCircle}>
                    <KoProgressCircle size={35} strokeColor={`var(--palette-success-main)`} type="circle" percent={invoiceSummary?.totalReceived ?? '0'} />
                    <div className={styles.amount}>
                      <KoTitle className={styles.text} text={formatCurrency(invoiceSummary?.totalReceived ?? '0')} level={5} />
                      <KoText className={styles.small} text='Total Received' />
                    </div>
                  </div>
                  <div className={styles.progressCircle}>
                    <KoProgressCircle size={35} strokeColor={`var(--palette-warning-main)`} type="circle" percent={invoiceSummary?.totalPending ?? '0'} />
                    <div className={styles.amount}>
                      <KoTitle className={styles.text} text={formatCurrency(invoiceSummary?.totalPending ?? '0')} level={5} />
                      <KoText className={styles.small} text='Total Pending' />
                    </div>
                  </div>
                  <div className={styles.progressCircle}>
                    <KoProgressCircle size={35} strokeColor={`var(--palette-error-main)`} type="circle" percent={invoiceSummary?.overdue ?? '0'} />
                    <div className={styles.amount}>
                      <KoTitle className={styles.text} text={formatCurrency(invoiceSummary?.overdue ?? '0')} level={5} />
                      <KoText className={styles.small} text='Overdue' />
                    </div>
                  </div>
                  <div className={styles.progressCircle}>
                    <KoProgressCircle size={35} strokeColor={`var(--palette-gray-7)`} type="circle" percent={invoiceSummary?.draft ?? '0'} />
                    <div className={styles.amount}>
                      <KoTitle className={styles.text} text={formatCurrency(invoiceSummary?.draft ?? '0')} level={5} />
                      <KoText className={styles.small} text='Draft' />
                    </div>
                  </div>
                </div>
                <KoTable data={{ columns: COLUMN, rows: invoiceByStatus }} maxHeight={65} />
              </div>
            </div>
          </div>
          <div className={styles.invoiceRow}>
            <div className={styles.card}>
              <div className={styles.header}>
                <KoTitle text='Finance Performance' level={5} />
              </div>
              <div className={styles.bodyContnet}>
                <div className={styles.graphDonut}>
                  {chartData &&  <KoCharts option={chartData} />}
                </div>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.header}>
                <KoTitle text='Invoice Proceesing Status' level={5} />
              </div>
              <div className={styles.bodyContnet}>
                <div className={styles.graphDonut}>
                  {invoiceCountByStatus && <KoCharts option={getDonutChartConfig(invoiceCountByStatus)} />}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.accountsInfo}>
            <div className={styles.accountReceivable}>
              <div className={styles.header}>
                <KoTitle text='Account Receivable' level={5} />
              </div>
              <div className={styles.leaveBalanceTable}>
                <KoTable rowKey='accountReceivable' data={{ columns: ACCOUNT_RECEIVABLE_COLUMN, rows: [] }} maxHeight={35} />
              </div>
            </div>
            <div className={styles.accountPayable}>
              <div className={styles.header}>
                <KoTitle text='Account Payable' level={5} />
              </div>
              <div className={styles.leaveBalanceTable}>
                <KoTable rowKey='accountPayable' data={{ columns: ACCOUNT_PAYABLE_COLUMN, rows: [] }} maxHeight={35} />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.incomeExpenditure}>
          <div className={styles.header}>
            <KoTitle text='Quick summary' level={5} />
          </div>
          <div className={styles.bodyContnet}>
            <KoTable rowKey='accountReceivable' data={{ columns: ACCOUNT_RECEIVABLE_COLUMN, rows: [] }} maxHeight={35} />
          </div>
          <div className={styles.header}>
            <KoTitle text='Income vs Expenditure' level={5} />
          </div>
          <div className={styles.bodyContnet}>
            <div className={styles.graphDonut}>
              <KoCharts option={INVOICE_EXPENDITURE} />
            </div>
            <div className={styles.expenditure}>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) =>
                <div key={item} className={styles.activity}>
                  <KoTitle className={styles.name} text={(item === 1 || item === 3) ? 'Office Supplies' :'Accommodation'} level={5} />
                  <div className={styles.amountExpenditure}>
                    <KoTitle className={styles.text} text='$ 13,870.00' level={5} />
                    <KoProgressCircle size={20} strokeColor={`${(item === 1 || item === 3)? 'var(--palette-error-dark)}' : 'var(--palette-success-dark)'}`} type="circle" percent={30+item} />
                  </div>
               
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
 
    
      <div className={styles.invoiceTable}>
        <div className={styles.segMentList}>
          <KoSegmented
            options={TAB_LIST}
            value={tabVal}
            onChange={setTabVal}
          />
          <div className={styles.accountReceivable}>
            <KoTable rowKey='accountPayable' data={{ columns: INVOICE_TABLE, rows: [] }} maxHeight={35} />
          </div>
        </div>
      </div>
    </>
  );
}
