import React from "react";
function Bold(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 32 32" width={width}
      height={height}>
      <path d="m20.64,15.67c1.03-.92,1.69-2.24,1.69-3.73v-.44c0-2.77-2.26-5.03-5.03-5.03H7.63c-.55,0-1,.45-1,1v18c0,.55.45,1,1,1h10.22c3.05,0,5.53-2.48,5.53-5.53v-.5c0-2.03-1.1-3.81-2.74-4.77Zm-3.34-7.2c1.67,0,3.03,1.36,3.03,3.03v.44c0,1.46-1.04,2.69-2.42,2.97-.02,0-.04,0-.05,0h-9.22v-6.44h8.67Zm4.09,12.47c0,1.95-1.58,3.53-3.53,3.53h-9.22v-7.5h8.67c.2,0,.4-.04.6-.06,1.93.02,3.49,1.59,3.49,3.53v.5Z" fill="#000000"></path></svg>
  );
}


export default Bold;


