/* eslint-disable no-unused-expressions */


import React, { useState } from 'react';
import { Table } from 'antd';
import { createStyles } from 'antd-style';

import { Resizable } from 'react-resizable';
import { serializeColumns, addLeftColumns, addRightColumns } from './utils';
import css from './datatable.module.scss';
import KoEmpty from 'packages/empty';
import KoIcon from 'packages/icon';
import { KoText } from 'packages/typography';

const ResizeableTitle = (props) => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};


const useStyle = createStyles(({ css, token }) => {
  const { antCls } = token;
  return {
    customTable: css`
      ${antCls}-table {
        ${antCls}-table-container {
          ${antCls}-table-body,
          ${antCls}-table-content {
            scrollbar-width: thin;
            scrollbar-color: unset;
          }
        }
      }
    `
  };
});



const KoTable = (props) => {
  const [top] = useState('none');
  const [bottom] = useState('bottomRight');
  const [columns, setColumns] = useState(serializeColumns(props.data.columns, props?.onCellClick, props?.onExtraClick));
  const [selectedRowKeys, setSelectedRowKeys] = useState(props?.selected ? props?.selected : []);
  const [expandedRow, setExpandedRow] = useState(0);
  const [sortBy, setSortBy] = useState({});
  const components = {
    header: {
      cell: ResizeableTitle
    }
  };

  const handleResize = (index) => (e, { size }) => {
    setColumns((prevColumns) => {
      const nextColumns = [...prevColumns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width
      };
      return nextColumns;
    });
  };

  const onSelectChange = (selectedKeys) => {
    setSelectedRowKeys(selectedKeys);
    props.onRowSelection(selectedKeys);
  };


  const onSort=(data)=>{
    setSortBy({[data.key]: sortBy[data.key] === 'asc' ? 'desc' : 'asc'});
    props?.onSort && props.onSort(data.key, sortBy[data.key] === 'asc' ? 'desc' : 'asc');
  };

  let colHeader = columns.map((col, index) => ({
    ...col,
    onHeaderCell: (column) => ({
      width: column.width,
      onResize: handleResize(index)
    }),
    title: () => (
      <div className={css.title} style={{ justifyContent: col.align ? col.align : 'left' }}>
        <KoText className={css.displayName} text={col?.label} ellipsis={true} />
        { sortBy[col.key] ? <div className={css.sort} onClick={() => onSort(col)}>
          {sortBy[col.key] === 'asc' ?<KoIcon name="DescendingOutlined" width={12} height={12} /> :
            <KoIcon name="AscendingOutlined" width={12} height={12} />}
        </div> :
          <div className={css.sort} onClick={() => onSort(col)}>
            <KoIcon name="SortOutlined" width={12} height={12} />
          </div>}
      </div>
    )
  }));

  const rowSelectionConfig = {
    selectedRowKeys,
    onChange: onSelectChange
  };

  if (props.extraColumnsRight) {
    colHeader = addRightColumns(colHeader, props.extraColumnsRight);
  }

  if (props.extraColumnsLeft) {
    colHeader = addLeftColumns(colHeader, props.extraColumnsLeft);
  }

  let locale = {
    emptyText: (
      <KoEmpty width={150} height={150} icon={'TableEmptyTwoTone'} isTable={true} />
    )
  };

  const expandedRowRender = () => {
    if (props.expandable.isTable) {
      let colHeader = serializeColumns(props.expandable.columns).map((col, index) => ({
        ...col,
        onHeaderCell: column => ({
          width: column.width,
          onResize: this.handleResize(index)
        }),
        title: () => <div>
          <span className={css.displayName}>{col['displayName']}</span>
        </div>
      }));

      return (
        <div className={css.subTable}>
          {props.expandable?.rows?.length > 0 ?
            <Table showHeader={props.expandable.showHeader}
              onRow={(record, index) => ({
                onClick: () => { props.onRowAction ? props.onRowAction(record, index) : this.onRow = () => { }; }
              })}
              columns={colHeader}
              dataSource={props.expandable.rows} pagination={false} scroll={{ x: 'max-content' }} /> :
            <KoEmpty />
          }
        </div>
      );
    } else {
      return props.expandable.component;
    }

  };



  let expandable = {};
  if (props.expandable) {
    expandable['expandedRowRender'] = props.expandable.isTable ? expandedRowRender : props?.expandable?.expandedRowRender;
    expandable['expandedRowKeys'] = [expandedRow];
    expandable['defaultExpandedRowKeys'] = [0];
    expandable['expandIcon'] = ({ expanded, onExpand, record }) =>
      expanded ? (
        record[props.expandable?.countBased] <= 1 ? "" : <KoIcon name="RowCloseOutlined"
          onClick={(e) => {
            onExpand(record, e);
            setExpandedRow(!expanded ? record[props?.rowKey] : undefined);
            onExpand(record, e);
          }} />
      ) : (
        record[props.expandable?.countBased] <= 1 ? "" : <KoIcon name="RowOpenOutlined"
          onClick={(e) => {
            onExpand(record, e); setExpandedRow(!expanded ? record[props?.rowKey] : undefined);
            onExpand(record, e);
          }} />
      );
  }

  const { styles } = useStyle();
  return (
    <div className={`${css.dataWrapper} ${props.className} ${props?.size === 'small' && css.small}`}>
      <Table
        className={styles.customTable}
        locale={locale}
        {...{
          pagination: false,
          top,
          bottom,
          rowSelection: props.onRowSelection ? rowSelectionConfig : false
        }}
        rowKey={props?.rowKey ? props?.rowKey : 'id'}
        pagination={false}
        columns={colHeader}
        dataSource={props.data.rows}
        expandable={expandable}
        components={!props.resize ? components : ''}
        size="small"
        onRow={(record, index) => ({
          onClick: () => {
            props.onRow ? props.onRow(record, index) : (() => { });
          }
        })}
        scroll={{
          x: 'max-content',
          y: props.maxHeight ? props?.maxHeight * 5 : 100 *5
        }}
      />
    </div>
  );
};

export default KoTable;
