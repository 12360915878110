"use client";
import React, { useCallback } from 'react';
import { KoFormBuilder, KoSelect, KoForm } from 'packages';
import styles from './employees.module.scss';

function GroupCrud({ form, handleFinish, initialValues }) {
  const handleSubmit = useCallback(values => {
    handleFinish(values);
  });

  const options = [
    { label: 'Engineering ', value: 'Enginering' },
    { label: 'Genral', value: 'Genral' }];

  const Groups = ({ value, onChange }) =>

    <KoSelect mode="multiple" value={value}
      onChange={v => onChange(v)}
      showSearch
      placeholder='Choose groups'
      options={options} />;

  const meta = {
    columns: 4,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [

      {
        key: 'groups', label: 'Select Groups', colSpan: 4,
        widget: Groups,
        forwardRef: true
      }

    ]
  };


  return (
    <div className={styles.wrapper}>
      <KoForm form={form} layout="vertical"
        onFinish={handleSubmit} onSubmit={handleSubmit}>
        <KoFormBuilder form={form} meta={meta} />
      </KoForm>
    </div>
  );
};

export default GroupCrud;