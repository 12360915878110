import React from "react";
function Left(props) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width={width}
      height={height}
    >
      <g fill="#333" className="color333 svgShape">
        <path
          d="M31 3H1a1 1 0 100 2h30a1 1 0 100-2zm0 6H1a1 1 0 100 2h30a1 1 0 100-2zm0 6H1a1 1 0 100 2h30a1 1 0 100-2zm0 6H1a1 1 0 100 2h30a1 1 0 100-2zm0 6H1a1 1 0 100 2h30a1 1 0 100-2z"
          fill="#000"
          className="color000 svgShape"
        />
      </g>
    </svg>
  );
}


export default Left;


