import { KoButton, KoForm, KoFormBuilder, KoIcon, KoImage, KoText, KoTitle, KoToast } from 'packages';
import { useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { accountAPI } from '../account.service';
import styles from './password.module.scss';
import { deleteCookie } from 'shared/utils/cookies';

function ChangePass() {
  let navigate = useNavigate();
  const [form] = KoForm.useForm();
  const {mailId} = useParams();
  const [isLoading, setLoading] = useState(false);
  
  const meta = {
    columns: 4,
    formItemLayout: null,
    colon: true,
    fields: [
      {
        key: 'newPassword', label: 'Password', widget: 'password', placeholder: 'Ex.****.', rules: [
          {
            required: true,
            message: 'Password is required'
          }
        ], colSpan: 4
      },
      {
        key: 'confirmPassword', label: 'Confirm Password', widget: 'password', placeholder: 'Ex.****.', rules: [
          {
            required: true,
            message: 'Confirm password is required'
          }
        ], colSpan: 4
      }
    ]
  };

  const handleFinish = useCallback(values => {
    setLoading(true);
    values['email'] = mailId;
    accountAPI.changePassword(values).then(
      () => {
        KoToast.success('Password changed successfully');
        setLoading(false);
        deleteCookie("accessToken", "", 0);
        deleteCookie("userId", "", 0);
        deleteCookie("storeId", "", 0);
        deleteCookie("accountId", "", 0);
        deleteCookie("name", "", 0);
        navigate('/');
        window.location.reload();
      },
      (err) => {
        setLoading(false);
      }
    );
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.modal}>
        <div className={styles.formWrapper}>
          <div className={styles.header}>
            <KoIcon name='AppLogoFilled' width={108}
              height={37} />
          </div>
          <KoTitle text={`Change your password`} level={4} />
          <KoText text={`Establish a fresh password for your account to sign in and avail yourself of all the features.`} className={`${styles.small}`} />
          <div className={styles.formData}>
          </div>
          <KoForm form={form} layout="vertical" onFinish={handleFinish} onSubmit={handleFinish}>
            <KoFormBuilder className={styles.formData} form={form} meta={meta} />
          </KoForm>
          {/* <div className={styles.otpSection}>
            <KoText text="Verification Code" />
            <KoOTP onChangeOtp={onChangeOtp} numInputs={4} />
          </div> */}
          <div className={styles.signupBtn}>
            <KoButton onClick={() => window.history.back()} label={'Back'} />
            <KoButton loading={isLoading} onClick={() => form.submit()} label={'Change'} type={'primary'} />
          </div>
        </div>
        <div className={styles.banner}>
          <div className={styles.slider}>
            <KoImage
              alt={'name'}
              src={`/images/banner.svg`}
              width={500}
              height={300}
              preview={false}
            />
            <div className={styles.footer}>
              <KoTitle text={'Connect your team’s work to your product roadmap'} level={5} />
              <KoText text="Every team has a unique process. Use a out-of-boss workflow,
              or create one to match the way team works"/>
            </div>
          </div>
          <div className={styles.dots}>
            <div className={styles.active} />
            <div className={styles.circle} />
            <div className={styles.circle} />
            <div className={styles.circle} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePass;