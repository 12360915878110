import { configureStore } from "@reduxjs/toolkit";

import breadcrumbSlice from "./breadcrumbSlice";
import departmentsSlice from "./departmentsSlice";
import designationsSlice from "./designationsSlice";
import groupsSlice from "./groupsSlice";
import settingsSlice from "./settingsSlice";
import userByIdSlice from "./userByIdSlice";
import usersSlice from "./usersSlice";
import workspaceByIdSlice from "./workspaceByIdSlice";
import workspaceSlice from "./workspaceSlice";
import wosAssigneesSlice from "./wosAssigneesSlice";
import sprintSlice from "./sprintSlice";
import chatSlice from "./chatSlice";
import authSclice from "./authSlice";

const store = configureStore({
  reducer: {
    users: usersSlice,
    groups: groupsSlice,
    departments: departmentsSlice,
    designations: designationsSlice,
    breadcrumbs: breadcrumbSlice,
    auth: authSclice,
    accountById: settingsSlice,
    userById: userByIdSlice,
    workspace: workspaceSlice,
    workspaceById: workspaceByIdSlice,
    wosAssignees: wosAssigneesSlice,
    sprints: sprintSlice,
    chat: chatSlice
  }
});

export default store;