import React from "react";
function Inbox(props) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 66 66"
      width={width} height={height}
    >
      <path d="M64.99 58.33V19.26c0-.04-.02-.07-.02-.1-.01-.05-.01-.1-.03-.15-.02-.05-.05-.09-.08-.14-.02-.03-.03-.06-.05-.09-.01-.01-.02-.01-.02-.02-.04-.04-.08-.07-.12-.1-.04-.03-.07-.06-.11-.08-.04-.02-.08-.02-.12-.03-.06-.01-.11-.03-.17-.03-.01 0-.02-.01-.03-.01h-7.39c-.41 0-.75.34-.75.75s.34.75.75.75h5.37L33 45.16 3.78 20.01h16.07c.41 0 .75-.34.75-.75s-.34-.75-.75-.75H1.76c-.01 0-.02 0-.03.01-.06 0-.11.02-.16.03l-.12.03c-.04.02-.07.05-.11.07-.05.03-.09.06-.13.1l-.02.02c-.02.03-.03.06-.05.08-.03.05-.06.09-.08.15-.02.05-.02.1-.03.15-.01.04-.02.07-.02.11v39.07a.717.717 0 00.19.49c.01.01.02.03.03.04.02.02.04.02.06.04.06.05.11.1.18.13.09.04.19.06.29.06h62.48c.1 0 .2-.02.29-.06.07-.03.13-.07.18-.12.02-.02.04-.02.06-.04.01-.01.02-.03.03-.04.05-.06.1-.13.13-.2.04-.1.06-.2.06-.3zM33 46.9c.17 0 .35-.06.49-.18l30-25.82v35.62L49.03 42.06a.754.754 0 00-1.06 0c-.29.29-.29.77 0 1.06l14.46 14.46H3.57l14.41-14.41c.29-.29.29-.77 0-1.06a.754.754 0 00-1.06 0L2.51 56.52V20.9l30 25.82c.14.12.32.18.49.18z" />
      <path d="M26.66 25.73h3.2v3.15c0 .29.17.55.43.68.1.05.21.07.33.07.17 0 .33-.06.47-.16l4.67-3.73H49.7c1.49 0 2.7-1.21 2.7-2.7V9.62c0-1.49-1.21-2.7-2.7-2.7H26.66c-1.49 0-2.7 1.21-2.7 2.7v13.4a2.704 2.704 0 002.7 2.71zm-1.2-16.11c0-.66.54-1.2 1.2-1.2h23.03c.66 0 1.2.54 1.2 1.2v13.4c0 .66-.54 1.2-1.2 1.2H35.48c-.17 0-.34.06-.47.16l-3.66 2.92v-2.34c0-.41-.34-.75-.75-.75h-3.95c-.66 0-1.2-.54-1.2-1.2V9.62z" />
      <path d="M31.04 19.09c1.52 0 2.76-1.24 2.76-2.76s-1.24-2.76-2.76-2.76c-1.52 0-2.76 1.24-2.76 2.76s1.24 2.76 2.76 2.76zm0-4.03a1.261 1.261 0 010 2.52c-.69 0-1.26-.57-1.26-1.26 0-.69.56-1.26 1.26-1.26zm7.13 4.03c1.52 0 2.77-1.24 2.77-2.76s-1.24-2.76-2.77-2.76c-1.52 0-2.76 1.24-2.76 2.76s1.24 2.76 2.76 2.76zm0-4.03a1.261 1.261 0 010 2.52c-.7 0-1.26-.57-1.26-1.26 0-.69.57-1.26 1.26-1.26zm7.13 4.03c1.52 0 2.76-1.24 2.76-2.76s-1.24-2.76-2.76-2.76-2.76 1.24-2.76 2.76 1.24 2.76 2.76 2.76zm0-4.03a1.261 1.261 0 010 2.52c-.7 0-1.26-.57-1.26-1.26 0-.69.57-1.26 1.26-1.26z" />
    </svg>
  );
}


export default Inbox;