import React from "react";
function ClosedSprint(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path d="M1 6.46154V9.92308H2.28906H3.57812V14.7692V19.6154H2.93359H2.28906V20.3077V21H5.51172H8.73438V20.3077V19.6154H8.08984H7.44531V18.2308V16.8462H12H16.5547V18.2308V19.6154H15.9102H15.2656V20.3077V21H18.4883H21.7109V20.3077V19.6154H21.0664H20.4219V14.7692V9.92308H21.7109H23V6.46154V3H12H1V6.46154ZM5.64062 4.40769C5.64062 4.42154 5.21094 5.35385 4.68672 6.48462L3.72852 8.53846H3.01094H2.28906V6.46154V4.38461H3.96484C4.88867 4.38461 5.64062 4.39385 5.64062 4.40769ZM9.50781 4.40769C9.50781 4.42154 9.07812 5.35385 8.55391 6.48462L7.5957 8.53846H6.38398H5.16797L6.13477 6.46154L7.10156 4.38461H8.30469C8.96641 4.38461 9.50781 4.39385 9.50781 4.40769ZM14.75 4.40769C14.75 4.42154 14.3203 5.35385 13.7961 6.48462L12.8379 8.53846H10.9387H9.03516L10.002 6.46154L10.9688 4.38461H12.8594C13.8992 4.38461 14.75 4.39385 14.75 4.40769ZM18.6172 4.40769C18.6172 4.42154 18.1875 5.35385 17.6633 6.48462L16.7051 8.53846H15.4934H14.2773L15.2441 6.46154L16.2109 4.38461H17.4141C18.0758 4.38461 18.6172 4.39385 18.6172 4.40769ZM21.7109 6.46154V8.53846H19.9277H18.1445L19.1113 6.46154L20.0781 4.38461H20.8945H21.7109V6.46154ZM6.15625 12V14.0769H12H17.8438V12V9.92308H18.4883H19.1328V14.7692V19.6154H18.4883H17.8438V17.5385V15.4615H12H6.15625V17.5385V19.6154H5.51172H4.86719V14.7692V9.92308H5.51172H6.15625V12ZM16.5547 11.3077V12.6923H12H7.44531V11.3077V9.92308H12H16.5547V11.3077Z" fill="black" />
    </svg>
  );
}


export default ClosedSprint;