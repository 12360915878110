import React from 'react';
import _find from 'lodash/find';
import { useSelector } from "react-redux";
import { KoFormBuilder, KoSelect, KoMobile } from 'packages';
import styles from './curd.module.scss';

function BusinessProfileCrud({ form, handleFinish, settings }) {

  const email = form.getFieldValue('email');
  const users = useSelector(state => state.users);
  const designations = useSelector(state => state.designations);

  const getUserInfo = (email, isEmailChange) => {
    let user = _find(users['data'], function(user) {
      return user.email === email;
    });
    if(isEmailChange){
      form.setFieldsValue(
        {
          firstName: user?.profile?.firstName,
          lastName: user?.profile?.lastName,
          mobile: user?.mobile
        });
    }
   
    return;
  };

  const getAlterUserInfo= (email, isEmailChange) => {
    let user = _find(users['data'], function(user) {
      return user.email === email;
    });
    if(isEmailChange){
      form.setFieldsValue(
        { alternativeContact: {
          firstName: user?.profile?.firstName,
          lastName: user?.profile?.lastName,
          mobile: user?.mobile
        }}
      );
    }
    return;
  };



  const Designations = ({ value, onChange }) =>

    <KoSelect value={value}
      onChange={v => onChange(v)}
      showSearch
      placeholder='Select designation'
      options={designations?.data?.map((user) => ({
        label: user.name,
        value: user?.name
      }))}
      
    />;

  const Mobile = ({ value, onChange }) =>
    <KoMobile value={value}
      onChange={v => onChange(v)}
    />;


  const Contact = ({ value, onChange }) =>

    <KoSelect value={value}
      showSearch
      onChange={v => { onChange(v); getUserInfo(v, true); }}
      placeholder='Select contact persion'
      options={users['data']?.map((user) => ({
        label: user.email,
        value: user?.email
      }))}
    />;

  const AlterContact = ({ value, onChange }) =>

    <KoSelect value={value}
      showSearch
      onChange={v => { onChange(v); getAlterUserInfo(v, true); }}
      placeholder='Select contact persion'
      options={users['data']?.map((user) => ({
        label: user.email,
        value: user?.email
      }))}
    />;

  const meta = {
    columns: 6,
    initialValues: settings?.account,
    formItemLayout: null,
    colon: true,
    fields: [
      {
        key: 'email', label: 'Email', placeholder: 'Enter business email', colSpan: 2, rules: [
          {
            required: true,
            message: 'Email is required'
          }
        ],
        widget: Contact,
        forwardRef: true
      },
      {
        key: 'firstName', initialValue: getUserInfo(email), label: 'First Name', placeholder: 'Enter first name', colSpan: 2, rules: [
          {
            required: true,
            message: 'First name is required'
          }
        ]
      },
      {
        key: 'lastName', label: 'Last Name', placeholder: 'Enter last name', colSpan: 2, rules: [
          {
            required: true,
            message: 'last name is required'
          }
        ]
      }, {
        key: 'mobile', label: 'Phone', placeholder: 'Enter phone no', colSpan: 2, rules: [
          {
            required: true,
            message: 'phone no is required'
          }
        ],
        widget: Mobile,
        forwardRef: true
      }, {
        key: 'designation', label: 'Designation', colSpan: 2,
        widget: Designations,
        forwardRef: true
      },
      {
        key: 'label1',
        colSpan: 4,
        render() {
          return (
            <fieldset>
              <legend>Alternative Contact Person</legend>
            </fieldset>
          );
        }
      },
      {
        key: 'alternativeContact.email', label: 'Email', placeholder: 'Enter business email', colSpan: 2,
        widget: AlterContact,
        forwardRef: true
      },
      {
        key: 'alternativeContact.firstName', label: 'First Name', placeholder: 'Enter first name', colSpan: 2
      },
      {
        key: 'alternativeContact.lastName', label: 'Last Name', placeholder: 'Enter last name', colSpan: 2
      },
      {
        key: 'alternativeContact.mobile', label: 'Phone', placeholder: 'Enter phone no', colSpan: 2,
        widget: Mobile,
        forwardRef: true
      }, {
        key: 'alternativeContact.designation', label: 'Designation', colSpan: 2,
        widget: Designations,
        forwardRef: true
      }

    ]
  };

  return (
    <div className={styles.wrapper}>
      <KoFormBuilder form={form} meta={meta} />
    </div>
  );
};

export default BusinessProfileCrud;