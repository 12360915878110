import React from "react";
function bullet(props) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
    >
      <path
        d="M23.5 4.5h-17a.5.5 0 010-1h17a.5.5 0 010 1zm0 8h-17a.5.5 0 010-1h17a.5.5 0 010 1zm0 8h-17a.5.5 0 010-1h17a.5.5 0 010 1zm-21-6C1.121 14.5 0 13.378 0 12s1.121-2.5 2.5-2.5S5 10.622 5 12s-1.121 2.5-2.5 2.5zm0-4c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5S4 12.827 4 12s-.673-1.5-1.5-1.5zm0 12C1.121 22.5 0 21.378 0 20s1.121-2.5 2.5-2.5S5 18.622 5 20s-1.121 2.5-2.5 2.5zm0-4c-.827 0-1.5.673-1.5 1.5s.673 1.5 1.5 1.5S4 20.827 4 20s-.673-1.5-1.5-1.5zm0-12C1.121 6.5 0 5.378 0 4s1.121-2.5 2.5-2.5S5 2.622 5 4 3.879 6.5 2.5 6.5zm0-4C1.673 2.5 1 3.173 1 4s.673 1.5 1.5 1.5S4 4.827 4 4s-.673-1.5-1.5-1.5z"
        className="color000 svgShape"
      />
    </svg>
  );
}


export default bullet;


