import React from "react";
function Numbers(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 100 100" {...props}>
      <path d="M79.54 12.82H20.46c-4.21 0-7.64 3.43-7.64 7.64v59.07c0 4.21 3.43 7.64 7.64 7.64h59.07c4.21 0 7.64-3.43 7.64-7.64V20.46c.01-4.21-3.42-7.64-7.63-7.64zm4.64 66.72c0 2.56-2.08 4.64-4.64 4.64H20.46c-2.56 0-4.64-2.08-4.64-4.64V20.46c0-2.56 2.08-4.64 4.64-4.64h59.07c2.56 0 4.64 2.08 4.64 4.64v59.08z" />
      <path d="M51.46 30.84a1.5 1.5 0 00-1.65.37l-10.74 11.5c-.57.6-.53 1.55.07 2.12.6.57 1.55.53 2.12-.07l8.15-8.72v31.73c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5V32.24c0-.62-.37-1.17-.95-1.4zM27.04 18.46h-7.28c-.83 0-1.5.67-1.5 1.5v7.18c0 .83.67 1.5 1.5 1.5s1.5-.67 1.5-1.5v-5.68h5.78c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5z" />
    </svg>
  );
}
export default Numbers;