
const RESULT={
  "workLog": [
    {
      "userId": "usr3a8157105d",
      "profile": {
        "logo": [
          {
            "name": "pic.jpg",
            "uid": "rc-upload-1735904542151-23",
            "url": "https://storage.googleapis.com/kwapio_profile/acc219cf99ae4/organization/user/usr3a8157105d/pic.jpg",
            "size": 23637,
            "type": "image/jpeg",
            "lastModifiedDate": "2025-01-03T12:03:50.541Z"
          }
        ],
        "firstName": "Kamal",
        "lastName": "K",
        "email": "kamal@qubernet.com"
      },
      "approval": [
        {
          "workspace": "wos614b813b5c",
          "name": "Kwapio UAT",
          "customer": "KAHEDI",
          "totalBillableWorkHours": "02h 00m",
          "totalNonBillableWorkHours": "00h 00m",
          "approver": "usrc4cdfd4b0f",
          "profile": {
            "logo": null,
            "firstName": "Gokul",
            "lastName": "R",
            "email": "Gokul_R@pitchworks.club"
          },
          "approvelRequestedDate": "2025-01-05T13:23:02.243Z",
          "approvedDate": "0001-01-01T00:00:00Z",
          "comments": "",
          "status": 1,
          "workLogs": [
            {
              "ticketId": "KU-2",
              "summary": "worklog",
              "issueType": 2,
              "ticketStatus": 1,
              "isBillable": true,
              "status": 1,
              "totalBillableWorkHours": "01h 30m",
              "totalNonBillableWorkHours": "00h 00m",
              "ticketClosedDate": "2025-01-03T13:48:00.119Z",
              "logs": [
                {
                  "ticketId": "KU-2",
                  "workspace": "wos614b813b5c",
                  "date": "2025-01-04T00:00:00Z",
                  "workLogId": "wkl93d2a0e614",
                  "timeSpent": "10m",
                  "workTime": "00:10",
                  "timeRemaining": "15m",
                  "description": [
                    {
                      "children": [
                        {
                          "bold": true,
                          "text": "WorklogCheck"
                        }
                      ],
                      "type": "paragraph"
                    }
                  ],
                  "totalHours": "0h 10m",
                  "isBillable": true,
                  "status": 1,
                  "ticketClosedDate": "2025-01-03T13:48:00.119Z",
                  "createdAt": "2025-01-05T05:34:37.343Z",
                  "createdBy": "usr3a8157105d",
                  "updatedBy": "",
                  "updatedAt": "0001-01-01T00:00:00Z"
                },
                {
                  "ticketId": "KU-2",
                  "workspace": "wos614b813b5c",
                  "date": "2025-01-05T00:00:00Z",
                  "workLogId": "wklee6b06e73b",
                  "timeSpent": "90m",
                  "workTime": "01:30",
                  "timeRemaining": "60m",
                  "description": [
                    {
                      "children": [
                        {
                          "bold": true,
                          "text": "**********"
                        }
                      ],
                      "type": "paragraph"
                    }
                  ],
                  "totalHours": "1h 30m",
                  "isBillable": true,
                  "status": 1,
                  "ticketClosedDate": "2025-01-03T13:48:00.119Z",
                  "createdAt": "2025-01-05T05:35:43.421Z",
                  "createdBy": "usr3a8157105d",
                  "updatedBy": "",
                  "updatedAt": "0001-01-01T00:00:00Z"
                }
              ]
            },
            {
              "ticketId": "KU-1",
              "summary": "Activity Test",
              "issueType": 5,
              "ticketStatus": 1,
              "isBillable": true,
              "status": 1,
              "totalBillableWorkHours": "00h 20m",
              "totalNonBillableWorkHours": "00h 00m",
              "ticketClosedDate": "2025-01-03T13:50:09.296Z",
              "logs": [
                {
                  "ticketId": "KU-1",
                  "workspace": "wos614b813b5c",
                  "date": "2025-01-04T18:30:00Z",
                  "workLogId": "wklc00ade6e99",
                  "timeSpent": "20m",
                  "workTime": "00:20",
                  "timeRemaining": "60m",
                  "description": [
                    {
                      "children": [
                        {
                          "text": "nfiuhfiffee"
                        }
                      ],
                      "type": "paragraph"
                    }
                  ],
                  "totalHours": "0h 20m",
                  "isBillable": true,
                  "status": 1,
                  "ticketClosedDate": "2025-01-03T13:50:09.296Z",
                  "createdAt": "2025-01-05T05:39:13.052Z",
                  "createdBy": "usr3a8157105d",
                  "updatedBy": "",
                  "updatedAt": "0001-01-01T00:00:00Z"
                }
              ]
            }
          ]
        },
        {
          "workspace": "wos4cbbeaa773",
          "name": "Timesheet",
          "customer": "KAHEDI",
          "totalBillableWorkHours": "00h 00m",
          "totalNonBillableWorkHours": "00h 02m",
          "approver": "usrc4cdfd4b0f",
          "profile": {
            "logo": null,
            "firstName": "Gokul",
            "lastName": "R",
            "email": "Gokul_R@pitchworks.club"
          },
          "approvelRequestedDate": "2025-01-05T13:23:02.243Z",
          "approvedDate": "0001-01-01T00:00:00Z",
          "comments": "",
          "status": 1,
          "workLogs": [
            {
              "ticketId": "TIM-1",
              "summary": "time shett check",
              "issueType": 2,
              "ticketStatus": 1,
              "isBillable": false,
              "status": 1,
              "totalBillableWorkHours": "00h 00m",
              "totalNonBillableWorkHours": "00h 02m",
              "ticketClosedDate": null,
              "logs": [
                {
                  "ticketId": "TIM-1",
                  "workspace": "wos4cbbeaa773",
                  "date": "2025-01-05T00:00:00Z",
                  "workLogId": "wrl65dd4e0421",
                  "timeSpent": "2m",
                  "workTime": "00:02",
                  "timeRemaining": "2m",
                  "description": [
                    {
                      "children": [
                        {
                          "text": "aasdasd"
                        }
                      ],
                      "type": "paragraph"
                    }
                  ],
                  "totalHours": "0h 2m",
                  "isBillable": false,
                  "status": 1,
                  "ticketClosedDate": null,
                  "createdAt": "2025-01-05T13:06:30.068Z",
                  "createdBy": "usr3a8157105d",
                  "updatedBy": "",
                  "updatedAt": "0001-01-01T00:00:00Z"
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};

const WORKLOG_COLUMNS = [
  {
    name: "createdBy",
    id: "createdBy",
    label: "Assignee",
    widget: "assignees",
    width:100
  },
  {
    name: "description",
    id: "description",
    label: "Description",
    width:200,
    widget: "richText"
  },
  {
    name: "timeSpent",
    id: "timeSpent",
    label: "TimeSpent",
    widget: "log",
    width:100
  },
  {
    name: "timeRemaining",
    id: "timeRemaining",
    label: "Time Remaining",
    widget: "log",
    width:150
  },
  {
    name: "date",
    id: "date",
    label: "Date",
    format: "DD-MMM-YYYY",
    widget: "date",
    width:150
  }];


const KoTicketTable_COLUMNS = [
  {
    name: "ticketId",
    id: "ticketId",
    label: "Ticket Id",
    width:130,
    widget: "ticketLink",
    fixed: true
  },
  {
    name: "summary",
    id: "summary",
    label: "Summary",
    width:230,
    widget: "text"
  },
  {
    name: "totalBillableWorkHours",
    id: "totalBillableWorkHours",
    label: "Total Billable WorkHours",
    widget: "text"
  },
  {
    name: "totalNonBillableWorkHours",
    id: "totalNonBillableWorkHours",
    label: "Total Non-Billable WorkHours",
    widget: "text",
    width:250
  },
  {
    name: "status",
    id: "status",
    label: "Approval Status",
    widget: "workflowstatus",
    width:200
  }
];
  
const KoTicketTable_COLUMNSNONTicket = [
  {
    name: "date",
    id: "date",
    label: "Log Date",
    widget: "date",
    width:130
  },
  {
    name: "summary",
    id: "summary",
    label: "Summary",
    width:230,
    widget: "text"
  },
  {
    name: "totalHours",
    id: "totalHours",
    label: "Total Billable WorkHours",
    widget: "billable"
  },
  {
    name: "totalHours",
    id: "totalHours",
    label: "Total Non-Billable WorkHours",
    widget: "nonbillable",
    width:250
  },
  {
    name: "status",
    id: "status",
    label: "Approval Status",
    widget: "workflowstatus",
    width:200
  }
];
  
export {
  RESULT,
  WORKLOG_COLUMNS,
  KoTicketTable_COLUMNS,
  KoTicketTable_COLUMNSNONTicket
};