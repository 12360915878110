import React from "react";
function Paragraph(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" {...props}>
      <g data-name="&lt;Group&gt;">
        <path
          fill="none"
          stroke="#303c42"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M.5 9.5h23m-23 5h23m-23 5h15m-15-15h23"
          data-name="&lt;Path&gt;"
        />
      </g>
    </svg>
  );
}
export default Paragraph;