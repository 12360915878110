import _find from 'lodash/find';
import KoIcon from "../icon";
import { TASKSTATUSLIST, TASKSTATUSLISTCOB } from '../datatable/widgets/common';
import styles from './status.module.scss';

function TaskStatus(props) {
  let { data, cob } = props;
  let issueType = _find( cob ? TASKSTATUSLISTCOB :TASKSTATUSLIST, function(o) { return o.value === data; }) || {};

  
  return (
    <div className={styles.wrapper}>
      <div className={`${styles.status}`} style={{background: issueType?.background, color: issueType?.color}}>
        <div className={styles.text}>{issueType?.label}</div>
      </div>
      {
        data === 2 &&
            <div className={styles.text}>
              <KoIcon width={14} height={14} name={'TimeSheetFilled'}/>
            </div>
      }
    </div>
  );
}
export default TaskStatus;
