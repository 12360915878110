import React, {useState, useEffect} from 'react';
import styles from './template.module.scss';
import { KoAffix, KoButton, KoIcon, KoLoader, KoForm, KoModal, KoTitle, KoNano, KoDropdown, KoText, KoToast } from 'packages';
import KoToolBar from 'shared/components/toolbar';
import TemplateCreation from './curd';
import { InvoicesAPI } from '../invoice.service';
import PreviewTemplate from './previewTemplate';

export default function InvoiceTemplate() {
  const [currentRecord, setCurrentRecord] = useState({
    name:'',
    columns:6,
    description:'',
    fields:[]
  });
  const [isTemplateOpen, setTemplateModal] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [form] = KoForm.useForm();
  const [isOpenDelete, setDeleteModal] = useState(false);
  const [templatePreviewModal, setTemplatePreviewModal] = useState(false);
  

  useEffect(()=>{
    getTemplates();
  },[]);

  const onFinish=(val)=>{
    setLoading(true);
    if(val['templateId']){
      val['templateId'] = KoNano('TEP');
    }
    val['templateId'] = KoNano('TEP');
    InvoicesAPI.createOrUpdateTemplate(val, currentRecord?.templateId).then(
      (data) => {
        setLoading(false);
        setTemplateModal();
        getTemplates();
      },
      (err) => {
      }
    );
  };

  const getTemplates = () => {
    setLoading(true);
    InvoicesAPI.getTemplates().then(
      (data) => {
        setTemplates(data['templates']);
        setLoading(false);
      },
      (err) => {
      }
    );
  };

  const handleFinishDelete=()=>{
    setLoading(true);
    InvoicesAPI.deleteById(currentRecord?.templateId).then(
      (data) => {
        setLoading(false);
        setDeleteModal(false);
        getTemplates();
        KoToast.success(`Template deleted successfully! ${currentRecord.name}!`);
      },
      (err) => {
        setLoading(false);
        KoToast.error(`error`);
      }
    );
  };

  const onClick = ({key}, items) => {
    setCurrentRecord(items);
    if(key === "1"){
      setTemplateModal(true);
    } else if(key === "5"){
      setDeleteModal(true);

    }else if(key === "2"){
      setTemplatePreviewModal(true);
    }else if(key === "4"){
      setLoading(true);
      items['name'] = items['name']+'_copy';
      items['templateId'] = KoNano('TEP');
      InvoicesAPI.createOrUpdateTemplate(items).then(
        (data) => {
          setLoading(false);
          setTemplateModal();
          getTemplates();
        },
        (err) => {
        }
      );
    }else{
    }
  };

  const actionitems = [
    {
      label: "Edit",
      key: "1",
      icon: <KoIcon name="EditOutlined" />
    },
    {
      label: "Preview",
      key: "2",
      icon: <KoIcon name="OpenOutlined"  />
    },
    {
      label: "Set Default",
      key: "3",
      icon: <KoIcon name="SharedOutlined"  />
    },
    {
      label: "Copy",
      key: "4",
      icon: <KoIcon name="SharedOutlined"  />
    },
    {
      type: 'divider'
    },
    {
      label: "Delete",
      key: "5",
      danger: true,
      icon: <KoIcon name="DeleteOutlined" />
    }
  ];

  const actionitemsRole = [
    {
      label: "Edit",
      key: "1",
      icon: <KoIcon name="EditOutlined" />
    },
    {
      label: "Preview",
      key: "2",
      icon: <KoIcon name="OpenOutlined"  />
    },
    {
      label: "Set Default",
      key: "3",
      icon: <KoIcon name="SharedOutlined"  />
    },
    {
      label: "Copy",
      key: "4",
      icon: <KoIcon name="SharedOutlined"  />
    }
  ];


  return (
    <div className={styles.wrapper}>
      {isLoading && <KoLoader/>}
      <KoAffix top={56}>
        <KoToolBar
          className={styles.toolbar}
          title={"Templates"}
          search={{
            visible: true,
            onChange: () => { },
            placeholder: "Search by name"
          }}
          buttonList={[
            {
              label: "New Template",
              type: "primary",
              icon: (
                <KoIcon
                  color="#FFF"
                  name="PlusOutlined"
                  width={18}
                  height={18}
                />
              ),
              onClick: () => setTemplateModal(true)
            }
          ]}
        />
      </KoAffix>
      <div className={styles.templeteWrapper}>
        {templates?.map((items)=>
          <div key={items} className={styles.card} >
            <div className={styles.content}>
              <div className={styles.image}>
                <KoIcon name='InvoiceTempleteTwoTone' width={140} height={150}/>
              </div>
              <div className={styles.actionsCard}>
                <KoDropdown
                  overlayClassName={'dropdownOverlayClassName'}
                  placement={ "bottomRight"}
                  menu={{
                    items: items?.name === "Invoice Default" ? actionitemsRole : actionitems,
                    onClick:(e)=>onClick(e, items)
                  }}
                >
                  <div onClick={(e) => {
                    e.preventDefault();
                  }}>
                    <KoIcon name="VerticalMenuOutlined" />
                  </div>
                </KoDropdown>
              </div>
            </div>
            <div className={styles.bottom}>
              <KoTitle ellipsis={true} text={items.name} level={5}/>
            </div>
          </div>
        )}
      </div>
      {/* <KoFlex wrap="wrap" gap="small" className={styles.emptySection}>
        <KoEmpty onClick={()=>('1')} label="Create Template" icon={'EmptyTwoTone'} title="Create Template"/>
      </KoFlex> */}

      <KoModal
        title={currentRecord?.invoiceId ? `Update Template ${currentRecord?.name?.toUpperCase()}` : 'New Template'}
        centered
        open={isTemplateOpen}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setTemplateModal(false); }}
        onClose={() => { setCurrentRecord(); setTemplateModal(false); }}
        width={'100%'}
        // maskClosable={false}
        height={'95.5%'}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setTemplateModal(!isTemplateOpen)} />,
          <KoButton key='preview' loading={isLoading} type='primary' label={`Preview`} onClick={() => form.submit()} />,
          <KoButton key='create' loading={isLoading} type='primary' label={`Save`} onClick={() => form.submit()} />
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <TemplateCreation currentRecord={currentRecord} saveForm={form} saveFormData={onFinish}/>
      </KoModal>
      <KoModal
        title={`Delete workspace ${currentRecord?.name?.toUpperCase()}`}
        centered
        open={isOpenDelete}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setDeleteModal(false); }}
        onClose={() => { setCurrentRecord(); setDeleteModal(false); }}
        width={580}
        // maskClosable={false}
        height={450}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setDeleteModal(!isOpenDelete)} />,
          <KoButton key='reset' loading={isLoading} type='primary' label='Delete' onClick={() => handleFinishDelete()} />
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <div className={styles.suspend}>
          <KoTitle level={5} text={`Are you sure you want to delete this Template?`} />
          <KoText text={`This action is irreversible, and it will result in the removal of their associated data and Kwapio license.`} />
        </div>
      </KoModal>

      <KoModal
        title={currentRecord?.name?.toUpperCase()}
        centered
        open={templatePreviewModal}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setTemplatePreviewModal(false); }}
        onClose={() => { setCurrentRecord(); setTemplatePreviewModal(false); }}
        width={'100%'}
        // maskClosable={false}
        height={'95.5%'}
        footer={[
          <KoButton key='cancel' label='Close' onClick={() => setTemplatePreviewModal(!templatePreviewModal)} />
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <PreviewTemplate currentRecord={currentRecord}/>
      </KoModal>

    </div>
  );
}
