import React from "react";
function Query(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" width="24" height="24" rx="4" fill="var(--query)" />
      <path d="M19.5 12C19.5 13.3845 19.0895 14.7378 18.3203 15.889C17.5511 17.0401 16.4579 17.9373 15.1788 18.4672C13.8997 18.997 12.4922 19.1356 11.1344 18.8655C9.7765 18.5954 8.52922 17.9287 7.55026 16.9497C6.57129 15.9708 5.9046 14.7235 5.63451 13.3656C5.36441 12.0078 5.50303 10.6003 6.03285 9.32122C6.56266 8.04213 7.45987 6.94888 8.61101 6.17971C9.76215 5.41054 11.1155 5 12.5 5C14.3559 5.00201 16.1352 5.74015 17.4475 7.05247C18.7599 8.36479 19.498 10.1441 19.5 12ZM13.0833 13.3166C13.076 13.1136 13.1223 12.9123 13.2175 12.7328C13.3128 12.5534 13.4536 12.4023 13.6258 12.2946C13.9863 12.096 14.2878 11.8056 14.4999 11.4529C14.7119 11.1002 14.8268 10.6977 14.8331 10.2862C14.8393 9.8747 14.7366 9.46888 14.5354 9.1099C14.3341 8.75091 14.0415 8.45153 13.6873 8.24213C13.333 8.03273 12.9296 7.92075 12.5181 7.91756C12.1066 7.91437 11.7015 8.02008 11.344 8.22396C10.9866 8.42784 10.6893 8.72264 10.4826 9.07846C10.2758 9.43428 10.1668 9.83846 10.1667 10.25H11.3333C11.3332 10.0784 11.3709 9.90882 11.4438 9.75344C11.5167 9.59806 11.623 9.46068 11.7551 9.3511C11.8872 9.24152 12.0418 9.16242 12.208 9.11947C12.3742 9.07651 12.5478 9.07074 12.7164 9.10258C12.9469 9.14732 13.1587 9.25972 13.325 9.42545C13.4912 9.59118 13.6043 9.8027 13.6498 10.033C13.6957 10.2747 13.664 10.5248 13.5592 10.7474C13.4544 10.9701 13.2819 11.1539 13.0664 11.2726C12.7095 11.4794 12.4146 11.778 12.2124 12.1375C12.0102 12.4971 11.908 12.9042 11.9167 13.3166V13.75H13.0833V13.3166ZM13.0833 14.9167H11.9167V16.0833H13.0833V14.9167Z" fill="white" />
    </svg>
  );
}


export default Query;