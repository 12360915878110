import React, { useEffect, useState } from 'react';
import styles from './dashboard.module.scss';
import { KoTable, KoSegmented, KoTitle, KoRow, KoCol, KoCard, KoIcon, KoText, KoTooltips, KoButton, KoLoader } from 'packages';
import { COLUMN } from './constant';
import { useDispatch } from 'react-redux';
import { add, remove } from 'shared/store/breadcrumbSlice';
import { CalendarAPI } from '../calendar.service';
import dayjs from 'dayjs';

export default function CalendarDashboard() {
  const dispatch = useDispatch();
  const [tabVal, setTabVal] = useState('all');
  const [loading, setLoading] = useState(false);
  const [eventCountByStatus, setEventCountByStatus] = useState();
  const [todaySlots, setTodaySlots] = useState();
  const currentTime = dayjs();

  useEffect(()=>{
    dispatch(remove());
    dispatch(add([]));

    const selectedDate = new Date();
    const day = selectedDate.getDate().toString().padStart(2, '0');
    const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
    const year = selectedDate.getFullYear().toString().slice(0);
    const query = `${year}-${month}-${day}`;

    getEventCountByStatus();
    getTodaySlots({startDate: query});
  },[]);

  const TAB_LIST = [
    {
      label: 'Upcoming',
      value: 'upcoming'
    },
    // {
    //   label: 'Pending',
    //   value: 'pending'
    // },
    {
      label: 'Completed',
      value: 'completed'
    }
    // {
    //   label: 'Canceled',
    //   value: 'canceled'
    // },
    // {
    //   label: 'Draft',
    //   value: 'draft'
    // }
  ];
  const totalMeetingValue = eventCountByStatus?.accepted + eventCountByStatus?.canceled + eventCountByStatus?.changeRequest + eventCountByStatus?.completed + eventCountByStatus?.declined + eventCountByStatus?.inprogress + eventCountByStatus?.tentative + eventCountByStatus?.yetToStart;

  const counts = [{
    name: 'Total meetings',
    count:  totalMeetingValue || '-',
    icon: 'CalendarOutlined',
    color: `var(--palette-secondary-main)`
  }, {
    name: 'Accepted meetings',
    count: eventCountByStatus?.accepted || '-',
    icon: 'CalendarOutlined',
    color: ` var(--palette-success-main)`
  }, {
    name: 'Canceled meetings',
    icon: 'CalendarOutlined',
    count: eventCountByStatus?.canceled || '-',
    color: `var(--palette-warning-main)`
  }, {
    name: 'Requested meetings',
    icon: 'CalendarOutlined',
    count: eventCountByStatus?.changeRequest || '-',
    color: `var(--palette-error-main)`
  }, {
    name: 'Declined meetings',
    icon: 'CalendarOutlined',
    count: eventCountByStatus?.declined || '-',
    color: `var(--palette-error-main)`
  }, {
    name: 'Requested meetings',
    icon: 'CalendarOutlined',
    count: eventCountByStatus?.changeRequest || '-',
    color: `var(--palette-info-main)`
  }, {
    name: 'Inprogress meetings',
    icon: 'CalendarOutlined',
    count: eventCountByStatus?.inprogress || '-',
    color: `var(--palette-info-main)`
  }, {
    name: 'Completed meetings',
    icon: 'CalendarOutlined',
    count: eventCountByStatus?.completed || '-',
    color: `var(--palette-info-main)`
  }

  ];

  
  const getEventCountByStatus=()=>{
    setLoading(true);
    setEventCountByStatus();
    CalendarAPI.getEventCountByStatus().then(
      (data) => {
        setEventCountByStatus(data['totalCount']);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const getTodaySlots=(query)=>{
    setLoading(true);
    setTodaySlots();
    CalendarAPI.getTodaySlots(query).then(
      (data) => {
        setTodaySlots(data);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  return (
    <div className={styles.wrapper}>
      {loading && <KoLoader />}
      <div className={styles.header}>
        <KoButton color="default" variant="filled" iconBtn={true} icon={<KoIcon name='ArrowLeftOutlined' />} onClick={() => window.history.back()}/>
        <KoTitle text='Calendar Summary' level={5} />
      </div>
      <KoRow gutter={16}>
        <KoCol span={13}>
          <div className={styles.cardWrapper}>
            {counts?.map((opt, i) =>
              <KoCard key={i} className={styles.assigned}>
                <div className={styles.assignedWrap}>
                  <div className={`${styles.cardIcon} ${styles['c' + i]}`}>
                    <KoIcon name={opt.icon} width={18} height={18} color={`${opt.color}`} />
                  </div>
                  <div className={styles.content}>
                    <KoText className={styles.count} text={opt.count} />
                    <KoText className={styles.name} text={opt.name} />
                  </div>
                </div>
              </KoCard>
            )}
          </div>
        </KoCol>
        <KoCol span={11}>
          <div className={styles.meetingBreakdown}>
            <KoCard className={styles.card}>
              <div className={styles.title}>
                <KoTitle text='Today Slots' level={5} />
                <KoTitle text={todaySlots?.totalSlots} level={5} />
              </div>
              <div className={styles.slotsList}>
                {todaySlots?.slots?.map((slot) => {
                  const startTime = slot.match(/start:(\d{2}:\d{2})/)?.[1];
                  const endTime = slot.match(/end:(\d{2}:\d{2})/)?.[1];

                  if (!startTime || !endTime) return null;
                  const isOngoing = currentTime.isAfter(dayjs(startTime, "HH:mm")) && currentTime.isBefore(dayjs(endTime, "HH:mm"));
                  const formattedStartTime = dayjs(startTime, "HH:mm").format("hh:mm A");

                  return {
                    startTime, endTime, formattedStartTime,
                    icon: isOngoing
                      ? "LogOutlined"
                      : currentTime.isBefore(dayjs(startTime, "HH:mm"))
                        ? "TickFilled"
                        : "CrossFilled" };
                }).filter(Boolean).sort((a, b) => {
                  const timeA = dayjs(a.startTime, "HH:mm");
                  const timeB = dayjs(b.startTime, "HH:mm");
                  return timeA.isAfter(timeB) ? 1 : -1;
                }).map((slot, i) => (
                  <KoTooltips key={i} title={`Meeting from ${slot.startTime} to ${slot.endTime}`}>
                    <div className={styles.slot}>
                      <KoButton
                        icon={<KoIcon name={slot.icon} />}
                        label={slot.formattedStartTime}
                      />
                    </div>
                  </KoTooltips>
                ))}

              </div>
            </KoCard>
          </div>
        </KoCol>
      </KoRow>

      <div className={styles.calendarTable}>
        <div className={styles.segMentList}>
          <KoSegmented
            options={TAB_LIST}
            className={styles.segment}
            value={tabVal}
            onChange={setTabVal}
          />
          <div className={`${styles.table} tableCard`}>
            <div className={styles.headerTable}>
              <KoTitle text={`${tabVal} meetings`} level={5} />
            </div>
            <KoTable rowKey='accountPayable' data={{ columns: COLUMN, rows: [] }} maxHeight={35} />
          </div>
        </div>
      </div>
    </div>
  );
}
