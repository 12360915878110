import React from "react";
function BackLog(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path d="M3.34989 1.06164C3.02146 1.15185 2.77513 1.3065 2.5249 1.58142C2.27076 1.86065 2.11436 2.16564 2.04399 2.53937C1.98534 2.84867 1.98534 5.60224 2.04399 5.90724C2.18865 6.64611 2.72821 7.23892 3.40071 7.39786C3.69004 7.46659 13.2067 7.46659 13.5038 7.39786C14.1685 7.24321 14.7198 6.63322 14.8606 5.90724C14.9192 5.59794 14.9192 2.84437 14.8606 2.53937C14.7159 1.80051 14.1724 1.2034 13.5038 1.04875C13.191 0.975724 3.61576 0.988611 3.34989 1.06164ZM12.2449 3.66486C12.4208 3.76366 12.5576 4.00852 12.5576 4.22331C12.5576 4.43809 12.4208 4.68295 12.2449 4.78175C12.0963 4.86767 12.022 4.86767 8.44836 4.86767H4.80827L4.65187 4.77316C4.25698 4.5283 4.25307 3.93979 4.64405 3.68204L4.79654 3.57894H8.44446C12.022 3.57894 12.0963 3.57894 12.2449 3.66486Z" fill="black" />
      <path d="M17.9728 1.92938C17.8008 2.02818 16.2407 3.74219 16.1508 3.9312C16.1117 4.02141 16.0765 4.15028 16.0765 4.22331C16.0765 4.29634 16.1117 4.42521 16.1508 4.51542C16.2447 4.71302 17.8086 6.42273 17.9806 6.51724C18.4576 6.77928 18.9894 6.19506 18.7509 5.67097C18.7157 5.59365 18.5554 5.38316 18.3912 5.19844L18.0901 4.86767H19.052C20.1624 4.86767 20.2875 4.89344 20.5533 5.18985C20.87 5.53351 20.8466 5.01802 20.8466 11.9986C20.8466 18.9792 20.87 18.4637 20.5533 18.8074C20.264 19.1252 20.2445 19.1295 18.2778 19.1295C16.6161 19.1295 16.5379 19.1338 16.3893 19.2155C16.2134 19.3143 16.0765 19.5591 16.0765 19.7739C16.0765 19.9887 16.2134 20.2335 16.3932 20.3323C16.5457 20.4226 16.5926 20.4226 18.4342 20.4097C20.2718 20.3925 20.3266 20.3925 20.5494 20.298C21.2102 20.0273 21.6872 19.499 21.9101 18.7945L22 18.5067V5.49055L21.9101 5.20274C21.6872 4.49824 21.2063 3.96556 20.5494 3.69923C20.3383 3.61331 20.2327 3.60472 19.2083 3.58754L18.0979 3.57036L18.3951 3.24388C18.5554 3.06346 18.7157 2.85297 18.7509 2.77564C18.9894 2.25156 18.4459 1.65875 17.9728 1.92938Z" fill="black" />
      <path d="M4.91384 8.83694C4.58541 8.92715 4.33908 9.08179 4.08885 9.35672C3.83471 9.63594 3.67831 9.94094 3.60794 10.3147C3.54929 10.624 3.54929 13.3775 3.60794 13.6825C3.7526 14.4214 4.29217 15.0142 4.96466 15.1732C5.254 15.2419 14.7706 15.2419 15.0678 15.1732C15.7325 15.0185 16.2838 14.4085 16.4245 13.6825C16.4832 13.3732 16.4832 10.6197 16.4245 10.3147C16.2798 9.5758 15.7364 8.97869 15.0678 8.82405C14.755 8.75102 5.17971 8.76391 4.91384 8.83694ZM13.8088 11.4402C13.9848 11.539 14.1216 11.7838 14.1216 11.9986C14.1216 12.2134 13.9848 12.4582 13.8088 12.557C13.6602 12.643 13.5859 12.643 10.0123 12.643H6.37222L6.21583 12.5485C5.82093 12.3036 5.81702 11.7151 6.208 11.4573L6.36049 11.3542H10.0084C13.5859 11.3542 13.6602 11.3542 13.8088 11.4402Z" fill="black" />
      <path d="M3.34989 16.6122C3.02146 16.7024 2.77513 16.8571 2.5249 17.132C2.27076 17.4112 2.11436 17.7162 2.04399 18.09C1.98534 18.3993 1.98534 21.1528 2.04399 21.4578C2.18865 22.1967 2.72821 22.7895 3.40071 22.9485C3.69004 23.0172 13.2067 23.0172 13.5038 22.9485C14.1685 22.7938 14.7198 22.1838 14.8606 21.4578C14.9192 21.1485 14.9192 18.395 14.8606 18.09C14.7159 17.3511 14.1724 16.754 13.5038 16.5993C13.191 16.5263 3.61576 16.5392 3.34989 16.6122ZM12.2449 19.2155C12.4208 19.3143 12.5576 19.5591 12.5576 19.7739C12.5576 19.9887 12.4208 20.2335 12.2449 20.3323C12.0963 20.4183 12.022 20.4183 8.44836 20.4183H4.80827L4.65187 20.3238C4.25698 20.0789 4.25307 19.4904 4.64405 19.2326L4.79654 19.1295H8.44446C12.022 19.1295 12.0963 19.1295 12.2449 19.2155Z" fill="black" />
    </svg>

  );
}


export default BackLog;