import React from "react";

function Improvement(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" width="24" height="24" rx="4" fill="var(--improvement)" />
      <path d="M16.5833 5H8.41667C6.80842 5 5.5 6.30842 5.5 7.91667V16.0833C5.5 17.6916 6.80842 19 8.41667 19H16.5833C18.1916 19 19.5 17.6916 19.5 16.0833V7.91667C19.5 6.30842 18.1916 5 16.5833 5ZM15.0439 12H13.0833V14.9167C13.0833 15.2387 12.8226 15.5 12.5 15.5C12.1774 15.5 11.9167 15.2387 11.9167 14.9167V12H9.95608C9.55067 12 9.34708 11.503 9.63408 11.2125L12.178 8.63533C12.3559 8.45508 12.6441 8.45508 12.822 8.63533L15.3659 11.2125C15.6529 11.503 15.4493 12 15.0439 12Z" fill="white" />
    </svg>

  );
}

export default Improvement;