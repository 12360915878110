import _find from 'lodash/find';
import { KoAvatar, KoDate, KoForm, KoFormBuilder, KoIcon, KoInput, KoSelect, KoText, KoUserSelect } from 'packages';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TaskPlannerAPI } from "../taskPlanner.service";
import styles from './crud.module.scss';
import { TASKSTATUSLIST } from 'shared/commonConstants';
import { useParams } from 'react-router';
const PullRequestCrud = ({ form, handleFinish, initialValues }) => {
  const wosAssignees = useSelector(state => state.wosAssignees);
  const [tickets, setTickets] = useState();
  const [environments, setEnvironments] = useState();
  const [services, setServices] = useState();
  const {workspaceId} = useParams();

  useEffect(()=>{
    getTickets();
    getEnvironments();
    getServices();
  },[]);

  useEffect(() => {
    if (!initialValues || Object.keys(initialValues).length === 0) {
      form.resetFields();
    } else {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  const handleSubmit = useCallback(values => {
    handleFinish(values);
  });


  const getEnvironments=()=>{
    setEnvironments();
    TaskPlannerAPI.getEnvironments(null, workspaceId).then(
      (data) => {
        setEnvironments(data?.environments);
      },
      (err) => {
      }
    );
  };

  const getServices=()=>{
    setEnvironments();
    TaskPlannerAPI.getServices( workspaceId).then(
      (data) => {
        setServices(data?.services);
      },
      (err) => {
      }
    );
  };
  const getTickets=()=>{
    setTickets();
    TaskPlannerAPI.getIssues(null, workspaceId).then(
      (data) => {
        setTickets(data?.tickets);
      },
      (err) => {
      }
    );
  };


  const Approval = ({ value, onChange }) =>
    <KoUserSelect value={value} onChange={v => onChange(v)} users={wosAssignees[0]} placeholder='Select Approval' mode="multiple"/>;
 

  const Reviewer = ({ value, onChange }) =>
    <KoSelect mode="multiple" value={value}
      onChange={v => onChange(v)}
      showSearch
      placeholder='Select review person'
      options={wosAssignees[0]?.map((user) => ({
        label: (
          <>
            <KoAvatar inLine={'0rem'} size={20} options={[{ name: user?.profile?.firstName, logo: user?.profile?.logo }]} /> {user.profile?.firstName +' '+ user.profile?.lastName}
          </>
        ),
        value: user?.userId
      }))}
    />;

  const Types = ({ value, onChange }) =>
    <KoSelect value={value}
      showSearch
      onChange={v => { onChange(v); }}
      placeholder='Select environment'
      options={environments?.map((env) => ({
        label: env?.name,
        value: env?.environmentId
      }))}
    />;

  const Services = ({ value, onChange }) =>
    <KoSelect value={value}
      showSearch
      onChange={v => { onChange(v); }}
      placeholder='Select Service'
      options={services?.map((ser) => ({
        label: ser?.serviceName,
        value: ser?.serviceId
      }))}
    />;

  const issueTypes = [{
    label: 'Epic',
    value: 1
  }, {
    label: 'Story',
    value: 2
  }, {
    label: 'Task',
    value: 3
  }, {
    label: 'Subtask',
    value: 4
  }, {
    label: 'Bug',
    value: 5
  }, {
    label: 'Improvements',
    value: 6
  }, {
    label: 'Feature request',
    icon: 'FeaturerequestFilled',
    value: 7
  }, {
    label: 'Change request',
    icon: 'ChangerequestFilled',
    value: 8
  }, {
    label: 'Support',
    value: 9
  }];



  const Status = ({ value, onChange }) => (
    <KoSelect
      value={value}
      showSearch
      defaultValue={TASKSTATUSLIST[0].value}
      onChange={v => onChange(v)}
      placeholder='Select status'
      options={TASKSTATUSLIST}
      optionRender={(option) => {
        const label = option?.data?.label;
        const formattedLabel = typeof label === 'string' ? label.replace(" ", '') : '';
        return (
          <div className={`${styles.statusWrapper}`}>
            <div className={`${styles.status} ${styles[formattedLabel]}`} role="img" aria-label={label}/>
            <KoText text={label} />
          </div>
        );
      }}
    />
  );


  const TicketId = ({ value, onChange }) => {
    const options = tickets?.map((ticket) => {
      const issueType = _find(issueTypes, (o) => o.value === ticket.issueType);
      return {
        label: (
          <div className={styles.TicketIdWrapper} aria-label={ticket?.ticketId}>
            <KoIcon name={issueType?.icon ? issueType?.icon : `${issueType?.label}Filled`} width={14} height={14} />
            <KoText className={styles.text} text={ticket?.ticketId} />
          </div>
        ),
        value: ticket?.ticketId
      };
    });
    
    return (
      <KoSelect
        value={value}
        mode="multiple"
        onChange={(v) => onChange(v) }
        showSearch
        placeholder="Select Ticket Id"
        options={options}
      />
    );
  };
    

  const Version = ({ value, onChange }) => {
    return (
      <KoInput
        value={value}
        placeholder="Ex: 1.21"
        addonBefore="v"
        onChange={(v) => onChange(v) }
      />
    );
  };




  const Date = ({ value, onChange }) =>
    <KoDate value={value} dateFormat = {'DD-MMM-YYYY'} daysAgo={0}
      onChange={v => onChange(v)} />;


  const meta = {
    columns: 4,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      {
        key: 'name', placeholder: 'Enter Name', label: 'Name', colSpan: 2, rules: [
          {
            required: true,
            message: `Please enter name.`
          }
        ]
      },
      {
        key: 'service', label: 'Service', colSpan: 2,
        widget: Services,rules: [
          {
            // required: true,
            message: `Please Select Service.`
          }]
      },
      {
        key: 'pullRequest', placeholder: 'Ex: https://github.com/name/repo/pull/17', label: 'Pull Request Url', colSpan: 2, rules: [
          {
            required: true,
            message: `Please enter url.`
          },
          {
            pattern: new RegExp('https:\\/\\/github\\.com\\/[\\w-]+\\/[\\w-]+\\/pull\\/\\d+'),
            message: `Enter valid url.`
          }
        ]
      },
      {
        key: 'ticketId', label: 'Ticket Id', colSpan: 2, widget: TicketId,
        forwardRef: true, rules: [
          {
            required: true,
            message: `Please Select Ticket Id.`
          }]
      },
     
      {
        key: 'requestApproval', label: 'Request for Approval', colSpan: 2,
        widget: Approval,
        forwardRef: true
      },
      {
        key: 'environment', label: 'Environment', colSpan: 2,
        widget: Types,rules: [
          {
            required: true,
            message: `Please Select Environment.`
          }]
      },
      {
        key: 'releaseVersion', placeholder: 'Enter Release Version', label: 'Release Version', colSpan: 2,  forwardRef: true,widget: Version
      }
      ,
      { key: 'startDate', placeholder: 'Enter Start Date', label: 'Start Date', colSpan: 2,widget:Date },
      { key: 'endDate', placeholder: 'Enter End Date', label: 'End Date', colSpan: 2,widget:Date},
      {
        key: 'status', label: 'Status', colSpan: 2,
        widget: Status, rules: [
          {
            required: true,
            message: `Please select status.`
          }
        ]
      }
    ]

  };

  if (form.getFieldValue('status') === 3) {
    meta['fields'].push( { key: ' reviewPerson', placeholder:'Select review person', label: 'Review person', colSpan: 2, widget: Reviewer, rules: [
      {
        required: true,
        message: 'Review person is required'
      }
    ] });
  }

  return (
    <div className={styles.wrapper}>
      <KoForm form={form} layout="vertical" onFinish={handleSubmit} onSubmit={handleSubmit} >
        <KoFormBuilder form={form} meta={meta} />
      </KoForm>
    </div>
  );
};

export default PullRequestCrud;
