import { KoForm, KoFormBuilder } from 'packages';
import { useCallback } from 'react';
import styles from './about.module.scss';

function OrgCrud({form, handleFinish, initialValues}) {

  const handleSubmit = useCallback(values => {
    const updatedValues = {
      organization: { ...values.organization}
    };
    handleFinish(updatedValues);
  });


  const meta = {
    columns: 4,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      {
        key: 'organization.costCenter', label: 'costCenter',  placeholder: 'Enter costCenter', colSpan: 2
      }, {
        key: 'organization.legalEntity',label: 'legalEntity', placeholder: 'Enter legalEntity', colSpan: 2
      },
      {
        key: 'organization.location', label: 'Location', placeholder: 'Enter Location', colSpan: 2
      }
    ]
  };
  return (
    <div className={styles.crudWrapper}>
      <KoForm form={form} layout="vertical" onFinish={handleSubmit} onSubmit={handleSubmit}>
        <KoFormBuilder form={form} meta={meta} />
      </KoForm>
    </div>
  );
};

export default OrgCrud;