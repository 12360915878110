import _find from 'lodash/find';
import KoIcon from "packages/icon";
import KoPopOver from "packages/popover";
import { KoText } from "packages/typography";
import styles from './ticketLink.module.scss';

function MultiTicketLink(props) {
  const { data, row } = props;
  console.log('data', data);
  const issueTypes = [
    { label: 'Epic', value: 1 },
    { label: 'Story', value: 2 },
    { label: 'Task', value: 3 },
    { label: 'Subtask', value: 4 },
    { label: 'Bug', value: 5 },
    { label: 'Improvements', value: 6 },
    { label: 'Feature request', icon: 'FeatureRequestFilled', value: 7 },
    { label: 'Change request', icon: 'ChangeRequestFilled', value: 8 },
    { label: 'Support', value: 9 }
  ];

  const getIcons = () => {
    if (!row?.issueType) return [];
    
    const issueTypeValues = Array.isArray(row.issueType) ? row.issueType : [row.issueType];
    
    return issueTypeValues.map(value => {
      const issueType = _find(issueTypes, o => o.value === value);
      return issueType ? (issueType.icon ? issueType.icon : issueType.label + 'Filled') : '';
    });
  };

  const icons = getIcons().slice(0, 3);

  const validData = Array.isArray(data) ? data : [];
  const displayCount = Math.min(validData.length, 3);
  const additionalCount = validData.length > 3 ? validData.length - 3 : 0;

  const content = validData.slice(3).map((item, index) => {
    const issueType = _find(issueTypes, o => o.value === row.issueType[index + 3]);
    const iconName = issueType ? (issueType.icon || `${issueType.label}Filled`) : '';

    return (
      <div key={index} className={styles.issueTagWrapper}>
       
        {iconName && (
          <KoIcon
            name={iconName}
            width={14}
            height={14}
          />
        )}
        <KoText text={item} />
      </div>
    );
  });


  return (
    <div className={styles.wrapper}>
      {validData.length > 0 ? (
        <>
          {validData.slice(0, displayCount).map((e, index) => (
            <div key={index} className={styles.issueWrapper}>
              {icons[index] && (
                <KoIcon
                  className={styles.icon}
                  name={icons[index]}
                  width={14}
                  height={14}
                />
              )}
              <KoText className={styles.text} text={e} />
            </div>
          ))}
          {additionalCount > 0 && (
            <KoPopOver content={content} title="Additional Ticket Id's" trigger="hover">
             
              <KoText
                className={styles.text}
                text={`and ${additionalCount} more`}
              />
            </KoPopOver>
          )}
        </>
      ) : (
        <>
          {icons.length > 0 && (
            <KoIcon
              className={styles.icon}
              name={icons[0]}
              width={14}
              height={14}
            />
          )}
          <KoText className={styles.text} text="" />
        </>
      )}
    </div>
  );
}

export default MultiTicketLink;
