import React from "react";
import { Mentions  } from 'antd';

function KoMentions(props) {
  return (
    <Mentions
      {...props}
      style={{ width: '100%' }}
    />
  );
}

export default KoMentions;