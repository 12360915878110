import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { TaskPlannerAPI } from "pages/taskPlanner/taskPlanner.service";
const initialState = {
  data: [],
  state: ""
};
export const sprintSlice = createSlice({
  name: "sprint",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(getSprint.fulfilled, (state, action) => {
        state.data = action.payload;
        state.state = "success";
      })
      .addCase(getSprint.pending, (state, action) => {
        state.state = "pending";
      });
  }
});

export const getSprint = createAsyncThunk('sprints/get', async({ query, workspaceId }) => {
  let data = await TaskPlannerAPI.getSprints(query, workspaceId);
  return data['sprints'];

});


export default sprintSlice.reducer;