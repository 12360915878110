import React from "react";
import styles from './loader.module.scss';
import KoIcon from "packages/icon";

function KoLoader({size, circle=true}) {
  return (
    <div className={size ? styles.small : styles.large}>
      {/* {circle && <div className={styles.loader}></div>} */}
      {circle &&
      <div className={styles.bg} >
        <KoIcon name='LoaderFilled' width={size? 20 : 40} height={size? 20 : 40}/>
      </div>
      }
    </div>
  );
}

export default KoLoader;