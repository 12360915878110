import styles from './folder.module.scss';

function Folder(props) {
  let { data } = props;

  return (
    <div className={styles.wrapper}>
      {data.isFolder ? '📁' : '📄'} {data.name}
    </div>

  );
}
export default Folder;
