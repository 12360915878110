import React, { useState, useEffect } from 'react';
import { KoAcceptanceCriteria, KoModal, KoButton, KoForm,
  KoFormBuilder, KoEmpty, KoNano, KoToast, KoComments,
  KoEditorReadOnly, KoTitle, KoDP, KoDateFormatter,
  KoLoader,
  KoText} from 'packages';
import styles from './curd.module.scss';
import { TaskPlannerAPI } from '../taskPlanner.service';
export default function Comments({workspaceId, ticketId, setCreateComment, isOpenCommentsModal}) {
  const [commentsform] = KoForm.useForm();
  const [commentsList, setCommentsList] = useState();
  const [currentRecord, setCurrentRecord ] = useState();
  const [loading, setLoading] = useState(false);
  const [action, setaction] = useState(false);
  const [isOpenDelete, setDeleteModal] = useState(false);
  useEffect(() => {
    getComments(ticketId, workspaceId);
  }, []);

  const getComments = (ticketId, workspaceId) => {
    setLoading(true);
    TaskPlannerAPI.getComments(ticketId, workspaceId).then(
      (data) => {
        setLoading(false);
        setCurrentRecord();
        setCommentsList(data?.comments);
      },
      (err) => {
        setLoading(false);
      }
    );

  };

  const createComment = (values) => {
    setLoading(true);
    if(!currentRecord?.commentId){
      values['commentId'] = KoNano('COM');
    }else{
      values['commentId'] = currentRecord?.commentId;
    }
    if(action === 'Reply'){
      values['parentCommentId'] = currentRecord?.commentId;
      values['commentId'] = KoNano('COM');
    }
    values['ticketId'] = ticketId;
   
    TaskPlannerAPI.createComment(values, action=== 'Reply' ? null : currentRecord?.commentId, workspaceId).then(
      (res) => {
        commentsform.resetFields();
        TaskPlannerAPI.getComments(ticketId, workspaceId).then(
          (res) => {
            if(action === 'Reply'){
              KoToast.success(`Comment Replied successfully`);
            }else{
              KoToast.success(`Comment ${currentRecord?.commentId ? 'updated':'created'} successfully`);
            }
            setCurrentRecord();
            setCreateComment(false);
            setLoading(false);
            getComments(ticketId, workspaceId);
          }
        );
      }
    );
  };

  const CommentsCriteria = ({ value, onChange }) =>
    <KoAcceptanceCriteria  placeholder='Add a comment...' value={value} initialValue={value} workspaceId={workspaceId}
      form={commentsform} name='content'
      onChange={v => onChange(v)}
    />;
    


  
  const COMMENTSSECTION = {
    columns: 6,
    formItemLayout: null,
    colon: true,
    fields: [
      {
        key: 'content', placeholder: 'Comments', label: '', colSpan: 6,
        widget: CommentsCriteria
      }
    ]
  };

  const onReaction = (val, record) => {
    console.log('record', val, record);
    let obj = {
      reaction: val
    };
    TaskPlannerAPI.createReaction(ticketId, record?.commentId, workspaceId, obj ).then(
      (res) => {
        getComments(ticketId, workspaceId);
      }
    );
    setCurrentRecord(record);
  };

  const onReply = (record) => {
    setCurrentRecord(record);
    setCreateComment(true);
    setaction('Reply');
  };

  const onDelete = (record) => {
    setCurrentRecord(record);
    setDeleteModal(true);
  };

  const deleteComment = () => {
    setLoading(true);
    TaskPlannerAPI.deleteComment(currentRecord?.ticketId, currentRecord?.commentId, workspaceId).then(
      (res) => {
        setLoading(false);
        KoToast.success(`Comment deleted successfully`);
        getComments(currentRecord?.ticketId, workspaceId);
        setDeleteModal(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const onUploadFiles = (record, files) => {
    console.log('--------',record, files);
    let obj =  record;
    let att =[];
    for (let i = 0; i < files.length; i++) {
      att.push({
        ...files[i],
        url: files[i]?.response[0]
      });
    }
    obj['attachments'] = att;
    setCurrentRecord(obj);
    // createComment(obj);
  };

  const onEdit = (record) => {
    setCurrentRecord(record);
    setCreateComment(true);
    setaction('Edit');
  };

  return (
    <div className={styles.commentsSection}>
      {loading &&  <KoLoader />}
      {(!commentsList || commentsList?.length === 0) ?  <KoEmpty width={300} height={200} onClick={() => setCreateComment(true)} title={'Start a Conversation'}
        subText={''} label={'Add a Comment'}
        description={''}/> :
        <div className={styles.commentsList}>
          <div className={styles.comments}>
            <KoForm form={commentsform} layout="vertical"
              onFinish={createComment} onSubmit={createComment}>
              <KoFormBuilder meta={COMMENTSSECTION} />
            </KoForm>
          </div>
          <div className={styles.saveCancel}>
            <KoButton key='cancel' label='Cancel' onClick={() => setCreateComment(!isOpenCommentsModal)} />
            <KoButton type='primary' key='Save'  label='Save' onClick={() => commentsform.submit()}/>
          </div>
          <KoComments workspaceId={workspaceId} ticketId={ticketId} data={commentsList} onReaction={onReaction}
            onReply={onReply} onDelete={onDelete} onUploadFiles={onUploadFiles} onEdit={onEdit}/>
        </div>
      }
      <KoModal
        title={action ?  action + ' Comment' : 'Add a Comment'}
        centered
        open={isOpenCommentsModal}
        handleOk={() => { setCreateComment(false); }}
        onClose={() => { setCreateComment(false); }}
        width={`60vw`}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setCreateComment(!isOpenCommentsModal)} />,
          <KoButton type='primary' key='Save'  label={action &&  action !== 'Edit'?  action : 'Update'} onClick={() => commentsform.submit()}/>
        ]}>
        <div className={styles.mcomments}>
          {loading &&  <KoLoader />}
          {currentRecord && <div className={styles.replyComment}>
            <div className={styles.commentHeader}>
              <div className={styles.commentPic}>
                <KoDP width={35} height={35} logo={currentRecord?.profile?.logo} />
              </div>
              <div className={styles.commentName}>
                <KoTitle level={4} className={styles.name} text={currentRecord?.profile?.firstName} />
                <KoTitle level={4} className={styles.name}  text={currentRecord?.profile?.lastName} />
              </div>
              <div className={styles.dot}/>
              <KoDateFormatter data={currentRecord?.createdAt}  format={'DD-MMM-YYYY hh:mm a'}/>
            </div>
            <div className={styles.commentContent}>
              <KoEditorReadOnly initialValue={currentRecord?.content} />
            </div>
          </div>}
          <KoForm form={commentsform} layout="vertical"
            onFinish={createComment} onSubmit={createComment}>
            <KoFormBuilder meta={COMMENTSSECTION} />
          </KoForm>
        </div>
      </KoModal>

      <KoModal
        title={`Delete Comment`}
        centered
        open={isOpenDelete}
        afterClose={() => { setCurrentRecord(); }}
        handleOk={() => { setCurrentRecord(); setDeleteModal(false); }}
        onClose={() => { setCurrentRecord(); setDeleteModal(false); }}
        width={580}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setDeleteModal(!isOpenDelete)} />,
          <KoButton key='delete' loading={loading} type='primary' label='Delete Comment' onClick={() => deleteComment()} />
        ]}
      >
        <div className={styles.wrapperModal}>
          <KoTitle level={5} text={`This action will delete item and cannot be undone`} />
          <KoText text={`Do you wish to proceed?`} />
        </div>
      </KoModal>
    
    </div>
  );
}
