import React from 'react';
import { FloatButton } from 'antd';

function KoFloatButton(props) {

  return (
    <FloatButton {...props}/>
  );
}

export default KoFloatButton;