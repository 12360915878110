import {
  KoBreadcrumb, KoButton,
  KoEmpty,
  KoForm,
  KoIcon, KoLoader,
  KoModal,
  KoTable, KoText, KoTitle, KoToast, KoTooltips
} from 'packages';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from 'react-router-dom';
import { getTicketBreadCrumb } from 'shared/commonConstants';
import { add, remove } from 'shared/store/breadcrumbSlice';
import { getCookie } from 'shared/utils/cookies';
import { PRO_ITEMS_SUB_COLUMNS } from '../constant';
import { TaskPlannerAPI } from '../taskPlanner.service';
import TaskCrud from './crud';
import styles from './curd.module.scss';
import SubtaskCrud from './subtaskCrud';
import { setSelectedChatData, setSelectedChatType } from 'shared/store/chatSlice';

function SubTasksTable({parentRecord, createSubTask, openCreateSubTaskModal, workspace, profile}) {
  const dispatch = useDispatch();
  const userById = useSelector(state => state?.userById);
  const [isLoading, setLoading] = useState(false);
  const [breadCurmbs, setBreadCrumbs] = useState();
  const [isOpen, setModal] = useState(false);
  const [copy, setCopy] = useState(false);
  const [isOpenDelete, setDeleteModal] = useState(false);
  let userDetails = userById['data'];
  const [currentRecord, setCurrentRecord] = useState({
    status:1,
    issueType:2,
    priority:3,
    reporter: userDetails?.userId
  });
  const [tickets, setTickets] = useState();
  const [form] = KoForm.useForm();
  const [formSubTask] = KoForm.useForm();

  const { workspaceId } = useParams();

  useEffect(()=>{
    dispatch(remove());
    dispatch(add([
      {
        title: 'workspace',
        path: 'workspace'
      },
      {
        title: workspace?.name
      },
      {
        title: 'My Tickets'
      }
    ]));

  }, [workspace]);

  useEffect(()=>{
    getTickets(parentRecord?.ticketId);
  },[]);


  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copy) setCopy(false);
    }, 500);

    return () => clearTimeout(timeout);
  }, [copy]);

  const getTickets=()=>{
    setLoading(true);
    setTickets();
    TaskPlannerAPI.getSubTaskIssues(parentRecord?.ticketId,  workspaceId || parentRecord?.workspace).then(
      (data) => {
        setLoading(false);
        setModal(false);
        setTickets(data?.tickets);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

   

  const handleFinish = (values => {
    setLoading(true);
    TaskPlannerAPI.createOrUpdateIssues(values, currentRecord?.ticketId, workspaceId).then(
      (data) => {
        setLoading(false);
        setModal(false);
        getTickets();
        formSubTask.resetFields();
        KoToast.success(`Ticket ${currentRecord?.ticketId ? 'updated':'created'} successfully`);
      },
      (err) => {
        setLoading(false);
      }
    );
  });

  const handleFinishSubTask = (values => {
    setLoading(true);
    values['parentId'] = parentRecord?.ticketId;
    values['parentIssueType'] = parentRecord?.issueType;
    values['issueType'] = 4;
    values['epic'] = values?.epic ?  values?.epic : parentRecord?.epic;
    values['priority'] = values?.priority ?  values?.priority : parentRecord?.priority;
    values['assignee'] = values?.assignee ?  values?.assignee : parentRecord?.assignee;
    values['sprint'] =  values?.sprint ?  values?.sprint : parentRecord?.sprint;
    values['reporter'] = values?.reporter ?  values?.reporter : parentRecord?.reporter;
    values['timeSpent'] = values?.timeSpent ?  values?.timeSpent : parentRecord?.timeSpent;
    values['timeRemaining'] = values?.timeRemaining ?  values?.timeRemaining : parentRecord?.timeRemaining;

    TaskPlannerAPI.createOrUpdateSubtask(values, currentRecord?.ticketId, workspaceId || parentRecord?.workspace).then(
      (data) => {
        setLoading(false);
        setModal(false);
        getTickets();
        form.resetFields();
        openCreateSubTaskModal(false);
        KoToast.success(`Subtask ${currentRecord?.ticketId ? 'updated':'created'} successfully`);
      },
      (err) => {
        setLoading(false);
      }
    );
  });

  const DeleteIssues= ()=> {
    TaskPlannerAPI.deleteIssues(currentRecord?.ticketId).then(
      (data) => {
        setLoading(false);
        setModal(false);
        setDeleteModal(false);
        getTickets();
        form.resetFields();
        KoToast.success("Ticket deleted successfully");
      },
      (err) => {
        setLoading(false);
      }
    );
    getTickets();
  };

  const onCopyTicket=(row)=>{
    setCopy(true);
    navigator.clipboard.writeText(`${window.location.origin}/open/ticket/${getCookie("accountId")}/${row?.workspace}/${row?.ticketId}`);
  };
 

  const onCellClick=(row)=>{
    setCurrentRecord(row);
    const updatedRoomObj = { ...row, source: 'tickets' };
    dispatch(setSelectedChatData(updatedRoomObj));
    dispatch(setSelectedChatType('tickets'));
    let crums = getTicketBreadCrumb(row);
    setBreadCrumbs(crums);
    setModal(true);
  };


  const columnsRight = [{
	  title: '',
	  dataIndex: 'x',
	  key: 'x',
	  fixed: 'right',
	  width: 80,
	  render: (data, row) => {
	    return <div className={`${styles.table} tableBtn columnsRight`} onClick={() => setCurrentRecord(row)}>
        <KoTooltips title={copy ? 'Copied' : 'Copy'}>
	      <KoButton onClick={()=>onCopyTicket(row)} className={'btn'} iconBtn={true} icon={<KoIcon width={14} height={14} name={'CopyOutlined'}/>}/>
        </KoTooltips>

        <KoTooltips title={'New window'}>
          <Link target="_blank" to={`/open/ticket/${getCookie("accountId")}/${row?.workspace}/${row?.ticketId}`}>
            <KoButton className={'btn'}  iconBtn={true} icon={<KoIcon width={14} height={14} name={'OpenOutlined'}/>}/>
          </Link>
        </KoTooltips>
	    </div>;
	  }
  }];

  return (<div className={styles.subTaskWrapper}>
    { isLoading && <KoLoader />}
    {(!tickets || tickets?.length === 0) ?  <KoEmpty width={300} height={200} onClick={() => openCreateSubTaskModal(true)} title={'Create Subtask'}
      subText={''} label={'Add Subtask'}
      description={''}/> :
      <div className={`tableCard`}>
        <div className={styles.tableHeader}>
          <KoTitle level={4} text={`Subtasks (${tickets?.length})`} />
          <KoButton type='primary' label='Add Subtask' onClick={() => openCreateSubTaskModal(true)} />
        </div>
        <KoTable rowKey='ticketId'
          onCellClick={onCellClick}
          data={{ columns: PRO_ITEMS_SUB_COLUMNS, rows: tickets }}
          extraColumnsRight={columnsRight} maxHeight={38} />
      </div>

    }
    
   
    <KoModal
      title={breadCurmbs ? <KoBreadcrumb items={breadCurmbs} /> : currentRecord?.ticketId ? `Update Issue ${currentRecord?.ticketId?.toUpperCase()}` : `Create Issue`}
      centered
      open={isOpen}
      handleOk={() => {setCurrentRecord(); setModal(false);}}
      onClose={() => {setCurrentRecord(); setModal(false); form.resetFields();}}
      width={'93vw'}
      height={'93vh'}
      footer={[
        <KoButton key='cancel' label='Cancel' onClick={() => {setModal(!isOpen); form.resetFields();}} />,
        <KoButton key='create' loading={isLoading} type='primary' label={currentRecord?.ticketId ? `Update` : `Create`}
          onClick={() => form.submit()} />
      ]}
    >
      {isLoading && <KoLoader circle={false} />}

      <TaskCrud subtask={true} form={form} handleFinish={handleFinish} initialValues={currentRecord} />
    
    </KoModal>
    <KoModal
      title={`Delete Issue ${currentRecord?.ticketId?.toUpperCase()}`}
      centered
      open={isOpenDelete}
      handleOk={() => { setCurrentRecord(); setDeleteModal(false); }}
      onClose={() => { setCurrentRecord(); setDeleteModal(false); }}
      width={580}
      // maskClosable={false}
      height={450}
      footer={[
        <KoButton key='cancel' label='Cancel' onClick={() => setDeleteModal(!isOpenDelete)} />,
        <KoButton key='delete' loading={isLoading} type='primary' label='Delete Sprint' onClick={() => DeleteIssues()} />
      ]}
    >
      <div className={styles.suspend}>
        <KoTitle level={5} text='License fees apply to activated users,' />
        <KoText text={`granting access to all paid services.`} />
      </div>
    </KoModal>

    <KoModal
      title={`Create subtask`}
      centered
      open={createSubTask}
      afterClose={() => {setCurrentRecord(); formSubTask.resetFields();}}
      handleOk={() => {setCurrentRecord(); openCreateSubTaskModal(false);}}
      onClose={() => {setCurrentRecord(); openCreateSubTaskModal(false); form.resetFields();}}
      width={'60vw'}
      height={'60vh'}
      footer={[
        <KoButton key='cancel' label='Cancel' onClick={() => {openCreateSubTaskModal(false); formSubTask.resetFields();}} />,
        <KoButton key='create' loading={isLoading} type='primary' label={`Create`}
          onClick={() => formSubTask.submit()} />
      ]}
    >
      {isLoading && <KoLoader circle={false} />}

      <div className={styles.createSubTaskWrapper}>
        <SubtaskCrud form={formSubTask} handleFinish={handleFinishSubTask} profile={profile} />
      </div>

    
    </KoModal>

  </div>);
}

export default SubTasksTable;