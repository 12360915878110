import React, { useEffect, useState } from 'react';
import styles from './insights.module.scss';
import { KoLoader, KoTable, KoTitle } from 'packages';
import { DashbordAPI } from '../dashboard.service';
import { dueCountCol } from '../constant';
export default function WorkspaceInsights() {
  const [activeTicketCount, setActiveTicketCount] = useState([]);
  const [ticketPercentage, setTicketPercentage] = useState([]);
  const [cycleTaskCount, setCycleTaskCount] = useState([]);
  const [projectCompletedTask, setProjectCompletedTask] = useState([]);
  const [workspaceCriteriaCount, setWorkspaceCriteriaCount] = useState([]);
  const [workspaceDescriptionCount, setWorkspaceDescriptionCount] = useState([]);
  const [overdueIssueCount, setOverdueIssueCount] = useState([]);
  const [workspaceDateFilledCount, setWorkspaceDateFilledCount] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getWorkspaceTaskCount();
    getTicketPercentage();
    getCycleTaskCount();
    getProjectCompletedTask();
    getWorkspaceCriteriaCount();
    getWorkspaceDescriptionCount();
    getOverdueIssueCount();
    getWorkspaceDateFilledCount();
  }, []);


  const getWorkspaceTaskCount = () => {
    setLoading(true);
    DashbordAPI.getWorkspaceTaskCount().then(
      (data) => {
        setActiveTicketCount(data?.tickets || []);
        setLoading(false);
      },
      () => setLoading(false)
    );
  };

  const getTicketPercentage = () => {
    setLoading(true);
    DashbordAPI.getTicketPercentage().then(
      (data) => {
        setTicketPercentage(data?.percentages || []);
        setLoading(false);
      },
      () => setLoading(false)
    );
  };

  const getCycleTaskCount = () => {
    setLoading(true);
    DashbordAPI.getCycleTaskCount().then(
      (data) => {
        setCycleTaskCount(data?.counts || []);
        setLoading(false);
      },
      () => setLoading(false)
    );
  };

  const getProjectCompletedTask = () => {
    setLoading(true);
    DashbordAPI.getProjectCompletedTask().then(
      (data) => {
        setProjectCompletedTask(data?.tasks || []);
        setLoading(false);
      },
      () => setLoading(false)
    );
  };

  const getWorkspaceCriteriaCount = () => {
    setLoading(true);
    DashbordAPI.getWorkspaceCriteriaCount().then(
      (data) => {
        setWorkspaceCriteriaCount(data?.criteria || []);
        setLoading(false);
      },
      () => setLoading(false)
    );
  };

  const getWorkspaceDescriptionCount = () => {
    setLoading(true);
    DashbordAPI.getWorkspaceDescriptionCount().then(
      (data) => {
        setWorkspaceDescriptionCount(data?.descriptions || []);
        setLoading(false);
      },
      () => setLoading(false)
    );
  };

  const getOverdueIssueCount = () => {
    setLoading(true);
    DashbordAPI.getOverdueIssueCount().then(
      (data) => {
        setOverdueIssueCount(data?.issues || []);
        setLoading(false);
      },
      () => setLoading(false)
    );
  };

  const getWorkspaceDateFilledCount = () => {
    setLoading(true);
    DashbordAPI.getWorkspaceDateFilledCount().then(
      (data) => {
        setWorkspaceDateFilledCount(data?.dates || []);
        setLoading(false);
      },
      () => setLoading(false)
    );
  };

  return (
    <div className={styles.wrapper}>
      {loading && <KoLoader/>}
      <div className={styles.content}>
        <div className={'tableCard'}>
          <KoTitle text="Tickets by date filled count" level={5} className={styles.panelHeaderTitle} />
          <KoTable data={{ rows: activeTicketCount || [], columns: dueCountCol }} />
        </div>
        <div className={'tableCard'}>
          <KoTitle text="Ticket Percentage" level={5} className={styles.panelHeaderTitle} />
          <KoTable data={{ rows: ticketPercentage || [], columns: dueCountCol }} />
        </div>
        <div className={'tableCard'}>
          <KoTitle text="Cycle Task Count" level={5} className={styles.panelHeaderTitle} />
          <KoTable data={{ rows: cycleTaskCount || [], columns: dueCountCol }} />
        </div>
        <div className={'tableCard'}>
          <KoTitle text="Project Completed Task" level={5} className={styles.panelHeaderTitle} />
          <KoTable data={{ rows: projectCompletedTask || [], columns: dueCountCol }} />
        </div>
        <div className={'tableCard'}>
          <KoTitle text="Workspace Criteria Count" level={5} className={styles.panelHeaderTitle} />
          <KoTable data={{ rows: workspaceCriteriaCount || [], columns: dueCountCol }} />
        </div>
        <div className={'tableCard'}>
          <KoTitle text="Workspace Description Count" level={5} className={styles.panelHeaderTitle} />
          <KoTable data={{ rows: workspaceDescriptionCount || [], columns: dueCountCol }} />
        </div>
        <div className={'tableCard'}>
          <KoTitle text="Overdue Issue Count" level={5} className={styles.panelHeaderTitle} />
          <KoTable data={{ rows: overdueIssueCount || [], columns: dueCountCol }} />
        </div>
        <div className={'tableCard'}>
          <KoTitle text="Workspace Date Filled Count" level={5} className={styles.panelHeaderTitle} />
          <KoTable data={{ rows: workspaceDateFilledCount || [], columns: dueCountCol }} />
        </div>
      </div>
    </div>
  );
}
