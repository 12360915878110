import React from "react";
function ChangePassword(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-lock-password">
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M5 13a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v6a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2z" />
      <path d="M8 11v-4a4 4 0 1 1 8 0v4" />
      <path d="M15 16h.01" />
      <path d="M12.01 16h.01" />
      <path d="M9.02 16h.01" />
    </svg>
  );
}

export default ChangePassword;