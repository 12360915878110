import { KoAvatar, KoForm, KoFormBuilder, KoSelect } from 'packages';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { PRIORITY, STATUS } from 'shared/commonConstants';
import { TaskPlannerAPI } from '../taskPlanner.service';
import styles from './crud.module.scss';
const BulkCrud = ({ form, bulkUpdate, initialValues }) => {
  const {workspaceId} = useParams();
  const users = useSelector(state => state.users);
  const [sprints, setSprints] = useState();


  useEffect(() => {
    getSprints();
  }, []);

  const getSprints = () => {
    setSprints();
    TaskPlannerAPI.getActiveSprints(workspaceId).then(
      (data) => {
        setSprints(data?.sprints);
      },
      (err) => {
      }
    );
  };

  const handleSubmit = useCallback(values => {
    bulkUpdate(values);
  });

  const Assignees = ({ value, onChange }) =>
    <KoSelect mode="multiple" value={value}
      onChange={v => onChange(v)}
      showSearch
      placeholder='Select Assignees'
      options={users['data']?.map((user) => ({
        label: (
          <>
            <KoAvatar inLine={'0rem'} size={20} options={[{ name: user?.profile?.firstName, logo: user?.profile?.logo }]} /> {user.profile?.firstName + ' ' + user.profile?.lastName}
          </>
        ),
        value: user?.userId
      }))}
    />;
  const Sprint = ({ value, onChange }) =>
    <KoSelect value={value}
      onChange={v => onChange(v)}
      showSearch
      placeholder='Select sprint'
      options={sprints?.map((spr) => ({
        label: spr?.name,
        value: spr?.sprintId
      }))}
    />;


  const Status = ({ value, onChange }) => (
    <KoSelect
      value={value}
      showSearch
      onChange={v => { onChange(v); }}
      placeholder='Select status'
      options={STATUS}
  
    />
  );


  const Priority = ({ value, onChange }) =>
    <KoSelect value={value}
      showSearch
      onChange={v => onChange(v)}
      placeholder='Select priority'
      options={PRIORITY}/>;

  const meta = {
    columns: 4,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      {
        key: 'assignees', label: 'Assignees', colSpan: 2,
        widget: Assignees,
        forwardRef: true
      },
      {
        key: 'status', label: 'Status', colSpan: 2,
        widget: Status
      },
      {
        key: 'priority', label: 'Priority', colSpan: 2,
        widget: Priority
      },
      {
        key: 'sprint', label: 'Sprint', colSpan: 2, widget: Sprint
      }
    ]

  };


  return (
    <div className={styles.wrapper}>
      <KoForm form={form} layout="vertical"
        onFinish={handleSubmit} onSubmit={handleSubmit}
      >
        <KoFormBuilder form={form} meta={meta} />
      </KoForm>
    </div>
  );
};

export default BulkCrud;
