import React from "react";
function WishList(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 22" fill="none">
      <path d="M0.431975 0.0536003C0.263732 0.126987 0.113678 0.292107 0.0500182 0.461813C0.0136413 0.553547 0 3.74128 0 11.002C0 19.6891 0.00909421 21.4366 0.0636595 21.5605C0.190978 21.8723 0.536559 22.0512 0.886686 21.987C1.00491 21.9641 1.89614 21.2715 4.51073 19.1616C6.42051 17.6205 7.99381 16.3638 8.00745 16.3638C8.02564 16.3638 9.59439 17.6205 11.5042 19.1616C14.0642 21.2256 15.01 21.9641 15.1237 21.987C15.4874 22.0558 15.8694 21.8357 15.9603 21.51C15.9967 21.3907 16.0058 18.1296 15.9967 10.8965L15.9831 0.452641L15.8876 0.3288C15.8376 0.26 15.7375 0.159094 15.6693 0.108641C15.5466 0.0123215 15.542 0.0123215 8.05747 0.00314713C1.88705 -0.00602531 0.545653 0.00314713 0.431975 0.0536003ZM14.5507 10.5892C14.5507 17.8774 14.5371 19.712 14.4962 19.6845C14.4643 19.6616 13.0638 18.5378 11.3814 17.1848C8.18024 14.6025 8.20753 14.6162 7.84831 14.685C7.73463 14.708 6.87977 15.373 4.59712 17.2123C2.89196 18.5883 1.49145 19.712 1.47781 19.712C1.46417 19.712 1.45507 15.6024 1.45507 10.5846V1.45712H8.00291H14.5507V10.5892Z" fill="black" />
    </svg>
  );
}


export default WishList;