import React from "react";
import { Switch  } from 'antd';

function KoSwitch(props) {
  return (
    <Switch
      {...props}
      style={{ width: '100%' }}
    />
  );
}

export {KoSwitch, Switch};