import { createSlice } from "@reduxjs/toolkit";

const initialState = [];
const breadcrumbSlice = createSlice({
  name: "breadcrumbs",
  initialState,
  reducers: {
    add(state, action) {
      state.push(action.payload);
    },
    remove(state, action) {
      return state.filter(prd => prd.id !== action.payload);
    }
  }
});

export const { add, remove } = breadcrumbSlice.actions;
export default breadcrumbSlice.reducer;