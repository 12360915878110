

import React from "react";
import { Pagination } from "antd";
import styles from './pagination.module.scss';

function KoPagination(props) {
  return (
    <div className={styles.wrapper}>
      <Pagination
        showTotal={(total, range) => `Presenting ${range[0]}-${range[1]} of ${total} records`}
        showSizeChanger={true}
        title={props.title}
        defaultCurrent={props.defaultCurrent}
        defaultPageSize={props.defaultPageSize}
        disabled={props.disabled}
        hideOnSinglePage={props.hideOnSinglePage}
        pageSize={props.pageSize}
        responsive={props.responsive}
        showLessItems={props.showLessItems}
        showQuickJumper={props.showQuickJumper}
        showTitle={props.showTitle}
        simple={props.simple}
        total={props.total}
        onChange={props.onChange}
        onShowSizeChange={props.onShowSizeChange}
        size={props.size}
        current={props.current} />
    </div>
  );
}

export default KoPagination;