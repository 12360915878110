import { Input } from 'antd';
import React, { forwardRef } from 'react';

const KoInput = forwardRef((props, ref) => {
  return (
    <Input
      {...props}
      ref={ref}
      style={{ width: '100%' }}
      autoComplete="off"
    />
  );
});

export default KoInput;
