import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {getCookie} from "shared/utils/cookies";
import { OrgAPI } from "pages/organization/org.service";

const initialState = {
  data: {},
  state: ""
};
export const userByIdSlice = createSlice({
  name: "userById",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserById.fulfilled, (state, action) => {
        state.data = action.payload;
        state.state = "success";
      })
      .addCase(getUserById.pending, (state, action) => {
        state.state = "pending";
      });
  }
});

export const getUserById = createAsyncThunk('user/get', async(query) => {
  let userId = getCookie('userId');
  let data = await OrgAPI.getUserById(userId);
  return data['user'];
});


export default userByIdSlice.reducer;