import React from 'react';
import styles from './crud.module.scss';
import { KoForm, KoFormBuilder, KoTabs, KoTitle, Select } from 'packages';
import API from 'packages/datatable/widgets/api';
import { getRequetsTabs, getResponseTabs } from './constants';

const { Option } = Select;

function RequestCrud({
  initialValues,
  handleFinish,
  form
}) {

  const handleSubmit = (values) => {
    handleFinish(values);
  };

  const Methods = ({ value, onChange }) =>
    <Select value={value || 'GET'} onChange={(v) => onChange(v)} showSearch popupClassName={styles.select}>
      <Option value="GET" label="GET"> <API hideCopy={true} value={'GET'} /></Option>
      <Option value="POST" label="POST"><API hideCopy={true} value={'POST'} /></Option>
      <Option value="PATCH" label="PATCH"><API hideCopy={true} value={'PATCH'} /></Option>
      <Option value="PUT" label="PUT"><API hideCopy={true} value={'PUT'} /></Option>
      <Option value="DELETE" label="DELETE"><API hideCopy={true} value={'DELETE'} /></Option>
      <Option value="OPTIONS" label="OPTIONS"><API hideCopy={true} value={'OPTIONS'} /></Option>
    </Select>;

  const requestTabs = getRequetsTabs(form, initialValues);
  const responseTabs = getResponseTabs(form, initialValues);

  const meta = {
    columns: 6,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      {
        key: 'name', placeholder: 'Ex: Create user', label: 'Name', colSpan: 2, rules: [
          {
            required: true,
            message: `Enter new request name.`
          }
        ]
      },
      {
        key: 'method', label: 'Method', colSpan: 1,
        widget: Methods,
        forwardRef: true,
        rules: [
          {
            required: true,
            message: `Select method`
          }
        ]
      },
      {
        key: 'requestURL', placeholder: 'Ex: /api/user/create', label: 'Request URL', colSpan: 3, rules: [
          {
            required: true,
            message: `Enter new request url.`
          }
        ]
      }
    ]
  };

  return (
    <div
      className={`${styles.wrapper} request`}>
      <KoForm form={form} layout="vertical" onFinish={handleSubmit} onSubmit={handleSubmit} >
        <KoFormBuilder form={form} meta={meta} />
        <div className={styles.tabsWrapper}>
          <div>
            <KoTitle level={5} text={'Request'} />
            <KoTabs options={requestTabs} />
          </div>
          <div>
            <KoTitle level={5} text={'Response'} />
            <KoTabs options={responseTabs} />
          </div>
        </div>
      </KoForm>
    </div>
  );
}

export default RequestCrud;