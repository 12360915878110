import React, { useState, useEffect } from 'react';
import {
  KoAffix,
  KoText,
  KoIcon,
  KoModal,
  KoButton,
  KoForm,
  KoLoader,
  KoNano,
  KoTitle,
  KoToast,
  KoFlex,
  KoFolder,
  KoEmpty
} from "packages";
import KoToolBar from "shared/components/toolbar";


import EnvironmentCrud from './envCrud';
import { ApiClientAPI } from '../apiClient.service';
import styles from "./env.module.scss";
import { debounce } from 'lodash';
import { useParams } from 'react-router';

function Environments() {
  const [isEnvOpen, setEnvModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [environments, setEnvironments] = useState();
  const [searchedEnvironments, setSearchedEnvironments] = useState();
  const [currentRecord, setCurrentRecord] = useState();
  const [isOpenEnvDelete, setDeleteEnvModal] = useState(false);
  const { workspaceId } = useParams();
  const [form] = KoForm.useForm();

  useEffect(() => {
    getEnvironments();
  }, []);

  const getEnvironments = () => {
    setLoading(true);
    ApiClientAPI.getEnvironments(workspaceId).then(
      (data) => {
        setLoading(false);
        setEnvironments(data['environments']);
        setSearchedEnvironments(data['environments']);
        setEnvModal(false);
      },
      (err) => {
        setLoading(false);
        KoToast.error(`error`);
      }
    );
  };

  const onCreateEnvironment = () => {
    setCurrentRecord();
    form.resetFields();
    setEnvModal(true);
  };

  const handleEnvFinish = (values => {
    setLoading(true);
    if (!currentRecord?.environmentId) {
      values['environmentId'] = KoNano('ENV');
    }

    ApiClientAPI.createOrUpdateEnvironment(values, currentRecord?.environmentId).then(
      (data) => {
        getEnvironments();
        setLoading(false);
        KoToast.success(`Environment ${currentRecord?.environmentId ? `update` : `created`} successfully! ${values.name}!`);
      },
      (err) => {
        setLoading(false);
        KoToast.error(`error`);
      }
    );
  });

  const onHandelEnvironment = (key, env) => {
    form.resetFields();
    setCurrentRecord(env);
    if (key === "1") {
      setEnvModal(true);
    } else {
      setDeleteEnvModal(true);
    }
  };

  const handleFinishEnvDelete = () => {
    setLoading(true);
    ApiClientAPI.deleteEnvironment(currentRecord?.environmentId).then(
      (data) => {
        getEnvironments();
        setLoading(false);
        setDeleteEnvModal(false);
        KoToast.success(`Environment deleted successfully! ${currentRecord.name}!`);
      },
      (err) => {
        setLoading(false);
        KoToast.error(`error`);
      }
    );
  };

  const handleSearch = debounce((value) => {
    const filteredValues = environments?.filter(obj => {
      const objName = obj?.name?.toLowerCase();
      return objName.startsWith(value.toLowerCase());
    });
    setSearchedEnvironments(filteredValues);
  }, 200);


  const items = [
    {
      label: "Update Environment",
      key: "1",
      icon: <KoIcon name="EditOutlined" height={12} width={13} />
    },
    {
      label: "Delete",
      key: "2",
      danger: true,
      icon: <KoIcon name="DeleteOutlined" height={12} width={13} />
    }
  ];
  const onClick = ({ key }, proj) => {
    onHandelEnvironment(key, proj);
  };

  return (
    <div className={styles.wrapper}>
      {isLoading && <KoLoader />}
      <KoAffix top={56}>
        <KoToolBar
          className={styles.toolbar}
          title={searchedEnvironments?.length > 0 && "Environments"}
          search={{
            visible: true,
            onChange: handleSearch,
            placeholder: "Search by name"
          }}
          buttonList={[
            {
              label: "Create Environment",
              type: "primary",
              icon: (
                <KoIcon
                  color="#FFF"
                  name="PlusOutlined"
                  width={18}
                  height={18}
                />
              ),
              onClick: () => onCreateEnvironment()
            }
          ]}
        />
      </KoAffix>

      {environments?.length > 0 ?
        <KoFlex wrap="wrap" gap="small" className={styles.recentFolders}>
          {environments?.map((folder, i) => (
            <div key={folder?.environmentId}>
              <KoFolder noLink={true} id={'environmentId'} data={folder} actions={items} onClick={onClick} />
            </div>
          ))}
        </KoFlex> :
        <KoFlex wrap="wrap" gap="small" className={styles.emptySection}>
          <KoEmpty onClick={() => onHandelEnvironment('1')} label="Create Environment" icon={'EmptyTwoTone'} title="Create a new Environment" />
        </KoFlex>}

      <KoModal
        title={currentRecord?.environmentId ? `Update Environment ${currentRecord?.name?.toUpperCase()}` : 'Create Environment'}
        centered
        open={isEnvOpen}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setEnvModal(false); }}
        onClose={() => { setCurrentRecord(); setEnvModal(false); }}
        width={760}
        // maskClosable={false}
        height={750}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setEnvModal(!isEnvOpen)} />,
          <KoButton key='create' loading={isLoading} type='primary' label={`${currentRecord?.environmentId ? 'Update' : 'Create'}`} onClick={() => form.submit()} />
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <EnvironmentCrud form={form} handleFinish={handleEnvFinish} initialValues={currentRecord} />
      </KoModal>

      <KoModal
        title={`Delete Environment ${currentRecord?.name?.toUpperCase()}`}
        centered
        open={isOpenEnvDelete}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setDeleteEnvModal(false); }}
        onClose={() => { setCurrentRecord(); setDeleteEnvModal(false); }}
        width={580}
        // maskClosable={false}
        height={450}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setDeleteEnvModal(!isOpenEnvDelete)} />,
          <KoButton key='reset' loading={isLoading} type='primary' label='Delete' onClick={() => handleFinishEnvDelete()} />
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <div className={styles.formWrapper}>
          <KoTitle level={5} text={`Are you sure you want to delete this Environment?`} />
          <KoText text={`This action is irreversible, and it will result in the removal of their associated data and Kwapio license.`} />
        </div>
      </KoModal>
    </div>
  );
}

export default Environments;