import React from "react";
function NonBillable(props) {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
      <path
        fill="#65bc6a"
        d="M22.88 8.17l35.3 26.25a6.013 6.013 0 001.24 8.4l-9.55 12.83c-2.66-1.97-6.42-1.42-8.39 1.24L6.17 30.63a5.99 5.99 0 00-1.24-8.39L14.48 9.4c2.66 1.98 6.41 1.42 8.4-1.23z"
      />
      <path
        fill="#398f3e"
        d="M62.2 37.4c.44.33.53.96.2 1.4l-2.98 4.02a6.013 6.013 0 01-1.24-8.4l4.02 2.98zM49.87 55.65l-2.98 4.02c-.33.44-.96.53-1.4.2l-4.01-2.98c1.97-2.66 5.73-3.21 8.39-1.24zM37.55 25.3c3.98 2.97 4.81 8.61 1.85 12.6-2.97 3.99-8.61 4.81-12.59 1.85-3.99-2.97-4.82-8.61-1.86-12.59 2.97-3.99 8.61-4.82 12.6-1.86z"
      />
      <path
        fill="#65bc6a"
        d="M27.999 38.143a.999.999 0 001.399-.205l.849-1.142c.068.01.132.029.201.034a3.006 3.006 0 003.209-2.776 2.997 2.997 0 00-.683-2.13 1.003 1.003 0 00-.201-.2.998.998 0 01-.206-1.399.996.996 0 011.4-.205.988.988 0 01.393.657 1 1 0 001.979-.291 2.999 2.999 0 00-.214-.753c-.072-.167-.177-.315-.278-.465l.713-.959a.999.999 0 10-1.605-1.193l-.705.948a2.952 2.952 0 00-1.316-.11 2.982 2.982 0 00-1.971 1.178 3.003 3.003 0 00.504 4.109.987.987 0 01.196.67c-.04.55-.534.966-1.069.925a.99.99 0 01-.684-.343.989.989 0 01-.241-.726 1 1 0 00-.925-1.07.99.99 0 00-1.07.925 2.977 2.977 0 00.724 2.179c.018.021.04.035.058.055l-.66.888a.995.995 0 00.203 1.399zM6.17 30.63l27.82 20.69h-.01L6.79 42.67c1.01-3.16-.74-6.53-3.9-7.54L4 31.64l.67-2.12 1.5 1.11z"
      />
      <path
        fill="#398f3e"
        d="M33.98 51.32L24.21 48.21 4.17 33.31 3.6 32.89 4 31.64 4.67 29.52 6.17 30.63z"
      />
      <path
        fill="#65bc6a"
        d="M36.25 53H10c0-3.31-2.69-6-6-6v-5.22l2.79.89 27.19 8.65h.01L36.25 53z"
      />
      <path
        fill="#29752b"
        d="M36.25 53L29.83 53 6.79 45.67 4 44.78 4 41.78 6.79 42.67 33.98 51.32 33.99 51.32z"
      />
      <path
        fill="#236823"
        d="M60 47v5c0 .55-.45 1-1 1h-3.02l.28-.89 1.49-4.67c.69-.29 1.45-.44 2.25-.44z"
      />
      <path
        fill="#29752b"
        d="M60 42.03V47c-.8 0-1.56.15-2.25.44l1.32-4.15v-.01l.35-.46.58-.79z"
      />
      <path
        fill="#65bc6a"
        d="M59.07 43.29l-1.32 4.15-1.49 4.67c-.71-.23-1.44-.31-2.15-.27-.56.03-1.11.14-1.64.32l6.6-8.87z"
      />
      <path
        fill="#398f3e"
        d="M56.26 52.11l-.28.89-1.24 3.88c-.16.52-.73.81-1.25.65l-4.05-1.29.43-.59 2.6-3.49c.53-.18 1.08-.29 1.64-.32.71-.04 1.44.04 2.15.27zM60 26v4.22l-1.99-.64-2.71-.86h-.01A5.952 5.952 0 0154 25h5c.55 0 1 .45 1 1z"
      />
      <path
        fill="#65bc6a"
        d="M55.29 28.72l-8.33-2.65h-.01L45.52 25H54c0 1.41.48 2.7 1.29 3.72z"
      />
      <path fill="#398f3e" d="M10.67 14.52L8.54 17.39 8.54 17.38 9.56 14.17z" />
      <path
        fill="#398f3e"
        d="M10 53H5c-.55 0-1-.45-1-1v-5c3.31 0 6 2.69 6 6zM2.89 35.13c3.16 1.01 4.91 4.38 3.9 7.54L4 41.78l-1.98-.63c-.52-.16-.81-.73-.65-1.25l1.52-4.77zM22.88 8.16v.01c-1.99 2.65-5.74 3.21-8.4 1.23l2.99-4.01c.32-.45.95-.54 1.39-.21l4.02 2.98zM4.93 22.24a5.99 5.99 0 011.24 8.39l-4.01-2.98a.996.996 0 01-.21-1.4l2.98-4.01z"
      />
      <path
        fill="#65bc6a"
        d="M57.98 29.67c-.43 1.17-.5 3.04-.23 4.42l-10.79-8.02 8.33 2.65h.01l2.71.86-.03.09z"
      />
      <path
        fill="#398f3e"
        d="M57.75 34.09c-.27-1.38-.2-3.25.23-4.42l.03-.09 1.99.64 1.98.63c.52.16.81.73.65 1.25l-1.45 4.54-3-2.22-.43-.33zm1.32 9.2l-1.32 4.15-1.49 4.67c-.71-.23-1.44-.31-2.15-.27-.56.03-1.11.14-1.64.32l6.6-8.87z"
      />
      <path
        fill="#29752b"
        d="M54.11 51.84c-.56.03-1.11.14-1.64.32l-1.73 2.33-.87 1.16-.43.59 3.23 1.03.82.26c.52.16 1.09-.13 1.25-.65L55.98 53l.1-.32.18-.57c-.71-.23-1.44-.31-2.15-.27z"
      />
      <path
        fill="#236823"
        d="M56.34 29.05l-1.04-.33h-.01c-.22-.27-.41-.56-.57-.87l1.62 1.2z"
      />
      <path
        fill="#29752b"
        d="M55.29 28.72l-8.33-2.65h-.01L45.52 25h5.37l3.83 2.85c.16.31.35.6.57.87z"
      />
      <path
        fill="#398f3e"
        d="M57.63 31.32c.05-.41.11-.81.2-1.16l-1.49-1.11-1.04-.33h-.01l-8.33-2.65 10.06 7.48.73.54c-.16-.83-.2-1.84-.12-2.77z"
      />
      <path fill="#398f3e" d="M61.09 36.57L61.29 36.3 61.18 36.64z" />
      <path
        fill="#29752b"
        d="M62.2 33.4l-4.02-2.98-.35-.26c-.09.35-.15.75-.2 1.16-.08.93-.04 1.94.12 2.77l.43.33 2.91 2.15.2-.27.92-2.89-.01-.01z"
      />
    </svg>
  );
}
export default NonBillable;