import { Statistic } from "antd";

function KoStatistic(props) {
  const { title = "Active", value = 10, precision = 0, onClick } = props;
  
  return (
    <div onClick={onClick} style={{ cursor: 'pointer' }}>
      <Statistic
        title={title}
        value={value}
        precision={precision}
        {...props}
      />

     
    </div>
  );
}

export default KoStatistic;