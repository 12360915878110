const roleAccess = {
  1: ['Peoples', 'Groups', 'Designations', 'Departments', 'Roles'],
  2: ['Peoples', 'Groups', 'Designations', 'Departments', 'Roles'],
  3: ['Peoples', 'Groups', 'Designations', 'Departments', 'Roles'],
  4: [],
  5: ['Peoples', 'Groups', 'Designations', 'Departments', 'Roles'],
  6: [],
  7: []
};

const menu =  [
  {
    key: '/org/employees',
    icon:'ActiveUseresOutlined',
    label: "Peoples"
  },
  {
    key: '/org/groups',
    icon:'UsersOutlined',
    label: "Groups"
  },
  {
    key: '/org/designations',
    icon:'PayslipOutlined',
    label: "Designations"
  },
  {
    key: '/org/departments',
    icon:'DepartmentOutlined',
    label: "Departments"
  },
  {
    key: '/org/roles',
    icon:'SecurityOutlined',
    label: "Roles"
  }
];
  
function getOrgMenuForRoles(user) {
  const accessibleMenus = new Set();
  user?.roles?.forEach(roleId => {
    const accessibleItems = roleAccess[roleId] || [];
    accessibleItems?.forEach(item => accessibleMenus.add(item));
  });
  return menu.filter(menuItem => accessibleMenus.has(menuItem.label));
}

  
export {
  getOrgMenuForRoles
};
  