const STATISTICS = [{
  color: '--palette-gray-10',
  backgroud: '--palette-primary-darker',
  title: 'Total Employees',
  icon: 'TotalUsersOutlined',
  value: 0
}, {
  color: '--palette-gray-10',
  backgroud: '--color-palette-6-dark',
  title: 'Active Employees',
  icon: 'ActiveUseresOutlined',
  value: 0
}, {
  color: '--palette-gray-10',
  backgroud: '--color-palette-2-dark',
  title: 'Inactive Employees',
  icon: 'InactiveUsersOutlined',
  value: 0
}, {
  color: '--palette-gray-10',
  backgroud: '--color-palette-4-dark',
  title: 'Total Groups',
  icon: 'TotalGroupsOutlined',
  value: 0
}, {
  color: '--palette-gray-10',
  backgroud: '--color-palette-5-dark',
  title: 'Invited',
  icon: 'InvitedOutlined',
  value: 0
}, {
  color: '--palette-gray-10',
  backgroud: '--color-palette-7-dark',
  title: 'Requested',
  icon: 'RequestedOutlined',
  value: 0
}];

export{
  STATISTICS
};