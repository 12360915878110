const KoColors = {
  a: '#2fa561',
  j: '#2fa561',
  s: '#2fa561',
  b: '#ff647c',
  k: '#ff647c',
  t: '#ff647c',
  c: '#6258c4',
  l: '#6258c4',
  u: '#6258c4',
  d: '#6979f8',
  m: '#6979f8',
  v: '#6979f8',
  e: '#be52f2',
  n: '#be52f2',
  w: '#be52f2',
  f: '#ffa030',
  o: '#ffa030',
  x: '#ffa030',
  g: '#11afa1',
  p: '#11afa1',
  y: '#11afa1',
  h: '#f52f62',
  q: '#f52f62',
  z: '#f52f62',
  i: '#1c90f5',
  r: '#1c90f5'
};

export default KoColors;