/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import isArray from 'lodash/isArray';
import find from 'lodash/find';
import { Row, Col } from 'antd';
import FormBuilderField from './formbuilderfield';

const widgetMap = {};

function getWidget(widget) {
  if (!widget) return null;
  if (typeof widget === 'string') {
    if (!widgetMap[widget] || !widgetMap[widget].widget) {
      throw new Error(
        `Widget '${widget}' not found, did you define it by FormBuilder.defineComponent?`
      );
    }
    return widgetMap[widget].widget;
  }
  return widget;
}

function normalizeMeta(meta) {
  let fields = isArray(meta) ? meta : meta.fields || meta.elements;
  if (!fields) fields = [meta];
  fields = fields.map(field => {
    const widget = getWidget(field.widget);
    const viewWidget = getWidget(field.viewWidget);
    const dynamic = field.dynamic !== false;
    const item = find(
      Object.values(widgetMap),
      entry => (entry.widget === widget || entry.widget === viewWidget) && entry.metaConvertor
    );
    if (item) {
      const newField = item.metaConvertor(field);
      if (!newField) {
        throw new Error(`metaConvertor of '${String(field.widget)}' must return a field`);
      }
      return { ...newField, viewWidget, widget, dynamic };
    }
    return { ...field, widget, viewWidget, dynamic };
  });
  return isArray(meta) || (!meta.fields && !meta.elements) ? { fields } : { ...meta, fields };
}

function FormBuilder(props) {
  const { getMeta, form } = props;
  const meta = getMeta ? getMeta(form, props) : props.meta;
  return <FormBuilderInner {...props} form={form ? form.current || form : null} meta={meta} />;
}

function FormBuilderInner(props) {
  const { meta, viewMode, initialValues, disabled = false, form = null } = props;
  if (!meta) return null;

  const newMeta = normalizeMeta(meta);
  newMeta.viewMode = newMeta.viewMode || viewMode;
  newMeta.initialValues = newMeta.initialValues || initialValues;
  const { fields, columns = 1, gutter = 10 } = newMeta;

  const elements = fields.map(field => (
    <FormBuilderField
      key={field.key}
      field={field}
      disabled={disabled}
      meta={newMeta}
      form={form}
    />
  ));

  if (columns === 1) {
    return elements;
  }

  const rows = [];
  const spanUnit = 24 / columns;
  for (let i = 0; i < elements.length; ) {
    const cols = [];
    for (
      let j = 0;
      (j < columns || j === 0) &&
      i < elements.length &&
      (!['left', 'both'].includes(fields[i].clear) || j === 0);
    ) {
      const fieldSpan = fields[i].colSpan || 1;
      cols.push(
        <Col key={j} span={Math.min(24, spanUnit * fieldSpan)}>
          {elements[i]}
        </Col>
      );
      j += fieldSpan;
      if (['both', 'right'].includes(fields[i].clear)) {
        i += 1;
        break;
      }
      i += 1;
    }
    rows.push(
      <Row key={i} gutter={gutter}>
        {cols}
      </Row>
    );
  }
  return rows;
}

FormBuilder.defineWidget = (name, widget, metaConvertor = null) => {
  if (widgetMap[name]) throw new Error(`Widget "${name}" already defined.`);
  widgetMap[name] = { widget, metaConvertor };
};

FormBuilder.useForceUpdate = () => {
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  return forceUpdate;
};

FormBuilder.propTypes = {
  meta: PropTypes.any
};

export default FormBuilder;
