
import PhoneInput from 'antd-phone-input';
import { KoAvatar, KoButton, KoCard, KoDateFormatter, KoDivider, KoIcon, KoModal, KoText } from 'packages';
import { useState } from 'react';
import { getCookie } from 'shared/utils/cookies';
import styles from './about.module.scss';
import AddressCrud from './addressCrud';
import Crud from './crud';


export default function ProfileContact({userData, handleFinish, form, preferenceData}) {
  const [isOpen, setModal] = useState(false);
  const [isAddressOpen, setAddressModal] = useState(false);

  const handleSubmit = (values) => {
    handleFinish(values);
    setModal(false);
  };

  return (
    <div className={styles.wrapper}>
     
      <KoCard hoverable={false} className={styles.tabs}>
        <div className={styles.previewWrapper}>
          <KoDivider orientation="left" orientationMargin="0">
            <div className={styles.heading}>
              <KoText className={styles.headingText} text={'Primary Details'} />
              {
                userData?.userId === getCookie('userId') &&
             <KoIcon width={14} height={14} name={'EditOutlined'} onClick={() => setModal(true)}/>
              }
            </div>
          </KoDivider>
          <div className={styles.card}>
            
            <div className={styles.fieldList}>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'First Name'} />
                <KoText className={styles.titleValue} text={userData?.primaryDetails?.firstName} />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Middle Name'} />
                <KoText className={styles.titleValue} text={userData?.primaryDetails?.middleName ? userData?.primaryDetails?.middleName : '-Not Set-'} />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Last Name'} />
                <KoText className={styles.titleValue} text={userData?.primaryDetails?.lastName ? userData?.primaryDetails?.lastName : '-Not Set-'} />

              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Display Name'} />
                <KoText className={styles.titleValue} text={userData?.primaryDetails?.displayName ? userData?.primaryDetails?.displayName : '-Not Set-'} />

              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Gender'} />
                <KoText className={styles.titleValue} text={userData?.primaryDetails?.gender ? userData?.primaryDetails?.gender : '-Not Set-'} />

              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Date of Birth'} />
                {
                  userData?.primaryDetails?.dateOfBirth === "0001-01-01T00:00:00Z" ?
                    <KoText className={styles.titleValue} text={'-Not Set-'} />
                    :
                    <KoDateFormatter format='DD-MMM-YYYY' data={userData?.primaryDetails?.dateOfBirth} className={styles.titleValue}/>
                }
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Marital Status'} />
                <KoText className={styles.titleValue} text={userData?.primaryDetails?.maritalStatus ? userData?.primaryDetails?.maritalStatus : '-Not Set-' } />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Blood Group'} />
                <KoText className={styles.titleValue} text={userData?.primaryDetails?.bloodGroup ? userData?.primaryDetails?.bloodGroup : '-Not Set-'} />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Physically Handicapped'} />
                <KoText className={styles.titleValue} text={userData?.primaryDetails?.disability ? userData?.primaryDetails?.disability : '-Not Set-'} />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Nationality'} />
                <KoText className={styles.titleValue} text={userData?.primaryDetails?.nationality ? userData?.primaryDetails?.nationality : '-Not Set-'} />
              </div>
            </div>
          </div>
         
          <KoDivider orientation="left" orientationMargin="0">
            <div className={styles.heading}>
              <KoText className={styles.headingText} text={'Addresses'} />
              {
                userData?.userId === getCookie('userId') &&
             <KoIcon width={14} height={14} name={'EditOutlined'} onClick={() => setAddressModal(true)}/>
              }
            </div>
          </KoDivider>
          <div className={styles.card}>
            <div className={styles.fieldList}>
              <div className={styles.viewMode}>
                <KoText className={styles.headingText} text={'Current Address'} />
                <KoText className={styles.titleValue}
                  text={`${userData?.addresses?.currentAddress?.addressLine1 ? userData?.addresses?.currentAddress?.addressLine1+', ' :''}
          ${userData?.addresses?.currentAddress?.addressLine2 ? userData?.addresses?.currentAddress?.addressLine2+', ' :''}
          ${userData?.addresses?.currentAddress?.city ? userData?.addresses?.currentAddress?.city+', ' :''}
          ${userData?.addresses?.currentAddress?.state ? userData?.addresses?.currentAddress?.state+', ' :''}
          ${userData?.addresses?.currentAddress?.country ? userData?.addresses?.currentAddress?.country+', ' :''}
          ${userData?.addresses?.currentAddress?.pincode ? userData?.addresses?.currentAddress?.pincode :''}`} />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.headingText} text={'Permanent Address'} />
                <KoText className={styles.titleValue}
                  text={`${userData?.addresses?.permanentAddress?.addressLine1 ? userData?.addresses?.permanentAddress?.addressLine1+', ' :''}
          ${userData?.addresses?.permanentAddress?.addressLine2 ? userData?.addresses?.permanentAddress?.addressLine2+', ' :''}
          ${userData?.addresses?.permanentAddress?.city ? userData?.addresses?.permanentAddress?.city+', ' :''}
          ${userData?.addresses?.permanentAddress?.state ? userData?.addresses?.permanentAddress?.state+', ' :''}
          ${userData?.addresses?.permanentAddress?.country ? userData?.addresses?.permanentAddress?.country+', ' :''}
          ${userData?.addresses?.permanentAddress?.pincode ? userData?.addresses?.permanentAddress?.pincode :''}`} />
              </div>
            </div>
          </div>
          <KoDivider orientation="left" orientationMargin="0">
            <KoText className={styles.headingText} text={'General Preferences'} />
          </KoDivider>
          <div className={styles.card}>
            <div className={styles.fieldList}>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Default Language'} />
                <KoText className={styles.titleValue} text={preferenceData?.preferences?.defaultLanguage ? preferenceData?.preferences?.defaultLanguage : '-Not Set-'} />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Primary Currency'} />
                <KoText className={styles.titleValue} text={preferenceData?.preferences?.primaryCurrency ? preferenceData?.preferences?.primaryCurrency : '-Not Set-'} />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Number Format'} />
                <KoText className={styles.titleValue} text={preferenceData?.preferences?.numberFormat ? preferenceData?.preferences?.numberFormat : '-Not Set-'} />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Date Format'} />
                <KoText className={styles.titleValue} text={preferenceData?.preferences?.dateFormat ? preferenceData?.preferences?.dateFormat  : '-Not Set-'} />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Time Format'} />
                <KoText className={styles.titleValue} text={preferenceData?.preferences?.timeFormat ? preferenceData?.preferences?.timeFormat : '-Not Set-'} />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Financial Year Begins'} />
                <KoText className={styles.titleValue} text={preferenceData?.preferences?.financialYearBegins ? preferenceData?.preferences?.financialYearBegins : '-Not Set-'} />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Invoice No. Prefix'} />
                <KoText className={styles.titleValue} text={preferenceData?.preferences?.invoiceNoPrefix ? preferenceData?.preferences?.invoiceNoPrefix : '-Not Set-'} />
              </div>
            </div>
          </div>
         
        </div>
  
  
      </KoCard>

      <KoCard hoverable={false} className={styles.tabs}>
        <div className={styles.previewWrapper}>
          <KoDivider orientation="left" orientationMargin="0">
            <KoText className={styles.headingText} text={'Contact Details'} />
          </KoDivider>
          <div className={styles.card}>
            <div className={styles.profileName}>
              <KoAvatar inLine={'0rem'} options={[{ name: userData?.primaryDetails?.firstName }]} size={30}/>
              {userData?.primaryDetails?.firstName && <div className={styles.userInfo}>
                <KoText className={styles.nameText} text={`${userData?.primaryDetails?.firstName} ${userData?.primaryDetails?.lastName}`} />
                <KoText className={styles.designationText} text={userData?.Designation} />
              </div>}
            </div>
            <div className={styles.contacts}>
              <div className={styles.circle}>
                <KoIcon name="PhoneOutlined" height={12} width={12} />
              </div>
              {userData?.contactDetails?.mobile ? <PhoneInput className='mobileNo' disableDropdown readOnly value={userData?.contactDetails?.mobilePhone}/> : '-Not Set-'}
            </div>
            <div className={styles.contacts}>
              <div className={styles.circle}>
                <KoIcon name="SkypeOutlined" height={18} width={18} />
              </div>
              <KoText className={styles.nameText} text={userData?.contactDetails?.skypeId ? userData?.contactDetails?.skypeId : '-Not Set-'} copyable={{
                tooltips: false
              }} />
            </div>
            <KoDivider orientation="left" orientationMargin="0">
              <KoText className={styles.headingText} text={'Work Email'} />
            </KoDivider>
            <div className={styles.contacts}>
              <div className={styles.circle}>
                <KoIcon name="EmailOutlined" height={14} width={14} />
              </div>
              <KoText className={styles.nameText} text={userData?.contactDetails?.email ? userData?.contactDetails?.email : '-Not Set-'} copyable={{
                tooltips: false
              }} />
            </div>
          </div>
          <KoDivider orientation="left" orientationMargin="0">
            <KoText className={styles.headingText} text={'Emergency Contact Details'} />
          </KoDivider>
          <div className={styles.card}>
            <div className={styles.contacts}>
              <div className={styles.circle}>
                <KoIcon name="PhoneOutlined" height={12} width={12} />
              </div>
              {userData?.contactDetails?.emergencyNumber ? <PhoneInput className='mobileNo' disableDropdown readOnly value={userData?.contactDetails?.emergencyNumber}/> : '-Not Set-'}
            </div>
            <div className={styles.contacts}>
              <div className={styles.circle}>
                <KoIcon name="EmailOutlined" height={14} width={14} />
              </div>
              <KoText className={styles.nameText} text={userData?.contactDetails?.personalEmail ? userData?.contactDetails?.personalEmail : '-Not Set-'} copyable={{
                tooltips: false
              }} />
            </div>
          </div>
        
          <KoDivider orientation="left" orientationMargin="0">
            <KoText className={styles.headingText} text={'Bank Details'} />
          </KoDivider>
          <div className={styles.card}>
            <div className={styles.fieldList}>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Account Name'} />
                <KoText className={styles.titleValue} text={`${userData?.bankDetails?.accountHolderName ? userData?.bankDetails?.accountHolderName : '-Not set-'}`} />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Bank Name'} />
                <KoText className={styles.titleValue} text={`${userData?.bankDetails?.bankName ? userData?.bankDetails?.bankName : '-Not set-'}`} />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Branch Name'} />
                <KoText className={styles.titleValue} text={`${userData?.bankDetails?.branchName ? userData?.bankDetails?.branchName : '-Not set-'}`} />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'IFSC Code'} />
                <KoText className={styles.titleValue} text={`${userData?.bankDetails?.ifscCode ? userData?.bankDetails?.ifscCode : '-Not set-'}`} />
              </div>
              <div className={styles.viewMode}>
                <KoText className={styles.title} text={'Account No'} />
                <KoText className={styles.titleValue} text={`${userData?.bankDetails?.accountNumber ? userData?.bankDetails?.accountNumber : '-Not set-'}`} />
              </div>
            </div>
          </div>

        </div>
  
  
      </KoCard>

      <KoModal
        title={`Edit`}
        centered
        open={isOpen}
        handleOk={() => { setModal(false); }}
        onClose={() => { setModal(false); }}
        width={780}
        // maskClosable={false}
        height={650}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setModal(!isOpen)} />,
          <KoButton key='create' type='primary' label={`Update`}
            onClick={() => form.submit()} />
        ]}
      >
        <Crud form={form} handleFinish={handleSubmit} initialValues={userData}/>
      </KoModal>
      <KoModal
        title={`Edit`}
        centered
        open={isAddressOpen}
        handleOk={() => { setAddressModal(false); }}
        onClose={() => { setAddressModal(false); }}
        width={880}
        // maskClosable={false}
        height={650}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setAddressModal(!isAddressOpen)} />,
          <KoButton key='create' type='primary' label={`Update`}
            onClick={() => form.submit()} />
        ]}
      >
        <AddressCrud form={form} handleFinish={handleSubmit} initialValues={userData?.address}/>
      </KoModal>
    </div>
  );
}
