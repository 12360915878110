import React from "react";
import { Select  } from 'antd';
import KoIcon from "packages/icon";

function KoSelect(props) {
  return (
    <Select
      {...props}
      suffixIcon={<KoIcon name='DownArrowOutlined' width={11} height={11}/>}
      maxTagCount='responsive'
      style={{ width: props?.width ? props?.width :'100%' }}
    />
  );
}

export {KoSelect, Select};