/* eslint-disable no-self-assign */
import { getCookie } from "shared/utils/cookies";
import { configs } from 'configuration';
const createRequest = (url, reqConfig) => {
  var headers = Object.assign({
    "Content-Type": "application/json"
  }, reqConfig.header || {});

  if (getCookie("accessToken")) {
    headers["Authorization"] = `Bearer ${getCookie("accessToken")}`;
  }
  
  if(reqConfig.query) {
    url = serializeQueryParams(url, reqConfig.query);
  }

  var requestOption = {
    method: reqConfig.method,
    headers: headers,
    credentials: reqConfig.isCORS ? "include" : "same-origin"
  };

  if(reqConfig.data) {
    requestOption["body"] = (typeof reqConfig.data !== "string") ? JSON.stringify(reqConfig.data) : reqConfig.data;
  } else if(reqConfig.body) {
    requestOption["body"] = reqConfig.body;
  }

  if(reqConfig.signal) {
    requestOption['signal'] = requestOption.signal;
  }

  return new Request(url, requestOption);
};

const serializeQueryParams = (url, params = {}) => {
  var queryString = url.lastIndexOf('?') !== -1 ? `&` : `?`;
  Object.keys(params)
    .forEach((key) => {
      queryString += `${key}=${encodeURIComponent(params[key])}&`;
    });
  return `${url}${queryString}`;
};


const sendRequest = (url, options, fullURL) => {
  let path = url;
  if(!fullURL && (process.env.REACT_APP_ENV === 'prod' || process.env.REACT_APP_ENV === 'dev') ){
    path =  configs().API_HOST+ url;
  }
  const request = createRequest(path, options);
  if(fullURL){
    const promise = fetch(request)
      .then(response => {
        return response.json().then(json => {
          return response.ok ? json : Promise.reject(json);
        });
      });
    return promise;
  }else{
    const promise = fetch(request)
      .then(response => {
        return response.json().then(json => {
          return response.ok ? json : Promise.reject(json);
        });
      });
    return promise;
  }
 
};

const KoAPI = {
  get: (url, options = {}) => {
    options.method = "GET";
    return sendRequest(url, options);
  },

  getURL: (url, options = {}) => {
    options.method = "GET";
    return sendRequest(url, options, true);
  },

  getBulk:(url, options = {})=>{
    return Promise.all(url.map((endpoint) => sendRequest(endpoint, options))).then(response => response);
  },

  post: (url, options = {}) => {
    options.method = options.method || "POST";
    return sendRequest(url, options);
  },

  update: function(url, options = {}) {
    options.method = "PUT";
    return this.post(url, options);
  },

  patch: function(url, options = {}) {
    options.method = "PATCH";
    return sendRequest(url, options);
  },

  delete: (url, options = {}) => {
    options.method = "DELETE";
    return sendRequest(url, options);
  }

 
};

export default KoAPI;
