import {KoAPI} from "packages";
import { configs } from 'configuration';
const version = configs().API_VERSION;
export const InboxAPI = {
  rejectTimesheet(data){
    return KoAPI.update(`/api/${version}/home/ticket/worklog/changeRequest`, {data});
  },
  approveTimesheet(data){
    return KoAPI.update(`/api/${version}/home/ticket/worklog/approved`, {data});
  },
  getTimesheetApprovals(id){
    return KoAPI.get(`/api/${version}/home/timesheet/week/worklog/waiting/${id}`);
  },
  getTimesheetHistoryApproved(id){
    return KoAPI.get(`/api/${version}/home/ticket/worklog/approved/${id}`);
  },

  getTimesheetHistoryRejected(id){
    return KoAPI.get(`/api/${version}/home/ticket/worklog/rejected/${id}`);
  },
  getUsersbyTimeSheetId(id){
    return KoAPI.get(`/api/${version}/home/ticket/worklog/requested/users`);
  },
  getAllTimesheets(id){
    return KoAPI.get(`/api/${version}/home/ticket/user/worklog/${id}`);
  },
  getTimesheetCount(id){
    return KoAPI.get(`/api/${version}/home/ticket/user/worklog/count/${id}`);
  }
};