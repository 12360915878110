import React from "react";
import { Checkbox } from "antd";

function KoCheckBox(props) {
  const {  label, options } =  props;

  return (
    options ? (
      <Checkbox.Group
        options={options}
        {...props}
      ></Checkbox.Group>
    ) : (
      <Checkbox
        {...props} >
        {label}
      </Checkbox>
    )
  );
}

export {KoCheckBox, Checkbox};
