import React from "react";
function Assignee(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 14" fill="none">
      <path d="M5.67851 0.749244C5.61378 0.776985 5.23466 1.13145 4.73225 1.63077L3.89387 2.46915L3.46544 2.04996C3.00001 1.59379 2.8983 1.53522 2.67021 1.57838C2.52226 1.60612 2.33424 1.79105 2.3065 1.939C2.26335 2.17942 2.28493 2.21332 2.99385 2.92533C3.56407 3.49863 3.67503 3.59726 3.77366 3.61267C4.05415 3.66507 4.03566 3.6774 5.18226 2.54004C6.31037 1.41501 6.38126 1.32563 6.34119 1.10371C6.30112 0.881782 6.08537 0.696846 5.87269 0.699928C5.82645 0.70301 5.73707 0.724586 5.67851 0.749244Z" fill="var(--palette-primary-darker)" />
      <path d="M11.3653 0.743115C11.316 0.767773 10.8906 1.16847 10.4252 1.63697L9.58066 2.48768L9.16147 2.07157C8.74536 1.66163 8.60358 1.563 8.43097 1.563C8.30768 1.563 8.11042 1.68629 8.04261 1.80033C7.97171 1.92362 7.96863 2.13938 8.03028 2.25959C8.10117 2.39213 9.28476 3.55723 9.38648 3.5973C9.50977 3.64045 9.67313 3.63737 9.77176 3.58497C9.81799 3.56031 10.3358 3.0579 10.9245 2.4661C11.7722 1.61231 11.9941 1.3719 12.0126 1.29176C12.0927 0.909557 11.7105 0.589001 11.3653 0.743115Z" fill="var(--palette-primary-darker)" />
      <path d="M3.82916 5.00278C3.7182 5.02436 3.49627 5.11066 3.336 5.18772C3.08942 5.31101 3.00003 5.37882 2.76578 5.61307C2.53769 5.84116 2.46063 5.94288 2.35892 6.15555C2.17706 6.52851 2.12466 6.75351 2.12466 7.15729C2.12466 7.56107 2.17706 7.78607 2.35892 8.15903C2.52536 8.50116 2.92914 8.92035 3.25894 9.08987C3.66272 9.29947 3.85382 9.3457 4.32232 9.3457C4.79083 9.3457 4.98193 9.29947 5.38571 9.08987C5.71551 8.92035 6.11929 8.50116 6.28573 8.15903C6.46758 7.78607 6.51998 7.56107 6.51998 7.15729C6.51998 6.75351 6.46758 6.52851 6.28573 6.15555C6.18401 5.94288 6.10696 5.84116 5.87887 5.61307C5.64462 5.37882 5.55523 5.31101 5.30865 5.18772C5.14837 5.11066 4.92028 5.02436 4.80008 4.9997C4.53192 4.94422 4.09424 4.94422 3.82916 5.00278Z" fill="var(--palette-primary-darker)" />
      <path d="M9.57761 4.98421C8.7454 5.1599 8.07346 5.79793 7.86387 6.60857C7.78065 6.93221 7.78065 7.38222 7.86387 7.70586C8.02415 8.31923 8.45566 8.85246 9.00739 9.12062C9.66391 9.44118 10.3358 9.44426 10.9646 9.13295C11.4424 8.89561 11.7537 8.58122 11.9818 8.11272C12.2869 7.48393 12.2869 6.83049 11.9818 6.20171C11.7075 5.63765 11.279 5.26162 10.6872 5.05819C10.4838 4.99038 10.3759 4.97497 10.0708 4.96572C9.86734 4.95955 9.64542 4.9688 9.57761 4.98421Z" fill="var(--palette-primary-darker)" />
      <path d="M2.29111 9.82969C2.12466 10.2242 2.12466 10.2489 2.11234 11.9811L2.10001 13.5993L2.1709 13.738C2.21405 13.8181 2.28494 13.9014 2.34967 13.9384C2.45447 13.9969 2.50687 14 4.32232 14C6.13778 14 6.19018 13.9969 6.29498 13.9384C6.3597 13.9014 6.4306 13.8181 6.47375 13.738L6.54464 13.5993L6.53231 11.9811C6.52306 10.6187 6.51382 10.3321 6.47066 10.178C6.42751 10.0115 6.31039 9.7064 6.27956 9.67249C6.27032 9.66633 6.17785 9.71873 6.07305 9.78654C5.78948 9.97147 5.39495 10.1472 5.07748 10.2273C4.67678 10.3321 3.96786 10.3321 3.56717 10.2273C3.25278 10.1472 2.85516 9.97147 2.57776 9.7927C2.47604 9.72489 2.38357 9.66941 2.37433 9.66941C2.36508 9.66941 2.32809 9.74338 2.29111 9.82969Z" fill="var(--palette-primary-darker)" />
      <path d="M7.97479 9.82969C7.79602 10.2211 7.78986 10.2828 7.78986 12.0428C7.78986 13.6055 7.79294 13.664 7.8515 13.7627C7.88541 13.8181 7.96246 13.8952 8.02103 13.9322C8.12891 14 8.13199 14 9.99368 14C11.803 14 11.8615 13.9969 11.9633 13.9384C12.0218 13.9044 12.0958 13.8366 12.1328 13.7904C12.1975 13.7041 12.1975 13.6733 12.2067 12.1044C12.216 10.403 12.2098 10.3013 12.0619 9.91599C11.9571 9.63859 11.9694 9.64167 11.7198 9.80811C10.9553 10.3167 9.91046 10.4523 9.03201 10.1626C8.72378 10.0609 8.48028 9.94065 8.23987 9.78037C8.15048 9.71873 8.07034 9.66941 8.0611 9.66941C8.05493 9.66941 8.01486 9.74338 7.97479 9.82969Z" fill="var(--palette-primary-darker)" />
    </svg>


  );
}


export default Assignee;