import React, { useState, useRef, useEffect } from "react";
import { KoIcon, KoButton, KoDP, KoBadge, KoModal, KoPreview, KoTable, KoText, KoNano, Upload, KoDriver
  , KoEditorReadOnly,
  KoLoader} from "packages";
import { useSelector } from "react-redux";
import _find from 'lodash/find';
import styles from './chat.module.scss';
import ChatBox from "./chatBox";
import TypingIndicator from "./typingIndicator";
import { ISSUETYPE } from "shared/commonConstants";
import EmojiPicker from "emoji-picker-react";
import { getCookie } from "shared/utils/cookies";
import MessageBox from "./messageBox";
import Driver from "packages/drivers";
import { ChatAPI } from "shared/contacts/chat.service";
import { useParams } from "react-router";
import { useSocket } from "shared/socketContext";
import { useAppStore } from "shared/newstore";
function KoChat({chatBodyCls='', footerCls='' }) {
  let { roomId } = useParams();
  const instance = useSocket();
  const {addMessage, addContactInDMContacts,
    selectedChatType,
    selectedChatMessages, selectedChatData, setSelectedChatMessages} =useAppStore();
  const userById = useSelector(state => state?.userById);
  const userDetails = userById['data'];
  const [currentUser, setCurrentUser] = useState(userDetails);
  const [, setUploadSource] = useState('documents');
  const [openUpload, setOpenUpload] = useState(false);
  const [openAttachmentGallery, setOpenAttachmentGallery] = useState(false);
  const [openLinkGallery, setOpenLinkGallery] = useState(false);
  const [typingUsers, setTypingStatus ] = useState([]);
  const [callback, setCallback] = useState(false);
  const [emoji, setEmoji] = useState();
  const [fileList, setFileList] = useState([]);
  const [activeFile, setActiveFile] = useState();
  const [ updateMessage, setUpdateMessage] = useState();
  const [editMessageModal, setEditMessageModal] = useState(false);
  const [replyMessageModal, setReplyMessageModal] = useState();
  const [loading, setLoading] = useState(false);
  const [prevMessagesLength, setPrevMessagesLength] = useState(selectedChatMessages?.length);
  const chatListRef = useRef(null);
  const endOfMessagesRef = useRef(null);
  const [assignesInfo, setAssignesInfo] = useState();
  const [totalCount, setTotalCount] = useState();
  useEffect(() => {
    if(selectedChatData?.source === 'groups'){
      ChatAPI.getGroupUsers(roomId)
        .then((data) => {
          setAssignesInfo(data);
        })
        .catch((error) => {
          console.error("Error fetching group users:", error);
          setAssignesInfo([]);
        });
    } else if(selectedChatData?.source === 'tickets') {
      ChatAPI.getTicketUsers(roomId)
        .then((data) => {
          setAssignesInfo(data);
        })
        .catch((error) => {
          console.error("Error fetching ticket users:", error);
          setAssignesInfo(null);
        });
    }
    if(roomId){
      getChatHistory(roomId);
    }
   
  }, [selectedChatData]);


  useEffect(() => {
    if (instance) {
      instance?.on("receiveMessage", (updatedMessage) => {
        if (selectedChatType !== undefined &&(selectedChatData?.userId === updatedMessage.sender || selectedChatData.userId === updatedMessage.recipient)) {
          addMessage(updatedMessage);
        }
        addContactInDMContacts(updatedMessage);
      });

      instance?.on("typing", ({ userId, status }) => {
        setTypingStatus(`${userId} is ${status ? "typing..." : ""}`);
      });

      instance?.on("editMessage", (updatedMessage) => {
        // messageUpdate && messageUpdate(updatedMessage, 'upadte');
      });

      instance?.on("replyMessage", (updatedMessage) => {
        // messageUpdate && messageUpdate(updatedMessage, 'upadte');
      });

      instance?.on("deleteMessage", (deletedMessageId) => {
        // messageUpdate && messageUpdate(deletedMessageId, 'delete');
      });

      instance?.on("reactions", (message) => {
        // messageUpdate && messageUpdate(message, 'upadte');
      });


      return () => {
        instance.off("receiveMessage");
        instance.off("typing");
        instance.off("editMessage");
        instance.off("replyMessage");
        instance.off("deleteMessage");
        instance.off("reactions");
      };
    }
  }, [instance]);


  
  
  
  

  // const onMessageReceived = (receivedMessage) => {
  //   messageUpdate(receivedMessage, receivedMessage?.action);
  // };

  // const onRoomsRecived =(rooms)=>{
  //   dispatch(setRooms(rooms));
  // };


  const getChatHistory = async(roomId) => {
    try {
      setLoading(true);
      const data = await ChatAPI.getChatHistory(roomId);
      setSelectedChatMessages(data?.messages);
      setTotalCount(data?.totalCount);
    } catch (error) {
      console.error('Error fetching chat history:', error, totalCount);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    setCurrentUser(userDetails);
  }, [userDetails]);


  
  const handleSendMessage = (message, user, messageId, attachments) => {
    const msId = messageId ? messageId : KoNano('msg');
    const newMessage = {
      attachments: attachments ? attachments : null,
      content: message,
      sender: user,
      recipient: roomId,
      dateTime: new Date().toISOString(),
      isOwnMessage: currentUser?.userId === user,
      type: 'text',
      messageId: msId,
      roomId: roomId,
      source:selectedChatData?.source,
      reference: `${getCookie('accountId')}/messages/${roomId}/${msId}/`,
      profile: currentUser?.profile
    };
    instance?.emit("sendMessage", newMessage);
    setCallback(false);
    setOpenUpload(false);
    setFileList([]);
    setEditMessageModal(false);
  };

  const handleEditMessage = (message, user, messageId, attachments) => {
    const msId = messageId;
    const newMessage = {
      attachments: attachments ? attachments : null,
      content: message,
      sender: user,
      recipient: roomId,
      dateTime: new Date().toISOString(),
      isOwnMessage: currentUser?.userId === user,
      type: 'text',
      roomId: roomId,
      messageId: msId,
      source:selectedChatData?.source,
      reference: `${getCookie('accountId')}/messages/${roomId}/${msId}/`,
      profile: currentUser?.profile
    };
    instance?.emit("editMessage", newMessage);
    setCallback(false);
    setOpenUpload(false);
    setFileList([]);
    setEditMessageModal(false);
  };


  const handleReplyMessage = (message, user, messageId, attachments) => {
    const msId = KoNano('msg');
    const parentContent = selectedChatMessages?.find(message => message?.messageId === messageId);
    const newMessage = {
      attachments: attachments ? attachments : null,
      content: message,
      sender: user,
      recipient: roomId,
      dateTime: new Date().toISOString(),
      isOwnMessage: currentUser?.userId === user,
      type: 'text',
      roomId: roomId,
      messageId: msId,
      source:selectedChatData?.source,
      parentMessageId: messageId,
      parentContent: parentContent,
      reference: `${getCookie('accountId')}/messages/${roomId}/${msId}/`,
      profile: currentUser?.profile
    };
    instance?.emit("replyMessage", newMessage);
    setCallback(false);
    setOpenUpload(false);
    setFileList([]);
    setReplyMessageModal(false);
  };

  useEffect(() => {
    if (chatListRef.current) {
      if (selectedChatMessages.length > prevMessagesLength) {
        setTimeout(() => {
          chatListRef.current.scrollTop = chatListRef?.current?.scrollHeight;
        }, 0);
      }
    }
    
    setPrevMessagesLength(selectedChatMessages.length);
  }, [selectedChatMessages]);
  
  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [selectedChatMessages]);

  const handleSendClick = () => {
    setCallback(true);
  };


  const onMessageUpdate = (data, action) => {
    setUpdateMessage(data);
    if (action === 'reply') {
      setReplyMessageModal(true);
    }else{
      setEditMessageModal(true);
    }
   
  };
  
  const handleEditSendMessage = () => {
    handleEditMessage(JSON.parse(localStorage.getItem('message')),
      currentUser?.userId, updateMessage?.messageId, updateMessage?.attachments);
  };

  const handleReplySendMessage = () => {
    handleReplyMessage(JSON.parse(localStorage.getItem('message')),
      currentUser?.userId, updateMessage?.messageId, updateMessage?.attachments);
  };

  const handleDeleteMessage = (messageId) => {
    instance?.emit("deleteMessage", messageId);
  };

  const handleSendReaction = (messageId, reaction, currentUserId) => {
    let messageObj = {
      messageId: messageId,
      reactions: reaction,
      userId: currentUserId
    };
    instance?.emit("reactions", messageObj);
  };

  const handleSendMessageWithAttachment = (message, user) => {
    let messageId = KoNano('msg');
    setLoading(true);
    let attachments = [];
    const formData = new FormData();
    fileList.forEach((file) => {
      attachments.push({
        name: file.name,
        size: file.size,
        type: file.type,
        uid: file.uid,
        lastModifiedDate: file.lastModifiedDate

      });
      formData.append('files', file);
    });
    
    formData.append('service', roomId);
    formData.append('module', 'messages');
    formData.append('lookupId', messageId);
    
    fetch(`${process.env.REACT_APP_PROXY_HOST}/api/v1/org/upload`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getCookie("accessToken")}`
      },
      body: formData
    })
      .then((res) => res.json())
      .then((data) => {
        setCallback(true);
        setLoading(false);
        setOpenUpload(false);
        const attachmentsList = attachments.map((item, index) => ({
          ...item,
          url: data[index] || null
        }));
        handleSendMessage(JSON.parse(localStorage.getItem('message')), currentUser?.userId, messageId, attachmentsList);
      })
      .catch(() => {
        console.error('upload failed.');
      })
      .finally(() => {
      });
  };


  const handleTyping = (isTyping) => {
    instance?.emit("typing", {roomId: isTyping, isTyping: 'typing'});
  };

  const props = {
    beforeUpload: (file) => {
      setActiveFile(file);
      let fList =fileList;
      fList.push(file);
      setFileList(fList);
      setOpenUpload(true);
      return false;
    },
    fileList,
    multiple: true
  };


  const onRemoveFile= (file, index) => {
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
    setActiveFile(newFileList[0]);
  };

  const getTempUrl = (file) => {
    return URL.createObjectURL(file);
  };

  const uploadButton = (
    <div className={styles.dndArea}>
      <KoIcon name='PlusOutlined' width={25} height={25} />
    </div>
  );


  const openDropArea = ({ key }) => {
    setUploadSource(key);
    setOpenUpload(true);
  };

  const columnsRight = [{
    title: '',
    dataIndex: 'x',
    key: 'x',
    fixed: 'right',
    width: 40,
    render: (text, row) => (
      <div className={'columnsRight'}>
        <KoIcon name="OpenOutlined" onClick={() => (row)} />
      </div>
    )
  }];

  const getIcon = (type) => {
    const issue = _find(ISSUETYPE, o => o.value === type);
    return issue?.icon;
  };


  const onEmojiClick = (emojiObject) => {
    setEmoji(emojiObject?.unified);
  };

  const emojiContent = (
    <div className={styles.popContent}>
      <EmojiPicker
        theme="auto"
        height={450}
        width={370}
        emojiStyle="native"
        onEmojiClick={onEmojiClick}
        previewConfig={{ showPreview: false }}
        allowExpandReactions={false}
        suggestedEmojisMode='recent'
        skinTonesDisabled
      />
    </div>
  );
  return (
    <>
      {loading&& <KoLoader/>}
      <div className={`${styles.wrapper}`}>
        <div className={styles.profileHeader}>
          {selectedChatData?.source === 'users' && (
            <div className={styles.profile}>
              <KoBadge
                size={'default'}
                offset={[-8, 33]}
                dot
                color={selectedChatData?.isOnline ? 'var(--palette-error-main)' : 'var(--palette-success-main)'}
              >
                <div className={styles.circle}>
                  {selectedChatData?.profile?.logo
                    ? <KoDP fallback='UserFilled' className={styles.icon} width={35} height={35} logo={selectedChatData?.profile?.logo} />
                    : <KoIcon name='UserFilled' className={styles.icon} width={35} height={35} />}
                </div>
              </KoBadge>
              <div className={styles.taskInfo}>
                <KoText className={styles.name} text={selectedChatData.profile?.firstName + ' ' + selectedChatData.profile?.lastName} />

                {typingUsers.length > 0 && (
                  <div className={styles.typingIndicator}>
                    {typingUsers.map((userId) => (
                      <TypingIndicator key={userId} sender={userId} />
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
          {selectedChatData?.source === 'groups' && <div className={styles.profile}>
            <KoBadge size={'default'} offset={[-8, 33]} dot={false}
              color={!selectedChatData?.isOnline ? `var(--palette-success-main)` : `var(--palette-error-main)`} >
              <div className={styles.circle}>
                {selectedChatData?.logo?.length > 0 ? <KoDP fallback='GrpupsFilled' className={styles.icon} width={35} height={35} logo={selectedChatData?.logo} /> :
                  <KoIcon name='GrpupsFilled' className={styles.icon} width={35} height={35} />}
              </div>
            </KoBadge>
            <div className={styles.taskInfo}>
              <div className={styles.groupName}>
                <KoText text={`${selectedChatData?.name}`} className={styles.name} />
                <KoText text={`(Group . ${assignesInfo?.totalCount} peoples)`} className={styles.count} />
              </div>
              <div  className={styles.assignees}>
                {assignesInfo?.members?.map((member, i) => (
                  <KoText key={i} ellipsis={true} text={`${member.profile.firstName} ${member.profile.lastName}${i === assignesInfo?.members?.length-1 ? '':','}`}
                    className={styles.userName} />
                ))}
              </div>
              
            </div>
          </div>}
          {selectedChatData?.source === 'tickets' && <div className={styles.profile}>
            <KoBadge size={'default'} offset={[-8, 33]} dot={false}
              color={!selectedChatData?.isOnline ? `var(--palette-success-main)` : `var(--palette-error-main)`} >
              <div className={styles.circle}>
                {getIcon(selectedChatData?.issueType)}
              </div>
            </KoBadge>
            <div className={styles.taskInfo}>
              <div className={styles.groupName}>
                <KoText text={`${selectedChatData?.ticketId}`} className={styles.name} />
                <KoText text={`(Watching . ${assignesInfo?.totalCount} peoples)`} className={styles.count} />
              </div>
              <div  className={styles.assignees}>
                {assignesInfo?.members?.map((member, i) => (
                  <KoText key={i} ellipsis={true} text={`${member.profile.firstName} ${member.profile.lastName}${i === assignesInfo?.members?.length-1 ? '':','}`}
                    className={styles.userName} />
                ))}
              </div>
              
            </div>
          </div>}
          {selectedChatData?.source !== 'tickets' && <div className={styles.options}>
            <KoButton iconBtn={true} shape='circle' icon={<KoIcon width={16} height={16} name="PhoneOutlined" />} />
            <KoButton onClick={() => setOpenAttachmentGallery(true)} iconBtn={true} shape='circle' icon={<KoIcon width={16} height={16} name="ImagesOutlined" />} />
            <KoButton onClick={() => setOpenLinkGallery(true)} iconBtn={true} shape='circle' icon={<KoIcon width={16} height={16} name="LinkOutlined" />} />
          </div>}
        </div>
        <div className={`${styles.chatContent} ${chatBodyCls}`} ref={chatListRef}>
          <ChatBox
            updateMessage={onMessageUpdate}
            currentUser={currentUser}
            deleteMessage={handleDeleteMessage}
            handleSendReaction={handleSendReaction}
            users={assignesInfo?.members}
            source={selectedChatData?.source}
            messages={selectedChatMessages} />
          <div ref={endOfMessagesRef} />
        </div>
        <MessageBox
          callback={callback}
          footerCls={footerCls}
          emojiContent={emojiContent}
          emoji={emoji}
          openDropArea={openDropArea}
          handleSendClick={handleSendClick}
          currentUser={currentUser}
          handleSendMessage={handleSendMessage}
          handleTyping={handleTyping}
          uploadProps={{...props}}
        />
        <KoModal title="Photos and videos" open={openAttachmentGallery} onClose={() => setOpenAttachmentGallery(false)}>
          <KoPreview width="400px" height="500px" />
        </KoModal>
        <KoModal title="Links" open={openLinkGallery} onClose={() => setOpenLinkGallery(false)}>
          <KoTable columns={columnsRight} />
        </KoModal>
        <KoModal
          title="Edit message"
          open={editMessageModal}
          centered
          onClose={() => setEditMessageModal(false)}>
          <div className={styles.editMessage}>
            <div className={styles.editMessageCard}>
              {updateMessage?.attachments?.length >0 && <div className={styles.thumbnailContainer}>
                {updateMessage?.attachments?.map((file, index) => (
                  <div
                    key={index}
                    className={`${styles.thumbnail}`}>
                    <Driver fileType={file?.type} filePath={file?.url} />
                  </div>
                ))}
              </div>}
              {updateMessage?.content&&  <KoEditorReadOnly text='-' initialValue={updateMessage?.content} />}
            </div>
            {updateMessage?.content&&   <MessageBox
              callback={callback}
              footerCls={styles.editMsgfooterCls}
              emojiContent={emojiContent}
              emoji={emoji}
              updateMessage={updateMessage}
              openDropArea={openDropArea}
              handleSendClick={handleEditSendMessage}
              currentUser={currentUser}
              handleSendMessage={handleEditSendMessage}
              handleTyping={handleTyping}
              uploadProps={{...props}}
              fileList={fileList}
            />}
          </div>
        </KoModal>



        <KoModal
          title="Reply message"
          open={replyMessageModal}
          centered
          onClose={() => setReplyMessageModal(false)}>
          <div className={styles.editMessage}>
            <div className={styles.editMessageCard}>
              {updateMessage?.attachments?.length >0 && <div className={styles.thumbnailContainer}>
                {updateMessage?.attachments?.map((file, index) => (
                  <div
                    key={index}
                    className={`${styles.thumbnail}`}>
                    <Driver fileType={file?.type} filePath={file?.url} />
                  </div>
                ))}
              </div>}
              {updateMessage?.content&&  <KoEditorReadOnly text='-' initialValue={updateMessage?.content} />}
            </div>
            {updateMessage?.content&&   <MessageBox
              callback={callback}
              footerCls={styles.editMsgfooterCls}
              emojiContent={emojiContent}
              emoji={emoji}
              replyMessage={updateMessage}
              openDropArea={openDropArea}
              handleSendClick={handleReplySendMessage}
              currentUser={currentUser}
              handleSendMessage={handleReplySendMessage}
              handleTyping={handleTyping}
              uploadProps={{...props}}
              fileList={fileList}
            />}
          </div>
        </KoModal>

        <KoModal
          title="Attachments"
          open={openUpload}
          centered
          width="70%"
        
          onClose={() => {setOpenUpload(false); setFileList([]);}}>
          <div className={styles.attachments}>
            { loading&& <KoLoader />}
            <div className={styles.attachmentsBody}>
              <div className={styles.attachment}>
                {activeFile&& <KoDriver fileType={activeFile?.type} filePath={getTempUrl(activeFile)} />}
              </div>
            </div>
            <div className={styles.uploadWrapper}>
              <div className={styles.uploadArea}>
                <div className={styles.thumbnails}>
                  {fileList.map((file, index) => (
                    <div className={`${styles.attachment} ${file?.name === activeFile?.name && styles.active}`} onClick={() => setActiveFile(file)} key={index}>
                      <KoDriver action={false} fileType={file?.type} filePath={ getTempUrl(file)} />
                      <KoButton
                        onClick={() => onRemoveFile(file, index)}
                        type='primary' className={styles.remove} iconBtn shape='circle'
                        icon={<KoIcon width={6} height={6} name="CloseOutlined" />}  />
                    </div>
                  ))}
                </div>
                <Upload
                  {...props}
                  listType="picture-card"
                  fileList={fileList}
                  showUploadList={false}
                  multiple={true}
                  className="smallupload"
                >
                  {fileList.length >= 8 ? null : uploadButton}
                </Upload>
              </div>
            </div>
            <MessageBox
              callback={callback}
              footerCls={styles.afooterCls}
              emojiContent={emojiContent}
              emoji={emoji}
              openDropArea={openDropArea}
              handleSendClick={handleSendMessageWithAttachment}
              currentUser={currentUser}
              handleSendMessage={handleSendMessage}
              handleTyping={handleTyping}
              uploadProps={{...props}}
              fileList={fileList}
              attachmentHide={true}
            />
          </div>
        </KoModal>
      
      </div>
    </>
  );
}

export default KoChat;
