import React, {useState, useEffect} from 'react';
import { Droppable } from 'react-beautiful-dnd';

function withDroppable(Component) {

  return function WrapperComponent({ children, ...droppableProps }) {
    const [enabled, setEnabled] = useState(false);
    useEffect(() => {
      const animation = requestAnimationFrame(() => setEnabled(true));
  
      return () => {
        cancelAnimationFrame(animation);
        setEnabled(false);
      };
    }, []);
  
    if (!enabled) {
      return null;
    }
    return (
      <Droppable className="droppable" {...droppableProps}>
        {(provided) => (
          <Component ref={provided.innerRef} {...provided.droppableProps}>
            {children}
            {provided.placeholder}
          </Component>
        )}
      </Droppable>
    );
  };
}

export default withDroppable;
