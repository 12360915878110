import { KoDate, KoForm, KoFormBuilder, KoIcon, KoInput, KoSelect, KoTextArea } from 'packages';
import { useCallback, useEffect } from 'react';
import { SPRINTSTATUS } from '../../../shared/commonConstants';
import styles from './curd.module.scss';
import SprintGoalCrud from './sprintGoal';
import SprintLeavePlan from './sprintLeavePlan';
function SprintCrud({ form, handleFinish, initialValues , profile}) {
  useEffect(() => {
    if (!initialValues || Object.keys(initialValues).length === 0) {
      form.resetFields();
    } else {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  const handleSubmit = useCallback(values => {
    handleFinish(values);
  });


  const StatusType = ({ value, onChange }) =>
    <KoSelect value={value}
      defaultValue={1}
      onChange={v => onChange(v)}
      showSearch
      placeholder='Select issue type'
      options={SPRINTSTATUS}
      optionRender={(option) => (
        <div className={styles.iconWithLabel} role="img" aria-label={option.data.label}>
          {option.data.value === 1 && <KoIcon name="ActiveSprintOutlined" />}
          {option.data.value === 2 && <KoIcon name="ActiveSprintOutlined" />}
          {option.data.value === 3 && <KoIcon name="LastSprintOutlined" />}
          {option.data.value === 4 && <KoIcon name="ClosedSprintOutlined" />}
          {option.data.value === 5 && <KoIcon name="ActiveSprintOutlined" />}
          {option.data.label}
        </div>
      )}
    />;

  const handleEntryFinish = (values) => {
    handleSubmit(values);
  };

  const ItemTable = ({ value, onChange }) =>
    <SprintGoalCrud form={form} initialValues={initialValues} handleFinish={handleEntryFinish} />;

  const ItemSLPTable = ({ value, onChange }) =>
    <SprintLeavePlan form={form} initialValues={initialValues} handleFinish={handleEntryFinish} profile={profile}/>;
    

  const Date = ({ value, onChange }) =>
    <KoDate value={value} dateFormat = {'DD-MMM-YYYY'}
      onChange={v => onChange(v)} daysAgo={0}/>;

  const meta = {
    columns: 4,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      {
        key: 'name', placeholder: 'Enter sprint name', label: 'Sprint Name', colSpan: 2,
        widget: ({ value, onChange }) => (
          <KoForm.Item>
            <KoInput
              value={value}
              onChange={onChange}
              disabled={initialValues?.name}
              placeholder="Enter sprint name"
            />
          </KoForm.Item>
        ),
        rules: [
          {
            required: true,
            message: `Please enter summary.`,
            disable: true
          }
        ]
      },
      {
        key: 'status', label: 'Sprint status', colSpan: 2,
        widget: StatusType,
        forwardRef: true
      },
      {
        key: 'startDate', label: 'Start date', colSpan: 2,
        widget: Date
      },
     
      {
        key: 'endDate', label: 'End date', colSpan: 2,
        widget: Date
      },
      {
        key: 'sprintGoal', label: 'Sprint Goal', colSpan: 4,
        widget: ItemTable,
        forwardRef: true
      },
      {
        key: 'leavePlan', label: 'Leave Plan', colSpan: 4,
        widget: ItemSLPTable,
        forwardRef: true
      },
      {
        key: 'description', widget: KoTextArea, label: 'Description', colSpan: 4, placeholder: 'Enter project description'
      }

    ]
  };


  return (
    <div className={styles.wrapper}>
      <KoForm form={form} layout="vertical" onFinish={handleSubmit}
        onSubmit={handleSubmit}>
        <KoFormBuilder form={form} meta={meta} />
      </KoForm>
    </div>
  );
};

export default SprintCrud;