import React from "react";
import { Input  } from 'antd';

function KoPassword(props) {
  return (
    <Input.Password
      {...props}
      style={{ width: '100%' }}
    />
  );
}

export default KoPassword;