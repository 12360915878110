import { KoForm, KoFormBuilder, KoSelect, KoTextArea, KoUserSelect } from 'packages';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import styles from './curd.module.scss';
import { ApiClientAPI } from '../apiClient.service';

function CollectionCrud({ form, handleFinish, initialValues }) {
  const [environments, setEnvironments] = useState();
  const wosAssignees = useSelector(state => state.wosAssignees);
  const groups = useSelector(state => state.groups);

  useEffect(() => {
    getEnvironments();
  },[]);

  const handleSubmit = useCallback(values => {
    handleFinish(values);
  });


  const getEnvironments = () => {
    ApiClientAPI.getEnvironments().then(
      (data) => {
        setEnvironments(data['environments']);
      },
      (err) => {
      }
    );
  };

  const Environments = ({ value, onChange }) =>
    <KoSelect value={value}
      onChange={v => onChange(v)}
      showSearch
      placeholder='Select Environment'
      options={environments?.map((env) => ({
        label: env?.name,
        value: env?.environmentId
      }))}
    />;

  const Members = ({ value, onChange }) =>
    <KoUserSelect value={value} onChange={v => onChange(v)} users={wosAssignees[0]} placeholder='Select author name' mode="multiple"/>;
  
  
  const Groups = ({ value, onChange }) =>
    <KoSelect mode="multiple" value={value}
      onChange={v => onChange(v)}
      showSearch
      placeholder='Choose assignee groups'
      options={groups['data']?.map((group) => ({
        label: group.name,
        value: group?.groupId
      }))}
    />;

    
  const Assignees = ({ value, onChange }) =>
    <KoUserSelect value={value} onChange={v => onChange(v)} users={wosAssignees[0]} placeholder='Select assignees' mode="multiple"/>;
 


  const meta = {
    columns: 4,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      { key: 'name', placeholder: 'Enter collection name', label: 'Name', colSpan: 2, rules: [
        {
          required: true,
          message: `Enter new collection name.`
        }
      ] },
      
      {
        key: 'author', label: 'Author', colSpan: 2,
        widget: Members,
        forwardRef: true,
        rules: [
          {
            required: true,
            message: `Select Author!`
          }
        ]
      },
      {
        key: 'assignees', label: 'Assignees', colSpan: 2, widget: Assignees, forwardRef: true
      },
      {
        key: 'groups', label: 'Assign to groups', colSpan: 2,
        widget: Groups,
        forwardRef: true
      },
      {
        key: 'environment', label: 'Environment', colSpan: 2, widget: Environments
      },
      {
        key: 'description', widget: KoTextArea, label: 'Description', colSpan: 4, placeholder: 'Enter project description'
      }
     
    ]
  };

  return (
    <div className={styles.wrapper}>
      <KoForm form={form} layout="vertical" onFinish={handleSubmit} onSubmit={handleSubmit}>
        <KoFormBuilder form={form} meta={meta} />
      </KoForm>
    </div>
  );
};

export default CollectionCrud;