import React, { useState, useEffect } from 'react';
import * as THREE from 'three';
import PhotoViewer from '../photo';
import Photo360Viewer from '../photo360';
import KoLoader from 'packages/loader';

const getPhotoDriver = (width, height, fileType) => {
  if ((fileType === 'jpg' || fileType === 'jpeg' || fileType === 'png' || fileType === 'gif') && Math.abs((width / height) - 2) <= 0.01) {
    return Photo360Viewer;
  }
  return PhotoViewer;
};

const PhotoViewerWrapper = (props) => {
  const [state, setState] = useState({
    originalWidth: 0,
    originalHeight: 0,
    imageLoaded: false,
    texture: null
  });

  useEffect(() => {
    const loadPhoto = () => {
      setState({
        ...state,
        imageLoaded: false
      });

      const urlToLoad = props.filePath;
      const loader = new THREE.TextureLoader();
      loader.crossOrigin = null; // This allows for cross-origin requests

      loader.load(
        urlToLoad,
        (texture) => {
          // If the filePath prop changed since we started loading,
          // we need to abort because the new image will have already started loading.
          if (props.filePath !== urlToLoad) {
            return;
          }

          setState({
            originalWidth: texture.image.width,
            originalHeight: texture.image.height,
            imageLoaded: true,
            texture
          });
        },
        (xhr) => {
        },
        (xhr) => {
        }
      );
    };

    loadPhoto();
  }, [props.filePath]);

  if (!state.imageLoaded) {

    const loaderWrapper ={
      "position" : "absolute",
      "top": "-1.5rem"
    };

    return <div style={loaderWrapper}>
      <KoLoader size={'small'}/>
    </div>;
  }

  const { originalWidth, originalHeight } = state;
  const PhotoDriver = getPhotoDriver(originalWidth, originalHeight, props.fileType);

  return (
    <PhotoDriver {...state} {...props} />
  );
};

export default PhotoViewerWrapper;
