import React from "react";
import _find from 'lodash/find';
import styles from './ticketLink.module.scss';
import { KoText } from "packages/typography";
import KoIcon from "../../../icon";
import KoButton from "packages/button";

function TicketLink(props) {
  const { data, row, onCellClick, onExtraClick } = props;

  const issueTypes = [
    { label: 'Epic', value: 1 },
    { label: 'Story', value: 2 },
    { label: 'Task', value: 3 },
    { label: 'Subtask', value: 4 },
    { label: 'Bug', value: 5 },
    { label: 'Improvements', value: 6 },
    { label: 'Feature request', icon: 'FeatureRequestFilled', value: 7 },
    { label: 'Change request', icon: 'ChangerequestFilled', value: 8 },
    { label: 'Support', value: 9 }
  ];

  const getIcons = () => {
    if (!row?.issueType) return [];
    
    const issueTypeValues = Array.isArray(row.issueType) ? row.issueType : [row.issueType];
    
    return issueTypeValues.map(value => {
      const issueType = _find(issueTypes, o => o.value === value);
      return issueType ? (issueType.icon ? issueType.icon : issueType.label + 'Filled') : '';
    });
  };

  const icons = getIcons();

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper} onClick={() => onCellClick&& onCellClick(row)}>
        {Array.isArray(data) && data.length > 0 ? (
          data.map((e, index) => (
            <div key={index} className={styles.issueWrapper}>
              {icons[index % icons.length] && (
                <KoIcon
                  className={styles.icon}
                  name={icons[index % icons.length]}
                  width={14}
                  height={14}
                />
              )}
              <KoText className={styles.text} text={e} />
            </div>
          ))
        ) : (
          <>
            {icons.length > 0 && (
              <>
                <KoIcon
                  className={styles.icon}
                  name={icons[0]}
                  width={14}
                  height={14}
                />
              </>
            )}
            <KoText className={styles.text} text={data} />
          </>
        )}
      </div>
      {row?.subTask &&
      <KoButton onClick={() => onExtraClick && onExtraClick(row)}
        className={styles.btn} iconBtn={true} type={'text'}
        icon={<KoIcon className={styles.icon} name={'TreeOutlined'} width={14} height={14} />}/>
      }
      {(row?.logs?.length > 0 && data) &&
      <KoButton onClick={() => onExtraClick && onExtraClick(row)}
        className={styles.btn} iconBtn={true} type={'text'}
        icon={<KoIcon className={styles.icon} name={'LogOutlined'} width={12} height={12} />}/>
      }
    </div>
  );
}

export default TicketLink;
