import React from "react";
function Edit(props) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width={width}
      height={height}
    >
      <path
        fill="none"
        stroke="var(--palette-gray-1)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M20 6l4 4m-7 7l-5 1 1-5 8.94-8.94a1.49 1.49 0 012.12 0l1.88 1.88a1.49 1.49 0 010 2.12z"
        className="colorStroke000 svgStroke"
      />
      <path
        fill="none"
        stroke="var(--palette-gray-1)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M13 6H6.5A2.5 2.5 0 004 8.5v15A2.5 2.5 0 006.5 26h15a2.5 2.5 0 002.5-2.5V17"
        className="colorStroke000 svgStroke"
      />
    </svg>
  );
}


export default Edit;