import React from "react";
import { Menu } from 'antd';

function KoMenu(props) {
  return (
    <Menu
      {...props}
    />
  );
}

export default KoMenu;