import React, { useEffect, useState } from 'react';
import { accountAPI } from '../account.service';
import styles from './dashbord.module.scss';
import { KoCard, KoFlex, KoImage, KoText, KoTitle, KoLoader, KoAccordion, KoIcon, KoDescription } from 'packages';

export default function AccountDashboard() {
  const [accounts, setAccounts] = useState([]);
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    getAccounts();
  }, []);

  const getAccounts = () => {
    setLoader(true);
    accountAPI.getAccounts().then((data) => {
      setAccounts(data['accounts']);
      setLoader(false);
    });
  };

  const calculateUserStats =(data)=> {
    let totalActiveUsers = 0;
    let totalInactiveUsers = 0;
    let totalUsers = 0;

    data.forEach(account => {
      totalActiveUsers += account.activeUsers || 0;
      totalInactiveUsers += account.inactiveUsers || 0;
      totalUsers += account.userCount || 0;
    });

    return {
      totalActiveUsers,
      totalInactiveUsers,
      totalUsers
    };
  };

  const stats = calculateUserStats(accounts);

  const panelStyle = {
    marginBottom: 2,
    background: '#fff',
    border: 'none',
    borderRadius: 1
  };


  const HEADER_OPTIONS = [{
    label: <div className={styles.header}>
      <div className={styles.circle}>
        <KoIcon name="BusinessFilled" height={12} width={12} />
      </div>
      <div className={styles.title}>
        <KoTitle className={styles.title} level={5} text="Business Information" />
        <KoDescription className={styles.description} text="Details about your business information" />
      </div>
    </div>,
    children: <div>aadsasd</div>,
    key: 1,
    style: panelStyle
  }];
  return (
    <div className={styles.wrapper}>
      {loader&& <KoLoader />}
      <KoTitle text={`Customer information`} level={3} />
      <KoFlex wrap="wrap" gap="small" className={styles.stats}>
        <div className={styles.count}>
          <KoText text={'Total Customers'}/>
          <KoText className={`${styles.squre} ${styles.c5}`} text={accounts?.length}/>
        </div>
        <div className={styles.count}>
          <KoText text={'Total Users'}/>
          <KoText className={`${styles.squre} ${styles.c3}`} text={stats?.totalUsers}/>
        </div>
        <div className={styles.count}>
          <KoText text={'Total Active Users'}/>
          <KoText className={`${styles.squre} ${styles.c1}`} text={stats?.totalActiveUsers}/>
        </div>
        <div className={styles.count}>
          <KoText text={'Total Inactive Users'}/>
          <KoText className={`${styles.squre} ${styles.c2}`} text={stats?.totalInactiveUsers}/>
        </div>
      
      </KoFlex>

      <KoAccordion defaultActiveKey={[1]} expandIconPosition={'end'} data={HEADER_OPTIONS} bordered={false} />
      <KoFlex wrap="wrap" gap="small" className={styles.accountsList}>
        {accounts?.map((account, i) =>
          <KoCard className={styles.card}
            key={account?.accountId}
            hoverable={true}>
            <div className={styles.aLink}>
              <div >
                <div className={styles.titleKey}>
                  <KoImage
                    src={`${account?.logo ?  account?.logo[0]?.url : ''}`}
                    width={35}
                    preview={false}
                    alt={`doc${i % 3}`}
                  />
                  <div className={styles.titleValue}>
                    <div className={styles.verified}>
                      <KoText className={`${styles.text}`} text={account?.domain} />
                    </div>
                    <KoTitle className={styles.title} ellipsis={true} text={account?.companyName} level={5} />
                  </div>
                </div>
                <KoText className={`${styles.key} ${!account?.color && styles[account?.key ? account?.key?.charAt(0).toLowerCase() : 'a']}`}
                  text={`Trail`} />
                <div className={styles.description} >
                  <KoText className={styles.active} text={`Active Users ${account?.activeUsers ? account?.activeUsers : '0'}`} ellipsis={true} />
                  <KoText className={styles.inactive} text={`Inactive Users ${account?.inactiveUsers ? account?.inactiveUsers : '0'}`} ellipsis={true} />
                </div>
              </div>
            </div>
          </KoCard>
        )}
      </KoFlex>
    </div>
  );
}
