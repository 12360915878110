import React from "react";
function Goal(props) {
  const { width, height } = props;
  return (<svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    width={width}
    height={height}
  >
    <path
      d="M22.94 47.5c12.37 0 22.44-10.07 22.44-22.44 0-3.43-.78-6.75-2.31-9.87-.24-.49-.84-.7-1.34-.46a1 1 0 0 0-.46 1.34c1.4 2.84 2.11 5.87 2.11 8.99 0 11.27-9.17 20.44-20.44 20.44S2.5 36.33 2.5 25.06 11.67 4.62 22.94 4.62c3.12 0 6.15.71 8.99 2.11.5.24 1.1.04 1.34-.46s.04-1.09-.46-1.34c-3.12-1.53-6.44-2.31-9.87-2.31C10.57 2.62.5 12.69.5 25.06S10.57 47.5 22.94 47.5z"
      fill="#000000"
      className="color000 svgShape"
    />
    <path
      d="M40.58 13.93c.26 0 .52-.11.71-.29l5.92-5.92c.29-.29.37-.72.22-1.09A1.02 1.02 0 0 0 46.5 6H42V1.5c0-.4-.24-.77-.62-.92-.37-.15-.8-.07-1.09.22l-5.92 5.92c-.19.19-.3.45-.29.72l.06 5.02-11.9 11.9a.996.996 0 0 0 .71 1.7c.26 0 .51-.1.71-.29l11.9-11.9 5.02.06c-.01 0-.01 0 0 0zm-4.46-2.05-.04-4.04L40 3.91V7c0 .55.45 1 1 1h3.09l-3.92 3.92-4.05-.04z"
      fill="#000000"
      className="color000 svgShape"
    />
    <path
      d="M24.33 20.07a.997.997 0 1 0 .54-1.92c-.64-.18-1.29-.27-1.93-.27-3.97 0-7.19 3.23-7.19 7.19s3.23 7.19 7.19 7.19c3.97 0 7.19-3.23 7.19-7.19 0-.64-.09-1.29-.27-1.93a.997.997 0 1 0-1.92.54c.13.47.2.94.2 1.4 0 2.86-2.33 5.19-5.19 5.19s-5.19-2.33-5.19-5.19c-.02-3.34 3.18-5.96 6.57-5.01z"
      fill="#000000"
      className="color000 svgShape"
    />
    <path
      d="M31.15 13.93c.3-.47.16-1.08-.31-1.38a14.737 14.737 0 0 0-7.91-2.31c-8.17 0-14.81 6.65-14.81 14.81 0 8.17 6.65 14.82 14.81 14.82s14.82-6.65 14.82-14.82c0-2.79-.8-5.53-2.31-7.91-.3-.47-.92-.6-1.38-.31-.47.3-.61.91-.31 1.38 1.31 2.06 2 4.42 2 6.83 0 7.07-5.75 12.82-12.82 12.82s-12.81-5.75-12.81-12.82 5.75-12.81 12.81-12.81c2.41 0 4.78.69 6.83 2a.99.99 0 0 0 1.39-.3z"
      fill="#000000"
      className="color000 svgShape"
    />
  </svg>
  );
}
export default Goal;