import React from "react";
import isString from 'lodash/isString';
import { useSelector } from "react-redux";
import KoIcon from "../icon";
import styles from './user.module.scss';
import { KoText } from "packages/typography";

function KoUser(props) {
  const users = useSelector(state => state.users);
  let { data, email, onlyFirstName, className } = props;
  if (!email &&isString(data)) {

    let matchedRecords = users['data'].filter(record => [props.data]?.includes(record.userId));

    let result = matchedRecords.map(({ profile, userId, logo }) => ({ profile, userId, logo }));
    data = result.length > 0 && result[0].profile;
  }

  return (
    email ? <div className={styles.userWrapper}>
      <div className={`${styles.circle} ${styles[data ?
        data?.charAt(0).toLowerCase() : 'a']}`}>
        <span className={`${styles.letter}`}>{data?.charAt(0)}</span>
      </div>
      <KoText ellipsis={true} className={`${styles.text} ${className ? className : ''}`} text={`${data? data : ''}`} />
    </div> : <div className={styles.userWrapper}>
      <div className={`${styles.circle}  ${styles[data ?
        data?.firstName?.charAt(0).toLowerCase() : 'a']}`}>
        {data?.['logo'] ?
          <KoIcon name={data['logo'] ? data['logo'] : 'UsersOutlined'} size={{ width: 20, height: 20 }} /> :
          <span className={`${styles.letter}`}>{data?.firstName?.charAt(0)}</span>}
      </div>
      {onlyFirstName ? <KoText ellipsis={true} className={`${styles.text} ${className ? className : ''}`} text={`${data?.firstName ? data?.firstName : ''}`} />:
        <KoText ellipsis={true} className={`${styles.text} ${className ? className : ''}`} text={`${data?.firstName ? data?.firstName : ''} ${data?.lastName ? data?.lastName : ''}`} />}
    </div>
  );
}
export default KoUser;

