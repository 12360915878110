import React from "react";

function SendRequest(props) {
  const { width, height, color } = props;
  return (
    <svg fill="none" viewBox="0 0 38 37" width={width} height={height}>
      <path stroke={color ? color : "#000"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"
        d="M25.753 18.5604L13.2 11.1954C12.8844 11.0052 12.4874 11.0143 12.1808 11.2186C11.8742 11.423 11.713 11.7859 11.767 12.1504L14.086 18.5604">
      </path>
      <path stroke={color ? color : "#000"} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"
        d="M25.753 18.5603L13.2 25.9253C12.8844 26.1155 12.4874 26.1064 12.1808 25.9021 11.8742 25.6977 11.713 25.3348 11.767 24.9703L14.086 18.5603M25.7531 18.5603H14.0861">
      </path>
      <circle cx="18.755" cy="18.56" r="18.121" stroke={color ? color : "#000"} strokeLinecap="round" strokeLinejoin="round"
        strokeWidth="1"></circle>
    </svg>
  );
}


export default SendRequest;