import React from "react";
import _groupBy from 'lodash/groupBy';
import { KoDescription, KoText, KoTitle, KoAvatar } from "packages";
import styles from './dashboard.module.scss';

function HighPriorityTasks({data}) {
 
  const groupedTickets = _groupBy(data, 'priority');

  const TaskStatuList = [{
    label: 'Todo',
    value: 1
  }, {
    label: 'In Progress',
    value: 2
  }, {
    label: 'Blocked',
    value: 3
  }, {
    label: 'With QA',
    value: 4
  }, {
    label: 'In Review',
    value: 5
  }, {
    label: 'On Hold',
    value: 6
  }, {
    label: 'QA Failed',
    value: 7
  }, {
    label: 'Abandoned',
    value: 8
  }, {
    label: 'Deferred',
    value: 9
  },{
    label: 'Done',
    value: 10
  }];


  const issueTypes = [{
    label: 'Epic',
    value: 1
  }, {
    label: 'Story',
    value: 2
  }, {
    label: 'Task',
    value: 3
  }, {
    label: 'Subtask',
    value: 4
  }, {
    label: 'Bug',
    value: 5
  }, {
    label: 'Improvements',
    value: 6
  }, {
    label: 'Feature request',
    icon: 'FeaturerequestFilled',
    value: 7
  }, {
    label: 'Change request',
    icon: 'ChangerequestFilled',
    value: 8
  }, {
    label: 'Support',
    value: 9
  }];





  return (
    <>
      <KoTitle text='Priority Tasks' level={5} />
      <KoText className={styles.liteText} text={`${groupedTickets?.['5']?.length + groupedTickets?.['4']?.length} Highest & High Priority Tickets`} />
      <div className={styles.highprioritytasks}>
        <div className={styles.items}>
          <div className={`${styles.prioritytasksTitle} ${styles.space}`}>
            <KoTitle className={styles.highest} text={'Highest Priority'} level={5} />
            <div>&nbsp;</div>
            <div className={styles.status}>
              <KoText className={styles.title} text={'Ticket id'} />
              <KoText className={styles.title} text={'Issue type'} />
              <KoText className={styles.title} text={'Status'} />
              <KoText className={styles.title} text={'Release'} />
            </div>
      
          </div>
          {groupedTickets['5']?.map((ticket)=>
            <div key={ticket?.ticketId} className={`${styles.prioritytasksTitle} ${styles.space}`}>
              <KoDescription ellipsis className={styles.description} text={ticket?.summary} />
              <KoAvatar size={20} options={[{ name: 'Dion' }]} />
              <div className={styles.statusText}>
                <div className={`${styles.stage} ${styles.done}`}>{ticket.ticketId}</div>
                <div className={`${styles.stage} ${styles.todo}`}>{issueTypes[ticket.issueType-1]?.label}</div>
                <div className={`${styles.stage} ${styles[TaskStatuList[ticket.status]?.label?.replace(" ", '')]}`}>{TaskStatuList[ticket.status]?.label}</div>
                <div className={`${styles.stage} ${styles.stuck}`}>Stuck</div>
              </div>
            </div>
          )}
        </div>
        <div className={styles.items}>
          <div className={`${styles.prioritytasksTitle} ${styles.space}`}>
            <KoTitle className={styles.high} text={'High Priority'} level={5} />
            <div>&nbsp;</div>
            <div className={styles.status}>
              <KoText className={styles.title} text={'Ticket id'} />
              <KoText className={styles.title} text={'Issue type'} />
              <KoText className={styles.title} text={'Status'} />
              <KoText className={styles.title} text={'Release'} />
            </div>
          </div>
          {groupedTickets['4']?.map((ticket)=>
            <div key={ticket?.ticketId} className={`${styles.prioritytasksTitle} ${styles.space}`}>
              <KoDescription ellipsis className={styles.description} text={ticket?.summary} />
              <KoAvatar size={20} options={[{ name: 'Dion' }]} />
              <div className={styles.statusText}>
                <div className={`${styles.stage} ${styles.done}`}>{ticket.ticketId}</div>
                <div className={`${styles.stage} ${styles.todo}`}>{issueTypes[ticket.issueType-1].label}</div>
                <div className={`${styles.stage} ${styles[TaskStatuList[ticket.status]?.label?.replace(" ", '')]}`}>{TaskStatuList[ticket.status]?.label}</div>
                <div className={`${styles.stage} ${styles.stuck}`}>Stuck</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default HighPriorityTasks;