import React,{useEffect, useState} from 'react';
import { Link, useNavigate, useParams } from "react-router-dom";
import KoToolBar from "shared/components/toolbar";
import {
  KoIcon,KoAffix ,KoFlex,  KoDropdown, KoText,KoModal,KoForm,KoButton,
  KoLoader,
  KoToast,
  KoDateFormatter,
  KoTitle,
  KoEmpty
} from "packages";
import { getQueryParams } from 'shared/utils';
import styles from "./artifacts.module.scss";
import FolderCrud from './folderCrud';
import { FilesAPI } from '../files.service';
import FileShareCrud from './fileShareCrud';
import FileRenameCrud from './renameCrud';
import FileCurd from './curd';
function Artifacts(props) {
  const [isFolderOpen, setFolderModal] = useState(false);
  const [isFileOpen, setFileModal] = useState(false);
  const [isFileShareOpen, setFileShareModal] = useState(false);
  const [isFileRenameOpen, setFileRenameModal] = useState(false);
  const [isOpenDelete, setDeleteModal] = useState(false);
  const [isLoading,setLoading]= useState(false);
  const [currentRecord, setCurrentRecord] = useState();
  const [folderDetails, setFolderDetails] = useState();
  let navigate = useNavigate();
  const [Files,setFiles]=useState([]);
  const [form] = KoForm.useForm();
  const {folderId, workspaceId}=useParams();

  useEffect(()=>{
    getFolder();
  },[]);

  useEffect(()=>{
    if(folderId){
      getAllFiles(folderId);
    }
  },[folderId]);


  const getAllFiles=(folderId)=>{
    setLoading(true);
    FilesAPI.getFilesByFolderId(folderId, workspaceId).then((data)=>{
      setFiles(data?.files);
      setLoading(false);
    },
    (err) => {
      setLoading(false);
    });
  };

  

  const getFolder=()=>{
    setLoading(true);
    FilesAPI.getFolderById(folderId, workspaceId).then((data)=>{
      setFolderDetails(data?.folder);
      setLoading(false);
    },
    (err) => {
      setLoading(false);
    });
  };

  const handleFileShareFinish = (values => {
    setLoading(true);
    FilesAPI.createOrUpdateFile(values, folderId, currentRecord?.fileId, workspaceId).then(
      (data) => {
        setLoading(false);
        KoToast.success('File Shared successfully');
        setFileShareModal(false);
      },
      (err) => {
        setLoading(false);
        KoToast.error(err?.error);
      }
    );
  });

  const handleFileRenameFinish = (values => {
    setLoading(true);
    FilesAPI.createOrUpdateFile(values, folderId, currentRecord?.fileId, workspaceId).then(
      (data) => {
        setLoading(false);
        setFileRenameModal(false);
        KoToast.success('File Rename successfully');
        getAllFiles(folderId);
      },
      (err) => {
        setLoading(false);
        KoToast.error(err?.error);
      }
    );
  });


  const handleFinishDelete=()=>{
    setLoading(true);
    FilesAPI.deleteFileById(currentRecord?.folderId, currentRecord?.fileId, workspaceId).then(
      (data) => {
        setLoading(false);
        setDeleteModal(false);
        KoToast.success(`File deleted successfully! ${currentRecord?.Name}!`);
        getAllFiles(folderId);
      },
      (err) => {
        setLoading(false);
        KoToast.error(`error`);
      }
    );
  };

  const onClick = ({key}, project) => {
    setCurrentRecord(project);
    if(key === "0"){
      // setModal(true);
    } else if(key === "1"){
      setFileRenameModal(true);
    }else if(key === "2"){
      setDeleteModal(true);
    }else if(key === "3"){
      setFileShareModal(true);
    }else{
      
    }
  };

  const action = [
    {
      label: "Download",
      key: "0",
      icon: <KoIcon name="CloudDownloadOutlined" width={13} />
    },
    {
      label: "Edit",
      key: "1",
      icon: <KoIcon name="EditOutlined" height={12} width={13} />
    },
    
    {
      label: "Share Document",
      key: "3",
      icon: <KoIcon name="ShareOutlined" height={14} width={13} />
    },
    {
      type: 'divider'
    },
    {
      label: "Delete",
      danger : true,
      key: "2",
      icon: <KoIcon name="DeleteOutlined" height={12} width={13} />
    }
  ];


  // const onClick = (event) => {
  //   event?.domEvent.preventDefault();
  // };

  const handleFolderFinish = (values => {
    // let formData=new FormData();
    // // formData.append("file",values?.profilePicture?.[0]);
    // formData.append("name",values?.name);
    // formData.append("key",values?.key);
    // if(values?.tag){
    //   formData.append("tag",values?.tag);
    // }
    // if(values?.description){
    //   formData.append("description",values?.description);
    // }
    FilesAPI.uploadFile(folderId, values, workspaceId)
      .then((data)=>{
        KoToast.success(`File Uploaded Successfully`);
        getAllFiles(folderId);
        setFolderModal(false);
        setLoading(false);
      },
      (err) => {
        KoToast.success(`error`);
        setLoading(false);
      });
  });

  const onCreateFolder = () => {
    setFolderModal(true);
  };
  const onCreateFile = () => {
    navigate(`fle-a87826584b`);
  };

  const handleFileFinish = (values => {
  });


  
  async function getFile(url) {
    let queryParams = getQueryParams(url);
    let obj={
      Expires: queryParams?.Expires,
      GoogleAccessId:queryParams?.GoogleAccessId,
      Signature:queryParams?.Signature,
      fields:'cors'
    };
    FilesAPI.getFile(queryParams?.url, obj)
      .then((res)=>{
        getFile(res);
      },
      (err) => {
        KoToast.error(`error`);
        setLoading(false);
        onFIleOpe();
      });
  }


  const onFIleOpe = (values)=>{
    let obj = {
      "files": [
        values?.fileId+'.json'
      ],
      "path": `file/${values?.folderId}/`
    };
    FilesAPI.getUpload(obj)
      .then((res)=>{
        getFile(res['urls'][0]);
      },
      (err) => {
        KoToast.success(`error`);
        setLoading(false);
      });
   
  };



  return (
    <div className={styles.wrapper}>
      {isLoading && <KoLoader/> }
      <KoAffix top={56}>
        <KoToolBar
          className={styles.toolbar}
          title={folderDetails?.name}
          search={{
            visible: true,
            onChange: () => {},
            placeholder: "Search by file name"
          }}
          buttonList={[
            {
              label: "Add File",
              type: "primary",
              icon: (
                <KoIcon
                  color="#FFF"
                  name="PlusOutlined"
                  width={18}
                  height={18}
                />
              ),
              onClick: () => onCreateFile()
            },
            {
              label: "Upload File",
              type: "primary",
              icon: (
                <KoIcon
                  color="#FFF"
                  name="UploadOutlined"
                  width={18}
                  height={18}
                />
              ),
              onClick: () => onCreateFolder()
            }
              
          ]}
        />
      </KoAffix>
      {Files?.length > 0 ?
        <KoFlex wrap="wrap" gap="small" className={styles.quickAccess}>
          {Files.map((project, i) => (
            <div className={`${styles.card} ${styles.link}`} key={project?.fileId}>
              <div className={styles.actions}>
                <KoDropdown
                  overlayClassName={styles.actionsLi}
                  menu={{
                    items:action,
                    onClick:(e)=>onClick(e, project)
                  }}
                  placement="bottomRight"
                >
                  <div onClick={(e) => e.preventDefault()}>
                    <KoIcon name="VerticalMenuOutlined" />
                  </div>
                </KoDropdown>
              </div>
              <Link to={`${project?.fileId}`}>
                <div className={styles.fineName} >
                  <div className={styles.image}>
                    <KoIcon name='InvoicesOutlined' width={70} height={90}/>
                  </div>
                </div>
                <div className={styles.footer}>
                  <KoText ellipsis={true} className={styles.projectName} text={project?.name} />
                  <KoDateFormatter format='DD-MMM-YYYY' data={project?.createdAt} className={styles.mimeType}/>
                </div>
            
              </Link>
            </div>
          ))}
        </KoFlex> :

        <KoFlex wrap="wrap" gap="small" className={styles.emptySection}>
          <KoEmpty onClick={()=>onCreateFile(true)} label="Create File" icon={'EmptyTwoTone'} title="Create a new file"/>
        </KoFlex>
      }

      <KoModal
        title={currentRecord?.id ? `Update Folder ${currentRecord?.folderId?.toUpperCase()}` : 'New Folder'}
        centered
        open={isFolderOpen}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setFolderModal(false); }}
        onClose={() => { setCurrentRecord(); setFolderModal(false); }}
        width={680}
        // maskClosable={false}
        height={650}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setFolderModal(!isFolderOpen)} />,
          <KoButton key='create' loading={isLoading} type='primary' label={`${currentRecord?.folderId ? 'Update' : 'Create'}`} onClick={() => form.submit()} />
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <FolderCrud form={form} handleFinish={handleFolderFinish}/>
      </KoModal>
      <KoModal
        title={currentRecord?.id ? `Update File ${currentRecord?.folderId?.toUpperCase()}` : 'New File'}
        centered
        open={isFileOpen}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setFileModal(false); }}
        onClose={() => { setCurrentRecord(); setFileModal(false); }}
        width={1200}
        // maskClosable={false}
        height={650}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setFileModal(!isFileOpen)} />,
          <KoButton key='create' loading={isLoading} type='primary' label={`${currentRecord?.folderId ? 'Update' : 'Create'}`} onClick={() => form.submit()} />
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <FileCurd form={form} initialValues={currentRecord} handleFinish={handleFileFinish}/>
      </KoModal>
      <KoModal
        title={'Share file'}
        centered
        open={isFileShareOpen}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setFileShareModal(false); }}
        onClose={() => { setCurrentRecord(); setFileShareModal(false); }}
        width={560}
        // maskClosable={false}
        height={650}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setFileShareModal(!isFileShareOpen)} />,
          <KoButton key='create' loading={isLoading} type='primary' label={'Share'} onClick={() => form.submit()} />
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <FileShareCrud form={form} handleFinish={handleFileShareFinish}/>
      </KoModal>
      <KoModal
        title={'Rename file'}
        centered
        open={isFileRenameOpen}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setFileRenameModal(false); }}
        onClose={() => { setCurrentRecord(); setFileRenameModal(false); }}
        width={560}
        // maskClosable={false}
        height={650}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setFileRenameModal(!isFileRenameOpen)} />,
          <KoButton key='create' loading={isLoading} type='primary' label={'Save'} onClick={() => form.submit()} />
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <FileRenameCrud form={form} handleFinish={handleFileRenameFinish} initialValues={currentRecord}/>
      </KoModal>

      <KoModal
        title={`Delete File ${currentRecord?.name?.toUpperCase()}`}
        centered
        open={isOpenDelete}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setDeleteModal(false); }}
        onClose={() => { setCurrentRecord(); setDeleteModal(false); }}
        width={580}
        // maskClosable={false}
        height={450}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setDeleteModal(!isOpenDelete)} />,
          <KoButton key='reset' loading={isLoading} type='primary' label='Delete' onClick={() => handleFinishDelete()} />
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <div className={styles.suspend}>
          <KoTitle level={5} text={`Are you sure you want to delete this file?`} />
          <KoText text={`This action is irreversible, and it will result in the removal of their associated data and Kwapio license.`} />
        </div>
      </KoModal>
    </div>
  );
}

export default Artifacts;