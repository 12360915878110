import React from "react";
function Copy(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height}>
      <path
        d="M18.93945,2.23242A2.48391,2.48391,0,0,0,17.17188,1.5H12A2.50294,2.50294,0,0,0,9.5,4V16A2.50294,2.50294,0,0,0,12,18.5h8A2.50263,2.50263,0,0,0,22.5,16V6.82861a2.48219,2.48219,0,0,0-.73242-1.76806ZM17.5,2.5401a1.50868,1.50868,0,0,1,.73242.39935l2.82813,2.82813A1.49048,1.49048,0,0,1,21.461,6.5H19A1.50164,1.50164,0,0,1,17.5,5ZM21.5,16A1.50164,1.50164,0,0,1,20,17.5H12A1.50164,1.50164,0,0,1,10.5,16V4A1.50164,1.50164,0,0,1,12,2.5h4.5V5A2.50263,2.50263,0,0,0,19,7.5h2.5Zm-20,4V8A2.50294,2.50294,0,0,1,4,5.5H8v1H4A1.50164,1.50164,0,0,0,2.5,8V20A1.50164,1.50164,0,0,0,4,21.5h8A1.50164,1.50164,0,0,0,13.5,20h1A2.50263,2.50263,0,0,1,12,22.5H4A2.50294,2.50294,0,0,1,1.5,20Z"
        fill="var(--palette-gray-10)" className="color000 svgShape"></path>
    </svg>

  
  );
}


export default Copy;