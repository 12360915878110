import React, { useEffect, useState } from "react";
import { KoFlex, KoSkeleton, KoText } from "packages";
import { OrgAPI } from "../org.service";
import { STATISTICS } from "./constant";
import styles from './statistic.module.scss';

function Statistic() {
  const [count, setCount] = useState(STATISTICS);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    OrgAPI.getCount().then(
      (userdata) => {
        setLoading(true);
        OrgAPI.getGroupsCount().then(
          (data) => {
            STATISTICS[0].value = userdata?.activeUsers + userdata?.inactiveUsers;
            STATISTICS[1].value = userdata?.activeUsers;
            STATISTICS[2].value = userdata?.inactiveUsers;
            STATISTICS[3].value = data?.totalCount;
            STATISTICS[4].value = userdata?.invitedUsers;
            STATISTICS[5].value = userdata?.requetedUsers;
            setCount(STATISTICS);
            setLoading(false);
          },
          (err) => {
            setLoading(false);
          }
        );
      },
      (err) => {
        setLoading(false);
      }
    );
   
  },[]);

  return (
    <KoFlex wrap="wrap" gap="small" className={styles.wrapper}>
      {count?.map((option, i) =>
        <div key={i} >
          {isLoading?<KoSkeleton active title={false} paragraph={{ rows: 1 }} />:
            <div className={styles.count}>
              <KoText text={option?.title}/>
              <KoText className={`${styles.squre} ${styles['c'+i]}`} text={option?.value}/>
            </div>}
        </div>
      )}
    </KoFlex>

  );
}
export default Statistic;
