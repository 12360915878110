import React from "react";
import {KoTooltips, KoIcon} from "../index";
import styles from './log.module.scss';

function KoWorkLog(props) {
  const { value } = props;

  return (
    <div className={styles.priorityWrapper}>
      {value&& <KoTooltips title={`${value} Logged`}>
        <div className={`${styles.priority}`}>
          <KoIcon name="LogOutlined" width={13} height={13}/>
        </div>
      </KoTooltips>}
    </div>
  );
}
export default KoWorkLog;
