import React from "react";
function Images(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_130_135)">
        <path d="M8 5C8 4.45 8.45 4 9 4C9.55 4 10 4.45 10 5C10 5.55 9.55 6 9 6C8.45 6 8 5.55 8 5ZM24 2.5V16H4V2.5C4 1.12 5.12 0 6.5 0H21.5C22.88 0 24 1.12 24 2.5ZM5 2.5V14.82L13.03 6.79L16.33 10.09L23 3.42V2.5C23 1.67 22.33 1 21.5 1H6.5C5.67 1 5 1.67 5 2.5ZM23 15V4.83L16.33 11.5L13.03 8.2L6.24 14.99H23V15ZM18.62 22.78L1.22 18.13L2 15.23V11.36L0 18.84L19.32 24L20.92 18H19.89L18.61 22.78H18.62Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_130_135">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}


export default Images;