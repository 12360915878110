const WORKSPACE_STATISTICS = [{
  color: '--palette-gray-10',
  backgroud:'--active',
  title: 'Total Folders',
  icon: 'AssigneeTwoTone',
  value: 10
}, {
  color: '--palette-gray-10',
  backgroud:'--active',
  title: 'Total Documents',
  icon: 'AssigneeTwoTone',
  value: 120
}, {
  color: '--palette-gray-10',
  backgroud:'--active',
  title: 'Total Media Files',
  icon: 'AssigneeTwoTone',
  value: 103
}, {
  color: '--palette-gray-10',
  backgroud:'--active',
  title: 'Other Files',
  icon: 'AssigneeTwoTone',
  value: 110
}];


const DOC_LIST = [{
  name: 'qubernet, inc.',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint:2,
  id: 'Pro_121212',
  tags: ['Development', 'design', 'IT', 'Finance', 'Devops', 'Demo']
}, {
  name: 'Kwapio, inc.',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint:5,
  id: 'Pro_1212122',
  tags: ['Development', 'Demo']
}, {
  name: 'tonelix',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  id: 'Pro_1212123',
  sprint:7,
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint:5,
  id: 'Pro_12121234',
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint:6,
  id: 'Pro_12121235',
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint:4,
  id: 'Pro_12121236',
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  sprint:9,
  progress: 80,
  id: 'Pro_121212',
  tags: ['Development', 'design', 'IT', 'Finance', 'Devops', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint:10,
  id: 'Pro_1212122',
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  sprint:7,
  progress: 80,
  id: 'Pro_1212123',
  tags: ['Development', 'Demo']
},{
  name: 'tonelix',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  id: 'Pro_1212124',
  sprint:7,
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint:5,
  id: 'Pro_12121246',
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint:6,
  id: 'Pro_12121245',
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint:4,
  id: 'Pro_12121247',
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  sprint:9,
  progress: 80,
  id: 'Pro_121248',
  tags: ['Development', 'design', 'IT', 'Finance', 'Devops', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint:10,
  id: 'Pro_1212149',
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  sprint:7,
  progress: 80,
  id: 'Pro_1212143',
  tags: ['Development', 'Demo']
}];

const ENV_LIST = [{
  name: 'Developemnt',
  icon:'DevFilled',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint:2,
  id: 'Pro_121212',
  tags: ['Development', 'design', 'IT', 'Finance', 'Devops', 'Demo']
}, {
  name: 'Testing',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  icon:'TestingFilled',
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint:5,
  id: 'Pro_1212122',
  tags: ['Development', 'Demo']
}, {
  name: 'UAT',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  icon:'UatFilled',
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  id: 'Pro_1212123',
  sprint:7,
  tags: ['Development', 'Demo']
}, {
  name: 'Production',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  icon:'PrdFilled',
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint:5,
  id: 'Pro_12121234',
  tags: ['Development', 'Demo']
}];

const CLIENT_LIST = [{
  name: 'qubernet, inc.',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint: 2,
  id: 'Pro_121212',
  tags: ['Development', 'design', 'IT', 'Finance', 'Devops', 'Demo']
}, {
  name: 'Kwapio, inc.',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint: 5,
  id: 'Pro_1212122',
  tags: ['Development', 'Demo']
}, {
  name: 'tonelix',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  id: 'Pro_1212123',
  sprint: 7,
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint: 2,
  id: 'Pro_121212',
  tags: ['Development', 'design', 'IT', 'Finance', 'Devops', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint: 5,
  id: 'Pro_1212122',
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  id: 'Pro_1212123',
  sprint: 7,
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint: 5,
  id: 'Pro_12121234',
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint: 6,
  id: 'Pro_12121235',
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint: 4,
  id: 'Pro_12121236',
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  sprint: 9,
  progress: 80,
  id: 'Pro_121212',
  tags: ['Development', 'design', 'IT', 'Finance', 'Devops', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint: 10,
  id: 'Pro_1212122',
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  sprint: 7,
  progress: 80,
  id: 'Pro_1212123',
  tags: ['Development', 'Demo']
}];
const COLUMNS_GROUPS = [
  {
    id: "randaomId",
    label: "Invoice No",
    widget: "user",
    fixed:true
  }, {
    id: "priority",
    label: "Client Name",
    widget: "text"
  },  {
    id: "priority",
    label: "Invoice Date",
    widget: "date"
  }, {
    id: "lastSignedIn",
    label: "Due Date",
    widget: "date"
  },{
    id: "randaomId",
    label: "Amount",
    widget: "text"
  }, {
    id: "status",
    label: "Status",
    widget: "status"
  }
];
const PRINT_HEADER = [
  {
    id: "item",
    label: "Item & Description",
    widget: "text",
    width:250
  }, {
    id: "quantity",
    label: "Quantity",
    widget: "text",
    width:80
  },  {
    id: "rate",
    label: "Rate",
    widget: "text",
    width:50
  }, {
    id: "unit",
    label: "Unit",
    widget: "text",
    width:50
  },{
    id: "amount",
    label: "Amount",
    widget: "number",
    width:100
  }
];
const PRINT_ROW =[{
  item:"sdfs",
  quantity:"sdf",
  rate:"sdfs",
  unit:"asd",
  amount:3243
},{
  item:"sdfs",
  quantity:"sdf",
  rate:"sdfs",
  unit:"asd",
  amount:3243
},{
  item:"sdfs",
  quantity:"sdf",
  rate:"sdfs",
  unit:"asd",
  amount:3243
},{
  item:"sdfs",
  quantity:"sdf",
  rate:"sdfs",
  unit:"asd",
  amount:3243
},{
  item:"sdfs",
  quantity:"sdf",
  rate:"sdfs",
  unit:"asd",
  amount:3243
},{
  item:"sdfs",
  quantity:"sdf",
  rate:"sdfs",
  unit:"asd",
  amount:3243
}];
const ROWS = [{
  id:'1',
  roleName:'roleName',
  permissionName:'permissionName',
  roleDescription:' simply dummy text of the printing and ',
  permissionDescription:' simply dummy text of the printing and ',
  randaomId: 'Kamal K',
  createdBy: 'Kamal K',
  designation: "Hr",
  phone: {
    countryCode: 91,
    phoneNumber: "9944929666"
  },
  email: "kamal@qubernet.com",
  description:`Lorem Ipsum is simply dummy text of the printing and Typesetting industry.` +
      `Lorem Ipsum has been the industry’s Standard dummy text ever since the 1500s,`  +
      ` when an unknown Printer took a gallery of type and scrambled it to make a type Specimen book.`+
      `It has survived not only five centuries, but also The leap into electronic typesetting`,
  status: "Active",
  roles: 'Paradot',
  groups:'human resources',
  lastSignedIn:'2024-01-25T10:42:08.097Z',
  issueType:'bug',
  task:'Android Keyboard Document',
  comments:[{text:1},{text:2}],
  priority:2,
  members:[{name:'kamal', profilePic:'m1'},{name:'suku', profilePic:'m2'},{name:'john', profilePic:'m3'}]
}, {
  id:'2',
  randaomId: 'John',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  description:"Desc Text Desc TextDesc TextDesc Text Desc TextDesc TextDesc TextDesc TextDesc Text Desc TextDesc Text",
  status: "Inactive",
  roles: 'engineering',
  groups:'engineering',
  lastSignedIn:'2024-01-25T10:42:08.097Z',
  issueType:'task',
  comments:[{text:1}],
  priority:4,
  task:'Android Keyboard Document',
  members:[{name:'kamal', profilePic:'m1'},{name:'suku', profilePic:'m2'},{name:'john', profilePic:'m3'}]
},{
  id:'3',
  randaomId: 'Musk',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  description:"Desc Text Desc TextDesc TextDesc Text Desc TextDesc TextDesc TextDesc TextDesc Text Desc TextDesc Text",
  status: "On Hold",
  roles: 'Devops',
  groups:'Devops',
  lastSignedIn:'2024-01-25T10:42:08.097Z',
  issueType:'improvement',
  task:'Android Keyboard Document',
  priority:3,
  comments:[{text:1},{text:2}, {text:3}],
  members:[{name:'kamal', profilePic:'m1'},{name:'suku', profilePic:'m2'},{name:'john', profilePic:'m3'}]
},{
  id:'4',
  randaomId: 'Siva',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  description:"Desc Text Desc TextDesc TextDesc Text Desc TextDesc TextDesc TextDesc TextDesc Text Desc TextDesc Text",
  status: "In Review",
  roles: 'Finance',
  groups:'Finance',
  lastSignedIn:'2024-01-25T10:42:08.097Z',
  issueType:'improvement',
  priority:1,
  task:'Android Keyboard Document',
  comments:[],
  members:[{name:'kamal', profilePic:'m1'},{name:'suku', profilePic:'m2'},{name:'john', profilePic:'m3'}]
},{
  id:'5',
  randaomId: 'Rajesh',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  description:"Desc Text Desc TextDesc TextDesc Text Desc TextDesc TextDesc TextDesc TextDesc Text Desc TextDesc Text",
  status: "Blocked",
  roles: 'Paradot',
  groups:'Marketing',
  lastSignedIn:'2024-01-25T10:42:08.097Z',
  issueType:'improvement',
  priority:5,
  task:'Android Keyboard Document',
  comments:[],
  members:[]
},{
  id:'6',
  randaomId: 'Gokul',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  description:"Desc Text Desc TextDesc TextDesc Text Desc TextDesc TextDesc TextDesc TextDesc Text Desc TextDesc Text",
  status: "Duplicate",
  roles: 'Paradot',
  groups:'Pm team',
  lastSignedIn:'2024-01-25T10:42:08.097Z',
  issueType:'improvement',
  priority:4,
  task:'Android Keyboard Document',
  comments:[{text:1},{text:2}, {text:3}],
  members:[]
},
{
  id:'7',
  randaomId: 'Kamal K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  status: "Done",
  roles: 'Paradot',
  groups:'design',
  lastSignedIn:'2024-01-25T10:42:08.097Z',
  issueType:'newFeature',
  task:'Android Keyboard Document',
  priority:5,
  comments:[],
  members:[]
},{
  id:'8',
  randaomId: 'Kamal K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  status: "Completed",
  roles: 'Paradot',
  groups:'Development',
  lastSignedIn:'2024-01-25T10:42:08.097Z',
  issueType:'newFeature',
  priority:1,
  task:'Android Keyboard Document',
  comments:[{text:1}],
  members:[]
}, {
  id:'9',
  randaomId: 'Hema K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  status: "Reopen",
  roles: 'Paradot',
  groups:'Javascript',
  lastSignedIn:'2024-01-25T10:42:08.097Z',
  issueType:'query',
  priority:5,
  task:'Android Keyboard Document',
  members:[],
  comments:[{text:1},{text:2}, {text:3}]
},{
  id:'19',
  randaomId: 'Hema K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  status: "Reopen",
  roles: 'Paradot',
  groups:'Javascript',
  lastSignedIn:'2024-01-25T10:42:08.097Z',
  issueType:'query',
  priority:5,
  task:'Android Keyboard Document',
  members:[],
  comments:[{text:1},{text:2}, {text:3}]
},{
  id:'29',
  randaomId: 'Hema K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  status: "Reopen",
  roles: 'Paradot',
  groups:'Javascript',
  lastSignedIn:'2024-01-25T10:42:08.097Z',
  issueType:'query',
  priority:5,
  task:'Android Keyboard Document',
  members:[],
  comments:[{text:1},{text:2}, {text:3}]
},{
  id:'39',
  randaomId: 'Hema K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  status: "Reopen",
  roles: 'Paradot',
  groups:'Javascript',
  lastSignedIn:'2024-01-25T10:42:08.097Z',
  issueType:'query',
  priority:5,
  task:'Android Keyboard Document',
  members:[],
  comments:[{text:1},{text:2}, {text:3}]
},{
  id:'49',
  randaomId: 'Hema K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  status: "Reopen",
  roles: 'Paradot',
  groups:'Javascript',
  lastSignedIn:'2024-01-25T10:42:08.097Z',
  issueType:'query',
  priority:5,
  task:'Android Keyboard Document',
  members:[],
  comments:[{text:1},{text:2}, {text:3}]
},{
  id:'59',
  randaomId: 'Hema K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  status: "Open",
  roles: 'Paradot',
  groups:'Javascript',
  lastSignedIn:'2024-01-25T10:42:08.097Z',
  issueType:'query',
  priority:5,
  task:'Android Keyboard Document',
  members:[],
  comments:[{text:1},{text:2}, {text:3}]
},{
  id:'69',
  randaomId: 'Hema K',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  status: "Todo",
  roles: 'Paradot',
  groups:'Javascript',
  lastSignedIn:'2024-01-25T10:42:08.097Z',
  issueType:'query',
  priority:5,
  task:'Android Keyboard Document',
  members:[],
  comments:[{text:1},{text:2}, {text:3}]
}];

const INV={
  "invoiceNo": "#0001",
  "name": "Software development",
  "project": "wos-925b2bc4be",
  "invoiceDate": "2024-08-16T18:30:00.000Z",
  "dueDate": "2024-08-26T18:30:00.000Z",
  "from": "No, 89, 90, Ram nagr,\nGomathi nagar, Velachery,\nChennai,\nTN,\nIN,\n600042",
  "to": "N0 90,\nopp, Gomathi nagar,\nChennai,\nTN,\nIN,\n60042",
  "notes": "12121",
  "summary": {
    "fid-4f59407f5e": {
      "tax": [
        {
          "label": "GST (18%)",
          "value": "tax-5282e5298c",
          "percent": 18
        }
      ],
      "discount": [
        {
          "label": "OPEN (1%)",
          "value": "dis-8ae030f3b4",
          "percent": 1
        }
      ],
      "shippingCharge": 100
    },
    "fid-ccd9e2b4bc": {
      "tax": [
        {
          "label": "GST (18%)",
          "value": "tax-5282e5298c",
          "percent": 18
        }
      ],
      "discount": [
        {
          "label": "SAVE 20 (20%)",
          "value": "dis-25daa992e1",
          "percent": 20
        }
      ],
      "shippingCharge": 12
    }
  },
  "lineItems": {
    "fid-4f59407f5e": [
      {
        "key": "lit-7743be4648",
        "items": "asdsad",
        "unit": "d",
        "quantity": 1,
        "rate": 1212,
        "amount": 1212
      }
    ],
    "fid-ccd9e2b4bc": [
      {
        "key": "lit-33ef3ee9f8",
        "items": "12",
        "unit": "hrs",
        "quantity": 12,
        "rate": 1212,
        "tax": [
          {
            "label": "IGST (18%)",
            "value": "tax-5a3f660351",
            "percent": 18
          }
        ],
        "discount": [
          {
            "label": "SAVE 20 (20%)",
            "value": "dis-25daa992e1",
            "percent": 20
          }
        ],
        "amount": 13729.536
      }
    ]
  },
  "invoiceId": "inv-49344b42f2"
};
export {
  WORKSPACE_STATISTICS,
  PRINT_HEADER,
  PRINT_ROW,
  DOC_LIST,
  ENV_LIST,
  CLIENT_LIST,
  COLUMNS_GROUPS,
  ROWS,
  INV
};