import React from "react";
function Status(props) {
  const { width, height } = props;
  return (
    <svg
      width={width}
      height={height}
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit={2}
      fill="#657583"
      clipRule="evenodd"
      viewBox="0 0 48 48"
      {...props}
    >
      <path d="M27.638 41.027a17.588 17.588 0 01-3.638.379c-9.659 0-17.5-7.841-17.5-17.5a1.5 1.5 0 00-3 0c0 11.314 9.186 20.5 20.5 20.5 1.46 0 2.885-.153 4.259-.444a1.5 1.5 0 00-.621-2.935zM26.465 6.578C34.958 7.777 41.5 15.084 41.5 23.906c0 6.24-3.274 11.722-8.195 14.821a1.5 1.5 0 001.598 2.538c5.765-3.629 9.597-10.05 9.597-17.359 0-10.335-7.666-18.894-17.616-20.298a1.5 1.5 0 00-.419 2.97zM7.76 17.378a17.57 17.57 0 0110.449-9.99 1.5 1.5 0 00-.993-2.831 20.58 20.58 0 00-12.239 11.7 1.501 1.501 0 002.783 1.121z" />
    </svg>

  );
}


export default Status;