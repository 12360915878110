const roleAccess = {
  1: ['Dashboard', 'Scrum', 'My Tickets', 'Sprints', 'Epic', 'Backlog', 'Release', 'Modules', 'Components', 'Test Cases'],
  2: ['Dashboard', 'Scrum', 'My Tickets', 'Sprints', 'Epic', 'Backlog', 'Release', 'Modules', 'Components', 'Test Cases'],
  3: ['Dashboard', 'Scrum', 'My Tickets', 'Epic'],
  4: ['Dashboard', 'Scrum', 'My Tickets', 'Sprints', 'Epic', 'Backlog', 'Release', 'Test Cases'],
  5: ['Dashboard', 'Scrum', 'My Tickets', 'Epic'],
  6: ['Dashboard', 'Scrum', 'My Tickets', 'Epic'],
  7: ['Dashboard', 'Scrum', 'My Tickets', 'Epic', 'Release', 'Test Cases']
};

const menu = [
  { label: 'Dashboard', key: 'taskplanner/dashboard', icon: 'DashboardOutlined' },
  { label: 'Scrum', key: 'taskplanner/scrum', icon: 'ScrumSprintOutlined' },
  { label: 'My Tickets', key: 'taskplanner/tickets', icon: 'ProjectsOutlined' },
  { label: 'Sprints', key: 'taskplanner/sprints', icon: 'ActiveSprintOutlined' },
  { label: 'Epic', key: 'taskplanner/epic', icon: 'EpicOutlined' },
  { label: 'Backlog', key: 'taskplanner/backlog', icon: 'BackLogOutlined' },
  { label: 'Modules', key: 'taskplanner/modules', icon: 'ModulesOutlined' },
  { label: 'Components', key: 'taskplanner/components', icon: 'ComponentsOutlined' },
  { label: 'Release', key: 'taskplanner/release', icon: 'DeploymentOutlined' },
  { label: 'Test Cases', key: 'taskplanner/testcases', icon: 'TestCasesOutlined' }
];
  
function getTaskSubMenuForRoles(user) {
  const accessibleMenus = new Set();
  user?.roles?.forEach(roleId => {
    const accessibleItems = roleAccess[roleId] || [];
    accessibleItems?.forEach(item => accessibleMenus.add(item));
  });
  return menu.filter(menuItem => accessibleMenus.has(menuItem.label));
}

  
export {
  getTaskSubMenuForRoles
};
  