import React from "react";
import { InputNumber  } from 'antd';

function KoNumber(props) {
  return (
    <InputNumber
      controls={props?.controls ? props?.controls :false}
      {...props}
      style={{ width: '100%' }}
    />
  );
}

export default KoNumber;