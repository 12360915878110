import React from "react";
function Underline(props) {
  const { width, height } = props;
  return (
    <svg data-name="Layer 1" viewBox="0 0 32 32" width={width}
      height={height}>
      <path d="M22 5.56c-.55 0-1 .45-1 1v9c0 2.76-2.24 5-5 5s-5-2.24-5-5V6.56c0-.55-.45-1-1-1s-1 .45-1 1v9c0 3.86 3.14 7 7 7s7-3.14 7-7V6.56c0-.55-.45-1-1-1zM22 24.44h-12c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1z" fill="#000000" >
      </path>
    </svg>
  );
}


export default Underline;


