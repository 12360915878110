import React from "react";
function Invoices(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width={width} height={height}>
      <path
        d="M38 29H14a3 3 0 0 1-3-3v-6a3 3 0 0 1 3-3h24a3 3 0 0 1 3 3v6a3 3 0 0 1-3 3zM14 19a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h24a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1zm17 30H12a1 1 0 0 1 0-2h19a1 1 0 0 1 0 2zm1 5H12a1 1 0 0 1 0-2h20a1 1 0 0 1 0 2z"
        fill="var(--palette-gray-8)" className="color000 svgShape"></path>
      <path
        d="M32 54H12a1 1 0 0 1 0-2h20a1 1 0 0 1 0 2zM12 39h15a1 1 0 0 0 0-2H12a1 1 0 0 0 0 2zm0-5h15a1 1 0 0 0 0-2H12a1 1 0 0 0 0 2zm0 10h15a1 1 0 0 0 0-2H12a1 1 0 0 0 0 2zm20 3H12a1 1 0 0 0 0 2h20a1 1 0 0 0 0-2zm16.6-7h-.94c-.53 0-.53-1 0-1H50a1 1 0 0 0 0-2h-1v-1a1 1 0 0 0-2 0v1.11c-2.68.81-2.09 4.89.66 4.89h.94c.53 0 .53 1 0 1H46a1 1 0 0 0 0 2h1v1a1 1 0 0 0 2 0v-1c2.9-.55 2.52-5-.4-5zm-13.15-2.36a8.32 8.32 0 0 0-.26 1.24 1 1 0 0 0 2 .26 7.48 7.48 0 0 1 .2-1 1 1 0 0 0-1.94-.5z"
        fill="var(--palette-gray-8)" className="color000 svgShape"></path>
      <path
        d="M60.86 39.14A13 13 0 0 0 49 28c0-17.23.2-16.39-.43-16.84C39.92 2.55 40.53 3 40 3H8a5.08 5.08 0 0 0-5 5.15v47.7A5.08 5.08 0 0 0 8 61h36a5.08 5.08 0 0 0 5-5.15V54a13 13 0 0 0 11.86-14.86ZM41 6.46 45.54 11H42a1 1 0 0 1-1-1ZM48 52a11 11 0 0 1-10.84-9.18 1 1 0 0 0-2 .34A13 13 0 0 0 47 54v1.89A3.08 3.08 0 0 1 44 59H8a3.08 3.08 0 0 1-3-3.15V8.15A3.08 3.08 0 0 1 8 5h31v5a3 3 0 0 0 3 3h5v15.06a12.93 12.93 0 0 0-9.79 5.68 1 1 0 0 0 1.66 1.12A11 11 0 1 1 48 52Z"
        fill="var(--palette-gray-8)" className="color000 svgShape"></path>
    </svg>);
}


export default Invoices;