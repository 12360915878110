import React from "react";
function ProfileUpdate(props) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 66 66"
      width={width}
      height={height}
    >
      <path
        d="M25.1 35.5c-3.8 0-6.9 3.1-6.9 6.9V50c0 .6.4 1 1 1h27.5c.6 0 1-.4 1-1v-7.7c0-3.8-3.1-6.9-6.9-6.9H25.1zm20.6 6.8V49H20.3v-6.7c0-2.7 2.2-4.9 4.9-4.9H41c2.6.1 4.7 2.3 4.7 4.9zM24.6 23.4c0 4.6 3.8 8.4 8.4 8.4 4.6 0 8.4-3.8 8.4-8.4S37.6 15 33 15c-4.6 0-8.4 3.7-8.4 8.4zm14.8 0c0 3.5-2.9 6.4-6.4 6.4-3.5 0-6.4-2.9-6.4-6.4S29.5 17 33 17c3.5 0 6.4 2.8 6.4 6.4z"
      />
      <path
        d="M49.9 5.8l6.3-1.2c.6-.1.9-.6.8-1.2-.1-.5-.6-.9-1.2-.8l-8.9 1.8c-.3.1-.5.2-.6.4-.1.2-.2.5-.1.8l1.8 8.9c.1.5.6.9 1.2.8.5-.1.9-.6.8-1.2l-1.3-6.8C58 13 63.6 23.5 62.9 34.8 62 49.9 49.7 62.1 34.6 63 17.4 63.9 3 50.1 3 33 3 16.8 15.9 3.5 32 3c.5 0 1-.5 1-1 0-.6-.5-1-1-1C14.1 1.6-.2 16.9 1.1 35.3 2.2 51 14.9 63.8 30.7 64.9 49.4 66.2 65 51.4 65 33c0-11.2-5.7-21.3-15.1-27.2z"
      />
    </svg>
  );
}

export default ProfileUpdate;
