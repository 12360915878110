import React, { useState } from 'react';
import { KoIcon, KoButton, KoDataTableEdit, KoNano, KoText,  KoSelect} from 'packages';
import styles from './previewTemp.module.scss';

function LineItemEntryCrud({ fields, form}) {
  let colFields = fields?.children?.defaultColumns;
 

  const [dataSource, setDataSource] = useState([{
    key: KoNano('Lit'),
    taxName: null,
    taxRate: null,
    isDefault: false
  }]);

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };

  
  const labelRender = (props) => {
    const { label, value } = props;
   
    return <span>{label} {value}</span>;
  };
  const TaxName = ({ value, onChange }) =>
    <KoSelect value={value}
      showSearch
      mode="multiple"
      labelRender={labelRender}
      onChange={v => onChange(v)}
      placeholder='Select Tax'
      options={ [{
        value: 'hrs',
        label: 'Hours'
      }, {
        value: 'd',
        label: 'Day'
      }]}
    />;

  const DiscountName = ({ value, onChange }) =>
    <KoSelect value={value}
      showSearch
      mode="multiple"
      onChange={v => onChange(v)}
      placeholder='Select Discount'
      options={[]} />;


  const handleFieldWidgets = (colFields) => {
    return colFields?.map(field => {
      let updatedField = { ...field };

      switch (field.widget) {

      case 'options':
        switch (field.dataIndex) {
        case 'tax':
          updatedField.options = [];
          break;
        case 'discount':
          updatedField.options = [];
          break;
        default:
          break;
        }
        break;

      default:
        break;
      }

      return updatedField;
    });
  };
  let col = handleFieldWidgets(colFields);
  col[0].fixed='left';
  const columns = [
    {
      dataIndex: 'amount',
      title: 'Amount',
      editable: true,
      widget: 'amount',
      placeholder: 'Amount',
      width: 130,
      readonly: true,
      align:'right',
      fixed: 'right'
    },
    {
      title: ` `,
      dataIndex: 'operation',
      width: 40,
      fixed: 'right',
      render: (_, record, i) =>
        dataSource.length >= 1 ? (
          <div className={'editTableCol'}>
            <KoButton icon={<KoIcon width={12} height={12} name={'DeleteOutlined'} />} className={styles.actionBtn} onClick={() => handleDelete(record?.key)} label="" />
          </div>
        ) : null
    }
  ];

  col= [...col, ...columns];

  const handleAdd = () => {
    const newData = {
      key: KoNano('Lit'),
      taxName: ``,
      taxRate: '',
      isDefault: false
    };
    setDataSource([...dataSource, newData]);
  };

  const handleSave = (newData) => {
    newData.map(item => {
      const amount = item.quantity * item.rate;
      item['amount'] = amount;
      return item;
    });
    setDataSource([...newData]);
  };

  return (
    <KoDataTableEdit
      dataSource={dataSource}
      form={form}
      rowKey={'key'}
      footer={() => <div className={styles.footer}>
        <KoButton type='primary' icon={<KoIcon color={`var(--palette-gray-7)`} width={17} height={17} name={'PlusOutlined'} />} className={styles.actionBtn} onClick={() => handleAdd()} label={`New Item`} />
        <div className={styles.totalSummary}>
          <div className={styles.tax}>
            <div className={styles.row}>
              <label className={styles.bold}>Sub Total</label>
              <KoText className={`${styles.amountInfo} ${styles.bold}`} text='12537'/>
            </div>
          </div>
          <div className={styles.tax}>
            <div className={styles.row}>
              <TaxName onChange={()=>{}}/>
              <KoText className={`${styles.amountInfo}`} text='12537'/>
            </div>
          </div>
          <div className={styles.tax}>
            <div className={styles.row}>
              <DiscountName onChange={()=>{}}/>
              <KoText className={`${styles.amountInfo}`} text='12537'/>
            </div>
          </div>
          <div className={`${styles.tax} ${styles.totalAmount}`}>
            <div className={styles.row}>
              <label className={styles.bold}>Total (INR)</label>
              <KoText className={`${styles.amountInfo} ${styles.bold}`} text='12537'/>
            </div>
          </div>
        </div>
      </div>}
      onSave={handleSave}
      defaultColumns={col} />
  );
};

export default LineItemEntryCrud;