import React from "react";
function Plus(props) {
  const { width, height, color = '#637481' } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={width} height={height}>
      <path d="M12,18a1,1,0,0,0,1-1V13h4a1,1,0,0,0,0-2H13V7a1,1,0,0,0-2,0v4H7a1,1,0,0,0,0,2h4v4A1,1,0,0,0,12,18Z"
        fill={color}></path>
    </svg>
  );
}


export default Plus;