import React from 'react';
import { Button } from 'antd';
import styles from './button.module.scss';


function KoButton(props) {
  const { className, shape, text, label='', icon, iconBtn, active, circle } = props;

  return (
    iconBtn ?  <Button
      className={`${icon ? styles.wrapper:''} ${className} ${styles.isBtnIcon} ${active ? styles.active : ''}`}
      {...props}/>:
      shape ?<Button {...props} className={`${icon ? circle:''}`} shape={shape}>
        {label}
      </Button>
        :<Button
          className={`${icon ? styles.wrapper:''} ${className}`}{...props}>{label || text}</Button>
    
  );
}

export default KoButton;
