import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { OrgAPI } from "pages/organization/org.service";
const initialState = {
  data: [],
  state: ""
};
export const departmentsSlice = createSlice({
  name: "departments",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(getDepartments.fulfilled, (state, action) => {
        state.data = action.payload;
        state.state = "success";
      })
      .addCase(getDepartments.pending, (state, action) => {
        state.state = "pending";
      });
  }
});

export const getDepartments = createAsyncThunk('departments/get', async(query) => {
  
  let data = await OrgAPI.getOrgDepartment();
  return data['departments'];

});


export default departmentsSlice.reducer;