function NoWorkSpace(props) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width={width}
      height={height}
    >
      <path
        fill="#E8ECFD"
        d="M256.009 396.804h-51.212c-7.075 0-12.8-5.725-12.8-12.8s5.725-12.8 12.8-12.8h51.212c7.075 0 12.8 5.725 12.8 12.8s-5.725 12.8-12.8 12.8zm118.325-96.163c-4.7 0-9.212-2.587-11.463-7.075l-24.438-48.862c-3.162-6.325-.6-14.012 5.725-17.175 6.337-3.15 14-.6 17.175 5.725l24.438 48.862c3.162 6.325.6 14.012-5.725 17.175a12.803 12.803 0 01-5.712 1.35zm-216.7-39.925c-1.925 0-3.875-.438-5.712-1.35-6.325-3.162-8.888-10.85-5.725-17.175l21.55-43.112c3.15-6.325 10.85-8.862 17.175-5.725 6.325 3.162 8.888 10.85 5.725 17.175l-21.55 43.112c-2.238 4.488-6.763 7.075-11.463 7.075z"
        className="colorc9a04f svgShape"
      />
      <circle
        cx={256}
        cy={128}
        r={102.4}
        fill="#FE8F81"
        className="colorfcedab svgShape"
      />
      <circle
        cx={102.4}
        cy={384}
        r={102.4}
        fill="#4D579F"
        className="colorffbc6d svgShape"
      />
      <circle
        cx={409.6}
        cy={384}
        r={102.4}
        fill="#3485E9"
        className="colora194fc svgShape"
      />
    </svg>
  );
}


export default NoWorkSpace;