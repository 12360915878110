import { configs } from 'configuration';
import { KoAPI } from "packages";
const version = configs().API_VERSION;
export const DashboardAPI = {
  getDaysRemaining(id, workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/dashboard/sprint/${id}`, { header: { "workspace": workspaceId } });
  },
  getPercentageByStatus(id, workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/dashboard/issue/status/${id}`, { header: { "workspace": workspaceId } });
  },
  getIssuetypeCountByStatus(status, id, workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/dashboard/issuetype/${status}/${id}`, { header: { "workspace": workspaceId } });
  },
  getTicketInprogressStatistics(id, workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/dashboard/issuetype/inprogress/${id}`, { header: { "workspace": workspaceId } });
  },
  getIssueStatistics(id, workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/dashboard/issuetype/statistics/${id}`, { header: { "workspace": workspaceId } });
  },

  getTicketByPriority(id, workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/dashboard/priority/${id}`, { header: { "workspace": workspaceId } });
  },

  getTicketCountByStatus(id, workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/dashboard/issuetype/statistics/${id}`, { header: { "workspace": workspaceId } });
  },
  

  getWorkspaceById(id){
    return KoAPI.get(`/api/${version}/workspace/${id}`);
  },
  createOrUpdateSprint(data, id, workspaceId) {
    if(id) {
      return KoAPI.update(`/api/${version}/taskplanner/sprint/${id}`, { data: { ...data }, header: { "workspace": workspaceId } });
    } else {
      return KoAPI.post(`/api/${version}/taskplanner/sprint`, { data: { ...data }, header: { "workspace": workspaceId } });
    }
  },
  updateSprintStatus(data, id, workspaceId) {
    return KoAPI.update(`/api/${version}/taskplanner/sprint/status/${id}`, { data: { ...data }, header: { "workspace": workspaceId } });
  },
  getActiveSprints(workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/sprint/active`);
  },
  getSprints(query, workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/sprint`, { query: { ...query }, header: { "workspace": workspaceId } });
  },
  deleteSprint(id, workspaceId) {
    return KoAPI.delete(`/api/${version}/taskplanner/sprint/${id}`);
  },

  createOrUpdateIssues(data, id, workspaceId) {
    if(id) {
      return KoAPI.update(`/api/${version}/taskplanner/ticket/${id}`, { data: { ...data }, header: { "workspace": workspaceId } });
    } else {
      return KoAPI.post(`/api/${version}/taskplanner/ticket`, { data: { ...data }, header: { "workspace": workspaceId } });
    }
  },
  BulkUpdateIssue(data, workspaceId) {
    return KoAPI.update(`/api/${version}/taskplanner/ticket/multiple`, { data: { ...data }, header: { "workspace": workspaceId } });
  },

  uploadAttachments(data){
    return KoAPI.post(`/api/${version}/upload`, { data: { ...data } });
  },
  getIssues(query, workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/ticket`, { query: { ...query }, header: { "workspace": workspaceId } });
  },
  deleteIssues(id, workspaceId) {
    return KoAPI.delete(`/api/${version}/taskplanner/ticket/${id}`, { header: { "workspace": workspaceId } });
  },

  getModules(workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/module`, { header: { "workspace": workspaceId } });
  },
  createOrUpdateModule(data, id, workspaceId) {
    if(id) {
      return KoAPI.update(`/api/${version}/taskplanner/module/${id}`, { data: { ...data }, header: { "workspace": workspaceId } });
    } else {
      return KoAPI.post(`/api/${version}/taskplanner/module`, { data: { ...data },   header: { "workspace": workspaceId } });
    }
  },
  
  getComponents(workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/component`);
  },
  createOrUpdateComponent(data, id, workspaceId) {
    if(id) {
      return KoAPI.update(`/api/${version}/taskplanner/component/${id}`, { data: { ...data }, header: { "workspace": workspaceId } });
    } else {
      return KoAPI.post(`/api/${version}/taskplanner/component`, { data: { ...data }, header: { "workspace": workspaceId } });
    }
  },
  getEnvironments(id) {
    return KoAPI.get(`/api/${version}/apiclient/environment`);
  },
  getServices(id) {
    return KoAPI.get(`/api/${version}/deployment/service`);
  },

  getRelease(workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/release`);
  },
  createOrUpdateRelease(data, id, workspaceId) {
    if(id) {
      return KoAPI.update(`/api/${version}/taskplanner/release/${id}`, { data: { ...data }, header: { "workspace": workspaceId } });
    } else {
      return KoAPI.post(`/api/${version}/taskplanner/release`, { data: { ...data }, header: { "workspace": workspaceId } });
    }
  },
  DeleteRelease(id, workspaceId) {
    return KoAPI.delete(`/api/${version}/taskplanner/release/${id}`, { header: { "workspace": workspaceId } });
  },

  getPullRequest(query, id, workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/${id}/pr`, { header: { "workspace": workspaceId } });
  },
  createOrUpdatePullRequest(data, id, relid, workspaceId) {
    if(id) {
      return KoAPI.update(`/api/${version}/taskplanner/${relid}/pr/${id}`, { data: { ...data }, header: { "workspace": workspaceId } });
    } else {
      return KoAPI.post(`/api/${version}/taskplanner/${relid}/pr`, { data: { ...data }, header: { "workspace": workspaceId } });
    }
  },
  deletePullRequest(relId, id, workspaceId) {
    return KoAPI.delete(`/api/${version}/taskplanner/${relId}/pr/${id}`, { header: { "workspace": workspaceId } });
  },
  getRecentPullRequest(query, workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/pr/recent`, { query: { ...query }, header: { "workspace": workspaceId } });
  },

  GetIssueStatistics(id, workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/ticket/issuetype/statistics/${id}`, { header: { "workspace": workspaceId } });
  },
 
  createOrUpdateClient(data, id) {
    if(id){
      return KoAPI.update(`/api/${version}/workspace/${id}`, { data });
    }else{
      return KoAPI.post(`/api/${version}/workspace`, { data });
    }
  }

};