import React from "react";
function IssueRised(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 14 14" fill="none">
      <g clipPath="url(#clip0_204_496)">
        <path d="M13.5106 5.75916L8.23783 0.48641C7.57633 -0.174507 6.42366 -0.174507 5.76275 0.48641L0.489414 5.75916C-0.193086 6.44166 -0.193086 7.55174 0.489414 8.23424L5.76216 13.507C6.09291 13.8372 6.53216 14.0197 7 14.0197C7.46783 14.0197 7.90708 13.8377 8.23725 13.507L13.5106 8.23424C14.1931 7.55174 14.1931 6.44166 13.5106 5.75916ZM7.58333 10.4999H6.41666V9.33324H7.58333V10.4999ZM7.58333 8.16658H6.41666V3.49991H7.58333V8.16658Z" fill="var(--palette-primary-darker)" />
      </g>
      <defs>
        <clipPath id="clip0_204_496">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}


export default IssueRised;