import React from "react";
import { KoTitle, KoRow, KoSearch, KoButton, KoDropdown, KoIcon } from "packages";
import styles from './toolbar.module.scss';
function KoToolBar(props) {
  const { title, extraComponentBefore, extraComponentAfter, backBtn,
    buttonList = [{
      label: 'Label',
      type: 'primary',
      onClick: () => { }
    }],
    filter,
    search = { visible: true, onChange: () => { }, placeholder: 'Search by any parameter' }, className } = props;
  return (
    <KoRow className={`${styles.wrapper} ${className? className:''} ${backBtn ? styles.backBtn : styles.noBackBtn}`}>
      {backBtn &&   <KoButton color="default" variant="filled" iconBtn={true} icon={<KoIcon name='ArrowLeftOutlined' />} onClick={() => window.history.back()}/>}
      
      {title ? <KoTitle level={5} text={title} /> : <div>&nbsp;</div>}
      <div className={styles.rightContent}>
        {extraComponentBefore}
        {search?.visible && <KoSearch allowClear={true}  showSearch onChange={(e)=>search?.onChange(e.target.value)} placeholder={search?.placeholder} />}
        {filter?.visible && <KoDropdown
          menu={{
            items: filter?.options,
            onClick: (event) => {filter?.onClick(event.key);}
          }}
          overlayClassName={styles.actionDropdown}
          placement="bottomRight">
          <KoButton iconBtn={true} icon={ <KoIcon name={`FilterOutlined`}/>}/>
        </KoDropdown>}
        {extraComponentAfter}
        {buttonList?.map((btn) =>
          <div key={btn.label} className={styles.actionList}>
            <KoButton {...btn} />
          </div>
        )}
      </div>
      
    </KoRow>
  );
}

export default KoToolBar;