import React from "react";
function Invited(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icons/invited" clipPath="url(#clip0_110_53)">
        <path id="Vector" d="M13.3333 5.33333C11.86 5.33333 10.6667 4.14 10.6667 2.66667C10.6667 1.19333 11.86 0 13.3333 0C14.8067 0 16 1.19333 16 2.66667C16 4.14 14.8067 5.33333 13.3333 5.33333ZM8 10.28C8.51333 10.28 9.02667 10.0867 9.41333 9.69333L12.5267 6.58C10.7067 6.20667 9.33333 4.59333 9.33333 2.66C9.33333 2.43333 9.36 2.21333 9.39333 1.99333H3.33333C2.12667 1.99333 1.08 2.64 0.493333 3.6L6.58667 9.7C6.97333 10.0867 7.48667 10.2867 8 10.2867V10.28ZM14.5067 6.48667L10.3533 10.64C9.70667 11.2867 8.84667 11.6133 7.99333 11.6133C7.14 11.6133 6.28667 11.2867 5.63333 10.64L0.0333333 5.02667C0.0266667 5.12667 0 5.22667 0 5.33333V12.6667C0 14.5067 1.49333 16 3.33333 16H12.6667C14.5067 16 16 14.5067 16 12.6667L15.9867 5.64667C15.56 6.02667 15.06 6.31333 14.5067 6.48667Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_110_53">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}


export default Invited;