import React from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
function KoYear(props) {
  console.log('props', props?.value);
  return (
    <DatePicker value={props?.value ? dayjs(props.value) : null}  onChange={props?.onChange} picker="year" />
  );
}

export default KoYear;
