import React from "react";
function DownArrow(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={width} height={height}>
      <path fill="none" stroke="#787878" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M23 6 12 18 1 6"
        data-name="02-Down Arrow" className="colorStroke000 svgStroke"></path>
    </svg>
  );
}
export default DownArrow;