import React from "react";
import styles from './ticketLink.module.scss';
import { Link } from "react-router-dom";
import { KoText } from "packages/typography";
import KoIcon from "packages/icon";

function FileLink(props) {
  const { data, onCellClick, row } = props;
  return (
    <div className={styles.wrapper}>
      <Link target="_blank" to={`/open/file/${data}`}>
        <KoText className={styles.text} text={data} copyable={{
          tooltips: false,
          text: `${window.location.origin}/open/ticket/${data}`
        }}/>
      </Link>
      <div onClick={()=>onCellClick && onCellClick(data, row, 'source')} className={styles.icon}>
        <KoIcon name='QuickViewOutlined'/>
      </div>
     
    </div>
  );
}
export default FileLink;
