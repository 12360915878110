import { KoIcon, KoText, KoTooltips } from "packages";
import styles from './toolbar.module.scss';
import { getCookie } from "./utils/cookies";
import { Link } from "react-router-dom";

const ISSUETYPE = [{
  label: 'Epic',
  value: 1,
  key: 1,
  icon: <KoIcon name="EpicFilled" />
}, {
  label: 'Story',
  value: 2,
  key: 2,
  icon: <KoIcon name="StoryFilled" />
}, {
  label: 'Task',
  value: 3,
  key: 3,
  icon: <KoIcon name="TaskFilled" />
}, {
  label: 'Subtask',
  value: 4,
  key: 4,
  icon: <KoIcon name="SubtaskFilled" />
}, {
  label: 'Bug',
  value: 5,
  key: 5,
  icon: <KoIcon name="BugFilled" />
}, {
  label: 'Improvement',
  value: 6,
  key: 6,
  icon: <KoIcon name="ImprovementsFilled" />
}, {
  label: 'Feature request',
  value: 7,
  key: 7,
  icon: <KoIcon name="FeatureRequestFilled" />
}, {
  label: 'Change request',
  value: 8,
  key: 8,
  icon: <KoIcon name="ChangerequestFilled" />
}, {
  label: 'Support',
  value: 9,
  key: 9,
  icon: <KoIcon name="SupportFilled" />
}];

const ISSUETYPEWITHOUTSUBTASK = [{
  label: 'Epic',
  value: 1,
  key: 1,
  icon: <KoIcon name="EpicFilled" />
}, {
  label: 'Story',
  value: 2,
  key: 2,
  icon: <KoIcon name="StoryFilled" />
}, {
  label: 'Task',
  value: 3,
  key: 3,
  icon: <KoIcon name="TaskFilled" />
}, {
  label: 'Bug',
  value: 5,
  key: 5,
  icon: <KoIcon name="BugFilled" />
}, {
  label: 'Improvement',
  value: 6,
  key: 6,
  icon: <KoIcon name="ImprovementsFilled" />
}, {
  label: 'Feature request',
  value: 7,
  key: 7,
  icon: <KoIcon name="FeatureRequestFilled" />
}, {
  label: 'Change request',
  value: 8,
  key: 8,
  icon: <KoIcon name="ChangerequestFilled" />
}, {
  label: 'Support',
  value: 9,
  key: 9,
  icon: <KoIcon name="SupportFilled" />
}];


const ISSUETYPEFORCOB = [{
  label: 'Bug',
  value: 5,
  key: 5,
  icon: <KoIcon name="BugFilled" />
}, {
  label: 'Improvement',
  value: 6,
  key: 6,
  icon: <KoIcon name="ImprovementsFilled" />
}, {
  label: 'Feature request',
  value: 7,
  key: 7,
  icon: <KoIcon name="FeatureRequestFilled" />
}, {
  label: 'Change request',
  value: 8,
  key: 8,
  icon: <KoIcon name="ChangerequestFilled" />
}, {
  label: 'Support',
  value: 9,
  key: 9,
  icon: <KoIcon name="SupportFilled" />
}];

const PRIORITY = [{
  label: <div className={styles.priorityWrapper}>
    <div className={`${styles.priority}`}>
      <div className={`${styles.small} ${styles.Highest}`} />
    </div>
    <KoText text='Highest' />
  </div>,
  value: 5,
  key: 5
}, {
  label: <div className={styles.priorityWrapper}>
    <div className={`${styles.priority}`}>
      <div className={`${styles.small} ${styles.High}`} />
    </div>
    <KoText text='High' />
  </div>,
  value: 4,
  key: 4
}, {
  label: <div className={styles.priorityWrapper}>
    <div className={`${styles.priority}`}>
      <div className={`${styles.small} ${styles.Medium}`} />
    </div>
    <KoText text='Medium' />
  </div>,
  value: 3,
  key: 3
}, {
  label: <div className={styles.priorityWrapper}>
    <div className={`${styles.priority}`}>
      <div className={`${styles.small} ${styles.Low}`} />
    </div>
    <KoText text='Low' />
  </div>,
  value: 2,
  key: 2
}, {
  label: <div className={styles.priorityWrapper}>
    <div className={`${styles.priority}`}>
      <div className={`${styles.small} ${styles.Lowest}`} />
    </div>
    <KoText text='Lowest' />
  </div>,
  value: 1,
  key: 1
}];

const TASKSTATUSLIST = [{
  label: 'Todo',
  name: 'Todo',
  value: 1,
  background: 'rgba(145, 158, 171, 0.16)',
  color: '#637381'
},
{
  label: 'In Progress',
  value: 2,
  name: 'In Progress',
  background: 'rgba(255, 239, 179, 0.16)',
  color: '#f57f17'
},
{
  label: 'Blocked',
  value: 3,
  name: 'Blocked',
  background: 'rgba(255, 171, 145, 0.16)',
  color: '#c62828'
},
{
  label: 'Ready For QA',
  value: 4,
  name: 'Ready For QA',
  background: 'rgba(144, 202, 249, 0.16)',
  color: '#0d47a1'
},
{
  label: 'In Review',
  value: 5,
  name: 'In Review',
  background: 'rgba(209, 164, 224, 0.16)',
  color: '#6a1b9a'
},
{
  label: 'On Hold',
  value: 6,
  name: 'On Hold',
  background: 'rgba(255, 204, 128, 0.16)',
  color: '#ff8f00'
},
{
  label: 'QA Failed',
  value: 7,
  name: 'QA Failed',
  background: 'rgba(255, 171, 145, 0.16)',
  color: '#c62828'
},
{
  label: 'Reopen',
  value: 12,
  name: 'Reopen',
  background: 'rgba(145, 158, 171, 0.16)',
  color: '#637381'
},
{
  label: 'Abandoned',
  value: 8,
  name: 'Abandoned',
  background: 'rgba(244, 143, 177, 0.16)',
  color: '#d81b60'
},
{
  label: 'Deferred',
  value: 9,
  name: 'Deferred',
  background: 'rgba(176, 190, 195, 0.16)',
  color: '#37474f'
},
{
  label: 'Done',
  value: 10,
  name: 'Done',
  background: 'rgba(165, 214, 167, 0.16)',
  color: '#388e3c'
},
{
  label: 'Closed',
  value: 11,
  name: 'Closed',
  background: 'rgba(200, 230, 201, 0.16)',
  color: '#4caf50'
}];

const STATUS = [{
  label: 'Todo',
  name: 'Todo',
  value: 1,
  background: 'rgba(145, 158, 171, 0.16)',
  color: '#637381'
},
{
  label: 'In Progress',
  value: 2,
  name: 'In Progress',
  background: 'rgba(255, 239, 179, 0.16)',
  color: '#f57f17'
},
{
  label: 'Blocked',
  value: 3,
  name: 'Blocked',
  background: 'rgba(255, 171, 145, 0.16)',
  color: '#c62828'
},
{
  label: 'Ready For QA',
  value: 4,
  name: 'Ready For QA',
  background: 'rgba(144, 202, 249, 0.16)',
  color: '#0d47a1'
},
{
  label: 'In Review',
  value: 5,
  name: 'In Review',
  background: 'rgba(209, 164, 224, 0.16)',
  color: '#6a1b9a'
},
{
  label: 'On Hold',
  value: 6,
  name: 'On Hold',
  background: 'rgba(255, 204, 128, 0.16)',
  color: '#ff8f00'
},
{
  label: 'QA Failed',
  value: 7,
  name: 'QA Failed',
  background: 'rgba(255, 171, 145, 0.16)',
  color: '#c62828'
},
{
  label: 'Reopen',
  value: 12,
  name: 'Reopen',
  background: 'rgba(145, 158, 171, 0.16)',
  color: '#637381'
},
{
  label: 'Abandoned',
  value: 8,
  name: 'Abandoned',
  background: 'rgba(244, 143, 177, 0.16)',
  color: '#d81b60'
},
{
  label: 'Deferred',
  value: 9,
  name: 'Deferred',
  background: 'rgba(176, 190, 195, 0.16)',
  color: '#37474f'
},
{
  label: 'Done',
  value: 10,
  name: 'Done',
  background: 'rgba(165, 214, 167, 0.16)',
  color: '#388e3c'
},
{
  label: 'Closed',
  value: 11,
  name: 'Closed',
  background: 'rgba(200, 230, 201, 0.16)',
  color: '#4caf50'
}].map(status => ({
  label: (
    <div className={styles.statusWrapper}>
      <div
        className={`${styles.status}`}
        style={{
          background: status.color,
          color: status.background
        }}
      />
      <KoText text={status.label} />
    </div>
  ),
  value: status.value,
  key: status.value
}));


const SPRINTSTATUS = [
  {
    label: 'Planning',
    key: 1,
    value: 1,
    icon: <KoIcon name="ActiveSprintOutlined" />
  }, {
    label: 'Planning complete',
    key: 5,
    value: 5,
    icon: <KoIcon name="ActiveSprintOutlined" />
  },{
    label: 'Active',
    key: 2,
    value: 2,
    icon: <KoIcon name="ActiveSprintOutlined" />
  }, {
    label: 'Suspended',
    key: 3,
    value: 3,
    icon: <KoIcon name="LastSprintOutlined" />
  }, {
    label: 'Closed',
    key: 4,
    value: 4,
    icon: <KoIcon name="ClosedSprintOutlined" />
  }
];


const PAYMENTTERMS = [{ value: 1, label: "Every Week" },
  { value: 2, label: "Two weeks once" },
  { value: 3, label: "Month" },
  { value: 4, label: "Two month Once" },
  { value: 5, label: "Three Months Once" },
  { value: 6, label: "Six Months once" },
  { value: 7, label: "Year" },
  { value: 8, label: "Two Year" },
  { value: 9, label: "Three year" },
  { value: 10, label: "Custom" }
];

const INVOICESTATUS = [
  { value: -1, label: "Deleted" },
  { value: 0, label: "Draft" },
  { value: 1, label: "In Review" },
  { value: 2, label: "Approved" },
  { value: 3, label: "Sent" },
  { value: 4, label: "In Clinet Review" },
  { value: 5, label: "Clinet Approved" },
  { value: 6, label: "Paid" },
  { value: 7, label: "Rejected" },
  { value: 8, label: "Pending" },
  { value: 9, label: "Overdue" },
  { value: 10, label: "Query" }
];

const TIMESPEND = [
  { value: '1m', label: '1 Minute', shortLabel: '1m' },
  { value: '2m', label: '2 Minutes', shortLabel: '2m' },
  { value: '3m', label: '3 Minutes', shortLabel: '3m' },
  { value: '5m', label: '5 Minutes', shortLabel: '5m' },
  { value: '10m', label: '10 Minutes', shortLabel: '10m' },
  { value: '15m', label: '15 Minutes', shortLabel: '15m' },
  { value: '20m', label: '20 Minutes', shortLabel: '20m' },
  { value: '25m', label: '25 Minutes', shortLabel: '25m' },
  { value: '30m', label: '30 Minutes', shortLabel: '30m' },
  { value: '35m', label: '35 Minutes', shortLabel: '35m' },
  { value: '40m', label: '40 Minutes', shortLabel: '40m' },
  { value: '45m', label: '45 Minutes', shortLabel: '45m' },
  { value: '50m', label: '50 Minutes', shortLabel: '50m' },
  { value: '55m', label: '55 Minutes', shortLabel: '55m' },
  { value: '60m', label: '1 Hour', shortLabel: '1h' },
  { value: '90m', label: '1 Hour 30 Minutes', shortLabel: '1.5h' },
  { value: '2h', label: '2 Hours', shortLabel: '2h' },
  { value: '2h30m', label: '2 Hours 30 Minutes', shortLabel: '2.5h' },
  { value: '3h', label: '3 Hours', shortLabel: '3h' },
  { value: '3h30m', label: '3 Hours 30 Minutes', shortLabel: '3.5h' },
  { value: '4h', label: '4 Hours', shortLabel: '4h' },
  { value: '4h30m', label: '4 Hours 30 Minutes', shortLabel: '4.5h' },
  { value: '5h', label: '5 Hours', shortLabel: '5h' },
  { value: '5h30m', label: '5 Hours 30 Minutes', shortLabel: '5.5h' },
  { value: '6h', label: '6 Hours', shortLabel: '6h' },
  { value: '6h30m', label: '6 Hours 30 Minutes', shortLabel: '6.5h' },
  { value: '7h', label: '7 Hours', shortLabel: '7h' },
  { value: '7h30m', label: '7 Hours 30 Minutes', shortLabel: '7.5h' },
  { value: '8h', label: '8 Hours', shortLabel: '8h' },
  { value: '8h30m', label: '8 Hours 30 Minutes', shortLabel: '8.5h' },
  { value: '9h', label: '9 Hours', shortLabel: '9h' },
  { value: '9h30m', label: '9 Hours 30 Minutes', shortLabel: '9.5h' },
  { value: '10h', label: '10 Hours', shortLabel: '10h' },
  { value: '10h30m', label: '10 Hours 30 Minutes', shortLabel: '10.5h' },
  { value: '11h', label: '11 Hours', shortLabel: '11h' },
  { value: '11h30m', label: '11 Hours 30 Minutes', shortLabel: '11.5h' },
  { value: '12h', label: '12 Hours', shortLabel: '12h' },
  { value: '1d', label: '1 Day', shortLabel: '1d' },
  { value: '1.5d', label: '1.5 Days', shortLabel: '1.5d' },
  { value: '2d', label: '2 Days', shortLabel: '2d' },
  { value: '2.5d', label: '2.5 Days', shortLabel: '2.5d' },
  { value: '3d', label: '3 Days', shortLabel: '3d' },
  { value: '3.5d', label: '3.5 Days', shortLabel: '3.5d' },
  { value: '4d', label: '4 Days', shortLabel: '4d' },
  { value: '4.5d', label: '4.5 Days', shortLabel: '4.5d' },
  { value: '5d', label: '5 Days', shortLabel: '5d' },
  { value: '5.5d', label: '5.5 Days', shortLabel: '5.5d' },
  { value: '6d', label: '6 Days', shortLabel: '6d' },
  { value: '6.5d', label: '6.5 Days', shortLabel: '6.5d' },
  { value: '7d', label: '7 Days', shortLabel: '7d' },
  { value: '1w', label: '1 Week', shortLabel: '1w' },
  { value: '2w', label: '2 Weeks', shortLabel: '2w' },
  { value: '3w', label: '3 Weeks', shortLabel: '3w' },
  { value: '4w', label: '4 Weeks', shortLabel: '4w' },
  { value: '1mo', label: '1 Month', shortLabel: '1mo' },
  { value: '1.5mo', label: '1.5 Months', shortLabel: '1.5mo' },
  { value: '2mo', label: '2 Months', shortLabel: '2mo' },
  { value: '2.5mo', label: '2.5 Months', shortLabel: '2.5mo' },
  { value: '3mo', label: '3 Months', shortLabel: '3mo' },
  { value: '3.5mo', label: '3.5 Months', shortLabel: '3.5mo' },
  { value: '4mo', label: '4 Months', shortLabel: '4mo' },
  { value: '4.5mo', label: '4.5 Months', shortLabel: '4.5mo' },
  { value: '5mo', label: '5 Months', shortLabel: '5mo' },
  { value: '5.5mo', label: '5.5 Months', shortLabel: '5.5mo' },
  { value: '6mo', label: '6 Months', shortLabel: '6mo' },
  { value: '6.5mo', label: '6.5 Months', shortLabel: '6.5mo' },
  { value: '7mo', label: '7 Months', shortLabel: '7mo' },
  { value: '7.5mo', label: '7.5 Months', shortLabel: '7.5mo' },
  { value: '8mo', label: '8 Months', shortLabel: '8mo' },
  { value: '8.5mo', label: '8.5 Months', shortLabel: '8.5mo' },
  { value: '9mo', label: '9 Months', shortLabel: '9mo' },
  { value: '9.5mo', label: '9.5 Months', shortLabel: '9.5mo' },
  { value: '10mo', label: '10 Months', shortLabel: '10mo' },
  { value: '10.5mo', label: '10.5 Months', shortLabel: '10.5mo' },
  { value: '11mo', label: '11 Months', shortLabel: '11mo' },
  { value: '12mo', label: '12 Months', shortLabel: '12mo' },
  { value: '1y', label: '1 Year', shortLabel: '1y' },
  { value: '1.5y', label: '1.5 Years', shortLabel: '1.5y' },
  { value: '2y', label: '2 Years', shortLabel: '2y' },
  { value: '2.5y', label: '2.5 Years', shortLabel: '2.5y' },
  { value: '3y', label: '3 Years', shortLabel: '3y' },
  { value: '3.5y', label: '3.5 Years', shortLabel: '3.5y' },
  { value: '4y', label: '4 Years', shortLabel: '4y' },
  { value: '4.5y', label: '4.5 Years', shortLabel: '4.5y' },
  { value: '5y', label: '5 Years', shortLabel: '5y' }
];



const getIssueTypeDetais=(issueType)=>{
  return ISSUETYPE.find((item)=>item.key===issueType);
};

const getTicketBreadCrumb=(row)=>{
  let crums = [];
  if (row?.epic) {
    crums.push({
      title: <div className={'ticketTypeBreadcrumb'}>
        {getIssueTypeDetais(1)?.icon}
        <Link to={`${window.location.origin}/open/ticket/${getCookie("accountId")}/${row?.workspace}/${row?.epic}`}
          target="_blank" >
          <KoText text={row.epic}/>
        </Link>
        <KoText
          copyable={{
            text: `${window.location.origin}/open/ticket/${getCookie("accountId")}/${row?.workspace}/${row?.epic}`
          }}
        />
      </div>
    });
  }
  if (row?.parentId) {
    crums.push({
      title: <div className={'ticketTypeBreadcrumb'}>
        {getIssueTypeDetais(row?.parentIssueType)?.icon}
        <Link to={`${window.location.origin}/open/ticket/${getCookie("accountId")}/${row?.workspace}/${row?.parentId}`}
          target="_blank">

          <KoText text={row.parentId}/>
        </Link>
        <KoText
          copyable={{
            text: `${window.location.origin}/open/ticket/${getCookie("accountId")}/${row?.workspace}/${row?.parentId}`
          }}
        />
      </div>
    });
  }
  if (row?.ticketId) {
    crums.push({
      title: <div className={'ticketTypeBreadcrumb'}>
        {getIssueTypeDetais(row?.issueType)?.icon}
        <Link to={`${window.location.origin}/open/ticket/${getCookie("accountId")}/${row?.workspace}/${row?.ticketId}`}
          target="_blank">
          <KoTooltips title={'New window'}>
            <KoText text={row.ticketId}/>
          </KoTooltips>
        </Link>
        <KoText
          copyable={{
            text: `${window.location.origin}/open/ticket/${getCookie("accountId")}/${row?.workspace}/${row?.ticketId}`
          }}
        />
      </div>
    });
  }
  return crums;
};

const PRO_ITEMS_COLUMNS = [
  {
    name: "ticketId",
    id: "ticketId",
    label: "Ticket Id",
    width:110,
    widget: "ticketLink",
    fixed: true
  },
  {
    name: "workspace",
    id: "workspace",
    label: "Workspace",
    width:150,
    widget: "text"
  },
  {
    name: "sprint",
    id: "sprint",
    label: "Sprint",
    width:150,
    widget: "text"
  },
  {
    name: "summary",
    id: "summary",
    label: "Summary",
    width:200,
    widget: "text"
  },
  {
    name: "priority",
    id: "priority",
    label: "Priority",
    widget: "priority",
    width:130
  },
  {
    name: "status",
    id: "status",
    label: "Status",
    widget: "taskstatus",
    isLog:true,
    width:100,
    fixed:'right'
  }];


export {
  getIssueTypeDetais,TIMESPEND, getTicketBreadCrumb, INVOICESTATUS, ISSUETYPE, ISSUETYPEFORCOB, ISSUETYPEWITHOUTSUBTASK,
  PAYMENTTERMS, PRIORITY, PRO_ITEMS_COLUMNS, SPRINTSTATUS, STATUS, TASKSTATUSLIST
};
