import { KoForm, KoFormBuilder, KoSelect, KoUserSelect } from 'packages';
import {  Date, Priority,  Sprint, TaskStatus } from './components';
import styles from './curd.module.scss';
import { TIMESPEND } from 'shared/commonConstants';


const SubtaskCrud = ({ form, handleFinish, profile}) => {
  
  const Assignees = ({ value, onChange }) =>
    <KoUserSelect  value={value} onChange={v => onChange(v)} users={profile} placeholder='Select assignee' />;


  const Reporter = ({ value, onChange }) =>
    <KoUserSelect  value={value} onChange={v => onChange(v)} users={profile} placeholder='Select reporter' />;

  const TimeSpentSelect = ({ value, onChange }) => {
    return (
      <KoSelect
        value={value || undefined}
        placeholder="Ex: 1w 3d 6h 20m"
        showSearch
        onChange={(v) => onChange(v)}
        options={TIMESPEND}     />
    );
  };

  const subTaskMeta = {
    columns: 6,
    formItemLayout: null,
    colon: true,
    fields: [
      {
        key: 'summary', placeholder: 'Enter issue summary', label: 'Summary', colSpan: 6, rules: [
          {
            required: true,
            message: `Please enter summary.`
          }
        ]
      },
      {
        key: 'status', label: 'Status', colSpan: 2,
        widget: TaskStatus,
        forwardRef: true
      },
      {
        key: 'priority', label: 'Priority', colSpan: 2,
        widget: Priority,
        forwardRef: true
      },
      {
        key: 'sprint', label: 'Sprint', colSpan: 2,
        widget: Sprint,
        forwardRef: true
      },
      {
        key: 'startDate', placeholder: 'Enter Start Date', label: 'Start Date', colSpan: 3, widget: Date
      },
    
      {
        key: 'endDate', placeholder: 'Enter End Date', label: 'Due Date', colSpan: 3, widget: Date
      },
     
      {
        key: 'assignee', label: 'Assignee', colSpan: 3,
        widget: Assignees,
        forwardRef: true
      },
      {
        key: 'reporter', label: 'Reporter', colSpan:3,
        widget: Reporter,
        rules: [
          {
            required: true,
            message: `Please select Reporter.`
          }
        ]
      },
      {
        key: 'timeSpent', label: 'Time spent', colSpan: 3, placeholder: 'Ex: 1w 3d 6h 20m',  widget: TimeSpentSelect
      },
      {
        key: 'timeRemaining', label: 'Time remaining', colSpan: 3, placeholder: 'Ex: 1w 3d 6h 20m',  widget: TimeSpentSelect
      },
      {
        key: 'lookupId', label: 'Lookup Id', colSpan: 0, className: 'metaFieldHide'
      }
    ]
  };

  return (<div className={styles.subTaskWrapper}>
    <KoForm form={form} layout="vertical" onFinish={handleFinish}>
      <KoFormBuilder form={form} meta={subTaskMeta} />
    </KoForm>
  </div>);
};

export default SubtaskCrud;
