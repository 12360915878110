import React from "react";
function QuickView(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width={width} height={height}>
      <path fill="var(--palette-primary-darker)"
        d="M19.25 9a.75.75 0 0 0 1.5 0V5A1.75 1.75 0 0 0 19 3.25h-4a.75.75 0 0 0 0 1.5h3.19l-6.72 6.72a.75.75 0 1 0 1.06 1.06l6.72-6.72V9Z"
        className="color000 svgShape"></path>
      <path fill="var(--palette-primary-darker)" fillRule="evenodd"
        d="M3.25 6A2.75 2.75 0 0 1 6 3.25h5a.75.75 0 0 1 0 1.5H6c-.69 0-1.25.56-1.25 1.25v12c0 .69.56 1.25 1.25 1.25h12c.69 0 1.25-.56 1.25-1.25v-5a.75.75 0 0 1 1.5 0v5A2.75 2.75 0 0 1 18 20.75H6A2.75 2.75 0 0 1 3.25 18V6Z"
        clipRule="evenodd" className="color000 svgShape"></path>
    </svg>  );
}


export default QuickView;