import React from "react";
import KoIcon from "../../../icon";
import styles from './comments.module.scss';

function Comments(props) {
  return (
    <div className={styles.commentsWrapper}>
      <div className={styles.circle}>
        <KoIcon name={props.data.length > 0 ? 'comments' : 'nocomment'} size={{width:22, height:22}}/>
        <div className={styles.text}>{props.data.length > 0 ? props.data.length : ''}</div>
      </div>
    </div>
  );
}
export default Comments;
