import React from "react";
function Dev(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" width={width} height={height}>
      <path fill="#44464a"
        d="M12.39 11.46h-1.33c-.56 0-1-.44-1-1 0-.55.44-1 1-1h1.33c.55 0 1 .45 1 1 0 .56-.45 1-1 1zm5.03 0h-1.34c-.55 0-1-.44-1-1 0-.55.45-1 1-1h1.34c.55 0 1 .45 1 1 0 .56-.45 1-1 1zm5.02 0h-1.33c-.55 0-1-.44-1-1 0-.55.45-1 1-1h1.33c.55 0 1 .45 1 1 0 .56-.45 1-1 1zm26.51 0H29.12c-.55 0-1-.44-1-1 0-.55.45-1 1-1h19.83c.55 0 1 .45 1 1 0 .56-.45 1-1 1zM53.7 5.9c-.53-.39-1.18-.63-1.89-.63H8.2C6.43 5.27 5 6.71 5 8.47v6.19h50V8.47a3.2 3.2 0 0 0-1.3-2.57z"
      ></path>
      <path fill="#3773b5"
        d="M5 16.66v34.89c0 1.76 1.43 3.19 3.2 3.19h43.61c1.76 0 3.19-1.43 3.19-3.19V16.66H5zm10.68 6.37h3.38c.55 0 1 .45 1 1s-.45 1-1 1h-3.38c-.56 0-1-.45-1-1s.44-1 1-1zm0 9.24h7.17c.55 0 1 .44 1 1 0 .55-.45 1-1 1h-7.17c-.56 0-1-.45-1-1 0-.56.44-1 1-1zm-2.17 8.77-2.17 2.17c-.2.2-.45.29-.71.29a.96.96 0 0 1-.7-.29.996.996 0 0 1 0-1.41l1.46-1.46-1.46-1.46c-.39-.39-.39-1.02 0-1.42a.996.996 0 0 1 1.41 0l2.17 2.17c.18.19.29.44.29.71 0 .26-.11.52-.29.7zm0-9.24-2.17 2.16c-.2.2-.45.3-.71.3a.97.97 0 0 1-.7-.3.996.996 0 0 1 0-1.41l1.46-1.46-1.46-1.46a.996.996 0 1 1 1.41-1.41l2.17 2.16c.18.19.29.44.29.71 0 .27-.11.52-.29.71zm0-9.23-2.17 2.17a1.001 1.001 0 0 1-1.41 0c-.39-.39-.39-1.03 0-1.42l1.46-1.46-1.46-1.46a.996.996 0 1 1 1.41-1.41l2.17 2.17c.39.39.39 1.01 0 1.41zm10.83 20.92h-8.66c-.56 0-1-.44-1-1 0-.55.44-1 1-1h8.66c.55 0 1 .45 1 1 0 .56-.45 1-1 1zm26.76-1.82-.46 2c-.24 1.07-1.25 1.8-2.36 1.71l-.77-.08h-.02c-.08 0-.15.07-.2.14-.32.48-.69.95-1.1 1.37-.06.06-.11.16-.09.25l.25.74a2.22 2.22 0 0 1-1.15 2.68l-1.84.89c-.3.15-.63.22-.95.22-.73 0-1.43-.36-1.86-1l-.42-.65c-.05-.06-.11-.11-.19-.09-.62.06-1.25.06-1.87 0a.208.208 0 0 0-.19.09l-.43.65a2.22 2.22 0 0 1-2.81.78l-1.84-.89a2.225 2.225 0 0 1-1.14-2.68l.24-.74c.04-.11-.05-.22-.08-.25a9.57 9.57 0 0 1-1.1-1.37c-.05-.07-.14-.15-.22-.14l-.77.08c-1.11.09-2.12-.64-2.36-1.71l-.46-2c-.24-1.08.35-2.18 1.38-2.56l.74-.28c.08-.03.13-.13.14-.21.08-.59.2-1.17.38-1.72.03-.08.03-.2-.03-.26l-.54-.56a2.22 2.22 0 0 1-.13-2.91l1.28-1.6c.68-.86 1.91-1.08 2.86-.51l.67.4c.08.05.19.01.26-.03.5-.3 1.03-.56 1.58-.76.08-.03.17-.1.19-.19l.1-.77a2.21 2.21 0 0 1 2.19-1.92h2.05c1.1 0 2.04.82 2.19 1.92l.1.77c.01.09.1.16.18.19.55.2 1.08.46 1.58.76.08.04.19.08.26.03l.67-.4c.95-.57 2.18-.35 2.87.51l1.28 1.6c.68.86.63 2.12-.14 2.91l-.53.56c-.07.06-.06.18-.03.26.17.55.3 1.13.38 1.71.01.09.05.19.14.22l.73.28c1.04.38 1.63 1.49 1.39 2.56z"
      ></path>
      <path fill="#3773b5"
        d="M44.52 39.97c0 3.04-2.48 5.52-5.52 5.52-3.04 0-5.51-2.48-5.51-5.52 0-3.04 2.47-5.52 5.51-5.52 3.04 0 5.52 2.47 5.52 5.52z"
      ></path>
    </svg>
  );
}


export default Dev;