import React from "react";
function Bank(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} fill="var(--palette-primary-darker)" viewBox="0 0 24 24">
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier"><path d="M4 10v7h3v-7H4zm6 0v7h3v-7h-3zM2 22h19v-3H2v3zm14-12v7h3v-7h-3zm-4.5-9L2 6v2h19V6l-9.5-5z"></path>
      </g>
    </svg>

  );
}


export default Bank;