import React from "react";
function AddtoGroups(props) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 128 128"
      width={width}
      height={height}
    >
      <path
        d="M113 30c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15zm0-28c7.168 0 13 5.832 13 13a12.94 12.94 0 01-3 8.295v-.24c0-3.375-1.768-6.256-4.444-7.814a7.04 7.04 0 01-1.496 1.45c2.373 1.114 3.94 3.481 3.94 6.364V25c0 .07.026.13.04.196a12.922 12.922 0 01-16.08 0c.014-.066.04-.127.04-.196v-1.945c0-2.883 1.567-5.25 3.938-6.365a7.075 7.075 0 01-1.495-1.45c-2.675 1.558-4.443 4.44-4.443 7.815v.24A12.936 12.936 0 01100 15c0-7.168 5.832-13 13-13z"
      />
      <path
        d="M113 16a5.001 5.001 0 10-5-5c0 2.76 2.24 5 5 5zm0-8c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3zM15 30c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15C6.716 0 0 6.716 0 15c0 8.284 6.716 15 15 15zm0-28c7.168 0 13 5.832 13 13a12.94 12.94 0 01-3 8.295v-.24c0-3.375-1.768-6.256-4.444-7.814a7.04 7.04 0 01-1.496 1.45c2.373 1.114 3.94 3.481 3.94 6.364V25c0 .07.026.13.04.196a12.922 12.922 0 01-16.08 0c.014-.066.04-.127.04-.196v-1.945c0-2.883 1.567-5.25 3.938-6.365a7.075 7.075 0 01-1.495-1.45C6.768 16.798 5 19.68 5 23.055v.24A12.936 12.936 0 012 15C2 7.832 7.832 2 15 2z"
      />
      <path
        d="M15 16a5.001 5.001 0 10-5-5c0 2.76 2.24 5 5 5zm0-8c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3zm98 90c-8.284 0-15 6.716-15 15s6.716 15 15 15c8.284 0 15-6.716 15-15s-6.716-15-15-15zm10 23.295v-.24c0-3.375-1.768-6.256-4.444-7.814a7.04 7.04 0 01-1.496 1.45c2.373 1.114 3.94 3.481 3.94 6.364V123c0 .07.026.13.04.196a12.923 12.923 0 01-16.08 0c.014-.066.04-.127.04-.197v-1.944c0-2.883 1.567-5.25 3.938-6.365a7.075 7.075 0 01-1.495-1.45c-2.675 1.558-4.443 4.44-4.443 7.815v.24a12.936 12.936 0 01-3-8.295c0-7.169 5.832-13 13-13s13 5.831 13 13c0 3.15-1.128 6.043-3 8.295z"
      />
      <path
        d="M113 104a5.001 5.001 0 000 10 5.001 5.001 0 000-10zm0 8c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3-1.346 3-3 3zm-98 16c8.284 0 15-6.716 15-15s-6.716-15-15-15c-8.284 0-15 6.716-15 15s6.716 15 15 15zm0-28c7.168 0 13 5.831 13 13 0 3.15-1.128 6.043-3 8.295v-.24c0-3.375-1.768-6.256-4.444-7.814a7.04 7.04 0 01-1.496 1.45c2.373 1.114 3.94 3.481 3.94 6.364V123c0 .07.026.13.04.196a12.923 12.923 0 01-16.08 0c.014-.066.04-.127.04-.197v-1.944c0-2.883 1.567-5.25 3.938-6.365a7.075 7.075 0 01-1.495-1.45C6.768 114.798 5 117.68 5 121.055v.24A12.936 12.936 0 012 113c0-7.169 5.832-13 13-13z"
      />
      <path
        d="M15 114a5.001 5.001 0 10-5-5c0 2.76 2.24 5 5 5zm0-8c1.654 0 3 1.346 3 3s-1.346 3-3 3-3-1.346-3-3 1.346-3 3-3zm35-26.001a1 1 0 001-1v-3.11c0-4.975 2.83-9.143 6.959-10.939a10.083 10.083 0 01-1.56-1.469C51.977 65.744 49 70.39 49 75.888V79a1 1 0 001 1zM70.04 64.95C74.17 66.749 77 70.915 77 75.889V79a1 1 0 102 0v-3.112c0-5.497-2.977-10.143-7.4-12.406-.465.544-.99 1.035-1.56 1.469zM64 65c4.416 0 8-3.583 8-8 0-4.418-3.584-8-8-8s-8 3.582-8 8c0 4.417 3.584 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6-6-2.691-6-6 2.691-6 6-6z"
      />
      <path
        d="M23 64a1 1 0 001 1h7.025C31.545 82.428 45.57 96.455 63 96.975V104a1 1 0 102 0v-7.025C82.43 96.455 96.455 82.428 96.975 65H104a1 1 0 100-2h-7.025C96.455 45.572 82.43 31.545 65 31.025V24a1 1 0 10-2 0v7.025C45.57 31.545 31.545 45.572 31.025 63H24a1 1 0 00-1 1zm17-1h-6.975C33.545 46.702 46.701 33.546 63 33.025V40a1 1 0 102 0v-6.975c16.299.52 29.454 13.677 29.975 29.975H88a1 1 0 100 2h6.975C94.455 81.298 81.299 94.454 65 94.975V88a1 1 0 10-2 0v6.975C46.701 94.455 33.546 81.298 33.025 65H40a1 1 0 100-2zM15 96a1 1 0 001-1V36l1.2 1.6a.998.998 0 001.4.2.999.999 0 00.2-1.4l-3-4a.998.998 0 00-1.599 0l-3 4a.999.999 0 101.599 1.2L14 36v59a1 1 0 001 1zm98-64a1 1 0 00-1 1v59l-1.2-1.6a.999.999 0 10-1.6 1.2l3.001 4a.999.999 0 001.6 0l2.999-4a.999.999 0 00-.2-1.4.997.997 0 00-1.4.2L114 92V33a1 1 0 00-1-1zM33 16h59l-1.6 1.2a.999.999 0 101.2 1.6l4-2.999a1 1 0 000-1.6l-4-3a.999.999 0 10-1.2 1.6L92 14H33a1 1 0 100 2zm4.8 93.4a.998.998 0 00-1.4-.2l-4 3.001a1.002 1.002 0 000 1.6l4 2.999a.998.998 0 001.4-.2.999.999 0 00-.2-1.4L36 114h59a1 1 0 100-2H36l1.6-1.2a.999.999 0 00.2-1.4z"
      />
    </svg>
  );
}

export default AddtoGroups;
