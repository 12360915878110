import React from "react";
import {KoIcon, KoButton, KoDescription, KoTitle, KoText } from "packages";

import styles from './empty.module.scss';

function KoEmpty(props) {
  const { isTable, width, height, title = 'Create a new project', subText = 'How do you like to Start ?', icon = 'EmptyTwoTone', onClick,
    label = 'Create Project',
    description = 'Align your team around a shared vision by providing a workspace brief along with supporting files.' } = props;
  return (
    <div className={styles.wrapper}>
      { !isTable ? <div className={styles.container}>
        <div className={styles.text}>
          <KoTitle  text={title} level={5}/>
          <KoText className={styles.span} text={subText}/>
        </div>
        <KoIcon name={icon} width={width? width : 259} height={height ? height :156} />
        {onClick&&  <div className={styles.action}>
          <KoButton icon={ <KoIcon color="#FFF" name={'PlusOutlined'} width={18} height={18} />} type='primary' onClick={() => onClick('Create')} label={label} />
        </div>}
        <div className={styles.description}>
          <KoDescription className={styles.desc}  text={description} />
        </div>
      </div>:
        <div>
          {/* <KoIcon name={icon} width={width? width : 300} height={height ? height :200} /> */}
          <KoDescription text="No data available."/>
        </div>
      
      }
    </div>
  );
}
export default KoEmpty;
