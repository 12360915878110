import { Layout, theme } from 'antd';
import { useState } from 'react';
import { Outlet } from "react-router-dom";
import { KoBreadcrumb } from 'packages';
import GlobalHeader from './header';
import styles from './layouts.module.scss';
import GlobalSider from './sidenav';
import KoContacts from 'shared/contacts';
const { Content } = Layout;

function KoChatLayout(props) {
  let breadcrumb = [];

  const {
    token: { colorBgContainer }
  } = theme.useToken();
  const [collapsed] = useState(false);
  return (
    <Layout className={styles.wrapper}>
      <GlobalHeader  />
      <Layout hasSider className={styles.sectionLayout}>

        <GlobalSider collapsed={collapsed} background={colorBgContainer} />
        <KoContacts/>
        <Layout style={{
          marginLeft: 0,
          transition: ' transform 0.3s'
        }} className={`${styles.card}`}>
          {breadcrumb?.length >0 && <div className={styles.breadcrumbs}>
            <KoBreadcrumb
              className={styles.breadWrapper}
              separator="/"
              items={breadcrumb}
            />
          </div>}
          <Content
            style={{
              transition: ' transform 0.3s',
              margin: 0
            }}
          >
            <Outlet/>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
}

export default KoChatLayout;