import { KoDate, KoDescriptionEditor, KoForm, KoFormBuilder, KoSelect } from 'packages';
import { useEffect } from 'react';
import styles from './curd.module.scss';
import { TIMESPEND } from 'shared/commonConstants';


const WorkLogCrud = ({ form, handleFinish, initialValues}) => {

  useEffect(() => {
    if (!initialValues || Object.keys(initialValues).length === 0) {
      form?.resetFields();
    } else {
      form?.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  const Date = ({ value, onChange }) => {
    return (
      <KoDate
        value={value}
        format={'DD-MMM-YYYY'}
        onChange={(v) => onChange(v)}/>
    );
  };

  const Description = ({ value, onChange }) => {
    return (
      <KoDescriptionEditor
        value={value}
        form={form}
        initialValue={initialValues?.description}
        onChange={(e) => onChange(e.target.value)}
      />
    );
  };

  
  const TimeSpentSelect = ({ value, onChange }) => {
    return (
      <KoSelect
        value={value || undefined}
        placeholder="Ex: 1w 3d 6h 20m"
        showSearch
        onChange={(v) => onChange(v)}
        options={TIMESPEND}     />
    );
  };

  const Meta = {
    columns: 4,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      {
        key: 'timeSpent', label: 'Time spent', colSpan: 2, placeholder: 'Ex: 1w 3d 6h 20m',  widget: TimeSpentSelect,
        rules: [
          {
            required: true,
            message: `Please enter time spent.`
          }
        ]
      },
      {
        key: 'timeRemaining', label: 'Time remaining', colSpan: 2, placeholder: 'Ex: 1w 3d 6h 20m',  widget: TimeSpentSelect
      },
      {
        key: '', colSpan: 4,
        render() {
          return (
            <div className={styles.timeFormatInfo}>
              <span>Use this format: Ex: 1w 3d 6h 20m</span>
              <ul className={styles.timeInfoList}>
                <li><strong>w</strong> = week</li>
                <li><strong>d</strong> = days</li>
                <li><strong>h</strong> = hours</li>
                <li><strong>m</strong> = minutes</li>
              </ul>
            </div>
          );
        }
      },
      {
        key: 'date', label: 'Date', colSpan: 4,
        widget: Date, rules: [
          {
            required: true,
            message: `Please select date.`
          }
        ]
      },
      {
        key: 'description', widget: Description, label: 'Description', colSpan: 4, placeholder: 'Enter description'
      }
      
    ]
  };

  return (<div className={`${styles.subTaskWrapper} ${styles.formLayout}`}>
    <KoForm form={form} layout="vertical" onFinish={handleFinish}>
      <KoFormBuilder form={form} meta={Meta} />
    </KoForm>
  </div>);
};

export default WorkLogCrud;
