import React from "react";
import { Affix } from 'antd';

function KoAffix(props) {
  const { top, bottom, children } = props;

  return (
    top ? <Affix offsetTop={top}>
      {children}
    </Affix> : <Affix offsetBottom={bottom}>
      {children}
    </Affix>
  );
}

export default KoAffix;