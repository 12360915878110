import React from "react";
import { Input  } from 'antd';
const { Search } = Input;

function KoSearch(props) {
  return (
    <Search
      {...props}
      style={{ width: '100%' }}
    />
  );
}

export default KoSearch;