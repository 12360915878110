import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import { add, remove } from 'shared/store/breadcrumbSlice';
import styles from './deployment.module.scss';
import { KoAffix , KoIcon, KoDropdown,KoForm, KoFlex, KoCard,
  KoImage, KoText, KoTitle, KoDateFormatter, KoEmpty, KoModal, KoLoader, KoButton,
  KoNano,
  KoToast,
  KoTable,
  KoPagination} from "packages";
import KoToolBar from "shared/components/toolbar";
import EnvCurd from "./envCurd";
import { deploymentAPI } from "./deployment.services";
import { PRO_ITEMS_COLUMNS, PRO_ITEMS_ROWS } from "pages/workspace/constant";


function Deployment() {
  const dispatch = useDispatch();
  const [isOpen, setModal] = useState(false);
  const [envList, setEnvList] = useState(false);
  const [env, setEnv] = useState(false);
  const [totalCount] = useState(100);

  const [isOpenDelete, setDeleteModal] = useState(false);
  const [currentRecord, setCurrentRecord] = useState();
  const [isLoading, setLoading] = useState(false);
  const [form] = KoForm.useForm();

  const [pagination, setPagination] = useState({
    size: 10,
    offset: 0,
    page: 1
  });

  useEffect(() => {
    dispatch(remove());
    dispatch(add([
      {
        title: 'Workspace',
        path: 'workspace'
      },
      {
        title: 'Environments ', isOpen
      }
    ]));

    setCurrentRecord();
    setLoading(false);
    getEnvironments();
  }, []);

  if(false){
    console.log(env);
  }

  const getEnvironments = () => {
    form.resetFields();
    deploymentAPI.getEnvironments().then(
      (data) => {
        setLoading(false);
        setEnvList(data['environments']);
      },
      (err) => {
        setLoading(false);
        KoToast.error(`error`);
      }
    );
  };

  const action = [
   
    {
      label: "Update",
      key: "1",
      icon: <KoIcon name="EditOutlined" />
    },
    {
      type: 'divider'
    },
    {
      label: "Delete",
      key: "2",
      icon: <KoIcon name="DeleteOutlined" height={14} width={14} />,
      danger: true
    }
  ];

  const onClick = ({key}, environment) => {
    setCurrentRecord(environment);
    if (key === "2") {
      setDeleteModal(true);
    }
    else if (key === "1") {
      setModal(true);
    }
   
  };

  const handleFinish = (values) => {
   
    if(!currentRecord?.envId){
      values['environmentId'] = KoNano('ENV');
    }

    deploymentAPI.createOrUpdateEnvironment(values, currentRecord?.environmentId).then(
      (data) => {
        setLoading(false);
        getEnvironments();
        setModal(!isOpen);
        KoToast.success(`Environment created successfully!`);
      },
      (err) => {
        setLoading(false);
        KoToast.error('Error');
      }
    );
  };

  const handleFinishDelete=(e)=>{
    setLoading(true);
    deploymentAPI.deleteEnvironment(currentRecord?.environmentId).then(
      (data) => {
        getEnvironments();
        setLoading(false);
        setDeleteModal(false);
        KoToast.success(`Environment deleted successfully! ${currentRecord?.name}!`);
      },
      (err) => {
        setLoading(false);
        KoToast.error(`error`);
      }
    );
  };

  const items = [
    {
      key: '0',
      label: (<KoText text='Activate User' />),
      icon: <KoIcon name='ActivateOutlined'  />
      // disabled: true
    },
    {
      key: '1',
      label: (<KoText text='Update User' />),
      icon: <KoIcon name='ProfileUpdateOutlined' />
    }
  ];

  const handleRowActions = ({ key }) => {

  };

  const onPagination = (page, other) => {
    const query = {
      size: other,
      page: page,
      offset: (page - 1) * other
    };
    const pQuery = Object.assign(pagination, query);
    setPagination(pQuery);
  };


  const columnsRight = [{
    title: '',
    dataIndex: 'x',
    key: 'x',
    fixed: 'right',
    width: 40,
    render: (text, row) => {
      return <div className={`${styles.circle} columnsRight`} onClick={() => setCurrentRecord(row)}>
        <div shape="circle">
          <KoDropdown
          
            menu={{
              items,
              onClick: handleRowActions
            }}
            overlayClassName={styles.actionsLi}
            placement="bottomLeft"
          >
            <div shape="circle" >
              <KoIcon name="VerticalMenuOutlined" onClick={() => (row)} />
            </div>
          </KoDropdown>
        </div>
      </div>;
    }
  }];

  return (<div className={styles.wrapper}>
    <KoAffix top={56}>
      <KoToolBar
        className={styles.toolbar}
        title={'Environments '}
        search={{ visible: true, onChange: () => {}, placeholder: 'Search by name' }}
        buttonList={[{
          label: 'Create Environment',
          type: 'primary',
          icon: <KoIcon color="#FFF" name="PlusOutlined" width={18} height={18} />,
          onClick: () => setModal(true)
        }]} />
    </KoAffix>
    {envList?.length > 0 ?<KoFlex wrap="wrap" gap="small" className={styles.listSection}>
      {envList?.map((environment, i) =>
        <KoCard onClick={()=>setEnv(environment)} className={styles.card}
          key={environment?.id}
          hoverable={true}>
          <div className={styles.actions}>
            <KoDropdown
                // arrow
              placement="bottomRight"
              overlayClassName={'overlayClassName'}
              menu={{
                items:action,
                onClick:(e)=>onClick(e, environment)
              }}
            >
              <div onClick={(e) => e.preventDefault()}>
                <KoIcon name="MenuOutlined" />
              </div>
            </KoDropdown>
          </div>
          <Link to={`/deployment/${environment.environmentId}`}>
            <div className={styles.titleKey}>
              <KoImage
                src={`/images/org${i % 3}.png`}
                width={35}
                preview={false}
                height={35}
                alt={`doc${i % 3}`}
              />
              <div className={styles.titleValue}>
                <div className={styles.verified}>
                  <KoText className={`${styles.text}`} text={`${environment?.name ? environment?.name:'Tonelix Project description Here'}`} />
                  <KoIcon name='TickFilled'/>
                </div>
                <KoTitle className={styles.title} ellipsis={true} text={environment?.name} level={4} />
              </div>
               
            </div>
            <KoText className={`${styles.key} ${styles[environment?.environmentId? environment?.environmentId?.charAt(0).toLowerCase() :'a']}`}
              text={`${environment?.EnvironmentType}`} />
            <div className={styles.description} >
              <KoText text={`${environment?.description ? environment?.description :'Tonelix Project description Here'}`}/>
            </div>
            <div className={styles.link}>
              <div className={styles.row}>
                <KoText className={styles.date}  text='Created on'/>
                <KoDateFormatter format='DD-MMM-YYYY' data={environment?.createdAt} className={styles.date}/>
              </div>
            </div>
          </Link>
        </KoCard>
      )}
    </KoFlex>:
      <KoFlex wrap="wrap" gap="small" className={styles.emptySection}>
        <KoEmpty onClick={()=>setModal(true)} label="Create Environments" icon={'EmptyTwoTone'} title="Create a new environments"/>
      </KoFlex>
    }
    { envList?.length > 0 && <>
      <KoToolBar
        className={styles.toolbar}
        title={'Recently deployed services'}
        search={{ visible: true, onChange: () => { }, placeholder: 'Search by any parameter' }}
        filter={{
          visible: false,
          options: [{ label: 'Sort By Name', key: '0' },
            { label: 'Created At', key: '2' },
            { label: 'Sort By designation', key: '3' }]
        }}
        buttonList={[]} />
        
      <div className={styles.reacentTable}>
        <KoTable data={{ columns: PRO_ITEMS_COLUMNS, rows: PRO_ITEMS_ROWS }}
          extraColumnsRight={columnsRight} maxHeight={50} />
        <KoPagination current={parseInt(pagination.page) || 1}
          total={totalCount}
          showTotal={(total, range) =>
              `Displaying records ${range[0]}-${range[1]} of ${total}`
          }
          pageSize={pagination.size}
          defaultCurrent={pagination.page}
          onChange={onPagination}
          showQuickJumper={false}
          simple={false}
          responsive={false} />
      </div>
    </>}

    <KoModal
      title={currentRecord?.environmentId  ? `Update Environment ${currentRecord?.name?.toUpperCase()}` : 'Create Environment'}
      centered
      open={isOpen}
      handleOk={() => {setModal(false);}}
      onClose={() => {setModal(false);}}
      width={680}
      // maskClosable={false}
      height={520}
      footer={[
        <KoButton key='cancel' label='Cancel' onClick={() => setModal(!isOpen)} />,
        <KoButton key='create' loading={isLoading} type='primary' label={currentRecord?.environmentId ? `Update` : `Create`}
          onClick={() => form.submit()} />
      ]}
    >
      {isLoading && <KoLoader circle={false} />}
      <EnvCurd form={form} handleFinish={handleFinish} initialValues={currentRecord}/>
    </KoModal>

    <KoModal
      title={`Delete environment ${currentRecord?.name?.toUpperCase()}`}
      centered
      open={isOpenDelete}
      afterClose={() => {setCurrentRecord();}}
      handleOk={() => { setCurrentRecord(); setDeleteModal(false); }}
      onClose={() => { setCurrentRecord(); setDeleteModal(false); }}
      width={580}
      // maskClosable={false}
      height={450}
      footer={[
        <KoButton key='cancel' label='Cancel' onClick={() => setDeleteModal(!isOpenDelete)} />,
        <KoButton key='reset' loading={isLoading} type='primary' label='Delete' onClick={() => handleFinishDelete()} />
      ]}
    >
      {isLoading && <KoLoader circle={false} />}
      <div className={styles.formWrapper}>
        <KoTitle level={5} text={`Are you sure you want to delete this Environment?`} />
        <KoText text={`This action is irreversible, and it will result in the removal of their associated data and Kwapio license.`} />
      </div>
    </KoModal>
  </div>);
}

export default Deployment;
