import _find from 'lodash/find';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const determineSource = (input)=> {
  if (input?.startsWith('usr-') || input?.startsWith('usr')) {
    return "users";
  } else if (input?.startsWith('grp-') || input?.startsWith('grp')) {
    return "groups";
  } else {
    return "tickets";
  }
};

export const  getQueryParams=(url)=> {
  // Extract the query string from the URL
  const queryString = url.split('?')[1];
  
  // Create an object to hold the query parameters and the URL
  const params = {
    url: url.split('?')[0] // store the base URL without query parameters
  };

  // Split the query string into individual key-value pairs
  const pairs = queryString.split('&');

  // Iterate over each pair
  pairs.forEach(pair => {
    const [key, value] = pair.split('=');

    // Decode the URL-encoded key and value and add them to the params object
    params[decodeURIComponent(key)] = decodeURIComponent(value);
  });

  return params;
};

export const  camelize=(str)=> {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
};


export const getCompanyLogo=(dataClients, id)=>{
  let name ='Name not available';
  let client = _find(dataClients, function(cli) {
    return cli.customerId === id;
  });
  if(client?.logo){
    name= client?.logo[0]?.url;
  }
   
  return name;
};



export const getCompanyName=(dataClients, id)=>{
  let name ='Name not available';
  let client = _find(dataClients, function(cli) {
    return cli.customerId === id;
  });
  name= client?.companyName;
  return name;
};

export const deepMerge = (target, source) => {
  if (!target || typeof target !== 'object') {
    target = {};
  }

  for (const key in source) {
    if (source[key] instanceof Object && target[key] && typeof target[key] === 'object') {
      target[key] = deepMerge(target[key], source[key]);
    } else {
      target[key] = source[key];
    }
  }
  return target;
};


export const getTimeStamp = (timestamp, timezone = Intl.DateTimeFormat().resolvedOptions().timeZone) => {
  const date = dayjs.utc(timestamp).tz(timezone);
  const now = dayjs().tz(timezone);

  if (now.diff(date, 'day') === 1) {
    return 'yesterday';
  } else if (now.diff(date, 'day') > 1) {
    return date.format('DD/MM/YYYY');
  } else {
    return date.format('h:mm A');
  }
};
