import React from "react";
import { addWeeks, startOfWeek, endOfWeek, getMonth, format } from "date-fns";
import KoButton from "packages/button";
import { KoTitle } from "packages/typography";
import KoIcon from "packages/icon";
import styles from './calendar.module.scss';

const MonthName = ({ startWeek }) => {
  const getMonthName = () => {
    const endOfWeekDate = endOfWeek(startWeek);

    if (getMonth(endOfWeekDate) === getMonth(startWeek)) {
      return format(startWeek, "MMMM yyy");
    }

    return (
      format(startWeek, "MMMM yyy") + " - " + format(endOfWeekDate, "MMMM yyy")
    );
  };

  return (
    <KoTitle level={5}  text= {getMonthName()}/>
  );
};

export const CalendarHeader = ({ startWeek, setStartWeek, onCreateHuddle }) => {
  return (
    <div className={styles.calendarHeader}>
      <div className={styles.leftContnet}>
        <KoTitle level={5} text={'Meetings'} />
        <KoButton onClick={() => setStartWeek(startOfWeek(new Date()))} label={'Today'}/>
        <KoButton  onClick={() => setStartWeek(addWeeks(startWeek, -1))}
          iconBtn={true}
          icon={
            <KoIcon
              color="#FFF"
              name="ArrowLeftOutlined"
              width={18}
              height={18}
            />}
        />
        <MonthName startWeek={startWeek} />
        <KoButton  onClick={() => setStartWeek(addWeeks(startWeek, 1))}  iconBtn={true} icon={
          <KoIcon
            name="ArrowRightOutlined"
            width={18}
            height={18}
          />}
        />
        
      </div>
      <div className={styles.rightContnet}>
        <KoButton  onClick={() => onCreateHuddle()} icon={
          <KoIcon
            color="#FFF"
            name="PlusOutlined"
            width={18}
            height={18}
          />}
        type='primary' label={'New Meeting'}/>
      </div>
    </div>
  );
};
