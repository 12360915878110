import React from "react";
function Info(props) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 256 256"
      width={width}
      height={height}
    >
      <path fill="none" d="M0 0H256V256H0z" />
      <circle
        cx={128}
        cy={128}
        r={96}
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={8}
        className="colorStroke000 svgStroke"
      />
      <path
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={8}
        d="M120 120L128 120 128 176 136 176"
      />
      <circle cx={128} cy={84} r={8} />
    </svg>
  );
}


export default Info;