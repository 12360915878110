import { getCookie } from 'shared/utils/cookies';
import {
  KoAffix, KoButton, KoCard, KoDateFormatter, KoDescription, KoDropdown, KoEmpty,
  KoFlex, KoForm, KoIcon, KoLoader, KoModal, KoNano, KoTable, KoText, KoTitle, KoToast, KoTooltips, Radio
} from 'packages';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { add, remove } from 'shared/store/breadcrumbSlice';
import KoToolBar from 'shared/components/toolbar';
import { CalendarAPI } from '../calendar.service';
import { COLUMN } from './constant';
import EventCrud from './curd';
import styles from './events.module.scss';

export default function Events() {
  const dispatch = useDispatch();
  const [form] = KoForm.useForm();
  const [currentRecord, setCurrentRecord] = useState();
  // const [totalCount, setTotalCount] = useState(100);
  const [isLoading, setLoading] = useState(false);
  const [eventOpen, setEventOpen]   = useState(false);
  const [modelOpen, setModelOpen]   = useState(false);
  const [eventData, setEventData]   = useState();
  const [isBookingList, setBookingList] = useState();
  const [isOpenDelete, setDeleteModal] = useState(false);
  const [copy, setCopy] = useState(false);
  let accountId = getCookie('accountId');

  useEffect(()=>{
    dispatch(remove());
    dispatch(add([]));

    getEvents();
  },[]);

  useEffect(() => {
    if(currentRecord?.eventId){
      GetAllBookings(currentRecord?.eventId);
    }
  },[modelOpen]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copy) setCopy(false);
    }, 800);

    return () => clearTimeout(timeout);
  }, [copy]);

  const getEvents = () => {
    setEventData();
    setLoading(true);
    CalendarAPI.getEvents().then(
      (data) => {
        setEventData(data?.events);
        setEventOpen(false);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const action = [
   
    {
      label: "Update",
      key: "1",
      icon: <KoIcon name="EditOutlined" />
    },
    {
      label: "Copy",
      key: "2",
      icon: <KoIcon name="CopyOutlined" />
    },
    {
      type: 'divider'
    },
    {
      label: "Delete",
      key: "3",
      icon: <KoIcon name="DeleteOutlined" height={14} width={14} />,
      danger: true
    }
  ];



  const handleEventFinish = (values => {
    let data              = form.getFieldsValue();
    if(!currentRecord?.eventId){
      values['eventId']   = KoNano('EVE');
    }
    values['accepted']    = [];
    values['maybe']       = [];
    values['notJoining']  = [];
    values['platform']    = {name:values.eventVia,url:values.url,id:KoNano('URL')};
    values['agenda']      = data['agenda'];

    
    CalendarAPI.createOrUpdateEvents(values, currentRecord?.eventId).then((data) => {
      getEvents();
      setEventOpen(false);
      KoToast.success(`Event ${currentRecord?.eventId ? 'updated':'created'} successfully`);
    },
    (err) => {
      if(err?.error === 'this role does not have sufficient permission'){
        KoToast.error('You do not have sufficient permission');
      }
      setLoading(false);
    }
    );

  });

  const updateBookingStatus = (record, newStatus) => {
    const updatedValues = {
      bookingStatus: newStatus
    };
    CalendarAPI.updateEventStatus(record.eventId, updatedValues)
      .then(
        (data) => {
          setLoading(true);
          getEvents();
          setLoading(false);
          KoToast.success('Booking status updated successfully');
        },
        (err) => {
          setLoading(false);
        }
      );
  };


  const handleDelete= ()=> {
    CalendarAPI.deleteEvents(currentRecord?.eventId).then(
      (data) => {
        setLoading(false);
        setEventOpen(false);
        setDeleteModal(false);
        getEvents();
        KoToast.success("Event Deleted Successfully");
      },
      (err) => {
        if(err?.error === 'this role does not have sufficient permission'){
          KoToast.error('You do not have sufficient permission');
        }
        setDeleteModal(false);
        setLoading(false);
      }
    );
    getEvents();
  };


  const formatDuration = (duration) => {
    return duration
      .replace('h', ' hours')
      .replace('m', ' mins');
  };

  const handleRowActions = ({ key }) => {
    if (key === '1') {
      setEventOpen(true);
    } else if (key === '2') {
      onCopyLink(currentRecord);
    } else if (key === '3') {
      setDeleteModal(true);
    }

  };

  const onCopyLink=(data)=>{
    setCopy(true);
    navigator.clipboard.writeText(`${window.location.origin}/open/calendar/${accountId}/${data?.eventId}/${data?.createdBy}`);
  };

  const GetAllBookings = (eventId) => {
    setBookingList();
    setLoading(true);
    CalendarAPI.getAllBookings(eventId).then(
      (data) => {
        setBookingList(data?.bookings);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  return (
    <div className={styles.wrapper}>
      {isLoading && <KoLoader />}
      <KoAffix top={56}>
        <KoToolBar
          className={styles.toolbar}
          title={'Events '}
          backBtn={true}
          search={{ visible: true, onChange: () => {}, placeholder: 'Search by event name' }}
          buttonList={[{
            label: 'Create Event',
            type: 'primary',
            icon: <KoIcon color="#FFF" name="PlusOutlined" width={18} height={18} />,
            onClick: () => setEventOpen(true)
          }]} />
      </KoAffix>
      {eventData?.length > 0 ?<KoFlex wrap="wrap" gap="small" className={styles.listSection}>
        {eventData?.map((event, i) =>
          <KoCard  className={styles.card}
            key={event?.eventId}
            hoverable={true}>
            <div className={styles.actions} onClick={() => setCurrentRecord(event)}>
              <KoDropdown
                // arrow
                placement="bottomRight"
                overlayClassName={'overlayClassName'}
                menu={{
                  items:action,
                  onClick: handleRowActions
                }}
              >
                <div onClick={(e)=> e.preventDefault()}>
                  <KoIcon name="VerticalMenuOutlined" />
                </div>
              </KoDropdown>
            </div>
           
            <div className={styles.titleKey}>
              <div className={styles.titleValue}>
                <div className={styles.verified}>
                  <KoText className={`${styles.text}`} text={event?.name} />
                </div>
                <KoTitle className={styles.title} ellipsis={true}  text={''} level={4} />
              </div>
            </div>
           
            <div className={styles.description} >
              <KoDescription className={styles.descriptionText} text={`${event?.description ? event?.description :'-'}`} ellipsis={{
                rows: 1
              }}/>
            </div>
            <div className={`${styles.description} ${styles.onOff}`} >
              <div className={styles.meetingInfo}>
                <div className={styles.time}>
                  <KoIcon name="LogOutlined" width={10} height={10}/>
                  <KoText text={formatDuration(event?.duration)} ellipsis={true} />
                </div>
                <div className={styles.time}>
                  <KoIcon name={event?.eventType==='1' ? 'ProfileUpdateOutlined' :event?.eventType==='2' ? 'UsersOutlined':'CalendarOutlined' } width={10} height={10}/>
                  <KoText text={event?.eventType==='1' ? 'One on One' :event?.eventType==='2' ? 'Group':'-' } ellipsis={true}/>
                </div>
              </div>
              <Radio.Group size="small" buttonStyle="solid" value={event?.bookingStatus}
                onChange={(e) => updateBookingStatus(event, e.target.value)}>
                <Radio.Button value={true}>ON</Radio.Button>
                <Radio.Button value={false}>OFF</Radio.Button>
              </Radio.Group>

            </div>
            <div className={styles.link}>
              <div className={styles.row}>
                <KoText className={styles.date}  text='Created on'/>
                <KoDateFormatter format='DD-MMM-YYYY' data={event?.createdAt} className={styles.date}/>
              </div>
              <div className={styles.footerActions}>
                <KoTooltips title={'view'}>
                  <KoButton className={styles.btn} onClick={()=>{setModelOpen(true); setCurrentRecord(event);}} iconBtn={true} icon={<KoIcon width={14} height={14} name='EyeOpenedOutlined'/>}/>
                </KoTooltips>
                <KoTooltips title={copy ? 'Copied' : 'Copy'}>
                  <KoButton className={styles.btn} onClick={()=>onCopyLink(event)} iconBtn={true} icon={<KoIcon width={14} height={14} name='CopyOutlined'/>}/>
                </KoTooltips>

                
                <KoButton className={styles.btn} iconBtn={true} icon={<KoIcon width={14} height={14} name='OpenOutlined'/>}
                  onClick={() => window.open(`/open/calendar/${accountId}/${event?.eventId}/${event?.createdBy}`, '_blank')}/>
              </div>
            </div>
          
          </KoCard>
        )}
      </KoFlex>:
        <KoFlex wrap="wrap" gap="small" className={styles.emptySection}>
          <KoEmpty onClick={()=>setEventOpen(true)} label="Create Event" icon={'EmptyTwoTone'} title="Create a new event"/>
        </KoFlex>
      }

      <KoModal
        title={`${currentRecord?.eventId ? 'Update' : 'Create'} event`}
        centered
        open={eventOpen}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setEventOpen(false); form.resetFields(); }}
        onClose={() => { setCurrentRecord(); setEventOpen(false); form.resetFields(); }}
        width={800}
        // maskClosable={false}
        height={650}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setEventOpen(!eventOpen)} />,
          <KoButton key='create' loading={isLoading} type='primary' label={`${currentRecord?.eventId ? 'Update' : 'Create'}`} onClick={() => form.submit()} />
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <EventCrud form={form} handleFinish={handleEventFinish} initialValues={currentRecord}/>
      </KoModal>

      <KoModal
        title={`All events`}
        centered
        open={modelOpen}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setModelOpen(false); }}
        onClose={() => { setCurrentRecord(); setModelOpen(false); }}
        width={`60vw`}
        // maskClosable={false}
        height={650}>
        {isLoading && <KoLoader circle={false} />}
        <KoTable rowKey='id' data={{ columns: COLUMN, rows: isBookingList }} maxHeight={35} />
      </KoModal>

      <KoModal
        title={`Delete Event ${currentRecord?.name?.toUpperCase()}`}
        centered
        open={isOpenDelete}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setDeleteModal(false); }}
        onClose={() => { setCurrentRecord(); setDeleteModal(false); }}
        width={580}
        // maskClosable={false}
        height={450}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setDeleteModal(!isOpenDelete)} />,
          <KoButton key='delete' loading={isLoading} type='primary' label='Delete' onClick={() => handleDelete()} />
        ]}
      >
        <div className={styles.suspend}>
          <KoTitle level={5} text={`This action will delete event and cannot be undone`} />
          <KoText text={`Do you wish to proceed?`} />
        </div>
      </KoModal>
    </div>
  );
}
