import React from "react";
function SearchOutlined(props) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      width={width}
      height={height}
      fill="#C4CCDE"
    >
      <path
        d="M18 3a11 11 0 00-8.44 18.05l-6.27 6.24a1 1 0 000 1.42A1 1 0 004 29a1 1 0 00.71-.29L11 22.46A11 11 0 1018 3zm0 20a9 9 0 119-9 9 9 0 01-9 9z"
      />
    </svg>
  );
}

export default SearchOutlined;
