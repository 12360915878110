import React from "react";
function Events(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.7917 9.25C21.5608 9.25 23 7.81083 23 6.04167V4.20833C23 2.43917 21.5608 1 19.7917 1H4.20833C2.43917 1 1 2.43917 1 4.20833V6.04167C1 7.81083 2.43917 9.25 4.20833 9.25H4.66667V15.2083C4.66667 17.4817 6.51833 19.3333 8.79167 19.3333H11.0833V19.7917C11.0833 21.5608 12.5225 23 14.2917 23H19.7917C21.5608 23 23 21.5608 23 19.7917V17.9583C23 16.1892 21.5608 14.75 19.7917 14.75H14.2917C12.5225 14.75 11.0833 16.1892 11.0833 17.9583V18.4167H8.79167C7.0225 18.4167 5.58333 16.9775 5.58333 15.2083V9.25H19.7917ZM12 17.9583C12 16.6933 13.0267 15.6667 14.2917 15.6667H19.7917C21.0567 15.6667 22.0833 16.6933 22.0833 17.9583V19.7917C22.0833 21.0567 21.0567 22.0833 19.7917 22.0833H14.2917C13.0267 22.0833 12 21.0567 12 19.7917V17.9583ZM1.91667 6.04167V4.20833C1.91667 2.94333 2.94333 1.91667 4.20833 1.91667H19.7917C21.0567 1.91667 22.0833 2.94333 22.0833 4.20833V6.04167C22.0833 7.30667 21.0567 8.33333 19.7917 8.33333H4.20833C2.94333 8.33333 1.91667 7.30667 1.91667 6.04167Z" fill="black" />
    </svg>
  );
}


export default Events;