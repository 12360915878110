import React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import { Calendar, Button } from 'antd';
import dayLocaleData from 'dayjs/plugin/localeData';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { KoText } from 'packages/typography';
import styles from './calendar.module.scss';

dayjs.extend(dayLocaleData);

export default function KoCalendarPicker({ onChange }) {

  // User-specific holidays
  const userHolidays = ['2024-09-16'];

  // Disable past dates, weekends, and user holidays
  const disabledDate = (date) => {
    const today = dayjs();

    // Disable past dates
    if (date.isBefore(today, 'day')) {
      return true;
    }

    // Disable weekends (Saturday = 6, Sunday = 0)
    const dayOfWeek = date.day();
    if (dayOfWeek === 6 || dayOfWeek === 0) {
      return true;
    }

    // Disable specific user holidays
    const formattedDate = date.format('YYYY-MM-DD');
    if (userHolidays.includes(formattedDate)) {
      return true;
    }

    return false;
  };

  const onPanelChange = (value, mode) => {
  };

  return (
    <div  className={styles.pickerWrapper}>
      <Calendar
        fullscreen={false}
        onChange={onChange}
        className={styles.antdCalendar}
        disabledDate={disabledDate}
        headerRender={({ value, onChange }) => {
          const currentMonth = value.format('MMMM YYYY'); // Format as 'September 2024'
          const prevMonth = () => {
            const newValue = value.clone().subtract(1, 'month');
            onChange(newValue);
          };
          const nextMonth = () => {
            const newValue = value.clone().add(1, 'month');
            onChange(newValue);
          };

          return (
            <div className={styles.pickerHeader}>
              <Button shape='circle' type="text" icon={<LeftOutlined />} onClick={prevMonth} />
              <KoText text={currentMonth} />
              <Button shape='circle' type="text" icon={<RightOutlined />} onClick={nextMonth} />
            </div>
          );
        }}
        onPanelChange={onPanelChange}
      />
    </div>
  );
}
