import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";

import { KoFormBuilder, KoForm, KoIcon, KoDate, KoSelect, KoTextArea, CURRENCIES, KoNano } from 'packages';
import { InvoicesAPI } from '../invoice.service';
import styles from './previewTemp.module.scss';
import LineItemEntryCrud from './lineItem';
function PreviewTemplate({ currentRecord }) {
  const [form] = KoForm.useForm();
  const [fromAccount, setFromAccount] = useState();
  const [customers, setCustomers] = useState();
  let currentCustomers = null;
  const workspace = useSelector(state => state?.workspace);
  let workspaceList = workspace['data'];
  useEffect(() => {
    const getAccountById = () => {
      InvoicesAPI.getAccountById().then(
        (data) => {
          setFromAccount([data['account']]);
        },
        (err) => {
        }
      );
    };

    getAccountById();
  }, []);

  useEffect(() => {
    const getCustomers = () => {
      InvoicesAPI.getCustomers().then(
        (data) => {
          setCustomers(data['customers']);
        },
        (err) => {
        }
      );
    };

    getCustomers();
  }, []);


  const handleFieldWidgets = (fields) => {
    return fields?.map(field => {
      let updatedField = { ...field };

      switch (field.type) {
      case 'input':
        break;
      
      case 'divider':
        updatedField = {
          key: KoNano('lab'),
          colSpan: field.colSpan,
          render() {
            return (
              <></>
            );
          }
        };
        break;

      case 'date':
        updatedField.widget = Date;
        break;

      case 'select':
        switch (field.key) {
        case 'currency':
          updatedField.widget = Currency;
          break;
        case 'from':
          updatedField.widget = From;
          break;
        case 'to':
          updatedField.widget = To;
          break;
        case 'tax':
          updatedField.widget = TaxName;
          break;
        case 'discount':
          updatedField.widget = DiscountName;
          break;
        default:
          break;
        }
        break;
      case 'project':
        updatedField.widget = Projects;
        break;
      case 'textarea':
        updatedField.widget = InvoiceNote;
        break;

      case 'table':
        updatedField.widget = ItemTable;
        updatedField.children = {
          defaultColumns: field.defaultColumns,
          other: field?.lineItems,
          discount: currentCustomers?.discount,
          tax: currentCustomers?.tax
        };
        break;

      case 'fieldset':
        updatedField = {
          key: KoNano('lab'),
          colSpan: 6,
          render() {
            return (
              <fieldset>
                <legend>{field.label}</legend>
              </fieldset>
            );
          }
        };
        break;

      default:
        break;
      }

      return updatedField;
    });
  };



  const optionGroups = [];
  for (let i = 10; i < 36; i++) {
    optionGroups.push({
      label: i.toString(36) + i,
      value: i.toString(36) + i
    });
  }
  const From = ({ value, onChange }) =>
    <KoSelect value={value}
      showSearch
      onChange={v => onChange(v)}
      placeholder='Select business profile'
      options={fromAccount?.map((account) => ({
        label: account?.companyName,
        value: account?.id
      }))} />;

  const To = ({ value, onChange }) =>
    <KoSelect value={value}
      showSearch
      onChange={v => onChange(v)}
      placeholder='Select customer'
      options={customers?.map((account) => ({
        label: account?.companyName,
        value: account?.customerId
      }))} />;


  const Projects = ({ value, onChange }) =>
    <KoSelect value={value}
      showSearch
      onChange={v => onChange(v)}
      placeholder='Select project / item'
      options={workspaceList?.map((project) => ({
        label: project?.name,
        value: project?.workspaceId
      }))} />;

  const Currency = ({ value, onChange }) =>
    <KoSelect value={value}
      showSearch
      onChange={v => onChange(v)}
      placeholder='Select currency'
      options={CURRENCIES?.map((currency) => ({
        label: currency?.code + '-' + currency?.name,
        value: currency?.code
      }))} />;

  
  const TaxName = ({ value, onChange }) =>
    <KoSelect value={value}
      showSearch
      mode="multiple"
      onChange={v => onChange(v)}
      placeholder='Select Tax'
      options={currentCustomers?.tax?.map((tax) => ({
        label: tax?.taxName,
        value: tax?.taxRate
      }))} />;

  const DiscountName = ({ value, onChange }) =>
    <KoSelect value={value}
      showSearch
      mode="multiple"
      onChange={v => onChange(v)}
      placeholder='Select Discount'
      options={currentCustomers?.discount?.map((discount) => ({
        label: discount?.discountName,
        value: discount?.discountRate
      }))} />;

  const ItemTable = (defaultColumns, lineItems) =>
    <LineItemEntryCrud TaxName={TaxName} DiscountName={DiscountName}  form={form} fields={defaultColumns} lineItems={lineItems} handleFinish={handleEntryFinish} />;
    

  const InvoiceNote = ({ value, onChange }) =>
    <KoTextArea
      placeholder='Enter Invoice Notes'
    />;

  const Date = ({ value, onChange }) =>
    <KoDate value={value} dateFormat={'DD-MMM-YYYY'}
      onChange={v => onChange(v)} />;


  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleEntryFinish = (values) => {
  };

  const updatedFields = handleFieldWidgets(currentRecord?.fields);

  const meta = {
    columns: 6,
    formItemLayout: null,
    initialValues: currentRecord,
    colon: true,
    fields: updatedFields
  };

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.InvoiceCrudwrapper}>
        <KoForm layout="vertical" >
          <div className={styles.logoWrapper}>
            <KoForm.Item
              name="profilePicture"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <div className={styles.upload}>
                <div className={styles.picture}>
                  <KoIcon name="Avatar1Filled" width={100} height={100} />
                </div>
              </div>
            </KoForm.Item>
          </div>
          <KoFormBuilder meta={meta} />
        </KoForm>
      </div>
    </div>
  );
};

export default PreviewTemplate;