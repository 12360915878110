import React from "react";
function Featurerequrst(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" width="24" height="24" rx="4" fill="var(--featurerequest)" />
      <path d="M6.7955 5.06083C6.19159 5.22168 5.71989 5.70423 5.55908 6.31546C5.48047 6.61929 5.48047 9.68259 5.55908 9.98999C5.71989 10.6048 6.19873 11.0802 6.81694 11.2411C7.12068 11.3197 10.1831 11.3197 10.4905 11.2411C11.1051 11.0802 11.5804 10.6012 11.7412 9.98285C11.8198 9.68259 11.8198 6.61571 11.7412 6.31546C11.5804 5.69708 11.1051 5.2181 10.4905 5.05725C10.1903 4.97861 7.08852 4.98219 6.7955 5.06083Z" fill="white" />
      <path d="M16.2243 5.22097C16.1029 5.27675 15.9947 5.39159 15.9487 5.513C15.9257 5.57206 15.9126 5.96581 15.9126 6.56956V7.53425L14.8987 7.54409C13.9307 7.55394 13.8782 7.55722 13.7929 7.62284C13.619 7.75081 13.5665 7.85581 13.5665 8.06253C13.5665 8.26925 13.619 8.37425 13.7929 8.50222C13.8782 8.56784 13.9307 8.57112 14.8954 8.58097L15.9093 8.59081L15.9192 9.60472C15.929 10.5694 15.9323 10.6219 15.9979 10.7072C16.1259 10.8811 16.2309 10.9336 16.4376 10.9336C16.6443 10.9336 16.7493 10.8811 16.8773 10.7072C16.9429 10.6219 16.9462 10.5694 16.9561 9.60472L16.9659 8.59081L17.9798 8.58097C18.9445 8.57112 18.997 8.56784 19.0823 8.50222C19.2562 8.37425 19.3087 8.26925 19.3087 8.06253C19.3087 7.85581 19.2562 7.75081 19.0823 7.62284C18.997 7.55722 18.9445 7.55394 17.9798 7.54409L16.9659 7.53425L16.9561 6.52034C16.9462 5.55566 16.9429 5.50316 16.8773 5.41784C16.8412 5.36862 16.7723 5.29644 16.7264 5.26362C16.6148 5.18159 16.3556 5.15862 16.2243 5.22097Z" fill="white" />
      <path d="M6.7955 12.7608C6.19159 12.9217 5.71989 13.4042 5.55908 14.0155C5.48047 14.3193 5.48047 17.3826 5.55908 17.69C5.71989 18.3048 6.19873 18.7802 6.81694 18.9411C7.12068 19.0197 10.1831 19.0197 10.4905 18.9411C11.1051 18.7802 11.5804 18.3012 11.7412 17.6828C11.8198 17.3826 11.8198 14.3157 11.7412 14.0155C11.5804 13.3971 11.1051 12.9181 10.4905 12.7573C10.1903 12.6786 7.08852 12.6822 6.7955 12.7608Z" fill="white" />
      <path d="M14.4955 12.7608C13.8916 12.9217 13.4199 13.4042 13.2591 14.0155C13.1805 14.3193 13.1805 17.3826 13.2591 17.69C13.4199 18.3048 13.8987 18.7802 14.5169 18.9411C14.8207 19.0197 17.8831 19.0197 18.1905 18.9411C18.8051 18.7802 19.2804 18.3012 19.4412 17.6828C19.5198 17.3826 19.5198 14.3157 19.4412 14.0155C19.2804 13.3971 18.8051 12.9181 18.1905 12.7573C17.8903 12.6786 14.7885 12.6822 14.4955 12.7608Z" fill="white" />
    </svg>

  );
}


export default Featurerequrst;