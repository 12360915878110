import React from 'react';
import styles from './chat.module.scss';

const TypingIndicator = ({ sender }) => {
  return (
    <div className={styles.typing} >
      <span className={styles.sender}>typing </span>
      <span className={styles.dot}></span>
      <span className={styles.dot}></span>
      <span className={styles.dot}></span>
    </div>
  );
};

export default TypingIndicator;
