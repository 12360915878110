import React from "react";
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

function KoTimePicker(props) {
  let timeValue;
  if (props.value) {
    if (typeof props.value === 'string') {
      timeValue = dayjs(props.value, props.format || "HH:mm");
    } else if (props.value instanceof Date) {
      timeValue = dayjs(props.value);
    }
  } else {
    timeValue = dayjs('00:00:00', "HH:mm");
  }

  return (
    <TimePicker
      value={timeValue}
      allowClear={props.allowClear || false}
      format={props.format || "HH:mm:ss"}
      disabledTime={props.disabled}
      changeOnScroll needConfirm={props?.needConfirm}
      onChange={(time, timeString) => {
        props.onChange(timeString);
      }}
    />
  );
}

export default KoTimePicker;
