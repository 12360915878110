
import React, { useState } from 'react';
import { useSearchParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { KoButton, KoIcon, KoImage, KoTitle, KoOTP, KoToast, KoText } from 'packages';
import { accountAPI } from '../account.service';

import styles from './verify.module.scss';
import { useDispatch } from 'react-redux';
import { login, updateUser } from 'shared/store/authSlice';

function VerifyAccount() {
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [otp, onChangeOtp] = useState();
  const email = searchParams.get('email');

  
  const handleFinish = () => {
    setLoading(true);
    let obj ={
      email: email,
      otp:otp
    };
    accountAPI.verify(obj).then(
      (data) => {
        KoToast.success(`Login Successful! Welcome back ${email}!`);
        setLoading(false);
        dispatch(login(data));
        dispatch(updateUser({...data}));
        navigate('/account/login');
      },
      (err) => {
        setLoading(false);
      }
    );

  };

  const resedOTP=()=>{
    setLoading(true);
    let obj ={
      email:email
    };
    accountAPI.resedOTP(obj).then(
      (data) => {
        KoToast.success(`Otp sent ${email}!`);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.modal}>
        <div className={`${styles.formWrapper} ${styles.login}`}>
          <div className={styles.header}>
            <KoIcon name='AppLogoFilled' width={108}
              height={40} />
          </div>
          <KoTitle text={'Verify Your Account'} level={3} />
          <KoText text={`We've sent a four-digit code to ${email}. 
                    Please enter the code below to confirm your email address`} className={`${styles.small}`} />
          <div className={styles.formData}>
          </div>
          <KoOTP onChangeOtp={onChangeOtp} numInputs={4} />
          <div className={styles.signupBtn}>
            <KoButton loading={isLoading} onClick={() => handleFinish()} type="primary" label={'Verify'} />
            <KoText className={styles.otherOptions} onClick={() => resedOTP()} text={`Resend verification code?`} />
          </div>

        </div>
        <div className={styles.banner}>
          <div className={styles.slider}>
            <KoImage
              alt={'name'}
              src={`/images/banner.svg`}
              width={500}
              height={300}
              preview={false}
            />
            <div className={styles.footer}>
              <KoTitle text={'Connect your team’s work to your product roadmap'} level={5} />
              <KoText text="Every team has a unique process. Use a out-of-boss workflow,
              or create one to match the way team works"/>
            </div>
          </div>
          <div className={styles.dots}>
            <div className={styles.active} />
            <div className={styles.circle} />
            <div className={styles.circle} />
            <div className={styles.circle} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default VerifyAccount;

