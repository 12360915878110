import {
  KoAcceptanceCriteria,
  KoForm,
  KoFormBuilder,
  KoIcon,
  KoLoader,
  KoNano,
  KoSelect,
  KoTabs,
  KoText,
  KoToast,
  Upload
} from 'packages';
import Driver from 'packages/drivers';
import { useCallback, useEffect, useState } from 'react';
import { ISSUETYPEFORCOB } from 'shared/commonConstants';
import { getCookie } from 'shared/utils/cookies';
import styles from './curd.module.scss';
import Comments from 'pages/taskPlanner/task/comments';

function TaskCrud({ form, className, onValuesChange, handleFinish, initialValues,footerCls, chatBodyCls }) {
  const [loading, setLoading] = useState(false);
  const [lookupId, setLookupId] = useState();
  const [activeKey, setActive]= useState('about');
  useEffect(()=>{
    if(initialValues?.lookupId){
      setLookupId(initialValues?.lookupId);
    }else{
      const lp = KoNano('LOK');
      setLookupId(lp);
      let data = form?.getFieldsValue();
      data['lookupId'] = lp;
      form.setFieldsValue({...data});
    }
    
  },[initialValues]);

  useEffect(()=>{
    if(form){
      form?.resetFields();
    }
  },[initialValues]);
  
  const handleSubmit = useCallback(values => {
    
    const payload = {
      ...values,
      priority: 4,
      parentIssueType: values?.parentId?.parentIssueType || null
    };

    handleFinish(payload);
  });

  const IssueType = ({ value, onChange }) =>
    <KoSelect value={value}
      onChange={v => onChange(v)}
      showSearch
      placeholder='Select issue type'
      options={ISSUETYPEFORCOB}
      optionRender={(option) => (
        <div className={styles.iconWithLabel} role="img" aria-label={option.data.label}>
          {option.data.value === 5 && <KoIcon name="BugFilled" />}
          {option.data.value === 6 && <KoIcon name="ImprovementsFilled" />}
          {option.data.value === 7 && <KoIcon name="FeatureRequestFilled" />}
          {option.data.value === 8 && <KoIcon name="ChangerequestFilled" />}
          {option.data.value === 9 && <KoIcon name="SupportFilled" />}
          {option.data.label}
        </div>
      )}
    />;

  const AcceptanceCriteria = ({ value, onChange }) =>
    <KoAcceptanceCriteria value={value} initialValue={value}
      form={form} name='acceptanceCriteria'
      onChange={v => onChange(v)}
    />;

  const Description = ({ value, onChange }) =>
    <KoAcceptanceCriteria value={value} initialValue={value}
      form={form} name='description'
      onChange={v => onChange(v)}
    />;


  
  const BASICMETADATA = {
    columns: 6,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      {
        key: 'summary', placeholder: 'Enter issue summary', label: 'Summary', colSpan: 4, rules: [
          {
            required: true,
            message: `Please enter summary.`
          }
        ]
      },
      
      {
        key: 'issueType', label: 'Issue type', colSpan: 2,
        widget: IssueType,
        rules: [
          {
            required: true,
            message: `Please select issue type.`
          }
        ]
      }
    ]
  };
  
  const TASKINFOMETADATA = {
    columns: 6,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      {
        key: 'acceptanceCriteria', placeholder: 'Requirement', label: 'Acceptance Criteria', colSpan: 6,
        widget: AcceptanceCriteria
      },
      {
        key: 'description', placeholder: 'Description', label: 'Description', colSpan: 6,
        widget: Description
      }
  
    ]
  };


  const props = {
    name: 'files',
    multiple: true,
    action: `${process.env.REACT_APP_PROXY_HOST}/api/v1/org/upload/public`,
    data:{service:getCookie('wos'), module:'taskplanner', lookupId: lookupId},
    headers: {
      Authorization: `TempToken ${getCookie("tempToken")}`
    },
   
    onChange(info) {
      setLoading(true);
      if (info.file.status !== 'uploading') {
        onValuesChange({
          logo: info.fileList
        }, 'refresh');
      }
      if (info.file.status === 'done') {
        KoToast.success(`${info.file.name} file uploaded successfully`);
        setLoading(false);
      } else if (info.file.status === 'error') {
        setLoading(false);
        KoToast.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const removePrefix = (type, prefix) => {
    return type.startsWith(prefix) ? type.substring(prefix.length) : type;
  };
  const uploadButton = (
    <div className={styles.dndArea}>
      {loading && <KoLoader size='small'/>}
      <KoIcon name='AttachmentOutlined'/>
      <div>Add image, video, or file</div>
    </div>
    
  );
  
  const onRemoveFile=(d)=>{
    // let obj={
    //   path:`${getCookie('wos')}/taskplanner/${initialValues?.lookupId}/`,
    //   files:[d?.uid]
    // };

 
  };

  


  const onTabChange = (key) => {
    setActive(key);
  };

  

  return (
    <div className={styles.ticketWrapper}>
      <div className={`${styles.wrapper} ${className ? className :''} ${initialValues?.ticketId ? styles.wrapperWithChat : ''}`}>
        <KoForm form={form} layout="vertical"
          onFinish={handleSubmit} onSubmit={handleSubmit}>
          <div className={styles.leftForm}>
            <KoFormBuilder form={form} meta={BASICMETADATA} />
            <div className={`${styles.taskInfo} profileSection`}>

              <KoTabs activeKey={activeKey} onChange={onTabChange} options={[
                {
                  label: <KoText text={'Task Info'} />,
                  children: <div className={styles.taskInfoTabs}><KoFormBuilder form={form} meta={TASKINFOMETADATA} /></div>,
                  key: 'about'
                },
                {
                  label: 'Attachments',
                  children: <div className={styles.taskInfoTabs}><div className={styles.attachments}>
                    {initialValues?.attachments?.map((att) =>
                      <div key={att.uid} className={styles.attachmentsArea}>
                        <Driver action={true} fileType={removePrefix(att.type, 'image/')} filePath={att.url} />
                      </div>
                    )}
                    <KoForm.Item
                      name={["attachments"]}
                      valuePropName={["attachments"]}
                      getValueFromEvent={normFile}>

                      <Upload {...props}
                        className={styles.card}
                        onRemove={onRemoveFile}
                        showUploadList={false}
                        showPreviewIcon={false}
                        listType="picture-card"
                        multiple={true}
                      >
                        {uploadButton}
                      </Upload>
                    </KoForm.Item>
                  </div></div>,
                  key: 'attachments'
                }
              ]} />
            </div>
          </div>
        </KoForm>

       
      </div>
      <div className={styles.comments}>
        <Comments ticketId={initialValues?.ticketId} workspaceId={initialValues?.workspaceId || initialValues?.workspace} />
      </div>
    </div>
  );
};

export default TaskCrud;