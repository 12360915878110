import { KoAPI } from "packages";
import { configs } from 'configuration';
const version = configs().API_VERSION;

export const ApiClientAPI = {
 
  getCollections(workspaceId) {
    return KoAPI.get(`/api/${version}/apiclient/collection`, {header: {
      "workspace": workspaceId
    }});
  },

  getRecentRequest(workspaceId) {
    return KoAPI.get(`/api/${version}/apiclient/request/recent`, {header: {
      "workspace": workspaceId
    }});
  },

  getRequests(collectionId,workspaceId) {
    return KoAPI.get(`/api/${version}/apiclient/${collectionId}/request`, {header: {
      "workspace": workspaceId
    }});
  },


  getRequestsById(collectionId, rId, workspaceId) {
    return KoAPI.get(`/api/${version}/apiclient/${collectionId}/request/${rId}`, {header: {
      "workspace": workspaceId
    }});
  },


  deleteRequest(collectionId, rId, workspaceId) {
    return KoAPI.delete(`/api/${version}/apiclient/${collectionId}/request/delete/${rId}`, {header: {
      "workspace": workspaceId
    }});
  },

  createOrUpdateRequest(collectionId, environmentId, data, requestId,workspaceId) {
    if(requestId){
      return KoAPI.update(`/api/${version}/apiclient/${collectionId}/request/${requestId}`, { data: { ...data }, header: {
        "workspace": workspaceId
      }});
    }else{
      return KoAPI.post(`/api/${version}/apiclient/${collectionId}/request`, { data: { ...data }, header: {
        "workspace": workspaceId
      }});
    }
  },

  createOrUpdateCollection(data, id, workspaceId) {
    if(id){
      return KoAPI.update(`/api/${version}/apiclient/collection/${id}`, { data: { ...data }, header: {
        "workspace": workspaceId
      }});
    }else{
      return KoAPI.post(`/api/${version}/apiclient/collection`, { data: { ...data }, header: {
        "workspace": workspaceId
      }});
    }
  },

  deleteCollection(id, workspaceId){
    return KoAPI.delete(`/api/${version}/apiclient/collection/${id}`, {header: {
      "workspace": workspaceId
    }});
  },

  getEnvironments(workspaceId) {
    return KoAPI.get(`/api/${version}/apiclient/environment`, {header: {
      "workspace": workspaceId
    }});
  },

  createOrUpdateEnvironment(data, id, workspaceId) {
    if(id){
      return KoAPI.update(`/api/${version}/apiclient/environment/${id}`, { data: { ...data }, header: {
        "workspace": workspaceId
      }});
    }else{
      return KoAPI.post(`/api/${version}/apiclient/environment`, { data: { ...data }, header: {
        "workspace": workspaceId
      }});
    }
  },
  deleteEnvironment(id, workspaceId){
    return KoAPI.delete(`/api/${version}/apiclient/environment/${id}`, {header: {
      "workspace": workspaceId
    }});
  }

};
