import React from "react";
import KoIcon from "packages/icon";
import styles from './status.module.scss';

function UserStatus(props) {
  let { data, bool } = props;
  if (data === 1) {
    data = "active";
  } else if (data === 2) {
    data = "requested";
  } else if (data === 3) {
    data = "invited";
  } else if (data === 0) {
    data = "suspended";
  } else if (data === -1) {
    data = "deleted";
  }
  return (
    bool ? <div className={styles.wrapper}>
      { data ? <KoIcon name='TickFilled' /> : <KoIcon name='CrossFilled' />}
    </div> : <div className={styles.wrapper}>
      <div className={`${styles.status} ${styles[data?.replace(" ", '')]}`}>
        <div className={styles.text}>{data}</div>
      </div>
    </div>

  );
}
export default UserStatus;
