import React from "react";
function ZipFilled(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}>
      <circle cx={24} cy={24} r={23.5} fill="#f4f4f6" />
      <linearGradient
        id="afolder"
        x1={8}
        x2={40}
        y1={24}
        y2={24}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#f3aa0f" />
        <stop offset={1} stopColor="#fed46e" />
      </linearGradient>
      <path
        fill="url(#afolder)"
        d="M37.67 12.69H25.18c-.74 0-1.44-.35-1.88-.95l-2.04-2.79A2.33 2.33 0 0019.38 8h-9.05C9.04 8 8 9.04 8 10.33v27.34C8 38.96 9.04 40 10.33 40h27.34c1.29 0 2.33-1.04 2.33-2.33V15.03c0-1.29-1.04-2.34-2.33-2.34z"
      />
      <path
        fill="#fff"
        d="M16.39 9.99h2.43v2.18h-2.43zm-2.67 2.42h2.43v2.18h-2.43zm2.67 2.43h2.43v2.18h-2.43zm-2.67 2.42h2.43v2.18h-2.43zm2.67 2.42h2.43v2.18h-2.43zm-2.67 2.42h2.43v2.18h-2.43zm4.64 8.73v-4.84h-3.95v4.84a2.823 2.823 0 001.97 4.84c1.56 0 2.82-1.26 2.82-2.82.01-.79-.31-1.5-.84-2.02z"
      />
    </svg>
  );
}


export default ZipFilled;