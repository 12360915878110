import React from 'react';
import { Badge } from 'antd';

export default function KoBadge({color, offset, dot,size, count, children }) {
  return (
    <Badge color={color ?  color : `var(--palette-primary-darker)`} dot={dot} size={size} offset={offset} count={count}>
      {children}
    </Badge>
  );
}
