import React,{useEffect, useState} from 'react';
  // KoCard, KoText, KoTitle, KoCharts,
import { KoTitle,KoCard ,KoCharts,KoText,KoProgressCircle , KoTable} from "packages";
import { WORK_TIMINGS_DATA,DONUT ,ANNUAL_LEAVE_DATA,LEAVE_BALANCE,LEAVE_REQUEST} from './constant';
import styles from './leave.module.scss';
import { useDispatch } from 'react-redux';
import { add, remove } from 'shared/store/breadcrumbSlice';

const Leaves = () => {
  const dispatch = useDispatch();
  const [rowList] = useState();

  useEffect(()=>{
    dispatch(remove());
    dispatch(add([
      {
        title: 'Home',
        path: 'home/dashboard/myoverview/myoverview'
      },
      {
        title: 'Leaves'
      }
    ]));

  },[]);



  const WORKING_TIMINGS = [
    {name:"Annual Leave"},
    {name:"Sick Leave"},
    {name:"Leave Without Pay"},
    {name:"Compensatory Off"},
    {name:"Work from Home"},
    {name:"Maternity Leave"},
    {name:"Paternity Leave"},
    {name:"Exception Leave"}
  ];
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <KoTitle text='Leave Management' level={4} />
      </div>
      <div className={styles.leaveCard}>
        <KoCard className={styles.stats}>
          <KoTitle className={styles.cardTitle} text='Work Timings' level={5}/>
          <div className={styles.timiningChart}>
            <div className={styles.chart}>
              <KoCharts option={WORK_TIMINGS_DATA} />
            </div>
            <div className={styles.chartLegends}>
              {WORKING_TIMINGS.map(({name},i)=>(
                <div className={styles.legendTitle} key={i}>
                  <div className={styles[`hours${i+1}`]}/>
                  <KoText text={name} className={styles.name}/>
                </div>
              ))
              }
            </div>
          </div>
        </KoCard>
        <KoCard className={styles.stats}>
          <KoTitle className={styles.cardTitle} text='Approval Ratio' level={5}/>
          <div className={styles.approvalChart}>
            <div className={styles.graphDonut}>
              <KoCharts option={DONUT} />
            </div>
            <div className={styles.approvalData}>
              <div className={styles.progressCircle}>
                <KoTitle className={styles.text} text='Rejected' level={5} />
                <KoProgressCircle strokeColor={`var(--stuck)`} size={35} type="circle" percent={50} />
              </div>
              <div className={styles.progressCircle}>
                <KoTitle className={styles.text} text='Approved' level={5} />
                <KoProgressCircle strokeColor={`var(--done)`} size={35} type="circle" percent={90} />
              </div>
              <div className={styles.progressCircle}>
                <KoTitle className={styles.text} text='Cancel' level={5} />
                <KoProgressCircle strokeColor={`var(--color-palette-8-mid)`} size={35} type="circle" percent={70} />
              </div>
            </div>
          </div>
        </KoCard>
        <KoCard className={styles.stats}>
          <KoTitle className={styles.cardTitle} text='Annual Leave' level={5}/>
          <div className={styles.annualLeave}>
            <div className={styles.graphDonut}>
              <KoCharts option={ANNUAL_LEAVE_DATA} />
            </div>
          </div>
        </KoCard>
      </div>
      <div className={styles.leaveBalance}>
        <div className={styles.header}>
          <KoTitle text='Leave Balance' level={4} />
        </div>
        <div className={styles.leaveBalanceTable}>
          <KoTable rowKey='leaveBalance' data={{ columns: LEAVE_BALANCE, rows: rowList }} maxHeight={35} />
        </div>
      </div>
      <div className={styles.leaveBalance}>
        <div className={styles.header}>
          <KoTitle text='Leave Request' level={4} />
        </div>
        <div className={styles.leaveBalanceTable}>
          <KoTable rowKey='leaveRequest' data={{ columns: LEAVE_REQUEST, rows: rowList }} maxHeight={35} />
        </div>
      </div>
    </div>
  );
};

export default Leaves;
