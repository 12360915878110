import React from "react";
function BackLog(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
      <g data-name="Layer 1">
        <path d="M59 2H18a3.003 3.003 0 00-3 3v16a3.003 3.003 0 003 3h41a3.003 3.003 0 003-3V5a3.003 3.003 0 00-3-3zm1 19a1.001 1.001 0 01-1 1H18a1.001 1.001 0 01-1-1V5a1.001 1.001 0 011-1h41a1.001 1.001 0 011 1zM5 43h41a3.003 3.003 0 003-3V30a3.003 3.003 0 00-3-3H5a3.003 3.003 0 00-3 3v10a3.003 3.003 0 003 3zM4 30a1.001 1.001 0 011-1h41a1.001 1.001 0 011 1v10a1.001 1.001 0 01-1 1H5a1.001 1.001 0 01-1-1zm1 32h41a3.003 3.003 0 003-3V49a3.003 3.003 0 00-3-3H5a3.003 3.003 0 00-3 3v10a3.003 3.003 0 003 3zM4 49a1.001 1.001 0 011-1h41a1.001 1.001 0 011 1v10a1.001 1.001 0 01-1 1H5a1.001 1.001 0 01-1-1z" />
        <path d="M42.129 7.129l-5.379 5.379-1.878-1.88a3 3 0 00-4.243 4.243l4 4a3.014 3.014 0 004.242 0l7.5-7.5A3 3 0 0042.13 7.13zm2.828 2.828l-7.5 7.5a1.021 1.021 0 01-1.414 0l-4-4a1 1 0 011.414-1.414l2.586 2.586a1 1 0 001.414 0l6.086-6.086a1 1 0 011.414 1.414zM40 39a4 4 0 000-8 4 4 0 000 8zm0-6a2 2 0 010 4 2 2 0 010-4zm0 25a4 4 0 000-8 4 4 0 000 8zm0-6a2 2 0 010 4 2 2 0 010-4z" />
      </g>
    </svg>

  );
}


export default BackLog;