import React from "react";
import styles from '../icons.module.scss';

function ArrowRight(props) {
  const { width, height, color } = props;
  return (
    <svg className={styles.right} width={width} height={height} viewBox="0 0 12 12" fill={color ? color : "#BFBFBF"} aria-hidden="true">
      <path
        d="M6.432 7.967a.448.448 0 01-.318.133h-.228a.46.46 0 01-.318-.133L2.488 4.85a.305.305 0 010-.43l.427-.43a.293.293 0 01.42 0L6 6.687l2.665-2.699a.299.299 0 01.426 0l.42.431a.305.305 0 010 .43L6.432 7.967z">
      </path>
    </svg>
  );
}
export default ArrowRight;