import _find from 'lodash/find';
import { KoBreadcrumb, KoButton, KoDropdown, KoForm, KoFormBuilder, KoIcon, KoLoader, KoModal, KoNano, KoSelect, KoText, KoTicketTable, KoTitle, KoToast, KoTooltips } from 'packages';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from 'react-router-dom';
import { getTicketBreadCrumb } from 'shared/commonConstants';
import { add, remove } from 'shared/store/breadcrumbSlice';
import { getSprint } from 'shared/store/sprintSlice';
import { getCookie } from 'shared/utils/cookies';
import { TaskPlannerAPI } from '../taskPlanner.service';
import { LinkType, Sprint } from './components';
import KoTicketTable_COLUMNS from './constant';
import TaskCrud from './crud';
import styles from './curd.module.scss';
import SubtaskCrud from './subtaskCrud';
// import { postMessage } from 'shared/chat/useSocketActions';
import { setSelectedChatData, setSelectedChatType } from 'shared/store/chatSlice';


function Tasks(props) {
  const {workspaceId} = useParams();
  const dispatch = useDispatch();
  const userById = useSelector(state => state?.userById);

  const {title, profile} = props;
  const [totalCount, setTotalCount] = useState(100);
  const [dueTotalCount, setDueTotalCount] = useState(100);
  const [subTotalCount, setSubTotalCount] = useState(100);
  const [isLoading, setLoading] = useState(false);
  const [isDueLoading, setDueLoading] = useState(false);
  const [isSubLoading, setSubLoading] = useState(false);
  const [isBtnLoading, setBtnLoading] = useState(false);
  const [isOpen, setModal] = useState(false);
  const [isDueDateOpen, setDueDateModal] = useState(false);
  const [copy, setCopy] = useState(false);
  const [isOpenDelete, setDeleteModal] = useState(false);
  let userDetails = userById['data'];
  const [breadCrumbs, setBreadCrumbs] = useState();
  const [isOpenSpr, setSprModal] = useState(false);
  const [isLinkOpen, setLinkModal] = useState(false);
  const [isAddSubTaskOpen, setSubTaskOpen] = useState(false);
  const [openSubModel, setSubModel] = useState(false);
  const [currentRecord, setCurrentRecord] = useState({
    status:1,
    issueType:2,
    priority:3,
    reporter: userDetails?.userId
  });
  const [subCurrentRecord, setSubCurrentRecord] = useState({
    status:1,
    issueType:2,
    priority:3,
    reporter: userDetails?.userId
  });
  const [ dueCurrentRecord, setDueCurrentRecord] = useState({
    status:1,
    issueType:2,
    priority:3,
    reporter: userDetails?.userId
  });
  const [tickets, setTickets] = useState();
  const [dueTickets, setDueTickets] = useState();
  const [ subTickets ,setSubTickets] = useState();
  const [pagination, setPagination] = useState({
    size: 10,
    page: 1
  });
  const [subPagination, setSubPagination] = useState({
    size: 10,
    page: 1
  });
  const [duePagination, setDuePagination] = useState({
    size: 10,
    page: 1,
    isDueDay: true
  });
  const [form] = KoForm.useForm();
  const [sprForm] = KoForm.useForm();
  const [LinkForm] = KoForm.useForm();
  
  const workspace = useSelector(state => state?.workspace);
  let workspaceList = workspace['data'];
  const currentWorkspace = _find(workspaceList, function(o) { return o.workspaceId === workspaceId; });
  
  useEffect(()=>{
    dispatch(remove());
    dispatch(add([
      {
        title: 'workspace',
        path: 'workspace'
      },
      {
        title: currentWorkspace?.name
      },
      {
        title: 'My Tickets'
      }
    ]));

  }, [workspaceId, currentWorkspace]);


  useEffect(()=>{
    if(isDueDateOpen){
      getDueTickets();
    }
  },[isDueDateOpen]);
  useEffect(()=>{
    getTickets();
  },[workspaceId]);
  
  useEffect(()=>{
    dispatch(getSprint({ workspaceId: workspaceId }));
  },[workspaceId]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copy) setCopy(false);
    }, 500);

    return () => clearTimeout(timeout);
  }, [copy]);

  const getTickets=()=>{
    TaskPlannerAPI.getIssues(pagination, workspaceId).then(
      (data) => {
        setLoading(true);
        setTickets();
        setTotalCount();
        setLoading(false);
        setModal(false);
        setTickets(data?.tickets);
        setTotalCount(data?.totalCount);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const getDueTickets=()=>{
    setDueLoading(true);
    TaskPlannerAPI.getIssues(duePagination, workspaceId).then(
      (data) => {
        setDueTickets();
        setDueTotalCount();
        setDueLoading(false);
        setModal(false);
        setDueTickets(data?.tickets);
        setDueTotalCount(data?.totalCount);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const onChangeStatus=(key)=>{
    let q =  pagination;
    if(key){
      q['status'] = key.toString();
    }else{
      delete q?.['status'];
    }
   
    getTickets(q);
  };
  const onChangeType=(key)=>{
    let q =  pagination;
    if(key){
      q['issueType'] = key.toString();
    }else{
      delete q['issueType'];
    }
    getTickets(q);
  };
  const onChangePriority =(key)=>{
    let q =  pagination;
    if(key){
      q['priority'] = key.toString();
    }else{
      delete q?.['priority'];
    }
   
    getTickets(q);
  };

  const onChangeAssignees =(key)=>{
    let q =  pagination;
    if(key){
      q['assignee'] = key.toString();
    }else{
      delete q?.['assignee'];
    }
   
    getTickets(q);
  };
   
  const onPagination = (page, other) => {
    const query = {
      size: other,
      page: page
     
    };
    const p = Object.assign(pagination, query);
    setPagination(p);
    getTickets(p);
  };


  const onChangeDueStatus=(key)=>{
    let q =  duePagination;
    if(key){
      q['status'] = key.toString();
    }else{
      delete q?.['status'];
    }
   
    getDueTickets(q);
  };

  const onChangeDueType=(key)=>{
    let q =  duePagination;
    if(key){
      q['issueType'] = key.toString();
    }else{
      delete q['issueType'];
    }
    getDueTickets(q);
  };

  const onChangeDuePriority =(key)=>{
    let q =  duePagination;
    if(key){
      q['priority'] = key.toString();
    }else{
      delete q?.['priority'];
    }
   
    getDueTickets(q);
  };

  const onChangeDueAssignees =(key)=>{
    let q =  duePagination;
    if(key){
      q['assignee'] = key.toString();
    }else{
      delete q?.['assignee'];
    }
   
    getDueTickets(q);
  };

  const onDueSearchText=(text)=>{
    let q = duePagination;
    q['search'] = text;
    getDueTickets(q);
  };

  const onDueSort = (key, type) => {
    let q = duePagination;
    q['sort'] = `${key}:${type}`;
    getDueTickets(q);
  };
   
  const onDuePagination = (page, other) => {
    const query = {
      size: other,
      page: page
     
    };
    const p = Object.assign(duePagination, query);
    setDuePagination(p);
    getDueTickets(p);
  };


  const onChangeSubStatus=(key)=>{
    let q =  subPagination;
    if(key){
      q['status'] = key.toString();
    }else{
      delete q?.['status'];
    }
   
    getSubIssueById(currentRecord.ticketId, q);
  };

  const onChangeSubType=(key)=>{
    let q =  subPagination;
    if(key){
      q['issueType'] = key.toString();
    }else{
      delete q['issueType'];
    }
    getSubIssueById(currentRecord.ticketId, q);
  };

  const onChangeSubPriority =(key)=>{
    let q =  subPagination;
    if(key){
      q['priority'] = key.toString();
    }else{
      delete q?.['priority'];
    }
   
    getSubIssueById(currentRecord.ticketId, q);
  };

  const onChangeSubAssignees =(key)=>{
    let q =  subPagination;
    if(key){
      q['assignee'] = key.toString();
    }else{
      delete q?.['assignee'];
    }
   
    getSubIssueById(currentRecord.ticketId, q);
  };
  
  const onSubSearchText=(text)=>{
    let q = subPagination;
    q['search'] = text;
    getSubIssueById(currentRecord.ticketId, q);
  };

  const onSubSort = (key, type) => {
    let q = subPagination;
    q['sort'] = `${key}:${type}`;
    getSubIssueById(currentRecord.ticketId, q);
  };
   
  const onSubPagination = (page, other) => {
    const query = {
      size: other,
      page: page
     
    };
    const p = Object.assign(subPagination, query);
    setSubPagination(p);
    getSubIssueById(currentRecord.ticketId, p);
  };

  const handleFinish = (values => {
    setLoading(true);
    
    TaskPlannerAPI.createOrUpdateIssues(values, currentRecord?.ticketId || dueCurrentRecord?.ticketId, workspaceId).then(
      (data) => {
        setLoading(false);
        // setModal(false);
        // const newMessage = {
        //   ...values,
        //   attachments: values?.attachments ? values?.attachments : null,
        //   sender: values?.reporter,
        //   receiver: [values?.assignee],
        //   dateTime: new Date().toISOString(),
        //   isOwnMessage: false,
        //   type: 'ticket',
        //   ticketId: currentRecord?.ticketId || values?.ticketId,
        //   messageId: KoNano('msg')
        // };
        // postMessage(`${values?.reporter}_${values?.assignee}`, 'ticket', newMessage);
        getTickets();
        form.resetFields();
        KoToast.success(`Ticket ${currentRecord?.ticketId || dueCurrentRecord?.ticketId ? 'updated':'created'} successfully`);
        if(dueCurrentRecord?.ticketId){
          getDueTickets();
        }
      },
      (err) => {
        setLoading(false);
      }
    );
  });


  const handleUpdateStatus = (values => {
    setBtnLoading(true);
    const updatedValues = {
      status: values['status'] ? values['status'] === 2 ? 1 : 2 : currentRecord?.status,
      sprint: values?.sprint ? values?.sprint : currentRecord?.sprint,
      link: {
        type: values?.type ?values?.type : currentRecord?.link?.type,
        ticketId: values?.ticketId ? values?.ticketId   : currentRecord?.link?.ticketId
      }
    };

    TaskPlannerAPI.UpdateStatusSprint(updatedValues, currentRecord?.ticketId, workspaceId).then(
      (data) => {
        setBtnLoading(false);
        getTickets();
        setSprModal(false);
        form.resetFields();
        KoToast.success(`Ticket updated successfully`);
      },
      (err) => {
        setBtnLoading(false);
      }
    );
  });



  const onAddSprint = (row) => {
    setSprModal(true);
  };

  const DeleteIssues= ()=> {
    setLoading(true);
    TaskPlannerAPI.deleteIssues(currentRecord?.ticketId, workspaceId).then(
      (data) => {
        setLoading(false);
        setDeleteModal(false);
        getTickets();
        form.resetFields();
        KoToast.success("Ticket deleted successfully");
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const getIssueById = (ticketId, workspaceIdFromParam)=> {
    setLoading(true);
    TaskPlannerAPI.getIssueById(ticketId, workspaceId || workspaceIdFromParam).then(
      (data) => {
        setLoading(false);
        setCurrentRecord(data?.issue);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const getSubIssueById = (ticketId)=> {
    setSubLoading(true);
    TaskPlannerAPI.getSubTaskIssuesWithQuery(subPagination, ticketId,  workspaceId).then(
      (data) => {
        setSubLoading(false);
        setSubTotalCount(data?.totalCount);
        setSubTickets(data?.tickets);
      },
      (err) => {
        setSubLoading(false);
      }
    );
  };


  const handleFinishSubTask = (values => {
    setLoading(true);
    values['parentId'] = currentRecord?.ticketId;
    values['parentIssueType'] = currentRecord?.issueType;
    values['issueType'] = 4;
    values['epic'] = values?.epic ?  values?.epic : currentRecord?.epic;
    values['priority'] = values?.priority ?  values?.priority : currentRecord?.priority;
    values['assignee'] = values?.assignee ?  values?.assignee : currentRecord?.assignee;
    values['sprint'] =  values?.sprint ?  values?.sprint : currentRecord?.sprint;
    values['reporter'] = values?.reporter ?  values?.reporter : currentRecord?.reporter;
    values['timeSpent'] = values?.timeSpent ?  values?.timeSpent : currentRecord?.timeSpent;
    values['timeRemaining'] = values?.timeRemaining ?  values?.timeRemaining : currentRecord?.timeRemaining;

    TaskPlannerAPI.createOrUpdateIssues(values, null, workspaceId).then(
      (data) => {
        setLoading(false);
        setSubTaskOpen(false);
        const newMessage = {
          ...values,
          attachments: values?.attachments ? values?.attachments : null,
          sender: values?.reporter,
          receiver: [values?.assignee],
          dateTime: new Date().toISOString(),
          isOwnMessage: false,
          type: 'ticket',
          ticketId: currentRecord?.ticketId || values?.ticketId,
          messageId: KoNano('msg')
        };
        postMessage(`${values?.reporter}_${values?.assignee}`, 'ticket', newMessage);
        getTickets();
        form.resetFields();
        KoToast.success(`Subtask created successfully`);
      },
      (err) => {
        setLoading(false);
      }
    );
  });

  const onCopyTicket=(row)=>{
    setCopy(true);
    navigator.clipboard.writeText(`${window.location.origin}/open/ticket/${getCookie("accountId")}/${row?.workspace}/${row?.ticketId}`);
  };
 

  const onCellClick=(row)=>{
    setCurrentRecord(row);
    const updatedRoomObj = { ...row, source: 'tickets' };
    dispatch(setSelectedChatData(updatedRoomObj));
    dispatch(setSelectedChatType('tickets'));
    let crums = getTicketBreadCrumb(row);
    setBreadCrumbs(crums);
    setModal(true);
  };

  const onExtraClick=(row)=>{
    setCurrentRecord(row);
    const updatedRoomObj = { ...row, source: 'tickets' };
    dispatch(setSelectedChatData(updatedRoomObj));
    dispatch(setSelectedChatType('tickets'));
    let crums = getTicketBreadCrumb(row);
    setBreadCrumbs(crums);
    getSubIssueById(row.ticketId);
    setSubModel(true);
    console.log(subCurrentRecord);
  };


  const onSearchText=(text)=>{
    let q = pagination;
    q['search'] = text;
    getTickets(q);
  };

  const onSort = (key, type) => {
    let q = pagination;
    q['sort'] = `${key}:${type}`;
    getTickets(q);
  };


  let currentValue;
  let timeout;

  const fetch = (value, callback) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    currentValue = value;
    
    const HandleSearch = () => {
      const queryParam = { search: value };
      TaskPlannerAPI.getIssues(queryParam, workspaceId).then(
        (d) => {
          if (currentValue === value) {
            if (Array.isArray(d?.tickets)) {
              const data = d?.tickets?.map((item) => {
                return {
                  ticketId: item.ticketId,
                  summary: item.summary,
                  issueType: item.issueType
                };
              });

              callback(data);
            } else {
              callback([]);
            }
          }
        });
    };

    if (value) {
      timeout = setTimeout(HandleSearch, 200);
    } else {
      callback([]);
    }
  };

  const issueTypes = [
    { label: 'Epic', value: 1 },
    { label: 'Story', value: 2 },
    { label: 'Task', value: 3 },
    { label: 'Subtask', value: 4 },
    { label: 'Bug', value: 5 },
    { label: 'Improvements', value: 6 },
    { label: 'Feature request', icon: 'FeatureRequestFilled', value: 7 },
    { label: 'Change request', icon: 'ChangeRequestFilled', value: 8 },
    { label: 'Support', value: 9 }
  ];

  const SearchInput = ({ value, onChange }) => {
    const [data, setData] = useState([]);

    const handleSearch = (newValue) => {
      fetch(newValue, setData);
    };

    const handleChange = (selectedValue) => {
      const selectedOption = data.find(d => d.ticketId === selectedValue);
      if (selectedOption) { onChange(selectedOption?.ticketId);}
    };

    return (
      <KoSelect
        showSearch
        value={value?.ticketId}
        placeholder='Select Ticket'
        defaultActiveFirstOption={false}
        suffixIcon={null}
        filterOption={false}
        onSearch={handleSearch}
        onChange={handleChange}
        notFoundContent={null}
        options={(data || []).map((d) => {
          const issueType = _find(issueTypes, (o) => o.value === d?.issueType);
          return {
            label: (
              <div className={styles.TicketIdWrapper} aria-label={d?.issueType}>
                <KoIcon
                  name={issueType?.icon ? issueType?.icon : `${issueType?.label}Filled`}
                  width={14} height={14} />
                <KoText className={styles.text} text={`${d?.ticketId} | ${d?.summary}`} />
              </div>
            ),
            value: d?.ticketId
          };
        })}
      />
    );
  };

  const meta = {
    columns: 2,
    formItemLayout: null,
    initialValues: currentRecord?.sprint,
    colon: true,
    fields: [
      {
        key: 'sprint', label: 'Sprint', colSpan: 2, widget: Sprint,  forwardRef: true
      }
    ]

  };

  const linkMeta = {
    columns: 6,
    formItemLayout: null,
    initialValues: currentRecord?.link,
    colon: true,
    fields: [
      {
        key: 'type', label: 'Select ticket type', colSpan: 2, widget: LinkType,  forwardRef: true
      },
      {
        key: 'ticketId', label: 'Select ticket', widget: SearchInput,  colSpan: 4
      }
    ]

  };


  const onHandelTask = (e, row) => {
    setCurrentRecord(row);
    if (e.key === "1") {
      setLinkModal(true);
    }else if (e.key === "2") {
      onAddSprint(row.ticketId);
    }else if (e.key === "3") {
      setDeleteModal(true);
    }
  };

  const HandleAddSubtask = (row) => {
    let crums = getTicketBreadCrumb(row);
    setBreadCrumbs(crums);
    setSubTaskOpen(true);
  };



  const columnsRight = [{
	  title: '',
	  dataIndex: 'x',
	  key: 'x',
	  fixed: 'right',
	  width: 40,
	  render: (data, row) => {
      const isDeleteShow = row.createdBy === getCookie("userId") || userDetails?.roles?.includes([1,2,3]);

	    return <div className={`${styles.table} tableBtn columnsRight`} onClick={() => setCurrentRecord(row)}>
        <KoTooltips title={row.status === 2 ? 'Pause' : 'Start'} disabled={isBtnLoading}>
          <div  onClick={() => {handleUpdateStatus(row);}}  >
            <KoIcon width={18} height={18} name={row.status === 2 ?  'PauseOutlined' :'PlayOutlined'}/>
          </div>
        </KoTooltips>
        <KoTooltips title={row?.issueType !== 4 ?'Add Subtask' : 'Add Subtask'}>
          <div onClick={row?.issueType !== 4 ? () => HandleAddSubtask(row) : null} style={{visibility: row?.issueType === 4 ? 'hidden' : ''}}>
            <KoIcon width={18} height={18} name={'PlusFilled'} />
          </div>
        </KoTooltips>

        <KoTooltips title={copy ? 'Copied' : 'Copy'}>
	      <KoButton onClick={()=>onCopyTicket(row)} className={'btn'} iconBtn={true} icon={<KoIcon width={14} height={14} name={'CopyOutlined'}/>}/>
        </KoTooltips>

        <KoTooltips title={'New window'}>
          <Link target="_blank" to={`/open/ticket/${getCookie("accountId")}/${row?.workspace}/${row?.ticketId}`}>
            <KoButton className={'btn'}  iconBtn={true} icon={<KoIcon width={14} height={14} name={'OpenOutlined'}/>}/>
          </Link>
        </KoTooltips>

        <div shape="circle">
          <KoDropdown
            menu={{
              items: [
                {
                  key: '1',
                  label: 'Link',
                  icon: <KoIcon name="LinkOutlined" height={12} width={13} />
                },
                {
                  key: '2',
                  label: `${row.sprint ? 'Update sprint' : 'Add sprint'}`,
                  icon: <KoIcon name="PlusOutlined" height={15} width={16} />
                },
                ...(isDeleteShow
                  ? [{
                    key: '3',
                    danger: true,
                    label: 'Delete',
                    icon: <KoIcon name="DeleteOutlined" height={12} width={13} />
                  }]
                  : [])
              ],
              onClick: (e) => onHandelTask(e, row)
            }}
            overlayClassName={styles.actionDropdown}
            placement="bottomLeft"
          >
            <KoButton
              className={'btn'}
              iconBtn={true}
              icon={<KoIcon width={14} height={14} name={'VerticalMenuOutlined'} />}
            />
          </KoDropdown>
        </div>
	    </div>;
	  }
  }];




  return (<div className='tableCard'>
    { isLoading && <KoLoader />}

    <KoTicketTable
      setModal={setModal}
      setDueModal={setDueDateModal}
      isDueDateOpen={isDueDateOpen}
      onSearchText={onSearchText}
      profile={profile}
      getIssueById={getIssueById}
      isImport={getTickets}
      isExport={getTickets}
      title={title}
      API={TaskPlannerAPI}
      maxHeight={92}
      onSort={onSort}
      dueDate={{
        visible : true
      }}
      onChangeStatus={onChangeStatus}
      onChangeType={onChangeType}
      onChangePriority={onChangePriority}
      onChangeAssignees={onChangeAssignees}
      setCurrentRecord={setCurrentRecord}
      isOpen={isOpen}
      Bulk={{
        visible: false
      }}
      RowSelectionOption={{
        visible: false
      }}
      play={true}
      setBreadCrumbs={setBreadCrumbs}
      tickets={tickets}
      getChild={onCellClick}
      onExtraClick={onExtraClick}
      extraColumnsRight={columnsRight}
      onPagination={onPagination}
      pagination={{page: pagination.page, limit: pagination.limit}}
      totalCount={totalCount} />

    <KoModal
      title={
        breadCrumbs
          ? <KoBreadcrumb items={breadCrumbs} />
          : (currentRecord?.ticketId || dueCurrentRecord?.ticketId)
            ? `Update Issue ${(currentRecord?.ticketId || dueCurrentRecord?.ticketId).toUpperCase()}`
            : `Create Issue`}
      centered
      afterClose={() => {setCurrentRecord(); form.resetFields();setBreadCrumbs();}}
      open={isOpen}
      handleOk={() => {setCurrentRecord(); setModal(false);}}
      onClose={() => {setCurrentRecord(); setModal(false); form.resetFields();}}
      width={'95vw'}
      maskClosable={false}
      footer={[
        <KoButton key='cancel' label='Cancel' onClick={() => {setModal(!isOpen); form.resetFields();}} />,
        <KoButton key='create' loading={isLoading} type='primary' label={currentRecord?.ticketId ? `Update` : `Create`}
          onClick={() => form.submit()} />
      ]}
    >
      {isLoading && <KoLoader circle={false} />}
      <TaskCrud form={form} handleFinish={handleFinish} initialValues={currentRecord ? currentRecord : dueCurrentRecord} workspaceProfile={profile} getIssueById={getIssueById}/>
        
    </KoModal>
    <KoModal
      title={`Delete Ticket ${currentRecord?.ticketId?.toUpperCase()}`}
      centered
      open={isOpenDelete}
      afterClose={() => { setCurrentRecord(); }}
      handleOk={() => { setCurrentRecord(); setDeleteModal(false); }}
      onClose={() => { setCurrentRecord(); setDeleteModal(false); }}
      width={580}
      footer={[
        <KoButton key='cancel' label='Cancel' onClick={() => setDeleteModal(!isOpenDelete)} />,
        <KoButton key='delete' loading={isLoading} type='primary' label='Delete Ticket' onClick={() => DeleteIssues()} />
      ]}
    >
      <div className={styles.wrapperModal}>
        <KoTitle level={5} text={`This action will delete item and cannot be undone`} />
        <KoText text={`Do you wish to proceed?`} />
      </div>
    </KoModal>

    <KoModal
      title={`${currentRecord?.sprint ? 'Update' : 'Add'} Sprint ${currentRecord?.ticketId?.toUpperCase()}`}
      centered
      open={isOpenSpr}
      afterClose={() => { setCurrentRecord(); }}
      handleOk={() => { setCurrentRecord(); setSprModal(false); }}
      onClose={() => { setCurrentRecord(); setSprModal(false); }}
      width={380}
      // maskClosable={false}
      height={450}
      footer={[
        <KoButton key='cancel' label='Cancel' onClick={() => setSprModal(!isOpenSpr)} />,
        <KoButton key='Update' loading={isLoading} type='primary' label='Update' onClick={() => sprForm.submit()} />
      ]}
    >
      <div className={styles.wrapper}>
        <KoForm form={sprForm} layout="vertical" onFinish={handleUpdateStatus} onSubmit={handleUpdateStatus}>
          <KoFormBuilder form={sprForm} meta={meta} />
        </KoForm>
      </div>
    </KoModal>

    <KoModal
      title={'Overdue Tasks'}
      centered
      open={isDueDateOpen}
      afterClose={() => { setDueCurrentRecord(); setBreadCrumbs();}}
      handleOk={() => { setDueCurrentRecord(); setDueDateModal(false); }}
      onClose={() => { setDueCurrentRecord(); setDueDateModal(false); }}
      // maskClosable={false}
      width={`95vw`}
      footer={[]}>
      <>
        {isDueLoading && <KoLoader circle={false} />}
        <KoTicketTable
          setModal={setModal}
          onSearchText={onDueSearchText}
          profile={profile}
          API={TaskPlannerAPI}
          getIssueById={getIssueById}
          onSort={onDueSort}
          columns={KoTicketTable_COLUMNS}
          onChangeStatus={onChangeDueStatus}
          onChangeType={onChangeDueType}
          onChangePriority={onChangeDuePriority}
          onChangeAssignees={onChangeDueAssignees}
          setCurrentRecord={setDueCurrentRecord}
          isOpen={isDueDateOpen}
          maxHeight={100}
          Bulk={{
            visible: false
          }}
          RowSelectionOption={{
            visible: false
          }}
          setBreadCrumbs={setBreadCrumbs}
          createBtn={false}
          tickets={dueTickets}
          columnsRight={columnsRight}
          onPagination={onDuePagination}
          pagination={{page: duePagination.page, limit: duePagination.limit}}
          totalCount={dueTotalCount} />
      </>
    </KoModal>

    <KoModal
      title={`${currentRecord?.ticketId } Subtasks Lists`}
      centered
      open={openSubModel}
      afterClose={() => { setSubCurrentRecord(); setBreadCrumbs();}}
      handleOk={() => { setSubCurrentRecord(); setSubModel(false); }}
      onClose={() => { setSubCurrentRecord(); setSubModel(false); }}
      // maskClosable={false}
      width={`95vw`}
      footer={[]}>
      <>
        {isSubLoading && <KoLoader circle={false} />}
        <KoTicketTable
          setModal={setModal}
          onSearchText={onSubSearchText}
          profile={profile}
          API={TaskPlannerAPI}
          getIssueById={getIssueById}
          onSort={onSubSort}
          onChangeStatus={onChangeSubStatus}
          onChangeType={onChangeSubType}
          onChangePriority={onChangeSubPriority}
          onChangeAssignees={onChangeSubAssignees}
          setCurrentRecord={setSubCurrentRecord}
          isOpen={openSubModel}
          maxHeight={100}
          Bulk={{
            visible: false
          }}
          RowSelectionOption={{
            visible: false
          }}
          setBreadCrumbs={setBreadCrumbs}
          createBtn={false}
          tickets={subTickets}
          columnsRight={columnsRight}
          onPagination={onSubPagination}
          pagination={{page: subPagination.page, limit: subPagination.limit}}
          totalCount={subTotalCount} />
      </>
    </KoModal>


    <KoModal
      title={`Select an ticket to link`}
      centered
      open={isLinkOpen}
      afterClose={() => { setCurrentRecord(); }}
      handleOk={() => { setCurrentRecord(); setLinkModal(false); }}
      onClose={() => { setCurrentRecord(); setLinkModal(false); }}
      width={680}
      // maskClosable={false}
      height={450}
      footer={[
        <KoButton key='cancel' label='Cancel' onClick={() => setLinkModal(!isLinkOpen)} />,
        <KoButton key='link' loading={isLoading} type='primary' label='Link' onClick={() => LinkForm.submit()} />
      ]}
    >
      <div className={styles.wrapper}>
        <KoForm form={LinkForm} layout="vertical" onFinish={handleUpdateStatus} onSubmit={handleUpdateStatus}>
          <KoFormBuilder form={LinkForm} meta={linkMeta} />
        </KoForm>
      </div>
    </KoModal>

    <KoModal
      title={ breadCrumbs ? <KoBreadcrumb items={breadCrumbs} /> : (currentRecord?.ticketId || dueCurrentRecord?.ticketId)}
      centered
      open={isAddSubTaskOpen}
      afterClose={() => { setCurrentRecord(); form.resetFields();}}
      handleOk={() => { setCurrentRecord(); setSubTaskOpen(false); }}
      onClose={() => { setCurrentRecord(); setSubTaskOpen(false);form.resetFields(); }}
      width={`65vw`}
      // maskClosable={false}
      height={450}
      footer={[
        <KoButton key='cancel' label='Cancel' onClick={() => {setSubTaskOpen(!isAddSubTaskOpen); form.resetFields();}} />,
        <KoButton key='create' loading={isLoading} type='primary' label='Create' onClick={()=>form.submit()}/>
      ]}
    >
      <div className={styles.wrapperModal}>
        <SubtaskCrud form={form} handleFinish={handleFinishSubTask}  profile={profile}/>
      </div>
    </KoModal>

  </div>);
}

export default Tasks;