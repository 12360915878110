import React from "react";
function Filter(props) {

  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width={width} height={height}>
      <path
        d="M60 10H4a2 2 0 0 0 0 4H60A2 2 0 0 0 60 10zM52 30H12a2 2 0 0 0 0 4H52A2 2 0 0 0 52 30zM41.23 50H22.77a2 2 0 0 0 0 4H41.23A2 2 0 0 0 41.23 50z"
        data-name="Layer 91" fill="#000000" className="color000 svgShape"></path>
    </svg>
  );

}


export default Filter;