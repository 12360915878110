
const COLUMNS = [{
  name: "serviceId",
  id: "serviceId",
  label: "Service Id",
  widget: "link",
  fixed: true
}, {
  name: "serviceName",
  id: "serviceName",
  label: "Service Name",
  widget: "text"
}, {
  name: "buildNo",
  id: "buildNo",
  label: "Build No",
  widget: "text"
},
{
  name: "requested",
  id: "requested",
  label: "Requested by",
  widget: "members"
},
{
  name: "assignee",
  id: "assignee",
  label: "Approved by",
  widget: "members"
},
{
  name: "status",
  id: "status",
  label: "Status",
  widget: "status"
},

{
  name: "date",
  id: "date",
  label: "Deployed On",
  widget: "datetime"
}];

const ROWS=[
  {
    serviceId: '1',
    name: "Hr",
    buildNo: "98766433456",
    email: "kamal@qubernet.com",
    description: `Lorem Ipsum is simply dummy text of the printing and Typesetting industry.` +
          `Lorem Ipsum has been the industry’s Standard dummy text ever since the 1500s,` +
          ` when an unknown Printer took a gallery of type and scrambled it to make a type Specimen book.` +
          `It has survived not only five centuries, but also The leap into electronic typesetting`,
    status: "Active",
    roles: 'Paradot',
    groups: 'human resources',
    lastSignedIn: '4557890',
    storyPoints: 8,
    reporter: [{ name: 'Kamal' }],
    comments: [{ text: 1 }, { text: 2 }],
    priority: 2,
    requested: [{ name: 'kamal', profilePic: 'm1' }],
    assignee: [{ name: 'kamal', profilePic: 'm1' }, { name: 'suku', profilePic: 'm2' }, { name: 'john', profilePic: 'm3' }]
      
  }
];
const SERVICESTATUS =[
  {
    label: 'Open',
    value:1
  },{
    label: 'Approved',
    value:2
  }, {
    label: 'Rejected',
    value:3
  }, {
    label: 'Closed',
    value:4
  }
];

export {
  COLUMNS,
  ROWS,
  SERVICESTATUS
};