import React from "react";
import { Progress } from 'antd';
import _find from 'lodash/find';
import styles from './link.module.scss';
import { TASKSTATUSLIST } from "../common";

function Percentage(props) {
  const { data, onCellClick, row } = props;
  


  let issueType = _find(TASKSTATUSLIST, function(o) { return o.value === row?.status; }) || {};
  return (
    <div onClick={()=>onCellClick && onCellClick(row)} className={styles.wrapper}>
      <Progress
        percent={data.toFixed(0)}
        percentPosition={{
          align: 'end',
          type: 'inner'
        }}
        size={[200, 15]}
        trailColor={issueType?.background}
        strokeColor= {issueType?.color}
      />
    </div>
  );
}
export default Percentage;