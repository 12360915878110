import KoAPI from "../api";
import { configs } from 'configuration';
const version = configs().API_VERSION;
export const UploadAPI = {
  getUpload(data){
    return KoAPI.post(`/api/${version}/upload/read`, { data: { ...data } });
  },
  setUpload(data){
    return KoAPI.post(`/api/${version}/upload`, { data: { ...data } });
  }
};