import React from 'react';
import styles from './dashboard.module.scss';
import { KoCharts, KoProgressCircle, KoTable, KoText, KoTitle } from 'packages';
import { COLUMN,DONUT, ROW, BILLABLE_HOURS,INVOICE_EXPENDITURE,ACCOUNT_RECEIVABLE_COLUMN,INVOICE_TABLE } from './constant';
export default function FilesDashboard() {

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.invoiceSummary}>
          <div className={styles.invoiceSummaryRow}>
            <div className={styles.card}>
              <div className={styles.header}>
                <KoTitle text='File Summary' level={5} />
              </div>
              <div className={`${styles.bodyContnet} smallTable`}>
                <div className={styles.invoiceStatus}>
                  <div className={styles.progressCircle}>
                    <KoProgressCircle strokeColor={`var(--palette-info-main)`} size={35} type="circle" percent={70} />
                    <div className={styles.amount}>
                      <KoTitle className={styles.text} text='132' level={5} />
                      <KoText className={styles.small} text='Total File' />
                    </div>
                  </div>
                  <div className={styles.progressCircle}>
                    <KoProgressCircle size={35} strokeColor={`var(--palette-success-main)`} type="circle" percent={70} />
                    <div className={styles.amount}>
                      <KoTitle className={styles.text} text='12' level={5} />
                      <KoText className={styles.small} text='Total Received' />
                    </div>
                  </div>
                  <div className={styles.progressCircle}>
                    <KoProgressCircle size={35} strokeColor={`var(--palette-warning-main)`} type="circle" percent={30} />
                    <div className={styles.amount}>
                      <KoTitle className={styles.text} text='23' level={5} />
                      <KoText className={styles.small} text='Total Pending' />
                    </div>
                  </div>
                  <div className={styles.progressCircle}>
                    <KoProgressCircle size={35} strokeColor={`var(--palette-error-main)`} type="circle" percent={30} />
                    <div className={styles.amount}>
                      <KoTitle className={styles.text} text='343' level={5} />
                      <KoText className={styles.small} text='Overdue' />
                    </div>
                  </div>
                  <div className={styles.progressCircle}>
                    <KoProgressCircle size={35} strokeColor={`var(--palette-gray-7)`} type="circle" percent={30} />
                    <div className={styles.amount}>
                      <KoTitle className={styles.text} text='3' level={5} />
                      <KoText className={styles.small} text='Draft' />
                    </div>
                  </div>
                </div>
                <KoTable data={{ columns: COLUMN, rows: ROW }} maxHeight={65} />
              </div>
            </div>
          </div>
          <div className={styles.invoiceRow}>
            <div className={styles.card}>
              <div className={styles.header}>
                <KoTitle text='File Performance' level={5} />
              </div>
              <div className={styles.bodyContnet}>
                <div className={styles.graphDonut}>
                  <KoCharts option={BILLABLE_HOURS} />
                </div>
              </div>
            </div>
            <div className={styles.card}>
              <div className={styles.header}>
                <KoTitle text='File Proceesing Status' level={5} />
              </div>
              <div className={styles.bodyContnet}>
                <div className={styles.graphDonut}>
                  <KoCharts option={DONUT} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.incomeExpenditure}>
          <div className={styles.header}>
            <KoTitle text='Quick summary' level={5} />
          </div>
          <div className={styles.bodyContnet}>
            <KoTable rowKey='accountReceivable' data={{ columns: ACCOUNT_RECEIVABLE_COLUMN, rows: [] }} maxHeight={35} />
          </div>
          <div className={styles.header}>
            <KoTitle text='Me vs Shared' level={5} />
          </div>
          <div className={styles.bodyContnet}>
            <div className={styles.graphDonut}>
              <KoCharts option={INVOICE_EXPENDITURE} />
            </div>
            <div className={styles.expenditure}>
              {[1, 2, 3, 4, 5].map((item) =>
                <div key={item} className={styles.activity}>
                  <KoTitle className={styles.name} text={(item === 1 || item === 3) ? 'Office Supplies' :'Accommodation'} level={5} />
                  <div className={styles.amountExpenditure}>
                    <KoTitle className={styles.text} text='$ 13,870.00' level={5} />
                    <KoProgressCircle size={20} strokeColor={`${(item === 1 || item === 3)? 'var(--palette-error-dark)}' : 'var(--palette-success-dark)'}`} type="circle" percent={30+item} />
                  </div>
               
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
 
    
      <div className={styles.invoiceTable}>
        <div className={styles.header}>
          <KoTitle text='Recent Files' level={5} />
        </div>
        <div className={styles.segMentList}>
          <div className={styles.accountReceivable}>
            <KoTable rowKey='accountPayable' data={{ columns: INVOICE_TABLE, rows: [] }} maxHeight={35} />
          </div>
        </div>
      </div>
    </>
  );
}
