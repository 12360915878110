import React from "react";
import styles from './status.module.scss';

function SprintStatus(props) {
  let { data } = props;
  if (data === 0) {
    data = "Planning";
  }else if (data === 1) {
    data = "Todo";
  } else if (data === 2) {
    data = "Active";
  } else if (data === 3) {
    data = "Suspended";
  } else if (data === 4) {
    data = "Closed";
  }

  return (
    <div className={styles.wrapper}>
      <div className={`${styles.status} ${styles[data]}`}>
        <div className={styles.text}>{data}</div>
      </div>
    </div>
  );
}
export default SprintStatus;
