import React from "react";
import styles from './link.module.scss';

function DashBoardLink(props) {
  const { data, onCellClick, row } = props;
  return (
    <div onClick={()=>onCellClick && onCellClick(row)} className={styles.wrapper}>
      {data}
    </div>
  );
}
export default DashBoardLink;