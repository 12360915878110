import React, { useEffect, useState } from 'react';
import styles from './trackActivities.module.scss';
import { KoLoader, KoTable, KoTitle } from 'packages';
import { DashbordAPI } from '../dashboard.service';
import { dueCountCol } from '../constant';

export default function TrackActivities() {
  const [issueTypeCount, setIssueTypeCount] = useState([]);
  const [workspaceActiveCount, setWorkspaceActiveCount] = useState([]);
  const [ticketUserData, setTicketUserData] = useState([]);
  const [descriptionFilledCount, setDescriptionFilledCount] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getIssueTypeCount();
    getWorkspaceActiveCount();
    getTicketUserData();
    getDescriptionFilledCount();
  }, []);

  const getIssueTypeCount = () => {
    setLoading(true);
    DashbordAPI.getIssueTypeCount().then(
      (data) => {
        setIssueTypeCount(data?.issueTypes || []);
        setLoading(false);
      },
      () => setLoading(false)
    );
  };

  const getWorkspaceActiveCount = () => {
    setLoading(true);
    DashbordAPI.getWorkspaceActiveCount().then(
      (data) => {
        setWorkspaceActiveCount(data?.activeWorkspaces || []);
        setLoading(false);
      },
      () => setLoading(false)
    );
  };

  const getTicketUserData = () => {
    setLoading(true);
    DashbordAPI.getTicketUserData().then(
      (data) => {
        setTicketUserData(data?.users || []);
        setLoading(false);
      },
      () => setLoading(false)
    );
  };

  const getDescriptionFilledCount = () => {
    setLoading(true);
    DashbordAPI.getDescriptionFilledCount().then(
      (data) => {
        setDescriptionFilledCount(data?.descriptions || []);
        setLoading(false);
      },
      () => setLoading(false)
    );
  };
  return (
    <div className={styles.wrapper}>
      {loading && <KoLoader/>}
      <div className={styles.content}>
        <div className={'tableCard'}>
          <KoTitle text="Issue Type Count" level={5} className={styles.panelHeaderTitle} />
          <KoTable data={{ rows: issueTypeCount || [], columns: dueCountCol }} />
        </div>
        <div className={'tableCard'}>
          <KoTitle text="Workspace Active Count" level={5} className={styles.panelHeaderTitle} />
          <KoTable data={{ rows: workspaceActiveCount || [], columns: dueCountCol }} />
        </div>
        <div className={'tableCard'}>
          <KoTitle text="Ticket User Data" level={5} className={styles.panelHeaderTitle} />
          <KoTable data={{ rows: ticketUserData || [], columns: dueCountCol }} />
        </div>
        <div className={'tableCard'}>
          <KoTitle text="Description Filled Count" level={5} className={styles.panelHeaderTitle} />
          <KoTable data={{ rows: descriptionFilledCount || [], columns: dueCountCol }} />
        </div>
      </div>
    </div>
  );
}
