import React from 'react';
import TaskCrud from 'pages/taskPlanner/task/crud';

import styles from './preview.module.scss';


const TaskById = () => {
  return (
    <div className={styles.wrapper}>
      <TaskCrud/>
    </div>
  );
};

export default TaskById;
