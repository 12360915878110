import React from 'react';
import { Outlet } from 'react-router-dom';
import styles from './files.module.scss';

export default function Files() {

  return (
    <div className={`${styles.wrapper}`}>
      <Outlet/>
    </div>
  );
}
