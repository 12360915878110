import React from "react";
import { KoMonaco } from "packages";
import styles from './curd.module.scss';
export const RequestBody = ({form,initialValues, onChange }) => {

  const handleShowValue =(val)=> {
    onChange(val);
  };

  const updateData=(val)=>{
    handleShowValue(val);
  };



  return (
    <div className={styles.JSONdata}>
      <KoMonaco
        language="json"
        JSONdata={initialValues?.body}
        editorOnChange={(val)=>updateData(val)}
      />
    </div>
  );
};

export default RequestBody;

