import { useEffect, useState } from "react";
import _find from 'lodash/find';
import { useNavigate, useParams } from 'react-router-dom';
import { KoBadge, KoDP, KoEditorReadOnly, KoIcon, KoInput, KoText, KoTitle } from "packages";
import { ISSUETYPE } from "shared/commonConstants";
import { ChatAPI } from "./chat.service";
import { determineSource, getTimeStamp } from "shared/utils";
import styles from './contacts.module.scss';
import { useAppStore } from "shared/newstore";

function KoContacts({ onChange, users, groups }) {
  let navigate = useNavigate();
  let { roomId } = useParams();
  const {
    setDirectMessagesContacts,
    setSelectedChatData,
    setSelectedChatType,
    directMessagesContacts,
    setChannels
  } = useAppStore();

  const [active, setActive] = useState("All");
  const [searchList, setSearchList] = useState();
  const [queryText, setQueryText] = useState();

 

  useEffect(() => {
    const getContactsWithMessages = async() => {
      ChatAPI.getContactsWithMessages().then(
        (data) => {
          if (data?.rooms) {
            setDirectMessagesContacts(data['rooms']);
            setChannels(data);
            let roomObj = data['rooms'][0];
            let source = 'users';
            if (roomId && roomId !== ':roomId') {
              if(determineSource(roomObj?.groupId) === 'groups') {
                source = 'groups';
                roomObj = _find(data['rooms'], function(o) { return o.groupId === roomId; }) || roomObj;
              }else if (determineSource(roomObj?.userId)) {
                source = 'users';
                roomObj = _find(data['rooms'], function(o) { return o.userId === roomId; }) || roomObj;
              }else{
                source = 'tickets';
                roomObj = _find(data['rooms'], function(o) { return o.ticketId === roomId; }) || roomObj;
              }
            }
            const updatedRoomObj = { ...roomObj, source: source};
            navigate(`/chat/${roomObj?.userId}`);
            setSelectedChatData(updatedRoomObj);
            setSelectedChatType(source);
          }
        },
        (err) => {
        }
      );
    };
    getContactsWithMessages();
    getContacts();
  }, []);

  const getIcon = (type) => {
    let issue = _find(ISSUETYPE, function(o) { return o.value === type; });
    return issue?.icon;
  };


  const changeSegments = (val) => {
    setActive(val);
    if (val === "All") {
    
    } else {
     
    }
  };

  const getContacts = async(q) => {
    ChatAPI.getRoomsUsers(q).then(
      (data) => {
        console.log(data);
        setSearchList(data);
        // dispatch(setChannels(data));
      },
      (err) => {
      }
    );
  };


  const searchText = (e) => {
    const val = e.target.value;
    let q = { search: val };
    if (val) {
      setQueryText(val);
      setSearchList();
      getContacts(q);
    } else {
      setQueryText('');
    }


  };


  const onChangeChatUser = (item, source) => {
    setQueryText('');
    if (source === 'users') {
      const updatedRoomObj = { ...item, source: 'users' };
      setSelectedChatData(updatedRoomObj);
      setSelectedChatType('users');
      navigate(`/chat/${item.userId}`);
    } else if (source === 'groups') {
      const updatedRoomObj = { ...item, source: 'groups' };
      setSelectedChatData(updatedRoomObj);
      navigate(`/chat/${item.groupId}`);
      setSelectedChatType('groups');
    } else if (source === 'tickets') {
      const updatedRoomObj = { ...item, source: 'tickets' };
      setSelectedChatData(updatedRoomObj);
      navigate(`/chat/${item.ticketId}`);
      setSelectedChatType('tickets');
    };
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <KoTitle text='Chats' level={4} />
      </div>
      <div className={styles.search}>
        <KoInput allowClear onChange={searchText}
          value={queryText}
          variant="filled" size="large"
          addonBefore={<KoIcon name='SearchOutlined' />} placeholder='Search user or group or ticket' />
      </div>
      <div className={styles.contacts}>
        <div className={styles.options}>
          <div onClick={() => changeSegments('All')}
            className={`${styles.segmented} ${active === 'All' ? styles.active : ''}`}>
            <KoText text='All' />
          </div>
          <div onClick={() => changeSegments('users')}
            className={`${styles.segmented} ${active === 'users' ? styles.active : ''}`}>
            <KoText text='People' />
          </div>
          <div onClick={() => changeSegments('groups')}
            className={`${styles.segmented} ${active === 'groups' ? styles.active : ''}`}>
            <KoText text='Groups' />
          </div>
          <div onClick={() => changeSegments('tickets')}
            className={`${styles.segmented} ${active === 'tickets' ? styles.active : ''}`}>
            <KoText text='Tickets' />
          </div>
        </div>
        <KoTitle className={styles.recent} text='Recent' level={4} />
        <ul>
          {queryText && searchList?.users?.map((item, i) =>
            <li className={item.userId?.toLowerCase().includes(queryText) ? styles.searchActive : ''} key={i}
              onClick={() => onChangeChatUser(item, 'users')}>
              <div className={styles.chatType}>
                <KoBadge size={'default'} offset={[-4, 25]} dot={true} color={!item?.isOnline ? `var(--palette-success-main)` : `var(--palette-error-main)`} >
                  {item?.profile?.logo ? <KoDP fallback='UserFilled' className={styles.icon} width={26} height={26} logo={item?.profile?.logo} />
                    : <KoIcon name='UserFilled' className={styles.icon} width={26} height={26} />}
                </KoBadge>
                <div className={styles.profileMessage}>
                  <div className={styles.profileName}>
                    <KoText className={styles.name} text={item.profile?.firstName + ' ' + item.profile?.lastName} />
                    {item?.lastMessage?.dateTime && <KoText className={styles.time} text={getTimeStamp(item?.lastMessage?.dateTime)} />}
                  </div>
                  <div className={styles.message}>
                    <KoEditorReadOnly text={item?.email} initialValue={item?.lastMessage?.content} from='contacts' />
                  </div>
                </div>
              </div>
            </li>
          )}
          {queryText && searchList?.groups?.map((item, i) =>
            <li className={item.groupId?.toLowerCase().includes(queryText) ? styles.searchActive : ''} key={item.groupId}
              onClick={() => onChangeChatUser(item, 'groups')}>
              <div className={styles.chatType}>
                {item?.logo?.length > 0 ? <KoDP fallback='GrpupsFilled' className={styles.icon} width={26} height={26} logo={item?.logo} /> :
                  <KoIcon name='GrpupsFilled' className={styles.icon} width={26} height={26} />}
                <div className={styles.profileMessage}>
                  <div className={styles.profileName}>
                    <KoText className={styles.name} text={item.name} />
                    {item?.lastMessage?.dateTime && <KoText className={styles.time} text={getTimeStamp(item?.lastMessage?.dateTime)} />}
                  </div>
                  <div className={styles.message}>
                    <KoEditorReadOnly text={item?.email} initialValue={item.lastMessage?.content} from='contacts' />
                  </div>
                </div>
              </div>
            </li>
          )}

          {queryText && searchList?.tickets?.map((item, i) =>
            <li className={item.ticketId?.includes(queryText.toUpperCase()) ? styles.searchActive : ''} key={i}
              onClick={() => onChangeChatUser(item, 'tickets')}>
              <div className={styles.chatType}>
                <div className={styles.icon} >
                  {getIcon(item?.issueType)}
                </div>
                <div className={styles.profileMessage}>
                  <div className={styles.profileName}>
                    <KoText className={styles.name} text={item.ticketId} />
                    {item?.lastMessage?.dateTime && <KoText className={styles.time} text={getTimeStamp(item?.lastMessage?.dateTime)} />}
                  </div>
                  <div className={styles.message}>
                    <KoEditorReadOnly text={item?.summary} initialValue={item?.content} from='contacts' />
                  </div>
                </div>
              </div>
            </li>
          )}
          {
            directMessagesContacts?.length > 0 && directMessagesContacts?.map((item, i) => (
              item?.source === 'users' ? (
                <li className={roomId === item?.userId ? styles.active : ''} key={i} onClick={() => onChangeChatUser(item, 'users')}>
                  <div className={styles.chatType}>
                    <KoBadge size={'default'} offset={[-4, 25]} dot={true} color={!item?.isOnline ? `var(--palette-success-main)` : `var(--palette-error-main)`} >
                      {item?.profile?.logo ? <KoDP fallback='UserFilled' className={styles.icon} width={26} height={26} logo={item?.profile?.logo} />
                        : <KoIcon name='UserFilled' className={styles.icon} width={26} height={26} />}
                    </KoBadge>
                    <div className={styles.profileMessage}>
                      <div className={styles.profileName}>
                        <div className={styles.nameWithMessage}>
                          <KoText className={styles.name} text={item.profile?.firstName + ' ' + item.profile?.lastName} />
                          <div className={styles.message}>
                            <KoEditorReadOnly text='-' initialValue={item?.content} from='contacts' />
                          </div>
                        </div>
                        {item?.dateTime && <div className={styles.messageTime}>
                          <KoText className={styles.time} text={getTimeStamp(item?.dateTime)} />
                          {item?.count ? <div className={styles.unreadCount}>{item?.count}</div> : <span>&nbsp;</span>}
                        </div>}
                      </div>

                    </div>
                  </div>
                </li>
              ) : item?.source === 'groups' ? (
                <li className={roomId === item.groupId ? styles.active : ''} key={i}
                  onClick={() => onChangeChatUser(item, 'groups')}>
                  <div className={styles.chatType}>
                    {item?.logo?.length > 0 ? <KoDP fallback='GrpupsFilled' className={styles.icon} width={26} height={26} logo={item?.logo} /> :
                      <div className={styles.iconTicket} >
                        <KoIcon name='GrpupsFilled' className={styles.icon} width={26} height={26} />
                      </div>
                    }
                    <div className={styles.profileMessage}>
                      <div className={styles.profileName}>
                        <div className={styles.nameWithMessage}>
                          <KoText className={styles.name} text={item.name} />
                          <div className={styles.message}>
                            <KoEditorReadOnly text='-' initialValue={item?.content} from='contacts' />
                          </div>
                        </div>
                        {item?.dateTime && <div className={styles.messageTime}>
                          <KoText className={styles.time} text={getTimeStamp(item?.dateTime)} />
                          {item?.count ? <div className={styles.unreadCount}>{item?.count}</div> : <span>&nbsp;</span>}
                        </div>}
                      </div>
                    </div>
                  </div>
                </li>
              ) : item?.source === 'tickets' && (
                <li className={roomId === item.ticketId ? styles.active : ''} key={i}
                  onClick={() => onChangeChatUser(item, 'tickets')}
                >
                  <div className={styles.chatType}>
                    <div className={styles.iconTicket} >
                      {getIcon(item?.issueType)}
                    </div>
                    <div className={styles.profileMessage}>
                      <div className={styles.profileName}>
                        <div className={styles.nameWithMessage}>
                          <KoText className={styles.name} text={item.ticketId} />
                          <div className={styles.message}>
                            <KoEditorReadOnly text={item?.summary} initialValue={item?.content} from='contacts' />
                          </div>
                        </div>
                        {item?.dateTime && <div className={styles.messageTime}>
                          <KoText className={styles.time} text={getTimeStamp(item?.dateTime)} />
                          {item?.count ? <div className={styles.unreadCount}>{item?.count}</div> : <span>&nbsp;</span>}
                        </div>}
                      </div>
                    </div>
                  </div>
                </li>
              )
            ))
          }
        </ul>
      </div>
    </div>
  );
}

export default KoContacts;