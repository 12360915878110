import React from "react";
import { Collapse } from 'antd';
import styles from './accordion.module.scss';


function KoAccordion(props) {
  const { data } = props;
  return (
    <div className={styles.wrapper}>
      <Collapse
        {...props}
        items={data} />
    </div>

  );
}
export default KoAccordion;
