const TASKSTATUSLIST = [{
  label: 'Todo',
  name: 'Todo',
  value: 1,
  background: 'rgba(145, 158, 171, 0.16)',
  color: '#637381'
},
{
  label: 'In Progress',
  value: 2,
  name: 'In Progress',
  background: 'rgba(255, 239, 179, 0.16)',
  color: '#f57f17'
},
{
  label: 'Blocked',
  value: 3,
  name: 'Blocked',
  background: 'rgba(255, 171, 145, 0.16)',
  color: '#c62828'
},
{
  label: 'Ready For QA',
  value: 4,
  name: 'Ready For QA',
  background: 'rgba(144, 202, 249, 0.16)',
  color: '#0d47a1'
},
{
  label: 'In Review',
  value: 5,
  name: 'In Review',
  background: 'rgba(209, 164, 224, 0.16)',
  color: '#6a1b9a'
},
{
  label: 'On Hold',
  value: 6,
  name: 'On Hold',
  background: 'rgba(255, 204, 128, 0.16)',
  color: '#ff8f00'
},
{
  label: 'QA Failed',
  value: 7,
  name: 'QA Failed',
  background: 'rgba(255, 171, 145, 0.16)',
  color: '#c62828'
},
{
  label: 'Reopen',
  name: 'Reopen',
  value: 12,
  background: 'rgba(145, 158, 171, 0.16)',
  color: '#637381'
},
{
  label: 'Abandoned',
  value: 8,
  name: 'Abandoned',
  background: 'rgba(244, 143, 177, 0.16)',
  color: '#d81b60'
},
{
  label: 'Deferred',
  value: 9,
  name: 'Deferred',
  background: 'rgba(176, 190, 195, 0.16)',
  color: '#37474f'
},
{
  label: 'Done',
  value: 10,
  name: 'Done',
  background: 'rgba(165, 214, 167, 0.16)',
  color: '#388e3c'
},
{
  label: 'Closed',
  value: 11,
  name: 'Closed',
  background: 'rgba(200, 230, 201, 0.16)',
  color: '#4caf50'
}];


const WORKFLOWSTATUSLIST = [{
  label: 'Not Submitted',
  name: 'Not Submitted',
  value: 0,
  background: 'rgba(145, 158, 171, 0.16)',
  color: '#637381'
},
{
  label: 'Waiting for approval',
  value: 1,
  name: 'Waiting for approval',
  background: 'rgba(255, 239, 179, 0.16)',
  color: '#f57f17'
},
{
  label: 'Rejected',
  value: 3,
  name: 'Rejected',
  background: 'rgba(255, 171, 145, 0.16)',
  color: '#c62828'
},
{
  label: 'Ready For QA',
  value: 4,
  name: 'Ready For QA',
  background: 'rgba(144, 202, 249, 0.16)',
  color: '#0d47a1'
},
{
  label: 'In Review',
  value: 5,
  name: 'In Review',
  background: 'rgba(209, 164, 224, 0.16)',
  color: '#6a1b9a'
},
{
  label: 'On Hold',
  value: 6,
  name: 'On Hold',
  background: 'rgba(255, 204, 128, 0.16)',
  color: '#ff8f00'
},
{
  label: 'QA Failed',
  value: 7,
  name: 'QA Failed',
  background: 'rgba(255, 171, 145, 0.16)',
  color: '#c62828'
},
{
  label: 'Reopen',
  name: 'Reopen',
  value: 12,
  background: 'rgba(145, 158, 171, 0.16)',
  color: '#637381'
},
{
  label: 'Abandoned',
  value: 8,
  name: 'Abandoned',
  background: 'rgba(244, 143, 177, 0.16)',
  color: '#d81b60'
},
{
  label: 'Deferred',
  value: 9,
  name: 'Deferred',
  background: 'rgba(176, 190, 195, 0.16)',
  color: '#37474f'
},
{
  label: 'Approved',
  value: 2,
  name: 'Approved',
  background: 'rgba(165, 214, 167, 0.16)',
  color: '#388e3c'
},
{
  label: 'Closed',
  value: 11,
  name: 'Closed',
  background: 'rgba(200, 230, 201, 0.16)',
  color: '#4caf50'
}];

const TASKSTATUSLISTCOB = [{
  label: 'Unresolved',
  name: 'Todo',
  value: 1,
  background: 'rgba(145, 158, 171, 0.16)',
  color: '#637381'
},
{
  label: 'In Progress',
  value: 2,
  name: 'In Progress',
  background: 'rgba(255, 239, 179, 0.16)',
  color: '#f57f17'
},
{
  label: 'Reopen',
  name: 'Reopen',
  value: 12,
  background: 'rgba(145, 158, 171, 0.16)',
  color: '#637381'
},
{
  label: 'Abandoned',
  value: 8,
  name: 'Abandoned',
  background: 'rgba(244, 143, 177, 0.16)',
  color: '#d81b60'
},
{
  label: 'Deferred',
  value: 9,
  name: 'Deferred',
  background: 'rgba(176, 190, 195, 0.16)',
  color: '#37474f'
},
{
  label: 'Resolved',
  value: 10,
  name: 'Done',
  background: 'rgba(165, 214, 167, 0.16)',
  color: '#388e3c'
},
{
  label: 'Resolved',
  value: 11,
  name: 'Closed',
  background: 'rgba(200, 230, 201, 0.16)',
  color: '#4caf50'
}];

export { TASKSTATUSLIST, TASKSTATUSLISTCOB, WORKFLOWSTATUSLIST };