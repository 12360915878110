import React from "react";
function Avatar2(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <g id="avatar_4" transform="translate(0.5 0.5)">
        <path id="Path_15844" fill="#f2fdff" stroke="#c0f4fc" d="M65.813 81H15.188A15.187 15.187 0 0 1 0 65.813V15.188A15.187 15.187 0 0 1 15.188 0h50.625A15.187 15.187 0 0 1 81 15.188v50.625A15.187 15.187 0 0 1 65.813 81z" />
        <g id="telescope" transform="translate(13.725 18)">
          <g id="Group_12188" transform="translate(4.276 0)">
            <path id="Path_30303" d="M13.006 174.591a1.031 1.031 0 0 0 .175-.864l-1.155-4.311a1.243 1.243 0 0 0-1.582-.731l-5.174 1.386a1.339 1.339 0 0 0-.947 1.64l1.04 3.88a1.339 1.339 0 0 0 1.64.947l5.174-1.386a1.486 1.486 0 0 0 .829-.561z" fill="#ff5e82" transform="translate(-4.276 -153.576)" />
            <g id="Group_12186" transform="translate(0.566 17.995)">
              <g id="Group_12185">
                <path id="Path_30304" d="M11.133 205.6a1.339 1.339 0 0 0 1.64.947l5.174-1.386a1.485 1.485 0 0 0 .83-.561 1.031 1.031 0 0 0 .175-.864l-.578-2.156-7.761 2.079z" fill="#b7457d" transform="translate(-10.613 -201.582)" />
              </g>
            </g>
            <path id="Path_30305" d="M59.716 131.432a3.128 3.128 0 0 1 2.209-3.827l3.449-.924a1.339 1.339 0 0 1 1.64.947l2.31 8.623a1.339 1.339 0 0 1-.947 1.64l-3.449.924a3.128 3.128 0 0 1-3.827-2.209z" fill="#5e54ac" transform="translate(-54.67 -115.331)" />
            <g id="Group_12187" transform="translate(5.739 16.609)">
              <path id="Path_30306" d="M69.263 190.719a3.128 3.128 0 0 0 3.827 2.209l3.449-.928a1.339 1.339 0 0 0 .947-1.64l-1.155-4.311-7.761 2.079z" fill="#453d81" transform="translate(-68.57 -186.053)" />
            </g>
            <path id="Path_30307" d="M111.353 49.011a2.678 2.678 0 0 1 1.894-3.28l21.126-5.661a1.339 1.339 0 0 1 1.64.947l3.235 12.072a1.339 1.339 0 0 1-.947 1.64l-21.126 5.661a2.678 2.678 0 0 1-3.28-1.894z" fill="#ff5e82" transform="translate(-101.711 -36.451)" />
            <path id="Path_30308" d="M153.15 124.8l-1.617-6.036-25.006 6.7 1.273 4.736a2.678 2.678 0 0 0 3.28 1.894l21.126-5.661a1.339 1.339 0 0 0 .944-1.633z" fill="#b7457d" transform="translate(-115.614 -108.158)" />
            <path id="Path_30309" d="M353.907 3.534a1.339 1.339 0 0 1 .947-1.64l6.9-1.848a1.339 1.339 0 0 1 1.64.947l4.159 15.521a1.339 1.339 0 0 1-.947 1.64l-6.9 1.846a1.339 1.339 0 0 1-1.64-.947z" fill="#5e54ac" transform="translate(-322.655 0)" />
            <path id="Path_30310" d="M389.1 106.832a1.339 1.339 0 0 0 .134-1.016l-2.079-7.76-9.485 2.542 2.079 7.76a1.339 1.339 0 0 0 1.64.947l6.9-1.848a1.339 1.339 0 0 0 .811-.625z" fill="#453d81" transform="translate(-344.335 -89.302)" />
          </g>
          <g id="Group_12189" transform="translate(14.696 18.033)">
            <path id="Path_30311" d="M144.967 279.71l-9.553-19.9a1.339 1.339 0 0 0-1.207-.76h-2.318a1.339 1.339 0 0 0-1.207.76l-9.553 19.9a1.339 1.339 0 1 0 2.414 1.159l8.166-17.013v16.434a1.339 1.339 0 0 0 2.678 0v-16.434l8.166 17.013a1.339 1.339 0 1 0 2.414-1.159z" fill="#5e54ac" transform="translate(-120.996 -253.955)" />
            <path id="Path_30312" d="M258.366 259.808a1.339 1.339 0 0 0-1.207-.76H256v22.581a1.339 1.339 0 0 0 1.339-1.339v-16.434l8.166 17.013a1.339 1.339 0 1 0 2.414-1.159z" fill="#453d81" transform="translate(-243.948 -253.955)" />
            <path id="Path_30313" fill="#f2faff" d="M215.017 210.034a4.017 4.017 0 1 1 4.017-4.017 4.022 4.022 0 0 1-4.017 4.017z" transform="translate(-202.965 -202)" />
          </g>
          <g id="Group_12190" transform="translate(26.747 18.033)">
            <path id="Path_30314" fill="#c8eafa" d="M256 202v8.034a4.017 4.017 0 1 0 0-8.034z" transform="translate(-256 -202)" />
          </g>
        </g>
      </g>
    </svg>

  );
}


export default Avatar2;