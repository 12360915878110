import { Space } from 'antd/es';
import {
  Checkbox,
  KoButton,
  KoDate,
  KoDivider,
  KoForm,
  KoFormBuilder,
  KoIcon,
  KoInput,
  KoSelect, KoTextArea,
  KoUserSelect
} from 'packages';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from "react-redux";
import styles from './crud.module.scss';
import ItemEntryCrud from './itemEntryCrud';


const optionGroups = [
  { label: 'Zoom', value: "zoom" },
  { label: 'Microsoft Teams', value: "teams" },
  { label: 'Google Meet', value: "gmeet" },
  { label: 'Slack', value: "slack" },
  { label: 'Skype', value: "skype" },
  { label: 'GoToMeeting', value: "goToMeeting" },
  { label: 'Cisco Webex', value: "ciscoWebex" }
];

const DurationValues = [
  {
    label: '15 Mins',
    value: '15m'
  },
  {
    label: '30 Mins',
    value: '30m'
  },
  {
    label: '45 Mins',
    value: '45m'
  }
];

const RecurrenceValues = [
  {
    label: 'Every Day',
    value: '1'
  },
  {
    label: 'Every Week',
    value: '2'
  },
  {
    label: 'Week Ends',
    value: '3'
  },
  {
    label: 'Every month',
    value: '4'
  }
];
const OneValues = [
  {
    label: 'One on one',
    value: '1'
  },
  {
    label: 'Group',
    value: '2'
  }
];

function EventCrud({ form, handleFinish, initialValues }) {
  const users = useSelector(state => state?.users);
  const groups = useSelector(state => state?.groups);
  const orgWatch = KoForm.useWatch('isOrganization', form);
  const eventTypeWatch = KoForm.useWatch('eventType', form);

  useEffect(() => {
    if (!initialValues || Object.keys(initialValues).length === 0) {
      form.resetFields();
    } else {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  const handleSubmit = useCallback(values => {
    handleFinish(values);
  });


  const UrlValidation = (value) => {
    
    const urlPatterns = {
      zoom: `https://(?:www\\.)?zoom\\.us/(?:j/|my/)?[\\w\\d]{9,11}`,
      gmeet: `https://meet\\.google\\.com/[a-z]{3}-[a-z]{4}-[a-z]{3}`,
      teams: `https://teams\\.microsoft\\.com/l/meetup-join/\\w+/\\w+`,
      slack: `https://(?:[\\w\\d-]+\\.)?slack\\.com/calendar/event\\?.*`,
      skype: `https://join\\.skype\\.com/(?:call|meeting)/[\\w\\d-]+`,
      ciscoWebex: `https://(?:[\\w\\d-]+\\.)?webex\\.com/(?:[\\w\\d\\/-]+/)?(?:meet|join)/[\\w\\d-]+`,
      goToMeeting: `https://(?:www\\.)?gotomeeting\\.com/(?:join/|meeting/join/)?[\\w\\d-]+`
    };
    
    const eventVia = form?.getFieldValue('meetingVia');
    const regex = new RegExp(urlPatterns[eventVia] || '', 'i');
    
    if (eventVia && !regex.test(value)) {
      return Promise.reject(new Error(`Enter a valid ${eventVia} Meeting URL!`));
    }
    return Promise.resolve();
  };


   
  const Groups = ({ value, onChange }) =>

    <KoSelect mode="multiple" value={value}
      onChange={v => onChange(v)}
      showSearch
      allowClear
      placeholder='Choose groups'
      options={groups['data']?.map((group) => ({
        label: group.name,
        value: group?.groupId
      }))}
    />;


  const Assignees = ({ value, onChange }) =>
    <KoUserSelect value={value}  onChange={v => onChange(v)} users={users['data']} placeholder='Select assignees' mode="multiple"/>;
 
  const Org = ({ value, onChange }) =>{
    const options = [
      {
        label: "Organization",
        value: true
      },
      {
        label: "OutSide Organization",
        value: false
      }
    ];

    
    return(
      <KoSelect value={value}
        showSearch
        allowClear
        onChange={v => { onChange(v); }}
        placeholder='Select'
        options={options?.map((option) => ({
          label: option?.label,
          value: option?.value
        }))}
      />
    );
  };

  const ItemTable = () =>
    <ItemEntryCrud form={form} handleFinish={handleFinish} initialValues={initialValues}/>;


  const EventVia = ({ value, onChange }) => {
    const [items, setItems] = useState(optionGroups);
    const [name, setName] = useState('');
    const inputRef = useRef(null);

    const AddCustomMeeting = useCallback((e) => {
      e.preventDefault();
      if (name.trim() === '') return;

      setItems((prevItems) => [...prevItems, { label: name, value: name }]);
      setName('');
      inputRef.current?.focus();
    }, [name]);

    return (
      <KoSelect
        allowClear
        showSearch
        value={value}
        onChange={(v) => onChange(v)}
        className={styles.dropdown}
        placeholder="Choose EventVia"
        options={items}
        optionRender={(option) => (
          <div className={styles.iconWithLabel} role="img" aria-label={option.data.label}>
            {option.data.value === 'zoom' && <KoIcon name="ZoomFilled" />}
            {option.data.value === 'teams' && <KoIcon name="MicrosoftTeamsFilled" />}
            {option.data.value === 'gmeet' && <KoIcon name="GoogleMeetFilled" />}
            {option.data.value === 'slack' && <KoIcon name="SlackFilled" />}
            {option.data.value === 'skype' && <KoIcon name="SkypeFilled" />}
            {option.data.value === 'goToMeeting' && <KoIcon name="GoToMeetingFilled" />}
            {option.data.value === 'ciscoWebex' && <KoIcon name="CiscoWebexFilled" />}
            {option.data.label}
          </div>
        )}
        dropdownRender={(menu) => (
          <>
            {menu}
            <KoDivider style={{ margin: '8px 0' }} />
            <Space>
              <KoInput
                placeholder="Custom Meeting"
                onChange={(e) => setName(e.target.value)}
                value={name}
                ref={inputRef}
              />
              <KoButton
                key="create"
                type="primary"
                label="Add"
                icon={<KoIcon color="#FFF" name="PlusOutlined" />}
                onClick={AddCustomMeeting}
              />
            </Space>
          </>
        )}
      />
    );
  };



  const Date = ({ value, onChange }) =>
    <KoDate showTime={true} value={value} format={'DD-MMM-YYYY HH:mm'}
      onChange={v => onChange(v)} />;




  const options = [
    {
      label: 'Modify Events',
      value: 'modify_events'
    },
    {
      label: 'Invite Others',
      value: 'invite_others'
    },
    {
      label: 'Attendees List',
      value: 'attendees_list'
    }
  ];

  const Duration = ({ value, onChange }) => {
    const [durationItems, setDurationItems] = useState(DurationValues);
    const [durationName, setDurationName] = useState('');
    const [unit, setUnit] = useState('Hr');
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const inputRef = useRef(null);

    const AddCustomDuration = () => {
      if (durationName && unit) {
        const newItem = { value: `${durationName}${unit}`, label: `${durationName} ${unit}` };
        setDurationItems([...durationItems, newItem]);
        setDurationName('');
        setUnit('');
        inputRef.current?.focus();
      }
    };

    const handleDropdownOpen = (open) => {
      setDropdownOpen(open);
    };

    return (
      <KoSelect
        value={value}
        onChange={v => onChange(v)}
        showSearch
        allowClear
        placeholder='Choose duration'
        options={durationItems}
        onDropdownVisibleChange={(open) => handleDropdownOpen(open)}
        open={dropdownOpen}
        dropdownRender={(menu) => (
          <>
            {menu}
            <KoDivider
              style={{
                margin: '8px 0'
              }}
            />
            <Space>
              <KoInput
                placeholder="Custom duration"
                onChange={(e) => setDurationName(e.target.value)}
                value={durationName}
                ref={inputRef}
              />
              <KoSelect
                placeholder="Select Hr/min"
                onChange={(v) => setUnit(v)}
                value={unit}
                options={[
                  { value: 'Hr', label: 'Hr' },
                  { value: 'Mins', label: 'Min' }
                ]}
              />
              <KoButton
                key='add'
                type='primary'
                label="Add"
                icon={<KoIcon color="#FFF" name='PlusOutlined' />}
                onClick={AddCustomDuration}
              />
            </Space>
          </>
        )}
      />
    );
  };

  const Recurrence = ({ value, onChange }) =>

    <KoSelect value={value}
      onChange={v => onChange(v)}
      showSearch
      allowClear
      placeholder='Choose recurrence'
      options={RecurrenceValues?.map((value) => ({
        label: value.label,
        value: value?.value
      }))}
    />;


  const EventType = ({ value, onChange }) =>
    <KoSelect value={value}
      onChange={v => onChange(v)}
      showSearch
      allowClear
      placeholder='Choose Event Type'
      options={OneValues?.map((option) => ({
        label: option.label,
        value: option?.value
      }))}
    />;

  const Email = ({ value, onChange }) => (
    <KoSelect
      value={value}
      showSearch
      onChange={v => onChange(v)}
      mode="tags"
      tokenSeparators={[',']}
      placeholder='Enter Email'
    />
  );

  const meta = {
    columns: 6,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      {
        key: 'name', placeholder: 'Enter Event Name', label: 'Event Name', colSpan: 2, rules: [
          {
            required: true,
            message: `Enter Event Name.`
          }
        ]
      },
      {
        key: 'isOrganization', label: 'Org', colSpan: 2, widget: Org, forwardRef: true, rules: [
          {
            required: true,
            message: `Please Select Organization.`
          }
        ]
      },
      {
        key: 'email', label: 'Custom Email', colSpan: 2, widget: Email, forwardRef: true
      },
      {
        key: 'startTime', placeholder: 'Enter Start Date', label: 'Start Date', colSpan: 2, widget: Date, rules: [
          {
            required: true,
            message: `Enter Start Date.`
          }
        ]
      },
      {
        key: 'endTime', placeholder: 'Enter End Date', label: 'End Date', colSpan: 2, widget: Date, rules: [
          {
            required: true,
            message: `Enter End Date.`
          }
        ]
      },

      {
        key: 'eventVia', label: 'Event Via', colSpan: 2, widget: EventVia, forwardRef: true, rules: [
          {
            required: true,
            message: `Please Select Event Platform`
          }
        ]
      },
      // {
      //   key: 'mail', placeholder: 'Type their email', label: 'Not a team member?', colSpan: 2
      // },
      {
        key: 'duration',  label: 'Duration', colSpan: 2, widget: Duration,
        rules: [
          {
            required: true,
            message: `Please Select Duration`
          }
        ]
      },
      {
        key: 'reoccurrence', label: 'Recurrence', colSpan: 2, widget: Recurrence
      },
      {
        key: 'eventType', label: 'Event Type', colSpan: 2, widget: EventType
      },
      {
        key: 'url', placeholder: 'Enter Meeting link', label: 'Meeting Link', colSpan: 4, rules: [
          {
            required: true,
            message: `Enter Meeting Url.`
          },
          { validator: (rule, value) => UrlValidation(value) }
        ]
      },
     
      { key: 'description', placeholder: 'Enter Instruction / Description/ Notes', label: 'Instruction / Description/ Notes', colSpan: 6, widget: KoTextArea },
      {
        key: 'permissions',
        colSpan: 4,
        label: 'Attendees Permission',
        widget: Checkbox.Group,
        options: options
      },

      {
        key: '', label: 'Agenda', colSpan: 6, widget: ItemTable, forwardRef: true
      }
    ]
  };

  if (orgWatch === true) {
    meta['fields'].splice(2, 1,(
      {
        key: 'assignees', label: 'Assignees', colSpan: 2, widget: Assignees, forwardRef: true
      }
    ));
    meta['fields'].splice(3, 0,(
      {
        key: 'groups', label: 'Groups', colSpan: 2, widget: Groups, forwardRef: true
      }
    ));
  }
  if (eventTypeWatch === '2') {
    meta['fields'].splice(orgWatch ? 11 : 10, 0,(
      {
        key: 'membersCount', label: 'How many members', colSpan: 2,type: 'number', forwardRef: true
      }
    ));
  }

  return (
    <div className={styles.modalWrapper}>
      <div className={styles.InvoiceCrudwrapper}>
        <KoForm form={form} layout="vertical" onFinish={handleSubmit} onSubmit={handleSubmit}>
          <KoFormBuilder form={form} meta={meta} />
        </KoForm>
      </div>
    </div>
  );
};

export default EventCrud;