import { Dropdown, Layout } from 'antd';
import _find from 'lodash/find';
import { KoBadge, KoDP, KoIcon, KoInput, KoText, KoLoader } from 'packages';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { deleteCookie, getCookie, setCookie } from 'shared/utils/cookies';
import {
  getDepartments,
  getDesignations,
  getGroups,
  getSettings, getUserById,
  getUsers,
  getWorkspace
} from "shared/store/slice";
import styles from './layouts.module.scss';
import { getMenuForRoles } from './getmenu';
import { accountAPI } from 'pages/account/account.service';
import { updateUser, login } from 'shared/store/authSlice';

const { Header } = Layout;

function GlobalHeader(props) {
  const userById = useSelector(state => state?.userById);
  const workspace = useSelector(state => state?.workspace);
  const [isLoading, setLoading] = useState(false);
  let userDetails = userById['data'];
  let workspaceList = workspace['data'];
  const { workspaceId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;

  const currentPath = workspaceId ? pathname?.split('/')[2] : pathname?.split('/')[1];

  const [activeLink, setActiveLink] = useState(currentPath);
  const [, setWos] = useState();


  useEffect(() => {
    if (workspaceId) {
      setCookie('wos', workspaceId);
    }
  }, [userDetails]);

  // useEffect(() => {
  //   if (Notification.permission !== 'granted') {
  //     Notification.requestPermission();
  //   }
  // }, []);



  useEffect(() => {
    if (getCookie('userId')) {
      dispatch(getUsers());
      dispatch(getGroups());
      dispatch(getDesignations());
      dispatch(getDepartments());
      dispatch(getWorkspace());
      dispatch(getSettings(getCookie('accountId')));
      dispatch(getUserById(getCookie('userId')));
    }
  }, []);

  useEffect(() => {
    setActiveLink(currentPath);
  }, [currentPath]);

  useEffect(() => {
    const currentWorkspace = _find(workspaceList, function(o) { return o.workspaceId === workspaceId; });
    setWos(currentWorkspace);
  }, [workspace, workspaceId]);




  const SearchInput = () => {
    return (
      <div
        key="SearchOutlined"
        aria-hidden
        className={styles.search}
        onMouseDown={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <KoInput
          className={styles.searchBox}
          prefix={
            <KoIcon name='SearchOutlined'
              width={16}
              height={16}
            />}
          placeholder="search task or actions (Ex: Apply Leave, Attendance Approvals)"
          variant={'borderless'} />
        <KoIcon name='PlusFilled'
          width={29}
          height={29}
        />
      </div>
    );
  };


  const logout = () => {
    deleteCookie("accessToken", "", 0);
    deleteCookie("userId", "", 0);
    deleteCookie("storeId", "", 0);
    deleteCookie("accountId", "", 0);
    deleteCookie("name", "", 0);
    navigate('/');
    window.location.reload();
  };



  const switchAccount=(account)=>{
    setLoading(true);
    accountAPI.switchAccount(account).then(
      (data) => {
        setLoading(false);
        if(data?.status === 2){
          navigate(`/account/requested`);
          return;
        }
        if(!data?.isVerified){
          navigate('/account/verify');
        } else if(data?.isChangePassword){
          navigate('/account/resetpassword');
        }else{
          const urlParams = new URLSearchParams(window.location.search);
          const redirectUrl = urlParams.get('redirect');
          if (redirectUrl) {
            window.location.href = redirectUrl;
          } else {
            dispatch(updateUser({...data}));
            dispatch(
              login({
                userId: data.userId,
                user: data.user,
                accessToken: data.accessToken,
                accountId: data.accountId
              })
            );
            navigate('/home/dashboard/myoverview');
            window.location.reload();
          }
        }
      },
      (err) => {
        setLoading(false);
      }
    );

  };


  return (
    <>
      {isLoading && <KoLoader/>}
      <Header className={styles.shallowHeader}>
      </Header>
      <Header className={`${styles.header}`}>
        <div className={styles.topNavHeader}>
          <div className={styles.topNavHeaderMain}>
            <div className={styles.topNavHeaderMainLeft} onClick={() => navigate('/home/dashboard/myoverview')}>
              <div className={styles.logo}>
                <KoIcon name='AppLogoFilled' width={115} height={25} />
              </div>
            </div>
            <div className={`${styles.topNavHeaderMenu} topNav`}>
              <ul>
                {getMenuForRoles(userDetails).map((item) => {
                  if (item.active && item.condition === 'workspace') {
                    return (
                      workspaceId && <li key={item.key} className={activeLink === item.key ? styles.active : ''}>
                        <Link to={`${workspaceId ? workspaceId : getCookie('wos')}${item.link}`}>
                          <KoIcon name={item.icon} />
                          <KoText ellipsis={true} className={styles.text} text={item.text} />
                        </Link>
                      </li>
                    );
                  } else if (item.active) {
                    return (
                      <li key={item.key} className={activeLink === item.key ? styles.active : ''}>
                        <Link to={item.link}>
                          {/* { item.key === 'chat' &&
                            <span className={styles.chatCount}>
                              <span className={styles.count}>2</span>
                            </span>
                          } */}
                          <KoIcon name={item.icon} />
                          <KoText ellipsis={true} className={styles.text} text={item.text} />
                        </Link>
                      </li>
                    );
                  }
                  return null;
                })}

              </ul>

            </div>

            <div className={styles.headerRightContent}>
              <SearchInput />
            </div>
            {/* <div className={styles.headerBtnRightContent}>
              <Badge size={'small'} dot={true} offset={[-1, 1]}>
                <Button shape="circle" icon={<KoIcon name='NotificationOutlined'
                  width={17} height={17} />} />
              </Badge>
            </div> */}
            <div className={styles.headerActionsAvatar}>
              <Dropdown
                overlayClassName={styles.settings}
                dropdownRender={(menu) => (
                  <div className={styles.customDropdown}>
                    <div className={styles.customDropdownContnet}>
                      <ul>
                        <li>
                          <KoIcon name={'ActiveUseresOutlined'} />
                          <Link to={`/profile/${userDetails?.userId}`}> <KoText text={'My profile'} /></Link>
                        </li>
                        <li>
                          <KoIcon name={'SettingsOutlined'} />
                          <KoText text={'Account managemnet'} />
                        </li>
                        <li>
                          <KoIcon name={'ChangePasswordOutlined'}  width={18} height={18}/>
                          <Link to={`/account/changepassword/${userDetails?.email}`}> <KoText text={'Change password'} /></Link>
                        </li>
                        <li onClick={logout}>
                          <KoIcon name={'LogoutOutlined'}  width={18} height={18}/>
                          <KoText text={'Sign out'}/>
                        </li>
                      </ul>
                      {userDetails?.userAccount&& <KoText className={styles.switchAccountText} text='SWITCH ACCOUNT' />}
                      <ul className={styles.switchAccount}>
                        {
                          userDetails?.userAccount?.map((account)=>
                            <li onClick={()=>switchAccount(account)} key={account.accountId} className={styles.userWrapper}>
                              <div className={`${styles.headCircle}  ${styles[account ?
                                account?.companyName?.charAt(0).toLowerCase() : 'a']}`}>

                                {account?.['logo'] ?

                                  <KoDP shape={'default'} logo={account?.logo} width={account?.logo?.[0]?.name ? 25 : 25} height={account?.companyName?.logo?.[0]?.name ? 25 : 25} />
                                  :
                                  <KoText className={`${styles.letter}`} text={account?.companyName?.charAt(0)} />
                                }
                              </div>
                              <div className={styles.accounts}>
                                <KoText ellipsis={true} className={styles.domain} text={`${account?.companyName}`} />
                                <KoText ellipsis={true} className={styles.subdomain} text={`https://${account?.domain}.kwapio.com`} />
                              </div>
                              {getCookie('accountId') === account?.accountId && <div className={styles.circleActive}></div>}
                            </li>
                          )
                        }
                      </ul>
                    </div>
                  </div>
                )}
                placement={'bottomRight'}>
                <span>
                  <div className={styles.userWrapper} >
                    <KoBadge dot={true} size={'small'} offset={[-4, 23]} color={'status' === 'online' ? 'var(--palette-success-main)' : 'var(--palette-error-main)'}>
                      <div className={`${styles.headCircle}  ${styles[userDetails ?
                        userDetails?.profile?.firstName?.charAt(0).toLowerCase() : 'a']}`}>

                        {userDetails?.profile?.['logo'] ?

                          <KoDP shape={'default'} logo={userDetails?.profile?.logo} width={userDetails?.profile?.logo?.[0]?.name ? 25 : 25} height={userDetails?.profile?.logo?.[0]?.name ? 25 : 25} />
                          :
                          <KoText className={`${styles.letter}`} text={userDetails?.profile?.firstName?.charAt(0)} />
                        }
                      </div>
                    </KoBadge>
                    <KoText ellipsis={true} className={styles.name} text={`${userDetails?.profile?.firstName} ${userDetails?.profile?.lastName}`} />
                  </div>
                </span>
              </Dropdown>

            </div>
          </div>
        </div>
      </Header>
    </>
  );
}

export default GlobalHeader;