

import { KoAccordion, KoCard, KoForm, KoIcon, KoTitle, KoToast } from 'packages';
import AadhaarCrud from './aadhaarCrud';
import styles from './documents.module.scss';
import DrivingLicenseCrud from './drivingLicense';
import EducationCrud from './education';
import PanCardCrud from './panCardCrud';
import PassportCrud from './passport';
import PreviousExperienceCrud from './previousExperience';
import VoterCrud from './voterCrud';

export const beforeUpload = (file) => {
  const isPdf = file.type === 'application/pdf';
  if (!isPdf) {
    KoToast.error('You can only upload PDF files!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    KoToast.error('File must be smaller than 2MB!');
  }
  return isPdf && isLt2M;
};


export default function ProfileDocuments({userData, userDetails, handleFinish }) {

  const [form] = KoForm.useForm();
  const panelStyle = {
    marginBottom: 2,
    background: '#fff',
    border: 'none',
    borderRadius: 1
  };

  const HEADER_OPTIONS = [{
    label: <div className={styles.header}>
      <div className={styles.circle}>
        <KoIcon name="BusinessFilled" height={12} width={12} />
      </div>
      <div className={styles.title}>
        <div className={styles.titleWithIcon}>
          <KoTitle className={styles.title} level={5} text="Aadhaar Card" />
          {/* <KoIcon name="TickFilled" height={12} width={12} /> */}
        </div>
        {/* <KoDescription className={styles.description} text="Status : Not uploaded" /> */}
      </div>
    </div>,
    children: <AadhaarCrud form={form} handleFinish={handleFinish} initialValues={userData}/>,
    key: 1,
    style: panelStyle
  },
  {
    label: <div className={styles.header}>
      <div className={styles.circle}>
        <KoIcon name="BusinessProfileFilled" height={15} width={15} />
      </div>
      <div className={styles.title}>
        <div className={styles.titleWithIcon}>
          <KoTitle className={styles.title} level={5} text="Pan Card" />
          {/* <KoIcon name="CrossFilled" height={12} width={12} /> */}
        </div>
        {/* <KoDescription className={styles.description} text="Status : Rejected" /> */}
      </div>
    </div>,
    children: <PanCardCrud form={form} handleFinish={handleFinish} initialValues={userData}/>,
    key: 2,
    style: panelStyle
  }, {
    label: <div className={styles.header}>
      <div className={styles.circle}>
        <KoIcon name="PreferencesFilled" height={12} width={12} />
      </div>
      <div className={styles.title}>
        <div className={styles.titleWithIcon}>
          <KoTitle className={styles.title} level={5} text="Voter Id Card" />
          {/* <KoIcon name="DotFilled" height={12} width={12} /> */}
        </div>
        {/* <KoDescription className={styles.description} text="Status : Pending Verification" /> */}
      </div>
    </div>,
    children: <VoterCrud handleFinish={handleFinish} form={form} initialValues={userData}/>,
    key: 3,
    style: panelStyle
  }, {
    label: <div className={styles.header}>
      <div className={styles.circle}>
        <KoIcon name="LocationFilled" height={20} width={20} />
      </div>
      <div className={styles.title}>
        <div className={styles.titleWithIcon}>
          <KoTitle className={styles.title} level={5} text="Driving License" />
          {/* <KoIcon name="TickFilled" height={12} width={12} /> */}
        </div>
        {/* <KoDescription className={styles.description} text="Status : Not uploaded" /> */}
      </div>
    </div>,
    children: <DrivingLicenseCrud handleFinish={handleFinish} initialValues={userData} form={form} />,
    key: 4,
    style: panelStyle
  }, {
    label: <div className={styles.header}>
      <div className={styles.circle}>
        <KoIcon name="BankFilled" height={14} width={14} />
      </div>
      <div className={styles.title}>
        <div className={styles.titleWithIcon}>
          <KoTitle className={styles.title} level={5} text="Passport" />
          {/* <KoIcon name="CrossFilled" height={12} width={12} /> */}
        </div>
        {/* <KoDescription className={styles.description} text="Status : Rejected" /> */}
      </div>
    </div>,
    children: <PassportCrud handleFinish={handleFinish} initialValues={userData} form={form} />,
    key: 5,
    style: panelStyle
  }, {
    label: <div className={styles.header}>
      <div className={styles.circle}>
        <KoIcon name="TaxFilled" height={12} width={12} />
      </div>
      <div className={styles.title}>
        <div className={styles.titleWithIcon}>
          <KoTitle className={styles.title} level={5} text="Degrees & Certificates" />
          {/* <KoIcon name="TickFilled" height={12} width={12} /> */}
        </div>
        {/* <KoDescription className={styles.description} text="Status : Not uploaded" /> */}
      </div>
    </div>,
    children: <EducationCrud handleFinish={handleFinish} initialValues={userData} form={form} />,
    key: 6,
    fields: 2,
    outof: 5,
    style: panelStyle
  }, {
    label: <div className={styles.header}>
      <div className={styles.circle}>
        <KoIcon name="DiscountFilled" height={16} width={16} />
      </div>
      <div className={styles.title}>
        <div className={styles.titleWithIcon}>
          <KoTitle className={styles.title} level={5} text="Previous Experience" />
          {/* <KoIcon name="CrossFilled" height={12} width={12} /> */}
        </div>
        {/* <KoDescription className={styles.description} text="Status : Rejected" /> */}
      </div>
    </div>,
    children: <PreviousExperienceCrud handleFinish={handleFinish} initialValues={userData} form={form} />,
    key: 7,
    style: panelStyle
  }];
  return (
    <div className={styles.wrapper}>
      <KoCard hoverable={false} className={styles.tabsContnet}>
        <div className={styles.accordion}>
          <KoForm form={form} layout="vertical" onFinish={handleFinish} onSubmit={handleFinish}>
            <KoAccordion accordion onChange={(e) =>  form?.resetFields()} defaultActiveKey={[1]} expandIconPosition={'end'} data={HEADER_OPTIONS} bordered={false} />
          </KoForm>
        </div>
      </KoCard>
     
    </div>
  );
}
