import { getCookie } from "shared/utils/cookies";
export const configs = () => {
  const accountId = getCookie('accountId');
  let API_HOST = process.env.REACT_APP_PROXY_HOST;
  let SOCKET_HOST = process.env.REACT_APP_PROXY_HOST;
  let API_VERSION = 'v1';
  let ATTACHEMNT_BASE_URL = `https://storage.cloud.google.com/kwapio/${accountId}`;
  return {
    API_HOST,
    ATTACHEMNT_BASE_URL,
    API_VERSION,
    SOCKET_HOST
  };
};
