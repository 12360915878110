import React from 'react';
import { KoDate, KoForm, KoFormBuilder, KoSelect } from 'packages';
import styles from './curd.module.scss';
import { PAYMENTTERMS } from 'shared/commonConstants';

export default function RecurringCurd({form, initialValues}) {
  const expiry = KoForm.useWatch(['schedule','neverExpires'], form);
  const repeatEvery = KoForm.useWatch(['schedule','repeatEvery'], form);

  const Date = ({ value, onChange }) =>
    <KoDate value={value} dateFormat={'DD-MMM-YYYY'}  daysAgo={0}
      onChange={v => onChange(v)} />;


  const options = [
    { label: 'Yes', value: true },
    { label: 'No', value: false }];
    
    
    
  const paymentsTerms = ({ value, onChange }) =>
    <KoSelect value={value}
      showSearch
      onChange={v => { onChange(v); }}
      placeholder='Select payment terms'
      options={PAYMENTTERMS}
    />;
    
         
  const recurringMeta = {
    columns: 4,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      {
        key: 'schedule.startOn', placeholder: 'Enter Start Date', label: 'Start Date', colSpan: 4, widget: Date
      },
      {
        colSpan: 4,
        key: 'schedule.neverExpires',
        widget: 'radioGroup',
        direction: 'horizontal',
        label:'Set Expiry',
        initialValues: true,
        options
      },
      {
        key: 'schedule.repeatEvery', label: 'Repeat Every', colSpan: 4,widget: paymentsTerms
      }
      
    ]
  };

  if (expiry) {
    recurringMeta['fields'].splice(2, 0, {
      key: 'schedule.endsOn',
      placeholder: 'Enter End Date',
      label: 'End Date',
      colSpan: 4,
      widget: Date
    });
  } else {
    if (recurringMeta['fields'][2]?.key === 'schedule.endsOn') {
      recurringMeta['fields'].splice(2, 1);
    }
  }

  if(repeatEvery === 10){
    recurringMeta['fields'].splice(4, 0, {
      key: 'schedule.repeatEveryCustom',
      placeholder: 'Enter repeat every custom',
      label: 'Repeat Every Custom',
      colSpan: 4,
      widget: 'number'
    });
  }else{
    if (recurringMeta['fields'][4]?.key === 'schedule.repeatEveryCustom') {
      recurringMeta['fields'].splice(4, 1);
    }
  }

  return (
    <div className={styles.schedule}>
      <div className={styles.recurring}>
        <KoForm layout="vertical" form={form} >
          <KoFormBuilder form={form}  meta={recurringMeta} />
        </KoForm>
      </div>
    </div>
  );
}
