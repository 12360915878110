const LEV_ITEMS_COLUMNS = [{
  name: "name",
  id: "name",
  label: "Name",
  widget: "text",
  fixed: true
},  {
  name: "leads",
  id: "leads",
  label: "Leads",
  widget: "assignees"
},
{
  name: "moduleOwner",
  id: "moduleOwner",
  label: "Module Owner",
  widget: "assignees"
}
];

export {
  LEV_ITEMS_COLUMNS
};