import _find from 'lodash/find';
import {
  Checkbox,
  KoAcceptanceCriteria,
  KoButton,
  KoDateFormatter,
  KoForm,
  KoFormBuilder,
  KoIcon,
  KoNano,
  KoSelect,
  KoTabs,
  KoText,
  KoTimePicker,
  KoToast,
  KoUserSelect,
  Upload
} from 'packages';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router';
import { ISSUETYPE, ISSUETYPEWITHOUTSUBTASK, STATUS, TIMESPEND } from 'shared/commonConstants';
import { getSprint } from "shared/store/slice";
import { getCookie } from 'shared/utils/cookies';
import { TaskPlannerAPI } from "../taskPlanner.service";
import { Date, Priority, Sprint } from './components';
import styles from './curd.module.scss';
import SubTasksTable from './subtasks';
import WorkLog from './workLog';
import { TimeSpent } from 'shared/components';
import Comments from './comments';
import Attachemnts from './attachemnts';
import Activities from './activities';

function TaskCrud({ form, saveBtn, getIssueById,
  className, handleFinish, initialValues, subtask , epic, epicId, workspaceProfile}) {
  const {workspaceId} = useParams();
  const userById = useSelector(state => state?.userById);
  const [currentWorkspaceId, setCurrentWorkspaceId] = useState(workspaceId);
  const [profile, setProfile] = useState(workspaceProfile);
  const [modulesList, setModulesList] = useState();
  const [componentsList, setComponentsList] = useState();
  const [releaseList, setReleaseList] = useState();
  const [workspace, setWorkspaceRecord] = useState();
  const [lookupId, setLookupId] = useState();
  const [tickets, setTickets] = useState();
  const [createSubTask, setCreateSubTask] = useState();
  const [isCreateLogOpen, setCreateLog] = useState();
  const [isOpenCommentsModal, setCreateComment] = useState();
  const dispatch = useDispatch();
  const StatusWatch = KoForm.useWatch('status', form);
  const includeInCalendarWatch = KoForm.useWatch('includeInCalendar', form) || false;
  let userDetails = userById['data'];
  
  useEffect(() => {
    if (!initialValues || Object.keys(initialValues).length === 0) {
      form?.resetFields();
    } else {
      form?.setFieldsValue(initialValues);
    }
    setCurrentWorkspaceId(initialValues?.workspace);
  }, [initialValues, form]);

  useEffect(() => {
    if (workspaceProfile?.length > 0) {
      setProfile(workspaceProfile);
    }else {
      getWorkspaceById(currentWorkspaceId);
    }
  }, [workspaceProfile]);

  useEffect(() => {
    if (epic) {
      form.setFieldsValue({ issueType: 1 ,  reporter: userDetails && userDetails?.userId});
    }
  }, [epic]);






  const getWorkspaceById = (workspaceId) => {
    if(!workspaceId){
      return;
    }
    TaskPlannerAPI.getWorkspaceById(workspaceId).then(
      (data) => {
        setWorkspaceRecord(data['project']);
        setProfile(data['project'].teams);
      },
      (err) => {
      }
    );

  };

  useEffect(() => {
    if ((StatusWatch === 10 || StatusWatch === 11) && workspace?.isPublic) {
      form.setFieldsValue({ assignee: initialValues?.reporter });
    }
  }, [StatusWatch]);

  useEffect(() => {
    if (initialValues?.lookupId) {
      setLookupId(initialValues.lookupId);
    } else {
      const lp = KoNano('LOK');
      setLookupId(lp);
      let data = form?.getFieldsValue() || {};
      data['lookupId'] = lp;
      form?.setFieldsValue({ ...data });
    }

    if(currentWorkspaceId){
      getModules();
      getComponents();
      getReleases();
      getTickets();
      getSprints();
    }
  }, []);


  const getModules = () => {
    setModulesList();
    TaskPlannerAPI.getModules(null,currentWorkspaceId).then(
      (data) => {
        setModulesList(data?.modules);
      },
      (err) => {
      }
    );
  };

  const getSprints = () => {
    if(!currentWorkspaceId){
      return;
    }
    TaskPlannerAPI.getSprints(null,currentWorkspaceId).then(
      (data) => {
        dispatch(getSprint({ query: null, workspaceId: currentWorkspaceId }));
      },
      (err) => {
      }
    );
  };

  const getComponents = () => {
    setComponentsList();
    TaskPlannerAPI.getComponents(null, currentWorkspaceId).then(
      (data) => {
        setComponentsList(data?.components);
      },
      (err) => {
      }
    );
  };

  const getReleases = () => {
    setReleaseList();
    TaskPlannerAPI.getReleases(workspaceId).then(
      (data) => {
        setReleaseList(data?.releases);
      },
      (err) => {
      }
    );
  };



  const handleSubmit = useCallback(values => {
    values['lookupId'] = lookupId;
    handleFinish(values, epicId);
  });

  const optionGroups = [];
  for (let i = 10; i < 36; i++) {
    optionGroups.push({
      label: i.toString(36) + i,
      value: i.toString(36) + i
    });
  }
  const TaskStatus = ({ value, onChange }) => (
    <KoSelect
      value={value}
      showSearch
      defaultValue={STATUS[0].value}
      onChange={v => onChange(v)}
      placeholder='Select status'
      options={STATUS}
      optionRender={(option) => {
        const label = option?.data?.label;
        const formattedLabel = typeof label === 'string' ? label.replace(" ", '') : '';
        return (
          <div>
            <div className={`${styles.status} ${styles[formattedLabel]}`} role="img" aria-label={label} />
            <KoText text={label} />
          </div>
        );
      }}
    />
  );


  const IssueType = ({ value, onChange }) => (
    <KoSelect
      value={epic ? 1 : value}
      onChange={v => onChange(v)}
      showSearch
      disabled={subtask || initialValues?.issueType === 4 || epic}
      placeholder="Select issue type"
      options={(subtask || initialValues?.issueType === 4) ? ISSUETYPE : ISSUETYPEWITHOUTSUBTASK}
      optionRender={(option) => (
        <div className={styles.iconWithLabel} role="img" aria-label={option.data.label}>
          {option.data.value === 1 && <KoIcon name="EpicFilled" />}
          {option.data.value === 2 && <KoIcon name="StoryFilled" />}
          {option.data.value === 3 && <KoIcon name="TaskFilled" />}
          {option.data.value === 4 && <KoIcon name="SubtaskFilled" />}
          {option.data.value === 5 && <KoIcon name="BugFilled" />}
          {option.data.value === 6 && <KoIcon name="ImprovementsFilled" />}
          {option.data.value === 7 && <KoIcon name="FeatureRequestFilled" />}
          {option.data.value === 8 && <KoIcon name="ChangerequestFilled" />}
          {option.data.value === 9 && <KoIcon name="SupportFilled" />}
          {option.data.label}
        </div>
      )}
    />
  );


  const getTickets=()=>{
    setTickets();
    TaskPlannerAPI.getIssues({ issueType: 1}, currentWorkspaceId).then(
      (data) => {
        setTickets(data?.tickets);
      },
      (err) => {
      }
    );
  };


  const AcceptanceCriteria = ({ value, onChange }) =>
    <KoAcceptanceCriteria value={value} initialValue={value} workspaceId={currentWorkspaceId}
      form={form} name='acceptanceCriteria' lookupId={lookupId}
      onChange={v => onChange(v)}
    />;


  const Description = ({ value, onChange }) =>
    <KoAcceptanceCriteria value={value} initialValue={value} lookupId={lookupId} workspaceId={currentWorkspaceId}
      form={form} name='description'
      placeholder='Add a description...'
      onChange={v => onChange(v)}
    />;

  const Assignees = ({ value, onChange }) =>
    <KoUserSelect  value={value} onChange={v => onChange(v)} users={profile} placeholder='Select assignee' />;


  const Reporter = ({ value, onChange }) =>
    <KoUserSelect  value={value} onChange={v => onChange(v)} users={profile} placeholder='Select reporter' />;

  const Watchers = ({ value, onChange }) =>
    <KoUserSelect mode='multiple'  value={value} onChange={v => onChange(v)} users={profile} placeholder='Select watchers' />;

  const Module = ({ value, onChange }) =>
    <KoSelect
      placeholder="Select module"
      value={value}
      onChange={v => onChange(v)}
      options={modulesList?.map((module) => ({
        label: module?.name,
        value: module?.moduleId
      }))}
    />;

  const CheckBox = ({ value, onChange }) =>
    <Checkbox checked={value} onChange={v => onChange(v?.target?.checked)}>{`Track issue in Calendar`}</Checkbox>;

  const Components = ({ value, onChange }) =>
    <KoSelect
      placeholder='Select component'
      value={value}
      onChange={v => onChange(v)}
      showSearch
      options={componentsList?.map((comp) => ({
        label: comp?.name,
        value: comp?.componentId
      }))}
    />;


  const Releases = ({ value, onChange }) =>
    <KoSelect value={value}
      onChange={v => onChange(v)}
      showSearch
      placeholder='Select Tag / Release'
      options={releaseList?.map((env) => ({
        label: env?.name,
        value: env?.releaseId
      }))}
    />;



  const EpicTicketId = ({ value, onChange }) => {
    const options = tickets?.map((ticket) => {
      const issueType = _find(ISSUETYPE, (o) => o.value === ticket.issueType);
      return {
        label: (
          <div className={styles.TicketIdWrapper} aria-label={ticket?.ticketId}>
            {issueType.icon}
            <KoText className={styles.text} text={`${ticket?.ticketId} |`} />
            <KoText className={styles.text} text={ticket?.summary} />
          </div>
        ),
        value: ticket?.ticketId
      };
    });
    
    return (
      <KoSelect
        value={value}
        onChange={(v) => onChange(v) }
        showSearch
        defaultValue={epicId ? epicId : value}
        // onFocus={getTickets}
        placeholder="Select Ticket Id"
        options={options}
      />
    );
  };


  const Time = ({ value, onChange }) => {
    const handleChange = (v) => {
      const formattedValue = v instanceof Date ? v.toTimeString().slice(0, 5) : v;
      onChange(formattedValue);
    };

    const formattedValue = value ? (value instanceof Date ? value.toTimeString().slice(0, 5) : value) : '';

    return (
      <KoTimePicker changeOnScroll needConfirm={false} allowClear={false}
        value={formattedValue} format='HH:mm' onChange={handleChange} />
    );
  };


  const TimeSpentSelect = ({ value, onChange }) => {
    return (
      <KoSelect
        value={value || undefined}
        placeholder="Ex: 1w 3d 6h 20m"
        showSearch
        onChange={(v) => onChange(v)}
        options={TIMESPEND}     />
    );
  };

  const SUMMARYSECTION= {
    columns: 6,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      {
        key: 'summary', placeholder: 'Enter issue summary', label: 'Summary', colSpan: 3, rules: [
          {
            required: true,
            message: `Please enter summary.`
          }
        ]
      },
      {
        key: 'issueType', label: 'Issue type', colSpan: 1.5,
        widget: IssueType,
        disabled: initialValues?.issueType === 4,
        rules: [
          {
            required: true,
            message: `Please select issue type.`
          }
        ]
      },
      {
        key: 'status', label: 'Status', colSpan: 1.5,
        widget: TaskStatus,
        forwardRef: true
      },
      
      {
        key: 'lookupId', label: 'Lookup Id', colSpan: 0, className: 'metaFieldHide'
      }
    ]
  };


  const BASICMETADATA = {
    columns: 6,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      {
        key: 'label1s',
        colSpan: 64,
        render() {
          return (
            <fieldset>
              <div className={styles.createdAt}>
                <legend>ISSUE DETAILS</legend>
                {initialValues?.createdAt && <div className={styles.createdAtWrapper}>
                  <KoText text="Created on"  className={styles.createdAtText}/>
                  <KoDateFormatter className={styles.createdAtDate} data={initialValues?.createdAt} format="DD-MMM-YYYY" />
                </div>}
              </div>
            </fieldset>
          );
        }
      },
      {
        key: 'sprint',  colSpan: 3,
        widget: Sprint,
        forwardRef: true,
        label: 'Sprint'
      },
      {
        key: 'epic', label: 'Epic', colSpan: 3, widget: EpicTicketId
      },
      {
        key: 'priority', label: 'Priority', colSpan: 3,
        widget: Priority,
        forwardRef: true
      },
      {
        key: 'storyPoints', label: 'Story Points / Estimate', colSpan: 3, widget: TimeSpentSelect
      },
      {
        key: 'label1',
        colSpan: 6,
        render() {
          return (
            <fieldset>
              <legend>PEOPLE</legend>
            </fieldset>
          );
        }
      },
      {
        key: 'assignee', label: <div className={styles.assigneeLabel}>
          <KoText text={'Assignee'}/>
          { initialValues?.assignee !== userDetails?.userId && <KoText className={styles.assignToMe} text={'(Assign to me)'} onClick={v => form.setFieldsValue({ assignee: userDetails?.userId })}  />}
        </div>, colSpan: 3,
        widget: Assignees,
        forwardRef: true
      },
      {
        key: 'reporter', label: 'Reporter', colSpan:3,
        widget: Reporter,
        rules: [
          {
            required: true,
            message: `Please select Reporter.`
          }
        ]
      },
      {
        key: 'watchers', label: 'Watchers', colSpan:6,
        widget: Watchers
      },
      {
        key: 'label3',
        colSpan: 6,
        render() {
          return (
            <fieldset>
              <legend>DATE & CALENDAR</legend>
            </fieldset>
          );
        }
      },
      {
        key: 'includeInCalendar',
        colSpan: 3,
        label: 'Include in Calendar',
        widget: CheckBox
      },
      {
        key: 'calendarTime', placeholder: '0:00', label: 'Pick a Time',colSpan: 3,
        disabled: !includeInCalendarWatch,
        widget: Time, rules: [
          {
            required: !includeInCalendarWatch ? false : true,
            message: `Please Pick a Time.`
          }
        ]
      },
      {
        key: 'startDate', placeholder: 'Enter Start Date', label: 'Start Date',
        colSpan: 3, widget: Date, rules: includeInCalendarWatch ? [
          {
            required: true,
            message: 'Please select start date.'
          }
        ] : []
      },
      {
        key: 'endDate', placeholder: 'Enter End Date', label: 'Due Date', colSpan: 3, widget: Date,
        rules: includeInCalendarWatch ? [
          {
            required: true,
            message: 'Please select end date.'
          }
        ] : []
      },
      {
        key: 'label3',
        colSpan: 64,
        render() {
          return (
            <fieldset>
              <legend>WORK LOG</legend>
            </fieldset>
          );
        }
      },
      {
        key: 'timeSpent', label: 'Time spent', colSpan: 3, widget: TimeSpentSelect
      },
      {
        key: 'timeRemaining', label: 'Time remaining', colSpan: 3, widget: TimeSpentSelect
      },
      {
        key: 'label1a',
        colSpan: 6,
        render() {
          return (
            <fieldset>
              <legend>ADDITIONAL INFORMATION </legend>
            </fieldset>
          );
        }
      },
      {
        key: 'release', label: 'Tag / Release', colSpan: 3, widget: Releases
      },
      {
        key: 'module', label: 'Module', colSpan: 3, widget: Module
      },
      {
        key: 'component', label: 'Component', colSpan: 3, widget: Components
      },

      {
        key: 'specLink', label: 'Spec Link', colSpan: 3, widget: Sprint
      }
    
    ]
  };

  if (epic) {
    BASICMETADATA['fields'].splice(3, 1);
    BASICMETADATA['fields'].splice(3, 1);
    BASICMETADATA['fields'].splice(3, 1);
    BASICMETADATA['fields'].splice(3, 1);
  }

  const TASKINFOMETADATA = {
    columns: 6,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      {
        key: 'acceptanceCriteria', placeholder: 'Requirement', label: 'Acceptance Criteria', colSpan: 6,
        widget: AcceptanceCriteria
      },
      {
        key: 'description', placeholder: 'Description', label: 'Description', colSpan: 6,
        widget: Description
      }

    ]
  };
  const props = {
    name: 'files',
    multiple: true,
    action: `${process.env.REACT_APP_PROXY_HOST}/api/v1/org/upload`,
    data: { service: getCookie('wos'), module: 'taskplanner', lookupId: lookupId },
    headers: {
      Authorization: `Bearer ${getCookie("accessToken")}`
    },

    onChange(info) {
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'done') {
        KoToast.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        KoToast.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };


  const uploadButton = (
    <div className={styles.dndArea}>
      <KoIcon name='AttachmentOutlined' />
      <div>Add image, video, or file</div>
    </div>

  );

  const onRemoveFile = (d) => {
    TaskPlannerAPI.deleteAttachments(currentWorkspaceId,initialValues?.lookupId, d?.uid).then(
      (data) => {
      },
      (err) => {
      }
    );
  };

  return (
    <div className={`${styles.ticketWrapper} 'tabContnetTicket'`}>
      <div className={`${styles.wrapper} ${className ? className : ''}`}>
        <KoForm form={form} layout="vertical"
          onFinish={handleSubmit} onSubmit={handleSubmit}>
          <div className={styles.leftForm}>
            <KoFormBuilder form={form} meta={SUMMARYSECTION} />
            <div className={`${styles.taskInfo} profileSection`}>

              <KoTabs options={[
                {
                  label:<div className={styles.subTaskTab}>
                    <KoIcon name='TextOutlined' width={14} height={14} />
                    <KoText text={'Issue Details'} />
                  </div>,
                  children: <div className={styles.taskInfoTabs}>
                    <KoFormBuilder form={form} meta={TASKINFOMETADATA} /></div>,
                  key: 'about'
                },
                {
                  label: <div className={styles.subTaskTab}>
                    <KoIcon name='AttachmentOutlined' width={14} height={14} />
                    <KoText text={'Attachments'} />
                  </div>,
                  children: <div className={styles.taskInfoTabs}>
                    <div className={styles.attachments}>
                      <Attachemnts onRemoveFile={onRemoveFile} ticketId={initialValues?.ticketId} workspaceId={currentWorkspaceId}/>
                      <KoForm.Item
                        name={["attachments"]}
                        valuePropName={["attachments"]}
                        getValueFromEvent={normFile}>

                        <Upload {...props}
                          className={styles.card}
                          onRemove={onRemoveFile}
                          showUploadList={{showPreviewIcon : false}}
                          showPreviewIcon={true}
                          listType="picture-card"
                          multiple={true}
                        >
                          {uploadButton}
                        </Upload>
                      </KoForm.Item>
                    </div>
                  </div>,
                  key: 'attachments'
                },
                ...(!initialValues?.ticketId || initialValues?.issueType === 4 ?
                  []: [{
                    label: <div className={styles.subTaskTab}>
                      <KoIcon name='SubtaskOutlined' color="var(--palette-gray-7)" width={14} height={14} />
                      <KoText text={'Subtasks'} />
                      {/* {initialValues?.ticketId &&  <KoIcon name='PlusFilled' onClick={()=>setCreateSubTask(true)}/>} */}
                    </div>,
                    children:  <SubTasksTable
                      issueType={IssueType}
                      Reporter={Reporter}
                      taskStatus={TaskStatus}
                      createSubTask={createSubTask}
                      workspace={workspace}
                      profile={profile}
                      openCreateSubTaskModal={setCreateSubTask}
                      priority={Priority}
                      date={Date}
                      sprint={Sprint}
                      assignees={Assignees}
                      TimeSpent={TimeSpent}
                      parentRecord={initialValues} />,
                    key: 'Subtasks',
                    disabled: (!initialValues?.ticketId || initialValues?.issueType === 4)
                  }]),
             
                {
                  label: <div className={styles.subTaskTab}>
                    <KoIcon name='LogOutlined' width={13} height={13} />
                    <KoText text={'Work Log'} />
                    {/* {initialValues?.ticketId &&  <KoIcon name='PlusFilled' onClick={()=>setCreateLog(true)}/>} */}
                  </div>,
                  children:  <WorkLog LogList={initialValues?.workLog} workspaceIdFromParam={initialValues?.workspace} getIssueById={getIssueById} ticketId={initialValues?.ticketId} form={form} setCreateLog={setCreateLog} isCreateLogOpen={isCreateLogOpen}/>,
                  key: 'worklog',
                  disabled: (!initialValues?.ticketId)
                },
                {
                  label: <div className={styles.subTaskTab}>
                    <KoIcon name='ChatOutlined' width={15} height={15} color="var(--palette-gray-10)"/>
                    <KoText text={'Comments'} />
                    {/* {initialValues?.ticketId &&  <KoIcon name='PlusFilled' onClick={()=>setCreateComment(true)}/>} */}
                  </div>,
                  children: <div className={styles.worklogTab}>
                    <Comments workspaceId={initialValues?.workspace}
                      ticketId={initialValues?.ticketId}
                      setCreateComment={setCreateComment}
                      isOpenCommentsModal={isOpenCommentsModal}/>
                  </div>,
                  key: 'comments',
                  disabled: (!initialValues?.ticketId)
                },
                
                {
                  label: <div className={styles.subTaskTab}>
                    <KoIcon name='InfoOutlined' width={15} height={15} color="var(--palette-gray-10)"/>
                    <KoText text={'Activities'} />
                  </div>,
                  children: <div className={styles.taskInfoTabs}>
                    <Activities  workspaceId={initialValues?.workspace}
                      ticketId={initialValues?.ticketId}/>
                  </div>,
                  key: 'activities'
                }
             
              ]} />
            </div>
          </div>
          <div className={styles.rightForm}>
            <KoFormBuilder form={form} meta={BASICMETADATA} />
          </div>
        </KoForm>
        {saveBtn &&
      <KoButton key='Update' type='primary' label='Update' onClick={() => form.submit()} className={styles.saveBtn} />
        }
      </div>
    </div>
  );
};

export default TaskCrud;