import React from "react";
function Activate(props) {
  const { width, height } = props;
  return (<svg
    viewBox="0 0 24 24"
    width={width}
    height={height}
  >
    <g data-name="Arrow 6 Left">
      <circle
        cx={12}
        cy={12}
        r="11.5"
        fill="none"
        stroke="#303c42"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="colorStroke303c42 svgStroke"
      />
      <path
        fill="none"
        stroke="#303c42"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m10 6.5 5.5 5.5-5.5 5.5"
        className="colorStroke303c42 svgStroke"
      />
    </g>
  </svg>
  );
}
export default Activate;