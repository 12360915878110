import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/en';

import React from 'react';
import { KoText } from '../typography';
import styles from './dateformat.module.scss';

dayjs.extend(utc);
dayjs.extend(timezone);

function KoDateFormatter({ className, format, timeZone, data }) {
  const appliedFormat = format ? format : "DD-MMM-YYYY hh:mm a";
  const appliedTimeZone = timeZone ? timeZone : dayjs.tz.guess();

  const finalData = data === '0001-01-01T00:00:00Z' ? '' : data;

  return (
    <KoText className={`${styles.text} ${className ? className :''}`} text={finalData ? dayjs(finalData)?.tz(appliedTimeZone).format(appliedFormat)?.toUpperCase() : ''}/>
  );
}

export default KoDateFormatter;
