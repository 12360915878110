import React from "react";
function Meetings(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path d="M11.082 2.04297C8.74218 2.28125 6.68749 3.23828 5.03124 4.85156C4.14843 5.71094 3.58593 6.48828 3.05468 7.56641C2.55468 8.57812 2.26952 9.47656 2.11327 10.5234C1.76561 12.8672 2.24218 15.1914 3.49218 17.2578L3.70702 17.6094L2.94139 19.582C2.51952 20.6641 2.13671 21.6523 2.08983 21.7734L2.00389 21.9961L2.22655 21.9102C2.34764 21.8633 3.33593 21.4805 4.42186 21.0586L6.39452 20.293L6.74218 20.5039C8.47264 21.5547 10.3867 22.0625 12.332 21.9883C13.832 21.9297 15.1328 21.5977 16.4492 20.9414C17.5312 20.4023 18.3008 19.8398 19.168 18.9492C19.9766 18.1172 20.5 17.3711 21.0078 16.3203C21.4531 15.3906 21.7578 14.3906 21.9101 13.3477C22.0039 12.6758 22.0039 11.332 21.9062 10.6523C21.5976 8.49219 20.6406 6.54297 19.1367 5.01172C17.5859 3.42969 15.6562 2.44531 13.4648 2.11328C12.875 2.02344 11.6211 1.98438 11.082 2.04297ZM13.9961 8.41797C14.2187 8.53516 14.4023 8.78125 14.457 9.03125C14.4805 9.14062 14.5 9.42188 14.5 9.65625C14.5 9.89453 14.5156 10.0859 14.5351 10.0859C14.5508 10.0859 15.1875 9.83203 15.9492 9.51953C16.7109 9.20703 17.3672 8.95312 17.4101 8.95312C17.5117 8.95312 17.5625 8.99219 17.6211 9.09766C17.6836 9.21875 17.6836 14.7812 17.6211 14.9023C17.5625 15.0078 17.5117 15.0469 17.4101 15.0469C17.3672 15.0469 16.707 14.793 15.9492 14.4805C15.1875 14.168 14.5508 13.9141 14.5312 13.9141C14.5156 13.9141 14.5 14.1055 14.5 14.3438C14.5 14.5781 14.4805 14.8594 14.457 14.9688C14.4023 15.2188 14.2187 15.4648 13.9961 15.582L13.8242 15.6719H10.418H7.01171L6.83983 15.582C6.61718 15.4648 6.43358 15.2188 6.37889 14.9688C6.3203 14.6914 6.3203 9.30859 6.37889 9.03125C6.43358 8.78125 6.61718 8.53516 6.83983 8.41797L7.01171 8.32812H10.418H13.8242L13.9961 8.41797Z" fill="var(--palette-primary-darker)" />
    </svg>
  );
}


export default Meetings;