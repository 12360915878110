import {KoAPI} from "packages";
import { configs } from 'configuration';
const version = configs().API_VERSION;
export const ProfileAPI = {

  getInbox(){
    return KoAPI.get(`/api/${version}/home/inbox/approvals`);
  },

  getProfile(id){
    return KoAPI.get(`/api/${version}/org/profile/${id}`);
  },
  updateFieldProfile(data, id){
    return KoAPI.patch(`/api/${version}/org/profile/field/${id}`, { data : data });
  },
  updateProfile(data, id){
    return KoAPI.update(`/api/${version}/org/profile/${id}`, { data : data });
  },
  getPreference(){
    return KoAPI.get(`/api/${version}/org/profile/user/preference`);
  },
  getTimesheet(){
    return KoAPI.get(`/api/${version}/home/timesheet`);
  },
  getAllWorkLogsTime(query){
    return KoAPI.get(`/api/${version}/home/timesheet/week/worklog`, { query: { ...query } });
  },
  getTimesheetById(id){
    return KoAPI.get(`/api/${version}/home/timesheet/${id}`);
  },
  createOrUpdateTimesheet(data, id) {
    if(id){
      return KoAPI.update(`/api/${version}/home/timesheet/${id}`, { data });
    }else{
      return KoAPI.post(`/api/${version}/home/timesheet`, { data });
    }
  },
  deleteTimesheetById(id){
    return KoAPI.delete(`/api/${version}/home/timesheet/${id}`);
  },
  getBillableNonBillableTime(query){
    return KoAPI.get(`/api/${version}/home/timesheet/dashboard/billable`, { query: { ...query } });
  },
  getTaskAssignedCount(query){
    return KoAPI.get(`/api/${version}/home/timesheet/dashboard/task`);
  },
  getCurrentWeekTotalWorkTime(query){
    return KoAPI.get(`/api/${version}/home/timesheet/dashboard/time`, { query: { ...query } });
  },
  getTotalHours(){
    return KoAPI.get(`/api/${version}/home/timesheet/dashboard/hours`);
  },
  


  getClients(){
    return KoAPI.get(`/api/${version}/admin/customer`);
  },
      
  getRecentTicket(query) {
    return KoAPI.get(`/api/${version}/taskplanner/ticket/recent`, { query: { ...query } });
  },

  getWorkspaceById(id){
    return KoAPI.get(`/api/${version}/workspace/${id}`);
  },
  sendApprovalRequest(query, data){
    return KoAPI.update(`/api/${version}/home/ticket/worklog/approval`, { query: { ...query }, data });
  },
  cancelTimesheet(data, query){
    return KoAPI.update(`/api/${version}/home/ticket/worklog/approvel/cancel`, { data, query: { ...query } });
  },

  getTimesheetHistoryApproved(){
    return KoAPI.get(`/api/${version}/home/ticket/worklog/approved`);
  },
  
  getTimesheetHistoryRejected(query){
    return KoAPI.get(`/api/${version}/home/ticket/worklog/rejected`);
  }
};