import React from "react";
function TailIn() {
  return (
    <svg viewBox="0 0 8 13" x="0px" y="0px" height={13} width={8}>
      <path
        opacity={0.13}
        fill="#FFF"
        d="M1.533 3.568L8 12.193V1H2.812C1.042 1 .474 2.156 1.533 3.568z"
      />
      <path
        fill="#FFF"
        d="M1.533 2.568L8 11.193V0H2.812C1.042 0 .474 1.156 1.533 2.568z"
      />
    </svg>

  );
}
export default TailIn;