import { useState } from 'react';

import { City, Country, State } from 'country-state-city';
import { Checkbox, KoDivider, KoForm, KoFormBuilder, KoSelect, KoText } from 'packages';
import styles from './about.module.scss';

function AddressCrud({ form, handleFinish, initialValues }) {
  const [countriesList] = useState(Country.getAllCountries());
  const [stateList, setStateList] = useState();
  const [cityList, setCityList] = useState();


  const handleSubmit =(values)=>{
    const {checkSameAddress, ...restValues} = values;
    handleFinish(restValues);
  };


  const optionGroups = [];
  for (let i = 10; i < 36; i++) {
    optionGroups.push({
      label: i.toString(36) + i,
      value: i.toString(36) + i
    });
  }


  const CountryD = ({ value, onChange }) =>
    <KoSelect showSearch value={value}
      onChange={(v) => {
        onChange(v);
        setStateList(State.getStatesOfCountry(v));
      }}
      placeholder={'Select Country'}
      options={countriesList?.map((country) => ({
        label: country?.name,
        value: country?.isoCode
      }))} />;


  const StateD = ({ value, onChange }) =>

    <KoSelect showSearch value={value}
      onChange={(v) => {
        onChange(v);
        setCityList(City.getCitiesOfState(form.getFieldValue('address')?.country, v));
      }}
      disabled={!form.getFieldValue('address')?.country}
      placeholder={'Select State'}
      options={stateList?.map((state) => ({
        label: state?.name,
        value: state.isoCode
      }))} />;

  const CityD = ({ value, onChange }) =>

    <KoSelect showSearch value={value}
      onChange={(v) => {
        onChange(v);
      }}
      disabled={!form.getFieldValue('address')?.state}
      placeholder={'Select City'}
      options={cityList?.map((cit, index) => ({
        label: cit?.name,
        value: cit?.name
      }))} />;

  const CheckBox = ({ value, onChange }) => {
    const handleCheckChange = (checked) => {
      onChange(checked);

      if (checked) {
        const {currentAddress} = form.getFieldValue('addresses');
        form.setFieldsValue({
          addresses: {
            ...form.getFieldValue('addresses'),
            permanentAddress: {
              ...currentAddress
            }
          }
        });
      } else {
        form.setFieldsValue({
          addresses: {
            ...form.getFieldValue('addresses'),
            permanentAddress: {
              addressLine1: '',
              addressLine2: '',
              country: '',
              state: '',
              city: '',
              pincode: ''
            }
          }
        });
      }
    };

    return (
      <Checkbox
        checked={value}
        onChange={(e) => handleCheckChange(e.target.checked)}
      >
      Same as Current Address
      </Checkbox>
    );
  };



  const meta = {
    columns: 6,
    formItemLayout: null,
    initialValues: { ...initialValues, checkSameAddress: false },
    colon: true,
    fields: [
      {
        key: '', colSpan: 6,
        widget: () => (
          <KoDivider orientation="left" orientationMargin="0">
            <KoText className={styles.headingText} text={'Current Address'} />
          </KoDivider>
        ),
        forwardRef: true
      },
      {
        key: 'addresses.currentAddress.addressLine1', label: 'Address Line 1', placeholder: 'Enter Address Line 1', colSpan: 2,
        rules: [{ required: true, message: 'Address Line 1 is required' }]
      },
      {
        key: 'addresses.currentAddress.addressLine2', label: 'Address Line 2', placeholder: 'Enter Address Line 2', colSpan: 2,
        rules: [{ required: true, message: 'Address Line 2 is required' }]
      },
      {
        key: 'addresses.currentAddress.country', label: 'Country', colSpan: 2, widget: CountryD, forwardRef: true
      },
      {
        key: 'addresses.currentAddress.state', label: 'State', colSpan: 2, widget: StateD, forwardRef: true
      },
      {
        key: 'addresses.currentAddress.city', label: 'City', colSpan: 2, widget: CityD, forwardRef: true
      },
      {
        key: 'addresses.currentAddress.pincode', label: 'Pincode', placeholder: 'Enter pincode', colSpan: 2,
        rules: [{ required: true, message: 'Pincode is required' }]
      },
      {
        key: 'checkSameAddress', colSpan: 2, label: '', widget: CheckBox
      },
      {
        key: '', colSpan: 6,
        widget: () => (
          <KoDivider orientation="left" orientationMargin="0">
            <KoText className={styles.headingText} text={'Permanent Address'} />
          </KoDivider>
        ),
        forwardRef: true
      },
      {
        key: 'addresses.permanentAddress.addressLine1', label: 'Address Line 1', placeholder: 'Enter Address Line 1', colSpan: 2,
        rules: [{ required: true, message: 'Address Line 1 is required' }]
      },
      {
        key: 'addresses.permanentAddress.addressLine2', label: 'Address Line 2', placeholder: 'Enter Address Line 2', colSpan: 2,
        rules: [{ required: true, message: 'Address Line 2 is required' }]
      },
      {
        key: 'addresses.permanentAddress.country', label: 'Country', colSpan: 2, widget: CountryD, forwardRef: true
      },
      {
        key: 'addresses.permanentAddress.state', label: 'State', colSpan: 2, widget: StateD, forwardRef: true
      },
      {
        key: 'addresses.permanentAddress.city', label: 'City', colSpan: 2, widget: CityD, forwardRef: true
      },
      {
        key: 'addresses.permanentAddress.pincode', label: 'Pincode', placeholder: 'Enter pincode', colSpan: 2,
        rules: [{ required: true, message: 'Pincode is required' }]
      }
    ]
  };
  return (
    <div className={styles.crudWrapper}>
      <KoForm form={form} layout="vertical" onFinish={handleSubmit} onSubmit={handleSubmit}>
        <KoFormBuilder form={form} meta={meta} />
      </KoForm>
    </div>
  );
};

export default AddressCrud;