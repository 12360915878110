import styles from './log.module.scss';

function Log(props) {
  const { data } = props;
  
  const extractUnits = (input) => {
    return input?.match(/\d+[a-zA-Z]/g) || [];
  };

  const formatUnits = (unit, number) => {
    if (unit.includes("w")) return number > 1 ? "Weeks" : "Week";
    if (unit.includes("d")) return number > 1 ? "Days" : "Day";
    if (unit.includes("h")) return number > 1 ? "Hours" : "Hour";
    if (unit.includes("m")) return number > 1 ? "Minutes" : "Minute";
    return "";
  };

  const units = extractUnits(data);
  
  const formattedData = units.map((unit) => {
    const number = unit.match(/\d+/)[0];
    const unitLetter = unit.match(/[a-zA-Z]/)[0];
    const fullUnit = formatUnits(unitLetter, parseInt(number));
    return `${number} ${fullUnit}`;
  }).join(" ");

  return (
    <div className={styles.wrapper}>
      <div className={`${styles.status}`}>
        <div className={styles.text}>{formattedData}</div>
      </div>
    </div>
  );
}

export default Log;
