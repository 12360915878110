import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';

import { Table, Select } from 'antd';

const { Option } = Select;

const XlxsViewer = ({ filePath }) => {
  const [sheets, setSheets] = useState([]);
  const [names, setNames] = useState([]);
  const [curSheetIndex, setCurSheetIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAndParseData = async() => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(filePath);
        if (!response.ok) {
          throw new Error(`Failed to fetch file: ${response.statusText}`);
        }
        const arrayBuffer = await response.arrayBuffer();
        const dataArr = new Uint8Array(arrayBuffer);
        const arr = [];
        for (let i = 0; i < dataArr.length; i += 1) {
          arr.push(String.fromCharCode(dataArr[i]));
        }

        const workbook = XLSX.read(arr.join(''), { type: 'binary' });
        const sheetNames = workbook.SheetNames;
        const sheetsData = sheetNames.map(name => XLSX.utils.sheet_to_json(workbook.Sheets[name], { header: 1 }));

        setSheets(sheetsData);
        setNames(sheetNames);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAndParseData();
  }, [filePath]);

  const handleSheetChange = value => {
    setCurSheetIndex(value);
  };

  const columns = sheets[curSheetIndex]?.[0]?.map((col, index) => ({
    title: col,
    dataIndex: index,
    key: index
  })) || [];

  const dataSource = sheets[curSheetIndex]?.slice(1).map((row, index) => ({
    key: index,
    ...row.reduce((acc, cell, cellIndex) => ({ ...acc, [cellIndex]: cell }), {})
  })) || [];

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <Select
        defaultValue={curSheetIndex}
        style={{ width: 200, marginBottom: 16 }}
        onChange={handleSheetChange}
      >
        {names.map((name, index) => (
          <Option key={index} value={index}>
            {name}
          </Option>
        ))}
      </Select>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
    </div>
  );
};

export default XlxsViewer;
