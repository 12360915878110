import isHotkey from "is-hotkey";
import { KoButton, KoColorPicker, KoDropdown, KoIcon, KoInput, KoSelect, KoToast } from "packages";
import { useCallback, useMemo, useState } from "react";
import ImageResizer from 'react-image-height-width-resizer';
import {
  createEditor,
  Editor,
  Range,
  Element as SlateElement,
  Transforms
} from "slate";
import { withHistory } from "slate-history";
import { Editable, Slate, useSlate, withReact } from "slate-react";
import styles from './ac.module.scss';
import { ALIGN, HEADER, SIZE } from './data';
import { Toolbar } from "./toolbar";
import { getCookie } from '../../shared/utils/cookies';

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code"
};

const LIST_TYPES = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true
  });
  let newProperties;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format
    };
  } else {
    newProperties = {
      type: isActive ? "paragraph" : isList ? "list-item" : format
    };
  }
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor, format, blockType = "type") => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format
    })
  );

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const Element = ({ attributes, children, element }) => {
  const style = {textAlign: element.align};
  // const styleFont = {fontSize: element.size};
  
  
  switch (element.type) {
  case "block-quote":
    return (
      <blockquote className={style} {...attributes}>
        {children}
      </blockquote>
    );
  case "bulleted-list":
    return (
      <ul className={style} {...attributes}>
        {children}
      </ul>
    );
  case "h1":
    return (
      <h1 className={style} {...attributes}>
        {children}
      </h1>
    );
  case "h2":
    return (
      <h2 className={style} {...attributes}>
        {children}
      </h2>
    );
  case "h3":
    return (
      <h3 className={style} {...attributes}>
        {children}
      </h3>
    );
  case "h4":
    return (
      <h4 className={style} {...attributes}>
        {children}
      </h4>
    );
  case "h5":
    return (
      <h5 className={style} {...attributes}>
        {children}
      </h5>
    );
  case "h6":
    return (
      <h6 className={style} {...attributes}>
        {children}
      </h6>
    );
  case window.fontSize:
    return (
      <font size={window.fontSize} className={style} {...attributes}>
        {children}
      </font>
    );
  case "list-item":
    return (
      <li className={style} {...attributes}>
        {children}
      </li>
    );
  
  case "numbered-list":
    return (
      <ol className={style} {...attributes}>
        {children}
      </ol>
    );
  case "left":
    return (
      <span className={style} {...attributes}>
        {children}
      </span>
    );
  case "center":
    return (
      <span className={style} {...attributes}>
        {children}
      </span>
    );
  case "right":
    return (
      <span className={style} {...attributes}>
        {children}
      </span>
    );
  case "justify":
    return (
      <span className={style} {...attributes}>
        {children}
      </span> );
    
  case "image":
    return (
      <ImageResizer
        src={element.url}
        width={200}
        height={200}
      />
    );


  case "embed": {
    const url = element.url;
    const isImage = url.match(/\.(jpg|jpeg|png|gif|bmp|svg)$/i);
    const isVideo = url.match(/\.(mp4|webm|ogg|mov|wmv|flv|avi)$/i);
  
    if (isImage) {
      return (
        <div {...attributes} className={styles.iframeContainer}>
          <ImageResizer src={url} alt="Embedded image" className={styles.imageContainer} />
          {children}
        </div>
      );
    } else if (isVideo) {
      return (
        <div {...attributes} className={styles.iframeContainer}>
          <video controls className={styles.videoContainer}>
            <source src={url} type="video/mp4" />
            <source src={url} type="video/webm" />
            <source src={url} type="video/ogg" />
          Your browser does not support the video tag.
          </video>
          {children}
        </div>
      );
    }

    return (
      <div {...attributes} className={styles.iframeContainer}>
        <ImageResizer src={url} alt="Embedded image" className={styles.imageContainer} />
       
        <iframe
          title="Embedded content"
          src={url}
          className={styles.iframe}
          frameBorder="0"
          allowFullScreen
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        />
        {children}
      </div>
    );
  }

  default:
    return (
      <p className={style} {...attributes}>
        {children}
      </p>
    );
  }
};



const Leaf = ({ attributes, children, leaf ,element}) => {
  const style = { color: window.myTextColor };
  const bgStyle = { backgroundColor: window.myBgColor, color: '#ffff' };
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <i>{children}</i>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }
  if (leaf.TextColor) {
    children = <span className={style}>{children}</span>;
  }
  if (leaf.BgColor) {
    children = <span className={bgStyle}>{children}</span>;
  }
  if (leaf.strikethrough) {
    children = <s>{children}</s>;
  }
  if (leaf.link) {
    children = <a href={children?.props?.text?.text}>{children?.props?.text?.text}</a>;
  }

  return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, icon, disabled }) => {
  const editor = useSlate();
  return (
    <KoButton
      type="text"
      disabled={disabled}
      iconBtn={true}
      active={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
      )}
      onClick={event => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
      icon={<KoIcon name={icon} />}
    />

  );
};


const BlockTextButton = ({ format, icon, disabled }) => {
  const editor = useSlate();
  return (
    <KoSelect
      disabled={disabled}
      defaultValue="Normal text"
      onChange={opt => {
        toggleBlock(editor, opt);
      }}
      options={HEADER}
    />
  );
};


const BlockSizeButton = ({ format, icon ,size, setSize, disabled}) => {
  const editor = useSlate();
  return (
    <KoSelect
      defaultValue="13"
      disabled={disabled}
      onChange={opt => {
        setSize(opt);
        toggleBlock(editor, opt);
      }}
      options={SIZE}
    />
  );
};

const BlockAlignmentButton = ({ format, icon, disabled }) => {
  const editor = useSlate();

  return (
    <KoDropdown
      menu={{
        items: ALIGN,
        onClick: (event) => {
          toggleBlock(editor, event.key);
        }
      }}
      disabled={disabled}
      overlayClassName={styles.actionDropdown}
      placement="bottom">
      <div shape="circle">
        <KoIcon name={`LeftOutlined`}/>
      </div>
    </KoDropdown>
  );};

// const ImageUploader = ({ editor, icon}) => {
//   const handleImageUpload = useCallback((e) => {
//     const file = e.target.files[0];
//     const reader = new FileReader();
//     reader.onload = () => {
//       // var url = reader.result;
//       // insertImage(url);
      
//     };
//     reader.readAsDataURL(file);
//   }, []);
  
//   // const insertImage = (url) => {
//   //   const image = { type: 'image', url, children: [{ text: '' }] };
//   //   editor.insertNode(image);
//   // };
  
//   return (
//     <label htmlFor="imageInput"><KoIcon name={icon}/>
//       <KoInput type="file"  id="imageInput" accept="image/*" onChange={handleImageUpload} className={styles.input}/>
//     </label>
//   );
// };

// const BlockFontsButton= ({ format, icon }) => {
//   const editor = useSlate();
//   return (
//     <KoSelect
//       defaultValue="13"
//       onChange={opt => {
//         toggleBlock(editor, opt);
//       }}
//       options={FONTS}
//     />
//   );
// };

const ColorButton = ({ format, icon, disabled }) => {
  const [TextColor, setTextColor] = useState('#1677ff');
  const [currentColor, setCurrentColor] = useState();
  const editor = useSlate();

  const onChange = (color) =>{
    setCurrentColor(color.toHexString());
    setTextColor(color);
    
  };
  window.myTextColor=currentColor;
  return (
    <KoColorPicker value={TextColor} onChangeComplete={onChange}>
      <KoButton
        type="Text"
        disabled={disabled}
        iconBtn={true}
        active={isMarkActive(editor, format)}
        onClick={event => {
          event.preventDefault();
          toggleMark(editor, format);
        }}
        icon={<KoIcon name={icon} color={currentColor}/>}
      />
    </KoColorPicker>
  );
};

const BgColorButton = ({ format, icon, disabled }) => {
  const [TextBgColor, setTextBgColor] = useState('#1677ff');
  const [currentColor, setCurrentColor] = useState();
  const editor = useSlate();

  const onChange = (color) =>{
    setCurrentColor(color.toHexString());
    setTextBgColor(color);
  };
  window.myBgColor=currentColor;
  return (
    <KoColorPicker value={TextBgColor} onChangeComplete={onChange} >
      
      <KoButton
        type="Text"
        disabled={disabled}
        iconBtn={true}
        active={isMarkActive(editor, format)}
        onClick={event => {
          event.preventDefault();
          toggleMark(editor, format);
        }}
        icon={<KoIcon name={icon} color={currentColor}/>}
      />
    </KoColorPicker>
  );
};


// const LinkInsert = ({ editor, icon}) => {

//   return (
//     <KoButton
//       type="text"
//       iconBtn={true}
//       active={isMarkActive(editor)}
//       onClick={event => {
//         event.preventDefault();
//         toggleMark(editor);
//       }}
//       icon={<KoIcon name={icon} />}
//     />
//   );
// };

const MarkButton = ({ format, icon, disabled }) => {
  const editor = useSlate();
  return (
    <KoButton
      type="text"
      disabled={disabled}
      iconBtn={true}
      active={isMarkActive(editor, format)}
      onClick={event => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
      icon={<KoIcon name={icon} />}
    />
  );
};

const initial = [
 
  {
    type: "paragraph",
    children: [{ text: "" }]
  }
];


const insertEmbed = (editor, url) => {
  const urlPattern = /^(https?:\/\/[^\s]+)/;
  if (!urlPattern.test(url)) {
    return;
  }

  const embed = { type: 'embed', url, children: [{ text: '' }] };

  if (editor.selection) {
    Transforms.insertNodes(editor, embed, { at: editor.selection });
  } else {
    Transforms.insertNodes(editor, embed);
  }
};

const EmbedButton = ({ format, icon, disabled, editor, lookupId, workspaceId }) => {

  const handleEmbed = async(event) => {
    const file = event.target.files[0];

    try {
      const formData = new FormData();
      formData.append('files', file);
      formData.append('module', 'taskplanner');
      formData.append('lookupId', lookupId);
      formData.append('service', workspaceId);


      const response = await fetch(`${process.env.REACT_APP_PROXY_HOST}/api/v1/org/upload`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${getCookie('accessToken')}`
        },
        body: formData
      });

      const data = await response.json();

      if (response.ok) {
        const fileUrl = data[0];
        KoToast.success('Image added.');
        insertEmbed(editor, fileUrl);
      }

    } catch (error) {
      
    }

  };

  return (
    <label><KoIcon name={icon}/>
      <KoInput type="file" id="imageInput" accept="image/*" onChange={handleEmbed} className={styles.input}/>
      {/* <Upload {...props}  isDnd={false}/> */}
    </label>
  );
};


const KoAcceptanceCriteria = (props) => {
  const {form, name, placeholder, initialValue=initial } = props;
  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const [fontSize, setFontSize] = useState();
  window.fontSize = fontSize;
  const onChange = (value) => {
    if(form){
      let data = form?.getFieldsValue();
      data[name] = value;
      form?.setFieldsValue({...data});
    }
    
  };
  

  const handleKeyDown = (event) => {
    for (const hotkey in HOTKEYS) {
      if (isHotkey(hotkey, event)) {
        event.preventDefault();
        const mark = HOTKEYS[hotkey];
        toggleMark(editor, mark);
      }
    }

    if (event.key === 'Enter') {
      event.preventDefault();
      const { selection } = editor;
      if (selection && Range.isCollapsed(selection)) {
        Transforms.insertText(editor, '\n');
      } else {
        Transforms.splitNodes(editor);
      }
    }
  };

  const onPaste = (event) => {
    const clipboardItems = event.clipboardData.items;

    for (let i = 0; i < clipboardItems?.length; i++) {
      const item = clipboardItems[i];
    
      if (item.type.indexOf("image") !== -1) {
        const blob = item.getAsFile();
        const reader = new FileReader();

        reader.onloadend = () => {
          const base64 = reader.result;
          insertImage(editor, base64);
        };

        reader.readAsDataURL(blob);
        event.preventDefault();
      }
    }
  };

  const insertImage = (editor, imageUrl) => {
    const imageNode = {
      type: 'image',
      url: imageUrl,
      children: [{ text: '' }]
    };
  
    if (editor.selection) {
      Transforms?.insertNodes(editor, imageNode, { at: editor.selection });
    } else {
      Transforms?.insertNodes(editor, imageNode);
    }
  };



  return (
    <Slate editor={editor} initialValue={initialValue ? initialValue : initial} onChange={onChange}>
      <div className={styles.card}>
        <div className={styles.shadowBar}>
          <Toolbar className={styles.toolbar}>
            <BlockTextButton format="heading" disabled={props.readOnly? true : false}/>
            <BlockSizeButton format="size" size={fontSize} setSize={setFontSize} disabled={props.readOnly? true : false}/>
            {/* <BlockFontsButton format="font"  /> */}
            <MarkButton format="bold" icon="BoldOutlined" disabled={props.readOnly? true : false}/>
            <MarkButton format="italic" icon="ItalicOutlined" disabled={props.readOnly? true : false}/>
            <MarkButton format="underline" icon="UnderlineOutlined" disabled={props.readOnly? true : false}/>
            <MarkButton format="strikethrough" icon='StrikethroughOutlined' disabled={props.readOnly? true : false}/>
            {/* <ColorButton format="TextColor" icon='TextColorOutlined'/> disabled={props.readOnly? true : false}*/}
            <ColorButton format="TextColor" icon='TextColorOutlined'disabled={props.readOnly? true : false}/>
            <BgColorButton format="BgColor" icon='BgColorOutlined' disabled={props.readOnly? true : false}/>
            <MarkButton format="code" icon="CodeOutlined" disabled={props.readOnly? true : false}/>
            <MarkButton format="link" icon="LinkOutlined" disabled={props.readOnly? true : false}/>
            {/* <ImageUploader format="Image" icon="ImagesOutlined" editor={editor}/> */}
            {/* <FileUploader  format="Attachment" icon="AttachmentOutlined" /> */}
            <BlockButton format="block-quote" icon="DoubleQuotesOutlined" disabled={props.readOnly? true : false}/>
            <BlockButton format="numbered-list" icon="NumberedOutlined" disabled={props.readOnly? true : false}/>
            <BlockButton format="bulleted-list" icon="BulletOutlined" disabled={props.readOnly? true : false}/>
            <BlockAlignmentButton format="align" disabled={props.readOnly? true : false}/>
            {/* <BlockButton  format="Table" icon="TableOutlined" disabled={props.readOnly? true : false}/> */}
            <EmbedButton format="embed" icon="AttachmentOutlined" editor={editor} lookupId={props.lookupId} workspaceId={props.workspaceId}/>
          </Toolbar>
        </div>
        <div className={styles.wrapper}>
          {/* <ImageUploader editor={editor} /> */}

          <Editable
            readOnly={props.readOnly? true : false}
            className={styles.editableWrapper}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder={ placeholder ? placeholder : "Enter the acceptance criteria"}
            spellCheck
            onKeyDown={handleKeyDown}
            onPaste={onPaste}
            // onKeyDown={event => {
            //   for (const hotkey in HOTKEYS) {
            //     if (isHotkey(hotkey, event)) {
            //       event.preventDefault();
            //       const mark = HOTKEYS[hotkey];
            //       toggleMark(editor, mark);
            //     }
            //   }
            // }}
          />
        </div>
        
      </div>
    </Slate>
  );
};


export default KoAcceptanceCriteria;
