import React from "react";
import { Card } from "antd";
import KoIcon from "../icon";
import styles from './card.module.scss';


function KoCard(props) {
  const { className = '', children, bordered = false,
    backgroud, icon, hoverable,
    cardBg = false,
    loading = false, ...rest } = props;
  return (<Card
    className={`${styles.wraper} ${cardBg ? styles.card :''} ${hoverable ? styles.hover : ''} ${className ? className : ''}`}
    loading={loading} hoverable={hoverable} bordered={bordered} {...rest}>
    {children}
    {icon && <div className={styles.icon} style={{ background: backgroud }}>
      <KoIcon name={icon} height={14} width={14} />
    </div>}
  </Card>);
}

export default KoCard;