import React from "react";
function At(props) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
    >
      <path
        d="M13.808 2.162a10.031 10.031 0 00-4.456.208C4.014 3.85.887 9.38 2.37 14.719a9.846 9.846 0 004.15 5.665 10.22 10.22 0 005.492 1.613A9.96 9.96 0 0017 20.66a.5.5 0 10-.5-.865 9.084 9.084 0 01-9.444-.255 8.831 8.831 0 01-3.722-5.084A9.032 9.032 0 0113.63 3.146 9.153 9.153 0 0121 12.291v.209a2.5 2.5 0 11-5 0v-4a.5.5 0 00-1 0v.88A3.973 3.973 0 0012 8a4 4 0 100 8 3.99 3.99 0 003.397-1.914A3.487 3.487 0 0018.5 16a3.5 3.5 0 003.5-3.5v-.209a10.152 10.152 0 00-8.192-10.129zM12 15a3 3 0 110-6 3 3 0 010 6z"
        className="color000 svgShape"
      />
    </svg>
  );
}


export default At;