const ORG_STATISTICS = [{
  color: '--palette-gray-10',
  backgroud: '--palette-primary-darker',
  title: 'Total Users',
  icon: 'TotalUsersOutlined',
  value: 10
}, {
  color: '--palette-gray-10',
  backgroud: '--color-palette-6-dark',
  title: 'Active Users',
  icon: 'ActiveUseresOutlined',
  value: 120
}, {
  color: '--palette-gray-10',
  backgroud: '--color-palette-2-dark',
  title: 'Inactive Users',
  icon: 'InactiveUsersOutlined',
  value: 103
}, {
  color: '--palette-gray-10',
  backgroud: '--color-palette-4-dark',
  title: 'Total Groups',
  icon: 'TotalGroupsOutlined',
  value: 110
}, {
  color: '--palette-gray-10',
  backgroud: '--color-palette-5-dark',
  title: 'Invited',
  icon: 'InvitedOutlined',
  value: 10
}, {
  color: '--palette-gray-10',
  backgroud: '--color-palette-7-dark',
  title: 'Requested',
  icon: 'RequestedOutlined',
  value: 120
}];

const REQUEST_COLUMNS = [
  {
    id: "name",
    label: "Name",
    widget: "text",
    fixed: 'left',
    width:150
  }, {
    id: "requestURL",
    label: "Request URL",
    widget: "api",
    width:250
  }, {
    id: "method",
    label: "Method",
    widget: "methods",
    width:100
  },{
    id: "status",
    label: "Status",
    widget: "status",
    width:150
  },{
    id: "statusCode",
    label: "Status Code",
    width:150,
    widget: "text"
  },{
    id: "executionTime",
    label: "Execution time",
    width:150,
    widget: "time"
  }, {
    id: "createdBy",
    label: "Created By",
    widget: "profile"
  }, {
    id: "createdAt",
    label: "Created At",
    width:150,
    widget: "date"
  }, {
    id: "updatedAt",
    label: "Last Run",
    width:150,
    widget: "date"
  }];


const COLUMNS_GROUPS = [
  {
    id: "name",
    label: "Group Name",
    widget: "user",
    fixed: true
  }, {
    id: "priority",
    label: "members",
    widget: "text"
  }, {
    id: "status",
    label: "status",
    widget: "status"
  }, {
    id: "name",
    label: "Created By",
    widget: "user"
  }, {
    id: "lastSignedIn",
    label: "Created On",
    widget: "datetime"
  }];


const COLUMNS_ROLES = [
  {
    id: "roleName",
    label: "Role Name",
    widget: "text",
    fixed: true
  }, {
    id: "roleDescription",
    label: "Role Description",
    widget: "text"
  }, {
    id: "permissionName",
    label: "Permission Name",
    widget: "text"
  }, {
    id: "permissionDescription",
    label: "Permission Description",
    widget: "text"
  }, {
    id: "createdBy",
    label: "Created By",
    widget: "user"
  }, {
    id: "lastSignedIn",
    label: "Created On",
    widget: "datetime"
  }];


const COLUMNS_designATION = [
  {
    id: "name",
    label: "designation Name",
    widget: "user",
    fixed: true
  }, {
    id: "priority",
    label: "Description",
    widget: "text"
  }, {
    id: "status",
    label: "Salary Range",
    widget: "status"
  }, {
    id: "status",
    label: "Seniority Level",
    widget: "status"
  }, {
    id: "name",
    label: "Created By",
    widget: "user"
  }, {
    id: "lastSignedIn",
    label: "Created On",
    widget: "datetime"
  }];

const ROWS = [{
  id: '1',
  apiUrl: '/api/address/create',
  createdBy: 'Kamal K',
  executionTime:'500 ms',
  method: 'Post',
  roleDescription: ' simply dummy text of the printing and ',
  permissionDescription: ' simply dummy text of the printing and ',
  name: 'Create Address',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  description: `Lorem Ipsum is simply dummy text of the printing and Typesetting industry.` +
    `Lorem Ipsum has been the industry’s Standard dummy text ever since the 1500s,` +
    ` when an unknown Printer took a gallery of type and scrambled it to make a type Specimen book.` +
    `It has survived not only five centuries, but also The leap into electronic typesetting`,
  status: "Success",
  roles: 'Paradot',
  statusCode:200,
  groups: 'human resources',
  lastSignedIn: '4557890',
  issueType: 'bug',
  task: 'Android Keyboard Document',
  comments: [{ text: 1 }, { text: 2 }],
  priority: 2,
  members: [{ name: 'kamal', profilePic: 'm1' }, { name: 'suku', profilePic: 'm2' }, { name: 'john', profilePic: 'm3' }]
}, {
  id: '2',
  name: 'Update Address',
  designation: "Hr",
  mobile: "98766433456",
  apiUrl: '/api/address/update/{id}',
  createdBy: 'Kamal K',
  executionTime:'500 ms',
  method: 'Patch',
  email: "kamal@qubernet.com",
  description: "Desc Text Desc TextDesc TextDesc Text Desc TextDesc TextDesc TextDesc TextDesc Text Desc TextDesc Text",
  status: "Failed",
  roles: 'engineering',
  statusCode:404,
  groups: 'engineering',
  lastSignedIn: '4557890',
  issueType: 'task',
  comments: [{ text: 1 }],
  priority: 4,
  task: 'Android Keyboard Document',
  members: [{ name: 'kamal', profilePic: 'm1' }, { name: 'suku', profilePic: 'm2' }, { name: 'john', profilePic: 'm3' }]
}, {
  id: '3',
  name: 'Get Address',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  description: "Desc Text Desc TextDesc TextDesc Text Desc TextDesc TextDesc TextDesc TextDesc Text Desc TextDesc Text",
  status: "Success",
  statusCode:200,
  apiUrl: '/api/getaddress/{id}',
  createdBy: 'Kamal K',
  executionTime:'500 ms',
  method: 'Post',
  roles: 'Devops',
  groups: 'Devops',
  lastSignedIn: '4557890',
  issueType: 'improvement',
  task: 'Android Keyboard Document',
  priority: 3,
  comments: [{ text: 1 }, { text: 2 }, { text: 3 }],
  members: [{ name: 'kamal', profilePic: 'm1' }, { name: 'suku', profilePic: 'm2' }, { name: 'john', profilePic: 'm3' }]
}, {
  id: '4',
  name: 'Update Bulk address',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  description: "Desc Text Desc TextDesc TextDesc Text Desc TextDesc TextDesc TextDesc TextDesc Text Desc TextDesc Text",
  status: "Canceled",
  roles: 'Finance',
  groups: 'Finance',
  apiUrl: '/api/address/update',
  createdBy: 'Kamal K',
  executionTime:'500 ms',
  method: 'Put',
  statusCode:401,
  lastSignedIn: '4557890',
  issueType: 'improvement',
  priority: 1,
  task: 'Android Keyboard Document',
  comments: [],
  members: [{ name: 'kamal', profilePic: 'm1' }, { name: 'suku', profilePic: 'm2' }, { name: 'john', profilePic: 'm3' }]
}, {
  id: '5',
  name: 'Delete address',
  designation: "Hr",
  mobile: "98766433456",
  email: "kamal@qubernet.com",
  description: "Desc Text Desc TextDesc TextDesc Text Desc TextDesc TextDesc TextDesc TextDesc Text Desc TextDesc Text",
  status: "Blocked",
  roles: 'Paradot',
  apiUrl: '/api/address/delete/{id}',
  createdBy: 'Kamal K',
  executionTime:'500 ms',
  method: 'Delete',
  groups: 'Marketing',
  lastSignedIn: '4557890',
  issueType: 'improvement',
  priority: 5,
  task: 'Android Keyboard Document',
  comments: [],
  statusCode:501,
  members: []
}];

const USERINFO = [{
  profilePic: '',
  email: 'hema@gmail.com',
  phone: '',
  designation: '',
  Password: '',
  isVerified: false
}, {
  profilePic: '',
  email: 'kamal@gmail.com',
  phone: '',
  designation: '',
  Password: '',
  isVerified: false
}];

const COLUMNS_GROUPS_MEMBERS = [
  {
    id: "name",
    label: "Name",
    widget: "user",
    fixed: true
  }, {
    id: "roles",
    label: "Roles",
    widget: "text"
  }];

const COLUMNS_ROLES_PERMISSION = [{
  id: "name",
  label: "Module",
  widget: "text",
  width: 100,
  fixed: true
}, {
  id: "roles",
  label: "Read",
  widget: "checkbox",
  width: 100
}, {
  id: "roles",
  label: "Write",
  widget: "checkbox",
  width: 100
}, {
  id: "roles",
  label: "Delete",
  widget: "checkbox",
  width: 100
}];

const DOC_LIST = [{
  name: 'qubernet, inc.',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint:2,
  id: 'Pro_121212',
  tags: ['Development', 'design', 'IT', 'Finance', 'Devops', 'Demo']
}, {
  name: 'Kwapio, inc.',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint:5,
  id: 'Pro_1212122',
  tags: ['Development', 'Demo']
}, {
  name: 'tonelix',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  id: 'Pro_1212123',
  sprint:7,
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint:5,
  id: 'Pro_12121234',
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint:6,
  id: 'Pro_12121235',
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint:4,
  id: 'Pro_12121236',
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  sprint:9,
  progress: 80,
  id: 'Pro_121212',
  tags: ['Development', 'design', 'IT', 'Finance', 'Devops', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  progress: 80,
  sprint:10,
  id: 'Pro_1212122',
  tags: ['Development', 'Demo']
}, {
  name: 'Batch Email Management for Projects and Rockshell application',
  description: 'Batch Email Management for Projects and Rockshell application',
  cout: 12,
  dueDate: '20 JUNE',
  createdBy: 'Edward Norton',
  Issues: '21',
  sprint:7,
  progress: 80,
  id: 'Pro_1212123',
  tags: ['Development', 'Demo']
}];

export {
  ORG_STATISTICS, REQUEST_COLUMNS, COLUMNS_GROUPS_MEMBERS, COLUMNS_GROUPS, COLUMNS_ROLES_PERMISSION,
  COLUMNS_ROLES, DOC_LIST, COLUMNS_designATION, ROWS, USERINFO
};