import React from "react";
function Arrow(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 6L13 6M13 6L8 1M13 6L8 11" stroke="#0050FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    

  );
}


export default Arrow;