import React from 'react';
import { Drawer } from 'antd';
import styles from './drawer.module.scss';
import {KoButton, KoIcon, KoTitle} from '../index';
function KoDrawer(props) {

  return (
    <div className={`${styles.wrapper}`}>
      <Drawer
        title={<KoTitle text={props.title} level={3}/>}
        placement={props.placement}
        className={props.className ? 'drawerWrapper' : ''}
        closable={props.closable}
        closeIcon={<KoIcon name="CloseOutlined" height={10} width={10}/>}
        onClose={props.onClose}
        destroyOnClose={true}
        {...props}
        height={props.height}
        width={props.width}
        getContainer={props.getContainer}
        style={props.mobile}
        footer={props.footer ? <div className={styles.footer}>{props.footer?.map((btn) =>
          <KoButton key={btn?.label} label={btn?.label} type={btn?.type} onClick={btn?.onClick} />
        )}</div> : false}>
        {props.children}
      </Drawer>
    </div>
  );
}


export default KoDrawer;