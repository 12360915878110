import KoButton from 'packages/button';
import KoTable from 'packages/datatable';
import KoIcon from 'packages/icon';
import KoModal from 'packages/modal';
import KoPagination from 'packages/pagination';
import KoTooltips from 'packages/tooltips';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { getCookie } from 'shared/utils/cookies';
import BulkCrud from './bulkUpdate';
import KoTicketTable_COLUMNS from './constant';
import KoToolBar from './toolbar';
import { getTicketBreadCrumb } from 'shared/commonConstants';
import { KoText, KoTitle } from 'packages';
import { useDispatch } from 'react-redux';
import { setSelectedChatData, setSelectedChatType } from 'shared/store/chatSlice';
const KoTicketTable = (props) => {

  const { tickets,maxHeight,workspaceId, form, pagination, onPagination,
    totalCount, setModal, onSearchText, setDueModal, extraColumnsRight,
    profile, title, onChangeStatus, onChangeType, isLoading,
    currentRecord, setCurrentRecord, onChangePriority, setBreadCrumbs,
    onChangeAssignees, API, getTicketsList, setLoading, GetChild,
    columns, onSort, createBtn = true,
    getIssueById, onExtraClick, onCellClickExternal,
    isExport,
    isImport,
    RowSelectionOption: {
      visible: rowSelectionVisible
    },
    Bulk: {
      visible: bulkVisible
    },
    dueDate = {
      visible: false
    }
  } = props;

  
  const [copy, setCopy] = useState(false);
  const [selectedTickets, setSelectedTickets] = useState();
  const [isBulkModal, setBulkModal] = useState(false);
  const [isOpenBulkConfirm, setBulkConfirmModal] = useState(false);
  const dispatch = useDispatch();


  const OnRowSelection=(ticketId)=>{
    setSelectedTickets(ticketId);
  };

  
  const bulkUpdate =(values)=>{
    values['ticketId'] = selectedTickets;
    setLoading(true);
    API.BulkUpdateIssue(values, workspaceId).then(
      (data) => {
        setLoading(false);
        setBulkModal(false);
        setBulkConfirmModal(false);
        getTicketsList();
      },
      (err) => {
        setLoading(false);
      }
      
    );
  };

  const onCopyTicket=(row)=>{
    setCopy(true);
    navigator.clipboard.writeText(`${window.location.origin}/open/ticket/${getCookie("accountId")}/${row?.workspace}/${row?.ticketId}`);
  };

  const columnsRight = [{
	  title: '',
	  dataIndex: 'x',
	  key: 'x',
	  fixed: 'right',
	  width: 40,
	  render: (text, row) => {
	    return <div className={` tableBtn columnsRight`} onClick={() => setCurrentRecord(row)}>
        <KoTooltips title={copy ? 'Copied' : 'Copy'}>
	      <KoButton onClick={()=>onCopyTicket(row)} className={'btn'} iconBtn={true} icon={<KoIcon width={14} height={14} name={'CopyOutlined'}/>}/>
        </KoTooltips>
        <KoTooltips title={'New window'}>
          <Link target="_blank" to={`/open/ticket/${getCookie("accountId")}/${row?.workspace}/${row?.ticketId}`}>
            <KoButton className={'btn'}  iconBtn={true} icon={<KoIcon width={14} height={14} name={'OpenOutlined'}/>}/>
          </Link>
        </KoTooltips>
	    </div>;
	  }
  }];

  const onCellClick = (row, action) => {
    const updatedRoomObj = { ...row, source: 'tickets' };
    dispatch(setSelectedChatData(updatedRoomObj));
    dispatch(setSelectedChatType('tickets'));
    if (setBreadCrumbs) {
      let crumbs = getTicketBreadCrumb(updatedRoomObj);
      setBreadCrumbs(crumbs);
    }
    if (GetChild) {
      setCurrentRecord(updatedRoomObj);
      GetChild(updatedRoomObj);
    } else {
      getIssueById(updatedRoomObj?.ticketId);
      setModal(true);
    }
  };

  const handleExport = () => {
    const headers = Object.keys(tickets[0]).join(",");

    const csvRows = tickets.map(row => {
      return Object.values(row).map(value => {
        if (Array.isArray(value)) return `"${value.join(", ")}"`;
        if (value === null) return "";
        if (typeof value === 'object') return `"${JSON.stringify(value)}"`;
        if (value === "0001-01-01T00:00:00Z") return "";
        return `"${value}"`;
      }).join(",");
    });

    const csvData = `${headers}\n${csvRows.join("\n")}`;
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "data.csv";
    link.click();
  };


  return (
    <>
      <KoToolBar
        title={title}
        onChangeStatus={onChangeStatus}
        onChangeType={onChangeType}
        onChangePriority={onChangePriority}
        onChangeAssignees={onChangeAssignees}
        isImport={isImport}
        isExport={isExport}
        handleExport={handleExport}
        Bulk={{
          visible: bulkVisible ? bulkVisible : false,
          open: isBulkModal,
          setBulkModal: setBulkModal,
          setBulkConfirmModal: setBulkConfirmModal,
          handleOk: () => {setBulkModal(!isBulkModal); form?.resetFields();},
          handleCancel: () => {setBulkModal(!isBulkModal); form?.resetFields();},
          form: form,
          onSubmit: (values) => {bulkUpdate(values);},
          onCancel: () => {setBulkModal(!isBulkModal); form?.resetFields();},
          selectedTickets: selectedTickets
        }}
        profile={profile}
        search={{ visible: true, onChange: onSearchText, placeholder: 'Ex: Summary / Ticket Id' }}
        filter={
          { visible: false,
            label:'Status',
            options: [{ label: 'Sort By Name', key: '0' }, { label: 'Sort By Roles', key: '1' }, { label: 'Created At', key: '2' }, { label: 'Sort By designation', key: '3' }] }}
        buttonList={[
          ...(dueDate?.visible ? [{
            label: 'Overdue',
            type: 'primary',
            color: "danger",
            variant: "filled",
            onClick: () => { setDueModal(true); setBreadCrumbs(); }
          }] : []),
          ...(createBtn ? [{
            label: 'Create',
            type: 'primary',
            icon: <KoIcon color="#FFF" name="PlusOutlined" width={18} height={18} />,
            onClick: () => setModal(true)
          }] : [])
        ]}

      />

      <KoTable rowKey='ticketId'
        maxHeight={maxHeight ? maxHeight : 100}
        onSort={onSort}
        onExtraClick={onExtraClick && onExtraClick}
        onRowSelection={rowSelectionVisible && OnRowSelection}
        onCellClick={onCellClickExternal? onCellClickExternal : onCellClick}
        data={{ columns:columns ? columns : KoTicketTable_COLUMNS, rows: tickets }}
        extraColumnsRight={extraColumnsRight? extraColumnsRight : columnsRight}
      />
      
      {
        pagination && <KoPagination current={parseInt(pagination.page) || 1}
          total={totalCount}
          showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} records`
          }
          pageSize={pagination.limit}
          defaultCurrent={pagination.page}
          onChange={onPagination}
          showQuickJumper={false}
          simple={false}
          responsive={false} />
      }


      <KoModal
        title={`Bulk Update`}
        centered
        open={isBulkModal}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setBulkModal(false); form?.resetFields();}}
        onClose={() => { setCurrentRecord(); setBulkModal(false); form?.resetFields();}}
        width={780}
        // maskClosable={false}
        height={650}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => {setBulkModal(!isBulkModal); form?.resetFields();}} />,
          <KoButton key='update' loading={isLoading} type='primary' label={ `Update`}
            onClick={() => setBulkConfirmModal(true)} />
        ]}
      >
        <BulkCrud initialValues={currentRecord} form={form} setBulkConfirmModal={setBulkConfirmModal} bulkUpdate={bulkUpdate} profile={profile} API={API} />
      </KoModal>

      <KoModal
        title={`Confirmation`}
        centered
        open={isOpenBulkConfirm}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setBulkConfirmModal(false); }}
        onClose={() => { setCurrentRecord(); setBulkConfirmModal(false); }}
        width={580}
      // maskClosable={false}
        height={450}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setBulkConfirmModal(!isOpenBulkConfirm)} />,
          <KoButton key='proceed' loading={isLoading} type='primary' label='Proceed' onClick={() => form.submit()} />
        ]}
      >
        <div>
          <KoTitle level={5} text={`Are you sure you want to proceed with this bulk update?`} />
          <KoText text={`This action will apply changes to multiple users and may affect their data and associated permissions. Please review carefully before proceeding.`} />
        </div>
      </KoModal>
    </>
  );
};

export default KoTicketTable;


