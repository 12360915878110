import React from "react";
function Text(props) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
    >
      <path d="M21 11.44h-6a.5.5 0 000 1h2.5v5.5a.5.5 0 001 0v-5.5H21a.5.5 0 000-1zM16 5.5a.5.5 0 00-.5-.5h-13a.5.5 0 000 1h6v12.5a.5.5 0 001 0V6h6a.5.5 0 00.5-.5z" />
    </svg>
  );
}
export default Text;