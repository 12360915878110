import React, { useState, useEffect } from 'react';
import Driver from 'packages/drivers';
import styles from './preview.module.scss';
import KoButton from 'packages/button';
import KoIcon from 'packages/icon';


const KoPreview = ({data, active}) => {

  const [activeIndex, setActiveIndex] = useState(active ? active : 0);

  useEffect(() => {
    setActiveIndex(active ? active : 0);
  }, [active]);
  
  // Get the current active file
  const activeFile = data[activeIndex];

  // Handle next and previous buttons for carousel
  const handleNext = () => {
    setActiveIndex((prevIndex) =>
      prevIndex + 1 >= data.length ? 0 : prevIndex + 1
    );
  };

  const handlePrev = () => {
    setActiveIndex((prevIndex) =>
      prevIndex - 1 < 0 ? data.length - 1 : prevIndex - 1
    );
  };

  // Handle thumbnail click
  const handleThumbnailClick = (index) => {
    setActiveIndex(index);
  };

  const removePrefix = (type, prefix) => {
    return type?.startsWith(prefix) ? type?.substring(prefix.length) : type;
  };

  return (
    <div className={styles.carousel}>
      <div className={styles.carouselContent}>
        <KoButton className={`${styles.carouselBtn} ${styles.prev}`} onClick={handlePrev} iconBtn={true} icon={<KoIcon name='ArrowLeftOutlined' />} />
        <Driver action={false} fileType={removePrefix(activeFile?.type, 'image/')} filePath={activeFile?.url} />
        <KoButton className={`${styles.carouselBtn} ${styles.next}`} onClick={handleNext} iconBtn={true} icon={<KoIcon name='ArrowRightOutlined' />} />
      </div>
      <div className={styles.thumbnailContainer}>
        {data?.map((file, index) => (
          <div
            key={index}
            onClick={() => handleThumbnailClick(index)}
            className={`${styles.thumbnail} ${index === activeIndex ? styles.active : ''}`}>
            <Driver action={false} fileType={removePrefix(activeFile?.type, 'image/')} filePath={file?.url} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default KoPreview;
