import React, { useCallback } from 'react';
import { KoFormBuilder, KoForm, KoSelect, KoAvatar } from 'packages';
import { useSelector } from 'react-redux';

function FileShareCrud({ form, handleFinish, initialValues }) {
  const users = useSelector(state => state?.users);
  const groups = useSelector(state => state?.groups);
  
  const handleSubmit = useCallback(values => {
    handleFinish(values);
  });

  const Receivers = ({ value, onChange }) =>
    <KoSelect mode="multiple" value={value}
      onChange={v => onChange(v)}
      showSearch
      placeholder='Select assignee'
      options={users['data']?.map((user) => ({
        label: (
          <>
            <KoAvatar inLine={'0rem'} size={20} options={[{ name: user?.profile?.firstName, logo: user?.profile?.logo }]} /> {user.profile?.firstName +' '+ user.profile?.lastName}
          </>
        ),
        value: user?.userId
      }))}
    />;

  const Groups = ({ value, onChange }) =>
    <KoSelect mode="multiple" value={value}
      showSearch
      onChange={v => { onChange(v); }}
      placeholder='Select Group'
      options={groups['data']?.map((group) => ({
        label: group?.name,
        value: group?.groupId
      }))}
    />;

  
  const meta = {
    columns: 4,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      {
        key: 'users', label: 'Receiver', colSpan: 2,
        widget: Receivers,
        forwardRef: true
      },
      {
        key: 'groups', label: 'Groups', colSpan: 2,
        widget: Groups,
        forwardRef: true
      }
    ]
  };



  return (
    <div >
      <div >
        <KoForm form={form} layout="vertical" onFinish={handleSubmit}
          onSubmit={handleSubmit}>
          <KoFormBuilder form={form} meta={meta} />
        </KoForm>
      </div>
    </div>
  );
};

export default FileShareCrud;