import React from 'react';
import _find from 'lodash/find';
import KoButton from 'packages/button';
import { KoForm } from 'packages/form';
import KoIcon from 'packages/icon';
import KoModal from 'packages/modal';
import KoPreview from 'packages/preview';
import KoPriority from 'packages/priority';
import { KoDescription } from 'packages/typography';
import TaskCrud from 'pages/taskPlanner/task/crud';
import { useState } from 'react';
import { getTicketBreadCrumb, ISSUETYPE } from 'shared/commonConstants';
import { useDispatch } from 'react-redux';
import KoBreadcrumb from 'packages/breadcrumb';
import styles from './ticektCard.module.scss';
import { KoStatus, KoToast, KoLoader } from 'packages';
import { TaskPlannerAPI } from 'pages/taskPlanner/taskPlanner.service';
import { setSelectedChatData, setSelectedChatType } from 'shared/store/chatSlice';

export default function TicektCard({card}) {
  const dispatch = useDispatch();
  const [isTicketOpen, setTicketModal] = useState(false);
  const [isPreviewOpen, setPreviewModal] = useState(false);
  const [isCommentsOpen, setCommentsModal] = useState(false);
  const [breadCrumbs, setBreadCrumbs] = useState();
  const [isLoading, setLoading] = useState(false);
  const [form] = KoForm.useForm();
  let issueType = _find(ISSUETYPE, function(o) { return o.value === card?.issueType; }) || {};


  const onCellClick=(row)=>{
    const updatedRoomObj = { ...row, source: 'tickets' };
    dispatch(setSelectedChatData(updatedRoomObj));
    dispatch(setSelectedChatType('tickets'));
    let crums = getTicketBreadCrumb(row);
    setBreadCrumbs(crums);
    setTicketModal(true);
  };

  const handleFinish = (values => {
    setLoading(true);
    TaskPlannerAPI.createOrUpdateIssues(values, card?.ticketId,card?.workspace).then(
      (data) => {
        setLoading(false);
      
        form.resetFields();
        KoToast.success(`Ticket ${card?.ticketId ? 'updated':'created'} successfully`);
       
      },
      (err) => {
        setLoading(false);
      }
    );
  });


  return (
    <>
      {isLoading && <KoLoader/>}
   
      <div className={`${styles.card}`}>
     
        <div className={styles.cardTitle}>
          <div className={styles.cardPriority}>
            { card?.priority ? <div className={`${styles.priority} ${styles['priority'+card?.priority]}`}>
              <KoPriority size={'small'} value={card?.priority}/>
            </div> : ''}
            <KoStatus data={card?.status} row={card}/>
          </div>
          <KoDescription className={styles.summary}  text={card.summary}
            ellipsis={{
              rows:2
            }}
          />
          <div className={styles.contentTags}>
            <div className={styles.contentTagsInfo}  onClick={() => onCellClick(card)}>
              <KoButton variant="outlined" icon={issueType?.icon} className={`${styles.btn}`} label={card?.ticketId} type="text"/>
            </div>
            <div className={styles.contentTagsFile}>
              { card?.subTask && <KoButton iconBtn={true} type="text" className={`${styles.btn}`}  icon={<KoIcon width={11} height={11} name='TreeOutlined'/>}/>}
            </div>
          </div>
          {/* {allowRemoveCard && (
          <span style={{ cursor: 'pointer' }} onClick={() => onCardRemove(card)}>
              ×
          </span>
        )} */}
        </div>
        {/* <KoDescription text={card.description} className={styles.cardDescription}/> */}
        <KoModal
          title={breadCrumbs? <KoBreadcrumb items={breadCrumbs} /> : ''}
          centered
          open={isTicketOpen}
          handleOk={() => { setTicketModal(false); }}
          onClose={() => { setTicketModal(false); }}
          width={'95vw'}
          height={'95vh'}
          footer={[
            <KoButton key='cancel' label='Cancel' onClick={() => setTicketModal(!isTicketOpen)} />,
            <KoButton key='create'  type='primary' label={`Update`}
              onClick={() => form.submit()} />
          ]}
        >
          <TaskCrud form={form}handleFinish={handleFinish} initialValues={card}/>
        </KoModal>

        <KoModal
          title={`Preview Attachment`}
          centered
          open={isPreviewOpen}
          handleOk={() => { setPreviewModal(false); }}
          onClose={() => { setPreviewModal(false); }}
          width={'45vw'}
          // maskClosable={false}
          height={650}
          footer={[
            <KoButton key='cancel' label='Close' onClick={() => setPreviewModal(!isPreviewOpen)} />
            // <KoButton key='create'  type='primary' label={`Update`}
            //   onClick={() => form.submit()} />
          ]}
        >
          <KoPreview/>
        </KoModal>

        <KoModal
          title={`Comments`}
          centered
          open={isCommentsOpen}
          handleOk={() => { setCommentsModal(false); }}
          onClose={() => { setCommentsModal(false); }}
          width={'45vw'}
          // maskClosable={false}
          height={650}
          footer={[
            <KoButton key='cancel' label='Close' onClick={() => setCommentsModal(!isCommentsOpen)} />
            // <KoButton key='create'  type='primary' label={`Update`}
            //   onClick={() => form.submit()} />
          ]}
        >
          <KoPreview/>
        </KoModal>


      </div>
    </>
  );
}
