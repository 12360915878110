import React from 'react';
import {KoForm, KoFormBuilder, KoSelect } from 'packages';
import styles from './curd.module.scss';

export default function SendCurd({form, initialValues}) {

  const Email = ({ value, onChange }) => (
    <KoSelect
      value={value}
      showSearch
      onChange={v => onChange(v)}
      mode="tags"
      tokenSeparators={[',']}
      placeholder='Enter Email'
    />
  );
 
  const cusMeta = {
    columns: 4,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      {
        key: 'customerApprover', placeholder: 'Enter customer Email', label: 'Customer Email', colSpan: 4, widget: Email
      },
     
      {
        key: 'comments', label: 'comments', colSpan: 4, widget: 'textarea'
      }
      
    ]
  };


  return (
    <div className={styles.schedule}>
      <div className={styles.recurring}>
        <KoForm layout="vertical" form={form} >
          <KoFormBuilder form={form}  meta={cusMeta} />
        </KoForm>
      </div>
    </div>
  );
}
