import React from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(customParseFormat);

function KoDate(props) {
  const { disabledDate, format, showTime, onChange, value, picker, disabled } = props;

  const dateValue = value && value !== "0001-01-01T00:00:00Z" ? dayjs(value) : null;

  const handleChange = (date) => {
    if (onChange) {
      const utcDate = date ? dayjs(date).utc().format() : null;
      onChange(utcDate);
    }
  };

  return (
    <DatePicker
      format={format || "DD-MMM-YYYY"}
      disabledDate={disabledDate || null}
      showTime={showTime || false}
      onChange={handleChange}
      value={dateValue}
      picker={picker || "date"}
      disabled={disabled || false}
    />
  );
}

export default KoDate;
