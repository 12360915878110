import _find from 'lodash/find';
import { KoDate, KoDescriptionEditor, KoForm, KoFormBuilder, KoSelect, KoText } from 'packages';
import { useCallback, useEffect, useState } from 'react';

import { Tooltip } from 'antd';
import { TaskPlannerAPI } from 'pages/taskPlanner/taskPlanner.service';
import { ISSUETYPE, TIMESPEND } from 'shared/commonConstants';
import styles from './timesheet.module.scss';


function TimeSheetCurd({ form, handleFinish, initialValues, setModal, workspace  }) {
  console.log('workspace',initialValues?.workspace);
  const [tickets, setTickets] = useState();
  const projectId = KoForm.useWatch('project', form);
  console.log('initialValues',initialValues);
  useEffect(()=>{
    form.setFieldsValue({
      project: projectId || initialValues?.workspace
    });
    (projectId || initialValues?.workspace) && getMyTickets(projectId || initialValues?.workspace);
  }, [projectId, initialValues?.workspace]);

  const getMyTickets=(workspaceId)=>{
    setTickets();
    TaskPlannerAPI.getIssues({}, workspaceId).then(
      (data) => {
        setTickets(data?.tickets);
      },
      (err) => {
      }
    );
  };

  const MyTickets = ({ value, onChange }) => {
    


    const options = tickets?.map((ticket) => {
      const issueType = _find(ISSUETYPE, (o) => o.value === ticket.issueType);

      return {
        label: (
          <div className={styles.TicketIdWrapper} aria-label={ticket?.ticketId}>
            {issueType.icon}
            <KoText className={styles.text} text={`${ticket?.ticketId} -`} />
            <KoText ellipsis={true} className={styles.text} text={ticket?.summary} />
          </div>
        ),
        value: ticket?.ticketId,
        title: ticket?.summary
      };
    });
    
    return (
      <KoSelect
        value={value}
        onChange={(v) => onChange(v) }
        showSearch
        disabled={!projectId}
        mode="multiple"
        
        maxTagPlaceholder={(omittedValues) => (
         
          <Tooltip
            overlayStyle={{
              pointerEvents: 'none'
            }}
            title={omittedValues.map(({ title, value }) => value + '-' + title).join(', ')}
          >
            <span>{`${omittedValues?.length} tasks added`}</span>
          </Tooltip>
        )}
        placeholder="Select task Id"
        options={options}
      />
    );
  };


  const handleSubmit = useCallback(values => {
    handleFinish(values);
  });

  const ProjectList = ({ value, onChange }) =>

    <KoSelect  value={value}
      showSearch
      disabled
      onChange={v => { onChange(v); }}
      placeholder='Select Project'
      options={workspace['data']?.map((project) => ({
        label: project.name,
        value: project?.workspaceId
      }))}
    />;

  const Date = ({ value, onChange }) =>
    <KoDate value={value} dateFormat = {'DD-MMM-YYYY'}
      onChange={v => onChange(v)} />;

  // const Time = ({ value, onChange }) => {
  //   const handleChange = (v) => {
  //     const formattedValue = v instanceof Date ? v.toTimeString().slice(0, 5) : v;
  //     onChange(formattedValue);
  //   };

  //   const formattedValue = value ? (value instanceof Date ? value.toTimeString().slice(0, 5) : value) : '';

  //   return (
  //     <KoTimePicker
  //       changeOnScroll
  //       needConfirm={false}
  //       allowClear={false}
  //       value={formattedValue}
  //       format='HH:mm'
  //       onChange={handleChange}
  //     />
  //   );
  // };

  const Description = ({ value, onChange }) => {
    return (
      <KoDescriptionEditor
        value={value}
        form={form}
        initialValue={initialValues?.description}
        onChange={(e) => onChange(e.target.value)}
      />
    );
  };

  const TimeSpentSelect = ({ value, onChange }) => {
    return (
      <KoSelect
        value={value || undefined}
        placeholder="Ex: 1w 3d 6h 20m"
        showSearch
        onChange={(v) => onChange(v)}
        options={TIMESPEND}     />
    );
  };

  const meta = {
    columns: 6,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      {
        key: 'date', placeholder: 'Choose Date', label: 'Date', colSpan: 3, widget: Date, rules: [
          {
            required: true,
            message: `Choose Date.`
          }
        ]
      },
      {
        key: 'project', label: 'Project Chosen', colSpan: 3,widget: ProjectList
      },
      {
        key: 'timeSpent', label: 'Time spent', colSpan: 3, placeholder: 'Ex: 1w 3d 6h 20m',  widget: TimeSpentSelect,
        rules: [
          {
            required: true,
            message: `Please enter time spent.`
          }
        ]
      },
      {
        key: 'timeRemaining', label: 'Time remaining', colSpan: 3, placeholder: 'Ex: 1w 3d 6h 20m',  widget: TimeSpentSelect
      },
      {
        key: '', colSpan: 6,
        render() {
          return (
            <div className={styles.timeFormatInfo}>
              {/* <span>Use this format: Ex: 1w 3d 6h 20m</span>
              <ul className={styles.timeInfoList}>
                <li><strong>w</strong> = week</li>
                <li><strong>d</strong> = days</li>
                <li><strong>h</strong> = hours</li>
                <li><strong>m</strong> = minutes</li>
              </ul> */}
            </div>
          );
        }
      },
      // {
      //   key: 'workTime', placeholder: '0:00', label: 'Time of hours', colSpan: 2,widget: Time,
      //   rules: [
      //     {
      //       required: true,
      //       message: `Enter Time of hours.`
      //     }
      //   ]
      // },
      {
        key: 'task', label: 'Tasks', colSpan: 6, widget: MyTickets
      },
      { key: 'description', placeholder: 'Enter Instruction / Description/ Notes', label: 'Instruction / Description/ Notes', colSpan: 6,
        widget: Description
      }
    ]
  };


  return (
    <div className={styles.timesheetCrudwrapper}>
      <KoForm form={form} layout="vertical" onFinish={handleSubmit} onSubmit={handleSubmit} >
        <KoFormBuilder form={form} meta={meta} />
      </KoForm>
    </div>
  );
};

export default TimeSheetCurd;