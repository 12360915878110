/* eslint-disable no-undef */
import React, { Component } from 'react';

import Error from './error';
import Loading from './loading';

function withFetching(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = { data: null, error: null };
      this.xhr = this.createRequest(props.filePath);
    }

    componentDidMount() {
      try {
        this.fetch();
      } catch (e) {
        if (this.props.onError) {
          this.props.onError(e);
        }
        this.setState({ error: 'Fetch error' });
      }
    }

    componentWillUnmount() {
      this.abort();
    }

    createRequest(path) {
      let xhr = new XMLHttpRequest();

      if ('withCredentials' in xhr) {
        // XHR for modern browsers.
        xhr.open('GET', path, true);
      } else if (typeof XDomainRequest !== 'undefined') {
        // XDomainRequest for IE.
        xhr = new XDomainRequest();
        xhr.open('GET', path);
      } else {
        // CORS not supported.
        return null;
      }

      const { responseType } = this.props;
      if (responseType) {
        xhr.responseType = responseType;
      }

      xhr.onload = () => {
        if (xhr.status >= 400) {
          this.setState({ error: `Fetch error with status ${xhr.status}` });
          return;
        }
        const resp = responseType ? xhr.response : xhr.responseText;
        this.setState({ data: resp });
      };

      xhr.onerror = () => {
        this.setState({ error: 'Fetch error' });
      };

      return xhr;
    }

    fetch() {
      if (this.xhr) {
        this.xhr.send();
      }
    }

    abort() {
      if (this.xhr) {
        this.xhr.abort();
      }
    }

    render() {
      const { error, data } = this.state;

      if (!this.xhr) {
        return <h1>CORS not supported</h1>;
      }

      if (error) {
        return <Error {...this.props} error={error} />;
      }

      if (data) {
        return <WrappedComponent data={data} {...this.props} />;
      }

      return <Loading />;
    }
  };
}

export default withFetching;
