import React, { useCallback, useState } from 'react';
import styles from './deployment.module.scss';
import { KoAvatar, KoForm, KoFormBuilder, KoSelect, KoText } from 'packages';
import { useSelector } from 'react-redux';
import { SERVICESTATUS } from './constant';

export default function ServiceCurd({ form, handleFinish, initialValues }) {
  const [buildType] = useState([{ name: 'Automatic', id: 'automatic' }, { name: 'Manual', id: 'manual' }]);
  const users = useSelector(state => state.users);
  const handleSubmit = useCallback(values => {
    handleFinish(values);
    form.resetFields();
  });

  const builds = ({ value, onChange }) =>
    <KoSelect value={value}
      showSearch
      onChange={v => { onChange(v); }}
      placeholder='Select Branch'
      options={buildType?.map((build) => ({
        label: build?.name,
        value: build?.id
      }))}
    />;

  const Approvers = ({ value, onChange }) =>
    <KoSelect mode="multiple" value={value}
      onChange={v => onChange(v)}
      showSearch
      placeholder='Select Approvers'
      options={users['data']?.map((user) => ({
        label: (
          <>
            <KoAvatar inLine={'0rem'} size={20} options={[{ name: user?.profile?.firstName, logo: user?.profile?.logo }]} /> {user.profile?.firstName +' '+ user.profile?.lastName}
          </>
        ),
        value: user?.userId
      }))}
    />;

  const Status = ({ value, onChange }) =>

    <KoSelect value={value}
      showSearch
      defaultValue={SERVICESTATUS[0].value}
      onChange={v => { onChange(v); }}
      placeholder='Select status'
      options={SERVICESTATUS}
      optionRender={(option) => (
        <div className={styles.statusWrapper}>
          <div className={`${styles.status} ${styles[option?.data?.label?.replace(" ", '')]}`} role="img" aria-label={option.data.label}/>
          <KoText text= {option.data.label}/>
        </div>
      )}
    />;

  const meta = {
    columns: 4,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      {
        key: 'serviceName', placeholder: 'Enter service name', label: 'Name', colSpan: 2, rules: [
          {
            required: true,
            message: `Enter service name.`
          },{
            pattern: new RegExp(/^[a-zA-Z]{1,}$/),
            message: `Enter valid service name.`
          }
        ]
      },
      {
        key: 'buildType', label: 'Build Type', colSpan: 2,
        widget: builds,
        rules: [
          {
            required: true,
            message: `Select build Type.`
          }
        ]
      },
      {
        key: 'addApprovers', label: 'Approvers', colSpan: 2,
        widget: Approvers,
        forwardRef: true
      },
      {
        key: 'status', label: 'Status', colSpan: 2,
        widget: Status,
        forwardRef: true
      }
      
    ]
  };
  return (
    <div className={styles.formWrapper}>
      <KoForm form={form} layout="vertical" onFinish={handleSubmit} onSubmit={handleSubmit}>
        <KoFormBuilder form={form} meta={meta} />
      </KoForm>
    </div>
  );
}
