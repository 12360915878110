import React from "react";
import { Divider } from 'antd';

function KoDivider(props) {
  return (
    <Divider
      {...props}
    />
  );
}

export default KoDivider;