import React from "react";
function PlusFilled(props) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      width={width}
      fill="var(--palette-primary-darker)"
      height={height}
    >
      <path
        stroke="var(--palette-primary-darker)"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={3}
        d="M50.002 10C27.908 10 10 27.909 10 50c0 22.093 17.908 40 40.002 40S90 72.094 90 50c0-22.091-17.904-40-39.998-40zm22.5 45H55.001v17.502H44.999V55H27.5V45h17.499V27.5h10.002V45h17.501v10z"
      />
    </svg>
  );
}

export default PlusFilled;
