import React from "react";
import styles from './task.module.scss';

function SalaryRange(props) {
  const {data} = props;
  return (
    <div className={styles.taskWrapper}>
      <div className={styles.text}>{data?.from + ' - ' + data?.to}</div>
    </div>
  );
}
export default SalaryRange;
