import { KoForm, KoFormBuilder, KoUserSelect } from 'packages';
import { useCallback, useEffect } from 'react';
import styles from './component.module.scss';
import { useSelector } from 'react-redux';

function Crud({ form, handleFinish, initialValues}) {
  const wosAssignees = useSelector(state => state.wosAssignees);
  useEffect(() => {
    if (!initialValues || Object.keys(initialValues).length === 0) {
      form.resetFields();
    } else {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);

  const handleSubmit = useCallback(values => {
    handleFinish(values);
  });

  const Leads = ({ value, onChange }) =>
    <KoUserSelect value={value} onChange={v => onChange(v)} users={wosAssignees[0]} placeholder='Select Leads' mode="multiple"/>;
 
  const Owner = ({ value, onChange }) =>
    <KoUserSelect value={value} onChange={v => onChange(v)} users={wosAssignees[0]} placeholder='Select Owner'/>;



  const meta = {
    columns: 6,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      {
        key: 'name', placeholder: 'Enter name', label: 'Name', colSpan: 3, rules: [
          {
            required: true,
            message: `Please enter name.`
          }
        ]
      },
      {
        key: 'leads', label: 'Leads', colSpan: 3,
        widget: Leads,
        forwardRef: true
      },
      {
        key: 'moduleOwner', label: 'Module Owner', colSpan: 3,
        widget: Owner,
        forwardRef: true
      }
    ]
  };


  return (
    <div className={styles.delWrapper}>
      <KoForm form={form} layout="vertical"
        onFinish={handleSubmit} onSubmit={handleSubmit}>
        <div>
          <KoFormBuilder form={form} meta={meta} />
        </div>
      </KoForm>
    </div>
  );
};

export default Crud;