import _find from 'lodash/find';
import {
  KoAcceptanceCriteria,
  KoDateFormatter,
  KoForm,
  KoFormBuilder,
  KoIcon,
  KoLoader,
  KoNano,
  KoSelect,
  KoTabs,
  KoText,
  KoUserSelect
} from 'packages';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useParams } from 'react-router';
import { ISSUETYPE, ISSUETYPEWITHOUTSUBTASK, STATUS, TIMESPEND } from 'shared/commonConstants';
import { getSprint, getUsers } from "shared/store/slice";
import { TaskPlannerAPI } from 'pages/taskPlanner/taskPlanner.service';
import { Date, Priority, Sprint } from './components';
import styles from './curd.module.scss';

function TestCaseCrud({ className, form, handleFinish, initialValues, subtask , epic, epicId, workspaceProfile}) {
  const {workspaceId} = useParams();
  const userById = useSelector(state => state?.userById);
  const [currentWorkspaceId, setCurrentWorkspaceId] = useState(workspaceId);
  const [profile, setProfile] = useState(workspaceProfile);
  const [modulesList, setModulesList] = useState();
  const [componentsList, setComponentsList] = useState();
  const [releaseList, setReleaseList] = useState();
  const [workspace, setWorkspaceRecord] = useState();
  const [isLoading, setLoading] = useState(false);
  const [lookupId, setLookupId] = useState();
  const [tickets, setTickets] = useState();
  const [epicList, setTicketsEpics] = useState();
  const [testCaseInitialValues, setTestCaseInitialValues] = useState();
  const dispatch = useDispatch();
  const StatusWatch = KoForm.useWatch('status', form);
  const includeInCalendarWatch = KoForm.useWatch('includeInCalendar', form) || false;
  let userDetails = userById['data'];
  const ticketId = KoForm.useWatch('ticketId', form);

  useEffect(()=>{
    if(initialValues?.ticketId || ticketId){
      getIssueById(initialValues?.ticketId || ticketId);
    }
  },[initialValues?.ticketId, ticketId]);
  
  useEffect(() => {
    if (!initialValues || Object.keys(initialValues).length === 0) {
      form?.resetFields();
    } else {
      form?.setFieldsValue(initialValues);
    }
    setCurrentWorkspaceId(initialValues?.workspace);
    getTicketsall();
  }, [initialValues, form]);

  useEffect(() => {
    if (workspaceProfile?.length > 0) {
      setProfile(workspaceProfile);
    }else {
      getWorkspaceById(currentWorkspaceId);
    }
  }, [workspaceProfile]);

  useEffect(() => {
    if (epic) {
      form.setFieldsValue({ issueType: 1 ,  reporter: userDetails && userDetails?.userId});
    }
  }, [epic]);



  const getIssueById = (ticketId, workspaceIdFromParam)=> {
    setLoading(true);
    TaskPlannerAPI.getIssueById(ticketId, workspaceId || workspaceIdFromParam).then(
      (data) => {
        setLoading(false);
        setTestCaseInitialValues(data?.issue);
      },
      (err) => {
        setLoading(false);
      }
    );
  };



  const getWorkspaceById = (workspaceId) => {
    TaskPlannerAPI.getWorkspaceById(workspaceId).then(
      (data) => {
        setWorkspaceRecord(data['project']);
        setProfile(data['project'].teams);
      },
      (err) => {
      }
    );

  };

  useEffect(() => {
    if ((StatusWatch === 10 || StatusWatch === 11) && workspace?.isPublic) {
      form.setFieldsValue({ assignee: testCaseInitialValues?.reporter });
    }
  }, [StatusWatch]);

  useEffect(() => {
    if (testCaseInitialValues?.lookupId) {
      setLookupId(testCaseInitialValues.lookupId);
    } else {
      const lp = KoNano('LOK');
      setLookupId(lp);
      let data = form?.getFieldsValue() || {};
      data['lookupId'] = lp;
      form?.setFieldsValue({ ...data });
    }

    dispatch(getUsers());
    if(currentWorkspaceId){
      getModules();
      getComponents();
      getReleases();
      getTickets();
    }
  }, []);

  useEffect(() => {
    if(currentWorkspaceId){
      getSprints();
    }
   
  }, [currentWorkspaceId]);


  const getModules = () => {
    setModulesList();
    TaskPlannerAPI.getModules(null,currentWorkspaceId).then(
      (data) => {
        setModulesList(data?.modules);
      },
      (err) => {
      }
    );
  };

  const getSprints = () => {
    TaskPlannerAPI.getSprints(null,currentWorkspaceId).then(
      (data) => {
        dispatch(getSprint({ query: null, workspaceId: currentWorkspaceId }));
      },
      (err) => {
      }
    );
  };

  const getComponents = () => {
    setComponentsList();
    TaskPlannerAPI.getComponents(null, currentWorkspaceId).then(
      (data) => {
        setComponentsList(data?.components);
      },
      (err) => {
      }
    );
  };

  const getReleases = () => {
    setReleaseList();
    TaskPlannerAPI.getReleases(workspaceId).then(
      (data) => {
        setReleaseList(data?.releases);
      },
      (err) => {
      }
    );
  };



  const handleSubmit = useCallback(values => {
    values['lookupId'] = lookupId;
    handleFinish(values, epicId);
  });

  const optionGroups = [];
  for (let i = 10; i < 36; i++) {
    optionGroups.push({
      label: i.toString(36) + i,
      value: i.toString(36) + i
    });
  }
  const TaskStatus = ({ value, onChange }) => (
    <KoSelect
      value={value}
      showSearch
      disabled={true}
      defaultValue={STATUS[0].value}
      onChange={v => onChange(v)}
      placeholder='Select status'
      options={STATUS}
      optionRender={(option) => {
        const label = option?.data?.label;
        const formattedLabel = typeof label === 'string' ? label.replace(" ", '') : '';
        return (
          <div>
            <div className={`${styles.status} ${styles[formattedLabel]}`} role="img" aria-label={label} />
            <KoText text={label} />
          </div>
        );
      }}
    />
  );


  const IssueType = ({ value, onChange }) => (
    <KoSelect
      value={epic ? 1 : value}
      onChange={v => onChange(v)}
      showSearch
      disabled={true}
      placeholder="Select issue type"
      options={(subtask || testCaseInitialValues?.issueType === 4) ? ISSUETYPE : ISSUETYPEWITHOUTSUBTASK}
      optionRender={(option) => (
        <div className={styles.iconWithLabel} role="img" aria-label={option.data.label}>
          {option.data.value === 1 && <KoIcon name="EpicFilled" />}
          {option.data.value === 2 && <KoIcon name="StoryFilled" />}
          {option.data.value === 3 && <KoIcon name="TaskFilled" />}
          {option.data.value === 4 && <KoIcon name="SubtaskFilled" />}
          {option.data.value === 5 && <KoIcon name="BugFilled" />}
          {option.data.value === 6 && <KoIcon name="ImprovementsFilled" />}
          {option.data.value === 7 && <KoIcon name="FeatureRequestFilled" />}
          {option.data.value === 8 && <KoIcon name="ChangerequestFilled" />}
          {option.data.value === 9 && <KoIcon name="SupportFilled" />}
          {option.data.label}
        </div>
      )}
    />
  );


  const getTickets=()=>{
    setTicketsEpics();
    TaskPlannerAPI.getIssues({ issueType: 1}, currentWorkspaceId).then(
      (data) => {
        setTicketsEpics(data?.tickets);
      },
      (err) => {
      }
    );
  };

  const getTicketsall=()=>{
    setTickets();
    TaskPlannerAPI.getIssues(null, currentWorkspaceId).then(
      (data) => {
        setTickets(data?.tickets);
      },
      (err) => {
      }
    );
  };



  const AcceptanceCriteria = ({ value, onChange }) =>
    <KoAcceptanceCriteria  disabled={true} value={value} initialValue={value} workspaceId={currentWorkspaceId}
      form={form} name='acceptanceCriteria' lookupId={lookupId}
      onChange={v => onChange(v)}
    />;


  const Description = ({ value, onChange }) =>
    <KoAcceptanceCriteria  disabled={true} value={value} initialValue={value} lookupId={lookupId} workspaceId={currentWorkspaceId}
      form={form} name='description'
      placeholder='Add a description...'
      onChange={v => onChange(v)}
    />;

  const Assignees = ({ value, onChange }) =>
    <KoUserSelect  disabled={true}  value={value} onChange={v => onChange(v)} users={profile} placeholder='Select assignee' />;


  const Reporter = ({ value, onChange }) =>
    <KoUserSelect  disabled={true}  value={value} onChange={v => onChange(v)} users={profile} placeholder='Select reporter' />;

  const Watchers = ({ value, onChange }) =>
    <KoUserSelect  disabled={true} mode='multiple'  value={value} onChange={v => onChange(v)} users={profile} placeholder='Select watchers' />;

  const Module = ({ value, onChange }) =>
    <KoSelect
      placeholder="Select module"
      value={value}
      disabled={true}
      onChange={v => onChange(v)}
      options={modulesList?.map((module) => ({
        label: module?.name,
        value: module?.moduleId
      }))}
    />;

  const Components = ({ value, onChange }) =>
    <KoSelect
      disabled={true}
      placeholder='Select component'
      value={value}
      onChange={v => onChange(v)}
      showSearch
      options={componentsList?.map((comp) => ({
        label: comp?.name,
        value: comp?.componentId
      }))}
    />;


  const Releases = ({ value, onChange }) =>
    <KoSelect value={value}
      onChange={v => onChange(v)}
      disabled={true}
      showSearch
      placeholder='Select Tag / Release'
      options={releaseList?.map((env) => ({
        label: env?.name,
        value: env?.releaseId
      }))}
    />;



  const EpicTicketId = ({ value, onChange }) => {
    const options = epicList?.map((ticket) => {
      const issueType = _find(ISSUETYPE, (o) => o.value === ticket.issueType);
      return {
        label: (
          <div className={styles.TicketIdWrapper} aria-label={ticket?.ticketId}>
            {issueType.icon}
            <KoText className={styles.text} text={`${ticket?.ticketId} |`} />
            <KoText className={styles.text} text={ticket?.summary} />
          </div>
        ),
        value: ticket?.ticketId
      };
    });
    
    return (
      <KoSelect
        value={value}
        onChange={(v) => onChange(v) }
        showSearch
        defaultValue={epicId ? epicId : value}
        disabled={true}
        placeholder="Select Ticket Id"
        options={options}
      />
    );
  };

  const Tickets = ({ value, onChange }) => {
    const options = tickets?.map((ticket) => {
      const issueType = _find(ISSUETYPE, (o) => o.value === ticket.issueType);
      return {
        label: (
          <div className={styles.TicketIdWrapper} aria-label={ticket?.ticketId}>
            {issueType.icon}
            <KoText className={styles.text} text={`${ticket?.ticketId} |`} />
            <KoText className={styles.text} text={ticket?.summary} />
          </div>
        ),
        value: ticket?.ticketId
      };
    });
    
    return (
      <KoSelect
        value={value}
        onChange={(v) => onChange(v) }
        showSearch
        disabled={true}
        defaultValue={value}
        placeholder="Select Ticket Id"
        options={options}
      />
    );
  };


  const TimeSpentSelect = ({ value, onChange }) => {
    return (
      <KoSelect
        value={value || undefined}
        placeholder="Ex: 1w 3d 6h 20m"
        showSearch
        disabled={true}
        onChange={(v) => onChange(v)}
        options={TIMESPEND}     />
    );
  };

  const SUMMARYSECTION= {
    columns: 6,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      {
        key: 'ticketId', label: 'Ticket Id', colSpan: 3, widget: Tickets
      },
      {
        key: 'status', label: 'Status', colSpan: 1.5,
        widget: TaskStatus,
        forwardRef: true
      },
      
      {
        key: 'lookupId', label: 'Lookup Id', colSpan: 0, className: 'metaFieldHide'
      }
    ]
  };


  const BASICMETADATA = {
    columns: 6,
    formItemLayout: null,
    testCaseInitialValues,
    colon: true,
    disabled: true,
    fields: [
      {
        key: 'label1s',
        colSpan: 64,
        render() {
          return (
            <fieldset>
              <div className={styles.createdAt}>
                <legend>ISSUE DETAILS</legend>
                {testCaseInitialValues?.createdAt && <div className={styles.createdAtWrapper}>
                  <KoText text="Created on"  className={styles.createdAtText}/>
                  <KoDateFormatter className={styles.createdAtDate} data={testCaseInitialValues?.createdAt} format="DD-MMM-YYYY" />
                </div>}
              </div>
            </fieldset>
          );
        }
      },
      {
        key: 'issueType', label: 'Issue type', colSpan: 3,
        widget: IssueType,
        disabled: true,
        rules: [
          {
            required: true,
            message: `Please select issue type.`
          }
        ]
      },
      {
        key: 'status', label: 'Ticket status', colSpan: 3,
        widget: TaskStatus,
        disabled: true,
        forwardRef: true
      },
      {
        key: 'sprint',  colSpan: 3,
        widget: Sprint,
        forwardRef: true,
        label: 'Sprint'
      },
      {
        key: 'epic', label: 'Epic', colSpan: 3, widget: EpicTicketId
      },
      {
        key: 'priority', label: 'Priority', colSpan: 3,
        widget: Priority,
        forwardRef: true
      },
      {
        key: 'storyPoints', label: 'Story Points / Estimate', colSpan: 3, widget: TimeSpentSelect
      },
      {
        key: 'label1',
        colSpan: 6,
        render() {
          return (
            <fieldset>
              <legend>PEOPLE</legend>
            </fieldset>
          );
        }
      },
      {
        key: 'assignee', label: <div className={styles.assigneeLabel}>
          <KoText text={'Assignee'}/>
          { testCaseInitialValues?.assignee !== userDetails?.userId && <KoText className={styles.assignToMe} />}
        </div>, colSpan: 3,
        widget: Assignees,
        forwardRef: true
      },
      {
        key: 'reporter', label: 'Reporter', colSpan:3,
        widget: Reporter,
        rules: [
          {
            required: true,
            message: `Please select Reporter.`
          }
        ]
      },
      {
        key: 'watchers', label: 'Watchers', colSpan:6,
        widget: Watchers
      },
      {
        key: 'label3',
        colSpan: 6,
        render() {
          return (
            <fieldset>
              <legend>DATE & CALENDAR</legend>
            </fieldset>
          );
        }
      },
      {
        key: 'startDate', placeholder: 'Enter Start Date', label: 'Start Date',
        colSpan: 3, widget: Date, rules: includeInCalendarWatch ? [
          {
            required: true,
            message: 'Please select start date.'
          }
        ] : []
      },
      {
        key: 'endDate', placeholder: 'Enter End Date', label: 'Due Date', colSpan: 3, widget: Date,
        rules: includeInCalendarWatch ? [
          {
            required: true,
            message: 'Please select end date.'
          }
        ] : []
      },
      
      {
        key: 'label1a',
        colSpan: 6,
        render() {
          return (
            <fieldset>
              <legend>ADDITIONAL INFORMATION </legend>
            </fieldset>
          );
        }
      },
      {
        key: 'release', label: 'Tag / Release', colSpan: 3, widget: Releases
      },
      {
        key: 'module', label: 'Module', colSpan: 3, widget: Module
      },
      {
        key: 'component', label: 'Component', colSpan: 3, widget: Components
      },

      {
        key: 'specLink', label: 'Spec Link', colSpan: 3, widget: Sprint
      }
    
    ]
  };

  if (epic) {
    BASICMETADATA['fields'].splice(3, 1);
    BASICMETADATA['fields'].splice(3, 1);
    BASICMETADATA['fields'].splice(3, 1);
    BASICMETADATA['fields'].splice(3, 1);
  }

  const TASKINFOMETADATA = {
    columns: 6,
    formItemLayout: null,
    testCaseInitialValues,
    colon: true,
    fields: [
      {
        disabled: true,
        key: 'acceptanceCriteria', placeholder: 'Requirement', label: 'Acceptance Criteria', colSpan: 6,
        widget: AcceptanceCriteria
      },
      {
        key: 'description', placeholder: 'Description', label: 'Description', colSpan: 6,
        widget: Description
      }

    ]
  };
  
  console.log(testCaseInitialValues);
  return (
    <div className={`${styles.ticketWrapper} 'tabContnetTicket'`}>
      {isLoading && <KoLoader/>}
      <div className={`${styles.wrapper} ${className ? className : ''}`}>
        <KoForm form={form} layout="vertical"
          onFinish={handleSubmit} onSubmit={handleSubmit}>
          <div className={styles.leftForm}>
            <KoFormBuilder form={form} meta={SUMMARYSECTION} />
            <div className={`${styles.taskInfo} profileSection`}>

              <KoTabs options={[
                {
                  label:<div className={styles.subTaskTab}>
                    <KoIcon name='TextOutlined' width={14} height={14} />
                    <KoText text={'Issue Details'} />
                  </div>,
                  children: <div className={styles.taskInfoTabs}>
                    <KoFormBuilder form={form} meta={TASKINFOMETADATA} /></div>,
                  key: 'about'
                }]} />
            </div>
          </div>
          <div className={styles.rightForm}>
            <KoFormBuilder  form={form} meta={BASICMETADATA} />
          </div>
        </KoForm>
      </div>
    </div>
  );
};

export default TestCaseCrud;