import React from "react";
function TimeSheet(props) {
  const { width, height } = props;
  return (
   
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width={width} height={height}
    >
      <path d="M32 10.99c-1.314.022-1.314 1.978 0 2 1.314-.022 1.314-1.978 0-2z" />
      <path d="M57 11l-20.112-.052C36.39 8.661 34.336 7 32 7s-4.39 1.66-4.9 4H7c-1.654 0-3 1.346-3 3v22c.033 1.316 1.971 1.316 2 0V18c14.24.007 33.801-.005 48 0 1.295-.01 1.329-1.981 0-2H41v-3h16c.552 0 1 .449 1 1v34c0 .551-.448 1-1 1H44.63A13.891 13.891 0 0046 43c0-7.72-6.28-14-14-14-10.091-.18-17.144 11.013-12.63 20H7c-.552 0-1-.449-1-1v-7a1 1 0 10-2 0v7c0 1.654 1.346 3 3 3h13.527c2.533 3.622 6.728 6 11.473 6s8.94-2.378 11.473-6H57c1.654 0 3-1.346 3-3V14c0-1.654-1.346-3-3-3zM6 14c0-.551.448-1 1-1h16v3H6v-2zm33 2H25v-3h2.1c.96 0 1.77-.673 1.967-1.628.649-3.126 5.212-3.129 5.864-.009.2.964 1.01 1.637 1.97 1.637H39v3zm-7 39c-6.617 0-12-5.383-12-12 .659-15.92 23.343-15.915 24 0 0 6.617-5.383 12-12 12z" />
      <path d="M32 34a1 1 0 00-1 1v7h-4c-1.312.02-1.316 1.979 0 2h5a1 1 0 001-1v-8a1 1 0 00-1-1z" />
    </svg>
  );
}


export default TimeSheet;