import React from "react";
function Testing(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width={width} height={height}>
      <g fill="#ff7058">
        <path d="M22 11.3l18.4 32.1-6.4-1.7-1.7 6.3-18.4-32z" fill="#FF941A"></path>
        <path d="M26 11.3L7.6 43.4l6.4-1.7 1.7 6.3 18.4-32z" fill="#FF941A"></path>
      </g>
      <path fill="#ffd15c"
        d="M43.7 19.2c0 1.4-2.4 2.6-2.7 3.9-.3 1.4 1.3 3.4.7 4.7-.6 1.3-3.2 1.2-4.1 2.3-.9 1.1-.3 3.7-1.4 4.5-1.1.9-3.5-.3-4.7.3-1.2.6-1.8 3.2-3.2 3.5-1.3.3-3-1.8-4.4-1.8-1.4 0-3.1 2.1-4.4 1.8-1.4-.3-1.9-2.9-3.2-3.5-1.3-.6-3.6.5-4.7-.3-1.1-.9-.5-3.4-1.4-4.5-.7-1.1-3.3-1.1-3.9-2.3-.6-1.2 1.1-3.3.7-4.7-.3-1.3-2.7-2.5-2.7-3.9s2.4-2.6 2.7-3.9c.3-1.4-1.3-3.4-.7-4.7.6-1.3 3.2-1.2 4.1-2.3.9-1.1.3-3.7 1.4-4.5 1.1-.9 3.5.3 4.7-.3 1.2-.6 1.8-3.2 3.2-3.5 1.3-.3 3 1.8 4.4 1.8 1.4 0 3.1-2.1 4.4-1.8 1.4.3 1.9 2.9 3.2 3.5 1.3.6 3.6-.5 4.7.3 1.1.9.5 3.4 1.4 4.5.9 1.1 3.5 1.1 4.1 2.3.6 1.2-1.1 3.3-.7 4.7.1 1.4 2.5 2.5 2.5 3.9z"></path>
      <circle cx="24" cy="19.2" r="13.2" fill="#f8b64c"></circle>
      <path fill="#ffd15c" d="M24 9.6l3 6 6.7 1-4.8 4.7L30 28l-6-3.1-6 3.1 1.1-6.7-4.8-4.7 6.7-1z"></path>
    </svg>
  );
}


export default Testing;