import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { KoButton, KoImage, KoForm, KoFormBuilder, KoIcon, KoTitle, KoText, KoMobile, KoNano, KoInput } from 'packages';
import { accountAPI } from '../account.service';

import styles from '../login/signin.module.scss';
import { useDispatch } from 'react-redux';
import { login, updateUser } from 'shared/store/authSlice';

function CreateAccount() {
  let navigate = useNavigate();
  const [form] = KoForm.useForm();
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();

  // function isEmailValid(email) {
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //   const excludedDomains = ['gmail.com', 'outlook.com', 'yahoo.com'];
  //   if (!emailRegex.test(email)) {
  //     return 'Email is required';
  //   }
  //   const domain = email.split('@')[1];
  //   if (excludedDomains.includes(domain.toLowerCase())) {
  //     return 'Enter office email address';
  //   }
  //   return true;
  // }

  function handleEnterKey(event) {
    if (event.keyCode === 13) {
      form.submit();
    }
  }

  const meta = {
    columns: 4,
    formItemLayout: null,
    colon: true,
    fields: [
      {
        key: 'email',
        label: 'Office Email Address',
        placeholder: 'Ex. example@business.com',
        colSpan: 4,
        forwardRef: true,
        widget: ({ value, onChange }) => (
          <KoForm.Item
            name="email"
            rules={[
              // {
              //   type: 'email',
              //   message: 'Please enter a valid email address!'
              // },
              {
                pattern: /^[a-zA-Z0-9._%+-]+@(?!gmail\.com$|yahoo\.com$|hotmail\.com$|outlook\.com$|icloud\.com$|aol\.com$|protonmail\.com$|zoho\.com$|mail\.com$|gmx\.com$|live\.com$|msn\.com$|yandex\.com$|qq\.com$)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: 'Personal email addresses are not allowed.'
              },
              {
                pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|org|net|biz|info|co|io|tech|ai|dev|health|club|edu|gov|me)$/,
                message: 'Please enter a business email with a valid domain.'
              }
            ]}
          >
            <KoInput
              value={value}
              onChange={onChange}
              placeholder="Enter business email"
            />
          </KoForm.Item>
        )
      },
      {
        key: 'password', label: 'Password', widget: 'password', placeholder: 'Ex.****.', rules: [
          {
            required: true,
            message: 'Password is required'
          }
        ], colSpan: 4
      },
      { key: 'mobile', label: 'Mobile (optional)', placeholder: 'Ex. 98765*****', colSpan: 4, widget:KoMobile }
    ]
  };

  const handleFinish = useCallback(values => {
    setLoading(true);
    values['userId'] = KoNano("USR");
    values['accountId'] = KoNano("ACC");
    accountAPI.createAccount(values).then(
      (data) => {
        setLoading(false);
        dispatch(updateUser({...data}));
        dispatch(
          login({
            userId: data.userId,
            user: data.user,
            accessToken: data.accessToken,
            accountId: data.accountId
          })
        );
        if(data?.status === 2){
          navigate(`/account/requested`);
        }else if(!data?.isVerified){
          navigate(`/account/verify?email=${values.email}`);
        }
      },
      (err) => {
        setLoading(false);
      }
    );
  });

  const onPageChangeCreate=()=>{
    navigate('/account/login');
  };


  return (
    <div className={styles.wrapper}>
      <div className={styles.modal}>
        <div className={styles.formWrapper}>
          <div className={styles.header}>
            <KoIcon name='AppLogoFilled' width={108}
              height={37} />
          </div>
          <KoTitle text={'Welcome to Kwapio!'} level={4} />
          <KoText text={`Join the adventure! Create your account for a world of possibilities. Start with a simple signup.`} className={`${styles.small}`} />
          <KoText onClick={onPageChangeCreate} className={styles.otherOptions} text={` Already have an account? Log in`} />
          <div className={styles.formData}>
          </div>
          <KoForm autoFocus form={form} layout="vertical" onKeyUp={handleEnterKey} onFinish={handleFinish} onSubmit={handleFinish}>
            <KoFormBuilder className={styles.formData} form={form} meta={meta} />
          </KoForm>
        
          <div className={styles.signUpOptions}>
            <div className={styles.line} />
            <KoText className={styles.otherOptions} text='Sign up with' />
          </div>
          <div className={styles.thirdPartyBtn}>
            <KoButton label='Sign in with Google' icon={<KoIcon name="GoogleFilled" />} />
            <KoButton label='Sign in with Microsoft' icon={<KoIcon name="MicrosoftFilled" />} />
          </div>
          <div className={styles.signupBtn}>
            <KoButton loading={isLoading} onClick={() => form.submit()} label={'Create Account'} type={'primary'} />
          </div>
        </div>
        <div className={styles.banner}>
          <div className={styles.slider}>
            <KoImage
              alt={'name'}
              src={`/images/banner.svg`}
              width={500}
              preview={false}
              height={300}
            />
            <div className={styles.footer}>
              <KoTitle text={'Connect your team’s work to your product roadmap'} level={5} />
              <KoText text="Every team has a unique process. Use a out-of-boss workflow,
              or create one to match the way team works"/>
            </div>
          </div>
          <div className={styles.dots}>
            <div className={styles.active} />
            <div className={styles.circle} />
            <div className={styles.circle} />
            <div className={styles.circle} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateAccount;

// ,
//               {
//                 validator: (rule, value, callback) => {
//                   return new Promise((resolve, reject) => {
//                     if (isEmailValid(value) === 'Email is required' || isEmailValid(value) === 'Enter office email address') {
//                       reject(new Error(isEmailValid(value)));
//                     } else {
//                       resolve();
//                     }
//                   });
//                 }
//               }