import {
  KoAffix,
  KoBreadcrumb,
  KoButton,
  KoCard,
  KoDateFormatter,
  KoDP,
  KoEmpty,
  KoFlex,
  KoForm,
  KoIcon,
  KoLoader,
  KoModal,
  KoNano,
  KoPagination,
  KoTable,
  KoText,
  KoTitle,
  KoToast,
  KoTooltips
} from 'packages';
import TaskCrud from 'pages/taskPlanner/task/crud';
import { TaskPlannerAPI } from 'pages/taskPlanner/taskPlanner.service';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { add, remove } from 'shared/store/breadcrumbSlice';
import KoToolBar from 'shared/components/toolbar';
import { getCookie, setCookie } from 'shared/utils/cookies';
import AssigneeCrud from './assignee';
import { PRO_ITEMS_COLUMNS } from './constant';
// import ProjectCrud from './curd';
import AssigneeGroupCrud from './groups';
import WorkspaceSettings from './settings';
import styles from './workspace.module.scss';
import { workspaceAPI } from './workspace.service';
import { canWorkspaceCreate } from 'layouts/permissions';
import { getTicketBreadCrumb } from 'shared/commonConstants';
import { removeAssignees, updateAssignees } from 'shared/store/wosAssigneesSlice';
import { setSelectedChatData, setSelectedChatType } from 'shared/store/chatSlice';


function Workspace() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const accountId = getCookie("accountId");
  const [isTicketOpen, setTicketModal] = useState(false);
  const [form] = KoForm.useForm();
  const [workspaceList, setDataList] = useState();
  const [breadCurmbs, setBreadCrumbs] = useState();
  const [recent, setRecent] = useState();
  const [currentRecord, setCurrentRecord] = useState();
  const [totalCount, setTotalCount] = useState(100);
  const [isLoading, setLoading] = useState(false);
  const [isOpenDelete, setDeleteModal] = useState(false);
  const [isOpenGroupsModal, setGroupsModal] = useState(false);
  const [isAssigneeModal, setAssigneeModal] = useState(false);
  const [selected, setSelected] = useState([]);
  const [copy, setCopy] = useState(false);
  const [openSettings, setOpenSettings] = useState(false);
  const [profiles, setProfiles] = useState();
  const [pagination, setPagination] = useState({
    size: 10,
    page: 1
  });
  const userById = useSelector(state => state?.userById);
  let userDetails = userById['data'];

  useEffect(()=>{
    dispatch(remove());
    dispatch(add([]));
    getWorkspace();
    getRecentTicket();
   
  },[]);
  
  const getRecentTicket=()=>{
    setLoading(true);
    setRecent();
    setTotalCount();
    workspaceAPI.getRecentTicket(pagination).then(
      (data) => {
        setRecent(data['tickets']);
        setTotalCount(data?.totalCount);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const getWorkspace=()=>{
    setLoading(true);
    setDataList();
    workspaceAPI.getWorkspace().then(
      (data) => {
        setDataList(data['workspaces']);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const getIssueById = (ticketId, workspaceId)=> {
    setLoading(true);
    TaskPlannerAPI.getIssueById(ticketId, workspaceId).then(
      (data) => {
        setLoading(false);
        const updatedRoomObj = { ...data?.issue, source: 'tickets' };
        dispatch(setSelectedChatData(updatedRoomObj));
        dispatch(setSelectedChatType('tickets'));
        setCurrentRecord(data?.issue);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const onPagination = (page, other) => {
    const query = {
      size: other,
      page: page
    };
    const p = Object.assign(pagination, query);
    setPagination(p);
    getRecentTicket(p);
    
  };


  const handleFinish = (values => {
    if(!currentRecord?.workspaceId){
      values['workspaceId'] = KoNano('WOS');
    }

    workspaceAPI.createOrUpdateClient(values, currentRecord?.workspaceId).then(
      (data) => {
        setLoading(false);
        getWorkspace();
        setOpenSettings(false);
        KoToast.success( currentRecord?.workspaceId ? 'Workspace updated successfully' :'Workspace created successfully');
      },
      (err) => {
        setLoading(false);
      }
    );
  });

  const handleFinishDelete=()=>{
    setLoading(true);
    workspaceAPI.deleteById(currentRecord?.workspaceId).then(
      (data) => {
        getWorkspace();
        setLoading(false);
        setDeleteModal(false);
        KoToast.success(`Profile deleted successfully! ${currentRecord.profile?.firstName}!`);
      },
      (err) => {
        setLoading(false);
        KoToast.error(`error`);
      }
    );
  };

  const handleFinishAssignee=()=>{
    let values = currentRecord;
    values['assignees'] = selected;
    workspaceAPI.createOrUpdateClient(values, currentRecord?.workspaceId).then(
      (data) => {
        setLoading(false);
        getWorkspace();
        setAssigneeModal(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const handleFinishAssigneeGroups=()=>{
    let values = currentRecord;
    values['groups'] = selected;
    workspaceAPI.createOrUpdateClient(values, currentRecord?.workspaceId).then(
      (data) => {
        setLoading(false);
        getWorkspace();
        setGroupsModal(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const handleTaskFinish = (values => {
    setLoading(true);
    TaskPlannerAPI.createOrUpdateIssues(values, currentRecord?.ticketId, currentRecord?.workspace).then(
      (data) => {
        setLoading(false);
        setTicketModal(false);
        getRecentTicket();
        form.resetFields();
        KoToast.success(`Ticket ${currentRecord?.ticketId ? 'updated':'created'} successfully`);
      },
      (err) => {
        setLoading(false);
      }
    );
  });

  const onSearchText=(text)=>{
    let q = pagination;
    q['search'] = text;
    getRecentTicket(q);
  };
  
  const onSort = (key, type) => {
    let q = pagination;
    q['sort'] = `${key}:${type}`;
    getRecentTicket(q);
  };

  const onCopyTicket=(row)=>{
    setCopy(true);
    navigator.clipboard.writeText(`${window.location.origin}/open/ticket/${getCookie("accountId")}/${row?.workspaceId}/${row?.ticketId}`);
  };

  const onCopyPublicUrl=(text)=>{
    setCopy(true);
    navigator.clipboard.writeText(`${text}`);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copy) setCopy(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [copy]);

  const getWorkspaceById = (workspaceId,ticketId) => {
    TaskPlannerAPI.getWorkspaceById(workspaceId).then(
      (data) => {
        dispatch(removeAssignees());
        dispatch(updateAssignees(data['project'].teams));
        setProfiles(data['project'].teams);
        setTicketModal(true);
        getIssueById( ticketId, workspaceId);
      },
      (err) => {
      }
    );
  };
  
  const onCellClick=(row)=>{
    getWorkspaceById(row?.workspaceId, row?.ticketId);
    let crums = getTicketBreadCrumb(row);
    setBreadCrumbs(crums);
    const updatedRoomObj = { ...row, source: 'tickets' };
    dispatch(setSelectedChatData(updatedRoomObj));
    dispatch(setSelectedChatType('tickets'));
  };


  const columnsRight = [{
	  title: '',
	  dataIndex: 'x',
	  key: 'x',
	  fixed: 'right',
	  width: 40,
	  render: (text, row) => {
	    return <div className={`${styles.circle} tableBtn columnsRight`} onClick={() => setCurrentRecord(row)}>
        <KoTooltips title={copy ? 'Copied' : 'Copy'}>
	      <KoButton onClick={()=>onCopyTicket(row)} className={'btn'} iconBtn={true} icon={<KoIcon width={14} height={14} name={'CopyOutlined'}/>}/>
        </KoTooltips>
        <KoTooltips title={'New window'}>
          <Link target="_blank" to={`/open/ticket/${getCookie("accountId")}/${row?.workspaceId}/${row?.ticketId}`}>
            <KoButton className={'btn'} onClick={()=>setWorkspace(row)}  iconBtn={true} icon={<KoIcon width={14} height={14} name={'OpenOutlined'}/>}/>
          </Link>
        </KoTooltips>
	    </div>;
	  }
  }];


 


  const onAction = (key, workspace) => {
    setCurrentRecord(workspace);
    if(key === "1"){
      setOpenSettings(true);
    } else if(key === "2"){
      setSelected(workspace['assignees']);
      setAssigneeModal(true);
    }else if(key === "3"){
      setSelected(workspace['groups']);
      setGroupsModal(true);
    }else{
      setDeleteModal(true);
    }
  };

  const setWorkspace=(wos)=>{
    setCookie('wos', wos?.workspaceId || wos?.workSpace);
  };


  const KeyColor = (bgColor) => {
    const isColorDark = (color) => {
      const hex = color.replace("#", "");
      const [r, g, b] = [parseInt(hex.substr(0, 2), 16), parseInt(hex.substr(2, 2), 16), parseInt(hex.substr(4, 2), 16)];
      const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
      return luminance < 128;
    };
    
    return {
      backgroundColor: bgColor,
      color: isColorDark(bgColor) ? "#ffffff" : "#000000"
    };
  };
  

  return <div className={styles.wrapper}>
    {isLoading && <KoLoader />}
    <KoAffix top={56}>
      <KoToolBar
        className={styles.toolbar}
        backBtn={true}
        title={'Workspace'}
        search={{ visible: true, onChange: () => {}, placeholder: 'Search by project name' }}
        visible={ canWorkspaceCreate(userDetails?.roles)}
        canCreate={false}
        buttonList={[{
          label: 'Create Workspace',
          type: 'primary',
          icon: <KoIcon color="#FFF" name="PlusOutlined" width={18} height={18} />,
          onClick: () => setOpenSettings(true),
          disabled: !canWorkspaceCreate(userDetails?.roles)
        }]} />
    </KoAffix>
    {workspaceList?.length > 0 ?<KoFlex wrap="wrap" gap="small" className={styles.listSection}>
      {workspaceList?.map((workspace, i) =>
        <KoCard onClick={()=>setWorkspace(workspace)} className={styles.card}
          key={workspace?.workspaceId}
          hoverable={true}>
          <div className={styles.aLink}>
            <div onClick={() => navigate(`/${workspace?.workspaceId}/taskplanner/dashboard`)}>
              <div className={styles.titleKey}>
                <div className={`${styles.headCircle}  ${styles[workspace?.customerDetails?.companyName ?
                  workspace?.customerDetails?.companyName?.charAt(0).toLowerCase() : 'a']}`}>
                  {workspace?.customerDetails?.['logo'] ?
                    <KoDP shape={'default'} logo={workspace?.customerDetails?.['logo']} width={25} height={'auto'} />
                    :
                    <KoText className={`${styles.letter}`} text={workspace?.customerDetails?.companyName?.charAt(0)} />
                  }
                </div>
                <div className={styles.titleValue}>
                  <div className={styles.verified}>
                    <KoText className={`${styles.text}`} text={workspace?.name} />
                  </div>
                  <KoTitle className={styles.title} ellipsis={true}  text={workspace?.customerDetails?.companyName} level={4} />
                </div>
              </div>
              <KoText style={workspace?.color && KeyColor(workspace?.color)} className={`${styles.key} ${!workspace?.color && styles[workspace?.key? workspace?.key?.charAt(0).toLowerCase() :'a']}`}
                text={`${workspace?.key}`} />
              <div className={styles.description} >
                <KoText text={`${workspace?.description ? workspace?.description :'-'}`} ellipsis={true}/>
              </div>
            </div>
            <div className={styles.link}>
              <div className={styles.row}>
                <KoText className={styles.date}  text='Created on'/>
                <KoDateFormatter format='DD-MMM-YYYY' data={workspace?.createdAt} className={styles.date}/>
              </div>
              {
                userDetails?.roles?.some((role) => [1, 2, 4].includes(role)) &&
                <div className={styles.workspaceLink}>
               
                  <KoTooltips title={'Delete workspace'}>
                    <KoButton className={styles.copyBtn} onClick={()=>onAction('4', workspace)} iconBtn={true} icon={<KoIcon width={14} height={14} name={'DeleteOutlined'}/>}/>
                  </KoTooltips>
                  {workspace?.isPublic && (
                    <KoTooltips title={copy ? 'Copied' : 'Copy Public Url'}>
                      <KoButton className={styles.copyBtn} onClick={()=>onCopyPublicUrl(`https://app.kwapio.com/support/tickets/${accountId}/${workspace?.workspaceId}`)} iconBtn={true} icon={<KoIcon width={14} height={14} name={'CopyOutlined'}/>}/>
                    </KoTooltips>
                  ) }
                  <KoTooltips title={'Edit workspace'}>
                    <KoButton className={styles.copyBtn} onClick={()=>onAction('1', workspace)} iconBtn={true} icon={<KoIcon width={14} height={14} name={'EditOutlined'}/>}/>
                  </KoTooltips>
                </div>
              }
            </div>
          </div>
        </KoCard>
      )}
    </KoFlex>:
      <KoFlex wrap="wrap" gap="small" className={styles.emptySection}>
        <KoEmpty onClick={()=>setOpenSettings(true)} label="Create Workspace" icon={'EmptyTwoTone'} title="Create a new workspace"/>
      </KoFlex>
    }
    { workspaceList?.length > 0 && <div className='tableCard'>
      <KoToolBar
        className={styles.toolbar}
        title={'Recent works'}
        search={{ visible: true, onChange: onSearchText, placeholder: 'Search by any parameter' }}
        filter={{
          visible: false,
          options: [{ label: 'Sort By Name', key: '0' },
            { label: 'Created At', key: '2' },
            { label: 'Sort By designation', key: '3' }]
        }}
        buttonList={[]} />
        
      <div className={styles.reacentTable}>
        <KoTable rowKey='ticketId'
          onSort={onSort}
          onCellClick={onCellClick}
          data={{ columns: PRO_ITEMS_COLUMNS, rows: recent }}
          extraColumnsRight={columnsRight} maxHeight={100} />
        <KoPagination current={parseInt(pagination.page) || 1}
          total={totalCount}
          showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} records`
          }
          pageSize={pagination.limit}
          defaultCurrent={pagination.page}
          onChange={onPagination}
          showQuickJumper={false}
          simple={false}
          responsive={false} />
      </div>
    </div>}
    
    <KoModal
      title={`Delete workspace ${currentRecord?.name?.toUpperCase()}`}
      centered
      open={isOpenDelete}
      handleOk={() => { setCurrentRecord(); setDeleteModal(false); }}
      onClose={() => { setCurrentRecord(); setDeleteModal(false); }}
      width={580}
      footer={[
        <KoButton key='cancel' label='Cancel' onClick={() => setDeleteModal(!isOpenDelete)} />,
        <KoButton key='reset' loading={isLoading} type='primary' label='Delete' onClick={() => handleFinishDelete()} />
      ]}
    >
      {isLoading && <KoLoader circle={false} />}
      <div className={styles.suspend}>
        <KoTitle level={5} text={`Are you sure you want to delete this workspace?`} />
        <KoText text={`This action is irreversible, and it will result in the removal of their associated data and Kwapio license.`} />
      </div>
    </KoModal>

    <KoModal
      title={`Update assignees ${currentRecord?.name?.toUpperCase()}`}
      centered
      open={isAssigneeModal}
      handleOk={() => { setCurrentRecord(); setAssigneeModal(false); }}
      onClose={() => { setCurrentRecord(); setAssigneeModal(false); }}
      width={780}
      footer={[
        <KoButton key='cancel' label='Cancel' onClick={() => setAssigneeModal(!isAssigneeModal)} />,
        <KoButton key='reset' loading={isLoading} type='primary' label='Update' onClick={() => handleFinishAssignee()} />
      ]}
    >
      {isLoading && <KoLoader circle={false} />}
      <div className={styles.suspend}>
        <AssigneeCrud
          handleFinishAssignee={(data)=>setSelected(data)}
          selected={selected}/>
      </div>
    </KoModal>

    <KoModal
      title={`Update assignee groups ${currentRecord?.name?.toUpperCase()}`}
      centered
      open={isOpenGroupsModal}
      handleOk={() => { setCurrentRecord(); setGroupsModal(false); }}
      onClose={() => { setCurrentRecord(); setGroupsModal(false); }}
      width={780}
      footer={[
        <KoButton key='cancel' label='Cancel' onClick={() => setGroupsModal(!isOpenGroupsModal)} />,
        <KoButton key='reset' loading={isLoading} type='primary' label='Update' onClick={() => handleFinishAssigneeGroups()} />
      ]}
    >
      {isLoading && <KoLoader circle={false} />}
      <div className={styles.suspend}>
        <AssigneeGroupCrud  handleFinishAssigneeGroups={(data)=>setSelected(data)}
          selected={selected}/>
      </div>
    </KoModal>

    <KoModal
      title={breadCurmbs ? <KoBreadcrumb items={breadCurmbs} /> : currentRecord?.ticketId ? `Update Issue ${currentRecord?.ticketId?.toUpperCase()}` : `Create Issue`}
      centered
      open={isTicketOpen}
      afterClose={() => {setCurrentRecord();}}
      handleOk={() => {setCurrentRecord(); setTicketModal(false); }}
      onClose={() => {setCurrentRecord(); setTicketModal(false); }}
      width={'95vw'}
      height={'95vh'}
      footer={[
        <KoButton key='cancel' label='Cancel' onClick={() => setTicketModal(!isTicketOpen)} />,
        <KoButton key='create' loading={isLoading} type='primary' label={currentRecord?.ticketId ? `Update` : `Create`}
          onClick={() => form.submit()} />
      ]}
    >
      {isLoading && <KoLoader circle={false} />}
      <TaskCrud form={form} handleFinish={handleTaskFinish} initialValues={currentRecord} workspaceProfile={profiles} getIssueById={getIssueById}/>
    </KoModal>

    <KoModal
      title={`${currentRecord?.workspaceId ? `Update` : `Create`} settings`}
      centered
      open={openSettings}
      afterClose={() => {setCurrentRecord();}}
      handleOk={() => {setOpenSettings(false); }}
      onClose={() => {setOpenSettings(false); }}
      width={'70%'}
      footer={[
        <KoButton key='cancel' label='Cancel' onClick={() => setOpenSettings(!openSettings)} />,
        <KoButton key='create' loading={isLoading} type='primary' label={currentRecord?.workspaceId ? `Update` : `Create`}
          onClick={() => form.submit()} />
      ]}
    >
      {isLoading && <KoLoader circle={false} />}
      <WorkspaceSettings form={form} initialValues={currentRecord} workspaceList={workspaceList} handleFinish={handleFinish}/>
    </KoModal>
  </div>;
}

export default Workspace;
