import React from "react";
function Avatar4(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <g id="users_48px"  transform="translate(-266 -374)">
        <g id="user_2_" transform="translate(271.143 382.571)">
          <g id="Group_12744" transform="translate(6.102 0)">
            <g id="Group_12743">
              <circle id="Ellipse_277" cx="7.119" cy="7.119" r="7.119" fill="#aeb8c6" />
            </g>
          </g>
          <g id="Group_12746" transform="translate(22.375 8.136)">
            <g id="Group_12745" transform="translate(0 0)">
              <circle id="Ellipse_278" cx="5.085" cy="5.085" r="5.085" fill="#5b6374" />
            </g>
          </g>
          <g id="Group_12748" transform="translate(0 16.273)">
            <g id="Group_12747" transform="translate(0 0)">
              <path id="Path_30768" d="M119.888 256a13.237 13.237 0 0 0-13.222 13.222 1.018 1.018 0 0 0 1.017 1.017h24.409a1.017 1.017 0 0 0 1.017-1.017A13.237 13.237 0 0 0 119.888 256z" fill="#5b6374" transform="translate(-106.666 -256)" />
            </g>
          </g>
          <g id="Group_12750" transform="translate(25.563 20.341)">
            <g id="Group_12749" transform="translate(0)">
              <path id="Path_30769" d="M376.661 298.667a9.119 9.119 0 0 0-1.9.22 15.16 15.16 0 0 1 2.915 8.934 3.019 3.019 0 0 1-.187 1.017h7.311a1.018 1.018 0 0 0 1.017-1.017 9.165 9.165 0 0 0-9.156-9.154z" fill="#aeb8c6" transform="translate(-374.763 -298.667)" />
            </g>
          </g>
        </g>
      </g>
    </svg>

  );
}


export default Avatar4;