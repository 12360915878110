import React, { useState, useEffect } from 'react';
import { TaskPlannerAPI } from '../taskPlanner.service';
import { KoDateFormatter, KoDP, KoEditorReadOnly, KoLoader, KoTitle } from 'packages';
import styles from './activities.module.scss';
export default function Activities({workspaceId, ticketId}) {
  const [loading, setLoading] = useState(false);
  const [activitiesList, setActivitiesList] = useState();

  useEffect(() => {
    setLoading(true);
    TaskPlannerAPI.getActivities(ticketId, workspaceId).then((res) => {
      setActivitiesList(res['history']);
      setLoading(false);
    });
  }, []);
  return (
    <div className={styles.wrapper}>
      {loading && <KoLoader />}
      {
        activitiesList?.map((activities, index) => {
          return (
            <div key={index} className={styles.commentContainer}>
              <div className={styles.commentWrapper}>
                <div className={styles.commentHeader}>
                  <div className={styles.commentPic}>
                    <KoDP width={35} height={35} logo={activities?.profile?.logo} />
                  </div>
                  <div className={styles.commentName}>
                    <KoTitle level={4} className={styles.name} text={activities?.profile?.firstName} />
                    <KoTitle level={4} className={styles.name}  text={activities?.profile?.lastName} />
                  </div>
                  <div className={styles.dot}/>
                  <KoDateFormatter data={activities?.modifiedAt}  format={'DD-MMM-YYYY hh:mm a'}/>
                </div>
                <div className={styles.comment}>
                  <div className={styles.element}>
                    <KoEditorReadOnly initialValue={activities?.changes?.acceptanceCriteria} />
                  </div>
                </div>
              </div>
            </div>
          );
        })
      }
    </div>
  );
}
