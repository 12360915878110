import React from 'react';
import { Modal } from 'antd';
import {KoIcon, KoTitle} from '../index';
function KoModal(props) {
  const { footer, open, title, onClose, handleOk } = props;
  return (<Modal
    open={open}
    title={title ? <KoTitle text={props.title} level={3}/> : false}
    onOk={handleOk}
    destroyOnClose={true}
    closeIcon={<KoIcon name="CloseOutlined" height={12} width={12}/>}
    {...props}
    onCancel={onClose}
    footer={footer ? footer : false}>
    {props.children}
  </Modal>
  );
}


export default KoModal;