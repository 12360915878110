import React from "react";
function Left(props) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 32 32"
      width={width}
      height={height}
    >
      <path
        d="M2 5h28a1 1 0 000-2H2a1 1 0 000 2zm24 23a1 1 0 00-1-1H7a1 1 0 000 2h18a1 1 0 001-1zM7 11a1 1 0 000 2h18a1 1 0 000-2zM2 21h28a1 1 0 000-2H2a1 1 0 000 2z"
        className="color000 svgShape"
      />
    </svg>
  );
}


export default Left;


