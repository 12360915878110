import React from "react";
function Menu(props) {
  const { width, height } = props;
  return (
    <svg fill="var(--palette-gray-7)" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 28 28">
      <defs>
        <clipPath id="77307d3upa">
          <path data-name="Rectangle 18223" transform="translate(564 33)" d="M0 0h28v28H0z" />
        </clipPath>
      </defs>
      <g >
        <path data-name="Path 78148" d="M12.819-10.8a2.766 2.766 0 0 1 2.021-.883 2.766 2.766 0 0 1 2.021.883 2.808 2.808 0 0 1 .876 2.037 2.808 2.808 0 0 1-.876 2.037 2.766 2.766 0 0 1-2.021.883 2.766 2.766 0 0 1-2.021-.883 2.808 2.808 0 0 1-.876-2.037 2.808 2.808 0 0 1 .876-2.037zm8.623 0a2.766 2.766 0 0 1 2.021-.883 2.766 2.766 0 0 1 2.021.883 2.808 2.808 0 0 1 .876 2.04 2.808 2.808 0 0 1-.876 2.037 2.766 2.766 0 0 1-2.021.883 2.766 2.766 0 0 1-2.021-.883 2.808 2.808 0 0 1-.876-2.037 2.808 2.808 0 0 1 .876-2.04zM4.2-10.8a2.766 2.766 0 0 1 2.021-.883 2.766 2.766 0 0 1 2.021.883 2.808 2.808 0 0 1 .872 2.04 2.808 2.808 0 0 1-.876 2.037 2.766 2.766 0 0 1-2.021.883A2.766 2.766 0 0 1 4.2-6.723a2.808 2.808 0 0 1-.88-2.037 2.808 2.808 0 0 1 .88-2.04z" transform="translate(-.911 22.855)"  />
      </g>
    </svg>
  );
}

export default Menu;



