const DONUT = {
  tooltip: {
    trigger: 'item'
  },
  legend: {
    top: '20%',
    left: '-1%',
    orient: 'vertical',
    show: true,
    icon: 'circle'
  },
  color: ["#FE9082", "#D3D7E7", "#4D579F", "#3179D4"],
  series: [
    {
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      left: '23%',
      showEmptyCircle: true,
      top: '-8%',
      data: [
        { value: 234, name: 'Testing' },
        { value: 200, name: 'Production' },
        { value: 180, name: 'UAT' },
        { value: 100, name: 'Demo' }
      ].sort(function(a, b) {
        return a.value - b.value;
      }),
      labelLine: {
        smooth: 0.2,
        length: 7
      },
      label: {
        formatter: '{d}',
        color: "var(--palette-gray-7)"
      },
      animationType: 'scale',
      animationEasing: 'elasticOut',
      animationDelay: function() {
        return Math.random() * 200;
      }
    }
  ],
  height: "100%"
};

const DONUTBYISSUETYPE = {
  tooltip: {
    trigger: 'item'
  },
  series: [
    {
      type: 'pie',
      radius: ['40%', '70%'],
      avoidLabelOverlap: false,
      left: '-1%',
      showEmptyCircle: true,
      top: '-8%',
      data: [

        { value: 180, name: 'In Progress' },
        { value: 100, name: 'With QA' },
        { value: 100, name: 'In Review' },
        { value: 120, name: 'Done' },
        { value: 10, name: 'On Hold' },
        { value: 40, name: 'QA Failed' },
        { value: 10, name: 'Abandoned' },
        { value: 5, name: 'Blocked' }
      ].sort(function(a, b) {
        return a.value - b.value;
      }),
      labelLine: {
        smooth: 0.2,
        length: 7
      },
      label: {
        formatter: '{b} {c}',
        color: "var(--palette-gray-7)"
      },
      animationType: 'scale',
      animationEasing: 'elasticOut',
      animationDelay: function() {
        return Math.random() * 200;
      }
    }
  ],
  height: "100%"
};

const TASKBYTYPE = [{
  assignee: [{ name: 'John', id: '10' }],
  issueType: [{
    value: 5, label: 'story', count: 30
  }, {
    value: 6, label: 'story', count: 40
  }, {
    value: 4, label: 'story', count: 30
  }]
}, {
  assignee: [{ name: 'Hema', id: '10' }],
  issueType: [{
    value: 3, label: 'story', count: 30
  }, {
    value: 2, label: 'story', count: 40
  }, {
    value: 4, label: 'story', count: 30
  }]

}, {
  assignee: [{ name: 'Dino', id: '10' }],

  issueType: [{
    value: 1, label: 'story', count: 30
  }, {
    value: 4, label: 'story', count: 40
  }, {
    value: 8, label: 'story', count: 30
  }]
}, {
  assignee: [{ name: 'Kamalakannan K', id: '10' }],

  issueType: [{
    value: 1, label: 'story', count: 30
  }, {
    value: 9, label: 'story', count: 40
  }, {
    value: 4, label: 'story', count: 30
  }]
}];

const ISSUESTATUS = {
  legend: {
    top: '4%',
    bottom: '2%',
    right: '0%',
    show: true,
    icon: 'circle'
  },
  tooltip: {
    trigger: 'item'
  },
  color: ["var(--palette-info-main)", "var(--palette-warning-main)", "var(--palette-success-main)"],
  dataset: {

    source: [
      ['product', '2-Aug', '3-Aug', '4-Aug', '5-Aug', '6-Aug', '7-Aug', '8-Aug', '9-Aug', '10-Aug'],
      ['Created', 6.5, 82.1, 88.7, 70.1, 53.4, 85.1, 0, 0, 0],
      ['Inprogress', 0.1, 62.2, 69.5, 36.4, 45.2, 32.5, 2, 3, 4],
      ['Resolved', 51.1, 51.4, 55.1, 53.3, 73.8, 68.7, 1, 1, 1]

    ]
  },
  xAxis: { type: 'category' },
  yAxis: { gridIndex: 0 },
  series: [
    {
      left: '-1%',
      type: 'line',
      smooth: true,
      seriesLayoutBy: 'row',
      emphasis: { focus: 'series' }
    },
    {
      left: '-1%',
      type: 'line',
      smooth: true,
      seriesLayoutBy: 'row',
      emphasis: { focus: 'series' }
    },
    {
      left: '-1%',
      type: 'line',
      smooth: true,
      seriesLayoutBy: 'row',
      emphasis: { focus: 'series' }
    }
  ]
};


let xAxisData = [];
let data1 = [];
let data2 = [];
let data3 = [];
let data4 = [];
for (let i = 0; i < 10; i++) {
  xAxisData.push('Class' + i);
  data1.push(+(Math.random() * 2).toFixed(2));
  data2.push(+(Math.random() * 5).toFixed(2));
  data3.push(+(Math.random() + 0.3).toFixed(2));
  data4.push(+Math.random().toFixed(2));
}


const grid = {
  left: '0%',
  right: '0%',
  bottom: '0%',
  containLabel: true
};
const series = [
  {
    name: 'Story',
    type: 'bar',
    stack: 'total',
    label: {
      show: true
    },
    emphasis: {
      focus: 'series'
    },
    data: [320, 302, 301, 334, 390, 330, 320]
  },
  {
    name: 'Task',
    type: 'bar',
    stack: 'total',
    label: {
      show: true
    },
    emphasis: {
      focus: 'series'
    },
    data: [120, 132, 101, 134, 90, 230, 210]
  },
  {
    name: 'Sub Task',
    type: 'bar',
    stack: 'total',
    label: {
      show: true
    },
    emphasis: {
      focus: 'series'
    },
    data: [220, 182, 191, 234, 290, 330, 310]
  },
  {
    name: 'Bug',
    type: 'bar',
    stack: 'total',
    label: {
      show: true
    },
    emphasis: {
      focus: 'series'
    },
    data: [150, 212, 201, 154, 190, 330, 410]
  },
  {
    name: 'Support',
    type: 'bar',
    stack: 'total',
    label: {
      show: true
    },
    emphasis: {
      focus: 'series'
    },
    data: [150, 212, 201, 154, 190, 330, 410]
  },
  {
    name: 'Query',
    type: 'bar',
    stack: 'total',
    label: {
      show: true
    },
    emphasis: {
      focus: 'series'
    },
    data: [820, 832, 901, 934, 2, 130, 0],
    barMinHeight: 15,
    itemStyle: {
      borderRadius: [4, 4, 0, 0] // Adding border radius here
    }
  }
];



const TITKETSTACK = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  color: [
    "var(--story)",
    "var(--task)",
    "var(--subtask)",
    "var(--bug)",
    "var(--support)",
    "var(--query)"],
  legend: {
    top: '4%',
    bottom: '2%',
    right: '0%',
    show: true,
    icon: 'circle'
  },
  grid,
  yAxis: {
    type: 'value'
  },
  xAxis: {
    type: 'category',
    data: ['Bug', 'Task', 'Story', 'Query', 'Sub Task', 'Support']
  },
  series
};
const SPR_ITEMS_COLUMNS = [{
  name: "status",
  id: "status",
  label: "Status",
  widget: "taskstatus",
  isLog:true,
  width: 100
}, {
  name: "count",
  id: "count",
  label: "Count",
  widget: "number",
  width: 100
}, {
  name: "percentage",
  id: "percentage",
  label: "Percentage",
  widget: "percent",
  width: 200
}];

const ISSUE_STATUS_COLUMNS = [
  {
    name: "assignee",
    id: "assignee",
    label: "Assignee",
    widget: "profile"
  }, {
    name: "epic",
    id: "epic",
    label: "Epic",
    widget: "number",
    width:100
  }, {
    name: "story",
    id: "story",
    label: "Story",
    widget: "number",
    width:100
  }, {
    name: "task",
    id: "task",
    label: "Task",
    widget: "number",
    width:100
  }, {
    name: "subtask",
    id: "subtask",
    label: "Sub Task",
    widget: "number",
    width:100
  }, {
    name: "bug",
    id: "bug",
    label: "Bug",
    widget: "number",
    width:100
  }, {
    name: "improvement",
    id: "improvement",
    label: "Improvement",
    widget: "text"
  }, {
    name: "featureRequest",
    id: "featureRequest",
    label: "Feature Request",
    widget: "text"
  }, {
    name: "changeRequest",
    id: "changeRequest",
    label: "Change Request",
    widget: "text"
  }, {
    name: "support",
    id: "support",
    label: "Support",
    widget: "text"
  }, {
    name: "totalCount",
    id: "totalCount",
    label: "Total",
    widget: "dashboardLink",
    width: '10rem',
    fixed: 'right',
    align: 'center'
  }
];

const ISSUE_STATUS_COLUMNS_BUG = [
  {
    name: "assignee",
    id: "assignee",
    label: "Assignee",
    widget: "profile"
  }, {
    name: "todo",
    id: "todo",
    label: "Todo",
    widget: "number",
    width:100
  }, {
    name: "inprogress",
    id: "inprogress",
    label: "Working",
    widget: "number",
    width:100
  }, {
    name: "inQA",
    id: "inQA",
    label: "Ready For QA",
    widget: "number",
    width:200
  }
  , {
    name: "done",
    id: "done",
    label: "Done",
    widget: "text"
  }, {
    name: "inReview",
    id: "inReview",
    label: "In Review",
    widget: "number",
    width:100
  }, {
    name: "QAFailed",
    id: "QAFailed",
    label: "QA Failed",
    widget: "number",
    width:100
  }, {
    name: "blocked",
    id: "blocked",
    label: "Blocked",
    widget: "number"
  }, {
    name: "onHold",
    id: "onHold",
    label: "on Hold",
    widget: "number"
  }, {
    name: "deferred",
    id: "deferred",
    label: "deferred",
    widget: "number"
  }, {
    name: "totalCount",
    id: "totalCount",
    label: "Total",
    widget: "dashboardLink",
    width: 100,
    fixed: 'right',
    align: 'center'
  }
];

const TICKET_ITEMS_COLUMNS = [
  {
    name: "ticketId",
    id: "ticketId",
    label: "Ticket Id's",
    width:130,
    widget: "ticketLink",
    fixed: true
  },

  {
    name: "summary",
    id: "summary",
    label: "Summary",
    width:300,
    widget: "task"
  },
  {
    name: "status",
    id: "status",
    label: "Status",
    widget: "taskstatus",
    isLog:true,
    width:150
  },
  {
    name: "priority",
    id: "priority",
    label: "Priority",
    widget: "priority",
    width:130
  },
  {
    name: "assignee",
    id: "assignee",
    label: "Assignee",
    widget: "assignees"
  },
  {
    name: "attachments",
    id: "attachments",
    label: "Attachments",
    widget: "attachments"
  },
  {
    name: "reporter",
    id: "reporter",
    label: "Reporter",
    widget: "assignees"
  }];


export {TICKET_ITEMS_COLUMNS, DONUT, ISSUE_STATUS_COLUMNS_BUG, TITKETSTACK, TASKBYTYPE, ISSUESTATUS, DONUTBYISSUETYPE, SPR_ITEMS_COLUMNS, ISSUE_STATUS_COLUMNS };