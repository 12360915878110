import React from "react";
function Folder4(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 66.942 50.551">
      <defs>
        <linearGradient id="4yivwta68b" x1=".882" y1="1.781" x2=".125" y2="-.285" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#ff9517" stopOpacity="0" />
          <stop offset=".307" stopColor="#ee8421" stopOpacity=".306" />
          <stop offset=".678" stopColor="#e07728" stopOpacity=".678" />
          <stop offset="1" stopColor="#db722b" />
        </linearGradient>
        <linearGradient id="yv8iya5ejc" x1=".5" y1="-.356" x2=".5" y2="1.011" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#fffcf8" />
          <stop offset=".188" stopColor="#faf8f6" />
          <stop offset=".404" stopColor="#eceef2" />
          <stop offset=".635" stopColor="#d5dcea" />
          <stop offset=".875" stopColor="#b5c4df" />
          <stop offset="1" stopColor="#a1b5d8" />
        </linearGradient>
        <linearGradient id="hfk1pv66da" x1=".993" y1=".572" x2=".714" y2=".535" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#a1b5d8" stopOpacity="0" />
          <stop offset=".67" stopColor="#8190a4" stopOpacity=".671" />
          <stop offset="1" stopColor="#748290" />
        </linearGradient>
        <linearGradient id="8mq6s58d4d" x1=".869" y1="2.371" x2=".149" y2="-1.02" href="#hfk1pv66da" />
        <linearGradient id="xq6nfqxdfe" x1=".5" y1=".137" x2=".5" y2=".843" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#ff4974" />
          <stop offset=".228" stopColor="#fd4571" />
          <stop offset=".466" stopColor="#f93867" />
          <stop offset=".71" stopColor="#f12358" />
          <stop offset=".955" stopColor="#e60642" />
          <stop offset="1" stopColor="#e4003d" />
        </linearGradient>
      </defs>
      <path data-name="Path 30350" d="M167.551 50.551h-44.375a2.125 2.125 0 0 1-2.125-2.125V5.664A5.664 5.664 0 0 1 126.715 0H166.5a5.664 5.664 0 0 1 5.664 5.664v40.272a4.615 4.615 0 0 1-4.613 4.615zm0 0" transform="translate(-105.224)" fill="#614df4" />
      <path data-name="Path 30351" d="M123.176 75.063h44.375a4.615 4.615 0 0 0 4.615-4.615V33.923l-4.413-4.413a3.926 3.926 0 0 0-2.932-1.311h-43.415a5.65 5.65 0 0 0-.355 1.977v42.762a2.125 2.125 0 0 0 2.125 2.125zm0 0" transform="translate(-105.224 -24.512)" fill="#7562fa" />
      <path data-name="Path 30352" d="M131.911 4.254a5.913 5.913 0 0 0 5.634 4.117h15.693a5.863 5.863 0 0 1-.616-.033l-6.372-6.366a5.909 5.909 0 0 0-4.28-1.835h-6.679a5.9 5.9 0 0 0-4.506 2.086 5.9 5.9 0 0 1 1.126 2.031zm0 0" transform="translate(-113.685 -.119)" fill="url(#4yivwta68b)" />
      <path data-name="Path 30353" d="M85.851 75.06H34.139a3.936 3.936 0 0 1-3.939-3.936V32.131a3.936 3.936 0 0 1 3.939-3.931h51.712a3.936 3.936 0 0 1 3.936 3.936v38.988a3.936 3.936 0 0 1-3.936 3.936zm0 0" transform="translate(-26.254 -24.509)" fill="url(#yv8iya5ejc)" />
      <path data-name="Path 30354" d="M84.31 64.976a5.893 5.893 0 0 0-4.3-1.855H30.2v38.362a3.936 3.936 0 0 0 3.936 3.936h51.715a3.936 3.936 0 0 0 3.936-3.936v-31.03zm0 0" transform="translate(-26.254 -54.868)" fill="url(#hfk1pv66da)" />
      <path data-name="Path 30355" d="M138.3 28.644a5.913 5.913 0 0 0 5.634 4.117h15.693a5.867 5.867 0 0 1-.616-.033l-4.539-4.528h-16.34c.061.142.117.291.168.444zm0 0" transform="translate(-120.072 -24.509)" fill="url(#8mq6s58d4d)" />
      <path data-name="Path 30356" d="M144.379 9.862v-1.49h-.5a5.913 5.913 0 0 1-5.633-4.117 5.913 5.913 0 0 0-5.631-4.118h-6.679a5.913 5.913 0 0 0-5.913 5.913v3.812zm0 0" transform="translate(-104.331 -.119)" fill="url(#xq6nfqxdfe)" />
      <path data-name="Path 30357" d="M18.225 4.254A5.912 5.912 0 0 0 12.592.137H5.913A5.913 5.913 0 0 0 0 6.049V44.83a5.839 5.839 0 0 0 5.839 5.84h55.445a1.614 1.614 0 0 1-1.614-1.614V14.284a5.913 5.913 0 0 0-5.913-5.913h-29.9a5.913 5.913 0 0 1-5.632-4.117zm0 0" transform="translate(0 -.119)" fill="#8675fb" />
    </svg>

  );
}


export default Folder4;