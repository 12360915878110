import React from "react";
function LastSprint(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path d="M10.9905 1.02429C8.90111 1.23506 7.02666 1.9663 5.34567 3.22662C4.81257 3.62666 3.87964 4.53426 3.43252 5.08914C1.11955 7.9582 0.397285 11.8252 1.51938 15.3395C1.9235 16.6127 2.59418 17.8558 3.44972 18.914C3.87964 19.4516 4.82117 20.3636 5.34567 20.7593C6.45917 21.5938 7.75753 22.239 9.03009 22.596C12.3534 23.5165 15.883 22.8455 18.6431 20.7722C19.1633 20.3765 20.0146 19.5592 20.4789 19.0043C21.2269 18.1096 21.9664 16.8149 22.3576 15.7137C23.0928 13.6361 23.2003 11.4166 22.6629 9.28305C22.2888 7.79045 21.515 6.25483 20.5348 5.04613C20.1091 4.52135 19.1676 3.60945 18.6431 3.21372C17.0868 2.04803 15.3026 1.31678 13.3766 1.0673C12.8822 1.00278 11.4677 0.976969 10.9905 1.02429ZM12.8435 2.83519C16.0937 3.1492 18.8925 5.11065 20.3026 8.05714C21.0077 9.53253 21.3129 11.2316 21.1538 12.836C20.84 16.0879 18.8796 18.8882 15.9346 20.299C13.4153 21.5034 10.509 21.4862 7.98108 20.2517C5.07482 18.8279 3.16167 16.0621 2.84783 12.836C2.55979 9.89815 3.79796 6.86994 6.05504 4.99021C8.00258 3.36857 10.423 2.59861 12.8435 2.83519Z" fill="black" />
      <path d="M11.2012 7.02049C11.0851 7.07211 6.2614 10.9864 5.90027 11.3219C5.50044 11.6919 5.50044 12.2941 5.90027 12.664C6.3001 13.0382 11.0894 16.9181 11.2141 16.9654C11.5623 17.116 12.0567 16.9611 12.2846 16.6299L12.4093 16.4535L12.4222 15.1889C12.4308 14.4964 12.4437 13.9286 12.4566 13.9286C12.4695 13.9286 13.2777 14.5824 14.2493 15.3782C15.2252 16.1783 16.1066 16.8751 16.2098 16.9267C16.6913 17.1762 17.2975 16.9095 17.4565 16.3847C17.5253 16.1524 17.5253 7.83346 17.4565 7.60118C17.2975 7.07641 16.6913 6.80972 16.2098 7.0592C16.1066 7.11082 15.2252 7.80765 14.2493 8.60772C13.2777 9.40349 12.4695 10.0573 12.4566 10.0573C12.4437 10.0573 12.4308 9.50672 12.4308 8.84C12.4308 7.6442 12.4308 7.61409 12.3319 7.42913C12.1384 7.06351 11.5666 6.85273 11.2012 7.02049ZM10.6165 13.0898L10.6036 14.191L9.27944 13.107C8.55288 12.5134 7.96389 12.0059 7.96819 11.9844C7.97679 11.9585 8.57437 11.4596 9.29234 10.8703L10.6036 9.79922L10.6165 10.8961C10.6208 11.4983 10.6208 12.4876 10.6165 13.0898ZM15.6982 11.993C15.6982 13.1974 15.6896 14.1867 15.6767 14.1867C15.6337 14.1867 13.0112 12.0274 13.0112 11.993C13.0112 11.9542 15.6165 9.80782 15.6681 9.80352C15.6853 9.79922 15.6982 10.7843 15.6982 11.993Z" fill="black" />
    </svg>
  );
}


export default LastSprint;