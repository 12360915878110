function DoubleQuotes(props) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      width={width}
      height={height}
    >
      <g clipPath="url(#a)" fill="#000" className="color000 svgShape">
        <path
          fill="#272848"
          d="M19.417 6.679C20.447 7.773 21 9 21 10.989c0 3.283-2.161 6.246-5.378 7.881a.81.81 0 01-1.044-.298c-.27-.417-.124-.973.292-1.243 2.65-1.725 3.216-3.8 3.454-5.151-.537.278-1.24.375-1.929.311-1.804-.167-3.226-1.648-3.226-3.489a3.5 3.5 0 013.5-3.5c1.073 0 2.099.49 2.748 1.179zm-10 0C10.447 7.773 11 9 11 10.989c0 3.283-2.161 6.246-5.378 7.881a.81.81 0 01-1.044-.298c-.27-.417-.124-.973.292-1.243 2.65-1.725 3.216-3.8 3.454-5.151-.537.278-1.24.375-1.929.311C4.591 12.322 3.17 10.841 3.17 9a3.5 3.5 0 013.5-3.5c1.073 0 2.099.49 2.748 1.179h-.001z"
          className="color272848 svgShape"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" className="colorfff svgShape" />
        </clipPath>
      </defs>
    </svg>
  );
}


export default DoubleQuotes;