import React from "react";
function ProjectAvatar(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <g id="avatar_5" transform="translate(.5 .5)">
        <path id="Path_15844" data-name="Path 15844" d="M17.435 21.459H4.024A4.023 4.023 0 0 1 0 17.435V4.024A4.023 4.023 0 0 1 4.024 0h13.411a4.023 4.023 0 0 1 4.024 4.024v13.411a4.023 4.023 0 0 1-4.024 4.024z" fill="#f2fdff" stroke="#c0f4fc" />
        <g id="leaf" transform="translate(4.471 4.924)">
          <path id="Path_30368" data-name="Path 30368" fill="#00b9a0" d="M75.319 56.519a5.268 5.268 0 0 0-1.424.974 4.82 4.82 0 0 0-.086 6.835 4.97 4.97 0 0 0 6.9-.018 5.073 5.073 0 0 0 .965-1.445l-5.256-1.154z" transform="translate(-70.682 -55.161)" />
          <path id="Path_30369" data-name="Path 30369" fill="#00cb75" d="m198.137 16.307-1.77-2.1a13.048 13.048 0 0 0-3.439 1.017l.033 6.259 6.324.087a13.491 13.491 0 0 0 .977-3.474z" transform="translate(-188.292 -13.865)" />
          <path id="Path_30370" data-name="Path 30370" d="M339.775 4.242A27.768 27.768 0 0 0 340.1.57a.546.546 0 0 0-.559-.558 26.164 26.164 0 0 0-3.659.341v3.874z" transform="translate(-327.804 -.012)" fill="#70d840" />
          <path id="Path_30371" data-name="Path 30371" fill="#009398" d="M129.421 274.288a4.97 4.97 0 0 0 6.9-.018 5.074 5.074 0 0 0 .965-1.445l-5.256-1.154v-.022l-2.623 2.623z" transform="translate(-126.295 -265.121)" />
          <path id="Path_30372" data-name="Path 30372" fill="#00b9a0" d="m194.316 106.213 6.324.087a13.49 13.49 0 0 0 .977-3.474l-2.125-1.789-.012-.014-5.163 5.163z" transform="translate(-189.646 -98.596)" />
          <path id="Path_30373" data-name="Path 30373" fill="#00cb75" d="m335.876 10.083 3.9.015a27.762 27.762 0 0 0 .322-3.672.545.545 0 0 0-.176-.414l-4.045 4.045z" transform="translate(-327.804 -5.868)" />
          <path id="Path_30374" data-name="Path 30374" fill="#009398" d="M12.293.528A.546.546 0 0 0 11.767 0L.109 11.662a.371.371 0 1 0 .525.525L12.2.617z" transform="translate(0 -.003)" />
          <path id="Path_30375" data-name="Path 30375" d="M3.909 18.046a.371.371 0 0 0 .525 0L16 6.476l.089-.089a.544.544 0 0 0-.176-.381L3.894 18.029l.015.017z" transform="translate(-3.8 -5.862)" fill="#007578" />
        </g>
      </g>
    </svg>

  );
}


export default ProjectAvatar;