import React from "react";
import isString from 'lodash/isString';
import { useSelector } from "react-redux";
import KoDP from "../../../dp";
import styles from './user.module.scss';
import KoAvatar from "packages/avatar";
import { KoText } from "packages/typography";

function Assignees(props) {
 
  const users = useSelector(state => state?.users);
  let { data } = props;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  let isValid = emailRegex.test(data);
  function findMatchingUsers(users, data) {
    if (!Array.isArray(users) || !Array.isArray(data)) {
      return [];
    }
    
    // return users.filter(user => data.includes(user.userId));
    return users.filter(user => user?.userId && data.includes(user.userId));
  }
  let matchingUsers = findMatchingUsers(users['data'], data);
  
  if(isString(data)){
    matchingUsers = isValid ? data : findMatchingUsers(users['data'], [data]);
  }
  
  return (
    !isValid ?<div className={styles.wrapper}>
      { matchingUsers?.map((user)=>
        <div key={user.userId} className={user?.profile?.logo?.length > 0 ? styles.bgImage :styles.userWrapper}>
          <div className={`${styles.circle}  ${styles[user?
            user?.profile?.firstName?.charAt(0).toLowerCase() :'a']}`}>
            {user?.profile?.logo?.length > 0  ?
              <KoDP className={styles.dp} width={19} height={19} logo={user?.profile?.logo}/>
              :
              <span className={`${styles.letter}`}>{user?.profile?.firstName?.charAt(0)}</span>}
          </div>
          <div className={`${styles.text}`}>{`${user?.profile?.firstName ? user?.profile?.firstName:''} ${user?.profile?.lastName ? user?.profile?.lastName:''}`}</div>
        </div>
      )}
    </div>:
      <div className={styles.wrapper}>
        <div className={`${styles.text} `}>
          <KoAvatar inLine={'0rem'} options={[{ name: data }]} size={20} />
          <KoText text={data} style={{ paddingLeft: '.4rem' }}/>
        </div>
      </div>
  );
}
export default Assignees;

