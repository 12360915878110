import React from "react";
function Bug(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="bug">
        <g fill="red">
          <path id="Vector" d="M22 7C22.5304 7 23.0391 6.78929 23.4142 6.41421C23.7893 6.03914 24 5.53043 24 5V0H22V5H17.964C17.4797 3.81699 16.6544 2.80486 15.593 2.09247C14.5316 1.38008 13.2823 0.999672 12.004 0.999672C10.7257 0.999672 9.47635 1.38008 8.41498 2.09247C7.35361 2.80486 6.52825 3.81699 6.044 5H2V0H0V5C0 5.53043 0.210714 6.03914 0.585786 6.41421C0.960859 6.78929 1.46957 7 2 7H5.485L4.472 11H0V13H3.965L3.82 13.573L3.8 13.7C3.75541 14.0422 3.73203 14.3869 3.73 14.732C3.72891 15.499 3.83493 16.2624 4.045 17H2C1.46957 17 0.960859 17.2107 0.585786 17.5858C0.210714 17.9609 0 18.4696 0 19L0 24H2V19H4.919C5.65461 20.2203 6.6931 21.2298 7.93376 21.9306C9.17441 22.6314 10.5751 22.9996 12 22.9996C13.4249 22.9996 14.8256 22.6314 16.0662 21.9306C17.3069 21.2298 18.3454 20.2203 19.081 19H22V24H24V19C24 18.4696 23.7893 17.9609 23.4142 17.5858C23.0391 17.2107 22.5304 17 22 17H19.955C20.1662 16.2608 20.2729 15.4957 20.272 14.727C20.2691 14.3835 20.2451 14.0405 20.2 13.7L20.025 13H24V11H19.522L18.513 7H22Z" fill="red" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_76_197">
          <rect width="24" height="24" rx="4" fill="#FFF" />
        </clipPath>
      </defs>
    </svg>

  );
}


export default Bug;