import { forwardRef } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import Card from './card';
import withDroppable from 'packages/kanban/withDroppable';
import CardAdder from './addCard';
import { pickPropOut } from 'packages/kanban/services/utils';
import styles from '../kanban.module.scss';

const ColumnEmptyPlaceholder = forwardRef((props, ref) => (
  <div className={styles.columns} ref={ref} {...props} />
));

const DroppableColumn = withDroppable(ColumnEmptyPlaceholder);

function Column({
  children,
  index: columnIndex,
  renderCard,
  renderColumnHeader,
  disableColumnDrag,
  disableCardDrag,
  onCardNew,
  allowAddCard
}) {
  return (
    <Draggable draggableId={`column-draggable-${children.id}`} index={columnIndex} isDragDisabled={disableColumnDrag}>
      {(columnProvided) => {
        const draggablePropsWithoutStyle = pickPropOut(columnProvided.draggableProps, 'style');

        return (
          <div
            ref={columnProvided.innerRef}
            {...draggablePropsWithoutStyle}
            style={{
              height: '100%',
              minHeight: '28px',
              display: 'inline-block',
              verticalAlign: 'top',
              ...columnProvided.draggableProps.style
            }}
            className={styles.kanbanColumn}
            data-testid={`column-${children.id}`}
          >
            {renderColumnHeader(children)}
            {allowAddCard && <CardAdder column={children} onConfirm={onCardNew} />}
            <DroppableColumn droppableId={String(children.id)}>
              {children.cards.length ? (
                children.cards.map((card, index) => (
                  <Card
                    key={card.id}
                    index={index}
                    renderCard={(dragging) => renderCard(children, card, dragging)}
                    disableCardDrag={disableCardDrag}
                  >
                    {card}
                  </Card>
                ))
              ) : (
                <div className={styles.cardSkeleton} />
              )}
            </DroppableColumn>
          </div>
        );
      }}
    </Draggable>
  );
}

export default Column;
