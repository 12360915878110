import React from "react";
function InvoiceTemplete(props) {
  const { width, height } = props;
  return (
    <svg
      fill="none"
      height={height}
      viewBox="0 0 200 200"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#919eab">
        <path
          d="M52 50.588C52 44.74 56.74 40 62.588 40h74.118c5.848 0 10.588 4.74 10.588 10.588v98.824c0 5.847-4.74 10.588-10.588 10.588H62.588C56.741 160 52 155.259 52 149.412z"
          opacity={0.24}
        />
        <g opacity={0.24}>
          <path d="M66.118 113.235a4.412 4.412 0 014.411-4.412h58.236a4.411 4.411 0 010 8.824H70.529a4.412 4.412 0 01-4.411-4.412zM66.118 129.117a4.411 4.411 0 014.411-4.411h58.236a4.411 4.411 0 010 8.823H70.529a4.412 4.412 0 01-4.411-4.412z" />
        </g>
        <g opacity={0.48}>
          <path d="M66.118 65.588a4.412 4.412 0 014.411-4.412H97A4.412 4.412 0 1197 70H70.53a4.412 4.412 0 01-4.412-4.412zM66.118 97.353a4.412 4.412 0 014.411-4.412H97a4.412 4.412 0 110 8.824H70.53a4.412 4.412 0 01-4.412-4.412zM66.118 81.47a4.412 4.412 0 014.411-4.411H97a4.412 4.412 0 110 8.823H70.53a4.412 4.412 0 01-4.412-4.412z" />
        </g>
        <path d="M130.08 81.696a.776.776 0 011.314.564c0 5.2-4.24 9.389-9.464 9.389h-4v3.09c0 .802-.866 1.277-1.553.861l-10.061-6.12a1 1 0 010-1.723l10.031-6.12c.687-.417 1.553.088 1.553.86v3.12h3.791a10.62 10.62 0 007.165-2.792zm-9.105-13.162c0-.802.865-1.278 1.552-.862l10.031 6.12a1 1 0 010 1.724l-10.001 6.09c-.687.416-1.552-.089-1.552-.861v-3.09h-3.822c-2.657 0-5.224 1.01-7.165 2.793l-1.224 1.129c-.477.445-1.284.089-1.284-.565l.002-.155c.083-5.127 4.29-9.233 9.462-9.233h4.001z" />
      </g>
    </svg>
  );
}


export default InvoiceTemplete;