import React from "react";
function Share(props) {
  const { width, height } = props;
  return (
    <svg fill="var(--palette-gray-7)" width={width} height={height} viewBox="0 0 10.46 10.483">
      <g data-name="Group 10484">
        <path data-name="Path 15915" d="m10.258 51.975-4.341-2.824a.34.34 0 0 0-.525.285V50.9a5.953 5.953 0 0 0-4.833 4.258l-.547 1.921a.34.34 0 0 0 .592.3c1.417-1.773 2.356-3.32 4.788-3.649v1.348a.34.34 0 0 0 .525.285l4.341-2.824a.34.34 0 0 0 0-.564z" transform="translate(.412 -48.688)" fill="none"stroke="var(--palette-gray-7)"strokeWidth=".8px" />
      </g>
    </svg>

  );
}


export default Share;