import React from "react";
import MonacoEditor from "@monaco-editor/react";

export const KoMonaco = ({editorOnChange, height="50vh", language, JSONdata, options}) => {
  return (
    <MonacoEditor
      options = {options ? options : {}}
      height={height}
      language={language}
      saveViewState={true}
      value={JSON.stringify(
        JSONdata,
        null,
        2
      )}
      onChange={(data)=>editorOnChange(data)}
    />
  );
};

export default KoMonaco;