import React from 'react';

import { KoForm, KoFormBuilder, KoInput, KoMobile } from 'packages';
import styles from './curd.module.scss';

function BusinessProfileCrud({ form, handleFinish, settings }) {

  const Mobile = ({ value, onChange }) =>
    <KoMobile value={value}
      onChange={v => onChange(v)}
    />;


  const meta = {
    columns: 4,
    initialValues: settings,
    formItemLayout: null,
    colon: true,
    fields: [
      {
        key: 'email', label: 'Email', placeholder: 'Enter business email', colSpan: 2,  widget: ({ value, onChange }) => (
          <KoForm.Item
            name="email"
            rules={[
              {
                type: 'email',
                message: 'Please enter a valid email address!'
              },
              {
                pattern: /^[a-zA-Z0-9._%+-]+@(?!gmail\.com$|yahoo\.com$|hotmail\.com$|outlook\.com$|icloud\.com$)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: 'Personal email addresses are not allowed. Please use a business email.'
              }
            ]}
          >
            <KoInput
              value={value}
              onChange={onChange}
              placeholder="Enter business email"
            />
          </KoForm.Item>
        ),
        forwardRef: true
      },
      {
        key: 'firstName', label: 'First Name', placeholder: 'Enter first name', colSpan: 2, rules: [
          {
            required: true,
            message: 'First name is required'
          }
        ]
      },
      {
        key: 'lastName', label: 'Last Name', placeholder: 'Enter last name', colSpan: 2, rules: [
          {
            required: true,
            message: 'last name is required'
          }
        ]
      }, {
        key: 'mobile', label: 'Phone', placeholder: 'Enter phone no', colSpan: 2, rules: [
          {
            required: true,
            message: 'phone no is required'
          }
        ],
        widget: Mobile,
        forwardRef: true
      }, {
        key: 'designation', label: 'Designation', colSpan: 2,
        forwardRef: true
      },
      {
        key: 'label1',
        colSpan: 4,
        render() {
          return (
            <fieldset>
              <legend>Alternative Contact Person</legend>
            </fieldset>
          );
        }
      },
      {
        key: 'alternativeContact.email',
        label: 'Email',
        placeholder: 'Enter business email',
        colSpan: 2,
        forwardRef: true,
        widget: ({ value, onChange }) => (
          <KoForm.Item
            name="alternativeContact.email"
            rules={[
              {
                type: 'email',
                message: 'Please enter a valid email address!'
              },
              {
                pattern: /^[a-zA-Z0-9._%+-]+@(?!gmail\.com$|yahoo\.com$|hotmail\.com$|outlook\.com$|icloud\.com$|aol\.com$|protonmail\.com$|zoho\.com$|mail\.com$|gmx\.com$|live\.com$|msn\.com$|yandex\.com$|qq\.com$)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                message: 'Personal email addresses are not allowed. Please use a business email.'
              },
              {
                pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|org|net|biz|info|c|io|tech|ai|dev)$/,
                message: 'Please enter a business email with a valid domain.'
              }
            ]}
          >
            <KoInput
              value={value}
              onChange={onChange}
              placeholder="Enter business email"
            />
          </KoForm.Item>
        )
      },
      {
        key: 'alternativeContact.firstName', label: 'First Name', placeholder: 'Enter first name', colSpan: 2
      },
      {
        key: 'alternativeContact.lastName', label: 'Last Name', placeholder: 'Enter last name', colSpan: 2
      },
      {
        key: 'alternativeContact.mobile', label: 'Phone', placeholder: 'Enter phone no', colSpan: 2,
        widget: Mobile,
        forwardRef: true
      }, {
        key: 'alternativeContact.designation', label: 'Designation', colSpan: 2,
        forwardRef: true
      }

    ]
  };

  return (
    <div className={styles.wrapper}>
      <KoFormBuilder form={form} meta={meta} />
    </div>
  );
};

export default BusinessProfileCrud;