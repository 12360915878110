import React from "react";
import { Dropdown } from 'antd';
import KoIcon from "../../../icon";
import styles from './description.module.scss';

function Description(props) {

  const descriptionText = (
    <div className={styles.text}>
      {props.data}
    </div>
  );

  return (
    <div className={styles.descriptionWrapper}>
      <div className={styles.text}>{props.data}</div>
      {props.data && <div className={styles.circle}>
        <Dropdown
          overlayClassName={styles.descriptionText}
          overlay={descriptionText}>
          <KoIcon name={'description'} size={{width:16, height:16}}/>
        </Dropdown>
       
      </div>}
    </div>
  );
}
export default Description;
