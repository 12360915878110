import React from 'react';
import _concat from "lodash/concat";
import styles from './datatable.module.scss';
import { Checkbox, KoDateFormatter, KoIcon, KoText } from '../index';
import User from './widgets/user';
import Status from './widgets/status';
import Groups from './widgets/groups';
import Description from './widgets/description';
import Comments from './widgets/comments';
import Priority from './widgets/priority';
import Members from './widgets/members';
import Methods from './widgets/methods';
import API from './widgets/api';
import Phone from './widgets/phone';
import ExecutionTime from './widgets/time';
import SalaryRange from './widgets/salaryRange';
import Departments from './widgets/departments';
import Assignees from './widgets/assignees';
import TaskStatus from './widgets/taskStatus';
import SprintStatus from './widgets/sprintStatus';
import TicketLink from './widgets/ticketLink';
import InvoiceLink from './widgets/invoiceLink';
import FileLink from './widgets/fileLink';
import PullRequestLink from './widgets/prLink';
import ReleaseStatus from './widgets/releaseStatus';
import Link from './widgets/link';
import Attachments from './widgets/attachments';
import KoEditorReadOnly from '../editor/readOnly';
import InvoiceStatus from './widgets/invoicestatus';
import UserStatus from './widgets/userStatus';
import Percentage from './widgets/percentage';
import DashBoardLink from './widgets/dashboardlink';
import Text from './widgets/text';
import UserRole from './widgets/userRole';
import OptionalStatus from './widgets/optionalstatus';
import MultiTicketLink from './widgets/multiticketLink';
import EventSlot from './widgets/eventSlot';
import Folder from './widgets/folder';
import OverDueStatus from './widgets/overdueStatus';
import Log from './widgets/log';
import Workspace from './widgets/workspace';
import WorkFlowStatus from './widgets/workflowStatus';
import StaicMembers from './widgets/staticMember';
import MultiTicketLinkStatic from './widgets/multiticketLinkstatic';

const serializeColumns = (columns, onCellClick, onExtraClick) => {
  columns.map(function(column) {
    column.dataIndex = column.id;
    column.key = column.id;
    column.ellipsis = true;
    column.sorter = false;
    column.width = column?.width || 200;
    column.filterIcon = <KoIcon name='FilterOutlined'/>;
    column.filtered=true;
    column.render = (data, row, dataIndex) => {
      let dataType = <KoText onCellClick={onCellClick && onCellClick} className={styles.text} text={data} ellipsis={true}/>;
      if (column['widget'] === 'billable') {
        dataType = <KoText onCellClick={onCellClick && onCellClick} className={styles.text} text={row?.isBillable ? data : '00h 00m'} ellipsis={true}/>;
      }else if (column['widget'] === 'nonbillable') {
        dataType = <KoText onCellClick={onCellClick && onCellClick} className={styles.text} text={!row?.isBillable ? data : '00h 00m'} ellipsis={true}/>;
      } else if (column['widget'] === 'profile') {
        dataType = <User onCellClick={onCellClick && onCellClick} data={data} row={row} />;
      }else if (column['widget'] === 'percent') {
        dataType = <Percentage onCellClick={onCellClick && onCellClick} data={data} row={row} />;
      }else if (column['widget'] === 'assignees') {
        dataType = <Assignees onCellClick={onCellClick && onCellClick} data={data} row={row} />;
      }else if (column['widget'] === 'taskstatus') {
        dataType = <TaskStatus  isLog={column?.isLog ? column?.isLog : false}  onCellClick={onCellClick && onCellClick} data={data} row={row} />;
      }else if (column['widget'] === 'taskstatuscob') {
        dataType = <TaskStatus cob={true} onCellClick={onCellClick && onCellClick} data={data} row={row} />;
      }else if (column['widget'] === 'workflowstatus') {
        dataType = <WorkFlowStatus onCellClick={onCellClick && onCellClick} data={data} row={row} />;
      }else if (column['widget'] === 'sprintstatus') {
        dataType = <SprintStatus onCellClick={onCellClick && onCellClick} data={data} row={row} />;
      }else if (column['widget'] === 'releasestatus') {
        dataType = <ReleaseStatus onCellClick={onCellClick && onCellClick} data={data} row={row} />;
      }else if (column['widget'] === 'phone') {
        dataType = <Phone onCellClick={onCellClick && onCellClick} data={data} row={row} />;
      }else if (column['widget'] === 'dateTime') {
        dataType = <KoDateFormatter onCellClick={onCellClick && onCellClick} className={styles.text} format={column?.format} timeZone={column?.timeZone} data={data} row={row} />;
      }else if (column['widget'] === 'date') {
        dataType = <KoDateFormatter onCellClick={onCellClick && onCellClick} className={styles.text} format={column?.format} timeZone={column?.timeZone} data={data} row={row} />;
      } else if (column['widget'] === 'userStatus') {
        dataType = <UserStatus onCellClick={onCellClick && onCellClick} data={data} row={row} />;
      } else if (column['widget'] === 'userRole') {
        dataType = <UserRole onCellClick={onCellClick && onCellClick} data={data} row={row} />;
      }else if (column['widget'] === 'status') {
        dataType = <Status onCellClick={onCellClick && onCellClick} data={data} row={row} />;
      }else if (column['widget'] === 'invoicestatus') {
        dataType = <InvoiceStatus onCellClick={onCellClick && onCellClick} data={data} row={row} />;
      }  else if (column['widget'] === 'bool') {
        dataType = <Status bool={true} onCellClick={onCellClick && onCellClick} data={data} row={row} />;
      } else if (column['widget'] === 'groups') {
        dataType = <Groups onCellClick={onCellClick && onCellClick} data={data} row={row} />;
      } else if (column['widget'] === 'description') {
        dataType = <Description onCellClick={onCellClick && onCellClick} data={data} row={row} dataIndex={dataIndex} />;
      } else if (column['widget'] === 'comments') {
        dataType = <Comments onCellClick={onCellClick && onCellClick} data={data} row={row} />;
      } else if (column['widget'] === 'priority') {
        dataType = <Priority onCellClick={onCellClick && onCellClick} data={data} row={row} />;
      } else if (column['widget'] === 'members') {
        dataType = <Members onCellClick={onCellClick && onCellClick} data={data} row={row} />;
      } else if (column['widget'] === 'staticmember') {
        dataType = <StaicMembers onCellClick={onCellClick && onCellClick} data={data} row={row} />;
      } else if (column['widget'] === 'checkbox') {
        dataType = <Checkbox  row={row} onCellClick={onCellClick && onCellClick} value={data} />;
      } else if (column['widget'] === 'methods') {
        dataType = <Methods  row={row} onCellClick={onCellClick && onCellClick} value={data} />;
      } else if (column['widget'] === 'api') {
        dataType = <API  row={row} onCellClick={onCellClick && onCellClick} value={data} />;
      } else if (column['widget'] === 'time') {
        dataType = <ExecutionTime   row={row} onCellClick={onCellClick && onCellClick} value={data} />;
      } else if (column['widget'] === 'salaryRange') {
        dataType = <SalaryRange   row={row} onCellClick={onCellClick && onCellClick} data={data} />;
      } else if (column['widget'] === 'department') {
        dataType = <Departments  row={row} onCellClick={onCellClick && onCellClick} data={data} />;
      } else if (column['widget'] === 'ticketLink') {
        dataType = <TicketLink row={row} onCellClick={onCellClick && onCellClick} data={data} onExtraClick={onExtraClick && onExtraClick}/>;
      } else if (column['widget'] === 'multiTicketLinkStatic') {
        dataType = <MultiTicketLinkStatic row={row} onCellClick={onCellClick && onCellClick} data={data} />;
      }else if (column['widget'] === 'multiTicketLink') {
        dataType = <MultiTicketLink row={row} onCellClick={onCellClick && onCellClick} data={data} />;
      } else if (column['widget'] === 'link') {
        dataType = <Link row={row} onCellClick={onCellClick && onCellClick} data={data} />;
      } else if (column['widget'] === 'dashboardLink') {
        dataType = <DashBoardLink row={row} onCellClick={onCellClick && onCellClick} data={data} />;
      } else if (column['widget'] === 'invoiceLink') {
        dataType = <InvoiceLink row={row} onCellClick={onCellClick && onCellClick} data={data} />;
      } else if (column['widget'] === 'fileLink') {
        dataType = <FileLink row={row} onCellClick={onCellClick && onCellClick} data={data} />;
      } else if (column['widget'] === 'pullRequestLink') {
        dataType = <PullRequestLink row={row} onCellClick={onCellClick && onCellClick} data={data} />;
      } else if (column['widget'] === 'richText') {
        dataType = <KoEditorReadOnly row={row} onCellClick={onCellClick && onCellClick} data={data} />;
      } else if(column['widget'] === 'attachments'){
        dataType = <Attachments row={row} onCellClick={onCellClick && onCellClick} data={data} />;
      } else if(column['widget'] === 'text'){
        dataType = <Text row={row} width={column?.width}  onCellClick={onCellClick && onCellClick} data={data} />;
      } else if(column['widget'] === 'optionalStatus'){
        dataType = <OptionalStatus row={row} onCellClick={onCellClick && onCellClick} data={data} />;
      } else if(column['widget'] === 'eventSlot'){
        dataType = <EventSlot row={row} onCellClick={onCellClick && onCellClick} data={data} />;
      }else if(column['widget'] === 'folder'){
        dataType = <Folder row={row} onCellClick={onCellClick && onCellClick} data={data} />;
      }else if (column['widget'] === 'overDueStatus') {
        dataType = <OverDueStatus onCellClick={onCellClick && onCellClick} data={data} row={row} />;
      }else if (column['widget'] === 'log') {
        dataType = <Log onCellClick={onCellClick && onCellClick} data={data} row={row} />;
      }else if (column['widget'] === 'workspace') {
        dataType = <Workspace onCellClick={onCellClick && onCellClick} data={data} row={row} />;
      }


      return dataType;
    };
    return column;
  });
  return columns;
};

function addRightColumns(allcolumns, columnList) {
  columnList = columnList.map(function(colObject) {
    return colObject;
  });
  return _concat(allcolumns, columnList);
}
function addLeftColumns(allcolumns, columnList) {
  columnList = columnList.map(function(colObject) {
    return colObject;
  });
  return _concat(columnList, allcolumns);
}

export {
  serializeColumns,
  addRightColumns,
  addLeftColumns
};
