import React, { useRef } from "react";
import { add, format, getDay, setDay, differenceInMinutes } from "date-fns";
import { Table } from "antd";
import { getDayHoursEvents, sizeEventBox, MIN_BOX_SIZE } from "./utils";
import styles from './calendar.module.scss';
import { KoText, KoTitle } from "../typography";
import KoIcon from "../icon";
import { Space } from "antd/es";
import KoDivider from "../divider";
import KoDateFormatter from "../dateformat";
import KoButton from "../button";
import KoModal from "../modal";
import isString from 'lodash/isString';
import { useSelector } from "react-redux";
import KoDP from "../dp";
import { Link } from "react-router-dom/dist";
import KoTable from "../datatable";
import KoTooltips from "../tooltips";

const BOX_POSITION_OFFSET = 26;
const SCROLL_TO_ROW = new Date().toLocaleTimeString([], { hour: "2-digit" });
const EventBlock = ({ event, index, hour, events, onEventClick, currentRecord, onCreateHuddle}) => {
  const getEventDay = getDay(new Date(event.endTime));
  const fitHourToDate = setDay(hour, getEventDay);

  const boxStyle = event.allDay
    ? { boxSize: MIN_BOX_SIZE, boxPosition: index * BOX_POSITION_OFFSET }
    : sizeEventBox(event, fitHourToDate);


  return (
    <div className={styles[event?.meetingVia]}>

      <div className={`${styles.descriptionOverride} ${styles.description}`}
        style={{
          display:
            !event.allDay &&
              differenceInMinutes(new Date(event.endTime), fitHourToDate) === 0
              ? "none"
              : "block",
          height: boxStyle.boxSize + "%",
          width: event.allDay ? 98 + "%" : 98 / events + "%",
          
          top: boxStyle.boxPosition + "%"
        
        }}
        onClick={onEventClick ? () => onEventClick(event) : undefined}
        key={index}
      >
        <div className={styles.title}>
          <KoText
            className={`${styles.eventTitle} ${styles.titleOverride} ${new Date(event.endTime) < new Date() ? styles.timeOver : ''}`}
            text={event.name}
          />
        </div>
        <div className={styles.platformIcon}>
          <KoIcon name={`${event?.meetingVia === 'gmeet' ? 'GoogleMeetFilled' : event?.meetingVia === 'zoom' ? 'ZoomFilled' : event?.meetingVia === 'teams' ? 'MicrosoftTeamsFilled' : event?.meetingVia === 'slack' ? 'SlackFilled': event?.meetingVia === 'skype' ? 'SkypeFilled' : event?.meetingVia === 'goToMeeting' ? 'GoToMeetingFilled' : 'CiscoWebexFilled'}`} width={20} />

        </div>
        {/* <p
          style={{
            color: "#737373",
            fontSize: "10px",
            paddingLeft: "5px",
            paddingTop: "5px"
          }}
        >
          {event.author}
        </p> */}
      </div>
 
    </div>
  );
};

function Calendar({ weekDates, getDayEvents, onEventClick, weekends, currentRecord, setCurrentRecord, isOpen, setModal, onCreateHuddle, setDeleteModal}) {
  const users = useSelector(state => state?.users);
  let data = currentRecord?.assignees;
  
  function findMatchingUsers(users, data) {
    if (!Array.isArray(users) || !Array.isArray(data)) {
      return [];
    }
    
    return users.filter(user => user?.userId && data.includes(user.userId));
  }
  let matchingUsers = findMatchingUsers(users['data'], data);
  
  if(isString(data)){
    
    matchingUsers = findMatchingUsers(users['data'], [data]);
  }
  const rowRef = useRef(null);
  const dayList = weekends
    ? [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday"
    ]
    : ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];

  const dayColumns = dayList.map((day, counter) => {
    const columnDate = add(new Date(weekDates.startDate), {
      days: 1 + counter
    });
    const formattedDayandMonth =
      format(columnDate, "iii") + ", " + format(columnDate, "dd");
    return {
      title: formattedDayandMonth,
      dataIndex: day,
      key: day,
      width: 2,
      render: function(events, row) {
        if (events && events.length > 0 && events instanceof Array) {
          const eventsBlock = events.map(function(event, index) {
            return (
              <EventBlock
                key={event.meetingId}
                event={event}
                index={index}
                hour={row.hourObject}
                events={events.length}
                onEventClick={onEventClick}
                currentRecord={currentRecord}
                setModal={setModal}
              />
            );
          });

          return {
            props: {
              style: { position: "relative", padding: "0" }
            },
            children: <>{eventsBlock}</>
          };
        }
        return undefined;
      }
    };
  });
  const hourColumn = {
    title: "",
    dataIndex: "hour",
    key: "hour",
    width: .8,
    fixed: 'left',
    render: (hour) => {
      return {
        children:
          SCROLL_TO_ROW === hour ? (
            <div className={styles.time} ref={rowRef}>{hour}</div>
          ) : (
            <div className={styles.time}>{hour}</div>
          )
      };
    }
  };
  const tableColumns = [hourColumn, ...dayColumns];

  const AGN_ITEMS_COLUMNS = [
    {
      name: "title",
      id: "title",
      label: "Title",
      width:200,
      widget: "text",
      fixed: true
    },
    {
      name: "presenter",
      id: "presenter",
      label: "Presenter",
      widget: "text"
    },
    
    {
      name: "endDate",
      id: "endDate",
      label: "Due Date",
      format: "DD-MMM-YYYY",
      widget: "date",
      width:130
    },
    {
      name: "status",
      id: "status",
      label: "Status",
      widget: "text"
    }
  ];
  
  return (
    <div className={`${styles.dataWrapper} calendar`}>
      <Table
        rowKey={record => record.id}
        dataSource={getDayHoursEvents(weekDates, getDayEvents)}
        columns={tableColumns}
        pagination={false}
        bordered={true}
        // showHeader={true}
        scroll={{
          y: `calc(100vh - 22rem)`
        }}

      />
      <KoModal
        title={`${currentRecord?.name?.toUpperCase()}`}
        centered
        open={isOpen}
        // afterClose={() => { setCurrentRecord(); }}
        handleOk={() => { setModal(false); setCurrentRecord();}}
        onClose={() => { setModal(false); setCurrentRecord();}}
        width={800}
        // maskClosable={false}
        height={550}
        footer={[
          <KoButton key='yes'  type='primary' label='Yes' onClick={() => setModal(!isOpen)} />,
          <KoButton key='cancel' label='Maybe' onClick={() => setModal(!isOpen)} />,
          <KoButton key='no'   label='No' onClick={() => setModal(!isOpen)} />
        ]}
      >
        <div>
          <Space>
            <KoDateFormatter format='DD-MMM-YYYY' data={currentRecord?.meetingId ? currentRecord?.startTime : "16 Sep, 22 11:05 AM"} className={styles.files} />
            <KoDateFormatter format='hh:mm a' data={currentRecord?.meetingId ? currentRecord?.startTime : "16 Sep, 22 11:05 AM"} className={styles.files} />
            <span>to</span>
            <KoDateFormatter format='hh:mm a' data={currentRecord?.meetingId ? currentRecord?.endTime : "16 Sep, 22 11:05 AM"} className={styles.files} />
          </Space>
          <KoDivider
            style={{
              margin: '8px 0'
            }}
          />
          <div className={styles.ModalWrapper}>
            <Space>
              <KoIcon name={`${currentRecord?.meetingVia === 'gmeet' ? 'GoogleMeetFilled' : currentRecord?.meetingVia === 'zoom' ? 'ZoomFilled' : currentRecord?.meetingVia === 'teams' ? 'MicrosoftTeamsFilled' : currentRecord?.meetingVia === 'slack' ? 'SlackFilled': currentRecord?.meetingVia === 'skype' ? 'SkypeFilled' : currentRecord?.meetingVia === 'goToMeeting' ? 'GoToMeetingFilled' : 'CiscoWebexFilled'}`} width={20} height={20} />

              {/* <Link to={new URL(currentRecord?.url)?.href} target="_blank"> */}
              <Link
                to={currentRecord?.url?.startsWith('http') ? currentRecord.url : `https://${currentRecord?.url}`}
                target="_blank"
              >
                <Space>
                  <KoTitle className={styles.meetLink} level={5} text={`Join ${currentRecord?.meetingVia === 'gmeet' ? 'Google Meet' : currentRecord?.meetingVia === 'zoom' ? 'Zoom' : currentRecord?.meetingVia === 'teams' ? 'Microsoft Teams' : currentRecord?.meetingVia === 'slack' ? 'Slack': currentRecord?.meetingVia === 'skype' ? 'Skype' : currentRecord?.meetingVia === 'goToMeeting' ? 'GoTo' : 'Cisco Webex'} Meeting`}/>
                  <KoTooltips title={'open'}>
                    <div>
                      <KoIcon width={14} height={14} name={'OpenOutlined'} />
                    </div>
                  </KoTooltips>

                </Space>
              </Link>

              {
                new Date(currentRecord?.endTime) > new Date() &&
                <>
                  <KoTooltips title={'Edit'}>
                    <div onClick={() => {
                      onCreateHuddle();
                      setModal(false);
                    }}>
                      <KoIcon width={14} height={14} name={'EditOutlined'} />
                    </div>
                  </KoTooltips>

                  <KoTooltips title={'Delete'}>
                    <div onClick={() => {
                      setDeleteModal(true);
                    }}>
                      <KoIcon width={14} height={14} name={'DeleteOutlined'} />
                    </div>
                  </KoTooltips>
                </>
              }
           
            </Space>
          

            <div className={ `${styles.meetAssignees} ${styles.subWrapper}`}>
              <KoIcon width={16} height={16} name={'UsersOutlined'} />
              <KoText text={`10 yes, 2 declined`} />
            </div>
            <div className={styles.assigneesWrap}>
              { matchingUsers?.map((user)=>
                <div key={user.userId} className={`${user?.profile?.logo?.length > 0 ? styles.bgImage :styles.userWrapper} ${styles.commonWrap}`}>
                  <div className={`${styles.circle}  ${styles[user?
                    user?.profile?.firstName?.charAt(0).toLowerCase() :'a']}`}>
                    {user?.profile?.logo?.length > 0  ?
                      <KoDP logo={user?.profile?.logo} className={styles.dp} width={19} height={19} />
                      :
                      <span className={`${styles.letter}`}>{user?.profile?.firstName?.charAt(0)}</span>
                    }
                  
                  </div>
                  <div className={`${styles.text}`}>{`${user?.profile?.firstName ? user?.profile?.firstName:''} ${user?.profile?.lastName ? user?.profile?.lastName:''}`}</div>

                </div>
              )}
            </div>
            <KoTable rowKey='ticketId'
              // onCellClick={onCellClick}
              data={{ columns: AGN_ITEMS_COLUMNS, row: currentRecord?.agenda }}
              // extraColumnsRight={columnsRight}
              maxHeight={15} />
            <KoTitle level={5} text={`description text`} />
            <KoText text={`Are you attending?`} />
          </div>
        </div>
      </KoModal>
    </div>
  );
}

export default Calendar;
