import {
  KoButton,
  KoDP,
  KoDropdown,
  KoForm,
  KoIcon,
  KoLoader,
  KoModal,
  KoNano,
  KoPagination,
  KoTable,
  KoText, KoTitle,
  KoToast,
  KoTooltips
} from 'packages';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import KoToolBar from 'shared/components/toolbar';
import isString from 'lodash/isString';
// import {  COLUMNS_USERS } from './constant';
import { useDispatch } from 'react-redux';
import { add, remove } from 'shared/store/breadcrumbSlice';
import styles from '../org.module.scss';
import { OrgAPI } from '../org.service';
import { COLUMNS_USERS } from './constant';
import UserCrud from './crud';
import GroupCrud from './groupcrud';
import ResetCrud from './resetcrud';
import { getUsers } from 'shared/store/usersSlice';
import { getDesignations } from 'shared/store/designationsSlice';

function Employees() {
  // const users = useSelector(state => state.users);
  const dispatch = useDispatch();
  const [totalCount, setTotalCount] = useState(100);
  const [rowList, setDataList] = useState();
  const [isOpen, setModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [currentRecord, setCurrentRecord] = useState();
  const [isOpenResetPass, setResetPassModal] = useState(false);
  const [isOpenGroup, setGroupModal] = useState(false);
  const [isOpenSuspend, setSuspendModal] = useState(false);
  const [isOpenActivate, setActivateModal] = useState(false);
  const [isOpenDelete, setDeleteModal] = useState(false);
  const [pagination, setPagination] = useState({
    size: 10,
    offset: 0,
    page: 1
  });
  const [form] = KoForm.useForm();
  const [formReset] = KoForm.useForm();
  const [formGroup] = KoForm.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(remove());
    dispatch(add([
      {
        title: 'Home',
        path: 'home/dashboard/myoverview'
      },
      {
        title: 'Employees'
      }
    ]));

    getOrgUsers();
  }, []);

  const getOrgUsers = () => {
    setLoading(true);
    OrgAPI.getOrgUsers(pagination).then(
      (data) => {
        setDataList();
        setTotalCount();
        setLoading(false);
        setDataList(data['users']);
        setTotalCount(data['totalCount']);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const onSearchText=(text)=>{
    let q = pagination;
    if(text){
      q['search'] = text;
    }
    else{
      delete q?.['search'];
    }
    getOrgUsers(q);
  };

  const onSort = (key, type) => {
    let q = pagination;
    q['sort'] = `${key}:${type}`;
    getOrgUsers(q);
  };

  const onPagination = (page, other) => {
    const query = {
      size: other,
      page: page,
      offset: (page - 1) * other
    };
    const pQuery = Object.assign(pagination, query);
    setPagination(pQuery);
    getOrgUsers(pQuery);
  };


  const onValuesChange = (changedValues) => {
    setCurrentRecord(changedValues);
  };



  const handleFinish = (values => {
    setLoading(true);
    if (values['autoGenerate'] === 'Yes') {
      values['isChangePassword'] = true;
    } else {
      values['isChangePassword'] = values['isChangePassword'] ? values['isChangePassword'][0] : false;
    }
    if (!currentRecord?.userId) {
      values['userId'] = KoNano('USR');
    }
  
    OrgAPI.createOrgUsers(values, currentRecord?.userId).then(
      (data) => {
        setLoading(false);
        setModal(false);
        form.resetFields();
        dispatch(getDesignations());
        KoToast.success(`Profile ${currentRecord?.userId? 'updated': 'created'} successfully! ${values?.profile?.firstName}!`) ;
        getOrgUsers();
      },
      
      (err) => {
        setLoading(false);
        err.error === 'this user already exists please contact your admin' && KoToast.error(`User already exists`);
        KoToast.error(`error`);
      }
    );
  });

  const handleFinishReset = (values) => {
    setLoading(true);
    values['email'] = currentRecord['email'];
    // values['userId'] = currentRecord['userId'];
    // if (values['autoGenerate'] === 'Yes') {
    //   values['isChangePassword'] = true;
    // } else {
    //   values['isChangePassword'] = values['isChangePassword'] ? values['isChangePassword'][0] : false;
    // }
    OrgAPI.resetPassword(values).then(
      (data) => {
        getOrgUsers();
        setLoading(false);
        setResetPassModal(false);
        KoToast.success(`Password reset successfully! ${values?.profile?.firstName}!`);
      },
      (err) => {
        setLoading(false);
        KoToast.error(`error`);
      }
    );
  };


  const handleFinishGroup = (values) => {
    setLoading(true);
    values['userId'] = currentRecord?.userId + 's';
    OrgAPI.addToGroup(values).then(
      (data) => {
        getOrgUsers();
        setLoading(false);
        setGroupModal(false);
        setResetPassModal(false);
        KoToast.success(`Profile created successfully! ${values.firstName}!`);
      },
      (err) => {
        setLoading(false);
        KoToast.error(`error`);
      }
    );
  };

  const handleFinishSuspend = () => {
    setLoading(true);
    let obj = {
      userId: [currentRecord?.userId],
      status: 0
    };
    OrgAPI.statusUpdate(obj).then(
      () => {
        getOrgUsers();
        setLoading(false);
        dispatch(getUsers());
        setResetPassModal(false);
        setSuspendModal(false);
        KoToast.success(`Profile suspend successfully! ${currentRecord?.profile?.firstName}!`);
      },
      (err) => {
        setLoading(false);
        KoToast.error(`Error`);
      }
    );
  };

  const handleFinishActivate = () => {
    setLoading(true);
    let obj = {
      userId: [currentRecord?.userId],
      status: 1
    };
    OrgAPI.statusUpdate(obj).then(
      (data) => {
        getOrgUsers();
        setLoading(false);
        dispatch(getUsers());
        setActivateModal(false);
        setResetPassModal(false);
        KoToast.success(`Profile activate successfully! ${currentRecord.profile?.firstName}!`);
      },
      (err) => {
        setLoading(false);
        KoToast.error(`error`);
      }
    );
  };

  const handleFinishDelete = () => {
    setLoading(true);
    OrgAPI.userDelete(currentRecord?.userId).then(
      (data) => {
        getOrgUsers();
        setLoading(false);
        setResetPassModal(false);
        setDeleteModal(false);
        KoToast.success(`Profile deleted successfully! ${currentRecord.profile?.firstName}!`);
      },
      (err) => {
        setLoading(false);
        KoToast.error(`error`);
      }
    );
  };

  const handleRowActions = ({ key }) => {
    if (key === '2') {
      setResetPassModal(true);
    } else if (key === '3') {
      setGroupModal(true);
    } else if (key === '4') {
      setSuspendModal(true);
    } else if(key === '0'){
      navigate('/profile');
    }else if(key === '6'){
      setActivateModal(true);
    }
    else {
      setDeleteModal(true);
    }

  };

  const setCurrentRecordModify =(data)=>{
    setCurrentRecord(data);
  };



  const getItems = (user) => {
    return [
      {
        key: '0',
        label: (<KoText text='View Profile' />),
        icon: <KoIcon name='OrgOutlined' />
      },
      {
        key: '6',
        label: (<KoText text='Activate User' />),
        icon: <KoIcon name='ActivateOutlined' />,
        disabled: user?.status === 1
      },
      {
        key: '2',
        label: (<KoText text='Reset password' />),
        icon: <KoIcon name='ChangePasswordOutlined' />
      },
      {
        key: '4',
        label: (<KoText text=' Suspend user' />),
        icon: <KoIcon name='SuspendOutlined' />
      },
      {
        type: 'divider'
      },
      {
        key: '5',
        danger: true,
        label: 'Delete user',
        icon: <KoIcon name='DeleteOutlined' />
      }
    ].filter(item => !(user?.status === 1 && item.key === '6'));
  };

  const columnsLeft = [{
	  title: 'Name',
	  dataIndex: 'x',
	  key: 'x',
	  fixed: 'left',
	  width: 250,
	  render: (text, row) => {
      let data;
      if(isString(row) || !row?.profileId){
        let id =  row?.userId || rowList;
        let matchedRecords = rowList.filter(record => [id]?.includes(record.userId));
        let result = matchedRecords.map(({ profile, userId, logo }) => ({ profile, userId, logo }));
        
        data = result.length > 0 && result[0];
      }

	  return (
        <div className={styles.userWrapper}>
          <div className={`${styles.circle}  ${styles[data ?data?.profile?.firstName?.charAt(0).toLowerCase() :'a']}`}>
            {data?.profile?.['logo'] ?
              <KoDP logo={data?.profile?.['logo']} className={styles.profile} width={20} height={20} />
              :
              <KoText className={styles.letter} text={data?.profile?.firstName?.charAt(0)}/>}
          </div>
          <KoText ellipsis={true} className={`${styles.text}`} text={`${data?.profile?.firstName ? data?.profile?.firstName:''} ${data?.profile?.lastName ? data?.profile?.lastName:''}`}/>
        </div>
      );
	  }
  }];

  const columnsRight = [{
	  title: '',
	  dataIndex: 'x',
	  key: 'x',
	  fixed: 'right',
	  width: 40,
	  render: (text, row) => {
	    return <div className={`${styles.circle} tableBtn columnsRight`} onClick={() => setCurrentRecordModify(row)}>
        <KoTooltips title={'Edit'}>
	      <KoButton  className={'btn'} iconBtn={true} icon={<KoIcon width={14} height={14} name={'EditOutlined'}/>} onClick={() => setModal(true)}/>
        </KoTooltips>
	      <div shape="circle">
	        <KoDropdown
	          menu={{
	            items:getItems(row),
	            onClick: handleRowActions
	          }}
	          overlayClassName={styles.actionDropdown}
	          placement="bottomLeft"
	        >
	          <div shape="circle" >
	            <KoIcon name="VerticalMenuOutlined" onClick={() => (row)} />
	          </div>
	        </KoDropdown>
	      </div>
	    </div>;
	  }
  }];

   
  return (<div className='tableCard'>
    { isLoading && <KoLoader />}
    <KoToolBar
      title={'Employees '}
      search={{ visible: true,onChange: (e) => { onSearchText(e);}, placeholder: 'Search by any parameter' }}
      filter={{
        visible: false,
        options: [{ label: 'Sort By Name', key: '0' },
          { label: 'Created At', key: '2' },
          { label: 'Sort By designation', key: '3' }]
      }}
      buttonList={[{
        label: 'Create User',
        type: 'primary',
        icon: <KoIcon color="#FFF" name="PlusOutlined" width={18} height={18} />,
        onClick: () => { setCurrentRecord(); setModal(!isOpen); }
      }]} />

    <KoTable rowKey='userId' data={{ columns: COLUMNS_USERS, rows: rowList }} onSort={onSort}
      extraColumnsRight={columnsRight} extraColumnsLeft={columnsLeft} maxHeight={100} />


    {/* <div className={styles.userList}>  */}


    {/* <KoFlex wrap="wrap" className={styles.userList}>
      {rowList?.map((data) =>
        <KoCard key={data.userId} cardBg={true} className={styles.card}>
          <div className={styles.actions}>
            <KoDropdown
              menu={{
                items,
                onClick: handleRowActions
              }}
              trigger={['click']}
              overlayClassName={styles.actionDropdown}
              placement="bottomRight"
            >
              <div className={styles.vmenu} onClick={() => setCurrentRecordModyify(data)} >
                <KoIcon name="VerticalMenuOutlined" />
              </div>
            </KoDropdown>
          </div>
          <div className={styles.profile}>
            <div className={styles.logo}>
              <KoAvatar isProfile={true} options={[{ name: data?.profile?.firstName, logo:`/organization/user/${data?.userId}/${data?.profile?.logo?.length > 0 ? data?.profile?.logo[0]?.name : false}` }]} />
            </div>
            <div className={styles.emailName}>
              <KoTitle level={5} ellipsis={true} text={`${data?.profile?.firstName} ${data?.profile?.lastName}`} />
              <KoText className={styles.mobile} ellipsis={true} text={data?.email} />
            </div>
          </div>
          <div className={styles.footer}>
            <KoUserStatus data={data?.status} />
            <div className={styles.fActions}>
              <KoDateFormatter data={data.lastSignedIn} className={styles.lite} />
            </div>
          </div>
        </KoCard>
      )}
      {isLoading &&  <>
        <KoCard cardBg={true} className={styles.card}>
          <KoSkeleton loading avatar active title={false} paragraph={{ rows: 2 }} />
          <div className={styles.footer}>
          </div>
        </KoCard>
        <KoCard cardBg={true} className={styles.card}>
          <KoSkeleton loading avatar active title={false} paragraph={{ rows: 2 }} />
          <div className={styles.footer}>
          </div>
        </KoCard>
        <KoCard cardBg={true} className={styles.card}>
          <KoSkeleton loading avatar active title={false} paragraph={{ rows: 2 }} />
          <div className={styles.footer}>
          </div>
        </KoCard>
        <KoCard cardBg={true} className={styles.card}>
          <KoSkeleton loading avatar active title={false} paragraph={{ rows: 2 }} />
          <div className={styles.footer}>
          </div>
        </KoCard>
        <KoCard cardBg={true} className={styles.card}>
          <KoSkeleton loading avatar active title={false} paragraph={{ rows: 2 }} />
          <div className={styles.footer}>
          </div>
        </KoCard>
      </>}
    </KoFlex> */}

    {/* </div> */}

    <KoPagination current={parseInt(pagination.page) || 1}
      total={totalCount}
      showTotal={(total, range) =>
				`Displaying records ${range[0]}-${range[1]} of ${total}`
      }
      pageSize={pagination.size}
      defaultCurrent={pagination.page}
      onChange={onPagination}
      showQuickJumper={false}
      simple={false}
      responsive={false} />
    <KoModal
      title={currentRecord?.userId ? `Update user ${currentRecord?.profile?.firstName?.toUpperCase()}` : 'Add new user'}
      centered
      open={isOpen}
      afterClose={() => {setCurrentRecord();}}
      handleOk={() => { setCurrentRecord(); setModal(false); }}
      onClose={() => { setCurrentRecord(); setModal(false); }}
      width={780}
      // maskClosable={false}
      height={650}
      footer={[
        <KoButton key='cancel' label='Cancel' onClick={() => setModal(!isOpen)} />,
        <KoButton key='create' loading={isLoading} type='primary' label={`${currentRecord?.userId ? 'Update' : 'Create'}`}
          onClick={() => form.submit()} />
      ]}
    >
      {isLoading && <KoLoader circle={false} />}
      <UserCrud initialValues={currentRecord} onValuesChange={onValuesChange} form={form} handleFinish={handleFinish}  getOrgUsers={getOrgUsers} setModal={setModal}/>
    </KoModal>

    <KoModal
      title={`Reset password for ${currentRecord?.profile?.firstName?.toUpperCase()}`}
      centered
      open={isOpenResetPass}
      afterClose={() => {setCurrentRecord();}}
      handleOk={() => { setCurrentRecord(); setResetPassModal(false); }}
      onClose={() => { setCurrentRecord(); setResetPassModal(false); }}
      width={680}
      // maskClosable={false}
      height={650}
      footer={[
        <KoButton key='cancel' label='Cancel' onClick={() => setResetPassModal(!isOpenResetPass)} />,
        <KoButton key='reset' loading={isLoading} type='primary' label='Reset' onClick={() => formReset.submit()} />
      ]}
    >
      {isLoading && <KoLoader circle={false} />}
      <ResetCrud initialValues={currentRecord} form={formReset} handleFinish={handleFinishReset} />
    </KoModal>

    <KoModal
      title={`Add ${currentRecord?.profile?.firstName?.toUpperCase()} to Groups`}
      centered
      open={isOpenGroup}
      afterClose={() => {setCurrentRecord();}}
      handleOk={() => { setCurrentRecord(); setGroupModal(false); }}
      onClose={() => { setCurrentRecord(); setGroupModal(false); }}
      width={580}
      // maskClosable={false}
      height={450}
      footer={[
        <KoButton key='cancel' label='Cancel' onClick={() => setGroupModal(!isOpenGroup)} />,
        <KoButton key='reset' loading={isLoading} type='primary' label='Add to groups' onClick={() => formGroup.submit()} />
      ]}
    >
      {isLoading && <KoLoader circle={false} />}
      <GroupCrud initialValues={currentRecord} form={formGroup} handleFinish={handleFinishGroup} />
    </KoModal>

    <KoModal
      title={`Suspend user ${currentRecord?.profile?.firstName?.toUpperCase()}`}
      centered
      open={isOpenSuspend}
      afterClose={() => {setCurrentRecord();}}
      handleOk={() => { setCurrentRecord(); setSuspendModal(false); }}
      onClose={() => { setCurrentRecord(); setSuspendModal(false); }}
      width={580}
      // maskClosable={false}
      height={450}
      footer={[
        <KoButton key='cancel' label='Cancel' onClick={() => setSuspendModal(!isOpenSuspend)} />,
        <KoButton key='reset' loading={isLoading} type='primary' label='Suspend' onClick={() => handleFinishSuspend()} />
      ]}
    >
      {isLoading && <KoLoader circle={false} />}
      <div className={styles.suspend}>
        <KoTitle level={5} text='User License fees still apply to the suspended users.' />
        <KoText text={`Suspended users can be restored as long as they're not deleted.`} />
      </div>
    </KoModal>

    <KoModal
      title={`Activate user ${currentRecord?.profile?.firstName?.toUpperCase()}`}
      centered
      open={isOpenActivate}
      afterClose={() => {setCurrentRecord();}}
      handleOk={() => { setCurrentRecord(); setActivateModal(false); }}
      onClose={() => { setCurrentRecord(); setActivateModal(false); }}
      width={580}
      // maskClosable={false}
      height={450}
      footer={[
        <KoButton key='cancel' label='Cancel' onClick={() => setActivateModal(!isOpenActivate)} />,
        <KoButton key='reset' loading={isLoading} type='primary' label='Activate' onClick={() => handleFinishActivate()} />
      ]}
    >
      <div className={styles.suspend}>
        <KoTitle level={5} text='License fees apply to activated users,' />
        <KoText text={`granting access to all paid services.`} />
      </div>
    </KoModal>

    <KoModal
      title={`Delete user ${currentRecord?.profile?.firstName?.toUpperCase()}`}
      centered
      open={isOpenDelete}
      afterClose={() => {setCurrentRecord();}}
      handleOk={() => { setCurrentRecord(); setDeleteModal(false); }}
      onClose={() => { setCurrentRecord(); setDeleteModal(false); }}
      width={580}
      // maskClosable={false}
      height={450}
      footer={[
        <KoButton key='cancel' label='Cancel' onClick={() => setDeleteModal(!isOpenDelete)} />,
        <KoButton key='reset' loading={isLoading} type='primary' label='Delete' onClick={() => handleFinishDelete()} />
      ]}
    >
      {isLoading && <KoLoader circle={false} />}
      <div className={styles.suspend}>
        <KoTitle level={5} text={`Are you sure you want to delete this user?`} />
        <KoText text={`This action is irreversible, and it will result in the removal of their associated data and Kwapio license.`} />
      </div>
    </KoModal>
  </div>);
}

export default Employees;