import React from 'react';

export default function Reply(props) {
  return (
    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" {...props}  fill="none" viewBox="0 0 24 24">
      <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"  strokeWidth="1.5" d="M14.5 8.046H11V6.119c0-.921-.9-1.446-1.524-.894l-5.108 4.49a1.2 1.2 0 0 0 0 1.739l5.108 4.49c.624.556 1.524.027 1.524-.893v-1.928h2a3.023 3.023 0 0 1 3 3.046V19a5.593 5.593 0 0 0-1.5-10.954Z"/>
    </svg>
  
  );
}
