import {KoAPI} from "packages";
import { configs } from 'configuration';
import {getCookie} from "shared/utils/cookies";
const version = configs().API_VERSION;
export const supportAPI = {

  getWorkspace(){
    return KoAPI.get(`/api/${version}/workspace`);
  },
  getValidateEmail(data){
    return KoAPI.post(`/api/${version}/workspace/send/otp`, { data });
  },
  getWorkspaceById(id){
    return KoAPI.get(`/api/${version}/workspace/${id}`);
  },
  getAccounts(){
    return KoAPI.get(`/api/${version}/account/domain`, {
      header: {
        "key": 'kio'
      }
    });
  },
  verifyOtp(data){
    return KoAPI.get(`/api/${version}/workspace/${data?.accountId}/${data?.workspaceId}/${data?.email}/${data?.otp}`);
  },
  createOrUpdate(data, id) {
    if(id){
      return KoAPI.update(`/api/${version}/taskplanner/public/ticket/${id}`, { data,  query: { Authorization: 'TempToken ' + getCookie('tempToken') }  });
    }else{
      return KoAPI.post(`/api/${version}/taskplanner/public/ticket`, { data, query: { Authorization: 'TempToken ' + getCookie('tempToken') }   });
    }
  
  },
  getIssueByIdPublic(id) {
    return KoAPI.get(`/api/${version}/taskplanner/public/ticket/${id}`, { query: { Authorization: 'TempToken ' + getCookie('tempToken') }  });
  },

  deleteById(id){
    return KoAPI.delete(`/api/${version}/workspace/${id}`);
  },
  getClients(){
    return KoAPI.get(`/api/${version}/admin/customer`);
  },
      
  getRecentTicket(query){
    return KoAPI.get(`/api/${version}/taskplanner/public/tickets`, { query: { ...query } });
  }
};

