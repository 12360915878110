import React, { useState, useEffect } from 'react';
import {
  KoAffix,
  KoText,
  KoIcon,
  KoModal,
  KoButton,
  KoForm,
  KoLoader,
  KoNano,
  KoTitle,
  KoToast,
  KoFlex,
  KoFolder,
  KoEmpty
} from "packages";
import { useDispatch } from "react-redux";
import KoToolBar from "shared/components/toolbar";

import CollectionCrud from './crud';
import {
  add,
  remove
} from 'shared/store/breadcrumbSlice';
import { ApiClientAPI } from '../apiClient.service';
import styles from "./collections.module.scss";
import { useParams } from 'react-router';
import { debounce } from 'lodash';

function Collections() {
  const dispatch = useDispatch();
  const [isFolderOpen, setFolderModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [collections, setCollections] = useState();
  const [searchedCollections, setSearchedCollections] = useState();
  const [currentRecord, setCurrentRecord] = useState();
  const [isOpenColDelete, setDeleteColModal] = useState(false);

  const [form] = KoForm.useForm();
  const { envronmentId: environmentId } = useParams();

  useEffect(() => {
    getCollections();
    dispatch(remove());
    dispatch(add([
      {
        title: 'Workspace',
        path: 'workspace'
      },
      {
        title: 'Environments',
        path: '/apiclient/environments'
      },
      {
        title: environmentId
      }
    ]));
  }, []);

  const getCollections = () => {
    setLoading(true);
    ApiClientAPI.getCollections().then(
      (data) => {
        setLoading(false);
        setCollections(data['collections']);
        setSearchedCollections(data['collections']);
        setFolderModal(false);
      },
      (err) => {
        setLoading(false);
        KoToast.error(`error`);
      }
    );
  };

  const onCreateFolder = () => {
    setCurrentRecord();
    form.resetFields();
    setFolderModal(true);
  };

  const handleFolderFinish = (values => {
    if (!currentRecord?.collectionId) {
      values['collectionId'] = KoNano('COL');
    }
    ApiClientAPI.createOrUpdateCollection(values, currentRecord?.collectionId).then(
      (data) => {
        getCollections();
        setLoading(false);
        setFolderModal(false);
        KoToast.success(`Profile ${currentRecord?.collectionId ? 'updated' : 'created'} successfully! ${values.name}!`);
      },
      (err) => {
        setLoading(false);
        KoToast.error(`error`);
      }
    );
  });

  const onHandelCollection = (key, coll) => {
    form.resetFields();
    setCurrentRecord(coll);
    if (key === "1") {
      setFolderModal(true);
    } else {
      setDeleteColModal(true);
    }
  };

  const handleFinishFolderDelete = () => {
    setLoading(true);
    ApiClientAPI.deleteCollection(currentRecord?.collectionId).then(
      (data) => {
        setLoading(false);
        setDeleteColModal(false);
        KoToast.success(`Profile deleted successfully! ${currentRecord.name}!`);
        getCollections();
      },
      (err) => {
        setLoading(false);
        KoToast.error(`error`);
      }
    );
  };

  const handleSearch = debounce((value) => {
    const filteredValues = collections?.filter(obj => {
      const objName = obj?.name?.toLowerCase();
      return objName.startsWith(value.toLowerCase());
    });
    setSearchedCollections(filteredValues);
  }, 200);

  const items = [
    // {
    //   label: "Download",
    //   key: "0",
    //   icon: <KoIcon name="CloudDownloadOutlined" width={13} />
    // },
    {
      label: "Update Collection",
      key: "1",
      icon: <KoIcon name="EditOutlined" height={12} width={13} />
    },
    {
      label: "Delete",
      key: "2",
      danger: true,
      icon: <KoIcon name="DeleteOutlined" height={12} width={13} />
    }
    // {
    //   label: "Share Document",
    //   key: "4",
    //   icon: <KoIcon name="ShareOutlined" height={14} width={13} />
    // }
  ];

  const onClick = ({ key }, proj) => {
    onHandelCollection(key, proj);
  };


  return (
    <div className={styles.wrapper}>
      {isLoading && <KoLoader />}
      <KoAffix top={56}>
        <KoToolBar
          className={styles.toolbar}
          title={searchedCollections?.length > 0 && "Collections Details"}
          search={{
            visible: true,
            onChange: handleSearch,
            placeholder: "Search by name"
          }}
          buttonList={[
            {
              label: "New Collections",
              type: "primary",
              icon: (
                <KoIcon
                  color="#FFF"
                  name="PlusOutlined"
                  width={18}
                  height={18}
                />
              ),
              onClick: () => onCreateFolder()
            }
          ]}
        />
      </KoAffix>
      {collections?.length > 0 ? <KoFlex wrap="wrap" gap="small" className={styles.recentFolders}>
        {collections?.map((collection, i) => (
          <div key={collection?.environmentId}>
            <KoFolder id={'collectionId'} data={collection} actions={items} onClick={onClick} />
          </div>
        ))}
      </KoFlex> : <KoFlex wrap="wrap" gap="small" className={styles.emptySection}>
        <KoEmpty onClick={() => onHandelCollection('1')} label="New Collections" icon={'EmptyTwoTone'} title="Create a new collection" />
      </KoFlex>}

      <KoModal
        title={currentRecord?.collectionId ? `Update Collection ${currentRecord?.name?.toUpperCase()}` : 'Create Collection'}
        centered
        open={isFolderOpen}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setFolderModal(false); }}
        onClose={() => { setCurrentRecord(); setFolderModal(false); }}
        width={680}
        // maskClosable={false}
        height={650}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setFolderModal(!isFolderOpen)} />,
          <KoButton key='create' loading={isLoading} type='primary' label={`${currentRecord?.collectionId ? 'Update' : 'Create'}`} onClick={() => form.submit()} />
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <CollectionCrud form={form} handleFinish={handleFolderFinish} initialValues={currentRecord} />
      </KoModal>

      <KoModal
        title={`Delete Collection ${currentRecord?.name?.toUpperCase()}`}
        centered
        open={isOpenColDelete}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setDeleteColModal(false); }}
        onClose={() => { setCurrentRecord(); setDeleteColModal(false); }}
        width={580}
        // maskClosable={false}
        height={450}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setDeleteColModal(!isOpenColDelete)} />,
          <KoButton key='reset' loading={isLoading} type='primary' label='Delete' onClick={() => handleFinishFolderDelete()} />
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <div className={styles.formWrapper}>
          <KoTitle level={5} text={`Are you sure you want to delete this Collection?`} />
          <KoText text={`This action is irreversible, and it will result in the removal of their associated data and Kwapio license.`} />
        </div>
      </KoModal>
    </div>
  );
}

export default Collections;