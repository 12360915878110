import React from "react";
function Sales(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.7083 4.66667H17.5V2.375C17.5 1.61692 16.8831 1 16.125 1H7.875C7.11692 1 6.5 1.61692 6.5 2.375V4.66667H3.29167C2.0285 4.66667 1 5.69425 1 6.95833V23H23V6.95833C23 5.69425 21.9724 4.66667 20.7083 4.66667ZM7.41667 2.375C7.41667 2.12292 7.622 1.91667 7.875 1.91667H16.125C16.3771 1.91667 16.5833 2.12292 16.5833 2.375V4.66667H7.41667V2.375ZM22.0833 22.0833H17.5V7.41667H16.5833V22.0833H7.41667V7.41667H6.5V22.0833H1.91667V6.95833C1.91667 6.20025 2.53358 5.58333 3.29167 5.58333H20.7083C21.4664 5.58333 22.0833 6.20025 22.0833 6.95833V22.0833Z" fill="black" />
    </svg>
  );
}


export default Sales;