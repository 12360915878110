import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { KoIcon, KoAccordion, KoBreadcrumb, KoToast, KoCard, KoTitle,
  KoForm, KoDescription, KoLoader } from 'packages';
import BusinessInfoCurd from './curd/business';
import BusinessProfile from './curd/businessprofile';
import PreferencesCrud from './curd/preferences';
import AddressCrud from './curd/address';
import DiscountCrud from './curd/discount';
import TaxCrud from './curd/tax';
import Preview from './preview';
import { add, remove } from 'shared/store/breadcrumbSlice';
import BankCrud from './curd/bank';
import { clientsAPI } from '../customers.service';

import styles from './settings.module.scss';
import { deepMerge } from 'shared/utils';



function ClientDetails(props) {
  const {isBack} = props;
  const [isLoading,  setLoading] = useState(false);
  const [form] = KoForm.useForm();
  const [settings, setSettings] = useState({
    preferences:{
      "defaultLanguage": "en",
      "primaryCurrency": "inr",
      "currencyPosition": "right",
      "numberFormat": "comma_dot",
      "dateFormat": "12 Hour (AM/PM)",
      "timeFormat": "12 Hour (AM/PM)",
      "financialYearBegins": "March",
      "invoiceNoPrefix": "INV"
    }
  });
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;
  const clientId = pathname?.split('/')[pathname?.split('/').length - 1];


  useEffect(()=>{
    dispatch(remove());
    dispatch(add([
      {
        title: 'Admin Console',
        path: 'admin/settings'
      },
      {
        title: 'Customers',
        path: 'admin/customers'
      },
      {
        title: settings?.companyName
      }
    ]));
  },[settings]);

  const panelStyle = {
    marginBottom: 2,
    background: '#fff',
    border: 'none',
    borderRadius: 1
  };

  useEffect(()=>{
    if(clientId !== 'CLI3103B7FE26'){
      getClientsById(clientId);
    }
  },[]);

  
  const onValuesChange = (changedValues, refresh) => {
    const updatedCObj = deepMerge(settings, changedValues);
    if(refresh === 'refresh'){
      handleFinish(updatedCObj, refresh);
      setSettings(updatedCObj);
    }
  };

  const getClientsById=(clientId)=>{
    setLoading(true);
    clientsAPI.getClientById(clientId).then(
      (data) => {
        setLoading(false);
        setSettings(data?.customer);

        form.setFieldsValue(Object.assign(settings, data?.customer));
        dispatch(add([
          {
            title: 'Customers',
            path:'customers'
          },
          {
            title: data?.customer?.companyName
          }
        ]));
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const handleFinish = useCallback((values, refresh) => {
    setLoading(true);
   
    if(settings?.customerId){
      values = settings;
    }

    clientsAPI.createOrUpdateClient(values, clientId).then(
      (data) => {
        setLoading(false);
        getClientsById(clientId);
        if(refresh !== 'refresh'){
          KoToast.success(`Customer ${settings?.customerId ? 'updated':'created'}  successfully`);
        }
      },
      (err) => {
        setLoading(false);
      }
    );
  });


  const HEADER_OPTIONS = [{
    label: <div className={styles.header}>
      <div className={styles.circle}>
        <KoIcon name="BusinessFilled" height={12} width={12} />
      </div>
      <div className={styles.title}>
        <KoTitle className={styles.title} level={5} text="Business Information" />
        <KoDescription className={styles.description} text="Details about your business information" />
      </div>
    </div>,
    children: <BusinessInfoCurd onValuesChange={onValuesChange} settings={settings} form={form} />,
    key: 1,
    style: panelStyle
  },
  {
    label: <div className={styles.header}>
      <div className={styles.circle}>
        <KoIcon name="BusinessProfileFilled" height={15} width={15} />
      </div>
      <div className={styles.title}>
        <KoTitle className={styles.title} level={5} text="Business Profile" />
        <KoDescription className={styles.description} text="Details about your business profile" />
      </div>
    </div>,
    children: <BusinessProfile  settings={settings} form={form} />,
    key: 2,
    style: panelStyle
  }, {
    label: <div className={styles.header}>
      <div className={styles.circle}>
        <KoIcon name="PreferencesFilled" height={12} width={12} />
      </div>
      <div className={styles.title}>
        <KoTitle className={styles.title} level={5} text="General Preferences" />
        <KoDescription className={styles.description} text="Describe about your general preferences" />
      </div>
    </div>,
    children: <PreferencesCrud  settings={settings} form={form} />,
    key: 3,
    style: panelStyle
  }, {
    label: <div className={styles.header}>
      <div className={styles.circle}>
        <KoIcon name="LocationFilled" height={20} width={20} />
      </div>
      <div className={styles.title}>
        <KoTitle className={styles.title} level={5} text="Address Information" />
        <KoDescription className={styles.description} text="Details about organization  location" />
      </div>
    </div>,
    children: <AddressCrud  settings={settings} form={form} />,
    key: 4,
    style: panelStyle
  }, {
    label: <div className={styles.header}>
      <div className={styles.circle}>
        <KoIcon name="BankFilled" height={14} width={14} />
      </div>
      <div className={styles.title}>
        <KoTitle className={styles.title} level={5} text="Bank Details" />
        <KoDescription className={styles.description} text="Details about organization bank info" />
      </div>
    </div>,
    children: <BankCrud  settings={settings} form={form} />,
    key: 5,
    style: panelStyle
  }, {
    label: <div className={styles.header}>
      <div className={styles.circle}>
        <KoIcon name="TaxFilled" height={12} width={12} />
      </div>
      <div className={styles.title}>
        <KoTitle className={styles.title} level={5} text="Tax Details" />
        <KoDescription className={styles.description} text="Details about your tax levied directly" />
      </div>
    </div>,
    children: <TaxCrud   settings={settings} form={form} />,
    key: 6,
    fields: 2,
    outof: 5,
    style: panelStyle
  }, {
    label: <div className={styles.header}>
      <div className={styles.circle}>
        <KoIcon name="DiscountFilled" height={16} width={16} />
      </div>
      <div className={styles.title}>
        <KoTitle className={styles.title} level={5} text="Discount Details" />
        <KoDescription className={styles.description} text="Details about your discount rates" />
      </div>
    </div>,
    children: <DiscountCrud  settings={settings} form={form} />,
    key: 7,
    style: panelStyle
  }];

  return (
    <div className={styles.layout}>
      {isLoading&& <KoLoader/>}
      <div className={styles.saveAction}>
        <KoBreadcrumb items={[
          {
            title: <div className={styles.breadcrumb}>
              {isBack && <Link className={styles.back} to="/clients">
                <KoIcon name={'ArrowLeftOutlined'} />
              </Link>}
              <KoTitle className={styles.title} strong={true} level={5} text={`The settings for ${settings?.companyName}. have been configured.`} />
            </div>
          }
        ]} />
        <KoDescription className={styles.descriptionTitle} text={`All of the preferences below are directly associated
          with the current business (${settings?.companyName})
          you are currently using. Changes on this page will only affect the current business.`} />
      </div>
      <div className={styles.wrapper}>
        <KoCard hoverable={false} className={styles.tabsContnet}>
          <div className={styles.accordion}>
            <KoForm form={form} layout="vertical" onValuesChange={onValuesChange} onFinish={handleFinish} onSubmit={handleFinish}>
              <KoAccordion defaultActiveKey={[1]} expandIconPosition={'end'} data={HEADER_OPTIONS} bordered={false} />
            </KoForm>
          </div>
        </KoCard>
        <Preview settings={settings} form={form}/>
      </div>
    </div>
  );
}

export default ClientDetails;