import React, { useState, useMemo, useEffect } from 'react';
import EmojiPicker, { Emoji } from 'emoji-picker-react';


import styles from './chat.module.scss';
import { KoButton, KoDP, KoDropdown, KoEditorReadOnly, KoIcon, KoPopOver, KoText } from 'packages';
import TailIn from './tailin';
import TailOut from './tailout';
import Driver from 'packages/drivers';
import { useSelector } from 'react-redux';
import TicektCard from './ticektCard';

const MessageBubble = ({ data, updateMessage, users, deleteMessage, handleSendReaction }) => {
 
  const [messageObject, setMessageObject] = useState(data || {});
  const userById = useSelector(state => state?.userById);
  const [isOwnMessage, setOwnMessage] = useState(false);

  useEffect(() => {
    setMessageObject(data);
  }, [data]);
 
  const currentUserId  = userById?.data?.userId;
  function ownMessage(message, currentUserId) {
    const { sender } = message;
    return sender === currentUserId;
  }
  useEffect(() => {
    if(messageObject){
      setOwnMessage(ownMessage(messageObject, currentUserId));
    }
  }, [messageObject, currentUserId]);

  const renderMessageContent = (message) => {
   
    switch (message?.type) {
    case 'text':
      return message?.attachments?.length >0 ? <div className={styles.messageCard}>
        <div className={styles.thumbnailContainer}>
          {message?.attachments?.map((file, index) => (
            <div
              key={index}
              className={`${styles.thumbnail}`}>
              <Driver fileType={file?.type} filePath={file?.url} />
            </div>
          ))}
        </div>
        <div className={styles.messageCard}>
          <KoEditorReadOnly text='-' initialValue={message?.content} />
        </div>
      </div> :
        <div className={styles.messageCard}>
          <KoEditorReadOnly text='-' initialValue={message?.content} />
        </div>;
    case 'ticket':
      return <div className={styles.messageCard}>
        <TicektCard card={message}/>
      </div>;
   
    case 'pdf':
      return message?.attachments?.length >0 ? <div className={styles.messageCard}>
        <div className={styles.thumbnailContainer}>
          {message?.attachments?.map((file, index) => (
            <div
              key={index}
              className={`${styles.thumbnail}`}>
              <Driver thumbnail={true} fileType={file?.type} filePath={file?.url} />
            </div>
          ))}
        </div>
        <div className={styles.messageCard}>
          <KoEditorReadOnly text='-' initialValue={message?.content} />
        </div>
      </div> :
        <div className={styles.messageCard}>
          <KoEditorReadOnly text='-' initialValue={message?.content} />
        </div>;
    case 'emoji':
      return <div className={`${styles.messageCard} ${styles.emojiMessageCard}`}>
        <div className={`${styles.emojiMessage} `}>
          <KoEditorReadOnly text='-' initialValue={message?.content} />
        </div>
      </div>;
    default:
      return message?.attachments?.length >0 ? <div className={styles.messageCard}>
        <div className={styles.thumbnailContainer}>
          {message?.attachments?.map((file, index) => (
            <div
              key={index}
              className={`${styles.thumbnail}`}>
              <Driver fileType={file?.type} filePath={file?.url} />
            </div>
          ))}
        </div>
        <div className={styles.messageCard}>
          <KoEditorReadOnly text='-' initialValue={message?.content} />
        </div>
      </div> :
        <div className={styles.messageCard}>
          <KoEditorReadOnly text='-' initialValue={message?.content} />
        </div>;
    }
  };

  const items = [
    {
      label: "Message Info",
      key: "messageInfo"
    },
    {
      label: "Reply",
      key: "reply"
    },
    {
      label: "Edit",
      key: "edit"
    },
    {
      type: 'divider'
    },
    {
      label: "Delete",
      key: "delete",
      danger: true
    }
  ];

  const itemsOthers = [
    {
      label: "Reply",
      key: "reply"
    }
    
  ];

  const onEmojiClick = (data) => {
    handleSendReaction(messageObject?.messageId, data?.unified, currentUserId);
  };

  const usernameColor = useMemo(() => {
    const randomDarkColor = `hsl(${Math.floor(Math.random() * 360)}, 50%, 30%)`;
    return randomDarkColor;
  }, [messageObject?.profile?.firstName, messageObject?.profile?.lastName]);

  const onHandelMessage = (key) => {
    switch (key) {
    case 'messageInfo':
      break;
    case 'download':
      break;
    case 'reply':
      updateMessage(messageObject, 'reply');
      break;
    case 'edit':
      updateMessage(messageObject);
      break;
    case 'delete':
      deleteMessage(messageObject?.messageId);
      break;
    default:
      break;
    }
  };

  const emojiContent = (
    <div className={styles.popContent}>
      <EmojiPicker
        Theme="auto"
        height={450}
        width={350}
        emojiStyle="native"
        onEmojiClick={onEmojiClick}
        previewConfig={{ showPreview: false }}
        suggestedEmojisMode='recent'
        skinTonesDisabled={true}
        reactionsDefaultOpen={true}  />
    </div>
  );
  const formatTime = (dateTime) => {
    const date = dateTime instanceof Date ? dateTime : new Date(dateTime);
  
    return date?.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit"
    });
  };

  return (
    messageObject?.messageId && <div className={styles.messageContainer}  style={{
      display: 'flex',
      flexDirection: isOwnMessage ? 'row-reverse' : 'row',
      alignItems: 'center',
      margin: '3rem 0'
    }}>
      <div className={styles.messageContent} style={{ display: 'flex', flexDirection: 'column', maxWidth: '60%' }}>
        <KoDropdown placement='bottomRight'  menu={{
          items: isOwnMessage ? items  : itemsOthers,
          onClick: (event) => {
            onHandelMessage(event.key);
          }
        }}>
          <span className={`${styles.dropdownOutlined} ${!isOwnMessage ? styles.ddRight : styles.ddLeft}`}
          >
            <KoIcon name="DropdownOutlined" width={23} height={23}/>
          </span>
        </KoDropdown>
        {messageObject && <div className={styles.messageBox}>
          {(!isOwnMessage ) && (
            <div className={styles.dp}>
              {messageObject?.profile?.['logo'] ?

                <KoDP type={'text'} logo={messageObject?.profile?.logo} width={messageObject?.profile?.logo?.[0]?.name ? 29 : 20} height={messageObject?.profile?.logo?.[0]?.name ? 29 : 20} />
                :
                <KoText className={`${styles.letter} ${styles[messageObject?.profile?.firstName?.charAt(0).toLowerCase()]}`} text={messageObject?.profile?.firstName?.charAt(0)} />
              }

              {messageObject?.avatar ? <img
                src={messageObject?.avatar || 'default-avatar-url.jpg'}
                alt={`${messageObject?.profile?.firstName}'s avatar`}
                style={{
                  width: '30px',
                  height: '30px',
                  borderRadius: '50%'
                }}
              /> : <div className={styles.firstLetter}>
              </div>}
            </div>
          )}
          <div className={styles.message} style={{
            backgroundColor: isOwnMessage ? 'var(--active-light)' : 'var(--palette-gray-1)',
            color: isOwnMessage ? 'var(--palette-primary-darker)' : 'var(--palette-primary-darker)',
            borderRadius: '8px',
            position: 'relative',
            borderTopRightRadius: isOwnMessage ? '0' : '8px',
            borderTopLeftRadius: !isOwnMessage ? '0' : '8px'
          }}>
            {(!isOwnMessage )&& <div className={styles.username} style={{
              textAlign: isOwnMessage ? 'right' : 'left',
              color: usernameColor
            }}>
              {`${messageObject?.profile?.firstName} ${messageObject?.profile?.lastName}`}
            </div>}
            {isOwnMessage ? <div className={`${styles.tailOut} ${styles.tail}`}>
              <TailOut/>
            </div> : <div className={`${styles.tailIn} ${styles.tail}`}>
              <TailIn/>
            </div>
            }
            {messageObject?.parentMessageId && (
              <div className={`${isOwnMessage ? styles.messageContentOwnReply : styles.messageContentReply}`}>
                <div className={`${isOwnMessage ? styles.usernameOwnReply : styles.usernameReply}`}>
                  {`${isOwnMessage ? 'You' : messageObject?.profile?.firstName} ${messageObject?.profile?.lastName}`}
                </div>
                {Array.isArray(messageObject?.parentContent) && messageObject?.parentContent.map((item, index) => (
                  renderMessageContent(item)
                ))}
              </div>
            )}

            {renderMessageContent(messageObject)}
            <div className={`${styles.timeContainer} ${!isOwnMessage ? styles.timeRight : styles.timeLeft}`}>
              {/* <KoIcon name='LogOutlined' width={9} height={9} /> */}
              <KoText text={formatTime(messageObject?.dateTime)} className={styles.time} />
            </div>
            {messageObject?.reactions?.length > 0 && <div className={`${styles.reactionsContainer} ${isOwnMessage ? styles.reactionsRight : styles.reactionsLeft}`}>
              {messageObject?.reactions?.map((reaction, index) => (
                <div className={styles.reaction} key={index}>
                  <span className={styles.reactionIcon} >
                    <Emoji
                      size={17}
                      unified={reaction?.reaction}
                    />
                  </span>
                  {index === messageObject?.reactions?.length-1 && messageObject?.reactions?.length > 1 && <KoText text={messageObject?.reactions?.length} className={styles.reactionCount} />}
                </div>
              ))}
            </div>}
          </div>
        </div>}
      </div>
      <div className={`${styles.emojiContainer} popoverContainer`}>
        <KoPopOver className={styles.emojiPop} arrow={false} content={emojiContent} trigger="click">
          <KoButton className={styles.emojiBtn} shape="circle" type="text" iconBtn={true}
            icon={ <KoIcon name="SmileyOutlined" width={21} height={21}/>} />
        </KoPopOver>
      </div>
    </div>
  );
};

export default MessageBubble;
