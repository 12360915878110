import { createSlice } from "@reduxjs/toolkit";

const initialState = [];
const wosAssigneesSlice = createSlice({
  name: "wosAssignees",
  initialState,
  reducers: {
    updateAssignees(state, action) {
      state.push(action.payload);
    },

    removeAssignees(state, action) {
      return state.filter(prd => prd?.id !== action.payload);
    }
  }
});

export const { updateAssignees, removeAssignees } = wosAssigneesSlice.actions;
export default wosAssigneesSlice.reducer;