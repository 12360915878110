import _find from 'lodash/find';
import KoIcon from "packages/icon";
import { TASKSTATUSLIST } from "../../shared/commonConstants";
import styles from './status.module.scss';
import KoPopOver from 'packages/popover';
import { KoText } from 'packages/typography';

function KoTaskStatus(props) {
  let { data, cob, row } = props;
  let issueType = _find(TASKSTATUSLIST, function(o) { return o.value === data; }) || {};
  
  return (
    cob ? <div className={`${styles.wrapper}`}>
      <div className={`${styles.status}`} style={{background: issueType?.background, color: issueType?.color}}>
        <div className={styles.text}>{issueType?.label}</div>
      </div>
    </div>:<div className={`${styles.wrapper}`}>
      <div className={`${styles.status}`} style={{background: issueType?.background, color: issueType?.color}}>
        <KoPopOver title={'Work Log'} content={
          <div className={styles.worklog}>
            <KoText text={`Time spent: ${row?.timeSpent ? row?.timeSpent : '0h'}`}/>
            <KoText text={`Time remaining: ${row?.timeRemaining ? row?.timeRemaining : '0h'}`}/>
          </div>
        }>
          <div className={styles.text}>
            <KoIcon width={14} height={14} name={'LogOutlined'}/>
          </div>
        </KoPopOver>
        <div className={styles.text}>{issueType?.label}</div>
      </div>
    </div>
  );
}
export default KoTaskStatus;
