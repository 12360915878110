import { createRef } from 'react';
import { when } from 'packages/kanban/services/utils';
import styles from '../../kanban.module.scss';
function ColumnForm({ onConfirm, onCancel }) {
  // FIXME use hook
  const inputColumnTitle = createRef();

  function addColumn(event) {
    event.preventDefault();

    when(inputColumnTitle.current.value)(onConfirm);
  }

  return (
    <div className={styles.kanbanColumn} style={{ minWidth: '230px' }}>
      <form style={{ display: 'flex', justifyContent: 'space-between' }} onSubmit={addColumn}>
        <input type='text' ref={inputColumnTitle} autoFocus />
        <button type='submit'>Add</button>
        <button type='button' onClick={onCancel}>
          Cancel
        </button>
      </form>
    </div>
  );
}

export default ColumnForm;
