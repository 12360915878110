import React from "react";
function Stop(props) {
  const { width, height, color } = props;
  return (
    

    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill={color? color : 'var(--palette-primary-darker)'}
      {...props}
    >
      <path
        d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2zm4 13c0 .6-.4 1-1 1H9c-.6 0-1-.4-1-1V9c0-.6.4-1 1-1h6c.6 0 1 .4 1 1v6z"
        className="color000 svgShape"
      />
    </svg>
  );
}


export default Stop;