import { KoAPI } from "packages";
import { configs } from 'configuration';
const version = configs().API_VERSION;

export const OrgAPI = {
  getCount(data) {
    return KoAPI.get(`/api/${version}/org/user/statistics`, { data });
  },
  getUserById(id) {
    return KoAPI.get(`/api/${version}/org/user/${id}`);
  },
  getGroupsCount(data) {
    return KoAPI.get(`/api/${version}/org/group/statistics`, { data });
  },
  getOrgUsers(query) {
    return KoAPI.get(`/api/${version}/org/user`, { query: { ...query } });
  },
  getActiveUsers(query) {
    return KoAPI.get(`/api/${version}/org/user/active`, { query: { ...query } });
  },
  createOrgUsers(data, id) {
    if(id) {
      return KoAPI.update(`/api/${version}/org/user/${id}`, { data: { ...data } });
    } else {
      return KoAPI.post(`/api/${version}/org/user`, { data: { ...data } });
    }
  },
  userDelete(id){
    return KoAPI.delete(`/api/${version}/org/user/${id}`);
  },
  getOrgGroups(query) {
    return KoAPI.get(`/api/${version}/org/group`, { query: { ...query } });
  },
  createOrgGroups(data, id) {
    if(id) {
      return KoAPI.update(`/api/${version}/org/group/${id}`, { data: { ...data } });
    } else {
      return KoAPI.post(`/api/${version}/org/group`, { data: { ...data } });
    }
  },
  groupdDelete(id){
    return KoAPI.delete(`/api/${version}/org/group/${id}`);
  },
  getOrgDesignations(query) {
    return KoAPI.get(`/api/${version}/org/designation`, { query: { ...query } });
  },
  createOrgDesignations(data, id) {
    if(id) {
      return KoAPI.update(`/api/${version}/org/designation/${id}`, { data: { ...data } });
    } else {
      return KoAPI.post(`/api/${version}/org/designation`, { data: { ...data } });
    }
  },
  resetPassword(data){
    return KoAPI.update(`/api/${version}/account/password/reset`, { data: { ...data } });
  },
  addToGroup(data){
    return KoAPI.update(`/api/${version}/org/group/update`, { data: { ...data } });
  },
  statusUpdate(data){
    return KoAPI.update(`/api/${version}/org/user/status`, { data: { ...data } });
  },
  getOrgDepartment(query) {
    return KoAPI.get(`/api/${version}/org/department`, { query: { ...query } });
  },
  createDepartment(data, id){
    if(id){
      return KoAPI.update(`/api/${version}/org/department/${id}`, { data: { ...data } });
    }else{
      return KoAPI.post(`/api/${version}/org/department`, { data: { ...data } });
    }
  },
  deleteDepartment(id){
    return KoAPI.delete(`/api/${version}/org/department/${id}`);
  },
  deleteDesignation(id){
    return KoAPI.delete(`/api/${version}/org/designation/${id}`);
  },
  profileUpload(data){
    return KoAPI.post(`/api/${version}/upload`, { data: { ...data } });
  }

};