import React from "react";
function Subtask(props) {
  const { width,color,  height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="4" fill={color ? color :"var(--subtask)"} />
      <path d="M19 10.8333V6.75C19 5.7875 18.2125 5 17.25 5H6.75C5.7875 5 5 5.7875 5 6.75V10.8333H7.33333V14.9167C7.33333 15.8792 8.12083 16.6667 9.08333 16.6667H10.8333V17.25C10.8333 18.2125 11.6208 19 12.5833 19H17.25C18.2125 19 19 18.2125 19 17.25V13.1667H10.8333V15.5H9.08333C8.7625 15.5 8.5 15.2375 8.5 14.9167V10.8333H19Z" fill="white" />
    </svg>
  );
}


export default Subtask;