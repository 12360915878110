import React from "react";
function Folder3(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 66.942 50.551">
      <defs>
        <linearGradient id="igw8s54v1b" x1=".882" y1="1.781" x2=".125" y2="-.285" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#ff9517" stopOpacity="0" />
          <stop offset=".307" stopColor="#ee8421" stopOpacity=".306" />
          <stop offset=".678" stopColor="#e07728" stopOpacity=".678" />
          <stop offset="1" stopColor="#db722b" />
        </linearGradient>
        <linearGradient id="7qjscl7t7c" x1=".5" y1="-.356" x2=".5" y2="1.011" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#fffcf8" />
          <stop offset=".188" stopColor="#faf8f6" />
          <stop offset=".404" stopColor="#eceef2" />
          <stop offset=".635" stopColor="#d5dcea" />
          <stop offset=".875" stopColor="#b5c4df" />
          <stop offset="1" stopColor="#a1b5d8" />
        </linearGradient>
        <linearGradient id="cln5ppn9na" x1=".993" y1=".572" x2=".714" y2=".535" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#a1b5d8" stopOpacity="0" />
          <stop offset=".67" stopColor="#8190a4" stopOpacity=".671" />
          <stop offset="1" stopColor="#748290" />
        </linearGradient>
        <linearGradient id="6lr3un0a0d" x1=".869" y1="2.371" x2=".149" y2="-1.02" href="#cln5ppn9na" />
        <linearGradient id="44c38al8ue" x1=".5" y1=".137" x2=".5" y2=".843" gradientUnits="objectBoundingBox">
          <stop offset="0" stopColor="#ff4974" />
          <stop offset=".228" stopColor="#fd4571" />
          <stop offset=".466" stopColor="#f93867" />
          <stop offset=".71" stopColor="#f12358" />
          <stop offset=".955" stopColor="#e60642" />
          <stop offset="1" stopColor="#e4003d" />
        </linearGradient>
      </defs>
      <path data-name="Path 30350" d="M162.725 45.3h-39.77a1.9 1.9 0 0 1-1.9-1.9V5.076A5.076 5.076 0 0 1 126.127 0h35.658a5.076 5.076 0 0 1 5.076 5.076v36.093a4.136 4.136 0 0 1-4.136 4.131zm0 0" transform="translate(-106.866)" fill="#ff8ea3" />
      <path data-name="Path 30351" d="M122.955 70.2h39.77a4.136 4.136 0 0 0 4.136-4.136V33.329l-3.955-3.955a3.518 3.518 0 0 0-2.628-1.175h-38.909a5.064 5.064 0 0 0-.319 1.772v38.324a1.9 1.9 0 0 0 1.905 1.905zm0 0" transform="translate(-106.866 -24.895)" fill="#fc6c82" />
      <path data-name="Path 30352" d="M131.794 3.827a5.3 5.3 0 0 0 5.049 3.69h14.064a5.255 5.255 0 0 1-.552-.03l-5.71-5.706a5.3 5.3 0 0 0-3.836-1.644h-5.986a5.286 5.286 0 0 0-4.038 1.87 5.29 5.29 0 0 1 1.009 1.82zm0 0" transform="translate(-115.46 -.121)" fill="url(#igw8s54v1b)" />
      <path data-name="Path 30353" d="M80.076 70.2H33.73a3.527 3.527 0 0 1-3.53-3.531V31.723a3.527 3.527 0 0 1 3.53-3.523h46.346a3.527 3.527 0 0 1 3.524 3.523v34.946a3.527 3.527 0 0 1-3.524 3.531zm0 0" transform="translate(-26.664 -24.891)" fill="url(#7qjscl7t7c)" />
      <path data-name="Path 30354" d="M78.694 64.784a5.282 5.282 0 0 0-3.852-1.662H30.2V97.5a3.527 3.527 0 0 0 3.527 3.527h46.349A3.527 3.527 0 0 0 83.6 97.5V69.692zm0 0" transform="translate(-26.664 -55.725)" fill="url(#cln5ppn9na)" />
      <path data-name="Path 30355" d="M138.281 28.6a5.3 5.3 0 0 0 5.049 3.69h14.064a5.258 5.258 0 0 1-.552-.03l-4.065-4.06h-14.644c.054.127.104.26.148.4zm0 0" transform="translate(-121.947 -24.891)" fill="url(#6lr3un0a0d)" />
      <path data-name="Path 30356" d="M141.852 8.853V7.517h-.445a5.3 5.3 0 0 1-5.049-3.69 5.3 5.3 0 0 0-5.049-3.69h-5.986a5.3 5.3 0 0 0-5.3 5.3v3.416zm0 0" transform="translate(-105.959 -.121)" fill="url(#44c38al8ue)" />
      <path data-name="Path 30357" d="M16.334 3.827a5.3 5.3 0 0 0-5.049-3.69H5.3A5.3 5.3 0 0 0 0 5.436v34.756a5.233 5.233 0 0 0 5.233 5.233h49.691a1.447 1.447 0 0 1-1.447-1.447V12.816a5.3 5.3 0 0 0-5.3-5.3h-26.8a5.3 5.3 0 0 1-5.043-3.689zm0 0" transform="translate(0 -.121)" fill="#fa93aa" />
    </svg>

  );
}


export default Folder3;