import React, { useState, useEffect } from 'react';
import {KoTable, KoLoader,
  KoButton, KoModal,KoTitle, KoText,KoDropdown,KoNano,
  KoPagination, KoForm , KoToast, KoIcon,
  KoTooltips} from 'packages';
import KoToolBar from 'shared/components/toolbar';
import { COLUMNS_designATION } from './constant';
import { OrgAPI } from '../org.service';
import DesignationCrud from './crud';
import { useDispatch } from 'react-redux';
import { add, remove } from 'shared/store/breadcrumbSlice';
import styles from '../org.module.scss';

function OrgDepartment() {
  const dispatch = useDispatch();
  const [totalCount, setTotalCount] = useState(100);
  const [dataList, setDataList] = useState();
  const [isOpen, setModal] = useState(false);
  const [isOpenDelete, setDeleteModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [currentRecord, setCurrentRecord] = useState();
  const [pagination, setPagination] = useState({
    limit: 20,
    offset: 0,
    page: 1
  });
  const [form] = KoForm.useForm();

  useEffect(() => {
    dispatch(remove());
    dispatch(add([
      {
        title: 'Home',
        path: 'home/dashboard/myoverview'
      },
      {
        title: 'Department'
      }
    ]));
    
    getOrgDepartment();
  }, []);

  const getOrgDepartment = () => {
    setLoading(true);
    OrgAPI.getOrgDepartment(pagination).then(
      (data) => {
        setDataList();
        setTotalCount();
        setLoading(false);
        setDataList(data['departments']);
        setTotalCount(data['totalCount']);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const onSearchText=(text)=>{
    let q = pagination;
    if(text){
      q['search'] = text;
    }
    else{
      delete q?.['search'];
    }
    getOrgDepartment(q);
  };

  const onSort = (key, type) => {
    let q = pagination;
    q['sort'] = `${key}:${type}`;
    getOrgDepartment(q);
  };

  const onPagination = (page, other) => {
    const query = {
      size: other,
      page: page,
      offset: (page - 1) * other
    };
    const pQuery = Object.assign(pagination, query);
    setPagination(pQuery);
    getOrgDepartment(pQuery);
  };

  const handleFinish = (values => {
    setLoading(true);
    if(!currentRecord?.departmentId){
      values['departmentId'] = KoNano('DEP');
    }
    OrgAPI.createDepartment(values, currentRecord?.departmentId).then(
      (data) => {
        getOrgDepartment();
        setLoading(false);
        setModal(false);
        form.resetFields();
        KoToast.success(`Department ${currentRecord?.departmentId ? 'updated':'created'}  successfully! ${values.name}!`);
      },
      (err) => {
        setLoading(false);
        KoToast.error(`error!`);
      }
    );
  });

  const handleFinishDelete=()=>{
    setLoading(true);
    OrgAPI.deleteDepartment(currentRecord?.departmentId).then(
      (data) => {
        getOrgDepartment();
        setLoading(false);
        // setResetPassModal(false);
        setDeleteModal(false);
        KoToast.success(`Deleted successfully!`);
      },
      (err) => {
        setLoading(false);
        KoToast.error(`Login Successful! Welcome back!`);
      }
    );
  };

  const handleRowActions = ({ key }) => {
    if (key === '2') {
      setDeleteModal(true);
    }

  };

  const items = [
    // {
    //   key: '1',
    //   label: (<KoText text='Update Department' />),
    //   icon: <KoIcon name='EditOutlined' />
    // },
    // {
    //   type: 'divider'
    // },
    {
      key: '2',
      danger: true,
      label: 'Delete',
      icon: <KoIcon name='DeleteOutlined' />
    }
  ];

  const columnsRight = [{
    title: '',
    dataIndex: 'x',
    key: 'x',
    fixed: 'right',
    width: 40,
    render: (text, row) => {
      return <div className={`${styles.circle} tableBtn columnsRight`} onClick={() => setCurrentRecord(row)}>
        <KoTooltips title={'Edit'}>
	      <KoButton  className={'btn'} iconBtn={true} icon={<KoIcon width={14} height={14} name={'EditOutlined'}/>} onClick={() => setModal(true)}/>
        </KoTooltips>
        <KoDropdown
          menu={{
            items,
            onClick: handleRowActions
          }}
          overlayClassName={styles.actionDropdown}
          placement="bottomLeft"
        >
          <div shape="circle" >
            <KoIcon name="VerticalMenuOutlined" onClick={() => (row)} />
          </div>
        </KoDropdown>

      </div>;
    }
  }];

  return (<div className='tableCard'>
    { isLoading && <KoLoader />}
    <KoToolBar
      title={'Departments '}
      search={{ visible: true, onChange: (e) => { onSearchText(e);}, placeholder: 'Search by any parameter' }}
      filter={{ visible: false,
        options: [{ label: 'Sort By Name', key: '0' },
          { label: 'Sort By Roles', key: '1' },
          { label: 'Created At', key: '2' },
          { label: 'Sort By designation', key: '3' }] }}
      buttonList={[{
        label: 'Create Department',
        icon:<KoIcon color="#FFF" name="PlusOutlined" width={18} height={18}/>,
        type: 'primary',
        onClick: () => {setCurrentRecord();   form.resetFields();setModal(!isOpen);}
      }]} />

    <KoTable rowKey='departmentId' extraColumnsRight={columnsRight} onSort={onSort}
      data={{ columns: COLUMNS_designATION, rows: dataList }} maxHeight={100} />
    <KoPagination current={parseInt(pagination.page) || 1}
      total={totalCount}
      showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} records`
      }
      pageSize={pagination.limit}
      defaultCurrent={pagination.page}
      onChange={onPagination}
      showQuickJumper={false}
      simple={false}
      responsive={false} />
    
    <KoModal
      title={currentRecord?.departmentId ? `Update Department ${currentRecord?.name?.toUpperCase()}` : 'Create Department'}
      centered
      open={isOpen}
      afterClose={() => {setCurrentRecord();}}
      handleOk={() => { setCurrentRecord(); setModal(false); }}
      onClose={() => { setCurrentRecord(); setModal(false); }}
      width={680}
      // maskClosable={false}
      height={650}
      footer={[
        <KoButton key='cancel' label='Cancel' onClick={() => setModal(!isOpen)} />,
        <KoButton key='create' type='primary' label={`${currentRecord?.departmentId ? 'Update' : 'Create'}`} onClick={() => form.submit()} />
      ]}
    >
      {isLoading && <KoLoader circle={false} />}
      <DesignationCrud initialValues={currentRecord} form={form} handleFinish={handleFinish} />
    </KoModal>

    <KoModal
      title={`Delete Departments ${currentRecord?.name?.toUpperCase()}`}
      centered
      open={isOpenDelete}
      afterClose={() => {setCurrentRecord();}}
      handleOk={() => { setCurrentRecord(); setDeleteModal(false); }}
      onClose={() => { setCurrentRecord(); setDeleteModal(false); }}
      width={580}
      // maskClosable={false}
      height={450}
      footer={[
        <KoButton key='cancel' label='Cancel' onClick={() => setDeleteModal(!isOpenDelete)} />,
        <KoButton key='reset' loading={isLoading} type='primary' label='Delete' onClick={() => handleFinishDelete()} />
      ]}
    >
      {isLoading && <KoLoader circle={false} />}
      <div className={styles.suspend}>
        <KoTitle level={5} text={`Are you sure you want to delete this departments?`} />
        <KoText text={`This action is irreversible, and it will result in the removal of their associated data.`} />
      </div>
    </KoModal>
  </div>);
}

export default OrgDepartment;