import { useCallback, useEffect } from 'react';

import { KoForm, KoFormBuilder, KoUserSelect } from 'packages';
import { useSelector } from 'react-redux';
import styles from './department.module.scss';

function DepartmentCrud({ form, initialValues,  handleFinish}) {

  const users = useSelector(state => state.users);
  
  useEffect(() => {
    if (!initialValues || Object.keys(initialValues).length === 0) {
      form.resetFields();
    } else {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);



  const onFinish = useCallback(values => {
    handleFinish(values);
  });

  const Members = ({ value, onChange }) =>
    <KoUserSelect value={value} onChange={v => onChange(v)} users={users['data']} placeholder='Select Department Head'/>;




  const meta = {
    columns: 4,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      {
        key: 'name', label: 'Department Name', colSpan: 2, rules: [
          {
            required: true,
            message: 'Department name is required'
          }
        ]
      },
      {
        key: 'head', label: 'Department Head', colSpan: 2,  widget: Members, rules: [
          {
            required: true,
            message: 'Department head is required'
          }
        ]
      }
    ]
  };

  return (
    <div className={styles.wrapper}>
      <KoForm form={form} layout="vertical"
        onFinish={onFinish} onSubmit={onFinish}
      >
        <KoFormBuilder form={form} meta={meta} />
      </KoForm>
    </div>
  );
};

export default DepartmentCrud;