import { KoFormBuilder, KoMonaco, KoTable, KoTitle } from "packages";
import ParamsCrud from "../params";
import RequestBody from "../requestBody";
import HeaderCrud from "../headers";
import styles from './crud.module.scss';
import moment from "moment";

export const getRequetsTabs = (form, initialValues) => {

  const RquestParams = ({ onChange }) =>
    <ParamsCrud onChange={(v) => onChange(v)} initialValues={initialValues} form={form} />;
  const RquestHeaders = ({ onChange }) =>
    <HeaderCrud onChange={(v) => onChange(v)} initialValues={initialValues} form={form} />;
  const RquestBody = ({ onChange }) =>
    <RequestBody onChange={(v) => onChange(v)} initialValues={initialValues} form={form} />;

  return [
    {
      label: 'Headers',
      key: 'headers',
      children: <div className={styles.tabWrapper}>
        <KoFormBuilder form={form} meta={{
          initialValues,
          fields: [
            {
              key: 'requestHeaders',
              widget: RquestHeaders
            }
          ]
        }} />
      </div>
    },
    {
      label: 'Params',
      key: 'queryParams',
      children: <div className={styles.tabWrapper}>
        <KoFormBuilder form={form} meta={{
          initialValues,
          fields: [
            {
              key: 'requestParams',
              widget: RquestParams
            }
          ]
        }} />
      </div>
    },
    {
      label: 'Body',
      key: 'body',
      children: <div className={styles.tabWrapper}>
        <KoFormBuilder form={form} meta={{
          initialValues,
          fields: [
            {
              key: 'body',
              widget: RquestBody
            }
          ]
        }} />
      </div>
    }
  ];
};

export const getResponseTabs = (form, initialValues) => {

  const ResponseBody = () => {
    let data = '';
    try {
      data = JSON.parse(initialValues?.responseBody);
    } catch (err) {
      data = initialValues?.responseBody;
    }
    return  <div  className={styles.JSONdata}>
      <KoMonaco
        className={styles.JSONdata}
        options={
          { readOnly: true }
        }
        language="json"
        JSONdata={data}
      /></div>;
  };

  const responseHeaderTabelColumns = [
    {
      id: "name",
      label: "Name",
      widget: "text",
      width: 100
    },
    {
      id: "value",
      label: "Value",
      widget: "text",
      width: 100
    }
  ];

  const responseData = Object.keys(initialValues?.responseHeaders ? initialValues?.responseHeaders : {})?.map(key => {
    if (key === "Date") {
      const dateStr = initialValues?.responseHeaders?.[key]?.join(',');
      return {
        name: key,
        value: moment(dateStr).format("DD-MMM-YYYY HH:mm A")
      };
    } else {
      return {
        name: key,
        value: initialValues?.responseHeaders?.[key]?.join(",")
      };
    }

  });

  const ResponseHeaders = () => <KoTable
    maxHeight ={"37rem"}
    data={
      {
        columns: responseHeaderTabelColumns,
        rows: responseData?.length ? responseData : []
      }
    } />;

  return [
    {
      label: 'Body',
      key: 'body',
      children: <div className={styles.tabWrapper}>
        {initialValues?.statusCode && <div className={styles.statusCode}>
          <KoTitle level={5} text={`Status Code: ${initialValues?.statusCode}`} />
        </div>}
        <KoFormBuilder form={form} meta={{
          initialValues,
          fields: [
            {
              key: 'responseBody',
              widget: ResponseBody
            }
          ]
        }} />
      </div>
    },
    {
      label: 'Headers',
      key: 'headers',
      children: <div className={styles.tabWrapper}>
        {initialValues?.statusCode && <div className={styles.statusCode}>
          <KoTitle level={5} text={`Status Code: ${initialValues?.statusCode}`} />
        </div>}
        <KoFormBuilder form={form} meta={{
          initialValues,
          fields: [
            {
              key: 'responseHeaders',
              widget: ResponseHeaders
            }
          ]
        }} />
      </div>
    }
  ];
};