import isHotkey from "is-hotkey";
import { KoAffix, KoButton, KoCard, KoColorPicker, KoIcon, KoInput, KoSelect, KoTitle } from "packages";
import { useCallback, useMemo, useState } from "react";
import ImageResizer from 'react-image-height-width-resizer';
import {
  createEditor,
  Editor,
  Element as SlateElement,
  Transforms
} from "slate";
import { withHistory } from "slate-history";
import { Editable, Slate, useSlate, withReact } from "slate-react";
import { HEADER, SIZE } from './data';
import styles from './editor.module.scss';
import { Toolbar } from "./toolbar";
const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code"
};
const LIST_TYPES = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: n =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true
  });
  let newProperties;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format
    };
  } else {
    newProperties = {
      type: isActive ? "paragraph" : isList ? "list-item" : format
    };
  }
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};



const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor, format, blockType = "type") => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: n =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format
    })
  );

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const Element = ({ attributes, children, element }) => {
  const style = { textAlign: element.align };
  switch (element.type) {
  case "block-quote":
    return (
      <blockquote style={style} {...attributes}>
        {children}
      </blockquote>
    );
  case "bulleted-list":
    return (
      <ul style={style} {...attributes}>
        {children}
      </ul>
    );
  case "h1":
    return (
      <h1 style={style} {...attributes}>
        {children}
      </h1>
    );
  case "h2":
    return (
      <h2 style={style} {...attributes}>
        {children}
      </h2>
    );
  case "h3":
    return (
      <h3 style={style} {...attributes}>
        {children}
      </h3>
    );
  case "h4":
    return (
      <h4 style={style} {...attributes}>
        {children}
      </h4>
    );
  case "h5":
    return (
      <h5 style={style} {...attributes}>
        {children}
      </h5>
    );
  case "h6":
    return (
      <h6 style={style} {...attributes}>
        {children}
      </h6>
    );

  case "list-item":
    return (
      <li style={style} {...attributes}>
        {children}
      </li>
    );
  
  case "numbered-list":
    return (
      <ol style={style} {...attributes}>
        {children}
      </ol>
    );
  case window.fontSize:
    return (
      <font size={window.fontSize} style={style} {...attributes}>
        {children}
      </font>
    );
  case "left":
    return (
      <span style={style} {...attributes}>
        {children}
      </span>
    );
  case "center":
    return (
      <span style={style} {...attributes}>
        {children}
      </span>
    );
  case "right":
    return (
      <span style={style} {...attributes}>
        {children}
      </span>
    );
  case "justify":
    return (
      <span style={style} {...attributes}>
        {children}
      </span> );
  case "image":
    return (
      <ImageResizer
        src={element.url}
        height={200}
        width={400}/>
    );
  default:
    return (
      <p style={style} {...attributes}>
        {children}
      </p>
    );
  }
};
const Leaf = ({ attributes, children, leaf }) => {
  const style = { color: window.myTextColor };
  const bgStyle = { backgroundColor: window.myBgColor, color: '#ffff' };
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <i>{children}</i>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }
  if (leaf.TextColor) {
    children = <span style={style}>{children}</span>;
  }
  if (leaf.BgColor) {
    children = <span style={bgStyle}>{children}</span>;
  }
  if (leaf.strikethrough) {
    children = <s>{children}</s>;
  }
  if (leaf.link) {
    children = <a href={children?.props?.text?.text}>{children?.props?.text?.text}</a>;
  }
  

  return <span {...attributes}>{children}</span>;
};


const ColorButton = ({ format, icon }) => {
  const [TextColor, setTextColor] = useState('#1677ff');
  const [currentColor, setCurrentColor] = useState();
  const editor = useSlate();

  const onChange = (color) =>{
    setCurrentColor(color.toHexString());
    setTextColor(color);
    
  };
  window.myTextColor=currentColor;
  return (
    <KoColorPicker value={TextColor} onChangeComplete={onChange}>
      <KoButton
        type="Text"
        iconBtn={true}
        active={isMarkActive(editor, format)}
        onClick={event => {
          event.preventDefault();
          toggleMark(editor, format);
        }}
        icon={<KoIcon name={icon} color={currentColor}/>}
      />
    </KoColorPicker>
  );
};

const BgColorButton = ({ format, icon }) => {
  const [TextBgColor, setTextBgColor] = useState('#1677ff');
  const [currentColor, setCurrentColor] = useState();
  const editor = useSlate();

  const onChange = (color) =>{
    setCurrentColor(color.toHexString());
    setTextBgColor(color);
  };
  window.myBgColor=currentColor;
  return (
    <KoColorPicker value={TextBgColor} onChangeComplete={onChange} >
      
      <KoButton
        type="Text"
        iconBtn={true}
        active={isMarkActive(editor, format)}
        onClick={event => {
          event.preventDefault();
          toggleMark(editor, format);
        }}
        icon={<KoIcon name={icon} color={currentColor}/>}
      />
    </KoColorPicker>
  );
};
const ImageUploader = ({ editor, icon, image}) => {
  const handleImageUpload = useCallback((e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      var url = reader.result;
      insertImage(url);
      
    };
    reader.readAsDataURL(file);
  }, []);
  
  const insertImage = (url) => {
    const image = { type: 'image', url, children: [{ text: '' }] };
    editor.insertNode(image);
  };
  
  return (
    <label htmlFor="imageInput"><KoIcon name={icon}/>
      <KoInput type="file"  id="imageInput" accept="image/*" onChange={handleImageUpload} className={styles.input}/>
    </label>
  );
};


const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <KoButton
      type="text"
      iconBtn={true}
      active={isBlockActive(
        editor,
        format,
        TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
      )}
      onClick={event => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
      icon={<KoIcon name={icon} />}
    />

  );
};


const BlockTextButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <KoSelect
      defaultValue="Normal text"
      onChange={opt => {
        toggleBlock(editor, opt);
      }}
      options={HEADER}
    />
  );
};


const BlockSizeButton = ({ format, icon, setSize }) => {
  const editor = useSlate();
  return (
    <KoSelect
      defaultValue="13"
      onChange={opt => {
        toggleBlock(editor, opt);
        setSize(opt);
      }}
      options={SIZE}
    />
  );
};

// const BlockFontsButton= ({ format, icon }) => {
//   const editor = useSlate();
//   return (
//     <KoSelect
//       defaultValue="13"
//       onChange={opt => {
//         toggleBlock(editor, opt);
//       }}
//       options={FONTS}
//     />
//   );
// };

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <KoButton
      type="text"
      iconBtn={true}
      active={isMarkActive(editor, format)}
      onClick={event => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
      icon={<KoIcon name={icon} />}
    />
  );
};

// const initialValue = [
 
//   {
//     type: 'paragraph',
//     children: [
//       { text: 'This is editable' }
//     ]
//   }

// ];


const KoEditor = (props) => {
  const { fileName, onSaveFile, initialValues } = props;
  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const [fontSize, setFontSize] = useState();
  const [title, setTitle] = useState(fileName ? fileName : "untitled");
  const [data, setData] = useState();
  window.fontSize = fontSize;
  
  const onChange = (value) => {
    setData({
      name : title,
      content : value
    });
  };

  const handleSubmit = () => {
    onSaveFile(data);
  };


  return (
    <Slate editor={editor} initialValue={initialValues} onChange={onChange}>

      <KoAffix top={55}>
        <div className={styles.shadowBar}>
          <div className={styles.titleAction}>
            <KoTitle text={title} level={5} editable={{ onChange: (e) => setTitle(e) }} />
            <div className={styles.actions}>
              <KoButton label="Save & Continue" type="primary" onClick={value =>handleSubmit()}/>
            </div>
          </div>
          <Toolbar className={styles.toolbar}>
            <BlockTextButton format="heading"/>
            <BlockSizeButton format="size"  size={fontSize} setSize={setFontSize}/>
            {/* <BlockFontsButton format="font"  /> */}
            <MarkButton format="bold" icon="BoldOutlined" />
            <MarkButton format="italic" icon="ItalicOutlined" />
            <MarkButton format="underline" icon="UnderlineOutlined" />
            <MarkButton format="strikethrough" icon='StrikethroughOutlined'/>
            <ColorButton format="TextColor" icon='TextColorOutlined'/>
            <BgColorButton format="BgColor" icon='BgColorOutlined'/>
            <MarkButton format="code" icon="CodeOutlined" />
            <MarkButton format="link" icon="LinkOutlined" />
            <ImageUploader format="Image" icon="ImagesOutlined" editor={editor}/>
            <BlockButton  format="Smiley" icon="SmileyOutlined"/>
            <BlockButton  format="At" icon="AtOutlined" />
            <BlockButton  format="Attachment" icon="AttachmentOutlined" />
            <BlockButton format="block-quote" icon="DoubleQuotesOutlined" />
            <BlockButton format="numbered-list" icon="NumberedOutlined" />
            <BlockButton format="bulleted-list" icon="BulletOutlined" />
            <BlockButton format="left" icon="LeftOutlined" />
            <BlockButton format="center" icon="CenterOutlined" />
            <BlockButton format="right" icon="RightOutlined" />
            <BlockButton format="justify" icon="JustifyOutlined" />
            <BlockButton  format="Table" icon="TableOutlined" />
            <BlockButton  format="SearchDoc" icon="SearchDocOutlined" />
          </Toolbar>
        </div>
      </KoAffix>
        
      <KoCard
        hoverable={false}
        className={styles.card}
      >
        <div className={styles.wrapper}>
          <Editable
            className={styles.editableWrapper}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder="Enter some rich text…"
            spellCheck
            autoFocus
            onKeyDown={event => {
              for (const hotkey in HOTKEYS) {
                if (isHotkey(hotkey, event)) {
                  event.preventDefault();
                  const mark = HOTKEYS[hotkey];
                  toggleMark(editor, mark);
                }
              }
            }}
          />
        </div>
      </KoCard>
    </Slate>
  );
};


export default KoEditor;