import React from "react";
function Tax(props) {
  const { width, height } = props;
  return (
    <svg fill="#FFF" width={width} height={height} viewBox="-174.08 -174.08 1372.16 1372.16" stroke="#FFF" strokeWidth="0.01024">
      <g id="SVGRepo_bgCarrier" strokeWidth="0" transform="translate(0,0), scale(1)">
        <rect x="-174.08" y="-174.08" width="1372.16" height="1372.16" rx="219.5456" fill="var(--palette-primary-darker)" strokeWidth="0"></rect>
      </g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" stroke="#CCCCCC" strokeWidth="2.048"></g>
      <g id="SVGRepo_iconCarrier"><path d="M486.4 422.4c0-42.667-34.133-76.8-76.8-76.8s-76.8 34.133-76.8 76.8c0 42.667 34.133 76.8 76.8 76.8s76.8-34.133 76.8-76.8zm-106.667 0c0-21.333 17.067-34.133 34.133-34.133s34.133 17.067 34.133 34.133-17.067 34.133-34.133 34.133-34.133-12.8-34.133-34.133zm226.134 251.733c42.667 0 76.8-34.133 76.8-76.8s-34.133-76.8-76.8-76.8-76.8 34.133-76.8 76.8 34.133 76.8 76.8 76.8zm0-110.933c17.067 0 34.133 17.067 34.133 34.133 0 21.333-17.067 34.133-34.133 34.133s-34.133-17.067-34.133-34.133c0-21.333 17.067-34.133 34.133-34.133zM396.8 652.8c4.267 0 12.8-4.267 17.067-8.533l217.6-226.133c8.533-8.533 8.533-21.333 0-29.867s-21.333-8.533-29.867 0L384 614.4c-8.533 8.533-8.533 21.333 0 29.867 0 4.267 4.267 8.533 12.8 8.533z"></path><path d="M955.733 512c0-247.467-200.533-448-448-448C264.533 64 64 264.533 64 512c0 238.933 187.733 435.2 426.667 448 12.8 0 21.333-8.533 21.333-21.333s-8.533-21.333-21.333-21.333C273.067 908.801 102.4 729.601 102.4 512.001c0-221.867 179.2-405.333 405.333-405.333s405.333 179.2 405.333 405.333c0 187.733-128 349.867-311.467 392.533l17.067-29.867c4.267-8.533 4.267-21.333-8.533-29.867-8.533-4.267-21.333-4.267-29.867 8.533l-34.133 59.733c-4.267 0-4.267 4.267-8.533 8.533s-4.267 8.533-4.267 12.8 4.267 17.067 8.533 21.333c4.267 0 8.533 4.267 12.8 4.267l59.733 34.133c4.267 0 8.533 4.267 12.8 4.267 8.533 0 12.8-4.267 17.067-8.533 4.267-8.533 4.267-21.333-8.533-29.867l-17.067-12.8c200.533-55.467 337.067-230.4 337.067-435.2z">
      </path>
      </g>
    </svg>

  );
}


export default Tax;