import React, {useState} from 'react';
import { useSelector } from "react-redux";
import { KoTable} from 'packages';
import KoToolBar from 'shared/components/toolbar';
import { COLUMNS_USERS } from './constant';
import styles from './curd.module.scss';

function AssigneeCrud({ handleFinishAssignee, selected }) {
  const users = useSelector(state => state.users);
  const [userList, setUserList] = useState(users['data']);


  const onRowSelection=(data)=>{
    handleFinishAssignee(data);
  };


  const onSearchAssignees=(query)=>{
    query = query.toLowerCase();
    if (!query) {
      setUserList(users['data']);
      return;
    }

    let usersData =  userList.filter(user =>
      user.profile.firstName.toLowerCase().includes(query) ||
        user.profile.lastName.toLowerCase().includes(query) ||
        user.email.toLowerCase().includes(query)
    );

    setUserList(usersData);
  };

  return (
    <div className={styles.wrapper}>
      <KoToolBar
        title={'Select assignees '}
        search={{ visible: true, onChange: (txt) => {onSearchAssignees(txt);}, placeholder: 'Search by name' }}
        filter={{
          visible: false,
          options: [{ label: 'Sort By Name', key: '0' },
            { label: 'Created At', key: '2' },
            { label: 'Sort By designation', key: '3' }]
        }}
        buttonList={[]} />
      <KoTable rowKey='userId'
        selected={selected}
        onRowSelection={onRowSelection}
        data={{ columns: COLUMNS_USERS, rows: userList }}
        maxHeight={60} />
    </div>
  );
};

export default AssigneeCrud;