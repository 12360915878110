import { KoButton, KoForm, KoKanban, KoLoader, KoModal, KoText, KoToast, KoDP, KoTabs, KoTicketTable, KoBreadcrumb } from "packages";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { getTicketBreadCrumb, TASKSTATUSLIST } from "shared/commonConstants";
import TaskCrud from "../task/crud";
import { TaskPlannerAPI } from "../taskPlanner.service";
import BulkCrud from "./BulkCrud";
import styles from './scrum.module.scss';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setSelectedChatData, setSelectedChatType } from "shared/store/chatSlice";
const Scrum = ({profile}) => {
  const {workspaceId} = useParams();
  const userById = useSelector(state => state?.userById);
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(false);
  const [tickets, setTickets] = useState();
  const [currentRecord, setCurrentRecord] = useState();
  const [isBulkOpen, setBulkModal] = useState();
  const [isOpen, setModal] = useState(false);
  const [form] = KoForm.useForm();
  let userDetails = userById['data'];
  const [breadCrumbs, setBreadCrumbs] = useState();
  const [subPagination, setSubPagination] = useState({
    size: 10,
    page: 1
  });
  const [subCurrentRecord, setSubCurrentRecord] = useState({
    status:1,
    issueType:2,
    priority:3,
    reporter: userDetails?.userId
  });
  const [openSubModel, setSubModel] = useState(false);
  const [isSubLoading, setSubLoading] = useState(false);
  const [subTotalCount, setSubTotalCount] = useState(100);
  const [ subTickets ,setSubTickets] = useState();

  const [query ] = useState({
    sort: 'assigneeName:asc'
  });
  
  useEffect(()=>{
    getTickets();
  },[]);

  const handleFinish = useCallback((values) => {
    setLoading(true);
    TaskPlannerAPI.createOrUpdateIssues(values, currentRecord?.ticketId, workspaceId).then(
      (data) => {
        setLoading(false);
        setModal(false);
        form.resetFields();
        KoToast.success(`Ticket ${currentRecord?.ticketId ? 'updated' : 'created'} successfully`);
      },
      (err) => {
        setLoading(false);
      }
    );
  }, [form]);
  

  const getCards = (tickets) => {
    var mapped =tickets?.map(item => ({ 'id': item.ticketId, 'summary': item.summary, 'status': item.status, ...item}) );
    const groups =mapped.reduce((groups, item) => {
      const group = (groups[item.status] || []);
      group.push(item);
      groups[item.status] = group;
      return groups;
    }, {});
    const initializeBoard = TASKSTATUSLIST.map(status => ({
      id: status.value,
      label: status.label,
      background: status.background,
      color: status.color,
      cards: []
    }));
        
    const populateBoardWithTickets = (tickets) => {
      const board = { columns: initializeBoard };
      if (!tickets) return board;
      Object.entries(tickets).forEach(([status, cards]) => {
        const column = board.columns.find(col =>
          col.id === parseInt(status)
        );
        if (column) {
          column.cards = cards;
        }
      });
          
      return board;
    };
    const populatedBoard = populateBoardWithTickets(groups);
    return populatedBoard;
  };

  const getTickets=()=>{
    setLoading(true);
    TaskPlannerAPI.getIssuesByScrum(query, workspaceId).then(
      (data) => {
        setLoading(false);
        setTickets();
        let tickets = [];
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          const cards = getCards(element?.tickets ? element?.tickets : []);
          tickets.push({
            key: element?.assignee,
            label: <div className={styles.assignee}>
              <KoDP className={styles.dp} width={18} height={18} logo={element?.['logo']} />
              <KoText className={styles.text} ellipsis={true} text={element?.assigneeName} />
            </div>,
            children:cards ? <KoKanban
              initialBoard={cards}
              onCardDragEnd={UpdateStatus}
              onNewCardConfirm={draftCard => ({
                id: new Date().getTime(),
                ...draftCard
              })}
              setSubModel={setSubModel}
              onCellClick={onCellClick}
              onCardNew={()=>{}}
            /> : <></>
          });
        }
        setTickets(tickets);
      },
      (err) => {
        setLoading(false);
      }
    );
  };




  const STATUS = [{
    label: 'Todo',
    value: 1
  },
  {
    label: 'In Progress',
    value: 2
  },
  {
    label: 'Blocked',
    value: 3
  },
  {
    label: 'Ready For QA',
    value: 4
  },
  {
    label: 'In Review',
    value: 5
  },
  {
    label: 'On Hold',
    value: 6
  },
  {
    label: 'QA Failed',
    value: 7
  },
  {
    label: 'Reopen',
    value: 12
  },
  {
    label: 'Abandoned',
    value: 8
  },
  {
    label: 'Deferred',
    value: 9
  },
  {
    label: 'Done',
    value: 10
  },
  {
    label: 'Closed',
    value: 11
  }];

  const ModifyTheLabel = async(status) => {
    const statusObject = STATUS.find(item => item.label === status);
    return statusObject ? statusObject.value : null;
  };

  
  const UpdateStatus =async(newStatus) => {
    const {cardId, columnHeader, currentCardData} = newStatus;
    const newStatusValue = await ModifyTheLabel(columnHeader);

    const updatedValues = {
      ...currentCardData,
      status: newStatusValue
    };

   
    TaskPlannerAPI.createOrUpdateIssues(updatedValues, currentCardData?.ticketId,  workspaceId)
      .then(
        (data) => {
          KoToast.success(`${cardId} status updated successfully`);
          getTickets();
        },
        (err) => {
        }
      );
  };

  const getSubIssueById = (ticketId)=> {
    setSubLoading(true);
    TaskPlannerAPI.getSubTaskIssuesWithQuery(subPagination, ticketId,  workspaceId).then(
      (data) => {
        setSubLoading(false);
        setSubTotalCount(data?.totalCount);
        setSubTickets(data?.tickets);
      },
      (err) => {
        setSubLoading(false);
      }
    );
  };

  const getIssueById = (ticketId, workspaceIdFromParam)=> {
    setLoading(true);
    TaskPlannerAPI.getIssueById(ticketId, workspaceId || workspaceIdFromParam).then(
      (data) => {
        setLoading(false);
        setSubCurrentRecord(data?.issue);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const onCellClick=(row)=>{
    setCurrentRecord(row);
    const updatedRoomObj = { ...row, source: 'tickets' };
    dispatch(setSelectedChatData(updatedRoomObj));
    dispatch(setSelectedChatType('tickets'));
    let crums = getTicketBreadCrumb(row);
    setBreadCrumbs(crums);
    getSubIssueById(row.ticketId);
  };




  const onChangeSubStatus=(key)=>{
    let q =  subPagination;
    if(key){
      q['status'] = key.toString();
    }else{
      delete q?.['status'];
    }
   
    getSubIssueById(currentRecord.ticketId, q);
  };

  const onChangeSubType=(key)=>{
    let q =  subPagination;
    if(key){
      q['issueType'] = key.toString();
    }else{
      delete q['issueType'];
    }
    getSubIssueById(currentRecord.ticketId, q);
  };

  const onChangeSubPriority =(key)=>{
    let q =  subPagination;
    if(key){
      q['priority'] = key.toString();
    }else{
      delete q?.['priority'];
    }
   
    getSubIssueById(currentRecord.ticketId, q);
  };

  const onChangeSubAssignees =(key)=>{
    let q =  subPagination;
    if(key){
      q['assignee'] = key.toString();
    }else{
      delete q?.['assignee'];
    }
   
    getSubIssueById(currentRecord?.ticketId, q);
  };
  
  const onSubSearchText=(text)=>{
    let q = subPagination;
    q['search'] = text;
    getSubIssueById(currentRecord?.ticketId, q);
  };

  const onSubSort = (key, type) => {
    let q = subPagination;
    q['sort'] = `${key}:${type}`;
    getSubIssueById(currentRecord?.ticketId, q);
  };
   
  const onSubPagination = (page, other) => {
    const query = {
      size: other,
      page: page
     
    };
    const p = Object.assign(subPagination, query);
    setSubPagination(p);
    getSubIssueById(currentRecord?.ticketId, p);
  };


  return (
    <div className={`${styles.wrapper} collapse`}>
      {tickets && <KoTabs options={tickets} defaultActiveKey={['1']}  /> }
      <KoModal
        title={ breadCrumbs ? <KoBreadcrumb items={breadCrumbs} />
          : (currentRecord?.ticketId) ? `Update Issue ${(currentRecord?.ticketId).toUpperCase()}` : `Create Issue`}
        centered
        open={isOpen}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => {setCurrentRecord(); setModal(false);form.resetFields(); }}
        onClose={() => {setCurrentRecord(); setModal(false); form.resetFields();}}
        width={'95vw'}
        height={'95vh'}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => {setModal(!isOpen); form.resetFields();}} />,
          <KoButton key='create' type='primary' label={currentRecord?.ticketId ? `Update` : `Create`} onClick={() => form.submit()} />
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <TaskCrud form={form} handleFinish={handleFinish} initialValues={subCurrentRecord} />
      </KoModal>

      <KoModal
        title={`Bulk Update`}
        centered
        open={isBulkOpen}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setBulkModal(false); form.resetFields();}}
        onClose={() => { setCurrentRecord(); setBulkModal(false); form.resetFields();}}
        width={780}
        // maskClosable={false}
        height={650}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => {setBulkModal(!isBulkOpen); form.resetFields();}} />,
          <KoButton key='update' loading={isLoading} type='primary' label={ `Update`}
            onClick={() => form.submit()} />
        ]}
      >
        <BulkCrud initialValues={currentRecord} form={form} />
      </KoModal>

      <KoModal
        title={`${currentRecord?.ticketId } Subtasks Lists`}
        centered
        open={openSubModel}
        afterClose={() => { setSubCurrentRecord(); setBreadCrumbs();}}
        handleOk={() => { setSubCurrentRecord(); setSubModel(false); }}
        onClose={() => { setSubCurrentRecord(); setSubModel(false); }}
      // maskClosable={false}
        width={`95vw`}
        footer={[]}>
        <>
          {isSubLoading && <KoLoader circle={false} />}
          <KoTicketTable
            setModal={setModal}
            onSearchText={onSubSearchText}
            profile={profile}
            API={TaskPlannerAPI}
            getIssueById={getIssueById}
            onSort={onSubSort}
            onChangeStatus={onChangeSubStatus}
            onChangeType={onChangeSubType}
            onChangePriority={onChangeSubPriority}
            onChangeAssignees={onChangeSubAssignees}
            setCurrentRecord={setSubCurrentRecord}
            isOpen={openSubModel}
            maxHeight={100}
            Bulk={{
              visible: false
            }}
            RowSelectionOption={{
              visible: false
            }}
            setBreadCrumbs={setBreadCrumbs}
            createBtn={false}
            tickets={subTickets}
            // columnsRight={columnsRight}
            // onCellClickExternal={onExtraClick}
            onPagination={onSubPagination}
            pagination={{page: subPagination.page, limit: subPagination.limit}}
            totalCount={subTotalCount} />
        </>
      </KoModal>

    </div>
  );
};

export default Scrum;
