import React from "react";
function Google(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16.003 16.003">
      <path fill="#fbbb00" d="M3.547 143.617L2.99 145.7l-2.036.043a8.016 8.016 0 0 1-.059-7.472l1.813.332.794 1.8a4.775 4.775 0 0 0 .045 3.215z" transform="translate(0 -133.946)" />
      <path fill="#518ef8" d="M269.314 208.176a8 8 0 0 1-2.852 7.735l-2.283-.117-.323-2.017a4.769 4.769 0 0 0 2.052-2.435h-4.279v-3.165h7.686z" transform="translate(-253.45 -201.669)" />
      <path fill="#28b446" d="M42.566 313.979a8 8 0 0 1-12.057-2.448l2.593-2.123a4.759 4.759 0 0 0 6.858 2.437z" transform="translate(-29.555 -299.738)" />
      <path fill="#f14336" d="M40.838 1.842l-2.592 2.122a4.758 4.758 0 0 0-7.015 2.492l-2.607-2.134a8 8 0 0 1 12.214-2.48z" transform="translate(-27.729)" />
    </svg>
  );
}


export default Google;