import React from "react";
function Security(props) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 128 128"
      width={width}
      height={height}
      {...props}
    >
      <path d="M48.183 127.848a1.995 1.995 0 001.529 0c21.46-8.886 35.985-22.332 43.173-39.967a73.6 73.6 0 005.111-30.029 30.794 30.794 0 01-4.086-.68 69.773 69.773 0 01-4.73 29.2c-6.684 16.4-20.217 29-40.233 37.46-19.988-8.45-33.499-21.03-40.159-37.398-10.006-24.592-1.476-50.684.794-56.776 23.083 1.621 35.37-7.179 39.422-10.843 2.805 2.525 9.548 7.496 21.039 9.806Q70 27.816 70 27c0-.841.043-1.672.11-2.497-13.453-2.946-19.513-9.714-19.595-9.809A1.997 1.997 0 0049 14h-.003a1.999 1.999 0 00-1.52.705c-.114.132-11.62 13.268-39.04 10.85a2 2 0 00-2.013 1.2c-.551 1.28-13.34 31.648-1.355 61.152 7.159 17.622 21.664 31.06 43.114 39.94z" />
      <path d="M26.038 58.241C26.105 58.098 32.826 44 49.516 44c.173 0 .35.002.526.005 16.88.298 22.053 15.042 22.264 15.665a2 2 0 001.897 1.37 1.976 1.976 0 00.631-.104 2 2 0 001.267-2.528c-.06-.18-6.228-18.054-25.989-18.403a44.588 44.588 0 00-.618-.005c-19.192 0-26.77 15.878-27.086 16.562a2 2 0 103.63 1.68zm-3.335 3.568a2.005 2.005 0 00-2.257 1.703c-.234 1.676-1.293 10.222.918 13.917a2 2 0 003.432-2.055c-1.099-1.837-.889-7.719-.389-11.309a2 2 0 00-1.704-2.256z" />
      <path d="M77.937 71.974a1.997 1.997 0 00-2.434-1.44 5.088 5.088 0 01-5.294-1.187 5.512 5.512 0 01-1.463-3.134c-.515-3.606-2.987-12.37-14.795-15.873-11.87-3.522-19.644 4.456-22.123 9.21-.443.772-3.733 6.976-.856 16.14 2.264 7.216-2.577 10.689-2.808 10.85a2 2 0 002.235 3.318c2.633-1.761 6.94-7.239 4.39-15.366-2.445-7.786.388-12.746.502-12.941.022-.035.045-.077.065-.114.229-.44 5.784-10.724 17.457-7.263C62.4 57.02 64.38 63.937 64.787 66.779a9.474 9.474 0 002.572 5.376c1.652 1.674 4.578 3.424 9.138 2.253a2 2 0 001.44-2.434z" />
      <path d="M37.743 85.295c-.003.05-.336 5.099-3.063 7.443a2 2 0 102.607 3.033c4.03-3.463 4.435-9.974 4.45-10.25a2 2 0 00-3.994-.226zm37.64-2.97a2 2 0 00-1.888-2.106 15.217 15.217 0 01-11.18-5.697 13.158 13.158 0 01-2.538-5.313 16.17 16.17 0 00-2.936-6.25 9.502 9.502 0 00-6.792-3.6 9.556 9.556 0 00-9.873 5.551 12.968 12.968 0 00-.646 9.47c5.36 17.475 18.355 24.021 18.906 24.292a2 2 0 001.765-3.589c-.119-.06-12.016-6.124-16.847-21.874a8.978 8.978 0 01.424-6.56 5.573 5.573 0 015.987-3.3 5.493 5.493 0 013.928 2.079 12.21 12.21 0 012.196 4.72 17.106 17.106 0 003.332 6.91 19.128 19.128 0 0014.057 7.155 2.017 2.017 0 002.106-1.889z" />
      <path d="M51.706 70.524a2 2 0 00-3.889.934 29.381 29.381 0 0018.214 20.848 2 2 0 101.48-3.717 25.47 25.47 0 01-15.805-18.065zm-4.907 23.167a2 2 0 00-3.013 2.631c1.374 1.574 4.847 5.274 7.42 5.657a2.044 2.044 0 00.297.021 2 2 0 00.3-3.977c-.78-.163-3.164-2.228-5.004-4.332zM128 27a27 27 0 10-27 27 27.03 27.03 0 0027-27zm-27 23a23 23 0 1123-23 23.026 23.026 0 01-23 23z" />
      <path d="M113.586 15.586L95 34.172l-6.586-6.586a2 2 0 00-2.828 2.828l8 8a2 2 0 002.828 0l20-20a2 2 0 00-2.828-2.828z" />
    </svg>
  );
}


export default Security;