import React from "react";
function Payslip(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icons/invoice" clipPath="url(#clip0_110_34)">
        <path id="Vector" d="M6 19H18V13H6V19ZM7 14H17V18H7V14ZM11 10H6V9H11V10ZM11 6H6V5H11V6ZM14.707 0H4.5C3.122 0 2 1.122 2 2.5V24H22V7.293L14.707 0ZM15 1.707L20.293 7H15V1.707ZM3 23V2.5C3 1.673 3.673 1 4.5 1H14V8H21V23H3Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_110_34">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}


export default Payslip;