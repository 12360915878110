import React from "react";
import { Tag } from "antd";

function KoTag(props) {
  const { value, bordered = false } = props;
  return (
    <Tag bordered={bordered} {...props}>{value}</Tag>
  );
}
export default KoTag;
