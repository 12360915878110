import React, { useState, useRef, useEffect } from "react";
import { Input,  List, Avatar } from "antd";
import styles from "./kioai.module.scss";
import { KoIcon } from "packages";

const ChatUI = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const messageEndRef = useRef(null);

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSend = () => {
    if (input.trim()) {
      setMessages([
        ...messages,
        { id: messages.length + 1, sender: "user", text: input },
        { id: messages.length + 2, sender: "assistant", text: "Thanks for your message!" }
      ]);
      setInput("");
    }
  };

  return (
    <div className={styles.chatContainer}>
      {messages.length === 0 && (
        <div className={styles.chatHeader}>What can I help with?</div>
      )}
      {messages.length > 0 && (
        <div className={styles.messageList}>
          <List
            dataSource={messages}
            renderItem={(message) => (
              <div
                className={`${styles.messageItem} ${
                  styles[message.sender]
                }`}
              >
                {message.sender === "assistant" && (
                  <Avatar className={styles.avatar}>A</Avatar>
                )}
                <div
                  className={`${styles.messageBubble} ${
                    styles[message.sender]
                  }`}
                >
                  {message.text}
                </div>
                {message.sender === "user" && (
                  <Avatar className={styles.avatar}>U</Avatar>
                )}
              </div>
            )}
          />
          <div ref={messageEndRef} />
        </div>
      )}
      <div className={`${styles.footer} suffix`}>
        <div className={styles.inputContainer}>
          <Input
            placeholder="Message ChatGPT"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onPressEnter={handleSend}
            className={styles.inputBox}
            size="large"
            suffix={<KoIcon name='SendOutlined' />}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatUI;
