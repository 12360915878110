import React from "react";
function Featurerequrst(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="feature-improvement">
        <g fill="red">
          <g id="Vector">
            <path d="M2.72065 0.104222C1.68537 0.379965 0.876744 1.2072 0.601078 2.25502C0.466307 2.77587 0.466307 8.02725 0.601078 8.55422C0.876744 9.60818 1.69762 10.4232 2.7574 10.6989C3.27811 10.8337 8.52802 10.8337 9.05485 10.6989C10.1085 10.4232 10.9233 9.60205 11.1989 8.54197C11.3337 8.02725 11.3337 2.76974 11.1989 2.25502C10.9233 1.19494 10.1085 0.373838 9.05485 0.0980943C8.54027 -0.0367136 3.22297 -0.0305859 2.72065 0.104222Z"fill="red" />
            <path d="M18.8844 0.378751C18.6763 0.474376 18.4906 0.671251 18.4119 0.879376C18.3725 0.980626 18.35 1.65563 18.35 2.69063V4.34438L16.6119 4.36125C14.9525 4.37813 14.8625 4.38375 14.7162 4.49625C14.4181 4.71563 14.3281 4.89563 14.3281 5.25C14.3281 5.60438 14.4181 5.78438 14.7162 6.00375C14.8625 6.11625 14.9525 6.12188 16.6063 6.13875L18.3444 6.15563L18.3612 7.89375C18.3781 9.5475 18.3837 9.6375 18.4963 9.78375C18.7156 10.0819 18.8956 10.1719 19.25 10.1719C19.6044 10.1719 19.7844 10.0819 20.0037 9.78375C20.1163 9.6375 20.1219 9.5475 20.1388 7.89375L20.1556 6.15563L21.8938 6.13875C23.5475 6.12188 23.6375 6.11625 23.7838 6.00375C24.0819 5.78438 24.1719 5.60438 24.1719 5.25C24.1719 4.89563 24.0819 4.71563 23.7838 4.49625C23.6375 4.38375 23.5475 4.37813 21.8938 4.36125L20.1556 4.34438L20.1388 2.60625C20.1219 0.952501 20.1163 0.862501 20.0037 0.716251C19.9419 0.631876 19.8237 0.508126 19.745 0.451876C19.5537 0.311251 19.1094 0.271876 18.8844 0.378751Z"fill="red" />
            <path d="M2.72065 13.3042C1.68537 13.58 0.876744 14.4072 0.601078 15.455C0.466307 15.9759 0.466307 21.2272 0.601078 21.7542C0.876744 22.8082 1.69762 23.6232 2.7574 23.8989C3.27811 24.0337 8.52802 24.0337 9.05485 23.8989C10.1085 23.6232 10.9233 22.802 11.1989 21.742C11.3337 21.2272 11.3337 15.9697 11.1989 15.455C10.9233 14.3949 10.1085 13.5738 9.05485 13.2981C8.54027 13.1633 3.22297 13.1694 2.72065 13.3042Z"fill="red" />
            <path d="M15.9206 13.3042C14.8854 13.58 14.0767 14.4072 13.8011 15.455C13.6663 15.9759 13.6663 21.2272 13.8011 21.7542C14.0767 22.8082 14.8976 23.6232 15.9574 23.8989C16.4781 24.0337 21.728 24.0337 22.2548 23.8989C23.3085 23.6232 24.1233 22.802 24.3989 21.742C24.5337 21.2272 24.5337 15.9697 24.3989 15.455C24.1233 14.3949 23.3085 13.5738 22.2548 13.2981C21.7403 13.1633 16.423 13.1694 15.9206 13.3042Z"fill="red" />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_76_209">
          <rect x="0.5" width="24" height="24" rx="4" fill="red" />
        </clipPath>
      </defs>
    </svg>
  );
}


export default Featurerequrst;