import {
  KoButton,
  KoForm,
  KoLoader,
  KoModal,
  KoNano,
  KoTable,
  KoText, KoTitle,
  KoToast
} from 'packages';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { add, remove } from 'shared/store/breadcrumbSlice';
import KoToolBar from 'shared/components/toolbar';
import styles from '../org.module.scss';
import { OrgAPI } from '../org.service';
import { COLUMNS_USERS, ROWS } from './constant';
import UserCrud from './crud';

function Roles() {
  const dispatch = useDispatch();
  const [totalCount, setTotalCount] = useState(100);
  const [rowList, setDataList] = useState();
  const [isOpen, setModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [currentRecord, setCurrentRecord] = useState();
  const [isOpenActivate, setActivateModal] = useState(false);
  const [isOpenDelete, setDeleteModal] = useState(false);
  const [pagination] = useState({
    size: 20,
    offset: 0,
    page: 1
  });
  const [form] = KoForm.useForm();

  useEffect(() => {
    dispatch(remove());
    dispatch(add([
      {
        title: 'Home',
        path: 'home/dashboard/myoverview'
      },
      {
        title: 'Roles'
      }
    ]));

    getOrgUsers();
  }, []);

  const getOrgUsers = () => {
    setLoading(true);
    OrgAPI.getOrgUsers(pagination).then(
      (data) => {
        setDataList();
        setDataList(data['users']);
        setTotalCount(data['totalCount']);
        setLoading(false);
      },
      (err) => {
        console.log(totalCount);
        setLoading(false);
      }
    );
  };

  // const onPagination = (page, other) => {
  //   const query = {
  //     size: other,
  //     page: page,
  //     offset: (page - 1) * other
  //   };
  //   const pQuery = Object.assign(pagination, query);
  //   setPagination(pQuery);
  //   getOrgUsers(pQuery);
  // };

  const handleFinish = (values => {
    setLoading(true);
  
    if (!currentRecord?.userId) {
      values['roleId'] = KoNano('ROL');
      console.log(rowList);
    }
  });


 
  const handleFinishActivate = () => {
    setLoading(true);
    let obj = {
      userId: [currentRecord?.userId],
      status: 1
    };
    OrgAPI.statusUpdate(obj).then(
      (data) => {
        getOrgUsers();
        setLoading(false);
        setActivateModal(false);
        KoToast.success(`Profile activate successfully! ${currentRecord.profile?.firstName}!`);
      },
      (err) => {
        setLoading(false);
        KoToast.error(`error`);
      }
    );
  };

  const handleFinishDelete = () => {
    setLoading(true);
    let obj = {
      userId: [currentRecord?.userId],
      status: -1
    };
    OrgAPI.statusUpdate(obj).then(
      (data) => {
        getOrgUsers();
        setLoading(false);
        setDeleteModal(false);
        KoToast.success(`Profile deleted successfully! ${currentRecord.profile?.firstName}!`);
      },
      (err) => {
        setLoading(false);
        KoToast.error(`error`);
      }
    );
  };



  // const setCurrentRecordModyify =(data)=>{
  //   if(data?.profile?.logo?.length > 0){
  //     data['profile']['logo'][0]['url'] = `/organization/user/${data?.userId}/${data?.profile?.logo[0]?.name}`;
  //   }
  //   setCurrentRecord(data);
  // };

  // const columnsRight = [{
	//   title: '',
	//   dataIndex: 'x',
	//   key: 'x',
	//   fixed: 'right',
	//   width: 40,
	//   render: (text, row) => {
	//     return <div className={`${styles.circle} tableBtn columnsRight`} onClick={() => setCurrentRecordModyify(row)}>
	//        <KoTooltips title={'Edit Role'}>
	//       <KoButton  className={'btn'} iconBtn={true} icon={<KoIcon width={14} height={14} name={'EditOutlined'}/>}/>
  //       </KoTooltips>

  //       <KoTooltips title={'Delete Role'}>
  //         <KoButton className={'btn'}  iconBtn={true} icon={<KoIcon width={14} height={14} name={'DeleteOutlined'}/>}/>
  //       </KoTooltips>
	//     </div>;
	//   }
  // }];

  
  return (<div className='tableCard'>
    <KoToolBar
      title={'Roles'}
      search={{ visible: true, onChange: () => { }, placeholder: 'Search by any parameter' }}
      filter={{
        visible: false,
        options: [{ label: 'Sort By Name', key: '0' },
          { label: 'Created At', key: '2' },
          { label: 'Sort By designation', key: '3' }]
      }}
      buttonList={[]} />

    <KoTable rowKey='roleId' data={{ columns: COLUMNS_USERS, rows: ROWS }}
      maxHeight={100} />
    

    {/* <KoPagination current={parseInt(pagination.page) || 1}
      total={totalCount}
      showTotal={(total, range) =>
				`Displaying records ${range[0]}-${range[1]} of ${total}`
      }
      pageSize={pagination.size}
      defaultCurrent={pagination.page}
      onChange={onPagination}
      showQuickJumper={false}
      simple={false}
      responsive={false} /> */}
    <KoModal
      title={currentRecord?.userId ? `Update Role ${currentRecord?.profile?.firstName?.toUpperCase()}` : 'Add new role'}
      centered
      open={isOpen}
      afterClose={() => {setCurrentRecord();}}
      handleOk={() => { setCurrentRecord(); setModal(false); }}
      onClose={() => { setCurrentRecord(); setModal(false); }}
      width={780}
      // maskClosable={false}
      height={650}
      footer={[
        <KoButton key='cancel' label='Cancel' onClick={() => setModal(!isOpen)} />,
        <KoButton key='create' loading={isLoading} type='primary' label={`${currentRecord?.userId ? 'Update' : 'Create'}`}
          onClick={() => form.submit()} />
      ]}
    >
      {isLoading && <KoLoader circle={false} />}
      <UserCrud initialValues={currentRecord} form={form} handleFinish={handleFinish} />
    </KoModal>


    <KoModal
      title={`Activate user ${currentRecord?.profile?.firstName?.toUpperCase()}`}
      centered
      open={isOpenActivate}
      afterClose={() => {setCurrentRecord();}}
      handleOk={() => { setCurrentRecord(); setActivateModal(false); }}
      onClose={() => { setCurrentRecord(); setActivateModal(false); }}
      width={580}
      // maskClosable={false}
      height={450}
      footer={[
        <KoButton key='cancel' label='Cancel' onClick={() => setActivateModal(!isOpenActivate)} />,
        <KoButton key='reset' loading={isLoading} type='primary' label='Activate' onClick={() => handleFinishActivate()} />
      ]}
    >
      <div className={styles.suspend}>
        <KoTitle level={5} text='License fees apply to activated users,' />
        <KoText text={`granting access to all paid services.`} />
      </div>
    </KoModal>

    <KoModal
      title={`Delete user ${currentRecord?.profile?.firstName?.toUpperCase()}`}
      centered
      open={isOpenDelete}
      afterClose={() => {setCurrentRecord();}}
      handleOk={() => { setCurrentRecord(); setDeleteModal(false); }}
      onClose={() => { setCurrentRecord(); setDeleteModal(false); }}
      width={580}
      // maskClosable={false}
      height={450}
      footer={[
        <KoButton key='cancel' label='Cancel' onClick={() => setDeleteModal(!isOpenDelete)} />,
        <KoButton key='reset' loading={isLoading} type='primary' label='Delete' onClick={() => handleFinishDelete()} />
      ]}
    >
      {isLoading && <KoLoader circle={false} />}
      <div className={styles.suspend}>
        <KoTitle level={5} text={`Are you sure you want to delete this user?`} />
        <KoText text={`This action is irreversible, and it will result in the removal of their associated data and Kwapio license.`} />
      </div>
    </KoModal>
  </div>);
}

export default Roles;