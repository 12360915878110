import React from "react";

function Empty(props){
  const { width, height } = props;
  return(<svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1={0.538}
        y1={0.919}
        x2={0.433}
        y2={0.181}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#f79239" />
        <stop offset={1} stopColor="#f7c759" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        y1={0.5}
        x2={1}
        y2={0.5}
        gradientUnits="objectBoundingBox"
      >
        <stop offset={0} stopColor="#3f2323" />
        <stop offset={1} stopColor="#5e3434" />
      </linearGradient>
      <style>
        {
          ".cls-2{fill:#fff}.cls-3{fill:#4e8fb7}.cls-4{fill:#70b2dd}.cls-5{fill:#abdaf7}.cls-6{fill:#f49187}.cls-8{fill:#f6613a;opacity:.5}.cls-9{fill:#feb19e}.cls-15{fill:#90c6e0}"
        }
      </style>
    </defs>
    <g
      id="create_new_project_illustration"
      transform="translate(-416.5 -54.852)"
    >
      <g id="Group_14031" data-name="Group 14031">
        <path
          id="Path_75583"
          data-name="Path 75583"
          d="M673.46 162.78c-.07.4-.14.79-.21 1.18-5.03 28.28-19.75 40.71-19.75 40.71H432.65s-13.51-24.83-7.57-46.5c.09-.33.18-.66.28-.98 3.91-12.9 14.94-24.52 40.14-28.89 5.42-.94 10.41-1.84 15.01-2.7.65-.13 1.28-.25 1.91-.37 15.99-3.06 27.14-5.79 35.62-8.91h.01c.62-.23 1.23-.47 1.83-.7 17.23-6.78 23.12-15.59 38.56-33.49q1.8-2.085 3.84-4.09c.3-.3.6-.59.91-.87a85.4 85.4 0 0131.31-18.7c.32-.11.64-.22.96-.32 15.27-4.84 31.86-4.91 45.89 3.56.53.32 1.06.65 1.59 1 11.11 7.33 20.47 20.28 26.06 40.79 6.68 24.53 6.96 44.12 4.46 59.28z"
          fill="#e4ebf7"
        />
        <g id="Group_14030" data-name="Group 14030">
          <path
            id="Path_75584"
            data-name="Path 75584"
            className="cls-2"
            d="M651.21 108.54c-3.18 16.03-14.11 28.92-32.48 38.33-36.1 18.47-50.47 41.58-56.15 57.8h-1.05l.03-.09c5.69-16.41 20.15-39.89 56.71-58.6 18.09-9.26 28.84-21.92 31.96-37.63 3.21-16.2-2.23-33.19-8.88-46.64.53.32 1.06.65 1.59 1 6.4 13.36 11.41 29.96 8.27 45.83z"
          />
          <path
            id="Path_75585"
            data-name="Path 75585"
            className="cls-2"
            d="M673.46 162.78c-.07.4-.14.79-.21 1.18a397.87 397.87 0 01-50.31 18.7c-16.54 5.03-28.15 12.41-34.65 22.01h-1.2c6.55-10.07 18.47-17.77 35.56-22.97a396.222 396.222 0 0050.81-18.92z"
          />
          <path
            id="Path_75586"
            data-name="Path 75586"
            className="cls-2"
            d="M596.8 74.52c-.01 17.43-5.13 41.16-29.42 55.29-10.29 5.99-21.85 8.24-33.43 8.24-15.72 0-31.47-4.15-44.13-8.78-3.21-1.17-6.33-2.41-9.31-3.67.65-.13 1.28-.25 1.91-.37 25.01 10.31 58.86 18.61 84.46 3.72 23.88-13.89 28.91-37.27 28.92-54.43a103.483 103.483 0 00-1.3-16.05c.32-.11.64-.22.96-.32a104.765 104.765 0 011.34 16.37z"
          />
          <path
            id="Path_75587"
            data-name="Path 75587"
            className="cls-2"
            d="M560.66 108.29c-4.87 6.43-13.49 9.65-25.75 9.65a99.531 99.531 0 01-16.86-1.62c.62-.23 1.23-.47 1.83-.7 20.11 3.23 33.53.58 39.98-7.93 5.78-7.61 5.15-18.94 2.42-29.65.3-.3.6-.59.91-.87 2.91 11.08 3.64 22.99-2.53 31.12z"
          />
          <path
            id="Path_75588"
            data-name="Path 75588"
            className="cls-2"
            d="M543.57 204.67h-1.03c-2.12-8.37-6.05-17.41-13.22-24.75-11.75-12.03-29.59-17.06-53.02-14.93-21.45 1.94-38.61-.35-51.22-6.82.09-.33.18-.66.28-.98 12.46 6.45 29.49 8.73 50.85 6.8 23.76-2.15 41.86 2.98 53.83 15.24 7.38 7.56 11.39 16.86 13.53 25.44z"
          />
        </g>
      </g>
      <g id="Group_14032" data-name="Group 14032">
        <path
          id="Path_75589"
          data-name="Path 75589"
          className="cls-3"
          d="M457.633 195.25s5.75-12.875-7.5-22.875-16.25-17.25-16.25-17.25-4.476 23.481 3.625 34.625v16.125h19.614z"
        />
        <path
          id="Path_75590"
          data-name="Path 75590"
          className="cls-4"
          d="M445.773 205s-.65-9 8.311-15.025 8.831-12.1 8.831-12.1S469.8 195.111 461.1 205z"
        />
        <path
          id="Path_75591"
          data-name="Path 75591"
          className="cls-5"
          d="M447.762 205s1.385-12.125-9.932-18.625-13.693-14.875-13.693-14.875-6.25 21.125 4 33.5z"
        />
      </g>
      <g id="Group_14040" data-name="Group 14040">
        <g id="Group_14035" data-name="Group 14035">
          <g id="Group_14033" data-name="Group 14033">
            <path
              id="Path_75592"
              data-name="Path 75592"
              className="cls-6"
              d="M517.88 180.75a80.962 80.962 0 01-13.61 16.664v6.046h13.519a111.986 111.986 0 0012.831-8.84s2.807-18.703-12.74-13.87z"
            />
            <path
              id="Path_75593"
              data-name="Path 75593"
              className="cls-6"
              d="M602.88 192.25s-.63 12-3.63 12.75-15.292-10.38-15.292-10.38l-1.4-5.31 9.107-6.643S602.51 186 602.88 192.25z"
            />
          </g>
          <g id="Group_14034" data-name="Group 14034">
            <path
              id="Path_75594"
              data-name="Path 75594"
              d="M602.88 192.25c-2.88-5.5-20.63-1.5-20.63-1.5l12.63 12.71h-56.76c1.88-6.54.76-22.46.76-22.46-.01.12-.03.23-.05.35a17.764 17.764 0 01-4.21 9.77c-.24 3.26-4 3.5-4 3.5a22.1 22.1 0 00-1.93-7.21c-3.69-7.63-10.81-6.66-10.81-6.66-.76-4 3.5-5 3.5-5s5.24-30.5 12.87-36.5 20.07-9 20.07-9l12.81-.87s25.12 5 30.12 13.62 4.13 41.25 4.13 41.25c4 3.25 1.5 8 1.5 8z"
              fill="url(#linear-gradient)"
            />
            <path
              id="Path_75595"
              data-name="Path 75595"
              className="cls-8"
              d="M582.25 190.75s2.125-18.5-4.75-34.625c0 0 1.125 24.5-8.875 31.375s-27 5.5-31.25 17.5l37.5-.333z"
            />
            <path
              id="Path_75596"
              data-name="Path 75596"
              className="cls-8"
              d="M538.83 181.35a17.764 17.764 0 01-4.21 9.77c-.24 3.26-4 3.5-4 3.5a22.1 22.1 0 00-1.93-7.21 21.167 21.167 0 005.56-9.24c2.65-8.76.96-18.28.84-18.96a161.073 161.073 0 013.74 22.14z"
            />
          </g>
        </g>
        <path
          id="Path_75597"
          data-name="Path 75597"
          className="cls-9"
          d="M566 197.125s14.125-9.875 26.125-5.875 11 12.25 7.125 13.75h-29.917z"
        />
        <g id="Group_14036" data-name="Group 14036">
          <path
            id="Path_75598"
            data-name="Path 75598"
            className="cls-9"
            d="M569.77 129.83s-2.94 16.5-11.69 16.59c-7.64.07-5.87-9.95-5.3-12.5.08-.38.14-.59.14-.59l-3.17-12.08 16.83-8.91.34 1.85v.03z"
          />
          <path
            id="Path_75599"
            data-name="Path 75599"
            d="M552.78 133.92c.08-.38.14-.59.14-.59l-3.17-12.08 16.83-8.91.34 1.85v.03c2.21 19.7-13.83 19.7-14.14 19.7z"
            fill="#ff856a"
          />
          <path
            id="Path_75600"
            data-name="Path 75600"
            d="M568.812 106.438a29.552 29.552 0 002.75-9.813c.5-5.125-.937-6.312-3.062-6.312 0 0-1.125-5.813-3.813-7.813s-13.625.938-17.812 3.438-7.25-4.387-9.188-4.1-2.312 5.6-.812 7.975c0 0-3.5-1.938-4.063-3.938s-4.562 1.625-3.125 9.063 4.75 8.375 4.75 8.375.625 7.875 7.063 8.75 23.437-.625 23.437-.625z"
            fill="url(#linear-gradient-2)"
          />
          <path
            id="Path_75601"
            data-name="Path 75601"
            className="cls-9"
            d="M538.875 103.625s3.125 21.375 11 24.125 14.458-5.657 15.458-7.907 1.421-6.966 1.421-6.966 2.683.623 3-4.127-3.813-7.562-4.813.625c0 0-3.854-2.125-2.687-9.375a16.917 16.917 0 01-10.583 4.167c-6.167 0-.834-4.167-.834-4.167a16.935 16.935 0 01-11.962 3.625z"
          />
        </g>
        <g id="Group_14039" data-name="Group 14039">
          <g id="Group_14037" data-name="Group 14037">
            <path
              id="Rectangle_17769"
              data-name="Rectangle 17769"
              className="cls-5"
              transform="translate(536.75 202.25)"
              d="M0 0h51.333v2.75H0z"
            />
            <path
              id="Rectangle_17770"
              data-name="Rectangle 17770"
              className="cls-4"
              transform="translate(572.25 202.25)"
              d="M0 0h15.833v2.75H0z"
            />
            <path
              id="Path_75602"
              data-name="Path 75602"
              d="M579.417 202.333s-4.54-6.774-11.35-6.665c-5.153.082-5.344 3.332-5.344 3.332a3.09 3.09 0 00-3.473 3.333z"
              fill="#ffc7ae"
            />
          </g>
          <g id="Group_14038" data-name="Group 14038">
            <path
              id="Path_75603"
              data-name="Path 75603"
              className="cls-9"
              d="M515.583 201.917s.25-3.75-1.416-4.25a3.024 3.024 0 00-2.917.916s-1.25-5.5-5.917-5.666-13.083 4.583-7.916 9z"
            />
            <path
              id="Path_75604"
              data-name="Path 75604"
              className="cls-4"
              d="M527.58 203.46a1.541 1.541 0 01-1.54 1.54h-28.08a1.54 1.54 0 010-3.08h28.08a1.541 1.541 0 011.54 1.54z"
            />
            <path
              id="Path_75605"
              data-name="Path 75605"
              className="cls-5"
              d="M519.25 203.46a1.541 1.541 0 01-1.54 1.54h-19.75a1.54 1.54 0 010-3.08h19.75a1.541 1.541 0 011.54 1.54z"
            />
            <path
              id="Path_75606"
              data-name="Path 75606"
              d="M512 199.438l-4.978-16.266a1.406 1.406 0 00-1.708-.947 1.406 1.406 0 00-.953 1.855l5.951 15.795a1.272 1.272 0 001.688-.437z"
              fill="#525559"
            />
          </g>
        </g>
      </g>
      <g id="Group_14042" data-name="Group 14042">
        <path
          id="Path_75607"
          data-name="Path 75607"
          d="M476.937 158.966c.542 3.281-.638 6.209-2.636 6.539s-4.057-2.062-4.6-5.344.639-6.208 2.636-6.538 4.058 2.062 4.6 5.343z"
          stroke="#f230db"
          strokeMiterlimit={10}
          fill="#fff"
        />
        <path
          id="Path_75608"
          data-name="Path 75608"
          className="cls-15"
          d="M507.32 187.88h-53.31a5.721 5.721 0 01-5.3-3.61c-6.24-15.58-10.85-30.73-12.72-45.16a5.707 5.707 0 015.66-6.44h54.04a5.693 5.693 0 015.63 4.86c2.03 13.37 6 27.7 11.38 42.75a5.7 5.7 0 01-5.38 7.6z"
        />
        <path
          id="Path_75609"
          data-name="Path 75609"
          className="cls-5"
          d="M505.16 187.88h-51.15a5.721 5.721 0 01-5.3-3.61c-6.24-15.58-10.85-30.73-12.72-45.16a5.707 5.707 0 015.66-6.44h51.87a5.71 5.71 0 015.64 4.86c2.02 13.37 5.99 27.7 11.37 42.75a5.694 5.694 0 01-5.37 7.6z"
        />
        <g id="Group_14041" data-name="Group 14041">
          <path
            id="Path_75610"
            data-name="Path 75610"
            className="cls-15"
            d="M502.533 205h-20.48a6.979 6.979 0 01-6.59-4.7l-6.13-21.42c3.39.01 6.72-.01 9.99-.01 1.09 0 2.18 0 3.26.01l4.88 16.66a5.525 5.525 0 004.32 3.55l7.66 1.2a3.167 3.167 0 012.6 2.45z"
          />
          <path
            id="Path_75611"
            data-name="Path 75611"
            d="M491.783 199.09l-1.73-.16a6.5 6.5 0 01-5.47-4.04l-5.26-16.02c1.09 0 2.18 0 3.26.01l4.88 16.66a5.525 5.525 0 004.32 3.55z"
            style={{
              mixBlendMode: "multiply",
              isolation: "isolate"
            }}
            opacity={0.35}
            fill="#90c6e0"
          />
        </g>
        <path
          id="Path_75612"
          data-name="Path 75612"
          className="cls-2"
          d="M476.525 159.673c.759 3.167-.213 6.115-2.171 6.584s-4.161-1.717-4.92-4.885.212-6.115 2.17-6.584 4.161 1.717 4.921 4.885z"
        />
      </g>
      <g id="Group_14043" data-name="Group 14043">
        <path
          id="Rectangle_17771"
          data-name="Rectangle 17771"
          className="cls-4"
          transform="translate(416.5 205)"
          d="M0 0h243.5v5H0z"
        />
        <path
          id="Rectangle_17772"
          data-name="Rectangle 17772"
          className="cls-3"
          transform="translate(606 205)"
          d="M0 0h54v5H0z"
        />
      </g>
      <g id="Group_14044" data-name="Group 14044">
        <path
          id="Path_75613"
          data-name="Path 75613"
          className="cls-4"
          d="M634.5 184.583l-2.333-14.5-7.417.917 2.75 14.415z"
        />
        <path
          id="Path_75614"
          data-name="Path 75614"
          className="cls-3"
          d="M634.285 183.25l2.568-17.9a1.009 1.009 0 011.165-.852 1.009 1.009 0 01.831 1.148L636 184.167h1.083l3.819-14.186a1.036 1.036 0 011.25-.735 1.035 1.035 0 01.75 1.266l-3.569 13.655-3.583 1.666z"
        />
        <path
          id="Path_75615"
          data-name="Path 75615"
          className="cls-5"
          d="M641.833 183.25v18.46a2.96 2.96 0 01-2.96 2.96H628.21a2.96 2.96 0 01-2.96-2.96v-18.46z"
        />
        <path
          id="Rectangle_17773"
          data-name="Rectangle 17773"
          className="cls-15"
          transform="translate(625.25 183.25)"
          d="M0 0h16.583v4.33H0z"
        />
      </g>
      <g id="Group_14045" data-name="Group 14045">
        <circle
          id="Ellipse_463"
          data-name="Ellipse 463"
          cx={23.59}
          cy={23.59}
          r={23.59}
          transform="translate(601.553 69.685)"
          style={{
            mixBlendMode: "multiply",
            isolation: "isolate"
          }}
          opacity={0.35}
          fill="#70b2dd"
        />
        <circle
          id="Ellipse_464"
          data-name="Ellipse 464"
          className="cls-4"
          cx={22.197}
          cy={22.197}
          r={22.197}
          transform="rotate(-26.203 472.482 -1237.629)"
        />
        <circle
          id="Ellipse_465"
          data-name="Ellipse 465"
          className="cls-5"
          cx={16.331}
          cy={16.331}
          r={16.331}
          transform="rotate(-81.414 364.258 -299.646)"
        />
        <path
          id="Path_75616"
          data-name="Path 75616"
          className="cls-4"
          d="M633.946 90.814H625.9V79.943a1.553 1.553 0 00-3.106 0v13.723h11.152a1.426 1.426 0 000-2.852z"
        />
      </g>
    </g>
  </svg>);
}

export default Empty;