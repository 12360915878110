import React from 'react';
import { Popover } from 'antd';
import { KoTitle} from '../index';
function KoPopOver(props) {
  const { content, open, title, placement,  className} = props;
  return (<Popover
    open={open}
    className={className}
    title={title ? <KoTitle text={props.title} level={3}/> : false}
    content={content}
    {...props}
    placement={placement}>
    {props.children}
  </Popover>
  );
}


export default KoPopOver;