import { KoBadge, KoButton, KoCard, KoCharts, KoCol, KoCollapse,
  KoDateFormatter, KoDP, KoEmpty, KoForm, KoIcon, KoList, KoLoader, KoModal, KoRow, KoTable, KoTabs, KoText, KoTitle, KoToast, KoTooltips, KoUserSelect } from "packages";
import { useEffect, useState } from 'react';
import _deepClone from 'lodash/cloneDeep';
import { useSelector } from 'react-redux';
import { BILLABLE_HOURS, KoTicketTable_COLUMNS, KoTicketTable_COLUMNSNONTicket, WORKING_HOURS } from './constant';
import { WORKLOG_COLUMNS } from "pages/taskPlanner/task/constant";
import { ProfileAPI } from '../profile.service';
import styles from "./timesheet.module.scss";
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { add, remove } from 'shared/store/breadcrumbSlice';
import { endOfWeek, startOfWeek } from "date-fns";
import WeeklyTabs from "packages/tabs/weeklyTabs";
import TimeSheetCurd from "./crud";
import { useNavigate, useParams } from "react-router";


const Timesheet = ({ showCreate=true, showWeeks=true }) => {
  const dispatch = useDispatch();
  const {tabId} = useParams();
  const [isOpen, setModal] = useState(false);
  const users = useSelector(state => state?.users);
  const [openSubmitModal, setSubmitModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [dataList, setDataList] = useState();
  const [sheetCount, setSheetCount] =  useState();
  const [currentRecord, setCurrentRecord] = useState();
  const [isOpenDelete, setDeleteModal] = useState(false);
  const [isOpenWorkLog, setWorkLogModal] = useState(false);
  const [billableList, setBillableList] = useState();
  const [activeKey, setActiveKey] = useState(tabId ? tabId :'draft');
  const [chartData, setChartData] = useState();
  const [selectedApprover, setSelectedApprover] = useState();
  const [taskCount, setTaskCount] = useState();
  const [weekData, setWeekData] = useState();
  const [workspaceDetails, setWorkspaceDetails] = useState([]);
  const [totalHoursData, setTotalHoursData] = useState();
  const [workLogHistory, setWorkLogHistory] = useState();
  const [selectedWorkspace, setSelectedWorkspace] = useState();
  const [form] = KoForm.useForm();
  const workspace = useSelector(state => state?.workspace);
  const [dateObj, setDateObj] = useState();

 
  const navigate = useNavigate();

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const today = new Date();
  const startDate = startOfWeek(today, { weekStartsOn: 1 });
  const endDate = endOfWeek(today, { weekStartsOn: 1 });

  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);


  useEffect(() => {
    console.log(workspaceDetails);
    dispatch(remove());
    dispatch(add([
      {
        title: 'Home',
        path: 'home/timesheet/darft'
      },
      {
        title: 'Time Sheet '
      }
    ]));

    getTimesheet(formattedStartDate, formattedEndDate);
    getTaskAssignedCount();
    getBillableNonBillableTime(formattedStartDate, formattedEndDate);
    getCurrentWeekTotalWorkTime(formattedStartDate, formattedEndDate);
    GetTotalHours();
    getAllWorkLogsTime(formattedStartDate, formattedEndDate);
   
  }, []);



  useEffect(() => {
    const WorkspaceDetails = async() => {
      if (Array.isArray(dataList)) {
        for (const entry of dataList) {
          if (entry?.project) {
            await getWorkspaceById(entry.project);
          }
        }
      }
    };

    WorkspaceDetails();
  }, [billableList, dataList]);

  const GetTotalHours = () => {
    setLoading(true);
    ProfileAPI.getTotalHours().then(
      (data) => {
        setLoading(false);
        setTotalHoursData(data);
      },
      (err) => {
        setLoading(false);
      }
    );
  };



  const getTimesheet = (startDate, endDate) => {
    setLoading(true);
    setDateObj({
      startDate: startDate,
      endDate: endDate
    });
    getAllWorkLogsTime(startDate, endDate);
  };

  const getCurrentWeekTotalWorkTime = (startDate, endDate) => {
    setLoading(true);
    // setDataList();
    const query = {
      startDate: startDate,
      endDate: endDate
    };

    ProfileAPI.getCurrentWeekTotalWorkTime(query).then(
      (data) => {
        setWeekData(data['totalTime']);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );

  };

  const getAllWorkLogsTime = (startDate, endDate) => {
    setLoading(true);
    // setDataList();
    const query = {
      startDate: startDate,
      endDate: endDate
    };

    ProfileAPI.getAllWorkLogsTime(query).then(
      (data) => {
       
        let id='all';
        if(tabId ==='draft'){
          id=0;
        }else  if(tabId ==='inprogress'){
          id=1;
        }else  if(tabId ==='approved'){
          id=2;
        }else  if(tabId ==='rejected'){
          id=3;
        }
        let newData = _deepClone(data['workLogs']);
        const filteredData = filterData(newData, id);
        setDataList(filteredData);
        setLoading(false);
        setSheetCount(data);
      },
      (err) => {
        setLoading(false);
      }
    );

  };




  function timeStringToHours(timeStr) {
    const [hours, minutes] = timeStr.split(':').map(Number);
    return hours + minutes / 60;
  }

  const getBillableNonBillableTime = (startDate, endDate) => {
    setLoading(true);
    setChartData();

    const query = {
      startDate: startDate,
      endDate: endDate
    };

    ProfileAPI.getBillableNonBillableTime(query).then(
      (data) => {
        setBillableList(data);
        const BillableData = data?.workLogs?.map(entry => timeStringToHours(entry.billableHours));
        const nonBillableData = data?.workLogs?.map(entry => timeStringToHours(entry.nonBillableHours));

        const workLogDatesArray = data?.workLogs.map(entry => {
          const date = new Date(entry.date);
          const day = date.getDate().toString().padStart(2, '0');
          const month = date.toLocaleString('en-US', { month: 'short' });
          return `${day}-${month}`;
        });


        const series = [
          {
            name: 'Billable Time',
            type: 'bar',
            stack: 'total',
            emphasis: {
              disabled: true
            },
            data: BillableData
          },
          {
            name: 'Non billable Time',
            type: 'bar',
            stack: 'total',
            emphasis: {
              disabled: true
            },
            data: nonBillableData
          }
        ];


        var stackInfo = {};
        for (let i = 0; i < series[0].data?.length; ++i) {
          for (let j = 0; j < series.length; ++j) {
            const stackName = series[j].stack;
            if (!stackName) {
              continue;
            }
            if (!stackInfo[stackName]) {
              stackInfo[stackName] = {
                stackStart: [],
                stackEnd: []
              };
            }
            const info = stackInfo[stackName];
            const data = series[j].data[i];
            if (data && data !== '-') {
              if (info.stackStart[i] == null) {
                info.stackStart[i] = j;
              }
              info.stackEnd[i] = j;
            }
          }
        }

        for (let i = 0; i < series.length; ++i) {
          const data = series[i].data;
          const info = stackInfo[series[i].stack];
          for (let j = 0; j < series[i].data?.length; ++j) {
            const isEnd = info.stackEnd[j] === i;
            const topBorder = isEnd ? 8 : 0;
            const bottomBorder = 0;
            data[j] = {
              value: data[j],
              itemStyle: {
                borderRadius: [topBorder, topBorder, bottomBorder, bottomBorder]
              }
            };
          }
        }

        let obj = BILLABLE_HOURS;
        obj['xAxis']['data'] = workLogDatesArray;
        obj['series'] = series;
        setChartData(obj);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const getTaskAssignedCount = () => {
    setLoading(true);
    ProfileAPI.getTaskAssignedCount().then(
      (data) => {
        setTaskCount(data?.taskCount);
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const handleFinish = (values) => {
    setLoading(true);

    ProfileAPI.createOrUpdateTimesheet(values, currentRecord?.workLogId).then(
      (data) => {
        setLoading(false);
        getTimesheet(formattedStartDate, formattedEndDate);
        getBillableNonBillableTime(formattedStartDate, formattedEndDate);
        getTaskAssignedCount();
        getCurrentWeekTotalWorkTime(formattedStartDate, formattedEndDate);
        setModal(false);
        KoToast.success(`Timesheet ${currentRecord?.workLogId ? 'updated' : 'created'} successfully`);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const handleFinishDelete = () => {
    setLoading(true);
    ProfileAPI.deleteTimesheetById(currentRecord?.workLogId).then(
      (data) => {
        getTimesheet(formattedStartDate, formattedEndDate);
        setLoading(false);
        setDeleteModal(false);
        KoToast.success(`Timesheet deleted successfully!`);
      },
      (err) => {
        setLoading(false);
        KoToast.error(`error`);
      }
    );
  };

  const getWorkspaceById = (id) => {
    ProfileAPI.getWorkspaceById(id).then(
      (data) => {

        setWorkspaceDetails(prevState => {
          const existingWorkspace = _.find(prevState, { id });

          if (existingWorkspace) {
            return _.map(prevState, ws =>
              ws?.id === id ? { ...ws, ...data } : ws
            );
          } else {
            return _.concat(prevState, { id, ...data });
          }
        });

      },
      (err) => {
        return null;
      }
    );

  };

  const formatTime = (time) => {
    if (!time || typeof time !== "string") return "-";

    const [hours, minutes] = time.split(":").map(Number);
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${formattedHours}h ${formattedMinutes}m`;
  };

  const panelStyle = {
    marginBottom: 15,
    background: 'var(--layout)',
    border:'1px solid var(--border)',
    borderRadius: 4
  };

  const onExtraClick = (record) => {
    setWorkLogModal(true);
    setWorkLogHistory(record?.logs || []);
  };


  const cancelTimeSheet = (item) => {
    setLoading(true);
    let data={
      status: 0,
      workspace: item?.workspace
    };
    console.log('data', data);
    ProfileAPI.cancelTimesheet(data, dateObj).then(
      (data) => {
        getTimesheet(formattedStartDate, formattedEndDate);
        setLoading(false);
        KoToast.success(`Timesheet cancelled successfully!`);
      },
      (err) => {
        setLoading(false);
        KoToast.error(`error`);
      }
    );
  };



  const columnsRight = [{
    title: '',
    dataIndex: 'x',
    key: 'x',
    fixed: 'right',
    width: 40,
    render: (text, row) => {
      return <div className={`${styles.circle} tableBtn columnsRight`} onClick={() => setCurrentRecord(row)}>
        <KoTooltips title={'Edit'}>
          <KoButton className={'btn'} iconBtn={true} icon={<KoIcon width={14} height={14} name={'EditOutlined'} />} onClick={() => setModal(true)} />
        </KoTooltips>
        <KoTooltips title={'Delete'}>
          <KoButton className={'btn'} iconBtn={true} icon={<KoIcon width={14} height={14} name={'DeleteOutlined'} />} onClick={() => setDeleteModal(true)} />
        </KoTooltips>
      </div>;
    }
  }];

  const getItems = () => {
    let items = dataList?.map((item) => {
      return  {
        key: item?.workspace,
        label: <div className={styles.panelHeader}>
          <div className={styles.panelHeaderWrapper}>
            <div className={`${styles.panelHeaderLeft} profileChar`}>
              <div className={`${styles.circleDP} ${item?.name?.charAt(0) ? item?.name?.charAt(0).toLowerCase() :'a'}`}>
                {item['logo'] ?
                  <KoDP logo={item['logo']} className={styles.profile} width={20} height={20} />
                  :
                  <KoText className={`${styles.letter}`} text={item?.name?.charAt(0)}/>}
              </div>
              <div>
                <KoTitle text={item?.name} level={5} className={styles.panelHeaderTitle} />
                <div className={styles.panelHeaderTextWrapper}>
                  <KoText text={`Total Billable Work Hours: `} className={styles.panelHeaderText} />
                  <KoText text={`${item?.totalBillableWorkHours} `} className={styles.panelHeaderTextBold} />
                  <KoText text={`Total Non-Billable Work Hours: `} className={styles.panelHeaderText} />
                  <KoText text={`${item?.totalNonBillableWorkHours} `} className={styles.panelHeaderTextBold} />
                </div>
                {item?.comments && item?.status === 3 && <div className={styles.panelHeaderTextWrapper}>
                  <KoText text={`Rejected Comments: ${item?.comments}`} className={styles.panelHeaderTextDanger} />
                </div>}
              </div>
            </div>
            <div>
              {item?.status === 0 && <div className={`${styles.panelHeaderCenter} ${styles.draft}`}>
                <KoIcon name="DotFilled" width={20} height={20} className={styles.panelHeaderIcon} />
                <KoText text="Not Submitted" className={styles.panelHeaderText} />
              </div>}
           
              {item?.status === 1 && <div className={`${styles.panelHeaderCenter} ${styles.waitingForApproval}`}>
                <KoIcon name="TickFilled" color={item?.status === 1 ? 'var(--inprogress)' : ''} width={20} height={20} className={styles.panelHeaderIcon} />
                <KoText text="Waiting for approval" className={styles.panelHeaderText} />
              </div>}
              {item?.status === 2 && <div className={`${styles.panelHeaderCenter} ${styles.approved}`}>
                <KoIcon name="TickFilled" width={20} height={20} className={styles.panelHeaderIcon} />
                <KoText text="Approved" className={styles.panelHeaderText} />
              </div>}
              {item?.status === 3 && <div className={`${styles.panelHeaderCenter} ${styles.rejected}`}>
                <KoIcon name="CrossFilled" width={20} height={20} className={styles.panelHeaderIcon} />
                <KoText text="Rejected" className={styles.panelHeaderText} />
              </div>}
             
              <div className={styles.customerSection}>
                <KoText text={`${item?.customer}`} className={styles.panelHeaderCenterText} />
              </div>
            </div>
          </div>
          <div className={styles.panelFooter}>
            {item?.approvelRequestedDate &&  <div className={` ${styles.tag} ${styles.panelHeaderTextIcon}`}>
              <KoIcon name="CalendarOutlined" className={styles.panelHeaderIcon} />
              <KoText text={`Requested at:`} className={styles.panelHeaderText} />
              <KoDateFormatter data={item?.approvelRequestedDate} />
            </div>}
            {item?.status === 2 &&
            <div className={` ${styles.tag} ${styles.panelHeaderTextIcon}`}>
              <KoIcon name="CalendarOutlined" className={styles.panelHeaderIcon} />
              <KoText text={`Approved at: `} className={styles.panelHeaderText} />
              <KoDateFormatter data={item?.approvedDate} />
            </div>}
            {item?.status === 3 &&
            <div className={` ${styles.tag} ${styles.panelHeaderTextIcon}`}>
              <KoIcon name="CalendarOutlined" className={styles.panelHeaderIcon} />
              <KoText text={`Rejected at: `} className={styles.panelHeaderText} />
              <KoDateFormatter data={item?.rejectedDate} />
            </div>}
            {item?.approverProfile?.firstName && <div className={` ${styles.tag} ${styles.panelHeaderTextIcon} profileChar`}>
              <KoText text="Approver: " className={styles.panelHeaderText} />
              <div className={`${styles.circleDPsmall}  ${item?.approverProfile?.firstName?.charAt(0) ? item?.approverProfile?.firstName?.charAt(0).toLowerCase() :'a'}`}>
                {item?.approverProfile?.logo ?
                  <KoDP logo={item?.approverProfile['logo']} className={styles.profile} width={20} height={20} />
                  :
                  <KoText className={`${styles.letter}`} text={item?.approverProfile?.firstName?.charAt(0)}/>}
              </div>
              <KoText text={`${item?.approverProfile?.firstName ? item?.approverProfile?.firstName : ''} ${item?.approverProfile?.lastName ? item?.approverProfile?.lastName : ''}`}className={styles.panelHeaderText} />
            </div>}
          </div>
        </div>,
        children:  <div className={`${ styles.tablePart} tableCard`}>
          <div className={styles.tableHeader}>
            <KoTitle text='Worklogs Associated with Tickets' level={5} />
            {showCreate&& <div className={styles.tableHeaderBtns}>
              {(item?.status === 0 || item?.status === 3 || item?.status === 2) &&  <KoButton type='default' label='Submit Timesheet' onClick={() => {setSubmitModal(true); setSelectedWorkspace(item);}} />}
              {item?.status === 1 &&  <KoButton type='default' label='Cancel Timesheet' onClick={() => {setCurrentRecord(item); cancelTimeSheet(item);}} />}
              {(item?.status === 0 || item?.status === 3 || item?.status === 2) &&   <KoButton type='primary' label='Log Work'
                icon={<KoIcon color="#FFF" name="PlusOutlined" width={18} height={18} />} onClick={()=>{form.resetFields(); setModal(true); setCurrentRecord(item);}} />}
            </div>}
          </div>
          <KoTable
            rowKey="workspace"
            onExtraClick={onExtraClick}
            data={{ columns: KoTicketTable_COLUMNS, rows: item.workLogs }}
          />
          <div className={styles.tableHeaderSub}>
            <KoTitle text='Worklogs Not Associated with Tickets' level={5} />
          </div>
          <KoTable
            rowKey="workspace"
            onExtraClick={onExtraClick}
            data={{ columns: KoTicketTable_COLUMNSNONTicket  , rows: item.nonTicketLogs?.logs }}
          />
        </div>,
        style:panelStyle
        // showArrow: false
      };
    });
    return items;
  };


  const sendApprovalRequest = () => {
    setLoading(true);
    let data={
      status: 1,
      approver: selectedApprover,
      workspace: selectedWorkspace?.workspace
    };
    ProfileAPI.sendApprovalRequest(dateObj, data).then(
      (data) => {
        setLoading(false);
        KoToast.success(`Request sent successfully!`);
        getTimesheet(dateObj?.startDate, dateObj?.endDate);
        setSubmitModal(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  function filterData(data, tabId) {
    if (tabId === 'all') {
      return data;
    }
    return data.filter(item => item.status === tabId);
  }

  const onChangeTabs =(key) =>{
    setActiveKey(key);
    navigate(`/home/timesheet/${key}`);
    let id='all';
    if(key ==='draft'){
      id=0;
    }else  if(key ==='inprogress'){
      id=1;
    }else  if(key ==='approved'){
      id=2;
    }else  if(key ==='rejected'){
      id=3;
    }
    const filteredData = filterData(sheetCount['workLogs'], id);
    setDataList(filteredData);
  };

  return (
    <div className={`${styles.wrapper}`}>
      {isLoading && <KoLoader />}
      <KoRow gutter={16}>
        <KoCol span={24}>
          <div className={styles.projectPart}>
            <KoTitle text='Project you are part off' level={4} />
          </div>
        </KoCol>
        <KoCol span={8}>
          <div className={styles.cardWrapper}>
            <KoCard className={styles.assignedTask}>
              <div className={styles.assignedTaskWrap}>
                <div className={styles.cardIcon}>
                  <KoIcon name="OrgOutlined" color="#B76E00" />
                </div>
                <div className={styles.content}>
                  <KoText className={styles.count} text={taskCount < 10 ? `0${taskCount}` : taskCount ?? '00'} />
                  <KoText className={styles.name} text="Assigned Tasks" />
                </div>
              </div>
            </KoCard>
            <KoCard className={styles.activeProjects}>
              <div className={styles.activeProjectsWrap}>
                <div className={styles.cardIcon}>
                  <KoIcon name="EmailOutlined" />
                </div>
                <div className={styles.content}>
                  <KoText className={styles.count} text={workspace['data']?.length < 10 ? `0${workspace['data']?.length}` : workspace['data']?.length ?? '00'} />
                  <KoText className={styles.name} text="Active Projects" />
                </div>
              </div>
            </KoCard>
            <KoCard className={styles.rejectedTimesheet}>
              <div className={styles.rejectedTimesheetWrap}>
                <div className={styles.cardIcon}>
                  <KoIcon name="CalendarOutlined" color="#B71D18" />
                </div>
                <div className={styles.content}>
                  <KoText className={styles.count} text="03" />
                  <KoText className={styles.name} text="Rejected Timesheets" />
                </div>
              </div>
            </KoCard>
            <KoCard className={styles.workingHours}>
              <div className={styles.workingHoursWrap}>
                <div className={styles.cardIcon}>
                  <KoIcon name="CalendarOutlined" color="#006C9C" />
                </div>
                <div className={styles.content}>
                  <KoText className={styles.count} text={billableList ? formatTime(weekData) : "00h 00m"} />
                  {/* <KoText className={styles.count} text="40h 30m"/> */}
                  <KoText className={styles.name} text="Working hours Current Weak" />
                </div>
              </div>
            </KoCard>
          </div>
        </KoCol>
        <KoCol span={8}>
          <div className={styles.billableHours}>
            <KoCard className={styles.card}>
              <KoTitle text='Billable Hours' level={5} />
              <div className={styles.billableContent}>
                <div className={styles.chart}>
                  {(billableList && chartData) && <KoCharts option={chartData} />}
                </div>
                <div className={styles.chartHolder}>
                  <div className={styles.chartContent}>
                    <div className={styles.avgWorkTime}>
                      <KoText className={styles.text} text="Avg Work Time" />
                      <KoText className={styles.time} text={billableList ? formatTime(billableList?.avgWorkTime) : "00"} />
                    </div>
                    <div className={styles.billableTime}>
                      <div className={styles.billableText}>
                        <div className={styles.billableCircle} />
                        <KoText className={styles.text} text="Billable Time" />
                      </div>
                      <KoText className={styles.time} text={billableList ? formatTime(billableList?.totalBillableTime) : ""} />
                    </div>
                    <div className={styles.nonBillableTime}>
                      <div className={styles.nonBillableContent}>
                        <div className={styles.nonBillableCircle} />
                        <KoText className={styles.text} text="Non Billable Time" />
                      </div>
                      <KoText className={styles.time} text={billableList ? formatTime(billableList?.totalNonBillableTime) : ""} />
                    </div>
                  </div>
                </div>
              </div>
            </KoCard>
          </div>
        </KoCol>
        <KoCol span={8}>
          <div className={styles.projectBreakdown}>
            <KoCard className={styles.card}>
              <div className={styles.title}>
                <KoTitle text='Project Breakdown' level={5} />
                <KoTitle text={formatTime(totalHoursData?.overAllTotalHours)} level={5} />
              </div>
              <div className={styles.projectBar}>
                {
                  totalHoursData?.workLogs?.map((item, index) => (
                    <div className={`${styles[`bar${index + 1}`]}`} key={index} />
                  ))
                }
              </div>
              <div className={styles.projectDesc}>
                <KoList
                  size={'small'}
                  itemLayout="horizontal"
                  dataSource={totalHoursData?.workLogs}
                  renderItem={(item, index) => (
                    <KoList.Item className={styles.descItemWrap} >
                      <div className={styles.projectDesHolder}>
                        <div className={styles.project1}>
                          <div className={styles.projectContent}>
                            <div className={styles[`projectInfo${index + 1}`]} />
                            <KoText className={styles.name} text={item.workspaceName} />
                          </div>
                          <div className={styles.timeInfo}>
                            <KoText className={styles.time} text={formatTime(item.totalHours)} />
                            <div className={styles.projectChart}>
                              <KoCharts option={WORKING_HOURS} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </KoList.Item>
                  )}
                />
              </div>
            </KoCard>
          </div>
        </KoCol>
      </KoRow>
      <KoRow>
        <KoCol span={24}>
          <div className={`${styles.sheetTabs} profileSection`}>
            <KoTabs activeKey={activeKey} onChange={onChangeTabs} options={[{
              label: <KoBadge count={sheetCount?.draftCount} color={`var(--palette-error-main)`} offset={[12, 0]} >
                <KoText text={`Not Submitted`}/>
              </KoBadge>,
              children:  <div className={`${ styles.tablePart}`}>
                {showWeeks && <WeeklyTabs getTimesheet={getTimesheet} isShowWeeks={false} />}
                <div className={`tableCard ${styles.timesheetContnet}`}>
                  {dataList?.length > 0 ?  <KoCollapse
                    className={styles.collapse}
                    bordered={false}
                    items={getItems(panelStyle)}
                  /> : <KoEmpty title="No data available." />}
                </div>
              </div>,
              key: 'draft'
            },
            {label: <KoBadge count={sheetCount?.workLogs?.length} color={`var(--palette-error-main)`} offset={[12, 0]} >
              <KoText text={`All Timesheets`}/>
            </KoBadge>,
            children:  <div className={`${ styles.tablePart} tableCard`}>
              {dataList?.length > 0 ? <KoCollapse
                className={styles.collapse}
                bordered={false}
                items={getItems(panelStyle)}
              /> : <KoEmpty title="No data available." />}
            </div>,
            key: 'all'
            },{
              label: <KoBadge count={sheetCount?.waitingCount} color={`var(--palette-error-main)`} offset={[12, 0]} >
                <KoText text={`Waiting for apprvals`}/>
              </KoBadge>,
              children:  <div className={`${ styles.tablePart} tableCard`}>
                {dataList?.length > 0 ? <KoCollapse
                  className={styles.collapse}
                  bordered={false}
                  items={getItems(panelStyle)}
                /> : <KoEmpty title="No data available." />}
              </div>,
              key: 'inprogress'
            },{
              label: <KoBadge count={sheetCount?.approvedCount} color={`var(--palette-error-main)`} offset={[12, 0]} >
                <KoText text={`Approved Timesheets`}/>
              </KoBadge>,
              children:  <div className={`${ styles.tablePart} tableCard`}>
                {dataList?.length > 0 ? <KoCollapse
                  className={styles.collapse}
                  bordered={false}
                  items={getItems(panelStyle)}
                /> : <KoEmpty title="No data available." />}
              </div>,
              key: 'approved'
            }, {
              label: <KoBadge count={sheetCount?.rejectedCount} color={`var(--palette-error-main)`} offset={[12, 0]} >
                <KoText text={`Rejected Timesheets`}/>
              </KoBadge>,
              children:  <div className={`${ styles.tablePart} tableCard`}>
                {dataList?.length > 0 ? <KoCollapse
                  className={styles.collapse}
                  bordered={false}
                  items={getItems(panelStyle)}
                /> : <KoEmpty title="No data available." />}
              </div>,
              key: 'rejected'
            }] }/>
         
          </div>
        </KoCol>
      </KoRow>
     
      <KoModal
        title={`${currentRecord ? 'Update' : 'Add new'} Time sheet`}
        centered
        open={isOpen}
        width={780}
        onClose={() => setModal(!isOpen)}
        // maskClosable={false}
        height={650}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setModal(!isOpen)} />,
          <KoButton key='add_entry' loading={isLoading} type='primary' label={'Add Entry'}
            onClick={() => form.submit()} />
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <TimeSheetCurd initialValues={currentRecord} form={form} handleFinish={handleFinish} setModal={setModal} workspace={workspace} />
      </KoModal>
      <KoModal
        title={`Send for approval`}
        centered
        open={openSubmitModal}
        width={600}
        onClose={() => setSubmitModal(!openSubmitModal)}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setSubmitModal(!openSubmitModal)} />,
          <KoButton key='RequestApproval' loading={isLoading} type='primary' label={'Request Approval'}
            onClick={() => sendApprovalRequest()} />
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <div className={styles.projectManagers}>
          <KoText level={5} text='Request approval from' />
          <KoUserSelect onChange={v => setSelectedApprover(v)} users={users['data']} placeholder='Select Reporting Manager' />
          <KoText text='to approve this timesheet entry' />
        </div>
      </KoModal>
      <KoModal
        title={`Delete user ${currentRecord?.profile?.firstName?.toUpperCase()}`}
        centered
        open={isOpenDelete}
        afterClose={() => { setCurrentRecord(); }}
        handleOk={() => { setCurrentRecord(); setDeleteModal(false); }}
        onClose={() => { setCurrentRecord(); setDeleteModal(false); }}
        width={580}
        // maskClosable={false}
        height={450}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setDeleteModal(!isOpenDelete)} />,
          <KoButton key='reset' loading={isLoading} type='primary' label='Delete' onClick={() => handleFinishDelete()} />
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <div className={styles.suspend}>
          <KoTitle level={5} text={`Are you sure you want to delete this user?`} />
          <KoText text={`This action is irreversible, and it will result in the removal of their associated data and Kwapio license.`} />
        </div>
      </KoModal>

      <KoModal
        title={`Worklog History`}
        centered
        open={isOpenWorkLog}
        afterClose={() => { setCurrentRecord(); }}
        handleOk={() => { setCurrentRecord(); setWorkLogModal(false); }}
        onClose={() => { setCurrentRecord(); setWorkLogModal(false); }}
        width={850}
        // maskClosable={false}
        footer={[
          <KoButton key='cancel' label='Close' onClick={() => setWorkLogModal(false)} />
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <div className={styles.suspend}>
          <KoTable   extraColumnsRight={showCreate && columnsRight} data={ {columns : WORKLOG_COLUMNS, rows: workLogHistory} } />
        </div>
      </KoModal>
     
    </div>
  );
};

export default Timesheet;
