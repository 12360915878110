// import React from "react";
// import styles from './ticketLink.module.scss';
// import { Link } from "react-router-dom";
// import { KoText } from "packages/typography";

// function PullRequestLink(props) {
//   const { data } = props;
//   return (
//     <div className={styles.wrapper}>
//       <Link target="_blank" to={`${data}`}>
//         <KoText className={styles.text} text={data}/>
//       </Link>
     
//     </div>
//   );
// }
// export default PullRequestLink;


import React from "react";
import { KoText } from "../../../typography";

function PullRequestLink(props) {
  return (
    <div >
      {props?.hideCopy ? <KoText text={props.data} /> :
        <KoText text={props.data} copyable={{
          tooltips: true
        }} />}
    </div>
  );
}
export default PullRequestLink;
