import jsPDF from 'jspdf';
import _find from 'lodash/find';
import { KoAffix, KoButton, KoForm, KoIcon, KoInvoiceView, KoLoader, KoModal, KoNano, KoTitle, KoToast } from "packages";
import Driver from "packages/drivers";
import { useEffect, useRef, useState } from "react";
import { useSelector } from 'react-redux';
import { useLocation, useParams } from "react-router";
import { useReactToPrint } from 'react-to-print';
import KoToolBar from "shared/components/toolbar";
import { InvoicesAPI } from "../invoice.service";
import ApprovalsCurd from './approvalsCurd';
import InvoiceCrud from './curd';
import styles from './viewOrEdit.module.scss';

function PreviewInvoice(props){
  const workspace = useSelector(state => state?.workspace);
  const componentRef = useRef();
  const [isPreview, setPreview] = useState(false);
  const [currentRecord, setCurrentRecord] = useState();
  const [isOpenApproval, setApprovalModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const location = useLocation();
  const path = location?.pathname;
  const pathParts = path.split("/");
  const isUpload = pathParts.find(part => part === "upload");
  const {folderId ,id, workspaceId} = useParams();
  const fileType = currentRecord?.invoiceFile?.type.split('/')[1];
  const [form] = KoForm.useForm();

  useEffect(()=>{
    getInvoiceById();
  },[]);
  
  
  const getInvoiceById = () => {
    if(isUpload){
      InvoicesAPI.getUploadInvoiceById(folderId, id, workspaceId).then(
        (data) => {
          setCurrentRecord(data?.invoice);
        },
        (err) => {
        }
      );
    }
    else{
      InvoicesAPI.getInvoiceById(folderId, id, workspaceId).then(
        (data) => {
          setCurrentRecord(data?.invoice);
          // dispatch(getWorkspaceById(currentRecord?.project));
        },
        (err) => {
        }
      );
    }
  };
  const WorkspaceDetails = _find(workspace['data'], function(o) {
    return o.workspaceId === currentRecord?.project; });

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const handleSaveAsPDF = async() => {
    const pdf = new jsPDF('p', 'mm', 'a4');
    const content = componentRef.current;

    pdf.html(content, {
      callback: function(doc) {
        doc.save('invoice.pdf');
      },
      x: 10,
      y: 10,
      width: 190,
      windowWidth: content.scrollWidth
    });
  };

  const handleInvoiceFinish = (values => {
    setLoading(true);
    if(!currentRecord?.invoiceId){
      values['invoiceId'] = KoNano('INV');
    }

    InvoicesAPI.createOrUpdateInvoices(values, currentRecord?.invoiceId, folderId,workspaceId).then(
      (data) => {
        setPreview(false);
        setLoading(false);
        KoToast.success(`Invoice ${currentRecord?.invoiceId ? 'updated' : 'created' } successfully`);
      },
      (err) => {
        setLoading(false);
        KoToast.error(err?.error);
      }
    );
  });

  return(
    <div className={styles.wrapper}>
      {/* <KoCard
        hoverable={false}
        className={styles.tabs}
        padding={{ top: 0, bottom: 2, right: 2, left: 2 }}
      > */}
      <KoAffix top={56}>
        <KoToolBar
          className={styles.toolbar}
          title={currentRecord?.name}
          search={{
            visible: false
          }}
          buttonList={[
            {
              label: `${isPreview ? "Preview" : "Edit"}`,
              type: "primary",
              icon: (
                <KoIcon
                  color="#FFF"
                  name= {`${isPreview ? "PreviewFilled" : "EditFilled"}`}
                />
              ),
              onClick: () => setPreview(!isPreview)
            },
            {
              label: "Share",
              type: "primary",
              icon: (
                <KoIcon
                  color="#FFF"
                  name="ShareFilled"
                />
              )
                // onClick: ()
            },
            {
              label: "Print",
              type: "primary",
              icon: (
                <KoIcon
                  color="#FFF"
                  name="PrintFilled"
                />
              ),
              onClick: () => handlePrint()
            },
            {
              label: "Save as PDF",
              type: "primary",
              icon: (
                <KoIcon
                  color="#FFF"
                  name="PdfFilled"
                />
              ),
              onClick: () => handleSaveAsPDF()
            }
              
          ]}
        />
      </KoAffix>
      {currentRecord && currentRecord?.invoiceFile ? <Driver action={false} currentRecord={currentRecord} fileType={fileType}/> :
        <KoInvoiceView ref={componentRef} currentRecord={currentRecord} WorkspaceDetails={WorkspaceDetails}/> }
      {/* </KoCard> */}



      <KoModal
        title={<div className={styles.titleActions}>
          <KoTitle text={currentRecord?.invoiceId ? `Update Invoice ${currentRecord?.name?.toUpperCase()}` : 'New Invoice'} level={5}/>
          <div className={styles.actionsBtn}>
            <KoButton onClick={()=>setApprovalModal(true)} type={'primary'} label='Approval'/>
          </div>
        </div>}
        centered
        open={isPreview}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setPreview(false); }}
        onClose={() => { setCurrentRecord(); setPreview(false); }}
        width={'100%'}
        // maskClosable={false}
        height={'95.5%'}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setPreview(!isPreview)} />,
          <KoButton key='create' loading={isLoading} type='primary' label={`${currentRecord?.invoiceId ? 'Update' : 'Create'}`} onClick={() => form.submit()} />
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <InvoiceCrud form={form} initialValues={currentRecord} handleFinish={handleInvoiceFinish}/>
      </KoModal>

      <KoModal
        title={`Approval Request`}
        centered
        open={isOpenApproval}
        afterClose={() => {setCurrentRecord();}}
        handleOk={() => { setCurrentRecord(); setApprovalModal(false); }}
        onClose={() => { setCurrentRecord(); setApprovalModal(false); }}
        width={580}
        // maskClosable={false}
        height={450}
        footer={[
          <KoButton key='cancel' label='Cancel' onClick={() => setApprovalModal(!isOpenApproval)} />,
          <KoButton key='reset' loading={isLoading} type='primary' label='send'/>
        ]}
      >
        {isLoading && <KoLoader circle={false} />}
        <ApprovalsCurd form={form} />
      </KoModal>


    </div>
  );
}

export default PreviewInvoice;