"use client";
import React from 'react';
import { KoFormBuilder, KoSelect } from 'packages';
import { LANGUAGES, DATEFORMATS, NUMBERFORMT,
  CURRENCY,
  CURRENCYPOSITION, TIMEFORMAT, MONTHS } from './constant';
import styles from './curd.module.scss';

function PreferencesCrud({ form, handleFinish, settings }) {

  const numberFormat = ({ value, onChange }) =>

    <KoSelect value={value}
      showSearch
      placeholder='Select number format'
      onChange={v => onChange(v)}
      options={NUMBERFORMT} />;

  const currencyFormat = ({ value, onChange }) =>

    <KoSelect value={value}
      showSearch
      placeholder='Select currency format'
      onChange={v => onChange(v)}
      options={CURRENCY} />;

  const currencyPosition = ({ value, onChange }) =>

    <KoSelect value={value}
      showSearch
      placeholder='Select currency position'
      onChange={v => onChange(v)}
      options={CURRENCYPOSITION} />;

  const months = ({ value, onChange }) =>

    <KoSelect value={value}
      showSearch
      placeholder='Select number format'
      onChange={v => onChange(v)}
      options={MONTHS} />;

  const timeFormat = ({ value, onChange }) =>
    <KoSelect value={value}
      showSearch
      placeholder='Select time format'
      onChange={v => onChange(v)}
      options={TIMEFORMAT} />;


  const dateFormat = ({ value, onChange }) =>
    <KoSelect value={value}
      showSearch
      placeholder='Select date Format'
      onChange={v => onChange(v)}
      options={DATEFORMATS} />;

  const Languages = ({ value, onChange }) =>

    <KoSelect value={value}
      showSearch
      placeholder='Select Language'
      onChange={v => onChange(v)}
      options={LANGUAGES} />;

  const meta = {
    columns: 4,
    formItemLayout: null,
    initialValues: settings,
    colon: true,
    fields: [
      {
        key: 'preferences.defaultLanguage', label: 'Default Language',
        widget: Languages,
        forwardRef: true,
        colSpan: 2, rules: [
          {
            required: true,
            message: 'Default Language is required'
          }
        ]
      },
      {
        key: 'preferences.primaryCurrency', label: 'Primary Currency',
        widget: currencyFormat,
        forwardRef: true,
        colSpan: 2, rules: [
          {
            required: true,
            message: 'Primary Currency is required'
          }
        ]
      },
      {
        key: 'preferences.currencyPosition', label: 'Currency Position',
        widget: currencyPosition,
        forwardRef: true,
        colSpan: 2, rules: [
          {
            required: true,
            message: 'Primary currency position is required'
          }
        ]
      },
      {
        key: 'preferences.numberFormat', label: 'Number Format',
        widget: numberFormat,
        forwardRef: true,
        colSpan: 2, rules: [
          {
            required: true,
            message: 'Primary Number is required'
          }
        ]
      },
      {
        key: 'preferences.dateFormat', label: 'Date Format', widget: dateFormat,
        forwardRef: true,
        colSpan: 2, rules: [
          {
            required: true,
            message: 'Date Format is required'
          }
        ]
      },
      {
        key: 'preferences.timeFormat', label: 'Time Format',
        widget: timeFormat,
        forwardRef: true,
        colSpan: 2, rules: [
          {
            required: true,
            message: 'Time Format is required'
          }
        ]
      },
      {
        key: 'preferences.financialYearBegins', label: 'Financial Year Begins',
        widget: months,
        placeholder: 'Financial Year Begins', colSpan: 2, rules: [
          {
            required: true,
            message: 'Financial Year Begins is required'
          }
        ]
      },
      {
        key: 'preferences.invoiceNoPrefix', label: 'Invoice No. Prefix', placeholder: 'Invoice No. Prefix', colSpan: 2
      }
    ]
  };

  return (
    <div className={styles.wrapper}>
      <KoFormBuilder form={form} meta={meta} />
    </div>
  );
};

export default PreferencesCrud;