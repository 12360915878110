import React from "react";
function Task(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 25 24" fill="none">
      <rect x="0.5" width="24" height="24" rx="4" fill="var(--task)" />
      <path d="M15.4167 18.6582V14.9167H19.1582L15.4167 18.6582ZM14.25 19H5.5V6.75C5.5 5.78517 6.28517 5 7.25 5H17.75C18.7148 5 19.5 5.78517 19.5 6.75V13.75H14.25V19ZM7.83333 8.79167C7.83333 9.27467 8.22533 9.66667 8.70833 9.66667C9.19133 9.66667 9.58333 9.27467 9.58333 8.79167C9.58333 8.30867 9.19133 7.91667 8.70833 7.91667C8.22533 7.91667 7.83333 8.30867 7.83333 8.79167ZM9.58333 15.2083C9.58333 14.7253 9.19133 14.3333 8.70833 14.3333C8.22533 14.3333 7.83333 14.7253 7.83333 15.2083C7.83333 15.6913 8.22533 16.0833 8.70833 16.0833C9.19133 16.0833 9.58333 15.6913 9.58333 15.2083ZM9.58333 12C9.58333 11.517 9.19133 11.125 8.70833 11.125C8.22533 11.125 7.83333 11.517 7.83333 12C7.83333 12.483 8.22533 12.875 8.70833 12.875C9.19133 12.875 9.58333 12.483 9.58333 12Z" fill="white" />
    </svg>
  );
}


export default Task;