import React, {useState} from 'react';
import _find from 'lodash/find';
import { useDispatch } from 'react-redux';
import {
  KoText, KoTitle, KoTaskStatus, KoUser, KoDriver,
  KoDateFormatter, KoEditorReadOnly
} from 'packages';
import { ISSUETYPEFORCOB } from 'shared/commonConstants';

import styles from './crud.module.scss';
import { setSelectedChatData, setSelectedChatType } from 'shared/store/chatSlice';
import Comments from 'pages/taskPlanner/task/comments';

export default function ReadonlyCurd(props) {
  const [isOpenCommentsModal, setCreateComment] = useState();
  const dispatch = useDispatch();
  const { issue } = props;
  const updatedRoomObj = { ...issue, source: 'tickets' };
  dispatch(setSelectedChatData(updatedRoomObj));
  dispatch(setSelectedChatType('tickets'));
  const issueType = _find(ISSUETYPEFORCOB, function(o) { return o.value === issue?.issueType; }) || {};

  return (
    <div className={styles.readOnlyWrapper}>
      {issue &&<div className={styles.formData}>
        <div className={styles.summaryRow}>
          <div className={styles.summary}>
            <KoText text={'Summary'} />
            <KoTitle level={3} text={issue?.summary} />
          </div>
        </div>
        <div className={styles.ticketDetails}>
          <div>
            <div className={styles.summaryRow}>
              <div className={styles.summary}>
                <KoText className={styles.label} text={'Type'} />
                <div className={styles.issueType}>
                  {issueType.icon}
                  <KoText text={issueType?.label} />
                </div>
              </div>
              <div className={styles.summary}>
                <KoText className={styles.label} text={'Resolution'} />
                <KoTaskStatus cob={true} data={issue?.status} />
              </div>
            </div>

          </div>
          <div>
            <div className={styles.summaryRow}>
              <div className={styles.summary}>
                <KoText className={styles.label} text={'Created'} />
                <KoDateFormatter data={issue?.createdAt} />
              </div>
              <div className={styles.summary}>
                <KoText className={styles.label} text={'Updated'} />
                <KoDateFormatter data={issue?.updatedAt} />
              </div>
            </div>
          </div>
          <div>
            <div className={styles.summaryRow}>
              <div className={styles.summary}>
                <KoText className={styles.label} text={'Assignee'} />
                { issue?.assigneeName ? <KoUser data={issue?.assigneeName} email={true} /> : <KoText text={'-'} /> }
              </div>
              <div className={styles.summary}>
                <KoText className={styles.label} text={'Submitted By'} />
                {issue?.reporter ? <KoUser data={issue?.reporter} email={true} /> : <KoText text={'-'} />}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.ticketDetails}>
          <div className={styles.summaryRow}>
            <div className={styles.summary}>
              <KoText className={styles.label} text={'Component'} />
              <KoTitle level={5} text={issue?.component ? issue?.component : '-'} />
            </div>
          </div>
          <div className={styles.summaryRow}>
            <div className={styles.summary}>
              <KoText className={styles.label} text={'Module'} />
              <KoTitle level={5} text={issue?.module ? issue?.module : '-'} />
            </div>
          </div>
        </div>
        <KoTitle level={5} className={styles.description} text={'Description'} />
        <div className={styles.summaryRow}>
          <div className={styles.summary}>
            <KoEditorReadOnly text='-' initialValue={issue?.description} />
          </div>
        </div>
        <KoTitle className={styles.description} level={5} text={'Acceptance Criteria'} />
        <div className={styles.summaryRow}>
          <div className={styles.summary}>
            <KoEditorReadOnly text='-' initialValue={issue?.acceptanceCriteria} />
          </div>
        </div>
        <KoTitle level={5} text={'Attachments'} />
        <div className={styles.summaryRow}>
          <div className={styles.summary}>
            <div className={styles.thumbnailContainer}>
              {issue?.attachments?.map((file, index) => (
                <div
                  key={index}
                  className={`${styles.thumbnail}`}>
                  <KoDriver fileType={file?.type} filePath={file?.url} />
                </div>
              ))}
              {!issue?.attachments && <div className={styles.thumbnail}>No Attachments</div>}
            </div>

          </div>
        </div>
      </div>}
      <div className={styles.comments}>
        <Comments ticketId={issue?.ticketId} workspaceId={issue?.workspaceId || issue?.workspace} setCreateComment={setCreateComment} isOpenCommentsModal={isOpenCommentsModal}  />
      </div>
    </div>
  );
}
