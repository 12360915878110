import React from "react";
function Changerequest(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="change-request">
        <g fill="red">
          <g id="Vector">
            <path d="M14.0754 0.0446478C13.78 0.143189 13.474 0.401274 13.3236 0.68282C13.1948 0.926827 13.184 0.997214 13.1679 1.75739C13.1465 2.68649 13.2216 2.58326 12.5129 2.63488C11.9545 2.67711 10.8269 2.8695 10.1504 3.04312C6.38648 4.00507 3.24542 6.38414 1.62388 9.49054C1.08158 10.5323 0.603712 11.9775 0.512433 12.8879C0.485587 13.1506 0.496325 13.2163 0.603712 13.3759C0.823854 13.6997 1.25877 13.817 1.64536 13.6574C1.75812 13.6105 1.97289 13.4181 2.19303 13.1741C3.93806 11.2174 6.55293 9.89409 9.50606 9.48115C10.1933 9.38261 11.9813 9.37792 12.674 9.47177L13.1304 9.53277L13.1572 10.4243C13.1894 11.4191 13.2377 11.5928 13.5921 11.8978C13.9733 12.2309 14.4566 12.3107 15.0204 12.1324C15.3103 12.0385 15.4929 11.8931 17.9252 9.77209C20.5991 7.44463 20.7924 7.25224 20.9642 6.74546C21.0716 6.43106 21.077 5.80697 20.9749 5.51604C20.7763 4.94356 20.728 4.89664 18.0003 2.51287C15.1761 0.0493405 15.16 0.0399554 14.5532 0.00241584C14.3921 -0.00696904 14.1774 0.0118007 14.0754 0.0446478Z"fill="red" />
            <path d="M23.3267 10.3493C23.2408 10.3962 22.9938 10.6214 22.7844 10.8513C22.328 11.3534 21.3347 12.2028 20.7816 12.5688C19.246 13.5824 17.4741 14.2393 15.5197 14.5115C14.8324 14.61 13.0444 14.6147 12.3518 14.5208L11.8954 14.4598L11.8632 13.6152C11.8256 12.5922 11.7558 12.3764 11.3746 12.0573C10.9773 11.7195 10.4403 11.6678 9.87654 11.9071C9.57049 12.0385 9.23759 12.3154 7.00395 14.2674C5.1569 15.877 4.42667 16.548 4.30318 16.7357C3.86289 17.4208 3.84142 18.2419 4.24412 18.9458C4.32466 19.0866 5.18912 19.8749 6.96636 21.4375C9.76378 23.887 9.83895 23.9386 10.4296 23.9949C11.0309 24.0465 11.5571 23.704 11.7612 23.1268C11.8471 22.8828 11.8686 22.6904 11.8686 22.1273C11.8686 21.3249 11.7988 21.4093 12.5129 21.3577C13.0498 21.3202 14.129 21.1325 14.811 20.9636C19.8098 19.7107 23.5898 15.999 24.4328 11.5224C24.5402 10.9405 24.5241 10.734 24.3469 10.5276C24.1267 10.2695 23.6381 10.185 23.3267 10.3493Z"fill="red" />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_76_211">
          <rect x="0.5" width="24" height="24" rx="4" fill="red" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Changerequest;