import React from "react";
function GoToMeeting(props) {
  const { width, height } = props;
  return (
  
    <svg
      width={width} height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M23 20.77L22.49 16 1 18.225 1.51 23 23 20.77z" fill="#FFE900" />
      <path
        d="M20.992 12.355v4.978h-3.01v-3.345s-1.428 3.345-6.683 3.345c-3.52 0-5.484-1.79-6.351-2.924a9.175 9.175 0 01-1.505-3.055 8.968 8.968 0 01-.077-4.426 8.837 8.837 0 013.061-5.005A8.748 8.748 0 018.876.527a8.315 8.315 0 014.387-.395 8.31 8.31 0 014.26 2.107l.408.37L15.79 4.82l-.23-.21a5.577 5.577 0 00-3.16-1.429 5.514 5.514 0 00-3.376.73 5.78 5.78 0 00-2.34 2.618 6.021 6.021 0 00-.435 3.533c.148.856.488 1.664.993 2.359a5.425 5.425 0 001.915 1.645c.919.527 2.424.553 3.47.395 3.469-.553 4.132-3.978 4.132-3.978H11.91V7.376h9.081v4.979z"
        fill="#000"
      />
    </svg>
  );
}


export default GoToMeeting;