import React from "react";
function Pin(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height}>
      <defs>
        <clipPath id="bbmm21">
          <path id="Rectangle_17839" data-name="Rectangle 17839" transform="translate(355 170)"  d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
      <g id="attachments_16px" transform="translate(-355 -170)">
        <g id="pin-diagonal-tool-of-black-shape" transform="translate(356 170.857)">
          <path id="Path_75571" data-name="Path 75571"  d="m.634 642.055 3.872-2.7-.859-.859-.863-.863L.075 641.5a.4.4 0 0 0 .559.555z" transform="translate(0 -628.378)" />
          <path id="Path_75572" data-name="Path 75572"  d="m88.108 6.608 1.959 1.959.991.991.987.987 1.965 1.965a.467.467 0 0 0 .743-.112 27.632 27.632 0 0 1 4.155-6.3l.308.308a.85.85 0 0 0 .054.049.725.725 0 0 0 .458.164.818.818 0 0 0 .084 0 .725.725 0 0 0 .593-.982.716.716 0 0 0-.164-.251l-.284-.286-4.44-4.439-.284-.284a.736.736 0 0 0-.251-.164.726.726 0 0 0-.824 1.136.565.565 0 0 0 .049.054l.308.308a27.661 27.661 0 0 1-6.295 4.153.469.469 0 0 0-.112.744z" transform="translate(-86.695 -.164)" />
        </g>
      </g>
    </svg>
  );
}


export default Pin;