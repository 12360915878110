import styles from './status.module.scss';

function OptionalStatus(props) {
  let { data } = props;
  if (data === true) {
    data = "optional";
  } else if (data === false) {
    data = "--";
  }

  return (
    <div className={styles.wrapper}>
      <div className={`${styles.status} ${styles[data?.replace(" ", '')]}`}>
        <div className={styles.text}>{data}</div>
      </div>
    </div>

  );
}
export default OptionalStatus;
