import {KoAPI} from "packages";
import { configs } from 'configuration';
const version = configs().API_VERSION;
export const workspaceAPI = {

  getWorkspace(){
    return KoAPI.get(`/api/${version}/workspace`);
  },

  getWorkspaceById(id){
    return KoAPI.get(`/api/${version}/workspace/${id}`);
  },

  createOrUpdateClient(data, id) {
    if(id){
      return KoAPI.update(`/api/${version}/workspace/${id}`, { data });
    }else{
      return KoAPI.post(`/api/${version}/workspace`, { data });
    }
  
  },
  deleteById(id){
    return KoAPI.delete(`/api/${version}/workspace/${id}`);
  },
  getClients(){
    return KoAPI.get(`/api/${version}/admin/customer`);
  },
      
  getRecentTicket(query){
    return KoAPI.get(`/api/${version}/taskplanner/ticket/recent`, { query: { ...query } });
  }
};