import * as React from "react";

function EyeOpened(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" {...props}>
      <path
        style={{
          fontFeatureSettings: "normal",
          fontVariantAlternates: "normal",
          fontVariantCaps: "normal",
          fontVariantLigatures: "normal",
          fontVariantNumeric: "normal",
          fontVariantPosition: "normal",
          isolation: "auto",
          mixBlendMode: "normal",
          shapePadding: 0,
          textDecorationColor: "#000",
          textDecorationLine: "none",
          textDecorationStyle: "solid",
          textIndent: 0,
          textOrientation: "mixed",
          textTransform: "none"
        }}
        d="M32 21c-6.057 0-11 4.943-11 11 0 1.61.354 3.198 1.035 4.656a1.5 1.5 0 102.717-1.27A8 8 0 0124 32c0-4.436 3.564-8 8-8s8 3.564 8 8-3.564 8-8 8a7.995 7.995 0 01-3.387-.752 1.5 1.5 0 10-1.271 2.717A11.001 11.001 0 0032 43c6.057 0 11-4.943 11-11s-4.943-11-11-11z"
        color="#000"
        paintOrder="fill markers stroke"
      />
      <path
        style={{
          fontFeatureSettings: "normal",
          fontVariantAlternates: "normal",
          fontVariantCaps: "normal",
          fontVariantLigatures: "normal",
          fontVariantNumeric: "normal",
          fontVariantPosition: "normal",
          isolation: "auto",
          mixBlendMode: "normal",
          shapePadding: 0,
          textDecorationColor: "#000",
          textDecorationLine: "none",
          textDecorationStyle: "solid",
          textIndent: 0,
          textOrientation: "mixed",
          textTransform: "none"
        }}
        d="M32 29c-1.64 0-3 1.36-3 3s1.36 3 3 3 3-1.36 3-3-1.36-3-3-3z"
        color="#000"
        paintOrder="fill markers stroke"
      />
      <path
        style={{
          fontFeatureSettings: "normal",
          fontVariantAlternates: "normal",
          fontVariantCaps: "normal",
          fontVariantLigatures: "normal",
          fontVariantNumeric: "normal",
          fontVariantPosition: "normal",
          isolation: "auto",
          mixBlendMode: "normal",
          shapePadding: 0,
          textDecorationColor: "#000",
          textDecorationLine: "none",
          textDecorationStyle: "solid",
          textIndent: 0,
          textOrientation: "mixed",
          textTransform: "none"
        }}
        d="M32 15C14.088 15 4.22 31.217 4.22 31.217a1.5 1.5 0 000 1.566S14.089 49 32 49s27.78-16.217 27.78-16.217a1.5 1.5 0 000-1.566S49.911 15 32 15zm0 3c14.724 0 23.25 11.9 24.652 14C55.25 34.1 46.724 46 32 46S8.75 34.1 7.348 32C8.75 29.9 17.276 18 32 18z"
        color="#000"
        paintOrder="fill markers stroke"
      />
    </svg>
  );
}

export default EyeOpened;
