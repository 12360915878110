import React from 'react';

import styles from './drivers.module.scss';

const Error = props => (
  <div className={styles.errorMessage}>
    {props.errorComponent
      ? <props.errorComponent {...props} />
      : <p className={styles.alert}>Unable to preview file</p>}
  </div>
);

export default Error;