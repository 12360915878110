import { KoButton, KoDate, KoForm, KoFormBuilder, KoIcon, KoToast, Upload } from 'packages';
import { useCallback } from 'react';
import { getCookie } from 'shared/utils/cookies';
import styles from './crud.module.scss';
import Driver from 'packages/drivers';
import { beforeUpload } from '.';

const PanCardCrud = ({form, handleFinish, initialValues}) => {
    
  const handleSubmit = useCallback(values => {
    const updatedValues = {
      documents: {
        PanDetails: { ...values.documents.PanDetails, file: values.file  }
      }
    };
    handleFinish(updatedValues);
  });

  const Date = ({ value, onChange }) =>
    <KoDate value={value} format = {'DD-MMM-YYYY'}
      onChange={v => onChange(v)} />;

  const optionGroups = [];
  for (let i = 10; i < 36; i++) {
    optionGroups.push({
      label: i.toString(36) + i,
      value: i.toString(36) + i
    });
  }

  const meta = {
    columns: 4,
    formItemLayout: null,
    initialValues,
    colon: true,
    fields: [
      {
        key: 'documents.PanDetails.PanNumber', placeholder: 'Enter PanCard Number', label: 'Enter PanCard Number', colSpan: 2, rules: [
          {
            required: true,
            message: `Please PanCard Number.`
          }
        ]
      },
      {
        key: 'documents.PanDetails.name', placeholder: 'Enter Full Name', label: 'Full Name', colSpan: 2, rules: [
          {
            required: true,
            message: `Please PanCard Number.`
          }
        ]
      },  {
        key: 'documents.PanDetails.dateOfBirth', placeholder: 'Enter Date', label: 'Date of Birth', colSpan: 2,
        widget: Date, rules: [
          {
            required: true,
            message: `Please enter date of birth.`
          }
        ]
      }
    ]
  };
  const props = {
    name: 'files',
    multiple: true,
    action: `${process.env.REACT_APP_PROXY_HOST}/api/v1/org/upload`,
    data: { module: 'documents'},
    headers: {
      Authorization: `Bearer ${getCookie("accessToken")}`
    },

    onChange(info) {
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'done') {
        KoToast.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        KoToast.error(`${info.file.name} file upload failed.`);
      }
    }
  };
  const normFile = (e) => {
    return e?.file;
  };


  const uploadButton = (
    <div className={styles.dndArea}>
      <KoIcon name='AttachmentOutlined' />
      <div className={styles.dndText}>Add image, PDF or file</div>
    </div>

  );
  const onRemoveFile = (d) => {
    console.log();
  };

  return (
    <div className={styles.wrapper}>
      <KoForm form={form} layout="vertical" onFinish={handleSubmit} onSubmit={handleSubmit}>
        <div className={styles.attachments}>
          {
            initialValues?.documents?.PanDetails?.file?.type !== "" &&
          <div className={styles.attachmentsArea}>
            <Driver DeleteFile={false} action={true} fileType={initialValues?.documents?.PanDetails?.file?.type} filePath={initialValues?.documents?.PanDetails?.file.url} handleDelete={() => onRemoveFile(initialValues?.documents?.PanDetails?.file)} />
          </div>
          }

          <KoForm.Item
            name={["file"]}
            valuePropName={["file"]}
            getValueFromEvent={normFile}>

            <Upload {...props}
              className={styles.card}
              onRemove={onRemoveFile}
              beforeUpload={beforeUpload}
              showUploadList={{showPreviewIcon : false}}
              showPreviewIcon={true}
              listType="picture-card"
              multiple={true}
            >
              {uploadButton}
            </Upload>
          </KoForm.Item>
        </div>
        <KoFormBuilder form={form} meta={meta} />
        <div className={styles.buttonWrapper}>
          <KoButton type="primary" label="submit" onClick={() => form.submit()}/>
        </div>
      </KoForm>
    </div>
  );
};

export default PanCardCrud;
