import { getCookie } from 'shared/utils/cookies';
import _set from 'lodash/set';
import { KoForm, KoFormBuilder, KoIcon, KoImage, KoLoader, KoText, KoToast, KoUserSelect, Upload } from 'packages';
import { useCallback, useEffect, useState } from 'react';
import { OrgAPI } from '../org.service';
import styles from './groups.module.scss';

function GroupsCrud({ form, handleFinish, initialValues, getOrgGroups }) {
  const [users, setUsers] = useState();
  const [isLoading, setLoading] = useState(false);
  useEffect(()=>{
    getOrgUsers();
  },[]);

  useEffect(() => {
    if (!initialValues || Object.keys(initialValues).length === 0) {
      form.resetFields();
    } else {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, form]);
  
  const getOrgUsers = () => {
    // setLoading(true);
    OrgAPI.getOrgUsers().then(
      (data) => {
        setUsers(data['users']);
        // setLoading(false);
      },
      (err) => {
        // setLoading(false);
      }
    );
  };


  const onFinish = useCallback(values => {
    handleFinish(values);
  });


  const options = [
    { label: 'Anyone in the organization can ask', value: 'Anyone in the organization can ask' },
    { label: 'Only Invited Users', value: 'Only invited users' },
    { label: 'Anyone in the organization can join', value: 'Anyone in the organization can join' }];


  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const Members = ({ value, onChange }) =>
    <KoUserSelect value={value} onChange={v => onChange(v)} users={users} placeholder='' mode='multiple'/>;



  const meta = {
    columns: 4,
    formItemLayout: null,
    colon: true,
    initialValues,
    fields: [
      {
        key: 'name', label: 'Group Name', colSpan: 4, rules: [
          {
            required: true,
            message: 'Group name is required'
          }
        ]
      },
      {
        key: 'description', label: 'Description', colSpan: 4, widget: 'textarea'
      },
      {
        key: 'members', label: 'Add Members', colSpan: 4,
        widget: Members,
        forwardRef: true
      },
      {
        colSpan: 4,
        label: 'Who can join in this Group',
        key: 'whoCanJoin',
        widget: 'radioGroup',
        direction: 'horizontal',
        options
      }
    ]
  };


  const updateGroup = (values) => {
    OrgAPI.createOrgGroups(values, values?.groupId).then(
      (data) => {
        getOrgGroups();
        setLoading(false);
        KoToast.success(`Group image updated successfully! `) ;
      },
      (err) => {
        setLoading(false);
      }
    );
  };


  const props = {
    name: 'files',
    action: `${process.env.REACT_APP_PROXY_HOST}/api/v1/org/upload/profile/${initialValues?.groupId}`,
    data:{service:'organization', module:'groups'},
    headers: {
      Authorization: `Bearer ${getCookie("accessToken")}`
    },
    onChange(info) {
      setLoading(true);
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'done') {
        const file = info.file;
        file['url'] = file.response[0];
        _set(initialValues, 'logo', [file]);
        form.setFieldsValue(initialValues);
        updateGroup(initialValues);
        setLoading(false);
      } else if (info.file.status === 'error') {
        setLoading(false);
        KoToast.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      KoToast.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      KoToast.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  const uploadButton = (
    <div className={styles.dndArea}>
      {Array.isArray(initialValues?.logo) && initialValues.logo.length > 0 && initialValues.logo[0]?.url ? (
        <KoImage preview={false} src={initialValues.logo[0].url} />
      ) : (
        <>
          <KoIcon name="ImagesOutlined" />
          {isLoading && <KoLoader size="small" />}
          <KoText className={styles.fileInfo} text="At least 256 x 256px PNG or JPG file" />
        </>
      )}
    </div>
  );


  return (
    <div className={styles.wrapper}>
      {isLoading && <KoLoader />}
      <KoForm form={form} layout="vertical" onFinish={onFinish} onSubmit={onFinish}>
        <KoForm.Item
          name={["logo"]}
          valuePropName={["logo"]}
          getValueFromEvent={normFile}

        >
          <Upload {...props}
            className={styles.upload}
            beforeUpload={beforeUpload}
            max={{ count: 1 }}
            showUploadList={false}
            showPreviewIcon={false}
            listType="picture-card"
          >
            {uploadButton}
          </Upload>
        </KoForm.Item>
        <KoFormBuilder form={form} meta={meta} />
      </KoForm>
    </div>
  );
};

export default GroupsCrud;