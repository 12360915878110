/* eslint-disable import/no-anonymous-default-export */
import { useState } from 'react';
import styles from '../kanban.module.scss';
import KoButton from 'packages/button';
import { KoText, KoTitle } from 'packages/typography';



function ColumnTitle({ background, column, color, allowRenameColumn, onClick, children: title, totalCards  }) {

  return allowRenameColumn ? (
    <KoText text={title} onClick={onClick}/>
  ) : (
    <div className={styles.colHeader}>
      <div className={styles.circle} style={{ background:color, color }}>
      </div>
      <KoTitle level={5} text={title}/>
      <KoTitle level={5} text={`${column.cards?.length} / ${totalCards}`}/>
    </div>
  );
}

function useRenameMode(state) {
  const [renameMode, setRenameMode] = useState(state);

  function toggleRenameMode() {
    setRenameMode(!renameMode);
  }

  return [renameMode, toggleRenameMode];
}

export default function({ children: column, allowRemoveColumn, onColumnRemove, allowRenameColumn, onColumnRename, totalCards  }) {
  const [renameMode, toggleRenameMode] = useRenameMode(false);
  const [titleInput, setTitleInput] = useState('');

  function handleRenameColumn(event) {
    event.preventDefault();

    onColumnRename(column, titleInput);
    toggleRenameMode();
  }

  function handleRenameMode() {
    setTitleInput(column.title);
    toggleRenameMode();
  }

  return (
    <div className={styles.kanbanColumnHeader}>
      {renameMode ? (
        <form onSubmit={handleRenameColumn}>
          <span>
            <input
              type='text'
              value={titleInput}
              onChange={({ target: { value } }) => setTitleInput(value)}
              autoFocus
            />
          </span>
          <span>
            <KoButton label='Rename' type='submit'/>
            <KoButton onClick={handleRenameMode} label='Cancel' type='submit'/>
          </span>
        </form>
      ) : (
        <>
          <ColumnTitle background={column.background} totalCards={totalCards} column={column} color={column.color} allowRenameColumn={allowRenameColumn} onClick={handleRenameMode}>
            {column.label}
          </ColumnTitle>
          {allowRemoveColumn && <span onClick={() => onColumnRemove(column)}>×</span>}
        </>
      )}
    </div>
  );
}
