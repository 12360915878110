import { KoAPI } from "packages";
import { configs } from 'configuration';
const version = configs().API_VERSION;
export const BrowseAPI = {
  getIssuesById(id, accountId, workspaceId) {
    return KoAPI.get(`/api/${version}/taskplanner/ticket/${id}`, { header: { "workspace": workspaceId } });
  },
  getEventById(id) {
    return KoAPI.get(`/api/${version}/events/${id}`);
  },
  getEventDetailsById(accountId, eventId, userId, query) {
    return KoAPI.get(`/api/${version}/events/${accountId}/${eventId}/${userId}/${query}`);
  },
  
  createOrUpdateEvents(data, id){
    if(id) {
      return KoAPI.update(`/api/${version}/events/${id}`, { data: { ...data } });
    } else {
      return KoAPI.post(`/api/${version}/events`, { data: { ...data } });
    }
  },

  getBookingEventById(eventId, id) {
    return KoAPI.get(`/api/${version}/events/booking/${eventId}/${id}`);
  },
  createOrUpdateEventsById(data, AccountId,  eventId, userId, id){
    if(id) {
      return KoAPI.update(`/api/${version}/events/booking/${eventId}/${id}`, { data: { ...data } });
    } else {
      return KoAPI.post(`/api/${version}/events/booking/${AccountId}/${eventId}/${userId}`, { data: { ...data } });
    }
  }
};