import React from "react";
function ClosedSprint(props) {
  const { width, height } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" fill="none">
      <path d="M1 9.92308H2.48242L4.08516 6.48462C4.96172 4.59231 5.68359 3.03692 5.68359 3.02308C5.68359 3.00923 4.63086 3 3.3418 3H1V9.92308Z" fill="black" />
      <path d="M3.92188 9.92308H6.99415L8.59688 6.48462C9.47344 4.59231 10.1953 3.03692 10.1953 3.02308C10.1953 3.00923 9.50782 3 8.66993 3H7.14454L3.92188 9.92308Z" fill="black" />
      <path d="M8.4336 9.92308H12.1504L13.7531 6.48462C14.6297 4.59231 15.3516 3.03692 15.3516 3.02308C15.3516 3.00923 14.5223 3 13.5039 3H11.6563L8.4336 9.92308Z" fill="black" />
      <path d="M13.5898 9.92308H16.6621L18.2648 6.48462C19.1414 4.59231 19.8633 3.03692 19.8633 3.02308C19.8633 3.00923 19.1758 3 18.3379 3H16.8125L13.5898 9.92308Z" fill="black" />
      <path d="M18.1016 9.92308H23V3H21.3242L18.1016 9.92308Z" fill="black" />
      <path d="M3.6211 19.6154H2.33204V21H7.48829V19.6154H6.19923V16.8462H17.8008V19.6154H16.5117V21H21.668V19.6154H20.3789V11.3077H17.8008V14.0769H6.19923V11.3077H3.6211V19.6154Z" fill="black" />
    </svg>

  );
}


export default ClosedSprint;