import React from "react";
function Support(props) {
  const { width, height } = props;
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="4" fill="var(--support)" />
      <path d="M9.08332 12C9.08343 11.4285 9.25249 10.8698 9.56924 10.3941L6.65956 7.48439C5.58822 8.74507 5 10.3456 5 12C5 13.6544 5.58822 15.2549 6.65956 16.5156L9.56924 13.6059C9.25249 13.1302 9.08343 12.5715 9.08332 12Z" fill="white" />
      <path d="M17.3404 7.48439L14.4308 10.3941C14.7476 10.8697 14.9166 11.4285 14.9166 12C14.9166 12.5715 14.7476 13.1303 14.4308 13.6059L17.3404 16.5156C18.4118 15.2549 19 13.6544 19 12C19 10.3456 18.4118 8.74507 17.3404 7.48439Z" fill="white" />
      <path d="M12 14.9167C11.4285 14.9166 10.8698 14.7475 10.3941 14.4308L7.48439 17.3404C8.74507 18.4118 10.3456 19 12 19C13.6544 19 15.2549 18.4118 16.5156 17.3404L13.6059 14.4308C13.1302 14.7475 12.5715 14.9166 12 14.9167Z" fill="white" />
      <path d="M12 9.08332C12.5715 9.08343 13.1302 9.25249 13.6059 9.56924L16.5156 6.65956C15.2549 5.58822 13.6544 5 12 5C10.3456 5 8.74507 5.58822 7.48439 6.65956L10.3941 9.56924C10.8698 9.25249 11.4285 9.08343 12 9.08332Z" fill="white" />
    </svg>

  );
}
export default Support;