import React, { useState, useEffect, useRef } from 'react';
import * as pdfjs from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import styles from './pdf.module.scss';
import KoIcon from 'packages/icon';
import { KoText } from 'packages/typography';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const PdfViewer = ({ filePath, thumbnail }) => {
  const [, setPdf] = useState(null);
  const canvasRef = useRef(null);

  useEffect(() => {
    const loadPdf = async() => {
      try {
        const pdfDocument = await pdfjs.getDocument(filePath).promise;
        setPdf(pdfDocument);
        renderPage(pdfDocument, 1); // Render the first page
      } catch (error) {
        console.error('Error loading PDF:', error);
      }
    };

    !thumbnail && loadPdf();

    // Cleanup function
    return () => {
      // Clean up logic if necessary
    };
  }, [filePath]);

  const renderPage = async(pdfDocument, pageNumber) => {
    try {
      const page = await pdfDocument.getPage(pageNumber);
      const viewport = page.getViewport({ scale: 1 });
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');

      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport
      };

      await page.render(renderContext).promise;
    } catch (error) {
      console.error('Error rendering page:', error);
    }
  };

  return (
    !thumbnail? <div>

    
      <canvas ref={canvasRef}/>
    </div>:  <div className={`${styles.chatContent}`}>
      <KoIcon name={'PdfOutlined'} width={23} height={24} />
      <div className={styles.fileInfo}>
        <KoText ellipsis={true} className={styles.fileName} text={'Project Flow.pdf'} />
        <div className={styles.fileSize}>756 kb</div>
      </div>
    </div>
  );
};

export default PdfViewer;