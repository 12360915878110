import React from "react";
function ActiveSprint(props) {
  return (
    <svg  viewBox="0 0 24 24" {...props}>
      <path
        fill="#666"
        d="M17.717 17.137l-1.436-1.438a1.003 1.003 0 011.418-1.418l3.007 3.01a1 1 0 010 1.417l-3.007 3.01a1.003 1.003 0 01-1.417-1.42l1.3-1.3-6.072-.003A8 8 0 014 11.01C4 6.587 7.582 3 12 3s8 3.587 8 8.01a8 8 0 01-.789 3.451l-1.438-1.44a6.1 6.1 0 00.345-2.01A6.12 6.12 0 0012 4.884a6.12 6.12 0 00-6.118 6.125 6.12 6.12 0 005.647 6.102zm-12.163-.119c.561.759 1.456 1.49 2.457 1.981L3.937 19C3.42 19 3 18.552 3 18s.42-1 .937-1l1.614.005z"
      />
    </svg>

  );
}


export default ActiveSprint;