import React from "react";
import { Input  } from 'antd';

const { TextArea } = Input;

function KoTextArea(props) {
  return (
    <TextArea
      {...props}
      style={{ width: '100%' }}
    />
  );
}

export default KoTextArea;