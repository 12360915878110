import React from "react";
function Priority(props) {
  const { width, height } = props;
  return (
    <svg
      width={width}
      height={height}
      data-name="Layer 1"
      viewBox="0 0 1000 1000"
      fill="#657583"
      {...props}
    >
      <path d="M194.61 773.13a16 16 0 01-16-16v-60.68a16 16 0 0132 0v60.68a16 16 0 01-16 16zm0-302.39a16 16 0 01-16-16v-60.68a16 16 0 0132 0v60.68a16 16 0 01-16 16z" />
      <path d="M771.37 916H273.88a95.47 95.47 0 01-89.73-63.22A16 16 0 01214.29 842a63.4 63.4 0 0059.59 42h497.49a63.34 63.34 0 0063.27-63.27V179.27A63.34 63.34 0 00771.37 116H273.88a63.4 63.4 0 00-59.59 42 16 16 0 01-30.14-10.75A95.46 95.46 0 01273.88 84h497.49a95.38 95.38 0 0195.27 95.27v641.46A95.38 95.38 0 01771.37 916z" />
      <path d="M194.61 319.55a16 16 0 01-16-16v-60.68a16 16 0 0132 0v60.68a16 16 0 01-16 16zm0 302.39a16 16 0 01-16-16v-60.68a16 16 0 0132 0v60.68a16 16 0 01-16 16zM319.7 916a16 16 0 01-16-16V100a16 16 0 0132 0v800a16 16 0 01-16 16z" />
      <path d="M194.61 258.87a61.25 61.25 0 1161.26-61.25 61.32 61.32 0 01-61.26 61.25zm0-90.5a29.25 29.25 0 1029.26 29.25 29.28 29.28 0 00-29.26-29.25zm0 241.69a61.25 61.25 0 1161.26-61.25 61.32 61.32 0 01-61.26 61.25zm0-90.5a29.25 29.25 0 1029.26 29.25 29.28 29.28 0 00-29.26-29.25zm0 241.69A61.25 61.25 0 11255.87 500a61.32 61.32 0 01-61.26 61.25zm0-90.5A29.25 29.25 0 10223.87 500a29.28 29.28 0 00-29.26-29.25zm0 241.69a61.25 61.25 0 1161.26-61.25 61.32 61.32 0 01-61.26 61.25zm0-90.5a29.25 29.25 0 1029.26 29.25 29.28 29.28 0 00-29.26-29.25zm0 241.69a61.25 61.25 0 1161.26-61.25 61.32 61.32 0 01-61.26 61.25zm0-90.5a29.25 29.25 0 1029.26 29.25 29.28 29.28 0 00-29.26-29.25zM623.6 488.05H399.91a16 16 0 01-16-16V343.18a16 16 0 0116-16H623.6a16 16 0 0116 16v128.87a16 16 0 01-16 16zm-207.69-32H607.6v-96.87H415.91zm272.64 216.77H399.91a16 16 0 01-16-16V528a16 16 0 0116-16h288.64a16 16 0 0116 16v128.82a16 16 0 01-16 16zm-272.64-32h256.64V544H415.91zM770.43 857.6H399.91a16 16 0 01-16-16V712.73a16 16 0 0116-16h370.52a16 16 0 0116 16V841.6a16 16 0 01-16 16zm-354.52-32h338.52v-96.87H415.91zm157.97-522.33h-174a16 16 0 01-16-16V158.4a16 16 0 0116-16h174a16 16 0 0116 16v128.87a16 16 0 01-16 16zm-158-32h142V174.4h-142z" />
    </svg>

  );
}


export default Priority;