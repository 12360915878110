import React from 'react';

import { ConfigProvider } from 'antd';
import { Toaster } from 'react-hot-toast';
import { theme } from './shared/theme';
import RootRouter from './router';
import SocketProvider from 'shared/socketContext';


const App = () => {
 
  return (
    <SocketProvider>
      <ConfigProvider theme={theme}>
        <RootRouter />
        <Toaster
          position="top-right"
          reverseOrder={false}
          gutter={8}
          containerClassName=""
          containerStyle={{}}
          toastOptions={{
            className: '',
            duration: 5000,
            style: {
              background: '#000',
              color: '#fff',
              borderRadius: '.4rem',
              boxShadow: `var(--card-4)`
            },
            success: {
              duration: 3000,
              theme: {
                primary: 'green',
                secondary: 'black'
              }
            }
          }}
        />
      </ConfigProvider>
    </SocketProvider>
  );
};

export default App;


// import React, {useEffect, useState} from 'react';
// import dayjs from 'dayjs';

// import advancedFormat from 'dayjs/plugin/advancedFormat';
// import customParseFormat from 'dayjs/plugin/customParseFormat';
// import localeData from 'dayjs/plugin/localeData';
// import weekday from 'dayjs/plugin/weekday';
// import weekOfYear from 'dayjs/plugin/weekOfYear';
// import weekYear from 'dayjs/plugin/weekYear';
// import { ConfigProvider } from 'antd';
// import { Toaster } from 'react-hot-toast';
// import Auth from './shared/rbac/provider';
// import { theme } from './shared/theme';
// import RootRouter from './router';
// import Error404 from 'pages/error/404';
// import { supportAPI } from 'pages/support/tickets/support.service';

// dayjs.extend(customParseFormat);
// dayjs.extend(advancedFormat);
// dayjs.extend(weekday);
// dayjs.extend(localeData);
// dayjs.extend(weekOfYear);
// dayjs.extend(weekYear);

// const App = () => {
//   const [isValidSubdomain, setIsValidSubdomain] = useState(true);
//   const [subdomain, setSubdomain] = useState(null);
//   useEffect(() => {
//     const host = window.location.host;
  
//     // Handle localhost case
//     if (host.includes("localhost")) {
//       const arr = host.split(".").slice(0, host.includes("localhost") ? -1 : -2);
//       const currentSubDomain = arr[0];
//       setSubdomain(currentSubDomain);
//       if (['dev'].includes(currentSubDomain)) {
//         setIsValidSubdomain(true);
//       } else {
//         setIsValidSubdomain(false);
//       }
//     } else {
//       // Handle live case
//       supportAPI.getAccounts().then(
//         (data) => {
//           const validDomains = data['domain'].map(url => url.split('.')[0].toLowerCase());
//           const arr = host.split(".").slice(0, host.includes("kwapio.com") ? -1 : -2);
//           const currentSubDomain = arr[0];
//           setSubdomain(currentSubDomain);
          
//           if (arr.length > 0) {
//             if (validDomains.includes(currentSubDomain)) {
//               setIsValidSubdomain(true);
//             } else {
//               setIsValidSubdomain(false);
//             }
//           } else {
//           }
//         },
//         (err) => {
//           // Handle API error
//           setIsValidSubdomain(false);
//         }
//       );
//     }
//   }, []);

//   return (
//     <ConfigProvider theme={theme}>
//       {isValidSubdomain ? (
//         <Auth>
//           <RootRouter />
//           <Toaster
//             position="top-right"
//             reverseOrder={false}
//             gutter={8}
//             containerClassName=""
//             containerStyle={{}}
//             toastOptions={{
//               className: '',
//               duration: 5000,
//               style: {
//                 background: '#000',
//                 color: '#fff',
//                 borderRadius: '.4rem',
//                 boxShadow: `var(--card-4)`
//               },
//               success: {
//                 duration: 3000,
//                 theme: {
//                   primary: 'green',
//                   secondary: 'black'
//                 }
//               }
//             }}
//           />
//         </Auth>
//       ) : (
//         <Error404 onClick={() => window.location.replace("https://app.kwapio.com")} label="Login" subText={`Maybe it was deleted, or you accidentally mistyped the URL`} title={`Sorry, we couldn't find the site ${subdomain}`}/>
//       )}
//     </ConfigProvider>
//   );
// };

// export default App;
