import dayjs from 'dayjs';
import { KoButton, KoCalendarPicker, KoForm, KoFormBuilder, KoIcon, KoNano, KoSelect, KoTag, KoText, KoTimeLine, KoTitle, KoToast, KoTooltips } from 'packages';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getUserById } from 'shared/store/userByIdSlice';
import { BrowseAPI } from '../browse.service';
import styles from './opencalender.module.scss';
export default function CalendarPreview() {
  const userById = useSelector(state => state?.userById);
  const [form] = KoForm.useForm();
  const [isLoading, setLoading] = useState(false);
  const [currentRecord, setCurrentRecord] = useState();
  const [slotDate, setSlotDate] = useState();
  const [slots, setSlots] = useState();
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [isDate, setDate] = useState();
  const { eventId, accountId, userId } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    const selectedDate = new Date();
    const day = selectedDate.getDate().toString().padStart(2, '0');
    const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
    const year = selectedDate.getFullYear().toString().slice(0);
  
    const formattedDate = `${year}-${month}-${day}`;
    setDate(selectedDate);
    setLoading(false);
    getEventData();
    getSlots(formattedDate);
  }, []);

  useEffect(() => {
    const today = new Date();
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = today.toLocaleDateString('en-US', options);
    setSlotDate(formattedDate);
  }, []);
  
  const getEventData = () => {
    BrowseAPI.getEventById(eventId).then(
      (data) => {
        setCurrentRecord(data?.event);
        dispatch(getUserById(data?.event?.createdBy));
        setLoading(false);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const getSlots = (formattedDate) => {
    BrowseAPI.getEventDetailsById(accountId, eventId, userId, formattedDate).then(
      (data) => {
        setLoading(true);
        setSlots();
        setLoading(false);
        setSelectedSlot();
        setSlots(data?.eventDetails);
      },
      (err) => {
        setLoading(false);
      }
    );
  };

  const updateEventData = async(record, values) => {
    setLoading(true);

    if (!record?.bookingId) {
      values['bookingId'] = KoNano('BOK');
    }

    values['date'] = isDate;
    values['slot'] = selectedSlot;
    await BrowseAPI.createOrUpdateEventsById(values, accountId,  record.eventId, userId, record.bookingId)
      .then(
        (data) => {
          setLoading(false);
          KoToast.success(`Meeting ${record?.bookingId ? 'updated':'scheduled'} successfully`);
        },
        (err) => {
          setLoading(false);
        }
      );
  };

  const handleFinish =  useCallback(values => {
    setLoading(true);
    updateEventData(currentRecord, values);
  });

  const items = currentRecord?.agenda?.map(agenda => ({
    children: <KoText className={styles.lite} text={`${agenda.title} ${agenda.duration ?? 0} mins`} />
  }));

  const Email = ({ value, onChange }) => (
    <KoSelect
      value={value}
      showSearch
      onChange={v => onChange(v)}
      mode="tags"
      tokenSeparators={[',']}
      placeholder='Enter Email'
    />
  );


  const meta = {
    columns: 4,
    formItemLayout: null,
    initialValues: currentRecord?.booking,
    colon: true,
    fields: [
      {
        key: 'name', label: 'Name', placeholder: 'Enter your name', rules: [
          {
            required: true,
            message: 'Name is required'
          }
        ], colSpan: 2
      },
      {
        key: 'email', label: 'Email', placeholder: 'Enter your email', rules: [
          {
            required: true,
            message: 'Email is required'
          }
        ], colSpan: 2
      },
      {
        key: 'guestEmails', label: 'Guest Email(s)', placeholder: 'Enter guest emails',widget: Email, colSpan: 4
      },
      {
        key: 'notes', label: 'Notes', widget: 'textarea', placeholder: 'Please share any prep materials for our meeting.', colSpan: 4
      }
    ]
  };

  const HandleSelectedDate = (e) => {
    const selectedDate = new Date(e);
    const day = selectedDate.getDate().toString().padStart(2, '0');
    const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
    const year = selectedDate.getFullYear().toString().slice(0);
  
    const formattedDate = `${year}-${month}-${day}`;
    setDate(e);
    getSlots(formattedDate);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.modal}>
        <div className={styles.content}>
          <div className={styles.eventDetails}>
            <div className={styles.header}>
              <KoTitle text={'Overview of Event Details'} level={3} />
            </div>
            <div className={styles.eventInfo}>
              <KoText className={styles.lite} text={'Organiser'} />
              <KoText className={styles.dark} text={`${userById?.data?.profile?.firstName} ${userById?.data?.profile?.lastName}`} />
            </div>
            <div className={styles.eventInfo}>
              <KoText className={styles.lite} text={'Duration'} />
              <div className={styles.duration}>
                <KoIcon name={'LogOutlined'} width={14} height={14} />
                <KoText className={styles.dark} text={currentRecord?.duration} />
              </div>
            </div>

            <div className={styles.eventInfo}>
              <KoText className={styles.lite} text={'TimeZone'} />
              <div className={styles.duration}>
                <KoIcon name={'TimeZoneOutlined'} width={14} height={14} />
                <KoText className={styles.dark} text={'India Standard Time'} />
              </div>
            </div>

            <div className={styles.eventInfo}>
              <KoText className={styles.lite} text={'Summary'} />
              <KoText className={styles.dark} text={currentRecord?.description} />
            </div>
            <div className={styles.eventInfo}>
              <KoText className={styles.dark} text={'Agenda'} />
              <div className={styles.agenda}>
                <KoTimeLine
                  items={items}
                />

              </div>
            </div>
          </div>
          <div className={styles.calEventDetails}>
            <div className={styles.header}>
              <KoTitle text={'Choose Your Preferred Date & Time'} level={3} />
            </div>
            <KoCalendarPicker onChange={(e)=>{
              const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
              const formattedDate = new Date(e).toLocaleDateString('en-US', options);
              setSlotDate(formattedDate);
              HandleSelectedDate(e);
            }}/>
          </div>
          <div className={styles.eventDetails}>
            <KoText className={styles.lite} text={'Select a slot for booking on '} />
            <KoText className={styles.dark} text={slotDate} />
            <div className={styles.slots}>
              {slots?.map((slotData) => {
                const startTime = slotData.slot?.match(/start:(\d{2}:\d{2})/)?.[1];
                const formattedStartTime = startTime ? dayjs(startTime, "HH:mm").format("hh:mm A") : "";
                const isSelected = selectedSlot === slotData.slot;

                return (
                  <>
                    {
                      startTime &&
                  <KoTooltips
                    key={slotData.slot}
                    title={slotData.booked ? "This slot is already booked" : "Click to select this slot"}>
                    <KoTag
                      className={`${styles.tag} ${isSelected ? styles.selectedTag : ''}`}
                      value={
                        <span style={{
                          textDecoration: slotData.booked ? 'line-through' : 'none',
                          color: slotData.booked ? 'gray' : 'inherit' }}
                        className={slotData.booked ? styles.bookedHover : ''}>
                          {formattedStartTime}
                        </span> }
                      closable={false}
                      icon={ <KoIcon name={slotData.booked ? "CloseOutlined" : "LogOutlined"}
                        color={isSelected ? "white" : (slotData.booked ? "var(--color-palette-2-dark)" : "")}
                        width={14} height={14}/> }

                      color={slotData.booked ? "default" : isSelected ? "primary" : "default"}
                      onClick={() => {
                        if (!slotData.booked) {
                          setSelectedSlot(slotData.slot);
                        }
                      }}
                    />
                  </KoTooltips>
                    }
                  </>);
              })}
            </div>
            <div className={styles.attendeesInfo}>
              <KoForm form={form} layout="vertical" onFinish={handleFinish} onSubmit={handleFinish}>
                <KoFormBuilder className={styles.formData} form={form} meta={meta} />
              </KoForm>
            </div>
          </div>
        </div>
        <div className={styles.footer}>
          <KoButton loading={isLoading} onClick={() => form.submit()} label={'Schedule Meeting'} type={'primary'} />
        </div>
      </div>
    </div>
  );
}
