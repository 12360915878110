import {KoAPI} from "packages";
import { configs } from 'configuration';
const version = configs().API_VERSION;
export const ChatAPI = {
  getRecentTicket(query){
    return KoAPI.get(`/api/${version}/taskplanner/ticket/recent`, { query: { ...query } });
  },
  getContactsWithMessages(query){
    return KoAPI.get(`/api/${version}/chat/rooms`, { query: { ...query } });
  },
  getRoomsUsers(query){
    return KoAPI.get(`/api/${version}/chat/rooms/search`, { query: { ...query } });
  },
  getRoomsGroups(query){
    return KoAPI.get(`/api/${version}/chat/rooms/groups`, { query: { ...query } });
  },
  getRoomsTickets(query){
    return KoAPI.get(`/api/${version}/chat/rooms/tickets`, { query: { ...query } });
  },
  getUsers(query){
    return KoAPI.get(`/api/${version}/org/user`, { query: { ...query } });
  },
  getGroups(query){
    return KoAPI.get(`/api/${version}/org/group`, { query: { ...query } });
  },
  getGroupUsers(id){
    return KoAPI.get(`/api/${version}/chat/rooms/members/${id}`);
  },
  getTicketUsers(id){
    return KoAPI.get(`/api/${version}/chat/ticket/rooms/members/${id}`);
  },
  getRooms(query){
    return KoAPI.get(`/api/${version}/chat/rooms`, { query: { ...query } });
  },
  getChatHistory(roomId){
    return KoAPI.get(`/api/${version}/chat/rooms/history/${roomId}`);
  }
};