import React from "react";
function Print(props) {
  const { width, height } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 96 96"
      width={width}
      height={height}
    >
      <path
        stroke="#FFF"
        strokeWidth={5}
        d="M29.482 68H12a4 4 0 01-4-4V35a4 4 0 014-4h72a4 4 0 014 4v29a4 4 0 01-4 4H66.519M28 13a3 3 0 013-3h34a3 3 0 013 3v18H28V13z"
        className="colorStroke12182B svgStroke"
      />
      <path
        stroke="#FFF"
        strokeWidth={5}
        d="M28.5 18H22a3 3 0 00-3 3v10h58V21a3 3 0 00-3-3h-6"
        className="colorStroke12182B svgStroke"
      />
      <path
        stroke="#FFF"
        strokeLinecap="round"
        strokeWidth={5}
        d="M21 53h54M58 43h17"
        className="colorStroke12182B svgStroke"
      />
      <path
        fill="#FFF"
        d="M25 43a3 3 0 11-6 0 3 3 0 016 0z"
        className="color12182B svgShape"
      />
      <path
        stroke="#FFF"
        strokeWidth={5}
        d="M69 55v28a3 3 0 01-3 3H30a3 3 0 01-3-3V55"
        className="colorStroke12182B svgStroke"
      />
      <path
        stroke="#FFF"
        strokeLinecap="round"
        strokeWidth={5}
        d="M36 77h24m-24-8h24m-24-8h24"
        className="colorStroke12182B svgStroke"
      />
    </svg>

  );
}


export default Print;