import React from "react";
import AppLogoFilled from './filled/logo';
import KoImage from "../image";
import StoryFilled from './filled/story';
import BugFilled from './filled/bug';
import ChangeRequestFilled from './filled/changerequest';
import EpicFilled from './filled/epic';
import FeatureRequrstFilled from './filled/featurerequrst';
import GoogleFilled from './filled/google';
import ImprovementFilled from './filled/improvement';
import MicrosoftFilled from './filled/microsoft';
import QueryFilled from './filled/query';
import PriorityFilled from './filled/priority';
import StatusFilled from './filled/status';
import SubTaskFilled from './filled/subtask';
import SupportFilled from './filled/support';
import TaskFilled from './filled/task';
import WishListFilled from './filled/wishlist';
import BackLogFilled from './filled/backlog';
import LastSprintFilled from './filled/lastsprint';
import GoalFilled from './outlined/goal';
import ActiveSprintFilled from './filled/activesprint';
import ClosedSprintFilled from './filled/closed';
import RightArrowFilled from './filled/rightarrow';
import Avatar1Filled from './filled/avatar1';
import Avatar2Filled from './filled/avatar2';
import Avatar3Filled from './filled/avatar3';
import Avatar4Filled from './filled/avatar4';
import BusinessFilled from './filled/business';
import BusinessProfileFilled from './filled/businessprofile';
import PreferencesFilled from './filled/preferences';
import LocationFilled from './filled/location';
import BankFilled from './filled/bank';
import TaxFilled from './filled/tax';
import DiscountFilled from './filled/discount';
import Folder0Filled from './filled/folder0';
import Folder1Filled from './filled/folder1';
import Folder2Filled from './filled/folder2';
import Folder3Filled from './filled/folder3';
import Folder4Filled from './filled/folder4';
import Folder5Filled from './filled/folder5';
import TestingFilled from './filled/qa';
import DevFilled from './filled/dev';
import UatFilled from './filled/uat';
import PrdFilled from './filled/production';
import PlusFilled from './filled/plus';
import PdfFilled from './filled/pdf';
import PrintFilled from './filled/print';
import ShareFilled from './filled/share';
import EditFilled from './filled/edit';
import PreviewFilled from './filled/preview';
import TickFilled from './filled/tick';
import CrossFilled from "./filled/cross";
import DotFilled from "./filled/dot";
import ArrowFilled from './filled/arrow';
import UserFilled from './filled/user';
import DashboardFilled from "./filled/dashboard";
import InboxFilled from "./filled/inbox";
import TimeSheetFilled from "./filled/timesheet";
import PerformanceFilled from "./filled/performance";
import KanbanOutlined from './outlined/kanban';
import GrpupsFilled from './filled/groups';
import UploadFilled from "./filled/upload";

import ProfileUpdateOutlined from './outlined/profileupdate';
import EpicOutlined from './outlined/epic';
import UploadOutlined from './outlined/upload';
import WorkspaceOutlined from './outlined/workspace';
import NoWorkSpaceOutlined from './outlined/noworkspace';
import AddtoGroupsOutlined from './outlined/addtogroup';
import ProjectsOutlined from './outlined/projects';
import DocumentsOutlined from './outlined/documents';
import InvoicesOutlined from './outlined/invoices';
import CalendarOutlined from './outlined/calendar';
import DeploymentOutlined from './outlined/deployment';
import EventsOutlined from './outlined/events';
import SalesOutlined from './outlined/sales';
import ClientsOutlined from './outlined/clients';
import OrgOutlined from './outlined/org';
import SettingsOutlined from './outlined/settings';
import PollOutlined from './outlined/poll';
import SharedOutlined from './outlined/shared';
import EnvOutlined from "./outlined/env";
import CopyOutlined from "./outlined/copy";
import InfoOutlined from "./outlined/info";
import TickOutlined from "./outlined/tick";
import TotalUsersOutlined from './outlined/totalusers';
import ActiveUseresOutlined from './outlined/activeusers';
import InactiveUsersOutlined from './outlined/inactiveusers';
import TotalGroupsOutlined from './outlined/totalgroup';
import InvitedOutlined from './outlined/invited';
import RequestedOutlined from './outlined/requested';
import ArrowLeftOutlined from './outlined/arrowleft';
import ArrowRightOutlined from './outlined/arrowright';
import RowOpenOutlined from './outlined/rowOpen';
import RowCloseOutlined from './outlined/rowClose';
import DownArrowOutlined from "./outlined/downarrow";
import StopHandOutlined from "./outlined/stophand";
import TestCasesOutlined from "./outlined/testcases";
import ListOutlined from "./outlined/list";
import TextOutlined from "./outlined/text";
import EmpIdOutlined from "./outlined/empId";
import ResetOutlined from "./outlined/reset";
import AudioOutlined from "./outlined/audio";
import ScrumSprintOutlined from "./outlined/scrum";
import DescendingOutlined from "./outlined/descending";
import AscendingOutlined from "./outlined/ascending";
import SortOutlined from "./outlined/sort";
import ModulesOutlined from "./outlined/module";
import ComponentsOutlined from "./outlined/component";
import MeetingsOutlined from "./outlined/meetings";
import BoldOutlined from './outlined/bold';
import BugOutlined from './outlined/bug';
import ChangerequestOutlined from './outlined/changerequest';
import ChatOutlined from './outlined/chat';
import SearchDocOutlined from './outlined/searchdoc';
import DragOutlined from './outlined/drag';
import CloseOutlined from './outlined/close';
import CloudDownloadOutlined from './outlined/clouddownload';
import EditOutlined from './outlined/edit';
import CodeOutlined from './outlined/code';
import MoveToOutlined from './outlined/moveto';
import DoubleQuotesOutlined from "./outlined/doublequotes";
import BulletOutlined from './outlined/bullet';
import NumberedOutlined from './outlined/numbered';
import ShareOutlined from './outlined/share';
import DeleteOutlined from './outlined/delete';
import DocOutlined from './outlined/doc';
import DownloadOutlined from './outlined/download';
import FeaturerequrstOutlined from './outlined/featurerequrst';
import ImagesOutlined from './outlined/image';
import TextColorOutlined from './outlined/textcolor';
import BgColorOutlined from './outlined/bgcolor';
import ImprovementOutlined from './outlined/improvement';
import ItalicOutlined from './outlined/italic';
import UnderlineOutlined from './outlined/underline';
import LinkOutlined from './outlined/link';
import VerticalMenuOutlined from './outlined/virticalmenu';
import MenuOutlined from './outlined/menu';
import NotificationOutlined from './outlined/notification';
import OpenOutlined from './outlined/open';
import QuickViewOutlined from './outlined/quickview';
import PdfOutlined from './outlined/pdf';
import PinOutlined from './outlined/pin';
import PlayingaudioOutlined from './outlined/playingaudio';
import PlaywhiteOutlined from './outlined/playwhite';
import PointsOutlined from './outlined/points';
import QueryOutlined from './outlined/query';
import SendOutlined from './outlined/send';
import SmileyOutlined from './outlined/smiley';
import AtOutlined from './outlined/at';
import StoryOutlined from './outlined/story';
import SubtaskOutlined from './outlined/subtask';
import SupportOutlined from './outlined/support';
import TableOutlined from './outlined/table';
import TaskOutlined from './outlined/task';
import UnplayedaudioOutlined from './outlined/unplayedaudio';
import LogOutlined from './outlined/log';
import PlayOutlined from './outlined/play';
import PauseOutlined from './outlined/pause';
import StopOutlined from './outlined/stop';
import VideoOutlined from './outlined/video';
import FilterOutlined from './outlined/filter';
import WishListOutlined from './outlined/wishlist';
import BackLogOutlined from './outlined/backlog';
import LastSprintOutlined from './outlined/lastsprint';
import ActiveSprintOutlined from './outlined/activesprint';
import ClosedSprintOutlined from './outlined/closed';
import TimeZoneOutlined from './outlined/timezone';
import Users from './outlined/users';
import Attachments from './outlined/attachments';
import EmailOutlined from './outlined/email';
import PhoneOutlined from './outlined/phone';
import HomeOutlined from './outlined/home';
import PayslipOutlined from './outlined/payslip';
import ChangePasswordOutlined from './outlined/changepassword';
import AnnouncementsOutlined from './outlined/announcement';
import LogoutOutlined from './outlined/logout';
import APIOutlined from './outlined/api';
import SendRequestOutlined from './outlined/sendrequest';
import PlusOutlined from './outlined/plus';
import SearchOutlined from './outlined/search';
import SuspendOutlined from './outlined/suspand';
import ActivateOutlined from './outlined/activate';
import LeftOutlined from './outlined/left';
import RightOutlined from './outlined/right';
import CenterOutlined from './outlined/center';
import JustifyOutlined from './outlined/justify';
import StrikethroughOutlined from './outlined/strikethrough';
import FolderOutlined from './outlined/folder';
import SecurityOutlined from './outlined/security';
import ParagraphOutlined from './outlined/paragraph';
import NumbersOutlined from "./outlined/number";
import DropdownOutlined from "./outlined/dropdown";
import CheckboxOutlined from "./outlined/checkbox";
import ChoiceOutlined from "./outlined/choice";
import LineOutlined from "./outlined/line";
import WebOutlined from "./outlined/web";
import CountryOutlined from "./outlined/country";
import CustomerOutlined from "./outlined/customer";
import YesnoOutlined from "./outlined/yesno";
import ExportOutlined from "./outlined/export";
import ImportOutlined from "./outlined/import";
import SkypeOutlined from "./outlined/skype";
import TreeOutlined from "./outlined/tree";
import DashboardOutlined from "./outlined/dashboard";
import BillableOutlined from "./outlined/billable";
import NonBillableOutlined from "./outlined/nonbillable";
import ReplyOutlined from "./outlined/reply";

import BannerTwoTone from './twotone/banner';
import AssignedTaskTwoTone from './twotone/assignedtask';
import AssigneeTwoTone from './twotone/assignee';
import DaysReminingTwoTone from './twotone/daysremaining';
import IssueRisedTwoTone from './twotone/issuerised';
import LeaveTodayTwoTone from './twotone/leavetoday';
import MeetingsTwoTone from './twotone/meetings';
import OfficeTwoTone from './twotone/office';
import WishListTwoTone from './twotone/wishlist';
import WorkFromHomeTwoTone from './twotone/workfromhome';
import EmptyTwoTone from './twotone/empty';
import TableEmptyTwoTone from './twotone/tableEmpty';
import InvoiceTempleteTwoTone from './twotone/invoiceTemplete';
import WorkingTwoTone from "./twotone/working";
import PageNotFoundTwoTone from "./twotone/pagenotfound";

import styles from './icons.module.scss';
import MobileMenuBar from "./filled/bar";
import MobileMenuClose from "./filled/menuclose";
import EyeOpenedOutlined from "./outlined/Eyeopened";
import ManageTaxOutlined from "./outlined/managetax";
import GoogleMeetFilled from "./filled/googlemeet";
import CiscoWebexFilled from "./filled/ciscowebex";
import ZoomFilled from "./filled/zoom";
import MicrosoftTeamsFilled from "./filled/microsoftteams";
import SlackFilled from "./filled/slack";
import SkypeFilled from "./filled/skype";
import GoToMeetingFilled from "./filled/gotomeeting";
import DepartmentOutlined from "./outlined/department";
import LoaderFilled from "./filled/loader";
import ZipFilled from "./filled/zip";

import user from './user.svg';
import group from './groups.svg';
import photo from './photo.svg';


function KoIcon(props) {
  const { src, fallback, name = 'default', height = 16, isImage, width = 16, onClick, color } = props;
  let defaultUrl = user;
  if (fallback === 'user') {
    defaultUrl = user;
  } else if (fallback === 'groups') {
    defaultUrl = group;
  } else {
    defaultUrl = photo;
  }


  return (
    isImage ? <div className={styles.wrapper}
      onClick={onClick && onClick} style={{ width: width + 1, height: height + 1 }}>
      <KoImage
        preview={false} src={src || defaultUrl} width={width}
        height={height}
        fallback={defaultUrl}
      />
    </div> : <div className={styles.wrapper} onClick={onClick && onClick} style={{ width: width + 1, height: height + 1 }}>
      {name === "ProjectsOutlined" && <ProjectsOutlined height={height} width={width} />}
      {name === "ProfileUpdateOutlined" && <ProfileUpdateOutlined height={height} width={width} />}
      {name === "EpicOutlined" && <EpicOutlined height={height} width={width} />}
      {name === "WorkspaceOutlined" && <WorkspaceOutlined height={height} width={width} />}
      {name === "NoWorkSpaceOutlined" && <NoWorkSpaceOutlined height={height} width={width} />}
      {name === "DownArrowOutlined" && <DownArrowOutlined height={height} width={width} />}
      {name === "StopHandOutlined" && <StopHandOutlined height={height} width={width} />}
      {name === "TestCasesOutlined" && <TestCasesOutlined height={height} width={width} />}
      {name === "ListOutlined" && <ListOutlined height={height} width={width} />}
      {name === "AddtoGroupsOutlined" && <AddtoGroupsOutlined height={height} width={width} />}
      {name === "DocumentsOutlined" && <DocumentsOutlined height={height} width={width} />}
      {name === "InvoicesOutlined" && <InvoicesOutlined height={height} width={width} />}
      {name === "SecurityOutlined" && <SecurityOutlined height={height} width={width} />}
      {name === "CalendarOutlined" && <CalendarOutlined height={height} width={width} color={color} />}
      {name === "DeploymentOutlined" && <DeploymentOutlined height={height} width={width} />}
      {name === "SuspendOutlined" && <SuspendOutlined height={height} width={width} />}
      {name === "ActivateOutlined" && <ActivateOutlined height={height} width={width} />}
      {name === "EventsOutlined" && <EventsOutlined height={height} width={width} />}
      {name === "SalesOutlined" && <SalesOutlined height={height} width={width} />}
      {name === "ClientsOutlined" && <ClientsOutlined height={height} width={width} />}
      {name === "OrgOutlined" && <OrgOutlined height={height} width={width} color={color} />}
      {name === "SettingsOutlined" && <SettingsOutlined height={height} width={width} />}
      {name === "PollOutlined" && <PollOutlined height={height} width={width} />}
      {name === "SharedOutlined" && <SharedOutlined height={height} width={width} />}
      {name === "InfoOutlined" && <InfoOutlined height={height} width={width} />}
      {name === "TickOutlined" && <TickOutlined height={height} width={width} />}
      {name === "EmpIdOutlined" && <EmpIdOutlined height={height} width={width} />}
      {name === "EnvOutlined" && <EnvOutlined height={height} width={width} />}
      {name === "UploadOutlined" && <UploadOutlined height={height} width={width} />}
      {name === "CopyOutlined" && <CopyOutlined height={height} width={width} />}
      {name === "LogOutlined" && <LogOutlined height={height} width={width} color={color}/>}
      {name === "TotalUsersOutlined" && <TotalUsersOutlined height={height} width={width} />}
      {name === "ActiveUseresOutlined" && <ActiveUseresOutlined height={height} width={width} />}
      {name === "InactiveUsersOutlined" && <InactiveUsersOutlined height={height} width={width} />}
      {name === "TotalGroupsOutlined" && <TotalGroupsOutlined height={height} width={width} />}
      {name === "InvitedOutlined" && <InvitedOutlined height={height} width={width} />}
      {name === "RequestedOutlined" && <RequestedOutlined height={height} width={width} />}
      {name === "ArrowLeftOutlined" && <ArrowLeftOutlined height={height} width={width} />}
      {name === "ArrowRightOutlined" && <ArrowRightOutlined color={color} height={height} width={width} />}
      {name === "RowOpenOutlined" && <RowOpenOutlined height={height} width={width} />}
      {name === "ModulesOutlined" && <ModulesOutlined height={height} width={width} />}
      {name === "ComponentsOutlined" && <ComponentsOutlined height={height} width={width} />}
      {name === "MeetingsOutlined" && <MeetingsOutlined height={height} width={width} />}
      {name === "QuickViewOutlined" && <QuickViewOutlined height={height} width={width} />}
      {name === "RowCloseOutlined" && <RowCloseOutlined height={height} width={width} />}
      {name === "BoldOutlined" && <BoldOutlined height={height} width={width} />}
      {name === "DescendingOutlined" && <DescendingOutlined height={height} width={width} />}
      {name === "AscendingOutlined" && <AscendingOutlined height={height} width={width} />}
      {name === "SortOutlined" && <SortOutlined height={height} width={width} />}
      {name === "BugOutlined" && <BugOutlined height={height} width={width} />}
      {name === "ChangerequestOutlined" && <ChangerequestOutlined height={height} width={width} />}
      {name === "ChatOutlined" && <ChatOutlined height={height} width={width} />}
      {name === "CloseOutlined" && <CloseOutlined height={height} width={width} color={color}/>}
      {name === "CloudDownloadOutlined" && <CloudDownloadOutlined height={height} width={width} />}
      {name === "EditOutlined" && <EditOutlined height={height} width={width} />}
      {name === "MoveToOutlined" && <MoveToOutlined height={height} width={width} />}
      {name === "ShareOutlined" && <ShareOutlined height={height} width={width} />}
      {name === "CodeOutlined" && <CodeOutlined height={height} width={width} />}
      {name === "DeleteOutlined" && <DeleteOutlined height={height} width={width} />}
      {name === "DocOutlined" && <DocOutlined height={height} width={width} />}
      {name === "DownloadOutlined" && <DownloadOutlined height={height} width={width} />}
      {name === "FeaturerequrstOutlined" && <FeaturerequrstOutlined height={height} width={width} />}
      {name === "ImagesOutlined" && <ImagesOutlined height={height} width={width} />}
      {name === "ImprovementOutlined" && <ImprovementOutlined height={height} width={width} />}
      {name === "ItalicOutlined" && <ItalicOutlined height={height} width={width} />}
      {name === "UnderlineOutlined" && <UnderlineOutlined height={height} width={width} />}
      {name === "LinkOutlined" && <LinkOutlined height={height} width={width} />}
      {name === "VerticalMenuOutlined" && <VerticalMenuOutlined height={height} width={width} />}
      {name === "MenuOutlined" && <MenuOutlined height={height} width={width} />}
      {name === "NotificationOutlined" && <NotificationOutlined height={height} width={width} />}
      {name === "OpenOutlined" && <OpenOutlined height={height} width={width} />}
      {name === "PdfOutlined" && <PdfOutlined height={height} width={width} />}
      {name === "PinOutlined" && <PinOutlined height={height} width={width} />}
      {name === "PlayingAudioOutlined" && <PlayingaudioOutlined height={height} width={width} />}
      {name === "PlayWhiteOutlined" && <PlaywhiteOutlined height={height} width={width} />}
      {name === "PointsOutlined" && <PointsOutlined height={height} width={width} />}
      {name === "QueryOutlined" && <QueryOutlined height={height} width={width} />}
      {name === "SendOutlined" && <SendOutlined height={height} width={width} />}
      {name === "SmileyOutlined" && <SmileyOutlined height={height} width={width} />}
      {name === "AtOutlined" && <AtOutlined height={height} width={width} />}
      {name === "SearchDocOutlined" && <SearchDocOutlined height={height} width={width} />}
      {name === "DragOutlined" && <DragOutlined height={height} width={width} />}
      {name === "ParagraphOutlined" && <ParagraphOutlined height={height} width={width} />}
      {name === "NumbersOutlined" && <NumbersOutlined height={height} width={width} />}
      {name === "DropdownOutlined" && <DropdownOutlined height={height} width={width} />}
      {name === "CheckboxOutlined" && <CheckboxOutlined height={height} width={width} />}
      {name === "ChoiceOutlined" && <ChoiceOutlined height={height} width={width} />}
      {name === "LineOutlined" && <LineOutlined height={height} width={width} />}
      {name === "WebOutlined" && <WebOutlined height={height} width={width} />}
      {name === "CountryOutlined" && <CountryOutlined height={height} width={width} />}
      {name === "CustomerOutlined" && <CustomerOutlined height={height} width={width} />}
      {name === "YesnoOutlined" && <YesnoOutlined height={height} width={width} />}
      {name === "ImportOutlined" && <ImportOutlined height={height} width={width} />}
      {name === "ExportOutlined" && <ExportOutlined height={height} width={width} />}
      {name === "ResetOutlined" && <ResetOutlined height={height} width={width} />}
      {name === "AudioOutlined" && <AudioOutlined height={height} width={width} />}
      {name === "ScrumSprintOutlined" && <ScrumSprintOutlined height={height} width={width} />}
      {name === "BillableOutlined" && <BillableOutlined height={height} width={width} />}
      {name === "NonBillableOutlined" && <NonBillableOutlined height={height} width={width} />}
      {name === "ReplyOutlined" && <ReplyOutlined height={height} width={width} />}
      {name === "StoryOutlined" && <StoryOutlined height={height} width={width} />}
      {name === "SubtaskOutlined" && <SubtaskOutlined color={color} height={height} width={width} />}
      {name === "SupportOutlined" && <SupportOutlined height={height} width={width} />}
      {name === "TableOutlined" && <TableOutlined height={height} width={width} />}
      {name === "TaskOutlined" && <TaskOutlined height={height} width={width} />}
      {name === "DepartmentOutlined" && <DepartmentOutlined height={height} width={width} />}
      {name === "UnplayedaudioOutlined" && <UnplayedaudioOutlined height={height} width={width} />}
      {name === "PlayOutlined" && <PlayOutlined color={color} height={height} width={width} />}
      {name === "PauseOutlined" && <PauseOutlined color={color} height={height} width={width} />}
      {name === "StopOutlined" && <StopOutlined color={color} height={height} width={width} />}
      {name === "FilterOutlined" && <FilterOutlined height={height} width={width} />}
      {name === "VideoOutlined" && <VideoOutlined height={height} width={width} />}
      {name === "WishListOutlined" && <WishListOutlined height={height} width={width} />}
      {name === "BackLogOutlined" && <BackLogOutlined height={height} width={width} />}
      {name === "LastSprintOutlined" && <LastSprintOutlined height={height} width={width} />}
      {name === "ActiveSprintOutlined" && <ActiveSprintOutlined height={height} width={width} />}
      {name === "ClosedSprintOutlined" && <ClosedSprintOutlined height={height} width={width} />}
      {name === "TimeZoneOutlined" && <TimeZoneOutlined height={height} width={width} />}
      {name === "UsersOutlined" && <Users height={height} width={width} />}
      {name === "AttachmentOutlined" && <Attachments height={height} width={width} />}
      {name === "PhoneOutlined" && <PhoneOutlined height={height} width={width} />}
      {name === "HomeOutlined" && <HomeOutlined height={height} width={width} />}
      {name === "EmailOutlined" && <EmailOutlined height={height} width={width} />}
      {name === "PayslipOutlined" && <PayslipOutlined height={height} width={width} />}
      {name === "ManageTaxOutlined" && <ManageTaxOutlined height={height} width={width} />}
      {name === "ChangePasswordOutlined" && <ChangePasswordOutlined height={height} width={width} />}
      {name === "AnnouncementsOutlined" && <AnnouncementsOutlined height={height} width={width} />}
      {name === "LogoutOutlined" && <LogoutOutlined height={height} width={width} />}
      {name === "TextOutlined" && <TextOutlined height={height} width={width} />}

      {name === "APIOutlined" && <APIOutlined height={height} width={width} />}
      {name === "SendRequestOutlined" && <SendRequestOutlined color={color} height={height} width={width} />}
      {name === "PlusOutlined" && <PlusOutlined color={color} height={height} width={width} />}
      {name === "SearchOutlined" && <SearchOutlined color={color} height={height} width={width} />}
      {name === "DoubleQuotesOutlined" && <DoubleQuotesOutlined color={color} height={height} width={width} />}
      {name === "NumberedOutlined" && <NumberedOutlined color={color} height={height} width={width} />}
      {name === "BulletOutlined" && <BulletOutlined color={color} height={height} width={width} />}
      {name === "LeftOutlined" && <LeftOutlined color={color} height={height} width={width} />}
      {name === "RightOutlined" && <RightOutlined color={color} height={height} width={width} />}
      {name === "CenterOutlined" && <CenterOutlined color={color} height={height} width={width} />}
      {name === "JustifyOutlined" && <JustifyOutlined color={color} height={height} width={width} />}
      {name === "StrikethroughOutlined" && <StrikethroughOutlined color={color} height={height} width={width} />}
      {name === "TextColorOutlined" && <TextColorOutlined color={color} height={height} width={width} />}
      {name === "BgColorOutlined" && <BgColorOutlined color={color} height={height} width={width} />}
      {name === "FolderOutlined" && <FolderOutlined color={color} height={height} width={width} />}
      {name === "EyeOpenedOutlined" && <EyeOpenedOutlined color={color} height={height} width={width} />}
      {name === "SkypeOutlined" && <SkypeOutlined color={color} height={height} width={width} />}
      {name === "TreeOutlined" && <TreeOutlined color={color} height={height} width={width} />}
      {name === "DashboardOutlined" && <DashboardOutlined color={color} height={height} width={width} />}

      {name === "ZipFilled" && <ZipFilled height={height} width={width} />}
      {name === "StoryFilled" && <StoryFilled height={height} width={width} />}
      {name === "Folder0Filled" && <Folder0Filled height={height} width={width} />}
      {name === "Folder1Filled" && <Folder1Filled height={height} width={width} />}
      {name === "Folder2Filled" && <Folder2Filled height={height} width={width} />}
      {name === "Folder3Filled" && <Folder3Filled height={height} width={width} />}
      {name === "Folder4Filled" && <Folder4Filled height={height} width={width} />}
      {name === "Folder5Filled" && <Folder5Filled height={height} width={width} />}
      {name === "TestingFilled" && <TestingFilled height={height} width={width} />}
      {name === "GrpupsFilled" && <GrpupsFilled height={height} width={width} />}
      {name === "UploadFilled" && <UploadFilled height={height} width={width} />}
      {name === "DevFilled" && <DevFilled height={height} width={width} />}
      {name === "UatFilled" && <UatFilled height={height} width={width} />}
      {name === "PrdFilled" && <PrdFilled height={height} width={width} />}
      {name === "PlusFilled" && <PlusFilled height={height} width={width} />}
      {name === "AppLogoFilled" && <AppLogoFilled height={height} width={width} />}
      {name === "LoaderFilled" && <LoaderFilled height={height} width={width} />}
      {name === "BugFilled" && <BugFilled height={height} width={width} />}
      {name === "ChangerequestFilled" && <ChangeRequestFilled height={height} width={width} />}
      {name === "EpicFilled" && <EpicFilled height={height} width={width} />}
      {name === "FeatureRequestFilled" && <FeatureRequrstFilled height={height} width={width} />}
      {name === "GoogleFilled" && <GoogleFilled height={height} width={width} />}
      {name === "ImprovementsFilled" && <ImprovementFilled height={height} width={width} />}
      {name === "MicrosoftFilled" && <MicrosoftFilled height={height} width={width} />}
      {name === "QueryFilled" && <QueryFilled height={height} width={width} />}
      {name === "SubtaskFilled" && <SubTaskFilled height={height} width={width} />}
      {name === "PriorityFilled" && <PriorityFilled height={height} width={width} />}
      {name === "StatusFilled" && <StatusFilled height={height} width={width} />}

      {name === "SupportFilled" && <SupportFilled height={height} width={width} />}
      {name === "TaskFilled" && <TaskFilled height={height} width={width} />}
      {name === "WishListFilled" && <WishListFilled height={height} width={width} />}
      {name === "BackLogFilled" && <BackLogFilled height={height} width={width} />}
      {name === "LastSprintFilled" && <LastSprintFilled height={height} width={width} />}
      {name === "GoalFilled" && <GoalFilled height={height} width={width} />}
      {name === "ActiveSprintFilled" && <ActiveSprintFilled height={height} width={width} />}
      {name === "ClosedSprintFilled" && <ClosedSprintFilled height={height} width={width} />}
      {name === "RightArrowFilled" && <RightArrowFilled height={height} width={width} />}
      {name === "UserFilled" && <UserFilled height={height} width={width} />}
      {name === "KanbanOutlined" && <KanbanOutlined height={height} width={width} />}
      {name === "Avatar1Filled" && <Avatar1Filled height={height} width={width} />}
      {name === "Avatar2Filled" && <Avatar2Filled height={height} width={width} />}
      {name === "Avatar3Filled" && <Avatar3Filled height={height} width={width} />}
      {name === "Avatar4Filled" && <Avatar4Filled height={height} width={width} />}
      {name === "BusinessFilled" && <BusinessFilled height={height} width={width} />}
      {name === "BusinessProfileFilled" && <BusinessProfileFilled height={height} width={width} />}
      {name === "PreferencesFilled" && <PreferencesFilled height={height} width={width} />}
      {name === "LocationFilled" && <LocationFilled height={height} width={width} />}
      {name === "BankFilled" && <BankFilled height={height} width={width} />}
      {name === "TaxFilled" && <TaxFilled height={height} width={width} />}
      {name === "DiscountFilled" && <DiscountFilled height={height} width={width} />}
      {name === "PdfFilled" && <PdfFilled height={height} width={width} />}
      {name === "ShareFilled" && <ShareFilled height={height} width={width} />}
      {name === "PrintFilled" && <PrintFilled height={height} width={width} />}
      {name === "EditFilled" && <EditFilled height={height} width={width} />}
      {name === "PreviewFilled" && <PreviewFilled height={height} width={width} />}
      {name === "TickFilled" && <TickFilled color={color} height={height} width={width} />}
      {name === "DotFilled" && <DotFilled color={color} height={height} width={width} />}
      {name === "CrossFilled" && <CrossFilled height={height} width={width} color={color} />}
      {name === "ArrowFilled" && <ArrowFilled height={height} width={width} />}
      {name === "MenubarFilled" && <MobileMenuBar height={height} width={width} />}
      {name === "CloseMenubarFilled" && <MobileMenuClose height={height} width={width} />}
      {name === "DashboardFilled" && <DashboardFilled height={height} width={width} />}
      {name === "InboxFilled" && <InboxFilled height={height} width={width} />}
      {name === "TimeSheetFilled" && <TimeSheetFilled height={height} width={width} />}
      {name === "PerformanceFilled" && <PerformanceFilled height={height} width={width} />}
      {name === "GoogleMeetFilled" && <GoogleMeetFilled height={height} width={width} />}
      {name === "CiscoWebexFilled" && <CiscoWebexFilled height={height} width={width} />}
      {name === "ZoomFilled" && <ZoomFilled height={height} width={width} />}
      {name === "MicrosoftTeamsFilled" && <MicrosoftTeamsFilled height={height} width={width} />}
      {name === "SlackFilled" && <SlackFilled height={height} width={width} />}
      {name === "SkypeFilled" && <SkypeFilled height={height} width={width} />}
      {name === "GoToMeetingFilled" && <GoToMeetingFilled height={height} width={width} />}

      {name === "BannerTwoTone" && <BannerTwoTone height={height} width={width} />}
      {name === "AssignedTaskTwoTone" && <AssignedTaskTwoTone height={height} width={width} />}
      {name === "AssigneeTwoTone" && <AssigneeTwoTone height={height} width={width} />}
      {name === "DaysReminingTwoTone" && <DaysReminingTwoTone height={height} width={width} />}
      {name === "IssueRisedTwoTone" && <IssueRisedTwoTone height={height} width={width} />}
      {name === "LeaveTodayTwoTone" && <LeaveTodayTwoTone height={height} width={width} />}
      {name === "MeetingsTwoTone" && <MeetingsTwoTone height={height} width={width} />}
      {name === "OfficeTwoTone" && <OfficeTwoTone height={height} width={width} />}
      {name === "WishListTwoTone" && <WishListTwoTone height={height} width={width} />}
      {name === "WorkFromHomeTwoTone" && <WorkFromHomeTwoTone height={height} width={width} />}
      {name === "EmptyTwoTone" && <EmptyTwoTone height={height} width={width} />}
      {name === "TableEmptyTwoTone" && <TableEmptyTwoTone height={height} width={width} />}
      {name === "InvoiceTempleteTwoTone" && <InvoiceTempleteTwoTone height={height} width={width} />}
      {name === "WorkingTwoTone" && <WorkingTwoTone height={height} width={width} />}
      {name === "PageNotFoundTwoTone" && <PageNotFoundTwoTone height={height} width={width} />}


    </div>
  );
}

export default KoIcon;