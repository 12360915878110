import { KoInput } from "packages";

const TimeSpent = ({ value, onChange }) => {
  const formatInput = (input) => {
    
    const sanitizedInput = input
      .replace(/[^0-9wdhm\s]/gi, '')
      .replace(/([wdhm])(?=[wdhm])/gi, '$1 ')
      .replace(/(\d)([wdhm])/g, '$1$2 ')
      .replace(/\s+/g, ' ')
      .trim();

    const order = ['w', 'd', 'h', 'm'];
    const seenUnits = new Set();
    let wCount = 0;

    const filteredInput = sanitizedInput
      .split(' ')
      .filter((segment) => {
        
        const match = segment.match(/^(\d{1,2})([wdhm])$/i);
        if (match) {
          const [, number, unit] = match;
          const lowerUnit = unit.toLowerCase();

          
          if (number.length > 2) {
            return false;
          }

          
          if (lowerUnit === 'w') {
            if (wCount > 0) return false;
            wCount += 1;
          }

          
          if (seenUnits.has(lowerUnit)) {
            return false;
          }

          
          const currentIndex = order.indexOf(lowerUnit);
          if (
            currentIndex === -1 ||
            (seenUnits.size > 0 && currentIndex < Math.max(...[...seenUnits].map(unit => order.indexOf(unit))))
          ) {
            return false;
          }

          seenUnits.add(lowerUnit);
        }
        return true;
      })
      .join(' ');

    return filteredInput;
  };

  const handleChange = (event) => {
    const formattedValue = formatInput(event.target.value);
    onChange(formattedValue);
  };

  return (
    <KoInput
      value={value}
      onChange={handleChange}
      placeholder="Ex: 1w 3d 6h 20m"
    />
  );
};

export { TimeSpent };
