const HOME_SUB_MENU =
  [
    {
      key: 'home/approvals/tms/all',
      icon: 'TickOutlined',
      label: "Inbox"
    },
    {
      key: 'home/timesheet/draft',
      icon: 'TimeSheetFilled',
      label: "Time Sheet"
    }
    // {
    //   key: 'home/leaves',
    //   icon:'CalendarOutlined',
    //   label: "Leaves"
    // },
    // {
    //   key: 'home/performance',
    //   icon:'PerformanceFilled',
    //   label: "Performance"
    // },
    // {
    //   key: 'home/expenses',
    //   icon:'PayslipOutlined',
    //   label: "Expenses"
    // },
    // {
    //   key: 'home/payslips',
    //   icon:'PayslipOutlined',
    //   label: "Pay slips"
    // },
    // {
    //   key: 'home/managetax',
    //   icon:'ManageTaxOutlined',
    //   label: "Manage Tax"
    // }
  ];


const FILES_SUB_MENU = [{
  label: 'Dashboard',
  key: 'files/dashboard',
  icon: 'DashboardFilled'
}, {
  label: 'My Files',
  key: 'files/me',
  icon: 'DocumentsOutlined'
},
{
  label: 'Shared',
  key: 'files/shared',
  icon: 'SharedOutlined'
}];

const APICLIENT_SUB_MENU = [{
  label: 'Dashboard',
  key: 'apiclient/dashboard',
  icon: 'DashboardFilled'
}, {
  label: 'Environments',
  key: 'apiclient/environments',
  icon: 'EnvOutlined'
},
{
  label: 'Collections',
  key: 'apiclient/collections',
  icon: 'APIOutlined'
}];
// ,
// {
//   label: 'CI/CD',
//   key: 'apiclient/cicd',
//   icon: 'DeploymentOutlined'
// }];



const NAVIGATIONS = [{
  label: 'Task Planner',
  id: 'taskplanner'
}, {
  label: 'Files',
  id: 'files'

}, {
  label: 'API Client',
  id: 'apiclient'
}, {
  label: 'Invoices',
  id: 'invoices'
}, {
  label: 'Organization',
  id: 'org'
}, {
  label: 'Admin Console',
  id: 'settings'
}];

const CALENDAR_SUB_MENU = [{
  label: 'Dashboard',
  key: 'calendar/dashboard',
  icon: 'DashboardFilled'
},
{
  label: 'Events',
  key: 'calendar/events',
  icon: 'CalendarOutlined'
},
{
  label: 'Meetings',
  key: 'calendar/meetings',
  icon: 'MeetingsOutlined'
}];


const TOPNAV = [
  {
    key: 'workspace',
    link: 'workspace',
    icon: 'WorkspaceOutlined',
    text: 'Workspace',
    active: true
  },
  {
    key: 'taskplanner',
    link: `/taskplanner/dashboard`,
    icon: 'ProjectsOutlined',
    text: 'Task Planner',
    active: true,
    condition: 'workspace'
  },
  {
    key: 'files',
    link: `/files/dashboard`,
    icon: 'DocumentsOutlined',
    text: 'Files',
    active: false,
    condition: 'workspace'
  },
  {
    key: 'apiclient',
    link: `/apiclient/environments`,
    icon: 'EnvOutlined',
    text: 'API Client',
    active: false,
    condition: 'workspace'
  },
  {
    key: 'invoices',
    link: `/invoices/dashboard`,
    icon: 'InvoicesOutlined',
    text: 'Invoices',
    condition: 'workspace',
    active: true
  },
  {
    key: 'calendar',
    link: '/calendar/dashboard',
    icon: 'CalendarOutlined',
    text: 'Calendar',
    active: true
  },
  {
    key: 'org',
    link: '/org/employees',
    icon: 'OrgOutlined',
    text: 'Organization',
    active: true
  },
  {
    key: 'admin',
    link: '/admin/settings',
    icon: 'SettingsOutlined',
    text: 'Admin Console',
    active: true
  }
];

export {
  NAVIGATIONS,
  HOME_SUB_MENU,
  FILES_SUB_MENU,
  APICLIENT_SUB_MENU,
  CALENDAR_SUB_MENU,
  TOPNAV
};