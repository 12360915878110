import React, { useState } from 'react';
import KoIcon from 'packages/icon';
import styles from './attachment.module.scss';
import {  KoToast, Upload } from 'packages';
import { getCookie } from 'shared/utils/cookies';

const KoAttachment = ({form, name}) => {

  const [fileList, setFileList] = useState([]);
  // const [previewOpen, setPreviewOpen] = useState(false);
  // const [previewImage, setPreviewImage] = useState('');
  const props = {
    name: 'files',
    multiple: true,
    action: `${process.env.REACT_APP_PROXY_HOST}/api/v1/org/upload`,
    data:{service:'taskplanner'},
    headers: {
      Authorization: `Bearer ${getCookie("accessToken")}`
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'done') {
        setFileList( info.fileList);
        KoToast.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        KoToast.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  const getBase = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handlePreview = async(file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase(file.originFileObj);
    }
    // setPreviewImage(file.url || file.preview);
    // setPreviewOpen(true);
  };


  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };


  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e?.fileList;
  };

  const uploadButton = (
    <div className={styles.dndArea}>
      <KoIcon name='AttachmentOutlined'/>
      <div>Add image, video, or file</div>
    </div>
  );

  return (
    <Upload
      getValueFromEvent={normFile}
      className={styles.card}
      listType="picture-card"
      fileList={fileList}
      {...props}
      onChange={handleChange}
      onPreview={handlePreview}
      showUploadList={{showPreviewIcon : false}}>
      {uploadButton}
    </Upload>
  );
};
export default KoAttachment;