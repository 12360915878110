const roleAccess = {
  1: ['Workspace', 'Task Planner', 'Files', 'API Client', 'Invoices', 'Calendar', 'Organization', 'Admin Console'],
  2: ['Workspace', 'Task Planner', 'Files', 'API Client', 'Invoices', 'Calendar', 'Organization', 'Admin Console'],
  3: ['Workspace', 'Task Planner', 'Files', 'Calendar', 'Organization', 'Admin Console'],
  4: ['Workspace', 'Task Planner', 'Files', 'API Client', 'Invoices', 'Calendar'],
  5: ['Workspace', 'Task Planner', 'Files', 'Calendar', 'Organization'],
  6: ['Workspace', 'Task Planner', 'Files', 'Invoices', 'Calendar'],
  7: ['Workspace', 'Task Planner', 'Files', 'API Client', 'Calendar']
};

const menu = [
  {
    key: 'workspace',
    link: 'workspace',
    icon: 'WorkspaceOutlined',
    text: 'Workspace',
    active: true
  },
  {
    key: 'taskplanner',
    link: '/taskplanner/dashboard',
    icon: 'ProjectsOutlined',
    text: 'Task Planner',
    condition: 'workspace',
    active: true
  },
  {
    key: 'files',
    link: '/files/dashboard',
    icon: 'DocumentsOutlined',
    text: 'Files',
    condition: 'workspace',
    active: false
  },
  {
    key: 'apiclient',
    link: '/apiclient/environments',
    icon: 'EnvOutlined',
    text: 'API Client',
    condition: 'workspace',
    active: false
  },
  // {
  //   key: 'invoices',
  //   link: '/invoices/dashboard',
  //   icon: 'InvoicesOutlined',
  //   text: 'Invoices',
  //   condition: 'workspace',
  //   active: true
  // },
  // {
  //   key: 'calendar',
  //   link: '/calendar/dashboard',
  //   icon: 'CalendarOutlined',
  //   text: 'Calendar',
  //   active: true
  // },
  {
    key: 'org',
    link: '/org/employees',
    icon: 'OrgOutlined',
    text: 'Organization',
    active: true
  },
  {
    key: 'admin',
    link: '/admin/settings',
    icon: 'SettingsOutlined',
    text: 'Admin Console',
    active: true
  }
];

function getMenuForRoles(user) {
  const accessibleMenus = new Set();

  user?.roles?.forEach(roleId => {
    const accessibleItems = roleAccess[roleId] || [];
    accessibleItems?.forEach(item => accessibleMenus?.add(item));
  });

  return menu.filter(menuItem => menuItem.active && accessibleMenus.has(menuItem.text));
}

export { getMenuForRoles };
